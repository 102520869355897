import React, { useCallback } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { useStore } from '@asteria/utils';

import { TranslationService } from '@asteria/services-language';

import { Tag, StatsTag } from '@asteria/component-tags';
import Text from '@asteria/component-typography/text';
import { CurrencyView } from '@asteria/component-graph/controllers/currency';

const SearchMatch = React.memo(styled(
	({
		className,
		match,
		onHide,
		notifications = [],
		isActive = false,
		matchAction = () => {},
	}) => {
		const [listStatistics = []] = useStore(
			'store-list',
			({ searchStatistics }) => searchStatistics,
		);

		const action = useCallback(
			(_, status) => {
				matchAction(match, status);
			},
			[match, matchAction],
		);

		if (match.type === 'client') {
			const total =
				listStatistics?.[
					(match.config.type === 'SUPPLIER' ? 'withdraw' : 'deposit')
				]?.total || 0;
			const stats = Math.abs(
				match?.config?.statistic?.invoices?.perYear?.total?.[
					new Date().getFullYear()
				] || 0,
			);

			return (
				<li
					className={classNames(
						className,
						'asteria-search-results-item',
						`asteria-search-results-item-customer`,
					)}
				>
					<Tag
						tag={match.config.name}
						onClick={action}
						type="client"
						bar={stats !== 0}
						data={{
							values: [
								{ size: (stats / total) * 100, status: 'SENT' },
							],
						}}
						translationKey="client"
						className={classNames({
							'asteria-state-active': isActive,
						})}
					>
						{stats !== 0 ? (
							<Text className="asteria-text-tag-size">
								{`${Math.round((stats / total) * 100)}%`}
							</Text>
						) : (
							<Text className="asteria-text-tag-size asteria-text-tag-empty">
								{TranslationService.get(
									'search.results.customer.activity.none',
								)}
							</Text>
						)}
					</Tag>
				</li>
			);
		}

		if (match.type === 'currency') {
			return (
				<li
					className={classNames(
						className,
						'asteria-search-results-item',
						`asteria-search-results-item-currency`,
					)}
				>
					<CurrencyView
						currency={match.currency}
						total={match.total}
						totalImpact={match.totalImpact}
						append
						setShow={onHide}
					/>
				</li>
			);
		}

		return (
			<li
				className={classNames(
					className,
					'asteria-search-results-item',
					`asteria-search-results-item-${match.type}`,
				)}
			>
				<StatsTag
					name={match.config.name}
					tagType={match.type}
					statistics={listStatistics}
					notifications={notifications}
					onClick={action}
					active={isActive}
				/>
			</li>
		);
	},
)`
	${StatsTag} {
		width: 100%;
	}

	.asteria-text-tag-empty {
		width: 300px !important;
		color: #999999 !important;
	}
`);

SearchMatch.displayName = 'SearchMatch';

export default SearchMatch;
