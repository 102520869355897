import React, { useContext } from 'react';
import { Text } from '@asteria/component-typography';
import { ButtonV2 } from '@asteria/component-button';
import Modal, { ModalBody, ModalFooter } from '@asteria/component-modal';
import { GLOBAL } from '@asteria/env';
import { Translation, TranslationService } from '@asteria/services-language';
import Context from '../../context';
import helpKeys from './helpKeys';

const HelpStepTwo = () => {
	const { setActiveModal } = useContext(Context);

	return (
		<Modal
			backdrop
			open
			onClose={() => setActiveModal('')}
			size="small"
			className="asteria-onboarding-guide-modal"
			detach
			title={TranslationService.get(
				'onboarding.asteria.help.first.header',
				'Var hittar jag min API-nyckel',
			)}
		>
			<ModalBody className="modal-body">
				<div
					style={{
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
					}}
				>
					<Translation
						translationKey="onboarding.asteria.help.second.text"
						defaultText={`Klicka på Administrera användare i menyn och därefter
Lägg till integration

Sök efter Asteria AB, klicka därefter på godkänn

Kopiera API-nyckeln och klistra in den i fältet.`}
						Component={Text}
					/>
					<div>
						<img
							style={{
								// width: '100%',
								width: '56%',
								display: 'flex',
								margin: '16px auto',
								// maxHeight: '350px',
								// paddingRight: '40px',
							}}
							src={`${
								GLOBAL.baseUrl
							}assets/asteria/images/integrations/asteria/gif/02.gif`}
							alt=""
						/>
					</div>
				</div>
			</ModalBody>
			<ModalFooter className="modal-footer">
				<div
					className="asteria-onboarding-help-footer"
					style={{
						display: 'flex',
						width: '100%',
						justifyContent: 'space-between',
					}}
				>
					<ButtonV2
						size="medium"
						type="default"
						text={TranslationService.get(
							'onboarding.back',
							'Tillbaka',
						)}
						onClick={() => setActiveModal(helpKeys.HelpStepOne)}
					/>
					<ButtonV2
						size="medium"
						type="secondary"
						text={TranslationService.get(
							'onboarding.close',
							'Stäng',
						)}
						onClick={() => setActiveModal('')}
					/>
				</div>
			</ModalFooter>
		</Modal>
	);
};

HelpStepTwo.navigationKey = helpKeys.HelpStepTwo;

export default HelpStepTwo;
