import React from 'react';
import styled, { keyframes } from 'styled-components';
import classNames from 'classnames';
import Text from '@asteria/component-typography/text';
import { Getter } from '@asteria/utils';
import { Wrapper } from '@asteria/component-core';

const pulseAnimation = keyframes`
	0% {
		transform: scale(0.95) translate(-50%, -50%);
		box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
	}
	
	70% {
		transform: scale(1) translate(-50%, -50%);
		box-shadow: 0 0 0 6px rgba(255, 121, 63, 0);
	}
	100% {
		transform: scale(0.95) translate(-50%, -50%);
		box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
	}
}`;

const Prefix = styled.span`
	display: inline-block;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	margin-right: 5px;
`;
Prefix.displayName = 'Prefix';

const Part = styled(
	({ className, active, onClick = () => {}, part, children }) => (
		<div
			className={classNames(className, `asteria-part-${part}`, {
				'asteria-state-active': active,
			})}
			onClick={() => onClick(part)}
			onKeyPress={() => onClick(part)}
			role="button"
			tabIndex="-1"
		>
			<Prefix />
			{children}
		</div>
	),
)`
	display: flex;
	margin-right: 5px;

	&.asteria-part-risk {
		${Prefix} {
			border: 2px solid #f35c1b;
			animation: ${pulseAnimation} 2s infinite;
			margin-top: 2px;
		}
	}
`;

Part.displayName = 'Part';
Part.Styler = {
	children: [
		{
			component: Prefix,
			base: [Getter('prefix')],
		},
		{
			component: Text,
			base: [Getter('text')],
		},
	],
};

const Legends = styled(({ className, legends = [], scroll }) => (
	<Wrapper className={className} horizontalScroll={scroll}>
		{legends.map(({ key, label }) => (
			<Part key={key} part={key}>
				<Text type="label">{label}</Text>
			</Part>
		))}
	</Wrapper>
))`
	display: flex;
	position: relative;
`;
Legends.displayName = 'Legends';
Legends.Styler = {
	children: [
		{
			typePrefix: 'asteria-part',
			component: Part,
			base: [Getter('part')],
		},
		{
			typePrefix: 'asteria-horizontal-scroll',
			component: Wrapper,
			base: [Getter('wrap')],
		},
	],
};

export default Legends;
