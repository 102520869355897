export default {
	'menu.financial.title': 'La finance',
	'menu.financial.factoring.title': 'Affacturage',
	'menu.financial.currencyrisk.title': 'Risque de change',
	'menu.categories.title': 'Catégories',
	'menu.clients.title': 'Les clients',
	'menu.status.title': 'Statut',
	'menu.currency.title': 'Devises',

	'menu.financial.credit.title': 'Prêts de crédit',
};
