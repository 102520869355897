import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Text as TextV2 } from '@asteria/component-core/typography';
import { TranslationService } from '@asteria/services-language';
import { StyleGetter, Getter } from '@asteria/utils';
import SecurityBar from '../securityBar';

const Content = styled.div`
	display: flex;
`;
Content.displayName = 'DetailedBarInfoBoxContent';

const Security = styled.div`
	display: flex;
	align-items: center;
`;
Security.displayName = 'DetailedBarInfoBoxSecurity';

Security.Styler = {
	children: [
		{
			component: SecurityBar,
			base: [Getter('bar')],
		},
		{
			component: TextV2,
			base: [Getter('text')],
		},
	],
};

const InfoBoxWrapper = styled.div``;
InfoBoxWrapper.displayName = 'DetailedBarInfoBoxWrapper';
const InfoBoxContainer = styled.div``;
InfoBoxContainer.displayName = 'DetailedBarInfoBoxContainer';

const InfoBox = styled(
	forwardRef((props, ref) => (
		<InfoBoxWrapper className="infobox" {...props} ref={ref}>
			<InfoBoxContainer>
				<TextV2 size="small">
					{TranslationService.get(
						'list.infoBox.description',
						'Prognosen baseras på informationen från ert bokföringssystem och banktransaktioner. Små mängder data, felaktigt bokförda transaktioner samt släpande bokföring påverkar kvaliteten på prognosen. Om ni hittar fel, kontakta vår tjänsteleverantör via supportformuläret i menyn (ikon med tre punkter ovanför grafen) under menyvalet Hjälp & support.',
					)}
				</TextV2>
				<Content className="infoBox-security-wrapper">
					<Security className="infoBox-security">
						<SecurityBar steps={5} value={1.0} max={1.0} />
						<TextV2 size="small">
							{TranslationService.get(
								'list.infoBox.legend.veryProbable',
								'Mycket sannolikt',
							)}
						</TextV2>
					</Security>
					<Security className="infoBox-security">
						<SecurityBar steps={5} value={0.8} max={1.0} />
						<TextV2 size="small">
							{TranslationService.get(
								'list.infoBox.legend.probable',
								'Sannolikt',
							)}
						</TextV2>
					</Security>
					<Security className="infoBox-security">
						<SecurityBar steps={5} value={0.61} max={1.0} />
						<TextV2 size="small">
							{TranslationService.get(
								'list.infoBox.legend.somethingLikely',
								'Något sannolikt',
							)}
						</TextV2>
					</Security>
					<Security className="infoBox-security">
						<SecurityBar steps={5} value={0.4} max={1.0} />
						<TextV2 size="small">
							{TranslationService.get(
								'list.infoBox.legend.quiteUnlikely',
								'Ganska osannolikt',
							)}
						</TextV2>
					</Security>
					<Security className="infoBox-security">
						<SecurityBar steps={5} value={0.2} max={1.0} />
						<TextV2 size="small">
							{TranslationService.get(
								'list.infoBox.legend.unlikely',
								'Osannolikt',
							)}
						</TextV2>
					</Security>
				</Content>
			</InfoBoxContainer>
		</InfoBoxWrapper>
	)),
)``;
InfoBox.displayName = 'DetailedBarInfoBox';
InfoBox.Styler = {
	children: [
		{
			base: [StyleGetter('content', {})],
			children: [
				{
					base: [StyleGetter('security', {})],
					component: Security,
				},
			],
			component: Content,
		},
		{
			base: [StyleGetter('text', {})],
			component: TextV2,
		},
		{
			base: [StyleGetter('container', {})],
			component: InfoBoxContainer,
		},
	],
};
export default InfoBox;
