import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import Title, { Text } from '@asteria/component-typography';
import Tabs, {
	TabsNavigation,
	TabsContent,
} from '@asteria/component-form/tabs';
import { Getter } from '@asteria/utils';
import {
	format,
	addMonths,
	addYears,
	isSameMonth,
	isSameDay,
	isThisMonth,
	isThisYear,
} from 'date-fns';
import Button from '@asteria/component-button/button';

// import Input from '../input';
import DateBox from './dateBox';
import YearsList from './YearsList';
import MonthsBox from './monthBox';

const Section = styled.div``;
const Header = styled.div`
	height: 40px;
`;
const Dates = styled.div``;
const Months = styled.div``;
const SelectYear = styled.div``;
const FlexDiv = styled.div``;

const CalenderController = styled(
	({
		className,
		disabled = false,
		title,
		next = () => {},
		prev = () => {},
	}) => (
		<HeaderSection className={classNames(className)}>
			<Button
				type="iconOnly"
				icon="chevronLeft"
				className={classNames('asteria-button-previous', {
					'asteria-state-disabled': disabled,
				})}
				disabled={disabled}
				onClick={e => {
					e.stopPropagation();
					prev();
				}}
			/>
			<Title size="title3">{title}</Title>
			<Button
				type="iconOnly"
				icon="chevronRight"
				className="asteria-button-next"
				onClick={e => {
					e.stopPropagation();
					next();
				}}
				// behöver "unavailable state om  man ej kan klicka, opacity 0.35 på knapp
			/>
		</HeaderSection>
	),
)``;

const HeaderSection = styled.div``;

HeaderSection.Styler = {
	children: [
		{
			component: Title,
			base: [Getter('title')],
		},
	],
};

const DateController = styled(
	({ currentDate, changeActive, multi, dates, active: activeDates }) => (
		<>
			<Weekdays className="asteria-calendar-weekdays">
				<Text>Må</Text>
				<Text>Ti</Text>
				<Text>On</Text>
				<Text>To</Text>
				<Text>Fr</Text>
				<Text>Lö</Text>
				<Text>Sö</Text>
			</Weekdays>
			<Dates className="asteria-calendar-content">
				{dates.map(date => (
					<FlexDiv>
						<DateBox
							date={date}
							isOtherMonth={!isSameMonth(currentDate, date)}
							setActive={changeActive}
							active={
								multi
									? !!activeDates.find(d =>
											isSameDay(d, date),
									  )
									: isSameDay(activeDates, date)
							}
						/>
					</FlexDiv>
				))}
			</Dates>
		</>
	),
)``;

const Weekdays = styled.div``;

Weekdays.Styler = {
	children: [
		{
			component: Text,
			base: [Getter('text')],
		},
	],
};

const MonthController = ({
	months,
	currentDate,
	changeActive,
	multi,
	active,
}) => (
	<Months className="asteria-calendar-content">
		{months.map(month => (
			<MonthsBox
				isOtherMonth={!isSameMonth(currentDate, month)}
				date={month}
				setActive={changeActive}
				active={
					multi
						? !!active.find(d => isSameMonth(d, month))
						: isSameDay(active, month)
				}
			/>
		))}
	</Months>
);

const YearController = ({ years, currentDate, changeActive, active }) => (
	<SelectYear>
		{years.map(year => (
			<YearsList
				isOtherMonth={!isSameMonth(currentDate, year)}
				date={year}
				setActive={changeActive}
				active={isSameDay(active, year)}
			/>
		))}
	</SelectYear>
);

const CalenderWrapper = styled(
	({
		className,
		extend,
		isOpen,
		allow,
		multi,
		currentDate,
		setCurrentDate,
		active,
		changeActive,
		dates,
		months,
		years,
		children,
	}) => (
		<div
			className={classNames(className, 'asteria-calendar-wrapper', {
				'asteria-calendar-wrapper-open': isOpen,
			})}
		>
			{isOpen && (
				<Tabs active="days">
					{!extend && allow.length > 1 ? (
						<TabsNavigation key="navigation">
							{allow.includes('day') ? (
								<Text tab="days">Days</Text>
							) : (
								<></>
							)}
							{allow.includes('month') ? (
								<Text tab="months">Months</Text>
							) : (
								<></>
							)}
							{allow.includes('year') ? (
								<Text tab="years">Years</Text>
							) : (
								<></>
							)}
						</TabsNavigation>
					) : (
						<></>
					)}
					<TabsContent key="content">
						<div tab="days" className="asteria-tab-transactions">
							<Section className="asteria-calendar-section first">
								<Header className="asteria-calendar-header">
									{!extend ? (
										<CalenderController
											className="asteria-calendar-header-year"
											disabled={isThisYear(currentDate)}
											title={format(currentDate, 'YYYY')}
											prev={() => {
												setCurrentDate(
													addYears(currentDate, -1),
												);
											}}
											next={() => {
												setCurrentDate(
													addYears(currentDate, +1),
												);
											}}
										/>
									) : null}
									<CalenderController
										className="asteria-calendar-header-month"
										disabled={
											isThisYear(currentDate) &&
											isThisMonth(currentDate)
										}
										title={format(currentDate, 'MMM')}
										prev={() => {
											setCurrentDate(
												addMonths(currentDate, -1),
											);
										}}
										next={() => {
											setCurrentDate(
												addMonths(currentDate, +1),
											);
										}}
									/>
								</Header>
								<DateController
									currentDate={currentDate}
									changeActive={changeActive}
									dates={dates}
									multi={multi}
									active={active}
								/>
							</Section>
						</div>
						<div tab="months" className="asteria-tab-forecast">
							<Section className="asteria-calendar-section first">
								<Header className="asteria-calendar-header">
									<CalenderController
										className="asteria-calendar-header-year"
										disabled={isThisYear(currentDate)}
										title={format(currentDate, 'YYYY')}
										prev={() => {
											setCurrentDate(
												addYears(currentDate, -1),
											);
										}}
										next={() => {
											setCurrentDate(
												addYears(currentDate, +1),
											);
										}}
									/>
								</Header>
								<MonthController
									currentDate={currentDate}
									active={active}
									months={months}
									changeActive={changeActive}
								/>
							</Section>
						</div>
						<div tab="years" className="asteria-tab-forecast">
							<YearController
								changeActive={changeActive}
								years={years}
								active={active}
								currentDate={currentDate}
							/>
						</div>
					</TabsContent>
				</Tabs>
			)}
			{children}
		</div>
	),
)``;

CalenderWrapper.Styler = {
	children: [
		{
			component: Tabs,
			base: [Getter('tabs')],
			children: [
				{
					base: [Getter('tabsNavigation')],
					children: [
						{
							component: Text,
							base: [Getter('text')],
						},
					],
				},
			],
		},
		{
			component: SelectYear,
			base: [Getter('selectYear')],
			children: [
				{
					component: YearsList,
					base: [Getter('yearsList')],
					children: [
						{
							component: Button,
							base: [Getter('button')],
						},
					],
				},
			],
		},
		{
			component: Section,
			base: [Getter('section')],
			children: [
				{
					component: Header,
					base: [Getter('header')],
					children: [
						{
							component: HeaderSection,
							base: [Getter('headerSection')],
							children: [
								{
									component: Button,
									base: [Getter('button')],
								},
							],
						},
					],
				},
				{
					component: Weekdays,
					base: [Getter('weekdays')],
				},
				{
					component: Dates,
					base: [Getter('dates')],
					children: [
						{
							component: DateBox,
							base: [Getter('date')],
						},
					],
				},
				{
					component: Months,
					base: [Getter('months')],
					children: [
						{
							component: MonthsBox,
							base: [Getter('monthsbox')],
						},
					],
				},
			],
		},
	],
};

export default CalenderWrapper;
