import React, { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import { GLOBAL } from '@asteria/env';
import { Title, Text } from '@asteria/component-core';
import Button from '@asteria/component-core/button';
import Prefix from '@asteria/component-core/prefix';
import SecurityBar from '@asteria/component-list/components/securityBar';
import Bar from '@asteria/component-graph/controllers/bar';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import getStoreValue from '@asteria/services-datalayer/utils/get';
import { FeatureFlag } from '@asteria/component-featureflag';
import { markNotificationAsViewed } from '@asteria/services-datalayer/services/notifications/actions';
import Modal, { ModalBody, ModalFooter } from '@asteria/component-modal';
import Tooltip from '@asteria/component-tooltip';
import { useHistory } from '@asteria/utils/hooks';

import { TranslationService, Translation } from '@asteria/services-language';
import { saveUser } from '@asteria/services-datalayer/services/auth/actions';
import NotificationItemBase from './base';
import trigger from '../actions';

// eslint-disable-next-line import/prefer-default-export
export const getVolatilityText = (value, max = 1, steps = 5) => {
	const roundedValue = Math.min(1, Math.round(value * 100) / 100);
	const step = Math.floor(roundedValue / (max / steps));
	return TranslationService.get(`security.level.${step}`);
};

const Image = styled(({ className, src, style }) => {
	const assetSrc = src;

	return <img className={className} src={assetSrc} alt="" style={style} />;
})`
	max-width: 100%;
`;

const NotificationForecastModal = styled(
	({ className, open, onClose, onAbort }) => (
		<Modal
			open={open}
			portal
			onClose={onAbort}
			className={className}
			title={TranslationService.get(
				'notification.forecast.onboarding.title',
				'Visa prognos',
			)}
		>
			<ModalBody>
				<Translation
					size="text"
					translationKey="notification.forecast.onboarding.body"
					Component={Text}
				/>
				<Image
					src={`${GLOBAL.baseUrl}assets/asteria/images/fc.gif`}
					alt="forecast"
					style={{
						margin: '16px auto',
						display: 'block',
						maxWidth: '100%',
					}}
				/>
			</ModalBody>
			<ModalFooter className="asteria-modal-footer">
				<Button
					size="medium"
					type="default"
					text={TranslationService.get('button.abort', 'Avbryt')}
					onClick={onAbort}
				/>
				<Button
					size="primary"
					type="default"
					text={TranslationService.get(
						'notification.forecast.onboarding.review',
						'Visa',
					)}
					onClick={onClose}
				/>
			</ModalFooter>
		</Modal>
	),
)`
	.asteria-modal .asteria-title {
		margin: 0 !important;
	}
	.asteria-modal-footer {
		justify-content: space-between;
	}
	.asteria-modal-footer .asteria-button-primary {
		margin-left: auto;
	}
`;

const NotificationItemForecast = styled(
	({
		className,
		id,
		title,
		notificationKey = '',
		data: { action, probability, tag = {}, bar } = {},
		data,
		onClick = () => {},
	}) => {
		const { dispatch, lookup } = useContext(DatalayerContext);
		const [showOnboarding, setShowOnboarding] = useState(false);
		const history = useHistory();

		const onClose = useCallback(() => {
			// onClick();
			dispatch(markNotificationAsViewed(id));
		}, [dispatch, id]);

		const onAction = useCallback(() => {
			onClick();
			setShowOnboarding(false);

			const user = getStoreValue(
				lookup('store-auth'),
				({ user: obj }) => obj,
			);

			dispatch(markNotificationAsViewed(id));
			if (!user?.settings?.flags?.hasOnboardedNotificationForecast) {
				dispatch(
					saveUser({
						...user,
						settings: {
							...user.settings,
							flags: {
								...(user?.settings?.flags || {}),
								hasOnboardedNotificationForecast: true,
							},
						},
					}),
				);
			}

			trigger(action, {
				dispatch,
				lookup,
				history,
			});

			/*
			const tags = getStoreValue(
				lookup('store-graph'),
				({ availableTags = [] }) => availableTags,
			);

			const size = getStoreValue(
				lookup('store-appstate'),
				({ timesliceSize }) => timesliceSize,
			);

			const user = getStoreValue(
				lookup('store-auth'),
				({ user: obj }) => obj,
			);

			if (!user?.settings?.flags?.hasOnboardedNotificationForecast) {
				dispatch(
					saveUser({
						...user,
						settings: {
							...user.settings,
							flags: {
								...(user?.settings?.flags || {}),
								hasOnboardedNotificationForecast: true,
							},
						},
					}),
				);
			}

			dispatch(markNotificationAsViewed(id));
			dispatch(setCurrentDate(addTimeslice(new Date(), size, -1)));
			dispatch(setActiveGroups('cashflow-bar-graph', [startDate]));
			dispatch(setListOpen(true, 'notification.forecast'));
			dispatch(setSelectedType(tag?.type || ['WITHDRAW', 'DEPOSIT']));
			dispatch(setSelectedDate(startDate));

			if (hasListDateOrder) {
				dispatch(setListReverse(true));
			}

			if (data.filters) {
				const filters = [
					...tags.filter(({ config: { name } = {} }) =>
						data.filters.find(({ tagName }) => tagName === name),
					),
					...data.filters.filter(
						({ type: filterType }) => filterType,
					),
				];

				dispatch(setFilters(filters));
			}
			*/
		}, [action, dispatch, history, id, lookup, onClick]);

		return (
			<NotificationItemBase
				onClick={() => {
					const user = getStoreValue(
						lookup('store-auth'),
						({ user: obj }) => obj,
					);

					if (
						!user?.settings?.flags?.hasOnboardedNotificationForecast
					) {
						setShowOnboarding(true);
					} else {
						onAction();
					}
				}}
				className={classNames(
					className,
					`asteria-notification-item`,
					(notificationKey || '')
						.split('.')
						.reduce(
							(acc, k) => [...acc, `${acc[acc.length - 1]}-${k}`],
							['asteria-notification-item'],
						),
					{
						'asteria-notification-modal-open': showOnboarding,
					},
				)}
			>
				<NotificationForecastModal
					open={showOnboarding}
					onClose={onAction}
					onAbort={() => setShowOnboarding(false)}
				/>
				<div className="asteria-notification-item-header">
					<div className="asteria-title-wrapper">
						{tag && <Prefix color={tag.name} status={tag.status} />}
						<Title size="title">
							{TranslationService.get(title, title)}
						</Title>
					</div>
					{probability && (
						<div className="asteria-probability">
							<Tooltip
								className="asteria-tooltip-transaction-cell-security"
								hover
								isStatic
								content={TranslationService.get(
									[
										'notification.securitybar.content',
										`notification.${notificationKey}.securitybar.content`,
									],
									'{{volatilityText}}',
									{
										data,
										volatilityText: getVolatilityText(
											probability || 0,
										),
									},
								)}
								title=""
							>
								<SecurityBar
									value={probability}
									max={1}
									steps={5}
								/>
							</Tooltip>
							<Text size="text">
								{getVolatilityText(probability || 0)}
							</Text>
						</div>
					)}
				</div>

				<div className="asteria-notification-item-body">
					<Translation
						Component={Text}
						size="text"
						translationKey={`notification.${notificationKey}.text`}
						default={`notification.${notificationKey}.text`}
						data={data}
					/>
					<FeatureFlag feature="notifications-forecast-bar">
						{bar && <Bar hasTooltip={false} {...bar} />}
					</FeatureFlag>
				</div>

				<div className="asteria-notification-item-footer">
					<Button
						className={className}
						onClick={() => {
							const user = getStoreValue(
								lookup('store-auth'),
								({ user: obj }) => obj,
							);

							if (
								!user?.settings?.flags
									?.hasOnboardedNotificationForecast
							) {
								setShowOnboarding(true);
							} else {
								onAction();
							}
						}}
						type="link"
						size="small"
						title={TranslationService.get(
							[
								'notification.forecast.review',
								'notification.forecast.review.tooltip',
							],
							'Visa',
							{
								data,
								volatilityText: getVolatilityText(
									probability || 0,
								),
							},
						)}
						text={TranslationService.get(
							'notification.forecast.review',
							'Visa',
							{
								data,
								volatilityText: getVolatilityText(
									probability || 0,
								),
							},
						)}
					/>
				</div>
				<Button
					className="asteria-close"
					onClick={onClose}
					type="link"
					icon="close"
					size="small"
					title={TranslationService.get('notitification.hide')}
				/>
			</NotificationItemBase>
		);
	},
)`
	.asteria-notification-item-header {
		.asteria-title-wrapper {
			${Prefix} {
				display: block;
				height: 12px;
				width: 12px;
				margin-right: 8px;
			}
		}
		.asteria-probability {
			order: -1;
			flex-direction: row;
			display: flex;
			align-items: center;
			.asteria-text {
				color: #999999 !important;
				font-size: 12px !important;
				display: none;
			}
		}
	}

	.asteria-notification-item-body {
		${Bar} {
			display: none;
			margin-top: 8px;
			height: 8px;
		}
	}
`;

export default NotificationItemForecast;
