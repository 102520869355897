import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { FeatureFlag } from '@asteria/component-featureflag';
// import Text from '@asteria/component-typography/text';
// import Title from '@asteria/component-typography/title';
/* import DatePicker from '@asteria/component-form/datepicker'; */
import Button from '@asteria/component-button/button';
import {
	Dropdown,
	/* InputV2, */
	LabelV2,
	FormGroupV2,
} from '@asteria/component-form';
import Modal, { ModalBody, ModalFooter } from '@asteria/component-modal';
// import { TranslationService } from '@asteria/services-language';
// import InfoBox, { InfoBoxToggle } from '../infoBox';

import { closeRepeatableModal } from '@asteria/services-datalayer/services/adjustable/actions';

import DatalayerContext from '@asteria/services-datalayer/react/context';

const RepeatableModal = styled(({ className }) => {
	// eslint-disable-next-line no-unused-vars
	const [showInfo, showInfoBox] = useState(false);
	const { dispatch } = useContext(DatalayerContext);
	// const [activeDay, setActiveDay] = useState(false);
	const [activeDay, setActiveDay] = useState([]);
	return (
		<FeatureFlag feature="adjustable-repeatable">
			<div className={classNames(className)}>
				<Modal
					// title={TranslationService.get(
					// 	'adjustable.repeatable.title',
					// )}
					open
					title="Repeatera"
					onClose={() => dispatch(closeRepeatableModal())}
				>
					<ModalBody className="asteria-modal-body modal-body">
						<div className={classNames(`asteria-form`)}>
							{/* <Title size="title2">testar</Title>
							<Text>
								Lorem ipsum dolor sit amet, id oblique pertinax
								mediocrem pro, imperdiet comprehensam has in.
							</Text> */}
							{/* 							<FormGroupV2>
								<LabelV2>Belopp</LabelV2>
								<InputV2
									className="asteria-adjustable"
									elementType="input"
									type="number"
									placeholder=""
									helper="sek"
								/>
							</FormGroupV2> */}
							<FormGroupV2>
								<LabelV2>
									{/* {TranslationService.get(
										'list.adjustable.date.label',
									)} */}
									Från
								</LabelV2>
								{/* <DatePicker /> */}
							</FormGroupV2>
							<div className={classNames(`asteria-form-grouped`)}>
								<FormGroupV2 className="asteria-group">
									<LabelV2>Var</LabelV2>
									<Dropdown
										iconClosed="triangleDown"
										iconOpen="triangleUp"
										options={[
											{
												value: '1',
												label: '1',
											},
											{
												value: '2',
												label: '2',
											},
											{
												value: '3',
												label: '3',
											},
											{
												value: '4',
												label: '4',
											},
											{
												value: '5',
												label: '5',
											},
											{
												value: '6',
												label: '6 (upp till 99)',
											},
										]}
										placeholder="1"
										itemIcon="check"
										showSelected
										type="default"
									/>
								</FormGroupV2>
								<FormGroupV2 className="asteria-group">
									<LabelV2 />
									<Dropdown
										iconClosed="triangleDown"
										iconOpen="triangleUp"
										options={[
											{
												value: 'day',
												label: 'Dag',
											},
											{
												value: 'week',
												label: 'Vecka',
											},
											{
												value: 'month',
												label: 'Månad',
											},
											{
												value: 'year',
												label: 'År',
											},
										]}
										placeholder="Månad"
										itemIcon="check"
										showSelected
										type="default"
									/>
								</FormGroupV2>
							</div>
							<ul className="asteria-weekdays">
								{['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'].map(
									day => (
										<li className="asteria-weekday">
											<Button
												type="link"
												size="medium"
												className={classNames(
													`asteria-weekday-button`,
													{
														'asteria-state-active': activeDay.includes(
															day,
														),
													},
												)}
												onClick={() =>
													setActiveDay(
														activeDay.includes(day)
															? [
																	...activeDay.filter(
																		d =>
																			d !==
																			day,
																	),
															  ]
															: [
																	...activeDay,
																	day,
															  ],
													)
												}
												text={day}
											/>
										</li>
									),
								)}
								{/* {['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'].map(
									day => (
										<li className="asteria-weekday">
											<Button
												type="link"
												size="medium"
												className={classNames(
													`asteria-weekday-button`,
													{
														'asteria-state-active':
															activeDay === day,
													},
												)}
												onClick={() =>
													setActiveDay(
														activeDay === day
															? false
															: day,
													)
												}
												text={day}
											/>
										</li>
									),
								)} */}
							</ul>
							<FormGroupV2>
								<LabelV2>
									{/* {TranslationService.get(
										'list.adjustable.date.label',
									)} */}
									Slutdatum
								</LabelV2>
								{/* <DatePicker /> */}
								<Button
									icon="close"
									type="icon"
									size="small"
									className="asteria-date-remove"
								/>
							</FormGroupV2>
						</div>
					</ModalBody>
					<ModalFooter className="modal-footer">
						<Button
							size="medium"
							type="default"
							text="Avbryt"
							onClick={() => dispatch(closeRepeatableModal())}
						/>
						<Button
							size="medium"
							type="primary"
							text="Spara"
							onClick={() => dispatch(closeRepeatableModal())}
						/>
					</ModalFooter>
				</Modal>
			</div>
		</FeatureFlag>
	);
})`
	.asteria-modal {
		.asteria-modal-body {
			overflow: visible;
		}

		.asteria-form {
			background-color: rgb(250, 242, 235);
			padding: 16px 16px 30px;
			.asteria-form-grouped {
				justify-content: space-between;
				margin-left: -10px;
				margin-right: -10px;
				display: flex;
				.asteria-form-group {
					flex: 1;
					margin: 0 10px;
					width: 100%;
					&:first-of-type {
						max-width: 22%;
					}
				}
			}

			.asteria-weekdays {
				flex-direction: row;
				display: flex;
				list-style-type: none;
				padding: 0;
				margin: 24p 0 16px 0;
				.asteria-weekday {
					list-style-type: none;
					padding: 0;
					margin: 0;
					flex: 1;
					align-items: center;
					justify-content: center;
					display: flex;
					.asteria-weekday-button {
						border-radius: 50%;
						width: 40px;
						height: 40px;
						display: flex;
						justify-content: center;
						align-items: center;
						background: #ebe7e2;
						.asteria-text {
							color: #2f2424;
							font-family: Arial, Helvetica, sans-serif;
							font-size: 14px;
							font-weight: 400;
							text-decoration: none;
						}
						&.asteria-state-active {
							background: #efb7b6;
						}
					}
				}
			}

			.asteria-form-group {
				.asteria-state {
					background: #fff;
				}
				.asteria-forms-dropdown-open
					.asteria-forms-dropdown-menu-direction-down {
					max-height: 240px;
				}
				.asteria-button {
					&.asteria-date-remove {
						margin-top: -36px;
						margin-left: auto;
						margin-right: 44px;
						background: transparent;
						.asteria-icon-wrapper {
							svg {
								fill: #9f8c82;
							}
						}
					}
				}
			}
		}
	}
`;

RepeatableModal.displayName = 'RepeatableModal';

RepeatableModal.Styler = {
	children: [],
};
export default RepeatableModal;
