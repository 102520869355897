export default {
	'menu.financial.title': 'Finanzen',
	'menu.financial.factoring.title': 'Factoring',
	'menu.financial.currencyrisk.title': 'Währungsrisiko',
	'menu.categories.title': 'Kategorien',
	'menu.clients.title': 'Kunden',
	'menu.status.title': 'Status',
	'menu.currency.title': 'Währung',

	'menu.financial.credit.title': 'Kreditdarlehen',
};
