import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Getter } from '@asteria/utils';
import { Text } from '@asteria/component-core/typography';
import { TranslationService } from '@asteria/services-language';
import Tooltip from '@asteria/component-tooltip';
import SecurityBar from '../securityBar';

const processColor = (color = '', prefix = 'color', status = 'PAID') => {
	if (status === 'UNPAID') {
		return `var(--system-unpaid-${prefix})`;
	}

	if (color.startsWith('$')) {
		return `var(--${color.replace('$', 'system-')}-${prefix})`;
	}

	return color;
};

const Prefix = styled.div`
	background-color: ${({ color, status }) =>
		processColor(color, 'color', status)};
`;

const Pill = styled(
	({ className, color, status, text, probability, onClick }) => (
		<div
			className={classNames(className)}
			onClick={onClick}
			onKeyPress={onClick}
			tabIndex="-1"
			role="button"
			/* data-tooltip="12 transaktioner" */
		>
			<Prefix color={color} status={status} />
			<Text size="body">{text}</Text>
			{probability ? (
				<Tooltip
					hover
					isStatic
					content={TranslationService.get(
						'list.security.tooltip',
						'Sannolikhet {{volatility}}%',
						{
							volatility: Math.round((probability || 0.6) * 100),
						},
					)}
				>
					<SecurityBar max={1} value={probability} steps={5} />
				</Tooltip>
			) : null}
		</div>
	),
)`
	display: flex;
	align-items: center;
	${Prefix} {
		height: 12px;
		width: 12px;
		margin-right: 8px;
		border-radius: 50%;
	}

	${Text} {
		flex-grow: 1;
	}

	&:before {
		bottom: 0;
		top: 0;
	}
	&:after {
	}
`;

Pill.displayName = 'Pill';

Pill.Styler = {
	children: [
		{
			component: Prefix,
			base: [Getter('prefix')],
		},
		{
			component: Text,
			base: [Getter('text')],
		},
		{
			component: SecurityBar,
			base: [Getter('security')],
		},
	],
};

export default Pill;
