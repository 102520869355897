export default {
	support: {
		text: {
			types: {
				faqIntro: {
					style: {
						color: 'var(--dialog-text-color)',
						fontFamily: 'Arial,Helvetica,sans-serif',
						fontSize: '14px',
						whiteSpace: 'pre-wrap',
					},
				},
			},
		},
		faq: {
			title: {
				style: {
					fontFamily: 'Swedbank Headline',
					color: 'var(--primary-color)',
					fontSize: '20px',
					padding: '12px 16px',
					marginTop: '16px',
				},
			},
			button: {
				types: {
					showMore: {
						style: {
							backgroundColor: '#fff',
							marginTop: '-1px',
							width: '146px',
							borderRadius: '0px 0px 34px 34px',
							height: 'auto',
							marginLeft: 'auto',
							marginRight: 'auto',
							padding: '0px 16px 4px 8px',
							lineHeight: '19px',
							border: '2px solid rgb(235,231,225)',
							borderTop: '0px solid #fff',
							flexDirection: 'row-reverse',
						},
						icon: {
							style: {
								flexShrink: '1',
								flexGrow: '0',
								marginRight: '0px',
								width: '24px',
								alignItems: 'center',
							},
							svg: {
								style: {
									marginTop: '0px',
									height: '8px',
									width: '8px',
								},
							},
						},
						text: {
							style: {
								fontSize: '14px',
								textDecoration: 'none',
							},
						},
					},
					create: {
						style: {
							padding: '0',
							marginTop: '8px',
						},
						text: {
							style: {
								fontSize: '16px',
								textDecoration: 'none',
								fontFamily: 'Swedbank Headline',
								fontWeight: '400',
							},
						},
					},
				},
				icon: {
					style: {
						height: '16px',
					},
				},
			},
			item: {
				style: {
					display: 'flex',
					alignItems: 'flex-start',
					flexDirection: 'column',
					borderBottom: '1px solid rgb(235, 231, 225)',
				},
				button: {
					style: {
						alignItems: 'flex-start',
						padding: '10px 8px 8px 8px',
						height: 'auto',
						width: '100%',
					},
					text: {
						style: {
							fontSize: '14px',
							fontFamily: 'Swedbank Headline',
							color: 'var(--title-color)',
							textDecoration: 'none',
							textAlign: 'left',
							flex: '1',
						},
					},
					icon: {
						style: {
							flexShrink: '1',
							flexGrow: '0',
							marginRight: '4px',
						},
						svg: {
							style: {
								marginTop: '2px',
								height: '12px',
								width: '12px',
								fill: '#72605F',
								transform: 'rotate(0deg)',
								transition: 'transform 0.25s ease-in 0s',
							},
						},
					},
				},
				content: {
					style: {
						color: 'var(--dialog-text-color)',
						fontFamily: 'Arial,Helvetica,sans-serif',
						fontSize: '14px',
						whiteSpace: 'pre-wrap',
						overflow: 'hidden',
						margin: '-8px 8px 8px',
						width: '100%',
						transition: 'max-height 0.25s',
						maxHeight: '0',
					},
				},
				states: {
					active: {
						button: {
							icon: {
								svg: {
									style: {
										marginTop: '2px',
										height: '12px',
										width: '12px',
										fill: '#72605F',
										transform: 'rotate(90deg)',
										transition:
											'transform 0.25s ease-in 0s',
										transformOrigin: '50% 50%',
									},
								},
							},
						},
						content: {
							style: {
								margin: '8px',
								// transition: 'max-height 0.25s',
								maxHeight: '200px',
							},
						},
					},
				},
			},
		},
		createticket: {
			style: {
				padding: '0',
			},
			form: {
				style: {
					backgroundColor: '#FAF2EB',
				},
			},
			input: {
				placeholder: {
					style: { color: '#72605f' },
				},
				types: {
					textarea: {
						style: {
							paddingTop: '8px',
						},
					},
				},
			},
			checkbox: {
				style: {
					margin: '16px 20px 0 0px',
				},
				checkboxElement: {
					style: {
						paddingRight: '0px',
					},
				},
			},
			button: {
				types: {
					cancel: {
						style: {
							// marginLeft: '16px',
						},
					},
				},
			},
		},
		modal: {
			dialog: {
				header: {
					title: {
						queries: {
							'(max-width: 767.98px)': {
								style: {},
							},
							'(min-width: 768px)': {
								style: {},
							},
							'(min-width: 1200px)': {
								style: {},
							},
						},
					},
				},
				footer: {
					style: {
						justifyContent: 'space-between',
					},
				},
			},
		},
	},
};
