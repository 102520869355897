import React from 'react';
import styled from 'styled-components';
import Button from '@asteria/component-button/button';
import { Getter } from '@asteria/utils';
import { TranslationService } from '@asteria/services-language';

const Navigation = styled(
	({ className, onNext = () => {}, onPrev = () => {} }) => (
		<div className={className} role="grid" tabIndex="-1">
			<Button
				className="asteria-button-left"
				type="primary"
				size="large"
				onClick={() => onPrev()}
				icon="circleArrowLeft"
				title={TranslationService.get('graph.navigation.previous')}
			/>
			<Button
				className="asteria-button-right"
				type="primary"
				size="large"
				onClick={() => onNext()}
				icon="circleArrowRight"
				title={TranslationService.get('graph.navigation.next')}
			/>
		</div>
	),
)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	top: 0px;
	right: 0;
	left: 0;
	height: 100%;
	width: 100%;
`;
Navigation.displayName = 'Navigation';
Navigation.Styler = {
	children: [
		{
			component: Button,
			base: [Getter('button')],
		},
	],
};

export default Navigation;
