import AxiosSubscriber from './axiosSubscriber';

class GraphQLSubscriber extends AxiosSubscriber {
	constructor(observer, instance, query, variables, options) {
		super(
			observer,
			instance,
			'',
			'POST',
			{
				query: query.loc ? query.loc.source.body : query,
				variables,
			},
			options,
		);
	}
}

export default GraphQLSubscriber;
