import React, { useState, useCallback, useContext, useEffect } from 'react';
import { TextV2, Title } from '@asteria/component-typography';
import { ButtonV2 } from '@asteria/component-button';
import { TranslationService, Translation } from '@asteria/services-language';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { initiateIntegration } from '@asteria/services-datalayer/services/integrations/actions';
import Alert from '@asteria/component-alert';
import { useStore } from '@asteria/utils';
import Layout from '../../layout';
import Context from '../../context';
import HelpStepOne from './helpStepOne';

const Key = ({ navigator, callback = () => {} }) => {
	const { setActiveModal, setStepIndex } = useContext(Context);

	useEffect(() => {
		setStepIndex(2);
		callback('goto', {
			event: 'goto',
			integration: 'vismaeekonomi',
			integrationTitle: 'Fortnox',
			step: {
				stepId: 'vismaeekonomiActivation',
				title: 'Aktivera',
			},
			steps: [
				{ stepId: 'vismaeekonomiActivation', title: 'Aktivera' },
				{
					stepId: 'vismaeekonomiConnecting',
					title: 'Väntar på anslutning',
				},
			],
		});
	}, [callback, setStepIndex]);

	const { dispatch } = useContext(DatalayerContext);
	const [erps = []] = useStore(
		'store-integrations',
		({ integrations = [] }) => integrations,
	);
	const [integrationId] = useStore(
		'store-integrations',
		({ integrationInProgress }) => integrationInProgress,
	);

	const [errors, setErrors] = useState([]);

	useEffect(() => {
		if (integrationId) {
			const integration = erps.find(int => int.id === integrationId);
			if (integration) {
				setErrors([...errors, ...integration.config.errors]);
				dispatch(() => ({
					action: 'CONNECTION_CLEANUP',
				}));
			}
		}
	}, [dispatch, erps, errors, integrationId]);

	const nextStep = useCallback(() => {
		dispatch(
			initiateIntegration({
				integrationName: 'vismaeekonomi',
			}),
		);
		navigator.next(null, navigator);
	}, [dispatch, navigator]);

	return (
		<Layout
			type="vismaeekonomi-key"
			header={
				<Title
					className="asteria-title-onboarding-header"
					size="title2"
				>
					{TranslationService.get(
						'onboarding.connect.header',
						'2. Anslut till bokföringssystem',
					)}
				</Title>
			}
			body={
				<>
					{errors && errors.length > 0 ? (
						<Alert
							icon
							description={false}
							type="error"
							headingContent={
								<Translation
									translationKey={[
										'onboarding.visma.connection.error',
										`onboarding.visma.connection.error.${
											errors[0].code
										}`,
									]}
									defaultText={errors[0].message}
								/>
							}
						/>
					) : null}
					<div
						className="asteria-dialog-box"
						style={{
							padding: '20px 20px 20px',
							backgroundColor: '#fbf2ea',
						}}
					>
						<Title size="title4">Visma eEkonomi</Title>
						<TextV2 size="small">
							{TranslationService.get(
								'onboarding.vismaeekonomi.api_form.title',
								'För att aktivera kopplingen behöver du logga in hos Visma eEkonomi och godkänna att Asteria AB för tillgång till er bokföring',
							)}
						</TextV2>
					</div>
					<ButtonV2
						text={TranslationService.get(
							'onboarding.vismaeekonomi.api_form.help',
							'Var hittar jag min API-nyckel',
						)}
						type="flat"
						icon="triangleRight"
						iconPosition="after"
						onClick={() =>
							setActiveModal(HelpStepOne.navigationKey)
						}
					/>
					<div
						className="asteria-wizard-inner-footer"
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							paddingTop: '20px',
						}}
					>
						<ButtonV2
							size="medium"
							type="default"
							onClick={() =>
								navigator.cancel(
									{
										event: 'cancel',
									},
									navigator,
								)
							}
							text={TranslationService.get(
								'onboarding.abort',
								'Avbryt',
							)}
						/>
						<div
							className="asteria--activation-key-controls"
							style={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<ButtonV2
								size="medium"
								type="secondary"
								onClick={() =>
									navigator.back(
										{
											event: 'goto',
											step: {
												stepId: 'selectErp',
											},
										},
										navigator,
									)
								}
								text={TranslationService.get(
									'onboarding.back',
									'Tillbaka',
								)}
							/>
							<ButtonV2
								size="medium"
								type="primary"
								style={{ marginLeft: '16px' }}
								text={TranslationService.get(
									'onboarding.vismaeekonomi.url',
									'Logga in hos Visma eEkonomi',
								)}
								onClick={() => nextStep()}
							/>
						</div>
					</div>
				</>
			}
			footer={<></>}
		/>
	);
};

Key.navigationKey = Symbol('VismaEconomyKey');

export default Key;
