import React, {
	useState,
	useContext,
	useEffect,
	useCallback,
	useMemo,
} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import gql from 'graphql-tag';
// import { isFuture } from 'date-fns';
/* import Icon from '@asteria/component-icon/v2'; */
import Button from '@asteria/component-button';
import Title from '@asteria/component-typography/title';
import Text from '@asteria/component-typography/text';
// import Icon from '@asteria/component-icon/v2';

import { useStore, formatNumber } from '@asteria/utils';
import { TranslationService } from '@asteria/services-language';

import DatalayerContext from '@asteria/services-datalayer/react/context';
import { useLocation, useHistory } from '@asteria/utils/hooks';
import Modal, { ModalBody, ModalHeader } from '@asteria/component-modal';
import {
	addFilter,
	addToFactoring,
	toggleFilter,
} from '@asteria/services-datalayer/services/appstate/actions';
import Icon from '@asteria/component-icon/v2';
import { useFeature } from '@asteria/component-featureflag';

/* const CustomersList = styled(({ className }) => {
	const [showCustomer, setShowCustomer] = useState(false);
	return (
		<div className={classNames(className, `customers-list`)}>
			<Title size="title5">Företagsnamnet AB</Title>
			<Button
				className="asteria-button-close"
				icon="right"
				type="link"
				size="small"
				onClick={() => setShowCustomer(!showCustomer)}
			/>
			<Text>asd</Text>
		</div>
	);
})`
	.customer-list {
	}
`;
 */

export const GET_CLIENT = gql`
	query GetClient($id: ObjectId!) {
		client(id: $id) {
			id
			name
			type
			active
			contact {
				general {
					street
					street2
					city
					zipcode
					country
					phone
				}
			}
			meta {
				clientNumber
			}
			info {
				orgNumber
				creditLimit
				termsOfPayment
			}
			analysis {
				paymentMoral
			}
			unpaid: invoices(
				pageFilters: { first: 0 }
				filters: { paidStatus: UNPAID }
			) {
				id
				sums {
					original {
						total
					}
				}
				info {
					status
					isForecast
				}
			}
			overdue: invoices(
				pageFilters: { first: 0 }
				filters: { paidStatus: OVERDUE }
			) {
				id
				sums {
					original {
						total
					}
				}
				info {
					status
					isForecast
				}
			}
			invoices(pageFilters: { first: 0 }) {
				id
				sums {
					original {
						total
						currency
					}
				}
				dates {
					invoiceSent
					invoiceDue
					invoicePaid
				}
				info {
					status
					isForecast
				}
				meta {
					message
				}
			}
			statistic {
				invoices {
					perYear {
						total
					}
				}
			}
		}
	}
`;

// const getInvoiceStatus = ({ dates: { invoicePaid, invoiceDue } }) => {
// 	if (invoicePaid) {
// 		return 'paid';
// 	}

// 	if (isFuture(invoiceDue)) {
// 		return 'unpaid';
// 	}

// 	return 'overdue';
// };

const CustomerOverview = styled(({ className, clientId }) => {
	// const [
	// 	showMoreCustomerInformation,
	// 	setShowMoreCustomerInformation,
	// ] = useState(false);

	const { dispatch, lookup } = useContext(DatalayerContext);
	const [client, setClient] = useState(false);
	const [showFactoring, setShowFactoring] = useState(true);
	const hasFactoringFeature = useFeature({ key: 'factoring' });
	const history = useHistory();
	const apiService = lookup('service-api');

	const [availableCategories = []] = useStore(
		'store-graph',
		state => state.availableCategories,
	);

	const availableTags = useMemo(
		() =>
			availableCategories
				.reduce((acc, category) => acc.concat(category?.tags), [])
				.map(({ _id, name }) => ({
					id: _id,
					type: 'tag',
					config: { name },
				})),
		[availableCategories],
	);

	useEffect(() => {
		apiService
			.query(GET_CLIENT, { id: clientId }, { reqAuth: true })
			.subscribe(data => {
				setClient(data?.data?.client || {});
			});
	}, [apiService, clientId]);

	const forecastedTransactions = client?.invoices?.filter(
		({ info: { isForecast } }) => isForecast,
	);

	const filterClient = useCallback(() => {
		const { name, type } = client;
		const chip = {
			id: clientId,
			type: 'client',
			config: {
				client: clientId,
				name,
				type,
			},
		};

		dispatch(toggleFilter(chip));
		/*
		const paid = client?.invoices?.filter(
			invoice => invoice?.dates?.invoicePaid,
		);
		*/

		history.push('/');
	}, [client, clientId, dispatch, history]);

	const sentToFactoring = useCallback(() => {
		dispatch(
			addToFactoring({
				type: 'CLIENT',
				client,
				transactions: forecastedTransactions,
			}),
		);

		dispatch(
			addFilter({
				id: `status-UNPAID`,
				type: 'status',
				config: {
					name: `$unpaid`,
					status: 'UNPAID',
				},
			}),
		);
		dispatch(
			addFilter({
				id: `status-FORECAST`,
				type: 'status',
				config: {
					name: `$forecast`,
					status: 'FORECAST',
				},
			}),
		);

		const factoring = availableTags.find(
			({ config: { name } }) => name === '$factoring',
		);

		if (factoring) {
			dispatch(addFilter(factoring));
		}

		const customers = availableTags.find(
			({ config: { name } }) => name === '$customer',
		);

		if (customers) {
			dispatch(addFilter(customers));
		}

		history.push('/factoring');
	}, [availableTags, client, dispatch, forecastedTransactions, history]);

	if (!client) {
		return null;
	}

	const year = new Date().getFullYear();

	const { name } = client;

	return (
		<div className={classNames(className, `customer-overview`)}>
			<div className={classNames(`customer-header`)}>
				<div className={classNames(`customer-header-overview`)}>
					<Title
						size="title4"
						className={classNames(`customer-name`)}
					>
						{name}
					</Title>
					<Text
						className={classNames(
							`asteria-customer-cashflow-impact`,
						)}
					>
						{`${TranslationService.get(
							'customer.kpi.label.impact',
						)} ${year}`}
						<span>
							{`${formatNumber(
								client?.statistic?.invoices?.perYear?.total?.[
									year
								] || 0,
								false,
								false,
							)} SEK`}
						</span>
					</Text>

					<Button
						type="link"
						className="asteria-button-filter"
						onClick={() => filterClient('all')}
					>
						{TranslationService.get('customer.details.filterby')}
					</Button>
				</div>
				<div className={classNames(`asteria-kpi`)}>
					<Text className={classNames(`asteria-kpi-item`)}>
						{`${TranslationService.get(
							'customer.kpi.label.unpaid',
						)}`}
						<span>
							{client?.unpaid?.filter(
								({ info: { isForecast } }) => !isForecast,
							)?.length || 0}
						</span>
					</Text>
					<Text className={classNames(`asteria-kpi-item`)}>
						{`${TranslationService.get(
							'customer.kpi.label.overdue',
						)}`}
						<span>{client?.overdue?.length || 0}</span>
					</Text>
					<Text className={classNames(`asteria-kpi-item`)}>
						{`${TranslationService.get(
							'customer.kpi.label.invoices',
						)}`}
						<span>
							{client?.invoices?.filter(
								({ info: { isForecast } }) => !isForecast,
							)?.length || 0}
						</span>
					</Text>
					<Text className={classNames(`asteria-kpi-item`)}>
						{`${TranslationService.get(
							'customer.kpi.label.forecast',
						)}`}
						<span>
							{client?.invoices?.filter(
								({ info: { isForecast } }) => isForecast,
							)?.length || 0}
						</span>
					</Text>
				</div>

				{showFactoring && hasFactoringFeature ? (
					<div className="asteria-financial-options">
						<div className="asteria-box">
							<div className="asteria-box-header">
								<Title size="3">
									Få betalt direkt med fakturaköp
								</Title>
								<Button
									icon="close"
									size="regular"
									type="link"
									onClick={() => setShowFactoring(false)}
								/>
							</div>
							<Text>
								{`Genom att sälja eller belåna den här kundens prognostiserade och obetalda fakturor stärker du snabbt ditt företags ekonomi.`}
							</Text>
							<Button
								icon="triangleRight"
								iconPosition="before"
								onClick={sentToFactoring}
								size="regular"
								type="link"
								text={TranslationService.get(
									'drawer.finance.factoring.action.title',
									'Sälj fakturor',
								)}
							/>
						</div>
					</div>
				) : null}
			</div>
			<ul className={classNames(`asteria-customer-details`)}>
				{client?.info?.orgNumber ? (
					<li className={classNames(`asteria-customer-details-item`)}>
						<Text>
							<span className="asteria-customer-details-item-label">
								{`${TranslationService.get(
									'customer.details.regnr',
								)}:`}
							</span>
							<span className="asteria-customer-details-item-value">
								{client?.info?.orgNumber}
							</span>
						</Text>
					</li>
				) : null}
				{client?.meta?.clientNumber ? (
					<li className={classNames(`asteria-customer-details-item`)}>
						<Text>
							<span className="asteria-customer-details-item-label">
								{`${TranslationService.get(
									'customer.details.customernr',
								)}:`}
							</span>
							<span className="asteria-customer-details-item-value">
								{client?.meta?.clientNumber}
							</span>
						</Text>
					</li>
				) : null}
				{client?.contact?.general?.country ? (
					<li className={classNames(`asteria-customer-details-item`)}>
						<Text>
							<span className="asteria-customer-details-item-label">
								{`${TranslationService.get(
									'customer.details.country',
								)}:`}
							</span>
							<span className="asteria-customer-details-item-value">
								{client?.contact?.general?.country}
							</span>
						</Text>
					</li>
				) : null}
				{/* <li className={classNames(`asteria-customer-details-item`)}>
					<Text>
						<span className="asteria-customer-details-item-label">
							{`${TranslationService.get('customer.details.currency')}:`}
						</span>
						<span className="asteria-customer-details-item-value">
							SEK
						</span>
					</Text>
				</li> */}
				<li className={classNames(`asteria-customer-details-item`)}>
					<Text>
						<span className="asteria-customer-details-item-label">
							{`${TranslationService.get(
								'customer.details.status',
							)}:`}
						</span>
						<span className="asteria-customer-details-item-value">
							{client?.active
								? TranslationService.get(
										'customer.details.status.active',
								  )
								: TranslationService.get(
										'customer.details.status.inactive',
								  )}
						</span>
					</Text>
					{client?.info?.orgNumber ? (
						<a
							className="asteria-button-extern-link"
							href={`https://www.allabolag.se/${client?.info?.orgNumber?.replace(
								'-',
								'',
							)}`}
							target="_blank"
							rel="noopener noreferrer"
							style={{ marginLeft: '4px' }}
						>
							<Icon asset="extern" />
						</a>
					) : null}
				</li>
				{client?.contact?.general?.street ||
				client?.contact?.general?.zipcode ||
				client?.contact?.general?.city ||
				client?.contact?.general?.country ? (
					<li className={classNames(`asteria-customer-details-item`)}>
						<Text>
							<span className="asteria-customer-details-item-label">
								{`${TranslationService.get(
									'customer.details.address',
								)}:`}
							</span>
							<span
								className="asteria-customer-details-item-value"
								style={{
									marginLeft: '16px',
									textAlign: 'right',
								}}
							>
								{`${client?.contact?.general?.street}, ${
									client?.contact?.general?.zipcode
								} ${client?.contact?.general?.city}, ${
									client?.contact?.general?.country
								}`}
							</span>
						</Text>
					</li>
				) : null}
				{client?.contact?.general?.phone ? (
					<li className={classNames(`asteria-customer-details-item`)}>
						<Text>
							<span className="asteria-customer-details-item-label">
								{`${TranslationService.get(
									'customer.details.phone',
								)}:`}
							</span>
							<span className="asteria-customer-details-item-value">
								{client?.contact?.general?.phone}
							</span>
						</Text>
					</li>
				) : null}
				{client?.analysis?.paymentMoral ? (
					<li className={classNames(`asteria-customer-details-item`)}>
						<Text>
							<span className="asteria-customer-details-item-label">
								{`${TranslationService.get(
									'customer.details.paymentMoral',
								)}:`}
							</span>
							<span className="asteria-customer-details-item-value">
								{TranslationService.get(
									client?.analysis?.paymentMoral < 0
										? 'customer.details.paymentMoral.early'
										: 'customer.details.paymentMoral.late',
									'',
									{
										paymentMoral: Math.abs(
											client?.analysis?.paymentMoral,
										),
									},
								)}
							</span>
						</Text>
					</li>
				) : null}
			</ul>
		</div>
	);
})`
	display: flex;
	flex-direction: column;
	height: 100%;

	.customer-header-overview {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 40px 0 20px;
		margin: -32px -40px 0;
		background: #fbf2ea;
		border-bottom: 1px solid #eae7e3;
		.customer-logo {
			width: 40px;
			height: 40px;
			overflow: hidden;
			img {
				display: block;
				width: 100%;
			}
		}
		.customer-name {
			margin-left: 8px;
			margin-right: 8px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-right: 10px;
			font-size: 26px;
			color: rgb(238, 112, 35);
			font-family: Swedbank Headline;
			font-weight: 400;
			.asteria-small {
				/* color: #979797; */
				font-family: Arial, Helvetica, sans-serif;
				font-weight: 400;
				line-height: 12px;
				color: #80584f;
			}
		}
		.asteria-customer-cashflow-impact {
			font-family: Arial, Helvetica, sans-serif;
			font-size: 14px;
			color: var(--text-color);
			margin: 16px 0 0;
			padding: 0;
			line-height: 1.4;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			span {
				font-family: Swedbank Sans;
				font-weight: 700;
				font-size: 24px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		.asteria-button-extern-link {
			margin-left: auto;
			margin-top: -17px;
			.asteria-icon-wrapper {
				width: 12px;
				svg {
					fill: #9f8c82;
				}
			}
			&:hover .asteria-icon-wrapper {
				svg {
					fill: #ee7024;
				}
			}
		}
	}

	.customer-header {
		.asteria-button-filter {
			/* background: #d5d3d1; */
			max-height: 32px;
			padding: 5px 16px 6px 16px;
			border-radius: 50px;
			display: flex;
			margin: 0 auto 16px;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			&:hover {
				/* background: #c5c4c3; */
			}
			.asteria-text {
				font-family: Swedbank Sans;
				font-weight: 700;
				font-size: 14px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				color: #257886;
				margin: 0;
				padding: 0;
				line-height: 1.4;
				// text-decoration: none;
			}
		}

		.asteria-kpi {
			margin: 24px 0 16px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			padding: 0 32px;
			.asteria-kpi-item {
				// &:first-of-type {
				// 	flex-basis: 100%;
				// 	width: 0;
				// 	border-right: 0;
				// 	margin: 0;
				// 	padding: 12px 0;
				// 	text-align: center;
				// 	font-size: 11px;
				// 	span {
				// 		font-family: 'Swedbank Sans';
				// 		font-weight: 600;
				// 		font-size: 16px;
				// 		margin-top: 8px;
				// 	}
				// }
				font-family: Arial, Helvetica, sans-serif;
				font-weight: 400;
				line-height: 14px;
				color: #512b2b;
				font-size: 14px;
				margin-right: 12px;
				border-right: 1px solid #d1c4bd;
				text-align: center;
				flex: 1;
				span {
					font-family: 'Swedbank Headline';
					font-weight: 800;
					font-size: 16px;
					color: #512b2b;
					display: block;
					margin-top: 8px;
				}
				&:last-of-type {
					border-right: 0px solid transparent;
					margin-right: 0;
				}
			}
		}

		.asteria-financial-options {
			// display: grid;
			// grid-template-columns: repeat( auto-fit, minmax(244px, 1fr) );
			// grid-auto-flow: column;
			// grid-column-gap: 24px;
			// grid-row-gap: 24px;
			display: flex;
			flex-direction: row;
			width: 100%;
			padding: 24px 0;
		}

		.asteria-box {
			border: 1px solid #f2f0ed;
			padding: 16px 16px;
			display: flex;
			align-content: center;
			justify-content: center;
			flex-direction: column;
			min-height: 184px;
			max-width: 244px;
			margin-right: 16px;

			&:only-child {
				max-width: 100%;
				min-height: auto;
				margin-right: 0;
			}

			.asteria-box-header {
				display: flex;
				flex-direction: row;
				margin: 0 auto 8px !important;
				width: 100%;
				${Title} {
					margin-bottom: 0 !important;
					margin-left: 0 !important;
					text-align: left;
				}
				${Button} {
					margin-top: 0;
					margin-left: auto;
					left: auto;
					right: -8px;
					padding: 8px 16px;
					.asteria-icon-wrapper {
						svg {
							fill: #9f8c82 !important;
						}
					}
				}
			}

			${Title} {
				color: #262626;
				font-family: 'Swedbank Headline';
				font-size: 18px;
				margin: 0 auto 8px !important;
				text-align: center;
				font-weight: 600;
				line-height: 1.4;
				flex-grow: 0 !important;
			}
			${Text} {
				color: #696969;
				font-size: 14px;
				text-align: left;
			}
			${Button} {
				margin-top: 12px;
				margin-left: 0 !important;
				width: max-content;
				left: -8px;
				padding: 0;
				.asteria-icon-wrapper {
					width: 20px;
					height: 12px;
					margin-right: 4px;
					svg {
						fill: #257886 !important;
					}
				}
				.asteria-text {
					color: #257886 !important;
				}
			}
			&:hover {
				background: #f5f5f5;
				box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
				cursor: pointer;
				${Title} {
					color: #53a1ac;
				}
				${Text} {
				}
				${Button} {
				}
			}
		}

		.asteria-graph-bar {
			width: 100%;
			height: 12px;
			position: relative;
			margin-bottom: 16px;
			display: none;

			.asteria-graph-bar-part {
				height: 100%;
				left: 0;
				top: 0;
				bottom: 0;
				position: absolute;
				width: 100%;

				&.asteria-graph-bar-part-overdue {
					z-index: 4;
					background: rgb(168, 47, 44);
					height: 16px;
					width: 16px;
					border-radius: 100%;
					left: auto;
					right: 88px;
					top: -8px;
					color: #ffffff;
					font-size: 10px;
					text-align: center;
					font-family: Swedbank Headline;
					font-weight: 200;
					justify-content: center;
					align-items: center;
					line-height: 17px;
				}

				&.asteria-graph-bar-part-unpaid {
					z-index: 3;
					background: var(--system-unpaid-color);
					background-image: repeating-linear-gradient(
						135deg,
						transparent,
						transparent 1px,
						rgba(0, 0, 0, 0.1) 2px,
						rgba(0, 0, 0, 0.1) 4px,
						transparent 4.5px
					);
					left: 150px;
					width: 20px;
				}

				&.asteria-graph-bar-part-revenue {
					width: 60%;
					z-index: 2;
					background: rgba(43, 153, 164, 1);
				}

				&.asteria-graph-bar-part-expenses {
					width: 60;
					z-index: 2;
					background: rgba(103, 85, 83, 1);
				}

				&.asteria-graph-bar-part-base {
					background: #b7b7b7;
					z-index: 0;
				}

				&.asteria-graph-bar-part-forecast {
					background-image: repeating-linear-gradient(
						135deg,
						transparent,
						transparent 1px,
						rgba(255, 255, 255, 0.2) 2px,
						rgba(255, 255, 255, 0.2) 4px,
						transparent 4.5px
					);
					width: 30px;
					z-index: 3;
					left: 120px;
				}
			}
		}

		.asteria-button-accordion {
			margin-top: 0px;
			margin-bottom: 4px;
			padding: 0px;
			height: auto;
			.asteria-text {
				font-family: 'Swedbank Headline';
				font-weight: 400;
				font-size: 12px;
				color: rgb(38, 38, 38);
				margin-right: 8px;
				line-height: 100%;
				margin-top: 5px;
				text-decoration: none;
			}
			.asteria-icon-wrapper {
				width: 12px;
				height: 10px;
				margin-right: 2px;
				margin-top: 4px;
				svg {
					fill: #9f8c82;
					transition: transform 0.25s ease-in 0s;
				}
			}
			&:hover .asteria-icon-wrapper {
				svg {
					fill: #ee7024;
				}
			}
			&.asteria-state-active {
				.asteria-icon-wrapper {
					svg {
						transform: rotate(180deg);
					}
				}
			}
		}
	}

	.asteria-customer-details {
		list-style-type: none;
		margin: 0 0 16px;
		padding: 0 32px;
		.asteria-customer-details-item {
			list-style-type: none;
			margin: 0;
			padding: 10px 12px;
			flex-direction: row;
			display: flex;
			border-bottom: 1px solid #eae7e3;
			&:first-of-type {
				border-top: 1px solid #eae7e3;
			}
			&:last-of-type {
				border-bottom: 1px solid transparent;
			}
			.asteria-text {
				font-family: Arial, Helvetica, sans-serif;
				font-weight: 400;
				font-size: 14px;
				color: #512b2b;
				width: 100%;
				justify-content: flex-end;
				display: flex;
				span {
					&.asteria-customer-details-item-label {
						margin-right: auto;
					}
					&.asteria-customer-details-item-value {
						font-weight: 700;
					}
				}
			}
			.asteria-button-extern-link {
				/* margin-left: auto; */
				/* margin-top: -18px; */
				height: 17px;
				padding: 0;
				.asteria-icon-wrapper {
					width: 12px;
					svg {
						fill: #9f8c82;
					}
				}
				&:hover .asteria-icon-wrapper {
					svg {
						fill: #ee7024;
					}
				}
			}
		}
	}

	.asteria-transactions {
		margin-top: 16px;
		.asteria-transaction-row {
			border-bottom: 1px solid var(--transaction-list-border-color);
			background-color: var(--transaction-list-bg-color);
			min-height: 48px;
			align-items: center;
			cursor: pointer;
			display: flex;
			.asteria-transaction-cell {
				flex: 1;
				padding: 0 12px;
				.asteria-text {
					&.asteria-body {
						color: var(--transaction-list-text-color);
					}
					&.asteria-small {
						color: #979797;
						font-size: 11px;
					}
				}
				&.asteria-transaction-cell-description {
					.asteria-text {
						&.asteria-body {
							color: var(--transaction-list-text-color);
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							padding-right: 10px;
							max-width: 117px;
						}
					}
				}
				&.asteria-transaction-cell-total {
					margin-left: auto;
					.asteria-text {
						text-align: right;
					}
				}

				.asteria-icon-status {
					width: 12px;
					height: 12px;
					float: left;
					margin-right: 4px;
					margin-top: 3px;
					&.asteria-icon-overdue {
						svg {
							fill: var(--system-overdue-color);
						}
					}
					&.asteria-icon-unpaid {
						svg {
							fill: var(--system-unpaid-color);
						}
					}
					&.asteria-icon-forecast {
						svg {
							fill: var(--system-forecast-color);
						}
					}
				}
			}
		}
	}
`;

const CustomerPanel = styled(({ className }) => {
	const { pathname } = useLocation();
	const history = useHistory();

	const [clients = []] = useStore('store-appstate', state => state.clients);

	const [, , id] = pathname.split('/');

	const Header = useMemo(
		() => (
			<ModalHeader
				onClose={() => history.push('/')}
				className="modal-header"
			>
				{id ? (
					<Button
						className="asteria-button-back"
						icon="left"
						type="link"
						onClick={() => history.push('/customer')}
					/>
				) : null}
				<Title size="title1">
					{TranslationService.get(
						id
							? 'sidepanel.client.title'
							: 'sidepanel.clients.title',
					)}
				</Title>
				<Button
					icon="close"
					type="link"
					onClick={() => history.push('/')}
				/>
			</ModalHeader>
		),
		[history, id],
	);

	return (
		<Modal
			open={pathname.startsWith('/customer')}
			onClose={() => history.push('/')}
			// hasHeader={false}
			title={Header}
			style={{ maxHeight: 'none' }}
			className={classNames(className)}
		>
			<ModalBody
				style={{
					padding: '0 0 32px',
					height: 'auto',
					overflowY: 'visible',
				}}
			>
				<div className={classNames(`asteria-customers-listing`)}>
					<div
						className={classNames(
							`asteria-customers-listing-wrapper`,
						)}
					>
						{id ? (
							<CustomerOverview clientId={id} />
						) : (
							clients.map(({ _id, id: clientId = _id, name }) => (
								<div
									className={classNames(
										`asteria-customers-list`,
									)}
									key={clientId}
									onClick={() =>
										history.push(`/customer/${clientId}`)
									}
									onKeyPress={() => {}}
									role="button"
									tabIndex="-1"
								>
									<Title size="title5">{name}</Title>
									<Button
										className="asteria-button"
										icon="right"
										type="link"
										size="small"
										onClick={() =>
											history.push(
												`/customer/${clientId}`,
											)
										}
									/>
								</div>
							))
						)}
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
})`
	.asteria-customers-listing {
		height: 100%;
		.asteria-customers-listing-wrapper {
			max-height: 55vh;
			padding: 32px 32px;
		}
	}

	${ModalHeader} {
		padding: 16px 20px 16px 16px !important;
	}

	.asteria-modal-header-with-back {
		border-bottom: 1px solid rgba(124, 124, 124, 0.3);
		align-items: center;
		padding: 12px 0px 12px 12px;
		display: flex;
		flex-direction: row;
		@media screen and (min-width: 768px) {
			padding: 16px 20px 16px 32px;
		}
		.asteria-title {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			flex-grow: 1;
			font-size: 40px;
			color: var(--primary-color);
			display: block;
			font-family: Swedbank Headline;
			font-weight: 400;
			margin: 0;
			padding: 0;
			@media screen and (min-width: 768px) {
				font-size: 24px;
				line-height: 36px;
			}
		}
		.asteria-button {
			height: 24px;
			width: 24px;
			padding: 4px;
			background-color: transparent;
			cursor: pointer;
			align-items: center;
			justify-content: center;
			flex-shrink: 1;
			position: relative;
			display: flex;
			box-sizing: border-box;
			border: 0;
			border-radius: 2px;
		}
	}

	.asteria-customers-list {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding: 8px 12px;
		cursor: pointer;
		border-bottom: 1px solid var(--transaction-list-border-color);
		.asteria-title {
			font-family: Swedbank Sans;
			font-size: 16px;
			font-weight: 600;
			color: var(--transaction-list-text-color);
		}
		&:hover {
			background: rgb(251, 242, 234);
		}
		.asteria-icon-wrapper {
			width: 8px;
		}
		.asteria-button {
			margin-left: auto;
			.asteria-icon-wrapper {
				width: 16px;
				svg {
					fill: #9f8c82;
				}
			}
			&:hover .asteria-icon-wrapper {
				svg {
					fill: #ee7024;
				}
			}
		}
	}
`;

export default CustomerPanel;
