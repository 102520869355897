import React, { useContext } from 'react';
import styled from 'styled-components';
import { useStore } from '@asteria/utils';
import Modal from '@asteria/component-modal';
import DatalayerContext from '@asteria/services-datalayer/react/context';

import { gotoRoute } from '@asteria/services-datalayer/services/appstate/actions';

import Senarios from './senarios';

const SenariosModal = styled(({ className }) => {
	const { dispatch } = useContext(DatalayerContext);

	const [route] = useStore(
		'store-appstate',
		({ route: appRoute }) => appRoute,
	);

	if (route !== '/senarios') {
		return null;
	}

	return (
		<Modal
			onClose={() => dispatch(gotoRoute('/home'))}
			className={className}
			title="Hantera Prognos"
		>
			<Senarios onClose={() => dispatch(gotoRoute('/home'))} />
		</Modal>
	);
})``;

SenariosModal.Styler = Modal.Styler;

export default SenariosModal;
