import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
// import { Getter } from '@asteria/utils';

const Accordion = styled(({ className, open, children }) => (
	<CSSTransition
		timeout={500}
		classNames="animate-height"
		appear
		in={open}
		unmountOnExit
		mountOnEnter
	>
		<div className={classNames(className)}>{children}</div>
	</CSSTransition>
))``;

Accordion.displayName = 'Accordion';

Accordion.Styler = {
	children: [],
};
export default Accordion;
