import Key from './key';
import Connecting from './connecting';
import Success from './success';
import HelpStepOne from './helpStepOne';
import HelpStepTwo from './helpStepTwo';
import { navigatorCarrier } from '../../createNavigator';

// Apply default navigation
Connecting.navigator = {
	next: navigatorCarrier(Success.navigationKey),
};

Key.navigator = {
	next: navigatorCarrier(Connecting.navigationKey),
};

Success.navigator = {
	back: navigatorCarrier(Key.navigationKey),
};

const helpModals = {
	[HelpStepOne.navigationKey]: HelpStepOne,
	[HelpStepTwo.navigationKey]: HelpStepTwo,
};

// Build up the symbol-based key-value store of given steps
export default {
	[Connecting.navigationKey]: Connecting,
	[Success.navigationKey]: Success,
	[Key.navigationKey]: Key,
};

export { Connecting, Key, Success, HelpStepOne, HelpStepTwo, helpModals };
