/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const FETCH_TRANSLATIONS = gql`
	query GetTranslations(
		$code: String!
		$themeId: ObjectId!
		$partnerId: ObjectId
	) {
		translation(code: $code, themeId: $themeId, partnerId: $partnerId) {
			translations
		}
	}
`;
