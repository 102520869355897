import React, { useState, useEffect } from 'react';
import Widget, { Sidebar, Header } from '@asteria/component-widgetlayout';
import classNames from 'classnames';
import Button from '@asteria/component-button/button';
import { selectLanguage } from '@asteria/services-datalayer/services/language/actions';
import {
	fetchUser,
	saveUser,
} from '@asteria/services-datalayer/services/auth/actions';
import styled, { css } from 'styled-components';
import { setColorMode } from '@asteria/services-datalayer/services/appstate/actions';
// eslint-disable-next-line no-restricted-globals
const locationHref = location.href;
const URI =
	locationHref.includes('stage.') ||
	locationHref.includes('dev.') ||
	locationHref.includes('localhost')
		? 'https://stage.api.asteria.ai/'
		: 'https://prod.api.asteria.ai/';
let languageCode = localStorage.asteriaLanguage || 'sv';

// eslint-disable-next-line no-restricted-globals
const locationHash = location.hash;
if (locationHash) {
	languageCode = locationHash.substring(1);
	localStorage.setItem('asteriaLanguage', languageCode);
}

const WingsApp = styled(props => {
	const { className, datalayer, site = 'wings' } = props;
	const { dispatch, lookup } = datalayer;

	useEffect(() => {
		dispatch(selectLanguage(languageCode));
		dispatch(setColorMode(localStorage.asteriaColorMode));
	}, [dispatch]);

	const Store = lookup('store-appstate');
	const AuthStore = lookup('store-auth');

	const [colorMode, updateColorMode] = useState(
		Store?.value?.colorMode || {},
	);
	const [layout, updateLayout] = useState(Store?.value?.layout || {});
	const [currentUser, setCurrentUser] = useState(AuthStore?.value?.user);
	// const [currentUser = {}] = useStore('store-auth', ({ user }) => user);
	useEffect(() => {
		dispatch(fetchUser());
		const subscriptions = [
			Store.query(store => store.layout).subscribe(data => {
				updateLayout(data);
			}),
			Store.query(store => store.colorMode).subscribe(data => {
				updateColorMode(data);
			}),
			AuthStore.query(store => store.user).subscribe(data => {
				setCurrentUser(data);
			}),
		];

		return () => {
			subscriptions.map(it => it.unsubscribe());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Store]);

	if (!currentUser) {
		return null;
	}

	return (
		<div
			id="asteria-wings"
			className={classNames('asteria-wings', className)}
		>
			<div
				className={classNames(
					`asteria-site-${site}`,
					`asteria-colormode-${colorMode || 'light'}`,
					'asteria-wings-layout',
					`asteria-wings-positioning-${layout?.positioning ||
						'relative'}`,
					`asteria-wings-layout-${layout?.layout || 'vertical'}`,
					{
						'layout-full': layout?.showFullscreen,
						'layout-minimized-sidebar': layout?.sidebarMinimized,
						'layout-title-hidden': layout?.titleHidden,
					},
				)}
			>
				<Button
					className="btn btn-icon btn-fullscreen"
					style={{
						position: 'fixed',
						right: '12px',
						top: '12px',
						zIndex: '999',
						border: '0px solid transparent',
						backgroundColor: 'transparent',
						backgroundRepeat: 'no-repeat',
						backgroundImage: `url("data:image/svg+xml;charset=utf8,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7 10H5V5H10V7H7V10ZM5 14H7V17H10V19H5V14ZM17 17H14V19H19V14H17V17ZM14 7V5H19V10H17V7H14Z' fill='%23292D3E'/%3E%3C/svg%3E")`,
						width: '24px',
						height: '24px',
						display: 'block',
						opacity: '0.15',
					}}
					type="link"
					onClick={() => {
						dispatch(
							saveUser({
								...currentUser,
								settings: {
									...(currentUser?.settings || {}),
									layout: {
										...(currentUser.settings.layout || {}),
										showFullscreen: !layout?.showFullscreen,
									},
								},
							}),
						);
					}}
					/* onClick={setWingsLayout} */
				/>
				<Button
					className="btn btn-icon btn-minimize-sidebar"
					style={{
						position: 'fixed',
						left: '12px',
						bottom: '12px',
						zIndex: '999',
						border: '0px solid transparent',
						backgroundColor: 'transparent',
						backgroundRepeat: 'no-repeat',
						backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEnSURBVHgB7VSxbcMwEDySLlJqBI+gEeIyZTbIbxDDQYA0kZgyhuERqE2UDeIRNEKKdJH4eclCgBQyRRZ246uewP89/p93wBWXhkIi3L5Yqm7h2LREa9tM5WkkQMhzePPJ4Ft02p3KXSCNvAYjA/MBradT+VETuPfi4R/5t1/Ry/R6ehjMxECuTb+OG4A/hPyOrP0K1c06stu+PUrmfqyoaPNKmIngitzWlqnkwQZHclUcyX0ZSx5sAM38F3uVpJlgkduVBViX47Oip7gp4o8c2WT22G4n35TNqFrRgBENrMPfdLbQaGMroLuXUEhVLhZRi6qzUF304URwuQiuljCD4gZ6mKSZyo82O3q2B/huJXbRgNUyZHZJbjo0aaVJbxk/PlobV5wXv/JudtjcbzOmAAAAAElFTkSuQmCC')`,
						width: '24px',
						height: '24px',
						display: 'block',
					}}
					type="link"
					onClick={() => {
						dispatch(
							saveUser({
								...currentUser,
								settings: {
									...(currentUser?.settings || {}),
									layout: {
										...(currentUser.settings.layout || {}),
										sidebarMinimized: !layout?.sidebarMinimized,
									},
								},
							}),
						);
					}}
				/>
				{!layout?.showFullscreen ? (
					<>
						<div className="asteria-wings-header">
							<Header {...props} />
						</div>
						<div className="asteria-wings-sidebar">
							<Sidebar {...props} />
						</div>
					</>
				) : null}
				<div className="asteria-wings-content">
					{!layout?.titleHidden ? (
						<h1
							style={{ position: 'relative', padding: '2px' }}
							className="asteria-widget-title"
						>
							Företagskollen
							<Button
								className="btn btn-icon btn-hide-page-title"
								style={{
									position: 'absolute',
									left: '-40px',
									top: '50%',
									zIndex: '999',
									border: '0px solid transparent',
									backgroundColor: 'transparent',
									backgroundRepeat: 'no-repeat',
									backgroundImage: `url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22 21L2 0.999998L3.41421 -0.414215L23.4142 19.5858L22 21Z' fill='%23ACACAC'/%3E%3Cpath d='M18.7253 16.8969C20.6345 15.5706 22.1349 13.6954 23 11.5C21.27 7.11 17 4 12 4C10.1957 4 8.48647 4.40498 6.95735 5.12895L8.47478 6.64638C9.57606 6.22569 10.7663 6 12 6C15.79 6 19.17 8.13 20.82 11.5C20.009 13.1565 18.7824 14.5134 17.2934 15.465L18.7253 16.8969Z' fill='%23ACACAC'/%3E%3Cpath d='M16.9256 17.9257L15.4015 16.4015C14.3361 16.7914 13.1891 17 12 17C8.2 17 4.83 14.87 3.18 11.5C3.97166 9.8831 5.16156 8.55165 6.60392 7.60394L5.17388 6.17391C3.31267 7.49575 1.84986 9.34342 1 11.5C2.73 15.89 7 19 12 19C13.7584 19 15.4265 18.6154 16.9256 17.9257Z' fill='%23ACACAC'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")`,
									width: '24px',
									height: '24px',
									display: 'block',
									transform: 'translate(0,-50%) rotate(0deg)',
									// transform: 'rotate(90deg)',
								}}
								type="link"
								onClick={() => {
									dispatch(
										saveUser({
											...currentUser,
											settings: {
												...(currentUser?.settings ||
													{}),
												layout: {
													...(currentUser.settings
														.layout || {}),
													titleHidden: !layout?.titleHidden,
												},
											},
										}),
									);
								}}
							/>
						</h1>
					) : (
						<h1
							style={{ position: 'relative', margin: '0' }}
							className="asteria-widget-title"
						>
							<Button
								className="btn btn-icon btn-hide-page-title"
								type="link"
								style={{
									position: 'absolute',
									left: '-40px',
									top: '200%',
									zIndex: '999',
									border: '0px solid transparent',
									backgroundColor: 'transparent',
									backgroundRepeat: 'no-repeat',
									backgroundImage: `url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1 12C2.73 7.61 7 4.5 12 4.5C17 4.5 21.27 7.61 23 12C21.27 16.39 17 19.5 12 19.5C7 19.5 2.73 16.39 1 12ZM20.82 12C19.17 8.63 15.79 6.5 12 6.5C8.21 6.5 4.83 8.63 3.18 12C4.83 15.37 8.2 17.5 12 17.5C15.8 17.5 19.17 15.37 20.82 12ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM7.5 12C7.5 9.52 9.52 7.5 12 7.5C14.48 7.5 16.5 9.52 16.5 12C16.5 14.48 14.48 16.5 12 16.5C9.52 16.5 7.5 14.48 7.5 12Z' fill='%23ACACAC'/%3E%3C/svg%3E%0A")`,
									width: '24px',
									height: '24px',
									display: 'block',
									transform: 'translate(0,0) rotate(0deg)',
								}}
								onClick={() => {
									dispatch(
										saveUser({
											...currentUser,
											settings: {
												...(currentUser?.settings ||
													{}),
												layout: {
													...(currentUser.settings
														.layout || {}),
													titleHidden: !layout?.titleHidden,
												},
											},
										}),
									);
								}}
							/>
						</h1>
					)}

					<Widget
						baseUrl={URI}
						languageCode={languageCode}
						preview
						{...props}
					/>
				</div>
			</div>
		</div>
	);
})`
	${({ siteStyle }) =>
		css`
			${siteStyle}
			.asteria-widget-title {
				.btn-hide-page-title {
					opacity: 0;
				}
				&:hover {
					.btn-hide-page-title {
						opacity: 0.6;
					}
				}
			}

			.layout-title-hidden
				.asteria-feature-asteria-improvements
				.asteria-cashflow-header
				.asteria-cashflow-overview-wrapper
				.asteria-cashflow-overview
				.asteria-cashflow-overview-header
				.asteria-title-company-name {
				font-size: 40px;
			}

			.asteria-feature-layout-workareas-latest {
				&:first-of-type {
					height: calc(100vh - 80px) !important;
				}
				&.asteria-feature-layout-resizable.asteria-widget-layout-large {
					height: auto !important;
				}
			}
		`}
`;

export default WingsApp;
