import React, { useContext, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { useStore } from '@asteria/utils';

import DatalayerContext from '@asteria/services-datalayer/react/context';
import {
	setFilters,
	setMode,
} from '@asteria/services-datalayer/services/appstate/actions';
import { setListReverse } from '@asteria/services-datalayer/services/list/actions';
import { TranslationService } from '@asteria/services-language';

import { Tag } from '@asteria/component-tags';
import Button from '@asteria/component-button';
import { useFeature } from '@asteria/component-featureflag';

import filterAction from './filterAction';

const ActiveFilter = styled(({ className, tag, onClick }) => (
	<li className={classNames(className, 'asteria-search-active')}>
		<Tag
			tag={tag?.config?.name || ''}
			status={tag?.config?.status}
			onClick={e => onClick(e, tag)}
			type={tag?.type}
		>
			<Button
				icon="close"
				type="icon"
				onClick={e => onClick(e, tag)}
				className="asteria-tag-remove"
			/>
		</Tag>
	</li>
))``;

ActiveFilter.displayName = 'ActiveFilter';

const ActiveFilters = styled(({ className }) => {
	const { dispatch } = useContext(DatalayerContext);
	const hasShowStatusChip = useFeature({ key: 'show-status-chip' });
	const [mode] = useStore('store-appstate', ({ mode: val }) => val);
	const [activeTags] = useStore(
		'store-appstate',
		({ filters: list }) => list,
	);

	const action = useCallback(
		(e, tag) => {
			if (e && e.stopPropagation) {
				e.stopPropagation();
			}

			if (tag?.id === 'credit') {
				dispatch(setMode(null));
			} else {
				filterAction(tag, dispatch);

				if (
					activeTags.length > 1 &&
					activeTags.filter(({ type }) => type !== 'status')
						.length === 1
				) {
					dispatch(setFilters([]));
					dispatch(setListReverse(false));
				}
			}
		},
		[activeTags, dispatch],
	);

	const activeFilters = useMemo(() => {
		const allFilters = [...activeTags];

		if (mode === 'credit') {
			allFilters.push({
				id: 'credit',
				type: 'overdraft',
				config: {
					name: TranslationService.get(
						'credit.tag.name',
						'Kreditlån',
					),
				},
			});
		}

		if (hasShowStatusChip) {
			return allFilters;
		}

		return allFilters.filter(({ type }) => type !== 'status');
	}, [activeTags, mode, hasShowStatusChip]);

	return (
		<>
			<ul
				className={classNames(
					className,
					'asteria-controller-search-active-filters',
				)}
			>
				{activeFilters.map(part => (
					<ActiveFilter key={part.id} tag={part} onClick={action} />
				))}
			</ul>
		</>
	);
})`
	display: flex;
	margin: 0;
	padding: 0;
	list-style: none;

	div[role='button'] {
		background: #eae7e4;
		border-radius: 30px;
		cursor: pointer;
		padding-right: 8px;
		margin-right: 8px;
		.asteria-category-overview {
			width: 100%;
			.asteria-chip {
				padding: 5px 0 6px 12px;
				border: 1px solid transparent;
				&:hover {
					border: 1px solid transparent;
					cursor: pointer;
					background: transparent;
				}
			}
		}
		.asteria-text {
			font-family: Swedbank Sans;
			font-weight: 700;
			font-size: 14px;
		}
		.asteria-tag-remove {
			background: transparent;
			height: 32px !important;
			width: 32px !important;
			&:hover {
				background: transparent;
			}
			.asteria-icon-wrapper {
				height: 12px;
				width: 12px;
				padding: 0;
				background: 0;
				svg {
					fill: rgb(159, 140, 130);
					transition: 0.3s ease-in-out;
				}
			}
		}
		&:hover {
			cursor: pointer;
			background: #d5d3d1;
			.asteria-tag-remove .asteria-icon-wrapper svg {
				fill: #9f8c82;
			}
			.asteria-chip {
				border: 0px solid transparent !important;
			}
		}
	}
`;

ActiveFilters.displayName = 'ActiveFilters';

export default ActiveFilters;
export { ActiveFilter };
