import { findDOMNode } from 'react-dom';
import React, { useState, useCallback, useLayoutEffect } from 'react';

// eslint-disable-next-line react/no-find-dom-node
const getElement = el => (React.isValidElement(el) ? el : findDOMNode(el));

function getSize(el) {
	if (!el) {
		return {
			width: 0,
			height: 0,
		};
	}

	return {
		width: el.offsetWidth,
		height: el.offsetHeight,
	};
}

function useComponentSize(ref) {
	const [size, setSize] = useState(
		getSize(ref ? getElement(ref.current) : {}),
	);

	const handleResize = useCallback(() => {
		if (ref.current) {
			setSize(getSize(getElement(ref.current)));
		}
	}, [ref, setSize]);

	useLayoutEffect(() => {
		if (!ref.current) {
			return null;
		}

		// eslint-disable-next-line react/no-find-dom-node
		const effectRef = getElement(ref.current);

		handleResize();

		if (typeof ResizeObserver === 'function') {
			let resizeObserver = new ResizeObserver(() => {
				handleResize();
			});
			resizeObserver.observe(effectRef);

			return () => {
				resizeObserver.disconnect(effectRef);
				resizeObserver = null;
			};
		}
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [handleResize, ref]);

	return size;
}

export default useComponentSize;
