/* eslint-disable no-unused-vars */
import React, { useRef, useState, useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import Icon from '@asteria/component-icon';
import { useFeature, FeatureFlag } from '@asteria/component-featureflag';
import { addHours, format } from 'date-fns';
import {
	Dropdown,
	InputV2,
	LabelV2,
	FormGroupV2,
} from '@asteria/component-form';
import Tabs, {
	TabsNavigation,
	TabsContent,
} from '@asteria/component-form/tabs';
import Button from '@asteria/component-core/button';
import { Getter, useStore, formatNumber } from '@asteria/utils';
import { Text, Title } from '@asteria/component-core/typography';
import Alert from '@asteria/component-alert';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { setAdjustOpen } from '@asteria/services-datalayer/services/appstate/actions';
import {
	saveTransactions,
	removeTransactions,
} from '@asteria/services-datalayer/services/adjustable/actions';
import { TranslationService, Translation } from '@asteria/services-language';

import { QUERY_CASHFLOW } from '@asteria/services-datalayer/source/http/graph';
import {
	addItemAction,
	setItemAction,
	setIgnore,
} from '@asteria/services-datalayer/services/list/actions';

import InfoBox, { InfoBoxToggle } from '../infoBox';

import AdvancedAdjustable from '../advancedAdjustable';

const AdjustableWrapper = styled.div``;

const CATEGORIES = [
	{
		value: 'invoiceClient',
		label: 'Kundfakturor',
		tag: '$customer',
		category: '$invoices',
	},
	{
		value: 'invoiceSupplier',
		label: 'Leverantörsfakturor',
		tag: '$supplier',
		category: '$invoices',
	},
	{ value: 'salary', label: 'Löner', tag: '$salary', category: '$salaries' },
	{ value: 'tax', label: 'Skatter', tag: '$tax', category: '$taxes' },
	{ value: 'misc', label: 'Övrigt', tag: '$misc' },
];

const AdjustTotalInput = styled(
	({
		className,
		types,
		lowWarning,
		unpaid,
		value,
		min = 0,
		onChange = () => {},
	}) => {
		const [showInfo, showInfoBox] = useState(false);
		const icons = {
			ACCOUNT: 'adjustAccountBalance',
			DEPOSIT: 'adjustPlus',
			WITHDRAW: 'adjustMinus',
		};
		return (
			<div className={classNames(className)}>
				<div
					className={classNames(
						className,
						`asteria-adjustable-section-title`,
					)}
				>
					<Title size="title4">
						{TranslationService.get(
							`adjustable.total.header.${types}`,
						)}
					</Title>
					<InfoBoxToggle
						className="asteria-button-info"
						open={showInfo}
						toggle={showInfoBox}
					/>
				</div>
				<InfoBox open={showInfo}>
					<Text>
						{TranslationService.get([
							'adjustable.total.info',
							`adjustable.total.info.${types}`,
						])}
					</Text>
				</InfoBox>
				<FormGroupV2>
					<LabelV2>
						{TranslationService.get([
							'adjustable.total.label',
							`adjustable.total.label.${types}`,
						])}
					</LabelV2>
					<FeatureFlag feature="adjustable-amount-input-type-icon">
						<div className="asteria-input-type">
							<Button
								type="link"
								icon={icons[types]}
								size="medium"
								title={TranslationService.get(
									`adjustable.icon.title.adjust.${types}`,
								)}
							/>
						</div>
					</FeatureFlag>
					<InputV2
						className="asteria-adjustable-total"
						elementType="input"
						type="number"
						placeholder=""
						value={Math.round(value)}
						min={min}
						step={10000}
						helper="sek"
						style={{
							paddingLeft: '16px',
						}}
						onChange={e =>
							onChange(
								e.target.value
									? parseFloat(e.target.value)
									: '',
							)
						}
					/>
				</FormGroupV2>
				{lowWarning ? (
					<Alert
						className="asteria-alert-low"
						icon
						headingContent={
							/* 							<Text>
								{`Minsta värdet du kan ange är ${formatNumber(
									unpaid,
								)} då dina obetalda fakturor uppgår till denna summa`}
							</Text> */
							<Text>
								{`Du kan inte ange under ${formatNumber(
									unpaid,
								)}`}
							</Text>
						}
						type="error"
					/>
				) : null}
			</div>
		);
	},
)`
	.asteria-adjustable-section-title {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-bottom: 8px !important;
		@media (max-width: 767.98px) {
			align-items: flex-start;
		}
		.asteria-title {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.asteria-button-info {
			margin-left: auto;
			@media (max-width: 767.98px) {
				display: block;
			}
		}
	}
	.asteria-input-type {
		left: 12px;
		top: 55px;
		height: 12px;
		width: 12px;
		position: absolute;
		.asteria-icon-wrapper {
			svg {
				fill: #72605f !important;
			}
		}
	}
	.asteria-alert-low {
		border-top: 2px solid var(--alert-error-border-color) !important;
		background: var(--alert-error-bg-color) !important;
		margin: 0 !important;
	}
	.asteria-button-info svg {
		fill: #ee7024 !important;
	}
	.asteria-adjustable-total {
		padding-right: 50px;
		margin-left: 0 !important;
	}
`;

AdjustTotalInput.Styler = {
	children: [
		{
			component: LabelV2,
			base: [Getter('label')],
		},
		{
			component: InputV2,
			base: [Getter('input')],
		},
		{
			component: Dropdown,
			base: [Getter('dropdown')],
		},
		{
			component: FormGroupV2,
			base: [Getter('group')],
		},
	],
};

const AdjustableCategory = styled(
	({
		className,
		items = [],
		category,
		remove = () => {},
		onUpdate = () => {},
	}) => {
		const { tag: { name: tagName = false } = {} } = category;
		const transactions = useMemo(
			() =>
				items.filter(
					({ type, status, meta: { tags = [] } = {} }) =>
						status === 'FORECAST' &&
						tags.find(({ name }) => tagName === name),
				),
			[items, tagName],
		);

		return (
			<div className={classNames(className)}>
				<div className="asteria-form-row">
					<FormGroupV2 className="asteria-group-customer">
						<LabelV2>Kategori</LabelV2>
						<Dropdown
							iconClosed="triangleDown"
							iconOpen="triangleUp"
							options={CATEGORIES}
							placeholder="Välj kategori"
							itemIcon="check"
							showSelected
							selected={CATEGORIES.filter(
								c => c.tag === category?.tag?.name,
							)}
							onChange={c =>
								onUpdate({
									...category,
									tag: {
										name: c.tag,
									},
									category: {
										name: c.category,
									},
								})
							}
							search={false}
							type="default"
						/>
					</FormGroupV2>
					<FormGroupV2>
						<LabelV2>Belopp</LabelV2>
						<InputV2
							className="asteria-adjustable-total"
							elementType="input"
							type="number"
							placeholder=""
							value={Math.round(category.total)}
							step={10000}
							onChange={e => {
								onUpdate(
									category,
									e.target.value
										? parseFloat(e.target.value)
										: 0,
								);
							}}
						/>
					</FormGroupV2>
					<Button
						type="link"
						icon="circleMinusFilled"
						text="Ta bort rad"
						className="asteria-button-remove"
						onClick={remove}
					/>
				</div>
				<div
					className={classNames(
						'asteria-adjustable-group-transactions',
					)}
				>
					{transactions.length > 1
						? transactions.map(
								({
									description,
									sums: { original: { total } = {} } = {},
								}) => (
									<div className="asteria-form-row">
										<FormGroupV2 className="asteria-group-customer">
											<LabelV2>Transaktion</LabelV2>
											<InputV2
												elementType="input"
												placeholder="Beskrivning"
												value={
													description.includes('$')
														? ''
														: description
												}
											/>
										</FormGroupV2>
										<FormGroupV2>
											<LabelV2>Belopp</LabelV2>
											<InputV2
												className="asteria-adjustable-total"
												elementType="input"
												type="number"
												placeholder=""
												value={Math.round(total)}
												step={10000}
											/>
										</FormGroupV2>
										<Button
											type="link"
											icon="circleMinusFilled"
											text="Ta bort rad"
											className="asteria-button-remove"
											onClick={remove}
										/>
									</div>
								),
						  )
						: null}
				</div>
			</div>
		);
	},
)`
	display: flex;
	flex-direction: column;

	.asteria-form-row {
		align-self: stretch;
		display: flex;
	}

	.asteria-adjustable-group-transactions {
		padding-left: 20px;
		align-self: stretch;
	}

	.asteria-group-customer {
		max-width: 220px;
		@media (max-width: 767.98px) {
			max-width: 100%;
		}
	}
`;

AdjustableCategory.Styler = {
	children: [
		{
			component: Button,
			base: [Getter('button')],
		},
		{
			component: LabelV2,
			base: [Getter('label')],
		},
		{
			component: InputV2,
			base: [Getter('input')],
		},
		{
			component: Dropdown,
			base: [Getter('dropdown')],
		},
		{
			component: FormGroupV2,
			base: [Getter('group')],
		},
	],
};

const ControllerWrapper = styled.div`
	display: flex;
`;
ControllerWrapper.Styler = {
	children: [
		{
			component: Button,
			base: [Getter('button')],
		},
	],
};

const AdjustableCategories = styled(
	({ className, items, categories, setCategories, onUpdate }) => {
		const [showAdvanced, setShowAdvanced] = useState(false);
		return (
			<div className={classNames(className)} style={{ display: 'none' }}>
				{categories.map((category, index) => (
					<AdjustableCategory
						key={category?.tag?.id || `new_${index}`}
						category={category}
						items={items}
						onUpdate={(cat, newTotal = cat.total) => {
							setCategories(
								categories.map(c => {
									if (c === category) {
										return {
											...cat,
											total: newTotal,
										};
									}

									return c;
								}),
							);
						}}
						remove={() =>
							setCategories([
								...categories.filter(c => c !== category),
							])
						}
					/>
				))}
				{/* <Button
					size="medium"
					type="link"
					icon="circlePlusFilled"
					className="asteria-button-add-categories-row"
					onClick={() =>
						setCategories([
							...categories,
							{
								total: 0,
								status: 'FORECAST',
								risk: 1,
								tag: {
									name: '',
								},
							},
						])
					}
				>
					Lägg till kategori
				</Button> */}
				<FeatureFlag feature="AdvancedAdjustable">
					<Button
						size="small"
						type="link"
						icon="calendar"
						onClick={() => setShowAdvanced(true)}
					>
						Gör flera prognoser samtidigt
					</Button>

					{showAdvanced ? (
						<AdvancedAdjustable
							onClose={() => setShowAdvanced(false)}
						/>
					) : null}
				</FeatureFlag>
			</div>
		);
	},
)``;

AdjustableCategories.Styler = {
	children: [
		{
			component: Button,
			base: [Getter('button')],
		},
		{
			component: AdjustableCategory,
			base: [Getter('category')],
		},
	],
};

const Adjust = styled(
	({
		className,
		targetType,
		active,
		startDate: listStartDate,
		endDate: listEndDate,
		inline = false,
		onClose = () => {},
	}) => {
		const { dispatch, lookup } = useContext(DatalayerContext);
		const originalTotal = useRef(0);
		const [items = []] = useStore('store-list', ({ items: list }) => list);
		const [actions = []] = useStore(
			'store-list',
			({ actions: list }) => list,
		);
		const [actionIgnore = []] = useStore(
			'store-list',
			({ ignore: list }) => list,
		);

		const [listItems = []] = useStore('store-list', ({ items: list }) =>
			list.filter(({ status }) => status === 'FORECAST'),
		);

		const [lowWarning, setLowWarning] = useState(false);
		const [changeWarning, setChangeWarning] = useState(false);
		const [requiredChange, setRequiredChange] = useState(0);
		const [total, setTotal] = useState(0);
		const [unpaid, setUnpaid] = useState(0);
		const [categories, setCategories] = useState([]);

		useEffect(() => {
			const apiService = lookup('service-api');
			apiService
				.query(
					QUERY_CASHFLOW,
					{
						endDate: listEndDate,
						startDate: listStartDate,
					},
					{ reqAuth: true },
				)
				.subscribe(resp => {
					const entries = resp?.data?.cashflow || [];

					if (targetType !== 'ACCOUNT') {
						const typeEntries = entries.filter(
							({ type }) => type === targetType,
						);

						setUnpaid(
							Math.round(
								typeEntries
									.filter(
										({ status }) =>
											status !== 'FORECAST' &&
											status !== 'OVERDUE',
									)
									.reduce(
										(
											acc,
											{
												sums: {
													original: {
														total: entryTotal,
													},
												},
											},
										) => acc + entryTotal,
										0,
									),
							),
						);

						originalTotal.current = Math.round(
							typeEntries
								.filter(({ status }) => status !== 'OVERDUE')
								.reduce(
									(
										acc,
										{
											sums: {
												original: { total: entryTotal },
											},
										},
									) => acc + entryTotal,
									0,
								),
						);
						setTotal(originalTotal.current);
					} else {
						setUnpaid(
							Math.round(
								entries
									.filter(
										({ status }) =>
											status !== 'FORECAST' &&
											status !== 'OVERDUE',
									)
									.reduce(
										(
											acc,
											{
												sums: {
													original: {
														total: entryTotal,
													},
												},
												type,
											},
										) => acc + entryTotal,
										0,
									),
							),
						);

						originalTotal.current = Math.round(
							entries
								.filter(
									({ type, status }) =>
										type === 'ACCOUNT' &&
										status === 'FORECAST',
								)
								.reduce(
									(
										acc,
										{
											sums: {
												original: { total: entryTotal },
											},
										},
									) => acc + entryTotal,
									0,
								),
						);
						setTotal(originalTotal.current);
					}
				});
		}, [active, listEndDate, listStartDate, lookup, targetType]);

		useEffect(() => {
			if (active !== false) {
				dispatch(setItemAction([]));
				dispatch(setIgnore([]));
			}

			setChangeWarning(false);
			setLowWarning(false);
		}, [active, dispatch]);

		useEffect(() => {
			const apiService = lookup('service-api');
			apiService
				.query(
					QUERY_CASHFLOW,
					{
						endDate: listEndDate,
						startDate: listStartDate,
					},
					{ reqAuth: true },
				)
				.subscribe(resp => {
					const entries = resp?.data?.cashflow || [];

					const typeEntries = entries.filter(
						({ type }) => type === targetType,
					);

					if (targetType !== 'ACCOUNT') {
						originalTotal.current = Math.round(
							typeEntries
								.filter(({ status }) => status !== 'OVERDUE')
								.reduce(
									(
										acc,
										{
											sums: {
												original: { total: entryTotal },
											},
										},
									) => acc + entryTotal,
									0,
								),
						);
					} else {
						originalTotal.current = Math.round(
							entries
								.filter(
									({ type, status }) =>
										type === 'ACCOUNT' &&
										status === 'FORECAST',
								)
								.reduce(
									(
										acc,
										{
											sums: {
												original: { total: entryTotal },
											},
										},
									) => acc + entryTotal,
									0,
								),
						);
					}
				});
		}, [
			lookup,
			listItems,
			actionIgnore,
			targetType,
			active,
			listEndDate,
			listStartDate,
		]);

		const save = (currentActions = actions) => {
			if (active === false) {
				return;
			}
			dispatch(
				saveTransactions(
					currentActions
						.filter(({ operation }) => operation !== 'REMOVE')
						.map(action => {
							if (action.operation === 'CREATE') {
								return {
									...action.transaction,
									id: undefined,
								};
							}

							return action.transaction;
						}),
				),
			);

			dispatch(
				removeTransactions(
					currentActions
						.filter(({ operation }) => operation === 'REMOVE')
						.map(({ transaction }) => transaction),
				),
			);

			dispatch(setItemAction([]));
			dispatch(setIgnore([]));
			setChangeWarning(false);
			setLowWarning(false);
			dispatch(setAdjustOpen(false));
			onClose();
		};

		const updateForecast = () => {
			if (active === false) {
				return;
			}
			if (targetType !== 'ACCOUNT') {
				if (
					(targetType === 'DEPOSIT' && total < unpaid) ||
					(targetType === 'WITHDRAW' && total > unpaid)
				) {
					setLowWarning(true);
					return;
				}
			}

			setLowWarning(false);
			setRequiredChange(0);
			const apiService = lookup('service-api');
			apiService
				.query(
					`
				query Suggestions($target: Float!, $startDate: Date!, $endDate: Date!, $type: String!, $ignore: [ID]) {
					suggestModifications(target: $target, startDate: $startDate, endDate: $endDate, type: $type, ignore: $ignore) {
						operation
						transaction {
							id
							type
							status
							paymentDate
							sums {
								original {
									total
									currency
								}
							}
							meta {
								description
								categories {
									id
									name
								}
								tags {
									id
									_id
									name
									category {
										id
										_id
										name
									}
								}
							}
							description
							message
							oracle {
								risk
								max {
									original {
										total
										currency
									}
								}
								min {
									original {
										total
										currency
									}
								}
								forecast {
									risk
									max {
										display {
											total
											currency
										}
									}
									min {
										display {
											total
											currency
										}
									}
								}
								currency {
									risk
									max {
										display {
											total
											currency
										}
									}
									min {
										display {
											total
											currency
										}
									}
								}
							}
							manual

							links {
								id
								type
								invoiceDetails: details {
									... on Invoice {
										dates {
											invoiceSent
											invoiceDue
											invoicePaid
										}
										client {
											id
											name
										}
									}
								}
								accountDetails: details {
									... on BankAccountTransaction {
										identifiers {
											accountNumber
										}
										dates {
											booked
										}
										sums {
											account {
												total
												currency
												rate
											}
										}
									}
								}
							}
						}
						fields
					}
					}
				`,
					{
						endDate: listEndDate,
						startDate: listStartDate,
						target:
							targetType !== 'ACCOUNT' ? Math.abs(total) : total,
						type: targetType,
						ignore: actionIgnore,
					},
					{ reqAuth: true },
				)
				.subscribe(resp => {
					if (
						targetType === 'ACCOUNT' ||
						(targetType === 'DEPOSIT' &&
							originalTotal.current > total) ||
						(targetType === 'WITHDRAW' &&
							originalTotal.current < total)
					) {
						setChangeWarning(true);
						dispatch(
							setItemAction(
								resp?.data?.suggestModifications || [],
							),
						);
					} else {
						const operations =
							resp?.data?.suggestModifications || [];
						setChangeWarning(false);
						if (operations.length > 0) {
							save(operations);
						}
					}
				});
		};

		useEffect(() => {
			if (actionIgnore.length > 0) {
				updateForecast();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [actionIgnore, active]);

		const updateTotal = t => {
			setChangeWarning(false);
			setLowWarning(false);
			setTotal(t);

			if (actions.length > 0) {
				dispatch(setItemAction([]));
				dispatch(setIgnore([]));
			}
		};

		const cancel = () => {
			dispatch(setItemAction([]));
			dispatch(setIgnore([]));
			setChangeWarning(false);
			setLowWarning(false);
			setTotal(originalTotal.current);
		};

		useEffect(() => {
			if (active === false) {
				dispatch(setItemAction([]));
				dispatch(setIgnore([]));
				setChangeWarning(false);
				setLowWarning(false);
				setTotal(originalTotal.current);
			}
		}, [active, dispatch]);

		if (inline) {
			return (
				<AdjustTotalInput
					value={total}
					min={unpaid || 0}
					onChange={updateTotal}
					types={targetType}
					lowWarning={lowWarning}
					unpaid={unpaid}
				/>
			);
		}

		return (
			<div className={classNames(className)}>
				<AdjustableWrapper>
					<AdjustTotalInput
						value={total}
						min={unpaid || 0}
						onChange={updateTotal}
						types={targetType}
						lowWarning={lowWarning}
						unpaid={unpaid}
					/>
					{changeWarning && !lowWarning ? (
						<Alert type="warning">
							<Translation
								translationKey={[
									'adjustable.total.change.warning',
									`adjustable.total.${targetType}.change.warning`,
								]}
								defaultText={`Du har valt att ändra din prognos för {{startDate | date : 'MMMM' | capitalize}} från <strong>{{originalTotal | number}} {{currency}}</strong> till <strong>{{total | number}} {{currency}}</strong></br></br>För att prognosen ska justeras korrekt har vi nedan i listan tagit fram förslag på ändringar som är <strong>{{change | number}}.</strong><br/>Genom att klicka på Spara kommer prognosen att justeras.`}
								data={{
									startDate: listStartDate,
									endDate: listEndDate,
									originalTotal: originalTotal.current,
									total,
									change: Math.round(
										total - originalTotal.current,
									),
									currency: 'SEK',
								}}
							/>
							<div className="asteria-action-wrapper">
								<Button
									type="link"
									size="medium"
									text={TranslationService.get(
										'button.abort',
									)}
									className="asteria-cancel"
									onClick={cancel}
								/>
								<Button
									type="secondary"
									size="medium"
									text={TranslationService.get('button.save')}
									className="asteria-save"
									disabled={requiredChange !== 0}
									onClick={() => save()}
								/>
							</div>
						</Alert>
					) : null}
					{/* <AdjustableCategories
					categories={categories}
					setCategories={setCategories}
					items={items}
				/> */}
				</AdjustableWrapper>
				{!changeWarning ? (
					<ControllerWrapper className="asteria-adjustable-controllers">
						<Button
							type="default"
							size="medium"
							text={TranslationService.get('button.abort')}
							className="asteria-cancel"
							onClick={() => {
								dispatch(setAdjustOpen(false));
								onClose();
							}}
						/>
						<Button
							type="primary"
							size="medium"
							text={TranslationService.get('button.save')}
							disabled={originalTotal.current === total}
							className="asteria-save"
							onClick={() => {
								updateForecast();
								// dispatch(setAdjustOpen(false));
							}}
						/>
					</ControllerWrapper>
				) : null}
			</div>
		);
	},
)`
	.asteria-form-group-customer {
		max-width: 222px;
	}

	${Alert} {
		background-color: #f9f8f6;
		border-top: 2px solid rgba(238, 112, 35, 1);
		margin: 0 -20px -20px;

		.asteria-body {
			padding: 16px;
		}

		.asteria-action-wrapper {
			display: flex;
			justify-content: flex-end;

			${Button} {
				width: 100px;
				margin-right: 8px;
				&::last-child {
					margin-right: 0;
				}
			}
		}
	}
`;

const AdjustTotal = styled(
	React.memo(({ className, inline = false, startDate, endDate, onClose }) => {
		const { dispatch, lookup } = useContext(DatalayerContext);
		const [actionsWarning, setActionsWarning] = useState(false);
		const [listTypes = []] = useStore('store-list', ({ types }) => types);
		const [actions = []] = useStore(
			'store-list',
			({ actions: list }) => list,
		);
		const [currentType, setCurrentType] = useState(
			listTypes.length > 1 ? 'ACCOUNT' : listTypes[0],
		);

		useEffect(() => {
			setCurrentType(listTypes.length > 1 ? 'ACCOUNT' : listTypes[0]);
		}, [listTypes]);

		const discard = () => {
			setCurrentType(actionsWarning);
			setActionsWarning(false);
			dispatch(setItemAction([]));
			dispatch(setIgnore([]));
		};

		return (
			<div
				className={classNames(
					className,
					'asteria-adjust-total',
					listTypes.length === 1
						? `asteria-adjust-total-${listTypes[0].toLowerCase()}`
						: `asteria-adjust-total-account`,
				)}
			>
				<Adjust
					targetType={listTypes.length > 1 ? 'ACCOUNT' : listTypes[0]}
					startDate={startDate}
					endDate={endDate}
					onClose={onClose}
					inline={inline}
					active
				/>
			</div>
		);
	}),
)`
	&.asteria-adjust-total-account {
		${TabsNavigation} {
			display: none !important;
		}
	}

	&.asteria-adjust-total-deposit {
		${TabsNavigation} {
			display: none !important;
		}
	}

	&.asteria-adjust-total-withdraw {
		${TabsNavigation} {
			display: none !important;
		}
	}
`;

AdjustTotal.Styler = {
	children: [
		{
			component: AdjustableWrapper,
			base: [Getter('wrapper')],
			children: [
				{
					component: AdjustTotalInput,
					base: [Getter('total')],
				},
				{
					component: AdjustableCategories,
					base: [Getter('categories')],
				},
			],
		},
		{
			component: ControllerWrapper,
			base: [Getter('controller')],
		},
	],
};
AdjustTotal.displayName = 'Adjust';

export default AdjustTotal;
