let lastClickTarget;
let lastClickTimestamp;

/**
 * Check if we met this event earlier
 * @param e MouseEvent
 * @return {boolean}
 */
const isTheSameEvent = e => {
	if (
		lastClickTarget === e.currentTarget &&
		lastClickTimestamp === e.timestamp
	) {
		return true;
	}

	lastClickTarget = e.currentTarget;
	lastClickTimestamp = e.timeStamp;

	return false;
};

/**
 * Plain function to catch events on place manually
 * @param event MouseEvent object
 * @param callBack - possible callback function
 */
export default (event, callBack) => {
	// Prevent from processing the same event multiple times
	if (!isTheSameEvent(event)) {
		// Do some stuff with this
		const targetSelector = `${event.currentTarget.tagName.toLowerCase()}${
			typeof event.currentTarget.className === 'string' &&
			event.currentTarget.className.length
				? `.${event.currentTarget.className.split(' ').join('.')}`
				: ''
		}`;
		const { timeStamp } = event;

		let { CustomEvent } = window;
		if (typeof CustomEvent !== 'function') {
			CustomEvent = (e, params) => {
				// eslint-disable-next-line no-param-reassign
				params = params || {
					bubbles: false,
					cancelable: false,
					detail: null,
				};
				const evt = document.createEvent('CustomEvent');
				evt.initCustomEvent(
					e,
					params.bubbles,
					params.cancelable,
					params.detail,
				);
				return evt;
			};
		}

		if (event.target) {
			// create and dispatch the event
			const activityEvent = new CustomEvent('activity', {
				bubbles: true,
				detail: {
					targetSelector,
					timeStamp,
				},
			});
			event.target.dispatchEvent(activityEvent);
		}
	}

	if (callBack) {
		callBack(event);
	}
};
