import React from 'react';
import Button from '@asteria/component-core/button';
import styled from 'styled-components';
import classNames from 'classnames';
import { Getter, compileStyles } from '@asteria/utils';

const InfoBoxToggler = styled(({ className, open, toggle = () => {} }) => (
	<Button
		type="link"
		size="large"
		className={classNames(className, 'asteria-button-info')}
		icon={open ? 'circleClose' : 'circleHelp'}
		onClick={() => toggle(!open)}
	/>
))`
	${({ theme }) => compileStyles(InfoBoxToggler, theme)}
`;

InfoBoxToggler.Styler = {
	...Button.Styler,
	base: [Getter('infobox.toggler')],
};

export default InfoBoxToggler;
