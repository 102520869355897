import React, { useCallback, useMemo, useContext, useState } from 'react';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import Alert from '@asteria/component-alert';
import { ModalBody, ModalFooter } from '@asteria/component-modal';
import Button from '@asteria/component-button/button';
import { FeatureFlag, useFeature } from '@asteria/component-featureflag';
import classNames from 'classnames';
import Title from '@asteria/component-typography/title';
import Text from '@asteria/component-typography/text';
import Icon from '@asteria/component-icon/v2';
import {
	/* Dropdown, */
	FormGroupV2,
	CheckboxGroupV2,
	RadioGroupV2,
} from '@asteria/component-form';
// import { useUserSettings } from '@asteria/services-dashboard';
import { Translation, TranslationService } from '@asteria/services-language';
import styled from 'styled-components';
import { compileStyles, Getter, useStore } from '@asteria/utils';
import {
	setUserSettings,
	saveUser,
} from '@asteria/services-datalayer/services/auth/actions';
import { setLayout } from '@asteria/services-datalayer/services/appstate/actions';
/* import { selectLanguage } from '@asteria/services-datalayer/services/language/actions'; */

const CHOSEN_FIRST = [0];
const CHOSEN_NONE = [];

/* const LANGUAGES = [
	{
		value: 'sv',
		label: 'Swedish',
	},
	{
		value: 'en',
		label: 'English',
	},
	{
		value: 'br',
		label: 'Portuguese',
	},
]; */

const RADIO_ELEMENT_STYLES = {
	text: {
		color: '#262626',
		fontSize: '18px',
		lineHeight: '21px',
	},
};
const SHOW_BALANCE_NUMBERS_STYLE = {
	marginLeft: '38px',
};
const SHOW_BALANCE_NUMBERS_RADIO_ELEMENT_STYLES = {
	text: {
		...RADIO_ELEMENT_STYLES.text,
		fontSize: '16px',
	},
};
const SettingsView = styled(({ className, onClose }) => {
	const { dispatch } = useContext(DatalayerContext);
	/* 	const [language, setLanguage] = useState(
		localStorage.asteriaLanguage || 'sv',
	); */
	const hasGraphTabs = useFeature({ key: 'graph-tabs' });
	const hasGraphCombined = useFeature({ key: 'graph-combined' });
	/* 	const hasLanguageSwitch = useFeature({ key: 'language-switch' }); */

	const [restoreLayout, setRestoreLayout] = useState(false);
	const [currentUser = {}] = useStore('store-auth', ({ user }) => user);

	const { settings: userSettings = {} } = currentUser;

	const [restoreSettings, setRestore] = useState(false);

	const cancel = useCallback(() => {
		if (restoreSettings !== false) {
			dispatch(setUserSettings(restoreSettings));
			dispatch(setLayout(restoreLayout));
		}

		/* 		if (localStorage.asteriaLanguage !== language && hasLanguageSwitch) {
			dispatch(selectLanguage(localStorage.asteriaLanguage));
		} */

		onClose();
	}, [
		dispatch,
		/* 		hasLanguageSwitch,
		language, */
		onClose,
		restoreLayout,
		restoreSettings,
	]);

	/*
	useEffect(
		() => () => {
			if (restoreSettings !== false) {
				dispatch(setUserSettings(restoreSettings));
				dispatch(setLayout(restoreLayout));
			}
		},
		[dispatch, restoreLayout, restoreSettings],
	);
	*/

	const updateSettings = useCallback(
		settings => {
			if (restoreSettings === false) {
				setRestore(userSettings);
			}

			if (!restoreLayout) {
				setRestoreLayout(userSettings?.layout);
			}

			if (settings?.layout) {
				dispatch(setLayout(settings?.layout));
			}

			dispatch(setUserSettings(settings));
		},
		[dispatch, restoreLayout, restoreSettings, userSettings],
	);

	const {
		showBalance,
		showIn,
		showOut,
		showBalanceNumbers,
		layout = {},
	} = userSettings;

	const showSomething = showBalance || showIn || showOut;

	const saveSettings = useCallback(() => {
		if (!showSomething) {
			return;
		}

		/* 		if (hasLanguageSwitch) {
			localStorage.asteriaLanguage = language;
		}
 */
		dispatch(saveUser(currentUser));

		setRestore(false);
		onClose();
	}, [
		currentUser,
		dispatch,
		/* 		hasLanguageSwitch,
		language, */
		onClose,
		showSomething,
	]);

	const showBalanceItems = useMemo(
		() => [
			TranslationService.get(
				'settings.showBalance.title',
				'Visa kontobalans',
			),
		],
		[],
	);
	const showFullscreenItems = useMemo(
		() => [
			TranslationService.get(
				'appearance.showFullscreen.title',
				'Visa kontobalans',
			),
		],
		[],
	);
	const showBalanceNumbersItems = useMemo(
		() => [
			TranslationService.get(
				'settings.showBalanceNumbers.title',
				'Visa siffror i kontobalansgraf',
			),
		],
		[],
	);
	const showBalanceFilterItems = useMemo(
		() => [
			TranslationService.get(
				'settings.showBalance.search.title',
				'Visa kontobalans vid filtrering',
			),
		],
		[],
	);
	const showInItems = useMemo(
		() => [
			TranslationService.get(
				'settings.showIn.title',
				'Visa inbetalningar',
			),
		],
		[],
	);
	const showOutItems = useMemo(
		() => [
			TranslationService.get(
				'settings.showOut.title',
				'Visa utbetalningar',
			),
		],
		[],
	);
	const showRiskInGraph = useMemo(
		() => [
			TranslationService.get(
				'settings.showriskingraph.title',
				'Visa valutarisk i grafen',
			),
		],
		[],
	);

	if (userSettings === null) {
		return null;
	}

	const Layouts = [
		TranslationService.get(
			'settings.graph.layout.seprated.vertical.title',
			'Separera kontobalans & in-/utbetalningar vertikalt',
		),
		// TranslationService.get(
		// 	'settings.graph.layout.seprated.horizontal.title',
		// 	'Separera kontobalans & in-/utbetalningar horizontalt',
		// ),
	];

	if (hasGraphTabs) {
		Layouts.push(
			TranslationService.get(
				'settings.graph.layout.tabs.title',
				'Dela upp kontobalans & in-/utbetalningar i flikar',
			),
		);
	}

	if (hasGraphCombined) {
		Layouts.push(
			TranslationService.get(
				'settings.graph.layout.combined.title',
				'Kombinera kontobalans med in-/utbetalningar',
			),
		);
	}

	let selectedLayout = 0;
	if (layout?.graph?.layout === 'tabs') {
		selectedLayout = 1;
	}

	if (layout?.graph?.layout === 'combined') {
		selectedLayout = Layouts.length - 1;
	}

	return (
		<div
			className={classNames(
				className,
				`asteria-graph-layout-${layout?.graph?.layout}`,
			)}
		>
			<ModalBody className="modal-body">
				<FeatureFlag feature="widget-appearance">
					<Title
						size="title2"
						className="asteria-title-settings-layout"
					>
						{TranslationService.get(
							'settings.widget.appearance.title',
							'Utseende',
						)}
					</Title>
					<FormGroupV2>
						<CheckboxGroupV2
							className="asteria-checkbox-group-wrapper-widget-fullscreen"
							items={showFullscreenItems}
							onChange={() =>
								updateSettings({
									...userSettings,
									layout: {
										...layout,
										showFullscreen: !layout?.showFullscreen,
									},
								})
							}
							selected={
								layout?.showFullscreen
									? CHOSEN_FIRST
									: CHOSEN_NONE
							}
							elementStyles={RADIO_ELEMENT_STYLES}
						/>
					</FormGroupV2>
				</FeatureFlag>
				<FeatureFlag feature="layout-settings">
					<Title
						size="title2"
						className="asteria-title-settings-layout"
					>
						{TranslationService.get(
							'settings.layout.title',
							'Layout',
						)}
					</Title>
					<div className="asteria-layout-options">
						<div
							className={classNames('asteria-layout-box', {
								'asteria-state-active':
									layout?.positioning === 'flat',
							})}
							onClick={() => {
								updateSettings({
									...userSettings,
									layout: {
										...layout,
										positioning: 'flat',
									},
								});
							}}
							onKeyPress={() => {}}
							tabIndex="-1"
							role="button"
						>
							<FormGroupV2>
								<RadioGroupV2
									className="asteria-checkbox-group-wrapper-transactions-small"
									items={['Standard']}
									selected={
										layout?.positioning === 'flat'
											? [0]
											: []
									}
								/>
								<Icon
									asset="layoutStandard"
									className="asteria-icon-layout"
								/>
								<div className="asteria-layout-box-content">
									<Title size="title-3">
										{TranslationService.get(
											'settings.layout.standard.title',
											'Standard',
										)}
									</Title>
									<Text size="small">
										{TranslationService.get(
											'settings.layout.standard.text',
											'Visa grafer & lista oberoende av varandra',
										)}
									</Text>
								</div>
							</FormGroupV2>
						</div>

						<FeatureFlag feature="workareas-settings">
							<div
								className={classNames('asteria-layout-box', {
									'asteria-state-active':
										layout?.positioning === 'fixed',
								})}
								onClick={() => {
									updateSettings({
										...userSettings,
										layout: {
											...layout,
											graph: {
												...layout?.graph,
												layout: 'combined',
												barLayout: 'stacked',
											},
											positioning: 'fixed',
										},
									});
								}}
								onKeyPress={() => {}}
								tabIndex="-1"
								role="button"
							>
								<FormGroupV2>
									<RadioGroupV2
										className="asteria-checkbox-group-wrapper-transactions-small"
										items={['Arbetsyta']}
										selected={
											layout?.positioning === 'fixed'
												? [0]
												: []
										}
									/>
									<Icon
										asset="layoutWorkarea"
										className="asteria-icon-layout"
									/>
									<div className="asteria-layout-box-content">
										<Title size="title-3">
											{TranslationService.get(
												'settings.layout.workarea.title',
												'Arbetsyta',
											)}
										</Title>
										<Text size="small">
											{TranslationService.get(
												'settings.layout.workarea.text',
												'Visa grafer & lista samtidigt för en mer överskådlig översikt',
											)}
										</Text>
									</div>
								</FormGroupV2>
							</div>
						</FeatureFlag>

						<FeatureFlag feature="divided-settings">
							<div
								className={classNames('asteria-layout-box', {
									'asteria-state-active':
										layout?.positioning === 'divided',
								})}
								onClick={() => {
									updateSettings({
										...userSettings,
										layout: {
											...layout,
											positioning: 'divided',
										},
									});
								}}
								onKeyPress={() => {}}
								tabIndex="-1"
								role="button"
							>
								<FormGroupV2>
									<RadioGroupV2
										className="asteria-checkbox-group-wrapper-transactions-small"
										items={['Uppdelat']}
										selected={
											layout?.positioning === 'divided'
												? [0]
												: []
										}
									/>
									<Icon
										asset="layoutDivided"
										className="asteria-icon-layout"
									/>
									<div className="asteria-layout-box-content">
										<Title size="title-3">
											{TranslationService.get(
												'settings.layout.divided.title',
												'Uppdelat',
											)}
										</Title>
										<Text size="small">
											{TranslationService.get(
												'settings.layout.divided.text',
												'Dela grafer & lista på bredden',
											)}
										</Text>
									</div>
								</FormGroupV2>
							</div>
						</FeatureFlag>
					</div>
				</FeatureFlag>
				<FeatureFlag feature="layout-graph">
					<Title size="title2" style={{ marginTop: '16px' }}>
						<Translation
							translationKey="settings.subtitle2"
							defaultText="Graf layout"
						/>
					</Title>
					{/* feature flag as option? */}
					<RadioGroupV2
						items={Layouts}
						selected={[selectedLayout]}
						onChange={a => {
							let active = 0;
							if (
								Layouts[a] ===
								TranslationService.get(
									'settings.graph.layout.tabs.title',
									'Dela upp kontobalans & in-/utbetalningar i flikar',
								)
							) {
								active = 1;
							}

							if (
								Layouts[a] ===
								TranslationService.get(
									'settings.graph.layout.combined.title',
									'Kombinera kontobalans med in-/utbetalningar',
								)
							) {
								active = 2;
							}

							let extra = {};
							if (active === 1) {
								extra = {
									showIn: true,
									showOut: true,
									showBalanceNumbers: true,
									showBalance: true,
								};
							}

							updateSettings({
								...userSettings,
								...extra,
								layout: {
									...layout,
									graph: {
										...(layout?.graph || {}),
										barLayout:
											active === 2
												? 'stacked'
												: 'grouped',
										layout: ['flat', 'tabs', 'combined'][
											active
										],
									} /*  */,
								},
							});
						}}
						styler
						className={`asteria-stacked asteria-layout-${layout?.positioning ||
							'flat'}`}
					/>
				</FeatureFlag>
				<FeatureFlag feature="graph-functions">
					<Title
						size="title2"
						style={{ marginTop: '16px' }}
						className="asteria-settings-graph"
					>
						<Translation
							translationKey="settings.subtitle"
							defaultText="Graf och funktioner"
						/>
					</Title>
					{!showSomething && (
						<Alert
							icon
							description={false}
							type="error"
							headingContent={TranslationService.get(
								'bankList.error.title',
								'Välj minst ett alternativ',
							)}
						/>
					)}
					<FormGroupV2>
						<CheckboxGroupV2
							className="asteria-checkbox-group-wrapper-show-balance"
							items={showBalanceItems}
							onChange={() =>
								updateSettings({
									...userSettings,
									showBalance: !showBalance,
								})
							}
							selected={showBalance ? CHOSEN_FIRST : CHOSEN_NONE}
							elementStyles={RADIO_ELEMENT_STYLES}
						/>
					</FormGroupV2>
					{showBalance && (
						<FormGroupV2 style={SHOW_BALANCE_NUMBERS_STYLE}>
							<CheckboxGroupV2
								className="asteria-checkbox-group-wrapper-show-balance-numbers"
								items={showBalanceNumbersItems}
								onChange={() =>
									updateSettings({
										...userSettings,
										showBalanceNumbers: !showBalanceNumbers,
									})
								}
								selected={
									showBalanceNumbers
										? CHOSEN_FIRST
										: CHOSEN_NONE
								}
								elementStyles={
									SHOW_BALANCE_NUMBERS_RADIO_ELEMENT_STYLES
								}
							/>
						</FormGroupV2>
					)}
					{showBalance && (
						<FeatureFlag feature="hide-account-on-search">
							<FormGroupV2 style={SHOW_BALANCE_NUMBERS_STYLE}>
								<CheckboxGroupV2
									className="asteria-checkbox-group-wrapper-show-balance"
									items={showBalanceFilterItems}
									onChange={() =>
										updateSettings({
											...userSettings,
											layout: {
												...layout,
												graph: {
													...(layout?.graph || {}),
													showAccountOnSearch: !layout
														?.graph
														.showAccountOnSearch,
												} /*  */,
											},
										})
									}
									selected={
										layout?.graph?.showAccountOnSearch ||
										layout?.graph?.showAccountOnSearch ===
											null ||
										layout?.graph?.showAccountOnSearch ===
											undefined
											? CHOSEN_FIRST
											: CHOSEN_NONE
									}
									elementStyles={RADIO_ELEMENT_STYLES}
								/>
							</FormGroupV2>
						</FeatureFlag>
					)}
					<FormGroupV2>
						<CheckboxGroupV2
							className="asteria-checkbox-group-wrapper-show-deposit"
							items={showInItems}
							onChange={() =>
								updateSettings({
									...userSettings,
									showIn: !showIn,
								})
							}
							selected={showIn ? CHOSEN_FIRST : CHOSEN_NONE}
							elementStyles={RADIO_ELEMENT_STYLES}
						/>
					</FormGroupV2>
					<FormGroupV2>
						<CheckboxGroupV2
							className="asteria-checkbox-group-wrapper-show-withdraw"
							items={showOutItems}
							onChange={() =>
								updateSettings({
									...userSettings,
									showOut: !showOut,
								})
							}
							selected={showOut ? CHOSEN_FIRST : CHOSEN_NONE}
							elementStyles={RADIO_ELEMENT_STYLES}
						/>
					</FormGroupV2>
				</FeatureFlag>
				<FormGroupV2>
					<CheckboxGroupV2
						className="asteria-checkbox-group-wrapper-show-risk"
						items={showRiskInGraph}
						onChange={() =>
							updateSettings({
								...userSettings,
								layout: {
									...layout,
									graph: {
										...(layout?.graph || {}),
										showRisk: !(
											layout?.graph?.showRisk ||
											layout?.graph?.showRisk === null ||
											layout?.graph?.showRisk ===
												undefined
										),
									},
								},
							})
						}
						selected={
							layout?.graph?.showRisk ||
							layout?.graph?.showRisk === null ||
							layout?.graph?.showRisk === undefined
								? CHOSEN_FIRST
								: CHOSEN_NONE
						}
						elementStyles={RADIO_ELEMENT_STYLES}
					/>
				</FormGroupV2>
				{/* <FeatureFlag feature="language-switch">
					<Title
						size="title2"
						style={{ marginTop: '16px', marginBottom: '16px' }}
					>
						{TranslationService.get(
							'settings.language.section.title',
							'Språk',
						)}
					</Title>
					<div
						style={{ padding: '16px', backgroundColor: '#EDEDED' }}
						className="asteria-language-switch"
					>
						<FormGroupV2>
							<Dropdown
								iconClosed="triangleDown"
								iconOpen="triangleUp"
								options={LANGUAGES}
								placeholder={TranslationService.get(
									'settings.language.label',
									'Välj språk',
								)}
								itemIcon="check"
								showSelected
								search={false}
								type="default"
								selected={[
									LANGUAGES.find(
										({ value }) => value === language,
									),
								]}
								onChange={option => {
									setLanguage(option.value);
									dispatch(selectLanguage(option.value));
								}}
							/>
						</FormGroupV2>
					</div>
				</FeatureFlag> */}
				<FeatureFlag feature="reset-onboarding">
					<Button
						disabled={!showSomething}
						size="small"
						type="link"
						text={TranslationService.get('settings.button.reset')}
						style={{ marginTop: '16px', padding: '0' }}
						onClick={() => {
							dispatch(
								saveUser({
									...currentUser,
									settings: {
										...currentUser.settings,
										hasOnboarded: false,
										erpReminder: false,
										hasReadAdjustable: false,
										layout: {
											...layout,
											graph: {
												...(layout?.graph || {}),
												showAccountOnSearch: null,
											},
										},
										flags: {
											...(currentUser?.settings?.flags ||
												{}),
											hasOnboardedNotificationForecast: false,
											whyDidYouOnboard: false,
											hasReadCreditOnboarding: false,
											hasReadStatementInfo: false,
											hasClickedCredit: false,
										},
									},
								}),
							);
						}}
					/>
				</FeatureFlag>
			</ModalBody>
			<ModalFooter className="modal-footer">
				<Button
					size="medium"
					type="default"
					text={TranslationService.get(
						'settings.button.cancel',
						'Avbryt',
					)}
					onClick={cancel}
				/>
				<Button
					disabled={!showSomething}
					size="medium"
					type="primary"
					text={TranslationService.get(
						'settings.button.save',
						'Spara',
					)}
					onClick={saveSettings}
				/>
			</ModalFooter>
		</div>
	);
})`
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	.asteria-layout-options {
		display: flex;
	}

	.asteria-layout-fixed {
		.asteria-checkbox-wrapper:first-child {
			display: none;
		}
	}

	&.asteria-graph-layout-tabs {
		.asteria-settings-graph {
			display: none;
		}

		.asteria-checkbox-group-wrapper-show-balance {
			display: none;
		}

		.asteria-checkbox-group-wrapper-show-balance-numbers {
			display: none;
		}

		.asteria-checkbox-group-wrapper-show-deposit {
			display: none;
		}

		.asteria-checkbox-group-wrapper-show-withdraw {
			display: none;
		}
	}

	.asteria-layout-box {
		flex: 1;
		padding: 4px 16px 12px 12px;
		margin: 0 8px 0 0;

		border-radius: 3px;
		border: 1px solid #d3c3bc;
		flex: 1;

		&:last-of-type {
			margin: 0;
		}

		&.asteria-state-active {
			border: 1px solid #ee7024;
			background: #faf0e7;
			/* .asteria-checkbox-mark {
				border-color: rgba(114, 96, 94, 1);
				background-color: rgba(114, 96, 94, 1);
				&:after {
					height: 8px;
					width: 8px;
					background: #fff;
					border-radius: 50px;
					content: '';
					display: block;
				}
			} */
			&:hover {
				background: #faf0e7;
				cursor: pointer;
				.asteria-checkbox-mark {
					border-color: rgba(114, 96, 94, 1);
					background-color: rgba(114, 96, 94, 1);
					&:after {
						height: 8px;
						width: 8px;
						background: #fff;
						border-radius: 50px;
						content: '';
						display: none;
					}
				}
			}
		}

		${Icon} {
			width: 24px;
			height: 24px;
			margin: -27px 0 8px 28px;
			svg {
				fill: #9f8c82;
			}
		}

		.asteria-checkbox-label {
			display: none;
		}

		.asteria-layout-box-content {
			${Title} {
				font-size: 16px;
				font-family: Swedbank Sans;
				font-weight: 700;
				color: var(--text-color);
				margin-bottom: 4px;
			}

			${Text} {
				font-size: 12px;
			}
		}

		&:hover {
			background: #faf0e7;
			cursor: pointer;
			.asteria-checkbox-mark {
				border-color: rgba(114, 96, 94, 1);
				background-color: rgba(114, 96, 94, 1);
				&:after {
					height: 8px;
					width: 8px;
					background: #fff;
					border-radius: 50px;
					content: '';
					display: block;
				}
			}
		}
	}

	${({ theme }) => compileStyles(SettingsView, theme)}
`;

SettingsView.Styler = {
	base: [Getter('settings')],
	children: [
		{
			component: Title,
			base: [Getter('title')],
		},
		{
			component: FormGroupV2,
			base: [Getter('groups')],
			children: [
				{
					component: CheckboxGroupV2,
					base: [Getter('checkbox')],
				},
			],
		},
	],
};

export default SettingsView;
