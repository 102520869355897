export default {
	'menu.financial.title': 'Finansiera',
	'menu.financial.factoring.title': 'Fakturaköp',
	'menu.financial.currencyrisk.title': 'Valutarisk',
	'menu.categories.title': 'Kategorier',
	'menu.clients.title': 'Kunder',
	'menu.status.title': 'Status',
	'menu.currency.title': 'Valutor',
	'graph.tab.accountbalance': 'Kontobalans',
	'graph.tab.revenueexpenses': 'In-/utbetalningar',
	'notification.currency.risk.onboarding.body':
		'Vi har identifierat en risk mot {{{currency | currencyFlag}}} {{currency}} om {{total | number : false}}.',
	'notification.currency.risk.onboarding.action': 'Se min risk',

	'menu.financial.credit.title': 'Finansieringsbehov',

	'financial.service.overdraft.amount.label': 'Möjligt finansieringsbehov:',
	'button.apply': 'Ansök',
	'button.close': 'Stäng',
	'overdraft.modal.confirm.title': 'Finansieringsbehov',
	'overdraft.sent.alert.title': 'Tack',
	'overdraft.sent.content.title1': 'Finansieringsbehov',
	'overdraft.sent.content.title3':
		'En rådgivare kommer att kontakta dig så snart som möjligt angående ditt finansieringsbehov.',
	'financial.service.overdraft.helpTitle': 'Så här fungerar det',
	'financial.service.overdraft.help':
		'Klicka och drag i den röda rektangeln som syns i kontobalansgrafen för själv räkna ut vilket finansieringsbehov som skulle kunna passa dig, du kan även fylla i rutan nedan. När du räknat ut ditt finansieringsbehov skickas du vidare till kontaktformuläret. För att eventuellt få ett en snabbare granskning av ärendet rekommenderar vi att du gör följande.',
	'credit.tag.name': 'Finansieringsbehov',

	'accounting.results.deposit': 'Omsättning',
	'accounting.results.deposit.revenue': 'Omsättning',
	'accounting.results.deposit.purchases': 'Inköpskostnad',
	'accounting.results.deposit.total': 'TB',
	'accounting.results.deposit.faction.total': 'TB %',

	'accounting.results.expenses': 'Kostnader',
	'accounting.results.expenses.misc_expenses': 'Övriga rörelsekostnader',
	'accounting.results.withdraw.employees': 'Personalkostnader',
	'accounting.results.withdraw.depreciation': 'Avskrivningar',
	'accounting.results.expenses.total': 'Rörelseresultat',

	'accounting.financial_results': 'Resultat efter finans',
	'accounting.results.financial_net': 'Finansnetto',
	'accounting.financial_results.total': 'Resultat efter finans',

	'accounting.results_before_disp': 'Resultat före disp',
	'accounting.results.extra_netto': 'Extraordinärt netto',
	'accounting.results_before_disp.total': 'Resultat före disp',

	'accounting.results': 'Redovisat resultat',
	'accounting.results.statement': 'Bokslutsdisp, kc-bidrag',
	'accounting.results.taxes': 'Skatt',

	'statement.result': 'Redovisat resultat',

	'accounting.balance.assets': 'Tillgångar',
	'accounting.balance.assets.assets': 'Anl. tillg',
	'accounting.balance.assets.warehouse': 'Varulager',
	'accounting.balance.assets.customers': 'Kundfordringar',
	'accounting.balance.assets.receivables': 'Övriga korta fordringar',
	'accounting.balance.assets.account': 'Kassa inkl outnyttjad check',
	'accounting.balance.assets.total': 'Summa tillgångar',

	'accounting.balance.debt': 'Skulder',
	'accounting.balance.debt.capital_taxation': 'Eget kapital beskattat',
	'accounting.balance.debt.capital_none_taxation': 'Eget kapital obeskattat',
	'accounting.balance.debt.provisions': 'Avsättningar, mm (ex latent skatt)',
	'accounting.balance.debt.longterm': 'Långa skulder inkl beviljad check',
	'accounting.balance.debt.suppliers': 'Leverantörer',
	'accounting.balance.debt.credit':
		'Skulder till kreditinstitut, kortfristig del',
	'accounting.balance.debt.invoice_credit': 'Skuld fakturakredit',
	'accounting.balance.debt.misc': 'Övriga korta skulder',
	'accounting.balance.debt.total': 'Summa skulder / ek',

	'accounting.capital': 'Uppgifter för rörelsekapitalberäkning',
	'accounting.capital.storage': 'Genomsnittlig lagertid dagar',
	'accounting.capital.customer_credit': 'Kredittid kunder dagar',
	'accounting.capital.misc_receivables': 'Övriga korta fordringar',
	'accounting.capital.supplier_credit': 'Kredittid leverantörer dagar',
	'accounting.capital.invoice_credit_debt': 'Skuld fakturakredit',
	'accounting.capital.misc_credit': 'Övr korta skulder (exkl amort)',

	'accounting.misc': 'Övrigt',
	'accounting.misc.net_investments': 'Nettoinvesteringar',
	'accounting.misc.dividend': 'Utdelning',
	'accounting.misc.rights_issue': 'Planerad nyemission',
	'accounting.misc.amortizations': 'Planerade amorteringar',
	'accounting.misc.loans': 'Planerade nya lån',

	'survey.form.message.validation.required': 'Vänligen fyll i din åsikt',
	'feedback.form.message.validation.required': 'Vänligen fyll i din åsikt',

	'graph.credit.handle.tooltip':
		'Klicka och drag i den röda rektangeln för att själv räkna ut vilket finansieringsbehov som skulle kunna passa dig, du kan även fylla i rutan till höger.',
	'financial.service.overdraft.help.link': 'Hur fungerar Finansiering?',
	'rating.desc': 'Hur upplever du den här användarupplevelsen?',
	'rating.onboarding.desc':
		'Hur upplever du att det gick att ansluta ditt bokföringssystem?',
	'rating.onboarding.asteria.desc':
		'Hur upplever du att det gick att ansluta bokföringssystem Asteria ERP?',
	'financial.service.overdraft.amount.error':
		'Du måste ange ett belopp som skiljer sig från din nuvarande finansiering',

	'list.transactions.divider.adjustable.save.tooltip': 'Spara ändringar',
	'list.transactions.divider.adjustable.edit.tooltip':
		'Justera transaktioner',
	'list.transactions.divider.adjustable.edit.cancel.tooltip':
		'Avbryt ändringar',
	'list.transactions.divider.asteria-adjustable-help-text':
		'Ändra belopp på för en period går bara på in-/utgående läge, klicka på staplarna ovan för ändra.',

	'transaction.options.edit': 'Ändra transaktion',
	'transaction.options.remove': 'Ta bort',
	'transaction.options.details': 'Visa transaktionsdetaljer',
};
