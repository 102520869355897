import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { TranslationService } from '@asteria/services-language';
import Button from './button';
import { Text } from './typography';

const Icons = [
	{
		id: 1,
		label: 'Dålig',
		active: false,
		icon: (
			<svg
				className={classNames(
					'asteria-rating--rate_icon__svg asteria-rating--rate_icon__active',
				)}
				height="100%"
				width="100%"
				version="1.1"
				x="0px"
				y="0px"
				viewBox="0 0 24 24"
				enableBackground="new 0 0 24 24"
				xmlSpace="preserve"
			>
				<g>
					<path d="M23.174,10.191l-5.032,4.907l1.192,6.931c0.013,0.098,0.013,0.182,0.013,0.279c0,0.358-0.167,0.691-0.568,0.691   c-0.194,0-0.388-0.068-0.554-0.166L12,19.562l-6.225,3.272C5.596,22.932,5.416,23,5.222,23c-0.402,0-0.582-0.333-0.582-0.691   c0-0.098,0.015-0.182,0.026-0.279l1.192-6.931l-5.046-4.907c-0.167-0.181-0.346-0.416-0.346-0.666c0-0.416,0.431-0.583,0.776-0.637   l6.96-1.012l3.118-6.308C11.446,1.306,11.681,1,12,1s0.554,0.306,0.679,0.569l3.12,6.308l6.959,1.012   c0.33,0.055,0.775,0.222,0.775,0.637C23.533,9.775,23.354,10.011,23.174,10.191z" />
				</g>
			</svg>
		),
	},
	{
		id: 2,
		label: 'Besviken',
		active: false,
		icon: (
			<svg
				className={classNames(
					'asteria-rating--rate_icon__svg asteria-rating--rate_icon__active',
				)}
				height="100%"
				width="100%"
				version="1.1"
				x="0px"
				y="0px"
				viewBox="0 0 24 24"
				enableBackground="new 0 0 24 24"
				xmlSpace="preserve"
			>
				<g>
					<path d="M23.174,10.191l-5.032,4.907l1.192,6.931c0.013,0.098,0.013,0.182,0.013,0.279c0,0.358-0.167,0.691-0.568,0.691   c-0.194,0-0.388-0.068-0.554-0.166L12,19.562l-6.225,3.272C5.596,22.932,5.416,23,5.222,23c-0.402,0-0.582-0.333-0.582-0.691   c0-0.098,0.015-0.182,0.026-0.279l1.192-6.931l-5.046-4.907c-0.167-0.181-0.346-0.416-0.346-0.666c0-0.416,0.431-0.583,0.776-0.637   l6.96-1.012l3.118-6.308C11.446,1.306,11.681,1,12,1s0.554,0.306,0.679,0.569l3.12,6.308l6.959,1.012   c0.33,0.055,0.775,0.222,0.775,0.637C23.533,9.775,23.354,10.011,23.174,10.191z" />
				</g>
			</svg>
		),
	},
	{
		id: 3,
		label: 'Neutral',
		active: false,
		icon: (
			<svg
				className={classNames(
					'asteria-rating--rate_icon__svg asteria-rating--rate_icon__active',
				)}
				height="100%"
				width="100%"
				version="1.1"
				x="0px"
				y="0px"
				viewBox="0 0 24 24"
				enableBackground="new 0 0 24 24"
				xmlSpace="preserve"
			>
				<g>
					<path d="M23.174,10.191l-5.032,4.907l1.192,6.931c0.013,0.098,0.013,0.182,0.013,0.279c0,0.358-0.167,0.691-0.568,0.691   c-0.194,0-0.388-0.068-0.554-0.166L12,19.562l-6.225,3.272C5.596,22.932,5.416,23,5.222,23c-0.402,0-0.582-0.333-0.582-0.691   c0-0.098,0.015-0.182,0.026-0.279l1.192-6.931l-5.046-4.907c-0.167-0.181-0.346-0.416-0.346-0.666c0-0.416,0.431-0.583,0.776-0.637   l6.96-1.012l3.118-6.308C11.446,1.306,11.681,1,12,1s0.554,0.306,0.679,0.569l3.12,6.308l6.959,1.012   c0.33,0.055,0.775,0.222,0.775,0.637C23.533,9.775,23.354,10.011,23.174,10.191z" />
				</g>
			</svg>
		),
	},
	{
		id: 4,
		label: 'Nöjd',
		active: false,
		icon: (
			<svg
				className={classNames(
					'asteria-rating--rate_icon__svg asteria-rating--rate_icon__active',
				)}
				height="100%"
				width="100%"
				version="1.1"
				x="0px"
				y="0px"
				viewBox="0 0 24 24"
				enableBackground="new 0 0 24 24"
				xmlSpace="preserve"
			>
				<g>
					<path d="M23.174,10.191l-5.032,4.907l1.192,6.931c0.013,0.098,0.013,0.182,0.013,0.279c0,0.358-0.167,0.691-0.568,0.691   c-0.194,0-0.388-0.068-0.554-0.166L12,19.562l-6.225,3.272C5.596,22.932,5.416,23,5.222,23c-0.402,0-0.582-0.333-0.582-0.691   c0-0.098,0.015-0.182,0.026-0.279l1.192-6.931l-5.046-4.907c-0.167-0.181-0.346-0.416-0.346-0.666c0-0.416,0.431-0.583,0.776-0.637   l6.96-1.012l3.118-6.308C11.446,1.306,11.681,1,12,1s0.554,0.306,0.679,0.569l3.12,6.308l6.959,1.012   c0.33,0.055,0.775,0.222,0.775,0.637C23.533,9.775,23.354,10.011,23.174,10.191z" />
				</g>
			</svg>
		),
	},
	{
		id: 5,
		label: 'Bra',
		active: false,
		icon: (
			<svg
				className={classNames(
					'asteria-rating--rate_icon__svg asteria-rating--rate_icon__active',
				)}
				height="100%"
				width="100%"
				version="1.1"
				x="0px"
				y="0px"
				viewBox="0 0 24 24"
				enableBackground="new 0 0 24 24"
				xmlSpace="preserve"
			>
				<g>
					<path d="M23.174,10.191l-5.032,4.907l1.192,6.931c0.013,0.098,0.013,0.182,0.013,0.279c0,0.358-0.167,0.691-0.568,0.691   c-0.194,0-0.388-0.068-0.554-0.166L12,19.562l-6.225,3.272C5.596,22.932,5.416,23,5.222,23c-0.402,0-0.582-0.333-0.582-0.691   c0-0.098,0.015-0.182,0.026-0.279l1.192-6.931l-5.046-4.907c-0.167-0.181-0.346-0.416-0.346-0.666c0-0.416,0.431-0.583,0.776-0.637   l6.96-1.012l3.118-6.308C11.446,1.306,11.681,1,12,1s0.554,0.306,0.679,0.569l3.12,6.308l6.959,1.012   c0.33,0.055,0.775,0.222,0.775,0.637C23.533,9.775,23.354,10.011,23.174,10.191z" />
				</g>
			</svg>
		),
	},
];

// Sätt aktivt värde
// Veta vilken av array som är aktiv
// Klicka för att sätta current aktiv
// Map =< sätt aktiv på de som är före
// Klick öppnar modal och i den presentera formulär samt ratingen man valde och kan ändra efteråt
// Bryt ut till egen komponent

const Rating = styled(
	({ className, rating, flow, onClose = () => {}, onClick = () => {} }) => {
		const [clickedIndex, setClickedIndex] = useState(rating);

		const handleClick = index => () => {
			setClickedIndex(index);
			onClick(index);
		};

		useEffect(() => {
			setClickedIndex(rating);
		}, [rating]);

		return (
			<div className={classNames('asteria-rating', className)}>
				<div className="asteria-rating-inner">
					<Button
						icon="close"
						type="icon"
						className="asteria-rating-close"
						onClick={onClose}
					/>
					<Text type="paragraph">
						{TranslationService.get(
							['rating.desc', `rating.${flow}.desc`],
							'Hur upplever du den här användarupplevelsen?',
						)}
					</Text>
					<div className="asteria-rating-rates">
						{Icons.map((icon, index) => (
							<div
								key={icon.id}
								className={classNames(
									'asteria-rating--rate',
									clickedIndex !== null &&
										clickedIndex >= index
										? 'asteria-rating--rate__active'
										: 'asteria-rating--rate_icon__empty',
									icon.active
										? 'asteria-rating--rate__active'
										: '',
								)}
							>
								<Text className="asteria-rating--rate_label">
									{icon.label}
								</Text>
								<div
									className={classNames(
										'asteria-rating--rate_icon',
										clickedIndex !== null &&
											clickedIndex >= index
											? 'asteria-rating--rate_icon__active'
											: 'asteria-rating--rate_icon__empty',
										icon.active
											? 'asteria-rating--rate_icon__active'
											: 'asteria-rating--rate_icon__empty',
									)}
									onClick={handleClick(index)}
									onKeyPress={handleClick(index)}
									role="button"
									tabIndex="-0"
								>
									{icon.icon}
									<svg
										className={classNames(
											'asteria-rating--rate_icon__svg asteria-rating--rate_icon__empty',
										)}
										height="100%"
										width="100%"
										viewBox="0 0 24 24"
										x="0px"
										y="0px"
									>
										<path d="M5.22,23.5a1,1,0,0,1-1-1.27l1.61-7.09L.35,10.31a1,1,0,0,1-.3-1.1A1,1,0,0,1,.94,8.5l7.22-.66L11,1.13a1,1,0,0,1,1.91,0l2.85,6.69,7.25.68a1,1,0,0,1,.89.71,1,1,0,0,1-.3,1.1L18.19,15.1l1.6,7.13a1,1,0,0,1-.4,1.07,1,1,0,0,1-1.14.05L12,19.63l-.29-.43.26.43L5.76,23.35A1,1,0,0,1,5.22,23.5ZM12,18.62a1,1,0,0,1,.53.15l6.22,3.73L17.2,15.36a1,1,0,0,1,.33-1L23,9.56l-7.24-.72a1,1,0,0,1-.86-.63L12,1.52,9.11,8.21a1,1,0,0,1-.86.63L1,9.49l5.44,4.86a1,1,0,0,1,.33,1L5.19,22.45l6.28-3.68h0A1,1,0,0,1,12,18.62Z" />
									</svg>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	},
)`
	padding: 32px;
	display: flex;
	flex: 1;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background: #e5f8fb;
	position: relative;
	margin: 16px auto;
	.asteria-rating-inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.asteria-text {
			margin-bottom: 16px;
		}
		.asteria-rating-rates {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}
		.asteria-rating--rate {
			display: flex;
			align-items: center;
			flex-direction: column-reverse;
			justify-content: center;

			&:first-of-type {
				flex-direction: row;
				.asteria-rating--rate_label {
					display: flex;
				}
			}

			&:last-of-type {
				flex-direction: row-reverse;
				.asteria-rating--rate_label {
					display: flex;
				}
			}

			&.asteria-rating--rate__active {
			}

			.asteria-rating--rate_label {
				color: #a2a19e;
				display: none;
				margin-bottom: 0px;
			}
			.asteria-rating--rate_icon {
				margin: 8px;
				height: 32px;
				width: 32px;
				position: relative;
				cursor: pointer;
				&.asteria-rating--rate_icon__empty {
					.asteria-rating--rate_icon__svg {
						&.asteria-rating--rate_icon__active {
							opacity: 0;
						}
					}
				}
				&.asteria-rating--rate_icon__active {
					.asteria-rating--rate_icon__svg {
						&.asteria-rating--rate_icon__active {
							opacity: 1;
						}
						&.asteria-rating--rate_icon__empty {
							fill: #ee9611;
						}
					}
				}
				.asteria-rating--rate_icon__svg {
					height: 100%;
					width: 100%;
					position: absolute;
					fill: #999999;
					&.asteria-rating--rate_icon__empty {
						fill: #999999;
					}
					&.asteria-rating--rate_icon__active {
						fill: #ee9611;
					}
				}
			}

			&:hover {
				.asteria-rating--rate_icon {
					.asteria-rating--rate_icon__svg {
						&.asteria-rating--rate_icon__active {
							opacity: 0;
						}
						&.asteria-rating--rate_icon__empty {
							fill: #ee9611;
						}
					}
				}
			}
		}
		.asteria-rating-close {
			position: absolute;
			right: 16px;
			top: 16px;
			background: transparent;
			height: 16px;
			width: 16px;
			.asteria-icon-wrapper {
				.asteria-icon-svg {
					fill: #999999;
				}
			}
		}
	}
`;

export default Rating;
