import { useState, useEffect, useContext } from 'react';
import DatalayerContext from '@asteria/services-datalayer/react/context';

import useLocation from './useLocation';

const matchRoutes = (pathname, routes) => {
	const matched = routes.find(({ path }) => path(pathname));

	if (matched) {
		return { ...matched, ...matched.path(pathname) };
	}

	return false;
};

const useRoutes = routes => {
	const { dispatch } = useContext(DatalayerContext);
	const [route, setRoute] = useState(null);
	const { pathname = '/' } = useLocation();
	useEffect(() => {
		const matchedRoute = matchRoutes(pathname, routes);

		if (matchedRoute && route?.path !== matchedRoute?.path) {
			setRoute(matchedRoute);
			dispatch({ action: 'SET_ROUTE', payload: matchedRoute });
		} else if (!matchRoutes) {
			setRoute(null);
		}
	}, [dispatch, pathname, route, routes]);

	return route;
};

export default useRoutes;
