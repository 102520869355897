import React, { useCallback } from 'react';
import styled from 'styled-components';
import Button from '@asteria/component-button/button';
import Text from '@asteria/component-typography/text';
import classNames from 'classnames';
import { Getter } from '@asteria/utils';

const Content = styled(Text)``;
Content.Styler = Text.Styler;

/**
 * Custom Accordion component
 * @param props
 * @return {*}
 * @constructor
 */
const Accordion = styled(props => {
	const { title, isOpen, children, setOpen, index, className } = props;
	// const isOpened = openedIndex === index;
	const toggle = useCallback(() => {
		setOpen(isOpen ? null : index);
	}, [index, isOpen, setOpen]);

	return (
		<div
			className={classNames(className, 'accordion-element-wrapper', {
				'asteria-state-active': isOpen,
			})}
		>
			<Button
				type="link"
				size="large"
				iconPosition="before"
				icon="right"
				// icon={isOpened ? 'down' : 'right'}
				onClick={toggle}
			>
				{title}
			</Button>
			<Content size="body">{children}</Content>
		</div>
	);
})``;

Accordion.Styler = {
	children: [
		{
			component: Button,
			base: [Getter('button')],
		},
		{
			component: Content,
			base: [Getter('content')],
		},
	],
};

export default Accordion;
