import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import Text from '@asteria/component-typography/text';
import { Getter, useStore } from '@asteria/utils';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { FeatureFlag } from '@asteria/component-featureflag';

import { Dropdown } from '@asteria/component-form';

import {
	requestNewRange,
	clearGraphData,
	requestGraphData,
} from '@asteria/services-datalayer/services/graph/actions';
import { TranslationService } from '@asteria/services-language';
import {
	setTimesliceSize,
	setCurrentDate,
} from '@asteria/services-datalayer/services/appstate/actions';
import { addDays, addWeeks, addMonths, addYears } from 'date-fns';

const addTimeslice = (date, size, count = 1) => {
	if (size === 'day') {
		return addDays(date, count);
	}
	if (size === 'week') {
		return addWeeks(date, count);
	}
	if (size === 'month') {
		return addMonths(date, count);
	}
	if (size === 'year') {
		return addYears(date, count);
	}

	return date;
};

const TimeOptions = [
	{
		label: 'app.timeselector.weeks',
		value: 'week',
	},
	{
		label: 'app.timeselector.months',
		value: 'month',
	},
	{
		label: 'app.timeselector.years',
		value: 'year',
	},
];

const Time = styled(({ className, key, active = false, onClick, children }) => (
	<div
		role="button"
		className={classNames(className, 'asteria-timeslice-selector-time', {
			'asteria-state-active': active,
		})}
		onClick={() => onClick(key)}
		onKeyPress={() => onClick(key)}
		tabIndex="-1"
	>
		{children}
	</div>
))`
	cursor: pointer;
`;
Time.displayName = 'Time';

const Selector = styled(({ className }) => {
	const { dispatch } = useContext(DatalayerContext);

	const [size = false] = useStore(
		'store-appstate',
		({ timesliceSize }) => timesliceSize,
		true,
	);

	const updateRange = useCallback(
		timesliceSize => {
			if (size === timesliceSize) {
				return;
			}
			dispatch(setTimesliceSize(timesliceSize));
			dispatch(
				requestNewRange('cashflow-bar-graph', false, timesliceSize),
			);
			dispatch(
				requestGraphData('cashflow-bar-graph', {
					size: timesliceSize,
				}),
			);
			dispatch(
				setCurrentDate(addTimeslice(new Date(), timesliceSize, -4)),
			);
			dispatch(clearGraphData('cashflow-bar-graph'));
		},
		[dispatch, size],
	);

	return (
		<div
			className={classNames(
				className,
				'asteria-timeslice-selector-wrapper',
			)}
		>
			<div
				className={classNames(className, 'asteria-timeslice-selector')}
			>
				<FeatureFlag feature="asteria-improvements" invert>
					<FeatureFlag feature="timeSpanDropdown" invert>
						<Time
							key="week"
							active={size === 'week'}
							onClick={() => updateRange('week')}
						>
							<Text
								size="label"
								title={TranslationService.get(
									'app.timeselector.label',
								)}
							>
								{TranslationService.get(
									'app.timeselector.weeks',
								)}
							</Text>
						</Time>
						<Time
							key="month"
							active={size === 'month'}
							onClick={() => updateRange('month')}
						>
							<Text
								size="label"
								title={TranslationService.get(
									'app.timeselector.label',
								)}
							>
								{TranslationService.get(
									'app.timeselector.months',
								)}
							</Text>
						</Time>
						<Time
							key="year"
							active={size === 'year'}
							onClick={() => updateRange('year')}
						>
							<Text
								size="label"
								title={TranslationService.get(
									'app.timeselector.label',
								)}
							>
								{TranslationService.get(
									'app.timeselector.years',
								)}
							</Text>
						</Time>
					</FeatureFlag>
				</FeatureFlag>
			</div>
			<div
				className={classNames(
					className,
					'asteria-timeslice-selector-as-dropdown',
				)}
			>
				<FeatureFlag feature="timeSpanDropdown">
					<Dropdown
						className={classNames(
							className,
							'asteria-button-timespan-select',
						)}
						size="medium"
						type="link"
						iconClosed="calendar"
						iconOpen="calendar"
						title={TranslationService.get('app.timeselector.label')}
						alt={TranslationService.get('app.timeselector.label')}
						data-tooltip={TranslationService.get(
							'app.timeselector.label',
						)}
						direction={['down', 'left']}
						options={TimeOptions}
						selected={TimeOptions.filter(
							({ value }) => value === size,
						)}
						onChange={({ value }) => updateRange(value)}
					/>
				</FeatureFlag>
				<FeatureFlag feature="asteria-improvements">
					<Dropdown
						className={classNames(
							className,
							'asteria-button-timespan-select',
						)}
						size="medium"
						type="link"
						iconClosed="calendar"
						iconOpen="calendar"
						title={TranslationService.get('app.timeselector.label')}
						alt={TranslationService.get('app.timeselector.label')}
						data-tooltip={TranslationService.get(
							'app.timeselector.label',
						)}
						direction={['down', 'left']}
						options={TimeOptions}
						selected={TimeOptions.filter(
							({ value }) => value === size,
						)}
						onChange={({ value }) => updateRange(value)}
					/>
				</FeatureFlag>
			</div>
		</div>
	);
})`
	display: flex;
	position: relative;
	margin-top: 0;
`;
Selector.displayName = 'Selector';

Selector.Styler = {
	typePrefix: 'asteria-timeslice-selector',
	children: [
		{
			typePrefix: 'asteria-timeslice-selector-time',
			component: Time,
			base: [Getter('time')],
			children: [
				{
					component: Text,
					base: [Getter('text')],
				},
			],
		},
	],
};

export default Selector;
