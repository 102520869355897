import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Getter, compileStyles } from '@asteria/utils';

/**
 * Virtual component that serves one purpose:
 * to make adding global field properties simpler.
 *
 * For example, validation. It is a quite usual to have one logical form field
 * composed of three or more basic form elements (Input + Label + Help text for error messages, etc).
 * You can set one general validation state on this FormGroup component, and this property will be
 * propagated down to it's children
 *
 * @param children
 * @param style
 * @param validation
 * @return {*}
 * @constructor
 */
const FormGroup = styled(({ className, children, style, validation }) => {
	let childrenToRender = children;

	// Add validation option to children if any provided
	if (validation) {
		childrenToRender = React.Children.map(children, child =>
			React.cloneElement(child, { validation }),
		);
	}

	// Render wrapper only if there are any styles
	return (
		<div
			className={classNames(className, 'asteria-form-group')}
			style={style}
		>
			{childrenToRender}
		</div>
	);
})`
	${({ theme }) =>
		// Wrapper styles
		compileStyles(FormGroup, theme)}
`;

FormGroup.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	style: PropTypes.object,
	validation: PropTypes.oneOf(['error', 'warning', 'success', '']),
};

FormGroup.defaultProps = {
	children: null,
	style: undefined,
	validation: '',
};

FormGroup.Styler = {
	base: [Getter('formV2.group')],
	typePrefix: 'asteria-group',
};

export default FormGroup;
