import React, { useCallback, useRef, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { Getter, preProcess } from '@asteria/utils';
import Text from '@asteria/component-typography/text';
import { FeatureFlag } from '@asteria/component-featureflag';
import classNames from 'classnames';
import Button from '@asteria/component-button';
import { TranslationService } from '@asteria/services-language';
import posed from 'react-pose';
import Tooltip from '@asteria/component-tooltip';
import GraphContext from '../../context';

const Badge = styled(({ className, badge, bar, group }) => {
	const ref = useRef(null);
	const { types, count } = badge;

	const { clickAction, mouseEnterAction, mouseLeaveAction } = useContext(
		GraphContext,
	);

	const clickHandler = useCallback(
		e => {
			e.stopPropagation();
			clickAction({ bar, group, badge, source: 'badge' });
		},
		[clickAction, bar, group, badge],
	);

	return (
		<div
			ref={ref}
			className={classNames(
				className,
				'asteria-graph-bar-badge',
				types.map(type => `asteria-graph-bar-badge-${type}`),
			)}
			onClick={clickHandler}
			onKeyPress={clickHandler}
			role="button"
			tabIndex="-1"
			onMouseEnter={() =>
				mouseEnterAction({
					bar,
					group,
					badge,
					target: ref,
				})
			}
			onMouseLeave={() =>
				mouseLeaveAction({
					bar,
					group,
					badge,
					target: ref,
				})
			}
		>
			<Text size="label">{count > 9 ? '+9' : count}</Text>
		</div>
	);
})`
	pointer-events: all;
`;

Badge.Styler = {
	children: [
		{
			component: Text,
			base: [Getter('text')],
		},
	],
};

const pulseAnimation = keyframes`
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
	}
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
	}
}`;

const Blob = styled.div`
	background: rgba(255, 121, 63, 1);
	box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
	border-radius: 50%;
	margin: 10px;
	height: 20px;
	width: 20px;
	transform: scale(1);
	animation: ${pulseAnimation} 2s infinite;
`;

const Indicator = styled.span`
	display: none;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 8px solid rgb(244, 194, 78);
	position: absolute;
	top: -15px;
	left: calc(25% + 4px);
	transform: rotate(180deg);
`;
Indicator.displayName = 'Indicator';

const PartBar = styled(({ className, type, onClick, parts = [], value }) => (
	<div
		className={classNames(className, { [`asteria-${type}`]: type })}
		onClick={onClick}
		onKeyPress={onClick}
		role="button"
		tabIndex="-1"
	>
		{parts.map(({ value: partValue = 0, types: partTypes = [] }) => (
			<PartBar
				height={partValue / value}
				key={partTypes.join('-')}
				className={classNames(
					'asteria-graph-bar-part',
					partTypes.map(partType => `asteria-graph-part-${partType}`),
					partTypes.map(
						partType => `asteria-graph-bar-part-${partType}`,
					),
				)}
				types={partTypes}
			/>
		))}
	</div>
))`
	height: ${({ height = 0 }) => `${height * 100}%`};
	background-color: ${({ types = [], theme }) =>
		preProcess(
			types.reduce(
				(acc, type) => `var(--system-${type}-color, ${acc})`,
				'#000',
			),
			theme,
		)};
	background-image: ${({ types = [], theme }) =>
		preProcess(
			types.reduce(
				(acc, type) => `var(--system-${type}-image, ${acc})`,
				'#000',
			),
			theme,
		)};
`;

PartBar.displayName = 'GraphPartBar';

const AnimatedBar = posed.div({
	open: {
		height: props => {
			let { height } = props;
			if (height > 1) {
				height = 0;
			}
			return `${height * 100}%`;
		},
		transition: { duration: 1000 },
		delay: ({ index }) => index * 100,
	},
	enter: { height: '0%', transition: { duration: 0 } },
});
AnimatedBar.displayName = 'AnimatedBar';

const loading = keyframes`
0% {
	opacity: 0.1;
	height: 50%
}
50% {
	opacity: 0.3;
	height: 25%
}
100% {
	opacity: 0.1;
	height: 50%;
}
`;

const BaseBar = styled(
	React.forwardRef(
		(
			{
				className,
				type,
				onClick,
				onMouseEnter,
				onMouseLeave,
				height,
				index,
				badges,
				children,
			},
			ref,
		) => (
			<AnimatedBar
				className={classNames(className, { [`asteria-${type}`]: type })}
				onClick={onClick}
				onKeyPress={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				role="button"
				tabIndex="-1"
				key="bar"
				height={height}
				pose={(height > 0 && height <= 1) || badges ? 'open' : 'closed'}
				poseKey={height}
				index={index}
				ref={ref}
			>
				{children}
			</AnimatedBar>
		),
	),
)`
	height: 0%;
	background-color: black;
	position: relative;
	display: flex;
	flex-direction: column;
	&.asteria-graph-bar-loading {
		animation: ${loading} 1s infinite ease-in-out;
	}
	${({ pointer = true }) => (pointer === true ? 'pointer-events: all;' : '')}
`;

BaseBar.displayName = 'GraphBaseBar';

const AdjustBar = styled(({ types, onClick, className }) => (
	<div
		onClick={onClick}
		onKeyPress={onClick}
		role="button"
		tabIndex="-1"
		className={classNames(className, 'asteria-graph-bar-actions')}
	>
		<div
			className={classNames(
				className,
				'asteria-graph-bar-action asteria-adjust-add-event',
			)}
		>
			<Tooltip
				hover
				isStatic
				title={TranslationService.get(
					types.map(
						type => `graph.bargraph.bar.${type}.tooltip.title`,
					),
				)}
				content={TranslationService.get(
					'list.adjustable.getstarted.total.label',
				)}
			>
				<Button
					type="link"
					icon="circlePen"
					size="large"
					title={TranslationService.get(
						'list.adjustable.total.label',
					)}
					className={classNames(className, `asteria-adjustable`)}
				/>
			</Tooltip>
			<div
				className={classNames(
					className,
					'asteria-graph-bar-action-behind',
				)}
			/>
		</div>
	</div>
))`
	pointer-events: auto;
	z-index: -1;
	position: relative;
	width: 48px;
	display: flex;
	justify-content: center;
	height: 100%;
	cursor: pointer;
	.asteria-graph-bar-action-behind {
		height: 4px;
		width: 48px;
		position: absolute;
		background: #b7b7b7;
	}
	.asteria-tooltip-trigger-wrapper {
		align-items: flex-start;
		height: 30px;
	}
	.asteria-button {
		justify-content: center;
		.asteria-icon-wrapper {
			transform: rotate(180deg);
			height: 16px !important;
			margin: 0 !important;
			padding: 0;
		}
		display: none !important;
	}

	display: none;
`;
AdjustBar.displayName = 'AdjustBar';
AdjustBar.Styler = {
	children: [
		{
			component: Button,
			base: [Getter('button')],
		},
	],
};

const Bar = styled(
	({
		className,
		bar = {},
		group = {},
		height = 0,
		index = 0,
		active = false,
		pointer = false,
		beacon = false,
		rangeType = [],
	}) => {
		const { types = [], value = 0, parts = [], badges = [] } = bar;
		const ref = useRef(null);
		const { clickAction, mouseEnterAction, mouseLeaveAction } = useContext(
			GraphContext,
		);

		const clickHandler = useCallback(
			(source = 'bar') => {
				clickAction({
					bar,
					group,
					source,
					beacon: beacon ? types : false,
				});
			},
			[clickAction, bar, group, types, beacon],
		);

		/* 		if (value === 0 && badges.length === 0) {
			return null;
		} */

		return (
			<div
				className={classNames(
					className,
					types.map(type => `asteria-${type}`),
					{ 'asteria-state-active': active },
				)}
				role="button"
				tabIndex="-1"
			>
				{value !== 0 || badges.length !== 0 ? (
					<BaseBar
						height={height}
						index={index}
						pointer={pointer}
						ref={ref}
						badges={badges && badges.length > 0}
						className={classNames(
							'asteria-graph-bar-main',
							types.map(type => `asteria-graph-bar-${type}`),
							{ 'asteria-graph-bar-zero': value === 0 },
						)}
						onClick={() => clickHandler()}
						onMouseEnter={() =>
							mouseEnterAction({ bar, group, target: ref })
						}
						onMouseLeave={() =>
							mouseLeaveAction({ bar, group, target: ref })
						}
					>
						{beacon ? (
							<FeatureFlag feature="adjustable-onboarding">
								<div
									className="olle"
									data-tooltip="Kom igång och skapa din första prognos"
									style={{
										display: 'flex',
										flexGrow: 0,
										flexShrink: 1,
										width: '100%',
										margin: '0px auto',
										position: 'absolute',
										bottom: '0px',
										justifyContent: 'center',
										transform: 'rotate(180deg)',
									}}
								>
									<Blob />
								</div>
							</FeatureFlag>
						) : null}
						{badges.map(badge => (
							<Badge
								key={`badge_${badge.types.join('_')}`}
								badge={badge}
								group={group}
								bar={bar}
							/>
						))}
						{parts.map(
							(
								{
									value: partValue = 0,
									types: partTypes = [],
									parts: subParts = [],
								},
								i,
							) => (
								<PartBar
									height={partValue / value}
									value={partValue}
									types={partTypes}
									key={[
										...partTypes,
										...subParts.map(p => p.types),
										i, // TODO: remove when we get correct data
									].join('-')}
									parts={subParts}
									className={classNames(
										'asteria-graph-bar-part',
										partTypes.map(
											type =>
												`asteria-graph-part-${type}`,
										),
										partTypes.map(
											type =>
												`asteria-graph-bar-part-${type}`,
										),
									)}
								/>
							),
						)}
					</BaseBar>
				) : null}
				{value === 0 &&
				(rangeType.includes('forecast') ||
					rangeType.includes('today')) &&
				!types.includes('background') ? (
					<AdjustBar
						types={types}
						onClick={() => clickHandler(/* 'edit' */)}
					/>
				) : null}
				<FeatureFlag feature="show-label-below-bar-part">
					{types.includes('deposit') ? (
						<Text className="asteria-label revenue">&#65122;</Text>
					) : (
						<Text className="asteria-label expenses">﹣</Text>
					)}
				</FeatureFlag>
				<Indicator className="asteria-bar-indicator" />
			</div>
		);
	},
)`
	height: 100%;
	position: relative;
	pointer-events: none;
	z-index: 2;

	.asteria-label.revenue,
	.asteria-label.expenses {
		transform: rotate(180deg);
		position: absolute;
		top: -20px;
		left: 0;
		font-size: 12px;
		text-align: center;
		margin: 0 auto;
		opacity: 0.7;
		width: 48px;
	}

	.asteria-graph-part-paid {
		order: 1;
	}

	.asteria-graph-part-unpaid {
		order: 2;
	}

	.asteria-graph-part-signed {
		order: 3;
	}

	.asteria-graph-part-forecast {
		order: 4;
	}

	.asteria-graph-part-overdue {
		order: 5;
	}
`;

Bar.displayName = 'GraphBar';
Bar.Styler = {
	children: [
		{
			component: BaseBar,
			typePrefix: 'asteria-graph-bar',
			base: [Getter('base')],
			children: [
				{
					component: PartBar,
					typePrefix: 'asteria-graph-bar-part',
					base: [Getter('part')],
				},
				{
					component: Badge,
					typePrefix: 'asteria-graph-bar-badge',
					base: [Getter('badge')],
				},
			],
		},
		{
			component: AdjustBar,
			typePrefix: 'asteria-graph-bar-adjust',
			base: [Getter('adjust')],
		},
		{
			component: Indicator,
			typePrefix: 'asteria-graph-bar-part',
			base: [Getter('indicator')],
		},
	],
};

export { Bar, BaseBar };
