import React, { useState, useCallback, useContext, useEffect } from 'react';
import {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ContentContext,
} from '@asteria/component-modal';

import Title from '@asteria/component-typography/title';
import Text from '@asteria/component-typography/text';
import Button from '@asteria/component-button/button';
import { TranslationService } from '@asteria/services-language';
import styled from 'styled-components';
import { compileStyles, StyleGetter, Getter } from '@asteria/utils';
import Accordion from '../components/accordion';
import Division from '../components/division';

/*
const GET_FAQ = gql`
	query FaqQuestions($partnerId: ObjectId) {
		faqQuestions(partnerId: $partnerId) {
			title
			description
		}
	}
`;
*/

const ModalBodyStyled = styled(Division)`
	display: flex;
	justify-content: flex-start;

	${({ theme }) =>
		// Wrapper styles
		compileStyles(
			{
				component: Division,
				base: [StyleGetter(`supportV2.faq.modalBody`, {})],
				children: [
					{
						component: Button,
						base: [StyleGetter(`button`, {})],
					},
				],
			},
			theme,
		)}
`;

const INITIAL_MAX_SHOWED_FAQ_ITEMS_COUNT = 3;
const ACCORDION_WRAPPER_STYLE = {
	height: '280px',
	overflowX: 'hidden',
	overflowY: 'auto',
	borderTop: '2px solid rgb(235, 231, 226)',
};

const FAQ = styled(({ className, setTab, onEnd = () => {} }) => {
	const { content, setContent } = useContext(ContentContext);
	const [loadMore, setLoadMore] = useState(false);
	const [openedIndex, setOpen] = useState(null);
	const showMore = useCallback(() => setLoadMore(true), []);
	const goToTicket = useCallback(() => setTab('ticket'), [setTab]);
	useEffect(
		() =>
			setContent({
				...content,
				title: (
					<ModalHeader>
						<Title size="title1">
							{TranslationService.get(
								'supportModal.step1.title',
								'Hjälp & Support',
							)}
						</Title>
						<Button icon="close" type="link" onClick={onEnd} />
					</ModalHeader>
				),
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const faqQuestions = [
		{
			title: TranslationService.get(
				'supportModal.step1.faqItem1.title',
				'Hur ansluter jag mitt bokföringssystem till Företagskollen?',
			),
			description: TranslationService.get(
				'supportModal.step1.faqItem1.description',
				'Välja Hantera bokföringssystem under Inställningar.',
			),
		},
		{
			title: TranslationService.get(
				'supportModal.step1.faqItem2.title',
				'Vilka bokföringssystem har ni stöd för?',
			),
			description: TranslationService.get(
				'supportModal.step1.faqItem2.description',
				'I dagsläget stödjer vi Fortnox samt Visma eEkonomi. Utökat bokföringssystemstöd planeras längre fram.',
			),
		},
		{
			title: TranslationService.get(
				'supportModal.step1.faqItem3.title',
				'Hur kan jag lära mig mer om Företagskollen?',
			),
			description: TranslationService.get(
				'supportModal.step1.faqItem3.description',
				'Välj Hur fungerar tjänsten Företagskollen? under Inställningar.',
			),
		},
		{
			title: TranslationService.get(
				'supportModal.step1.faqItem4.title',
				'Hur kontaktar jag support för Företagskollen?',
			),
			description: TranslationService.get(
				'supportModal.step1.faqItem4a.description',
				'Välj Hjälp & Support följt av Skapa ärende / Rapportera fel under Inställningar.',
			),
		},
	];

	return (
		<div className={className}>
			<ModalBody scroll className="modal-body">
				<Text size="body" className="asteria-faq-intro">
					{TranslationService.get(
						'supportModal.step1.description',
						'Supporten besvarar frågor som gäller Företagskollen',
					)}
				</Text>

				<Title size="title2" className="asteria-faq-title">
					{TranslationService.get(
						'supportModal.step1.faqTitle',
						'Vanliga frågor och svar',
					)}
				</Title>
				<div
					className="asteria-accordion-wrapper"
					style={ACCORDION_WRAPPER_STYLE}
				>
					{loadMore ? (
						faqQuestions.map((faq, index) => (
							<Accordion
								{...faq}
								isOpen={openedIndex === index}
								setOpen={setOpen}
								index={index}
								key={faq.title}
							>
								{faq.description}
							</Accordion>
						))
					) : (
						<>
							{faqQuestions.map((item, index) =>
								index < INITIAL_MAX_SHOWED_FAQ_ITEMS_COUNT ? (
									<Accordion
										{...item}
										isOpen={openedIndex === index}
										setOpen={setOpen}
										index={index}
										key={item.title}
									>
										{item.description}
									</Accordion>
								) : null,
							)}
							{faqQuestions.length >
							INITIAL_MAX_SHOWED_FAQ_ITEMS_COUNT ? (
								<Button
									type="link"
									icon="down"
									iconPosition="after"
									text={`${TranslationService.get(
										'supportModal.step1.loadMore',
										'Visa Fler',
									)} (${faqQuestions.length -
										INITIAL_MAX_SHOWED_FAQ_ITEMS_COUNT})`}
									className="asteria-button-show-more"
									onClick={showMore}
								/>
							) : null}
						</>
					)}

					<ModalBodyStyled>
						<Button
							icon="triangleRight"
							iconPosition="after"
							text={TranslationService.get(
								'supportModal.step1.submitIssue',
								'Skapa ärende eller rapportera fel',
							)}
							className="asteria-button-create"
							onClick={goToTicket}
							type="link"
							size="medium"
						/>
					</ModalBodyStyled>
				</div>
			</ModalBody>
			<ModalFooter className="modal-footer">
				<Button
					size="medium"
					type="default"
					onClick={onEnd}
					text={TranslationService.get(
						'supportModal.step1.cancel',
						'Avbryt',
					)}
				/>
			</ModalFooter>
		</div>
	);
})``;

FAQ.Styler = {
	children: [
		{
			component: Accordion,
			base: [Getter('item')],
		},
		{
			component: Text,
			base: [Getter('text')],
		},
		{
			component: Title,
			base: [Getter('title')],
		},
		{
			component: Button,
			base: [Getter('button')],
		},
	],
};

export default FAQ;
