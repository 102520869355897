import React, { useEffect, useContext, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import classNames from 'classnames';
import Button from '@asteria/component-button/button';
import Title from '@asteria/component-typography/title';
import { Getter, compileStyles } from '@asteria/utils';
import { CSSTransition } from 'react-transition-group';
import ContentContext from './context';

const ModalHeader = styled(({ className, children }) => (
	<div className={classNames(className)}>{children}</div>
))`
	display: flex;
	flex-direction: row;
	${Title} {
		flex-grow: 1;
	}
`;

ModalHeader.Styler = {
	typePrefix: 'asteria-modal-header',
	children: [
		{
			component: Title,
			base: [Getter('title')],
		},
		{
			component: Button,
			base: [Getter('close')],
		},
	],
};

const ModalBody = styled.div`
	flex-grow: 1;
	padding: 16px;
`;

ModalBody.Styler = {
	children: [
		{
			component: Title,
			base: [Getter('title')],
		},
		{
			component: Button,
			base: [Getter('button')],
		},
	],
};

const ModalFooter = styled.div`
	display: flex;
`;

ModalFooter.Styler = {
	children: [
		{
			component: Title,
			base: [Getter('title')],
		},
		{
			component: Button,
			base: [Getter('button')],
		},
	],
};

const Modal = styled(
	({
		className,
		style,
		children,
		size,
		hasHeader = true,
		onClose = () => {},
	}) => {
		const { content: { title = 'Modal' } = {} } = useContext(
			ContentContext,
		);
		return (
			<div
				className={classNames(
					'asteria-modal',
					{
						[`asteria-modal-no-header`]: hasHeader === false,
						[`asteria-modal-${size}`]: size,
					},
					className,
				)}
				style={style}
				onClick={e => e.stopPropagation()}
				onKeyPress={() => {}}
				role="button"
				tabIndex="-1"
			>
				{React.isValidElement(title) ? (
					title
				) : (
					<ModalHeader onClose={onClose} className="modal-header">
						<Title size="title1">{title}</Title>
						<Button icon="close" type="link" onClick={onClose} />
					</ModalHeader>
				)}
				{children}
			</div>
		);
	},
)`
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	width: 50vw;
	min-height: 50vh;

	&.asteria-modal-no-header {
		${ModalHeader} {
			display: none;
		}
	}
`;

Modal.Styler = {
	typePrefix: 'asteria-modal',
	children: [
		{
			component: ModalHeader,
			base: [Getter('header')],
		},
		{
			component: ModalBody,
			base: [Getter('body')],
		},
		{
			component: ModalFooter,
			base: [Getter('footer')],
		},
	],
};

const ModalWrapper = styled(
	({
		className,
		closeOnEscape = true,
		closeOnClickOutside = true,
		onClose = () => {},
		title = 'Modal',
		open,
		portal = false,
		style,
		...props
	}) => {
		const [content, setContent] = useState({ title });
		const context = useMemo(() => ({ content, setContent }), [content]);

		useEffect(() => {
			setContent({ title });
		}, [title]);

		if (closeOnEscape) {
			useEffect(() => {
				const close = e => {
					if (e.keyCode === 27) {
						onClose();
					}
				};
				document.addEventListener('keydown', close);

				return () => {
					document.removeEventListener('keydown', close);
				};
			}, [onClose]);
		}

		if (portal) {
			const wrapper = document.getElementById('asteria-widget');

			if (!wrapper) {
				const el = document.createElement('div');
				el.setAttribute('id', 'asteria-widget');

				if (document.body.append) {
					document.body.append(el);
				} else {
					document.body.appendChild(el);
				}
			}
			return ReactDOM.createPortal(
				<ContentContext.Provider value={context}>
					<CSSTransition
						timeout={200}
						classNames="my-node"
						appear
						in={open}
						unmountOnExit
						mountOnEnter
					>
						<div
							className={classNames(className)}
							onClick={() => closeOnClickOutside && onClose()}
							onKeyPress={() => {}}
							role="button"
							tabIndex="-1"
						>
							<Modal onClose={onClose} style={style} {...props} />
						</div>
					</CSSTransition>
				</ContentContext.Provider>,
				document.getElementById('asteria-widget'),
			);
		}

		return (
			<ContentContext.Provider value={context}>
				<CSSTransition
					timeout={200}
					classNames="my-node"
					appear
					in={open}
					unmountOnExit
					mountOnEnter
				>
					<div
						className={classNames(
							className,
							'asteria-modal-window',
						)}
						onClick={() => closeOnClickOutside && onClose()}
						onKeyPress={() => {}}
						role="button"
						tabIndex="-1"
					>
						<Modal onClose={onClose} style={style} {...props} />
					</div>
				</CSSTransition>
			</ContentContext.Provider>
		);
	},
)`
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	z-index: 9999;

	${({ theme = {} }) => compileStyles(ModalWrapper, theme)}
`;

ModalWrapper.Styler = {
	base: [Getter('modal')],
	children: [
		{
			component: Modal,
			base: [Getter('dialog')],
		},
	],
};

export {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ContentContext,
	ContentContext as ModalContext,
};
export default ModalWrapper;
