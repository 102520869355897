import React, {
	useState,
	useContext,
	useEffect,
	useCallback,
	useMemo,
} from 'react';
import {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ContentContext,
} from '@asteria/component-modal';

import { sendSupport } from '@asteria/services-datalayer/services/appstate/actions';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import Title from '@asteria/component-typography/title';
import Button from '@asteria/component-button/button';
import styled from 'styled-components';
import {
	FormGroupV2,
	// Counter,
	InputV2,
	CheckboxGroupV2,
} from '@asteria/component-form';
import { TranslationService } from '@asteria/services-language';
import {
	createValidator,
	useInput,
	useIsMounted,
	Getter,
} from '@asteria/utils';
import Alert from '@asteria/component-alert';

const FormWrapper = styled.div`
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	/* margin: 0 20px; */
	padding: 16px 16px 30px;
	background-color: #faf2eb;
`;

const SUBJECT_FORM_GROUP_STYLE = { marginBottom: '15px' };
const EMAIL_FORM_GROUP_STYLE = SUBJECT_FORM_GROUP_STYLE;
const MESSAGE_FORM_GROUP_STYLE = { marginBottom: '8px' };

function invert(value) {
	return !value;
}
const ONLY_FIRST_SELECTED = [0];
const NONE_SELECTED = [];
const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const RequestHelp = styled(
	({ className, setTab = () => {}, onEnd = () => {} }) => {
		const { dispatch } = useContext(DatalayerContext);
		const { content, setContent } = useContext(ContentContext);
		const [title, handleTitleChange] = useInput('');
		const [email, handleEmailChange] = useInput('');
		const [message, handleMessageChange] = useInput('');
		const [isAgreed, setAgreementFlag] = useState(false);
		const [submission, setSubmission] = useState(false);
		const [validationErrors, setErrors] = useState({});
		const goToFaq = useCallback(() => setTab('faq'), [setTab]);
		useEffect(
			() =>
				setContent({
					...content,
					title: (
						<ModalHeader className="asteria-modal-header modal-header asteria-modal-header-with-back">
							<Button
								icon="left"
								type="link"
								onClick={goToFaq}
								className="asteria-button-back"
							/>
							<Title size="title1">
								{TranslationService.get(
									'request.help.title',
									'Nytt ärende / Rapportera fel',
								)}
							</Title>
							<Button
								icon="close"
								type="link"
								onClick={onEnd}
								className="asteria-button-close"
							/>
						</ModalHeader>
					),
				}),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[],
		);

		// const maxMessageLength = 400;

		// Create validator function and define its rules and messages
		const validate = useCallback(
			createValidator(
				{
					title: () =>
						!title.length
							? TranslationService.get(
									'request.help.form.subject.validation.required',
									'Vänligen, skriv ett ärende för meddelandet',
							  )
							: undefined,
					email: () => {
						if (!email.length) {
							return TranslationService.get(
								'request.help.form.email.validation.required',
								'Vänligen, skriv din e-postadress adress för meddelandet',
							);
						}
						if (!emailPattern.test(String(email).toLowerCase())) {
							return TranslationService.get(
								'request.help.form.email.validation.format',
								'Vänligen ange din e-postadress',
							);
						}
						return undefined;
					},
					message: () => {
						if (!message.length) {
							return TranslationService.get(
								'request.help.form.message.validation.required',
								'Vänligen, skriv ditt meddelande',
							);
						}
						// if (message.length > maxMessageLength) {
						// 	return TranslationService.get(
						// 		'request.help.form.message.validation.length',
						// 		'Your message is too long',
						// 	);
						// }
						return undefined;
					},
					agreement: () =>
						!isAgreed
							? TranslationService.get(
									'request.help.form.agreement.validation.required',
									'Please agree here',
							  )
							: undefined,
				},
				setErrors,
				validationErrors,
			),
			[setErrors, validationErrors, isAgreed, message, email, title],
		);

		const isMounted = useIsMounted();
		// Prepare a submit function here to keep it consistent with validation state
		const submitTicket = useCallback(async () => {
			if (!Object.keys(validationErrors).length) {
				// If there are no errors
				const response = await dispatch(
					sendSupport({ subject: title, message, email }),
				).toPromise();

				if (!isMounted.current) return;

				const {
					reportIssue: { ok, reportId },
				} = response.data;

				if (ok) {
					setTab('sent', { reportId });
				} else {
					setTab('sentWithError');
				}
			}
			if (!isMounted.current) return;
			setSubmission(false); // Don't forget to drop submission flag
		}, [
			dispatch,
			email,
			isMounted,
			message,
			setTab,
			title,
			validationErrors,
		]);

		// Submit form
		useEffect(() => {
			// Try to submit form just after we get a consistent state
			if (submission) {
				submitTicket();
			}
		}, [submitTicket, submission]);
		const checkboxGroupItems = useMemo(
			() => [
				TranslationService.get(
					'request.help.form.agreement.label',
					'Jag godkänner att Asteria kontaktar mig för support av Kassaflöde pä ovan nämnda e-postaddress',
				),
			],
			[],
		);
		const toggleIsAgreed = useCallback(() => setAgreementFlag(invert), []);
		const handleCreate = useCallback(async () => {
			validate(); // Check all form fields again
			setSubmission(true); // Try to submit form after we get a consistent form state
		}, [validate]);
		return (
			<div className={className}>
				<ModalBody scroll className="modal-body">
					<FormWrapper className="asteria-dialog-box">
						<FormGroupV2 style={SUBJECT_FORM_GROUP_STYLE}>
							<InputV2
								className="asteria-faq-title"
								placeholder={TranslationService.get(
									'request.help.form.subject.placeholder',
									'Ange ämne',
								)}
								onChange={handleTitleChange}
								value={title}
								invertedcolor
							/>
							{validationErrors.title ? (
								<Alert
									icon
									type="error"
									showClose={false}
									headingContent={validationErrors.title}
								/>
							) : null}
						</FormGroupV2>
						<FormGroupV2 style={EMAIL_FORM_GROUP_STYLE}>
							<InputV2
								className="asteria-faq-email"
								placeholder={TranslationService.get(
									'request.help.form.email.placeholder',
									'Skriv din e-postadress',
								)}
								onChange={handleEmailChange}
								value={email}
								invertedcolor
							/>
							{validationErrors.email ? (
								<Alert
									icon
									type="error"
									showClose={false}
									headingContent={validationErrors.email}
								/>
							) : null}
						</FormGroupV2>
						<FormGroupV2 style={MESSAGE_FORM_GROUP_STYLE}>
							<InputV2
								className="asteria-faq-message"
								elementType="textarea"
								placeholder={TranslationService.get(
									'request.help.form.message.placeholder',
									'Skriv ditt meddelande här',
								)}
								onChange={handleMessageChange}
								value={message}
								invertedcolor
							/>
							{validationErrors.message ? (
								<Alert
									icon
									type="error"
									showClose={false}
									headingContent={validationErrors.message}
								/>
							) : null}
							{/* <Counter */}
							{/*	maxLength={maxMessageLength} */}
							{/*	currentLength={message.length} */}
							{/*	customText={TranslationService.get( */}
							{/*		'request.help.form.message.counterLabel', */}
							{/*		'Antal tecken kvar:', */}
							{/*	)} */}
							{/*	style={{ color: '#72605F' }} */}
							{/*	countDown */}
							{/* /> */}
						</FormGroupV2>
					</FormWrapper>
					<FormGroupV2>
						<CheckboxGroupV2
							items={checkboxGroupItems}
							selected={
								isAgreed ? ONLY_FIRST_SELECTED : NONE_SELECTED
							}
							onChange={toggleIsAgreed}
							supressOwnState
							labelPosition="default"
						/>
						{validationErrors.agreement ? (
							<Alert
								icon
								type="error"
								showClose={false}
								headingContent={validationErrors.agreement}
							/>
						) : null}
					</FormGroupV2>
				</ModalBody>
				<ModalFooter className="modal-footer">
					<Button
						size="medium"
						type="default"
						className="asteria-button-cancel"
						text={TranslationService.get(
							'request.help.form.button.cancel',
							'Avbryt',
						)}
						onClick={onEnd}
					/>
					<Button
						size="medium"
						type="primary"
						className="asteria-button-send"
						text={TranslationService.get(
							'request.help.form.button.create',
							'Skicka',
						)}
						onClick={handleCreate}
					/>
				</ModalFooter>
			</div>
		);
	},
)``;

RequestHelp.Styler = {
	children: [
		{
			component: Button,
			base: [Getter('button')],
		},
		{
			component: CheckboxGroupV2,
			base: [Getter('checkbox')],
		},
		{
			component: InputV2,
			base: [Getter('input')],
		},
		{
			component: FormWrapper,
			base: [Getter('form')],
		},
	],
};

export default RequestHelp;
