import classNames from 'classnames';
import React from 'react';
import { StyleGetter, preProcess } from '@asteria/utils';
import styled from 'styled-components';
import AssetsService from '@asteria/services-assets';

const processColor = (color = '', prefix = 'color', status = 'PAID', theme) => {
	if (status === 'UNPAID') {
		return preProcess(`var(--system-unpaid-${prefix})`, theme);
	}

	if (status === 'OVERDUE') {
		return preProcess(`var(--system-overdue-${prefix})`, theme);
	}

	if (color.startsWith('$')) {
		// eslint-disable-next-line no-debugger
		return preProcess(
			`var(--${color.replace(
				'$',
				'system-',
			)}-${prefix}-${status.toLowerCase()}, var(--${color.replace(
				'$',
				'system-',
			)}-${prefix}, var(--system-${status.toLowerCase()}-${prefix})))`,
			theme,
		);
	}

	return color;
};
/*
#1e5799 0%,
#1e5799 51%,
#ff0000 51%,
#ff0000 78%,
#19ff00 78%,
#19ff00 100%

*/

const Prefix = styled.span`
	border-radius: 50%;
	height: 100%;
	width: 100%;
	${({ theme, status = 'PAID', color }) =>
		Array.isArray(color)
			? `
			background: linear-gradient(
				135deg,
				${color
					.map((c, index) => {
						const gradColor = processColor(
							c,
							'color',
							status,
							theme,
						);

						return `${gradColor} ${(100 / color.length) *
							index}%, ${gradColor} ${(100 / color.length) *
							(index + 1)}%`;
					})
					.join(',')}
				
			);
			`
			: `
			background-color: ${processColor(color, 'color', status, theme)};
			background-image: ${processColor(color, 'image', status, theme)};`}
`;

const defaultAssetService = new AssetsService();

const SVG = styled.svg`
	fill: #000;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
	height: 100%;
	width: 100%;
	${props => (props.styles ? props.styles : '')};
`;

const Icon = styled(
	React.forwardRef(
		(
			{
				asset,
				styles = {},
				service = defaultAssetService,
				theme = {},
				className,
				onPress,
				svg = false,
				...props
			},
			ref,
		) => {
			const {
				icon: { style: { alignSelf, ...iconStyles } = {} } = {},
				wrapper: { style: wrapperStyle = {} } = {},
			} = styles;

			let preserveAspectRatio = 'xMidYMid';

			if (alignSelf === 'flex-start') {
				preserveAspectRatio = 'xMinYMin meet';
			} else if (alignSelf === 'flex-end') {
				preserveAspectRatio = 'xMaxYMin meet';
			}

			const wrapStyle = { ...wrapperStyle };
			if (wrapStyle.width) {
				wrapStyle.flexShrink = 0;
				wrapStyle.flexGrow = 0;
			}

			if (asset === '$tag') {
				return (
					<div
						style={wrapStyle}
						className={classNames(
							'asteria-icon-wrapper',
							'asteria-icon-tag',
							className,
						)}
						ref={ref}
					>
						<Prefix {...props} />
					</div>
				);
			}

			if (asset === '$currency') {
				return (
					<div
						style={wrapStyle}
						className={classNames(
							'asteria-icon-wrapper',
							'asteria-icon-currency',
							className,
						)}
						ref={ref}
					>
						<span
							className={`currency-flag currency-flag-${props?.color?.toLowerCase()}`}
						/>
					</div>
				);
			}
			const assets = Array.isArray(asset) ? asset : [asset];
			const content = service.getMarkupData(assets, theme);

			if (svg) {
				return (
					<SVG
						ref={ref}
						className="asteria-icon-svg"
						dangerouslySetInnerHTML={content}
						styles={iconStyles}
						{...content.props}
						{...props}
						preserveAspectRatio={preserveAspectRatio}
					/>
				);
			}

			return (
				<div
					style={wrapStyle}
					className={classNames(
						'asteria-icon-wrapper',
						content?.classes?.map(a => `asteria-${a}`) || [],
						className,
					)}
					ref={ref}
				>
					<SVG
						className="asteria-icon-svg"
						dangerouslySetInnerHTML={content}
						styles={iconStyles}
						{...content.props}
						{...props}
						preserveAspectRatio={preserveAspectRatio}
					/>
				</div>
			);
		},
	),
)`
	display: flex;
	cursor: pointer;
	height: 100%;
	width: 100%;
	flex-grow: 1;
	flex-shrink: 1;
	${props => (props.styles ? props.styles : '')};
`;
Icon.displayName = 'Icon';
Icon.Styler = {
	children: [
		{
			component: SVG,
			base: [StyleGetter(`svg`, {})],
		},
	],
};

export { SVG };

export default Icon;
