export default `


.asteria-graph-area.asteria-graph-area-lines .asteria-graph-line-group {height: 117px;grid-template-rows: 117px;}
@keyframes pulseAnimation {
    0% {
        transform: scale(0.95) translate(-50%, -50%);
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
    }
    
    70% {
        transform: scale(1) translate(-50%, -50%);
        box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
    }
    100% {
        transform: scale(0.95) translate(-50%, -50%);
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
    }
}

@keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
        visibility: hidden;
        height: 0;
        overflow: hidden;
        min-height: fit-content;
        max-height: 0px;
        padding: 0;
    }
}

.asteria-range-slider {
    .asteria-range-slider__value {
        color: var(--text-color);
        font-family: Swedbank Sans;
        font-weight: 700;
        font-size: 14px;
        line-height: normal;
        min-width: 40px;
        margin: 0 0 0 8px;
    }
}

.asteria-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    &.asteria-list-currency-settings {
        margin-top: 40px;
    }
}

.asteria-prompt {
    border: 1px solid #e2dcd9;
    background-color: rgb(255,255,255);
    padding: 16px;
    margin-bottom: 16px;

    width: 460px;
    position: fixed;
    transform:translateX(-50%);
    left: calc(50% - -116px);
    z-index: 4;
    bottom: 0;
    box-shadow: 0px 2px 3px rgba(0,0,0,0.1);

    .asteria-title {
        font-size: 20px;
        color: #512b2b;
        font-family: Swedbank Headline;
        margin: 0 0 8px;
        padding: 0;
    }
    .asteria-text {
        font-size: 16px;
        color: #512b2b;
        font-family: Swedbank Sans;
        line-height: 24px;
        margin: 0 0 8px;
    }
    .asteria-actions {
        justify-content: space-between;
        display: flex;
        justify-content: flex-end;
        .asteria-button {
            .asteria-text {
                margin-bottom: 0;
            }
            &.asteria-button-link {
                .asteria-text {
                    color: #257886;
                }
            }
            &.asteria-button-primary {
                .asteria-text {
                    color: #fff;
                }
            }
        }
        .asteria-button-primary {
            margin-left: 8px;
        }
    }
    &:before {
        display: none;
    }
}

.currency-flag {
    box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
}

.asteria-cashflow-tooltip-section-inner-rest {
    font-size: 10px;
    font-family: Arial,Helvetica,sans-serif;
    color: var(--label-color);
    margin-left: 4px;
    margin-top: 5px;
}

.asteria-feature-graph-credit-line-indicator .asteria-graph-controlls-menu-tags .asteria-part-credit span {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAMCAYAAACwXJejAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACqSURBVHgBjY5NDoIwEEYHhH1DN2I3XEPd6A28gXoDjwA34AYeBTQix2hXhpXpzsSGjmWhIbapvqRNf943MwD/cKWMt5RtfU7QUIbDwWz1JI73806Ibyn82AArrRQ3oWM7zTKnNGKHSlWXJD1Y7ZyzAAiz8gg8IJq81sIpmS8ZIJbL+60Y7pYUoC6f+lGspZTvt2iUrsO+zxeyO1mlm2TGzzTdgI+KEAI/eAHlpTrLZEqSiwAAAABJRU5ErkJggg==) !important;
    background-repeat: no-repeat !important;
    background-position: 50% 50% !important;
    height: 15px !important;
}

.asteria-list-item {
    width: 100%;
    margin: 0;
    &:first-of-type {
        .asteria-list-item-inner {
            border-top: 1px solid #EAE7E4;
        }
    }
    .asteria-list-item-inner {
        min-height: 60px;
        // padding: 8px 0px 8px 0px;
        padding: 4px 0 4px 0;
        border-bottom: 1px solid #EAE7E4;
        display: flex;
    }
    .asteria-list-item-details {
        padding: 16px 0px 16px 0px;
    }
    .asteria-cell {
        display: flex;
        align-items: center;
        &.asteria-cell-risk-currency-base {
            padding-left: 86px;
            .asteria-text {
                color: #979797;
                font-family: Arial,Helvetica,sans-serif;
                font-weight: 400;
                font-size: 12px;
                margin-top: 0px;
            }
        }
        &.asteria-cell-risk-currency {
            min-width: 80px;
        }
        &.asteria-cell-risk-slider {
            padding: 0 8px;
            .asteria-range-slider {
                align-items: center;
                input {
                    cursor: pointer;
                }
            }
            .asteria-input-wrapper {
                min-height: 40px;
                height: 40px;
                background: transparent;
                border: 0px;
                max-width: 78px;
            }
        }
        &.asteria-cell-risk-min-value,
        &.asteria-cell-risk-max-value {
            max-width: 120px;
            padding: 0 8px;
            font-size: 14px;
            .asteria-input-wrapper {
                min-height: 40px;
                height: 40px;
                background: transparent;
                border: 0px;
            }
        }

    }
    .currency-flag {
        margin-right: 8px;
        // border-radius: 500px;
        margin-right: 8px;
        max-width: 16px;
        max-height: 16px;
        max-width: 16px;
        max-height: 12px;
        overflow: hidden;
        min-height: 12px;
        min-width: 16px;
        background-position: 50% 50%;
    }
}

.asteria-group-amount {
    .asteria-group-horizontal {
        .asteria-forms-dropdown-open {
            // min-width: 100px;
            .asteria-button {
                min-width: 90px;
            }
        }
        .asteria-forms-dropdown-menu {
            bottom: 2px;
        }
    }
}

.asteria-group-amount {
    .asteria-input-wrapper {
        .asteria-input {
            padding-right: 8px !important;
        }
    }
}

.asteria-button-dropdown-currencies {
    min-width: 110px;
    right: 0;
    left: auto;
    .asteria-forms-dropdown-toggle {
        .asteria-text {
            margin-left: 24px;
        }
    }
}

.asteria-timeslice-selector-wrapper {
    z-index: 3;
}

.asteria-group-horizontal {
    flex-direction: row;
    display: flex;
}

.asteria-filter-nav {
    // width: calc(100% + 380px) !important;
    width: calc(100% + 393px) !important;
    padding-top: 4px;
    .asteria-tab-navigation-item {
        max-width: none !important;
        .asteria-button-notification-icon {
            left: auto;
            right: -24px;
            height: 16px;
            width: 16px;
            top: -4px;
            .asteria-icon-svg {
                fill: #B0312E;
            }
        }
    }
    .asteria-search-examples {
        .currency-flag {
            max-height: 12px;
            max-width: 16px;
            min-width: 16px;
            min-height: 12px;
        }
    }
    .asteria-search-results {
        .asteria-search-results-item {
            &.asteria-search-results-item-currency {
                .asteria-currency-filter {
                    display: flex;
                    padding: 1px 12px 0px 12px;
                    // border-bottom: 2px solid var(--filter-header-border-color);
                    // background-color: var(--filter-header-bg-color);
                    // height: 37px;
                    align-items: center;
                    justify-content: flex-start;
                    position: relative;
                    // margin-top: -2px;
    
                    .asteria-currency-cell-currency-total {
                        padding-right: 40px;
                    }
                    
                    .asteria-currency-cell {
                        overflow: hidden;
                        white-space: nowrap;
                        display: flex;
                        .asteria-text {
                            color: #979797;
                            font-family: Arial,Helvetica,sans-serif;
                            font-weight: 400;
                            font-size: 12px;
                            margin-top: 0px;
                        }
                        &.asteria-currency-cell-exposure, &.asteria-currency-cell-currency-risk, &.asteria-currency-cell-currency-balance, &.asteria-currency-cell-currency-total {
                            .asteria-text {
                                color: #979797;
                                font-family: Arial,Helvetica,sans-serif;
                                font-weight: 400;
                                font-size: 12px;
                                margin-top: 0px;
                            }
                        }
                        &.asteria-currency-cell-currency {
                            visibility: hidden;
                            min-width: 230px;
                        }
                        &.asteria-currency-cell-currency-risk {
                            min-width: 130px;
                            flex: 0;
                        }
                        &.asteria-currency-cell-currency-total {
                            .asteria-tooltip-trigger-wrapper {
                                width: 100%;
                                .asteria-text {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
                .asteria-currency-cell {
                    flex: 1;
                    display: flex;
                    &.asteria-currency-cell-currency {
                        min-width: 138px;
                    }
                    &.asteria-currency-cell-currency {
    
                    }
                    &.asteria-currency-cell-meta {
    
                    }
                    &.asteria-currency-cell-exposure-deposit {
                        min-width: 100px;
                        max-width: 125px;
                        .asteria-label {
                            display: block;
                        }
                    }
                    &.asteria-currency-cell-exposure-withdraw {
                        min-width: 100px;
                        max-width: 105px;
                        .asteria-label {
                            display: block;
                        }
                    }
                    &.asteria-currency-cell-exposure-total {
                        min-width: 168px;
                    }
                    &.asteria-currency-cell-currency-balance {
                        .asteria-graph-pie {
                            margin-top: 4px;
                            margin-left: 8px;
                            min-width: 32px;
                        }
                    }
                    &.asteria-currency-cell-expand {
                        flex: 0;
                    }
                }
                .asteria-state-risk {
                    .asteria-icon-wrapper {
                        max-width: 20px !important;
                        max-height: 20px !important;
                        margin-right: 4px !important;
                        .asteria-icon-svg {
                            fill: #C01F26 !important;
                        }
                    }   
                }
                margin: 8px 0 !important;
                padding: 2px 16px;
                .asteria-tabs-navigation {
                    flex-direction: row;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 0px !important;
                    padding-top: 24px !important;
                    border: 1px solid #eae7e4;
                    border-bottom: 0px;
                    .asteria-accordion-item {
                        .asteria-tag-overview-bar {
                            max-width: 180px;
                            margin-right: 44px;
                        }
                    }
                    .asteria-tab-navigation-item {
                        .asteria-sum-deposit {
                            font-family: Arial,Helvetica,sans-serif;
                            font-size: 14px;
                            color: var(--text-color);
                            margin: 0;
                            padding: 0;
                            line-height: 1.4;
                            text-align: center;
                        }
                        .asteria-sum-withdraw {
                            font-family: Arial,Helvetica,sans-serif;
                            font-size: 14px;
                            color: var(--text-color);
                            margin: 0;
                            padding: 0;
                            line-height: 1.4;
                            text-align: center;
                        }
                        &:first-of-type {
                            .asteria-button-link {
                                border-radius: 4px 0px 0px 4px;
                            }
                        }
                        &:last-of-type {
                            .asteria-button-link {
                                border-radius: 0 4px 4px 0;   
                            }
                        }
                    }
                    .asteria-button-link {
                        font-weight: 600;
                        line-height: 1.2;
                        font-size: 14px;
                        max-height: 32px;
                        color: #80584f;
                        background-color: #ebe7e2;
                        border: none;
                        border-right: 1px solid #ffffff;
                        width: 100%;
                        padding: 8px 16px !important;
                        transition: background-color .3s ease-out;
                        text-align: center;
                        cursor: pointer;
                        margin-bottom: 8px;
                        position: relative;
                        &.asteria-state-active, &.asteria-state-hover, &:hover {
                            background: #fdc129;
                            color: #512b2b;
                            .asteria-text {
                                color: #512b2b !important;
                            }
                        }
                        .asteria-text {
                            text-decoration: none !important;
                            color: var(--text-color) !important;
                            font-family: "Swedbank Sans" !important;
                            font-weight: 700 !important;
                            font-size: 14px !important;
                        }
                    }
                }
                .asteria-tabs-wrapper {
                    border: 1px solid #eae7e4;
                    margin-bottom: -1px;
                    border-top: 0;
                    padding: 16px 16px 0px 16px;
                }

                margin: 0;
                .asteria-list-item {
                    border-top: 0px solid transparent;
                    width: 100%;
                    margin: 0;
                    &.asteria-state-open {
                        // background: #FCFAFA;
                    }
                    .asteria-list-item-sub {
                        padding: 8px 0;
                        .asteria-tabs-navigation {
                            border: 1px solid #eae7e4;
                            border-bottom: 0px;
                        }
                        .asteria-tabs-wrapper {
                            border: 1px solid #eae7e4;
                            margin-bottom: -1px;
                            border-top: 0;
                        }
                    }
                    .asteria-list-item-inner {
                        // padding: 8px 0px 8px 0px;
                        padding: 4px 0 4px 0;
                        // border-top: 1px solid #EAE7E4;
                        border-top: 0px solid transparent;
                        border-bottom: 0px solid transparent;
                        align-items: center;
                        .asteria-rank {
                            display: none;
                        }

                        .asteria-state-risk {
                            .asteria-icon-wrapper {
                                margin-top: 13px;
                            }
                        }
                        
                        .asteria-currency-cell-currency {
                            align-self: flex-start;
                        }

                        .asteria-currency-cell-currency-risk {
                            align-self: flex-start;
                        }

                        .asteria-currency-cell-expand {
                            align-self: center;
                        }

                        .asteria-currency-cell, .asteria-currency-cell-exposure-total  {
                            .asteria-label {
                                display: inline-flex;
                                color: #979797;
                                font-family: Arial,Helvetica,sans-serif;
                                font-weight: 400;
                                font-size: 10px;
                            }
                        }
                        .asteria-currency-cell-exposure {
                            align-self: flex-start;
                            > div {
                                margin-right: 8px;
                            }
                            .asteria-label {
                                display: block;
                            }
                        }
                        .asteria-currency-cell-risk-inner {
                            flex-direction: column;
                            display: flex;
                        }
                        .asteria-currency-cell-currency-balance {
                            align-self: flex-start;
                            .asteria-pie-segment-value {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .asteria-currency-cell-currency-balance-inner {
                            flex-direction: column;
                            display: flex;
                            align-items: flex-end;
                        }
                        .asteria-currency-cell-currency-total {
                            align-self: flex-start;
                            .asteria-label {
                                // margin-top: 3px;
                            }
                        }

                        .asteria-button {
                            margin-left: 4px;
                            margin-right: 2px;
                            .asteria-icon-wrapper {
                                margin-right: 0px !important;
                                // max-width: 12px;
                                height: 18px;
                                width: 18px;
                                .asteria-icon-svg {
                                    max-width: 16px;
                                    max-height: 8px;
                                    fill: #9f8c82 !important;
                                }
                            }
                        }
                        .asteria-overview {
                            .asteria-text {
                                border-color: #eae7e4 !important;
                                border-color: #eae7e4 !important;
                                width: auto;
                                padding: 5px 12px 6px 12px;
                                overflow: visible;
                                display: inline-flex;
                                &.asteria-state-active {
                                    background-color: #eae7e4 !important;
                                    border-color: #eae7e4 !important;
                                }
                                &:hover {
                                    background-color: #eae7e4 !important;
                                    border-color: #eae7e4 !important;
                                }
                            }
                        }
                        .asteria-prefix {
                            background: #eae7e3;
                            // margin-left: auto;
                            // margin-left: 40px;
                            height: 8px;
                            border-radius: 0;
                            position: relative;
                            // display: none;
                        }
                        .asteria-text-amount {
                            font-family: Arial,Helvetica,sans-serif;
                            font-size: 14px;
                            color: var(--text-color);
                            margin: 0;
                            font-weight: 300;
                            padding: 0;
                            line-height: 1.4;
                        }
                    }
                    .asteria-list-item-details {
                        padding: 16px 0px 16px 0px;
                        // border-top: 1px solid #EAE7E4;
                        .asteria-rank {
                            display: block;
                        }
                        .asteria-category-overview {
                            width: 450px;
                        }
                        .asteria-tag-overview-bar {
                            width: 100%;
                        }
                        .asteria-button {
                            margin-left: 4px;
                            .asteria-icon-wrapper {
                                .asteria-icon-svg {
                                    fill: #9f8c82 !important;
                                }
                            }
                        }
                    }
                    .asteria-currency-cell-exposure {
                        display: flex;
                        flex-direction: row;
                        .asteria-text {
                            color: var(--transaction-list-text-color);
                            font-family: Swedbank Sans;
                            font-weight: 700;
                            font-size: 16px;
                            span {
                                visibility: hidden;
                            }
                        }
                        .asteria-text-original {
                            color: #979797;
                            font-family: Arial,Helvetica,sans-serif;
                            font-weight: 400;
                            font-size: 12px;
                            margin-top: -4px;
                            span {
                                visibility: visible;
                            }
                        }
                    }
                    .asteria-currency-cell-risk {
                        display: flex;
                        .asteria-text {
                            color: var(--transaction-list-text-color);
                            font-family: Swedbank Sans;
                            font-weight: 700;
                            font-size: 16px; 
                        }
                    }
                    .asteria-currency-cell-currency-risk {
                        .asteria-text {
                            color: var(--transaction-list-text-color);
                            font-family: Swedbank Sans;
                            font-weight: 700;
                            font-size: 16px;
                        }
                    }
                    .asteria-currency-cell-currency-balance {
                        .asteria-text {
                            color: var(--transaction-list-text-color);
                            font-family: Swedbank Sans;
                            font-weight: 700;
                            font-size: 16px;
                        }
                    }
                    .asteria-currency-cell-currency-total {
                        .asteria-text {
                            color: var(--transaction-list-text-color);
                            font-family: Swedbank Sans;
                            font-weight: 700;
                            font-size: 16px;
                        }
                    }
                    .asteria-text-exposure {
                        display: column;
                        flex-direction: row;
                        margin-right: 8px;
                        margin-bottom: 0;
                        font-size: var(--text-small-font-size);
                        flex: 1;
                        // max-width: 70px;
                        // color: #437A88 !important;
                        // color: var(--text-color) !important;
                        // &.asteria-state-zero {
                            // color: #83645D !important;
                            // color: var(--text-color) !important;
                        // }
                        // &.asteria-text-withdraw, &.asteria-state-negative {
                            // color: #B8332E !important;
                            // color: var(--text-color) !important;
                        // }
                        // &.asteria-text-deposit {
                            // color: #437A88 !important;
                            // color: var(--text-color) !important;
                        // }
                        // span {
                            // display: flex;
                            // align-items: center;
                            // margin-right: 16px;
                            // color: #83645D !important;
                        // }
                    }
                    .asteria-currency-cell-currency-total {
                        flex-direction: column;
                        align-items: flex-end;
                        .asteria-text {
                            color: var(--transaction-list-text-color);
                            font-family: Swedbank Sans;
                            font-weight: 700;
                            font-size: 16px;
                            span {
                                visibility: hidden;
                                display: none;
                            }
                        }
                        .asteria-text-original {
                            color: #979797;
                            font-family: Arial,Helvetica,sans-serif;
                            font-weight: 400;
                            font-size: 12px;
                            margin-top: -4px;
                            span {
                                visibility: visible;
                                display: inline-flex;
                                width: auto;
                                height: auto;
                            }
                        }
                        .asteria-no-currency-account {
                            font-size: 12px !important;
                            text-align: left;
                            width: 100%;
                            padding: 0 4px 0 0;
                        }
                    }
                    .currency-flag {
                        margin-right: 8px;
                        // border-radius: 500px;
                        margin-right: 8px;
                        max-width: 16px;
                        max-height: 16px;
                        max-width: 16px;
                        max-height: 12px;
                        overflow: hidden;
                        min-height: 12px;
                        min-width: 16px;
                        background-position: 50% 50%;
                    }
                    .asteria-tag-type-tag {
                        padding: 4px 0px;
                    }
                    .asteria-button {
                    }
                }
            }
        }
    }
}

.asteria-notifications {
    .asteria-button {
        .asteria-text {
            color: #512b2b !important;
        }
        &.asteria-state-active {
            .asteria-text {
                color: #df7b3f !important;
            }
        }
    }
}

.asteria-graph-line-graph-component .asteria-scroll-horizontal {
    -webkit-mask: none;
    overflow: visible;
}

.asteria-graph-line-graph-legends .asteria-part-risk,
.asteria-graph-line-graph-component .asteria-part-risk {
    span {
        // margin-top: 3px !important;
        // height: 4px !important;
        // width: 4px !important;
        // background: #ee7023 !important;
        // margin-left: 8px !important;
        // margin-right: 10px !important;

        // background: #ee7023 !important;

        height: 8px !important;
        width: 8px !important;
        margin-top: 8px !important;
        margin-left: 8px !important;
        margin-right: 10px !important;
        position: relative;

        min-height: 10px;
        min-width: 10px;
        margin-top: 10px !important;

        &:after {
            content: "";
            height: 5px;
            width: 5px;
            background: #fff;
            position: absolute;
            left: 50%;
            top: 50%;
            transform:translate(-50%,-50%);
            border-radius: 50%;
        }
    }
    .asteria-text {
        margin-top: -2px;
        margin-left: -7px;
    }
}

.asteria-graph-area-lines {
    .asteria-graph-line-group {
        &.asteria-graph-line-group-dot {
            &.asteria-state-active {
                div[role='button'] {
                    div {
                        background-color: #fdc129 !important;
                    }
                    &.asteria-graph-line-info-risk {
                        div {
                            background-color: rgb(255,182,0) !important;
                            animation: none !important;
                        }
                    }
                }
            }
            &.asteria-state-hover {
                div[role='button'] {
                    div {
                        background-color: #fdc129 !important;
                    }
                    &.asteria-graph-line-info-risk {
                        div {
                            background-color: rgb(255,182,0) !important;
                        }
                    }
                }
            }
            div[role='button'] {
                &.asteria-graph-line-info-risk {
                    div {
                        // background-color: #ee7023 !important;
                        // background-color: #c01f26 !important;
                        background-color: #fff !important;
                    }
                }
            }
        }
    }
}

.asteria-cashflow-graph-hide-risk {
    .asteria-graph-area-lines {
        .asteria-graph-line-group {
            &.asteria-graph-line-group-dot {
                .asteria-graph-line-info-risk {
                    div {
                        animation: none !important;
                    }
                }
            }
        }
    }
}

.currency-flag {
    margin-right: 8px;
    // border-radius: 500px;
    margin-right: 8px;
    max-width: 16px;
    max-height: 16px;
    max-width: 16px;
    max-height: 12px;
    overflow: hidden;
    min-height: 16px;
    min-width: 16px;
    background-position: 50% 50%;
}

// .asteria-controller-search {
//     .asteria-prompt {
//         width: calc(100% + 359px);
//     }
// }

div[role='button'] {
    &:hover {
        .asteria-chip {
            border: 1px solid transparent !important;
        }
    }
}

.asteria-controller-search-active-filters {
    div[role='button'] {
        &.asteria-tag-type-currency {
            &:hover {
                .asteria-chip {
                    border: 1px solid transparent !important;
                }
            }
            span {
                display: none;
            }
            .currency-flag {
                margin-right: 8px;
                // border-radius: 500px;
                margin-right: 8px;
                max-width: 16px;
                max-height: 12px;
                overflow: hidden;
                min-height: 12px;
                min-width: 16px;
                background-position: 50% 50%;
            }
        }
    }
}

.asteria-risk-message {
    padding-top: 5px;
}

// .asteria-transaction-filter {
//     .asteria-transaction-cell-description {
//         width: 100%;
//         max-width: 291px;
//         min-width: 291px;
//     }
//     .asteria-transaction-cell-categories {
//         max-width: 170px;   
//     }
//     .asteria-transaction-cell-flat-status {
//         max-width: 120px;
//     }
//     .asteria-transaction-cell-flat-status {
//         max-width: 120px;
//     }
//     .asteria-transaction-cell-risk-high {
//         max-width: 160px;
//         width: 100%;
//     }
//     .asteria-transaction-cell-risk-low {
//         max-width: 160px;
//         width: 100%;
//     }
// }

.asteria-transactions-list-wrapper {
    .asteria-transaction-cell {
        &.asteria-transaction-cell-risk {
            width: 129px !important;
            .asteria-text {
                max-width: 100% !important;
            }
            > div {
                margin-left: auto !important;
                padding-right: 52px;
            }
        }
    }
}

.asteria-transactions-list-wrapper {
    .asteria-transaction-cell {
        &.asteria-transaction-cell-options {
            width: 24px;
            .asteria-button {
                height: 20px;
                width: 20px;
                padding: 2px;
                background: transparent;
                border-bottom: 0px solid transparent;
                .asteria-icon-wrapper {
                    height: 100%;
                    .asteria-icon-svg {
                        fill: #9f8b82;
                    }
                }
            }
            .asteria-forms-dropdown-menu {
                min-width: 220px;
                left: auto;
                right: 0;
            }
            .asteria-adjustable-total-cancel {
                display: none;
            }
            .asteria-adjustable-total {
                margin-left: 0;
            }
        }
        &.asteria-transaction-cell-currency {
            width: 7% !important;
            > div {
                margin-left: 0 !important;
                display: flex;
                padding-top: 1px;
                .currency-flag {
                    margin-right: 8px;
                    margin-right: 8px;
                    max-width: 16px;
                    max-height: 16px;
                    max-width: 16px;
                    max-height: 12px;
                    overflow: hidden;
                    min-height: 12px;
                    min-width: 16px;
                    background-position: 50% 50%;
                    margin-top: 3px;
                    margin-left: auto !important;
                }
                .asteria-text {
                    font-size: 14px !important;
                }
            }
            .asteria-button {
                .asteria-text {
                    max-width: 100% !important;
                }
            }
        }
    }
    .asteria-sidepane-body {
        .asteria-transaction-cell {
            &.asteria-transaction-cell-currency {
                width: auto !important;
                > div {
                    margin-left: auto !important;
    
                }
            }
        }
    }
}

.asteria-modal-overdraft-confirm {
    .asteria-modal-body {
        .asteria-lead {
            font-weight: 700;
            font-family: 'Swedbank Headline';
            font-size: 20px;
            text-align: left !important;
            margin-left: 8px;
            color: var(--alert-text-color);
        }
        .asteria-body {
            padding: 0 80px 24px;
            .asteria-title-1 {
                font-size: 24px;
                line-height: 44px;
                margin-bottom: 8px;
                font-size: 36px;
                color: rgb(72,140,26);
                text-align: center;
                font-family: 'Swedbank Sans';
                font-weight: 700;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0px 16px !important;
            }
            .asteria-title-2 {
                text-align: center;
                font-family: 'Swedbank Sans';
                font-weight: 700;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0px 16px !important;
                margin-top: 0px;
                margin-bottom: 8px;
                font-size: 14px;
            }
            .asteria-text {
                text-align: center;
                font-size: 14px;
            }
        }
    }
}

.asteria-sidepane-overdraft-application {
    .asteria-sidepane-body-inner {
        padding: 16px;

        .asteria-text {
            margin-bottom: 16px;
            &:nth-of-type(1) {
                // font-weight: 800 !important;
                margin-bottom: 16px;
            }
            &:nth-of-type(4) {
                margin-left: auto;
                margin-bottom: 0;
            }
        }
        .asteria-input-wrapper {
            max-width: 85%; 
            margin: 4px 0;
            .asteria-input {
                text-align: right;
                padding-right: 24px;
            }
        }
    }
}

.asteria-transaction-list-compress {
    .asteria-transactions-list-wrapper {
        .asteria-transaction-cell {
            &.asteria-transaction-cell-currency {
                > div {
                    margin-left: 0 !important;
                    display: flex;
                    padding-top: 1px;
                    .currency-flag {
                        margin-right: 8px;
                        margin-right: 8px;
                        max-width: 16px;
                        max-height: 16px;
                        max-width: 16px;
                        max-height: 12px;
                        overflow: hidden;
                        min-height: 12px;
                        min-width: 16px;
                        background-position: 50% 50%;
                        margin-top: 2px;
                        margin-left: auto !important;
                    }
                    .asteria-text {
                        font-size: 13px !important;
                    }
                }
            }
        }
        .asteria-sidepane-body {
            .asteria-transaction-cell {
                &.asteria-transaction-cell-currency {
                    width: auto !important;
                    > div {
                        margin-left: auto !important;
                        .asteria-text {
                            font-size: 12px !important;
                        }
                        .asteria-text-smaller {
                            font-size: 12px !important;
                            color: rgb(151, 151, 151);
                        }
                    }
                }
            }
        }
    }
}

// .asteria-transactions-list-wrapper {
//     .asteria-transaction-cell-description {
//         width: 100%;
//         min-width: 291px;
//         max-width: 291px;
//     }
//     .asteria-transaction-cell-categories {
//         max-width: 170px;
//     }
//     .asteria-transaction-cell-flat-status {
//         max-width: 120px;
//     }
//     .asteria-transaction-cell-risk-high {
//         max-width: 160px;
//         width: 100%;
//     }
//     .asteria-transaction-cell-risk-low {
//         max-width: 160px;
//         width: 100%;
//     }
// }

.asteria-transactions-header {

    flex-wrap: unset !important;

    .asteria-button {
        .asteria-text {
            width: max-content;
        }
    }


    .asteria-button-transactions-header {
        min-width: 0;
        justify-content: flex-start;
        .asteria-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: auto;
            display: block !important;
        }
    }

    .asteria-tooltip-trigger-wrapper {
        white-space: nowrap;
    }

    .asteria-text-transactions-header-exposure {
        margin-right: 24px;
        margin-top: -5px;
        display: flex;
        flex-direction: column;
        span {
            align-items: center;
            text-align: left;
            color: #ACACAC !important;
            font-weight: 600;
            font-family: var(--title-font-family) !important;
            font-size: 10px !important;
        }
    }

    .asteria-text-transactions-header-currency-risk {
        margin-right: 20px;
        margin-top: -5px;
        display: flex;
        flex-direction: column;
        span {
            align-items: center;
            color: #ACACAC !important;
            font-weight: 600;
            font-family: var(--title-font-family) !important;
            font-size: 10px !important;
            text-align: left;
        }
    }

    .asteria-button-transactions-currency-show {
        margin-right: 16px;
        margin-left: 0px;
        max-width: 32px;
        .asteria-icon-wrapper {
            max-height: 12px;
            max-width: 12px;
            .asteria-icon-svg {
                fill: rgb(159,140,130) !important;
            }
        }
    }
}

.asteria-list-divider-risk-tooltip-multiple {
    .asteria-text {
        // min-width: 260px;
        .asteria-text {
            // min-width: 0;
            display: none;
        }
    }
    .asteria-transactions-total-tooltip-currency-multiple {
        display: flex;
        flex-direction: column;
        .asteria-text {
            display: block;
            // text-align: left;
            &.asteria-transactions-total-tooltip-currency-label {
                margin-bottom: 3px;
                font-size: 14px;
                text-transform: none;
                text-align: center;
                line-height: 18px;
                font-family: 'Swedbank Headline',sans-serif;
                color: var(--text-color);
            }
            &.asteria-transactions-total-tooltip-currency-value {
                // text-align: right;
                // margin-left: auto;
                margin: 0 0 8px;
            }
        }
    }
    .asteria-transactions-total-tooltip-currency {
        .asteria-text {
            display: block;
        }
    }
}

.asteria-transactions-list-wrapper {

    .asteria-transaction-detail-inner {
        .asteria-transaction-cell-currency {
            .asteria-currency-convert {
                margin-left: auto;
                display: flex;
                flex-direction: column;
                .asteria-currency-org {
                    display: flex;
                    flex-direction: row;
                    .currency-flag {
                        margin-right: 8px;
                        margin-right: 8px;
                        max-width: 16px;
                        max-height: 16px;
                        max-width: 16px;
                        max-height: 12px;
                        overflow: hidden;
                        min-height: 12px;
                        min-width: 16px;
                        background-position: 50% 50%;
                        margin-top: 3px;
                        margin-left: auto !important;
                    }
                    .asteria-text {
                        margin-left: 0 !important;
                    }
                }
                .asteria-text-smaller {
                    font-size: 10px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .asteria-button-info {
            margin-left: auto;
            .asteria-icon-wrapper {
                .asteria-icon-svg {
                    fill: #ee7024 !important;
                }
            }
        }
        .asteria-transaction-cell-risk-high {
            width: auto !important;
            flex-direction: row !important;
            .asteria-text {
                color: #C6232F !important;
            }
        }
        .asteria-transaction-cell-risk-low {
            width: auto !important;
            flex-direction: row !important;
        }
    }

}

.asteria-button-risk-help {
    width: auto;
    margin: 8px 0 0;
    align-self: flex-start;
    padding-left: 0;
}

.asteria-transactions-divider {
    .asteria-transactions-divider-row {
        align-items: center;
    }
    .asteria-currency-info {
        margin: 0 -16px;
    }    
}

.asteria-currency-info {
    padding: 24px;
    background: #FCFAFA;
    ul {
        display: flex;
        flex-direction: row;
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
            flex-direction: column;
            display: flex;
            .asteria-currency-summary {
                .currency-flag {
                    margin-right: 8px;
                    // border-radius: 500px;
                    margin-right: 8px;
                    max-width: 16px;
                    max-height: 16px;
                    max-width: 16px;
                    max-height: 12px;
                    overflow: hidden;
                    min-height: 12px;
                    min-width: 16px;
                    background-position: 50% 50%;
                }
                flex-direction: row;
                display: flex;
                align-items: center;
                border-color: #eae7e4 !important;
                display: inline-flex;
                margin: 0 auto 16px;
                padding: 5px 12px 6px 12px;
                width: max-content;
                border-radius: 50px;
                align-items: center;
                cursor: pointer;
                max-width: 100%;
                color: var(--text-color);
                border: 1px solid transparent;
                background: transparent;
                p {
                    font-family: Swedbank Sans;
                    font-weight: 700;
                    font-size: 14px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 0;
                    padding: 0;
                }
                &.asteria-state-active, &:hover {
                    background-color: #eae7e4 !important;
                    border-color: #eae7e4 !important;
                }
            }
            .asteria-currency-meta {
                flex-direction: column;
                display: flex;
            }
            p {
                font-family: Swedbank Sans;
                font-weight: 700;
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                color: #512b2b !important;
                display: flex;
                justify-content: flex-end;
                &.small {
                    font-size: 12px;
                    font-weight: 300;
                    font-family: Arial,Helvetica,sans-serif;
                    padding: 4px 0;
                    margin: 0;
                    text-align: right;
                }
                span {
                    color: #83645D !important;
                    display: inline-block;
                    min-width: 100px;
                    text-align: left;
                    margin-right: auto;
                }
                &.asteria-currency-exposure {

                }
                &.asteria-currency-risk-procentage {

                }
                &.asteria-currency-deposit {
                    color: #437A88 !important;
                }
                &.asteria-currency-withdraw {
                    color: #B8332E !important
                }
                &.asteria-state-negative {
                    color: #B8332E !important
                }
                &.asteria-currency-total {
                    
                }
            }
        }
    }
}

.asteria-list-content {
    .asteria-currency-overview {
        padding: 0 0 16px;
    }
}

.asteria-transactions-divider {
    .asteria-amount-exposure {
        margin-right: 24px;
        margin-top: 2px;
        display: flex;
        flex-direction: column;
        color: #ACACAC !important;
        font-weight: 300;
        font-size: 12px;
        span {
            align-items: center;
            color: #ACACAC !important;
            font-weight: 600;
            font-family: var(--title-font-family) !important;
            font-size: 10px !important;
        }
    }
    .asteria-amount-risk {
        &.asteria-text-body {
            margin-right: 20px !important;    
        }
        margin-left: 16px;
        margin-right: 8px !important;
        margin-top: 2px;
        display: flex;
        flex-direction: column;
        color: #ACACAC !important;
        font-weight: 300;
        font-size: 12px;
        span {
            align-items: center;
            color: #ACACAC !important;
            font-weight: 600;
            font-family: var(--title-font-family) !important;
            font-size: 10px !important;
        }
    }
    .asteria-currency-show {
        margin-right: 16px;
        margin-right: -10px;
        margin-left: -2px;
        .asteria-icon-wrapper {
            max-height: 12px;
            max-width: 12px;
            .asteria-icon-svg {
                fill: rgb(159,140,130) !important;
            }
        }
    }
    .asteria-amount-divider {
        color: #4C5362 !important;
        font-weight: 300;
        font-size: 12px;
    }
}

.asteria-tab {
    &.asteria-tab-currency {
        padding: 0 0 40px;
        background: #fff;
        .asteria-title-currency {
            display: none;
        }
        .asteria-overview {
            width: 100%;
        }
        .asteria-loading {
            margin: 32px auto 16px;
        }
        padding: 0 12px 40px;

        .asteria-button-info {
            margin-bottom: 16px !important;
            margin-left: auto !important;
            // margin-top: 16px !important;
            // margin-top: -62px !important;
            margin-top: -62px !important;

            .asteria-icon-wrapper {
                max-width: 20px !important;
                max-height: 20px !important;
                margin-right: 0px !important;
                .asteria-icon-svg {
                    fill: #ee7024 !important;
                }
            }
        }
        
        .asteria-state-risk {
            .asteria-icon-wrapper {
                max-width: 20px !important;
                max-height: 20px !important;
                margin-right: 8px !important;
                .asteria-icon-svg {
                    fill: #C01F26 !important;
                }
            }   
        }

        .asteria-currency-filter {
            display: flex;
            padding: 1px 12px 0px 12px;
            border-bottom: 2px solid var(--filter-header-border-color);
            background-color: var(--filter-header-bg-color);
            height: 37px;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            margin-top: 42px;

            .asteria-currency-cell-currency-total {
                padding-right: 40px;
            }
            
            .asteria-currency-cell {
                overflow: hidden;
                white-space: nowrap;
                display: flex;
                .asteria-text {
                    font-weight: 700;
                    color: var(--filter-header-text-color);
                }
            }
        }

        .asteria-currency-cell {
            display: flex;
            flex: unset;
            margin: 0;
            &.asteria-currency-cell-expand {
                flex: 0;
            }
        }

        .asteria-currency-cell-currency {
            flex-grow: 0;
            flex-shrink: 0;
            width: 150px;
        }
        .asteria-currency-cell-exposure {
            min-width: 212px;
            flex: 1;

            .asteria-currency-cell-exposure-deposit {
                min-width: 100px;
                max-width: 105px;
            }
            .asteria-currency-cell-exposure-withdraw {
                min-width: 100px;
                max-width: 105px;
            }
        }
        .asteria-currency-cell-exposure-total {
            width: 168px;
        }
        .asteria-currency-cell-currency-risk {
            width: 100px;
        }
        .asteria-currency-cell-currency-balance {
            justify-content: flex-end;
            width: 150px;
            align-items: center;
            .asteria-graph-pie {
                margin-left: 8px;
                min-width: 32px;
            }
        }
        .asteria-currency-cell-currency-total {
            justify-content: flex-end;
            width: 150px;
        }
        .asteria-currency-cell-expand {
            margin-left: auto;
            padding-left: 16px;
        }

        .asteria-exposure-header {
            display: flex;
            flex-direction: row;
            margin-bottom: 0px;
            margin-top: 16px;
            .asteria-title-exposure {
                margin: 0 auto;
                font-size: 16px !important;
                justify-content: center;
                align-items: center;
                display: flex;
                color: #000000 !important;
                font-family: Swedbank Headline !important;
                font-weight: 200;
                // white-space: nowrap;
                line-height: 22px;
                overflow: visible;
                text-overflow: ellipsis;
                // max-width: 120px;
                flex-direction: column;
                span {
                    flex-direction: column;
                    text-align: center;
                    font-size: 20px !important;
                    margin-top: 0px !important;
                    margin-bottom: 0px !important;
                    color: #f35c1b !important;
                    font-family: Swedbank Headline !important;
                    font-weight: 200;
                    padding-left: 0px !important;
                    margin-left: 0px !important;
                    // flex-grow: 1;
                    display: flex;
                }
            }
            .asteria-title-risk {
                margin: 0 auto;
                font-size: 16px !important;
                justify-content: center;
                align-items: center;
                display: flex;
                color: #000000 !important;
                font-family: Swedbank Headline !important;
                font-weight: 200;
                // white-space: nowrap;
                overflow: visible;
                text-overflow: ellipsis;
                // max-width: 120px;
                line-height: 22px;
                flex-direction: column;
                span {
                    flex-direction: column;
                    text-align: center;
                    font-size: 20px !important;
                    margin-top: 0px !important;
                    margin-bottom: 0px !important;
                    color: #f35c1b !important;
                    font-family: Swedbank Headline !important;
                    font-weight: 200;
                    padding-left: 0px !important;
                    margin-left: 0px !important;
                    // flex-grow: 1;
                    display: flex;
                }
            }
        }

        .asteria-overview {

        }
        .asteria-currency-cell-currency {
            flex-direction: column;
            align-items: flex-start;
            flex-grow: 0;
            .asteria-text-account-number {
                color: #979797;
                font-family: Arial,Helvetica,sans-serif;
                font-weight: 400;
                font-size: 10px;
                margin-top: 4px;
                border-radius: 0;
                background: transparent;
                border: 0px solid transparent;
                padding: 0;
                // margin: 0;
                &:hover, &.asteria-state-active {
                    border: 0px solid transparent !important;
                    background-color: transparent !important;
                }
            }
        }
        .asteria-text-exposure, .asteria-text-risk {

        }
        .asteria-list-wrapper {
            .asteria-list {
                .asteria-currency-filter {
                    display: none;
                }
                display: flex !important;
                border-bottom: 1px solid #EAE7E4;
                .asteria-list-item {
                    margin: 0;
                    &.asteria-state-open {
                        background: #FCFAFA;
                    }
                    .asteria-list-item-sub {
                        padding: 8px;
                    }
                    .asteria-list-item-inner {
                        .asteria-currency-compare {
                            background-color: #eae7e3;
                            .asteria-prefix {
                                background: #999999 !important;
                            }
                        }
                        .asteria-currency-cell, .asteria-currency-cell-exposure-total {
                            .asteria-label {
                                display: none;
                            }
                        }
                        padding: 12px 12px 12px 12px;
                        // border-top: 1px solid #EAE7E4;
                        min-height: 50px;
                        .asteria-rank {
                            display: none;
                        }
                        .asteria-button {
                            .asteria-icon-wrapper {
                                margin-right: 0px !important;
                                .asteria-icon-svg {
                                    fill: #9f8c82 !important
                                }
                            }
                        }
                        .asteria-overview {
                            .asteria-text {
                                border-color: #eae7e4 !important;
                                &.asteria-state-active {
                                    // background-color: #eae7e4 !important;
                                    // border-color: #eae7e4 !important;
                                }
                                &:hover {
                                    // background-color: #eae7e4 !important;
                                    // border-color: #eae7e4 !important;
                                }
                            }
                        }
                        .asteria-prefix {
                            background: #eae7e3;
                            // margin-left: auto;
                            // margin-left: 40px;
                            height: 12px;
                            height: 8px;
                            border-radius: 0;
                            position: relative;
                        }
                        .asteria-text-amount {
                            font-family: Arial,Helvetica,sans-serif;
                            font-size: 14px;
                            color: var(--text-color);
                            margin: 0;
                            padding: 0;
                            line-height: 1.4;
                        }
                    }
                    .asteria-tabs-navigation {
                        flex-direction: row;
                        display: flex;
                        justify-content: center;
                        margin-bottom: 0px !important;
                        padding-top: 24px !important;
                        // border: 1px solid #eae7e4;
                        border-bottom: 0px;
                        padding: 0;
                        margin: 0 !important;
                        .asteria-tab-navigation-item {
                            .asteria-sum-deposit {
                                font-family: Arial,Helvetica,sans-serif;
                                font-size: 14px;
                                color: var(--text-color);
                                margin: 0;
                                padding: 0;
                                line-height: 1.4;
                                font-weight: 700;
                                text-align: center;
                            }
                            .asteria-sum-withdraw {
                                font-family: Arial,Helvetica,sans-serif;
                                font-size: 14px;
                                font-weight: 700;
                                color: var(--text-color);
                                margin: 0;
                                padding: 0;
                                line-height: 1.4;
                                text-align: center;
                            }
                            &:first-of-type {
                                .asteria-button-link {
                                    border-radius: 4px 0px 0px 4px;
                                }
                            }
                            &:last-of-type {
                                .asteria-button-link {
                                    border-radius: 0 4px 4px 0;   
                                }
                            }
                        }
                        .asteria-button-link {
                            font-weight: 600;
                            line-height: 1.2;
                            font-size: 14px;
                            max-height: 32px;
                            color: #80584f;
                            background-color: #ebe7e2;
                            border: none;
                            border-right: 1px solid #ffffff;
                            width: 100%;
                            padding: 8px 16px !important;
                            transition: background-color .3s ease-out;
                            text-align: center;
                            cursor: pointer;
                            margin-bottom: 8px;
                            position: relative;
                            &.asteria-state-active, &.asteria-state-hover, &:hover {
                                background: #fdc129;
                                color: #512b2b;
                                .asteria-text {
                                    color: #512b2b !important;
                                }
                            }
                            .asteria-text {
                                text-decoration: none !important;
                                color: var(--text-color) !important;
                                font-family: "Swedbank Sans" !important;
                                font-weight: 700 !important;
                                font-size: 14px !important;
                            }
                        }
                    }
                    .asteria-tabs-wrapper {
                        // border: 1px solid #eae7e4;
                        margin-bottom: -1px;
                        border-top: 0;
                    }
                    .asteria-list-item-details {
                        padding: 16px 0px 40px 0;
                        border-top: 0px solid #EAE7E4;
                        .asteria-rank {
                            display: block;
                        }
                        .asteria-category-overview {
                            width: 450px;
                        }
                        .asteria-tag-overview-bar {
                            width: 100%;
                        }
                        .asteria-button {
                            .asteria-icon-wrapper {
                                .asteria-icon-svg {
                                    fill: #9f8c82 !important;
                                }
                            }
                        }
                        .asteria-accordion-item {
                            .asteria-category-overview {
                                width: 422px;
                            }
                            .asteria-tag-overview-bar {

                            }
                        }
                    }
                    .asteria-currency-cell-exposure {
                        display: flex;
                        flex-direction: row;
                        .asteria-text {
                            color: var(--transaction-list-text-color);
                            font-family: Swedbank Sans;
                            font-weight: 700;
                            font-size: 16px;
                            span {
                                visibility: hidden;
                            }
                        }
                        .asteria-text-original {
                            color: #979797;
                            font-family: Arial,Helvetica,sans-serif;
                            font-weight: 400;
                            font-size: 12px;
                            margin-top: -4px;
                            span {
                                visibility: visible;
                            }
                        }
                    }
                    .asteria-currency-cell-risk {
                        display: flex;
                        .asteria-text {
                            color: var(--transaction-list-text-color);
                            font-family: Swedbank Sans;
                            font-weight: 700;
                            font-size: 16px; 
                        }
                    }
                    .asteria-currency-cell-currency-risk {
                        .asteria-text {
                            color: var(--transaction-list-text-color);
                            font-family: Swedbank Sans;
                            font-weight: 700;
                            font-size: 16px;
                        }
                    }
                    .asteria-currency-cell-currency-balance {
                        .asteria-text {
                            color: var(--transaction-list-text-color);
                            font-family: Swedbank Sans;
                            font-weight: 700;
                            font-size: 16px;
                        }
                    }
                    .asteria-currency-cell-currency-total {
                        flex-direction: column;
                        align-items: flex-end;
                        .asteria-text {
                            color: var(--transaction-list-text-color);
                            font-family: Swedbank Sans;
                            font-weight: 700;
                            font-size: 16px;
                            span {
                                visibility: hidden;
                                display: none;
                            }
                        }
                        .asteria-text-original {
                            color: #979797;
                            font-family: Arial,Helvetica,sans-serif;
                            font-weight: 400;
                            font-size: 12px;
                            margin-top: -4px;
                            span {
                                visibility: visible;
                                display: inline-flex;
                            }
                        }
                    }
                    .asteria-text-exposure {
                        display: column;
                        flex-direction: row;
                        margin-right: 8px;
                        margin-bottom: 0;
                        font-size: var(--text-small-font-size);
                        flex: 1;
                        // max-width: 70px;
                        // color: #437A88 !important;
                        // color: var(--text-color) !important;
                        // &.asteria-state-zero {
                            // color: #83645D !important;
                            // color: var(--text-color) !important;
                        // }
                        // &.asteria-text-withdraw, &.asteria-state-negative {
                            // color: #B8332E !important;
                            // color: var(--text-color) !important;
                        // }
                        // &.asteria-text-deposit {
                            // color: #437A88 !important;
                            // color: var(--text-color) !important;
                        // }
                        // span {
                            // display: flex;
                            // align-items: center;
                            // margin-right: 16px;
                            // color: #83645D !important;
                        // }
                    }
                    // .asteria-text-risk {
                    //     display: column;
                    //     flex-direction: row;
                    //     margin-right: 8px;
                    //     margin-bottom: 0;
                    //     font-size: var(--text-small-font-size);
                    //     flex: 1;
                        // max-width: 70px;
                        // color: #000000 !important;
                        // color: var(--text-color) !important;
                        // span {
                            // display: flex;
                            // align-items: center;
                            // margin-right: 16px;
                            // color: #83645D !important;
                        // }
                    // }
                    .currency-flag {
                        margin-right: 8px;
                        // border-radius: 500px;
                        margin-right: 8px;
                        max-width: 16px;
                        max-height: 16px;
                        max-width: 16px;
                        max-height: 12px;
                        overflow: hidden;
                        min-height: 12px;
                        min-width: 16px;
                        background-position: 50% 50%;
                    }
                    .asteria-tag-type-tag {
                        padding: 4px 0px;
                    }
                    .asteria-button {
                    }
                }
            }
        }
    }
}

.asteria-modal-window {
    align-items: flex-start;
    .asteria-modal {
        max-height: none;
        transform: translateY(30px);
        margin-bottom: 80px;
    }
    overflow-y: auto;
    .modal-body {
        padding: 32px 32px 20px;
    }
    .modal-footer {
        background: #fff;
    }
    &.asteria-modal-erp-missing {
        .asteria-modal {
            max-width: 450px;
            width: 450px;
            height: 460px;
        }
    }
}

.asteria-no-currency-account {
    p {
        color: #979797 !important;
        font-family: Arial,Helvetica,sans-serif !important;
        font-weight: 400 !important;
        font-size: 12px !important;
    }
}

.asteria-notification-item-body {
    .currency-flag {
        margin-right: 0px;
        border-radius: 0;
        margin-right: 0px;
        max-width: 14px;
        max-height: 10px;
        overflow: hidden;
        min-height: 8px;
        min-width: 12px;
        background-position: 50% 50%;
        display: none;
    }  
}


.asteria-tooltip {
    &.asteria-tooltip-close-to-top {
        .asteria-tooltip-wrapper {
            .asteria-tooltip-pointer {
                transform: rotate(180deg) !important;
                top: -8px;
            }
            // .currency-flag {
            //     transform: rotate(360deg) !important;
            // }
        }
    }
    .currency-flag {
        margin-right: 8px;
        margin-right: 8px;
        border-radius: 0;
        margin-right: 8px;
        max-width: 14px;
        max-height: 10px;
        overflow: hidden;
        min-height: 8px;
        min-width: 12px;
        background-position: 50% 50%;
    }
    .asteria-cashflow-tooltip-row-subtext {
        .asteria-text {
            font-family: Arial, Helvetica, sans-serif;
            color: var(--label-color);
            font-size: 12px;
            margin-top: -8px;
            font-weight: 400;
        }
    }
    .asteria-cashflow-tooltip-row {
        margin: 6px 0;
    }
    .asteria-cashflow-tooltip-row-currency-risk-account {
        .currency-flag {
            margin-right: 8px;
            border-radius: 0;
            max-width: 14px;
            max-height: 10px;
            overflow: hidden;
            min-height: 8px;
            min-width: 12px;
        }
    }
    .asteria-cashflow-tooltip-row-currencies-risk {
        span {
            margin-top: 3px !important;
            height: 4px !important;
            width: 4px !important;
            background: #ee7023 !important;
            margin-left: 6px !important;
            margin-right: 10px !important;
            animation: pulseAnimation 2s infinite;
        }
    }
    .asteria-cashflow-tooltip-row-currency-risk {
        .currency-flag {
            margin-right: 8px !important;
            border-radius: 0;
            max-width: 12px;
            max-height: 8px;
            overflow: hidden;
            min-height: 8px;
            min-width: 12px;
        }
    }
    .asteria-cashflow-tooltip-section {
        .asteria-cashflow-tooltip-row-subtext {
            .asteria-text {
                text-align: center;
                margin-right: auto;
                margin-left: auto;
            }
        }
        .asteria-cashflow-tooltip-section-inner {
        .asteria-cashflow-tooltip-row-subtext {
                .asteria-text {
                    text-align: left;
                    margin-right: 0;
                    margin-left: 20px;
                    margin-top: -12px;
                }
            }
        }
    }
    .asteria-cashflow-tooltip-section-total {
        .asteria-cashflow-tooltip-row-total {
            margin: 4px 0 4px;
            .asteria-text {
                text-align: left;
                margin-right: 0;
                margin-left: 20px;
            }
        }
        .asteria-cashflow-tooltip-section-inner {
            margin-left: 0 !important;
        }
    }
    .asteria-cashflow-tooltip-section-unpaid,
    .asteria-cashflow-tooltip-section-withdraw-forecast,
    .asteria-cashflow-tooltip-section-deposit-forecast,
    .asteria-cashflow-tooltip-section-withdraw,
    .asteria-cashflow-tooltip-section-deposit {
        .asteria-cashflow-tooltip-section-inner {
            .asteria-cashflow-tooltip-section-inner {
                margin-left: 20px;
            }
            .asteria-cashflow-tooltip-section-currency-risk-deposit,
            .asteria-cashflow-tooltip-section-currency-risk-withdraw {
                margin-top: -8px;
                .asteria-cashflow-tooltip-row-currencies-risk {
                    .asteria-text {
                        text-align: left;
                        font-family: Arial, Helvetica, sans-serif;
                        color: var(--label-color);
                        font-size: 12px;
                        margin-left: auto;
                        margin-right: auto;
                        text-align: left;
                        margin-top: 0;
                        font-weight: 400;
                    }
                }
                .asteria-cashflow-tooltip-section-inner {
                    padding-left: 0;
                    margin-left: 0;
                }
                .asteria-cashflow-tooltip-row-currency {
                    margin-top: 4px;
                    .currency-flag {
                        max-width: 12px !important;
                        max-height: 8px !important;
                        min-width: 12px !important;
                        min-height: 8px !important;
                        margin-right: 4px !important;
                    }
                    .asteria-cashflow-tooltip-label {
                        text-align: left;
                        font-family: Arial, Helvetica, sans-serif;
                        color: var(--label-color);
                        font-size: 12px;
                        margin-left: auto;
                        margin-right: auto;
                        text-align: left;
                        margin-top: 0;
                        font-weight: 400;
                    }
                    .asteria-cashflow-tooltip-total {
                        font-family: Arial,Helvetica,sans-serif;
                        color: var(--label-color);
                        font-size: 12px;
                    }
                }
            }
            .asteria-cashflow-tooltip-section-currencies {
                margin-top: -10px;
                .asteria-cashflow-tooltip-row-currencies-risk {
                    .asteria-text {
                        text-align: left;
                        font-family: Arial, Helvetica, sans-serif;
                        color: var(--label-color);
                        font-size: 12px;
                        margin-left: auto;
                        margin-right: auto;
                        text-align: left;
                        margin-top: 0;
                        font-weight: 400;
                    }
                }
                .asteria-cashflow-tooltip-section-inner {
                    padding-left: 0;
                    margin-left: 0;
                }
                .asteria-cashflow-tooltip-row-currency {
                    margin-top: 4px;
                    .currency-flag {
                        max-width: 12px !important;
                        max-height: 8px !important;
                        min-width: 12px !important;
                        min-height: 8px !important;
                        margin-right: 4px !important;
                    }
                    .asteria-cashflow-tooltip-label {
                        display: none;
                    }
                    .asteria-cashflow-tooltip-total {
                        font-size: 10px;
                    }
                }
            }
        }
    }
    .asteria-cashflow-tooltip-section-currencies {
        .asteria-cashflow-tooltip-section-inner-currencies-risk {
            padding: 0 0 0 21px;
            margin-top: -6px;
            .asteria-cashflow-tooltip-row-currency {
                margin-right: 8px;
                margin-top: 2px;
                .currency-flag {
                    max-width: 12px !important;
                    max-height: 8px !important;
                    min-width: 12px !important;
                    min-height: 8px !important;
                    margin-right: 4px !important;
                }
                .asteria-cashflow-tooltip-label {
                    display: none;
                }
                .asteria-cashflow-tooltip-total {
                    text-align: left;
                    font-family: Arial, Helvetica, sans-serif;
                    color: var(--label-color);
                    font-size: 10px;
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                    margin-top: 0;
                    font-weight: 400;
                }
            }
        }
    }
    .asteria-transactions-total-tooltip-currency {
        display: flex;
        flex-direction: row;
        align-items: center;
        .asteria-transactions-total-tooltip-currency-label {
            font-family: "Swedbank Sans";
            font-weight: 600;
            -webkit-box-flex: 1;
            flex-grow: 1;
            margin-right: 16px;
            // min-width: 180px;
            text-align: left;
            line-height: normal;
            margin-top: 4px;
            margin-bottom: 4px;
        }
        .asteria-transactions-total-tooltip-currency-value {
            font-family: Arial, Helvetica, sans-serif;
            color: var(--text-color);
            font-size: 14px;
            margin-top: 4px;
            margin-bottom: 4px;
            margin-right: 0px;
            text-align: right;
            white-space: nowrap;
            max-width: none;
        }
    }
    &.asteria-tooltip-close-to-top {
        .asteria-tooltip-wrapper {
            .asteria-tooltip-pointer {
                transform: rotate(180deg) !important;
                top: -8px;
            }
            // .currency-flag {
            //     transform: rotate(360deg) !important;
            // }
        }
    }
}

.asteria-graph-line-graph-legends > div > div,
.asteria-graph-line-graph-component > div > div:not(.asteria-graph-yaxis-line) {
    min-height: 20px;
}

// Below is work for improving rating and needs to be refactored

.asteria-widget-fullscreen {
    position: fixed;
    left: 0 !important;
    right: 0 !important;
    top: 64px;
    z-index: 999;
    background: #fff;
    bottom: 0;
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 0;
    box-shadow: 0px 1px 5px rgb(0 0 0 / 20%) inset;
    overflow-y: auto;
    &::-webkit-scrollbar {
        background-color: transparent;
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #BABAC0;
        border-radius: 16px;
        border: 0px solid rgba(255,255,255,0);
    }
    .asteria-cashflow-header {
        box-shadow: 0px 2px 4px -2px rgb(0 0 0 / 20%) inset;
        top: 0 !important;
    }
    .asteria-transactions-header {
        top: 71px !important;
    }
    .asteria-actionbar {
        top: 137px !important;
    }
    .asteria-transaction-filter {
        top: 137px !important;
    }
    .asteria-has-actionbar {
        .asteria-transaction-filter {
            top: 209px  !important;
        }
    }
}

.asteria-feature-show-larger-company-title {
    &.asteria-feature-asteria-improvements .asteria-cashflow-header .asteria-cashflow-overview-wrapper .asteria-cashflow-overview .asteria-cashflow-overview-header .asteria-title-company-name {
        font-size: 40px;
    }
}

.asteria-feature-adjustable-inline-swedbank-style {
    .asteria-widget-main {
        .asteria-transactions {
            .asteria-transactions-list-wrapper {
                .asteria-transactions-list-wrapper-inner {
                    .asteria-transaction-single-edit {
                        .asteria-transaction-edit {
                            padding: 8px 0;
                            &:hover, &.asteria-state-hover, &.asteria-state-active {
                                background: #f9f9f9 !important;
                            }
                            &.asteria-transaction-edit-removed {
                                .asteria-transaction-cell {
                                    &.asteria-transaction-cell-options {
                                        width: 36px;
                                        height: 38px;
                                        position: relative;
                                        .asteria-button {
                                            width: 20px;
                                            height: 20px;
                                            position: absolute;
                                            right: 10px;
                                            top: 10px;
                                            .asteria-icon-wrapper {
                                                height: 20px;
                                                width: 20px;
                                                .asteria-icon-svg {
                                                    left: 4px;
                                                }
                                            }
                                            &:hover {
                                                .asteria-icon-wrapper {
                                                    .asteria-icon-svg {
                                                        fill: #53A1AC !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .asteria-transaction-cell {
                                .asteria-text {
            
                                }
                                .asteria-input-wrapper {
                                    background: #EBE8E8 !important;
                                    border-radius: 3px !important;
                                    border: 2px solid transparent !important;
                                    .asteria-input {
                                    }
                                    &.asteria-state-focus {
                                        background: #ECF9F3 !important;
                                        border: 2px solid #AACCCF !important;
                                    }
                                    &.asteria-state-active {
                                        background: #ECF9F3 !important;
                                        border: 2px solid #AACCCF !important;
                                    }
                                    &:hover {
                                        background: #ECF9F3 !important;
                                        border: 2px solid #AACCCF !important;
                                    }
                                }
                                .asteria-forms-dropdown {
                                    .asteria-forms-dropdown-toggle  {
                                        background: #EBE8E8 !important;
                                        border-radius: 3px !important;
                                        border: 2px solid transparent !important;
                                        .asteria-input {
                                        }
                                        &.asteria-state-focus {
                                            background: #ECF9F3 !important;
                                            border: 2px solid #AACCCF !important;
                                        }
                                        &.asteria-state-active {
                                            background: #ECF9F3 !important;
                                            border: 2px solid #AACCCF !important;
                                        }
                                        &:hover {
                                            background: #ECF9F3 !important;
                                            border: 2px solid #AACCCF !important;
                                        }
                                    }
                                }
                                &.asteria-state-disabled  {
            
                                }
                                &.asteria-transaction-cell-status {
            
                                }
                                &.asteria-transaction-cell-description {
            
                                }
                                &.asteria-transaction-cell-payment-date {
                                    .asteria-datepicker {
                                        .asteria-button-icon {
                                            border-radius: 4px !important;
                                            border: 2px solid transparent !important;
                                            &:hover {
                                                background: #ECF9F3;
                                                border: 2px solid #AACCCF !important;
                                            }
                                            &.asteria-state-active {
                                                background: #ECF9F3;
                                                border: 2px solid #AACCCF !important;  
                                            }
                                        }
                                    }
                                }
                                &.asteria-transaction-cell-categories {
            
                                }
                                &.asteria-transaction-cell-flat-status {
                                    
                                }
                                &.asteria-transaction-cell-risk-high {
            
                                }
                                &.asteria-transaction-cell-risk-low {
            
                                }
                                &.asteria-transaction-cell-risk {
            
                                }
                                &.asteria-transaction-cell-currency {
            
                                }
                                &.asteria-transaction-cell-total {
            
                                }

                                &.asteria-transaction-cell-options {
                                    width: 36px;
                                    .asteria-adjustable-total {
                                        width: 32px;
                                        height: 32px;
                                        .asteria-icon-wrapper {
                                            height: 16px !important;
                                            width: 16px !important;
                                            .asteria-icon-svg {

                                            }
                                        }
                                        &:hover {
                                            .asteria-icon-wrapper {
                                                .asteria-icon-svg {
                                                    fill: #53A1AC !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .asteria-transactions-edit {
                        .asteria-transactions-divider {
                            &.asteria-transactions-divider-feature-inline-edit {
                                .asteria-transactions-divider-row {
                                    .asteria-title-divider {
                    
                                    }
                                    .asteria-amount-divider {
                    
                                    }
                                    .asteria-adjustable-total-account-balance {
                                        .asteria-tooltip-trigger-wrapper {}
                                        .asteria-form-group {
                                            .asteria-input-wrapper {
                                                .asteria-input {
                                                    
                                                }
                                            }
                                        }
                                        .asteria-adjustable-total {
                                            .asteria-icon-wrapper {
                                                .asteria-icon-svg {
                    
                                                }
                                            }
                                        }
                                        .asteria-adjustable-total-cancel {
                                            .asteria-icon-wrapper {
                                                .asteria-icon-svg {
                    
                                                }
                                            }
                                            .asteria-text {
                    
                                            } 
                                        }
                                    }
                                }
                    
                            }
                        }
                        .asteria-transaction {
                            .asteria-transaction-wrapper-inner {
                                &.asteria-transaction-edit {
                                    padding: 8px 0;
                                    &.asteria-transaction-edit-removed {
                                        .asteria-transaction-cell {
                                            &.asteria-transaction-cell-options {
                                                width: 36px;
                                                height: 38px;
                                                position: relative;
                                                .asteria-button {
                                                    width: 20px;
                                                    height: 20px;
                                                    position: absolute;
                                                    right: 10px;
                                                    top: 10px;
                                                    .asteria-icon-wrapper {
                                                        height: 20px;
                                                        width: 20px;
                                                        .asteria-icon-svg {
                                                            left: 4px;
                                                        }
                                                    }
                                                    &:hover {
                                                        .asteria-icon-wrapper {
                                                            .asteria-icon-svg {
                                                                fill: #53A1AC !important;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .asteria-transaction-cell {
                                        .asteria-text {
                    
                                        }
                                        .asteria-input-wrapper {
                                            background: #EBE8E8 !important;
                                            border-radius: 3px !important;
                                            border: 2px solid transparent !important;
                                            .asteria-input {
                                            }
                                            &.asteria-state-focus {
                                                background: #ECF9F3 !important;
                                                border: 2px solid #AACCCF !important;
                                            }
                                            &.asteria-state-active {
                                                background: #ECF9F3 !important;
                                                border: 2px solid #AACCCF !important;
                                            }
                                            &:hover {
                                                background: #ECF9F3 !important;
                                                border: 2px solid #AACCCF !important;
                                            }
                                        }
                                        .asteria-forms-dropdown {
                                            .asteria-forms-dropdown-toggle  {
                                                background: #EBE8E8 !important;
                                                border-radius: 3px !important;
                                                border: 2px solid transparent !important;
                                                .asteria-input {
                                                }
                                                &.asteria-state-focus {
                                                    background: #ECF9F3 !important;
                                                    border: 2px solid #AACCCF !important;
                                                }
                                                &.asteria-state-active {
                                                    background: #ECF9F3 !important;
                                                    border: 2px solid #AACCCF !important;
                                                }
                                                &:hover {
                                                    background: #ECF9F3 !important;
                                                    border: 2px solid #AACCCF !important;
                                                }
                                            }
                                        }
                                        &.asteria-state-disabled  {
                    
                                        }
                                        &.asteria-transaction-cell-status {
                    
                                        }
                                        &.asteria-transaction-cell-description {
                    
                                        }
                                        &.asteria-transaction-cell-payment-date {
                                            .asteria-datepicker {
                                                .asteria-button-icon {
                                                    border-radius: 4px !important;
                                                    border: 2px solid transparent !important;
                                                    &:hover {
                                                        background: #ECF9F3;
                                                        border: 2px solid #AACCCF !important;
                                                    }
                                                    &.asteria-state-active {
                                                        background: #ECF9F3;
                                                        border: 2px solid #AACCCF !important;  
                                                    }
                                                }
                                            }
                                        }
                                        &.asteria-transaction-cell-categories {
                    
                                        }
                                        &.asteria-transaction-cell-flat-status {
                                            
                                        }
                                        &.asteria-transaction-cell-risk-high {
                    
                                        }
                                        &.asteria-transaction-cell-risk-low {
                    
                                        }
                                        &.asteria-transaction-cell-risk {
                    
                                        }
                                        &.asteria-transaction-cell-currency {
                    
                                        }
                                        &.asteria-transaction-cell-total {
                    
                                        }
                                        &.asteria-transaction-cell-options {
                                            width: 36px;
                                            .asteria-button {
                                                width: 32px;
                                                height: 32px;
                                                .asteria-icon-wrapper {
                                                    height: 32px;
                                                    width: 32px;
                                                    .asteria-icon-svg {

                                                    }
                                                }
                                                &:hover {
                                                    .asteria-icon-wrapper {
                                                        .asteria-icon-svg {
                                                            fill: #53A1AC !important;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.asteria-feature-list-divider-inline-edit {
    .asteria-adjustable-add {
        .asteria-adjustable-accordion-inner {
            background: transparent;
            .asteria-button-link {
                margin-right: 8px !important;
                .asteria-text {
                    font-family: Arial !important;
                    font-size: 13px !important;
                    color: #512b2b !important;
                }
            }
            .asteria-button-add-transaction-row {
                margin: 0px 0 0 8px;
                padding: 0;
                .asteria-text {
                    display: none;
                }
                .asteria-icon-wrapper {
                    height: 16px;
                    width: 16px;
                }
            }

            padding: 0;
            .asteria-adjustable-section-title {
                background-color: rgb(251,242,234);
                border-bottom: 2px solid #fff;
                align-items: ;center
                justify-content: flex-start;
                padding: 0 12px;
                height: 38px;
                margin: 0 !important;
                span {
                    flex-grow: 1 !important;
                    font-family: Arial !important;
                    font-size: 14px !important;
                    color: #512b2b !important;
                    font-weight: 400;
                    margin-left: 0;
                    justify-content: flex-start;
                }
            }
            .asteria-button-info {
                order: 2;
                height: 24px;
                width: 24px;
                .asteria-icon-svg {

                }
            }
            .asteria-button-save-adjustments {
                order: 3;
            }
            form {
                display: flex;
                flex: 1;
                border-bottom: 1px solid var(--transaction-list-border-color);
                background: #fff;
                max-height: 38px;
                .asteria-forms-dropdown-toggle {
                    .asteria-text {
                    }
                }
                .asteria-input-wrapper {
                    &.asteria-state-active {
                        border-bottom: 2px solid #53a1ac !important;
                    }
                    &.asteria-state-focus {
                        border-bottom: 2px solid #53a1ac !important;
                    }
                    .asteria-input {

                    }
                }
                .asteria-button {
                    border-bottom: 2px solid transparent;
                    &:hover {
                        border-bottom: 2px solid #53a1ac;
                    }
                }
                .asteria-button-remove {
                    order: 5;
                    margin: 0;
                    height: 38px;
                    background: #fff;
                }
                .asteria-forms-dropdown-menu {
                    &::-webkit-scrollbar {
                        background-color: transparent;
                        width: 6px;
                    }
                    &::-webkit-scrollbar-track {
                        background-color: transparent;
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: #BABAC0;
                        border-radius: 16px;
                        border: 0px solid rgba(255,255,255,0);
                    }
                    .asteria-forms-dropdown-item {
                        padding: 10px 12px;
                        .asteria-text {
                            margin-left: 0;
                            font-size: 14px !important;
                            color: #80584F !important;
                            font-family: Arial,Helvetica,sans-serif !important;
                            font-weight: 400 !important;
                        }
                    }
                }
                .asteria-forms-repeatable {
                    margin: 0;
                    order: 2;
                    width: auto !important;
                    .asteria-group {
                        margin: 0;
                    }
                    .asteria-icon-triangleDown, .asteria-icon-triangleUp {
                        .asteria-icon-svg {
                            fill: #9f8c82 !important;
                            height: 5px;
                        }
                    }
                    .asteria-forms-dropdown-menu {
                        min-width: 140px;
                    }
                }
                .asteria-button-remove {
                    .asteria-icon-wrapper {
                        height: 16px;
                        width: 16px;
                    }
                    .asteria-text {
                        display: none;
                    }
                }
                .asteria-form-group {
                    margin: 0;
                    .asteria-input-wrapper {
                        .asteria-input {
                            font-size: 14px !important;
                            color: #80584F !important;
                            font-family: Arial,Helvetica,sans-serif !important;
                            font-weight: 400 !important;
                        }
                    }
                    .asteria-button {
                        width: 100% !important;
                        justify-content: flex-start !important;
                        height: 38px;
                        .asteria-text {
                            font-size: 14px !important;
                            color: #80584F !important;
                            font-family: Arial,Helvetica,sans-serif !important;
                            font-weight: 400 !important;
                        }
                        &.asteria-state-active {
                            border-bottom-color: #53a1ac;
                        }
                    }
                    .asteria-label-wrapper {
                        display: none;
                        span {

                        }
                    }
                    &.asteria-group-customer {
                        order: 4;
                        margin: 0;
                        align-items: center;
                        .asteria-icon-tag {
                            order: -1;
                            margin-left: 0;
                            margin-top: -3px;
                            width: 20px;
                            span {
                                max-width: 12px;
                                max-height: 12px;
                                height: 12px;
                                width: 12px;
                            }
                        }
                        .asteria-forms-dropdown-menu {
                            min-width: 210px;
                            .asteria-forms-dropdown-item {
                                padding: 10px 12px;
                                .asteria-icon-tag {
                                    order: -1;
                                    margin-left: 0;
                                    margin-top: 0;
                                    width: 20px;
                                    height: 12px;
                                    span {
                                        max-width: 12px;
                                        max-height: 12px;
                                        height: 12px;
                                        width: 12px;
                                    }
                                }
                            }
                        }
                    }
                    &.asteria-group-description {
                        max-width: none;
                        order: 0;
                        margin: 0;
                        .asteria-button {
                        }
                    }
                    &.asteria-group-category {
                        margin: 0;
                        .asteria-button {
                        }
                    }
                    &.asteria-group-date {
                        order: 1;
                        margin: 0;
                        max-width: none;
                        .asteria-input-wrapper {
                            height: 38px;
                            border-bottom: 2px solid #fff;
                            .asteria-text {
                                font-size: 14px !important;
                                color: #80584F !important;
                                font-family: Arial,Helvetica,sans-serif !important;
                                font-weight: 400 !important;
                            }
                            &.asteria-state-active {
                                border-bottom: 2px solid #53a1ac;
                            }
                            &.asteria-state-hover {
                                border-bottom: 2px solid #53a1ac;
                            }
                            &:hover {
                                border-bottom: 2px solid #53a1ac;
                            }
                        }
                        .asteria-icon-wrapper {
                        }
                        .asteria-button-icon-only {
                            background: transparent;
                            top: 0;
                            right: 0;
                            justify-content: center !important;
                            width: 40px !important;
                            height: 38px;
                            align-content: center;
                            justify-items: center;
                            align-items: center;
                            border-bottom: 2px solid transparent;
                            padding: 11px;
                            &.asteria-state-active {
                                border-bottom: 2px solid #53a1ac;
                            }
                        }
                    }
                    &.asteria-group-amount {
                        margin: 0;
                        margin-left: auto;
                        max-width: 274px;
                        order: 5;
                        .asteria-forms-dropdown-toggle {
                            .asteria-text {
                                margin-left: 4px;
                            }
                        }
                        .asteria-forms-dropdown-menu {
                            min-width: 120px;
                            top: 38px;
                            &::-webkit-scrollbar {
                                background-color: transparent;
                                width: 6px;
                            }
                            &::-webkit-scrollbar-track {
                                background-color: transparent;
                            }
                            &::-webkit-scrollbar-thumb {
                                background-color: #BABAC0;
                                border-radius: 16px;
                                border: 0px solid rgba(255,255,255,0);
                            }
                            .asteria-forms-dropdown-item {
                                padding: 10px 12px;
                                .asteria-text {
                                    margin-left: 0;
                                    font-size: 14px !important;
                                    color: #80584F !important;
                                    font-family: Arial,Helvetica,sans-serif !important;
                                    font-weight: 400 !important;
                                }
                                .asteria-icon-currency {
                                    margin-right: 4px;
                                    margin-top: -3px;
                                    order: -1;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                        }
                        .asteria-group-horizontal {
                            flex-direction: row-reverse;
                        }
                        .asteria-input-wrapper {
                            height: 38px;
                            border-bottom-color: #ebe7e2;
                            border-bottom: 2px solid #fff;
                            .asteria-input {
                                padding-right: 0px !important;
                                text-align: right;
                            }
                            .asteria-input-helper {
                                top:8px;
                                display: none;
                                span {
                                    font-size: 14px !important;
                                    color: #80584F !important;
                                    font-family: Arial,Helvetica,sans-serif !important;
                                    font-weight: 400 !important;
                                }
                            }
                        }
                        .asteria-icon-currency {
                            order: -1;
                            margin-left: 0;
                            margin-top: -10px;
                            width: 20px;
                            span {
                                max-width: 12px;
                                max-height: 12px;
                                height: 12px;
                                width: 12px;
                                margin-right: 0;
                                background-position: center center;
                                min-height: auto;
                                margin-top: 2px;
                                position: absolute;
                                top: 10px;
                            }
                        }
                        .asteria-forms-dropdown-toggle {
                            .asteria-text {
                                margin-left: 4px;
                            }
                        }
                        .asteria-forms-dropdown-menu {
                            min-width: 120px;
                            top: 48px;
                            bottom: auto;
                            .asteria-forms-dropdown-item {
                                padding: 10px 12px;
                                .asteria-text {
                                    margin-left: 0;
                                    margin-right: 0;
                                }
                                .asteria-icon-currency {
                                    margin-right: 4px !important;
                                    order: -1 !important;
                                    justify-content: center !important;
                                    align-items: center !important;
                                    margin-right: 6px !important;
                                    background-position: center center !important;
                                    min-height: auto !important;
                                    margin-top: -3px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
        .asteria-adjustable-controllers {
            .asteria-button-medium {
                .asteria-text {

                }
            }
            .asteria-button-primary {
                .asteria-text {

                }
            }
        }
    }
}

.asteria-feature-layout-resizable {
    &:first-of-type {
        height: 100vh;
        margin: 0;
        // min-height: 100vh;
        height: calc(100vh - 80px);
        // min-height: auto;
    }

    &.asteria-widget-layout-small {
        .asteria-cashflow-graph {
            &.asteria-cashflow-graph-layout-tabs {
                div {
                    &:nth-of-type(2) {
                        min-height: 0 !important;
                    }
                }
            }
        }
    }

    &.asteria-widget-layout-medium {
        .asteria-cashflow-graph {
            &.asteria-cashflow-graph-layout-tabs {
                div {
                    &:nth-of-type(2) {
                        min-height: 0 !important;
                    }
                }
            }
        }
    }

    &.asteria-widget-layout-large {
        height: auto !important;
        .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper {
            position: relative;
            height: auto !important;
            overflow: hidden;
            bottom: 0;
            top: 1px !important;
        }
        &.asteria-widget-fullscreen {
            
        }
    }
    
    .asteria-widget-wrapper {
        height: 100%;
    }
    
    .asteria-cashflow-graph {
        &::-webkit-scrollbar {
            background-color: transparent;
            width: 12px;
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #fff;
            border-radius: 16px;
            border: 0px solid rgba(255,255,255,0);
        }

        &.asteria-cashflow-graph-layout-flat {
            .asteria-graph-lines {
                height: calc(50% - 22px);
                .asteria-graph-line-group {
                    grid-template-rows: 100% !important;
                    height: calc(100% - 16px) !important;
                }
                .asteria-curreny-y-axis {
                    top: -26px;
                }
            }
            .asteria-graph-bars {
                height: calc(50% - 22px);
                .asteria-graph-bar-group {
                    height: calc(100% - 17px) !important;
                }
                .asteria-curreny-y-axis {
                    top: -20px;
                }
            }
            &.asteria-cashflow-graph-hide-account-balance {
                .asteria-graph-bars {
                    height: calc(100% - 44px);
                }      
            }
        }

        &.asteria-cashflow-graph-stacked {
            height: calc(100% - 19px);
            min-height: 170px;
            .asteria-graph-area-stacked {
            }
            .asteria-graph-lines {
                .asteria-graph-line-group {
                    grid-template-rows: 100% !important;
                    height: calc(100% - 16px) !important;
                }
            }
            .asteria-graph-bar-group {
                grid-row-gap: 4px !important;
                height: calc(100% - 17px) !important;
            }
        }

        &.asteria-cashflow-graph-layout-tabs {

            div {
                &:nth-of-type(2) {
                    min-height: 0 !important;
                }
            }
            
            .asteria-tab {
                
            }
            .asteria-tab-account {
                .asteria-part-risk {
                    margin-top: -2px;
                }
            }
            .asteria-tabs-navigation {
                margin-left: 54px;
            }
            .asteria-tabs-wrapper {
                height: calc(100% - 52px);
            }
            .asteria-graph-lines {
                height: calc(100% - 22px) !important;
                .asteria-graph-line-group {
                    grid-template-rows: 100% !important;
                    height: calc(100% - 16px) !important;
                }
                .asteria-curreny-y-axis {
                    top: -26px;
                }
            }
            .asteria-graph-bars {
                height: calc(100% - 20px) !important;
                .asteria-graph-bar-group {
                    height: calc(100% - 17px) !important;
                }
                .asteria-curreny-y-axis {
                    top: -20px;
                }
            }
        }
    }

    .asteria-transaction-list-compress {
        .asteria-transactions {
            .asteria-transactions-list-wrapper {
                .asteria-transactions-list-wrapper-inner {
                    .asteria-list-wrapper {
                        //top: 3px !important;
                        top: 33px !important;
                    }
                }
            }
        }
    }
    
    .asteria-transactions {
        display: flex;
        overflow: hidden;
        /* height: 35vh; */
        flex: 1;
        flex-direction: column;
        padding-bottom: 0;
        width: 100%;
        .asteria-transactions-header {
            top: 0;
        }
        .asteria-transactions-list-wrapper {
            flex: 1;
            // overflow: auto;
            height: auto;
            .asteria-transactions-list-wrapper-inner {
                position: relative;
                width: 100%;
                height: 100%;
                .asteria-transaction-filter {
                    position: sticky;
                    top: 0;
                }
                .asteria-list-wrapper {
                        overflow-y: scroll;
                        overflow-x: hidden;
                        position: absolute;
                        top: 40px !important;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        height: calc(100% - 31px);
                        &::-webkit-scrollbar {
                            background-color: transparent;
                            width: 6px;
                        }
                        &::-webkit-scrollbar-track {
                            background-color: transparent;
                        }
                        &::-webkit-scrollbar-thumb {
                            background-color: #BABAC0;
                            border-radius: 16px;
                            border: 0px solid rgba(255,255,255,0);
                        }
                    .asteria-list-content {
                        
                    }
                }
            }
        }
    }
}

.asteria-feature-asteria-improvements {
    .asteria-has-actionbar {
        .asteria-transaction-filter {
            top: 0 !important;
        }
        .asteria-actionbar {
            position: fixed;
            bottom: 0px;
            width: 100%;
            left: 50%;
            z-index: 5;
            align-items: center;
            padding: 0 16px 0 24px;
            height: 72px;
            max-width: 1161px;
            transform: translateX(calc(-59% + -10.5px));
            box-shadow: 0px -2px 2px 0px rgb(0 0 0 / 11%);
            top: auto;
            margin: 0 0 0 118px;
        }
        .asteria-floating-navigation {
            display: none;
        }
    }

    .asteria-display-status-navgiation {
        .asteria-forms-dropdown-menu {
            box-shadow: rgb(0 0 0 / 25%) 0px 3px 5px 0px;
        }
    }

    // .asteria-forms-dropdown-menu {
    //     background-color: transparent;
    //     box-shadow: rgb(0 0 0 / 25%) 0px 0 0 0px;
    //     width: auto;
    //     overflow-x: visible;
    //     min-width: 0;

    //     .asteria-forms-dropdown-item {
    //         border-top: 0px solid rgb(244,244,244);
    //         padding: 0px;
    //         max-height: 40px;
    //         display: flex;
    //         flex-direction: row;
    //         max-width: 142px;
    //         box-shadow: rgb(0 0 0 / 25%) 0px 3px 5px 0px;
    //         .asteria-option-inner {
    //             border-top: 1px solid rgb(244,244,244);
    //             padding: 16px;
    //             background-color: #ffffff;
    //             // width: 100%;
    //             min-width: 110px;
    //             .asteria-text {
    //                 font-size: 14px;
    //             }
    //         }
    //         .asteria-icon-tag {
    //             display: flex;
    //             align-items: center;
    //             margin-right: 4px;
    //             span[color^='$'] {
    //                 height: 12px;
    //                 width: 12px;
    //                 margin-right: 0;
    //                 max-width: 12px;
    //                 max-height: 12px;
    //                 min-width: 12px;
    //                 min-height: 12px;
    //             }
    //         }
    //         .asteria-icon-wrapper {
    //             .asteria-icon-svg {
    //                 opacity: 1;
    //             }
    //             &.asteria-right {
    //                 max-width: 12px;
    //                 max-height: 12px;
    //                 min-width: 12px;
    //                 min-height: 12px;
    //                 height: 12px;
    //                 width: 12px;
    //                 .asteria-icon-svg {
    //                     fill: #9f8c82 !important;
    //                 }
    //             }
    //         }
    //         &:hover, &.asteria-state-active {
    //             .asteria-text {
    //                 color: rgb(238, 112, 35);
    //             }
    //             .asteria-icon-wrapper {
    //                 .asteria-icon-svg {
    //                     fill: rgb(238, 112, 35) !important;
    //                 }
    //             }
    //         }
    //         .asteria-forms-dropdown-sub {
    //             // top: 0;
    //             // left: 100%;
    //             margin-left: 0;
    //             z-index: 2;
    //         }
    //     }

    // }

    .asteria-display-filter-navigation {
        align-items: center;
        min-height: 0;
        height: auto;
        border-bottom: 0px solid transparent;
        .asteria-button {
            align-items: center;
            min-height: 0;
            border-bottom: 0px solid transparent;
            height: auto;
        }
        .asteria-icon-wrapper {
            max-width: 12px;
            max-height: 12px;
            margin-right: 8px;
        }

        .asteria-text {
            text-decoration: none;
        }
        
        .asteria-forms-dropdown-toggle {
            .asteria-icon-wrapper {
                width: 16px !important;
                height: 6px !important;
                margin-left: 8px;
                .asteria-icon-svg {
                    fill: #9f8c82 !important;
                }
            }
            &:hover, &.asteria-state-active {
                .asteria-text {
                    color: rgb(238, 112, 35);
                }
                .asteria-icon-wrapper {
                    .asteria-icon-svg {
                        fill: #512b2b !important;
                    }
                }
            }
        }
        &.asteria-forms-dropdown-open {
            .asteria-forms-dropdown-menu-direction-down {
                top: 42px;
                min-width: 200px;
                right: auto;
                left: 0;
                transition: max-height 0s;
                .asteria-forms-dropdown-item {
                    &.asteria-state-active {
                        display: none;
                    }
                }
                .asteria-text {
                    font-size: 14px !important;
                    color: #80584F !important;
                    font-family: Arial,Helvetica,sans-serif !important;
                    font-weight: 400 !important;
                }
            }
        }
        .asteria-forms-dropdown-menu-direction-down {
            transition: max-height 0s !important;
        }
    }

    .asteria-modal-cashflow-company-overview {
        z-index: 9999;
        .asteria-modal {
            // width: calc(100vw - 30%);
            width: calc(100vw - 36%);
            min-width: 860px;
            max-width: 1280px;
            .modal-header {
                .asteria-title {
                    padding: 0;
                    margin: 0;
                }
            }
            .modal-body {
                .asteria-cashflow-overview-details {
                    background: transparent;
                    width: auto;
                    margin-top: 0;
                    .asteria-currency-overview {
                        background: transparent;
                        padding: 0;
                        > div {
                            background: transparent;
                        }
                        .asteria-title-currency {
                            display: flex;
                            float: left;
                            margin-top: 12px !important;
                        }
                    }
                }
            }
            .asteria-cashflow-overview-details-section-header {
                margin-bottom: 8px;
            }
            .asteria-cashflow-overview-details-section-content {
                margin-bottom: 0;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 16px;
            }
            .asteria-cashflow-overview-details-section-content-column {
                padding: 6px 6px 6px 12px;
                margin-bottom: 12px;
                height: 90px;
                border-radius: 5px;
                border: 1px solid #ebe7e2;
                .asteria-text {
                    font-family: Arial, Helvetica, sans-serif !important;
                    color: #a38b80;
                    font-size: 12px;
                    font-weight: 700;
                    margin-bottom: 16px;
                }
                .asteria-title {
                    font-family: Arial, Helvetica, sans-serif !important;
                    color: #72605e;
                    font-size: 18px;
                    font-weight: 700;
                    display: flex;
                    flex-direction: row;
                    .asteria-security-bar {
                        margin-left: 4px;
                    }
                }
            }
            .asteria-button-toggle-company-account-balance-overview {
                padding-left: 0;
                .asteria-text {
                    font-size: 14px !important;
                    color: #4C2D2C !important;
                    font-family: Arial, Helvetica, sans-serif !important;
                    font-weight: 600 !important;
                    text-decoration: none;
                }
                .asteria-icon-wrapper {
                    height: 10px;
                    width: 12px;
                    margin-right: 4px;
                    .asteria-icon-svg {
                        fill: #4C2D2C !important;
                    }
                }
            }
        }
    }

    .asteria-cashflow-overview-details {
        width: calc(100% + 60px);
        margin: 8px 0 0 0;
        background: #f9f8f6;

        .asteria-currency-overview {
            background: transparent;
            padding: 16px;

            .asteria-button-info {
                margin-top: 8px !important;
                margin-right: 8px !important;
            }
            .asteria-currency-filter {
                display: flex;
                margin-top: 0;
            }
            .asteria-title-currency {
                display: none;
            }
            .asteria-list-item .asteria-list-item-inner .asteria-overview {
                flex: 0;
                min-width: 138px;
            }
        }

        .asteria-currency-filter {
            background: #E2DCD9 !important;
            height: 35px;
            .asteria-currency-cell {
                .asteria-text {
                    font-size: 14px !important;
                    color: #4C2D2C !important;
                    font-family: Arial, Helvetica, sans-serif !important;
                    font-weight: 600 !important;
                    max-width: 90%;
                }
                .asteria-icon-wrapper {
                    width: 8px;
                    height: 20px;
                    .asteria-icon-svg {
                        fill: #9F8C82;
                    }
                }
                &.asteria-state-active {
                    .asteria-icon-wrapper {
                        width: 8px;
                        height: 20px;
                        .asteria-icon-svg {
                            fill: #9F8C82;
                        }
                    }
                }
            }
        }

        .asteria-list {
            .asteria-currency-cell {
                .asteria-text {
                    font-size: 14px !important;
                    color: #4C2D2C !important;
                    font-family: Arial, Helvetica, sans-serif !important;
                    font-weight: 400;
                    &.asteria-text-amount, &.asteria-text-exposure, &.asteria-currency-cell-currency-risk {
                        font-size: 16px !important;
                    }
                    &.asteria-text-original {
                        font-size: 12px !important;
                    }
                }
                .asteria-no-currency-account {
                    .asteria-button {
                        .asteria-text {
                            font-size: 12px !important;
                        }
                    }
                }
            }
        }
    }

    .asteria-cashflow-header {
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
        top: 80px;
        z-index: 7;

        transform: translateX(-28px);
        padding: 8px 28px !important;

        .asteria-notifications {
            margin-right: -11px;
        }

        .asteria-cashflow-overview-wrapper {
            width: 100%;
            flex-direction: row;
            display: flex;
            align-items: center;
            .asteria-cashflow-overview {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;

                .asteria-cashflow-overview-header {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    align-items: center;
                    min-height: 48px;
                    .asteria-button-notification-icon {
                        .asteria-icon-svg {
                            fill: #c01f26 !important;
                        }
                    }
                    .asteria-button-toggle-company-overview {
                        height: 16px;
                        width: 16px;
                        padding: 0;
                        margin-right: 8px;
                        .asteria-tooltip-trigger-wrapper {

                        }
                        .asteria-icon-svg {
                            fill: rgb(159,140,130);
                        }
                    }
    
                    .asteria-title-company {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }
        
                    .asteria-title-company-name {
                        color: #f35b1c;
                        height: auto;
                        cursor: pointer;
                        line-height: 100%;
                    }
        
                    .asteria-company-amount {
                        .asteria-overview-current-account-balance-title {
                            text-align: right;
                            .asteria-text {
                                margin-bottom: 4px;
                            }
                        }
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        margin-left: auto;
                        .asteria-security-bar {
                            margin-left: 8px;
                            margin-top: 22px;
                        }
                    }
                }

            }
        }

        .asteria-notifications {
            .asteria-button {
                padding: 0;
                margin: 0 0 0 16px;
                .asteria-text {
                    width: 0px;
                    overflow: hidden;
                    .asteria-badge {
                        margin-left: 26px;
                    }
                }
            }
            .asteria-forms-dropdown-menu {
                // background: #fff;
                // box-shadow: rgb(0 0 0 / 25%) 0px 3px 5px 0px;
                min-width: 330px;
                // .asteria-forms-dropdown-item {
                //     box-shadow: 0px 0px 0px transparent;
                //     max-height: none;
                //     max-width: none;
                //     padding: 16px;
                //     .asteria-text {
                //         width: auto;
                //         overflow: visible;
                //     }
                //     .asteria-button {
                //         margin-left: 0;
                //         &.asteria-button-link {
                //             color: #257886 !important;
                //         }
                //     }
                // }
                .asteria-notification-item-footer {
                    .asteria-button {
                        margin: 0;
                        .asteria-text {
                            width: auto;
                            overflow: visible;
                        }
                    }
                }
            }
        }

    }

    .asteria-filter-nav {
        width: 100% !important;
        top: auto;
        padding-top: 0;
    }

    .asteria-search-wrapper {
        flex-direction: row;
        align-items: center;
        display: flex;
        width: 100%;
        min-height: 40px;
        .asteria-search-area {
            height: 40px;
            border: 0px solid transparent;
            .asteria-search-area-wrapper {
                max-width: none;
                height: 40px;
                .asteria-search-area-controls {
                    height: 40px;
                    display: flex;
                    flex-direction: row;
                    margin: 0;
                }
            }
            div[role='button'] {
                background: rgb(234, 231, 228);
                border-radius: 30px;
                cursor: pointer;
                margin-right: 8px;
                padding-top: 0;
                padding-bottom: 0;
                padding: 0;
                padding-right: 8px;
                &.asteria-tag-type-overdraft {
                    &:hover {
                    }
                    span {
                        display: none;
                    }
                }
                &:hover {
                    cursor: pointer;
                    background: #E3E7EE !important;
                }
                .asteria-tag-overview {
                    padding: 0;
                    height: auto;
                    background: transparent;
                    .asteria-category-overview {
                        padding: 0;
                        height: auto;
                        background: transparent;
                    }
                }
                .asteria-chip {
                    border-radius: 50px;
                    display: inline-flex;
                    padding: 5px 0 5px 12px;
                    -ms-flex-pack: center;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    border: 1px solid #eae7e4;
                    height: auto;
                    background: transparent;
                    .asteria-text {
                        border-radius: 0;
                    }
                } 
                .asteria-tag-remove {
                    background: transparent;
                    height: 32px !important;
                    width: 32px !important;
                }
            }
            &:hover {
                .asteria-button-search {
                    .asteria-icon-wrapper {
                        .asteria-icon-svg {
                            fill: #000;
                        }
                    }
                }
            }
        }
        .asteria-input-wrapper {
            height: 40px;
            .asteria-input {
                padding-left: 38px;
            }
            .asteria-input {
                font-family: Swedbank Sans;
                font-weight: 700;
                font-size: 14px;
                color: var(--text-color);
                text-decoration: none;
                padding-left: 32px;
            }
        }
        .asteria-button-search {
            left: 0 !important;
            top: 50%;
            transform: translateY(-50%);
            padding: 0;
            .asteria-icon-search {
                height: 16px !important;
                width: 16px !important;
                .asteria-icon-svg {
                    fill: #9f8c82 !important;
                }
            }
            &.asteria-state-active {

            }
        }
        .asteria-button-clear-search {

        }
    }

    .asteria-prompt-wrapper {
        height: calc(100vh - 150px);
        display: flex;
        align-items: flex-end;
        position: fixed;
        background: transparent;
        border: 0px solid transparent;
        padding: 0;
        margin: 0;
        pointer-events: none;
        .asteria-prompt {
            pointer-events: all;
            width: 460px;
            position: fixed;
            transform:translateX(-50%);
            left: calc(50% - -116px);
            bottom: auto;
            // &:before {
            //     content: "";
            //     height: 90vh;
            //     display: block;
            //     position: relative; 
            // }
        }
        &.asteria-prompt-action {
            z-index: 9;
            height: calc(100vh - 89px);
            .asteria-prompt {
                width: 550px;                
                left: calc(50% - -107px);
            }
        }
    }

    // Test

    .asteria-search-wrapper {
        .asteria-search-area-controls {
            flex: 1;
        }
        .asteria-display-filter-navigation {
            display: none;
        }
        .asteria-controller-search {
            position: relative;
            left: auto;
            top: auto;
            right: auto;
            order: -1;
            margin-right: 16px;
            &.asteria-state-focus {
                position: relative;
                left: auto;
                top: auto;
                right: auto;  
            }
            .asteria-search-area {
                height: 44px;
                &:hover {
                    .asteria-button-search {
                        .asteria-icon-svg {
                            fill: rgb(126, 98, 98) !important;
                        }
                    }
                    &:before {
                        background: rgb(209, 196, 189);
                        content: '';
                        position: absolute;
                        top: auto;
                        bottom: 0;
                        right: 0px;
                        z-index: 2;
                        left: 0px;
                        height: 2px;
                    }
                    .asteria-input {
                        color: #512B2B !important;
                    }
                }
            }
            .asteria-search-area-wrapper {
                margin-left: 32px;
                .asteria-button-search {
                    &:hover {
                        .asteria-icon-wrapper {
                            background: transparent;
                            .asteria-icon-svg {
                                fill: rgb(126, 98, 98) !important;
                            }
                        }
                    }
                }
            }
            .asteria-input-wrapper {
                .asteria-input {
                    padding-left: 0px;
                    padding-top: 4px;
                }
            }
        }
        .asteria-adjustable-navigation {
            margin-top: -5px;
            .asteria-button {
                margin: 0 !important;
                padding: 0;
                .asteria-text {
                    display: none;
                }
            }
        }
        .asteria-timeslice-selector-wrapper {
            position: relative;
            top: 4px;
            right: 0px;
            margin: 0;
            width: 20px;
        }
        .asteria-display-currency-navgiation {
            display: none;
        }
        .asteria-display-status-navgiation {
            display: none;
        }
        .asteria-display-clients {
            display: none;
        }
        .asteria-display-financial-navigation {
            display: none;
        }
        .asteria-button-settings {
            position: relative;
            margin: 0 0 0 4px !important;
            top: auto;
            &:hover {
                .asteria-icon-wrapper {
                    .asteria-icon-svg {
                        fill: rgb(238, 112, 35) !important;
                    }
                }
            }
        }
        .asteria-prompt-wrapper {
            height: calc(100vh - 150px);
            display: flex;
            align-items: flex-end;
            position: fixed;
            background: transparent;
            border: 0px solid transparent;
            padding: 0;
            margin: 0;
            pointer-events: none;
            .asteria-prompt {
                pointer-events: all;
                width: 460px;
                position: fixed;
                transform:translateX(-50%);
                left: calc(50% - -116px);
                bottom: auto;
                // &:before {
                //     content: "";
                //     height: 90vh;
                //     display: block;
                //     position: relative; 
                // }
            }
        }
    }

    .asteria-has-search-focus {
        .asteria-button-clear-search {
            display: block;
            top: 12px;
            right: 16px;
            .asteria-icon-wrapper {
                background: transparent;
            }
            .asteria-icon-svg {
                fill: #9f8c82 !important;
            }
            &:hover {
                .asteria-icon-svg {
                    fill: #512b2b !important;
                }
            }
        }
        .asteria-search-wrapper {
            .asteria-input {
                color: #512B2B !important;
            }
            .asteria-display-filter-navigation {
                display: flex;
            }
            .asteria-display-currency-navgiation {
                display: flex;
            }
            .asteria-display-currency-navgiation {
                display: flex;
            }
            .asteria-display-clients {
                display: flex;
            }
            .asteria-display-status-navgiation {
                display: flex;
            }
            .asteria-display-financial-navigation {
                display: flex;
            }
            .asteria-button-search {
                .asteria-icon-wrapper {
                    .asteria-icon-svg {
                        fill: rgb(126, 98, 98) !important;
                    }
                }
            }
        }
        .asteria-filter-nav {
            top: 44px;
        }
        .asteria-search-area {
            &:before {
                background: rgb(49,163,174) !important;
            }
        }
    }

    .asteria-has-filters {
        .asteria-controller-search {
            .asteria-search-area {
                display: flex;
                flex: 1;
                overflow: hidden;
                .asteria-input-wrapper {
                    width: auto;
                    min-width: 40px;
                    .asteria-input {
                        &::placeholder,
                        &::-webkit-input-placeholder,
                        &.asteria-text-placeholder {
                            // opacity: 0;
                        }
                    }
                    display: flex;
                    flex: 1;
                }
                .asteria-controller-search-active-filters {
                    margin-left: 0px;
                }
                div[role='button'] {
                    background: rgb(234, 231, 228);
                    border-radius: 30px;
                    cursor: pointer;
                    margin-right: 8px;
                    padding-top: 0;
                    padding-bottom: 0;
                    padding: 0;
                    padding-right: 8px;
                    &.asteria-tag-type-status {
                        &.asteria-tag-status-PAID {
                            span {
                                display: none;
                            }
                        }
                        &.asteria-tag-status-FORECAST {
                            span {
                                background-color: rgb(255,255,255);
                                background-image: repeating-linear-gradient(135deg,transparent,transparent 2.5px,rgb(150,150,150) 3px,rgb(150,150,150) 4px,transparent 4.5px);
                            }
                        }
                    }
                    &:hover {
                        cursor: pointer;
                        background: rgb(213, 211, 209);
                    }
                    .asteria-tag-overview {
                        padding: 0;
                        height: auto;
                        background: transparent;    
                    }
                    .asteria-category-overview {
                        padding: 0;
                        height: auto;
                        background: transparent;
                    }  
                    .asteria-chip {
                        border-radius: 50px;
                        display: inline-flex;
                        padding: 5px 0 5px 12px;
                        -ms-flex-pack: center;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        border: 1px solid #eae7e4;
                        height: auto;
                        background: transparent;
                        .asteria-text {
                            border-radius: 0;
                        }
                    } 
                    .asteria-tag-remove {
                        background: transparent;
                        height: 32px !important;
                        width: 32px !important;
                    }
                }
            }
            &.asteria-state-focus {
                .asteria-search-area-wrapper {
                    max-width: 710px;
                }
                .asteria-search-area-controls {
                    /*                     width: 100%;
                    min-width: 100%; */
                    display: flex;
                    /* height: 54px; */
                    height: 40px;
                    flex-wrap: nowrap;
                    overflow-x: auto;
                    overflow-y: hidden;
                    overflow: -moz-scrollbars-none;
                    scrollbar-width: none;
                    -ms-overflow-style: none;
                    margin-bottom: -16px;
                    /* padding-right: 32px; */
                    scrollbar-width: none;
                    -ms-overflow-style: none;
                    -webkit-mask: none;

                    flex: 1;
    
                    &::-webkit-scrollbar {
                        height: 0 !important;
                    }

                    -webkit-mask: -webkit-gradient(
                        linear,
                        left top,
                        right top,
                        color-stop(0, black),
                        color-stop(0.45, black),
                        color-stop(0.7, black),
                        color-stop(0.85, black),
                        color-stop(1, rgba(0, 0, 0, 0))
                    );
                    mask: -webkit-gradient(
                        linear,
                        left top,
                        right top,
                        color-stop(0, black),
                        color-stop(0.45, black),
                        color-stop(0.7, black),
                        color-stop(0.85, black),
                        color-stop(1, rgba(0, 0, 0, 0))
                    );

                    &:after {
                        content: '&nbsp;';
                        display: block;
                        width: 80px;
                        background: transparent;
                        height: 30px;
                        color: transparent;
                        order: 99;
                    }
                    .asteria-input-wrapper {
                        min-width: max-content;
                        width: 100%;
                        .asteria-input {
                            min-width: 100%;
                        }
                    }
                }
                .asteria-controller-search-active-filters {

                }
            }
        }
    }

    .asteria-controller-search {
        display: flex;
        transition: all 0.5s;
        margin-right: 0;
        &:before {
            display: none;
            left: 0;
        }
        .asteria-search-area {
            flex: 1;
            padding-left: 0;
            &:before {
                left: 0;
                top: auto;
                bottom: 0;
            }
            div[role='button'] {
                .asteria-chip {
                    .asteria-text {
                        font-size: 14px !important;
                        font-family: Arial,Helvetica,sans-serif !important;
                        font-weight: 400 !important;
                    }
                }
            }
        }
        &.asteria-state-focus {
            // transform: translateX(-257px);
            // width: calc(100% + 257px);
            position: absolute;
            left: 0;
            top: 56px;
            right: auto;
            z-index: 1;
        }
        .asteria-controller-search-active-filters {
            margin-top: 0;
            // position: absolute;
            // left: 31px;
            // top: -4px;
        }
        .asteria-filter-nav {
            .asteria-search-results {
                .asteria-search-results-item {
                    &.asteria-search-results-item-nothing {
                        .asteria-text {
                            font-size: 14px !important;
                            font-family: Arial,Helvetica,sans-serif !important;
                            font-weight: 400 !important;
                        }
                    }
                }
            }
            .asteria-search-examples {
                div[role='button'] {
                    .asteria-chip {
                        .currency-flag {
                            max-width: 16px;
                            max-height: 12px;
                            overflow: hidden;
                            min-height: 12px;
                            min-width: 16px;
                            background-position: 50% 50%;
                        }
                        .asteria-text {
                            font-size: 14px !important;
                            font-family: Arial,Helvetica,sans-serif !important;
                            font-weight: 400 !important;
                        }
                    }
                }
            }
        }
    }
        
    .asteria-button-settings {
        .asteria-icon-svg {
            fill: #9f8c82 !important    ;
        }
        &.asteria-state-active {
            .asteria-icon-svg {
                fill: rgb(81, 43, 43) !important;
            }
        }
        &.asteria-forms-dropdown-open {
            .asteria-forms-dropdown-menu-direction-down {
                .asteria-text {
                    font-size: 14px !important;
                    color: #80584F !important;
                    font-family: Arial,Helvetica,sans-serif !important;
                    font-weight: 400 !important;
                }
            }
        }
    }

    .asteria-transaction-filter {
        top: 184px;
        .asteria-transaction-cell-settings {
            position: absolute;
            right: 0;
            .asteria-icon-wrapper {
                width: 16px !important;
                height: 16px !important;
                margin-left: 8px;
                .asteria-icon-svg {
                    fill: rgb(81, 43, 43) !important;
                }
            }
            .asteria-button-list-settings {
                // .asteria-forms-dropdown-toggle {
                //     border: 0px solid transparent;
                //     padding-right: 0;
                //     margin-right: 0;
                //     height: 32px;
                //     padding: 0 8px 0 16px;
                //     min-height: 32px;
                //     .asteria-text {
                //         font-family: Swedbank Sans;
                //         font-weight: 700;
                //         font-size: 14px;
                //         color: var(--text-color);
                //         text-decoration: none;
                //     }
                //     .asteria-icon-wrapper {
                //         width: 16px !important;
                //         height: 6px !important;
                //         margin-left: 8px;
                //         .asteria-icon-svg {
                //             fill: #9f8c82 !important;
                //         }
                //     }
                // }
                .asteria-forms-dropdown-menu {
                    .asteria-forms-dropdown-item {
                        .asteria-text {
                            font-weight: 400 !important;
                        }
                    }
                }
                &.asteria-forms-dropdown-open {
                     .asteria-forms-dropdown-menu-direction-down {
                        max-width: 200px;
                        right: 0px;
                        left: 0;
                        .asteria-forms-dropdown-item {
                            .asteria-text {
                                font-size: 14px !important;
                                color: #80584F !important;
                                font-family: Arial,Helvetica,sans-serif !important;
                                font-weight: 400 !important;
                            }
                        }
                     }
                }
            }
        }
    }

    .asteria-display-currency-navgiation {
        align-items: center;
        min-height: 0;
        border-bottom: 0px solid transparent;
        height: auto;
        .asteria-button {
            align-items: center;
            min-height: 0;
            border-bottom: 0px solid transparent;
            height: auto;
        }
        .asteria-forms-dropdown-toggle {
            .asteria-text {
                font-family: Swedbank Sans;
                font-weight: 700;
                font-size: 14px;
                color: var(--text-color);
                text-decoration: none;
            }
            .asteria-icon-wrapper {
                width: 16px !important;
                height: 6px !important;
                margin-left: 8px;
                .asteria-icon-svg {
                    fill: #9f8c82 !important;
                }
            }
            &:hover, &.asteria-state-active {
                .asteria-text {
                    color: rgb(238, 112, 35);
                }
                .asteria-icon-wrapper {
                    .asteria-icon-svg {
                        fill: #512b2b !important;
                    }
                }
            }
        }
        &.asteria-forms-dropdown-open {
             .asteria-forms-dropdown-menu-direction-down {
                top: 42px;
                min-width: 200px;
                right: auto;
                left: 0;
                transition: max-height 0s;
                .asteria-forms-dropdown-item {
                    &.asteria-state-active {
                        display: none;
                    }
                    .asteria-icon-currency {
                        margin-right: 4px;
                        order: -1;
                        justify-content: center;
                        align-items: center;
                        .currency-flag {
                            max-width: 16px;
                            max-height: 12px;
                            overflow: hidden;
                            min-height: 12px;
                            min-width: 16px;
                            background-position: 50% 50%;
                        }
                    }
                    .asteria-text {
                        font-size: 14px !important;
                        color: #80584F !important;
                        font-family: Arial,Helvetica,sans-serif !important;
                        font-weight: 400 !important;
                    }
                }
             }
         }
        .asteria-forms-dropdown-menu-direction-down {
            transition: max-height 0s !important;
        }
    }

    .asteria-display-clients {
        min-height: 0;
        border-bottom: 0px solid transparent;
        height: auto;
        .asteria-button {
            align-items: center;
            min-height: 0;
            border-bottom: 0px solid transparent;
            height: auto;
        }
        .asteria-forms-dropdown-toggle {
            .asteria-text {
                font-family: Swedbank Sans;
                font-weight: 700;
                font-size: 14px;
                color: var(--text-color);
                text-decoration: none;
            }
            .asteria-icon-wrapper {
                width: 16px !important;
                height: 6px !important;
                margin-left: 8px;
                .asteria-icon-svg {
                    fill: #9f8c82 !important;
                }
            }
            &:hover, &.asteria-state-active {
                .asteria-text {
                    color: rgb(238, 112, 35);
                }
                .asteria-icon-wrapper {
                    .asteria-icon-svg {
                        fill: #512b2b !important;
                    }
                }
            }
        }
        &.asteria-forms-dropdown-open {
            .asteria-forms-dropdown-menu-direction-down {
               top: 42px;
               min-width: 200px;
               right: 0px;
               left: auto;
               transition: max-height 0s;
               .asteria-forms-dropdown-item {
                    &.asteria-state-active {
                        display: none;
                    }
                    .asteria-text {
                        font-size: 14px !important;
                        color: #80584F !important;
                        font-family: Arial,Helvetica,sans-serif !important;
                        font-weight: 400 !important;
                    }
                }
            }
       }
        .asteria-forms-dropdown-menu-direction-down {
            transition: max-height 0s !important;
        }
    }

    .asteria-display-status-navgiation {
        align-items: center;
        min-height: 0;
        border-bottom: 0px solid transparent;
        height: auto;
        .asteria-button {
            align-items: center;
            min-height: 0;
            border-bottom: 0px solid transparent;
            height: auto;
        }
        .asteria-forms-dropdown-toggle {
            border: 0px solid transparent;
            padding-right: 0;
            margin-right: 0;
            height: 32px;
            padding: 0 8px;
            min-height: 32px;
            .asteria-text {
                font-family: Swedbank Sans;
                font-weight: 700;
                font-size: 14px;
                color: var(--text-color);
                text-decoration: none;
            }
            .asteria-icon-wrapper {
                width: 16px !important;
                height: 6px !important;
                margin-left: 8px;
                .asteria-icon-svg {
                    fill: #9f8c82 !important;
                }
            }
            &:hover, &.asteria-state-active {
                .asteria-text {
                    color: rgb(238, 112, 35);
                }
                .asteria-icon-wrapper {
                    .asteria-icon-svg {
                        fill: #512b2b !important;
                    }
                }
            }
        }
        &.asteria-forms-dropdown-open {
             .asteria-forms-dropdown-menu-direction-down {
                top: 42px;
                min-width: 200px;
                right: 0px;
                left: auto;
                transition: max-height 0s;
                .asteria-forms-dropdown-item {
                    &.asteria-state-active {
                        display: none;
                    }
                    .asteria-text {
                        font-size: 14px !important;
                        color: #80584F !important;
                        font-family: Arial,Helvetica,sans-serif !important;
                        font-weight: 400 !important;
                    }
                }
             }
        }
        .asteria-forms-dropdown-menu-direction-down {
            transition: max-height 0s;
         }
    }

    .asteria-display-financial-navigation {
        align-items: center;
        min-height: 0;
        border-bottom: 0px solid transparent;
        height: auto;
        .asteria-button {
            align-items: center;
            min-height: 0;
            border-bottom: 0px solid transparent;
            height: auto;
        }
        .asteria-forms-dropdown-toggle {
            border: 0px solid transparent;
            padding-right: 0;
            margin-right: 0;
            height: 32px;
            padding: 0 8px;
            min-height: 32px;
            .asteria-text {
                font-family: Swedbank Sans;
                font-weight: 700;
                font-size: 14px;
                color: var(--text-color);
                text-decoration: none;
            }
            .asteria-icon-wrapper {
                width: 16px !important;
                height: 6px !important;
                margin-left: 8px;
                .asteria-icon-svg {
                    fill: #9f8c82 !important;
                }
            }
            &:hover, &.asteria-state-active {
                .asteria-text {
                    color: rgb(238, 112, 35);
                }
                .asteria-icon-wrapper {
                    .asteria-icon-svg {
                        fill: #512b2b !important;
                    }
                }
            }
        }
        &.asteria-forms-dropdown-open {
             .asteria-forms-dropdown-menu-direction-down {
                top: 42px;
                min-width: 200px;
                right: 0px;
                left: auto;
                transition: max-height 0s;
                .asteria-forms-dropdown-item {
                    &.asteria-state-active {
                        display: none;
                    }
                    .asteria-text {
                        font-size: 14px !important;
                        color: #80584F !important;
                        font-family: Arial,Helvetica,sans-serif !important;
                        font-weight: 400 !important;
                    }
                }
             }
        }
        .asteria-forms-dropdown-menu-direction-down {
            transition: max-height 0s;
         }
    }

    .asteria-button-settings {
        position: absolute;
        right: 0;
        top: 50px;
    }

    &.asteria-cashflow-graph-layout-flat {
        .asteria-graph-lines {
            margin-bottom: -32px;
            .asteria-graph-xaxis-history, .asteria-graph-xaxis-today, .asteria-graph-xaxis-forecast {
                display: none;
                &.asteria-state-hover, &.asteria-state-active {
                    display: none;
                }
            }
        }
        .asteria-cashflow-graph-time-navigation {
            .asteria-button-left, .asteria-button-right {
                bottom: 21px !important;
                background: #fff;
                width: 51px;
                border-radius: 0;
            }
            .asteria-button-left {
                margin-left: 0 !important;
            }
            .asteria-button-right {
                margin-right: 0 !important;
            }
        }
    }

    &.asteria-cashflow-graph-layout-tabs {
        .asteria-graph-lines {
            .asteria-graph-area-lines {
                .asteria-graph-line-group {
                    height: 204px;
                    grid-template-rows: 204px;
                }
            }
        }
        .asteria-graph-line-graph-component {
            
        }
        .asteria-cashflow-graph-time-navigation {
            .asteria-button-left, .asteria-button-right {
                bottom: 21px !important;
                background: #fff;
                width: 51px;
                border-radius: 0;
            }
            .asteria-button-left {
                margin-left: 0;
            }
            .asteria-button-right {
                margin-right: 0;
            }
        }
    }

    &.asteria-cashflow-graph-stacked {
        .asteria-graph-line-graph-component {
            margin-top: 5px !important;
            margin-right: 0px !important;
            .asteria-part-risk {
                margin-top: -2px;
            }
        }
        .asteria-graph-controlls-menu-tag-unpaid,
        .asteria-graph-controlls-menu-tag-overdue {
            margin-top: 0px;
        }
        .asteria-curreny-y-axis {
            margin-top: 3px !important;
        }
        .asteria-cashflow-graph-time-navigation {
            .asteria-button-left, .asteria-button-right {
                bottom: 21px !important;
            }
        }
        .asteria-cashflow-graph-time-navigation {
            .asteria-button-left, .asteria-button-right {
                bottom: 21px !important;
                background: #fff;
                width: 51px;
                border-radius: 0;
            }
            .asteria-button-left {
                margin-left: 0 !important;
            }
            .asteria-button-right {
                margin-right: 0 !important;
            }
        }
    }

    .asteria-graph-line-graph-legends .asteria-part-risk .asteria-text, .asteria-graph-line-graph-component .asteria-part-risk .asteria-text {
        margin-top: -4px;
        margin-left: -7px;
    }

    // Font fix

    .asteria-tabs-navigation {
        .asteria-tab-navigation-item {
            .asteria-text {
                font-size: 14px !important;
                color: #80584F !important;
                font-family: Arial, Helvetica, sans-serif !important;
                font-weight: 400 !important;
            }
        }
    }

    .div[role='button'] .asteria-chip .asteria-text {
        font-size: 14px !important;
        color: #80584F !important;
        font-family: Arial, Helvetica, sans-serif !important;
        font-weight: 400 !important;
    }

    .asteria-search-wrapper .asteria-controller-search .asteria-input-wrapper .asteria-input {
        font-size: 14px !important;
        color: #80584F !important;
        font-family: Arial, Helvetica, sans-serif !important;
        font-weight: 400 !important;
    }

    .asteria-display-filter-navigation .asteria-forms-dropdown-toggle .asteria-text {
        font-size: 14px !important;
        color: #80584F !important;
        font-family: Arial, Helvetica, sans-serif !important;
        font-weight: 400 !important;
    }

    .asteria-display-financial-navigation .asteria-forms-dropdown-toggle .asteria-text {
        font-size: 14px !important;
        color: #80584F !important;
        font-family: Arial, Helvetica, sans-serif !important;
        font-weight: 400 !important;
    }

    .asteria-display-status-navgiation .asteria-forms-dropdown-toggle .asteria-text {
        font-size: 14px !important;
        color: #80584F !important;
        font-family: Arial, Helvetica, sans-serif !important;
        font-weight: 400 !important;
    }

    .asteria-display-currency-navgiation .asteria-forms-dropdown-toggle .asteria-text {
        font-size: 14px !important;
        color: #80584F !important;
        font-family: Arial, Helvetica, sans-serif !important;
        font-weight: 400 !important;
    }

    .asteria-display-clients .asteria-forms-dropdown-toggle .asteria-text {
        font-size: 14px !important;
        color: #80584F !important;
        font-family: Arial, Helvetica, sans-serif !important;
        font-weight: 400 !important;
    }

    .asteria-forms-dropdown-menu .asteria-forms-dropdown-item .asteria-text {
        // font-size: 14px !important;
        // font-family: Arial, Helvetica, sans-serif !important;
        // font-weight: 400 !important;
    }

    .asteria-graph-line-graph-legends {
        margin-left: 68px !important;
    }

    .asteria-graph-line-graph-legends, .asteria-graph-line-graph-component {
        span {
            height: 8px !important;
            width: 8px !important;
            margin-right: 4px !important;
        }
        .asteria-text {
            font-size: 12px !important;
            color: #80584F !important;
            font-family: Arial, Helvetica, sans-serif !important;
            font-weight: 400 !important;
        }
        .asteria-part-history {
            span {
                background-size: 9px !important;
            }
        }
        .asteria-part-forecast {
            span {
                background-size: 8px !important;
            }
        }
        .asteria-part-credit {
            span {
                // background-size: 6px !important;
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAMCAYAAACwXJejAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACqSURBVHgBjY5NDoIwEEYHhH1DN2I3XEPd6A28gXoDjwA34AYeBTQix2hXhpXpzsSGjmWhIbapvqRNf943MwD/cKWMt5RtfU7QUIbDwWz1JI73806Ibyn82AArrRQ3oWM7zTKnNGKHSlWXJD1Y7ZyzAAiz8gg8IJq81sIpmS8ZIJbL+60Y7pYUoC6f+lGspZTvt2iUrsO+zxeyO1mlm2TGzzTdgI+KEAI/eAHlpTrLZEqSiwAAAABJRU5ErkJggg==) !important;
                background-repeat: no-repeat !important;
                background-position: 50% 50% !important;
                height: 15px !important;
            }
        }
        .asteria-part-risk {
            span {
                height: 6px !important;
                width: 6px !important;
                margin-right: 6px !important;
                &:after {
                    height: 4px;
                    width: 4px;
                }
            }
            .asteria-text {
                margin-top: -4px;
            }
        }
    }

    .asteria-graph-lines .asteria-graph-area-lines .asteria-graph-xaxis-history .asteria-time-divider {transform: translateY(2px);}

    .asteria-cashflow-graph {
        &.asteria-feature-layout-resizable {
            // Pushes yAXIS outside wrapper and makes graph 100% but resize object goes outside 
            width: calc(105% + 12px) !important;
            margin-left: -72px;
            // end
        }

        &.asteria-state-small {
            &.asteria-cashflow-graph-layout-flat {
                .asteria-graph-lines {
                    .asteria-graph-yaxis-labels {
                        .asteria-graph-yaxis-label {
                            &:first-of-type {
                                opacity: 1;
                            }
                            opacity: 0;
                            &:last-of-type {
                                opacity: 1;
                            }
                            &.asteria-graph-yaxis-label-zero {
                                opacity: 1;
                            }
                        }
                        font-size: 9px !important;
                    }
                    .asteria-graph-yaxis-line {
                        &:first-of-type {
                            border-top: 1px solid #E9D7CA;
                        }
                        border-top: 1px solid transparent;
                        &:last-of-type {
                            border-top: 1px solid #E9D7CA;
                        }
                        &.asteria-graph-yaxis-line-zero {
                            border-top: 2px solid #725251;
                        }
                    }
                }
                .asteria-graph-bars {
                    .asteria-graph-yaxis-labels {
                        .asteria-graph-yaxis-label {
                            &:first-of-type {
                                opacity: 1;
                                display: flex;
                            }
                            opacity: 0;
                            display: none;
                            &:last-of-type {
                                opacity: 1;
                                display: flex;
                            }
                            &.asteria-graph-yaxis-label-zero {
                                opacity: 1;
                                display: flex;
                            }
                            font-size: 9px !important;
                        }
                    }
                    .asteria-graph-yaxis-line {
                        &:first-of-type {
                            border-top: 1px solid #E9D7CA;
                            display: flex;
                        }
                        border-top: 1px solid transparent;
                        display: none;
                        &:last-of-type {
                            border-top: 1px solid #E9D7CA;
                            display: flex;
                        }
                        &.asteria-graph-yaxis-line-zero {
                            border-top: 2px solid #725251;
                            display: flex;
                        }
                    }
                }
            }
            &.asteria-cashflow-graph-layout-tabs {
                .asteria-tabs-wrapper {
                    height: calc(100% - 52px);
                }
            }
        }
        
        &.asteria-state-medium {
            &.asteria-cashflow-graph-layout-flat {
                .asteria-graph-lines {
                    .asteria-graph-yaxis-labels {
                        .asteria-graph-yaxis-label {
                            &:first-of-type {
                                opacity: 1;
                            }
                            opacity: 0;
                            &:last-of-type {
                                opacity: 1;
                            }
                            &.asteria-graph-yaxis-label-zero {
                                opacity: 1;
                            }
                        }
                    }
                    .asteria-graph-yaxis-line {
                        &:first-of-type {
                            border-top: 1px solid #E9D7CA;
                        }
                        border-top: 1px solid transparent;
                        &:last-of-type {
                            border-top: 1px solid #E9D7CA;
                        }
                        &.asteria-graph-yaxis-line-zero {
                            border-top: 2px solid #725251;
                        }
                    }
                }
                .asteria-graph-bars {
                    .asteria-graph-yaxis-labels {
                        .asteria-graph-yaxis-label {
                            &:first-of-type {
                                opacity: 1;
                                display: flex;
                            }
                            opacity: 0;
                            display: none;
                            &:last-of-type {
                                opacity: 1;
                                display: flex;
                            }
                            &:nth-of-type(3) {
                                opacity: 1;
                                display: flex;
                            }
                            &.asteria-graph-yaxis-label-zero {
                                opacity: 1;
                                display: flex;
                            }
                        }
                    }
                    .asteria-graph-yaxis-line {
                        &:first-of-type {
                            border-top: 1px solid #E9D7CA;
                            display: flex;
                        }
                        border-top: 1px solid transparent;
                        display: none;
                        &:last-of-type {
                            border-top: 1px solid #E9D7CA;
                            display: flex;
                        }
                        &:nth-of-type(3) {
                            border-top: 1px solid #E9D7CA;
                            display: flex;
                        }
                        &.asteria-graph-yaxis-line-zero {
                            border-top: 2px solid #725251;
                            display: flex;
                        }
                    }
                }
            }
        }

        &.asteria-state-large {
        }

        .asteria-button-graph-toggle-accountbalance {
            top: -8px;
            right: 0;
            padding-right: 0;
        }
        &.asteria-cashflow-graph-hide-account-balance {
            &.asteria-cashflow-graph-stacked {

            }
            .asteria-graph-line-graph-component {
                &:first-of-type {
                    height: auto !important;
                    padding-right: 4px !important;
                    padding-top: 2px !important;
                    padding-bottom: 2px !important;
                    border-radius: 0;
                    overflow: visible;
                }
            }
            .asteria-graph-controller-menu {
                height: auto !important;
                padding-right: 16px !important;
                padding-top: 0px !important;
                padding-bottom: 12px !important;
            }
            .asteria-graph-lines {
                margin-bottom: 0;
            }
            &.asteria-cashflow-graph-layout-tabs {
                .asteria-graph-line-group {
                    display: flex;
                }
                .asteria-graph-lines {
                    height: calc(100% - 22px) !important;
                }
                .asteria-graph-bars {
                    height: calc(100% - 32px) !important;
                }
            }
            .asteria-button-right, .asteria-button-left {
                top: auto !important;
            }
        }

        margin-bottom: 16px;
        // display: grid;
        .asteria-graph-line-graph-legends {
            // order: 1;
            // justify-content: center;
            // margin-top: -4px;
        }
        .asteria-graph-lines {
            // margin-top: 20px;
            // order: 0;
        }
        .asteria-graph-controller-menu {
            // order: 3;
            // justify-content: center;
        }
        .asteria-graph-bars {
            // order: 2;
            .asteria-curreny-y-axis {
                // display: none;
            }
        }
    }

    .asteria-graph-lines {
        width: 100% !important;
        .asteria-graph-area-lines {
            .asteria-graph-xaxis-history {
                .asteria-time-divider {
                    font-size: 12px !important;
                    color: #80584F !important;
                    font-family: Arial, Helvetica, sans-serif !important;
                    font-weight: 400 !important; 
                    height: auto !important;
                    width: auto !important;
                    margin-right: auto !important; 
                    margin-left: auto !important; 
                }
                .asteria-text {
                    font-size: 12px !important;
                    color: #80584F !important;
                    font-family: Arial, Helvetica, sans-serif !important;
                    font-weight: 400 !important;
                }
                .asteria-time-indicator {
                    bottom: 0px;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 4px solid rgb(244,194,78);
                    bottom: 0px;
                    height: auto !important;
                    width: auto !important;
                    margin-right: auto !important;
                }
            }
            .asteria-graph-xaxis-today {
                .asteria-time-divider {
                    font-size: 12px !important;
                    color: #80584F !important;
                    font-family: Arial, Helvetica, sans-serif !important;
                    font-weight: 400 !important;  
                    height: auto !important;
                    width: auto !important;
                    margin-right: auto !important;
                    margin-left: auto !important; 
                }
                .asteria-text {
                    font-size: 12px !important;
                    color: #512B2B !important;
                    font-family: Arial, Helvetica, sans-serif !important;
                    font-weight: 600 !important;
                }
                .asteria-time-indicator {
                    bottom: 0px;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 4px solid rgb(244,194,78);
                    display: block;
                    height: auto !important;
                    width: auto !important;
                    margin-right: auto !important;
                }
            }
            .asteria-graph-xaxis-forecast {
                .asteria-time-divider {
                    font-size: 12px !important;
                    color: #80584F !important;
                    font-family: Arial, Helvetica, sans-serif !important;
                    font-weight: 400 !important;  
                    height: auto !important;
                    width: auto !important;
                    margin-right: auto !important;
                    margin-left: auto !important; 
                }
                .asteria-text {
                    font-size: 12px !important;
                    color: #80584F !important;
                    font-family: Arial, Helvetica, sans-serif !important;
                    font-weight: 400 !important;
                }
                .asteria-time-indicator {
                    bottom: 0px;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 4px solid rgb(244,194,78);
                    height: auto !important;
                    width: auto !important;
                    margin-right: auto !important;
                }
            }
        }
    }

    .asteria-graph-bars {
        width: 100% !important;
        .asteria-graph-area-bars {
            .asteria-graph-xaxis-history {
                .asteria-time-divider {
                    font-size: 12px !important;
                    color: #80584F !important;
                    font-family: Arial, Helvetica, sans-serif !important;
                    font-weight: 400 !important;  
                }
                .asteria-text {
                    font-size: 12px !important;
                    color: #80584F !important;
                    font-family: Arial, Helvetica, sans-serif !important;
                    font-weight: 400 !important;
                }
                .asteria-time-indicator {
                    bottom: 0px;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 4px solid rgb(244,194,78);
                }
            }
            .asteria-graph-xaxis-today {
                .asteria-time-divider {
                    font-size: 12px !important;
                    color: #80584F !important;
                    font-family: Arial, Helvetica, sans-serif !important;
                    font-weight: 400 !important;  
                }
                .asteria-text {
                    font-size: 12px !important;
                    color: #512B2B !important;
                    font-family: Arial, Helvetica, sans-serif !important;
                    font-weight: 600 !important;
                }
                .asteria-time-indicator {
                    bottom: 0px;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 4px solid rgb(244,194,78);
                    display: block;
                }
            }
            .asteria-graph-xaxis-forecast {
                .asteria-time-divider {
                    font-size: 12px !important;
                    color: #80584F !important;
                    font-family: Arial, Helvetica, sans-serif !important;
                    font-weight: 400 !important;  
                }
                .asteria-text {
                    font-size: 12px !important;
                    color: #80584F !important;
                    font-family: Arial, Helvetica, sans-serif !important;
                    font-weight: 400 !important;
                }
                .asteria-time-indicator {
                    bottom: 0px;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 4px solid rgb(244,194,78);
                }
            }
        }
    }

    .asteria-graph-controller-menu {
        > div > div > div {
            margin-right: 12px !important;
        }
        span {
            height: 8px !important;
            width: 8px !important;
            margin-right: 4px !important;
        }
        .asteria-text {
            font-size: 12px !important;
            color: #80584F !important;
            font-family: Arial, Helvetica, sans-serif !important;
            font-weight: 400 !important;
        } 
    }

    .asteria-floating-navigation {
        z-index: 8;
    }

    .asteria-transaction-list-compress {
        .asteria-transactions {
            .asteria-transactions-list-wrapper {
                .asteria-transactions-empty {
                    height: calc(100% - 32px);
                }
                .asteria-transactions-list-wrapper-inner {
                    .asteria-transaction-filter {
                        background: #E2DCD9 !important;
                        height: 28px;
                        // top: 188px !important;
                        .asteria-transaction-cell {
                            .asteria-text {
                                font-size: 12px !important;
                                color: #4C2D2C !important;
                                font-family: Arial, Helvetica, sans-serif !important;
                                max-width: 90%;
                            }
                            .asteria-icon-wrapper {
                                width: 8px;
                                height: 20px;
                                .asteria-icon-svg {
                                    fill: #9F8C82;
                                }
                            }
                            &.asteria-state-active {
                                .asteria-icon-wrapper {
                                    width: 8px;
                                    height: 20px;
                                    .asteria-icon-svg {
                                        fill: #9F8C82;
                                    }
                                }
                            }
                        }
                    }
                    .asteria-list-wrapper {
                        // top: 33px;
                        top: 2px;
                        .asteria-list-content {
                            &.asteria-transactions-edit {
                                .asteria-transaction {
                                    &:hover, &.asteria-state-hover, &.asteria-state-active {
                                        .asteria-transaction-wrapper-inner {
                                            background: #fff;
                                        }
                                    }   
                                }
                            }
                            .asteria-transactions-divider {
                                .asteria-transactions-divider-row {
                                    height: 28px;
                                    .asteria-adjustable-total {
                                        .asteria-icon-wrapper {
                                            .asteria-icon-svg {
                                                fill: #53a1ac !important;
                                            }
                                        }
                                    }
                                    .asteria-title {
                                        font-size: 12px !important;
                                    }
                                    .asteria-text {
                                        font-size: 12px !important;
                                    }
                                }
                            }
                            .asteria-transaction {
                                .asteria-transaction-wrapper-inner {
                                    min-height: 28px;
                                    height: 28px;
                                    .asteria-transaction-cell {
                                        overflow: visible;
                                        &.asteria-transaction-cell-status {
                                            align-items: center;
                                            .asteria-checkbox-group-wrapper {
                                                padding-bottom: 0;
                                                padding-top: 0;
                                                .asteria-checkbox-mark {
                                                    width: 14px;
                                                    height: 14px;
                                                    margin-left: 2px;
                                                }
                                            }
                                        }
                                        &.asteria-transaction-cell-description {
                                        }
                                        .asteria-text {
                                            font-size: 12px !important;
                                            color: #80584F !important;
                                            font-family: Arial, Helvetica, sans-serif !important;
                                            font-weight: 400 !important;
                                            &.asteria-text-small {
                                                font-size: 10px !important;
                                            }
                                        }
                                        &.asteria-transaction-cell-categories {
                                            .asteria-transaction-list-tags {
                                                span {
                                                    height: 8px;
                                                    width: 8px;
                                                    margin-right: 4px;
                                                }
                                            }
                                        }
                                        &.asteria-transaction-cell-total {
                                            .asteria-security-bar {
                                                width: 10px;
                                                height: 15px;
                                            }
                                        }
                                    }
                                    &.asteria-transaction-edit {
                                        .asteria-transaction-cell {
                                            // align-self: stretch;
                                            .asteria-datepicker {
                                                display: flex;
                                                flex: 1;
                                                > div {
                                                    display: flex;
                                                    flex: 1;
                                                }
                                                .asteria-input-wrapper {
                                                    .asteria-input {
                                                        
                                                    }
                                                }
                                                .asteria-calendar-calendars {
                                                    left: 0;
                                                    top: 38px;
                                                    bottom: auto;
                                                    .asteria-calendar-wrapper {
                                                        box-shadow: rgb(0 0 0 / 25%) 0px 3px 5px 0px;
                                                        min-width: 300px;
                                                    }
                                                }
                                                .asteria-button-icon-only {
                                                    // right: 119px;
                                                    top: 0px;
                                                    right: 0;
                                                    width: 40px;
                                                    height: 100%;
                                                    padding: 9px 11px;
                                                    border-bottom: 2px solid transparent;
                                                    &:hover, &.asteria-state-hover, &.asteria-state-active {
                                                        background: #f2f2f2;
                                                        border-bottom: 2px solid #257886;
                                                    }
                                                }
                                            }
                                            .asteria-input-wrapper {
                                                height: 100%;
                                                flex: 1;
                                                background: transparent; 
                                                border-bottom-color: transparent;
                                                align-items: center;
                                                .asteria-input {
                                                    font-size: 12px !important;
                                                    color: #80584F !important;
                                                    font-family: Arial,Helvetica,sans-serif !important;
                                                    font-weight: 400 !important;
                                                }
                                                &:hover, &.asteria-state-hover, &.asteria-state-active, &.asteria-state-focus {
                                                    border-bottom-color: #257886;
                                                    background: #f2f2f2;
                                                }
                                            }
                                            .asteria-forms-dropdown {
                                                flex-grow: 1;
                                                .asteria-forms-dropdown-toggle {
                                                    height: auto;
                                                    min-height: auto;
                                                    text-align: left;
                                                    justify-content: flex-start;
                                                    border-bottom-color: transparent;
                                                    background: transparent;
                                                    .asteria-text {
                                                        text-decoration: none;
                                                    }
                                                    .asteria-icon-wrapper {
                                                        .asteria-icon-svg {
                                                            fill: #53a1ac !important
                                                        }
                                                    }
                                                    &:hover, &.asteria-state-hover, &.asteria-state-active, &.asteria-state-focus {
                                                        border-bottom-color: #257886;
                                                        background: #f2f2f2;
                                                    }
                                                }
                                                .asteria-forms-dropdown-menu {
                                                    box-shadow: rgb(0 0 0 / 25%) 0px 3px 5px 0px;
                                                    max-width: none;
                                                    width: 100%;
                                                    .asteria-forms-dropdown-item {
                                                        max-width: none;
                                                        .asteria-option-inner {
                                                            flex: 1;
                                                        }
                                                    }
                                                    &.asteria-forms-dropdown-menu-direction-down {
                                                        left: 0;
                                                        top: 38px;
                                                    }
                                                }
                                            }
                                            &.asteria-transaction-cell-status {
                                                align-items: center;
                                                .asteria-checkbox-group-wrapper {
                                                    padding-bottom: 0;
                                                    .asteria-checkbox-mark {
                                                        width: 12px;
                                                        height: 12px;
                                                        margin-left: 2px;
                                                    }
                                                }
                                            }
                                            &.asteria-transaction-cell-toggle {
                                                .asteria-button {
                                                    &:after {
                                                        display: none;
                                                    }
                                                }
                                            }
                                            &.asteria-transaction-cell-categories {
                                                align-items: center;
                                            }
                                            &.asteria-transaction-cell-flat-status {
                                                opacity: 0;
                                            }
                                            &.asteria-transaction-cell-toggle {
                                                .asteria-button {
                                                    flex: 1;
                                                    display: flex;
                                                    height: 100%;
                                                }
                                            }
                                        }
                                    }
                                }
                                &:hover, &.asteria-state-hover, &.asteria-state-active {
                                    .asteria-transaction-wrapper-inner {
                                        background: #ECE8E6;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .asteria-transactions {
        .asteria-transactions-list-wrapper {
            .asteria-transactions-list-wrapper-inner {
                .asteria-transaction-filter {
                    background: #E2DCD9 !important;
                    height: 35px;
                    // top: 188px !important;
                    .asteria-transaction-cell {
                        .asteria-text {
                            font-size: 14px !important;
                            color: #4C2D2C !important;
                            font-family: Arial, Helvetica, sans-serif !important;
                            font-weight: 600 !important;
                            max-width: 90%;
                        }
                        .asteria-icon-wrapper {
                            width: 8px;
                            height: 20px;
                            .asteria-icon-svg {
                                fill: #9F8C82;
                            }
                        }
                        &.asteria-state-active {
                            .asteria-icon-wrapper {
                                width: 8px;
                                height: 20px;
                                .asteria-icon-svg {
                                    fill: #9F8C82;
                                }
                            }
                        }
                        &.asteria-transaction-cell-settings {
                            .asteria-button-list-settings {
                                .asteria-forms-dropdown-menu {
                                    max-width: 200px;
                                    top: 38px;
                                    .asteria-forms-dropdown-item {
                                        .asteria-text {
                                            font-weight: 400 !important;
                                            font-size: 14px !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .asteria-list-wrapper {
                    .asteria-list-content {
                        &.asteria-transactions-edit {
                            .asteria-transaction-cell-risk,
                            .asteria-transaction-cell-flat-status {
                                display: none !important;
                            }
                            .asteria-transactions-divider {
                                &.asteria-transactions-divider-feature-inline-edit {
                                    padding: 0 0 0 12px;
                                    .asteria-transactions-divider-row {
                                        .asteria-amount-divider {
                                            display: none;
                                        }
                                        .asteria-security-bar {
                                            display: none;
                                        }
                                        .asteria-adjustable-total-account-balance {
                                            order: 2;
                                            position: relative;
                                            display: flex;
                                            max-height: 32px;
                                            .asteria-form-group {
                                                .asteria-input-wrapper {
                                                    opacity: 0.7;
                                                    border-bottom-color: transparent;
                                                    .asteria-input {
                                                        cursor: no-drop;
                                                    }
                                                }
                                            }
                                            .asteria-tooltip-trigger-wrapper {
                                                position: absolute;
                                                right: 8px;
                                                z-index: 1;
                                                top: 8px;
                                                .asteria-icon-info {
                                                    .asteria-icon-svg {
                                                        fill: #9f8c82 !important;
                                                    }
                                                }
                                            }
                                        }  
                                        .list-divider-inline-edit {
                                            height: 32px;
                                            order: 3;
                                        }
                                        .asteria-input-wrapper {
                                            height: 100%;
                                            flex: 1;
                                            background: #fff; 
                                            border-bottom-color: #C4B1A8;
                                            min-height: auto;
                                            align-items: center;
                                            .asteria-input {
                                                height: 38px;
                                                font-size: 12px !important;
                                                color: #80584F !important;
                                                font-family: Arial,Helvetica,sans-serif !important;
                                                font-weight: 400 !important;
                                            }
                                            &:hover, &.asteria-state-hover, &.asteria-state-active, &.asteria-state-focus {
                                                border-bottom-color: #257886;
                                                background: #f2f2f2;
                                            }
                                        }
                                        .asteria-adjustable-total {
                                            order: 4;
                                            .asteria-icon-wrapper {
                                                height: 16px !important;
                                                width: 16px !important;
                                                &.asteria-icon-check {
                                                    .asteria-icon-svg {
                                                        fill: #e5561b !important;
                                                    }
                                                }
                                            }
                                        }
                                        .asteria-adjustable {
                                            margin: 0 0 0 24px;
                                            order: 3;
                                        }
                                        .asteria-button-link {
                                            padding: 0px;
                                            margin-left: 16px;
                                            margin-right: 8px;
                                            .asteria-text {
                                                font-size: 12px !important;
                                                color: #31818f;
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }
                            }
                            .asteria-transaction {
                                &:hover, &.asteria-state-hover, &.asteria-state-active {
                                    .asteria-transaction-wrapper-inner {
                                        background: #fff;
                                    }
                                }   
                            }
                        }
                        .asteria-transactions-divider {
                            .asteria-transactions-divider-row {
                                height: 38px;
                                .asteria-adjustable-total {
                                    .asteria-icon-wrapper {
                                        .asteria-icon-svg {
                                            fill: #53a1ac !important;
                                        }
                                    }
                                }
                                .asteria-adjustable {
                                    margin: 0;
                                    padding: 0 8px;
                                    .asteria-text {
                                        display: none;
                                    }
                                    .asteria-icon-wrapper {
                                        height: 16px !important;
                                        width: 16px !important;
                                    }
                                }
                            }
                            .asteria-input-wrapper {
                                height: 100%;
                                flex: 1;
                                background: transparent; 
                                border-bottom-color: transparent;
                                align-items: center;
                                .asteria-input {
                                    font-size: 12px !important;
                                    color: #80584F !important;
                                    font-family: Arial,Helvetica,sans-serif !important;
                                    font-weight: 400 !important;
                                }
                                &:hover, &.asteria-state-hover, &.asteria-state-active, &.asteria-state-focus {
                                    border-bottom-color: #257886;
                                    background: #f2f2f2;
                                }
                            }
                        }
                        .asteria-transaction {
                            .asteria-transaction-wrapper-inner {
                                min-height: 38px;
                                .asteria-transaction-cell {
                                    overflow: visible;
                                    &.asteria-transaction-cell-description {
                                    }
                                    .asteria-text {
                                        font-size: 14px !important;
                                        color: #80584F !important;
                                        font-family: Arial, Helvetica, sans-serif !important;
                                        font-weight: 400 !important;
                                        &.asteria-text-small {
                                            font-size: 12px !important;
                                        }
                                    }
                                    &.asteria-transaction-cell-categories {
                                        .asteria-transaction-list-tags {
                                            span {
                                                // height: 8px;
                                                // width: 8px;
                                                // margin-right: 4px;
                                            }
                                        }
                                    }
                                }
                                &.asteria-transaction-edit {
                                    &.asteria-transaction-edit-removed {
                                        animation: fadeOut forwards 2s 1s;
                                        //animation-delay: 1s;
                                    }
                                    .asteria-forms-dropdown {
                                        .asteria-forms-dropdown-toggle {
                                            // background: transparent !important;
                                        }
                                    }
                                    .asteria-transaction-cell {
                                        // align-self: stretch;
                                        .asteria-datepicker {
                                            display: flex;
                                            flex: 1;
                                            > div {
                                                display: flex;
                                                flex: 1;
                                            }
                                            .asteria-input-wrapper {
                                                .asteria-input {
                                                    
                                                }
                                            }
                                            .asteria-calendar-calendars {
                                                left: 0;
                                                top: 38px;
                                                bottom: auto;
                                                .asteria-calendar-wrapper {
                                                    box-shadow: rgb(0 0 0 / 25%) 0px 3px 5px 0px;
                                                    min-width: 300px;
                                                }
                                            }
                                            .asteria-button-icon-only {
                                                // right: 119px;
                                                top: 0px;
                                                right: 0;
                                                width: 40px;
                                                height: 100%;
                                                padding: 9px 11px;
                                                border-bottom: 2px solid transparent;
                                                &:hover, &.asteria-state-hover, &.asteria-state-active {
                                                    background: #f2f2f2;
                                                    border-bottom: 2px solid #257886;
                                                }
                                            }
                                        }
                                        .asteria-input-wrapper {
                                            height: 100%;
                                            flex: 1;
                                            background: transparent; 
                                            border-bottom-color: transparent;
                                            align-items: center;
                                            .asteria-input {
                                                font-size: 14px !important;
                                                color: #80584F !important;
                                                font-family: Arial,Helvetica,sans-serif !important;
                                                font-weight: 400 !important;
                                            }
                                            &:hover, &.asteria-state-hover, &.asteria-state-active, &.asteria-state-focus {
                                                border-bottom-color: #257886;
                                                background: #f2f2f2;
                                            }
                                        }
                                        .asteria-forms-dropdown {
                                            flex-grow: 1;
                                            .asteria-forms-dropdown-toggle {
                                                height: auto;
                                                min-height: auto;
                                                text-align: left;
                                                justify-content: flex-start;
                                                border-bottom-color: transparent;
                                                .asteria-text {
                                                    text-decoration: none;
                                                }
                                                .asteria-icon-wrapper {
                                                    .asteria-icon-svg {
                                                        fill: #9f8b82; !important
                                                    }
                                                }
                                                &:hover, &.asteria-state-hover, &.asteria-state-active, &.asteria-state-focus {
                                                    border-bottom-color: #257886;
                                                    background: #f2f2f2;
                                                }
                                            }
                                            .asteria-forms-dropdown-menu {
                                                box-shadow: rgb(0 0 0 / 25%) 0px 3px 5px 0px;
                                                max-width: none;
                                                width: 100%;
                                                .asteria-forms-dropdown-item {
                                                    max-width: none;
                                                    .asteria-option-inner {
                                                        flex: 1;
                                                    }
                                                }
                                                &.asteria-forms-dropdown-menu-direction-down {
                                                    left: 0;
                                                    top: 38px;
                                                }
                                            }
                                        }
                                        &.asteria-state-disabled {
                                            opacity: 0.5;
                                            .asteria-tooltip-trigger-wrapper {
                                                cursor: default;
                                            }
                                        }
                                        &.asteria-transaction-cell-status {
                                            align-items: center;
                                            opacity: 0;
                                            pointer-events: none;
                                        }
                                        &.asteria-transaction-cell-toggle {
                                            .asteria-button {
                                                &:after {
                                                    display: none;
                                                }
                                            }
                                        }
                                        &.asteria-transaction-cell-payment-date {
                                            .asteria-datepicker {
                                                .asteria-button-icon {
                                                    padding: 11px;
                                                }
                                            }
                                        }

                                        &.asteria-transaction-cell-currency {
                                            .asteria-icon-currency {
                                                order: -1;
                                                margin-left: 0;
                                                margin-top: -10px;
                                                width: 20px;
                                                span {
                                                    max-width: 12px;
                                                    max-height: 12px;
                                                    height: 12px;
                                                    width: 12px;
                                                }
                                            }
                                            .asteria-forms-dropdown-toggle {
                                                .asteria-text {
                                                    margin-left: 4px;
                                                }
                                            }
                                            .asteria-forms-dropdown-menu {
                                                min-width: 120px;
                                                .asteria-forms-dropdown-item {
                                                    padding: 10px 12px;
                                                    .asteria-text {
                                                        margin-left: 0;
                                                    }
                                                    .asteria-icon-currency {
                                                        margin-right: 4px;
                                                        margin-top: -3px;
                                                        order: -1;
                                                        justify-content: center;
                                                        align-items: center;
                                                    }
                                                }
                                            }
                                        }
                                        &.asteria-transaction-cell-categories {
                                            align-items: center;
                                            .asteria-icon-tag {
                                                order: -1;
                                                margin-left: 0;
                                                margin-top: -3px;
                                                width: 20px;
                                                span {
                                                    max-width: 12px;
                                                    max-height: 12px;
                                                    height: 12px;
                                                    width: 12px;
                                                }
                                            }
                                            .asteria-forms-dropdown-menu {

                                                .asteria-forms-dropdown-item {
                                                    padding: 10px 12px;
                                                    .asteria-icon-tag {
                                                        order: -1;
                                                        margin-left: 0;
                                                        margin-top: 0;
                                                        width: 20px;
                                                        height: 12px;
                                                        span {
                                                            max-width: 12px;
                                                            max-height: 12px;
                                                            height: 12px;
                                                            width: 12px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        &.asteria-transaction-cell-flat-status {
                                            opacity: 0;
                                        }
                                        &.asteria-transaction-cell-total {
                                            .asteria-input {
                                                text-align: right;
                                            }
                                        }
                                        &.asteria-transaction-cell-toggle {
                                            .asteria-button {
                                                flex: 1;
                                                display: flex;
                                                height: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                            &:hover, &.asteria-state-hover, &.asteria-state-active {
                                .asteria-transaction-wrapper-inner {
                                    background: #ECE8E6;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .asteria-text {

    }

    .asteria-cashflow-graph-time-navigation {
        // justify-content: flex-end;
        margin-left: 62px;
        width: calc(100% - 62px);
        align-items: flex-end;
        .asteria-button-left {
            margin-bottom: -12px;
            margin-right: 8px;
            .asteria-icon-wrapper {
                height: 24px;
                width: 24px;
                // background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z' fill='%239F8C82'/%3E%3Cpath d='M14.9031 7.06424L13.4888 5.65002L7.12488 12.014L13.4888 18.3779L14.9031 16.9637L9.9533 12.014L14.9031 7.06424Z' fill='%239F8C82'/%3E%3C/svg%3E%0A");
                background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.6667 6.66667L21 4L9 16L21 28L23.6667 25.3333L14.3333 16L23.6667 6.66667Z' fill='%239F8C82'/%3E%3C/svg%3E%0A");
                background-size: 16px 16px;
                background-repeat: no-repeat;
                background-position: center center;
                .asteria-icon-svg {
                    display: none;
                }
                &:hover {
                    // background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z' fill='%23EE7024'/%3E%3Cpath d='M14.9031 7.06424L13.4888 5.65002L7.12488 12.014L13.4888 18.3779L14.9031 16.9637L9.9533 12.014L14.9031 7.06424Z' fill='%23EE7024'/%3E%3C/svg%3E%0A");
                    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.6667 6.66667L21 4L9 16L21 28L23.6667 25.3333L14.3333 16L23.6667 6.66667Z' fill='%23EE7024'/%3E%3C/svg%3E%0A");
                }
            }
        }
        .asteria-button-right {
            margin-bottom: -12px;
            .asteria-icon-wrapper {
                height: 24px;
                width: 24px;
                // background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z' fill='%239F8C82'/%3E%3Cpath d='M9.09692 16.9358L10.5111 18.35L16.8751 11.986L10.5111 5.62207L9.09692 7.03628L14.0467 11.986L9.09692 16.9358Z' fill='%239F8C82'/%3E%3C/svg%3E%0A");
                background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 6.66667L11.6667 4L23.6667 16L11.6667 28L9 25.3333L18.3333 16L9 6.66667Z' fill='%239F8C82'/%3E%3C/svg%3E%0A");
                background-size: 16px 16px;
                background-repeat: no-repeat;
                background-position: center center;
                .asteria-icon-svg {
                    display: none;
                }
                &:hover {
                    // background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z' fill='%23EE7024'/%3E%3Cpath d='M9.09692 16.9358L10.5111 18.35L16.8751 11.986L10.5111 5.62207L9.09692 7.03628L14.0467 11.986L9.09692 16.9358Z' fill='%23EE7024'/%3E%3C/svg%3E%0A");
                    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 6.66667L11.6667 4L23.6667 16L11.6667 28L9 25.3333L18.3333 16L9 6.66667Z' fill='%23EE7024'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }

    .asteria-graph-area-lines {
        .asteria-graph-line-group {
            div[role='button'] {
                .asteria-label {
                    display: none;
                    font-size: 12px;
                    color: #80584F;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: 400;
                }
            }
            &.asteria-graph-line-group-today {
                div[role='button'] {
                    .asteria-label {
                        display: block;
                    }
                }
            }
        }  
    }

    .asteria-curreny-y-axis {
        font-size: 12px;
        color: #80584F;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 400;
    }

    .asteria-graph-yaxis-labels {
        .asteria-graph-yaxis-label {
            font-size: 12px;
            color: #80584F;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 400;
        }
    }

    .asteria-timeslice-selector-wrapper {
        position: absolute;
        // bottom: 4px;
        // left: 32px;
        // bottom: 8px;
        // bottom: 30px;
        // left: 62px;

        top: -48px;
        right: 48px;
        z-index: 6;
        .asteria-timeslice-selector-as-dropdown {
            .asteria-button-timespan-select {
                .asteria-forms-dropdown-toggle {
                    border: 0px solid transparent;
                    margin-right: 0;
                    height: 32px;
                    padding: 0;
                    min-height: 32px;
                    .asteria-text {
                        font-family: Swedbank Sans;
                        font-weight: 700;
                        font-size: 14px;
                        color: var(--text-color);
                        text-decoration: none;
                    }
                    .asteria-icon-wrapper {
                        width: 16px !important;
                        height: 16px !important;
                        margin-left: 8px;
                        .asteria-icon-svg {
                            fill: #9f8c82 !important;
                        }
                    }
                    &:hover {
                        .asteria-icon-svg {
                            fill: #512b2b !important;
                        }
                    }
                }
                .asteria-forms-dropdown-menu-direction-down {
                    // bottom: px;
                    //left: auto;
                    //top: auto;
                    //right: 26px;
                    //transform: translate(100%, -104%);

                    transform: translate(-72%,20%);
                    top: 0;
                    bottom: auto;

                    transition: max-height 0s;
                    max-height: 0;
                    overflow: hidden;
                    min-width: 120px;
                    .asteria-text {
                        font-size: 14px !important;
                        color: #80584F !important;
                        font-family: Arial,Helvetica,sans-serif !important;
                        font-weight: 400 !important;
                    }
                    .asteria-forms-dropdown-item {
                        position: relative;
                        &.asteria-state-active {
                            .asteria-text {

                            }
                            &:after {
                                background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.43431 11.6971L0.234305 6.49706C-0.0781016 6.18465 -0.0781016 5.67812 0.234305 5.36568L1.36565 4.23431C1.67806 3.92187 2.18462 3.92187 2.49703 4.23431L6 7.73725L13.503 0.234305C13.8154 -0.0781016 14.3219 -0.0781016 14.6344 0.234305L15.7657 1.36568C16.0781 1.67809 16.0781 2.18462 15.7657 2.49706L6.56569 11.6971C6.25325 12.0095 5.74672 12.0095 5.43431 11.6971Z' fill='%2399CC66'/%3E%3C/svg%3E%0A");
                                content: "";
                                height: 16px;
                                width: 16px;
                                display: block;
                                position: absolute;
                                right: 12px;
                                top: 50%;
                                transform: translateY(-50%);
                                background-repeat: no-repeat;
                                background-position: center center;
                                background-size: 12px;
                            }
                        }
                    }
                }
                &.asteria-forms-dropdown-open {
                    .asteria-forms-dropdown-toggle {
                        .asteria-icon-svg {
                            fill: #512b2b !important;
                        }
                    }
                    .asteria-forms-dropdown-menu-direction-down {
                        // bottom: 32px;
                        max-height: 160px;
                        overflow: visible;
                        display: block;
                    }
                }
            }
        }
    }

}

.asteria-feature-layout-workareas-latest {
    &:first-of-type {
        height: 100vh;
        margin: 0;
        // min-height: 100vh;
        // height: calc(100vh - 80px);
        height: calc(100vh - 120px);
        // Swedbank height fix, wings inside style.js
        // min-height: auto;
    }
    
    .asteria-widget-wrapper {
        height: 100%;
    }
    
    .asteria-cashflow-graph {
        
    }
    
    .asteria-transactions {
        display: flex;
        overflow: hidden;
        /* height: 35vh; */
        min-height: 214px;
        flex: 1;
        flex-direction: column;
        padding-bottom: 0;
        width: 100%;
        .asteria-transactions-header {
            top: 0;
        }
        .asteria-transactions-list-wrapper {
            flex: 1;
            // overflow: auto;
            height: auto;
            .asteria-transactions-list-wrapper-inner {
                position: relative;
                width: 100%;
                height: 100%;
                .asteria-transaction-filter {
                    position: sticky;
                    top: 0 !important;
                }
                .asteria-list-wrapper {
                        overflow-y: scroll;
                        overflow-x: hidden;
                        position: absolute;
                        top: 0px;
                        right: 0;
                        left: 0;
                        bottom: 40px;
                        height: calc(100%);
                        &::-webkit-scrollbar {
                            background-color: transparent;
                            width: 6px;
                        }
                        &::-webkit-scrollbar-track {
                            background-color: transparent;
                        }
                        &::-webkit-scrollbar-thumb {
                            background-color: #BABAC0;
                            border-radius: 16px;
                            border: 0px solid rgba(255,255,255,0);
                        }
                    .asteria-list-content {
                        
                    }
                }
            }
        }
    }

    .asteria-has-actionbar {
        .asteria-transactions {
            .asteria-transactions-list-wrapper {
                .asteria-transactions-list-wrapper-inner {
                    .asteria-list-wrapper {
                        height: calc(100% - 110px);
                    }
                }
            }
        }
        .asteria-actionbar-credit {
            .asteria-text {
                margin-left: auto;
                margin-right: 16px;
                margin-bottom: 0px;
                &:nth-of-type(2) {
                    max-width: 120px;
                    margin-right: auto;
                    margin-left: 16px;
                }
            }
            .asteria-input-wrapper {
                max-width: 550px;
                margin: 0;
                margin-right: 16px;
            }
            .asteria-actionbar-actions {
                margin-left: 0;
                .asteria-button {
                    .asteria-text {
                        margin: 0;
                        padding: 0;
                        line-height: 100%;
                    }
                }
            }
        }
    }
}

.asteria-cashflow-graph-layout-tabs {
    .asteria-graph-lines {
        .asteria-graph-area-lines {
            .asteria-graph-line-group {
                height: 204px;
                grid-template-rows: 204px;
            }
        }
    }
}


.asteria-resizer {
    display: flex;

    .asteria-resize-handle {
        overflow: auto;
        resize: vertical;
        display: block;
        height: 100%;
        width: 20px;
        position: relative;
        left: 50%;
        transform: translateX(-14px);
        z-index: 5;
        margin-bottom: 16px;
        min-height: 199px;
        &::-webkit-resizer {
            background-color: transparent;
        }
        .asteria-resize-handle--handle {
            content: '';
            display: block;
            width: calc(100% + 0px);
            height: 4px;
            background: #9f8c82;
            position: absolute;
            bottom: 14px;
            border-radius: 100px;
            cursor: row-resize;
            opacity: 0.4;
            padding: 0;
            &:focus, &:active {
                pointer-events: none;
            }
        }
        &:hover {
            .asteria-resize-handle--handle {
                background: #4C2D2C;
                opacity: 1;
            }
        }
        &:focus, &:active {
            .asteria-resize-handle--handle {
                background: #4C2D2C;
                opacity: 1;
                pointer-events: none;
            }
        }
    }

    .asteria-cashflow-graph {
        width: 100%;
    }
}

`;
