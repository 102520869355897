import React from 'react';
import styled, { withTheme } from 'styled-components';
import classNames from 'classnames';
import { compileObject, Getter, StyleGetter } from '@asteria/utils';
import CircularBar from './circularBar';
import IndeterminateCircularBar from './indeterminateCircularBar';

const circleRotationAnimation = `
	animation: indeterminateWrapper 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
		infinite;
	@keyframes indeterminateWrapper {
		0% {
			transform: rotate(0deg);
		}
		60% {
			transform: rotate(180deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

const ProgressTrackWrapper = styled.div`
	display: block;
	position: relative;
	overflow: hidden;
	width: 48px;
	height: 48px;
	${props =>
		!props.determinate && !props.isStatic ? circleRotationAnimation : ''};
`;

const CircleIndicator = styled(
	({
		className,
		determinate,
		isStatic,
		progressPercent,
		radius = 5.85,
		theme,
	}) => {
		const { svg: { style: svgStyle = {} } = {} } = compileObject(
			{
				base: [StyleGetter(`progressBar.circular`, {})],
			},
			theme,
		);

		return (
			<div className={classNames(className)}>
				<ProgressTrackWrapper element="container" isStatic={isStatic}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 12.7 12.7"
					>
						<circle
							r={
								radius +
								1 -
								(parseInt(svgStyle.width, 10) / 2 || 1)
							}
							cy="6.3499999"
							cx="6.3499999"
							id="path815-5"
							style={{
								fill: 'none',
								stroke: svgStyle.stroke,
								strokeWidth:
									parseInt(svgStyle.width, 10) / 2 || '1',
							}}
						/>
					</svg>
					{determinate ? (
						<CircularBar
							percentage={parseInt(progressPercent, 10) || 0}
						/>
					) : (
						<IndeterminateCircularBar isStatic={isStatic} />
					)}
				</ProgressTrackWrapper>
			</div>
		);
	},
)``;

CircleIndicator.displayName = 'CircleIndicator';

CircleIndicator.Styler = {
	children: [
		{
			component: ProgressTrackWrapper,
			base: [Getter('trackwrapper')],
		},
	],
};

export default withTheme(CircleIndicator);
