export default {
	tooltipOld: {
		default: {
			default: {
				tooltipContent: {
					style: {
						backgroundColor: 'rgba(255, 255, 255, 1)',
						padding: '10px',
						boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.25)',
						borderRadius: '4px',
						minWidth: '160px',
						right: '0',
						bottom: '120%',
						wrapper: {
							backgroundColor: '#fff',
							padding: '10px',
							boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.06)',
							borderRadius: '2px',
							minWidth: '160px',
							right: '0',
							bottom: '120%',
						},
						text: {
							fontSize: '14px',
							textAlign: 'center',
							lineHeight: '16.8px',
							color: '#000000',
						},
						borderColor: 'rgba(153, 153, 153, 1)',
						borderWidth: '1px',
						paddingTop: '12px',
						paddingLeft: '12px',
						paddingRight: '12px',
						paddingBottom: '12px',
					},
				},
				tooltipTitle: {
					style: {
						fontSize: '14px',
						textTransform: 'none',
						fontWeight: 'regular',
						textAlign: 'center',
						lineHeight: '16px',
						text: {
							fontSize: '12px',
							textTransform: 'uppercase',
							fontWeight: 'bold',
							textAlign: 'center',
							lineHeight: '14.5px',
						},
						fontFamily: 'Arial, sans-serif',
						color: 'rgba(37, 37, 37, 1)',
					},
				},
				tooltipText: {
					style: {
						fontSize: '14px',
						textAlign: 'center',
						lineHeight: '16.8px',
						color: 'rgba(38, 38, 38, 1)',
						fontFamily: 'Arial, sans-serif',
					},
				},
				tooltipPointer: {
					style: {
						width: '16px',
						height: '10px',
						margin: '0 auto',
						top: 'calc(100% - 1px)',
						left: '0',
						right: '0',
						wrapper: {
							width: '20px',
							height: '10px',
							margin: '0 auto',
							top: 'calc(100% - 1px)',
							left: '0',
							right: '0',
						},
						icon: {
							style: {
								fill: '#ffffff',
								filter:
									'drop-shadow(0 3px 3px rgba(0, 0, 0, 0.06))',
							},
						},
						marginTop: '-0.1px',
					},
					triangle: {
						icon: {
							style: {
								fill: 'rgba(255, 255, 255, 1)',
								filter:
									'drop-shadow(0 3px 3px rgba(0, 0, 0, 0.06))',
								stroke: '#999',
								strokeWidth: '1px',
							},
						},
						wrapper: {
							style: {
								borderWidth: '1px',
								borderColor: 'rgba(0, 0, 0, 1)',
								borderTopWidth: '1px',
								borderTopColor: 'rgba(255, 255, 255, 1)',
							},
						},
					},
					tooltipContent: {
						style: {
							borderWidth: '1px',
							borderColor: 'rgba(212, 42, 42, 1)',
						},
					},
				},
			},
			dark: {
				tooltipContent: {
					style: {
						backgroundColor: '#000',
						wrapper: { backgroundColor: '#000' },
						text: { color: '#fff' },
					},
				},
				tooltipTitle: {
					style: { color: '#fff', text: { color: '#fff' } },
				},
				tooltipText: { style: { color: '#fff' } },
				tooltipPointer: {
					triangle: { icon: { style: { fill: '#000000' } } },
					style: { icon: { style: { fill: '#000000' } } },
				},
			},
		},
		left: {
			default: {
				tooltipContent: {
					style: {
						right: '110%',
						top: '0',
						bottom: 'auto',
						left: 'auto',
						wrapper: {
							right: '110%',
							top: '0',
							bottom: 'auto',
							left: 'auto',
						},
					},
				},
				tooltipPointer: {
					style: {
						height: '20px',
						width: '10px',
						top: '0',
						left: 'calc(100% - 1px)',
						bottom: '0',
						right: 'auto',
						margin: 'auto 0',
						wrapper: {
							height: '20px',
							width: '10px',
							top: '0',
							left: 'calc(100% - 1px)',
							bottom: '0',
							right: 'auto',
							margin: 'auto 0',
						},
						icon: {
							style: {
								filter:
									'drop-shadow(3px 0 3px rgba(0, 0, 0, 0.06))',
							},
						},
					},
					triangle: {
						icon: {
							style: {
								filter:
									'drop-shadow(3px 0 3px rgba(0, 0, 0, 0.06))',
							},
						},
					},
				},
			},
		},
		bottom: {
			default: {
				tooltipContent: {
					style: {
						bottom: 'auto',
						left: 'auto',
						right: '0',
						top: '120%',
						wrapper: {
							bottom: 'auto',
							left: 'auto',
							right: '0',
							top: '120%',
						},
					},
				},
				tooltipPointer: {
					style: {
						bottom: 'calc(100% - 1px)',
						top: 'auto',
						left: '0',
						right: '0',
						wrapper: {
							bottom: 'calc(100% - 1px)',
							top: 'auto',
							left: '0',
							right: '0',
						},
						icon: {
							style: {
								filter:
									'drop-shadow(0 3px -3px rgba(0, 0, 0, 0.06))',
							},
						},
					},
					triangle: {
						icon: {
							style: {
								filter:
									'drop-shadow(0 3px -3px rgba(0, 0, 0, 0.06))',
								fill: 'rgba(200, 22, 22, 1)',
							},
						},
					},
				},
			},
		},
		right: {
			default: {
				tooltipContent: {
					style: {
						left: '110%',
						top: '0',
						bottom: 'auto',
						right: 'auto',
						wrapper: {
							left: '110%',
							top: '0',
							bottom: 'auto',
							right: 'auto',
						},
					},
				},
				tooltipPointer: {
					style: {
						height: '20px',
						width: '10px',
						top: '0',
						right: '100%',
						bottom: '0',
						left: 'auto',
						margin: 'auto 0',
						wrapper: {
							height: '20px',
							width: '10px',
							top: '0',
							right: '100%',
							bottom: '0',
							left: 'auto',
							margin: 'auto 0',
						},
						icon: {
							style: {
								filter:
									'drop-shadow(-3px 0 3px rgba(0, 0, 0, 0.06))',
							},
						},
					},
					triangle: {
						icon: {
							style: {
								filter:
									'drop-shadow(-3px 0 3px rgba(0, 0, 0, 0.06))',
							},
						},
					},
				},
			},
		},
	},
	tooltip: {
		default: {
			default: {
				tooltipTitle: {
					style: {
						marginBottom: '3px',
						fontSize: '14px',
						textTransform: 'none',
						textAlign: 'center',
						lineHeight: '18px',
						fontFamily: '"Swedbank Headline", sans-serif',
						color: 'var(--text-color)',
					},
				},
				tooltipContent: {
					style: {
						backgroundColor: '#fff',
						padding: '16px 16px',
						boxShadow: 'rgba(0, 0, 0, 0.25) 0px 3px 5px',
						border: '1px solid #999999',
						borderRadius: '3px',
						minWidth: '160px',
						right: '0',
						bottom: '120%',
					},
					tag: {
						text: {
							style: {
								fontFamily: 'Swedbank Sans',
								fontWeight: 600,
								color: 'var(--text-color)',
								fontSize: '14px',
							},
						},
					},
				},
				tooltipPointer: {
					style: {
						width: '16px',
						height: '10px',
						margin: '0 auto',
						top: 'calc(100% - 1px)',
						left: '0',
						right: '0',
						wrapper: {
							width: '20px',
							height: '10px',
							margin: '0 auto',
							top: 'calc(100% - 1px)',
							left: '0',
							right: '0',
						},
						icon: {
							style: {
								fill: '#ffffff',
								filter:
									'drop-shadow(0 3px 3px rgba(0, 0, 0, 0.06))',
							},
						},
						marginTop: '-0.1px',
					},
					triangle: {
						icon: {
							style: {
								fill: 'rgba(255, 255, 255, 1)',
								filter:
									'drop-shadow(0 3px 3px rgba(0, 0, 0, 0.06))',
								stroke: '#999',
								strokeWidth: '1px',
							},
						},
						wrapper: {
							style: {
								borderWidth: '1px',
								borderColor: 'rgba(0, 0, 0, 1)',
								borderTopWidth: '1px',
								borderTopColor: 'rgba(255, 255, 255, 1)',
							},
						},
					},
					tooltipContent: {
						style: {
							borderWidth: '1px',
							borderColor: 'rgba(212, 42, 42, 1)',
						},
					},
				},
			},
		},
		cashflow: {
			row: {
				style: {
					textAlign: 'left',
					margin: '8px 0px',
				},
				prefix: {
					style: {
						width: '12px',
						height: '12px',
						borderRadius: '50%',
						display: 'inline-block',
						marginRight: '8px',
					},
				},
				text: {
					style: {
						fontFamily: 'Arial,Helvetica,sans-serif',
						color: 'var(--text-color)',
						fontSize: '14px',
						marginRight: '0',
						textAlign: 'right',
						whiteSpace: 'nowrap',
						maxWidth: 'none',
					},
					types: {
						cashflowTooltipLabel: {
							style: {
								fontFamily: 'Swedbank Sans',
								fontWeight: 600,
								flexGrow: 1,
								marginRight: '16px',
								minWidth: '180px',
								textAlign: 'left',
								lineHeight: 'normal',
							},
						},
						cashflowTooltipTotal: {},
					},
				},
				types: {
					cashflowTooltipRowTitle: {
						types: {
							cashflowTooltipRowSubtext: {
								text: {
									style: {
										fontFamily:
											'Arial,Helvetica,sans-serif',
										color: 'var(--label-color)',
										fontSize: '12px',
										marginLeft: 'auto',
										marginRight: 'auto',
										textAlign: 'center',
										marginTop: '-8px',
									},
								},
							},
						},
					},
					cashflowTooltipRowAccount: {
						prefix: {
							style: {
								backgroundImage:
									'url("data:image/svg+xml;utf8,<svg xmlns=\\"http://www.w3.org/2000/svg\\" width=\\"12\\" height=\\"12\\"><path d=\\"M 0 5 L 12 5\\" style=\\"stroke: rgb(238, 112, 35);stroke-width: 2px;\\"></path></svg>")',
							},
						},
						types: {
							cashflowTooltipRowAccountForecast: {
								prefix: {
									style: {
										backgroundImage:
											'url("data:image/svg+xml;utf8,<svg xmlns=\\"http://www.w3.org/2000/svg\\" width=\\"12\\" height=\\"12\\"><path d=\\"M 0 5 L 12 5\\" style=\\"stroke: rgb(238, 112, 35);stroke-width: 2px;stroke-dasharray: 4;\\"></path></svg>")',
									},
								},
							},
						},
					},
					cashflowTooltipRowAccountCredit: {
						prefix: {
							style: {
								backgroundImage:
									'url("data:image/svg+xml;utf8,<svg xmlns=\\"http://www.w3.org/2000/svg\\" width=\\"12\\" height=\\"12\\"><path d=\\"M 0 5 L 12 5\\" style=\\"stroke: rgba(197,19,27,1);stroke-width: 2px;\\"></path></svg>")',
							},
						},
						types: {
							cashflowTooltipRowAccountForecast: {
								prefix: {
									style: {
										backgroundImage:
											'url("data:image/svg+xml;utf8,<svg xmlns=\\"http://www.w3.org/2000/svg\\" width=\\"12\\" height=\\"12\\"><path d=\\"M 0 5 L 12 5\\" style=\\"stroke: rgba(197,19,27,1);stroke-width: 2px;stroke-dasharray: 4;\\"></path></svg>")',
									},
								},
							},
						},
					},
					cashflowTooltipRowDepositForecast: {
						prefix: {
							style: {
								backgroundImage: `repeating-linear-gradient(135deg,transparent,transparent 1px,rgba(255,255,255,0.2) 2px,rgba(255,255,255,0.2) 4px,transparent 4.5px)`,
							},
						},
					},
					cashflowTooltipRowWithdrawForecast: {
						prefix: {
							style: {
								backgroundImage: `repeating-linear-gradient(135deg,transparent,transparent 1px,rgba(255,255,255,0.2) 2px,rgba(255,255,255,0.2) 4px,transparent 4.5px)`,
							},
						},
					},
					cashflowTooltipRowDepositUnpaid: {
						prefix: {
							style: {
								backgroundImage: `var(--system-unpaid-image);`,
							},
						},
					},
					cashflowTooltipRowWithdrawOverdue: {
						prefix: {
							style: {
								backgroundColor: `var(--system-overdue-color);`,
							},
						},
					},
					cashflowTooltipRowDepositOverdue: {
						prefix: {
							style: {
								backgroundColor: `var(--system-overdue-color);`,
							},
						},
					},
					cashflowTooltipRowWithdrawUnpaid: {
						prefix: {
							style: {
								backgroundImage: `var(--system-unpaid-image);`,
							},
						},
					},
					cashflowTooltipRowForecastSubtext: {
						text: {
							style: {
								fontFamily: 'Arial,Helvetica,sans-serif',
								color: 'var(--label-color)',
								fontSize: '12px',
								marginLeft: '20px',
							},
						},
					},
					cashflowTooltipRowTotal: {
						text: {
							style: {
								marginLeft: '20px',
								color: 'rgb(76, 45, 44)',
								fontFamily: "'Swedbank Headline', sans-serif",
								lineHeight: '18px',
								textAlign: 'left',
								marginRight: '0',
								fontWeight: '700',
							},
						},
					},
					cashflowTooltipRowSubtext: {
						text: {
							style: {
								fontFamily: 'Arial,Helvetica,sans-serif',
								color: 'var(--label-color)',
								fontSize: '12px',
								marginLeft: '20px',
							},
						},
					},
				},
			},
		},
	},
};
