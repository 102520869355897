import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const Drawer = styled(({ className, children }) => (
	<div className={classNames('asteria-widget-toolbar', className)}>
		{children}
	</div>
))`
	position: fixed;
	left: 0px;
	top: 0px;
	z-index: 9999;
	background-color: #292d3e;
	width: 300px;
	height: 100vh;
	display: flex;
`;

export default Drawer;
