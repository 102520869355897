import React, { useReducer } from 'react';
import styled from 'styled-components';
import { Getter, compileStyles } from '@asteria/utils';
import Button from '@asteria/component-button/button';
import Text from '@asteria/component-typography/text';

import FAQView from './views/faq';
import TicketFormView from './views/createTicket';
import SuccessView from './views/success';
import ErrorView from './views/error';

const supportStateReducer = (state, action) => {
	const { type = 'goto', tab, tabProps } = action;

	switch (type) {
		case 'goto':
			return {
				tab,
				tabProps,
			};
		default:
			return state;
	}
};

/**
 * Support modal component wrapper
 * @param closeAction
 * @return {*}
 * @constructor
 */
const SupportView = styled(({ className, onEnd = () => {} }) => {
	const [{ tab, tabProps }, dispatch] = useReducer(supportStateReducer, {
		tab: 'faq',
		tabProps: {},
	});

	/**
	 * Define our tab switcher
	 * @param {String} targetTab
	 * @param {Object} targetTabProps
	 */
	const setTab = (targetTab, targetTabProps = {}) =>
		dispatch({ type: 'goto', tab: targetTab, tabProps: targetTabProps });

	let View;

	switch (tab) {
		case 'faq':
			View = FAQView;
			break;
		case 'ticket':
			View = TicketFormView;
			break;
		case 'sent':
			View = SuccessView;
			break;
		case 'sentWithError':
			View = ErrorView;
			break;
		default:
			View = FAQView;
	}

	return [
		<div className={className}>
			<View setTab={setTab} onEnd={onEnd} {...tabProps} />
		</div>,
	];
})`
	${({ theme }) => compileStyles(SupportView, theme)}
`;

SupportView.Styler = {
	base: [Getter('support')],
	children: [
		{
			component: Text,
			base: [Getter('text')],
		},
		{
			component: Button,
			base: [Getter('button')],
		},
		{
			component: FAQView,
			base: [Getter('faq')],
		},
		{
			component: TicketFormView,
			base: [Getter('createticket')],
		},
	],
};

SupportView.displayName = 'SupportView';

export default SupportView;
