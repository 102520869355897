import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Getter } from '@asteria/utils';
import { format, isToday } from 'date-fns';
import Button from '@asteria/component-button/button';

const Div = styled.div`
	display: 'flex';
	justify-content: 'space-between';
	border-top: '1px solid #DCDCDC';
	cursor: 'pointer';
`;

const YearsList = styled(({ className, date, active = false, setActive }) => (
	<Div
		style={{
			display: 'flex',
			justifyContent: 'space-between',
			borderTop: '1px solid #DCDCDC',
			cursor: 'pointer',
		}}
		className={classNames(className, `asteria-datepicker-date`, {
			'asteria-state-active': active,
			'asteria-datepicker-date-today': isToday(date),
		})}
		onClick={e => {
			e.stopPropagation();
			setActive(date);
		}}
		onKeyPress={() => setActive(date)}
		role="button"
		tabIndex="-1"
	>
		<Button
			className="asteria-button-select-date"
			text={format(date, 'YYYY')}
			onClick={e => {
				e.stopPropagation();
				setActive(date);
			}}
		/>
		{active ? <Button type="link" icon="check" /> : null}
	</Div>
))`
	${Button} {
		background: none;
		color: inherit;
		border: none;
		padding: 0;
		cursor: pointer;
		outline: inherit;
	}
`;

YearsList.Styler = {
	typePrefix: 'asteria-datepicker-date',
	children: [
		{
			component: Button,
			base: [Getter('button')],
		},
	],
};

export default YearsList;
