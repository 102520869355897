const fonts = {
	font_body: 'Roboto',
	font_serif: 'Source Code Pro',
};

const fontSizes = {
	title1: '40px',
	title2: '32px',
	title3: '24px',
	large: '22px',
	regular: '16px',
	small: '14px',
	micro: '10px',
};

const colors = {
	headline: '#292D3E',
	text: '#7E8890',
	icon: '#000',
	primary: '#57B6EB',
	secondary: '',
	white: '#fff',
	gray: '#92969A',
};

const shadows = {
	box: '0px 1px 2px rgba(0,0,0,0.3)',
};

const padding = {
	box: '20px',
};

const radius = {
	box: '5px',
	label: '2px',
};

const BACKEND_URL =
	// eslint-disable-next-line no-restricted-globals
	location.href.includes('stage.') || location.href.includes('localhost')
		? 'https://stage.api.asteria.ai/api/v3/'
		: 'https://prod.api.asteria.ai/api/v3/';

const integrationStates = {
	idle: 'IDLE',
	initiating: 'INITIATING',
	importing: 'IMPORTING',
	analyzing: 'ANALYZING',
	error: 'ERROR',
	disabled: 'DISABLED',
};

export {
	fontSizes,
	colors,
	fonts,
	shadows,
	radius,
	padding,
	BACKEND_URL,
	integrationStates,
};

export default fontSizes;
