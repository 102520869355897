import { addMonths, startOfMonth } from 'date-fns';

import Store from '../../core/store';
import logger from '../../logger';

@logger('list:store')
class ListStore extends Store {
	name = 'list';

	constructor(subject) {
		super(subject, {
			items: [],
			ignore: [],
			actions: [],
			visibleColumns: [
				'description',
				'payment-date',
				'categories',
				'flat-status',
				'total',
			],
			startDate: startOfMonth(new Date()),
			endDate: addMonths(startOfMonth(new Date()), 1),
			types: ['DEPOSIT', 'WITHDRAW'],
			state: {
				active: null,
				selected: [],
			},
		});
	}
}

export default ListStore;
