import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Getter, preProcess } from '@asteria/utils';

const processColor = (color = '', prefix = 'color', status = 'PAID', theme) => {
	if (status === 'UNPAID') {
		return preProcess(`var(--system-unpaid-${prefix})`, theme);
	}

	if (color.startsWith('$')) {
		return preProcess(
			`var(--${color.replace('$', 'system-')}-${prefix})`,
			theme,
		);
	}

	return color;
};

const Part = styled(({ className }) => (
	<div className={classNames(className, 'asteria-horizontal-bar-part')} />
))`
	${({ faction }) => `width: ${faction * 100}%`};
	background-color: ${({ theme, color, status }) =>
		processColor(color, 'color', status, theme)};
`;

Part.displayName = 'Part';

Part.Styler = {
	typePrefix: 'asteria-horizontal-bar-part',
	children: [],
};

const HorizontalBar = styled(({ className, max = 0, parts = [] }) => (
	<div className={classNames(className)}>
		{parts.map(({ total = 0, status = '', tag = {} }) => (
			<Part
				key={`${tag?.name}-${status}`}
				className={classNames(
					`asteria-horizontal-bar-part-${status?.toLowerCase()}`,
					`asteria-horizontal-bar-part-${tag?.name?.replace(
						'$',
						'',
					)}`,
					`asteria-horizontal-bar-part-${status?.toLowerCase()}-${tag?.name?.replace(
						'$',
						'',
					)}`,
				)}
				faction={total / max}
				color={tag?.name || '$misc'}
				status={status}
			/>
		))}
	</div>
))`
	display: flex;
	width: 100%;
	height: 50px;
`;

HorizontalBar.displayName = 'HorizontalBar';

HorizontalBar.Styler = {
	children: [{ component: Part, base: [Getter('part')] }],
};
export default HorizontalBar;
