/* eslint-disable no-unused-vars */
import { findDOMNode } from 'react-dom';
import React, { useState, useCallback, useLayoutEffect } from 'react';
import useComponentSize from './useComponentSize';

function useHasScrolledPassed(ref, offset = 0) {
	const [passed, setPassed] = useState(false);

	const handleScroll = useCallback(
		e => {
			if (!ref || !ref.current) {
				return;
			}

			const box = ref.current.getBoundingClientRect();
			if (box.bottom + offset < 0) {
				setPassed(true);
			} else {
				setPassed(false);
			}
		},
		[offset, ref],
	);

	useLayoutEffect(() => {
		if (!ref.current) {
			return () => {};
		}

		const target = document.getElementsByClassName('asteria-wings-layout');

		if (!target || target.length === 0) {
			return () => {};
		}

		target[0].addEventListener('scroll', handleScroll);

		return () => {
			target[0].removeEventListener('scroll', handleScroll);
		};
	}, [handleScroll, ref]);

	return passed;
}

export default useHasScrolledPassed;
