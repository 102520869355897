import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { TranslationService } from '@asteria/services-language';
import { Text } from '@asteria/component-core/typography';
import Prefix from '@asteria/component-core/prefix';

import Tooltip from '@asteria/component-tooltip';

const TooltipContent = styled.div`
	display: flex;
	flex-direction: column;
`;

const TagTooltipGroup = styled(
	({ className, type = 'tag', prefix, tag = '', status, data = {} }) => {
		const displayTag = Array.isArray(tag) ? tag[0] : tag;
		const { subtags = [], currency } = data;
		let label = TranslationService.get(
			[
				`tags.${displayTag.replace('$', '')}`,
				`tags.${type}.${displayTag.replace('$', '')}`,

				`tags.${displayTag.replace('$', '')}.${status}`,
				currency
					? `tags.${displayTag.replace('$', '')}.currency.${status}`
					: `tags.${displayTag.replace('$', '')}.none.${status}`,
				`tags.${type}.${displayTag.replace('$', '')}.${status}`,

				currency
					? `tags.${displayTag.replace('$', '')}.currency`
					: `tags.${displayTag.replace('$', '')}.none`,
				`tags.${type}.${displayTag.replace('$', '')}.${status}`,

				currency
					? `tags.${type}.${displayTag.replace(
							'$',
							'',
					  )}.currency.${status}`
					: `tags.${type}.${displayTag.replace(
							'$',
							'',
					  )}.none.${status}`,

				`tags.tooltip.label`,
				`tags.${type}.tooltip.label`,
				`tags.${status}.tooltip.label`,
				currency
					? `tags.currency.tooltip.label`
					: `tags.none.tooltip.label`,

				`tags.${type}.${status}.tooltip.label`,

				`tags.${displayTag.replace('$', '')}.tooltip.label`,
				`tags.${type}.${displayTag.replace('$', '')}.tooltip.label`,
				`tags.${displayTag.replace('$', '')}.${status}.tooltip.label`,
				currency
					? `tags.${displayTag.replace(
							'$',
							'',
					  )}.currency.tooltip.label`
					: `tags.${displayTag.replace('$', '')}.none.tooltip.label`,
				`tags.${type}.${displayTag.replace(
					'$',
					'',
				)}.${status}.tooltip.label`,

				currency
					? `tags.${type}.${displayTag.replace(
							'$',
							'',
					  )}.currency.${status}.tooltip.label`
					: `tags.${type}.${displayTag.replace(
							'$',
							'',
					  )}.none.${status}.tooltip.label`,
			],
			status || 'Totalt',
			{
				status,
				...data,
			},
		);

		if (Array.isArray(tag) && tag.length > 1) {
			label = TranslationService.get(
				[
					`tags.multi.tooltip.label`,
					`tags.${type}.multi.tooltip.label`,
					`tags.${status}.multi.tooltip.label`,
					`tags.${type}.${status}.multi.tooltip.label`,
					`tags.${displayTag.replace('$', '')}.multi.tooltip.label`,
					`tags.${type}.${displayTag.replace(
						'$',
						'',
					)}.multi.tooltip.label`,
					`tags.${displayTag.replace(
						'$',
						'',
					)}.${status}.multi.tooltip.label`,
					`tags.${type}.${displayTag.replace(
						'$',
						'',
					)}.${status}.multi.tooltip.label`,
				],
				label,
				{
					label,
					count: tag.length - 1,
					tag,
				},
			);
		}

		const total = TranslationService.get(
			[
				`tags.tooltip.total`,
				`tags.${status}.tooltip.total`,
				`tags.${displayTag.replace('$', '')}.tooltip.total`,
				`tags.${displayTag.replace('$', '')}.${status}.tooltip.total`,
			],
			`{{value | number : false}}`,
			{
				status,
				...data,
			},
		);

		const subtext = TranslationService.get(
			[
				`tags.tooltip.subtext`,
				`tags.${status}.tooltip.subtext`,
				currency
					? `tags.currency.tooltip.subtext`
					: `tags.none.tooltip.subtext`,
				`tags.${type}.${status}.tooltip.subtext`,
				currency
					? `tags.${type}.currency.tooltip.subtext`
					: `tags.${type}.none.tooltip.subtext`,
				`tags.${displayTag.replace('$', '')}.tooltip.subtext`,
				`tags.${type}.${displayTag.replace('$', '')}.tooltip.subtext`,
				`tags.${displayTag.replace('$', '')}.${status}.tooltip.subtext`,
				`tags.${type}.${displayTag.replace(
					'$',
					'',
				)}.${status}.tooltip.subtext`,
				currency
					? `tags.${type}.${displayTag.replace(
							'$',
							'',
					  )}.currency.${status}.tooltip.subtext`
					: `tags.${type}.${displayTag.replace(
							'$',
							'',
					  )}.none.${status}.tooltip.subtext`,
			],
			'',
			{
				status,
				...data,
			},
		);

		return (
			<div
				className={classNames(
					className,
					'asteria-tooltip-tag-group',
					`asteria-tooltip-tag-group-${status}`,
					`asteria-tooltip-tag-group-${type}`,
					{
						[`asteria-tooltip-tag-group-currency-${currency?.toLowerCase()}`]: currency,
					},
				)}
			>
				<TooltipRow className="asteria-tooltip-row">
					{currency && (
						<div
							className={`currency-flag currency-flag-${currency.toLowerCase()}`}
						/>
					)}
					{prefix ? <Prefix color={tag} status={status} /> : null}
					<Text
						size="label"
						className={classNames('asteria-text-tooltip-row-label')}
					>
						{label}
					</Text>
					<Text
						size="body"
						className={classNames('asteria-text-tooltip-row-total')}
					>
						{total}
					</Text>
				</TooltipRow>
				{subtext ? (
					<TooltipRow className="asteria-tooltip-row-subtext">
						<Text
							size="body"
							className={classNames(
								'asteria-text-tooltip-row-subtext',
							)}
						>
							{subtext}
						</Text>
					</TooltipRow>
				) : null}
				{subtags
					? subtags.map(
							({
								config: {
									name,
									sums: {
										original: { total: subTagTotal },
									},
								},
							}) => {
								const subTagLabel = TranslationService.get(
									[
										`tags.tooltip.subtag.label`,
										`tags.${displayTag.replace(
											'$',
											'',
										)}.tooltip.subtag.label`,
										...name
											.slice(1)
											.map(
												n =>
													`tags.tooltip.subtag.${n.replace(
														'$',
														'',
													)}.label`,
											),
										...name
											.slice(1)
											.map(
												n =>
													`tags.tooltip.subtag.${n.replace(
														'$',
														'',
													)}.${status}.label`,
											),
										...name
											.slice(1)
											.map(
												n =>
													`tags.${displayTag.replace(
														'$',
														'',
													)}.tooltip.subtag.${n.replace(
														'$',
														'',
													)}.label`,
											),
										...name
											.slice(1)
											.map(
												n =>
													`tags.${displayTag.replace(
														'$',
														'',
													)}.tooltip.subtag.${n.replace(
														'$',
														'',
													)}.${status}.label`,
											),
									],
									name.slice(1).join(', '),
									{
										status,
									},
								);

								const subTagTotalText = TranslationService.get(
									[
										`tags.tooltip.total`,
										`tags.${status}.tooltip.total`,
										`tags.${displayTag.replace(
											'$',
											'',
										)}.tooltip.total`,
										`tags.${displayTag.replace(
											'$',
											'',
										)}.${status}.tooltip.total`,
									],
									`{{value | number : false}}`,
									{
										status,
										value: subTagTotal,
									},
								);

								return (
									<TooltipRow
										className={classNames(
											'asteria-tooltip-row-subtag',
											name
												.slice(1)
												.filter(n => n.startsWith('$'))
												.map(
													n =>
														`asteria-tooltip-row-subtag-${n.replace(
															'$',
															'',
														)}`,
												),
										)}
									>
										<Text
											size="body"
											className={classNames(
												'asteria-text-tooltip-row-subtext',
											)}
										>
											{subTagLabel}
										</Text>
										<Text
											size="body"
											className={classNames(
												'asteria-text-tooltip-row-total',
												'asteria-text-tooltip-row-subtext-total',
											)}
										>
											{subTagTotalText}
										</Text>
									</TooltipRow>
								);
							},
					  )
					: null}
			</div>
		);
	},
)`
	display: flex;
	flex-direction: column;

	${Prefix} {
		height: 12px;
		width: 12px;
	}

	${Text} {
		&.asteria-text-tooltip-row-label {
			font-family: Swedbank Sans;
			font-weight: 700;
			color: var(--text-color);
			font-size: 14px;
			text-transform: none;
			margin: 6px 0;
			flex-grow: 1;
			min-width: 180px;
			white-space: nowrap;
			max-width: 163px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&.asteria-text-tooltip-row-total {
			font-family: Arial, Helvetica, sans-serif;
			font-weight: 400;
			color: var(--text-color);
			font-size: 14px;
			text-transform: none;
			margin: 6px 0;
			flex-grow: 1;
			min-width: 180px;
			white-space: nowrap;
			max-width: 163px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			&.asteria-text-body {
				min-width: 0;
				max-width: 80px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		&.asteria-text-tooltip-row-subtext {
			font-family: Arial, Helvetica, sans-serif;
			color: #696969;
			font-size: 12px;
			margin: 0;
			margin-left: 20px;
			margin-top: -4px;
		}
		&.asteria-text-tooltip-row-subtext-total {
			font-family: Arial, Helvetica, sans-serif;
			color: #696969;
			margin-top: -2px !important;
		}
	}

	&.asteria-tooltip-tag-group-summary {
		order: 99;
		padding-left: 20px;
		${Text} {
			&.asteria-text-tooltip-row-total,
			&.asteria-text-tooltip-row-label {
				color: rgb(76, 45, 44);
				font-family: 'Swedbank Headline', sans-serif;
				line-height: 18px;
				text-align: left;
				margin-right: 0;
				font-weight: 700;
				&.asteria-text-body {
					font-weight: 600;
					font-family: Arial, Helvetica, sans-serif;
					margin-left: auto;
					text-align: right;
				}
			}

			&.asteria-text-tooltip-row-subtext {
				margin-left: 0px;
			}
		}
	}

	.asteria-text-tooltip-row-total {
		font-family: Arial, Helvetica, sans-serif;
		color: var(--text-color);
		font-size: 14px;
		margin-right: 0;
		text-align: right;
		white-space: nowrap;
		max-width: none;
	}

	.asteria-tooltip-row-subtag {
		${Text} {
			&.asteria-text-tooltip-row-subtext-total {
				margin: 0;
				font-size: 12px;
			}

			&.asteria-text-tooltip-row-subtext {
				flex-grow: 1;
			}
		}
	}

	&.asteria-tooltip-tag-group-PAID {
		order: 1;
	}

	&.asteria-tooltip-tag-group-UNPAID {
		order: 2;
	}

	&.asteria-tooltip-tag-group-FORECAST {
		order: 3;
	}

	&.asteria-tooltip-tag-group-OVERDUE {
		order: 4;
	}
`;

const TooltipRow = styled.div`
	display: flex;
	align-items: center;

	.asteria-text-tooltip-row-label {
		flex-grow: 1;
	}

	${Prefix} {
		margin-right: 8px;
	}
`;

const TagTooltip = styled(
	({
		className,
		tag,
		target,
		data: { values = [] } = {},
		data,
		translationKey = 'default',
	}) => {
		const title = TranslationService.get(
			`tags.${tag.replace('$', '')}`,
			tag,
		);

		const accValues = values.reduce((acc, { value }) => acc + value, 0);

		const content = values.map(
			({ status, size, value = size, ...rest }) => (
				<TagTooltipGroup
					prefix
					tag={tag || ''}
					status={status}
					data={{ size, value, ...data, ...rest }}
					type={translationKey}
				/>
			),
		);

		return (
			<Tooltip
				className={classNames(className)}
				open
				hover
				isStatic
				targetEl={target}
				content={
					<TooltipContent>
						{content}
						<TagTooltipGroup
							tag="total"
							data={{
								value: accValues,
							}}
							className={classNames(
								'asteria-tooltip-tag-group',
								'asteria-tooltip-tag-group-summary',
							)}
						/>
					</TooltipContent>
				}
				title={title}
			/>
		);
	},
)`
	z-index: 10000;

	.asteria-tooltip-tag-group {
	}
`;

TagTooltip.displayName = 'TagTooltip';

TagTooltip.Styler = {
	children: [],
};
export default TagTooltip;

export { TagTooltipGroup };
