import React, { useContext, useCallback } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { useStore } from '@asteria/utils';

import DatalayerContext from '@asteria/services-datalayer/react/context';
import { Translation, TranslationService } from '@asteria/services-language';
import { saveUser } from '@asteria/services-datalayer/services/auth/actions';

import { useFeature } from '@asteria/component-featureflag';
import Button from '@asteria/component-button';

const ShowAccountBalanceOnFilterSetting = styled(({ className }) => {
	const { dispatch } = useContext(DatalayerContext);
	const [currentUser = {}] = useStore('store-auth', ({ user }) => user);
	const hideOnSearch = useFeature({
		key: 'hide-account-on-search',
	});

	const [filters = []] = useStore(
		'store-appstate',
		({ filters: list }) => list,
	);

	const onHide = useCallback(() => {
		dispatch(
			saveUser({
				...currentUser,
				settings: {
					...currentUser.settings,
					layout: {
						...(currentUser?.settings?.layout || {}),
						graph: {
							...(currentUser?.settings?.layout?.graph || {}),
							showAccountOnSearch: false,
						},
					},
				},
			}),
		);
	}, [currentUser, dispatch]);

	const onShow = useCallback(() => {
		dispatch(
			saveUser({
				...currentUser,
				settings: {
					...currentUser.settings,
					layout: {
						...(currentUser?.settings?.layout || {}),
						graph: {
							...(currentUser?.settings?.layout?.graph || {}),
							showAccountOnSearch: true,
						},
					},
				},
			}),
		);
	}, [currentUser, dispatch]);

	if (!filters || filters.length === 0) {
		return null;
	}

	const value = currentUser?.settings?.layout?.graph?.showAccountOnSearch;
	const layout = currentUser?.settings?.layout?.graph?.layout;
	if (
		value === true ||
		value === false ||
		!hideOnSearch ||
		layout === 'tabs'
	) {
		return null;
	}

	return (
		<div className={classNames('asteria-prompt-wrapper', className)}>
			<div className={classNames('asteria-prompt', className)}>
				<Translation
					translationKey="onboarding.hideaccount.title"
					Component="h2"
					className="asteria-title"
					defaultText="Förenklad översikt"
				/>
				<Translation
					translationKey="onboarding.hideaccount.text"
					Component="p"
					className="asteria-text"
					defaultText="Du kan välja mellan att visa eller dölja kontobalansgrafen när du ser över din prognos."
				/>
				<div className="asteria-actions">
					<Button onClick={onShow} type="link">
						{TranslationService.get([
							'button.abort',
							'onboarding.hideaccount.abort',
						])}
					</Button>
					<Button onClick={onHide} type="primary">
						{TranslationService.get(
							'onboarding.hideaccount.hide',
							'Dölj',
						)}
					</Button>
				</div>
			</div>
		</div>
	);
})`
	border: 1px solid #e2dcd9;
	background-color: rgb(255, 255, 255);

	width: calc(100% + 392px);

	padding: 16px;

	margin-bottom: 16px;

	.asteria-title {
		font-size: 20px;
		color: #512b2b;
		font-family: Swedbank Headline;
		margin: 0 0 8px;
		padding: 0;
	}

	.asteria-text {
		font-size: 16px;
		color: #512b2b;
		font-family: Swedbank Sans;
		line-height: 24px;
		margin: 0 0 8px;
	}

	.asteria-actions {
		justify-content: space-between;
		display: flex;
		justify-content: flex-end;
	}

	.asteria-actions .asteria-button-primary {
		margin-left: 8px;
	}

	.asteria-actions .asteria-button {
		.asteria-text {
			margin-bottom: auto;
			margin-top: 2px;
			font-family: Swedbank Headline;
			font-weight: 600;
		}
	}
`;

ShowAccountBalanceOnFilterSetting.displayName =
	'ShowAccountBalanceOnFilterSetting';

export default ShowAccountBalanceOnFilterSetting;
