import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Getter, compileStyles } from '@asteria/utils';
import Text from '@asteria/component-typography/text';
import Title from '@asteria/component-typography/title';
import Icon from '@asteria/component-icon/v2';

const AlertHeader = styled(({ className, icon, title, onClose }) => (
	<div className={classNames(className, 'asteria-alert')}>
		{icon ? <Icon asset={icon} className="asteria-icon-alert" /> : null}
		<Text size="lead">{title}</Text>
		{onClose ? (
			<Icon
				asset="close"
				className="asteria-close"
				onClick={onClose}
				onPress={onClose}
			/>
		) : null}
	</div>
))`
	display: flex;
	align-items: center;
	${Text} {
		flex-grow: 1;
	}

	${Icon} {
		width: 16px !important;
		height: 16px !important;
		flex-grow: 0;
	}
`;

AlertHeader.displayName = 'AlertHeader';

AlertHeader.Styler = {
	children: [
		{
			component: Text,
			base: [Getter('title')],
		},
		{
			component: Icon,
			base: [Getter('icon')],
		},
	],
};

const AlertContent = styled(Text)``;

AlertContent.Styler = {
	children: [
		{
			component: Title,
			base: [Getter('title')],
		},
		{
			component: Text,
			base: [Getter('text')],
		},
	],
};

const AlertTypesIcon = {
	success: 'checkFilled',
	warning: 'info',
	error: 'info',
	info: 'info',
};

const Alert = styled(
	({
		className,
		type,
		icon,
		headingContent,
		description,
		children,
		onClose,
		show,
	}) => {
		if (show === false) {
			return null;
		}

		return (
			<div className={classNames(className, `asteria-alert-${type}`)}>
				<AlertHeader
					icon={icon ? AlertTypesIcon[type] : icon}
					title={headingContent}
					onClose={onClose}
				/>
				{description || children ? (
					<AlertContent size="body">
						{children || description}
					</AlertContent>
				) : null}
			</div>
		);
	},
)`
	${({ theme }) => compileStyles(Alert, theme)}
`;

Alert.displayName = 'Alert';

Alert.Styler = {
	base: [Getter('alert')],
	typePrefix: 'asteria-alert',
	children: [
		{
			component: AlertHeader,
			base: [Getter('header')],
		},
		{
			component: AlertContent,
			base: [Getter('content')],
		},
	],
};
export default Alert;
