import React from 'react';
import styled from 'styled-components';
import Modal from '@asteria/component-modal';
import { TranslationService } from '@asteria/services-language';
import { useLocation, useHistory, useRoute } from '@asteria/utils/hooks';

import Request from './request';

const RequestModal = styled(({ className, basePath = '' }) => {
	const { pathname } = useLocation();
	const history = useHistory();
	const { params: { id } = {} } = useRoute();

	if (id === undefined) {
		return null;
	}

	return (
		<Modal
			open={pathname.includes(`${basePath}/request`)}
			onClose={() => history.push(basePath)}
			className={className}
			title={TranslationService.get([
				'request.help.title',
				`request.help.${id}.title`,
			])}
		>
			<Request type={id} onClose={() => history.push(basePath)} />
		</Modal>
	);
})``;

RequestModal.Styler = Modal.Styler;

export default RequestModal;
