/* eslint-disable react/style-prop-object */
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import kebabCase from 'lodash/fp/kebabCase';
import classNames from 'classnames';
import debug from 'debug/dist/debug';

import { Getter, compileStyles } from '@asteria/utils';
import getLayoutSize from '@asteria/utils/getLayoutSize';
import { FeatureFlag, useFeature } from '@asteria/component-featureflag';

import Button from '@asteria/component-core/button';
import { Title, Text } from '@asteria/component-core/typography';

import { useStore, useLocation } from '@asteria/utils/hooks';
import Router from './router';

import Sidebar from './sidebar';
import Header from './header';
import Toolbar from '../common/toolbar';

// Pages
import HomePage from './routes/home';
import ComponentsPage from './routes/components';

// Wings styles
import AppCss from './style';

const CSS_VARS = {
	systemSignedColor: 'rgb(253, 193, 41)',
	systemSignedImage:
		'repeating-linear-gradient(135deg, rgb(252, 193, 39), rgb(252, 193, 39) 2.5px, rgb(250, 156, 16) 3px, rgb(250, 156, 16) 4px, rgb(252, 193, 39) 4.5px)',
	systemPaymentColor: 'rgb(253, 193, 41)',
	systemDepositColor: 'rgba(43,153,164,1)',
	systemForecastColorPaid: '#8B8B8B',
	systemForecastImage:
		'repeating-linear-gradient(135deg,transparent,transparent 1px,rgba(255,255,255,0.2) 2px,rgba(255,255,255,0.2) 4px,transparent 4.5px);',
	systemUnpaidColor: 'rgb(253, 193, 41)',
	systemUnpaidImage:
		'repeating-linear-gradient(135deg, rgb(252, 193, 39), rgb(252, 193, 39) 2.5px, rgb(250, 156, 16) 3px, rgb(250, 156, 16) 4px, rgb(252, 193, 39) 4.5px)',
	systemOverdueColor: 'rgb(168, 47, 44)',
	systemWithdrawColor: 'rgba(146,74,121,1)',
	systemSalariesColor: 'transparent',
	systemSalaryColor: 'rgb(80, 177, 208)',
	systemTaxesColor: 'transparent',
	systemTaxColor: 'rgb(81, 43, 43)',
	systemInvoicesColor: 'transparent',
	systemCustomerColor: 'rgb(80, 128, 208)',
	systemCustomerOverdueColor: 'rgb(168, 47, 44)',
	systemSupplierColor: 'rgb(190, 76, 143)',
	systemSupplierOverdueColor: 'rgb(168, 47, 44)',
	systemCashColor: 'rgba(236, 163, 99, 1)',
	systemCardColor: 'rgba(207, 175, 153, 1)',
	systemMiscColor: 'rgba(153, 153, 153, 1)',
	systemTransferColor: 'rgba(80, 208, 178, 1)',
	systemBankgiroColor: 'rgba(239, 183, 182, 1)',
	systemPlusgiroColor: 'rgba(138, 205, 195, 1)',
	systemSwishColor: 'rgba(210, 138, 197, 1)',
	systemFactoringColor: 'rgba(74, 165, 100)',
	systemInvoiceClientColor: 'rgb(80, 128, 208)',
	systemInternalTransfersColor: 'rgba(80, 177, 208)',
	systemOfficeSpaceColor: 'rgba(192,208,80,1)',
	systemOfficeElectricityColor: 'rgba(204,204,102,1)',
	systemOfficeInternetColor: 'rgba(153,204,51,1)',
	systemOfficeCleaningColor: 'rgba(102,153,0,1)',
	systemOfficeRentColor: 'rgba(112,188,104,1)',
	systemOfficeSuppliceColor: 'rgba(255,255,153,1)',
	systemOfficeMortgageColor: 'rgba(204,255,153,1)',
	systemOfficeRenovationColor: 'rgba(2,154,91,1)',
	systemOfficePetsColor: 'rgba(0,134,96,1)',
	systemOfficeGardeningColor: 'rgba(0,82,73,1)',
	systemOfficeSecurityColor: 'rgba(144,149,95,1)',
	systemOfficeVacationColor: 'rgba(242,254,220,1)',
	systemOfficeSanitationColor: 'rgba(39,55,0,1)',
	systemOfficeServicesColor: 'rgba(191,208,80,1)',
	systemTransportColor: 'rgba(239,183,182,1)',
	systemTransportParkingColor: 'rgba(204,157,173,1)',
	systemTransportFuelColor: 'rgba(163,134,160,1)',
	systemTransportServicesColor: 'rgba(121,113,141,1)',
	systemTransportLoadColor: 'rgba(81,93,117,1)',
	systemTransportCleaningColor: 'rgba(47,72,88,1)',
	systemTransportPublictransportationColor: 'rgba(182,130,129,1)',
	systemTransportTaxiColor: 'rgba(0,92,130,1)',
	systemTransportMotorbikeColor: 'rgba(191,165,164,1)',
	systemTransportOtherColor: 'rgba(87,65,65,1)',
	systemChildrenColor: 'rgba(236,163,99,1)',
	systemChildrenClothingColor: 'rgba(192,125,63,1)',
	systemChildrenToysColor: 'rgba(150,89,27,1)',
	systemChildrenActivityColor: 'rgba(109,54,0,1)',
	systemChildrenCareColor: 'rgba(72,22,0,1)',
	systemChildrenBabysittingColor: 'rgba(236,163,99,1)',
	systemChildrenAllowanceColor: 'rgba(176,137,105,1)',
	systemChildrenSchoolColor: 'rgba(64,46,50,1)',
	systemChildrenOtherColor: 'rgba(255,176,107,1)',
	systemHealthColor: 'rgba(208,80,80,1)',
	systemHealthPharmacyColor: 'rgba(255,165,158,1)',
	systemHealthHealthcareColor: 'rgba(136,0,27,1)',
	systemHealthSportsColor: 'rgba(202,75,75,1)',
	systemHealthBeautyColor: 'rgba(177,52,56,1)',
	systemHealthHairColor: 'rgba(208,80,80,1)',
	systemHealthSpaColor: 'rgba(255,117,115,1)',
	systemHealthOpticianColor: 'rgba(189,130,125,1)',
	systemHealthOtherColor: 'rgba(214,147,147,1)',
	systemFinancialColor: 'rgba(80,116,208,1)',
	systemFinancialBankColor: 'rgba(46,92,180,1)',
	systemFinancialLoadColor: 'rgba(0,68,152,1)',
	systemFinancialLatefeesColor: 'rgba(0,47,126,1)',
	systemFinancialOtherColor: 'rgba(0,27,100,1)',
	systemShoppingColor: 'rgba(246,208,3,1)',
	systemShoppingMultimediaColor: 'rgba(255,198,78,1)',
	systemShoppingClothingColor: 'rgba(255,192,127,1)',
	systemShoppingTailorColor: 'rgba(194,162,0,1)',
	systemShoppingBooksColor: 'rgba(144,119,0,1)',
	systemShoppingAlcoholColor: 'rgba(99,78,0,1)',
	systemShoppingElectronicsColor: 'rgba(64,40,0,1)',
	systemShoppingTobaccoColor: 'rgba(161,143,92,1)',
	systemShoppingFlowersColor: 'rgba(246,208,3,1)',
	systemShoppingGiftsColor: 'rgba(242,254,220,1)',
	systemShoppingJeweleryColor: 'rgba(159,144,101,1)',
	systemShoppingOtherColor: 'rgba(202,170,0,1)',
	systemLeisureColor: 'rgba(138,205,195,1)',
	systemLeisureTheatherColor: 'rgba(0,190,129,1)',
	systemLeisureMembershipColor: 'rgba(60,126,97,1)',
	systemLeisureBarColor: 'rgba(52,70,61,1)',
	systemLeisurePartiesColor: 'rgba(138,205,195,1)',
	systemLeisureCharitiesColor: 'rgba(0,170,151,1)',
	systemLeisureGolfingColor: 'rgba(97,232,206,1)',
	systemLeisureOutdoorColor: 'rgba(84,84,121,1)',
	systemLeisureArtColor: 'rgba(138,205,195,1)',
	systemLeisureDanceColor: 'rgba(52,75,72,1)',
	systemLeisurePhotoColor: 'rgba(150,177,172,1)',
	systemLeisureMusicColor: 'rgba(0,132,112,1)',
	systemLeisureHuntingColor: 'rgba(138,205,195,1)',
	systemLeisureBoatColor: 'rgba(149,216,206,1)',
	systemLeisureBettingColor: 'rgba(108,175,165,1)',
	systemLeisureSkiColor: 'rgba(100,167,157,1)',
	systemLeisureHorseColor: 'rgba(0,47,41,1)',
	systemLeisureBikingColor: 'rgba(5,83,75,1)',
	systemLeisureOtherColor: 'rgba(96,162,153,1)',
	systemEducationColor: 'rgba(0,130,141,1)',
	systemEducationLoanColor: 'rgba(0,99,110,1)',
	systemEducationFeeColor: 'rgba(0,69,80,1)',
	systemEducationEquipmentColor: 'rgba(0,41,52,1)',
	systemEducationOtherColor: 'rgba(53,122,140,1)',
	systemTravelColor: 'rgba(196,196,196,1)',
	systemTravelHotelColor: 'rgba(172,175,180,1)',
	systemTravelTransportColor: 'rgba(144,155,163,1)',
	systemTravelEntertainmentColor: 'rgba(114,137,143,1)',
	systemTravelFooddrinkColor: 'rgba(87,119,119,1)',
	systemTravelOtherColor: 'rgba(67,101,90,1)',
	systemInvestmentColor: 'rgba(115,172,57,1)',
	systemInvestmentBufferColor: 'rgba(140,198,83,1)',
	systemInvestmentPensionColor: 'rgba(153,204,102,1)',
	systemInvestmentSecuritiesColor: 'rgba(166,210,121,1)',
	systemInvestmentChildrenColor: 'rgba(179,217,140,1)',
	systemInvestmentOtherColor: 'rgba(191,223,159,1)',
	systemFoodColor: 'rgba(226,147,100,1)',
	systemFoodGroceriesColor: 'rgba(222,132,79,1)',
	systemFoodFastfoodColor: 'rgba(226,147,100,1)',
	systemFoodRestaurantsColor: 'rgba(230,162,121,1)',
	systemFoodKioskColor: 'rgba(234,177,143,1)',
	systemFoodOtherColor: 'rgba(238,192,164,1)',
	systemInsuranceColor: 'rgba(103,39,210,1)',
	systemInsuranceBuildingColor: 'rgba(132,79,222,1)',
	systemInsuranceCarColor: 'rgba(147,100,226,1)',
	systemInsuranceLifeColor: 'rgba(162,121,230,1)',
	systemInsuranceIncomeColor: 'rgba(177,143,234,1)',
	systemInsuranceAccidentColor: 'rgba(192,164,238,1)',
	systemInsuranceOtherColor: 'rgba(62,10,148,1)',
	primaryColor: '#f35b1c',
	secondaryColor: '#31A3AE',
	textColor: '#512b2b',
	titleColor: '#512b2b',
	chipColor: '#d3c3bc',
	mutedColor: '#999999',
	labelColor: '#696969',
	labelInputColor: '#2F2424',
	dialogTextColor: '#262626',
	filterHeaderBgColor: '#EAE7E3',
	filterHeaderBorderColor: '#D0C3BD',
	filterHeaderTextColor: '#72605F',
	transactionListBgColor: '#fff',
	transactionListBorderColor: '#EBE7E2',
	transactionListTextColor: '#262626',
	transactionListActiveBgColor: '#FBF2EA',
	transactionListDetailedBgColor: '#FBF2EA',
	fontColorBody: '#512b2b',
	fontColorCategory: '#512b2b',
	fontColorTag: '#512b2b',
	fontColorHeader: '#512b2b',
	fontColorLabel: '#2f2424',
	fontColorLink: '#31a3ae',
	fontColorCurrent: '#512B2B',
	fontColorActive: '#F35B1C',
	fontColorPast: '#696969',
	alertTextColor: '#262626',
	alertWarningBgColor: '#FFF3D5',
	alertErrorBgColor: '#F9DED3',
	alertErrorBorderColor: '#C5131C',
	alertWarningBorderColor: '#F9C642',
	fontCategory: 'Swedbank Sans',
	fontTag: 'Swedbank Sans',
	fontParagraphLead: 'Swedbank Sans',
	fontParagraph: 'Arial,Helvetica,sans-serif',
	fontBody: 'Arial,Helvetica,sans-serif',
	fontSizeCategory: '14px',
	fontSizeTag: '14px',
	mobileBreakpoint: '767.98px',
	tabletBreakpoint: '768px',
	desktopBreakpoint: '992px',
	desktopLargeBreakpoint: '1200px',
	pageTitleFontSize: '48px',
	title1FontSize: '32px',
	title2FontSize: '28px',
	title3FontSize: '20px',
	title4FontSize: '18px',
	title5FontSize: '16px',
	title6FontSize: '15px',
	titleFontWeight: '400',
	titleTextTransform: 'none',
	titleFontFamily: '"Open Sans", sans-serif',
	textLargeFontSize: '24px',
	textDefaultFontSize: '16px',
	textMediumFontSize: '14px',
	textSmallFontSize: '12px',
	textMicroFontSize: '10px',
	textFontFamily: '"Open Sans", sans-serif',
	textFontWeight: '300',
	textTransform: '',
	defaultBG: '#fff',
	defaultSurfaceBG: '#fff',
	buttonBorderColor: '#dadce0',
	buttonBorderWidth: '1px',
	buttonBorderRadius: '100px',
	buttonColor: '#4C5362',
	buttonBgColor: '#fff',
	buttonBorderStyle: 'solid',
	buttonFontFamily: '"Open Sans", sans-serif',
	buttonPrimaryBgColor: '#EE8F8B',
	buttonPrimaryBgColorHover: '#BE726F',
	buttonPrimaryColor: '#fff',
	buttonPrimaryColorHover: '#fff',
	buttonPrimaryBorderColor: 'transparent',
	buttonSecondaryBgColor: '#4C5362',
	buttonSecondaryBgColorHover: '#3D424E',
	buttonSecondaryColor: '#fff',
	buttonSecondaryColorHover: '#fff',
	buttonSecondaryBorderColor: 'transparent',
	buttonDefaultBgColor: '#F7F7F7',
	buttonDefaultBgColorHover: '#C6C6C6',
	buttonDefaultColor: '#EE8F8B',
	buttonDefaultColorHover: '#EE8F8B',
	buttonDefaultBorderColor: 'transparent',
	buttonIconOnlyBgColor: 'transparent',
	buttonIconOnlyBgColorActive: 'transparent',
	buttonIconOnlyBgColorHover: 'transparent',
	buttonIconOnlyBorderColor: 'transparent',
	buttonIconOnlyIconColor: '#3D424E',
	buttonIconOnlyIconColorActive: '#000',
	buttonIconOnlyIconColorHover: '#000',
	buttonIconOnlyIconBorderRadius: '0',
	buttonIconOnlyIconBorderWidth: '0px',
	buttonIconOnlyIconBorderStyle: 'solid',
	buttonIconOnlyIconPadding: '0',
	buttonIconOnlyIconMargin: '0',
	buttonIconOnlyRoundBgColor: '#ACACAC',
	buttonIconOnlyRoundBgColorActive: '#ACACAC',
	buttonIconOnlyRoundBgColorHover: '#ACACAC',
	buttonIconOnlyRoundBorderColor: 'transparent',
	buttonIconOnlyIconRoundColor: '#3D424E',
	buttonIconOnlyIconRoundColorActive: '#EE8F8B',
	buttonIconOnlyIconRoundColorHover: '#BE726F',
	buttonIconOnlyIconRoundBorderRadius: '50%',
	buttonIconOnlyIconRoundBorderWidth: '0px',
	buttonIconOnlyIconRoundBorderStyle: 'solid',
	buttonIconOnlyIconRoundPadding: '0',
	buttonIconOnlyIconRoundMargin: '0',
	buttonLinkBgColor: 'transparent',
	buttonLinkColor: '#EE8F8B',
	buttonLinkColorHover: '#BE726F',
	buttonLinkBorderColor: 'transparent',
	buttonFlatBgColor: 'transparent',
	buttonFlatColor: '#EE8F8B',
	buttonFlatColorHover: '#BE726F',
	buttonFlatBorderColor: 'transparent',
	buttonLargePadding: '24px 32px',
	buttonMediumPadding: '16px 24px',
	buttonSmallPadding: '8px 24px',
	buttonLargeFontSize: '24px',
	buttonMediumFontSize: '16px',
	buttonSmallFontSize: '14px',
	buttonFontWeight: '500',
	buttonTextTransform: 'none',
	iconColor: '#ACACAC',
	iconColorActive: '#000',
	iconColorHover: '#3D424E',
	iconColorInverted: 'rgba(255,255,255,0.3)',
	labelColorInverted: '#B8B8B8',
	labelFontWeight: '600',
	labelFontFamily: '"Open Sans", sans-serif',
	labelFontSize: '12px',
	inputBorderColor: '#dadce0',
	inputBorderColorHover: '#4C5362',
	inputBorderColorInverted: 'rgba(255,255,255,0.3)',
	inputBorderColorHoverInverted: 'rgba(255,255,255,0.5)',
	inputBorderWidth: '1px',
	inputBorderRadius: '4px',
	inputColor: '#4C5362',
	inputColorInverted: '#dadce0',
	inputBgColor: '#fff',
	inputBgColorInverted: 'transparent',
	inputBorderStyle: 'solid',
	inputBorderColorFocus: '#EE8F8B',
	inputBorderColorFocusInverted: '#EE8F8B',
	inputPlaceholderColor: '#ABAEB5',
	inputPlaceholderFontSize: '14px',
	inputPlaceholderFontWeight: '300',
	inputPlaceholderColorInverted: 'rgba(255,255,255,0.3)',
	inputFontFamily: '"Open Sans", sans-serif',
	inputFontWeight: '300',
	inputRadioLabelColor: '#ABAEB5',
	inputRadiolabelFontWeight: '300',
	inputRadioBorderColor: '#ACACAC',
	inputRadioBorderStyle: 'solid',
	inputRadioBorderWidth: '1px',
	inputRadioBorderColorSelected: '#EE8F8B',
	inputRadioBgColorSelected: '#EE8F8B',
	inputRadioLabelColorSelected: '#4C5362',
	inputRadioBorderColorHover: '#4C5362',
	inputRadioLabelColorHover: '#4C5362',
	inputDefaultPadding: '20px 40px 20px 20px',
	inputDefaultHeight: '56px',
	dropdownMenuBorderColor: '',
	dropdownMenuBorderWidth: '',
	dropdownMenuBorderRadius: '4px',
	dropdownMenuBorderBoxShadow: '',
	dropdownMenuBg: '#fff',
	dropdownMenuBoxShadow:
		'0 1px 3px 0 rgba(0,0,0,0.302), 0 4px 8px 3px rgba(0,0,0,0.149)',
	dropdownMenuItemBg: '#fff',
	dropdownMenuItemHoverBg: 'rgba(0,0,0,.06)',
	dropdownMenuItemBgActive: '#fff',
	dropdownMenuItemColor: '#616161',
	dropdownMenuItemActiveColor: '#000',
	dropdownMenuItemBorderColor: 'transparent',
	dropdownMenuItemWidth: '0px',
	dropdownMenuItemFontFamily: '"Open Sans", sans-serif',
	dropdownMenuItemFontWeight: '300',
	dropdownMenuItemFontSize: '14px',
	modalBackDropBg: 'rgba(194, 196, 201, 0,8)',
	modalWidth: '400px',
	modalBgColor: '#fff',
	modalPadding: '0',
	modalBorderColor: 'transparent',
	modalBorderWidth: '0',
	modalBorderRadius: '8px',
	modalBoxShadow:
		'0 1px 3px 0 rgba(0,0,0,0.302), 0 4px 8px 3px rgba(0,0,0,0.149)',
	modalHeaderBgColor: '',
	modalHeaderPadding: '16px 24px 4px 24px',
	modalHeaderBorderColor: 'transparent',
	modalHeaderBorderWidth: '0',
	modalHeaderBorderRadius: '0',
	modalHeaderBoxShadow: '',
	ModalHeaderTitleFontSize: '24px',
	ModalHeaderTitleColor: '#4C5362',
	modalBodyBgColor: '',
	modalBodyPadding: '8px 4px 32px 8px',
	modalBodyBorderColor: 'transparent',
	modalBodyBorderWidth: '0',
	modalBodyBorderRadius: '0',
	modalBodyBoxShadow: '',
	modalFooterBgColor: '',
	modalFooterPadding: '16px 24px 16px 24px',
	modalFooterBorderColor: 'transparent',
	modalFooterBorderWidth: '0',
	modalFooterBorderRadius: '0',
	modalFooterBoxShadow: '',
	headerBgColor: '#fff',
	alertTitleColor: '#262626',
	alertTitleFontSize: '16',
	alertTitleFontWeight: '800',
	alertTextFontSize: '12px',
	alertTextFontWeight: '600',
	sectionTitleBgColor: 'transparent',
	sectionTitleFontFamily: '"Open Sans", sans-serif',
	sectionTitleFontWeight: '600',
	sectionTitleFontSize: '12px',
	sectionTitleColor: '#4C5362',
	sectionTextTransform: 'capitalize',
	sectionTextColor: '#4C5362',
	sectionTextFontSize: '12px',
	sectionIconColor: '#ACACAC',
	sectionTitlePadding: '0',
	sectionTitleBorderColor: 'transparent',
	sectionTitleBorderWidth: '0',
	sectionTitleBorderStyle: 'solid',
	sectionTitleBorderRadius: '0',
	faqPadding: '0',
	faqBorderColor: 'transparent',
	faqBorderWidth: '0',
	faqBorderStyle: 'solid',
	faqBorderRadius: '0',
	faqIconColor: '#ACACAC',
	faqIconColorActive: '#4C5362',
	faqTitleFontSize: '14px',
	faqTitleFontFamily: '"Open Sans", sans-serif',
	faqTitleFontWeight: '500',
	faqTitleFontColor: '#4C5362',
	faqTitleFontColorActive: '#EE8F8B',
	faqTitlePadding: '0',
	faqTitleBorderColor: 'transparent',
	faqTitleBorderWidth: '0',
	faqTitleBorderStyle: 'solid',
	faqTitleBorderRadius: '0',
	faqTextFontSize: '14px',
	faqTextFontWeight: '300',
	faqTextFontColor: '#4C5362',
	faqTextPadding: '0',
	faqTextBorderColor: 'transparent',
	faqTextBorderWidth: '0',
	faqTextBorderStyle: 'solid',
	faqTextBorderRadius: '0',
};

window.___ASTERIA___LOGGER = debug;

const GlobalStyle = createGlobalStyle(AppCss);
const ThemeGlobalStyle = createGlobalStyle`
	:root {
		${() =>
			Object.entries(CSS_VARS).map(
				([key, value]) => `--${kebabCase(key)}: ${value};`,
			)}
	}
	
	.sortableHelper {
		z-index: 10000;
	}

	${({ hideSwedbankPageTitle }) =>
		hideSwedbankPageTitle
			? 'sw-head, sw-head h1, .asteria-widget-title {display: none;}'
			: ''}
	${({ theme: { font: { fonts = [] } = {} } = {} }) =>
		fonts.map(font => font.src).join('\n')};

	.asteria-transaction-detail-inner .asteria-button-help {
		display: none;
	}

	.asteria-currency-overview .asteria-button-help {
		display: none;
	}

	.asteria-cashflow-graph-hide-revenue.asteria-feature-asteria-improvements.asteria-cashflow-graph-layout-flat .asteria-graph-lines .asteria-graph-xaxis-history, .asteria-cashflow-graph-hide-revenue.asteria-feature-asteria-improvements.asteria-cashflow-graph-layout-flat .asteria-graph-lines .asteria-graph-xaxis-today, .asteria-cashflow-graph-hide-revenue.asteria-feature-asteria-improvements.asteria-cashflow-graph-layout-flat .asteria-graph-lines .asteria-graph-xaxis-forecast {
		display: flex !important;
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper .asteria-list-content.asteria-transactions-edit .asteria-transactions-divider.asteria-transactions-divider-feature-inline-edit .asteria-transactions-divider-row .asteria-adjustable-total.asteria-input-wrapper {
		border-radius: 4px;
		border: 2px solid rgb(235, 232, 232) !important;
		background: #ebe8e8 !important;
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper .asteria-list-content.asteria-transactions-edit .asteria-transactions-divider.asteria-transactions-divider-feature-inline-edit .asteria-transactions-divider-row .asteria-adjustable-total {
		background: transparent !important;
		border: 2px solid transparent !important;
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper .asteria-list-content.asteria-transactions-edit .asteria-transactions-divider.asteria-transactions-divider-feature-inline-edit .asteria-transactions-divider-row .asteria-adjustable-total {
		background: transparent !important;
		border: 2px solid transparent !important;
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-transaction-list-visible-account .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper .asteria-list-content.asteria-transactions-edit .asteria-transactions-divider.asteria-transactions-divider-feature-inline-edit .asteria-transactions-divider-row .asteria-adjustable-total {
		background: transparent !important;
		border: 2px solid transparent !important;
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper .asteria-list-content.asteria-transactions-edit .asteria-transactions-divider.asteria-transactions-divider-feature-inline-edit .asteria-transactions-divider-row .asteria-adjustable-total {
		background: transparent !important;
		border-radius: 4px;
		border: 0px solid transparent !important;
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper .asteria-list-content.asteria-transactions-edit .asteria-transactions-divider.asteria-transactions-divider-feature-inline-edit .asteria-transactions-divider-row .asteria-adjustable-total.asteria-state-focus {
		background: rgb(236, 249, 243) none repeat scroll 0% 0% !important;
		border: 2px solid rgb(170, 204, 207) !important;
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper .asteria-list-content.asteria-transactions-edit .asteria-transactions-divider.asteria-transactions-divider-feature-inline-edit .asteria-transactions-divider-row .asteria-input-helper {
		position: relative;
		top: 0;
		left: -8px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper .asteria-list-content.asteria-transactions-edit .asteria-transactions-divider.asteria-transactions-divider-feature-inline-edit {
		padding: 8px 4px 4px 12px !important;
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-alert .asteria-icon-wrapper {
		min-height: 32px;
		padding: 8px 0;
		max-width: none !important;
		max-height: none !important;
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-alert .asteria-icon-wrapper .asteria-icon-svg {
		max-height: 16px;
		margin-right: 8px;
		margin-left: 16px;
	}

	.asteria-modal-erp-missing {
		overflow: hidden !important;
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions-list-wrapper .asteria-adjustable-add .asteria-adjustable-section-title {
		position: sticky;
		top: 0;
		z-index: 2;
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper .asteria-list-content .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-categories {
		flex-basis: 16%
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-total {
		margin-left: auto !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-total {
		margin-left: auto;
	}

	.asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper .asteria-list-content .asteria-transactions-divider {
		position: sticky;
		top: 0;
		z-index: 1
	}

	.asteria-button-close-accordion {
		margin-left: auto
	}

	.asteria-button-close-accordion .asteria-text {
		font-size: 14px !important;
		color: #512b2b !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner .asteria-adjustable-section-title .asteria-button-save-adjustments {
		margin-right: -4px !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner .asteria-adjustable-section-title .asteria-button-info .asteria-icon-wrapper .asteria-icon-svg {
		fill: #9f8c82 !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner .asteria-infobox {
		margin: 0
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner .asteria-input-wrapper {
		background: #ebe8e8 !important;
		border-radius: 3px !important;
		border: 2px solid transparent !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner .asteria-input-wrapper.asteria-state-focus {
		background: #ecf9f3 !important;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner .asteria-input-wrapper.asteria-state-active {
		background: #ecf9f3 !important;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner .asteria-input-wrapper:hover {
		background: #ecf9f3 !important;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner .asteria-forms-dropdown .asteria-forms-dropdown-toggle {
		background: #ebe8e8 !important;
		border-radius: 3px !important;
		border: 2px solid transparent !important;
		padding-right: 8px;
		position: relative
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner .asteria-forms-dropdown .asteria-forms-dropdown-toggle.asteria-state-focus {
		background: #ecf9f3 !important;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner .asteria-forms-dropdown .asteria-forms-dropdown-toggle.asteria-state-active {
		background: #ecf9f3 !important;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner .asteria-forms-dropdown .asteria-forms-dropdown-toggle:hover {
		background: #ecf9f3 !important;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner .asteria-forms-dropdown .asteria-forms-dropdown-toggle:after {
		border-top: 5px solid #9f8c82;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		content: '';
		position: absolute;
		right: 16px;
		top: 14px;
		display: block
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner .asteria-forms-dropdown .asteria-forms-dropdown-item {
		position: relative
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form {
		min-height: 58px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-form-group {
		padding: 8px 4px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-form-group.asteria-group-description .asteria-input-wrapper {
		max-height: 38px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-form-group.asteria-group-date .asteria-datepicker .asteria-button-icon {
		margin-right: 0 !important;
		border-radius: 4px !important;
		border: 2px solid transparent !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-form-group.asteria-group-date .asteria-datepicker .asteria-button-icon:hover {
		background: #ecf9f3;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-form-group.asteria-group-date .asteria-datepicker .asteria-button-icon.asteria-state-active {
		background: #ecf9f3;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-form-group.asteria-group-date .asteria-datepicker .asteria-calendar-calendars {
		box-shadow: 0 0 0 transparent
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-form-group.asteria-group-date .asteria-datepicker .asteria-calendar-calendars .asteria-calendar-wrapper {
		max-width: 256px !important;
		min-width: 300px !important;
		box-shadow: 0 3px 5px 0 #000
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-form-group.asteria-group-date .asteria-datepicker .asteria-calendar-calendars .asteria-calendar-wrapper .asteria-calendar-content .asteria-button-select-date {
		width: 40px !important;
		border-bottom: 0 solid transparent;
		margin-right: 0 !important;
		height: 40px !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-form-group.asteria-group-date .asteria-datepicker .asteria-calendar-calendars .asteria-calendar-wrapper .asteria-calendar-content .asteria-button-select-date:hover {
		border-bottom: 0 solid transparent
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-form-group.asteria-group-amount .asteria-button-dropdown-currencies {
		margin-right: 8px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-forms-repeatable {
		padding: 8px 4px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-forms-repeatable .asteria-form-group {
		padding: 0
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-forms-repeatable .asteria-forms-dropdown .asteria-forms-dropdown-toggle:after {
		display: none
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-forms-repeatable .asteria-forms-dropdown .asteria-forms-dropdown-toggle .asteria-icon-wrapper {
		width: 16px;
		margin-left: 38px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-button-remove {
		margin: 8px 0 9px 8px;
		padding: 0 0
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-button-remove .asteria-icon-wrapper {
		position: relative;
		margin-right: 0;
		margin-left: 9px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-button-remove .asteria-icon-wrapper .asteria-icon-svg {
		display: none
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-button-remove .asteria-icon-wrapper:after {
		display: block;
		content: '';
		position: absolute;
		right: 4px;
		top: -8px !important;
		width: 16px;
		height: 16px;
		background-image: url("data:image/svg+xml,%3Csvg height='100px' width='100px' fill='%23000000' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 100 100' version='1.1' x='0px' y='0px'%3E%3Ctitle%3EDelete 17.5%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg fill='%239f8b82'%3E%3Cpath d='M38,67.0034652 C38,67.5538362 38.4474692,68 39.0006946,68 L60.9993054,68 C61.5519738,68 62,67.5601869 62,67.0034652 L62,40.9965348 C62,40.4461638 61.5525308,40 60.9993054,40 L39.0006946,40 C38.4480262,40 38,40.4398131 38,40.9965348 L38,67.0034652 Z M64,35.0093689 C64,34.4519098 63.5621186,34 62.9970707,34 L57.2491337,34 C57.111541,34 56.9140625,33.9140625 56.8294811,33.8294811 L55.7107565,32.7107565 C55.3182165,32.3182165 54.5443356,32 54.0046024,32 L45.9953976,32 C45.4456547,32 44.6875,32.3125 44.2892435,32.7107565 L43.1705189,33.8294811 C43.0763439,33.9236561 42.8785019,34 42.7508663,34 L37.0029293,34 C36.4490268,34 36,34.4433532 36,35.0093689 L36,36.9906311 C36,37.5480902 36.4398131,38 36.9965348,38 L63.0034652,38 C63.5538362,38 64,37.5566468 64,36.9906311 L64,35.0093689 Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
		background-size: 35px;
		background-repeat: no-repeat;
		background-position: center center
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-button-remove:hover {
		border: 0 solid transparent
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-adjustable-add .asteria-adjustable-accordion-inner form .asteria-button-remove:hover .asteria-icon-wrapper .asteria-icon-svg {
		fill: #512b2b !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit {
		padding: 8px 0
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit.asteria-state-active, .asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit.asteria-state-hover, .asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit:hover {
		background: #f9f9f9 !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit.asteria-transaction-edit-removed .asteria-transaction-cell.asteria-transaction-cell-options {
		width: 36px;
		height: 38px;
		position: relative
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit.asteria-transaction-edit-removed .asteria-transaction-cell.asteria-transaction-cell-options .asteria-button {
		width: 20px;
		height: 20px;
		position: absolute;
		right: 10px;
		top: 10px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit.asteria-transaction-edit-removed .asteria-transaction-cell.asteria-transaction-cell-options .asteria-button .asteria-icon-wrapper {
		height: 20px;
		width: 20px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit.asteria-transaction-edit-removed .asteria-transaction-cell.asteria-transaction-cell-options .asteria-button .asteria-icon-wrapper .asteria-icon-svg {
		left: 4px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit.asteria-transaction-edit-removed .asteria-transaction-cell.asteria-transaction-cell-options .asteria-button:hover .asteria-icon-wrapper .asteria-icon-svg {
		fill: #53a1ac !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell {
		margin: 0 4px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell .asteria-input-wrapper {
		background: #ebe8e8 !important;
		border-radius: 3px !important;
		border: 2px solid transparent !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell .asteria-input-wrapper.asteria-state-focus {
		background: #ecf9f3 !important;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell .asteria-input-wrapper.asteria-state-active {
		background: #ecf9f3 !important;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell .asteria-input-wrapper:hover {
		background: #ecf9f3 !important;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell .asteria-forms-dropdown .asteria-forms-dropdown-toggle {
		background: #ebe8e8 !important;
		border-radius: 3px !important;
		border: 2px solid transparent !important;
		position: relative;
		padding-right: 32px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell .asteria-forms-dropdown .asteria-forms-dropdown-toggle.asteria-state-focus {
		background: #ecf9f3 !important;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell .asteria-forms-dropdown .asteria-forms-dropdown-toggle.asteria-state-active {
		background: #ecf9f3 !important;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell .asteria-forms-dropdown .asteria-forms-dropdown-toggle:hover {
		background: #ecf9f3 !important;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell .asteria-forms-dropdown .asteria-forms-dropdown-toggle:after {
		border-top: 5px solid #9f8c82;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		content: '';
		position: absolute;
		right: 16px;
		top: 14px;
		display: block
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell .asteria-forms-dropdown .asteria-forms-dropdown-item {
		position: relative
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-description {
		max-width: 398px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-payment-date .asteria-datepicker .asteria-button-icon {
		border-radius: 4px !important;
		border: 2px solid transparent !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-payment-date .asteria-datepicker .asteria-button-icon:hover {
		background: #ecf9f3;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-payment-date .asteria-datepicker .asteria-button-icon.asteria-state-active {
		background: #ecf9f3;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-categories.asteria-state-disabled {
		max-width: 170px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-flat-status {
		display: none
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-risk-high {
		display: none
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-risk-low {
		display: none
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-risk {
		display: none
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-currency {
		width: auto !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-options {
		width: 36px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-options .asteria-adjustable-total {
		width: 32px;
		height: 32px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-options .asteria-adjustable-total .asteria-icon-wrapper {
		height: 16px !important;
		width: 16px !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-options .asteria-adjustable-total:hover .asteria-icon-wrapper .asteria-icon-svg {
		fill: #53a1ac !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transactions-divider.asteria-transactions-divider-feature-inline-edit {
		position: sticky;
		top: 0;
		z-index: 1
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit {
		padding: 8px 0
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit.asteria-transaction-edit-removed .asteria-transaction-cell.asteria-transaction-cell-options {
		width: 36px;
		height: 38px;
		position: relative
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit.asteria-transaction-edit-removed .asteria-transaction-cell.asteria-transaction-cell-options .asteria-button {
		width: 20px;
		height: 20px;
		position: absolute;
		right: 10px;
		top: 10px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit.asteria-transaction-edit-removed .asteria-transaction-cell.asteria-transaction-cell-options .asteria-button .asteria-icon-wrapper {
		height: 20px;
		width: 20px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit.asteria-transaction-edit-removed .asteria-transaction-cell.asteria-transaction-cell-options .asteria-button .asteria-icon-wrapper .asteria-icon-svg {
		left: 4px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit.asteria-transaction-edit-removed .asteria-transaction-cell.asteria-transaction-cell-options .asteria-button:hover .asteria-icon-wrapper .asteria-icon-svg {
		fill: #53a1ac !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell {
		margin: 0 4px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell .asteria-input-wrapper {
		background: #ebe8e8 !important;
		border-radius: 3px !important;
		border: 2px solid transparent !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell .asteria-input-wrapper.asteria-state-focus {
		background: #ecf9f3 !important;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell .asteria-input-wrapper.asteria-state-active {
		background: #ecf9f3 !important;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell .asteria-input-wrapper:hover {
		background: #ecf9f3 !important;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell .asteria-forms-dropdown .asteria-forms-dropdown-toggle {
		background: #ebe8e8 !important;
		border-radius: 3px !important;
		border: 2px solid transparent !important;
		position: relative;
		padding-right: 32px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell .asteria-forms-dropdown .asteria-forms-dropdown-toggle.asteria-state-focus {
		background: #ecf9f3 !important;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell .asteria-forms-dropdown .asteria-forms-dropdown-toggle.asteria-state-active {
		background: #ecf9f3 !important;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell .asteria-forms-dropdown .asteria-forms-dropdown-toggle:hover {
		background: #ecf9f3 !important;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell .asteria-forms-dropdown .asteria-forms-dropdown-toggle:after {
		border-top: 5px solid #9f8c82;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		content: '';
		position: absolute;
		right: 16px;
		top: 14px;
		display: block
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-description {
		max-width: 398px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-payment-date .asteria-datepicker .asteria-button-icon {
		border-radius: 4px !important;
		border: 2px solid transparent !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-payment-date .asteria-datepicker .asteria-button-icon:hover {
		background: #ecf9f3;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-payment-date .asteria-datepicker .asteria-button-icon.asteria-state-active {
		background: #ecf9f3;
		border: 2px solid #aacccf !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-currency {
		width: 8% !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-options {
		width: 36px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-options .asteria-button {
		width: 32px;
		height: 32px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-options .asteria-button .asteria-icon-wrapper {
		height: 32px;
		width: 32px
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transactions-edit .asteria-transaction .asteria-transaction-wrapper-inner.asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-options .asteria-button:hover .asteria-icon-wrapper .asteria-icon-svg {
		fill: #53a1ac !important
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-cashflow-graph.asteria-cashflow-graph-layout-tabs .asteria-tabs-navigation {
		max-width: 100% !important;
		border-radius: 4px !important;
		overflow: hidden !important;
		margin-top: 2px !important;
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-cashflow-graph.asteria-cashflow-graph-layout-tabs .asteria-tabs-navigation .asteria-tab-navigation-item {
		border-radius: 0 !important;
		flex: 1 !important;
		margin: 0 !important;
		gap: 1px !important;
		background: #ebe7e2 !important;
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-cashflow-graph.asteria-cashflow-graph-layout-tabs .asteria-tabs-navigation .asteria-tab-navigation-item.asteria-state-active {
		background: rgb(255, 204, 0) none repeat scroll 0% 0% !important;
	}

	.asteria-feature-adjustable-inline-swedbank-style .asteria-widget-main .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-transaction-single-edit .asteria-transaction-edit .asteria-transaction-cell.asteria-transaction-cell-options .asteria-adjustable-total {
		display: flex !important;
	}

	.asteria-feature-asteria-improvements .asteria-transactions-divider-feature-inline-edit .asteria-adjustable-total {
		display: flex !important;
	}

	acorn-page-region {
		margin: -16px 0 0 0;
	}

	acorn-hero, acorn-hero h1, sw-head, sw-head h1, .asteria-widget-title {
		display: none;
	}

	@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
		._theme-default .asteria-feature-layout-workareas-latest:first-of-type, .asteria-feature-layout-workareas-latest:first-of-type {
			max-width: 100% !important;
		}

		.asteria-feature-asteria-improvements .asteria-widget-wrapper .asteria-widget-main .asteria-cashflow-header {
			transform: translateX(0) !important;
			padding: 0px !important;
			width: 100% !important;
		}

		.asteria-feature-asteria-improvements .asteria-widget-wrapper .asteria-widget-main .asteria-cashflow-header .asteria-cashflow-overview-wrapper .asteria-cashflow-overview .asteria-cashflow-overview-header {
			flex-direction: column;
			align-items: flex-start;
		}

		.asteria-feature-asteria-improvements .asteria-widget-wrapper .asteria-widget-main .asteria-cashflow-header .asteria-cashflow-overview-wrapper .asteria-cashflow-overview .asteria-cashflow-overview-header .asteria-title-company-name {
			font-size: 24px !important;
		}

		.asteria-feature-asteria-improvements .asteria-widget-wrapper .asteria-widget-main .asteria-cashflow-header .asteria-cashflow-overview-wrapper .asteria-cashflow-overview .asteria-cashflow-overview-header .asteria-company-amount {
			margin-left: 0;
		}

		.asteria-feature-asteria-improvements .asteria-widget-wrapper .asteria-widget-main .asteria-cashflow-header .asteria-cashflow-overview-wrapper .asteria-cashflow-overview .asteria-cashflow-overview-header .asteria-company-amount .asteria-overview-current-account-balance-title .asteria-small {
			font-size: 12px;
			margin-bottom: 0;
		}

		.asteria-feature-asteria-improvements .asteria-widget-wrapper .asteria-widget-main .asteria-cashflow-header .asteria-cashflow-overview-wrapper .asteria-cashflow-overview .asteria-cashflow-overview-header .asteria-company-amount .asteria-overview-current-account-balance-title {
			text-align: left;
			margin-top: 12px;
			font-size: 24px !important;
		}

		.asteria-feature-asteria-improvements .asteria-widget-wrapper .asteria-widget-main .asteria-cashflow-header .asteria-cashflow-overview-wrapper .asteria-cashflow-overview .asteria-cashflow-overview-header .asteria-company-amount .asteria-tooltip-trigger-wrapper:last-of-type {
			order: -1
		}

		.asteria-feature-asteria-improvements .asteria-widget-wrapper .asteria-widget-main .asteria-cashflow-header .asteria-search-wrapper .asteria-timeslice-selector-wrapper {
			position: absolute;
			right: 50px;
			top: 68px;
		}

		.asteria-feature-asteria-improvements .asteria-widget-wrapper .asteria-widget-main .asteria-cashflow-header .asteria-search-wrapper .asteria-adjustable-navigation {
			position: absolute;
			right: 0;
		}

		.asteria-feature-asteria-improvements .asteria-widget-wrapper .asteria-widget-main .asteria-cashflow-header .asteria-search-wrapper .asteria-button-settings {
			top: -32px
		}

		.asteria-feature-asteria-improvements .asteria-widget-wrapper .asteria-widget-main .asteria-resizer {
			height: 230px !important;
		}

		.asteria-feature-asteria-improvements .asteria-widget-wrapper .asteria-widget-main .asteria-resizer .asteria-resize-handle .asteria-resize-handle--handle {
			display: none !important;
		}

		.asteria-feature-asteria-improvements .asteria-widget-wrapper .asteria-widget-main .asteria-resizer .asteria-cashflow-graph {
			padding-top: 24px !important;
			width: 100% !important;
			margin: 0px;
		}

		.asteria-feature-asteria-improvements .asteria-widget-wrapper .asteria-widget-main .asteria-resizer .asteria-cashflow-graph.asteria-cashflow-graph-layout-tabs .asteria-graph-line-graph-component {
			margin-left: 0;
		}

		.asteria-feature-asteria-improvements .asteria-widget-wrapper .asteria-widget-main .asteria-resizer .asteria-cashflow-graph.asteria-cashflow-graph-layout-tabs .asteria-graph-controller-menu {
			margin-left: 0;
		}
	}

	@media only screen and (min-device-width: 1024px) and (max-device-width: 768px) {
		.asteria-feature-asteria-improvements {}
	}

	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		.asteria-resizer {
			height: 30vh !important;
		}
	}

	._theme-default .asteria-feature-layout-workareas-latest:first-of-type.asteria-widget-fullscreen.asteria-feature-layout-resizable.asteria-widget-layout-large .asteria-cashflow-header::after {
		top: -40px !important;
		height: 150px !important;
	}

	._theme-default .asteria-feature-asteria-improvements.asteria-feature-layout-workareas-latest:first-of-type.asteria-widget-fullscreen.asteria-feature-layout-resizable.asteria-widget-layout-large .asteria-has-actionbar .asteria-actionbar {
		bottom: 0;
		position: fixed !important;
	}

	._theme-default .asteria-feature-asteria-improvements.asteria-feature-layout-workareas-latest:first-of-type.asteria-widget-fullscreen.asteria-feature-layout-resizable.asteria-widget-layout-large .asteria-has-actionbar .asteria-transactions .asteria-transactions-list-wrapper {
		margin-bottom: 100px;
	}

	._theme-default .asteria-feature-asteria-improvements.asteria-widget-fullscreen .asteria-actionbar {
		top: auto !important;
	}

	._theme-default .asteria-feature-layout-workareas-latest:first-of-type.asteria-widget-fullscreen.asteria-feature-layout-resizable.asteria-widget-layout-large {
		height: auto !important;
		overflow-y: scroll !important;
		margin-bottom: 0 !important;
	}

	._theme-default .asteria-feature-layout-workareas-latest:first-of-type.asteria-widget-fullscreen.asteria-feature-layout-resizable.asteria-widget-layout-large .asteria-widget-wrapper {
		height: auto;
	}

	.asteria-widget-fullscreen.asteria-feature-asteria-improvements.asteria-feature-layout-workareas-latest .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper {
		height: calc(100% - 35px) !important;
	}

	._theme-default .asteria-feature-layout-workareas-latest:first-of-type.asteria-widget-fullscreen {
		height: calc(100vh - 82px) !important;
	}

	._theme-default .asteria-feature-layout-workareas-latest:first-of-type {
		height: calc(100vh - 120px) !important;
	}

	.asteria-feature-asteria-improvements.asteria-feature-layout-workareas-latest .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper {
		height: calc(100% - 42px) !important;
	}

	.asteria-feature-asteria-improvements.asteria-widget-fullscreen {
		height: calc(100vh - px) !important;
		max-width: 100% !important;
		top: 82px !important;
		padding-left: 96px !important;
		padding-right: 96px !important;
		box-shadow: none !important;
		padding-top: 40px !important;
		overflow: hidden !important;
	}

	.asteria-feature-asteria-improvements .asteria-title-settings-layout {
		display: block !important;
	}

	.asteria-feature-asteria-improvements .asteria-checkbox-group-wrapper-widget-fullscreen {
		display: block !important;
	}

	.asteria-feature-asteria-improvements .asteria-transaction-filter .asteria-transaction-cell-settings {
		height: 40px;
	}

	.asteria-feature-asteria-improvements .asteria-button-list-settings .asteria-forms-dropdown-toggle {
		padding-top: 4px;
		border-bottom: 2px solid #d0c3bd;
		margin-right: 0;
		background: #e2dcd9;
		border-bottom: 2px solid #d0c3bd;
	}

	.asteria-feature-asteria-improvements .asteria-transactions-empty {
		height: 100%;
	}

	.asteria-feature-asteria-improvements .asteria-search-examples .asteria-category-overview {
		width: auto !important;
	}

	.asteria-feature-asteria-improvements .asteria-widget-main .asteria-cashflow-graph {
		padding-top: 0px !important;
	}

	.asteria-feature-asteria-improvements.asteria-feature-transcation-row-clickable .asteria-transaction .asteria-transaction-cell-categories .asteria-transaction-list-tags {
		overflow: hidden !important;
	}

	.asteria-feature-asteria-improvements .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper .asteria-list-content .asteria-transaction .asteria-transaction-wrapper-inner .asteria-transaction-cell .asteria-text {
		display: block;
	}

	.asteria-feature-asteria-improvements .asteria-controller-search .asteria-controller-search-active-filters {
		margin-top: 0px !important;
	}

	._theme-default .asteria-feature-layout-resizable.asteria-feature-asteria-improvements .asteria-cashflow-graph.asteria-cashflow-graph-layout-tabs .asteria-graph-bars .asteria-graph-bar-group {
		height: calc(100% - 14px) !important;
	}

	._theme-default .asteria-feature-layout-resizable.asteria-feature-asteria-improvements .asteria-cashflow-graph.asteria-cashflow-graph-layout-flat .asteria-graph-bars .asteria-graph-bar-group {
		height: calc(100% - 14px) !important;
	}

	.asteria-feature-asteria-improvements .asteria-cashflow-graph-hide-account-balance-numbers .asteria-graph-line-dot {
		display: none !important;
	}

	.asteria-feature-asteria-improvements .asteria-graph-line-graph-legends .asteria-part-risk .asteria-text, .asteria-feature-asteria-improvements .asteria-graph-line-graph-component .asteria-part-risk .asteria-text {
		margin-top: -1px !important;
	}

	.asteria-feature-layout-workareas-latest .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-content .asteria-transaction .asteria-transaction-cell.asteria-transaction-cell-account .asteria-title {
		flex-basis: 43% !important;
	}

	.asteria-feature-layout-workareas-latest .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-content .asteria-transaction .asteria-transaction-cell.asteria-transaction-cell-currency {
		width: 100% !important;
	}

	.asteria-feature-layout-workareas-latest .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-content .asteria-transaction .asteria-transaction-cell.asteria-transaction-cell-currency .asteria-currency-convert .asteria-currency-org .currency-flag {
		margin-left: 0 !important;
	}

	.asteria-currency-overview .asteria-list-item:first-of-type .asteria-currency-cell-currency-balance .asteria-currency-cell-currency-balance-inner {
		display: none;
	}

	.asteria-feature-layout-workareas-latest .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-content .asteria-transaction .asteria-transaction-cell.asteria-transaction-cell-adjustable .asteria-button-remove {
		margin-top: 0px !important;
	}

	.asteria-feature-layout-workareas-latest .asteria-transaction-edit form .asteria-calendar-calendars {
		z-index: 2 !important;
	}

	.asteria-feature-layout-workareas-latest .asteria-transaction-edit form {
		align-items: start !important
	}

	.asteria-feature-layout-workareas-latest .asteria-has-actionbar .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper {
		height: calc(100% - 37px) !important;
	}

	.asteria-feature-asteria-improvements .asteria-has-actionbar .asteria-actionbar, ._theme-default .asteria-feature-asteria-improvements .asteria-has-actionbar .asteria-actionbar {
		max-width: none !important;
		left: 50%;
		right: auto;
		margin: 0 auto !important;
		width: 100% !important;
		transform: translateX(-50%) !important;
		position: relative !important;
		order: 4;
	}

	._theme-default .asteria-feature-layout-workareas-latest .asteria-transactions {
		min-height: auto !important
	}

	._theme-default .asteria-feature-layout-workareas-latest:first-of-type {
		margin-bottom: -100px !important;
	}

	.asteria-feature-asteria-improvements .asteria-search-wrapper .asteria-prompt-wrapper .asteria-prompt {
		left: calc(50%) !important;
		transform: translateX(-50%) translateY(10px) translateY(-40px) !important;
	}

	@media (max-width: 1440px) {
		._theme-default .asteria-feature-layout-workareas-latest:first-of-type {
			max-width: 90%;
			margin-left: auto !important;
			margin-right: auto !important;
		}

		.asteria-feature-layout-workareas-latest:first-of-type {
			max-width: 90%;
			margin-left: auto !important;
			margin-right: auto !important;
			width: 100%;
		}

		._theme-default .asteria-feature-layout-workareas-latest .asteria-transactions {
			min-height: auto !important
		}
	}

	.asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper .asteria-list-content .asteria-transaction .asteria-transaction-wrapper-inner .asteria-transaction-cell .asteria-text {
		display: flex;
	}

	.modal-body .asteria-stacked .asteria-checkbox-wrapper:last-of-type {
		display: none !important;
	}

	._theme-default .asteria-feature-layout-resizable .asteria-transaction-list-compress .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper, .asteria-feature-layout-resizable .asteria-transaction-list-compress .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper {
		transform: translateY(27px)
	}

	._theme-default .asteria-feature-layout-resizable .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper, .asteria-feature-layout-resizable .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper {
		transform: translateY(-3px)
	}

	.asteria-title-settings-layout {
		display: none !important;
	}

	.asteria-checkbox-group-wrapper-widget-fullscreen {
		display: none !important;
	}

	.asteria-feature-asteria-improvements .asteria-adjustable-total {
		display: none !important;
	}

	.asteria-feature-asteria-improvements .asteria-adjustable-total {
		display: none !important;
	}

	.asteria-display-status-navigation {
		display: none !important;
	}

	.asteria-feature-asteria-improvements .asteria-cashflow-header:after {
		display: block;
		content: '';
		height: 110px;
		width: calc(100% + 200px);
		background: #fff;
		position: fixed;
		z-index: -2;
		top: 0;
		pointer-events: none;
	}

	._theme-default .asteria-feature-asteria-improvements .asteria-cashflow-header {
		width: calc(100% + 56px)
	}

	.asteria-integrations-banks {
		display: none;
	}

	.asteria-graph-area.asteria-graph-area-lines .asteria-graph-line-group {
		height: 117px;
		grid-template-rows: 117px;
	}

	.asteria-tooltip-tag-group-PAID {
		order: 3 !important;
	}

	.asteria-tooltip-tag-group-SIGNED {
		order: 2 !important;
	}

	.asteria-tooltip-tag-group-FORECAST {
		order: 1 !important;
	}

	.asteria-graph-part-signed {
		order: 2;
	}

	.asteria-transaction-tagged-transfer .asteria-transaction-cell-text-status p::after {
		content: 'Signerad';
		display: block;
		font-size: 12px;
	}

	.asteria-transaction-tagged-transfer .asteria-transaction-cell-text-status p {
		font-size: 0px !important;
	}

	.asteria-transaction-tagged-payment .asteria-transaction-cell-text-status p::after {
		content: 'Signerad';
		display: block;
		font-size: 12px;
	}

	.asteria-transaction-tagged-payment .asteria-transaction-cell-text-status p {
		font-size: 0px !important;
	}

	.asteria-security-bar.asteria-security-bar-0 .asteria-security-bar-level-1 {
		background-color: rgb(190, 18, 26);
	}

	@media screen and (min-width: 1200px) {
		.asteria-cashflow-graph-layout-flat .asteria-button-left, .asteria-cashflow-graph-layout-flat .asteria-button-right {
			margin-top: 70px !important;
		}
	}

	.ERCVi .asteria-filter-nav .asteria-search-results .asteria-search-results-item .asteria-text-tag-size {
		width: 55px !important;
	}

	.ERCVi .asteria-filter-nav .asteria-search-results .asteria-search-results-item .asteria-tag-overview-bar {
		width: 100%;
		max-width: 220px;
		margin-left: auto;
	}

	.ERCVi .asteria-filter-nav .asteria-search-results .asteria-search-results-item .asteria-bar-horizontal {
		margin-left: unset !important;
		width: auto !important;
	}

	.gkpPpB .sc-bdVaJa.asteria-text-tag-size {
		width: 115px !important;
	}

	.asteria-feature-sidepane-transaction-detail .asteria-sidepane .asteria-transaction-list-tags .efveRc {
		flex-direction: column !important;
		-webkit-mask-image: none !important;
	}

	.hCQXRX .sc-ccSCjj .sc-dRFtgE .sc-gbOuXE .sc-iNhVCk .sc-drKuOJ .sc-dyGzUR .sc-bFADNz.asteria-transaction-cell-all-categories .sc-dEoRIm .sc-bmyXtO {
		margin-bottom: 8px !important;
		align-self: flex-start;
		align-items: flex-start !important;
	}

	.asteria-transaction-status-unpaid.asteria-transaction-tagged-payment .asteria-transaction-cell-flat-status .asteria-text {
		font-size: 0px !important;
	}

	.asteria-transaction-status-unpaid.asteria-transaction-tagged-payment .asteria-transaction-cell-flat-status .asteria-text::after {
		content: 'Signerad';
		display: block;
		font-size: 12px;
	}

	.asteria-transaction-status-unpaid.asteria-transaction-tagged-transfer .asteria-transaction-cell-flat-status .asteria-text {
		font-size: 0px !important;
	}

	.asteria-transaction-status-unpaid.asteria-transaction-tagged-transfer .asteria-transaction-cell-flat-status .asteria-text::after {
		content: 'Signerad';
		display: block;
		font-size: 12px;
	}

	.asteria-wings-content .asteria-sidepane-header {
		max-height: 24px !important;
		height: 24px !important;
	}

	.asteria-sidepane-header {
		max-height: 56px !important;
		height: 24px !important;
	}

	@-moz-document url-prefix() {
		.asteria-graph-area-grouped.asteria-graph-area-lines .sc-jbKcbu {
			height: 117px;
		}

		.asteria-cashflow-graph-stacked .asteria-graph-stacked {
			height: 237px;
			z-index: 5;
		}

		.asteria-graph-stacked .asteria-graph-area .asteria-graph-bar-group {
			height: 189px;
		}

		.asteria-graph-stacked .asteria-graph-area .asteria-graph-bar-group, .asteria-graph-stacked .asteria-graph-area .asteria-graph-line-group {
			height: 189px;
			height: calc(100% - 76px);
		}

		.asteria-graph-stacked {
			height: calc(100% - 30px) !important;
		}

		.asteria-graph-stacked .asteria-graph-area .asteria-graph-bar-group, .asteria-graph-stacked .asteria-graph-area .asteria-graph-line-group {
			height: 189px;
			height: calc(100% - 19px);
		}

		.asteria-graph-area-stacked.asteria-graph-area-lines .sc-cmTdod {
			margin-top: 2px;
		}

		.asteria-cashflow-graph-layout-tabs .asteria-graph-area-grouped.asteria-graph-area-lines .sc-jbKcbu {
			height: 200px;
		}

		.asteria-feature-workareas.asteria-widget-positioning-fixed .asteria-cashflow-graph-layout-tabs .asteria-graph-area-grouped.asteria-graph-area-lines .sc-jbKcbu {
			height: calc(100% - 77px);
		}

		.asteria-feature-workareas.asteria-widget-positioning-fixed .asteria-cashflow-graph-layout-tabs .asteria-graph-area-lines .sc-cmTdod {
			margin-top: -55px;
		}

		.asteria-feature-workareas.asteria-widget-positioning-fixed .asteria-cashflow-graph-layout-tabs .asteria-tabs-navigation, .asteria-cashflow-graph-layout-tabs .asteria-tabs-navigation {
			flex-basis: initial !important;
		}

		.asteria-feature-workareas.asteria-widget-positioning-fixed .asteria-cashflow-graph-layout-tabs button, .asteria-cashflow-graph-layout-tabs button {
			height: 32px !important;
			flex-basis: auto !important;
		}
	}

	.asteria-feature-graph-tabs .asteria-cashflow-graph-layout-tabs .asteria-graph-area-grouped {
		padding-left: 4px !important;
	}

	.asteria-feature-graph-credit-line-indicator .asteria-graph-controlls-menu-tags .asteria-part-credit span {
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAMCAYAAACwXJejAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACqSURBVHgBjY5NDoIwEEYHhH1DN2I3XEPd6A28gXoDjwA34AYeBTQix2hXhpXpzsSGjmWhIbapvqRNf943MwD/cKWMt5RtfU7QUIbDwWz1JI73806Ibyn82AArrRQ3oWM7zTKnNGKHSlWXJD1Y7ZyzAAiz8gg8IJq81sIpmS8ZIJbL+60Y7pYUoC6f+lGspZTvt2iUrsO+zxeyO1mlm2TGzzTdgI+KEAI/eAHlpTrLZEqSiwAAAABJRU5ErkJggg==') !important;
		background-repeat: no-repeat !important;
		background-position: 50% 50% !important;
		height: 15px !important;
	}

	#asteria-widget.asteria-feature-sidepane-transaction-adjustable-add .asteria-widget-main .asteria-transactions.asteria-transactions-open .asteria-transaction.asteria-state-active {
		z-index: inherit !important;
	}

	.asteria-transactions.asteria-transactions-open .asteria-transaction.asteria-state-active {}

	@media not all and (min-resolution:0.001dpcm) {
		@supports (-webkit-appearance:none) and (stroke-color:transparent) {
			.asteria-graph-area-grouped.asteria-graph-area-lines .sc-jbKcbu {
				height: 117px;
			}

			.asteria-cashflow-graph-stacked .asteria-graph-stacked {
				height: 237px;
				z-index: 5;
			}

			.asteria-graph-stacked .asteria-graph-area .asteria-graph-bar-group {
				height: 189px;
			}

			.asteria-graph-stacked .asteria-graph-area .asteria-graph-bar-group, .asteria-graph-stacked .asteria-graph-area .asteria-graph-line-group {
				height: 189px;
				height: calc(100% - 76px);
			}

			.asteria-graph-stacked {
				height: calc(100% - 30px) !important;
			}

			.asteria-graph-stacked .asteria-graph-area .asteria-graph-bar-group, .asteria-graph-stacked .asteria-graph-area .asteria-graph-line-group {
				height: 189px;
				height: calc(100% - 73px);
			}

			.asteria-graph-area-stacked.asteria-graph-area-lines .sc-cmTdod {
				margin-top: -55px;
			}

			.asteria-cashflow-graph-layout-tabs .asteria-graph-area-grouped.asteria-graph-area-lines .sc-jbKcbu {
				height: 200px;
			}

			.asteria-feature-workareas.asteria-widget-positioning-fixed .asteria-cashflow-graph-layout-tabs .asteria-graph-area-grouped.asteria-graph-area-lines .sc-jbKcbu {
				height: calc(100% - 77px);
			}

			.asteria-feature-workareas.asteria-widget-positioning-fixed .asteria-cashflow-graph-layout-tabs .asteria-graph-area-lines .sc-cmTdod {
				margin-top: -55px;
			}

			.asteria-feature-workareas.asteria-widget-positioning-fixed .asteria-cashflow-graph-layout-tabs .asteria-tabs-navigation, .asteria-cashflow-graph-layout-tabs .asteria-tabs-navigation {
				flex-basis: initial !important;
			}

			.asteria-feature-workareas.asteria-widget-positioning-fixed .asteria-cashflow-graph-layout-tabs button, .asteria-cashflow-graph-layout-tabs button {
				height: 32px !important;
				flex-basis: auto !important;
			}
		}
	}

	.asteria-alert-success {
		width: 100% !important;
	}

	.asteria-alert-success .asteria-title-2, .asteria-onboarding-asteria-success .asteria-title-3 {
		white-space: nowrap !important;
		overflow: hidden !important;
		text-overflow: ellipsis !important;
		padding: 0 16px !important;
	}

	.asteria-alert-error .asteria-icon-alert, .asteria-alert-warning .asteria-icon-alert, .asteria-alert-success .asteria-icon-alert {
		height: auto !important;
		width: auto !important;
		min-width: 16px !important;
		min-height: 16px !important;
	}

	.asteria-graph-grouped.asteria-graph-lines .asteria-graph-line-group svg {
		overflow: visible !important;
	}

	.asteria-alert-error .asteria-icon-alert {
		padding: 8px 8px 8px 16px !important;
	}

	.asteria-widget-main .asteria-button-list-settings .asteria-forms-dropdown-toggle {
		width: 36px;
	}

	.asteria-widget-main .asteria-cashflow-graph .asteria-graph-line-overdraft {
		stroke: #C5131B !important
	}

	.asteria-widget-main .asteria-cashflow-graph .asteria-overdraft-indicator {
		fill: #C5131B !important
	}

	.asteria-widget-main .asteria-cashflow-graph-hide-account-balance .asteria-title-bars {
		margin-top: 0 !important
	}

	.asteria-widget-main .asteria-cashflow-graph {
		padding-top: 16px !important
	}

	.asteria-widget-main .asteria-cashflow-graph h2.asteria-title-2:first-of-type {
		margin-top: 0px !important;
	}

	.asteria-widget-main .asteria-actionbar {
		z-index: 5 !important;
	}

	.asteria-feature-sidepane-transaction-adjustable-add .asteria-transactions.asteria-transactions-open .asteria-transaction.asteria-state-active {
		z-index: 200 !important;
	}

	.asteria-widget .asteria-search-area .asteria-icon-search {
		height: 24px !important;
		width: 24px !important;
	}

	.asteria-widget-main .asteria-controller-search-active-filters {
		margin-top: 8px !important;
	}

	.asteria-widget-main .asteria-cashflow-header .asteria-adjustable {
		align-self: flex-start !important;
		margin-top: 6px !important;
	}

	.asteria-adjust-total .asteria-input-type {
		display: none;
	}

	.astera-onboarding-step-wrapper .asteria-form-group>div:nth-child(1) {
		margin-bottom: 8px !important;
	}

	.asteria-transactions-info-open .infoBox-security-wrapper {
		margin-left: 0 !important;
	}

	.asteria-transactions-info-open .infoBox-security-wrapper .infoBox-security {
		margin-right: 8px;
	}

	.asteria-mobile-message .asteria-title {
		display: none;
	}

	.asteria-feature-show-label-below-bar-part.asteria-cashflow-graph .asteria-graph-bars .asteria-label {
		transform: translateY(16px);
	}

	.asteria-feature-show-label-below-bar-part.asteria-cashflow-graph .asteria-graph-bars>div span:nth-child(2) {
		transform: translateY(8px);
	}

	.asteria-feature-show-label-below-bar-part.asteria-cashflow-graph .asteria-graph-bars>div:hover span:nth-child(1) {
		display: none !important;
	}

	.asteria-feature-show-label-below-bar-part.asteria-cashflow-graph .asteria-graph-bars .asteria-label.expenses, .asteria-feature-show-label-below-bar-part.asteria-cashflow-graph .asteria-graph-bars .asteria-label.revenue {
		transform: translateY(0);
	}

	.asteria-icon-alert {
		height: auto !important;
		width: auto !important;
	}

	.accordion-element-wrapper {
		width: 90%
	}

	.asteria-onboarding-body .asteria-button-medium.asteria-button-icon .asteria-icon-wrapper {
		height: 24px;
	}

	@media screen and (min-width: 1680px) {
		.asteria-widget .asteria-has-sidepane .asteria-widget-main {
			width: calc(100% - 158px) !important
		}
	}

	@media screen and (min-width: 1024px) and (max-width: 1279px) {
		.asteria-filter-nav .asteria-overview-account-balance {
			order: -1 !important;
			padding-bottom: 77px !important;
			width: 100% !important;
			margin-bottom: 0 !important;
		}

		.asteria-filter-nav .asteria-overview-deposit, .asteria-filter-nav .asteria-overview-withdraw, .asteria-filter-nav .asteria-tab>div {
			width: 100% !important;
		}

		.asteria-overview-account-balance .asteria-category-title, .asteria-overview-deposit .asteria-category-title, .asteria-overview-withdraw .asteria-category-title {
			flex-direction: row;
			font-weight: 200;
			margin-top: 16px !important;
			margin-bottom: 8px !important;
			font-size: 24px !important;
			padding-left: 8px !important;
		}

		.asteria-overview-account-balance .asteria-category-title .amount, .asteria-overview-deposit .asteria-category-title .amount, .asteria-overview-withdraw .asteria-category-title .amount {
			font-size: 20px !important;
			font-weight: 200;
			line-height: normal;
			margin-left: auto;
			margin-right: 0px;
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 120px;
		}

		.asteria-filter-nav .asteria-category-overview {
			width: 160px !important;
		}

		.asteria-filter-nav .asteria-tab {
			flex-direction: column !important;
		}

		.asteria-overview-deposit .asteria-category-title {
			flex-direction: row;
			font-weight: 200;
			margin-top: 16px !important;
			margin-bottom: 8px !important;
			font-size: 24px !important;
			padding-left: 8px !important;
		}

		.asteria-overview-deposit .asteria-category-title .amount {
			font-weight: 200;
			line-height: normal;
			margin-left: auto;
			margin-right: 0px;
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 120px;
			font-size: 20px !important;
			overflow: hidden;
		}
	}

	@media screen and (max-width: 1380px) {
		.asteria-adjustable .asteria-text {
			display: none !important
		}
	}

	@media screen and (min-width: 1380px) and (max-width: 1440px) {
		.asteria-feature-sidepane-transaction-detail .asteria-has-sidepane .asteria-transactions-header .asteria-button-large .asteria-text, .asteria-feature-sidepane-transaction-adjustable-add .asteria-has-sidepane .asteria-transactions-header .asteria-button-large .asteria-text, asteria-feature-sidepane-transaction-adjustable-edit .asteria-has-sidepane .asteria-transactions-header .asteria-button-large .asteria-text {
			font-size: 22px !important;
		}
	}

	.asteria-transaction-cell-account .asteria-title {
		flex-basis: 40% !important;
	}

	@media screen and (max-width: 2000px) {
		.asteria-slider-content {
			height: 340px !important;
		}

		.asteria-slider-content img {
			width: 114px !important;
		}

		.asteria-slider-content .asteria-title {
			font-size: 25px !important;
			line-height: 100% !important;
		}

		.asteria-has-sidepane .asteria-transactions-open {
			z-index: 4 !important;
		}
	}

	@media screen and (min-width: 1601px) {
		.asteria-widget .asteria-has-sidepane .asteria-widget-main {
			width: calc(100% - 288px);
		}
	}

	@media screen and (min-width: 1540px) and (max-width:1600px) {
		.asteria-widget .asteria-has-sidepane .asteria-widget-main {
			width: calc(100% - 238px);
		}
	}

	@media screen and (min-width: 1680px) {
		.asteria-widget .asteria-has-sidepane .asteria-widget-main {
			width: calc(100% - 108px);
		}
	}

	.asteria-feature-swedbankify .asteria-fill-color1 {
		fill: #FFD7BE;
		fill-opacity: 1;
	}

	.color-muted {
		color: var(--muted-color);
	}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}

	.asteria-icon-payment.asteria-icon-unpaid {
		display: none;
	}

	.asteria-feature-asteria-improvements .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper .asteria-list-content .asteria-transaction.asteria-transaction-status-unpaid.asteria-transaction-tagged-payment .asteria-transaction-wrapper-inner .asteria-transaction-cell.asteria-transaction-cell-flat-status .asteria-text {
		font-size: 0px !important;
	}

	.asteria-feature-asteria-improvements .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper .asteria-list-content .asteria-transaction.asteria-transaction-status-unpaid.asteria-transaction-tagged-payment .asteria-transaction-wrapper-inner .asteria-transaction-cell.asteria-transaction-cell-flat-status .asteria-text::after {
		content: 'Signerad';
		display: block;
		font-size: 14px;
	}

	.asteria-feature-asteria-improvements .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper .asteria-list-content .asteria-transaction.asteria-transaction-status-unpaid.asteria-transaction-tagged-transfer .asteria-transaction-wrapper-inner .asteria-transaction-cell.asteria-transaction-cell-flat-status .asteria-text {
		font-size: 0px !important;
	}

	.asteria-feature-asteria-improvements .asteria-transactions .asteria-transactions-list-wrapper .asteria-transactions-list-wrapper-inner .asteria-list-wrapper .asteria-list-content .asteria-transaction.asteria-transaction-status-unpaid.asteria-transaction-tagged-transfer .asteria-transaction-wrapper-inner .asteria-transaction-cell.asteria-transaction-cell-flat-status .asteria-text::after {
		content: 'Signerad';
		display: block;
		font-size: 14px;
	}
`;
/*
#asteria-widget {
	${({ siteStyle = '' }) =>
		css`
			${siteStyle}
		`}
}
*/

const Layout = styled(
	React.memo(({ className, showTerms, callback, siteStyle = '' }) => {
		useFeature({ key: 'combine-tooltip' });
		useFeature({ key: 'tooltip-include-account' });
		const hideSwedbankPageTitle = useFeature({
			key: 'hide-swedbank-page-title',
		});

		// eslint-disable-next-line no-unused-vars
		const { pathname } = useLocation();
		const [featureClasses = []] = useStore(
			'store-features',
			store => store?.classes,
		);

		const [size] = useStore('store-appstate', store => {
			const height = store?.size?.graph?.height || null;

			return getLayoutSize(height);
		});

		const [positioning] = useStore(
			'store-appstate',
			store => store?.layout?.positioning || 'relative',
		);

		const [layout] = useStore(
			'store-appstate',
			store => store?.layout?.layout || 'vertical',
		);
		const [showFullscreen] = useStore(
			'store-appstate',
			store => store?.layout?.showFullscreen || false,
		);

		const paths = pathname.split('/').reduce((acc, path) => {
			if (acc.length === 0) {
				return [path];
			}
			return [...acc, `${acc[acc.length - 1]}-${path}`];
		}, []);

		return (
			<div
				id="asteria-widget"
				className={classNames(
					className,
					featureClasses.filter(f => {
						if (positioning === 'flat') {
							return (
								f !== 'asteria-feature-layout-workareas-latest'
							);
						}
						return true;
					}),
					'asteria-widget',
					`asteria-widget-positioning-${positioning}`,
					`asteria-widget-layout-${layout}`,
					`asteria-widget-layout-${size}`,
					{
						'asteria-widget-fullscreen': showFullscreen,
					},
					paths.map(path => `asteria-route${path}`),
				)}
			>
				<GlobalStyle />
				<ThemeGlobalStyle
					siteStyle={siteStyle}
					hideSwedbankPageTitle={hideSwedbankPageTitle}
				/>
				<FeatureFlag feature="wings-toolbar">
					<Toolbar key="toolbar" />
				</FeatureFlag>
				<Router showTerms={showTerms} callback={callback} />
			</div>
		);
	}),
)`
	${({ theme }) => {
		const style = compileStyles(
			{
				children: [
					{ component: Button },
					{ component: Text },
					{ component: Title },
				],
			},
			theme,
		);
		return style;
	}}

	${({ theme }) => {
		const style = compileStyles(Layout, theme);
		return style;
	}}

	.my-node-enter {
		opacity: 0;
	}
	.my-node-enter-active {
		opacity: 1;
		transition: opacity 0.5s;
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			transition: opacity 0s;
		}
	}
	.my-node-exit {
		opacity: 1;
	}
	.my-node-exit-active {
		opacity: 0;
		transition: opacity 0.5s;
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			transition: opacity 0s;
		}
	}

	.animate-height-enter {
		max-height: 0;
		overflow: hidden;
		transition-timing-function: ease-in-out;
	}
	.animate-height-enter-active {
		max-height: 150px;
		transition: max-height 0.5s;
		transition-timing-function: ease-in-out;
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			transition: max-height 0s;
		}
	}
	.animate-height-exit {
		max-height: 150px;
		transition: max-height 0.5s;
		overflow: hidden;
		transition-timing-function: ease-in-out;
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			transition: max-height 0s;
		}
	}
	.animate-height-exit-active {
		max-height: 0;
		transition: max-height 0.5s;
		transition-timing-function: ease-in-out;
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			transition: max-height 0s;
		}
	}

	.animate-drawer-appear,
	.animate-drawer-enter {
		opacity: 0;
	}
	.animate-drawer-appear-active,
	.animate-drawer-enter-active {
		opacity: 1;
		transition: all 0.2s;
		.asteria-sidepane-wrapper {
			width: 288px;
		}
	}
	.animate-drawer-exit {
		opacity: 1;
		.asteria-sidepane-wrapper {
			width: 288px;
		}
	}
	.animate-drawer-exit-active {
		opacity: 0;
		transition: all 0.2s;
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			transition: all 0s;
		}
	}
	.animate-drawer-enter-done {
		.asteria-sidepane-wrapper {
			width: 288px;
		}
	}

	&.asteria-feature-sidepane-transaction-adjustable-edit
		.asteria-group-date
		.asteria-calendar-wrapper,
	&.asteria-feature-sidepane-transaction-adjustable-add
		.asteria-group-date
		.asteria-calendar-wrapper {
		max-width: 240px;
		min-width: 240px;
	}

	&.asteria-feature-sidepane-transaction-adjustable-edit
		.asteria-group-date
		.asteria-calendar-wrapper
		.asteria-calendar-header-year
		.asteria-title,
	&.asteria-feature-sidepane-transaction-adjustable-add
		.asteria-group-date
		.asteria-calendar-wrapper
		.asteria-calendar-header-month
		.asteria-title {
		font-size: 14px;
	}

	&.asteria-feature-sidepane-transaction-adjustable-edit
		.asteria-group-date
		.asteria-calendar-wrapper
		.asteria-calendar-weekdays
		.asteria-text,
	&.asteria-feature-sidepane-transaction-adjustable-add
		.asteria-group-date
		.asteria-calendar-wrapper
		.asteria-calendar-weekdays
		.asteria-text {
		font-size: 12px;
	}

	&.asteria-feature-sidepane-transaction-adjustable-add
		.asteria-group-date
		.asteria-calendar-wrapper
		.asteria-calendar-content
		.asteria-datepicker-date.asteria-state-active
		.asteria-button {
		background: none;
		.asteria-text {
			z-index: 1;
		}
		&:after {
			content: '';
			height: 30px;
			width: 30px;
			background: #efb7b6;
			border-radius: 50%;
			display: block;
			transform: translate(-50%, -50%);
			position: absolute;
			left: 50%;
			top: 50%;
		}
	}

	&.asteria-feature-sidepane-transaction-adjustable-add,
	&.asteria-feature-sidepane-transaction-adjustable-edit {
		.asteria-calendar-calendars {
			bottom: 0;
			width: 100%;
			.asteria-calendar-wrapper {
				max-width: 256px;
			}
		}
	}
	&.asteria-feature-adjustable-repeatable {
		.asteria-calendar-calendars {
			bottom: auto !important;
			width: 100%;
			.asteria-calendar-wrapper {
				max-width: 256px;
			}
		}
	}
	&.asteria-feature-sidepane-transaction-adjustable-add,
	&.asteria-feature-sidepane-transaction-adjustable-edit {
		.asteria-adjustable-section-title {
			flex: 0;
		}
		.asteria-adjustable-accordion {
			flex: 1;
			div {
				justify-content: flex-start !important;
				margin-bottom: 0px !important;
				.asteria-modal {
					justify-content: center !important;
					margin: 70px auto;
				}
			}
		}
	}

	&.asteria-feature-search {
		.asteria-timeslice-selector {
			@media (min-width: 768px) {
				margin-top: 16px !important;
			}
		}
	}

	margin-top: 16px;

	&.asteria-feature-actionbar-in-list .asteria-actionbar {
		background: #fbf2ec;
		position: sticky;
		top: 211px;
		left: auto;
		right: auto;
		transform: translateX(calc(0%));
		width: auto;
		max-width: none;
		margin: 0px 0 2px;
		.asteria-title {
			font-size: 20px;
			color: rgb(238, 112, 35);
			font-family: Swedbank Headline;
			font-weight: 400;
			text-align: left;
		}
	}

	&.asteria-feature-integrations-add-modal {
		.asteria-integrations-modal {
			.astera-onboarding-step-wrapper > div {
				flex-basis: 100% !important;
			}
			.asteria-onboarding-header,
			.asteria-onboarding-steps {
				display: none;
			}
			.asteria-status-layout {
				flex-basis: 100% !important;
			}
			.asteria-modal {
				max-width: 540px;
				min-height: unset;
				.asteria-modal-erp-missing,
				.asteria-onboarding-guide-modal {
					.asteria-modal {
						max-width: 490px;
					}
				}
				.astera-onboarding-step-wrapper {
					padding: 32px;
					flex: 1;
					height: 100%;
					> div {
						flex-basis: 70%;
					}
					.asteria-onboarding-steps {
						flex-basis: 30%;
						margin-left: auto;
					}
				}
				.asteria-onboarding-body {
					padding-bottom: 0;
					padding-right: 0;
				}
				.asteria-onboarding-footer {
					display: none;
				}
			}
		}
	}

	&.asteria-feature-show-label-below-bar-part {
		.asteria-bar-indicator {
			top: -29px;
		}
	}

	&.asteria-feature-graph-account-balance-without-xaxis {
		.asteria-graph-lines {
			margin-bottom: -18px;
		}
	}

	&.asteria-feature-sidepane-transaction-detail
		.asteria-transaction-list-tags {
		max-width: -webkit-fill-available !important;
	}

	.asteria-forms-dropdown-disabled {
		opacity: 0.6;
		button {
			cursor: not-allowed;
		}
	}

	&.asteria-feature-sidepane-transaction-adjustable-add
		.asteria-adjustable-section-title,
	&.asteria-feature-sidepane-transaction-adjustable-edit
		.asteria-adjustable-section-title {
		z-index: 1;
		overflow: visible;
	}

	&.asteria-feature-sticky-navigation {
		@supports (-ms-ime-align: auto) {
			.asteria-cashflow-header {
				top: 0px !important;
			}
		}
		@supports (-ms-ime-align: auto) and (-webkit-text-stroke: initial) {
			.asteria-cashflow-header {
				top: 0px !important;
			}
		}
	}

	&.asteria-feature-sidepane-transaction-detail
		.asteria-has-sidepane
		.asteria-transactions-header
		.asteria-adjustable-total
		.asteria-text {
		font-size: 16px !important;
	}

	&.asteria-feature-sidepane-transaction-adjustable-edit
		.asteria-has-sidepane
		.asteria-transactions-header
		.asteria-adjustable-total
		.asteria-text {
		font-size: 16px !important;
	}

	&.asteria-feature-sidepane-transaction-adjustable-add .asteria-has-sidepane,
	&.asteria-feature-sidepane-transaction-adjustable-edit
		.asteria-has-sidepane,
	&.asteria-feature-sidepane-transaction-details .asteria-has-sidepane {
		.asteria-transactions-header {
			@media screen and (max-width: 1380px) {
				.asteria-button-large {
					.asteria-text {
						font-size: 18px;
					}
				}
				.asteria-adjustable {
					.asteria-text {
						font-size: 12px;
					}
				}
				.transactions-header-total {
					font-size: 17px;
				}
			}
		}
	}
	&.asteria-feature-mobile-message {
		.asteria-insights {
			@media screen and (max-width: 600px) {
				display: none !important;
			}
		}
		.asteria-mobile-message {
			display: none;
		}
		@media screen and (max-width: 600px) {
			.asteria-cashflow-graph-grouped {
				display: none;
			}
			.asteria-transactions {
				display: none;
			}
			.asteria-mobile-message {
				display: flex;
				height: 100vh;
				background: #fff;
				z-index: 1;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				padding: 12px 12px 12px 12px;
				z-index: 9999;
				text-align: left;
				display: flex;
				flex-direction: column;
				// justify-content: center;
				// align-items: center;
				.asteria-title {
					// padding: 0 32px;
				}
				.asteria-text {
					// padding: 0 32px;
				}
			}
		}
	}
	&.asteria-feature-transcation-row-clickable {
		.asteria-transaction {
			position: relative;
			.asteria-transaction-cell-status {
				.asteria-checkbox-group-wrapper {
					position: relative;
					z-index: 1;
				}
			}
			.asteria-transaction-cell-description {
				.asteria-transaction-cell-status {
					position: relative;
					z-index: 1;
				}
			}
			.asteria-transaction-cell-flat-status {
				.asteria-text {
					position: relative;
					z-index: 1;
				}
				.asteria-transaction-cell-status {
					position: relative;
					z-index: 1;
				}
			}
			.asteria-transaction-cell {
				.asteria-text {
					position: relative;
					z-index: 1;
				}
			}
			.asteria-transaction-cell-categories {
				.asteria-transaction-list-tags {
					position: relative;
					z-index: 1;
				}
			}
			.asteria-transaction-cell-total {
				.asteria-security-bar {
					position: relative;
					z-index: 1;
				}
			}
			.asteria-transaction-cell-security {
				.asteria-security-bar {
					position: relative;
					z-index: 1;
				}
			}
			.asteria-transaction-cell-toggle {
				.asteria-button {
					position: static;
					&:after {
						position: absolute;
						right: 0;
						left: 0;
						bottom: 0;
						top: 0;
						content: '';
						width: 100%;
						background: transparent;
					}
				}
			}
		}
	}
	.asteria-transaction-cell {
	}
	&.asteria-feature-promotion-swedbankify {
	}
	.asteria-has-sidepane {
		.asteria-floating-navigation {
			display: none;
			right: 320px;
		}
	}
	.asteria-floating-navigation {
		position: fixed;
		bottom: 32px;
		right: 32px;
		width: 32px;
		transition: all 0.5s;
		height: 32px;
		z-index: 5;
		.asteria-button {
			width: 40px !important;
			height: 40px !important;
			padding: 0 !important;
			.asteria-text {
				display: none;
			}
			.asteria-icon-wrapper {
				width: 40px !important;
				height: 40px !important;
				margin-right: 0 !important;
			}
		}
	}
	&.asteria-feature-navigation-slim {
		.asteria-overview-deposit {
			div {
				display: none;
			}
		}
		.asteria-overview-withdraw {
			div {
				display: none;
			}
		}
		.asteria-tab-categories {
			margin-top: -32px;
			display: flex !important;
			.asteria-category-title {
				display: none;
			}
		}
	}
	.asteria-has-sidepane .asteria-transactions-open {
		z-index: unset !important;
		// z-index: 99 !important;
	}

	&.asteria-feature-sticky-navigation .asteria-transactions-header {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			position: relative !important;
			top: auto !important;
		}
	}
	&.asteria-feature-sticky-navigation .asteria-transaction-filter {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			position: relative !important;
			top: auto !important;
		}
	}
	.asteria-cashflow-header .asteria-forms-dropdown {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			margin-left: 80px;
			margin-right: -8px !important;
		}
	}

	div.asteria-overview-account-balance .asteria-category-title .amount {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			max-width: none;
		}
	}

	&.asteria-feature-sidepane-transaction-detail
		.asteria-transaction-cell-text-status {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			justify-content: flex-start !important;
		}
	}
	&.asteria-feature-sidepane-transaction-detail
		.asteria-transaction-cell-all-categories
		.asteria-title {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			margin-bottom: 16px !important;
		}
	}

	.asteria-customer-details .asteria-customer-details-item .asteria-text {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			justify-content: flex-start;
		}
	}
	.asteria-adjust-total .asteria-adjustable-section-title {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			justify-content: flex-start;
		}
	}
	.asteria-button-list-settings
		.asteria-forms-dropdown-toggle
		.asteria-icon-wrapper {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			height: 24px !important;
			width: 24px !important;
		}
	}
	.asteria-transaction-action-modify {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			border-left: 2px solid #53a1ac;
		}
	}
	.asteria-transaction-action-create {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			border-left: 2px solid #51971b;
		}
	}
	.asteria-transaction-action-remove {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			border-left: 2px solid #c5131c;
		}
	}
	&.asteria-feature-sidepane-transaction-adjustable-add
		.asteria-adjustable-transaction-accordion,
	&.asteria-feature-sidepane-transaction-adjustable-edit
		.asteria-transaction-edit
		> form {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			height: 100%;
		}
	}
	&.asteria-feature-sidepane-transaction-adjustable-edit
		.asteria-transaction-edit
		> form
		> div {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			max-height: 100px;
		}
	}
	&.asteria-feature-sidepane-transaction-adjustable-add
		.asteria-forms-dropdown
		.asteria-button,
	&.asteria-feature-sidepane-transaction-adjustable-edit
		.asteria-forms-dropdown
		.asteria-button,
	&.asteria-feature-sidepane-transaction-adjustable-add
		.asteria-forms-dropdown
		.asteria-button,
	&.asteria-feature-sidepane-transaction-adjustable-edit
		.asteria-forms-dropdown
		.asteria-button {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			justify-content: flex-start;
		}
	}

	&.asteria-feature-sidepane-transaction-adjustable-edit
		.asteria-button-info
		svg,
	&.asteria-feature-sidepane-transaction-adjustable-add
		.asteria-button-info
		svg {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			fill: #ee7024 !important;
		}
	}
	&.asteria-feature-sidepane-transaction-adjustable-add .asteria-button-info,
	&.asteria-feature-sidepane-transaction-adjustable-edit
		.asteria-button-info {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			margin-top: 16px;
			margin-bottom: 8px;
		}
	}
	&.asteria-feature-sidepane-transaction-adjustable-edit .asteria-infobox,
	&.asteria-feature-sidepane-transaction-adjustable-add .asteria-infobox {
		margin-top: 30px !important;
		margin-bottom: 40px !important;
	}
	.asteria-graph-controlls-menu-tags .asteria-text {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			color: #512b2b;
		}
	}
	.asteria-actionbar {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			width: calc(100% - 20px) !important;
			right: auto !important;
			top: auto !important;
			margin-top: 0 !important;
			position: relative !important;
			z-index: 0 !important;
		}
	}

	.asteria-calendar-weekdays {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			display: flex !important;
			flex: 1 !important;
			padding: 11px 15px 30px;
		}
	}
	.asteria-calendar-content {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			display: flex !important;
			flex: 1 !important;
			flex-wrap: wrap !important;
			height: 200px !important;
		}
	}
	.asteria-calendar-content > div {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			width: 40px !important;
			height: 40px !important;
		}
	}
	&.asteria-feature-sidepane-transaction-adjustable-edit
		.asteria-calendar-section
		.asteria-calendar-content
		div,
	&.asteria-feature-sidepane-transaction-adjustable-add
		.asteria-calendar-section
		.asteria-calendar-content
		div {
		justify-content: center !important;
	}
	&.asteria-feature-sidepane-transaction-adjustable-add
		.asteria-calendar-section
		.asteria-calendar-content
		div
		.asteria-button,
	&.asteria-feature-sidepane-transaction-adjustable-edit
		.asteria-calendar-section
		.asteria-calendar-content
		div
		.asteria-button {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			padding: 0 !important;
		}
	}
	&.asteria-feature-sidepane-transaction-adjustable-add
		.asteria-calendar-section
		.asteria-calendar-content
		div
		.asteria-button
		.asteria-text,
	&.asteria-feature-sidepane-transaction-adjustable-edit
		.asteria-calendar-section
		.asteria-calendar-content
		div
		.asteria-button
		.asteria-text {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			margin: 0 0 !important;
		}
	}
	&.asteria-feature-integrations-add-modal
		.asteria-integrations-modal
		.asteria-modal {
		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			min-height: 520px;
		}
	}

	@media screen and (-ms-high-contrast: active),
		screen and (-ms-high-contrast: none) {
		/* IE10+ specific styles go here */
		.asteria-button-arrange {
			display: none;
		}
	}

	&.asteria-feature-graph-credit-line-indicator
		.asteria-graph-line-graph-legends
		.asteria-part-credit {
	}
	&.asteria-feature-graph-credit-line-indicator
		.asteria-graph-line-graph-legends
		.asteria-part-credit
		span {
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAMCAYAAACwXJejAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACqSURBVHgBjY5NDoIwEEYHhH1DN2I3XEPd6A28gXoDjwA34AYeBTQix2hXhpXpzsSGjmWhIbapvqRNf943MwD/cKWMt5RtfU7QUIbDwWz1JI73806Ibyn82AArrRQ3oWM7zTKnNGKHSlWXJD1Y7ZyzAAiz8gg8IJq81sIpmS8ZIJbL+60Y7pYUoC6f+lGspZTvt2iUrsO+zxeyO1mlm2TGzzTdgI+KEAI/eAHlpTrLZEqSiwAAAABJRU5ErkJggg==') !important;
		background-repeat: no-repeat !important;
		background-position: 50% 50% !important;
		height: 15px !important;
	}
	.asteria-token-copy {
		background: #d4c4bc;
		.asteria-button {
			margin-right: 8px;
			svg {
				fill: rgb(114, 96, 93) !important;
			}
		}
	}

	&.asteria-feature-adjustable-amount-input-type-icon {
		.asteria-adjustable-total {
			.asteria-text-value {
				padding-left: 38px !important;
			}
		}
	}

	&.asteria-feature-sidepane-transaction-adjustable-add
		.asteria-transactions.asteria-transactions-open
		.asteria-transaction.asteria-state-active {
		z-index: 200 !important;
	}

	.asteria-widget-main
		.asteria-cashflow-graph-hide-account-balance
		.asteria-title-bars {
		margin-top: 0 !important;
	}

	.asteria-widget-main .asteria-cashflow-graph .asteria-graph-line-overdraft {
		stroke: #c5131b !important;
	}
	.asteria-widget-main .asteria-cashflow-graph .asteria-overdraft-indicator {
		fill: #c5131b !important;
	}

	&.asteria-feature-sidepane-no-content-push {
		.asteria-widget-main {
			width: 100%;
			@media (min-width: 1440px) {
				width: 100%;
			}
			.asteria-sidepane-wrapper {
				box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.1);
			}
		}
	}

	&.asteria-widget-positioning-fixed {
		.asteria-button-left,
		.asteria-button-right {
			// bottom: -47px !important;
		}
	}

	&.asteria-widget-positioning-divided {
		.asteria-widget-main {
			display: grid;
			grid-template-columns: 50%;
			grid-template-rows: 100px 1fr;

			.asteria-tab {
				.asteria-title {
					display: none !important;
				}

				.asteria-graph-lines,
				.asteria-graph-bars {
					height: calc(100% - 0px) !important;
				}
			}
		}
	}

	// Workareas styling

	&.asteria-widget-positioning-fixed {
		@media (max-height: 800px) {
			.asteria-graph-grouped.asteria-graph-lines .asteria-graph-area {
				grid-template-rows: auto 40px !important;
			}
		}
	}

	// Workarea default stacked graphs css

	&.asteria-widget-positioning-fixed
		.asteria-graph-lines
		.asteria-graph-xaxis-history,
	&.asteria-widget-positioning-fixed
		.asteria-graph-lines
		.asteria-graph-xaxis-today,
	&.asteria-widget-positioning-fixed
		.asteria-graph-lines
		.asteria-graph-xaxis-forecast {
		display: none;
	}

	&.asteria-widget-positioning-fixed
		.asteria-graph-bars
		.asteria-graph-xaxis-history
		.asteria-text,
	&.asteria-widget-positioning-fixed
		.asteria-graph-bars
		.asteria-graph-xaxis-today
		.asteria-text,
	&.asteria-widget-positioning-fixed
		.asteria-graph-bars
		.asteria-graph-xaxis-forecast
		.asteria-text {
		@media (max-height: 800px) {
			font-size: 12px !important;
		}
		@media (max-height: 960px) {
			font-size: 12px !important;
		}
	}

	&.asteria-widget-positioning-fixed
		.asteria-graph-bars
		.asteria-graph-xaxis-history,
	&.asteria-widget-positioning-fixed
		.asteria-graph-bars
		.asteria-graph-xaxis-today,
	&.asteria-widget-positioning-fixed
		.asteria-graph-bars
		.asteria-graph-xaxis-forecast {
		@media (max-height: 800px) {
			height: 23px !important;
		}
		@media (max-height: 960px) {
			height: 23px !important;
		}
	}

	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-bars
		.asteria-graph-xaxis-history,
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-bars
		.asteria-graph-xaxis-today,
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-bars
		.asteria-graph-xaxis-forecast {
		@media (max-height: 800px) {
			height: 32px !important;
		}
		@media (max-height: 960px) {
			height: 32px !important;
		}
	}

	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-bars
		.asteria-graph-xaxis-history,
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-bars
		.asteria-graph-xaxis-today,
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-bars
		.asteria-graph-xaxis-forecast {
		@media (max-height: 800px) {
			height: 32px !important;
		}
		@media (max-height: 960px) {
			height: 32px !important;
		}
	}

	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-controlls-menu-tags
		.asteria-graph-line-graph-component {
		@media (max-height: 800px) {
			margin-top: -16px !important;
		}
		@media (max-height: 960px) {
			margin-top: -16px !important;
		}
	}

	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph
		.asteria-forms-show-selected
		.asteria-forms-dropdown-toggle {
		@media (max-height: 800px) {
			background: transparent;
			margin-top: -6px;
			.asteria-text {
				font-size: 10px;
			}
		}
		@media (max-height: 960px) {
			background: transparent;
			margin-top: -6px;
			.asteria-text {
				font-size: 10px;
			}
		}
	}

	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-forms-show-selected
		.asteria-forms-dropdown-toggle {
		@media (max-height: 800px) {
			background: #fff !important;
			margin-top: 0px !important;
			.asteria-text {
				font-size: 14px !important;
			}
		}
		@media (max-height: 960px) {
			background: #fff !important;
			margin-top: 0px !important;
			.asteria-text {
				font-size: 14px !important;
			}
		}
	}

	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-forms-show-selected
		.asteria-forms-dropdown-toggle {
		@media (max-height: 800px) {
			background: #fff !important;
			margin-top: -6px !important;
			.asteria-text {
				font-size: 14px !important;
			}
		}
		@media (max-height: 960px) {
			background: #fff !important;
			margin-top: -6px !important;
			.asteria-text {
				font-size: 14px !important;
			}
		}
	}

	&.asteria-widget-positioning-fixed
		.asteria-graph-xaxis-history
		span:nth-child(1),
	&.asteria-widget-positioning-fixed
		.asteria-graph-xaxis-history
		span:nth-child(2),
	&.asteria-widget-positioning-fixed
		.asteria-graph-xaxis-today
		span:nth-child(1),
	&.asteria-widget-positioning-fixed
		.asteria-graph-xaxis-today
		span:nth-child(2),
	&.asteria-widget-positioning-fixed
		.asteria-graph-xaxis-forecast
		span:nth-child(1),
	&.asteria-widget-positioning-fixed
		.asteria-graph-xaxis-forecast
		span:nth-child(2) {
		@media (max-height: 800px) {
			display: none !important;
		}
		@media (max-height: 960px) {
			display: none !important;
		}
	}

	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-xaxis-history.asteria-state-active
		span:nth-child(1),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-xaxis-history.asteria-state-active
		span:nth-child(2),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-xaxis-today.asteria-state-active
		span:nth-child(1),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-xaxis-today.asteria-state-active
		span:nth-child(2),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-xaxis-forecast.asteria-state-active
		span:nth-child(1),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-xaxis-forecast.asteria-state-active
		span:nth-child(2) {
		@media (max-height: 800px) {
			display: flex !important;
		}
		@media (max-height: 960px) {
			display: flex !important;
		}
	}

	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-xaxis-history.asteria-state-active
		span:nth-child(1),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-xaxis-history.asteria-state-active
		span:nth-child(2),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-xaxis-today.asteria-state-active
		span:nth-child(1),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-xaxis-today.asteria-state-active
		span:nth-child(2),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-xaxis-forecast.asteria-state-active
		span:nth-child(1),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-xaxis-forecast.asteria-state-active
		span:nth-child(2) {
		@media (max-height: 800px) {
			display: flex !important;
		}
		@media (max-height: 960px) {
			display: flex !important;
		}
	}

	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-xaxis-history.asteria-state-hover
		span:nth-child(1),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-xaxis-history.asteria-state-hover
		span:nth-child(2),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-xaxis-today.asteria-state-hover
		span:nth-child(1),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-xaxis-today.asteria-state-hover
		span:nth-child(2),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-xaxis-forecast.asteria-state-hover
		span:nth-child(1),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-xaxis-forecast.asteria-state-hover
		span:nth-child(2) {
		@media (max-height: 800px) {
			display: flex !important;
		}
		@media (max-height: 960px) {
			display: flex !important;
		}
	}

	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-xaxis-history.asteria-state-hover
		span:nth-child(1),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-xaxis-history.asteria-state-hover
		span:nth-child(2),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-xaxis-today.asteria-state-hover
		span:nth-child(1),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-xaxis-today.asteria-state-hover
		span:nth-child(2),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-xaxis-forecast.asteria-state-hover
		span:nth-child(1),
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-xaxis-forecast.asteria-state-hover
		span:nth-child(2) {
		@media (max-height: 800px) {
			display: flex !important;
		}
		@media (max-height: 960px) {
			display: flex !important;
		}
	}

	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-bars
		.asteria-graph-xaxis-history
		.asteria-text,
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-bars
		.asteria-graph-xaxis-today
		.asteria-text,
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-bars
		.asteria-graph-xaxis-forecast
		.asteria-text {
		font-size: 16px !important;
	}

	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-bars
		.asteria-graph-xaxis-history
		.asteria-text,
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-bars
		.asteria-graph-xaxis-today
		.asteria-text,
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-bars
		.asteria-graph-xaxis-forecast
		.asteria-text {
		font-size: 16px !important;
	}

	&.asteria-widget-positioning-fixed
		.asteria-graph-lines
		.asteria-curreny-y-axis,
	&.asteria-widget-positioning-fixed
		.asteria-graph-bars
		.asteria-curreny-y-axis {
		@media (max-height: 800px) {
			font-size: 10px !important;
			margin-top: 9px !important;
			display: none;
		}
		@media (max-height: 960px) {
			font-size: 10px !important;
			margin-top: 9px !important;
			display: none;
		}
	}

	&.asteria-widget-positioning-fixed
		.asteria-graph-lines
		.asteria-graph-line-dot {
		@media (max-height: 800px) {
			font-size: 11px !important;
			/* top: -25px; */
		}
		@media (max-height: 960px) {
			font-size: 11px !important;
			/* top: -25px; */
		}
	}

	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-lines
		.asteria-graph-line-dot,
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-lines
		.asteria-graph-line-dot {
		// top: -25px;
		font-size: 14px !important;
	}

	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-lines
		.asteria-curreny-y-axis,
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-lines
		.asteria-curreny-y-axis {
		font-size: 14px !important;
		margin-top: 0px !important;
	}

	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-lines
		.asteria-curreny-y-axis,
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-lines
		.asteria-curreny-y-axis {
		font-size: 14px !important;
		margin-top: 0px !important;
	}

	// Workarea tabs combined graph css

	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-lines
		.asteria-graph-xaxis-history,
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-lines
		.asteria-graph-xaxis-today,
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-stacked
		.asteria-graph-lines
		.asteria-graph-xaxis-forecast {
		display: flex;
		min-width: 100px;
	}

	// Workarea tabs navigation graph css

	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-lines
		.asteria-graph-xaxis-history,
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-lines
		.asteria-graph-xaxis-today,
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-lines
		.asteria-graph-xaxis-forecast {
		display: flex;
		min-width: 144px;
	}

	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-lines
		.asteria-graph-yaxis-labels
		.asteria-text,
	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-bars
		.asteria-graph-yaxis-labels
		.asteria-text {
		@media (max-height: 800px) {
			font-size: 14px !important;
		}
		@media (max-height: 960px) {
			font-size: 14px !important;
		}
	}

	&.asteria-widget-positioning-fixed
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-lines {
		@media (max-height: 800px) {
			grid-template-rows: 1fr 40px !important;
		}
		@media (max-height: 960px) {
			grid-template-rows: 1fr 40px !important;
		}
	}

	&.asteria-widget-positioning-fixed.asteria-feature-actionbar-in-list
		.asteria-has-actionbar
		.asteria-list-wrapper {
		/* top: 110px !important; */
	}

	&.asteria-feature-workareas.asteria-widget-positioning-fixed {
		.asteria-list-wrapper {
			.asteria-list-wrapper {
				position: relative !important;
				top: auto !important;
				left: auto !important;
				right: auto !important;
				bottom: auto !important;
				overflow-y: auto !important;
			}
		}
	}

	&.asteria-feature-menu-category-icon-only {
		.asteria-button-navigation {
			padding: 0;
			padding-left: 8px;
			.asteria-icon-category {
				width: 32px;
				height: 32px;
			}
			&.asteria-navigation-active {
				.asteria-icon-category {
					svg {
						fill: #df7b3f !important;
					}
				}
				.asteria-text {
					color: #df7b3f !important;
				}
				&:after {
					@media (min-width: 1024px) {
						width: 56px;
					}
				}
			}
		}

		.asteria-state-focus {
			.asteria-search-area {
				&:before {
					@media (min-width: 1024px) {
						left: 56px;
					}
				}
			}
		}

		.asteria-controller-search {
			&:before {
				@media (min-width: 1024px) {
					left: 56px;
				}
			}
			.asteria-search-area {
				@media (min-width: 1024px) {
					padding-left: 82px;
				}
				.asteria-button-search {
					@media (min-width: 1024px) {
						left: 64px;
					}
				}
			}
		}
	}

	&.asteria-feature-menu-category-icon-with-text {
		.asteria-button-navigation {
			width: auto;
			padding: 0;
			padding-left: 12px;
			.asteria-icon-close {
				margin-left: 7px;
				margin-right: 7px;
			}
			.asteria-icon-category {
				width: 32px;
				height: 32px;
				svg {
					fill: #9f8c82 !important;
				}
			}
			.asteria-text {
				@media (max-width: 1024px) {
					display: none;
				}
				color: var(--text-color) !important;
				text-decoration: none !important;
				font-size: 16px !important;
				margin-left: 8px !important;
				white-space: nowrap;
			}
			&.asteria-navigation-active {
				.asteria-icon-category {
					svg {
						fill: #df7b3f !important;
					}
				}
				.asteria-text {
					color: #df7b3f !important;
				}
				&:after {
					@media (min-width: 1024px) {
						width: 150px;
					}
				}
			}
		}
		.asteria-state-focus {
			.asteria-search-area {
				&:before {
					@media (min-width: 1024px) {
						left: 148px;
					}
				}
			}
		}
		.asteria-controller-search {
			&:before {
				@media (min-width: 1024px) {
					left: 148px;
				}
			}
			.asteria-search-area {
				@media (min-width: 1024px) {
					padding-left: 173px;
				}
				.asteria-button-search {
					@media (min-width: 1024px) {
						left: 156px;
					}
				}
			}
		}
	}

	&.asteria-feature-menu-app-icon-only {
		.asteria-button-navigation {
			padding: 0;
			padding-left: 2px;
			.asteria-icon-apps {
				width: 24px;
				height: 24px;
			}
			&.asteria-navigation-active {
				.asteria-icon-apps {
					svg {
						fill: #df7b3f !important;
					}
				}
				.asteria-text {
					color: #df7b3f !important;
				}
				&:after {
					@media (min-width: 1024px) {
						width: 56px;
					}
				}
			}
		}

		.asteria-state-focus {
			.asteria-search-area {
				&:before {
					@media (min-width: 1024px) {
						left: 56px;
					}
				}
			}
		}

		.asteria-controller-search {
			&:before {
				@media (min-width: 1024px) {
					left: 56px;
				}
			}
			.asteria-search-area {
				@media (min-width: 1024px) {
					padding-left: 82px;
				}
				.asteria-button-search {
					@media (min-width: 1024px) {
						left: 64px;
					}
				}
			}
		}
	}

	&.asteria-feature-menu-app-icon-with-text {
		.asteria-button-navigation {
			width: auto;
			padding: 0;
			padding-left: 12px;
			.asteria-icon-close {
				margin-left: 4px;
				margin-right: 2px;
			}
			.asteria-icon-apps {
				width: 24px;
				height: 24px;
				svg {
					fill: #9f8c82 !important;
				}
			}
			.asteria-text {
				@media (max-width: 1024px) {
					display: none;
				}
				color: var(--text-color) !important;
				text-decoration: none !important;
				font-size: 16px !important;
				margin-left: 8px !important;
				white-space: nowrap;
			}
			&.asteria-navigation-active {
				.asteria-icon-apps {
					svg {
						fill: #df7b3f !important;
					}
				}
				.asteria-text {
					color: #df7b3f !important;
				}
				&:after {
					@media (min-width: 1024px) {
						width: 150px;
					}
				}
			}
		}
		.asteria-state-focus {
			.asteria-search-area {
				&:before {
					@media (min-width: 1024px) {
						left: 100px;
					}
				}
			}
		}
		.asteria-controller-search {
			&:before {
				@media (min-width: 1024px) {
					left: 100px;
				}
			}
			.asteria-search-area {
				@media (min-width: 1024px) {
					padding-left: 126px;
				}
				.asteria-button-search {
					@media (min-width: 1024px) {
						left: 108px;
					}
				}
			}
		}
	}

	&.asteria-feature-menu-default-icon-with-text {
		.asteria-button-navigation {
			width: auto;
			padding-left: 12px;
			.asteria-icon-wrapper {
				svg {
					fill: #9f8c82 !important;
				}
			}
			.asteria-text {
				@media (max-width: 1024px) {
					display: none;
				}
				color: var(--text-color) !important;
				text-decoration: none !important;
				font-size: 16px !important;
				margin-left: 12px !important;
				white-space: nowrap;
			}
			&.asteria-navigation-active {
				.asteria-icon-wrapper {
					svg {
						fill: #df7b3f !important;
					}
				}
				.asteria-text {
					color: #df7b3f !important;
				}
				&:after {
					@media (min-width: 1024px) {
						width: 130px;
					}
				}
			}
		}
		.asteria-state-focus {
			.asteria-search-area {
				&:before {
					@media (min-width: 1024px) {
						left: 100px;
					}
				}
			}
		}
		.asteria-controller-search {
			&:before {
				@media (min-width: 1024px) {
					left: 100px;
				}
			}
			.asteria-search-area {
				@media (min-width: 1024px) {
					padding-left: 126px;
				}
				.asteria-button-search {
					@media (min-width: 1024px) {
						left: 108px;
					}
				}
			}
		}
	}

	&.asteria-feature-show-data-tooltip {
		[data-tooltip] {
			&:before,
			&:after {
				visibility: visible !important;
			}
		}
	}

	&.asteria-feature-workareas.asteria-widget-positioning-fixed {
		.asteria-list-clients {
			.asteria-transaction-filter {
				top: auto !important;
				position: relative !important;
			}
			.asteria-list-wrapper {
				position: relative !important;
				top: auto !important;
				left: auto !important;
				right: auto !important;
				bottom: auto !important;
				overflow-y: auto !important;
			}
		}
	}

	&.asteria-feature-workareas.asteria-widget-positioning-fixed
		.asteria-transactions-mode-clients.asteria-transactions-open {
		.asteria-list-clients-wrapper {
			position: relative;
			display: flex;
			flex: 1;
			flex-direction: column;
		}
		.asteria-list-clients {
			overflow-y: auto;
			display: flex;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			padding: 0 0 0;
			flex-direction: column;
			flex: 1;
			flex-wrap: nowrap;
		}
	}

	&.asteria-route-factoring {
		.asteria-transactions-mode-clients {
			.asteria-transaction-filter {
				.asteria-transaction-cell {
					&:first-of-type {
						width: 16px;
						.asteria-checkbox-group-wrapper {
							display: none;
						}
					}
				}
			}

			.asteria-list-clients {
				.asteria-list-wrapper {
					.asteria-transaction {
						.asteria-transaction-cell {
							&:first-of-type {
								width: 16px;
								.asteria-checkbox-group-wrapper {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}

	.asteria-has-actionbar .asteria-transaction-filter {
		top: 283px !important;
	}

	.asteria-transaction-list-compress {
		.asteria-list-clients {
			.asteria-transaction {
				min-height: auto;
				padding: 6px 0 6px;
				.asteria-transaction-cell-description {
					.asteria-title {
						font-size: 13px;
					}
					.asteria-text {
						font-size: 11px;
					}
				}
				.asteria-transaction-cell-total {
					.asteria-text {
						font-size: 13px;
					}
				}
			}
			.asteria-transactions-list-clients {
				.asteria-transaction {
					padding: 0;
				}
			}
		}
	}

	.asteria-cashflow-graph-stacked {
		.asteria-curreny-y-axis {
			@media (max-height: 800px) {
				font-size: 10px !important;
				margin-top: 9px !important;
				display: block;
			}
			@media (max-height: 960px) {
				font-size: 10px !important;
				margin-top: 9px !important;
				display: block;
			}
		}
		.asteria-graph-bar-group {
			.asteria-deposit {
				div {
					&:hover {
						// box-shadow: 0px 0px 4px rgba(0,0,0,0.4);
					}
				}
			}
			.asteria-withdraw {
				div {
					&:hover {
						// box-shadow: 0px 0px 4px rgba(0,0,0,0.4);
					}
				}
			}

			&.asteria-state-hover {
				.asteria-deposit,
				.asteria-withdraw {
					opacity: 0.7;
					&:hover {
						opacity: 1;
					}
				}
			}
		}
		.asteria-graph-line-group {
			pointer-events: none;
			div:first-of-type {
				pointer-events: none !important;
				div:first-of-type {
					border-color: red;
				}
			}
			div:nth-child(2) {
				div {
					pointer-events: auto !important;
					box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5);
					&:hover {
						box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 1);
					}
				}
			}

			svg {
			}
		}
		.asteria-graph-xaxis-history.asteria-state-active,
		.asteria-graph-xaxis-today.asteria-state-active,
		.asteria-graph-xaxis-forecast.asteria-state-active,
		.asteria-graph-xaxis-history.asteria-state-hover,
		.asteria-graph-xaxis-today.asteria-state-hover,
		.asteria-graph-xaxis-forecast.asteria-state-hover {
			span {
				display: block;
			}
		}
		.asteria-graph-controlls-menu-tag-unpaid,
		.asteria-graph-controlls-menu-tag-overdue {
			margin-top: 1px;
		}
	}

	@media screen and (-ms-high-contrast: active),
		screen and (-ms-high-contrast: none) {
		/* IE10+ specific styles go here */
		.asteria-cashflow-graph {
			.asteria-tabs-navigation {
				display: block !important;
				min-height: 32px !important;
				.asteria-button {
					display: inline-block !important;
				}
			}
		}
		.asteria-dropdown-list-view .asteria-button .asteria-text {
			color: #512b2b !important;
		}

		.asteria-list-clients .asteria-list-clients-row {
			min-height: 48px !important;
			zoom: 1;

			&.asteria-state-active {
				min-height: auto !important;
			}
		}

		.asteria-transactions-open
			.asteria-transactions-list-clients
			div:nth-child(2) {
			display: block !important;
		}
	}

	// Safari and firefox fixes from stage/prod css backend api

	&.asteria-feature-graph-tabs
		.asteria-cashflow-graph-layout-tabs
		.asteria-graph-area-grouped {
		padding-left: 4px !important;
	}
	&.asteria-feature-graph-credit-line-indicator
		.asteria-graph-controlls-menu-tags
		.asteria-part-credit
		span {
		background-image: url('data:image/png;
		base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAMCAYAAACwXJejAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACqSURBVHgBjY5NDoIwEEYHhH1DN2I3XEPd6A28gXoDjwA34AYeBTQix2hXhpXpzsSGjmWhIbapvqRNf943MwD/cKWMt5RtfU7QUIbDwWz1JI73806Ibyn82AArrRQ3oWM7zTKnNGKHSlWXJD1Y7ZyzAAiz8gg8IJq81sIpmS8ZIJbL+60Y7pYUoC6f+lGspZTvt2iUrsO+zxeyO1mlm2TGzzTdgI+KEAI/eAHlpTrLZEqSiwAAAABJRU5ErkJggg==') !important;
		background-repeat: no-repeat !important;
		background-position: 50% 50% !important;
		height: 15px !important;
	}
	&#asteria-widget.asteria-feature-sidepane-transaction-adjustable-add
		.asteria-widget-main
		.asteria-transactions.asteria-transactions-open
		.asteria-transaction.asteria-state-active {
		z-index: inherit !important;
	}

	// Hide adjust actions in stacked graph for now

	.asteria-cashflow-graph-stacked .asteria-graph-bar-actions {
		display: none !important;
		.asteria-adjust-add-event {
			display: none !important;
			.asteria-button {
				display: none !important;
			}
		}
		&:hover {
			display: none !important;
			.asteria-adjust-add-event {
				display: none !important;
				.asteria-button {
					display: none !important;
				}
			}
		}
	}

	// Safari
	@media not all and (min-resolution: 0.001dpcm) {
		@supports (-webkit-appearance: none) and (stroke-color: transparent) {
			.asteria-graph-area-grouped.asteria-graph-area-lines .sc-jbKcbu {
				height: 117px;
			}

			.asteria-tag-overview
				.asteria-button-small.asteria-button-icon-only {
				height: 26px !important;
			}

			.asteria-cashflow-graph-stacked .asteria-graph-stacked {
				height: 237px;
				z-index: 5;
			}
			.asteria-graph-stacked
				.asteria-graph-area
				.asteria-graph-bar-group {
				height: 189px;
			}
			.asteria-graph-stacked .asteria-graph-area .asteria-graph-bar-group,
			.asteria-graph-stacked
				.asteria-graph-area
				.asteria-graph-line-group {
				height: 189px;
				height: calc(100% - 76px);
			}
			.asteria-graph-stacked {
				height: calc(100% - 30px) !important;
			}
			.asteria-graph-stacked .asteria-graph-area .asteria-graph-bar-group,
			.asteria-graph-stacked
				.asteria-graph-area
				.asteria-graph-line-group {
				height: 189px;
				height: calc(100% - 73px);
			}
			.asteria-graph-area-stacked.asteria-graph-area-lines .sc-cmTdod {
				margin-top: -55px;
			}
			.asteria-cashflow-graph-layout-tabs
				.asteria-graph-area-grouped.asteria-graph-area-lines
				.sc-jbKcbu {
				height: 200px;
			}
			&.asteria-feature-workareas.asteria-widget-positioning-fixed
				.asteria-cashflow-graph-layout-tabs
				.asteria-graph-area-grouped.asteria-graph-area-lines
				.sc-jbKcbu {
				height: calc(100% - 77px);
			}
			&.asteria-feature-workareas.asteria-widget-positioning-fixed
				.asteria-cashflow-graph-layout-tabs
				.asteria-graph-area-lines
				.sc-cmTdod {
				margin-top: -55px;
			}
			&.asteria-feature-workareas.asteria-widget-positioning-fixed
				.asteria-cashflow-graph-layout-tabs
				.asteria-tabs-navigation,
			.asteria-cashflow-graph-layout-tabs .asteria-tabs-navigation {
				flex-basis: initial !important;
			}
			&.asteria-feature-workareas.asteria-widget-positioning-fixed
				.asteria-cashflow-graph-layout-tabs
				button,
			.asteria-cashflow-graph-layout-tabs button {
				height: 32px !important;
				flex-basis: auto !important;
			}
		}
	}

	.asteria-survey-modal {
		.asteria-text-desc {
			margin-bottom: 32px;
		}

		.asteria-survey-title {
			display: none;
		}

		.asteria-survey-message {
			padding-top: 8px;
		}
	}

	.asteria-filter-nav {
		.asteria-tag-type-overdraft span,
		.asteria-tag-type-credit span {
			display: none;
		}
	}

	.asteria-feedback-modal {
		.asteria-text-desc {
			margin-bottom: 32px;
		}

		.asteria-survey-title {
			display: none;
		}

		.asteria-feedback-message {
			padding-top: 8px;
		}
	}

	.asteria-modal-overdraft-confirm {
		.asteria-modal-body .asteria-body .asteria-title-1 {
			color: #522f31 !important;
		}
		.asteria-modal-footer {
			.asteria-button-default {
				margin-left: 0;
			}
		}
		.asteria-statement-missing {
			margin-top: 32px;
			.asteria-button-add-erp {
				.asteria-text {
					padding: 0;
				}
			}
		}
	}

	// Firefox

	@-moz-document url-prefix() {
		.asteria-graph-area-grouped.asteria-graph-area-lines .sc-jbKcbu {
			height: 117px;
		}
		.asteria-cashflow-graph-stacked .asteria-graph-stacked {
			height: 237px;
			z-index: 5;
		}
		.asteria-graph-stacked .asteria-graph-area .asteria-graph-bar-group {
			height: 189px;
		}
		.asteria-graph-stacked .asteria-graph-area .asteria-graph-bar-group,
		.asteria-graph-stacked .asteria-graph-area .asteria-graph-line-group {
			height: 189px;
			height: calc(100% - 76px);
		}
		.asteria-graph-stacked {
			height: calc(100% - 30px) !important;
		}
		.asteria-graph-stacked .asteria-graph-area .asteria-graph-bar-group,
		.asteria-graph-stacked .asteria-graph-area .asteria-graph-line-group {
			height: 189px;
			height: calc(100% - 19px);
		}
		.asteria-graph-area-stacked.asteria-graph-area-lines .sc-cmTdod {
			margin-top: 2px;
		}
		.asteria-cashflow-graph-layout-tabs
			.asteria-graph-area-grouped.asteria-graph-area-lines
			.sc-jbKcbu {
			height: 200px;
		}
		&.asteria-feature-workareas.asteria-widget-positioning-fixed
			.asteria-cashflow-graph-layout-tabs
			.asteria-graph-area-grouped.asteria-graph-area-lines
			.sc-jbKcbu {
			height: calc(100% - 77px);
		}
		&.asteria-feature-workareas.asteria-widget-positioning-fixed
			.asteria-cashflow-graph-layout-tabs
			.asteria-graph-area-lines
			.sc-cmTdod {
			margin-top: -55px;
		}
		&.asteria-feature-workareas.asteria-widget-positioning-fixed
			.asteria-cashflow-graph-layout-tabs
			.asteria-tabs-navigation,
		.asteria-cashflow-graph-layout-tabs .asteria-tabs-navigation {
			flex-basis: initial !important;
		}
		&.asteria-feature-workareas.asteria-widget-positioning-fixed
			.asteria-cashflow-graph-layout-tabs
			button,
		.asteria-cashflow-graph-layout-tabs button {
			height: 32px !important;
			flex-basis: auto !important;
		}
	}
`;

/*
&#asteria-widget {
	${({ siteStyle = '' }) =>
		css`
			${siteStyle}
		`}
}
*/

Layout.Styler = {
	typePrefix: 'asteria-widget',
	base: [Getter('widget')],
	children: [
		{
			component: HomePage,
			base: [Getter('dashboard')],
		},
		{
			component: ComponentsPage,
			base: [Getter('components')],
		},
	],
};

Layout.displayName = 'Layout';
export default Layout;
export { Header, Sidebar };
