import React from 'react';
import Onboarding from '@asteria/component-onboarding';
import Steps from '@asteria/component-onboarding/static/steps';
import { useHistory, useRoute } from '@asteria/utils/hooks';
import { useFeature } from '@asteria/component-featureflag';

const AddErpPage = ({ callback = () => {}, onDone }) => {
	const history = useHistory();
	let { params: { type = 'add' } = {} } = useRoute();

	const hasShowFullOnboarding = useFeature({
		key: 'onboarding-full-flow',
	});

	const integrationsAddModal = useFeature({ key: 'integrations-add-modal' });
	if (type === 'status') {
		history.push('/');
		return null;
	}

	if (!type) {
		return null;
	}

	let startStep = type === 'status' ? Steps.Status : Steps.SelectProvider;

	if (hasShowFullOnboarding && type === 'add') {
		startStep = Steps.Splash;
	} else {
		type = 'erp';
	}

	return (
		<Onboarding
			startStep={startStep}
			beforeNavigate={(step, path) => {
				if (step === '' && hasShowFullOnboarding && type === 'add') {
					return Steps.Splash;
				}

				if (!step) {
					if (onDone) {
						onDone();
					} else if (type === 'status') {
						return Steps.Status;
					} else if (type === 'erp' || type === 'bank') {
						history.push(
							integrationsAddModal ? '/integrations' : '/',
						);
					} else if (type === 'add' && hasShowFullOnboarding) {
						history.push('/');
					} else {
						return Steps.SelectProvider;
					}

					return path[path.length - 1];
				}

				if (step === Steps.Splash && type !== 'add') {
					onDone();
					return Steps.SelectProvider;
				}

				return step;
			}}
			type={type === 'status' ? 'erp' : type}
			callback={callback}
		/>
	);
};

export default AddErpPage;
