import Store from '../../core/store';
import logger from '../../logger';

@logger('graph:store')
class GraphStore extends Store {
	name = 'graph';

	constructor(subject) {
		super(subject, {
			xAxis: [],
			bars: [],
			availableTags: [],
			availableParts: [
				{ id: 'system-taxes', name: '$taxes', tags: [] },
				{ id: 'system-salaries', name: '$salaries', tags: [] },
				{
					id: 'system-invoices',
					name: '$invoices',
					tags: [
						{
							id: 'system-customerInvoices',
							name: '$customerInvoices',
						},
						{
							id: 'system-supplierInvoices',
							name: '$supplierInvoices',
						},
					],
				},
			],
			'cashflow-bar-graph': {
				visibleTags: [],
				activeBars: [],
			},
		});
	}
}

export default GraphStore;
