import React from 'react';
import styled from 'styled-components';
import Modal from '@asteria/component-modal';
import { TranslationService } from '@asteria/services-language';
import { useLocation, useHistory } from '@asteria/utils/hooks';

import Settings from './settings';

const CurrencyModal = styled(({ className, basePath }) => {
	const { pathname } = useLocation();
	const history = useHistory();

	return (
		<Modal
			open={pathname === `${basePath}/currencies`}
			onClose={() => history.push(basePath)}
			className={className}
			title={TranslationService.get('currency.title')}
		>
			<Settings
				onClose={() => history.push(basePath)}
				open={pathname === `${basePath}/currencies`}
			/>
		</Modal>
	);
})``;

CurrencyModal.Styler = Modal.Styler;

export default CurrencyModal;
