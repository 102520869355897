import axios from 'axios';
import { Observable } from 'rxjs';
import { switchMap, take, skipWhile } from 'rxjs/operators';
import AxiosSubscriber from './axiosSubscriber';
import GraphQLSubscriber from './graphQLSubscriber';

class HttpService {
	baseURL = 'https://prod.api.asteria.ai/';

	token = false;

	constructor({
		baseURL,
		authStore = null,
		noBearer = false,
		demo = false,
	} = {}) {
		this.baseURL = baseURL || this.baseUrl;
		this.authStore = authStore;
		this.noBearer = noBearer;
		this.isDemoMode = demo;

		this.axios = axios.create({
			baseURL,
		});
	}

	query(
		query,
		variables = {},
		{ reqAuth = true, baseURL = false, admin = false } = {},
	) {
		const client = baseURL ? axios.create({ baseURL }) : this.axios;

		if (
			(query.loc ? query.loc.source.body : query).includes('mutation') &&
			this.isDemoMode
		) {
			return new Observable();
		}

		if (this.authStore && reqAuth) {
			const accessToken$ = this.authStore.query(state => {
				if (admin) {
					return state?.admin?.tokens;
				}

				return state.tokens;
			});
			// TODO: Implement refresh token
			const fetch$ = accessToken$.pipe(
				skipWhile(val => val === null),
				switchMap(
					token =>
						new Observable(
							observer =>
								new GraphQLSubscriber(
									observer,
									client,
									query,
									variables,
									{ noBearer: this.noBearer, ...token },
								),
						),
				),
				take(1),
			);

			return fetch$;
		}

		return new Observable(
			observer =>
				new GraphQLSubscriber(observer, client, query, variables),
		);
	}

	get(path, query) {
		return new Observable(
			observer => new AxiosSubscriber(observer, this.axios, path, query),
		);
	}
}

export default HttpService;
