import React from 'react';
import styled from 'styled-components';

import Text from '@asteria/component-typography/text';
import Title from '@asteria/component-typography/title';
import Button from '@asteria/component-button';
import { TranslationService } from '@asteria/services-language';
import { Getter, compileStyles, useStore } from '@asteria/utils';
import Alert from '@asteria/component-alert';
import { useHistory } from '@asteria/utils/hooks';
import { FeatureFlag } from '@asteria/component-featureflag';
import ErpListItem from './item';

const ErpListHeader = styled(Title)``;
ErpListHeader.Styler = Title.Styler;

const ErpList = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`;
ErpList.Styler = {
	children: [
		{
			component: ErpListItem,
			base: [Getter('item')],
		},
	],
};

/**
 * ErpList component
 */
const Erps = styled(({ className }) => {
	const history = useHistory();
	const [integrations = []] = useStore(
		'store-integrations',
		({ integrations: list = [] }) => list,
	);

	const erps = integrations.filter(({ type }) => type === 'erp');
	const banks = integrations.filter(({ type }) => type === 'bank');

	return (
		<div className={className}>
			<FeatureFlag feature="no-erps" invert>
				<div className="asteria-integrations-erps">
					<ErpListHeader
						size="small"
						className="asteria-title-section"
					>
						{`${TranslationService.get(
							'erpList.title',
							'Hantera bokföringssystem',
						)}`}
					</ErpListHeader>
					<ErpList className="asteria-list">
						{erps.map(item => (
							<ErpListItem
								data={item}
								key={item.id}
								prefix="erp"
							/>
						))}
						{erps.length === 0 && (
							<Alert
								type="warning"
								icon
								headingContent={TranslationService.get(
									'erpList.nothing_connected',
									'Inga anslutna bokföringssystem...',
								)}
							/>
						)}
					</ErpList>
					<Button
						type="link"
						size="small"
						className="asteria-button-add-erp"
						icon="triangleRight"
						iconPosition="after"
						onClick={() => history.push('/integrations/erp/add')}
					>
						{TranslationService.get('erpList.addnew')}
					</Button>
				</div>
			</FeatureFlag>
			<FeatureFlag feature="no-banks" invert>
				<div className="asteria-integrations-banks">
					<ErpListHeader
						size="small"
						className="asteria-title-section"
					>
						{`${TranslationService.get(
							'banksList.title',
							'Hantera banker',
						)}`}
					</ErpListHeader>
					<ErpList className="asteria-list">
						{banks.map(item => (
							<ErpListItem
								data={item}
								key={item.id}
								prefix="bank"
							/>
						))}
						{banks.length === 0 && (
							<Alert
								type="warning"
								icon
								headingContent={TranslationService.get(
									'bankList.nothing_connected',
									'Ingen ansluten bank...',
								)}
							/>
						)}
					</ErpList>
					<Button
						type="link"
						size="small"
						className="asteria-button-add-erp"
						icon="triangleRight"
						iconPosition="after"
						onClick={() => history.push('/integrations/bank/add')}
					>
						{TranslationService.get(
							'bankList.addnew',
							'Lägg till ny bank',
						)}
					</Button>
				</div>
			</FeatureFlag>
		</div>
	);
})`
	${({ theme }) => compileStyles(Erps, theme)}
`;

Erps.Styler = {
	base: [Getter('erps')],
	children: [
		{
			component: ErpListHeader,
			base: [Getter('header')],
		},
		{
			component: ErpList,
			base: [Getter('list')],
		},
		{
			component: Text,
			base: [Getter('text')],
		},
		{
			component: Button,
			base: [Getter('button')],
		},
	],
};

export default Erps;
