import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { TextV2, TitleV2 } from '@asteria/component-typography';
import Button, { ButtonV2 } from '@asteria/component-button';
import Alert from '@asteria/component-alert';
import Modal, { ModalBody, ModalFooter } from '@asteria/component-modal';
import { TranslationService } from '@asteria/services-language';
import DatalayerContext from '@asteria/services-datalayer/react/context';

import { Input, Dropdown, FormGroupV2 } from '@asteria/component-form';
import { sendMissingErp } from '@asteria/services-datalayer/services/integrations/actions';

const ERPS = [
	{
		value: 'bokio',
		label: 'Bokio',
	},
	{
		value: 'dooer',
		label: 'Dooer',
	},
	{
		value: 'speedledger',
		label: 'SpeedLedger',
	},
	{
		value: 'VismaAdministration',
		label: 'Visma Administration',
	},
	{
		value: 'showERP',
		label: 'integrations.erp.missing',
	},
];

const BANKS = [
	{
		value: 'danskebank',
		label: 'Danske bank',
	},
	{
		value: 'ica',
		label: 'ICA Banken',
	},
	{
		value: 'ikano',
		label: 'Ikano',
	},
	{
		value: 'lansforsakringar',
		label: 'Länsförsäkringar',
	},
	{
		value: 'marginalen',
		label: 'Marginalen',
	},
	{
		value: 'nordea',
		label: 'Nordea',
	},

	{
		value: 'svea',
		label: 'Svea',
	},

	{
		value: 'showERP',
		label: 'integrations.bank.missing',
	},
];

const ErpForm = styled(({ className, type, setName }) => {
	const [selected, setSelected] = useState([]);
	const [erpMissing, setErpMissing] = useState(false);
	const List = type === 'erp' ? ERPS : BANKS;

	return (
		<div className={className}>
			<TextV2>
				{TranslationService.get(
					`onboarding.${type}.missing.description`,
				)}
			</TextV2>
			<FormGroupV2>
				<Dropdown
					iconClosed="triangleDown"
					iconOpen="triangleUp"
					options={List}
					placeholder={TranslationService.get(
						`onboarding.${type}.missing.select`,
					)}
					itemIcon="check"
					showSelected
					search={false}
					type="default"
					selected={selected}
					onChange={option => {
						setSelected([option]);
						if (option.value === 'showERP') {
							setName('');
							setErpMissing(true);
						} else {
							setErpMissing(false);
							setName(option.value);
						}
					}}
				/>
			</FormGroupV2>
			{erpMissing ? (
				<div className="asteria-missing-system-custom-input-group">
					<Input
						placeholder={TranslationService.get(
							`onboarding.${type}.missing.select.placeholder`,
						)}
						onChange={e => setName(e.target.value)}
					/>
				</div>
			) : null}
		</div>
	);
})`
	width: 100%
	align-items: flex-start;
	justify-content: flex-start;
	${Dropdown} {
		margin-bottom: 16px;
		margin-top: 16px;
		.asteria-icon-wrapper {
			svg {
				fill: #257886;
			}
		}
		${Button} {
			border-bottom: 2px solid #257886;
			background: rgba(245,243,240,1);
		}
	}
`;

const SentView = styled(({ className, type, name }) => (
	<Alert
		className={className}
		icon
		type="success"
		headingContent={TranslationService.get(
			`onboarding.${type}.missing.sent.thanks`,
		)}
		showClose={false}
		description={
			<>
				<TitleV2 size="title3">
					{TranslationService.get(
						`onboarding.${type}.missing.sent.title`,
					)}
				</TitleV2>
				<TitleV2 size="title2">{name}</TitleV2>
				<TextV2 size="small" style={{ marginTop: '8px' }}>
					{TranslationService.get(`onboarding.${type}.missing.sent`)}
				</TextV2>
			</>
		}
	/>
))`
	&.asteria-alert-success {
		border-width: 0px 0px 0px;
	}
	${TitleV2} {
		text-align: center;
		font-family: Swedbank Sans;
		font-weight: 700;

		&.asteria-title-3 {
			color: var(--text-color);
			font-size: 22px;
			margin-top: 16px;
		}

		&.asteria-title-2 {
			color: rgb(72, 140, 26);
			font-size: 24px;
			line-height: 44px;
			text-transform: capitalize;
			font-weight: 500;
		}

		&.asteria-title-title-3 {
			color: var(--text-color);
			font-size: 22px;
			margin-top: 16px;
		}

		&.asteria-title-title-2 {
			color: rgb(72, 140, 26);
			font-size: 24px;
			line-height: 44px;
			text-transform: capitalize;
			font-weight: 500;
		}
	}

	${TextV2} {
		font-family: Swedbank Sans;
		font-weight: 700;
		&.asteria-lead {
			font-family: Swedbank Headline;
			font-size: 20px;
			font-weight: 700;
			color: var(--text-color);
		}

		&.asteria-body {
			border-top: 1px solid rgba(124, 124, 124, 0.3);
			margin-top: 8px;
		}

		&.asteria-small {
			display: block;
			text-align: center;
			padding: 0px 40px;
			font-family: Arial, Helvetica, sans-serif;
			font-weight: normal;
		}
	}

	margin-bottom: 35px;
	padding-bottom: 40px;
`;

const MissingErp = styled(
	({ type = 'erp', onClose = () => {}, onAbort = onClose }) => {
		const [name, setName] = useState('');
		const [sent, setSent] = useState(false);
		const { dispatch } = useContext(DatalayerContext);

		return (
			<Modal
				backdrop
				onClose={onAbort}
				open
				size="small"
				detach
				title={TranslationService.get(
					`onboarding.${type}.missing.header`,
					'Mitt bokföringssystem saknas',
				)}
				className="asteria-modal-erp-missing"
			>
				<ModalBody className="modal-body">
					{sent === false ? (
						<ErpForm type={type} setName={setName} />
					) : null}
					{sent === true ? (
						<SentView type={type} name={name} />
					) : null}
				</ModalBody>
				<ModalFooter className="modal-footer">
					{sent ? (
						<div
							className="asteria-onboarding-help-footer"
							style={{
								display: 'flex',
								width: '100%',
								justifyContent: 'space-between',
							}}
						>
							<ButtonV2
								size="medium"
								type="secondary"
								text={TranslationService.get(
									'onboarding.done',
									'Klar',
								)}
								onClick={() => {
									onClose();
								}}
							/>
						</div>
					) : null}
					{!sent ? (
						<div
							className="asteria-onboarding-help-footer"
							style={{
								display: 'flex',
								width: '100%',
								justifyContent: 'space-between',
							}}
						>
							<ButtonV2
								size="medium"
								type="default"
								text={TranslationService.get(
									'onboarding.abort',
									'Avbryt',
								)}
								onClick={onAbort}
							/>
							<ButtonV2
								size="medium"
								type="primary"
								text={TranslationService.get(
									'onboarding.send',
									'Skicka',
								)}
								onClick={() => {
									if (name === '') {
										return;
									}

									if (sent) {
										onClose();
									} else {
										dispatch(sendMissingErp({ name }));
										setSent(true);
									}
								}}
							/>
						</div>
					) : null}
				</ModalFooter>
			</Modal>
		);
	},
)``;

export default MissingErp;
