import React, {
	useState,
	useMemo,
	useEffect,
	useContext,
	useCallback,
} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import gql from 'graphql-tag';
import {
	addDays,
	addWeeks,
	addMonths,
	addYears,
	startOfYear,
	endOfYear,
} from 'date-fns';
import { useStore, formatNumber } from '@asteria/utils';
import { TranslationService } from '@asteria/services-language';
import { TabsNavigation, TabsContent } from '@asteria/component-form/tabs';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { Tabs } from '@asteria/component-form';
import Text from '@asteria/component-typography/text';
import Title from '@asteria/component-typography/title';
import Button from '@asteria/component-button';
import { useFeature, FeatureFlag } from '@asteria/component-featureflag';
import { clearGraphData } from '@asteria/services-datalayer/services/graph/actions';
import { useHistory } from '@asteria/utils/hooks';
/* import Icon from '@asteria/component-icon'; */
import {
	toggleFilter,
	addFilter,
	setCurrentDate,
	setListOpen,
} from '@asteria/services-datalayer/services/appstate/actions';
// import Pie from '../pie';
import { Tag, StatsTags } from '@asteria/component-tags';
import ClientsView from './clients';
import CurrenciesView from './currency';

export const GET_ACCOUNT_BALANCE = gql`
	query AccountBalance($date: Date!) {
		bankAccountsBalance(date: $date)
	}
`;

const addTimeslice = (date, size, count = 1) => {
	if (size === 'day') {
		return addDays(date, count);
	}
	if (size === 'week') {
		return addWeeks(date, count);
	}
	if (size === 'month') {
		return addMonths(date, count);
	}
	if (size === 'year') {
		return addYears(date, count);
	}

	return date;
};

const OverviewView = styled(({ className, date, setShow }) => {
	const { lookup, dispatch } = useContext(DatalayerContext);
	const [accountTotal, setAccountTotal] = useState(0);
	const isDemoMode = useFeature({ key: 'demo-mode' });
	const [listStatistics = []] = useStore(
		'store-list',
		({ searchStatistics }) => searchStatistics,
	);

	const onClick = useCallback(
		(tag, status) => {
			dispatch(clearGraphData('cashflow-bar-graph', true));
			if (tag) {
				dispatch(
					toggleFilter({
						id: `type-${tag}`,
						type: 'type',
						config: {
							name: `$${tag}`,
							type: tag.toUpperCase(),
						},
					}),
				);
			}

			if (status) {
				dispatch(
					addFilter({
						id: `status-${status}`,
						type: 'status',
						config: {
							name: `$${status.toLowerCase()}`,
							status,
						},
					}),
				);
			}

			dispatch(clearGraphData('cashflow-bar-graph'));
			setShow(false);
		},
		[dispatch, setShow],
	);

	useEffect(() => {
		const apiService = lookup('service-api');
		if (!apiService.isDemoMode) {
			apiService
				.query(GET_ACCOUNT_BALANCE, { date }, { reqAuth: true })
				.subscribe(({ data: { bankAccountsBalance = 0 } = {} }) => {
					setAccountTotal(bankAccountsBalance);
				});
		} else {
			setAccountTotal(12725);
		}
	}, [date, isDemoMode, lookup]);

	const deposit = useMemo(() => {
		const paid =
			listStatistics?.deposit?.parts
				?.filter(({ status }) => status === 'PAID')
				?.reduce((acc, { total }) => acc + total, 0) || 0;
		const unpaid =
			listStatistics?.deposit?.parts
				?.filter(({ status }) => status === 'UNPAID')
				?.reduce((acc, { total }) => acc + total, 0) || 0;
		const forecast =
			listStatistics?.deposit?.parts
				?.filter(({ status }) => status === 'FORECAST')
				?.reduce((acc, { total }) => acc + total, 0) || 0;

		const overdue =
			listStatistics?.deposit?.parts
				?.filter(({ status }) => status === 'OVERDUE')
				?.reduce((acc, { total }) => acc + total, 0) || 0;

		const paidCount =
			listStatistics?.deposit?.parts
				?.filter(({ status }) => status === 'PAID')
				?.reduce((acc, { count }) => acc + count, 0) || 0;
		const unpaidCount =
			listStatistics?.deposit?.parts
				?.filter(({ status }) => status === 'UNPAID')
				?.reduce((acc, { count }) => acc + count, 0) || 0;
		const forecastCount =
			listStatistics?.deposit?.parts
				?.filter(({ status }) => status === 'FORECAST')
				?.reduce((acc, { count }) => acc + count, 0) || 0;

		const overdueCount =
			listStatistics?.deposit?.parts
				?.filter(({ status }) => status === 'OVERDUE')
				?.reduce((acc, { count }) => acc + count, 0) || 0;

		return {
			paid: { total: paid, count: paidCount },
			unpaid: { total: unpaid, count: unpaidCount },
			forecast: { total: forecast, count: forecastCount },
			overdue: { total: overdue, count: overdueCount },
		};
	}, [listStatistics]);

	const withdraw = useMemo(() => {
		const paid =
			listStatistics?.withdraw?.parts
				?.filter(({ status }) => status === 'PAID')
				?.reduce((acc, { total }) => acc + total, 0) || 0;
		const paidCount =
			listStatistics?.withdraw?.parts
				?.filter(({ status }) => status === 'PAID')
				?.reduce((acc, { count }) => acc + count, 0) || 0;
		const unpaid =
			listStatistics?.withdraw?.parts
				?.filter(({ status }) => status === 'UNPAID')
				?.reduce((acc, { total }) => acc + total, 0) || 0;
		const unpaidCount =
			listStatistics?.withdraw?.parts
				?.filter(({ status }) => status === 'UNPAID')
				?.reduce((acc, { count }) => acc + count, 0) || 0;
		const forecast =
			listStatistics?.withdraw?.parts
				?.filter(({ status }) => status === 'FORECAST')
				?.reduce((acc, { total }) => acc + total, 0) || 0;
		const forecastCount =
			listStatistics?.withdraw?.parts
				?.filter(({ status }) => status === 'FORECAST')
				?.reduce((acc, { count }) => acc + count, 0) || 0;
		const overdue =
			listStatistics?.withdraw?.parts
				?.filter(({ status }) => status === 'OVERDUE')
				?.reduce((acc, { total }) => acc + total, 0) || 0;
		const overdueCount =
			listStatistics?.withdraw?.parts
				?.filter(({ status }) => status === 'OVERDUE')
				?.reduce((acc, { count }) => acc + count, 0) || 0;

		return {
			paid: { total: paid, count: paidCount },
			unpaid: { total: unpaid, count: unpaidCount },
			forecast: { total: forecast, count: forecastCount },
			overdue: { total: overdue, count: overdueCount },
		};
	}, [listStatistics]);

	/*
	const pieParts = useMemo(() => {
		const parts = [];

		const depositTotal =
			Math.abs(listStatistics?.deposit?.total || 0) -
			Math.abs(deposit.overdue);
		const widthdrawTotal =
			Math.abs(listStatistics?.withdraw?.total || 0) -
			Math.abs(withdraw.overdue);
		const maxTotal = Math.max(
			Math.abs(depositTotal),
			Math.abs(widthdrawTotal),
		);

		if (withdraw.paid !== 0) {
			parts.push({
				id: 'withdraw_paid',
				value: Math.abs(withdraw.paid),
				max: maxTotal * 2,
				types: ['withdraw', 'paid'],
				color: `var(--system-withdraw-color)`,
				onClick: () => onClick('withdraw', 'PAID'),
			});
		}

		if (withdraw.unpaid !== 0) {
			parts.push({
				id: 'withdraw_unpaid',
				value: Math.abs(withdraw.unpaid),
				max: maxTotal * 2,
				types: ['withdraw', 'unpaid'],
				color: `var(--system-withdraw-color)`,
				onClick: () => onClick('withdraw', 'UNPAID'),
			});
		}

		if (withdraw.forecast !== 0) {
			parts.push({
				id: 'withdraw_forecast',
				value: Math.abs(withdraw.forecast),
				max: maxTotal * 2,
				types: ['withdraw', 'forecast'],
				color: `var(--system-withdraw-color)`,
				onClick: () => onClick('withdraw', 'FORECAST'),
			});
		}

		if (depositTotal > widthdrawTotal) {
			parts.push({
				id: 'withdraw_fill',
				value: depositTotal - widthdrawTotal,
				max: maxTotal * 2,
				types: ['withdraw', 'fill'],
				color: `#DADADA`,
			});
		}

		if (widthdrawTotal > depositTotal) {
			parts.push({
				id: 'deposit_fill',
				value: widthdrawTotal - depositTotal,
				max: maxTotal * 2,
				types: ['deposit', 'fill'],
				color: `#DADADA`,
			});
		}

		if (deposit.forecast) {
			parts.push({
				id: 'deposit_forecast',
				value: deposit.forecast,
				max: maxTotal * 2,
				types: ['deposit', 'forecast'],
				color: `var(--system-deposit-color)`,
				onClick: () => onClick('deposit', 'FORECAST'),
			});
		}

		if (deposit.unpaid) {
			parts.push({
				id: 'deposit_unpaid',
				value: deposit.unpaid,
				max: maxTotal * 2,
				types: ['deposit', 'unpaid'],
				color: `var(--system-deposit-color)`,
				onClick: () => onClick('deposit', 'UNPAID'),
			});
		}

		if (deposit.paid) {
			parts.push({
				id: 'deposit_paid',
				value: deposit.paid,
				max: maxTotal * 2,
				types: ['deposit', 'paid'],
				color: `var(--system-deposit-color)`,
				onClick: () => onClick('deposit', 'PAID'),
			});
		}

		return parts;
	}, [
		deposit.forecast,
		deposit.overdue,
		deposit.paid,
		deposit.unpaid,
		listStatistics,
		onClick,
		withdraw.forecast,
		withdraw.overdue,
		withdraw.paid,
		withdraw.unpaid,
	]);
	*/

	/*
	{pieParts.length === 0 ? (
		<div className="asteria-empty-donut">
			<Text>This pie chart is empty</Text>
		</div>
	) : (
		<Pie items={pieParts} />
	)}
	*/

	const [filters = []] = useStore(
		'store-appstate',
		({ filters: list }) => list,
	);

	return (
		<div className={classNames(className)}>
			<div className="asteria-overview-deposit">
				<Title className="asteria-category-title">
					{TranslationService.get(
						'drawer.overview.deposit.title',
						'Inbetalningar',
						{ stats: listStatistics },
					)}
					<span className="amount">
						{TranslationService.get(
							'drawer.overview.deposit.total',
							'{{stats.deposit.total | number : false }}',
							{ stats: listStatistics },
						)}
					</span>
				</Title>
				{deposit.paid.count !== 0 ? (
					<Tag
						tag="$deposit"
						status="PAID"
						onClick={() => onClick('deposit', 'PAID')}
						className={classNames({
							'asteria-state-active':
								filters.find(
									({ id }) => id === 'type-deposit',
								) &&
								filters.find(({ id }) => id === 'status-PAID'),
						})}
					>
						<Text className="asteria-text-tag-size">
							{TranslationService.get(
								'drawer.overview.deposit.tag.paid.total',
								'{{deposit.paid.total | number : false }}',
								{ deposit, withdraw },
							)}
						</Text>
					</Tag>
				) : null}
				{deposit.unpaid.count !== 0 ? (
					<Tag
						tag="$deposit"
						status="UNPAID"
						onClick={() => onClick('deposit', 'UNPAID')}
						className={classNames({
							'asteria-state-active':
								filters.find(
									({ id }) => id === 'type-deposit',
								) &&
								filters.find(
									({ id }) => id === 'status-UNPAID',
								),
						})}
						data={{ count: deposit.unpaid.count }}
					>
						<Text className="asteria-text-tag-size">
							{TranslationService.get(
								'drawer.overview.deposit.tag.unpaid.total',
								'{{deposit.unpaid.total | number : false }}',
								{ deposit, withdraw },
							)}
						</Text>
					</Tag>
				) : null}
				{deposit.forecast.count !== 0 ? (
					<Tag
						tag="$deposit"
						status="FORECAST"
						onClick={() => onClick('deposit', 'FORECAST')}
						className={classNames({
							'asteria-state-active':
								filters.find(
									({ id }) => id === 'type-deposit',
								) &&
								filters.find(
									({ id }) => id === 'status-FORECAST',
								),
						})}
					>
						<Text className="asteria-text-tag-size">
							{TranslationService.get(
								'drawer.overview.deposit.tag.forecast.total',
								'{{deposit.forecast.total | number : false }}',
								{ deposit, withdraw },
							)}
						</Text>
					</Tag>
				) : null}
				{deposit.overdue.count !== 0 ? (
					<Tag
						tag="$deposit"
						status="OVERDUE"
						onClick={() => onClick('deposit', 'OVERDUE')}
						className={classNames({
							'asteria-state-active':
								filters.find(
									({ id }) => id === 'type-deposit',
								) &&
								filters.find(
									({ id }) => id === 'status-OVERDUE',
								),
						})}
						data={{ count: deposit.overdue.count }}
					>
						<Text className="asteria-text-tag-size">
							{TranslationService.get(
								'drawer.overview.deposit.tag.overdue.total',
								'{{deposit.overdue.total | number : false }}',
								{ deposit, withdraw },
							)}
						</Text>
					</Tag>
				) : null}
			</div>
			<div className="asteria-overview-account-balance">
				<Title className="asteria-category-title">
					{TranslationService.get(
						'drawer.overview.account.title',
						'Aktuell kontobalans',
						{ stats: listStatistics, accountTotal },
					)}
					<span className="amount">
						{TranslationService.get(
							'drawer.overview.account.total',
							'{{accountTotal | number : false }}',
							{ stats: listStatistics, accountTotal },
						)}
					</span>
				</Title>
			</div>
			<div className="asteria-overview-withdraw">
				<Title className="asteria-category-title">
					{TranslationService.get(
						'drawer.overview.withdraw.title',
						'Utbetalningar',
						{ stats: listStatistics },
					)}
					<span className="amount">
						{TranslationService.get(
							'drawer.overview.withdraw.total',
							'{{stats.withdraw.total | number : false }}',
							{ stats: listStatistics },
						)}
					</span>
				</Title>
				{withdraw.paid.count !== 0 ? (
					<Tag
						tag="$withdraw"
						status="PAID"
						onClick={() => onClick('withdraw', 'PAID')}
						className={classNames({
							'asteria-state-active':
								filters.find(
									({ id }) => id === 'type-withdraw',
								) &&
								filters.find(({ id }) => id === 'status-PAID'),
						})}
					>
						<Text className="asteria-text-tag-size">
							{TranslationService.get(
								'drawer.overview.withdraw.tag.overdue.total',
								'{{withdraw.paid.total | number : false }}',
								{ deposit, withdraw },
							)}
						</Text>
					</Tag>
				) : null}
				{withdraw.unpaid.count !== 0 ? (
					<Tag
						tag="$withdraw"
						status="UNPAID"
						onClick={() => onClick('withdraw', 'UNPAID')}
						className={classNames({
							'asteria-state-active':
								filters.find(
									({ id }) => id === 'type-withdraw',
								) &&
								filters.find(
									({ id }) => id === 'status-UNPAID',
								),
						})}
						data={{ count: withdraw.unpaid.count }}
					>
						<Text className="asteria-text-tag-size">
							{TranslationService.get(
								'drawer.overview.withdraw.tag.overdue.total',
								'{{withdraw.unpaid.total | number : false }}',
								{ deposit, withdraw },
							)}
						</Text>
					</Tag>
				) : null}
				{withdraw.forecast.count !== 0 ? (
					<Tag
						tag="$withdraw"
						status="FORECAST"
						onClick={() => onClick('withdraw', 'FORECAST')}
						className={classNames({
							'asteria-state-active':
								filters.find(
									({ id }) => id === 'type-withdraw',
								) &&
								filters.find(
									({ id }) => id === 'status-FORECAST',
								),
						})}
					>
						<Text className="asteria-text-tag-size">
							{TranslationService.get(
								'drawer.overview.withdraw.tag.overdue.total',
								'{{withdraw.forecast.total | number : false }}',
								{ deposit, withdraw },
							)}
						</Text>
					</Tag>
				) : null}
				{withdraw.overdue.count !== 0 ? (
					<Tag
						tag="$withdraw"
						status="OVERDUE"
						onClick={() => onClick('withdraw', 'OVERDUE')}
						className={classNames({
							'asteria-state-active':
								filters.find(
									({ id }) => id === 'type-withdraw',
								) &&
								filters.find(
									({ id }) => id === 'status-OVERDUE',
								),
						})}
						data={{ count: withdraw.overdue.count }}
					>
						<Text className="asteria-text-tag-size">
							{TranslationService.get(
								'drawer.overview.withdraw.tag.overdue.total',
								'{{withdraw.overdue.total | number : false }}',
								{ deposit, withdraw },
							)}
						</Text>
					</Tag>
				) : null}
			</div>
		</div>
	);
})`
	display: flex;
	justify-content: space-between;
	padding: 0 0 24px;

	flex-wrap: wrap;

	> div {
		/*width: 33%;*/
		padding: 8px 16px;
		width: 40%;

		@media (max-width: 1024px) {
			width: 100%;
			padding: 8px 0 8px;
		}

		&.asteria-overview-deposit {
			.asteria-category-title {
				flex-direction: column;
				text-align: center;
				font-size: 20px !important;
				@media (max-width: 1024px) {
					flex-direction: row;
					margin-top: 16px !important;
					margin-bottom: 8px !important;
					color: #f35c1b !important;
					font-family: Swedbank Headline !important;
					font-weight: 200;
					font-size: 24px !important;
					padding-left: 8px !important;
				}
				.amount {
					margin: 0 auto;
					font-size: 16px !important;
					justify-content: center;
					align-items: center;
					display: flex;
					@media (max-width: 1024px) {
						color: #000000 !important;
						font-family: Swedbank Headline !important;
						font-weight: 200;
						font-size: 20px !important;
						line-height: normal;
						margin-left: auto;
						margin-right: 0;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 120px;
					}
				}
			}
			.asteria-category-overview {
				min-width: 170px;
				margin: 0 16px 0 0;
				@media (max-width: 1024px) {
					width: 160px;
				}
				@media (max-width: 1280px) {
				}
			}
		}

		&.asteria-overview-account-balance {
			@media (max-width: 1024px) {
			}
			order: -1;
			flex-basis: 100%;
			margin-bottom: -77px !important;
			.asteria-category-title {
				flex-direction: column;
				text-align: center;
				@media (max-width: 1024px) {
					flex-direction: row;
					margin-top: 0px !important;
					margin-bottom: 8px !important;
					color: #f35c1b !important;
					font-family: Swedbank Headline !important;
					font-weight: 200;
					font-size: 24px !important;
					padding-left: 8px !important;
				}
				.amount {
					margin: 0 auto;
					@media (max-width: 1024px) {
						color: #000000 !important;
						font-family: Swedbank Headline !important;
						font-weight: 200;
						font-size: 20px !important;
						line-height: normal;
						margin-left: auto;
						margin-right: 0;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 120px;
					}
				}
			}
		}

		&.asteria-overview-withdraw {
			.asteria-category-title {
				flex-direction: column;
				text-align: center;
				font-size: 20px !important;
				@media (max-width: 1024px) {
					flex-direction: row;
					margin-top: 0px !important;
					margin-bottom: 8px !important;
					color: #f35c1b !important;
					font-family: Swedbank Headline !important;
					font-weight: 200;
					font-size: 24px !important;
					padding-left: 8px !important;
				}
				.amount {
					margin: 0 auto;
					font-size: 16px !important;
					@media (max-width: 1024px) {
						color: #000000 !important;
						font-family: Swedbank Headline !important;
						font-weight: 200;
						font-size: 20px !important;
						line-height: normal;
						margin-left: auto;
						margin-right: 0;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 120px;
					}
				}
			}
		}

		.asteria-category-title {
			margin-top: 0px !important;
			margin-bottom: 12px !important;
			color: #f35c1b !important;
			font-family: Swedbank Headline !important;
			font-weight: 200;
			font-size: 24px !important;
			line-height: normal;
			text-align: left;
			padding-left: 0px !important;
			margin-left: 0px !important;
			display: flex;
			.amount {
				color: #000000 !important;
				font-family: Swedbank Headline !important;
				font-weight: 200;
				font-size: 20px !important;
				line-height: normal;
				margin-left: auto;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 120px;
			}
		}

		.asteria-chip {
			width: auto;
			border-radius: 50px;
			display: inline-flex;
			padding: 7px 12px 7px 12px;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			margin: 4px 0;
			.asteria-text {
				font-family: Swedbank Sans;
				font-weight: 700;
				font-size: 14px;
				line-height: normal;
			}
			&:hover {
				border: 1px solid #eae7e4;
				border-radius: 50px;
			}
		}

		.asteria-empty-donut {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			&:before {
				content: '';
				display: block;
				height: 110px;
				width: 110px;
				margin: 0 auto;
				border-radius: 100px;
				border: solid 20px #dadada;
			}
			.asteria-text {
				font-family: Swedbank Sans;
				font-weight: 700;
				font-size: 14px;
				line-height: normal;
				margin-top: 16px;
			}
		}
	}
`;

OverviewView.displayName = 'OverviewView';

OverviewView.Styler = {
	children: [],
};

const OverviewView2 = styled(({ className, date, setShow }) => {
	const { lookup } = useContext(DatalayerContext);
	const [accountTotal, setAccountTotal] = useState(0);
	const isDemoMode = useFeature({ key: 'demo-mode' });
	const isNotifications = useFeature({
		key: 'notifications-drawer-notifications',
	});

	const [listStatistics = []] = useStore(
		'store-list',
		({ searchStatistics }) => searchStatistics,
	);

	const [notifications] = useStore(
		'store-notifications',
		({ notifications: list }) => list,
	);

	useEffect(() => {
		const apiService = lookup('service-api');
		if (!apiService.isDemoMode) {
			apiService
				.query(GET_ACCOUNT_BALANCE, { date }, { reqAuth: true })
				.subscribe(({ data: { bankAccountsBalance = 0 } = {} }) => {
					setAccountTotal(bankAccountsBalance);
				});
		} else {
			setAccountTotal(12725);
		}
	}, [date, isDemoMode, lookup]);

	return (
		<div className={classNames(className)}>
			<div className="asteria-overview-deposit">
				<Title className="asteria-category-title">
					{TranslationService.get(
						'drawer.overview.deposit.title',
						'Inbetalningar',
						{ stats: listStatistics },
					)}
					<span className="amount">
						{TranslationService.get(
							'drawer.overview.deposit.total',
							'{{stats.deposit.total | number : false }}',
							{ stats: listStatistics },
						)}
					</span>
				</Title>
				<StatsTags
					type="deposit"
					statistics={listStatistics}
					notifications={isNotifications ? notifications : []}
					onClick={() => setShow(false)}
				/>
			</div>
			<div className="asteria-overview-account-balance">
				<Title className="asteria-category-title">
					{TranslationService.get(
						'drawer.overview.account.title',
						'Aktuell kontobalans',
						{ stats: listStatistics, accountTotal },
					)}
					<span className="amount">
						{TranslationService.get(
							'drawer.overview.account.total',
							'{{accountTotal | number : false }}',
							{ stats: listStatistics, accountTotal },
						)}
					</span>
				</Title>
			</div>
			<div className="asteria-overview-withdraw">
				<Title className="asteria-category-title">
					{TranslationService.get(
						'drawer.overview.withdraw.title',
						'Utbetalningar',
						{ stats: listStatistics },
					)}
					<span className="amount">
						{TranslationService.get(
							'drawer.overview.withdraw.total',
							'{{stats.withdraw.total | number : false }}',
							{ stats: listStatistics },
						)}
					</span>
				</Title>
				<StatsTags
					type="withdraw"
					statistics={listStatistics}
					notifications={isNotifications ? notifications : []}
					onClick={() => setShow(false)}
				/>
			</div>
		</div>
	);
})`
	display: flex;
	justify-content: space-between;
	padding: 0 0 24px;

	flex-wrap: wrap;

	> div {
		width: 50%;
		padding: 8px 12px 40px;
		flex: 1 1 0%;

		overflow: hidden;

		@media (max-width: 1024px) {
			width: 100%;
			padding: 8px 0 8px;
		}

		@media (max-width: 1279px) {
			width: 100%;
			padding: 8px 0 8px;
		}

		&.asteria-overview-deposit {
			.asteria-category-title {
				flex-direction: column;
				text-align: center;
				font-size: 20px !important;
				@media (max-width: 1024px) {
					flex-direction: row;
					margin-top: 16px !important;
					margin-bottom: 8px !important;
					color: #f35c1b !important;
					font-family: Swedbank Headline !important;
					font-weight: 200;
					font-size: 24px !important;
					padding-left: 8px !important;
				}
				@media (max-width: 1279px) {
					flex-direction: row;
					margin-top: 16px !important;
					margin-bottom: 8px !important;
					color: #f35c1b !important;
					font-family: Swedbank Headline !important;
					font-weight: 200;
					font-size: 24px !important;
					padding-left: 8px !important;
				}
				.amount {
					margin: 0 auto;
					font-size: 16px !important;
					justify-content: center;
					align-items: center;
					display: flex;
					@media (max-width: 1024px) {
						color: #000000 !important;
						font-family: Swedbank Headline !important;
						font-weight: 200;
						font-size: 20px !important;
						line-height: normal;
						margin-left: auto;
						margin-right: 0;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 120px;
					}
					@media (max-width: 1279px) {
						margin: 0 0 0 auto;
					}
				}
			}
			.asteria-category-overview {
				min-width: 170px;
				margin: 0 16px 0 0;
				@media (max-width: 1024px) {
					width: 160px;
				}
				@media (max-width: 1280px) {
				}
			}
		}

		&.asteria-overview-account-balance {
			order: -1;
			pointer-events: none;
			flex-basis: 100%;
			margin-bottom: -77px !important;
			padding: 8px 16px;
			@media (max-width: 1279px) {
				order: -1;
				margin-bottom: 0 !important;
				padding: 8px 0;
			}
			.asteria-title {
				flex-direction: column;
				text-align: center;
				@media (max-width: 1024px) {
					flex-direction: row;
					margin-top: 16px !important;
					margin-bottom: 8px !important;
					color: #f35c1b !important;
					font-family: Swedbank Headline !important;
					font-weight: 200;
					font-size: 24px !important;
					padding-left: 8px !important;
				}
				@media (max-width: 1279px) {
					flex-direction: row;
					margin-top: 16px !important;
					margin-bottom: 8px !important;
					color: #f35c1b !important;
					font-family: Swedbank Headline !important;
					font-weight: 200;
					font-size: 24px !important;
					padding-left: 8px !important;
				}
				.amount {
					margin: 0 auto;
					font-size: 20px;
					justify-content: center;
					align-items: center;
					display: flex;
					@media (max-width: 1024px) {
						color: #000000 !important;
						font-family: Swedbank Headline !important;
						font-weight: 200;
						font-size: 16px !important;
						line-height: normal;
						margin-left: auto;
						margin-right: 0;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 120px;
					}
					@media (max-width: 1279px) {
						margin: 0 0 0 auto;
						color: #000000 !important;
						font-family: Swedbank Headline !important;
						font-weight: 200;
						font-size: 16px !important;
						line-height: normal;
						margin-left: auto;
						margin-right: 0;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 120px;
					}
				}
			}
			@media (min-width: 1280px) {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		&.asteria-overview-withdraw {
			.asteria-category-title {
				flex-direction: column;
				text-align: center;
				font-size: 20px !important;
				@media (max-width: 1024px) {
					flex-direction: row;
					margin-top: 0px !important;
					margin-bottom: 8px !important;
					color: #f35c1b !important;
					font-family: Swedbank Headline !important;
					font-weight: 200;
					font-size: 24px !important;
					padding-left: 8px !important;
				}
				@media (max-width: 1279px) {
					flex-direction: row;
					margin-top: 16px !important;
					margin-bottom: 8px !important;
					color: #f35c1b !important;
					font-family: Swedbank Headline !important;
					font-weight: 200;
					font-size: 24px !important;
					padding-left: 8px !important;
				}
				.amount {
					margin: 0 auto;
					font-size: 16px !important;
					justify-content: center;
					align-items: center;
					display: flex;
					@media (max-width: 1024px) {
						color: #000000 !important;
						font-family: Swedbank Headline !important;
						font-weight: 200;
						font-size: 20px !important;
						line-height: normal;
						margin-left: auto;
						margin-right: 0;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 120px;
					}
					@media (max-width: 1279px) {
						margin: 0 0 0 auto;
					}
				}
			}
			.asteria-category-overview {
				min-width: 170px;
				margin: 0 16px 0 0;
				@media (max-width: 1024px) {
					width: 160px;
				}
				@media (max-width: 1280px) {
				}
			}
		}

		.asteria-category-title {
			margin-top: 0px !important;
			margin-bottom: 12px !important;
			color: #f35c1b !important;
			font-family: Swedbank Headline !important;
			font-weight: 200;
			font-size: 24px !important;
			line-height: normal;
			text-align: left;
			padding-left: 0px !important;
			margin-left: 0px !important;
			display: flex;
			.amount {
				color: #000000 !important;
				font-family: Swedbank Headline !important;
				font-weight: 200;
				font-size: 20px !important;
				line-height: normal;
				margin-left: auto;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 120px;
			}
		}

		.asteria-chip {
			width: auto;
			border-radius: 50px;
			display: inline-flex;
			padding: 7px 12px 7px 12px;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			margin: 4px 0;
			.asteria-text {
				font-family: Swedbank Sans;
				font-weight: 700;
				font-size: 14px;
				line-height: normal;
			}
			&:hover {
				border: 1px solid #eae7e4;
				border-radius: 50px;
			}
		}

		.asteria-empty-donut {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			&:before {
				content: '';
				display: block;
				height: 110px;
				width: 110px;
				margin: 0 auto;
				border-radius: 100px;
				border: solid 20px #dadada;
			}
			.asteria-text {
				font-family: Swedbank Sans;
				font-weight: 700;
				font-size: 14px;
				line-height: normal;
				margin-top: 16px;
			}
		}
	}
`;

OverviewView2.displayName = 'OverviewView2';

OverviewView2.Styler = {
	children: [],
};

const CategoriesView = styled(({ className, setShow }) => {
	const [listStatistics = []] = useStore(
		'store-list',
		({ searchStatistics }) => searchStatistics,
	);
	return (
		<div className={classNames(className)}>
			<div>
				<Title className="asteria-category-title">
					{TranslationService.get(
						'drawer.categories.deposit.title',
						'Inbetalningar',
						{ stats: listStatistics },
					)}
					<span className="amount">
						{TranslationService.get(
							'drawer.categories.deposit.total',
							'{{stats.deposit.total | number : false }}',
							{ stats: listStatistics },
						)}
					</span>
				</Title>
				<StatsTags
					type="deposit"
					statistics={listStatistics}
					onClick={() => setShow(false)}
				/>
			</div>
			<div>
				<Title className="asteria-category-title">
					{TranslationService.get(
						'drawer.categories.withdraw.title',
						'Utbetalningar',
						{ stats: listStatistics },
					)}
					<span className="amount">
						{TranslationService.get(
							'drawer.categories.withdraw.total',
							'{{stats.withdraw.total | number : false }}',
							{ stats: listStatistics },
						)}
					</span>
				</Title>
				<StatsTags
					type="withdraw"
					statistics={listStatistics}
					onClick={() => setShow(false)}
				/>
			</div>
		</div>
	);
})`
	display: flex;

	justify-content: space-around;

	.asteria-category-title {
		margin-top: 0 !important;
		margin-bottom: 12px !important;
		color: #f35c1b !important;
		font-family: Swedbank Headline !important;
		font-weight: 200;
		font-size: 24px !important;
		line-height: normal;
		text-align: left;
		padding-left: 0px !important;
		margin-left: 0px !important;
		display: flex;
		@media (max-width: 1024px) {
			padding-left: 8px !important;
			margin-top: 0px !important;
		}
		.amount {
			color: #000000 !important;
			font-family: Swedbank Headline !important;
			font-weight: 200;
			font-size: 20px !important;
			line-height: normal;
			margin-left: auto;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 120px;
			justify-content: center;
			align-items: center;
			display: flex;
		}
	}

	> div {
		width: 50%;
		padding: 8px 12px 40px;
		flex: 1;
		@media (max-width: 1024px) {
			width: 100%;
			padding: 8px 0 16px;
		}
		div {
			.asteria-text-tag-name {
				padding-right: 10px;
				margin-left: 0px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				color: var(--text-color);
				font-family: Swedbank Sans;
				font-weight: 700;
				font-size: 14px;
				line-height: normal;
				@media (max-width: 1279px) {
				}
			}
		}
	}
`;

CategoriesView.displayName = 'CategoriesView';

CategoriesView.Styler = {
	children: [],
};

// const Box = styled(({ className }) => (
// 	<Box className={classNames(className, 'asteria-box')}>
// 		<Title size="3">Få betalt direkt med fakturaköp</Title>
// 		<Text>
// 			{`Fakturabelåning, eller factoring, innebär att du säljer eller
// 			belånar dina fakturor till oss.`}
// 		</Text>
// 		<Button
// 			icon="triangleRight"
// 			iconPosition="before"
// 			onClick=""
// 			size="regular"
// 			type="link"
// 			text={TranslationService.get(
// 				'drawer.finance.factoring.action.title',
// 				'Sälj fakturor',
// 			)}
// 		/>
// 	</Box>
// ))`
// 	border: 1px solid #F2F0ED;
// 	padding: 16px;
// 	display: flex;
// 	align-content: center;
// 	justify-content: center;
// 	flex-direction: column;
// 	${Title} {
// 		color: #262626;
// 		font-family: 'Swedbank Headline'
// 		font-size: 18px;
// 		margin: 0 auto 8px;
// 		text-align: center;
// 		font-weight: 600;
// 		line-height: 1.4;
// 	}
// 	${Text} {
// 		color: #696969;
// 		font-size: 14px;
// 		text-align: center;
// 	}
// 	${Button} {

// 	}
// `;

const FinancialView = styled(({ className, setShow }) => {
	const { dispatch } = useContext(DatalayerContext);
	const history = useHistory();
	const [sentToFactoring = []] = useStore(
		'store-appstate',
		({ sentToFactoring: list }) => list,
	);

	const [size = false] = useStore(
		'store-appstate',
		({ timesliceSize }) => timesliceSize,
		true,
	);

	const [availableCategories = []] = useStore(
		'store-graph',
		state => state.availableCategories,
	);

	const availableTags = useMemo(
		() =>
			availableCategories
				.reduce((acc, category) => acc.concat(category?.tags), [])
				.map(({ _id, name }) => ({
					id: _id,
					type: 'tag',
					config: { name },
				})),
		[availableCategories],
	);
	const activateFactoring = useCallback(() => {
		dispatch(setCurrentDate(addTimeslice(new Date(), size, -1)));
		dispatch(
			addFilter({
				id: `status-UNPAID`,
				type: 'status',
				config: {
					name: `$unpaid`,
					status: 'UNPAID',
				},
			}),
		);
		dispatch(
			addFilter({
				id: `status-FORECAST`,
				type: 'status',
				config: {
					name: `$forecast`,
					status: 'FORECAST',
				},
			}),
		);

		const factoring = availableTags.find(
			({ config: { name } }) => name === '$factoring',
		);

		if (factoring) {
			dispatch(addFilter(factoring));
		}

		const customers = availableTags.find(
			({ config: { name } }) => name === '$customer',
		);

		if (customers) {
			dispatch(addFilter(customers));
		}

		dispatch(setListOpen(true));

		history.push('/factoring');
		setShow(false);
	}, [availableTags, dispatch, history, setShow, size]);

	return (
		<div className={classNames(className)}>
			{!sentToFactoring || sentToFactoring.length === 0 ? (
				<div className="asteria-financial-options">
					<div className="asteria-box">
						<Title size="3">
							{TranslationService.get(
								'drawer.finance.factoring.title',
								`Få betalt direkt med fakturaköp`,
							)}
						</Title>
						<Text>
							{TranslationService.get(
								'drawer.finance.factoring.description',
								`Fakturabelåning, eller factoring, innebär att du säljer eller belånar dina fakturor till oss.`,
							)}
						</Text>
						<Button
							icon="triangleRight"
							iconPosition="before"
							size="regular"
							type="link"
							onClick={activateFactoring}
							text={TranslationService.get(
								'drawer.finance.factoring.action.title',
								'Sälj fakturor',
							)}
						/>
						<Button
							type="link"
							className="asteria-button-cover"
							onClick={activateFactoring}
						/>
					</div>
					{/* <div className="asteria-box">
					<Title size="3">Öka tryggheten med kontokredit</Title>
					<Text>
						{`En kontokredit kan vara en extra trygghet och säkerhet för företagets likviditet om inflödet är ojämnt.`}
					</Text>
					<Button
						icon="triangleRight"
						iconPosition="before"
						onClick=""
						size="regular"
						type="link"
						text={TranslationService.get(
							'drawer.finance.overdraft.action.title',
							'Skaffa kontokredit',
						)}
					/>
				</div>
				<div className="asteria-box">
					<Title size="3">FX</Title>
					<Text>
						{`En kontokredit kan vara en extra trygghet och säkerhet för företagets likviditet om inflödet är ojämnt.`}
					</Text>
					<Button
						icon="triangleRight"
						iconPosition="before"
						onClick=""
						size="regular"
						type="link"
						text={TranslationService.get(
							'drawer.finance.overdraft.action.title',
							'Förstärk valuta',
						)}
					/>
				</div> */}
				</div>
			) : (
				<div className="asteria-financial-overview">
					<div className="asteria-list-wrapper asteria-financial-factoring">
						<Title size="2" className="asteria-section-title">
							{TranslationService.get(
								'drawer.finance.factoring.list.title',
								'Fakturaköp',
							)}
						</Title>
						<ul className="asteria-list">
							<li className="asteria-list-item-divider">
								<div className="asteria-list-item-description">
									<Text>
										{TranslationService.get(
											'drawer.finance.factoring.list.processing',
											'Hanteras',
										)}
									</Text>
								</div>
								<div className="asteria-list-item-summary">
									<Text>
										{formatNumber(
											sentToFactoring
												.filter(
													({ status }) =>
														status === 'PROCESSING',
												)
												.reduce(
													(acc, { total }) =>
														acc + total,
													0,
												),
										)}
									</Text>
								</div>
							</li>
							{sentToFactoring
								.filter(({ status }) => status === 'PROCESSING')
								.map(({ total, count, date }) => (
									<li className="asteria-list-item">
										<div className="asteria-list-item-description">
											<Title size="4">
												{TranslationService.get(
													'drawer.finance.factoring.blabvla',
													'{{date | date: "YYYY-MM-DD"}}',
													{
														date,
													},
												)}
											</Title>
											<Text>{`${count} fakturor`}</Text>
										</div>
										<div className="asteria-list-item-summary">
											<Title size="4">
												{formatNumber(total)}
											</Title>
											<Button
												icon="triangleRight"
												iconPosition="before"
												onClick=""
												size="regular"
												type="link"
											/>
										</div>
									</li>
								))}
							<li className="asteria-list-item-divider">
								<div className="asteria-list-item-description">
									<Text>
										{TranslationService.get(
											'drawer.finance.factoring.list.processed',
											'Historiska fakturaköp',
										)}
									</Text>
								</div>
								<div className="asteria-list-item-summary">
									<Text>
										{formatNumber(
											sentToFactoring
												.filter(
													({ status }) =>
														status === 'ACCEPTED',
												)
												.reduce(
													(acc, { total }) =>
														acc + total,
													0,
												),
										)}
									</Text>
								</div>
							</li>
							{sentToFactoring
								.filter(({ status }) => status === 'ACCEPTED')
								.map(({ total, count, date }) => (
									<li className="asteria-list-item">
										<div className="asteria-list-item-description">
											<Title size="4">
												{TranslationService.get(
													'drawer.finance.factoring.blabvla',
													'{{date | date: "YYYY-MM-DD"}}',
													{
														date,
													},
												)}
											</Title>
											<Text>{`${count} fakturor`}</Text>
										</div>
										<div className="asteria-list-item-summary">
											<Title size="4">
												{formatNumber(total)}
											</Title>
											<Button
												icon="triangleRight"
												iconPosition="before"
												onClick=""
												size="regular"
												type="link"
											/>
										</div>
									</li>
								))}
						</ul>

						<Button
							icon="triangleRight"
							iconPosition="before"
							text="Sälj fakturor"
							type="link"
							size="regular"
							className="asteria-button-action"
							onClick={() => {
								history.push('/factoring');
								setShow(false);
							}}
						/>
					</div>
				</div>
			)}
		</div>
	);
})`
	display: flex;
	justify-content: space-around;
	padding: 0px 40px 32px;
	flex-direction: column;

	.asteria-financial-overview {
		display: grid;
		grid-column-gap: 32px;
		grid-template-columns: repeat(2, 1fr);
	}

	.asteria-list-wrapper {
		&.asteria-financial-factoring {
		}
		.asteria-section-title {
			font-family: Swedbank Headline;
			font-size: 24px;
			display: flex;
			align-items: center;
			line-height: 100%;
			color: #f35c1b;
			padding: 12px 16px;
			border-bottom: 2px solid #d4c4bc;
			margin: 0 !important;
		}
		width: 100%;
		margin: 8px 32px 0 0;
		${Button} {
			margin-left: 4px;
			padding-left: 0 !important;
			.asteria-icon-wrapper {
				width: 20px;
				height: 12px;
				margin-right: 4px;
				svg {
					fill: #257886 !important;
				}
			}
		}
		.asteria-list {
			margin: 0;
			padding: 0;
			display: flex;
			justify-content: center;
			flex-direction: column;
			.asteria-list-item-divider {
				background: rgb(251, 242, 234);
				border-bottom: 2px solid rgb(235, 231, 226);
				display: flex;
				flex-direction: row;
				padding: 10px 12px;
				margin: 0;
				${Text} {
					font-family: Arial, Helvetica, sans-serif;
					font-size: 14px;
					color: var(--text-color);
				}
				.asteria-list-item-summary {
					margin-left: auto;
				}
			}
			.asteria-list-item {
				align-items: center;
				padding: 9px 8px 9px 12px;
				border-bottom: 1px solid rgb(235, 231, 225);
				display: flex;
				flex-direction: row;
				margin: 0;

				.asteria-list-item-description {
					flex-grow: 1;
					${Title} {
						font-size: 16px;
						font-family: Swedbank Sans;
						font-weight: 700;
						color: var(--text-color);
						margin: 0 0 4px !important;
					}
					${Text} {
						color: var(--muted-color);
						font-family: Arial, Helvetica, sans-serif;
						font-size: 12px;
					}
				}

				.asteria-list-item-summary {
					display: flex;
					flex-direction: row;
					align-items: center;
					${Title} {
						margin: 0;
						font-size: 16px;
						font-family: Swedbank Sans;
						font-weight: 700;
						color: var(--text-color);
						margin: 0 0 4px !important;
					}
					${Button} {
						margin-left: 4px;
						.asteria-icon-wrapper {
							svg {
								fill: #9f8c82 !important;
							}
						}
					}
				}
			}
		}
	}

	.asteria-financial-options {
		// display: grid;
		// grid-template-columns: repeat( auto-fit, minmax(244px, 1fr) );
		// grid-auto-flow: column;
		// grid-column-gap: 24px;
		// grid-row-gap: 24px;
		display: flex;
		flex-direction: row;
		width: 100%;
		padding: 24px 0;
	}

	.asteria-box {
		border: 1px solid #f2f0ed;
		padding: 16px;
		display: flex;
		align-content: center;
		justify-content: center;
		flex-direction: column;
		min-height: 184px;
		max-width: 244px;
		margin-right: 16px;
		position: relative;
		${Title} {
			color: #262626;
			font-family: 'Swedbank Headline';
			font-size: 18px;
			margin: 0 auto 8px !important;
			text-align: center;
			font-weight: 600;
			line-height: 1.4;
			flex-grow: 0 !important;
		}
		${Text} {
			color: #696969;
			font-size: 14px;
			text-align: center;
		}
		${Button} {
			margin-top: 32px;
			.asteria-icon-wrapper {
				width: 20px;
				height: 12px;
				margin-right: 4px;
			}
			.asteria-text {
				color: #257886 !important;
			}
			&.asteria-button-cover {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				width: 100%;
				height: 100%;
			}
		}
		&:hover {
			background: #f5f5f5;
			box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
			cursor: pointer;
			${Title} {
				color: #53a1ac;
			}
			${Text} {
			}
			${Button} {
			}
		}
	}
`;

FinancialView.displayName = 'FinancialView';

FinancialView.Styler = {
	children: [],
};

const Drawer = styled(({ className, tab, setTab, setShow }) => {
	const { dispatch } = useContext(DatalayerContext);
	const [date, setDate] = useState(new Date());
	const name = false;

	const [notifications] = useStore(
		'store-notifications',
		({ notifications: list }) => list,
	);

	useEffect(() => {
		dispatch(() => ({
			action: 'REQUEST_DATA_LIST_STATISTICS',
			payload: {
				request: {
					startDate: startOfYear(date),
					endDate: endOfYear(date),
					status: ['PAID', 'UNPAID', 'OVERDUE', 'FORECAST'],
				},
				path: 'searchStatistics',
			},
		}));
	}, [date, dispatch]);

	return (
		<div className={classNames(className, 'asteria-cashflow-navigation')}>
			<div className="asteria-wrapper">
				<Button
					icon="circleArrowLeft"
					round
					onClick={() => setDate(addYears(date, -1))}
					type="link"
					className="asteria-overview-left"
					title={TranslationService.get('drawer.overview.previous')}
				/>
				<div className="asteria-company-overview">
					<Text>
						{TranslationService.get(
							'drawer.categories.withdraw.total',
							'{{date | date : "YYYY" }}',
							{ date },
						)}
					</Text>
					{name ? <Title>{name}</Title> : null}
				</div>
				<Button
					type="link"
					icon="circleArrowRight"
					round
					onClick={() => setDate(addYears(date, 1))}
					className="asteria-overview-right"
					title={TranslationService.get('drawer.overview.next')}
				/>
			</div>
			<FeatureFlag feature="navigation-v1">
				<Tabs active={tab} onChange={setTab}>
					<TabsNavigation>
						<Button type="link" tab="overview">
							{TranslationService.get(
								'drawer.overview',
								'Översikt',
							)}
						</Button>
						<Button type="link" tab="categories">
							{TranslationService.get(
								'drawer.categories',
								'Kategorier',
							)}
						</Button>
					</TabsNavigation>
					<TabsContent>
						<CategoriesView
							tab="categories"
							date={date}
							setShow={setShow}
						/>
						<OverviewView
							tab="overview"
							date={date}
							setShow={setShow}
						/>
					</TabsContent>
				</Tabs>
			</FeatureFlag>
			<FeatureFlag feature="navigation-v2">
				<Tabs active={tab} onChange={setTab}>
					<TabsNavigation>
						<Button type="link" tab="overview">
							{TranslationService.get(
								'drawer.overview',
								'Översikt',
							)}
						</Button>
						<FeatureFlag feature="factoring">
							<Button type="link" tab="financial">
								{TranslationService.get(
									'drawer.financial',
									'Finansiera',
								)}
							</Button>
						</FeatureFlag>
						<FeatureFlag feature="drawer-clients" tab="clients">
							<Button type="link">
								{TranslationService.get(
									'drawer.clients',
									'Kunder',
								)}
							</Button>
						</FeatureFlag>
						<FeatureFlag feature="drawer-customers" tab="customers">
							<Button type="link">
								{TranslationService.get(
									'drawer.customers',
									'Kunder',
								)}
							</Button>
						</FeatureFlag>
						<FeatureFlag feature="drawer-suppliers" tab="suppliers">
							<Button type="link">
								{TranslationService.get(
									'drawer.suppliers',
									'Leverantörer',
								)}
							</Button>
						</FeatureFlag>
						<FeatureFlag feature="drawer-currency" tab="currency">
							<Button
								type="link"
								notification={
									notifications.some(
										({ notificationKey = '' }) =>
											notificationKey?.includes(
												'currency',
											),
									)
										? TranslationService.get(
												'drawer.currency.notification',
												'Ditt företag kan påverkas av valutasvängningar. Klicka här för att få en översikt över dina valutor.',
												{
													notifications: notifications.filter(
														({
															notificationKey = '',
														}) =>
															notificationKey?.includes(
																'currency',
															),
													),
												},
										  )
										: false
								}
							>
								{TranslationService.get(
									'drawer.currency',
									'Valutor',
								)}
							</Button>
						</FeatureFlag>
					</TabsNavigation>
					<TabsContent>
						<OverviewView2
							tab="overview"
							date={date}
							setShow={setShow}
						/>

						<FeatureFlag feature="factoring">
							<FinancialView tab="financial" setShow={setShow} />
						</FeatureFlag>
						<FeatureFlag feature="drawer-clients" tab="clients">
							<ClientsView setShow={setShow} start={date} />
						</FeatureFlag>
						<FeatureFlag feature="drawer-customers" tab="customers">
							<ClientsView
								setShow={setShow}
								type="CUSTOMER"
								start={date}
							/>
						</FeatureFlag>
						<FeatureFlag feature="drawer-suppliers" tab="suppliers">
							<ClientsView
								setShow={setShow}
								type="SUPPLIER"
								start={date}
							/>
						</FeatureFlag>
						<FeatureFlag feature="drawer-currency" tab="currency">
							<CurrenciesView setShow={setShow} start={date} />
						</FeatureFlag>
					</TabsContent>
				</Tabs>
			</FeatureFlag>
		</div>
	);
})`
	${TabsNavigation} {
		display: flex !important;
		justify-content: center !important;
		align-items: center !important;
		margin: 8px 0 8px !important;
		${Button} {
			max-width: 90px !important;
			border-radius: 50px !important;
			margin: 0 4px !important;
			max-height: 32px !important;
			background: #d3c3bc !important;
			${Text} {
				text-decoration: none;
				text-align: center;
				font-family: Swedbank Sans;
				font-style: normal;
				font-weight: 700;
				font-size: 14px;
				text-align: center;
				color: #512b2b;
			}
			&:after {
				display: none !important;
			}
			&:hover {
				background: #fdc129 !important;
			}
			&.asteria-state-active {
				background: #fdc129 !important;
			}
		}
	}
	${TabsContent} {
		padding: 0px 40px;
		@media (max-width: 1024px) {
			padding: 0px 8px;
		}
		.asteria-tab {
			@media (max-width: 1440px) {
				flex-direction: row;
				flex-wrap: nowrap;
			}
			@media (max-width: 1279px) {
				flex-direction: column;
			}
			&.asteria-tab-financial {
				flex-direction: column;
			}
		}
	}
	.asteria-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 12px;

		.asteria-company-overview {
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column !important;
			padding: 8px 16px !important;
			margin: 0 0 0 0 !important;
			.asteria-text {
				text-align: center;
				font-family: Swedbank Sans;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 21px;
				text-align: center;
				color: #512b2b;
			}
			.asteria-title {
				font-family: Swedbank Sans;
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 21px;
				text-align: center;
				color: #512b2b;
				margin: 0 auto !important;
			}
		}

		.asteria-overview-left {
			width: 28px;
			height: 28px;
			svg {
				fill: #9f8c82 !important;
				&:hover,
				&.asteria-state-active {
					fill: #ee7024 !important;
				}
			}
		}

		.asteria-overview-right {
			width: 28px;
			height: 28px;
			svg {
				fill: #9f8c82 !important;
				&:hover,
				&.asteria-state-active {
					fill: #ee7024 !important;
				}
			}
		}

		${Title} {
			margin: 0;
			padding: 0;
		}
	}
`;

Drawer.displayName = 'Drawer';

Drawer.Styler = {
	children: [],
};

export default Drawer;
