import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import Button from './button';

const Badge = styled(
	({ className, verticle = 'top', horizontal = 'right', children }) => (
		<div
			className={classNames(
				className,
				`asteria-align-verticle-${verticle}`,
				`asteria-align-horizontal-${horizontal}`,
			)}
		>
			{children}
		</div>
	),
)`
	position: absolute;
	background-color: white;
	border-radius: 50%;
	border: 1px solid black;
	&.asteria-align-verticle-top {
		top: 0px;
	}

	&.asteria-align-verticle-bottom {
		top: 100%;
	}

	&.asteria-align-verticle-center {
		top: 50%;
	}

	&.asteria-align-horizontal-left {
		left: 0px;
	}

	&.asteria-align-horizontal-center {
		left: 50%;
	}

	&.asteria-align-horizontal-right {
		right: 0px;
	}

	${Button} & {
		&.asteria-align-verticle-top {
			&.asteria-align-horizontal-left {
				transform: translateY(-50%) translateX(-50%);
			}

			&.asteria-align-horizontal-right {
				transform: translateY(-50%) translateX(50%);
			}
		}

		&.asteria-align-verticle-bottom {
			&.asteria-align-horizontal-left {
				transform: translateY(50%) translateX(-50%);
			}

			&.asteria-align-horizontal-right {
				transform: translateY(50%) translateX(50%);
			}
		}
	}
`;

export default Badge;
