import React, { useContext, useEffect, useState, useCallback } from 'react';
import { LinearIndicator } from '@asteria/component-progressbar-indicator';
import { TextV2, Title } from '@asteria/component-typography';
import { ButtonV2 } from '@asteria/component-button';
import { TranslationService } from '@asteria/services-language';
import { fetchIntegrations } from '@asteria/services-datalayer/services/integrations/actions';
import { useStore } from '@asteria/utils';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import Steps from '../../static/steps';
import Layout from '../../layout';
import Success from './success';
import Context from '../../context';

let timer;
let nextCheckTime;

const clearAllTimeouts = () => {
	clearTimeout(timer);
	timer = undefined;
	nextCheckTime = 0;
};

const Connecting = ({ navigator, callback = () => {} }) => {
	const { setStepIndex } = useContext(Context);
	const { dispatch } = useContext(DatalayerContext);
	const timeoutBetweenChecks = 5000;
	const [connectionAttempts, setConnectionAttempts] = useState(0);

	// const bankOnlyOnboarding = useFeature({ key: 'bank-only-onboarding' });

	useEffect(() => {
		setStepIndex(2);
		callback('goto', {
			integration: 'bjornlunden',
			integrationTitle: 'Björn Lundén',
			step: {
				stepId: 'bjornlundenConnecting',
				title: 'Väntar på anslutning',
			},
			steps: [
				{ stepId: 'bjornlundenActivation', title: 'Aktivera' },
				{
					stepId: 'bjornlundenConnecting',
					title: 'Väntar på anslutning',
				},
			],
		});
	}, [callback, setStepIndex]);

	const [erps = []] = useStore(
		'store-integrations',
		({ integrations = [] }) => integrations,
	);
	const [integrationId] = useStore(
		'store-integrations',
		({ integrationInProgress }) => integrationInProgress,
	);
	const [error] = useStore(
		'store-integrations',
		({ networkError }) => networkError,
	);

	if (error) {
		navigator.back(null, navigator);
	}

	const integration = erps.find(int => int.id === integrationId);

	/*
	useEffect(() => {
		if (integration) {
			subscriptionService
				.subscribe({
					query: `
					subscription IntegrationUpdated {
						integrationUpdated {
							id
							config {
								connected
								errors {
									code
									message
								}
							}
						}
					}
				`,
				})
				.subscribe(({ data: { integrationUpdated = {} } = {} }) => {
					if (integrationUpdated.id === integration.id) {
						if (integrationUpdated?.config?.errors?.length) {
							navigator.back(
								{
									event: 'goto',
									integration: 'bjornlunden',
								},
								navigator,
							);
						}

						if (integrationUpdated?.config?.connected === true) {
							if (bankOnlyOnboarding) {
								navigator.navigateTo(
									Success.navigationKey,
									navigator,
								);
							} else {
								dispatch(() => ({
									action: 'CONNECTION_CLEANUP',
								}));

								navigator.navigateTo(
									Steps.SelectProvider,
									navigator,
								);
							}

							callback('success', {
								event: 'success',
								integration: 'bjornlunden',
								integrationTitle: 'Wint',
							});
						}
					}
				});
		}
	}, [
		bankOnlyOnboarding,
		callback,
		dispatch,
		integration,
		navigator,
		subscriptionService,
	]);

	if (
		integration &&
		(integration.config.errors && integration.config.errors.length > 0)
	) {
		navigator.back(
			{
				event: 'goto',
				integration: 'fortnox',
				// integrationTitle: fortnox.title,
				// step: fortnox.steps[0],
			},
			navigator,
		);
		return null;
	}
	*/

	const recheck = useCallback(() => {
		if (connectionAttempts * timeoutBetweenChecks < 60000) {
			const timestamp = new Date().getTime();

			if (!nextCheckTime || timestamp >= nextCheckTime) {
				// Do polling during a minute
				clearAllTimeouts();
				timer = setTimeout(() => {
					dispatch(fetchIntegrations());
					setConnectionAttempts(connectionAttempts + 1);
					recheck();
				}, timeoutBetweenChecks);
				nextCheckTime = timestamp + timeoutBetweenChecks;
			}
		} else {
			clearAllTimeouts();
			// Give an error if we've exceeded this timeout
			navigator.back(
				{
					event: 'goto',
					integration: 'bjornlunden',
					// integrationTitle: fortnox.title,
					// step: fortnox.steps[0],
				},
				navigator,
			);
		}
	}, [connectionAttempts, dispatch, navigator]);

	if (
		integration &&
		(integration.config.errors && integration.config.errors.length > 0)
	) {
		clearAllTimeouts();
		navigator.back(
			{
				event: 'goto',
				integration: 'bjornlunden',
				// integrationTitle: fortnox.title,
				// step: fortnox.steps[0],
			},
			navigator,
		);
		return null;
	}
	if (integration && integration.config.connected === true) {
		clearAllTimeouts();

		dispatch(() => ({
			action: 'CONNECTION_CLEANUP',
		}));
		navigator.navigateTo(Success.navigationKey, navigator);
	}

	recheck();

	return (
		<Layout
			header={
				<Title
					className="asteria-title-onboarding-header"
					size="title2"
				>
					{TranslationService.get(
						'onboarding.connect.header',
						'2. Anslut till bokföringssystem',
					)}
				</Title>
			}
			type="bjornlunden-connecting"
			body={
				<>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							padding: '20px 20px 20px',
							backgroundColor: '#fbf2ea',
							minHeight: '200px',
							justifyContent: 'center',
						}}
						className="asteria-dialog-box"
					>
						<TextV2 style={{ marginBottom: '8px' }}>
							{TranslationService.get(
								'onboarding.bjornlunden.connecting.text',
								'Ansluter till Björn Lundén',
							)}
						</TextV2>
						<LinearIndicator />
					</div>

					<div
						className="asteria-wizard-inner-footer"
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							paddingTop: '20px',
							marginTop: '55px',
						}}
					>
						<ButtonV2
							size="medium"
							type="default"
							onClick={() =>
								navigator.cancel(
									{
										event: 'cancel',
									},
									navigator,
								)
							}
							text={TranslationService.get(
								'onboarding.abort',
								'Avbryt',
							)}
						/>
						<ButtonV2
							size="medium"
							type="secondary"
							onClick={() =>
								navigator.back(
									{
										event: 'goto',
										integration: 'bjornlunden',
									},
									navigator,
								)
							}
							text={TranslationService.get(
								'onboarding.back',
								'Tillbaka',
							)}
						/>
					</div>
				</>
			}
			footer={null}
		/>
	);
};

Connecting.navigationKey = Steps.BjornLundenConnecting;

export default Connecting;
