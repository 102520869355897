import Tabs from './tabs';

const buildLegendIcon = style => {
	const data = `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"><path d="M 0 5 L 12 5" style="${style}"></path></svg>`;
	return `url("data:image/svg+xml;charset=utf8,${escape(data)}")`;
};

export default {
	graph: {
		queries: {
			'(max-width: 767.98px)': {
				style: {
					paddingRight: '0px',
					width: 'calc(100%)',
					marginLeft: '0',
				},
			},
			'(min-width: 768px)': {
				style: {
					margin: '0px',
				},
			},
			'(min-width: 990px)': {
				style: {
					margin: '0px',
					marginLeft: '0px',
				},
			},
			'(min-width: 1200px)': {
				style: {
					marginLeft: '0px',
					paddingTop: '0px',
				},
			},
		},
		header: {
			style: {
				alignItems: 'center',
				justifyContent: 'flex-end',
				marginTop: '0',
				alignSelf: 'flex-start',
				lineHeight: '100%',
				width: '100%',
			},
			queries: {
				'(max-width: 767.98px)': {
					style: {
						flexDirection: 'column-reverse',
						alignItems: 'flex-start',
						padding: '0px 12px',
					},
				},
				'(max-width: 990px)': {
					style: {
						padding: '0px 12px',
					},
				},
				'(min-width: 768px)': {
					style: {},
				},
				'(min-width: 1200px)': {
					style: {
						paddingRight: '6px',
					},
				},
			},
			timesliceselector: {
				style: {},
				queries: {
					'(max-width: 767.98px)': {
						style: {
							marginBottom: '16px',
							alignSelf: 'flex-start',
							marginTop: '8px',
						},
					},
					'(min-width: 768px)': {
						style: {
							marginRight: '-16px',
							alignSelf: 'flex-start',
							marginTop: '8px',
						},
					},
					'(min-width: 1200px)': {
						style: {
							alignSelf: 'flex-start',
							marginTop: '8px',
						},
					},
				},
				time: {
					style: {
						marginRight: '32px',
					},
					text: {
						style: {
							color: 'var(--text-color)',
						},
					},
					states: {
						hover: {
							text: {
								style: {
									textDecoration: 'underline',
								},
							},
						},
						active: {
							text: {
								style: {
									textDecoration: 'underline',
								},
							},
						},
					},
				},
			},
			title: {
				style: {
					flexGrow: 1,
					marginLeft: '0px',
					marginTop: '0px',
					transition: 'all 0.2s ease-in',
				},
				queries: {
					'(max-width: 767.98px)': {
						style: {
							fontSize: '20px',
						},
					},
					'(min-width: 768px)': {
						style: {
							marginLeft: '72px',
						},
					},
					'(min-width: 1200px)': {
						style: {
							/* marginTop: '39px', */
							marginTop: '29px',
						},
					},
				},
			},
			menu: {
				style: {
					minHeight: '0',
					background: 'transparent',
				},
				queries: {
					'(max-width: 767.98px)': {
						style: {
							marginLeft: 'auto',
							marginBottom: '-27px',
							alignSelf: 'flex-start',
						},
					},
					'(min-width: 768px)': {
						style: {
							alignSelf: 'flex-start',
						},
					},
					'(min-width: 1200px)': {
						style: {
							alignSelf: 'flex-start',
						},
					},
				},
				toggle: {
					style: {
						height: '36px',
						border: '0',
						padding: '0',
						background: 'transparent',
					},
					icon: {
						style: {
							height: '24px',
							width: '24px',
							flexGrow: '1',
						},
						svg: {
							style: {
								fill: '#ee7024',
							},
						},
					},
					states: {
						active: {
							icon: {
								svg: {
									style: {
										fill: '#EE7024',
									},
								},
							},
						},
						hover: {
							icon: {
								svg: {
									style: {
										fill: '#EE7024',
									},
								},
							},
						},
					},
				},
				dropdown: {
					style: {
						backgroundColor: '#ffffff',
						boxShadow: 'rgba(0, 0, 0, 0.25) 0px 3px 5px 0px',
						minWidth: '290px',
						overflow: 'hidden',
						transition: 'max-height 0.3s',
						maxHeight: '0',
						display: 'block',
					},
					item: {
						style: {
							borderTop: '1px solid rgb(244, 244, 244)',
							padding: '16px',
							fontFamily: 'Swedbank Sans',
							fontWeight: 700,
						},
						text: {
							style: {
								fontSize: '14px',
							},
						},
						states: {
							hover: {
								style: {
									backgroundColor: 'rgb(242, 242, 242)',
								},
							},
						},
					},
				},
				types: {
					open: {
						toggle: {},
						dropdown: {
							style: {
								transition: 'max-height 0.5s',
								maxHeight: '500px',
							},
						},
					},
				},
			},
		},
		title: {
			style: {
				marginTop: '8px',
				marginLeft: '12px',
				alignSelf: 'flex-start',
				lineHeight: '100%',
				transition: 'all 0.2s ease-in',
			},
			queries: {
				'(max-width: 767.98px)': {
					style: {
						fontSize: '20px',
					},
				},
				'(min-width: 768px)': {
					style: {
						marginLeft: '72px',
					},
				},
				'(min-width: 1200px)': {
					style: {
						marginTop: '0',
					},
				},
			},
		},
		...Tabs,
		graph: {
			style: {
				height: '175px',
				marginLeft: '0px',
				paddingRight: '0px',
				gridTemplateColumns: '72px 1fr',
				gridTemplateRows: '1fr 40px',
				'-ms-gridRows': 'auto 40px',
				width: 'calc(100% - 42px)',
				position:
					'relative' /* Quick fix for adding Tkr to the top of Yaxis */,
				// transition: 'all 0.2s ease-in',
			},
			queries: {
				'(max-width: 767.98px)': {
					style: {
						marginBottom: '20px',
					},
				},
				'(min-width: 768px)': {
					style: {},
				},
				'(min-width: 1200px)': {
					style: {
						'-ms-gridColumns': '65px 100%',
						gridTemplateColumns: '65px 1fr',
						width: 'calc(100% - 42px)',
					},
				},
			},
			types: {
				stacked: {
					style: {
						height: '100%',
					},
					graphArea: {
						bargroup: {
							style: {
								width: '100px',
								gridTemplateRows:
									'calc(50% - 2px) calc(50% - 2px)',
								gridTemplateColumns: '100%',
								gridRowGap: '3px',
							},
							bar: {
								style: {
									width: '48px',
									justifySelf: 'center',
								},
								indicator: {
									style: {
										display: 'none',
									},
								},
								types: {
									deposit: {
										style: {
											transform: 'rotate(180deg)',
											'-ms-gridColumn': '1',
											'-ms-gridRow': '1',
											gridColumnStart: 1,
											gridColumnEnd: 1,
											gridRowStart: 1,
											gridRowEnd: 1,
										},
									},
									withdraw: {
										style: {
											'-ms-gridColumn': '1',
											'-ms-gridRow': '2',
											gridColumnStart: 1,
											gridColumnEnd: 1,
											gridRowStart: 2,
											gridRowEnd: 2,
										},
										base: {
											badge: {
												style: {
													transform: 'rotate(0deg)',
												},
											},
										},
									},
								},
							},
						},
					},
				},
				grouped: {
					style: {},
					queries: {
						'(max-width: 767.98px)': {
							style: {},
						},
						'(min-width: 768px)': {
							style: {},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
					graphArea: {
						bargroup: {
							style: {
								width: '144px',
								'-ms-gridRows': '100%',
								'-ms-gridColumns': '50% 50%',
								gridTemplateRows: '100%',
								gridTemplateColumns: '50% 50%',
								paddingBottom: '3px',
							},
							bar: {
								style: {
									width: '48px',
									justifySelf: 'center',
									transform: 'rotate(180deg)',
								},
								types: {
									deposit: {
										style: {
											'-ms-gridColumn': '1',
											'-ms-gridRow': '1',
											gridColumnStart: 1,
											gridColumnEnd: 1,
											gridRowStart: 1,
											gridRowEnd: 1,
											justifySelf: 'flex-end',
											marginRight: '8px',
										},
										queries: {
											'(-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
												style: {
													left: '16px',
												},
											},
										},
									},
									withdraw: {
										style: {
											'-ms-gridColumn': '2',
											'-ms-gridRow': '1',
											gridColumnStart: 2,
											gridColumnEnd: 2,
											gridRowStart: 1,
											gridRowEnd: 1,
											justifySelf: 'flex-start',
											marginLeft: '8px',
										},
										queries: {
											'(-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
												style: {
													right: '4px',
												},
											},
										},
									},
								},
							},
							queries: {
								/* '(-webkit-min-device-pixel-ratio:0)': {
									style: {
										paddingBottom: '5px',
									},
								}, To increase padding from bars from 0 line, diffs with firefox */
							},
						},
						linegroup: {
							states: {
								active: {
									background: {
										style: {
											backgroundColor:
												'rgb(251, 242, 234)',
										},
									},
								},
								hover: {
									background: {
										style: {
											backgroundColor:
												'rgb(251, 242, 234)',
										},
									},
								},
							},
						},
					},
					types: {
						lines: {
							graphArea: {
								queries: {
									'(max-height: 800px)': {
										style: {
											'-ms-gridRows': '100%',
											gridTemplateRows: 'auto 40px',
										},
									},
								},
								linegroup: {
									wrapper: {
										style: {
											// height: '115px',
											height: '100%',
										},
									},
								},
							},
						},
					},
				},
				hasFilter: {
					graphArea: {
						bargroup: {
							bar: {
								base: {
									types: {
										deposit: {
											style: {
												background:
													'rgb(214, 214, 214)',
											},
										},
										withdraw: {
											style: {
												background:
													'rgb(183, 183, 183)',
											},
										},
									},
								},
							},
						},
					},
					types: {
						visibleCategoryInvoices: {
							graphArea: {
								bargroup: {
									bar: {
										base: {
											badge: {
												style: {
													display: 'flex',
												},
											},
										},
									},
								},
							},
						},
					},
				},
				hasNoFilter: {
					graphArea: {
						bargroup: {
							bar: {
								base: {
									types: {
										forecast: {
											part: {
												types: {
													unpaid: {
														style: {
															display: 'none',
														},
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
				isActiveGroup: {
					graphArea: {
						linegroup: {
							states: {
								active: {
									lineinfo: {
										dot: {
											style: {
												backgroundColor:
													'rgb(255, 182, 0)',
											},
										},
									},
								},
							},
						},
					},
				},
				dragging: {
					graphArea: {
						style: {
							cursor: 'grabbing',
						},
					},
				},
			},
			yaxis: {
				labels: {
					style: {
						marginRight: '10px',
					},
					text: {
						style: {
							textAlign: 'right',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							display: 'block',
							textOverflow: 'ellipsis',
						},
					},
				},
				lines: {
					line: {
						style: {
							borderTop: '2px dotted rgb(212, 197, 187)',
						},
						types: {
							zero: {
								style: {
									borderTop: '2px solid rgb(198, 195, 191)',
								},
							},
						},
					},
				},
			},
			graphArea: {
				style: {
					'-ms-gridRows': '136px 40px',
					gridTemplateRows: 'auto 40px',
					paddingLeft: '26px',
				},
				xaxis: {
					style: {
						justifyContent: 'flex-end',
						alignItems: 'center',
						height: '32px',
					},
					label: {
						style: {
							color: '#000',
							fontSize: '16px',
							cursor: 'pointer',
						},
					},
					prefix: {
						style: {
							color: '#000000',
							fontSize: '10px',
							fontFamily: 'Swedbank Sans',
							fontWeight: 700,
							cursor: 'pointer',
						},
					},
					states: {
						hover: {
							label: {
								style: {
									fontFamily: 'Swedbank Headline',
									color: 'var(--font-color-current)',
								},
							},
						},
						active: {
							label: {
								style: {
									fontFamily: 'Swedbank Headline',
									color: 'var(--font-color-active)',
								},
							},
						},
					},
					types: {
						noChildrenActive: {
							indicator: {
								style: {
									display: 'block',
								},
							},
						},
						noChildrenHover: {
							indicator: {
								style: {
									display: 'block',
								},
							},
						},
						history: {
							prefix: {
								style: {
									color: 'var(--font-color-past)',
									fontSize: '10px',
									fontFamily: 'Swedbank Sans',
									fontWeight: 700,
								},
							},
							label: {
								style: {
									fontFamily: 'Swedbank Sans',
									color: 'var(--font-color-past)',
								},
							},
							states: {
								hover: {
									label: {
										style: {
											fontFamily: 'Swedbank Headline',
											color: 'var(--font-color-current)',
										},
									},
								},
								active: {
									label: {
										style: {
											fontFamily: 'Swedbank Headline',
											color: 'var(--font-color-active)',
										},
									},
								},
							},
						},
						today: {
							prefix: {
								style: {
									color: 'var(--font-color-current)',
									fontSize: '10px',
									fontFamily: 'Swedbank Sans',
									fontWeight: 700,
								},
							},
							label: {
								style: {
									fontFamily: 'Swedbank Headline',
									color: 'var(--font-color-current)',
								},
							},
							states: {
								hover: {
									label: {
										style: {
											fontFamily: 'Swedbank Headline',
											color: 'var(--font-color-current)',
										},
									},
								},
								active: {
									label: {
										style: {
											fontFamily: 'Swedbank Headline',
											color: 'var(--font-color-active)',
										},
									},
								},
							},
						},
					},
				},
				bargroup: {
					style: {
						margin: '8px 0px',
					},
					bar: {
						base: {
							style: {
								minHeight: '4px',
								cursor: 'pointer',
							},
							part: {
								types: {
									forecast: {
										style: {
											backgroundImage:
												' repeating-linear-gradient(135deg,transparent,transparent 1px,rgba(255,255,255,0.2) 2px,rgba(255,255,255,0.2) 4px,transparent 4.5px)',
										},
										part: {
											style: {
												backgroundImage:
													'repeating-linear-gradient(135deg, transparent, transparent 1px, rgba(0, 0, 0, 0.1) 2px, rgba(0, 0, 0, 0.1) 4px, transparent 4.5px)',
											},
										},
									},
									unpaid: {
										style: {
											backgroundColor:
												'var(--system-unpaid-color)',
											backgroundImage:
												'repeating-linear-gradient(135deg, transparent, transparent 1px, rgba(0, 0, 0, 0.1) 2px, rgba(0, 0, 0, 0.1) 4px, transparent 4.5px)',
										},
									},
									overdue: {
										style: {
											// display: 'none',
										},
									},
								},
							},
							badge: {
								style: {
									position: 'absolute',
									left: '-10px',
									bottom: '-10px',
									height: '20px',
									width: '20px',
									borderRadius: '50%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									transform: 'rotate(180deg)',
									zIndex: '1',
								},
								types: {
									overdue: {
										style: {
											backgroundColor:
												'rgb(168, 47, 44);',
										},
									},
									unpaid: {
										style: {
											backgroundColor:
												'rgb(168, 47, 44);',
										},
									},
								},
								text: {
									style: {
										color: '#ffffff',
										fontSize: '12px',
										fontFamily: 'Swedbank Headline',
										fontWeight: '200',
										justifyContent: 'center',
										alignItems: 'center',
										lineHeight: '17px',
									},
								},
							},
							types: {
								zero: {
									style: {
										minHeight: '0px',
									},
								},
								forecast: {
									style: {
										backgroundImage:
											'repeating-linear-gradient(135deg,transparent,transparent 1px,rgba(255,255,255,0.2) 2px,rgba(255,255,255,0.2) 4px,transparent 4.5px)',
									},
									part: {
										types: {
											unpaid: {
												style: {
													display: 'none',
												},
											},
										},
									},
								},
							},
						},
						types: {
							/*
							deposit: {
								base: {
									style: {
										backgroundColor: 'rgba(43,153,164,1)',
									},
								},
							},
							withdraw: {
								base: {
									style: {
										backgroundColor: 'rgba(103,85,83,1)',
									},
								},
							},
							*/
							deposit: {
								types: {
									background: {
										base: {
											style: {
												backgroundColor:
													'rgb(214, 214, 214)',
											},
											part: {
												style: {
													backgroundColor:
														'transparent',
												},
											},
										},
									},
								},
							},
							withdraw: {
								types: {
									background: {
										base: {
											style: {
												backgroundColor:
													'rgb(183, 183, 183)',
											},
											part: {
												style: {
													backgroundColor:
														'transparent',
												},
											},
										},
									},
								},
							},
							unpaid: {
								base: {
									style: {
										backgroundColor:
											'var(--system-unpaid-color)',
										backgroundImage:
											'repeating-linear-gradient(135deg, transparent, transparent 1px, rgba(0, 0, 0, 0.1) 2px, rgba(0, 0, 0, 0.1) 4px, transparent 4.5px)',
									},
								},
							},
						},
						states: {
							active: {
								indicator: {
									style: {
										display: 'block',
									},
								},
							},
							hover: {
								indicator: {
									style: {
										display: 'block',
									},
								},
								adjust: {
									style: {
										display: 'flex',
									},
								},
							},
						},
					},
					states: {
						active: {
							background: {
								style: {
									backgroundColor: 'rgb(251, 242, 234)',
								},
							},
						},
						hover: {
							background: {
								style: {
									backgroundColor: 'rgb(251, 242, 234)',
								},
							},
							bar: {
								adjust: {
									style: {
										display: 'flex',
									},
									states: {
										hover: {
											button: {
												style: {
													display: 'flex !important',
												},
											},
										},
									},
								},
							},
						},
					},
				},
				linegroup: {
					style: {},
					line: {
						style: {
							stroke: 'rgb(114, 96, 95)',
							strokeWidth: 2,
						},
						/*
						types: {
							belowZero: {
								style: {
									stroke: 'red',
								},
							},
						},
						*/
					},
					/*
					states: {
						active: {
							lineinfo: {
								dot: {
									style: {
										backgroundColor: 'rgb(255, 182, 0)',
									},
								},
							},
						},
					},
					*/
					types: {
						forecast: {
							line: {
								style: {
									stroke: 'rgb(114, 96, 95)',
									strokeDasharray: 7,
									strokeWidth: 2,
								},
								types: {
									overdraft: {
										style: {
											strokeDasharray: 0,
											// strokeDasharray: 8,
										},
									},
								},
							},
						},
						today: {
							lineinfo: {
								dot: {
									style: {
										backgroundColor: 'rgb(114, 96, 95)',
									},
								},
							},
						},
					},
					/*
					lineinfo: {
						types: {
							belowZero: {
								dot: {
									style: {
										backgroundColor: 'red',
									},
								},
							},
						},
					}
					*/
				},
			},
		},
		controllers: {
			menu: {
				style: {
					marginTop: '8px',
					marginRight: '0px',
				},
				queries: {
					'(max-width: 767.98px)': {
						style: {
							marginLeft: '12px',
							marginBottom: '16px',
							marginRight: '0',
						},
					},
					'(min-width: 768px)': {
						style: {
							marginLeft: '72px',
							marginRight: '-72px',
							width: 'calc(100% - 106px)',
						},
					},
					'(min-width: 1200px)': {
						style: {},
					},
				},
				categories: {
					style: {
						display: 'flex',
					},
					category: {
						style: {
							backgroundColor: 'var(--chip-color)',
							paddingLeft: '16px',
							paddingRight: '16px',
							marginRight: '8px',
							borderRadius: '30px',
							height: '32px',
							alignSelf: 'flex-start',
							cursor: 'pointer',
							alignItems: 'center',
						},
						prefix: {
							style: {
								display: 'none',
							},
						},
						text: {
							style: {
								color: 'var(--text-color)',
								fontFamily: 'Swedbank Sans',
								fontWeight: 700,
							},
						},
						states: {
							hover: {
								style: {
									backgroundColor: 'rgb(252, 193, 42)',
								},
							},
							active: {
								style: {
									backgroundColor: 'rgb(252, 193, 42)',
								},
							},
						},
						type: {
							small: {
								style: {
									height: '24px',
									paddingLeft: '12px',
									paddingRight: '12px',
									paddingTop: '4px',
									paddingBottom: '4px',
								},
								text: {
									style: {
										fontSize: '12px',
									},
								},
							},
						},
					},
				},
				tags: {
					style: {
						display: 'flex',
						paddingRight: '0',
						paddingTop: '0px',
						paddingBottom: '0',
						marginRight: '8px',
						marginTop: '24px',
						marginBottom: '8px',
						borderRadius: '30px',
						// height: '32px',
					},
					tag: {
						style: {
							alignItems: 'center',
							marginRight: '8px',
							alignSelf: 'center',
						},
						prefix: {
							style: {
								height: '12px',
								width: '12px',
								marginRight: '8px',
							},
						},
						text: {
							style: {
								color: 'var(--text-color)',
								fontFamily: 'Swedbank Sans',
								fontWeight: 700,
								whiteSpace: 'pre',
							},
						},
						types: {
							defaultForecast: {
								style: {
									order: '-1',
								},
								prefix: {
									style: {
										backgroundColor: 'rgb(255, 255, 255)',
										backgroundImage:
											'repeating-linear-gradient(135deg, transparent, transparent 2.5px, rgb(150, 150, 150) 3px, rgb(150, 150, 150) 4px, transparent 4.5px)',
									},
								},
							},
							defaultWithdraw: {
								style: {
									order: '-1',
								},
							},
							defaultDeposit: {
								style: {
									order: '-1',
								},
							},
						},
					},
				},
			},
			search: {
				detailedbar: {
					style: {
						overflow: 'hidden',
					},
					queries: {
						'(max-width: 767.98px)': {
							style: {
								margin: '0px 12px',
								width: 'auto',
							},
						},
						'(min-width: 768px)': {
							style: {},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
					header: {
						style: {
							display: 'none',
						},
						button: {
							text: {
								style: {
									fontFamily: 'Swedbank Headline',
									fontWeight: '400',
									fontSize: '14px',
									color: '#262626',
									textDecoration: 'none',
									marginRight: '8px',
									lineHeight: '100%',
									marginTop: '3px',
								},
							},
							style: {
								padding: '0px',
								borderBottom: '0px solid transparent',
								marginTop: '4px',
								marginBottom: '4px',
							},
							icon: {
								style: {
									width: '12px',
									height: '10px',
									marginRight: '4px',
									marginTop: '4px',
								},
								svg: {
									style: {
										transform: 'rotate(0deg)',
										transition:
											'transform 0.25s ease-in 0s',
										fill: 'rgb(159,140,130)',
									},
								},
							},
							types: {
								infoHelp: {
									style: {
										padding: '0px',
										width: 'auto',
										height: 'auto',
										marginTop: '0px',
									},
									icon: {
										style: {
											width: '24px',
											height: '24px',
											marginLeft: '4px',
										},
										svg: {
											style: {
												transform: 'rotate(0deg)',
												fill: 'var(--primary-color)',
											},
										},
									},
								},
							},
						},
					},
					box: {
						style: {
							maxHeight: '0px',
							transition: 'max-height 0.5s',
						},
						bar: {
							style: {
								height: '18px',
								margin: '0 0 8px',
								backgroundColor: '#B7B7B7',
							},
							part: {
								types: {
									forecast: {
										style: {
											backgroundImage:
												'repeating-linear-gradient(135deg,transparent,transparent 1px,rgba(255, 255, 255, 0.2) 2px,rgba(255, 255, 255, 0.2) 4px,transparent 4.5px)',
										},
									},
								},
							},
						},
						pills: {
							box: {
								style: {
									marginRight: '16px',
									flexShrink: '0',
								},
								pill: {
									style: {
										border: '1px solid rgb(235, 231, 226)',
										borderRadius: '30px',
										padding: '6px 12px',
										cursor: 'pointer',
										marginBottom: '4px',
									},
									text: {
										style: {
											margin: '0',
										},
									},
									security: {
										style: {
											marginLeft: '8px',
										},
									},
									states: {
										hover: {
											style: {
												backgroundColor:
													'rgb(253, 193, 40)',
												borderColor:
													'rgb(253, 193, 40)',
											},
										},
										active: {
											style: {
												backgroundColor:
													'rgb(253, 193, 40)',
												borderColor:
													'rgb(253, 193, 40)',
											},
										},
									},
									types: {
										forecast: {
											prefix: {
												style: {
													backgroundImage:
														'repeating-linear-gradient(135deg,transparent,transparent 1px,rgba(255, 255, 255, 0.2) 2px,rgba(255, 255, 255, 0.2) 4px,transparent 4.5px)',
												},
											},
										},
									},
								},
								text: {
									style: {
										marginLeft: '32px',
									},
								},
							},
						},
					},
					states: {
						active: {
							box: {
								style: {
									maxHeight: '200px',
								},
							},
							header: {
								button: {
									text: {},
									style: {
										borderBottom: '0px solid transparent',
									},
									icon: {
										style: {},
										svg: {
											style: {
												transform: 'rotate(180deg)',
											},
										},
									},
								},
							},
						},
						hover: {
							header: {
								button: {
									text: {},
									style: {},
									icon: {
										style: {},
										svg: {
											style: {
												fill: 'var(--primary-color)',
											},
										},
									},
								},
							},
						},
					},
					wrapper: {
						style: {
							padding: '0px 0 16px',
						},
					},
					types: {
						locked: {
							header: {
								button: {
									style: {
										display: 'none',
									},
								},
							},
						},
					},
				},
			},
		},
		navigation: {
			style: {
				// paddingTop: '35px',
				padding: '0',
				margin: '0',
			},
			queries: {
				'(max-width: 767.98px)': {
					style: {
						justifyContent: 'flex-end',
						alignItems: 'flex-start',
						paddingTop: '6px',
					},
				},
				'(min-width: 768px)': {
					style: {},
				},
				'(min-width: 1200px)': {
					style: {
						marginTop: '6px',
					},
				},
			},
			button: {
				style: {
					borderRadius: '50%',
					backgroundColor: 'transparent',
					borderColor: 'rgb(159, 140, 130)',
					borderWidth: '2px',
					height: '34px',
					width: '24px',
					padding: '0px',
					position: 'relative',
					top: '-20px',
					zIndex: '1',
				},
				queries: {
					'(max-width: 767.98px)': {
						style: {
							top: '0',
						},
					},
					'(min-width: 768px)': {
						style: {},
					},
					'(min-width: 1200px)': {
						style: {},
					},
				},
				types: {
					left: {
						style: {
							marginLeft: '12px',
						},
						queries: {
							'(max-width: 767.98px)': {
								style: {
									marginRight: '12px',
								},
							},
							'(min-width: 768px)': {
								style: {},
							},
							'(min-width: 1200px)': {
								style: {
									marginLeft: '12px',
								},
							},
						},
					},
					right: {
						style: {
							marginRight: '14px',
						},
						queries: {
							'(max-width: 767.98px)': {
								style: {
									marginRight: '56px',
								},
							},
							'(min-width: 768px)': {
								style: {},
							},
							'(min-width: 1200px)': {
								style: {
									marginRight: '14px',
								},
							},
						},
						icon: {
							style: {
								/* transform: 'rotate(180deg)', */
							},
						},
					},
				},
				icon: {
					style: {
						margin: '0 auto',
					},
					svg: {
						style: {
							fill: 'rgb(159, 140, 130)',
							transition: 'all 80ms linear',
						},
					},
				},
				states: {
					hover: {
						icon: {
							svg: {
								style: {
									fill: 'var(--primary-color)',
								},
							},
						},
					},
				},
			},
		},
		legends: {
			style: {
				marginLeft: '0',
				display: 'flex',
				paddingRight: '16px',
				paddingTop: '0px',
				paddingBottom: '12px',
				marginRight: '8px',
				// marginBottom: '8px',
				marginBottom: '-8px',
				borderRadius: '30px',
				// height: '32px',
				pointerEvents: 'none',
			},
			queries: {
				'(max-width: 767.98px)': {
					style: {
						marginLeft: '12px',
						marginBottom: '8px',
						marginTop: '4px',
					},
				},
				'(min-width: 768px)': {
					style: {
						marginLeft: '72px',
					},
				},
				'(min-width: 1200px)': {
					style: {
						marginTop: '4px',
					},
				},
			},
			part: {
				style: {
					alignItems: 'center',
					marginRight: '12px',
					alignSelf: 'flex-start',
				},
				text: {
					style: {
						color: 'var(--text-color)',
						fontFamily: 'Swedbank Sans',
						fontWeight: 700,
					},
				},
				prefix: {
					style: {
						height: '12px',
						width: '12px',
						marginRight: '8px',
					},
				},
				types: {
					history: {
						prefix: {
							style: {
								backgroundImage: buildLegendIcon(
									'stroke: rgba(114,96,95,1);stroke-width: 2px;',
								),
								// 'url("data:image/svg+xml;utf8,<svg xmlns=\\"http://www.w3.org/2000/svg\\" width=\\"12\\" height=\\"12\\"><path d=\\"M 0 5 L 12 5\\" style=\\"stroke: rgba(114,96,95,1);stroke-width: 2px;\\"></path></svg>")',
							},
						},
					},
					forecast: {
						prefix: {
							style: {
								backgroundImage: buildLegendIcon(
									'stroke: rgba(114,96,95,1);stroke-width: 2px;stroke-dasharray: 4;',
								),
								// 'url("data:image/svg+xml;utf8,<svg xmlns=\\"http://www.w3.org/2000/svg\\" width=\\"12\\" height=\\"12\\"><path d=\\"M 0 5 L 12 5\\" style=\\"stroke: rgba(114,96,95,1);stroke-width: 2px;stroke-dasharray: 4;\\"></path></svg>")',
							},
						},
					},
					spread: {
						prefix: {
							style: {
								backgroundImage:
									'linear-gradient(141deg, #dadada 25%, #bfbfbf 25%, #bfbfbf 50%, #dadada 50%, #dadada 75%, #bfbfbf 75%, #bfbfbf 100%)',
								backgroundSize: '6.36px 5.15px',
								borderRadius: '0',
								marginTop: '-1px',
							},
						},
					},
					credit: {
						prefix: {
							style: {
								backgroundImage: buildLegendIcon(
									'stroke: rgba(156,57,50,1);stroke-width: 2px;',
								),
								// 'url("data:image/svg+xml;utf8,<svg xmlns=\\"http://www.w3.org/2000/svg\\" width=\\"12\\" height=\\"12\\"><path d=\\"M 0 5 L 12 5\\" style=\\"stroke: rgba(156,57,50,1);stroke-width: 2px;\\"></path></svg>")',
							},
						},
					},
				},
			},
		},
		types: {
			hideAccountBalance: {
				navigation: {
					style: {
						paddingTop: '0px',
					},
					queries: {
						'(max-width: 767.98px)': {
							style: {
								justifyContent: 'flex-end',
								paddingTop: '6px',
							},
						},
						'(min-width: 768px)': {
							style: {},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
					button: {
						types: {
							left: {
								style: {
									marginLeft: '6px',
								},
								queries: {
									'(max-width: 767.98px)': {
										style: {
											marginRight: '6px',
										},
									},
									'(min-width: 768px)': {
										style: {},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
							},
							right: {
								style: {},
								queries: {
									'(max-width: 767.98px)': {
										style: {
											marginRight: '56px',
										},
									},
									'(min-width: 768px)': {
										style: {},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
								icon: {
									style: {},
								},
							},
						},
					},
				},
			},
			stacked: {
				style: {
					height: 'calc(100vh - 240px)',
					display: 'flex',
					flexDirection: 'column',
				},
				queries: {
					'(max-width: 767.98px)': {
						style: {
							// height: 'calc(100vh - 240px)',
						},
					},
					'(min-width: 768px)': {
						style: {
							// height: 'calc(100vh - 220px)',
							height: '40vh',
						},
					},
					'(min-width: 990px)': {
						style: {
							// height: 'calc(100vh - 270px)',
							height: '40vh',
						},
					},
					'(min-width: 1200px)': {
						style: {
							// height: 'calc(100vh - 270px)',
							height: '40vh',
						},
					},
				},
				navigation: {
					style: {
						alignItems: 'flex-end',
					},
					queries: {
						'(max-width: 767.98px)': {
							style: {},
						},
						'(min-width: 768px)': {
							style: {},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
					button: {
						style: {
							bottom: '8px',
							top: 'auto',
						},
						queries: {
							'(max-width: 767.98px)': {
								style: {},
							},
							'(min-width: 768px)': {
								style: {},
							},
							'(min-width: 1200px)': {
								style: {},
							},
							'(max-width: 1230px)': {
								style: {
									bottom: '38px',
								},
							},
						},
						types: {
							left: {
								style: {},
								queries: {
									'(max-width: 767.98px)': {
										style: {},
									},
									'(min-width: 768px)': {
										style: {},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
							},
							right: {
								style: {
									marginRight: '14px',
								},
								queries: {
									'(max-width: 767.98px)': {
										style: {
											marginRight: '56px',
										},
									},
									'(min-width: 768px)': {
										style: {},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
								icon: {
									style: {
										/* transform: 'rotate(180deg)', */
									},
								},
							},
						},
					},
				},
				legends: {
					style: {
						margin: 0,
						padding: 0,
						alignSelf: 'center',
						flexShrink: '0',
						flexGrow: '1',
					},
				},
			},
		},
		features: {
			stickyNavigation: {
				header: {
					style: {},
					queries: {
						'(max-width: 767.98px)': {
							style: {},
						},
						'(min-width: 768px)': {
							style: {
								position: 'sticky',
								top: '80px',
								background: '#fff',
								zIndex: '3',
								padding: '8px 0',
							},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
				},
			},
			swedbankify: {
				style: {},
				controllers: {
					menu: {
						tags: {
							style: {},
							tag: {
								types: {
									defaultWithdraw: {
										style: {},
										prefix: {
											style: {
												backgroundColor:
													'rgba(146,74,121,1)',
											},
										},
									},
								},
							},
						},
					},
				},
				legends: {
					part: {
						style: {
							alignItems: 'center',
							marginRight: '12px',
							alignSelf: 'flex-start',
						},
						text: {
							style: {
								color: 'var(--text-color)',
								fontFamily: 'Swedbank Sans',
								fontWeight: 700,
							},
						},
						prefix: {
							style: {
								height: '12px',
								width: '12px',
								marginRight: '8px',
							},
						},
						types: {
							history: {
								prefix: {
									style: {
										backgroundImage: buildLegendIcon(
											'stroke: rgba(243,92,27,1);stroke-width: 2px;',
										),
										// 'url("data:image/svg+xml;utf8,<svg xmlns=\\"http://www.w3.org/2000/svg\\" width=\\"12\\" height=\\"12\\"><path d=\\"M 0 5 L 12 5\\" style=\\"stroke: rgba(243,92,27,1);stroke-width: 2px;\\"></path></svg>")',
									},
									queries: {
										'(max-height: 800px)': {
											style: {
												/* 												backgroundPosition: '-14px 1px',
												backgroundSize: '92%', */
											},
										},
									},
								},
							},
							forecast: {
								prefix: {
									style: {
										backgroundImage: buildLegendIcon(
											'stroke: rgba(243,92,27,1);stroke-width: 2px;stroke-dasharray: 4;',
										),
										// 'url("data:image/svg+xml;utf8,<svg xmlns=\\"http://www.w3.org/2000/svg\\" width=\\"12\\" height=\\"12\\"><path d=\\"M 0 5 L 12 5\\" style=\\"stroke: rgba(243,92,27,1);stroke-width: 2px;stroke-dasharray: 4;\\"></path></svg>")',
									},
									queries: {
										'(max-height: 800px)': {
											style: {
												/* 												backgroundPosition: '-14px 1px',
												backgroundSize: '92%', */
											},
										},
									},
								},
							},
							spread: {
								prefix: {
									style: {
										backgroundImage:
											'linear-gradient(141deg, #FFD7BE 25%, #FFB485 25%, #FFB485 50%, #FFD7BE 50%, #FFD7BE 75%, #FFB485 75%, #FFB485 100%)',
										backgroundSize: '6.36px 5.15px',
										borderRadius: '0',
										marginTop: '-1px',
									},
								},
							},
						},
					},
				},
				graph: {
					yaxis: {
						lines: {
							line: {
								style: {
									borderTop: '1px solid #E9D7CA',
								},
								types: {
									zero: {
										style: {
											borderTop: '2px solid #725251',
										},
									},
								},
							},
						},
					},
					graphArea: {
						linegroup: {
							line: {
								style: {
									stroke: '#ee7023',
									strokeWidth: 2,
								},
								types: {
									vertical: {
										style: {
											transform: 'translateY(-1px)',
											strokeWidth: 4,
										},
									},
									overdraft: {
										style: {
											stroke: '#C5131B',
										},
									},
								},
							},
							types: {
								history: {
									lineinfo: {
										dot: {
											style: {
												borderColor: '#ee7023',
											},
										},
									},
								},
								forecast: {
									style: {
										strokeWidth: 2,
									},
									lineinfo: {
										dot: {
											style: {
												borderColor: '#ee7023',
											},
										},
									},
								},
								today: {
									lineinfo: {
										dot: {
											style: {
												backgroundColor: '#ee7023',
												borderColor: '#ee7023',
											},
										},
									},
								},
							},
						},
						bargroup: {
							types: {
								today: {
									background: {
										style: {
											// background: 'red',
										},
									},
								},
								forecast: {
									background: {
										style: {},
									},
								},
							},
							bar: {
								types: {
									deposit: {
										base: {
											style: {
												backgroundColor:
													'rgba(43,153,164,1)',
											},
										},
									},
									withdraw: {
										base: {
											style: {
												backgroundColor:
													'rgba(146,74,121,1)',
											},
										},
									},
									paid: {
										base: {
											style: {
												backgroundColor:
													'rgba(146,74,121,1)',
											},
										},
									},
									supplier: {
										base: {
											style: {
												backgroundColor: '',
											},
										},
									},
								},
							},
						},
					},
					types: {
						hasFilter: {
							graphArea: {
								linegroup: {
									types: {
										today: {
											background: {
												style: {
													// background: 'red',
												},
											},
										},
										forecast: {
											background: {
												style: {
													// background: '#fbf2ea',
												},
											},
										},
									},
								},
								bargroup: {
									types: {
										today: {
											background: {
												style: {
													// background: 'red',
												},
											},
										},
										forecast: {
											background: {
												style: {
													// background: '#fbf2ea',
												},
											},
										},
									},
									bar: {
										types: {
											deposit: {
												base: {
													style: {
														backgroundColor:
															'rgb(214, 214, 214)',
													},
												},
											},
											withdraw: {
												base: {
													style: {
														backgroundColor:
															'rgb(183, 183, 183)',
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
				types: {
					hasFilter: {
						controllers: {
							menu: {
								tags: {
									style: {},
									tag: {
										types: {
											defaultWithdraw: {
												style: {},
												prefix: {
													style: {
														backgroundColor:
															'var(--system-withdraw-color)',
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
		},
		pie: {
			style: {
				width: '150px',
				height: '150px',
				margin: '0 auto',
			},
			segment: {
				types: {
					client: {
						value: {
							style: {
								backgroundColor: 'red',
							},
						},
					},
					forecast: {
						value: {
							style: {
								backgroundImage:
									'repeating-linear-gradient(135deg,transparent,transparent 1px,rgba(255,255,255,0.2) 2px,rgba(255,255,255,0.2) 4px,transparent 4.5px)',
							},
						},
					},
					unpaid: {
						value: {
							style: {
								backgroundColor: 'var(--system-unpaid-color)',
								backgroundImage:
									'repeating-linear-gradient(135deg, transparent, transparent 1px, rgba(0, 0, 0, 0.1) 2px, rgba(0, 0, 0, 0.1) 4px, transparent 4.5px)',
							},
						},
					},
				},
				/*
				'! hover': {
					style: {
						transform: `translate(0, -50%) rotate(90deg) rotate(calc(var(--pie-offset) * 1deg))`,
					},
				},
				*/
			},
		},
	},
};
