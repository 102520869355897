import React, { useState, useCallback, useContext, useEffect } from 'react';
import { TextV2, Title } from '@asteria/component-typography';
import { ButtonV2 } from '@asteria/component-button';
import {
	FormGroup,
	InputV2,
	LabelV2,
	RadioGroupV2,
} from '@asteria/component-form';
import Alert from '@asteria/component-alert';
import { Translation, TranslationService } from '@asteria/services-language';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { initiateIntegration } from '@asteria/services-datalayer/services/integrations/actions';
import { useStore } from '@asteria/utils';
import HelpStepOne from './helpStepOne';
import Layout from '../../layout';
import Context from '../../context';

const removeError = (error, errors) =>
	errors.filter(({ key }) => error.key !== key);

// PSU-IP-Address
// PSU-Corporate-ID

const Key = ({ navigator, callback = () => {} }) => {
	const { setStepIndex, setApiKey: setMethod, setActiveModal } = useContext(
		Context,
	);

	useEffect(() => {
		setStepIndex(2);
	}, [callback, setStepIndex]);

	const { dispatch } = useContext(DatalayerContext);
	const [banks = []] = useStore(
		'store-integrations',
		({ integrations = [] }) => integrations,
	);
	const [integrationId] = useStore(
		'store-integrations',
		({ integrationInProgress }) => integrationInProgress,
	);

	const [invalidKeyError, setInvalidKeyError] = useState(false);
	const [errors, setErrors] = useState([]);
	const [corporateId, setCorporateId] = useState('');
	const [userId, setUserId] = useState('');
	const [selectedSystem, selectSystem] = useState(0);

	useEffect(() => {
		if (integrationId) {
			const integration = banks.find(int => int.id === integrationId);
			if (integration) {
				setErrors([...errors, ...integration.config.errors]);
				dispatch(() => ({
					action: 'CONNECTION_CLEANUP',
				}));
			}
		}
	}, [dispatch, banks, errors, integrationId]);

	const nextStep = useCallback(() => {
		if (!corporateId || (selectedSystem === 0 && !userId)) {
			setInvalidKeyError(true);
		} else {
			setMethod(selectedSystem === 0 ? 'DECOUPLED' : 'REDIRECT');
			dispatch(
				initiateIntegration({
					integrationName: 'handelsbanken',
					type: 'bank',
					config: {
						client: {
							'PSU-Corporate-ID': corporateId
								.replace('-', '')
								.replace(' ', ''),
							'PSU-IP-Address': '92.34.106.88',
							method:
								selectedSystem === 0 ? 'DECOUPLED' : 'REDIRECT',
							userId:
								selectedSystem === 0
									? userId.replace('-', '').replace(' ', '')
									: undefined,
						},
					},
				}),
			);
			navigator.next(null, navigator);
		}
	}, [corporateId, dispatch, navigator, selectedSystem, setMethod, userId]);

	return (
		<Layout
			type="fortnox-key"
			header={
				<Title
					className="asteria-title-onboarding-header"
					size="title2"
				>
					{TranslationService.get(
						[
							'onboarding.connect.header',
							'onboarding.connect.bank.header',
						],
						'2. Anslut till bank',
					)}
				</Title>
			}
			body={
				<>
					{invalidKeyError ? (
						<Alert
							icon
							description={false}
							type="error"
							headingContent={
								<Translation
									translationKey="onboarding.handelsbanken.api_form.missing_key"
									defaultText="Var vänlig ange dina uppgifter innan du fortsätter."
								/>
							}
							onClose={() =>
								setErrors(
									removeError(
										{ key: 'validationError' },
										errors,
									),
								)
							}
						/>
					) : null}
					{errors && errors.length > 0 ? (
						<Alert
							icon
							description={false}
							type="error"
							headingContent={
								<Translation
									translationKey="onboarding.handelsbanken.connection.error"
									defaultText="Handelsbanken lyckades ej ansluta."
								/>
							}
							onClose={() =>
								setErrors(
									removeError(
										{ key: 'networkError' },
										errors,
									),
								)
							}
						/>
					) : null}
					<div
						className="asteria-dialog-box"
						style={{
							backgroundColor: '#fbf2ea',
							padding: '20px 20px 15px',
						}}
					>
						<Title size="title4">Handelsbanken</Title>
						<TextV2 size="small">
							{TranslationService.get(
								'onboarding.handelsbanken.api_form.title',
								'För att aktivera kopplingen behöver du godkänna Asteria AB via bankID.',
							)}
						</TextV2>

						<FormGroup>
							<LabelV2 path="form.label">
								{TranslationService.get(
									'onboarding.handelsbanken.api_form.corporateId.label',
									'Organisationsnummer',
								)}
							</LabelV2>
							<InputV2
								placeholder={TranslationService.get(
									'onboarding.handelsbanken.api_form.corporateId.placeholder',
									'123456-7890',
								)}
								onChange={e => {
									if (invalidKeyError) {
										// hide alert after user started typing again
										setInvalidKeyError(false);
									}
									setCorporateId(e.target.value);
								}}
								value={corporateId}
								invertedcolor
							/>
						</FormGroup>
						{selectedSystem === 0 && (
							<FormGroup>
								<LabelV2 path="form.label">
									{TranslationService.get(
										'onboarding.handelsbanken.api_form.userId.label',
										'Fyll i personnummer',
									)}
								</LabelV2>
								<InputV2
									placeholder={TranslationService.get(
										'onboarding.handelsbanken.api_form.userId.placeholder',
										'Fyll i organisationsnummer',
									)}
									onChange={e => {
										if (invalidKeyError) {
											// hide alert after user started typing again
											setInvalidKeyError(false);
										}
										setUserId(e.target.value);
									}}
									onBlur={e => {
										const value = e.target.value
											.replace('-', '')
											.replace(' ', '');
										const year = `${new Date().getFullYear()}`.substring(
											2,
											4,
										);

										if (value.length === 10) {
											if (value.substring(0, 2) < year) {
												// Fill with 00, otherwise 19
												setUserId(
													`20${e.target.value}`,
												);
											} else {
												setUserId(
													`19${e.target.value}`,
												);
											}
										}
									}}
									value={userId}
									invertedcolor
								/>
							</FormGroup>
						)}
						<FormGroup>
							<LabelV2 path="form.label">
								{TranslationService.get(
									'onboarding.handelsbanken.api_form.type.label',
									'Inloggningssätt',
								)}
							</LabelV2>
							<RadioGroupV2
								items={[
									TranslationService.get(
										'onboarding.handelsbanken.api_form.method_1',
										'Mobilt BankID eller SäkerhetsID',
									),
									TranslationService.get(
										'onboarding.handelsbanken.api_form.method_2',
										'Säkerhetsdosa',
									),
								]}
								selected={[selectedSystem]}
								direction="vertical"
								onChange={index => {
									selectSystem(index);
								}}
								style={{
									alignItems: 'flex-start',
									flexDirection: 'row',
									marginLeft: '0px',
									marginTop: '0px',
									marginBottom: '16px',
								}}
							/>
						</FormGroup>
					</div>
					<ButtonV2
						text={TranslationService.get(
							'onboarding.handelsbanken.api_form.help',
							'Hur aktiverar jag utökat mobilt bankID?',
						)}
						type="flat"
						icon="triangleRight"
						iconPosition="after"
						onClick={() =>
							setActiveModal(HelpStepOne.navigationKey)
						}
					/>
					<div
						className="asteria-wizard-inner-footer"
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							paddingTop: '20px',
						}}
					>
						<ButtonV2
							size="medium"
							type="default"
							onClick={() =>
								navigator.cancel(
									{
										event: 'cancel',
									},
									navigator,
								)
							}
							text={TranslationService.get(
								'onboarding.abort',
								'Avbryt',
							)}
						/>
						<div
							className="asteria--activation-key-controls"
							style={{
								display: 'flex',
								// minWidth: '220px',
								// justifyContent: 'space-between',
							}}
						>
							<ButtonV2
								size="medium"
								type="secondary"
								onClick={() =>
									navigator.back(
										{
											event: 'goto',
											step: {
												stepId: 'selectErp',
											},
										},
										navigator,
									)
								}
								text={TranslationService.get(
									'onboarding.back',
									'Tillbaka',
								)}
							/>
							<ButtonV2
								size="medium"
								type="primary"
								style={{ marginLeft: '16px' }}
								text={TranslationService.get(
									'onboarding.continue',
									'Fortsätt',
								)}
								onClick={() => nextStep()}
							/>
						</div>
					</div>
				</>
			}
			footer={<></>}
		/>
	);
};

Key.navigationKey = Symbol('AsteriaKey');

export default Key;
