const reducer = (state = {}, { action, payload }) => {
	switch (action) {
		case 'EDIT_TRANSACTION_FIELD': {
			const { id, field, value } = payload;

			if (value === null) {
				const newObj = {
					...(state.edits[id] || {}),
				};

				delete newObj[field];
				if (Object.keys(newObj).length > 0) {
					const newEdits = {
						...(state.edits || {}),
					};

					delete newEdits[id];

					return {
						...state,
						edits: {
							...newEdits,
						},
					};
				}

				return {
					...state,
					edits: {
						...(state.edits || {}),
						[id]: {
							...newObj,
						},
					},
				};
			}

			return {
				...state,
				edits: {
					...(state.edits || {}),
					[id]: {
						...(state.edits[id] || {}),
						[field]: value,
					},
				},
			};
		}
		case 'CLEAR_TRANSACTION_FIELD': {
			if (!payload) {
				return {
					...state,
					edits: {},
				};
			}

			const newEdits = {
				...(state?.edits || {}),
			};

			for (let i = 0; i < payload.length; i += 1) {
				delete newEdits[payload[i]];
			}

			return {
				...state,
				edits: newEdits,
			};
		}
		case 'ADD_TRANSACTION': {
			return {
				...state,
				transactions: {
					...state.transactions,
					adding: [...state.transactions.adding, payload],
				},
			};
		}
		case 'ADD_TRANSACTIONS': {
			return {
				...state,
				transactions: {
					...state.transactions,
					adding: payload,
				},
			};
		}
		case 'REMOVE_TRANSACTION': {
			return {
				...state,
				transactions: {
					...state.transactions,
					adding: state.transactions.adding.filter(
						t => t !== payload,
					),
					editing: state.transactions.editing.filter(
						t => t !== payload,
					),
				},
			};
		}
		case 'EDIT_TRANSACTIONS': {
			return {
				...state,
				transactions: {
					...state.transactions,
					editing: payload,
				},
			};
		}
		case 'SET_ADJUSTABLE_ONBOARDING_STEP': {
			return {
				...state,
				currentOnboardingStep: payload,
			};
		}
		case 'SET_ADJUSTABLE_ONBOARDING': {
			return {
				...state,
				onboarding: payload,
			};
		}
		case 'OPEN_REPEATABLE_MODAL': {
			return {
				...state,
				repeatable: payload,
			};
		}
		case 'CLOSE_REPEATABLE_MODAL': {
			return {
				...state,
				repeatable: null,
			};
		}
		default:
			return state;
	}
};

export default reducer;
