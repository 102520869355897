import React, { useContext, useCallback } from 'react';
import styled from 'styled-components';

import { useStore } from '@asteria/utils';
import debounce from '@asteria/utils/debounce';
import { TranslationService } from '@asteria/services-language';
import { setListStatus } from '@asteria/services-datalayer/services/list/actions';

import { FeatureFlag } from '@asteria/component-featureflag';
import Button from '@asteria/component-button';
import { Input } from '@asteria/component-form';

import { setFilters } from '@asteria/services-datalayer/services/appstate/actions';
import SearchContext from './context';
import ActiveFilters from './active';

const SearchInput = React.memo(
	styled(
		({
			isFocus,
			setFocus,
			isShowDrawer,
			setActiveCustomer,
			inputRef,
			onFocus,
			onChange,
			onKeyDown,
			search,
			hasFilter,
		}) => (
			<div className="asteria-search-area-wrapper">
				<FeatureFlag feature="asteria-improvements">
					<Button
						type="link"
						icon="search"
						size="medium"
						title={TranslationService.get('search.open', 'Sök')}
						data-tooltip={TranslationService.get(
							'search.open',
							'Sök',
						)}
						className={
							isFocus && !isShowDrawer
								? 'asteria-button-search asteria-search-active'
								: 'asteria-button-search'
						}
						onClick={() => {
							if (isFocus) {
								setFocus(false);
							} else {
								setFocus(true);
							}
						}}
					/>
					<div className="asteria-search-area-controls">
						<ActiveFilters setActiveCustomer={setActiveCustomer} />

						<Input
							type="text"
							placeholder={TranslationService.get(
								hasFilter
									? 'search.not.placeholder'
									: 'search.placeholder',
								'',
							)}
							ref={inputRef}
							onFocus={onFocus}
							onChange={onChange}
							onKeyDown={onKeyDown}
							value={search}
						/>
					</div>
				</FeatureFlag>

				<FeatureFlag feature="asteria-improvements" invert>
					<Input
						type="text"
						placeholder={TranslationService.get(
							hasFilter
								? 'search.not.placeholder'
								: 'search.placeholder',
							'',
						)}
						ref={inputRef}
						onFocus={onFocus}
						onChange={onChange}
						onKeyDown={onKeyDown}
						value={search}
					/>
				</FeatureFlag>
			</div>
		),
	)``,
);

SearchInput.displayName = 'SearchInput';

const SearchInputData = () => {
	const {
		dispatch,
		isFocus,
		setFocus,
		isShowDrawer,
		setActiveCustomer,
		activeCustomer,
		inputRef,
		setSearch,
		search,
		isAvailable,
		tags,
		setMatchedParts,
		listStatus,
		setListOpen,
		tagAction,
	} = useContext(SearchContext);

	const [hasFilter = false] = useStore(
		'store-appstate',
		({ filters: list }) =>
			(list || []).filter(({ type }) => type !== 'status').length > 0,
	);

	const onFocus = useCallback(() => setFocus(true), [setFocus]);
	const onUpdateValue = useCallback(
		debounce(val => {
			setSearch(val);
			setMatchedParts(val ? isAvailable(val) : []);
		}, 100),
		[isAvailable, setMatchedParts, setSearch],
	);
	const onChange = useCallback(e => onUpdateValue(e.target.value), [
		onUpdateValue,
	]);

	const onKeyDown = useCallback(
		({ keyCode }) => {
			if (search === '' && keyCode === 8) {
				if (tags.length > 0) {
					tagAction(tags[tags.length - 1]._id);
				} else if (listStatus && listStatus.length === 1) {
					dispatch(
						setListStatus([
							'PAID',
							'UNPAID',
							'OVERDUE',
							'FORECAST',
						]),
					);
				} else if (activeCustomer) {
					setActiveCustomer(false);
					dispatch(setListOpen(false));
				}

				setFocus(true);
			}

			if (keyCode === 27) {
				setFocus(false);
				setSearch('');
				if (inputRef && inputRef.current) {
					inputRef.current.blur();
				}
			}
		},
		[
			activeCustomer,
			dispatch,
			inputRef,
			listStatus,
			search,
			setActiveCustomer,
			setFocus,
			setListOpen,
			setSearch,
			tagAction,
			tags,
		],
	);

	return (
		<SearchInput
			isFocus={isFocus}
			setFocus={setFocus}
			isShowDrawer={isShowDrawer}
			setActiveCustomer={setActiveCustomer}
			inputRef={inputRef}
			onFocus={onFocus}
			onChange={onChange}
			onKeyDown={onKeyDown}
			search={search}
			hasFilter={hasFilter}
		/>
	);
};

SearchInputData.displayName = 'SearchInputData';

const MenuIcon = ({ isShowDrawer, setShowDrawer, setFocus }) => (
	<>
		<FeatureFlag feature="menu-level-notification">
			<div
				horizontal="left"
				vertical="top"
				className="asteria-badge"
				title={TranslationService.get('notifications.badge.new')}
			>
				9+
			</div>
		</FeatureFlag>
		<FeatureFlag feature="menu-category-icon-with-text">
			<Button
				type="link"
				icon={isShowDrawer ? 'close' : 'category'}
				size="medium"
				data-tooltip={TranslationService.get('search.menu.title')}
				title={TranslationService.get('search.menu.title')}
				text={TranslationService.get('search.menu.category.text')}
				className={
					isShowDrawer
						? 'asteria-button-navigation asteria-navigation-active'
						: 'asteria-button-navigation'
				}
				onClick={() => {
					setShowDrawer(!isShowDrawer);
					setFocus(false);
				}}
			/>
		</FeatureFlag>
		<FeatureFlag feature="menu-category-icon-only">
			<Button
				type="link"
				icon={isShowDrawer ? 'category' : 'category'}
				data-tooltip={TranslationService.get('search.menu.title')}
				size="medium"
				title={TranslationService.get('search.menu.title')}
				className={
					isShowDrawer
						? 'asteria-button-navigation asteria-navigation-active'
						: 'asteria-button-navigation'
				}
				onClick={() => {
					setShowDrawer(!isShowDrawer);
					setFocus(false);
				}}
			/>
		</FeatureFlag>
		<FeatureFlag feature="menu-app-icon-with-text">
			<Button
				type="link"
				icon={isShowDrawer ? 'close' : 'apps'}
				size="medium"
				data-tooltip={TranslationService.get('search.menu.title')}
				title={TranslationService.get('search.menu.app.title')}
				text={TranslationService.get('search.menu.app.text')}
				className={
					isShowDrawer
						? 'asteria-button-navigation asteria-navigation-active'
						: 'asteria-button-navigation'
				}
				onClick={() => {
					setShowDrawer(!isShowDrawer);
					setFocus(false);
				}}
			/>
		</FeatureFlag>
		<FeatureFlag feature="menu-app-icon-only">
			<Button
				type="link"
				icon={isShowDrawer ? 'close' : 'apps'}
				size="medium"
				data-tooltip={TranslationService.get('search.menu.title')}
				title={TranslationService.get('search.menu.app.title')}
				className={
					isShowDrawer
						? 'asteria-button-navigation asteria-navigation-active'
						: 'asteria-button-navigation'
				}
				onClick={() => {
					setShowDrawer(!isShowDrawer);
					setFocus(false);
				}}
			/>
		</FeatureFlag>
		<FeatureFlag feature="menu-default-icon">
			<Button
				type="link"
				icon={isShowDrawer ? 'close' : 'menu'}
				size="medium"
				title={TranslationService.get('search.menu.title')}
				data-tooltip={TranslationService.get('search.menu.title')}
				className={
					isShowDrawer
						? 'asteria-button-navigation asteria-navigation-active'
						: 'asteria-button-navigation'
				}
				onClick={() => {
					setShowDrawer(!isShowDrawer);
					setFocus(false);
				}}
			/>
		</FeatureFlag>
		<FeatureFlag feature="menu-default-icon-with-text">
			<Button
				type="link"
				icon={isShowDrawer ? 'close' : 'menu'}
				size="medium"
				data-tooltip={TranslationService.get('search.menu.title')}
				title={TranslationService.get('search.menu.title')}
				text={TranslationService.get('search.menu.text')}
				className={
					isShowDrawer
						? 'asteria-button-navigation asteria-navigation-active'
						: 'asteria-button-navigation'
				}
				onClick={() => {
					setShowDrawer(!isShowDrawer);
					setFocus(false);
				}}
			/>
		</FeatureFlag>
	</>
);

MenuIcon.displayName = 'MenuIcon';

const SearchArea = styled(
	({ dispatch, isFocus, setFocus, isShowDrawer, onHide, setShowDrawer }) => {
		const onReset = useCallback(() => {
			dispatch(setFilters([]));
			onHide();
		}, [dispatch, onHide]);
		return (
			<>
				<FeatureFlag feature="asteria-improvements">
					<div className="asteria-search-area">
						<SearchInputData />
						<Button
							type="link"
							size="small"
							icon="close"
							className="asteria-button-clear-search"
							data-tooltip={TranslationService.get(
								'search.reset.searchn',
								'Rensa',
							)}
							title={TranslationService.get(
								'search.reset.search',
								'Rensa',
							)}
							onClick={onReset}
						/>
					</div>
				</FeatureFlag>
				<FeatureFlag feature="asteria-improvements" invert>
					<div className="asteria-search-area">
						<SearchInputData />
						{/* To be used as Badge, and only on parent level.. */}
						<MenuIcon
							isShowDrawer={isShowDrawer}
							setShowDrawer={setShowDrawer}
							setFocus={setFocus}
						/>
						<Button
							type="link"
							icon="search"
							size="medium"
							title={TranslationService.get('search.open', 'Sök')}
							data-tooltip={TranslationService.get(
								'search.open',
								'Sök',
							)}
							className={
								isFocus && !isShowDrawer
									? 'asteria-button-search asteria-search-active'
									: 'asteria-button-search'
							}
							onClick={() => {
								if (isFocus) {
									setFocus(false);
								} else {
									setFocus(true);
								}
							}}
						/>
						<Button
							type="link"
							text="Rensa"
							size="small"
							className="asteria-button-clear-search"
							data-tooltip={TranslationService.get(
								'search.reset.searchn',
								'Rensa',
							)}
							title={TranslationService.get(
								'search.reset.search',
								'Rensa',
							)}
							onClick={() => {
								setFocus(false);
								setShowDrawer(false);
							}}
						/>
						{isFocus || isShowDrawer ? (
							<Button
								type="link"
								icon="close"
								size="small"
								data-tooltip={TranslationService.get(
									'search.close.search',
									'Stäng',
								)}
								className="asteria-button-close"
								title={TranslationService.get(
									'search.close.search',
									'Stäng',
								)}
								onClick={onHide}
							/>
						) : null}
					</div>
				</FeatureFlag>
			</>
		);
	},
)``;

SearchArea.displayName = 'SearchArea';

const SearchAreaData = () => {
	const {
		isFocus,
		setFocus,
		isShowDrawer,
		onHide,
		setShowDrawer,
		dispatch,
	} = useContext(SearchContext);

	return (
		<SearchArea
			isFocus={isFocus}
			setFocus={setFocus}
			isShowDrawer={isShowDrawer}
			setShowDrawer={setShowDrawer}
			onHide={onHide}
			dispatch={dispatch}
		/>
	);
};

SearchAreaData.displayName = 'SearchAreaData';

export default SearchAreaData;
