export default {
	'menu.financial.title': 'Finança',
	'menu.financial.factoring.title': 'Factoring',
	'menu.financial.currencyrisk.title': 'Risco de moeda',
	'menu.categories.title': 'Categorias',
	'menu.clients.title': 'Clientes',
	'menu.status.title': 'Status',
	'menu.currency.title': 'Moedas',

	'menu.financial.credit.title': 'Empréstimos de crédito',
};
