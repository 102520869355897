import Store from '../../core/store';
import logger from '../../logger';

@logger('language:store')
class LanguageStore extends Store {
	name = 'language';

	constructor(subject) {
		super(subject, {});
	}
}

export default LanguageStore;
