import { map, throttle } from 'rxjs/operators';
import { interval } from 'rxjs';
import {
	GET_NOTIFICATIONS,
	SUBSCRIBE_NOTIFICATION_CREATED,
	SUBSCRIBE_NOTIFICATION_UPDATED,
	SUBSCRIBE_NOTIFICATION_DELETED,
	MARK_AS_VIEWED,
	ANSWER_NOTIFICATION,
} from './queries';

export const markNotificationAsViewed = id => ({ lookup, dispatch }) => {
	dispatch(() => ({
		action: 'NOTIFICATION_VIEWED',
		payload: id,
	}));

	const apiService = lookup('service-api');

	apiService
		.query(MARK_AS_VIEWED, { id }, { reqAuth: true })
		.subscribe(() => {});
};

export const answerNotification = (id, answer) => ({ lookup, dispatch }) => {
	dispatch(() => ({
		action: 'NOTIFICATION_VIEWED',
		payload: id,
	}));

	const apiService = lookup('service-api');

	apiService
		.query(ANSWER_NOTIFICATION, { id, answer }, { reqAuth: true })
		.subscribe(() => {});
};

export const getNotifications = () => ({ lookup, dispatch }) => {
	const apiService = lookup('service-api');

	apiService
		.query(GET_NOTIFICATIONS, {}, { reqAuth: true })
		.pipe(map(resp => resp?.data?.notifications?.edges || []))
		.subscribe(data => {
			dispatch(() => ({
				action: 'SET_NOTIFICATIONS',
				payload: data.map(({ node }) => node),
			}));
		});
};

export const subscribeToNotifications = () => ({ dispatch, lookup }) => {
	const socket = lookup('service-websocket');

	socket
		.subscribe({
			query: SUBSCRIBE_NOTIFICATION_CREATED,
			operationName: 'NotificationCreate',
		})
		.pipe(throttle(() => interval(500), { leading: false, trailing: true }))
		.subscribe(() => {
			dispatch(getNotifications());
		});

	socket
		.subscribe({
			query: SUBSCRIBE_NOTIFICATION_UPDATED,
			operationName: 'NotificationUpdated',
		})
		.pipe(throttle(() => interval(500), { leading: false, trailing: true }))
		.subscribe(() => {
			dispatch(getNotifications());
		});

	socket
		.subscribe({
			query: SUBSCRIBE_NOTIFICATION_DELETED,
			operationName: 'NotificationDeleted',
		})
		.pipe(throttle(() => interval(500), { leading: false, trailing: true }))
		.subscribe(() => {
			dispatch(getNotifications());
		});
};
