import React, { useContext } from 'react';
import { Text } from '@asteria/component-typography';
import { ButtonV2 } from '@asteria/component-button';
import Modal, { ModalBody, ModalFooter } from '@asteria/component-modal';
import { Translation, TranslationService } from '@asteria/services-language';
import { GLOBAL } from '@asteria/env';
import Context from '../../context';
import helpKeys from './helpKeys';

const HelpStepOne = () => {
	const { setActiveModal } = useContext(Context);

	const loginUrl = TranslationService.get(
		'onboarding.visma.help.login.url',
		'',
	);
	const loginTitle = TranslationService.get(
		'onboarding.visma.help.login.title',
		'',
	);

	return (
		<Modal
			backdrop
			open
			onClose={() => setActiveModal('')}
			size="small"
			detach
			className="asteria-onboarding-guide-modal"
			title={TranslationService.get(
				'onboarding.visma.help.first.header',
				'Var hittar jag min API-nyckel',
			)}
		>
			<ModalBody className="modal-body">
				<div
					style={{
						width: '100%',
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
					}}
				>
					{loginUrl ? (
						<Text>
							<a
								href={loginUrl}
								target="_blank"
								rel="noopener noreferrer"
								style={{
									fontFamily: 'Swedbank Sans',
									fontSize: '16px',
									lineHeight: '26px',
									textDecoration: 'underline',
									marginBottom: '16px',
									color: 'rgba(37,120,134,1)',
									fontWeight: '700',
									display: 'block',
								}}
							>
								{loginTitle}
							</a>
						</Text>
					) : null}
					<Translation
						translationKey="onboarding.visma.help.first.text"
						defaultText="Klicka på “Appar och tillval” i menyn till vänster om startsidans innehåll."
						Component={Text}
					/>
					<div>
						<img
							style={{
								// width: '100%',
								width: '56%',
								display: 'flex',
								margin: '16px auto',
								// maxHeight: '350px',
								// paddingRight: '40px',
							}}
							src={`${
								GLOBAL.baseUrl
							}assets/asteria/images/integrations/visma/step01.gif`}
							alt=""
						/>
					</div>
				</div>
			</ModalBody>
			<ModalFooter className="modal-footer">
				<div
					className="asteria-onboarding-help-footer"
					style={{
						display: 'flex',
						width: '100%',
						justifyContent: 'space-between',
					}}
				>
					<ButtonV2
						size="medium"
						type="default"
						text={TranslationService.get(
							'onboarding.abort',
							'Avbryt',
						)}
						onClick={() => setActiveModal('')}
					/>
					<ButtonV2
						size="medium"
						type="primary"
						text={TranslationService.get(
							'onboarding.continue',
							'Fortsätt',
						)}
						onClick={() => setActiveModal(helpKeys.HelpStepTwo)}
					/>
				</div>
			</ModalFooter>
		</Modal>
	);
};

HelpStepOne.navigationKey = helpKeys.HelpStepOne;

export default HelpStepOne;
