import classNames from 'classnames';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { StyleProvider } from '@asteria/utils';
import Tooltip from '@asteria/component-tooltip';

const getterSecurityBarStyle = new StyleProvider('securityBar', {
	bar: ({ level }) => [
		{
			base: ['style'],
			selector: level,
		},
	],
	value: () => [
		{
			selector: 'value',
		},
	],
});

const getLevelStyle = (level, style) => {
	const levelStyle = style[level] || {};
	return levelStyle.style || levelStyle;
};

const Bars = styled.div`
	display: flex;
	flex: 1 0 auto;
	justify-content: center;
	position: relative;
	${props => props.style};
`;

const TapTrap = styled.div`
	position: absolute;
	left: -40%;
	top: -15%;
	right: -40%;
	bottom: -15%;
`;

const Bar = styled.div`
	margin-top: 1px;
	flex: 1;
	border-radius: 3px;
	${props =>
		getterSecurityBarStyle.getStyle(props.theme || {}, props, 'bar')};
	${props => getLevelStyle(props.level, props.styles || {})};
`;

class SecurityBar extends PureComponent {
	constructor(props) {
		super(props);

		this.state = { isHover: false };
		this.node = React.createRef();
	}

	renderBars(value, steps, styles = {}) {
		const bars = [];
		let level = 'low';
		const roundedValue = Math.min(1, Math.round(value * 100) / 100);
		const highStep = steps.findIndex(step => step > roundedValue);
		const topStep =
			Math.max(0, highStep !== -1 ? highStep : steps.length) - 1;

		if (topStep >= Math.floor(steps.length / 2)) {
			level = 'medium';
		}

		if (topStep === steps.length - 1) {
			level = 'high';
		}

		for (let i = steps.length - 1; i >= 0; i -= 1) {
			if (i <= topStep && roundedValue !== 0) {
				bars.push(
					<Bar level={level} styles={styles} key={`bar_${i}`} />,
				);
			} else {
				bars.push(
					<Bar level="empty" styles={styles} key={`bar_${i}`} />,
				);
			}
		}

		return bars;
	}

	render() {
		const {
			theme = {},
			onClick = () => {},
			className,
			value,
			steps = [0, 0.1875, 0.375, 0.5625, 0.75],
			styles,
			style = false,
			styler = false,
			wrapperStyle,
			tooltip = false,
		} = this.props;

		const { isHover } = this.state;

		const { securityBar: { value: themeValue = 1.0 } = {} } = theme;
		return (
			<Bars
				style={wrapperStyle || style}
				onClick={e => {
					e.stopPropagation();
					return onClick();
				}}
				className={classNames('asteria-security-bar', className)}
				onTouchEnd={() => this.setState({ isHover: !isHover })}
				onMouseEnter={() => this.setState({ isHover: true })}
				onMouseLeave={() => this.setState({ isHover: false })}
				ref={this.node}
			>
				<TapTrap className="asteria-tap-trap" />
				<Tooltip
					content={tooltip}
					open={isHover}
					hover
					isStatic
					targetEl={this.node}
				/>
				{this.renderBars(
					styler !== false ? themeValue : value,
					steps,
					styles,
				)}
			</Bars>
		);
	}
}

export default SecurityBar;

export const EmptySecurityBar = ({ wrapperStyle, className }) => (
	<Bars
		style={wrapperStyle}
		className={classNames('asteria-security-bar', className)}
	/>
);
