import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
// import { TranslationService } from '@asteria/services-language';
import { Getter } from '@asteria/utils';

import { format, isSaturday, isSunday, isToday, isPast } from 'date-fns';
import Button from '@asteria/component-button/button';

const TextWrapper = styled.div``;
const DateBox = styled(
	({ className, date, active = false, setActive, isOtherMonth = false }) => (
		<div
			className={classNames(className, `asteria-datepicker-date`, {
				'asteria-datepicker-date-today': isToday(date),
				'asteria-datepicker-date-past': !isToday(date) && isPast(date),
				'asteria-datepicker-date-other': isOtherMonth,
				'asteria-state-active': active,
				'asteria-datepicker-date-unavailable':
					isSaturday(date) || isSunday(date),
			})}
		>
			<TextWrapper>
				<Button
					type="link"
					size="medium"
					className="asteria-button-select-date"
					text={format(date, 'D')}
					onClick={e => {
						e.stopPropagation();
						setActive(date);
					}}
				/>
			</TextWrapper>
		</div>
	),
)``;

DateBox.Styler = {
	typePrefix: 'asteria-datepicker-date',
	children: [
		{
			component: TextWrapper,
			base: [Getter('wrapper')],
		},
		{
			component: Button,
			base: [Getter('button')],
		},
	],
};

export default DateBox;
