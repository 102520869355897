import React, { useMemo, useContext, useCallback } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Text } from '@asteria/component-core/typography';

import { useStore, formatNumber } from '@asteria/utils';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { TranslationService } from '@asteria/services-language';
import { clearGraphData } from '@asteria/services-datalayer/services/graph/actions';
import { setFilters } from '@asteria/services-datalayer/services/appstate/actions';
import { useFeature } from '@asteria/component-featureflag';
import { setListReverse } from '@asteria/services-datalayer/services/list/actions';
import Tag from './tag';

const StatsTag = styled(
	({
		className,
		name,
		type,
		tagType,
		statistics,
		onClick,
		active,
		showTotal,
		notifications = [],
	}) => {
		let parts = (statistics?.deposit?.parts || [])
			.map(p => ({ ...p, type: 'deposit' }))
			.concat(
				(statistics?.withdraw?.parts || []).map(p => ({
					...p,
					type: 'withdraw',
				})),
			);

		if (type) {
			parts = statistics?.[type]?.parts || [];
		}

		const tagNotifications = useMemo(
			() =>
				notifications.filter(
					({
						data: { tag: { name: notificationTag = '' } = {} } = {},
					}) => notificationTag === name,
				),
			[name, notifications],
		);

		const values = parts
			.filter(({ tag: { name: tagName } }) => tagName === name)
			.map(({ total, status, count, type: valueType = type }) => ({
				status,
				size:
					(Math.abs(total) /
						Math.abs(statistics?.[valueType]?.total || 0)) *
					100,
				value: total,
				count,
				notifications: tagNotifications.filter(
					({
						data: { tag: { status: notificationStatus } = {} } = {},
					}) => notificationStatus === status,
				),
			}));

		const badge = values.find(({ status }) => status === 'OVERDUE');
		let prefixText = `${
			values.reduce((acc, { size }) => acc + size, 0) > 0.5
				? Math.round(values.reduce((acc, { size }) => acc + size, 0))
				: '< 1'
		}%`;

		if (showTotal) {
			prefixText = formatNumber(
				parts
					.filter(({ tag: { name: tagName } }) => tagName === name)
					.reduce((acc, { total }) => acc + total, 0),
				false,
				false,
				false,
				{ thousand: false },
			);
		}

		return (
			<Tag
				className={classNames(className, {
					'asteria-state-active': active,
				})}
				tag={name}
				type={tagType}
				data={{ values, badge }}
				bar
				accordion
				onClick={onClick}
				translationKey="stats"
			>
				<Text className="asteria-text-tag-size">{prefixText}</Text>
			</Tag>
		);
	},
)``;

const NoTags = styled(({ className }) => (
	<div className={classNames(className)}>
		<Text className="asteria-empty-text">
			{TranslationService.get('drawer.categories.empty')}
		</Text>
	</div>
))`
	.asteria-empty-text {
		min-height: 40px;
		align-items: center;
		display: flex;
	}
`;

NoTags.displayName = 'NoTags';

NoTags.Styler = {
	children: [],
};

const StatsTags = styled(
	({
		className,
		type,
		statistics: { [type]: { parts } = {} } = {},
		statistics,
		notifications = [],
		active = true,
		onClick = () => {},
	} = {}) => {
		const { dispatch } = useContext(DatalayerContext);
		const hasListDateOrder = useFeature({ key: 'list-paymentdate-order' });
		const uniqueParts = useMemo(
			() => [
				...new Set(
					parts
						?.sort((a, b) => Math.abs(b.total) - Math.abs(a.total))
						?.map(({ tag: { name } }) => name) || [],
				),
			],
			[parts],
		);

		const [availableCategories = []] = useStore(
			'store-graph',
			state => state.availableCategories,
		);

		const availableTags = useMemo(
			() =>
				availableCategories.reduce(
					(acc, category) => acc.concat(category?.tags),
					[],
				),
			[availableCategories],
		);

		const [filters = []] = useStore(
			'store-appstate',
			({ filters: list }) => list,
		);

		const tagClick = useCallback(
			(name, status) => {
				if (name) {
					const match = availableTags.find(t => t.name === name);
					const activeFilters = [];

					if (match) {
						activeFilters.push({
							id: match._id,
							type: 'tag',
							config: {
								name: match.name,
							},
						});
					}

					if (status) {
						activeFilters.push({
							id: `status-${status}`,
							type: 'status',
							config: {
								name: `$${status.toLowerCase()}`,
								status,
							},
						});
					}

					dispatch(setFilters(activeFilters));
					dispatch(clearGraphData('cashflow-bar-graph', true));
					if (status === 'FORECAST' && hasListDateOrder) {
						if (hasListDateOrder) {
							dispatch(setListReverse(true));
						}
					} else if (hasListDateOrder) {
						dispatch(setListReverse(false));
					}
				}

				onClick();
			},
			[availableTags, dispatch, hasListDateOrder, onClick],
		);

		return (
			<div className={classNames(className)}>
				{uniqueParts.map(name => (
					<StatsTag
						name={name}
						statistics={statistics}
						type={type}
						onClick={tagClick}
						active={
							active &&
							filters.find(
								({ config: { name: tagName } }) =>
									tagName === name,
							)
						}
						notifications={notifications}
						showTotal
					/>
				))}
				{uniqueParts.length === 0 ? <NoTags /> : null}
			</div>
		);
	},
)`
	${StatsTag} {
		padding: 4px 0px;

		&.asteria-tag-type-status {
			padding-right: 24px;
		}
	}
`;

StatsTags.displayName = 'StatsTags';

StatsTags.Styler = {
	children: [],
};
export default StatsTags;
export { StatsTag };
