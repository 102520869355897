export default {
	tabs: {
		style: {
			display: 'flex',
			flexDirection: 'column',
		},
		title: {
			style: {
				display: 'none !important',
			},
		},
		navigation: {
			style: {
				display: 'flex',
				flexDirection: 'row',
				boxSizing: 'border-box',
				width: 'auto',
				flex: '0',
				position: 'relative',
				borderRadius: '3px',
				/* 				overflow: 'hidden', */
				height: 'auto',
				padding: '0 0 7px',
				// margin: '24px 0 16px 0',
				margin: '0',
			},
			button: {
				style: {
					justifyContent: 'center',
					alignContent: 'center',
					alignItems: 'center',
					maxWidth: '100%',
					background: '#D3C3BC',
					paddingTop: '8px',
					paddingBottom: '8px',
					borderRight: '1px solid #fff',
					borderRadius: '50px',
					display: 'flex',
					flex: '1',
					height: 'auto',
					maxHeight: '32px',
					marginRight: '8px',
				},
				text: {
					style: {
						color: '#4C2D2C',
						fontSize: '14px',
						fontFamily: 'Swedbank Sans',
						fontWeight: 700,
						cursor: 'pointer',
					},
				},
				after: {
					style: {
						content: '""',
						borderTop: '7px solid transparent',
						borderRight: '7px solid transparent',
						borderLeft: '7px solid transparent',
						position: 'absolute',
						bottom: '-7px',
						display: 'none',
					},
				},
				states: {
					active: {
						style: {
							background: '#fc0',
						},
						after: {
							style: {
								content: '""',
								borderTop: '7px solid #fc0',
								borderRight: '7px solid transparent',
								borderLeft: '7px solid transparent',
								display: 'none',
							},
						},
					},
				},
			},
		},
	},
};
