const reducer = (state = {}, { action, payload }) => {
	switch (action) {
		case 'SET_NOTIFICATIONS':
			return {
				...state,
				notifications: payload,
			};
		case 'NOTIFICATION_VIEWED':
			return {
				...state,
				notifications: state.notifications.filter(
					({ id }) => id !== payload,
				),
			};
		default:
			return state;
	}
};

export default reducer;
