import React, {
	useContext,
	useEffect,
	useState,
	useRef,
	useCallback,
} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { compileStyles, Getter, useStore } from '@asteria/utils';
import Title from '@asteria/component-typography/title';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { setGraphSize } from '@asteria/services-datalayer/services/appstate/actions';
import useHasScrolledPassed from '@asteria/utils/hooks/useHasScrolledPassed';
import { useFeature, FeatureFlag } from '@asteria/component-featureflag';
import { saveUser } from '@asteria/services-datalayer/services/auth/actions';

import { TranslationService } from '@asteria/services-language';
import Button from '@asteria/component-button';
import Tabs, {
	TabsContent,
	TabsNavigation,
} from '@asteria/component-form/tabs';
import Search from '@asteria/component-search';
import getLayoutSize from '@asteria/utils/getLayoutSize';
import useResize from '@asteria/utils/hooks/useResize';
import { requestGraphData } from '@asteria/services-datalayer/services/graph/actions';
import Navigation from '../navigation';
import Menu from '../controllers/menu';

import Graph from '../graphs/graph';
import Legends from '../components/legends';
import TimesliceSelector from '../components/time';
import AppMenu from '../components/appmenu';

function getViewport() {
	let viewPortWidth;
	let viewPortHeight;

	// the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
	if (typeof window.innerWidth !== 'undefined') {
		viewPortWidth = window.innerWidth;
		viewPortHeight = window.innerHeight;
	}

	// IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
	else if (
		typeof document.documentElement !== 'undefined' &&
		typeof document.documentElement.clientWidth !== 'undefined' &&
		document.documentElement.clientWidth !== 0
	) {
		viewPortWidth = document.documentElement.clientWidth;
		viewPortHeight = document.documentElement.clientHeight;
	}

	// older versions of IE
	else {
		viewPortWidth = document.getElementsByTagName('body')[0].clientWidth;
		viewPortHeight = document.getElementsByTagName('body')[0].clientHeight;
	}
	return [viewPortWidth, viewPortHeight];
}

const CashflowHeader = styled.div`
	display: flex;
`;
CashflowHeader.displayName = 'CashflowHeader';
CashflowHeader.Styler = {
	children: [
		{
			component: TimesliceSelector,
			base: [Getter('timesliceselector')],
		},
		{
			component: Title,
			base: [Getter('title')],
		},
		{
			component: AppMenu,
			base: [Getter('menu')],
		},
	],
};

const DefaultGraphs = {
	default: 'bars',
	bar: 'bars',
	account: 'account',
	badge: 'bars',
	'insight-unpaid': 'bars',
	'insight-overdue': 'bars',
};

const GroupedLayout = React.forwardRef(
	(
		{
			graph = {},
			className,
			updateSize,
			next,
			prev,
			onClick,
			onMouseEnter,
			onMouseLeave,
			getTooltip,
			currentTimesliceSize,
			settings: {
				showIn: showDeposit,
				showOut: showWithdraw,
				showBalance: showAccountBalance,
				showBalanceNumbers: showAccountBalanceNumbers,
				layout = {},
			} = {},
		},
		ref,
	) => {
		const { dispatch } = useContext(DatalayerContext);
		const hasSpreadFeature = useFeature({ key: 'legend-spread' });
		const resizer = useRef(null);
		const [hideRevenueGraph, setHideRevenueGraph] = useState(false);
		const [listOpenSource = false] = useStore(
			'store-appstate',
			({ listOpenSource: source }) => source,
		);

		const [filters = []] = useStore(
			'store-appstate',
			({ filters: list }) => list,
		);

		const [availableCategories = []] = useStore(
			'store-graph',
			state => state.availableCategories,
		);

		const [hasCredit] = useStore(
			'store-graph',
			({ hasCredit: bool = false }) => bool,
		);

		const [mode] = useStore('store-appstate', ({ mode: val }) => val);

		const [allwaysShowAccountBalance, setShowAccountBalance] = useState(
			false,
		);

		const hasToggleRevenue = useFeature({ key: 'toggle-revenue' });

		useEffect(() => {
			if (mode === 'credit' && hasToggleRevenue) {
				setHideRevenueGraph(true);
			} else {
				setHideRevenueGraph(false);
			}
		}, [mode, hasToggleRevenue]);

		const defaultActiveTabs =
			DefaultGraphs[listOpenSource] || DefaultGraphs.default;

		useFeature({ key: 'swedbankify' });
		const hideAccountBalanceAxis = useFeature({
			key: 'graph-account-balance-without-xaxis',
		});
		useFeature({ key: 'theme-plana' });
		useFeature({ key: 'sticky-navigation' });
		useFeature({ key: 'sidepane-no-content-push' });
		useFeature({ key: 'workareas' });
		useFeature({ key: 'divided' });
		useFeature({ key: 'swap' });
		useFeature({ key: 'asteria' });
		useFeature({ key: 'category-name-without-status' });
		useFeature({ key: 'status-icon-forecast' });
		useFeature({ key: 'status-icon-paid' });
		useFeature({ key: 'status-icon-cash' });
		useFeature({ key: 'status-icon-card' });
		useFeature({ key: 'status-icon-customer' });
		useFeature({ key: 'status-icon-supplier' });
		useFeature({ key: 'status-icon-tax' });
		useFeature({ key: 'status-icon-salary' });
		useFeature({ key: 'status-icon-transfer' });
		useFeature({ key: 'show-data-tooltip' });
		useFeature({ key: 'promotion-swedbankify' });
		useFeature({ key: 'status-icon-misc' });
		useFeature({ key: 'workareas' });
		useFeature({ key: 'status-icon-swish' });
		useFeature({ key: 'status-icon-plusgiro' });
		useFeature({ key: 'status-icon-bankgiro' });
		useFeature({ key: 'transcation-row-clickable' });
		useFeature({ key: 'layout-resizable' });
		useFeature({ key: 'show-larger-company-title' });
		useFeature({ key: 'layout-workareas-latest' });
		useFeature({ key: 'adjustable-inline-swedbank-style' });

		const hasFxModule = useFeature({
			key: 'fx-module',
		});

		const isLayoutSmallTabbed = useFeature({
			key: 'layout-tabbed-small',
		});

		const isLayoutResizable = useFeature({
			key: 'layout-resizable',
		});

		const hasPassed = useHasScrolledPassed(ref, -200);

		const hasGraphTabs = useFeature({ key: 'graph-tabs' });

		let { graph: { layout: graphLayout = 'flat' } = {} } = layout || {};

		if (!hasGraphTabs) {
			graphLayout = 'flat';
		}

		const hasRisk = hasFxModule;

		const hideAccountOnSearch =
			useFeature({
				key: 'hide-account-on-search',
			}) &&
			layout?.graph?.showAccountOnSearch === false &&
			graphLayout === 'flat';

		const hideAccountOnSearchButtonShow = useFeature({
			key: 'hide-account-on-search-button-show',
		});

		const [features = {}] = useStore(
			'store-features',
			store => store?.active,
		);

		const featureClasses = Object.entries(features)
			.filter(f => f)
			.map(
				([key, config]) =>
					`asteria-feature-${key} asteria-feature-${key}-${config?.version ||
						1}`,
			);

		const legends = [
			{
				key: 'history',
				label: TranslationService.get('graph.account.legend.history'),
			},
			{
				key: 'forecast',
				label: TranslationService.get('graph.account.legend.forecast'),
			},
		];

		if (hasSpreadFeature) {
			legends.push({
				key: 'spread',
				label: TranslationService.get('graph.account.legend.spread'),
			});
		}

		if (hasCredit) {
			legends.push({
				key: 'credit',
				label: TranslationService.get('graph.account.legend.credit'),
			});
		}

		if (
			hasRisk &&
			(layout?.graph?.showRisk ||
				layout?.graph?.showRisk === null ||
				layout?.graph?.showRisk === undefined)
		) {
			legends.push({
				key: 'risk',
				label: TranslationService.get('graph.account.legend.risk'),
			});
		}

		const typeFilters = filters.filter(({ type }) => type === 'type');
		const tagFilters = filters.filter(({ type }) => type !== 'status');
		const filterDeposit = !(
			typeFilters.length === 0 ||
			typeFilters.find(({ config: { type } }) => type === 'DEPOSIT')
		);

		const filterWithdraw = !(
			typeFilters.length === 0 ||
			typeFilters.find(({ config: { type } }) => type === 'WITHDRAW')
		);

		useEffect(() => {
			dispatch(
				requestGraphData('cashflow-bar-graph', {
					filters,
				}),
			);

			if (filters.filter(({ type }) => type !== 'type').length === 0) {
				setShowAccountBalance(false);
			}
		}, [dispatch, filters]);

		// This is resizzable

		const updater = useRef(null);

		const updateGraphSize = useCallback(
			entry => {
				dispatch(
					setGraphSize({
						width: entry.contentRect.width,
						height: entry.contentRect.height,
					}),
				);

				if (updater.current) {
					clearTimeout(updater.current);
				}

				updater.current = setTimeout(() => {
					const [, height] = getViewport();
					dispatch(
						saveUser({
							settings: {
								layout: {
									size: {
										[height]: {
											width: entry.contentRect.width,
											height: entry.contentRect.height,
										},
									},
								},
							},
						}),
					);
				}, 5000);
			},
			[dispatch, updater],
		);

		let [, height] = useResize(resizer.current, updateGraphSize);
		const [, viewPortHeight] = getViewport();

		if (height === null) {
			height = layout?.size?.[viewPortHeight]?.height || null;
		}

		const layoutSize = getLayoutSize(height);

		if (layoutSize === 'small' && isLayoutSmallTabbed) {
			graphLayout = 'tabs';
		}

		return (
			<div
				className={classNames('asteria-resizer')}
				style={isLayoutResizable ? { height: `${height}px` } : {}}
			>
				{isLayoutResizable && (
					<div className="asteria-resize-handle" ref={resizer}>
						<Button
							className="asteria-resize-handle--handle"
							title={TranslationService.get(
								'layout.resizable.handle',
								'Genom att klicka och dra kan man justera storleken i höjden på grafen efter vad som ger bäst översikt för dig',
							)}
							onClick={() => {}}
						/>
					</div>
				)}
				<div
					className={classNames(
						className,
						featureClasses,
						'asteria-cashflow-graph',
						'asteria-cashflow-graph-grouped',
						`asteria-cashflow-graph-layout-${graphLayout}`,
						`asteria-state-${layoutSize}`,
						{
							'asteria-has-scrolled-passed': hasPassed,
							'asteria-cashflow-graph-has-filter':
								filters.filter(({ type }) => type === 'tag')
									.length > 0,
							'asteria-cashflow-graph-has-no-filter':
								filters.filter(({ type }) => type === 'tag')
									.length === 0,
							'asteria-cashflow-graph-hide-despoit':
								filterDeposit || !showDeposit,
							'asteria-cashflow-graph-hide-withdraw':
								filterWithdraw || !showWithdraw,
							'asteria-cashflow-graph-hide-account-balance':
								(hideAccountOnSearch &&
									tagFilters.length > 0 &&
									!allwaysShowAccountBalance) ||
								!showAccountBalance,
							'asteria-cashflow-graph-hide-revenue': hideRevenueGraph,
							'asteria-cashflow-graph-hide-account-balance-numbers': !showAccountBalanceNumbers,
							'asteria-cashflow-graph-hide-risk': !(
								layout?.graph?.showRisk ||
								layout?.graph?.showRisk === null ||
								layout?.graph?.showRisk === undefined
							),
						},
					)}
					ref={ref}
				>
					<Navigation
						className="asteria-cashflow-graph-time-navigation"
						onNext={next}
						onPrev={prev}
					/>
					{graphLayout === 'flat' ? (
						<>
							<FeatureFlag feature="asteria-improvements">
								{layout?.graph?.showAccountOnSearch === false &&
								tagFilters.length > 0 ? (
									<Button
										className="asteria-button-graph-toggle-accountbalance"
										type="link"
										onClick={() =>
											setShowAccountBalance(
												!allwaysShowAccountBalance,
											)
										}
									>
										{TranslationService.get(
											allwaysShowAccountBalance
												? 'graph.cashflow.hide_account'
												: 'graph.cashflow.show_account',
											allwaysShowAccountBalance
												? 'Göm min kontobalans'
												: 'Visa min kontobalans',
										)}
									</Button>
								) : null}
								<FeatureFlag feature="toggle-graph-revenue-button">
									{mode === 'credit' && (
										<Button
											className="asteria-button-graph-toggle-accountbalance"
											type="link"
											onClick={() =>
												setHideRevenueGraph(
													!hideRevenueGraph,
												)
											}
										>
											{TranslationService.get(
												!hideRevenueGraph
													? 'graph.cashflow.hide_revenue'
													: 'graph.cashflow.show_revenue',
												!hideRevenueGraph
													? 'Göm in-/utbetalningar'
													: 'Visa in-/utbetalningar',
											)}
										</Button>
									)}
								</FeatureFlag>
							</FeatureFlag>
							<FeatureFlag feature="asteria-improvements" invert>
								{hideAccountOnSearch &&
								hideAccountOnSearchButtonShow &&
								tagFilters.length > 0 ? (
									<Button
										className="asteria-button-graph-toggle-accountbalance"
										type="link"
										onClick={() =>
											setShowAccountBalance(
												!allwaysShowAccountBalance,
											)
										}
									>
										{TranslationService.get(
											allwaysShowAccountBalance
												? 'graph.cashflow.hide_account'
												: 'graph.cashflow.show_account',
											allwaysShowAccountBalance
												? 'Göm min kontobalans'
												: 'Visa min kontobalans',
										)}
									</Button>
								) : null}
								<Title
									key="cashflow-title"
									size="title2"
									className="asteria-graph-line-graph-component"
								>
									{TranslationService.get(
										'graph.account.title',
									)}
								</Title>
							</FeatureFlag>
							{/* <FeatureFlag feature="asteria-improvements" invert> */}
							<Legends
								key="cashflow-legends"
								className="asteria-graph-line-graph-component asteria-graph-line-graph-legends"
								legends={legends}
								scroll
							/>
							{/* </FeatureFlag> */}
							<Graph
								key="cashflow-graph"
								className="asteria-graph-line-graph-component"
								parts={['lines']}
								graph={graph}
								updateSize={() => {}}
								next={next}
								prev={prev}
								onClick={onClick}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
								getTooltip={getTooltip}
								id={graph.id}
								filters={filters}
								availableCategories={availableCategories}
								size={currentTimesliceSize}
								options={{ showXAxis: !hideAccountBalanceAxis }}
								barLayout="grouped"
							/>
							{/* <FeatureFlag feature="asteria-improvements">
							<Legends
								key="cashflow-legends"
								className="asteria-graph-line-graph-component asteria-graph-line-graph-legends"
								legends={legends}
								scroll
							/>
						</FeatureFlag> */}
							<FeatureFlag feature="asteria-improvements" invert>
								<Title
									size="title2"
									key="cashflow-title2"
									className="asteria-title-bars"
								>
									{TranslationService.get(
										'graph.cashflow.title',
									)}
								</Title>
							</FeatureFlag>
							{/* <FeatureFlag feature="asteria-improvements" invert> */}
							<Menu
								id="cashflow-bar-graph"
								className="asteria-graph-revenue-graph-controlers"
							/>
							{/* </FeatureFlag> */}
							<Graph
								key="cashflow-graph-2"
								className="asteria-graph-revenue-graph"
								parts={['bars']}
								graph={graph}
								updateSize={updateSize}
								next={next}
								prev={prev}
								onClick={onClick}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
								getTooltip={getTooltip}
								id={graph.id}
								filters={filters}
								availableCategories={availableCategories}
								size={currentTimesliceSize}
								barLayout="grouped"
							/>
							{/* <FeatureFlag feature="asteria-improvements">
							<Menu id="cashflow-bar-graph" />
						</FeatureFlag> */}
							{/* <FeatureFlag feature="asteria-improvements">
								<TimesliceSelector />
							</FeatureFlag> */}
						</>
					) : null}

					{graphLayout === 'tabs' ? (
						<Tabs active={defaultActiveTabs} key="tabs">
							<FeatureFlag feature="asteria-improvements" invert>
								<FeatureFlag feature="timeSpanDropdown">
									<TimesliceSelector />
								</FeatureFlag>
							</FeatureFlag>
							<TabsNavigation key="navigation">
								<FeatureFlag
									feature="tabsAsIcons"
									tab="account"
									invert
								>
									<Button type="primary">
										{TranslationService.get(
											'graph.tab.accountbalance',
											'Kontobalans',
										)}
									</Button>
								</FeatureFlag>
								<FeatureFlag
									feature="tabsAsIcons"
									tab="account"
								>
									<Button
										type="primary"
										size="medium"
										icon="accountBalance"
										data-tooltip="Visa kontobalans"
									/>
								</FeatureFlag>
								<FeatureFlag
									feature="tabsAsIcons"
									tab="bars"
									invert
								>
									<Button type="primary">
										{TranslationService.get(
											'graph.tab.revenueexpenses',
											'In-/utbetalningar',
										)}
									</Button>
								</FeatureFlag>
								<FeatureFlag feature="tabsAsIcons" tab="bars">
									<Button
										type="primary"
										size="medium"
										icon="transactions"
										data-tooltip="Visa in-/utbetalningar"
									/>
								</FeatureFlag>
								{/* 							<Button type="primary" tab={['account', 'bars']}>
								Allt
								--- visa allt är en bra tab men behöver ej synas nu
							</Button> */}
							</TabsNavigation>
							<TabsContent key="content">
								<div
									tab="account"
									className="asteria-tab-account"
								>
									<Title
										size="title2"
										className="asteria-graph-line-graph-component"
									>
										{TranslationService.get(
											'graph.account.title',
										)}
									</Title>
									<Legends
										className="asteria-graph-line-graph-component"
										legends={legends}
									/>
									<Graph
										className="asteria-graph-line-graph-component"
										parts={['lines']}
										graph={graph}
										updateSize={updateSize}
										next={next}
										prev={prev}
										onClick={onClick}
										onMouseEnter={onMouseEnter}
										onMouseLeave={onMouseLeave}
										getTooltip={getTooltip}
										id={graph.id}
										filters={filters}
										availableCategories={
											availableCategories
										}
										size={currentTimesliceSize}
										barLayout="grouped"
									/>
								</div>
								<div tab="bars" className="asteria-tab-bars">
									<Title size="title2">
										{TranslationService.get(
											'graph.cashflow.title',
										)}
									</Title>
									<Menu id="cashflow-bar-graph" />
									<Graph
										parts={['bars']}
										graph={graph}
										updateSize={updateSize}
										next={next}
										prev={prev}
										onClick={onClick}
										onMouseEnter={onMouseEnter}
										onMouseLeave={onMouseLeave}
										getTooltip={getTooltip}
										id={graph.id}
										filters={filters}
										availableCategories={
											availableCategories
										}
										size={currentTimesliceSize}
										barLayout="grouped"
									/>
								</div>
							</TabsContent>
						</Tabs>
					) : null}
				</div>
			</div>
		);
	},
);
GroupedLayout.displayName = 'GroupedLayout';

const StackedLayout = React.forwardRef(
	(
		{
			graph = {},
			className,
			next,
			prev,
			onClick,
			onMouseEnter,
			onMouseLeave,
			getTooltip,
			currentTimesliceSize,
			updateSize,
			settings: {
				showIn: showDeposit,
				showOut: showWithdraw,
				showBalance: showAccountBalance,
				showBalanceNumbers: showAccountBalanceNumbers,
				layout = {},
			} = {},
		},
		ref,
	) => {
		const { dispatch } = useContext(DatalayerContext);
		const resizer = useRef(null);
		const hasSpreadFeature = useFeature({ key: 'legend-spread' });

		const [filters = []] = useStore(
			'store-appstate',
			({ filters: list }) => list,
		);

		const [hasCredit] = useStore(
			'store-graph',
			({ hasCredit: bool = false }) => bool,
		);

		const [allwaysShowAccountBalance, setShowAccountBalance] = useState(
			false,
		);

		const [availableCategories = []] = useStore(
			'store-graph',
			state => state.availableCategories,
		);

		const hideAccountBalanceAxis = useFeature({
			key: 'graph-account-balance-without-xaxis',
		});

		const hasPassed = useHasScrolledPassed(ref, -200);

		useFeature({ key: 'swedbankify' });
		useFeature({ key: 'theme-plana' });
		useFeature({ key: 'sticky-navigation' });
		useFeature({ key: 'workareas' });
		useFeature({ key: 'divided' });
		useFeature({ key: 'swap' });
		useFeature({ key: 'asteria' });
		useFeature({ key: 'category-name-without-status' });
		useFeature({ key: 'status-icon-forecast' });
		useFeature({ key: 'status-icon-paid' });
		useFeature({ key: 'status-icon-cash' });
		useFeature({ key: 'status-icon-card' });
		useFeature({ key: 'status-icon-customer' });
		useFeature({ key: 'status-icon-supplier' });
		useFeature({ key: 'status-icon-tax' });
		useFeature({ key: 'status-icon-salary' });
		useFeature({ key: 'show-data-tooltip' });
		useFeature({ key: 'status-icon-transfer' });
		useFeature({ key: 'status-icon-misc' });
		useFeature({ key: 'status-icon-swish' });
		useFeature({ key: 'status-icon-plusgiro' });
		useFeature({ key: 'status-icon-bankgiro' });
		useFeature({ key: 'transcation-row-clickable' });

		const isLayoutResizable = useFeature({
			key: 'layout-resizable',
		});

		const hideAccountOnSearch =
			useFeature({
				key: 'hide-account-on-search',
			}) && layout?.graph?.showAccountOnSearch === false;

		const [features = {}] = useStore(
			'store-features',
			store => store?.active,
		);

		const featureClasses = Object.entries(features)
			.filter(f => f)
			.map(
				([key, config]) =>
					`asteria-feature-${key} asteria-feature-${key}-${config?.version ||
						1}`,
			);

		const legends = [
			{
				key: 'history',
				label: TranslationService.get('graph.account.legend.history'),
			},
			{
				key: 'forecast',
				label: TranslationService.get('graph.account.legend.forecast'),
			},
		];

		if (hasCredit) {
			legends.push({
				key: 'credit',
				label: TranslationService.get('graph.account.legend.credit'),
			});
		}

		if (hasSpreadFeature) {
			legends.push({
				key: 'spread',
				label: TranslationService.get('graph.account.legend.spread'),
			});
		}

		const typeFilters = filters.filter(({ type }) => type === 'type');
		const tagFilters = filters.filter(({ type }) => type !== 'status');
		const filterDeposit = !(
			typeFilters.length === 0 ||
			typeFilters.find(({ config: { type } }) => type === 'DEPOSIT')
		);

		const filterWithdraw = !(
			typeFilters.length === 0 ||
			typeFilters.find(({ config: { type } }) => type === 'WITHDRAW')
		);

		useEffect(() => {
			dispatch(
				requestGraphData('cashflow-bar-graph', {
					filters,
				}),
			);

			if (filters.filter(({ type }) => type !== 'type').length === 0) {
				setShowAccountBalance(false);
			}
		}, [dispatch, filters]);

		const updater = useRef(null);

		const updateGraphSize = useCallback(
			entry => {
				dispatch(
					setGraphSize({
						width: entry.contentRect.width,
						height: entry.contentRect.height,
					}),
				);

				if (updater.current) {
					clearTimeout(updater.current);
				}

				updater.current = setTimeout(() => {
					const [, height] = getViewport();
					dispatch(
						saveUser({
							settings: {
								layout: {
									size: {
										[height]: {
											width: entry.contentRect.width,
											height: entry.contentRect.height,
										},
									},
								},
							},
						}),
					);
				}, 5000);
			},
			[dispatch, updater],
		);

		let [, height] = useResize(resizer.current, updateGraphSize);
		const [, viewPortHeight] = getViewport();

		if (height === null) {
			height = layout?.size?.[viewPortHeight]?.height || null;
		}

		const layoutSize = getLayoutSize(height);

		return (
			<div
				className={classNames('asteria-resizer')}
				style={isLayoutResizable ? { height: `${height}px` } : {}}
			>
				{isLayoutResizable && (
					<div className="asteria-resize-handle" ref={resizer}>
						<Button
							className="asteria-resize-handle--handle"
							title={TranslationService.get(
								'layout.resizable.handle',
								'Genom att klicka och dra kan man justera storleken i höjden på grafen efter vad som ger bäst översikt för dig',
							)}
							onClick={() => {}}
						/>
					</div>
				)}
				<div
					className={classNames(
						className,
						featureClasses,
						'asteria-cashflow-graph',
						'asteria-cashflow-graph-stacked',
						`asteria-state-${layoutSize}`,
						{
							'asteria-has-scrolled-passed': hasPassed,
							'asteria-cashflow-graph-has-filter':
								filters.filter(({ type }) => type === 'tag')
									.length > 0,
							'asteria-cashflow-graph-has-no-filter':
								filters.filter(({ type }) => type === 'tag')
									.length === 0,
							'asteria-cashflow-graph-hide-despoit':
								filterDeposit || !showDeposit,
							'asteria-cashflow-graph-hide-withdraw':
								filterWithdraw || !showWithdraw,
							'asteria-cashflow-graph-hide-account-balance':
								(hideAccountOnSearch &&
									tagFilters.length > 0 &&
									!allwaysShowAccountBalance) ||
								!showAccountBalance,
							'asteria-cashflow-graph-hide-account-balance-numbers': !showAccountBalanceNumbers,
						},
					)}
					ref={ref}
				>
					<Navigation
						onNext={next}
						onPrev={prev}
						className="asteria-cashflow-graph-time-navigation"
					/>
					<Menu id="cashflow-bar-graph" layout="stacked" />
					<FeatureFlag feature="timeSpanDropdown">
						<TimesliceSelector />
					</FeatureFlag>
					<Graph
						graph={graph}
						next={next}
						prev={prev}
						onClick={onClick}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
						getTooltip={getTooltip}
						id={graph.id}
						size={currentTimesliceSize}
						updateSize={updateSize}
						filters={filters}
						availableCategories={availableCategories}
						options={{ showXAxis: !hideAccountBalanceAxis }}
						barLayout="stacked"
						parts={['bars', 'lines']}
					/>
				</div>
			</div>
		);
	},
);
StackedLayout.displayName = 'StackedLayout';

const GraphLayout = styled(
	React.forwardRef((props, ref) =>
		props.settings?.layout?.graph?.barLayout === 'stacked' ? (
			<StackedLayout {...props} ref={ref} />
		) : (
			<GroupedLayout {...props} ref={ref} />
		),
	),
)`
	position: relative;

	&.asteria-cashflow-graph-hide-revenue {
		.asteria-graph-revenue-graph {
			display: none;
		}

		.asteria-graph-revenue-graph-controlers {
			.asteria-graph-controlls-menu-tags {
				display: none !important;
			}
		}

		.asteria-graph-lines {
			height: calc(100% - 22px) !important;
		}
	}

	.asteria-button-graph-toggle-accountbalance {
		margin: 0 0 0 auto;
		position: absolute;
		top: -4px;
		right: 37px;
		z-index: 5;
		.asteria-text {
			font-size: 12px;
			color: #563232;
		}
	}

	&.asteria-cashflow-graph-hide-despoit {
		.asteria-deposit {
			display: none;
		}
	}

	&.asteria-cashflow-graph-hide-withdraw {
		.asteria-withdraw {
			display: none;
		}
	}

	&.asteria-cashflow-graph-hide-account-balance {
		.asteria-graph-line-graph-component {
			height: 0px !important;
			overflow: hidden;
			padding: 0 !important;
		}

		.asteria-graph-line-graph-component {
			height: 0px !important;
			overflow: hidden;
			padding: 0 !important;
		}

		.asteria-title-bars {
			margin-top: -16px !important;
		}

		.asteria-button-left {
			top: -100px !important;
		}

		.asteria-button-right {
			top: -100px !important;
		}

		.asteria-graph-line-group {
			display: none;
		}

		.asteria-graph-line-graph-legends {
			display: none !important;
		}
	}

	&.asteria-cashflow-graph-hide-account-balance-numbers {
		.asteria-graph-line-dot {
			display: none;
		}
	}

	${({ theme = {} }) => {
		const style = compileStyles(GraphLayout.Styler, theme);
		return style;
	}}
`;
GraphLayout.displayName = 'GraphLayout';
GraphLayout.Styler = {
	typePrefix: 'asteria-cashflow-graph',
	base: [Getter('graph')],
	children: [
		{
			component: Navigation,
			base: [Getter('navigation')],
		},
		{
			component: Menu,
			base: [Getter('controllers.menu')],
		},
		{
			component: Search,
			base: [Getter('controllers.search')],
		},
		{
			component: Graph,
			base: [Getter('graph')],
		},
		{
			component: Legends,
			base: [Getter('legends')],
		},
		{
			component: Title,
			base: [Getter('title')],
		},
		{
			component: Tabs,
			base: [Getter('tabs')],
		},
	],
};

export default GraphLayout;

export { CashflowHeader };
