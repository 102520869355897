import React from 'react';
import styled from 'styled-components';

import Badge from '@asteria/component-core/badge';
import Button from '@asteria/component-core/button';

import { TranslationService } from '@asteria/services-language';

import { LogicNotificationsCount } from './logic';

const NotificationsBadge = styled(({ className, onClick = () => {} }) => (
	<LogicNotificationsCount>
		{({ notificationCount }) => (
			<Button
				className={className}
				onClick={onClick}
				type="link"
				icon="bell"
				size="large"
				stopPropogration={false}
				title={TranslationService.get(
					'notitification.navigation.title',
				)}
			>
				{notificationCount > 0 && (
					<Badge
						horizontal="left"
						vertical="top"
						className="asteria-badge"
					>
						{notificationCount < 10 ? notificationCount : '9+'}
					</Badge>
				)}
				{notificationCount > 0
					? TranslationService.get(
							'notitification.navigation.events',
							'Händelser',
					  )
					: TranslationService.get(
							'notitification.navigation.no_events',
							'Inga händelser',
					  )}
			</Button>
		)}
	</LogicNotificationsCount>
))`
	position: relative;
	flex-direction: column;
	padding: 0;
	margin: 0 16px;
	display: flex;
	.asteria-badge {
		border: 0px solid transparent;
		background: #c01f26;
		justify-content: center;
		align-items: center;
		border-radius: 50px;
		color: #ffffff;
		font-family: 'Swedbank Sans';
		font-weight: 700;
		font-size: 12px;
		width: 24px;
		height: 24px;
		white-space: nowrap;
		line-height: 100%;
		overflow: hidden;
		display: flex;
		text-overflow: ellipsis;
		margin-left: 42px;
		margin-top: 13px;
	}
	.asteria-icon-wrapper {
		svg {
			fill: #9f8c82 !important;
		}
	}
	.asteria-text {
		color: var(--text-color) !important;
		text-decoration: none !important;
		font-size: 16px !important;
		margin-left: 12px !important;
		white-space: nowrap;
	}
	&:hover {
		.asteria-icon-wrapper {
			svg {
				fill: #df7b3f !important;
			}
		}
		.asteria-text {
			color: #df7b3f !important;
		}
	}
	&.asteria-state-active {
		.asteria-icon-wrapper {
			svg {
				fill: #df7b3f !important;
			}
		}
		.asteria-text {
			color: #df7b3f !important;
		}
	}
`;

export default NotificationsBadge;
