import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Text, Title } from '@asteria/component-core';
import { Getter } from '@asteria/utils';
import Button from '@asteria/component-core/button';
import Icon from '@asteria/component-core/icon';
import { Dropdown, CheckboxGroup, TextInput } from '@asteria/component-form';
import DatePicker from '@asteria/component-form/datepicker';
import { format } from 'date-fns';
import Tooltip from '@asteria/component-tooltip';

import Tags from '../components/tags';
import SecurityBar from '../components/securityBar';

const TextCell = styled(
	({ className, data, field, getter, children, style, tooltip }) => {
		const el =
			children ||
			(getter ? <Text size="body">{getter(data)}</Text> : null);
		return (
			<div
				style={style}
				className={classNames(
					className,
					'asteria-transaction-cell',
					`asteria-transaction-cell-${field}`,
				)}
			>
				{tooltip ? (
					<Tooltip
						className="asteria-tooltip-transaction-cell"
						hover
						isStatic
						content={tooltip}
					>
						{el}
					</Tooltip>
				) : (
					el
				)}
			</div>
		);
	},
)``;

const Cell = styled(props => {
	const { data, getter, edit, onEdit, field, edits } = props;
	if (!edit) {
		return <TextCell {...props} />;
	}

	const value = useMemo(() => {
		const val = edits || getter(data, edit);

		if (Array.isArray(edit)) {
			return edit.filter(({ value: optionValue }) => optionValue === val);
		}

		return val;
	}, [data, edit, edits, getter]);

	const onEditCell = useCallback(
		newValue => {
			if (onEdit) {
				onEdit(field, newValue, getter(data, edit) === newValue);
			}
		},
		[data, edit, field, getter, onEdit],
	);

	if (Array.isArray(edit)) {
		return (
			<TextCell {...props}>
				<Dropdown
					size="medium"
					type="link"
					selected={value}
					showSelected
					onChange={val => onEditCell(val.value)}
					options={edit}
					toggleOptions={{ iconPosition: 'before' }}
				/>
			</TextCell>
		);
	}

	if (edit === 'text') {
		return (
			<TextCell {...props}>
				<TextInput
					value={value}
					onChange={e => onEditCell(e.target.value)}
				/>
			</TextCell>
		);
	}

	if (edit === 'number') {
		return (
			<TextCell {...props}>
				<TextInput
					value={value}
					type="number"
					step={1000}
					onChange={e => onEditCell(parseFloat(e.target.value))}
				/>
			</TextCell>
		);
	}

	if (edit === 'date') {
		return (
			<TextCell {...props}>
				<DatePicker
					value={value}
					min={new Date(format(new Date(), 'YYYY-MM-DD'))}
					onChange={val => onEditCell(val)}
				/>
			</TextCell>
		);
	}

	return null;
})``;

Cell.displayName = 'Cell';

Cell.Styler = {
	typePrefix: 'asteria-transaction-cell',
	children: [
		{
			component: Title,
			base: [Getter('title')],
		},
		{
			component: Text,
			base: [Getter('text')],
		},
		{
			component: Button,
			base: [Getter('button')],
		},
		{
			component: Icon,
			base: [Getter('icon')],
		},
		{
			component: Tags,
			base: [Getter('tags')],
		},
		{
			component: CheckboxGroup,
			base: [Getter('checkbox')],
		},
		{
			component: SecurityBar,
			base: [Getter('security')],
		},
	],
};

export default Cell;
