import React, { useState } from 'react';
import styled from 'styled-components';
import Text from '@asteria/component-typography/text';
import Title from '@asteria/component-typography/title';
import Button from '@asteria/component-button/button';
/* import Icon from '@asteria/component-icon'; */
import classNames from 'classnames';
import {
	InputV2,
	LabelV2,
	FormGroupV2,
	RadioGroupV2,
	RangeSlider,
} from '@asteria/component-form';

const Divin = styled.div`
	background: #ebe7e2;
	padding: 32px 32px 16px;
	margin-top: -32px;
`;

const AdjustableQuestions = styled(
	({ className, questions, answers, setAnswers }) => (
		<Divin className={classNames(className)}>
			{questions.map(question => (
				<AdjustableQuestion
					key={`question-${question.id}`}
					answers={answers}
					setAnswers={setAnswers}
					question={question}
				/>
			))}
		</Divin>
	),
)`
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
`;

AdjustableQuestions.displayName = 'AdjustableQuestions';

AdjustableQuestions.Styler = {
	children: [],
};

const WrapperDiv = styled.div`
	margin: 0 0 40px;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
`;

const AdjustableQuestion = styled(
	({ className, question, answers, setAnswers = () => {} }) => {
		const [advancedInput, setAdvancedInput] = useState(false);
		const {
			onChange = (key, value, data, setter) =>
				setter({
					...data,
					[key]: value,
				}),
		} = question;

		const { [question.form.confirm]: confirm = false } = answers;

		return (
			<WrapperDiv className={classNames(className)} key={question.id}>
				<Title>{question.title(answers)}</Title>
				<Text>{question.content}</Text>
				{question.form.confirm ? (
					<RadioGroupV2
						className="asteria-checkbox-group-wrapper-transactions-small"
						items={['Ja', 'Nej']}
						selected={[confirm ? 0 : 1]}
						onChange={index =>
							onChange(
								question.form.confirm,
								index === 0,
								answers,
								setAnswers,
							)
						}
					/>
				) : null}
				{question.form.input &&
				(!question.form.confirm || confirm === true) ? (
					<FormGroupV2 className="asteria-form">
						{question.label ? (
							<LabelV2>{question.label}</LabelV2>
						) : null}
						<InputV2
							className="asteria-input-wrapper"
							elementType="input"
							type="number"
							placeholder=""
							value={answers[question.form.input]}
							onChange={e =>
								onChange(
									question.form.input,
									e.target.value,
									answers,
									setAnswers,
								)
							}
							step={10000}
							helper={question.helper}
						/>
						<Text style={{ margin: '8px 0 16px' }}>
							{question.description}
						</Text>
					</FormGroupV2>
				) : null}
				{question.form.slider ? (
					<>
						<RangeSlider
							value={answers[question.form.slider] || 0}
							onChange={e =>
								onChange(
									question.form.slider,
									e.target.value,
									answers,
									setAnswers,
								)
							}
						/>
						{question.form.sliderText ? (
							<Text className="asteria-text-slider-text">
								{question.form.sliderText(answers)}
							</Text>
						) : null}
					</>
				) : null}
				{question.form.advanced ? (
					<>
						<Button
							iconPosition="before"
							icon={advancedInput ? 'down' : 'right'}
							className={classNames('asteria-button-accordion', {
								'asteria-state-active': advancedInput,
							})}
							size="small"
							type="link"
							text="Kategorier"
							style={{ margin: '0 auto	 16px' }}
							onClick={() => setAdvancedInput(!advancedInput)}
						/>
						{advancedInput ? (
							<AdjustableQuestions
								answers={answers}
								setAnswers={setAnswers}
								questions={question.form.advanced}
							/>
						) : null}
					</>
				) : null}
			</WrapperDiv>
		);
	},
)`
	.asteria-form {
		width: 100%;
		.asteria-input-wrapper {
			background: #fff;
			margin-top: 8px;
			.asteria-input-helper {
				bottom: 31px;
			}
		}
	}
	.asteria-button-accordion {
		height: auto;
		padding: 0;
		margin: 0;
		justify-content: center;
		align-items: center;
		display: flex;

		.asteria-text {
			font-family: Swedbank Headline;
			font-weight: 400;
			font-size: 14px;
			color: #262626;
			text-decoration: none;
			margin: 0;
			line-height: 100%;
			padding: 0;
		}

		.asteria-icon-wrapper {
			width: 12px;
			height: 10px;
			margin-right: 4px;
			margin-top: 4px;

			.asteria-icon-svg {
				transform: rotate(0deg);
				transition: transform 0.25s ease-in 0s;
				fill: rgb(159, 140, 130);
			}

			&.asteria-state-active {
				transform: rotate(180deg);
			}
		}
	}

	.asteria-button-medium .asteria-text {
		margin: 0 auto;
	}
`;

AdjustableQuestion.displayName = 'AdjustableQuestion';

AdjustableQuestion.Styler = {
	children: [],
};

export default AdjustableQuestions;
