import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Text } from '@asteria/component-core';
import { Translation } from '@asteria/services-language';
import NotificationItemBase from './base';

const NotificationItemEmpty = styled(({ className, notificationKey = '' }) => (
	<NotificationItemBase
		className={classNames(
			className,
			`asteria-notification-item`,
			(notificationKey || '')
				.split('.')
				.reduce((acc, k) => [...acc, `${acc[acc.length - 1]}-${k}`], [
					'asteria-notification-item',
				]),
		)}
	>
		<div className="asteria-notification-item-body">
			<Translation
				size="text"
				Component={Text}
				translationKey="notification.empty.text"
				defaultText="Bra jobbat! För tillfället har du inte några händelser att agera på."
			/>
		</div>
	</NotificationItemBase>
))``;

export default NotificationItemEmpty;
