export default {
	guide: {
		title: {
			style: {
				color: 'var(--text-color)',
				fontSize: '14px',
			},
		},
		text: {
			style: {
				marginBottom: '16px',
				minHeight: '32px',
			},
			types: {
				textStepCounter: {
					style: {
						textAlign: 'center',
						marginTop: '16px',
						marginBottom: '8px',
					},
				},
			},
		},
		modal: {
			dialog: {
				header: {
					title: {
						queries: {
							'(max-width: 767.98px)': {
								style: {},
							},
							'(min-width: 768px)': {
								style: {},
							},
							'(min-width: 1200px)': {
								style: {},
							},
						},
					},
				},
				body: {
					style: {
						overflow: 'hidden',
					},
					queries: {
						'(max-width: 767.98px)': {
							style: {},
						},
						'(min-width: 768px)': {
							style: {},
						},
						'(min-width: 1200px)': {
							style: {
								minHeight: '430px',
								marginBottom: '0',
							},
						},
					},
				},
				footer: {
					style: {
						justifyContent: 'space-between',
					},
					button: {
						style: {},
						text: {
							style: {
								marginBottom: 'auto',
								marginTop: '2px',
							},
						},
					},
				},
			},
		},
	},
};
