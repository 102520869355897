import { Service as FeatureService } from '@asteria/component-featureflag';

const generateCssClasses = features =>
	Object.entries(features)
		.filter(f => f)
		.map(([key, config]) => [
			`asteria-feature-${key}`,
			`asteria-feature-${key}-${config?.version || 1}`,
		])
		.flat();

const reducer = (state = {}, { action, payload }) => {
	switch (action) {
		case 'SET_AVAILABLE_FEATURES': {
			return {
				...state,
				available: payload,
			};
		}
		case 'SET_ACTIVE_FEATURE': {
			FeatureService.features = payload;
			return {
				...state,
				active: FeatureService.getEnabled(),
				classes: generateCssClasses(payload),
				loaded: true,
			};
		}
		case 'ENABLE_FEATURE': {
			const active = {
				...state.active,
				[payload.key]: payload,
			};

			FeatureService.features = active;
			return {
				...state,
				active: FeatureService.getEnabled(),
				classes: generateCssClasses(active),
			};
		}
		case 'DISABLE_FEATURE': {
			const { [payload.key]: _, ...active } = state.active;
			FeatureService.features = active;
			return {
				...state,
				active: FeatureService.getEnabled(),
				classes: generateCssClasses(active),
			};
		}
		default:
			return state;
	}
};

export default reducer;
