import React from 'react';
import styled from 'styled-components';
import Modal from '@asteria/component-modal';
import Support from '@asteria/component-support';
import { TranslationService } from '@asteria/services-language';
import { useLocation, useHistory } from '@asteria/utils/hooks';

const SupportModal = styled(({ className, basePath }) => {
	const { pathname } = useLocation();
	const history = useHistory();

	if (pathname !== `${basePath}/support`) {
		return null;
	}

	return (
		<Modal
			open={pathname === `${basePath}/support`}
			onClose={() => history.push(basePath)}
			className={className}
			title={TranslationService.get('support.title')}
		>
			<Support onEnd={() => history.push(basePath)} />
		</Modal>
	);
})``;

SupportModal.Styler = Modal.Styler;

export default SupportModal;
