import Store from '../../core/store';
import logger from '../../logger';

@logger('integrations:store')
class IntegrationsStore extends Store {
	name = 'integrations';

	constructor(subject) {
		super(subject, {
			integrations: [],
		});
	}
}

export default IntegrationsStore;
