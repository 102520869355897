/**
 *
 * @param key - navigation key
 * @return {function(statData: Object, navigateTo: function): *}
 */
export const navigatorCarrier = key => (statData, { navigateTo }) =>
	navigateTo(key, statData);

/**
 * Represents the navigator interface
 *
 * @param currentStep {Symbol}
 * @param previousStep {Symbol}
 * @param next {function}
 * @param back {function}
 * @param cancel {function}
 * @param navigateTo {function}
 * @return {{currentStep: *, next: *, cancel: *, previousStep: *, back: *, navigateTo: *}}
 */
const createNavigator = ({
	currentStep,
	previousStep,
	next,
	back,
	cancel,
	navigateTo,
	history,
	lastEvent,
}) => ({
	currentStep,
	previousStep,
	next,
	back,
	cancel,
	navigateTo,
	history,
	lastEvent,
});

export default createNavigator;
