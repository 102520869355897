import React, { useContext } from 'react';
import styled from 'styled-components';
import Modal from '@asteria/component-modal';
import {
	useLocation,
	useHistory,
	useStore,
	useRoute,
} from '@asteria/utils/hooks';
import { TranslationService } from '@asteria/services-language';
import { useFeature } from '@asteria/component-featureflag';
import classNames from 'classnames';
import { saveUser } from '@asteria/services-datalayer/services/auth/actions';
import DatalayerContext from '@asteria/services-datalayer/react/context';

import AddErpPage from '../routes/erpAdd';

const SupportModal = styled(
	({ className, basePath = '/integrations/', onDone }) => {
		const { dispatch } = useContext(DatalayerContext);
		const { pathname } = useLocation();
		const history = useHistory();
		const [user] = useStore('store-auth', ({ user: obj }) => obj);
		const hasIntegrationModalFirstTime = useFeature({
			key: 'integration-modal-first-time',
		});

		const { params: { type } = {} } = useRoute();

		const { settings: { erpReminder = false } = {} } = user || {};

		if (!pathname.startsWith(basePath)) {
			return null;
		}

		return (
			<Modal
				open={pathname.startsWith(basePath)}
				onClose={() => {
					if (hasIntegrationModalFirstTime && !erpReminder) {
						history.push(basePath);
						dispatch(
							saveUser({
								...user,
								settings: {
									...user.settings,
									erpReminder: true,
								},
							}),
						);
					} else if (onDone) {
						onDone();
					} else {
						history.push(basePath);
					}
				}}
				className={classNames(className, 'asteria-integrations-modal')}
				title={TranslationService.get(
					[
						'onboarding.modal.title',
						`onboarding.modal.${type}.title`,
					],
					'Lägg till bokföringssystem',
				)}
			>
				<AddErpPage
					onDone={() => {
						if (hasIntegrationModalFirstTime && !erpReminder) {
							history.push(basePath);
							dispatch(
								saveUser({
									...user,
									settings: {
										...user.settings,
										erpReminder: true,
									},
								}),
							);
						} else if (onDone) {
							onDone();
						} else {
							history.push(basePath);
						}
					}}
				/>
			</Modal>
		);
	},
)``;

SupportModal.Styler = Modal.Styler;

export default SupportModal;
