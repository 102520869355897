import React, { useContext, useCallback, useState, useEffect } from 'react';
import {
	ModalBody,
	ModalFooter,
	ContentContext,
} from '@asteria/component-modal';
import { useStore } from '@asteria/utils';
import { TranslationService } from '@asteria/services-language';
import { CircularIndicator } from '@asteria/component-progressbar-indicator';
import Button from '@asteria/component-button/button';
import Alert from '@asteria/component-alert';
import {
	getAccounts,
	updateAccounts,
} from '@asteria/services-datalayer/services/bankaccounts/actions';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { requestGraphData } from '@asteria/services-datalayer/services/graph/actions';
/* import { useHistory } from '@asteria/utils/hooks';
import { useFeature } from '@asteria/component-featureflag'; */
import BankAccountList from './bankAccountList';

const BankAccountsModal = ({ onClose }) => {
	const { content, setContent } = useContext(ContentContext);
	// eslint-disable-next-line no-unused-vars
	const [loadedAccounts, setAccounts] = useState([]);
	const [activeAccounts, setActiveAccounts] = useState([]);
	const [showAlert, setShowAlert] = useState(false);
	const { dispatch } = useContext(DatalayerContext);
	/* 	const hasBankIntegrations = useFeature({ key: 'bank-integrations' });
	const history = useHistory(); */
	useEffect(() => {
		dispatch(getAccounts());
	}, [dispatch]);

	useEffect(
		() =>
			setContent({
				...content,
				title: TranslationService.get(
					'bankList.title',
					'Hantera konton',
				),
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);
	const error = false;
	const [loading] = useStore(
		'store-bankaccounts',
		({ loadingBankAccounts = true }) => loadingBankAccounts,
	);
	const [items = []] = useStore(
		'store-bankaccounts',
		({ bankAccounts = [] }) => bankAccounts,
	);
	const [graphSize = 'month'] = useStore(
		'store-graph',
		({ 'cashflow-bar-graph': state = {} }) => state.size,
	);

	useEffect(() => {
		setActiveAccounts(items.filter(account => account.active));
	}, [items]);

	// eslint-disable-next-line no-unused-vars
	const save = useCallback(
		async (accountList, activeAccountList) => {
			/*
			accountList.map(account =>
				dispatch(
					updateAccount({
						active: activeAccountList.includes(account),
						identifiers: {
							number: account.identifiers.number,
						},
					}),
				),
			);
			*/
			dispatch(
				updateAccounts(
					accountList.map(account => ({
						active: activeAccountList.includes(account),
						identifiers: {
							number: account.identifiers.number,
						},
					})),
				),
			);

			setTimeout(() => {
				dispatch(
					requestGraphData('cashflow-bar-graph', { size: graphSize }),
				);
			}, 500);
		},
		[dispatch, graphSize],
	);

	const toggleSelection = useCallback(
		account => {
			if (activeAccounts.includes(account)) {
				setActiveAccounts(
					activeAccounts.filter(item => item !== account),
				);
			} else {
				setActiveAccounts([...activeAccounts, account]);
			}
			if (showAlert) {
				setShowAlert(false);
			}
		},
		[showAlert, activeAccounts],
	);

	return (
		<>
			<ModalBody scroll className="modal-body">
				{showAlert ? (
					<Alert
						icon
						type="error"
						headingContent={TranslationService.get(
							'bankList.error.title',
							'Välj minst ett alternativ',
						)}
						style={{
							marginBottom: '16px',
							paddingBottom: '4px',
						}}
						showClose={false}
						// TODO Check later and maybe put these more
						// likely styler props into custom Alert
						// component or refactor them out in other way
						overrideRoot="graph"
						parentSelector="alert"
					/>
				) : null}

				{(() => {
					if (loading) {
						return (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<CircularIndicator />
							</div>
						);
					}

					if (error) return `Error! ${error.message}`;
					/*
					if (activeAccounts === undefined) {
						// Save acounts to list
						setAccounts(items);

						// Update list of active accounts on first render
						setActiveAccounts(
							items
								.map(accountItem =>
									accountItem.active
										? accountItem.identifiers.number
										: false,
								)
								.filter(Boolean),
						);

						return null;
					}
					*/
					const accountsByCurrency = {};
					const cleanAccounts = items.filter(
						item => item?.sums?.original?.currency || false,
					);

					for (let i = 0; i < cleanAccounts.length; i += 1) {
						// Sort all accounts by currency
						const currency =
							cleanAccounts[i]?.sums?.original?.currency || false;

						if (accountsByCurrency[currency]) {
							accountsByCurrency[currency].push(cleanAccounts[i]);
						} else {
							accountsByCurrency[currency] = [cleanAccounts[i]];
						}
					}

					if (Object.keys(accountsByCurrency).length === 0) {
						return (
							<Alert
								type="warning"
								icon
								headingContent={TranslationService.get(
									'bankList.nothing_connected',
									'No connected integrations present...',
								)}
							/>
						);
					}

					return Object.keys(accountsByCurrency)
						.sort((a, b) => {
							if (a === 'SEK' && b === 'SEK') {
								return 0;
							}
							if (a === 'SEK') {
								return -1;
							}
							if (b === 'SEK') {
								return 1;
							}

							return a.localeCompare(b);
						})
						.map(key => (
							<BankAccountList
								key={key}
								currency={key}
								items={accountsByCurrency[key]}
								toggleSelection={toggleSelection}
								activeAccounts={activeAccounts}
							/>
						));
				})({})}
				{/* 				{hasBankIntegrations ? (
					<Button
						type="link"
						size="small"
						className="asteria-button-add-erp"
						icon="triangleRight"
						style={{ marginTop: '16px' }}
						iconPosition="after"
						onClick={() => history.push('/integrations/bank/add')}
					>
						{TranslationService.get(
							'bankList.addnew',
							'Lägg till bank',
						)}
					</Button>
				) : null} */}
			</ModalBody>
			<ModalFooter className="modal-footer">
				<Button
					size="medium"
					type="default"
					text={TranslationService.get(
						'bankList.button.cancel',
						'Avbryt',
					)}
					onClick={onClose}
				/>
				{items && items.length > 0 ? (
					<Button
						size="medium"
						type="primary"
						text={TranslationService.get(
							'bankList.button.save',
							'Spara',
						)}
						styles={{}}
						onClick={async () => {
							// validation
							if (activeAccounts && activeAccounts.length) {
								await save(items, activeAccounts);
								onClose();
							} else {
								setShowAlert(true);
							}
						}}
					/>
				) : null}
			</ModalFooter>
		</>
	);
};

export default BankAccountsModal;
