import gql from 'graphql-tag';

export default gql`
	query GetTheme($id: ID!) {
		theme(id: $id) {
			id
			name
			styles
			inherits
			partnerId
		}
	}
`;
