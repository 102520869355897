import React, {
	useContext,
	useState,
	useMemo,
	useEffect,
	useCallback,
} from 'react';
import classNames from 'classnames';
import styled, { withTheme } from 'styled-components';
import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';
import Icon from '@asteria/component-core/icon';
import { FeatureFlag, useFeature } from '@asteria/component-featureflag';
import { TranslationService } from '@asteria/services-language';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import Tooltip from '@asteria/component-tooltip';
// import { ListLoader } from '@asteria/component-progressbar-indicator';
import Tabs, {
	TabsContent,
	TabsNavigation,
} from '@asteria/component-form/tabs';

import {
	toggleActiveRow,
	toggleSelectedRow,
	setListItems,
	fetchListData,
	setListDates,
	setItemAction,
	setIgnore,
	setListReverse,
} from '@asteria/services-datalayer/services/list/actions';
import {
	setActiveBars,
	setActiveGroups,
} from '@asteria/services-datalayer/services/graph/actions';
import {
	setListOpen,
	setFilters,
	setAdjustOpen,
	setSelectedType,
	setSelectedDate,
} from '@asteria/services-datalayer/services/appstate/actions';
import {
	editTransactions,
	addTransactions,
} from '@asteria/services-datalayer/services/adjustable/actions';
import { compileStyles, Getter, useStore, formatNumber } from '@asteria/utils';
import {
	isFuture,
	format,
	startOfMonth,
	isThisISOWeek,
	isThisMonth,
	isThisYear,
	startOfISOWeek,
	startOfYear,
	addDays,
	addWeeks,
	addMonths,
	addYears,
	endOfISOWeek,
	endOfMonth,
	endOfYear,
	isSameISOWeek,
	isSameMonth,
	isSameYear,
} from 'date-fns';
import getStoreValue from '@asteria/services-datalayer/utils/get';
import { Dropdown } from '@asteria/component-form';
import { useLocation } from '@asteria/utils/hooks';
import ActionBar from '@asteria/component-core/actionbar';
import CurrenciesView from '@asteria/component-graph/controllers/currency';

import ActionContext from './layout/actionContext';
import Tags from './components/tags';
import Adjust from './components/adjustable/adjust';
import Adjustable from './components/adjustable/transaction';
import AddAdjustableTransaction from './components/adjustable/addAdjustable';
import AdjustGroup from './components/adjustable/adjustGroup';

import DetailedBar from './components/detailedBar';
import Transactions from './transactions/transaction';
import SecurityBar from './components/securityBar';
import InfoBox from './components/detailedBar/infoBox';
import AdjustableTools from './components/adjustable/adjustableTools';

import AdjustableQuestions from './components/adjustablequestions';
import Settings from './components/settings';
import ColumnSettings from './components/columnSettings';

import { getVolatilityText } from './utils';
import Clients from './clients';

const isThisTime = (date, size) => {
	if (size === 'week') {
		return isThisISOWeek(date);
	}
	if (size === 'month') {
		return isThisMonth(date);
	}
	if (size === 'year') {
		return isThisYear(date);
	}

	return isThisMonth(date);
};

const isSameTime = (a, b, size) => {
	if (size === 'week') {
		return isSameYear(a, b) && isSameISOWeek(a, b);
	}
	if (size === 'month') {
		return isSameYear(a, b) && isSameMonth(a, b);
	}
	if (size === 'year') {
		return isSameYear(a, b);
	}

	return false;
};

const startOfTime = (date, size) => {
	if (size === 'week') {
		return startOfISOWeek(date);
	}
	if (size === 'month') {
		return startOfMonth(date);
	}
	if (size === 'year') {
		return startOfYear(date);
	}

	return startOfMonth(date);
};

const endOfTime = (date, size) => {
	if (size === 'week') {
		return endOfISOWeek(date);
	}

	if (size === 'month') {
		return endOfMonth(date);
	}

	if (size === 'year') {
		return endOfYear(date);
	}

	return endOfMonth(date);
};

const addTimeslice = (date, size, count = 1) => {
	if (size === 'day') {
		return addDays(date, count);
	}
	if (size === 'week') {
		return addWeeks(date, count);
	}
	if (size === 'month') {
		return addMonths(date, count);
	}
	if (size === 'year') {
		return addYears(date, count);
	}

	return date;
};

const GET_CURRENCIES_EXPOSURE = `
query Exposure($filters: TransactionFiltersInput){
	transactionCurrencies(filters: $filters) {
	  currency
	  deposit {
		original {
		  total
		  currency
		}
		display {
		  total
		  currency
		}
		exposure {
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		tags {
		  tag {
			id
			name
		  }
		  type
		  parts {
			status
			count
			original {
			  total
			  currency
			}
			display {
			  total
			  currency
			}
		  }
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		count
	  }
  
	  withdraw {
		original {
		  total
		  currency
		}
		display {
		  total
		  currency
		}
		exposure {
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		tags {
		  tag {
			id
			name
		  }
		  type
		  parts {
			status
			count
			original {
			  total
			  currency
			}
			display {
			  total
			  currency
			}
		  }
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		count
	  }
  
	  exposure {
		original {
		  total
		  currency
		}
		display {
		  total
		  currency
		}
	  }

	  risk {
		  original {
			  total
			  currency
		  }
		  display {
			  total
			  currency
		  }
		  risk
		  alert
	  }
	}
  }
  
`;

const ComponentMap = {
	Text,
	Title,
	TextWithTitle: Text,
	Icon,
	Button,
	Tags,
	CheckBox: ({ id, ...props }) => (
		<input type="checkbox" name={id} value={id} {...props} />
	),
	// Grid,
};

const getComponent = type => {
	if (type === 'Cell') {
		return 'div';
	}

	const Component = ComponentMap[type] || false;

	return Component;
};

const Header = styled.div``;
Header.Styler = {
	children: [
		{
			component: Button,
			base: [Getter('button')],
		},
		{
			component: SecurityBar,
			base: [Getter('security')],
		},
		{
			component: Title,
			base: [Getter('amount')],
		},
		{
			component: AddAdjustableTransaction,
			base: [Getter('adjustable')],
		},
		{
			component: AdjustableTools,
			base: [Getter('tools')],
		},
	],
};

Header.displayName = 'Header';

const ListModes = [
	{
		label: 'list.settings.dropdown.clients',
		value: 'clients',
	},
	{
		label: 'list.settings.dropdown.transactions',
		value: 'transactions',
	},
];

const List = styled(
	withTheme(({ className, items: listItems = [], theme = {} }) => {
		const hasListHeaderCount = useFeature({ key: 'list-header-count' });
		const hasTransactionListClients = useFeature({
			key: 'transactions-list-clients',
		});
		const [showInfo, showInfoBox] = useState(false);
		const [showCurrencyInfo, setShowCurrencyInfo] = useState(false);
		const [showSettingsModal, setShowSettingsModal] = useState(false);
		const [showColumnsModal, setShowColumnsModal] = useState(false);
		const [listFilter, setListFilter] = useState(false);
		const [listMode, setMode] = useState('transactions');
		const { pathname } = useLocation();
		const { config = {} } = theme?.transactions || {};
		const { dispatch, lookup } = useContext(DatalayerContext);
		const apiService = lookup('service-api');
		const [listSettings = {}] = useStore(
			'store-auth',
			({ user: obj }) => obj?.settings?.layout?.list,
		);

		useEffect(() => {
			setMode(listSettings?.view || 'transactions');

			if (!hasTransactionListClients) {
				setMode('transactions');
			}
		}, [hasTransactionListClients, listSettings]);

		const isFactoringMode = pathname === '/factoring';

		useEffect(() => {
			if (isFactoringMode) {
				setMode('clients');
			} else {
				setMode(
					hasTransactionListClients
						? listSettings?.view || 'transactions'
						: 'transactions',
				);
			}
		}, [hasTransactionListClients, isFactoringMode, listSettings]);

		const toggleListFilter = useCallback(
			tag => {
				if (
					listFilter.id === tag.id &&
					listFilter.status === tag.status
				) {
					setListFilter(false);
				} else {
					setListFilter(tag);
				}
			},
			[listFilter],
		);
		const [cashflowBarGraphGroups = {}] = useStore(
			'store-graph',
			({ 'cashflow-bar-graph': { barGroups = {} } = {} }) => barGroups,
		);

		const [cashflowBarGraphTags = []] = useStore(
			'store-graph',
			({ 'cashflow-bar-graph': { visibleTags = [] } = {} }) =>
				visibleTags,
		);

		const [selected = []] = useStore(
			'store-graph',
			({ 'cashflow-bar-graph': { activeBars } = {} }) => activeBars,
		);

		const [isListOpen] = useStore(
			'store-appstate',
			({ listOpen = false }) => listOpen,
		);

		const [filters = []] = useStore(
			'store-appstate',
			({ filters: list }) => list,
		);

		const [isAdjustOpen] = useStore(
			'store-appstate',
			({ adjustOpen = false }) => adjustOpen,
		);

		const [timeSize = 'month'] = useStore(
			'store-list',
			({ size = 'month' }) => size,
		);

		const [listStatus = null] = useStore(
			'store-list',
			({ status }) => status,
		);

		const [listStartDate] = useStore(
			'store-list',
			({ startDate }) => startDate,
		);

		const [listEndDate] = useStore('store-list', ({ endDate }) => endDate);

		const [listOpenSource] = useStore(
			'store-appstate',
			({ listOpenSource: source }) => source,
		);

		let [visibleColumns] = useStore(
			'store-list',
			({ visibleColumns: list }) => list,
		);

		/*
		const { lines: [{ value = 0, probability = 0 } = {}] = [] } =
			cashflowBarGraphGroups?.[
				format(listStartDate, 'YYYY-MM-DD[T00:00:00.000Z]')
			] || {};
		*/

		const { lines = [] } =
			cashflowBarGraphGroups?.[
				format(listStartDate, 'YYYY-MM-DD[T00:00:00.000Z]')
			] || {};

		const { value = 0, probability = 0, max = 0, min = 0 } =
			lines.find(({ types = [] }) => types.includes('forecast')) ||
			lines.find(({ types = [] }) => types.includes('history')) ||
			{};

		const { bars = [] } =
			cashflowBarGraphGroups?.[
				format(listStartDate, 'YYYY-MM-DD[T00:00:00.000Z]')
			] || {};

		const [listTypes] = useStore('store-list', ({ types }) => types);

		const [state = {}] = useStore(
			'store-list',
			({ state: listState }) => listState,
		);

		const [listStatistics = []] = useStore(
			'store-list',
			({ statistics }) => statistics,
		);

		const [listactions = []] = useStore(
			'store-list',
			({ actions }) => actions,
		);

		const newItems = useMemo(
			() =>
				listactions
					.filter(({ operation }) => operation === 'CREATE')
					.map(({ transaction }) => transaction),
			[listactions],
		);

		useEffect(() => {
			setListFilter(false);
		}, [dispatch]);

		const [exposure, setExposure] = useState(0);
		const [currencies, setCurrencies] = useState([]);

		useEffect(() => {
			let status = ['PAID', 'UNPAID', 'OVERDUE', 'FORECAST'];

			if ((filters || []).some(({ type }) => type === 'status')) {
				status = (filters || [])
					.filter(({ type }) => type === 'status')
					.map(
						({ config: { status: filterStatus } }) => filterStatus,
					);
			}

			// ['PAID', 'UNPAID', 'OVERDUE', 'FORECAST']
			const search = {};

			if (
				filters &&
				filters.length > 0 &&
				filters.some(({ type }) => type === 'tag')
			) {
				search['meta.tags'] = {
					$in: filters
						.filter(({ type }) => type === 'tag')
						.map(({ id }) => id),
				};
			}

			if (
				filters &&
				filters.length > 0 &&
				filters.some(({ type }) => type === 'currency')
			) {
				search['sums.original.currency'] = {
					$in: filters
						.filter(({ type }) => type === 'currency')
						.map(({ config: { name } }) => name),
				};
			}

			const sub = apiService
				.query(
					GET_CURRENCIES_EXPOSURE,
					{
						filters: {
							startDate: listStartDate,
							endDate: listEndDate,
							types: listTypes,
							status,
							search,
						},
					},
					{ reqAuth: true },
				)
				.subscribe(({ data: { transactionCurrencies = [] } = {} }) => {
					if (listTypes.length === 1) {
						const [showType] = listTypes;
						setExposure(
							transactionCurrencies.reduce(
								(acc, item) =>
									acc +
									Math.abs(
										item?.[showType.toLowerCase()]?.exposure
											?.display?.total || 0,
									),
								0,
							),
						);
					} else {
						setExposure(
							transactionCurrencies.reduce(
								(acc, item) =>
									acc +
									Math.abs(
										item?.exposure?.display?.total || 0,
									),
								0,
							),
						);
					}

					setCurrencies(transactionCurrencies);
				});

			return () => {
				if (sub) {
					sub.unsubscribe();
				}
			};
		}, [
			listStartDate,
			listEndDate,
			apiService,
			listTypes,
			filters,
			isListOpen,
		]);

		useEffect(() => {
			if (!isAdjustOpen) {
				dispatch(setItemAction([]));
				dispatch(setIgnore([]));
			} else {
				dispatch(setAdjustOpen(true));
			}
		}, [dispatch, isAdjustOpen]);

		const filteredItems1 = useMemo(() => {
			let items = [...listItems, ...newItems];
			if (listFilter) {
				items = listItems.filter(
					({ status, meta: { tags = [] } = {} }) =>
						tags.some(
							t =>
								listFilter.id === t.id &&
								listFilter.status === status,
						),
				);
			}

			if (listStatus) {
				items = items.filter(({ status }) =>
					listStatus.includes(status),
				);
			}

			return items;
		}, [listFilter, listItems, newItems, listStatus]);

		const filteredItems = useMemo(() => {
			let items = filteredItems1;
			const clientFilters = filters.filter(
				({ type }) => type === 'client',
			);
			const tagFilters = filters.filter(({ type }) => type === 'tag');
			const statusFilters = filters.filter(
				({ type }) => type === 'status',
			);

			if (clientFilters.length > 0) {
				items = items.filter(({ links = [] }) => {
					const invoice = links.find(
						({ type }) =>
							type === 'INVOICE_PAYMENT' || type === 'CLIENT',
					);

					if (invoice) {
						return !!clientFilters.find(
							({ id }) =>
								id === invoice?.invoiceDetails?.client?.id ||
								invoice.id === id,
						);
					}

					return false;
				});
			}

			if (statusFilters.length > 0) {
				items = items.filter(({ status }) =>
					statusFilters.find(
						({ config: { status: filterStatus } = {} }) =>
							filterStatus === status,
					),
				);
			}

			if (tagFilters.length > 0) {
				items = items.filter(({ meta: { tags = [] } = {} }) =>
					tags.some(t => tagFilters.find(v => v.id === t.id)),
				);
			}

			if (isFactoringMode) {
				items = items.filter(
					({ meta: { tags = [] } = {} }) =>
						!tags.some(({ name }) => name === '$factoring'),
				);
			}

			return items;
		}, [filteredItems1, filters, isFactoringMode]);

		const hasRisk = useMemo(
			() =>
				currencies.some(
					({
						risk: {
							display: { total: totalRisk },
						},
					}) => totalRisk !== 0,
				),
			[currencies],
		);

		visibleColumns = useMemo(() => {
			if (hasRisk && !visibleColumns.includes('risk')) {
				const newColumns = [...visibleColumns];
				newColumns.splice(-1, 0, 'risk');
				return newColumns;
			}

			return visibleColumns;
		}, [hasRisk, visibleColumns]);

		const tagFilter = useMemo(() => {
			const tags = [];
			if (listFilter) {
				tags.push(listFilter.id);
			}

			return [
				...tags,
				...filters
					.filter(({ type }) => type === 'tag')
					.map(({ id }) => id),
			];
		}, [listFilter, filters]);

		const hasFxModule = useFeature({
			key: 'fx-module',
		});

		const currencyRisk = useMemo(() => {
			if (!hasFxModule) {
				return 0;
			}

			if (listTypes.length === 1) {
				const [showType] = listTypes;
				return currencies.reduce(
					(acc, item) =>
						acc +
							item?.[showType.toLowerCase()]?.risk?.display
								?.total || 0,
					0,
				);
			}

			return currencies.reduce(
				(acc, item) => acc + item?.risk?.display?.total || 0,
				0,
			);
		}, [currencies, hasFxModule, listTypes]);

		const displayCurrency = useMemo(
			() => currencies?.[0]?.risk?.display?.total || 'SEK',
			[currencies],
		);

		const actions = {
			toggleActive: item => dispatch(toggleActiveRow(item)),
			toggleSelected: item => {
				dispatch(toggleSelectedRow(item));
			},
			isActive: item => state.active === item,
			isSelected: item => (state.selected || []).includes(item),
			getTags: ({ meta: { tags = [] } = {} }) => tags,
			getTypes: item => ({
				'asteria-state-active': state.active === item,
				'asteria-transaction-selected': state.selected.includes(item),
			}),
		};

		const headerKeys = ['list.transactions.header'];
		if (listTypes.length === 1) {
			headerKeys.push(`${headerKeys[0]}.${listTypes[0].toLowerCase()}`);
		}

		const isListFuture = isFuture(listEndDate);

		if (isListFuture) {
			headerKeys.push(...headerKeys.map(k => `${k}.forecast`));
		}

		if (isThisTime(listStartDate, timeSize)) {
			headerKeys.push(...headerKeys.map(k => `${k}.today`));
		}

		if (listStatus !== null && listStatus.length > 0) {
			const [status] = listStatus;
			headerKeys.push(
				...headerKeys.map(k => `${k}.${status.toLowerCase()}`),
			);
		}

		headerKeys.push(...headerKeys.map(k => `${k}.${timeSize}`));

		let detailedStats;

		if (listTypes.length === 1) {
			if (listTypes[0] === 'DEPOSIT') {
				detailedStats = listStatistics?.deposit;
			} else if (listTypes[0] === 'WITHDRAW') {
				detailedStats = listStatistics?.withdraw;
			}
		}

		const [currentType] = listTypes;
		const headerTotal =
			listTypes.length === 1
				? bars
						.filter(
							({ data: { type } = {} }) =>
								type === currentType.toLowerCase(),
						)
						.reduce((acc, { value: barValue }) => acc + barValue, 0)
				: value;
		const headerProbability =
			detailedStats?.oracle?.probability || probability;

		let hasTotalTooltip = false;
		let headerTotalMax = 0;
		let headerTotalMin = 0;

		if (
			listTypes.length === 1 &&
			detailedStats?.oracle?.min?.original?.total !==
				detailedStats?.oracle?.max?.original?.total
		) {
			hasTotalTooltip = true;
			headerTotalMax = detailedStats?.oracle?.max?.original?.total;
			headerTotalMin = detailedStats?.oracle?.min?.original?.total;
		}

		if (!hasTotalTooltip && listTypes.length !== 1 && max !== min) {
			hasTotalTooltip = true;
			headerTotalMax = max;
			headerTotalMin = min;
		}

		const totalTooltipContent = [];

		if (hasTotalTooltip) {
			totalTooltipContent.push(
				<p key="tooltip-text">
					{TranslationService.get(
						[
							'list.total.tooltip',
							...(isListFuture
								? ['list.total.forecast.tooltip']
								: []),
							...(detailedStats?.parts?.map(
								({ status }) => `list.total.${status}.tooltip`,
							) || []),
						],
						'',
						{
							stats: detailedStats,
							max: headerTotalMax,
							min: headerTotalMin,
						},
					)}
				</p>,
			);
		}

		const riskTooltipContent = useMemo(() => {
			if (currencyRisk) {
				return [
					<Text size="text" key="curency-text">
						{TranslationService.get(
							'list.header.risk.text',
							'Du har en valutarisk i följande valutor',
						)}
					</Text>,
					currencies.filter(
						({
							risk: {
								display: { total: totalRisk },
							},
						}) => totalRisk !== 0,
					).length > 1 && (
						<div
							key="currency-multiple"
							className={classNames(
								'asteria-transactions-total-tooltip-currency-multiple',
							)}
						>
							<div className="asteria-part-risk" />
							<Text
								size="text"
								className={classNames(
									'asteria-transactions-total-tooltip-currency-label',
								)}
							>
								{TranslationService.get(
									`list.total.tooltip.currency.risk.multiple.label`,
									'Valutarisk i {{currencies.length}} valutor',
									{
										currencies: currencies.filter(
											({
												risk: {
													display: {
														total: totalRisk,
													},
												},
											}) => totalRisk !== 0,
										),
									},
								)}
							</Text>
							<Text
								size="text"
								className={classNames(
									'asteria-transactions-total-tooltip-currency-value',
								)}
							>
								{TranslationService.get(
									`list.total.tooltip.currency.risk.value`,
									'{{currencyRisk | number : false : false : true}} {{currency}}',
									{
										currencyRisk,
										currency: displayCurrency,
									},
								)}
							</Text>
						</div>
					),
					...currencies
						.filter(
							({
								risk: {
									display: { total: totalRisk },
								},
							}) => totalRisk !== 0,
						)
						.map(currency => (
							<div
								key={`currency-${currency.currency}`}
								className={classNames(
									'asteria-transactions-total-tooltip-currency',
								)}
							>
								<div
									className={`currency-flag currency-flag-${currency.currency?.toLowerCase()}`}
								/>
								<Text
									className={classNames(
										'asteria-transactions-total-tooltip-currency-label',
									)}
								>
									{TranslationService.get(
										`list.total.tooltip.currency.risk.label`,
										'{{currency}}',
										currency,
									)}
								</Text>
								<Text
									className={classNames(
										'asteria-transactions-total-tooltip-currency-value',
									)}
								>
									{TranslationService.get(
										`list.total.tooltip.currency.risk.value`,
										'{{risk.display.total | number : false : false : true}} {{risk.display.currency}}',
										currency,
									)}
								</Text>
							</div>
						)),
				];
			}

			return [];
		}, [currencies, currencyRisk, displayCurrency]);

		const exposureTooltipContent = useMemo(() => {
			if (
				currencies &&
				currencies.some(
					({
						exposure: {
							display: { total },
						},
					}) => total !== 0,
				)
			) {
				return [
					<Text key="curency-text">
						{TranslationService.get(
							'list.header.exposure.text',
							'Du har en valutaexponering i följande valutor',
						)}
					</Text>,
					currencies.filter(
						({
							exposure: {
								display: { total: totalExposure },
							},
						}) => totalExposure !== 0,
					).length > 1 && (
						<div
							key="currency-multiple"
							className={classNames(
								'asteria-transactions-total-tooltip-currency-multiple',
							)}
						>
							<div className="asteria-part-exposure" />
							<Text
								className={classNames(
									'asteria-transactions-total-tooltip-currency-label',
								)}
							>
								{TranslationService.get(
									`list.total.tooltip.currency.exposure.multiple.label`,
									'Exponering i {{currencies.length}} valutor',
									{
										currencies: currencies.filter(
											({
												exposure: {
													display: {
														total: totalExposure,
													},
												},
											}) => totalExposure !== 0,
										),
									},
								)}
							</Text>
							<Text
								className={classNames(
									'asteria-transactions-total-tooltip-currency-value',
								)}
							>
								{TranslationService.get(
									`list.total.tooltip.currency.exposure.value`,
									'{{exposure | number : false : false : true}}',
									{
										exposure,
									},
								)}
							</Text>
						</div>
					),
					...currencies
						.filter(
							({
								exposure: {
									display: { total: totalExposure },
								},
							}) => totalExposure !== 0,
						)
						.map(currency => (
							<div
								key={`currency-${currency.currency}`}
								className={classNames(
									'asteria-transactions-total-tooltip-currency',
								)}
							>
								<div
									className={`currency-flag currency-flag-${currency.currency?.toLowerCase()}`}
								/>
								<Text
									className={classNames(
										'asteria-transactions-total-tooltip-currency-label',
									)}
								>
									{TranslationService.get(
										`list.total.tooltip.currency.exposure.label`,
										'{{currency}}',
										currency,
									)}
								</Text>
								<Text
									className={classNames(
										'asteria-transactions-total-tooltip-currency-value',
									)}
								>
									{TranslationService.get(
										`list.total.tooltip.currency.exposure.value`,
										'{{exposure.display.total | number : false : false : true}}',
										currency,
									)}
								</Text>
							</div>
						)),
				];
			}

			return [];
		}, [currencies, exposure]);

		return (
			<div
				className={classNames(
					className,
					'asteria-transactions',
					`asteria-transactions-mode-${listMode}`,
					filters.map(
						({ config: { name = '' } = {} }) =>
							`asteria-transactions-filter-${name.replace(
								'$',
								'',
							)}`,
					),
					{
						'asteria-transactions-open': isListOpen,
						'asteria-transactions-future': isListFuture,
						'asteria-transactions-info-open': showInfo,
						'asteria-transactions-has-filter':
							filters.length > 0 || listFilter,
					},
				)}
			>
				<Settings
					show={showSettingsModal}
					setShow={setShowSettingsModal}
				/>
				<ColumnSettings
					show={showColumnsModal}
					setShow={setShowColumnsModal}
				/>
				<FeatureFlag feature="asteria-improvements" invert>
					<Header className="asteria-transactions-header">
						<Button
							className="asteria-button-transactions-header-close"
							icon={isListOpen ? 'down' : 'right'}
							type="link"
							style={{ marginRight: '4px' }}
							title={TranslationService.get(
								'list.header.toggle.visibility',
							)}
							onClick={() => {
								if (!isListOpen) {
									dispatch(setListOpen(true, 'account'));
									dispatch(
										setSelectedType([
											'DEPOSIT',
											'WITHDRAW',
										]),
									);
									dispatch(setSelectedDate(listStartDate));
								} else {
									showInfoBox(false);
									dispatch(setListOpen(false));
									dispatch(setListReverse(false));
									dispatch(editTransactions([]));
									dispatch(addTransactions([]));
									dispatch(
										setActiveGroups(
											'cashflow-bar-graph',
											[],
										),
									);
									dispatch(
										setActiveBars('cashflow-bar-graph', []),
									);
									dispatch(setListItems([]));
									dispatch(
										setListDates(
											startOfTime(new Date(), timeSize),
											addMonths(
												startOfTime(
													new Date(),
													timeSize,
												),
												1,
											),
											['DEPOSIT', 'WITHDRAW'],
											null,
											timeSize,
										),
									);

									if (
										listOpenSource === 'insight-unpaid' ||
										listOpenSource === 'insight-overdue'
									) {
										dispatch(setFilters([]));
									}
								}
							}}
						/>
						<Button
							className="asteria-button-transactions-header"
							text={TranslationService.get(
								headerKeys,
								headerKeys[0],
								{
									startDate: listStartDate,
									endDate: listEndDate,
									statistics: listStatistics,
								},
							)}
							subtext={
								hasListHeaderCount ? '1230123 händelser' : ''
							}
							size="large"
							type="link"
						/>
						<FeatureFlag feature="currency-list-header">
							{exposure !== 0 ? (
								<Tooltip
									key="asteria-list-divider-risk-exposure-tooltip"
									hover
									isStatic
									content={exposureTooltipContent}
									className={classNames({
										'asteria-list-divider-risk-tooltip-multiple':
											currencies.filter(
												({
													exposure: {
														display: {
															total: totalExposure,
														},
													},
												}) => totalExposure !== 0,
											).length > 1,
									})}
								>
									<Title
										size="title3"
										className={classNames(
											'asteria-text-transactions-header-exposure align-right',
											{
												'asteria-state-alert':
													exposure / headerTotal >
													0.1,
											},
										)}
									>
										<span>
											{TranslationService.get(
												'list.header.label.exposure',
												'Exponering:',
											)}
										</span>
										{formatNumber(
											exposure,
											false,
											false,
											true,
										)}
									</Title>
								</Tooltip>
							) : null}
							{currencyRisk !== 0 ? (
								<Tooltip
									key="asteria-list-divider-risk-tooltip"
									hover
									isStatic
									content={riskTooltipContent}
									className={classNames({
										'asteria-list-divider-risk-tooltip-multiple':
											currencies.filter(
												({
													risk: {
														display: {
															total: totalRisk,
														},
													},
												}) => totalRisk !== 0,
											).length > 1,
									})}
								>
									<Title
										size="title3"
										className={classNames(
											'asteria-text-transactions-header-currency-risk align-right',
											{
												'asteria-state-alert':
													currencyRisk / headerTotal >
													0.1,
											},
										)}
									>
										<span>
											{TranslationService.get(
												'list.header.label.risk',
												'Risk:',
											)}
										</span>
										{formatNumber(
											currencyRisk,
											false,
											false,
											true,
										)}
									</Title>
								</Tooltip>
							) : null}
						</FeatureFlag>
						<Tooltip
							hover
							isStatic
							style={{ maxWidth: '190px', minWidth: '0' }}
							content={
								totalTooltipContent.length > 0
									? totalTooltipContent
									: false
							}
						>
							<Title
								size="title3"
								className="asteria-text-transactions-header-total align-right"
							>
								{`${formatNumber(headerTotal)}`}
							</Title>
						</Tooltip>
						{isListFuture ? (
							<Tooltip
								hover
								isStatic
								content={TranslationService.get(
									'list.security.tooltip',
									'Sannolikhet {{volatility}}%',
									{
										volatility: Math.round(
											Math.min(
												100,
												headerProbability * 100,
											),
										),
										volatilityText: getVolatilityText(
											headerProbability,
										),
									},
								)}
							>
								<SecurityBar
									value={headerProbability}
									max={1}
									steps={5}
								/>
							</Tooltip>
						) : null}
						<FeatureFlag feature="list-header-currency-details">
							{(currencyRisk !== 0 || exposure !== 0) && (
								<Button
									key="asteria-button-transactions-currency-show"
									className="asteria-button-transactions-currency-show"
									icon={showCurrencyInfo ? 'up' : 'down'}
									type="link"
									title={TranslationService.get(
										'list.transactions.currency.title.tooltip',
									)}
									onClick={() => {
										setShowCurrencyInfo(!showCurrencyInfo);
									}}
								/>
							)}
						</FeatureFlag>
						{/* {isListFuture ? (
							<Button
								type="link"
								size="small"
								className="asteria-button-transactions-header-info"
								icon={showInfo ? 'circleClose' : 'circleHelp'}
								onClick={() => showInfoBox(!showInfo)}
							/>
						) : null} */}
						<FeatureFlag feature="adjustable">
							{isListFuture ? (
								<FeatureFlag feature="adjustable-total">
									<FeatureFlag
										feature="adjustable-total-icon-only"
										key="adjustable-total-icon-only-inverted"
										invert
									>
										<Button
											type="link"
											icon="circlePen"
											size="large"
											text={TranslationService.get(
												'list.adjustable.total.label',
											)}
											title={TranslationService.get(
												'list.adjustable.total.label',
											)}
											className={classNames(
												className,
												`asteria-adjustable-total`,
											)}
											onClick={() => {
												dispatch(setListOpen(true));
												dispatch(setAdjustOpen(true));
												if (!isListOpen) {
													dispatch(
														setSelectedType([
															'DEPOSIT',
															'WITHDRAW',
														]),
													);
													dispatch(
														setSelectedDate(
															listStartDate,
														),
													);
												}
											}}
										/>
									</FeatureFlag>
									<FeatureFlag
										feature="adjustable-total-icon-only"
										key="adjustable-total-icon-only"
									>
										<Button
											type="link"
											icon="circlePen"
											size="large"
											title={TranslationService.get(
												'list.adjustable.total.label',
											)}
											className={classNames(
												className,
												`asteria-adjustable-total`,
											)}
											onClick={() => {
												dispatch(setListOpen(true));
												dispatch(setAdjustOpen(true));
												if (!isListOpen) {
													dispatch(
														setSelectedType([
															'DEPOSIT',
															'WITHDRAW',
														]),
													);
													dispatch(
														setSelectedDate(
															listStartDate,
														),
													);
												}
											}}
										/>
									</FeatureFlag>
								</FeatureFlag>
							) : null}
						</FeatureFlag>
						<FeatureFlag feature="adjustable">
							<FeatureFlag feature="adjustable-add-event-in-list-header">
								<AddAdjustableTransaction />
							</FeatureFlag>
						</FeatureFlag>
						<FeatureFlag feature="menu-icon">
							<Dropdown
								size="medium"
								type="link"
								iconClosed="settingsnav"
								iconOpen="settingsnav"
								direction={['down', 'left']}
								className="asteria-button-list-settings"
								options={[
									{
										label: 'list.settings.dropdown.columns',
										value: 'columns',
									},
									{
										label:
											'list.settings.dropdown.settings',
										value: 'settings',
									},
								]}
								onChange={({ value: selectedValue }) => {
									if (selectedValue === 'columns') {
										setShowColumnsModal(true);
									} else if (selectedValue === 'settings') {
										setShowSettingsModal(true);
									}
								}}
							/>
						</FeatureFlag>
						<FeatureFlag feature="menu-icon" invert>
							<Dropdown
								size="medium"
								type="link"
								iconClosed="menudots"
								iconOpen="circleClose"
								direction={['down', 'left']}
								className="asteria-button-list-settings"
								options={[
									{
										label: 'list.settings.dropdown.columns',
										value: 'columns',
									},
									{
										label:
											'list.settings.dropdown.settings',
										value: 'settings',
									},
								]}
								onChange={({ value: selectedValue }) => {
									if (selectedValue === 'columns') {
										setShowColumnsModal(true);
									} else if (selectedValue === 'settings') {
										setShowSettingsModal(true);
									}
								}}
							/>
						</FeatureFlag>
						<FeatureFlag feature="adjustable">
							<FeatureFlag feature="tools" key="tools">
								<AdjustableTools />
							</FeatureFlag>
							<FeatureFlag
								feature="tools-grouped"
								invert="tools"
								key="group"
							>
								<AdjustGroup />
							</FeatureFlag>
						</FeatureFlag>
					</Header>
				</FeatureFlag>
				{/* <InfoBox /> */}
				{isListOpen ? (
					<>
						{isAdjustOpen ? (
							<Adjust
								startDate={listStartDate}
								endDate={listEndDate}
							/>
						) : null}
						<FeatureFlag feature="list-divider-inline-edit" invert>
							<Adjustable date={listStartDate} />
						</FeatureFlag>
					</>
				) : null}
				{showCurrencyInfo && (
					<CurrenciesView
						isTabActive
						data={currencies}
						setShow={() => {
							setShowCurrencyInfo(false);
						}}
					/>
				)}
				<FeatureFlag feature="actionbar-in-list">
					<ActionBar />
				</FeatureFlag>
				<ActionContext.Provider value={actions}>
					{isListOpen ? (
						<div className="asteria-transactions-list-wrapper">
							<FeatureFlag feature="Graph - Detailed">
								<DetailedBar
									locked={selected.length === 0}
									statistics={detailedStats}
									isFuture={isListFuture}
									setListFilter={toggleListFilter}
									listFilter={listFilter}
									mainFilter={cashflowBarGraphTags}
								/>
							</FeatureFlag>
							<FeatureFlag feature="search" invert>
								<DetailedBar
									locked={selected.length === 0}
									statistics={detailedStats}
									isFuture={isListFuture}
									setListFilter={toggleListFilter}
									listFilter={listFilter}
									mainFilter={cashflowBarGraphTags}
								/>
							</FeatureFlag>
							<FeatureFlag feature="transactions-list-clients">
								<FeatureFlag feature="transactions-list-view-header">
									<Dropdown
										size="medium"
										type="link"
										showSelected
										iconClosed="triangleDown"
										iconOpen="triangleUp"
										itemIconSelected="check"
										className="asteria-dropdown-list-view"
										selected={[
											ListModes.find(
												({ value: v }) =>
													v === listMode,
											),
										]}
										options={ListModes}
										onChange={({
											value: selectedValue,
										}) => {
											setMode(selectedValue);
										}}
									/>
								</FeatureFlag>
							</FeatureFlag>
							{listMode === 'clients' ? (
								<div className="asteria-list-clients-wrapper">
									<Dropdown
										size="medium"
										type="link"
										iconClosed="menudots"
										iconOpen="circleClose"
										direction={['down', 'left']}
										className="asteria-button-list-settings"
										options={[
											{
												label:
													'list.settings.dropdown.columns',
												value: 'columns',
											},
											{
												label:
													'list.settings.dropdown.settings',
												value: 'settings',
											},
										]}
										onChange={({
											value: selectedValue,
										}) => {
											if (selectedValue === 'columns') {
												setShowColumnsModal(true);
											} else if (
												selectedValue === 'settings'
											) {
												setShowSettingsModal(true);
											}
										}}
									/>
									<Clients
										transactions={filteredItems}
										isFuture={isListFuture}
										tagFilter={tagFilter}
										visibleColumns={visibleColumns}
										className="asteria-list-clients"
									/>
								</div>
							) : null}
							{listMode === 'transactions' ? (
								<FeatureFlag
									feature="adjustable-onboarding"
									invert
								>
									<Transactions
										className="asteria-transactions-list-wrapper-inner"
										format="table"
										config={config}
										items={filteredItems}
										isFuture={isListFuture}
										componentGetter={getComponent}
										tagFilter={tagFilter}
										visibleColumns={visibleColumns}
										setShowSettingsModal={
											setShowSettingsModal
										}
										setShowColumnsModal={
											setShowColumnsModal
										}
									/>
								</FeatureFlag>
							) : null}
							<FeatureFlag feature="adjustable-onboarding">
								<Tabs active="forecast">
									<TabsNavigation key="navigation">
										<Button
											type="primary"
											tab="transactions"
										>
											Transaktioner
										</Button>
										<Button type="primary" tab="forecast">
											Prognos
										</Button>
									</TabsNavigation>
									<TabsContent key="content">
										<div
											tab="transactions"
											className="asteria-tab-transactions"
										>
											<Transactions
												format="table"
												config={config}
												items={filteredItems}
												isFuture={isListFuture}
												componentGetter={getComponent}
												sortFunction={() => 0}
												tagFilter={tagFilter}
												visibleColumns={visibleColumns}
												setShowSettingsModal={
													setShowSettingsModal
												}
												setShowColumnsModal={
													setShowColumnsModal
												}
											/>
										</div>
										<div
											tab="forecast"
											className="asteria-tab-forecast"
										>
											<AdjustableQuestions
												transactions={listItems}
												statistics={listStatistics}
												id={`${format(
													listStartDate,
													'YYYY-MM-DD',
												)}T00:00:00.000Z`}
												timeSize={timeSize}
												next={`${format(
													addTimeslice(
														listStartDate,
														timeSize,
														1,
													),
													'YYYY-MM-DD',
												)}T00:00:00.000Z`}
											/>
										</div>
									</TabsContent>
								</Tabs>
							</FeatureFlag>
						</div>
					) : null}
				</ActionContext.Provider>
			</div>
		);
	}),
)`
	/* margin: 0px 16px; */
	.asteria-layout-cell-status {
		input {
			display: none;
		}
	}

	.asteria-list-clients {
		border-top: 1px solid var(--transaction-list-border-color);
	}

	&.asteria-transactions-future {
		.asteria-layout-cell-status {
			input {
				display: block;
			}
		}
	}

	.asteria-dropdown-list-view {
		border: 0px solid transparent;
		padding-right: 0;
		margin-right: 0;
		padding: 0 0;
		min-height: 32px;
		max-width: 140px;
		padding-left: 0;
		.asteria-button {
			border-bottom: 0px solid transparent;
			justify-content: flex-start;
		}
		.asteria-text {
			font-family: Swedbank Sans;
			font-weight: 700;
			font-size: 14px;
			color: var(--text-color);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			text-decoration: none;
			align-items: flex-start;
			margin: 0;
			padding: 0;
		}
		.asteria-icon-wrapper {
			width: 16px !important;
			height: 6px !important;
			margin-left: 8px;
			flex-grow: 0;
			flex-shrink: 1;
			svg {
				fill: #9f8c82 !important;
			}
		}
		.asteria-forms-dropdown-menu-direction-down {
			min-width: 170px;
		}
		.asteria-forms-dropdown-item {
			.asteria-icon-wrapper {
				width: 16px !important;
				height: 20px !important;
				margin-left: 8px;
				flex-grow: 0;
				flex-shrink: 1;
				svg {
					fill: #99cc66 !important;
					opacity: 1;
				}
			}
		}
	}

	&.asteria-transactions {
		/*margin-right: 30px;*/
	}

	&.asteria-transactions-open {
		height: auto;
	}

	.asteria-transactions-header {
		display: flex;
		flex-direction: row;
		align-items: center;

		${Button} {
			flex-grow: 1;
			text-decoration: none;
		}

		${Title} {
			&.asteria-text-transactions-header-total {
			}
		}

		${AddAdjustableTransaction} {
			flex-grow: 0;
		}

		div {
			&:first-of-type {
				/* margin-left: auto; */
			}
		}

		.asteria-text-transactions-header-total {
		}
	}

	.asteria-transactions-actions {
	}

	.asteria-button-list-settings {
		.asteria-forms-dropdown-toggle {
			height: 36px;
			border: 0;
			padding: 0;
			background: transparent;
			width: 36px;
			margin-right: 2px;
			.asteria-icon-wrapper {
				height: auto;
				width: auto;
				svg {
					fill: #ee7024;
				}
			}
		}
		.asteria-forms-dropdown-menu-direction-down {
			min-width: 290px;
			.asteria-forms-dropdown-item {
				.asteria-text {
					font-size: 14px !important;
					color: var(--text-color) !important;
				}
			}
		}
	}

	.asteria-transactions-header .asteria-adjustable {
		margin-right: 0 !important;
		width: auto !important;
	}

	.asteria-adjustable-total,
	.asteria-adjustable {
		width: auto;
		flex-grow: 0 !important;
		margin-left: 8px;
		.asteria-text {
			font-family: Arial, sans-serif !important;
			font-weight: 400 !important;
			font-size: 16px !important;
			color: #257886 !important;
			text-decoration: underline !important;
			cursor: pointer !important;
		}
		.asteria-icon-wrapper {
			height: 24px !important;
			width: 24px !important;
			svg {
				fill: #53a1ac !important;
				width: auto;
			}
		}
	}

	${Tabs} {
		flex: 1;
		display: flex;
		flex-direction: column;

		${TabsContent} {
			flex: 1;

			.asteria-tab-transactions {
				display: none;

				&.asteria-state-active {
					display: flex;
				}
			}
		}
	}

	${({ theme }) => {
		const style = compileStyles(
			List,
			theme,
			{},
			{ componentGetter: getComponent },
		);
		return style;
	}}
`;

List.displayName = 'List';
List.Styler = {
	base: [Getter('transactions')],
	typePrefix: 'asteria-transactions',
	children: [
		{
			component: Header,
			base: [Getter('header')],
		},
		{
			component: InfoBox,
			base: [Getter('info')],
		},
		{
			component: Transactions,
			base: [Getter('list')],
		},
		{
			component: DetailedBar,
			base: [Getter('detailedbar')],
		},
		{
			component: Adjustable,
			base: [Getter('adjustable')],
		},
		{
			component: Adjust,
			base: [Getter('adjust')],
		},
		{
			component: Tabs,
			base: [Getter('tabs')],
		},
	],
};

const ListLogic = React.memo(props => {
	const { dispatch, lookup } = useContext(DatalayerContext);

	// Features
	const hasListDivider = useFeature({ key: 'list-divider' });

	// Store data
	const [isListOpen] = useStore(
		'store-appstate',
		({ listOpen = false }) => listOpen,
	);

	const [selectedDate] = useStore(
		'store-appstate',
		({ selectedDate: date }) => date,
	);

	const [selectedType] = useStore(
		'store-appstate',
		({ selectedType: type }) => type,
	);

	const [timesliceSize = 'month'] = useStore(
		'store-appstate',
		({ timesliceSize: size }) => size,
	);

	const [filters = []] = useStore(
		'store-appstate',
		({ filters: list }) => list,
	);

	const [reverse = false] = useStore('store-list', ({ reverse: r }) => r);
	const [listItems = []] = useStore('store-list', ({ items }) => items);

	useEffect(() => {
		if (isListOpen === false) {
			return;
		}

		let startDate = selectedDate;

		const currentDate = getStoreValue(
			lookup('store-appstate'),
			({ currentDate: val }) => addTimeslice(val, timesliceSize, 4),
		);

		if (!isSameTime(startDate, currentDate)) {
			startDate = currentDate;
		}

		const endDate = `${format(
			endOfTime(startDate, timesliceSize),
			'YYYY-MM-DD',
		)}T23:59:59.999Z`;

		const types = selectedType;
		let status = ['PAID', 'UNPAID', 'OVERDUE', 'FORECAST'];

		if ((filters || []).some(({ type }) => type === 'status')) {
			status = (filters || [])
				.filter(({ type }) => type === 'status')
				.map(({ config: { status: filterStatus } }) => filterStatus);
		}

		// ['PAID', 'UNPAID', 'OVERDUE', 'FORECAST']
		const search = {};

		if (
			filters &&
			filters.length > 0 &&
			filters.some(({ type }) => type === 'tag')
		) {
			search['meta.tags'] = {
				$in: filters
					.filter(({ type }) => type === 'tag')
					.map(({ id }) => id),
			};
		}

		if (
			filters &&
			filters.length > 0 &&
			filters.some(({ type }) => type === 'currency')
		) {
			search['sums.original.currency'] = {
				$in: filters
					.filter(({ type }) => type === 'currency')
					.map(({ config: { name } }) => name),
			};
		}

		if (
			filters &&
			filters.length > 0 &&
			filters.some(({ type }) => type === 'client')
		) {
			search['links.clientId'] = {
				$in: filters
					.filter(({ type }) => type === 'client')
					.map(({ id }) => id),
			};
		}

		dispatch(
			fetchListData({
				startDate,
				endDate,
				types,
				status,
				search,
				reverse,
				/*
				reverse: (listOpenSource || '')?.includes(
					'notification.forecast',
				),
				*/
			}),
		);
	}, [
		dispatch,
		filters,
		hasListDivider,
		isListOpen,
		selectedDate,
		selectedType,
		timesliceSize,
		reverse,
		lookup,
	]);

	return <List items={listItems} {...props} />;
});

export default List;
export { ListLogic };
