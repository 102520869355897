import React from 'react';
import registerActivity from './registerActivity';

/**
 * HOC without wrapper for cases when you cannot use additional wrappers
 * @param Component
 * @return {function(*): *}
 */
export default Component => props => {
	const { onClick, children, ...otherProps } = props;

	return (
		<Component
			{...otherProps}
			onClick={e => {
				registerActivity(e);

				if (onClick) {
					onClick(e);
				}
			}}
		>
			{children}
		</Component>
	);
};
