import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LinearIndicator } from '@asteria/component-progressbar-indicator';
import styled from 'styled-components';
import classNames from 'classnames';
import { TranslationService } from '@asteria/services-language';
import { useLocation, useHistory, useStore } from '@asteria/utils/hooks';
import Modal from '@asteria/component-modal';
import Button from '@asteria/component-button';
import { Tabs } from '@asteria/component-form';
import { TabsNavigation, TabsContent } from '@asteria/component-form/tabs';
import DatalayerContext from '@asteria/services-datalayer/react/context';

import { Text, Title } from '@asteria/component-core/typography';
import { setMode } from '@asteria/services-datalayer/services/appstate/actions';
import Tooltip from '@asteria/component-tooltip';
import Icon from '@asteria/component-core/icon';
import { FeatureFlag, useFeature } from '@asteria/component-featureflag';
import { saveUser } from '@asteria/services-datalayer/services/auth/actions';

// const sumBalance = (index, row) => row.rows.reduce((sum, cell) => sum + cell.values[index], 0);

const ListMarker = ({ style = {} }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		id="svg8"
		version="1.1"
		viewBox="0 0 10.205579 20.410641"
		height="20"
		width="10"
		style={{
			width: '6px',
			minWidth: '6px',
			marginRight: '0.75em',
			...style,
		}}
		className="asteria-list-marker"
	>
		<g transform="translate(-51.074917,-104.18486)">
			<path
				d="M 51.074917,104.18486 V 124.5955 A 10.205358,10.205358 0 0 0 61.280496,114.39044 10.205358,10.205358 0 0 0 51.074917,104.18486 Z"
				style={{
					fill: '#5784d9',
					fillOpacity: '1',
					stroke: 'none',
				}}
			/>
		</g>
	</svg>
);

const LIST_CONTAINER_STYLES = {
	listStyleType: 'none',
	paddingLeft: '0px',
	margin: '0.5em 0 0',
};
const LIST_ELEMENT_STYLES = { display: 'flex' };

const NeedInput = styled(({ className, erp }) => {
	const history = useHistory();
	if (erp) {
		return (
			<div className={classNames(className, 'asteria-statement-missing')}>
				<div className={classNames('asteria-loading-wrapper')}>
					<Text>
						{TranslationService.get(
							'statement.missing.erp.connecting.text',
							'Ansluter till {{erp.key}}',
							{
								erp,
							},
						)}
					</Text>
					<LinearIndicator />
				</div>
			</div>
		);
	}

	return (
		<div className={classNames(className, 'asteria-statement-missing')}>
			<Title size="title3" style={{ marginBottom: '8px' }}>
				{TranslationService.get(
					'statement.missing.erp.title',
					'Anslut bokföringssystem',
				)}
			</Title>
			<Text>
				{TranslationService.get(
					'statement.missing.erp.desc',
					'För att se ditt årsresultat och tidigare årsresultat behöver du koppla ditt bokföringssystem.',
				)}
			</Text>
			<Text>
				{TranslationService.get(
					'onboarding.selectProvider.description',
					'Genom att koppla ditt bokföringssystem får du följande fördelar:',
				)}
			</Text>
			<ul style={LIST_CONTAINER_STYLES}>
				<li style={LIST_ELEMENT_STYLES}>
					<ListMarker />
					<Text>
						{TranslationService.get(
							'onboarding.selectProvider.description.list.point1',
							'Uppgifter hämtas från ditt bokföringssystem och internetbank, till exempel skatt, löner och fakturor.',
						)}
					</Text>
				</li>
				<li style={LIST_ELEMENT_STYLES}>
					<ListMarker />
					<Text>
						{TranslationService.get(
							'onboarding.selectProvider.description.list.point2',
							'Informationen analyseras och kategoriseras automatiskt.',
						)}
					</Text>
				</li>
				<li style={LIST_ELEMENT_STYLES}>
					<ListMarker />
					<Text>
						{TranslationService.get(
							'onboarding.selectProvider.description.list.point3',
							'Resultatet presenteras i enkla grafer som visar dig hur den framtida utvecklingen förväntas bli.',
						)}
					</Text>
				</li>
			</ul>

			<Button
				type="link"
				size="small"
				className="asteria-button-add-erp"
				icon="triangleRight"
				iconPosition="after"
				onClick={() => history.push('/integrations/erp/add')}
			>
				{TranslationService.get(
					'statement.missing.erp.add',
					'Lägg till bokföringssystem',
				)}
			</Button>
		</div>
	);
})`
	.asteria-loading-wrapper {
		background-color: rgb(251, 242, 234);
		padding: 32px 20px;
		margin-top: 16px;
		margin-bottom: 16px;
		.asteria-text {
			text-align: center;
		}
		.asteria-loading {
			margin: 16px auto 0;
			padding: 0;
		}
	}

	.asteria-button-add-erp {
		margin-top: 16px;
		padding-left: 0;
		.asteria-text {
			font-size: 16px;
			font-weight: 400;
			text-decoration: none;
			font-family: Swedbank Headline;
			font-weight: 400;
		}
		.asteria-icon-wrapper {
		}
	}
`;
/*
const sumBalance = (index, row) => {
	if (row.values) {
		return row.values[index];
	}

	return (
		row?.rows?.reduce((sum, cell) => sum + sumBalance(index, cell), 0) || 0
	);
};
*/

const Cell = styled(({ className, row, cell, badge, badgeAction }) => (
	<div
		className={classNames(
			className,
			'asteria-statement-table--cell',
			`asteria-statement-table--cell-${row?.label?.replace(/\./g, '-')}`,
		)}
	>
		<Text>{cell}</Text>
		{badge && (
			<Tooltip
				hover
				isStatic
				title=""
				content={TranslationService.get(
					`${row.label}.badge`,
					'Du vet väl att du kan beräkna ditt finansieringsbehov i Företagskollen. Klicka här för att testa Finansieringsbehov',
					{ row, cell },
				)}
			>
				<Icon asset="info" onClick={badgeAction} />
			</Tooltip>
		)}
	</div>
))`
	justify-content: space-between;
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
	width: 100%;
	min-height: 37px;
	border-bottom: 1px solid #f1efeb;
`;

const Row = styled(({ className, years, row, badgeAction }) => (
	<div
		className={classNames(
			className,
			'asteria-statement-table--row',
			`asteria-statement-table--row-${row?.label?.replace(/\./g, '-')}`,
		)}
	>
		{row?.rows && (
			<div className={classNames('asteria-statement-table--row_header')}>
				<div
					className={classNames(
						'asteria-statement-table--header_cell',
					)}
				>
					<Text>{TranslationService.get(row.label, row.label)}</Text>
				</div>
			</div>
		)}
		{row?.rows && (
			<div className={classNames('asteria-statement-table--row_inner')}>
				<div
					className={classNames(
						'asteria-statement-table--row_inner_label',
					)}
				>
					{row?.rows?.map(
						cell =>
							cell?.values && (
								<Cell
									key={cell.label}
									cell={TranslationService.get(
										cell.label,
										cell.label,
									)}
								/>
							),
					)}
				</div>
				{row?.rows?.some(cell => cell?.values) &&
					years.map((year, yearIndex) => (
						<div
							key={year}
							className={classNames(
								'asteria-statement-table--row_inner_value',
							)}
						>
							{row.rows.map(
								cell =>
									cell?.values && (
										<Cell
											key={cell.label}
											row={row}
											cell={TranslationService.get(
												'statement.row.value',
												'{{value | number : false}}',
												{
													value:
														cell?.values?.[
															yearIndex
														],
												},
											)}
											badge={cell?.badges?.[yearIndex]}
											badgeAction={badgeAction}
										/>
									),
							)}
						</div>
					))}
			</div>
		)}
		{row?.rows?.some(cell => cell?.rows) && (
			<div
				className={classNames('asteria-statement-table--row_children')}
			>
				{row.rows.map(
					cell =>
						cell?.rows && (
							<Row
								className="asteria-statement-table--row-child"
								key={cell.label}
								years={years}
								row={cell}
								badgeAction={badgeAction}
							/>
						),
				)}
			</div>
		)}
		{row?.total && (
			<div className={classNames('asteria-statement-table--summary')}>
				<div
					className={classNames(
						'asteria-statement-table--summary_cell',
					)}
				>
					<Text>
						{TranslationService.get(
							`${row.label}.total`,
							`${row.label}.total`,
						)}
					</Text>
				</div>
				{years.map((year, index) => (
					<div
						key={year}
						className={classNames(
							'asteria-statement-table--summary_cell',
						)}
					>
						<Text>
							{TranslationService.get(
								'statement.row.total',
								'{{value | number : false}}',
								{ value: row?.total?.[index] },
							)}
						</Text>
						{row?.badges?.[index] && (
							<Tooltip
								hover
								isStatic
								title=""
								content={TranslationService.get(
									`${row.label}.badge`,
									'Du vet väl att du kan beräkna ditt finansieringsbehov i Företagskollen. Klicka här för att testa Finansieringsbehov',
								)}
							>
								<Icon asset="info" onClick={badgeAction} />
							</Tooltip>
						)}
					</div>
				))}
			</div>
		)}
	</div>
))`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-bottom: 16px;

	&.asteria-statement-table--row-accounting-results-deposit-faction {
		margin-top: -16px;
		border-top: 1px solid #f1efeb;
	}

	&.asteria-statement-table--row-child {
	}

	.asteria-statement-table--row_header {
		color: #72605e;
		display: flex;
		flex-direction: row;
		align-items: center;
		flex: 1;
		min-height: 37px;
		background-color: #fbf2ea;
		justify-content: space-between;
		.asteria-statement-table--header_cell {
			padding-left: 12px;
			padding-right: 12px;
		}
	}
	.asteria-statement-table--row_inner {
		display: flex;
		flex: 1;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;
		.asteria-statement-table--row_inner_label {
			display: flex;
			flex-direction: column;
			flex: 1;
			min-width: 270px;
			.asteria-text {
				padding: 0 12px;
			}
		}
		.asteria-statement-table--row_inner_value {
			display: flex;
			flex-direction: column;
			flex: 1;
			.asteria-text {
				padding: 0 12px;
			}
		}
	}
	.asteria-statement-table--summary {
		color: #72605e;
		display: flex;
		flex-direction: row;
		align-items: center;
		flex: 1;
		min-height: 37px;
		justify-content: space-between;
		.asteria-statement-table--summary_cell {
			&:first-of-type {
				min-width: 270px;
			}
			justify-content: space-between;
			display: flex;
			flex: 1;
			flex-direction: row;
			align-items: center;
			width: 100%;
			.asteria-text {
				padding-left: 12px;
				padding-right: 12px;
				font-weight: 600;
				font-size: 12px;
			}
		}
	}
`;

const Table = styled(({ className, type, data, badgeAction }) => (
	<div
		className={classNames(
			className,
			'asteria-statement-table',
			`asteria-statement-table-${type}`,
		)}
	>
		<div className={classNames('asteria-statement-table--filter')}>
			<div
				className={classNames('asteria-statement-table--filter_cell')}
			/>
			{data?.years?.map(year => (
				<div
					className={classNames(
						'asteria-statement-table--filter_cell',
					)}
					key={year}
				>
					<Text>{year}</Text>
				</div>
			))}
		</div>
		{data?.rows?.map(row => (
			<Row
				key={data.id}
				row={row}
				years={data.years}
				badgeAction={badgeAction}
			/>
		))}
		{data?.total && (
			<div className={classNames('asteria-statement-table--total')}>
				<div
					className={classNames(
						'asteria-statement-table--total_cell',
					)}
				>
					<Text>
						{TranslationService.get('statement.result', 'Resultat')}
					</Text>
				</div>
				{data?.years?.map((year, index) => (
					<div
						key={year}
						className={classNames(
							'asteria-statement-table--total_cell',
						)}
					>
						<Text>
							{TranslationService.get(
								'statement.total',
								'{{value | number : false}}',
								{ value: data?.total?.[index] },
							)}
						</Text>
						{data?.badges?.[index] && (
							<Tooltip
								hover
								isStatic
								title=""
								content={TranslationService.get(
									[
										`statement.badge`,
										`statement.badge.${type}`,
									],
									'Du vet väl att du kan beräkna ditt finansieringsbehov i Företagskollen. Klicka här för att testa Finansieringsbehov',
								)}
							>
								<Icon asset="info" onClick={badgeAction} />
							</Tooltip>
						)}
					</div>
				))}
			</div>
		)}
	</div>
))`
	display: flex;
	flex-direction: column;
	flex: 1;
	font-family: Arial, Helvetica, sans-serif;
	.asteria-statement-table--filter {
		position: sticky;
		top: 0;
		color: #72605e;
		display: flex;
		flex-direction: row;
		align-items: center;
		flex: 1;
		min-height: 37px;
		background-color: #e2dbd9;
		justify-content: space-between;
		border-bottom: 2px solid #dfd4ce;
		.asteria-statement-table--filter_cell {
			&:first-of-type {
				min-width: 270px;
			}
			justify-content: space-between;
			display: flex;
			flex: 1;
			flex-direction: row;
			align-items: center;
			width: 100%;
			.asteria-text {
				font-size: 14px !important;
				color: #4c2d2c !important;
				font-family: Arial, Helvetica, sans-serif !important;
				font-weight: 600 !important;
				padding-left: 12px;
				padding-right: 12px;
			}
		}
	}
	.asteria-statement-table--total {
		border-top: 2px solid #dfd4ce;
		color: #72605e;
		display: flex;
		flex-direction: row;
		align-items: center;
		flex: 1;
		min-height: 37px;
		justify-content: space-between;
		.asteria-statement-table--total_cell {
			&:first-of-type {
				min-width: 270px;
			}
			justify-content: space-between;
			display: flex;
			flex: 1;
			flex-direction: row;
			align-items: center;
			width: 100%;
			.asteria-text {
				padding-left: 12px;
				padding-right: 12px;
				font-weight: 600;
				font-size: 12px;
			}
		}
	}
`;

const ApplyCredit = styled(({ className, activateCredit }) => {
	const { dispatch } = useContext(DatalayerContext);
	const [user = {}] = useStore('store-auth', ({ user: obj }) => obj);

	const onClose = useCallback(() => {
		dispatch(
			saveUser({
				...user,
				settings: {
					...(user?.settings || {}),
					flags: {
						...(user?.settings?.flags || {}),
						hasReadStatementInfo: true,
					},
				},
			}),
		);
	}, [dispatch, user]);

	if (user?.settings?.flags?.hasReadStatementInfo) {
		return null;
	}

	return (
		<div className={classNames(className, 'asteria-credit-application')}>
			<Title size="title3" style={{ marginBottom: '8px' }}>
				{TranslationService.get(
					'statement.apply-credit.title',
					'Beräkna på finansieringsbehov',
				)}
			</Title>
			<Button
				icon="close"
				type="icon"
				className="asteria-apply-credit-close"
				onClick={onClose}
			/>
			<Text>
				{TranslationService.get(
					'statement.apply-credit.desc',
					'Du vet väl att du kan beräkna ditt finansieringsbehov i Företagskollen.',
				)}
			</Text>
			<Button
				type="link"
				size="small"
				className="asteria-button-apply-credit"
				icon="triangleRight"
				iconPosition="after"
				onClick={activateCredit}
			>
				{TranslationService.get(
					'statement.apply-credit',
					'Klicka här för att testa Finansieringsbehov',
				)}
			</Button>
			<Button
				type="link"
				size="small"
				className="asteria-apply-credit-dismiss"
				onClick={onClose}
			>
				{TranslationService.get(
					'statement.apply-credit.dismiss',
					'Jag är inte intresserad',
				)}
			</Button>
		</div>
	);
})`
	margin-top: 24px;
	padding: 24px;
	border: 1px solid rgba(163, 139, 128, 0.3);
	position: relative;
	.asteria-apply-credit-close {
		position: absolute;
		right: 16px;
		top: 16px;
		background: transparent;
		height: 16px;
		width: 16px;
		.asteria-icon-wrapper {
			.asteria-icon-svg {
				fill: #999999;
			}
		}
	}
	.asteria-apply-credit-dismiss {
		padding-left: 0;
	}
	.asteria-button-apply-credit {
		margin-top: 16px;
		padding-left: 0;
		.asteria-text {
			font-size: 16px;
			font-weight: 400;
			text-decoration: none;
			font-family: Swedbank Headline;
			font-weight: 400;
		}
		.asteria-icon-wrapper {
		}
	}
`;

const GET_RESULTS = `
query {
	accountingResults {
		years
		rows {
			label
			values
			rows {
				label
				values
				total
				badges
				rows {
					label
					values
					total
					badges
				}
			}
			total
			badges
		}
		total
		currency
		badges
	}

	accountingBalanceSheet {
		years
		rows {
			label
			values
			rows {
				label
				values
				total
				badges
				rows {
					label
					values
					total
					badges
				}
			}
			total
			badges
		}
		currency
		badges
	}

	accountingWorkingCapital {
		years
		rows {
			label
			values
			rows {
				label
				values
				total
				badges
				rows {
					label
					values
					total
					badges
				}
			}
			total
			badges
		}
		total
		currency
		badges
	}

	accountingMisc {
		years
		rows {
			label
			values
			rows {
				label
				values
				total
				badges
				rows {
					label
					values
					total
					badges
				}
			}
			total
			badges
		}
		total
		currency
		badges
	}
}
`;

const Statement = styled(({ onClose }) => {
	const { lookup, dispatch } = useContext(DatalayerContext);
	const hasCreditApplication = useFeature({ key: 'credit-application' });
	const apiService = lookup('service-api');
	const [data, setData] = useState(null);
	const [integrations = []] = useStore(
		'store-integrations',
		({ integrations: list = [] }) => list,
	);

	const hasERP = integrations.find(({ type }) => type === 'erp');
	const erpStatus = hasERP?.status?.state || 'IDLE';

	if (!hasERP || erpStatus === 'INITIATING') {
		return (
			<div className={classNames('asteria-statement')}>
				<NeedInput erp={hasERP} />
			</div>
		);
	}

	useEffect(() => {
		apiService
			.query(GET_RESULTS, {}, { reqAuth: true })
			.subscribe(({ data: results }) => {
				setData(results);
			});
	}, [apiService]);

	const activateCredit = useCallback(() => {
		if (hasCreditApplication) {
			dispatch(setMode('credit'));
			onClose();
		}
	}, [dispatch, hasCreditApplication, onClose]);

	if (data === null) {
		return null;
	}

	return (
		<div className={classNames('asteria-statement')}>
			<div className="asteria-statement-inner">
				<Tabs active="result">
					<TabsNavigation>
						<Button type="link" tab="result">
							{TranslationService.get(
								'statement.tab.result',
								'Resultaträkning',
							)}
						</Button>
						<Button type="link" tab="balance">
							{TranslationService.get(
								'statement.tab.balance',
								'Balansrapport',
							)}
						</Button>
						<Button type="link" tab="workingcapital">
							{TranslationService.get(
								'statement.tab.workingcapital',
								'Rörelsekapital',
							)}
						</Button>
						<Button type="link" tab="other">
							{TranslationService.get(
								'statement.tab.other',
								'Övrigt',
							)}
						</Button>
					</TabsNavigation>
					<TabsContent>
						<div tab="result">
							<Table
								data={data?.accountingResults}
								type="result"
								badgeAction={activateCredit}
							/>
						</div>
						<div tab="balance">
							<Table
								data={data?.accountingBalanceSheet}
								type="balance"
								badgeAction={activateCredit}
							/>
						</div>
						<div tab="workingcapital">
							<Table
								data={data?.accountingWorkingCapital}
								type="workingcapital"
								badgeAction={activateCredit}
							/>
						</div>
						<div tab="other">
							<Table
								data={data?.accountingMisc}
								type="other"
								badgeAction={activateCredit}
							/>
						</div>
					</TabsContent>
				</Tabs>
			</div>
			<FeatureFlag feature="credit-application">
				<FeatureFlag feature="credit-application-teaser">
					<ApplyCredit activateCredit={activateCredit} />
				</FeatureFlag>
			</FeatureFlag>
		</div>
	);
})``;

const StatementModal = styled(({ className, basePath = '' }) => {
	const { pathname } = useLocation();
	const history = useHistory();

	const onClose = useCallback(() => {
		history.push(basePath);
	}, [basePath, history]);

	return (
		<Modal
			open={pathname === `${basePath}/statement`}
			onClose={onClose}
			className={className}
			title={TranslationService.get(['statement.title', 'Årsrapport'])}
		>
			<Statement onClose={onClose} />
		</Modal>
	);
})`
	.asteria-modal {
		width: 70%;
		max-width: 680px;
	}

	.asteria-statement {
		padding: 32px;
		.asteria-statement-inner {
			.asteria-icon-wrapper {
				max-width: 20px !important;
				max-height: 20px !important;
				margin-right: 8px !important;
				.asteria-icon-svg {
					fill: #c01f26 !important;
				}
			}
			.asteria-statement-table--row-accounting-results {
				.asteria-statement-table--row_inner_label,
				.asteria-statement-table--row_inner_value {
					.asteria-statement-table--cell {
						&:last-of-type {
							border-bottom: 0;
						}
					}
				}
			}
		}
	}

	.asteria-tabs-navigation {
		flex: 1 1 auto;
		min-width: 0;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-content: stretch;
		align-items: stretch;
		overflow-x: hidden;
		padding-bottom: 12px;
		margin-bottom: 16px;
		gap: 1px;
		.asteria-tab-navigation-item {
			background: #eaeaea;
			white-space: nowrap;
			position: relative;
			display: flex;
			flex-direction: row;
			justify-content: center;
			cursor: pointer !important;
			align-content: stretch;
			align-items: stretch;
			flex: 1;
			height: 32px;
			align-items: center;
			border-radius: 0;
			.asteria-text {
				justify-content: center;
				align-content: center;
				align-items: center;
				max-width: 100%;
				color: #262626 !important;
				font-family: Arial, Helvetica, sans-serif !important;
				font-weight: 400;
				text-decoration: none;
			}

			&.asteria-state-active {
				cursor: pointer;
				background: #fc0;
				&:after {
					content: ' ';
					position: absolute;
					top: 100%;
					left: 50%;
					pointer-events: none;
					border: 6px solid hsla(0, 0%, 7%, 0);
					border-top-color: rgba(18, 18, 18, 0);
					border-top-color: #fc0;
					margin-left: -6px;
				}
			}

			&.asteria-state-hover,
			&:hover {
				background: #fc0;
				cursor: default;
				&:after {
					content: ' ';
					position: absolute;
					top: 100%;
					left: 50%;
					pointer-events: none;
					border: 6px solid hsla(0, 0%, 7%, 0);
					border-top-color: rgba(18, 18, 18, 0);
					border-top-color: #fc0;
					margin-left: -6px;
				}
			}

			&:first-of-type {
				border-radius: 4px 0 0 4px;
			}
			&:last-of-type {
				border-radius: 0 4px 4px 0;
			}
		}
	}

	.asteria-tabs-wrapper {
		max-height: 50vh;
		overflow: auto;
		&::-webkit-scrollbar {
			background-color: transparent;
			width: 6px;
		}
		&::-webkit-scrollbar-track {
			background-color: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #babac0;
			border-radius: 16px;
			border: 0px solid rgba(255, 255, 255, 0);
		}
	}
`;

export default StatementModal;
export { NeedInput };
