import React, {
	useState,
	useCallback,
	useMemo,
	useContext,
	useEffect,
	useRef,
} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Getter, useStore } from '@asteria/utils';
import useIntersect from '@asteria/utils/hooks/useIntersect';
import { Title, Text } from '@asteria/component-core/typography';
import { FeatureFlag, useFeature } from '@asteria/component-featureflag';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import {
	setSelectedRow,
	fetchMoreListData,
	addIgnore,
	setListReverse,
	setItemAction,
} from '@asteria/services-datalayer/services/list/actions';
import {
	addDays,
	addMonths,
	addWeeks,
	addYears,
	endOfISOWeek,
	endOfMonth,
	endOfYear,
	format,
	isFuture,
	isSameISOWeek,
	isSameMonth,
	isSameYear,
	startOfISOWeek,
	startOfMonth,
	startOfYear,
} from 'date-fns';
import Icon from '@asteria/component-core/icon';
import SidePane from '@asteria/component-core/sidepane';
import Button from '@asteria/component-core/button';
import { TranslationService } from '@asteria/services-language';
import {
	LinearIndicator,
	ListLoader,
} from '@asteria/component-progressbar-indicator';
import {
	FormGroupV2,
	InputV2,
	CheckboxGroupV2,
	Dropdown,
	CheckboxGroup,
	TextInput,
} from '@asteria/component-form';
import {
	saveTransactions,
	removeTransactions,
	clearTransactionField,
	saveEditedTransactions,
} from '@asteria/services-datalayer/services/adjustable/actions';
import { useHistory } from '@asteria/utils/hooks';
import Tooltip from '@asteria/component-tooltip';
import { setActiveGroups } from '@asteria/services-datalayer/services/graph/actions';
import {
	addWidgetClass,
	removeWidgetClass,
	setCurrentDate,
} from '@asteria/services-datalayer/services/appstate/actions';
import CurrenciesView from '@asteria/component-graph/controllers/currency';
import getStoreValue from '@asteria/services-datalayer/utils/get';
import Tags from '../components/tags';
import SecurityBar from '../components/securityBar';
import AdjustableTransactions, {
	Transaction as AdjustableTransaction,
} from '../components/adjustable/transaction';
import InfoBox from '../components/infoBox';
import Row from './row';
import Cell from './cell';
import RowDetails from './details';
import { getVolatilityText } from '../utils';
import Adjust from '../components/adjustable/adjust';
import AddAdjustableTransaction from '../components/adjustable/addAdjustable';
import AdjustInlineTotal from '../components/adjustable/adjustInlineTotal';

const SCROLL_BOTTOM_MARGIN = 200;

const ControllerWrapper = styled.div``;
const ListWrapper = styled.div``;
const DetailsOverview = styled.div``;

const isSameTime = (a, b, size) => {
	if (size === 'week') {
		return isSameYear(a, b) && isSameISOWeek(a, b);
	}
	if (size === 'month') {
		return isSameYear(a, b) && isSameMonth(a, b);
	}
	if (size === 'year') {
		return isSameYear(a, b);
	}

	return false;
};

const addTimeslice = (date, size, count = 1) => {
	if (size === 'day') {
		return addDays(date, count);
	}
	if (size === 'week') {
		return addWeeks(date, count);
	}
	if (size === 'month') {
		return addMonths(date, count);
	}
	if (size === 'year') {
		return addYears(date, count);
	}

	return date;
};

const startOfTime = (date, size) => {
	if (size === 'week') {
		return startOfISOWeek(date);
	}
	if (size === 'month') {
		return startOfMonth(date);
	}
	if (size === 'year') {
		return startOfYear(date);
	}

	return startOfMonth(date);
};

const endOfTime = (date, size) => {
	if (size === 'week') {
		return endOfISOWeek(date);
	}

	if (size === 'month') {
		return endOfMonth(date);
	}

	if (size === 'year') {
		return endOfYear(date);
	}

	return endOfMonth(date);
};

const options = [
	{
		label: 'Status',
		value: '',
	},
	{
		label: 'Kundnamn',
		value: '',
	},
	{
		label: 'Beskrivning',
		value: '',
	},
	{
		label: 'Fakturadatum',
		value: '',
	},
	{
		label: 'Fakturanummer',
		value: '',
	},
	{
		label: 'Sannolikhet',
		value: '',
	},
	{
		label: 'Bankkonto',
		value: '',
	},
	{
		label: 'Förfallodatum',
		value: '',
	},
	{
		label: 'Betalningsdatum',
		value: '',
	},
	{
		label: 'Kategori',
		value: '',
	},
	{
		label: 'Belopp',
		value: '',
	},
];

const getRiskHigh = item => {
	if (item.status === 'PAID') {
		if (item?.oracle?.currency?.risk < 1) {
			return 0;
		}

		return item?.oracle?.currency?.max?.display?.total;
	}

	if (item.status === 'OVERDUE') {
		return 0;
	}

	return item?.oracle?.currency?.min?.display?.total;
};

const DropDownItem = styled(
	({
		className,
		active,
		onClick,
		setOpen,
		icon,
		iconSelected,
		option: { label } = {},
	}) => (
		<div
			className={classNames(className, {
				'asteria-state-active': active,
			})}
			onClick={() => {
				onClick();
				setOpen(false);
			}}
			onKeyPress={() => {
				onClick();
				setOpen(false);
			}}
			role="button"
			tabIndex="-1"
		>
			<FormGroupV2>
				<CheckboxGroupV2
					className="asteria-checkbox-group-wrapper-transactions-small"
					items={[{ label }]}
					selected={[0]}
					/* items={transactionListItems}
					onChange={() =>
						updateSettings({
							...userSettings,
							compressList: !compressList,
						})
					}
					selected={compressList ? CHOSEN_FIRST : CHOSEN_NONE} */
				/>
			</FormGroupV2>
			{icon && (!iconSelected || !active) ? <Icon asset={icon} /> : null}
			{iconSelected && active ? <Icon asset={iconSelected} /> : null}
		</div>
	),
)`
	cursor: pointer;
	display: flex;
	align-items: center;
	${Text} {
		flex-grow: 1;
	}
	${Icon} {
		width: 20px;
		height: 20px;
		flex-grow: 0;
	}
`;

const Empty = styled(Text)``;

const Header = styled(
	React.memo(
		({
			className,
			activeSort,
			activeOrder,
			isFuture: isHeaderFuture,
			isAllSelected = false,
			selectAll = () => {},
			setSort = () => {},
			setShowSettingsModal = () => {},
			setShowColumnsModal = () => {},
		}) => (
			<div
				className={classNames(className, 'asteria-transaction-filter')}
			>
				<Cell className="asteria-transaction-cell asteria-transaction-cell-status">
					{isHeaderFuture ? (
						<FeatureFlag feature="adjustable">
							<CheckboxGroup
								items={[{ value: 'selected', label: '' }]}
								selected={isAllSelected ? [0] : []}
								onChange={() => selectAll(!isAllSelected)}
							/>
						</FeatureFlag>
					) : null}
				</Cell>
				<Cell
					className={classNames(
						'asteria-transaction-cell',
						'asteria-transaction-cell-description',
						{
							'asteria-state-active':
								activeSort === 'description',
						},
					)}
				>
					<Button
						type="link"
						icon={
							(activeOrder === 'DESC' &&
								activeSort === 'description') ||
							activeSort !== 'description'
								? 'triangleDown'
								: 'triangleUp'
						}
						iconPosition="after"
						onClick={() => setSort('description')}
						title={TranslationService.get(
							'list.transactions.header.list.description',
						)}
					>
						{TranslationService.get(
							'list.transactions.header.list.description',
						)}
					</Button>
				</Cell>
				<Cell
					className={classNames(
						'asteria-transaction-cell',
						'asteria-transaction-cell-payment-date',
						{
							'asteria-state-active':
								activeSort === 'paymentdate',
						},
					)}
				>
					<Button
						type="link"
						icon={
							(activeOrder === 'DESC' &&
								activeSort === 'paymentdate') ||
							activeSort !== 'paymentdate'
								? 'triangleDown'
								: 'triangleUp'
						}
						iconPosition="after"
						onClick={() => setSort('paymentdate')}
						title={TranslationService.get(
							'list.transactions.header.list.paymentdate',
						)}
					>
						{TranslationService.get(
							'list.transactions.header.list.paymentdate',
						)}
					</Button>
				</Cell>
				<Cell
					className={classNames(
						'asteria-transaction-cell',
						'asteria-transaction-cell-categories',
						{
							'asteria-state-active': activeSort === 'categories',
						},
					)}
				>
					<Button
						type="link"
						icon={
							(activeOrder === 'DESC' &&
								activeSort === 'categories') ||
							activeSort !== 'categories'
								? 'triangleDown'
								: 'triangleUp'
						}
						iconPosition="after"
						onClick={() => setSort('categories')}
						title={TranslationService.get(
							'list.transactions.header.list.categories',
						)}
					>
						{TranslationService.get(
							'list.transactions.header.list.categories',
						)}
						<FeatureFlag feature="List header filter - subtext">
							<Text className="asteria-small">Subtext</Text>
						</FeatureFlag>
					</Button>
				</Cell>
				<FeatureFlag feature="List - flat status">
					<Cell
						className={classNames(
							'asteria-transaction-cell',
							'asteria-transaction-cell-flat-status',
							{
								'asteria-state-active': activeSort === 'status',
							},
						)}
					>
						<Button
							type="link"
							icon={
								(activeOrder === 'DESC' &&
									activeSort === 'status') ||
								activeSort !== 'status'
									? 'triangleDown'
									: 'triangleUp'
							}
							iconPosition="after"
							onClick={() => setSort('status')}
							title={TranslationService.get(
								'list.transactions.header.list.status',
							)}
						>
							{TranslationService.get(
								'list.transactions.header.list.status',
							)}
							<FeatureFlag feature="List header filter - subtext">
								<Text className="asteria-small">Subtext</Text>
							</FeatureFlag>
						</Button>
					</Cell>
				</FeatureFlag>
				<FeatureFlag feature="listSettingsInline">
					<Cell
						className={classNames(
							'asteria-transaction-cell',
							'asteria-transaction-cell-settings',
							{
								'asteria-state-active':
									activeSort === 'categories',
							},
						)}
					>
						<Dropdown
							className={className}
							size="medium"
							type="link"
							iconClosed="settings"
							iconOpen="settings"
							direction={['down', 'left']}
							options={options}
							item={DropDownItem}
						/>
					</Cell>
				</FeatureFlag>
				<FeatureFlag feature="fx-module">
					<Cell
						className={classNames(
							'asteria-transaction-cell',
							'asteria-transaction-cell-risk-low',
							{
								'asteria-state-active':
									activeSort === 'risk-low',
							},
						)}
					>
						<Button
							type="link"
							icon={
								(activeOrder === 'DESC' &&
									activeSort === 'risk-low') ||
								activeSort !== 'risk-low'
									? 'triangleDown'
									: 'triangleUp'
							}
							iconPosition="after"
							onClick={() => setSort('risk-low')}
							title={TranslationService.get(
								'list.transactions.header.list.risk-low',
							)}
						>
							{TranslationService.get(
								'list.transactions.header.list.risk-low',
							)}
						</Button>
					</Cell>
					<Cell
						className={classNames(
							'asteria-transaction-cell',
							'asteria-transaction-cell-risk-high',
							{
								'asteria-state-active':
									activeSort === 'risk-high',
							},
						)}
					>
						<Button
							type="link"
							icon={
								(activeOrder === 'DESC' &&
									activeSort === 'risk-high') ||
								activeSort !== 'total'
									? 'triangleDown'
									: 'triangleUp'
							}
							iconPosition="after"
							onClick={() => setSort('risk-high')}
							title={TranslationService.get(
								'list.transactions.header.list.risk-high',
							)}
						>
							{TranslationService.get(
								'list.transactions.header.list.risk-high',
							)}
						</Button>
					</Cell>
					<Cell
						className={classNames(
							'asteria-transaction-cell',
							'asteria-transaction-cell-risk',
							{
								'asteria-state-active': activeSort === 'risk',
							},
						)}
					>
						<Button
							type="link"
							icon={
								(activeOrder === 'DESC' &&
									activeSort === 'risk') ||
								activeSort !== 'risk'
									? 'triangleDown'
									: 'triangleUp'
							}
							iconPosition="after"
							onClick={() => setSort('risk')}
							title={TranslationService.get(
								'list.transactions.header.list.risk',
							)}
						>
							{TranslationService.get(
								'list.transactions.header.list.risk',
							)}
						</Button>
					</Cell>
				</FeatureFlag>
				<Cell
					className={classNames(
						'asteria-transaction-cell',
						'asteria-transaction-cell-total',
						{
							'asteria-state-active': activeSort === 'total',
						},
					)}
				>
					<Button
						type="link"
						icon={
							(activeOrder === 'DESC' &&
								activeSort === 'total') ||
							activeSort !== 'total'
								? 'triangleDown'
								: 'triangleUp'
						}
						iconPosition="after"
						onClick={() => setSort('total')}
						title={TranslationService.get(
							'list.transactions.header.list.total',
						)}
					>
						{TranslationService.get(
							'list.transactions.header.list.total',
						)}
					</Button>
				</Cell>
				<Cell
					className={classNames(
						'asteria-transaction-cell',
						'asteria-transaction-cell-currency',
						{
							'asteria-state-active': activeSort === 'currency',
						},
					)}
				>
					<Button
						type="link"
						icon={
							(activeOrder === 'DESC' &&
								activeSort === 'currency') ||
							activeSort !== 'currency'
								? 'triangleDown'
								: 'triangleUp'
						}
						iconPosition="after"
						onClick={() => setSort('currency')}
						title={TranslationService.get(
							'list.transactions.header.list.currency',
						)}
					>
						{TranslationService.get(
							'list.transactions.header.list.currency',
						)}
					</Button>
				</Cell>
				<Cell className="asteria-transaction-cell asteria-transaction-cell-security">
					<Title size="title4" />
				</Cell>
				<Cell className="asteria-transaction-cell asteria-transaction-cell-toggle">
					<Title size="title4" />
				</Cell>

				<FeatureFlag feature="asteria-improvements">
					<Cell className="asteria-transaction-cell asteria-transaction-cell-settings">
						<Dropdown
							size="medium"
							type="link"
							iconClosed="menudots"
							iconOpen="circleClose"
							direction={['down', 'left']}
							className="asteria-button-list-settings"
							options={[
								{
									label: 'list.settings.dropdown.columns',
									value: 'columns',
								},
								{
									label: 'list.settings.dropdown.settings',
									value: 'settings',
								},
							]}
							onChange={({ value: selectedValue }) => {
								if (selectedValue === 'columns') {
									setShowColumnsModal(true);
								} else if (selectedValue === 'settings') {
									setShowSettingsModal(true);
								}
							}}
						/>
					</Cell>
				</FeatureFlag>
			</div>
		),
	),
)`
	display: flex;
`;

Header.displayName = 'Header';

Header.Styler = {
	children: [
		{
			component: Cell,
			base: [Getter('cell')],
		},
	],
};

const DetailsWrapper = styled.div``;

const Details = styled.div``;

const getDescription = description => {
	if (description.startsWith('$')) {
		return TranslationService.get(
			`list.transactions.transaction.description.${description.replace(
				'$',
				'',
			)}`,
		);
	}

	return description;
};

const Transaction = styled(
	React.memo(
		({
			className,
			onClick,
			active = false,
			editGroup = false,
			edits,
			item = {},
			tagFilter = [],
		}) => {
			const [edit, setEditing] = useState(false);
			const [editRepeatable, setEditRepeatable] = useState(false);
			const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
			const [showEditConfirm, setShowEditConfirm] = useState(false);
			// const editSidepan = useFeature({
			// 	key: 'sidepane-transaction-adjustable-edit',
			// });

			const setEdit = useCallback((val, repeatable = false) => {
				// history.push('/');
				setEditRepeatable(repeatable);
				setEditing(val);
			}, []);

			const [isSelected] = useStore(
				'store-list',
				({ state: { selected = [] } = {} }) =>
					selected.find(({ id }) => id === item.id),
			);

			const [actions = {}] = useStore(
				'store-list',
				({ actions: list = [] }) =>
					list.find(({ transaction: { id } = {} }) => id === item.id),
			);

			useEffect(() => {
				if (editGroup) {
					setEditing(false);
				}
			}, [editGroup]);

			const isNew = actions && actions.operation === 'CREATE';

			return (
				<div
					className={classNames(
						className,
						{
							'asteria-state-active': active,
							'asteria-transaction-single-edit': edit,
							'asteria-transaction-selected': isSelected,
							'asteria-transaction-action-remove':
								edits?.$removed,
							'asteria-transaction-action-create': item?.$new,
							'asteria-transaction-action-modify':
								edits && !edits?.$removed,
							[`asteria-transaction-action-${actions?.operation?.toLowerCase()}`]:
								actions && actions.operation,
						},
						`asteria-transaction`,
						`asteria-transaction-status-${item?.status?.toLowerCase()}`,
						`asteria-transaction-type-${item?.type?.toLowerCase()}`,
						item?.meta?.tags
							?.slice(0, 1)
							?.map(
								tag =>
									`asteria-transaction-tagged-${tag?.name?.replace(
										'$',
										'',
									)}`,
							),
					)}
				>
					<FeatureFlag feature="list-divider-inline-edit" invert>
						<Row
							item={item}
							onClick={isNew ? () => {} : onClick}
							tagFilter={tagFilter}
							actions={actions}
							setEdit={setEdit}
						/>
						{edit && (
							<TransactionEdit
								className={className}
								item={item}
								editRepeatable={editRepeatable}
								setEdit={e => {
									onClick(e ? item : e);
									setEdit(e);
								}}
							/>
						)}
					</FeatureFlag>
					<FeatureFlag feature="list-divider-inline-edit">
						<Row
							edit={editGroup || edit}
							editGroup={editGroup}
							edits={edits}
							item={item}
							onClick={isNew ? () => {} : onClick}
							tagFilter={tagFilter}
							actions={actions}
							setEdit={setEdit}
						/>
					</FeatureFlag>
					<FeatureFlag feature="sidepane-transaction-detail" invert>
						<FeatureFlag feature="list-divider-inline-edit" invert>
							{!edit && (
								<RowDetails
									className="asteria-transaction-detail"
									item={item}
									setEdit={setEdit}
									edit={edit}
									setActive={onClick}
								/>
							)}
						</FeatureFlag>
						<FeatureFlag feature="list-divider-inline-edit">
							<RowDetails
								className="asteria-transaction-detail"
								item={item}
								setEdit={setEdit}
								edit={edit}
								setActive={onClick}
							/>
						</FeatureFlag>
					</FeatureFlag>
					<FeatureFlag feature="sidepane-transaction-detail">
						<SidePane
							title={TranslationService.get(
								'sidepanel.transaction.title',
							)}
							className="asteria-sidepane-transaction-details"
							open={active && !edit}
							onClose={() => onClick(false)}
							actions={
								showRemoveConfirm === false &&
								item?.status === 'FORECAST'
									? [
											{
												title: TranslationService.get(
													'button.remove',
												),
												action: e => {
													e.stopPropagation();
													setShowRemoveConfirm(true);
												},
												type: 'default',
											},
											{
												title: TranslationService.get(
													'button.edit',
												),
												action: e => {
													if (item?.recurrentEvent) {
														setShowEditConfirm(
															true,
														);
													} else {
														setEdit(true);
													}
													e.stopPropagation();
												},
												type: 'secondary',
											},
									  ]
									: []
							}
							setActive={onClick}
						>
							<RowDetails
								item={item}
								setEdit={setEdit}
								showRemove={showRemoveConfirm}
								setRemove={setShowRemoveConfirm}
								showEditConfirm={showEditConfirm}
								setEditConfirm={setShowEditConfirm}
							/>
						</SidePane>
					</FeatureFlag>
				</div>
			);
		},
	),
)`

	&.asteria-transaction-single-edit {
		.asteria-transaction-cell-remove {
			visible: hidden;
		}
	}

	.asteria-adjustable-transaction-accordion-toggle {
		padding-left: 0 !important;
		padding-right: 0 !important;
		width: 100% !important;
		padding-bottom: 0 !important;
		.asteria-text {
			flex-grow: 1;
			text-align: left;
			font-family: Swedbank Headline !important;
			font-weight: 400 !important;
			color: var(--title-color) !important;
			font-size: 14px !important;
			text-decoration: none !important;
		}
		.asteria-icon-wrapper {
			margin-left: auto;
			width: 16px !important;
			svg {
				fill: #9f8c82 !important;
				width: auto;
			}
		}
		&.asteria-state.active {
			overflow: visible !important:
		}
	}

	.asteria-button-remove {
		// padding: 0 !important;
		// margin-bottom: 4px !important;
		// margin-left: 0 !important;
		// margin-top: 8px !important;
		// margin-right: auto !important;
	}
`;

const TransactionEdit = styled(
	React.memo(
		({ className, item = {}, editRepeatable, setEdit = () => {} }) => {
			const { dispatch } = useContext(DatalayerContext);
			const [updated, setUpdated] = useState(item);

			const [actions = []] = useStore(
				'store-list',
				({ actions: list }) => list,
			);

			const el = (
				<div
					className={classNames(
						className,
						`asteria-transaction-status-${item?.status?.toLowerCase()}`,
						`asteria-transaction-type-${item?.type?.toLowerCase()}`,
						'asteria-transaction-edit',
					)}
				>
					<FeatureFlag feature="list-divider-inline-edit">
						<AdjustableTransaction
							className="asteria-adjustable-transaction-inline"
							transaction={item}
							index={0}
							update={(index, data) => setUpdated(data)}
							remove={() => dispatch(removeTransactions([item]))}
							editRepeatable={editRepeatable}
						/>
					</FeatureFlag>
					<FeatureFlag feature="list-divider-inline-edit" invert>
						<AdjustableTransaction
							transaction={item}
							index={0}
							update={(index, data) => setUpdated(data)}
							remove={() => dispatch(removeTransactions([item]))}
							editRepeatable={editRepeatable}
						/>
					</FeatureFlag>
					<FeatureFlag
						feature="sidepane-transaction-adjustable-edit"
						invert
					>
						<ControllerWrapper className="asteria-adjustable-controllers">
							<Button
								size="medium"
								type="default"
								text={TranslationService.get('button.abort')}
								className="asteria-button-cancel"
								onClick={e => {
									e.stopPropagation();
									setEdit(false);
								}}
							/>
							<Button
								size="medium"
								type="primary"
								text={TranslationService.get('button.save')}
								className="asteria-button-save"
								onClick={e => {
									setEdit(false);

									// updated.meta.description = updated.description;
									// delete updated.description;

									dispatch(
										saveTransactions(
											[updated],
											item,
											editRepeatable,
										),
									);
									e.stopPropagation();
								}}
							/>
						</ControllerWrapper>
					</FeatureFlag>
				</div>
			);

			return (
				<>
					<FeatureFlag
						feature="sidepane-transaction-adjustable-edit"
						invert
					>
						{el}
					</FeatureFlag>
					<FeatureFlag feature="sidepane-transaction-adjustable-edit">
						<SidePane
							title={TranslationService.get(
								'sidepanel.transaction.edit.title',
							)}
							className="asteria-sidepane-manage-transaction"
							open
							onClose={() => setEdit(false)}
							actions={[
								{
									title: TranslationService.get(
										'button.remove',
									),
									action: e => {
										e.stopPropagation();
										dispatch(removeTransactions([item]));
									},
									type: 'default',
								},
								{
									title: TranslationService.get(
										'button.save',
									),
									action: e => {
										setEdit(false);
										dispatch(
											saveTransactions(
												[updated],
												item,
												editRepeatable,
											),
										);
										if (
											updated &&
											updated.id &&
											actions.length > 0
										) {
											dispatch(addIgnore(updated.id));
										}
										e.stopPropagation();
									},
									type: 'primary',
								},
							]}
						>
							{el}
						</SidePane>
					</FeatureFlag>
				</>
			);
		},
	),
)``;

TransactionEdit.displayName = 'TransactionEdit';

Transaction.displayName = 'Transaction';

Transaction.Styler = {
	typePrefix: 'asteria-transaction',
	children: [
		{
			component: Row,
			base: [Getter('info')],
			children: [
				{
					component: Cell,
					base: [Getter('cell')],
				},
			],
		},
		{
			component: DetailsWrapper,
			base: [Getter('detailswrapper')],
			children: [
				{
					component: Details,
					base: [Getter('details')],
					children: [
						{
							component: Cell,
							base: [Getter('cell')],
						},
					],
				},
			],
		},
		{
			component: RowDetails,
			base: [Getter('detailswrapper')],
		},
		{
			component: Title,
			base: [Getter('title')],
		},
		{
			component: Text,
			base: [Getter('text')],
		},
		{
			component: Icon,
			base: [Getter('icon')],
		},
		{
			component: Tags,
			base: [Getter('tags')],
		},
		{
			component: SecurityBar,
			base: [Getter('security')],
		},
		{
			component: Button,
			base: [Getter('button')],
		},
		{
			component: AdjustableTransaction,
			base: [Getter('adjustable')],
		},
		{
			component: ControllerWrapper,
			base: [Getter('controllers')],
			children: [
				{
					component: Button,
					base: [Getter('button')],
				},
			],
		},
		{
			component: InfoBox,
			base: [Getter('confirm')],
			children: [
				{
					component: Button,
					base: [Getter('button')],
				},
			],
		},
	],
};

const GET_CURRENCIES_EXPOSURE = `
query Exposure($filters: TransactionFiltersInput){
	transactionCurrencies(filters: $filters) {
	  currency
	  deposit {
		original {
		  total
		  currency
		}
		display {
		  total
		  currency
		}
		exposure {
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		tags {
		  tag {
			id
			name
		  }
		  type
		  parts {
			status
			count
			original {
			  total
			  currency
			}
			display {
			  total
			  currency
			}
		  }
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		count
	  }
  
	  withdraw {
		original {
		  total
		  currency
		}
		display {
		  total
		  currency
		}
		exposure {
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		tags {
		  tag {
			id
			name
		  }
		  type
		  parts {
			status
			count
			original {
			  total
			  currency
			}
			display {
			  total
			  currency
			}
		  }
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		count
	  }
  
	  exposure {
		original {
		  total
		  currency
		}
		display {
		  total
		  currency
		}
	  }

	  risk {
		  original {
			  total
			  currency
		  }
		  display {
			  total
			  currency
		  }
		  risk
		  alert
	  }
	}
  }
  
`;

const ListDivider = styled(
	React.memo(props => {
		const {
			className,
			date,
			types,
			header,
			type: headerType = false,
			total = false,
			security = false,
			setEdit = () => {},
			save = () => {},
			max = false,
			min = false,
			watch,
			unwatch,
		} = props;

		const { lookup, dispatch } = useContext(DatalayerContext);
		const apiService = lookup('service-api');
		const [exposure, setExposure] = useState(0);
		const [suggestions, setSuggestions] = useState([]);
		const el = useRef(null);
		const [showCurrencyInfo, setShowCurrencyInfo] = useState(false);
		const [showAdjustableTotal, setShowAdjustableTotal] = useState(false);
		const [showEdit, setShowEdit] = useState(false);
		const [currencies, setCurrencies] = useState([]);
		const hasListDividerInlineEdit = useFeature({
			key: 'list-divider-inline-edit',
		});

		const hasGraphFollowList = useFeature({
			key: 'graph-follow-list',
		});

		const hasFxModule = useFeature({
			key: 'fx-module',
		});

		const [filters = []] = useStore(
			'store-appstate',
			({ filters: list }) => list,
		);

		const [timeSize = 'month'] = useStore(
			'store-list',
			({ size = 'month' }) => size,
		);

		const [actions = []] = useStore(
			'store-list',
			({ actions: list }) => list,
		);

		const startDate = useMemo(
			() =>
				`${format(
					startOfTime(date, timeSize),
					'YYYY-MM-DD',
				)}T00:00:00.000Z`,
			[date, timeSize],
		);

		const endDate = useMemo(
			() =>
				`${format(
					endOfTime(date, timeSize),
					'YYYY-MM-DD',
				)}T23:59:59.999Z`,
			[date, timeSize],
		);

		const update = useCallback(
			(visible, index) => {
				if (visible && date && index === 0) {
					const currentDate = startOfTime(
						addTimeslice(date, timeSize, -4),
						timeSize,
					);

					dispatch(setCurrentDate(currentDate));

					dispatch(
						setActiveGroups('cashflow-bar-graph', [
							format(
								addTimeslice(currentDate, timeSize, 4),
								'YYYY-MM-DD[T00:00:00.000Z]',
								{
									locale: TranslationService.getLocale(),
								},
							),
						]),
					);
				}
			},
			[date, dispatch, timeSize],
		);

		useEffect(
			() => () => {
				if (unwatch) {
					unwatch(el);
				}
			},
			[unwatch],
		);

		if (watch && hasGraphFollowList) {
			watch(el, update);
		}

		useEffect(() => {
			if (date) {
				let status = ['PAID', 'UNPAID', 'OVERDUE', 'FORECAST'];

				if ((filters || []).some(({ type }) => type === 'status')) {
					status = (filters || [])
						.filter(({ type }) => type === 'status')
						.map(
							({ config: { status: filterStatus } }) =>
								filterStatus,
						);
				}

				// ['PAID', 'UNPAID', 'OVERDUE', 'FORECAST']
				const search = {};

				if (
					filters &&
					filters.length > 0 &&
					filters.some(({ type }) => type === 'tag')
				) {
					search['meta.tags'] = {
						$in: filters
							.filter(({ type }) => type === 'tag')
							.map(({ id }) => id),
					};
				}

				if (
					filters &&
					filters.length > 0 &&
					filters.some(({ type }) => type === 'currency')
				) {
					search['sums.original.currency'] = {
						$in: filters
							.filter(({ type }) => type === 'currency')
							.map(({ config: { name } }) => name),
					};
				}

				apiService
					.query(
						GET_CURRENCIES_EXPOSURE,
						{
							filters: {
								startDate: `${format(
									startOfTime(date, timeSize),
									'YYYY-MM-DD',
								)}T00:00:00.000Z`,
								endDate: `${format(
									endOfTime(date, timeSize),
									'YYYY-MM-DD',
								)}T23:59:59.999Z`,
								types,
								status,
								search,
							},
						},
						{ reqAuth: true },
					)
					.subscribe(response => {
						const transactionCurrencies =
							response.data?.transactionCurrencies || [];
						if (types.length === 1) {
							const [showType] = types;
							setExposure(
								transactionCurrencies.reduce(
									(acc, item) =>
										acc +
										Math.abs(
											item?.[showType.toLowerCase()]
												?.exposure?.display?.total || 0,
										),
									0,
								),
							);
						} else {
							setExposure(
								transactionCurrencies.reduce(
									(acc, item) =>
										acc +
										Math.abs(
											item?.exposure?.display?.total || 0,
										),
									0,
								),
							);
						}
						setCurrencies(transactionCurrencies);
					});
			}
		}, [apiService, date, filters, timeSize, types]);

		const currencyRisk = useMemo(() => {
			if (!hasFxModule) {
				return 0;
			}

			if (types.length === 1) {
				const [showType] = types;
				return currencies.reduce(
					(acc, item) =>
						acc +
							item?.[showType.toLowerCase()]?.risk?.display
								?.total || 0,
					0,
				);
			}

			return currencies.reduce(
				(acc, item) => acc + item?.risk?.display?.total || 0,
				0,
			);
		}, [currencies, hasFxModule, types]);

		const displayCurrency = useMemo(
			() => currencies?.[0]?.risk?.display?.total || 'SEK',
			[currencies],
		);

		const totalTooltipContent = [];

		if (max !== min) {
			totalTooltipContent.push(
				<p key="tooltip-text">
					{TranslationService.get(
						['list.divider.total.tooltip'],
						'',
						{
							max,
							min,
						},
					)}
				</p>,
			);
		}

		const riskTooltipContent = useMemo(() => {
			if (currencyRisk) {
				return [
					<Text key="curency-text">
						{TranslationService.get(
							'list.divider.risk.text',
							'Du har en valutarisk i följande valutor',
						)}
					</Text>,
					currencies.filter(
						({
							risk: {
								display: { total: totalRisk },
							},
						}) => totalRisk !== 0,
					).length > 1 && (
						<div
							key="currency-multiple"
							className={classNames(
								'asteria-transactions-total-tooltip-currency-multiple',
							)}
						>
							<div className="asteria-part-risk" />
							<Text
								className={classNames(
									'asteria-transactions-total-tooltip-currency-label',
								)}
							>
								{TranslationService.get(
									`list.total.tooltip.currency.risk.multiple.label`,
									'Valutarisk i {{currencies.length}} valutor',
									{
										currencies: currencies.filter(
											({
												risk: {
													display: {
														total: totalRisk,
													},
												},
											}) => totalRisk !== 0,
										),
									},
								)}
							</Text>
							<Text
								className={classNames(
									'asteria-transactions-total-tooltip-currency-value',
								)}
							>
								{TranslationService.get(
									`list.total.tooltip.currency.value`,
									'{{currencyRisk | number : false : false : true}} {{currency}}',
									{
										currencyRisk,
										currency: displayCurrency,
									},
								)}
							</Text>
						</div>
					),
					...currencies
						.filter(
							({
								risk: {
									display: { total: totalRisk },
								},
							}) => totalRisk !== 0,
						)
						.map(currency => (
							<div
								key={`currency-${currency.currency}`}
								className={classNames(
									'asteria-transactions-total-tooltip-currency',
								)}
							>
								<div
									className={`currency-flag currency-flag-${currency.currency?.toLowerCase()}`}
								/>
								<Text
									className={classNames(
										'asteria-transactions-total-tooltip-currency-label',
									)}
								>
									{TranslationService.get(
										`list.total.tooltip.currency.label`,
										'{{currency}}',
										currency,
									)}
								</Text>
								<Text
									className={classNames(
										'asteria-transactions-total-tooltip-currency-value',
									)}
								>
									{TranslationService.get(
										`list.total.tooltip.currency.value`,
										'{{risk.display.total | number : false : false : true}} {{risk.display.currency}}',
										currency,
									)}
								</Text>
							</div>
						)),
				];
			}

			return [];
		}, [currencies, currencyRisk, displayCurrency]);

		const exposureTooltipContent = useMemo(() => {
			if (
				currencies &&
				currencies.some(
					({
						exposure: {
							display: { total: totalExposure },
						},
					}) => totalExposure !== 0,
				)
			) {
				return [
					<Text key="curency-text">
						{TranslationService.get(
							'list.divider.exposure.text',
							'Du har en valutaexponering i följande valutor',
						)}
					</Text>,
					currencies.filter(
						({
							exposure: {
								display: { total: totalExposure },
							},
						}) => totalExposure !== 0,
					).length > 1 && (
						<div
							key="currency-multiple"
							className={classNames(
								'asteria-transactions-total-tooltip-currency-multiple',
							)}
						>
							<div className="asteria-part-exposure" />
							<Text
								className={classNames(
									'asteria-transactions-total-tooltip-currency-label',
								)}
							>
								{TranslationService.get(
									`list.total.tooltip.currency.risk.multiple.label`,
									'Exponering i {{currencies.length}} valutor',
									{
										currencies: currencies.filter(
											({
												exposure: {
													display: {
														total: totalExposure,
													},
												},
											}) => totalExposure !== 0,
										),
									},
								)}
							</Text>
							<Text
								className={classNames(
									'asteria-transactions-total-tooltip-currency-value',
								)}
							>
								{TranslationService.get(
									`list.total.tooltip.currency.risk.value`,
									'{{exposure | number : false : false : true}}',
									{
										exposure,
									},
								)}
							</Text>
						</div>
					),
					...currencies
						.filter(
							({
								exposure: {
									display: { total: totalExposure },
								},
							}) => totalExposure !== 0,
						)
						.map(currency => (
							<div
								key={`currency-${currency.currency}`}
								className={classNames(
									'asteria-transactions-total-tooltip-currency',
								)}
							>
								<div
									className={`currency-flag currency-flag-${currency.currency?.toLowerCase()}`}
								/>
								<Text
									className={classNames(
										'asteria-transactions-total-tooltip-currency-label',
									)}
								>
									{TranslationService.get(
										`list.total.tooltip.currency.risk.label`,
										'{{currency}}',
										currency,
									)}
								</Text>
								<Text
									className={classNames(
										'asteria-transactions-total-tooltip-currency-value',
									)}
								>
									{TranslationService.get(
										`list.total.tooltip.currency.risk.value`,
										'{{exposure.display.total | number : false : false : true}}',
										currency,
									)}
								</Text>
							</div>
						)),
				];
			}

			return [];
		}, [currencies, exposure]);

		const showAdjustableSuggestions = useCallback(
			newTotal => {
				const targetType = types.length > 1 ? 'ACCOUNT' : types[0];

				const edits = getStoreValue(
					lookup('store-adjustable'),
					({ edits: val }) => val,
				);

				apiService
					.query(
						`
				query Suggestions($target: Float!, $startDate: Date!, $endDate: Date!, $type: String!, $ignore: [ID]) {
					suggestModifications(target: $target, startDate: $startDate, endDate: $endDate, type: $type, ignore: $ignore) {
						operation
						transaction {
							id
							type
							status
							paymentDate
							sums {
								original {
									total
									currency
								}
							}
							meta {
								description
								categories {
									id
									name
								}
								tags {
									id
									_id
									name
									category {
										id
										_id
										name
									}
								}
							}
							description
							message
							oracle {
								risk
								max {
									original {
										total
										currency
									}
								}
								min {
									original {
										total
										currency
									}
								}
								forecast {
									risk
									max {
										display {
											total
											currency
										}
									}
									min {
										display {
											total
											currency
										}
									}
								}
								currency {
									risk
									max {
										display {
											total
											currency
										}
									}
									min {
										display {
											total
											currency
										}
									}
								}
							}
							manual

							links {
								id
								type
								invoiceDetails: details {
									... on Invoice {
										dates {
											invoiceSent
											invoiceDue
											invoicePaid
										}
										client {
											id
											name
										}
									}
								}
								accountDetails: details {
									... on BankAccountTransaction {
										identifiers {
											accountNumber
										}
										dates {
											booked
										}
										sums {
											account {
												total
												currency
												rate
											}
										}
									}
								}
							}
						}
						fields
					}
					}
				`,
						{
							endDate,
							startDate,
							target:
								targetType !== 'ACCOUNT'
									? Math.abs(newTotal)
									: newTotal,
							type: targetType,
							ignore: Object.keys(edits),
						},
						{ reqAuth: true },
					)
					.subscribe(resp => {
						const items = resp?.data?.suggestModifications || [];
						dispatch(
							setItemAction([
								...actions.filter(
									item => !suggestions.includes(item),
								),
								...(resp?.data?.suggestModifications || []),
							]),
						);

						setSuggestions(items);
					});
			},
			[
				actions,
				apiService,
				dispatch,
				endDate,
				lookup,
				startDate,
				suggestions,
				types,
			],
		);

		return (
			<div
				ref={el}
				className={classNames(
					className,
					'asteria-transactions-divider',
					{
						'asteria-transactions-divider-feature-inline-edit': hasListDividerInlineEdit,
					},
				)}
			>
				<div className={classNames('asteria-transactions-divider-row')}>
					<Title size="title3" className="asteria-title-divider">
						{header}
					</Title>
					<FeatureFlag feature="currency-list-divider">
						{exposure !== 0 ? (
							<Tooltip
								key="asteria-list-divider-risk-tooltip-exposure"
								hover
								isStatic
								style={{ maxWidth: '190px', minWidth: '0' }}
								content={exposureTooltipContent}
								className={classNames({
									'asteria-list-divider-risk-tooltip-multiple':
										currencies.filter(
											({
												exposure: {
													display: {
														total: totalExposure,
													},
												},
											}) => totalExposure !== 0,
										).length > 1,
								})}
							>
								<Text
									size="body"
									className="asteria-amount-exposure"
								>
									<span>
										{TranslationService.get(
											'list.header.label.exposure',
											'Exponering:',
										)}
									</span>
									{TranslationService.get(
										[
											'list.transactions.divider.exposure',
											`list.transactions.divider.${headerType}.exposure`,
										],
										'{{exposure | number : false: false: true }}',
										{ exposure },
									)}
								</Text>
							</Tooltip>
						) : null}
						{currencyRisk !== 0 ? (
							<Tooltip
								key="asteria-list-divider-risk-tooltip-risk"
								hover
								isStatic
								style={{ maxWidth: '190px', minWidth: '0' }}
								className={classNames({
									'asteria-list-divider-risk-tooltip-multiple':
										currencies.filter(
											({
												risk: {
													display: {
														total: totalRisk,
													},
												},
											}) => totalRisk !== 0,
										).length > 1,
								})}
								content={riskTooltipContent}
							>
								<Text
									size="body"
									className="asteria-amount-risk"
								>
									<span>
										{TranslationService.get(
											'list.header.label.risk',
											'Risk:',
										)}
									</span>
									{TranslationService.get(
										[
											'list.transactions.divider.risk',
											`list.transactions.divider.${headerType}.risk`,
										],
										'{{currencyRisk | number : false: false: true }}',
										{ currencyRisk },
									)}
								</Text>
							</Tooltip>
						) : null}
					</FeatureFlag>
					{total !== false ? (
						<Tooltip
							hover
							isStatic
							style={{ maxWidth: '190px', minWidth: '0' }}
							content={
								totalTooltipContent.length > 0
									? totalTooltipContent
									: false
							}
						>
							<Text
								size="body"
								className="asteria-amount-divider"
							>
								{TranslationService.get(
									[
										'list.transactions.divider.total',
										`list.transactions.divider.${headerType}.total`,
									],
									'',
									{ total },
								)}
							</Text>
						</Tooltip>
					) : null}
					{showEdit && isFuture(endDate) && types.length === 2 && (
						<div className="asteria-adjustable-total-account-balance">
							<Tooltip
								hover
								isStatic
								title=""
								content={TranslationService.get(
									'list.transactions.divider.asteria-adjustable-help-text',
									'Ändra belopp för en period går bara på in-/utgående läge, klicka på staplarna ovan för ändra.',
								)}
							>
								<Icon asset="info" />
							</Tooltip>
							<FormGroupV2>
								<InputV2
									className="asteria-input"
									placeholder={TranslationService.get(
										'adjustable.total.accountbalance.placeholder',
										'Fyll i beloppet',
									)}
									invertedcolor
									disabled
								/>
							</FormGroupV2>
						</div>
					)}
					{showEdit && isFuture(endDate) && types.length === 1 && (
						<FeatureFlag feature="list-divider-inline-edit">
							<AdjustInlineTotal
								className="list-divider-inline-edit"
								startDate={startDate}
								endDate={endDate}
								total={total}
								onChange={showAdjustableSuggestions}
							/>
						</FeatureFlag>
					)}
					{security !== false ? (
						<Tooltip
							hover
							isStatic
							title=""
							content={TranslationService.get(
								'list.security.tooltip',
								'Sannolikhet {{volatilityText}}%',
								{
									volatilityText: getVolatilityText(
										security || 0,
									),
								},
							)}
						>
							<SecurityBar value={security} max={1} steps={5} />
						</Tooltip>
					) : null}
					<FeatureFlag feature="currency-list-divider">
						{(exposure !== 0 || currencyRisk !== 0) && (
							<Button
								key="asteria-currency-show"
								className="asteria-currency-show"
								icon={showCurrencyInfo ? 'up' : 'down'}
								type="link"
								title={TranslationService.get(
									'list.transactions.currency.title.tooltip',
								)}
								onClick={() => {
									setShowCurrencyInfo(!showCurrencyInfo);
								}}
							/>
						)}
					</FeatureFlag>
					<FeatureFlag feature="list-divider-inline-edit">
						{isFuture(endDate) && (
							<Button
								key="asteria-currency-show"
								className="asteria-adjustable-total"
								icon={showEdit ? 'checkFilled' : 'circlePen'}
								type="link"
								title={TranslationService.get(
									showEdit
										? 'list.transactions.divider.adjustable.save.tooltip'
										: 'list.transactions.divider.adjustable.edit.tooltip',
								)}
								onClick={() => {
									if (showEdit) {
										save();
									}

									setEdit(!showEdit);
									setShowEdit(!showEdit);
								}}
							/>
						)}
						{isFuture(endDate) && showEdit && (
							<Button
								key="asteria-currency-cancel-edit"
								className="asteria-adjustable-total-cancel"
								text={TranslationService.get(
									'list.transactions.divider.adjustable.edit.cancel',
									'Avbryt',
								)}
								type="link"
								size="small"
								title={TranslationService.get(
									'list.transactions.divider.adjustable.edit.cancel.tooltip',
								)}
								onClick={() => {
									setEdit(false);
									setShowEdit(false);
								}}
							/>
						)}
					</FeatureFlag>
					<FeatureFlag feature="list-divider-inline-edit" invert>
						{isFuture(endDate) && (
							<Button
								key="asteria-currency-show"
								className="asteria-adjustable-total"
								icon={
									showAdjustableTotal
										? 'checkFilled'
										: 'circlePen'
								}
								type="link"
								title={TranslationService.get(
									'list.transactions.divider.adjustable.title.tooltip',
								)}
								onClick={() => {
									setShowAdjustableTotal(
										!showAdjustableTotal,
									);
								}}
							/>
						)}
					</FeatureFlag>
					{isFuture(endDate) && (
						<FeatureFlag feature="list-divider-inline-add-adjustable">
							<AddAdjustableTransaction />
						</FeatureFlag>
					)}
				</div>
				{showCurrencyInfo && (
					<CurrenciesView
						isTabActive
						data={currencies}
						setShow={() => {
							setShowCurrencyInfo(false);
						}}
					/>
				)}
				{showAdjustableTotal && isFuture(endDate) && (
					<FeatureFlag feature="list-divider-inline-edit" invert>
						<Adjust
							startDate={startDate}
							endDate={endDate}
							onClose={() => {
								setEdit(false);
								setShowAdjustableTotal(false);
							}}
						/>
					</FeatureFlag>
				)}
			</div>
		);
	}),
)`
	background-color: rgb(251, 242, 234);
	border-bottom: 1px solid #ece7e2;
	align-items: center;
	padding: 0 12px;

	.asteria-transactions-divider-row {
		display: flex;
		height: 40px;
		${Title} {
			flex-grow: 1 !important;
			font-family: Arial !important;
			font-size: 14px !important;
			color: #512b2b !important;
		}

		${Text} {
			&.asteria-text-body {
				margin-right: 8px !important;
				font-family: Arial !important;
				font-size: 14px !important;
				color: #512b2b !important;
			}
		}
	}
`;

ListDivider.displayName = 'ListDivider';

const List = styled(
	React.memo(
		({
			className,
			items = [],
			tagFilter,
			header,
			date,
			types,
			watch,
			unwatch,
		}) => {
			const [active, setActive] = useState(null);
			const { dispatch, lookup } = useContext(DatalayerContext);
			const [edit, setEdit] = useState(false);
			const history = useHistory();
			const hasStatusDivide = useFeature({ key: 'list-status-divider' });
			const changeActive = useCallback(
				item => {
					dispatch(setSelectedRow([]));
					setActive(item);
					// history.push('/');
				},
				[setActive, dispatch],
			);

			const [{ editing = [], adding = [] } = {}] = useStore(
				'store-adjustable',
				({ transactions }) => transactions,
			);

			const [edits] = useStore(
				'store-adjustable',
				({ edits: val }) => val,
			);

			const componentWillUnmount = useRef(false);

			useEffect(
				() => () => {
					componentWillUnmount.current = true;
				},
				[],
			);

			/*
			const [timeSize = 'month'] = useStore(
				'store-list',
				({ size = 'month' }) => size,
			);

			
			const startDate = useMemo(
				() =>
					new Date(
						`${format(
							startOfTime(date, timeSize),
							'YYYY-MM-DD',
						)}T00:00:00.000Z`,
					),
				[date, timeSize],
			);

			const endDate = useMemo(
				() =>
					new Date(
						`${format(
							endOfTime(date, timeSize),
							'YYYY-MM-DD',
						)}T23:59:59.999Z`,
					),
				[date, timeSize],
			);

			
			const listAdding = useMemo(
				() =>
					adding.filter(
						({ paymentDate }) =>
							new Date(paymentDate) >= startDate &&
							new Date(paymentDate) <= endDate,
					),
				[adding, endDate, startDate],
			);
			*/

			const isListFuture = useMemo(() => {
				const timesliceSize = getStoreValue(
					lookup('store-appstate'),
					({ timesliceSize: size }) => size,
				);

				return (
					isFuture(date) ||
					isSameTime(date, new Date(), timesliceSize)
				);
			}, [date, lookup]);

			const save = useCallback(() => {
				const actions = getStoreValue(
					lookup('store-list'),
					({ actions: list }) => list,
				);

				const ids = items.map(({ _id, id = _id }) => id);

				dispatch(saveEditedTransactions(ids));

				/*
				const currentEdits = getStoreValue(
					lookup('store-adjustable'),
					({ edits: val }) => val,
				);
				
				const transactions = listAdding.map(item =>
					applyEditsToTransactions(item, currentEdits[item.id]),
				);

				dispatch(saveTransactions(transactions));
				*/

				const itemActions = actions.filter(
					({ transaction: { _id, id = _id } }) => ids.includes(id),
				);

				dispatch(
					saveTransactions(
						itemActions
							.filter(({ operation }) => operation !== 'REMOVE')
							.map(action => {
								if (action.operation === 'CREATE') {
									return {
										...action.transaction,
										id: undefined,
									};
								}

								return action.transaction;
							}),
					),
				);

				dispatch(
					removeTransactions(
						itemActions
							.filter(({ operation }) => operation === 'REMOVE')
							.map(({ transaction }) => transaction),
					),
				);
			}, [dispatch, items, lookup]);

			useEffect(
				() => () => {
					if (componentWillUnmount.current) {
						const actions = getStoreValue(
							lookup('store-list'),
							({ actions: list }) => list,
						);

						const currentEdits = getStoreValue(
							lookup('store-adjustable'),
							({ edits: val }) => val,
						);

						const ids = items.map(({ _id, id = _id }) => id);

						if (
							Object.keys(currentEdits).some(id =>
								ids.includes(id),
							)
						) {
							dispatch(clearTransactionField(ids));
						}

						if (
							actions.some(({ _id, id = _id }) =>
								ids.includes(id),
							)
						) {
							dispatch(
								setItemAction(
									actions.filter(
										({
											transaction: { _id, id = _id } = {},
										}) => !ids.includes(id),
									),
								),
							);
						}

						dispatch(
							removeWidgetClass(
								'asteria-transactions-inline-editing',
							),
						);
					}
				},
				[dispatch, items, lookup],
			);

			useEffect(() => {
				if (editing.length + adding.length + 1) {
					setActive(false);
					// history.push('/');
				}
			}, [adding.length, editing.length, history]);

			const setEditing = useCallback(
				val => {
					setActive(false);
					dispatch(
						clearTransactionField(
							items.map(({ _id, id = _id }) => id),
						),
					);

					const actions = getStoreValue(
						lookup('store-list'),
						({ actions: list }) => list,
					);

					const ids = items.map(({ _id, id = _id }) => id);

					/*
					dispatch(
						clearTransactionField(listAdding.map(({ id }) => id)),
					);
					*/

					dispatch(
						setItemAction(
							actions.filter(
								({ transaction: { _id, id = _id } = {} }) =>
									!ids.includes(id),
							),
						),
					);

					setEdit(val);
					if (val) {
						dispatch(
							addWidgetClass(
								'asteria-transactions-inline-editing',
							),
						);
					} else {
						dispatch(
							removeWidgetClass(
								'asteria-transactions-inline-editing',
							),
						);
					}
				},
				[dispatch, items, lookup],
			);

			/*
			useEffect(() => {
				if (listAdding.length > 0) {
					setEdit(true);
				}
			}, []);
			*/
			const reset = useCallback(() => setActive(null), []);
			const type = types.length > 1 ? 'ALL' : types?.[0] || 'ALL';

			if (hasStatusDivide) {
				const forecasted = items.filter(
					({ status }) =>
						status === 'FORECAST' ||
						status === 'UNPAID' ||
						status === 'SIGNED',
				);
				const rest = items.filter(
					({ status }) =>
						status !== 'FORECAST' &&
						status !== 'UNPAID' &&
						status !== 'SIGNED',
				);

				return (
					<div
						className={classNames(className, {
							'asteria-transactions-edit': edit,
						})}
					>
						{header && !hasStatusDivide ? (
							<ListDivider
								header={header}
								date={date}
								types={types}
								setEdit={setEditing}
								watch={watch}
								unwatch={unwatch}
								save={save}
							/>
						) : null}
						{isListFuture ? (
							<ListDivider
								header={TranslationService.get(
									[
										'list.transactions.divider.forecast',
										`list.transactions.divider.${type}.forecast`,
									],
									'',
									{
										date,
									},
								)}
								watch={watch}
								unwatch={unwatch}
								date={date}
								types={types}
								items={items}
								setEdit={setEditing}
								save={save}
								security={
									forecasted.reduce(
										(acc, item) =>
											acc + (item?.oracle?.risk || 1),
										0,
									) / forecasted.length
								}
								total={forecasted.reduce(
									(acc, item) =>
										acc +
										(item?.sums?.display?.total || 0) *
											(item.type === 'WITHDRAW' ? -1 : 1),
									0,
								)}
								max={forecasted.reduce((acc, item) => {
									const total =
										item.type === 'DEPOSIT'
											? item?.sums?.display?.total || 0
											: -(
													item?.sums?.display
														?.total || 0
											  );
									const max =
										item?.oracle?.forecast?.max?.display
											?.total || total;
									const min =
										item?.oracle?.forecast?.min?.display
											?.total || total;

									if (item.type === 'DEPOSIT') {
										return acc + max;
									}

									return acc + min;
								}, 0)}
								min={forecasted.reduce((acc, item) => {
									const total =
										item.type === 'DEPOSIT'
											? item?.sums?.display?.total || 0
											: -(
													item?.sums?.display
														?.total || 0
											  );
									const max =
										item?.oracle?.forecast?.max?.display
											?.total || total;
									const min =
										item?.oracle?.forecast?.min?.display
											?.total || total;
									if (item.type === 'DEPOSIT') {
										return acc + min;
									}

									return acc + max;
								}, 0)}
							/>
						) : null}
						{/* listAdding.map((item, index) => (
							<Transaction
								item={item}
								// eslint-disable-next-line react/no-array-index-key
								key={index}
								edits={
									edits && edits[item.id]
										? edits[item.id]
										: undefined
								}
								active={item === active}
								editGroup={edit}
								onClick={
									item === active || item === false
										? reset
										: changeActive
								}
								tagFilter={tagFilter}
							/>
							)) */}
						{forecasted.map(item => (
							<Transaction
								item={item}
								key={item.id}
								edits={
									edits && edits[item.id]
										? edits[item.id]
										: undefined
								}
								active={item === active}
								editGroup={edit}
								onClick={
									item === active || item === false
										? reset
										: changeActive
								}
								tagFilter={tagFilter}
							/>
						))}
						{rest.length > 0 ? (
							<ListDivider
								header={TranslationService.get(
									[
										'list.transactions.divider.paid',
										`list.transactions.divider.${type}.paid`,
									],
									'',
									{
										date,
									},
								)}
								watch={watch}
								unwatch={unwatch}
								date={date}
								types={types}
								save={save}
								total={rest.reduce(
									(acc, item) =>
										acc +
										(item?.sums?.original?.total || 0) *
											(item.type === 'WITHDRAW' ? -1 : 1),
									0,
								)}
							/>
						) : null}
						{rest.map(item => (
							<Transaction
								item={item}
								key={item.id}
								active={item === active}
								onClick={
									item === active || item === false
										? reset
										: changeActive
								}
								tagFilter={tagFilter}
							/>
						))}
					</div>
				);
			}

			return (
				<div className={classNames(className)}>
					{header ? (
						<ListDivider header={header} save={save} />
					) : null}
					{items.map(item => (
						<Transaction
							item={item}
							key={item.id}
							active={item === active}
							onClick={
								item === active || item === false
									? reset
									: changeActive
							}
							tagFilter={tagFilter}
						/>
					))}
				</div>
			);
		},
	),
)`
	&.asteria-transactions-edit {
		${TextInput} {
			display: flex !important;
		}

		${Dropdown} {
			display: flex !important;
		}
	}
`;

List.displayName = 'List';

List.Styler = {
	children: [
		{
			component: Transaction,
			base: [Getter('transaction')],
		},
	],
};

const Transactions = styled(
	({
		className,
		items,
		tagFilter,
		isFuture: isHeaderFuture,
		setShowSettingsModal,
		setShowColumnsModal,
	}) => {
		const { dispatch } = useContext(DatalayerContext);
		const [sort, setSort] = useState('paymentdate');
		const [order, setOrder] = useState('DESC');
		const hasListDivider = useFeature({ key: 'list-divider' });
		const hasListDateOrder = useFeature({ key: 'list-paymentdate-order' });
		const scrollEl = useRef(null);
		const { watch, unwatch } = useIntersect(scrollEl.current);

		const [selectedDate] = useStore(
			'store-appstate',
			({ selectedDate: date }) => date,
		);

		const [timesliceSize = 'month'] = useStore(
			'store-appstate',
			({ timesliceSize: size }) => size,
		);

		const [selected = []] = useStore(
			'store-list',
			({ state: { selected: list = [] } = {} }) => list,
		);

		const [listState = {}] = useStore('store-list', ({ state: s }) => s);

		const [endDate] = useStore('store-list', ({ endDate: data }) => data);
		const [listTypes] = useStore('store-list', ({ types: data }) => data);
		const [status] = useStore('store-list', ({ status: data }) => data);
		const [search] = useStore('store-list', ({ search: data }) => data);
		const [cursor] = useStore('store-list', ({ cursor: data }) => data);
		const [reverse] = useStore('store-list', ({ reverse: data }) => data);
		const [loadingMore] = useStore(
			'store-list',
			({ loadingMore: loading }) => loading,
		);

		const types = useMemo(() => listTypes || [], [listTypes]);

		const loadMore = useCallback(() => {
			dispatch(
				fetchMoreListData({
					endDate,
					types,
					status,
					search,
					cursor,
					reverse,
				}),
			);
		}, [cursor, dispatch, endDate, search, status, types, reverse]);

		useEffect(() => {
			if (hasListDateOrder && sort === 'paymentdate') {
				setOrder(reverse ? 'ASC' : 'DESC');
			}
		}, [hasListDateOrder, reverse, sort]);

		const grouped = useMemo(() => {
			const groups = items.reduce(
				(acc, item) => {
					let divider = format(item.paymentDate, 'YYYY-MM');
					if (hasListDivider) {
						if (!acc[divider]) {
							acc[divider] = [];
						}
					} else {
						divider = 'default';
					}

					acc[divider].push(item);

					return acc;
				},
				hasListDivider ? {} : { default: [] },
			);

			let [first = false] = Object.keys(groups).sort((a, b) =>
				b.localeCompare(a),
			);

			first = first && new Date(`${first}-01`);

			let index = 0;

			let date = new Date(selectedDate);
			while (index < 12 && first !== false) {
				if (isSameTime(date, first, timesliceSize)) {
					break;
				}

				groups[format(date, 'YYYY-MM')] = [];
				date = addTimeslice(date, timesliceSize, -1);
				index += 1;
			}

			return groups;
		}, [hasListDivider, items, selectedDate, timesliceSize]);

		const sorted = useMemo(() => {
			const sorting = { ...grouped };
			const keys = Object.keys(sorting);

			const sortFunc = (a, b) => {
				if (sort === 'total') {
					let totalA = a?.sums?.original?.total;
					let totalB = b?.sums?.original?.total;

					if (a?.type === 'WITHDRAW') {
						totalA = -totalA;
					}

					if (b?.type === 'WITHDRAW') {
						totalB = -totalB;
					}

					return totalA - totalB;
				}
				if (sort === 'paymentdate') {
					return (
						new Date(a.paymentDate).getTime() -
						new Date(b.paymentDate).getTime()
					);
				}

				if (sort === 'status') {
					const { status: statusA } = a;
					const { status: statusB } = b;

					return statusA.localeCompare(statusB);
				}

				if (sort === 'currency') {
					const currencyA = a?.sums?.original?.currency || '';
					const currencyB = b?.sums?.original?.currency || '';

					return currencyA.localeCompare(currencyB);
				}

				if (sort === 'categories') {
					const {
						status: statusA,
						meta: {
							tags: [{ name: aTagName = '' } = {}] = [],
						} = {},
					} = a;
					const {
						status: statusB,
						meta: {
							tags: [{ name: bTagName = '' } = {}] = [],
						} = {},
					} = b;

					const result = aTagName.localeCompare(bTagName);

					if (result === 0) {
						return statusA.localeCompare(statusB);
					}

					return result;
				}

				if (sort === 'risk-high' || sort === 'risk') {
					let totalA = Math.abs(getRiskHigh(a));

					if (!a?.oracle?.currency?.risk) {
						totalA = 0;
					}

					let totalB = Math.abs(getRiskHigh(b));

					if (!b?.oracle?.currency?.risk) {
						totalB = 0;
					}

					return totalA - totalB;
				}

				if (sort === 'risk-low') {
					// item?.sums.display?.total
					const totalA =
						a?.sums.display?.total -
							a?.oracle?.currency?.max?.display?.total || 0;
					const totalB =
						b?.sums.display?.total -
							b?.oracle?.currency?.max?.display?.total || 0;
					return totalA - totalB;
				}

				return getDescription(
					a?.description || 'Unknown',
				).localeCompare(getDescription(b?.description || 'Unknown'));
			};

			for (let i = 0; i < keys.length; i += 1) {
				const key = keys[i];
				sorting[key] = sorting[key].slice().sort(sortFunc);
			}

			return sorting;
		}, [grouped, sort]);

		const sortedOrder = useMemo(() => {
			const sorting = { ...sorted };
			const keys = Object.keys(sorted);
			for (let i = 0; i < keys.length; i += 1) {
				const key = keys[i];

				if (order === 'DESC') {
					sorting[key] = sorting[key].slice().reverse();
				}
			}

			return sorting;
		}, [order, sorted]);

		const forecastItems = useMemo(
			() => items.filter(({ status: s }) => s === 'FORECAST'),
			[items],
		);

		const selectAll = useCallback(
			state => {
				if (state === true) {
					dispatch(setSelectedRow(forecastItems));
				} else {
					dispatch(setSelectedRow([]));
				}
			},
			[dispatch, forecastItems],
		);

		const isAllSelected = useMemo(
			() =>
				forecastItems.every(item => selected.indexOf(item) !== -1) &&
				forecastItems.length > 0,
			[forecastItems, selected],
		);

		const detectEnd = useCallback(() => {
			if (scrollEl.current && !loadingMore && cursor) {
				const el = scrollEl.current;
				const elBottom = el.offsetHeight + el.scrollTop;

				if (elBottom + SCROLL_BOTTOM_MARGIN >= el.scrollHeight) {
					loadMore();
				}
			}
		}, [cursor, loadMore, loadingMore]);

		return (
			<div className={classNames(className)}>
				<Header
					activeSort={sort}
					activeOrder={order}
					isFuture={isHeaderFuture}
					isAllSelected={isAllSelected}
					selectAll={selectAll}
					setSort={sortIt => {
						if (hasListDateOrder && sortIt === 'paymentdate') {
							dispatch(setListReverse(order !== 'ASC'));
						}

						if (sort === sortIt) {
							setOrder(order === 'ASC' ? 'DESC' : 'ASC');
						} else {
							setOrder('DESC');
							setSort(sortIt);
						}
					}}
					setShowSettingsModal={setShowSettingsModal}
					setShowColumnsModal={setShowColumnsModal}
				/>
				{listState.loading ? <ListLoader /> : null}
				{listState.loading === false && (
					<ListWrapper
						className="asteria-list-wrapper"
						onScroll={detectEnd}
						ref={scrollEl}
					>
						<AdjustableTransactions date={selectedDate} />
						{items.length === 0 ? (
							<Empty
								size="body"
								className="asteria-transactions-empty"
							>
								{TranslationService.get('list.empty')}
							</Empty>
						) : (
							Object.entries(sortedOrder)
								.sort(
									([a], [b]) =>
										b.localeCompare(a) * (reverse ? -1 : 1),
								)
								.map(([header, listItems]) => (
									<List
										key={header}
										items={listItems}
										tagFilter={tagFilter}
										types={types}
										className="asteria-list-content"
										date={header}
										watch={watch}
										unwatch={unwatch}
										header={
											hasListDivider
												? TranslationService.get(
														`list.transactions.divider.month.title`,
														'{{date | date : "MMMM, YYYY" | capitalize}}',
														{
															date: new Date(
																header,
															),
														},
												  )
												: ''
										}
									/>
								))
						)}
						{hasListDivider && cursor && !loadingMore ? (
							<Button
								type="link"
								onClick={loadMore}
								icon="down"
								iconPosition="after"
								className="asteria-button-load-more"
								disabled={loadingMore}
							>
								{TranslationService.get(
									`list.transactions.loadMore`,
									'Ladda fler',
								)}
							</Button>
						) : null}
						{loadingMore && <LinearIndicator />}
					</ListWrapper>
				)}
			</div>
		);
	},
)`
	${Row}, ${Header} {
		${Cell} {
			display: none;

			&.asteria-transaction-cell-status {
				order: 1;
				display: flex;
			}

			&.asteria-transaction-cell-toggle {
				order: 99;
				display: flex;
			}

			&.asteria-transaction-cell-options {
				order: 99;
				display: flex;
			}

			&.asteria-transaction-cell-settings {
				order: 99;
				display: flex;
			}

			${({ visibleColumns }) =>
				visibleColumns.map(
					(column, index) =>
						`&.asteria-transaction-cell-${column} { display: flex; order: ${index +
							2}; }`,
				)}

			${({ visibleColumns }) => {
				const columnPriority = [
					'description',
					'categories',
					'flat-status',
					'payment-date',
				];

				for (let i = 0; i < columnPriority.length; i += 1) {
					if (visibleColumns.includes(columnPriority[i])) {
						return `&.asteria-transaction-cell-${
							columnPriority[i]
						} { flex-grow: 1; } }`;
					}
				}

				return `&.asteria-transaction-cell-${
					visibleColumns[0]
				} { flex-grow: 1; } }`;
			}}
		}
	}

	${LinearIndicator} {
		width: 100%;
	}

	.asteria-button-load-more {
		background-color: #fff !important;
		margin-top: -1px !important;
		width: 146px !important;
		border-radius: 0px 0px 34px 34px !important;
		height: auto !important;
		margin-left: auto !important;
		margin-right: auto !important;
		padding: 0px 16px 4px 8px !important;
		line-height: 19px !important;
		border: 2px solid rgb(235, 231, 225) !important;
		border-top: 0px solid #fff !important;
		flex-direction: row-reverse;
		.asteria-icon-wrapper {
			flex-shrink: 1 !important;
			flex-grow: 0 !important;
			margin-right: 0px !important;
			width: 24px !important;
			align-items: center !important;
			.asteria-icon-svg {
				margin-top: 0px !important;
				height: 8px !important;
				width: 8px !important;
				fill: #257886 !important;
			}
		}
		.asteria-text {
			font-size: 14px !important;
			text-decoration: none !important;
		}
	}
`;

Transactions.displayName = 'Transaction';

Transactions.Styler = {
	children: [
		{
			component: Cell,
			base: [Getter('cell')],
		},
		{
			component: Header,
			base: [Getter('header')],
		},
		{
			component: ListWrapper,
			base: [Getter('wrapper')],
		},
		{
			component: List,
			base: [Getter('list')],
		},
		{
			component: DetailsWrapper,
			base: [Getter('detailswrapper')],
			children: [
				{
					component: Details,
					base: [Getter('details')],
					children: [
						{
							component: Cell,
							base: [Getter('cell')],
						},
					],
				},
			],
		},
		{
			component: RowDetails,
			base: [Getter('detailswrapper')],
		},
		{
			component: DetailsOverview,
			base: [Getter('overview')],
			children: [
				{
					component: Cell,
					base: [Getter('cell')],
				},
			],
		},
		{
			component: Empty,
			base: [Getter('empty')],
		},
	],
};
export default Transactions;
