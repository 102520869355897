import React, { useState, useCallback, useMemo, useContext } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import DatalayerContext from '@asteria/services-datalayer/react/context';
import { Text } from '@asteria/component-core/typography';
import Button from '@asteria/component-core/button';
import Prefix from '@asteria/component-core/prefix';
import Bar from '@asteria/component-graph/controllers/bar';
import NotificationItem from '@asteria/component-notifications/items';
import trigger from '@asteria/component-notifications/actions';
import { FeatureFlag } from '@asteria/component-featureflag';
import Accordion from '@asteria/component-core/accordion';
import { TranslationService } from '@asteria/services-language';
import Tooltip from '@asteria/component-tooltip';
import { useHistory } from '@asteria/utils/hooks';
import { markNotificationAsViewed } from '@asteria/services-datalayer/services/notifications/actions';

const NotificationBadge = styled.div`
	border: 0px solid transparent;
	background: #c01f26;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	color: #ffffff;
	font-family: 'Swedbank headline';
	font-weight: 200;
	font-size: 11px;
	width: 16px;
	height: 16px;
	white-space: nowrap;
	line-height: 100%;
	overflow: hidden;
	display: flex;
	text-overflow: ellipsis;
	margin-right: -16px;
	margin-left: 4px;
	margin-top: -24px;
	cursor: pointer;
`;

const Tag = styled(
	({
		className,
		tag = '',
		status,
		type,
		data,
		data: { values = [], count } = {},
		bar,
		notifications,
		accordion,
		prefix = true,
		onClick = () => {},
		onMouseEnter,
		onMouseLeave,
		children,
		translationKey = 'default',
	}) => {
		const { dispatch, lookup } = useContext(DatalayerContext);
		const history = useHistory();
		const [isOpen, setOpen] = useState(false);
		const displayTag = Array.isArray(tag) ? tag[0] : tag;
		const click = useCallback(
			e => {
				e.stopPropagation();
				onClick(displayTag, status);
			},
			[onClick, displayTag, status],
		);

		const total = useMemo(
			() =>
				values
					.filter(({ status: vStatus }) => vStatus !== 'OVERDUE')
					.reduce((acc, { size }) => acc + size, 0),
			[values],
		);

		return (
			<div
				className={classNames(className, 'asteria-tag-status', {
					[`asteria-tag-status-${status}`]: status,
					[`asteria-tag-type-${type}`]: type,
					[`asteria-tag-accordion-disabled`]:
						accordion &&
						!(values || []).find(
							({ notifications: list }) =>
								list && list.length > 0,
						) &&
						(!values || values.length < 2),
				})}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				role="button"
				tabIndex="-1"
				onClick={e => {
					e.stopPropagation();

					if (accordion) {
						if (accordion && (!values || values.length < 2)) {
							return;
						}
						setOpen(!isOpen);
					} else {
						click(e);
					}
				}}
				onKeyPress={e => {
					e.stopPropagation();
					if (accordion) {
						if (accordion && (!values || values.length < 2)) {
							return;
						}
						setOpen(!isOpen);
					} else {
						click(e);
					}
				}}
			>
				<div className="asteria-tag-overview">
					<div className="asteria-category-overview">
						{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
						<div
							className="asteria-chip"
							onClick={click}
							onKeyPress={click}
							title={TranslationService.get(
								status
									? [
											`tags.${translationKey}`,
											`tags.${displayTag.replace(
												'$',
												'',
											)}`,
											`tags.${translationKey}.${displayTag.replace(
												'$',
												'',
											)}`,
											`tags.${translationKey}.${status}`,
											`tags.${displayTag.replace(
												'$',
												'',
											)}.${status}`,
											`tags.${translationKey}.${displayTag.replace(
												'$',
												'',
											)}.${status}`,
									  ]
									: [
											`tags.${translationKey}`,
											`tags.${displayTag.replace(
												'$',
												'',
											)}`,
											`tags.${translationKey}.${displayTag.replace(
												'$',
												'',
											)}`,
									  ],
								displayTag,
								{
									count,
								},
							)}
						>
							{prefix ? (
								<Prefix color={tag} status={status} />
							) : null}
							{type === 'currency' && (
								<div
									className={`currency-flag currency-flag-${tag.toLowerCase()}`}
								/>
							)}
							<Text className="asteria-text-tag-name">
								{TranslationService.get(
									status
										? [
												`tags.${translationKey}`,
												`tags.${displayTag.replace(
													'$',
													'',
												)}`,
												`tags.${translationKey}.${displayTag.replace(
													'$',
													'',
												)}`,
												`tags.${translationKey}.${status}`,
												`tags.${displayTag.replace(
													'$',
													'',
												)}.${status}`,
												`tags.${translationKey}.${displayTag.replace(
													'$',
													'',
												)}.${status}`,
										  ]
										: [
												`tags.${translationKey}`,
												`tags.${displayTag.replace(
													'$',
													'',
												)}`,
												`tags.${translationKey}.${displayTag.replace(
													'$',
													'',
												)}`,
										  ],
									displayTag,
									{
										count,
									},
								)}
							</Text>
							{/* To be used as Badge, and only on parent level.. */}
							{accordion &&
								values &&
								values.some(
									({ notifications: tagNotis }) =>
										tagNotis && tagNotis.length > 0,
								) && (
									<FeatureFlag feature="category-level-notification">
										<Tooltip
											hover
											className="asteria-category-notification-tooltip"
											isStatic
											content={
												<NotificationItem
													className="asteria-tooltip-notification-tag"
													notification={
														values.find(
															({
																notifications: tagNotis,
															}) =>
																tagNotis &&
																tagNotis.length >
																	0,
														).notifications[0]
													}
													onClick={() => onClick()}
												/>
											}
										>
											<NotificationBadge
												horizontal="left"
												vertical="top"
												className="asteria-badge"
												onClick={e => {
													e.stopPropagation();

													const notification = values.find(
														({
															notifications: tagNotis,
														}) =>
															tagNotis &&
															tagNotis.length > 0,
													).notifications[0];

													if (notification) {
														onClick();
														dispatch(
															markNotificationAsViewed(
																notification?.id,
															),
														);
														trigger(
															notification?.data
																?.action || {},
															{
																dispatch,
																lookup,
																history,
															},
														);
													}
												}}
											>
												!
											</NotificationBadge>
										</Tooltip>
									</FeatureFlag>
								)}
						</div>
					</div>
					{bar && (
						<div className="asteria-tag-overview-bar">
							<Bar
								tag={tag}
								data={data}
								horizontal
								translationKey={translationKey}
							/>
						</div>
					)}
					{children}
					{accordion ? (
						<Button
							type="link"
							size="small"
							icon={isOpen ? 'up' : 'down'}
							disabled={
								accordion &&
								!(values || []).find(
									({ notifications: list }) =>
										list && list.length > 0,
								) &&
								(!values || values.length < 2)
							}
							onClick={e => {
								e.stopPropagation();
								if (
									accordion &&
									!(values || []).find(
										({ notifications: list }) =>
											list && list.length > 0,
									) &&
									(!values || values.length < 2)
								) {
									return;
								}
								setOpen(!isOpen);
							}}
						/>
					) : null}
				</div>
				{notifications && notifications.length > 0 && (
					<NotificationItem
						notification={notifications[0]}
						onClick={() => onClick()}
					/>
				)}
				{accordion && values ? (
					<Accordion open={isOpen}>
						<div className="asteria-accordion-wrapper">
							{values.map(v => (
								<div className="asteria-accordion-item">
									<Tag
										tag={v.tag || v.name || tag}
										status={v.status}
										data={{
											values: [
												{
													...v,
													size:
														(v.size / total) * 100,
												},
											],
											count: v.count,
										}}
										bar={bar}
										notifications={v.notifications}
										onClick={onClick}
										translationKey={translationKey}
									>
										<Text className="asteria-text-tag-size">
											{`${
												(v.size / total) * 100 > 0.5
													? Math.round(
															(v.size / total) *
																100,
													  )
													: '< 1'
											}%`}
										</Text>
									</Tag>
								</div>
							))}
						</div>
					</Accordion>
				) : null}
			</div>
		);
	},
)`
	display: flex;
	flex-direction: column;

	&.asteria-tag-type-client {
		${Prefix} {
			display: none;
		}
	}

	${NotificationItem} {
		border: 1px solid #f2f0ed;
		padding: 12px;
		margin-top: 8px;
		margin-bottom: 16px;
		margin-left: 8px;
		border-radius: 0px;
		// max-width: 304px;
		max-width: 430px;
		flex-direction: row;
		display: flex;
		flex-wrap: wrap;
		&:hover {
			// box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
			background: #ece8e6;
		}
		.asteria-notification-item-header {
			// order: 2;
			.asteria-title-wrapper {
				display: none;
			}
			.asteria-probability {
				margin-left: 0;
				margin-top: -8px;
				margin-right: 10px;
			}
		}

		.asteria-close {
			right: 8px;
			top: 4px;
		}

		.asteria-notification-item-body {
			display: flex;
			// order: 1;
			flex: 1;
			order: -1;
			padding-right: 86px;
		}

		.asteria-notification-item-footer {
			flex-basis: 100%;
			flex-grow: 3;
			// order: 3;
			.asteria-button {
				margin-top: 0;
				margin-left: 0;
				margin-bottom: 0;
				border: 0px solid #f2f0ed;
			}
		}

		${Bar} {
			display: none;
		}
	}

	&.asteria-tag-accordion-disabled {
		.asteria-button {
			opacity: 0.3;
		}
	}

	.asteria-accordion-wrapper {
		padding: 0;
		margin: 0;
		border: 0px solid transparent;
	}

	.asteria-tag-overview {
		display: flex;
		flex-direction: row;
		align-items: center;
		.asteria-button-icon {
			width: 24px !important;
			height: 16px !important;
			.asteria-icon-wrapper {
				svg {
					fill: #9f8c82 !important;
				}
			}
		}
	}

	.asteria-category-overview {
		width: 190px;
		@media (max-width: 1024px) {
			width: 160px;
		}
	}

	.asteria-chip {
		border-radius: 50px;
		display: inline-flex;
		padding: 7px 12px 7px 12px;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		border: 1px solid #eae7e4;
		&:hover {
			border: 1px solid #eae7e4;
			border-radius: 50px;
			background: #eae7e4;
		}
		.asteria-text {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 110px;
		}
	}

	.asteria-text-tag-size {
		margin-left: auto;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	${Prefix} {
		height: 12px;
		width: 12px;
		margin-right: 8px;
	}

	${Text} {
		&.asteria-text-tag-name {
			flex-grow: 1;
			max-width: 150px;
			padding-right: 0;
		}

		&.asteria-text-tag-size {
			width: 115px;
			text-align: right;
			padding-left: 6px;
		}
	}

	&.asteria-state-active {
		> .asteria-tag-overview {
			.asteria-chip {
				background: #eae7e4;
				border-radius: 50px;
			}
		}
	}

	.asteria-tag-overview-bar {
		width: 180px;
		// align-self: flex-start;
		// margin-top: 16px;
		${Bar} {
			height: 8px;
			margin-left: auto;
		}
	}

	${Accordion} {
		display: flex;
		flex-direction: column;
		margin-left: 12px;
		@media (max-width: 1024px) {
			margin-left: 0;
		}
		.asteria-tag-status-PAID {
			order: 1;
			margin-top: 4px;
		}

		.asteria-tag-status-FORECAST {
			order: 3;
		}

		.asteria-tag-status-UNPAID {
			order: 2;
		}

		.asteria-tag-status-OVERDUE {
			order: 4;
		}

		.asteria-category-overview {
			align-self: flex-start;
			width: 177px;
		}

		${Prefix} {
			/* 			height: 8px;
			width: 8px;
			margin-right: 8px; */
		}

		div[role='button'] {
			&:only-child {
				margin-top: 4px;
			}
		}

		.asteria-chip {
			margin-left: 8px;
			margin-top: 4px;
			margin-bottom: 4px;
			@media (max-width: 1024px) {
				margin-left: 0;
			}
			.asteria-text {
				font-size: 12px;
				@media (max-width: 1024px) {
					font-size: 14px;
				}
			}
		}

		.asteria-text-tag-size {
			margin-right: 25px;
			// align-self: flex-start;
			// margin-top: 10px;
		}
	}
`;

Tag.displayName = 'Tag';

Tag.Styler = {
	children: [],
};

export default Tag;
