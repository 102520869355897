import { formatNumber } from '@asteria/utils';

const Questions = [
	{
		type: 'DEPOSIT',
		questions: [
			{
				id: 1,
				title: () => 'Hur många kunder kommer du att fakturera?',
				label: '',
				content: '',
				helper: '',
				description: '',
				form: { input: 'numberOfCustomers' },
				onSave: () => {},
				onInit: (list, statistics) => {
					const { deposit: { parts = [] } = {} } = statistics;
					const { count: customers = 0 } =
						parts.find(
							({ status, tag: { name } = {} }) =>
								status === 'FORECAST' && name === '$customer',
						) || {};

					return { numberOfCustomers: customers };
				},
			},
			{
				id: 2,
				title: () => 'Hur mycket kommer du att fakturera för?',
				label: '',
				content: '',
				helper: 'sek',
				description: '',
				form: { input: 'customersTotal' },
				onInit: (list, statistics) => {
					const { deposit: { parts = [] } = {} } = statistics;
					const { total: customersTotal = 0 } =
						parts.find(
							({ status, tag: { name } = {} }) =>
								status === 'FORECAST' && name === '$customer',
						) || {};

					return { customersTotal: Math.round(customersTotal) };
				},
			},
			{
				id: 3,
				title: () => 'Hanterar du kontanter?',
				label: '',
				content: '',
				helper: '',
				description: '',
				form: { confirm: 'hasDepositCash', input: 'depositCash' },
				onInit: (list, statistics) => {
					const { deposit: { parts = [] } = {} } = statistics;
					const { total: depositCash = 0 } =
						parts.find(
							({ status, tag: { name } = {} }) =>
								status === 'FORECAST' && name === '$cash',
						) || {};

					return {
						hasDepositCash: depositCash !== 0,
						depositCash: Math.round(depositCash),
					};
				},
			},
			{
				id: 4,
				title: () => 'Hanterar du kort?',
				label: '',
				content: '',
				helper: '',
				description: '',
				form: { confirm: 'hasDepositCard', input: 'depositCard' },
				onInit: (list, statistics) => {
					const { deposit: { parts = [] } = {} } = statistics;
					const { total: depositCard = 0 } =
						parts.find(
							({ status, tag: { name } = {} }) =>
								status === 'FORECAST' && name === '$card',
						) || {};

					return {
						hasDepositCard: depositCard !== 0,
						depositCard: Math.round(depositCard),
					};
				},
			},
			{
				id: 5,
				title: () => 'Förväntar du dig övriga inkomster?',
				label: '',
				content: 'Te.x inkomster som inte tillhör någon kategori',
				helper: '',
				form: { confirm: 'hasDepositMisc', input: 'depositMisc' },
				onInit: (list, statistics) => {
					const { deposit: { parts = [] } = {} } = statistics;
					const { total: depositMisc = 0 } =
						parts.find(
							({ status, tag: { name } = {} }) =>
								status === 'FORECAST' && name === '$misc',
						) || {};

					return {
						hasDepositMisc: depositMisc !== 0,
						depositMisc: Math.round(depositMisc),
					};
				},
			},
		],
	},
	{
		type: 'WITHDRAW',
		questions: [
			{
				id: 1,
				title: () =>
					'Hur mycket lön kommer du att betala ut denna månad?',
				label: '',
				content: '',
				helper: 'sek',
				description: '',
				form: { input: 'salary' },
				onInit: (list, statistics) => {
					const { withdraw: { parts = [] } = {} } = statistics;
					const { total: salary = 0 } =
						parts.find(
							({ status, tag: { name } = {} }) =>
								status === 'FORECAST' && name === '$salary',
						) || {};

					return { salary: Math.abs(Math.round(salary)) };
				},
			},
			{
				id: 2,
				title: () => 'Hur mycket inköp kommer du göra denna månad?',
				label: '',
				content: '',
				helper: '',
				description:
					'Kostnad för saker som hyra, prylar och försäkring',
				form: {
					input: 'supplierTotal',
					advanced: [
						{
							id: 1,
							title: () =>
								'Hur stor del av dina utgifter kommer vara material kostnader?',
							label: '',
							content: '',
							helper: '%',
							description: '',
							form: {
								slider: 'suppliesPart',
								sliderText: ({
									supplierTotal = 0,
									suppliesPart = 0,
								}) =>
									`${formatNumber(
										supplierTotal * (suppliesPart / 100),
									)}`,
							},
							onChange: (key, value, answers, setAnswers) => {
								let {
									suppliesPart = 0,
									rentPart = 0,
									insurancePart = 0,
									miscPart = 0,
								} = answers;

								let numberValue = parseInt(value, 10);
								if (Number.isNaN(numberValue)) {
									numberValue = 0;
								}

								let diff = numberValue - suppliesPart;
								suppliesPart = numberValue;

								miscPart -= diff;
								diff = miscPart < 0 ? Math.abs(miscPart) : 0;
								miscPart = Math.max(0, miscPart);

								rentPart -= diff;
								diff = rentPart < 0 ? Math.abs(rentPart) : 0;
								rentPart = Math.max(0, rentPart);

								insurancePart -= diff;
								diff =
									insurancePart < 0
										? Math.abs(insurancePart)
										: 0;
								insurancePart = Math.max(0, insurancePart);

								setAnswers({
									...answers,
									suppliesPart,
									rentPart,
									insurancePart,
									miscPart,
								});
							},
						},
						{
							id: 2,
							title: () =>
								'Hur stor del av dina utgifter kommer vara hyra',
							label: '',
							content: '',
							helper: '%',
							description: '',
							form: {
								slider: 'rentPart',
								sliderText: ({
									supplierTotal = 0,
									rentPart = 0,
								}) =>
									`${formatNumber(
										supplierTotal * (rentPart / 100),
									)}`,
							},
							onChange: (key, value, answers, setAnswers) => {
								let {
									suppliesPart = 0,
									rentPart = 0,
									insurancePart = 0,
									miscPart = 0,
								} = answers;

								let numberValue = parseInt(value, 10);
								if (Number.isNaN(numberValue)) {
									numberValue = 0;
								}

								let diff = numberValue - rentPart;
								rentPart = numberValue;

								miscPart -= diff;
								diff = miscPart < 0 ? Math.abs(miscPart) : 0;
								miscPart = Math.max(0, miscPart);

								insurancePart -= diff;
								diff =
									insurancePart < 0
										? Math.abs(insurancePart)
										: 0;
								insurancePart = Math.max(0, insurancePart);

								suppliesPart -= diff;
								diff =
									suppliesPart < 0
										? Math.abs(suppliesPart)
										: 0;
								suppliesPart = Math.max(0, suppliesPart);

								setAnswers({
									...answers,
									suppliesPart,
									rentPart,
									insurancePart,
									miscPart,
								});
							},
						},
						{
							id: 3,
							title: () =>
								'Hur stor del av dina utgifter kommer vara försäkrings kostnader',
							label: '',
							content: '',
							helper: '%',
							description: '',
							form: {
								slider: 'insurancePart',
								sliderText: ({
									supplierTotal = 0,
									insurancePart = 0,
								}) =>
									`${formatNumber(
										supplierTotal * (insurancePart / 100),
									)}`,
							},
							onChange: (key, value, answers, setAnswers) => {
								let {
									suppliesPart = 0,
									rentPart = 0,
									insurancePart = 0,
									miscPart = 0,
								} = answers;

								let numberValue = parseInt(value, 10);
								if (Number.isNaN(numberValue)) {
									numberValue = 0;
								}

								let diff = numberValue - insurancePart;
								insurancePart = numberValue;

								miscPart -= diff;
								diff = miscPart < 0 ? Math.abs(miscPart) : 0;
								miscPart = Math.max(0, miscPart);

								suppliesPart -= diff;
								diff =
									suppliesPart < 0
										? Math.abs(suppliesPart)
										: 0;
								suppliesPart = Math.max(0, suppliesPart);

								rentPart -= diff;
								diff = rentPart < 0 ? Math.abs(rentPart) : 0;
								rentPart = Math.max(0, rentPart);

								setAnswers({
									...answers,
									suppliesPart,
									rentPart,
									insurancePart,
									miscPart,
								});
							},
						},
						{
							id: 4,
							title: () =>
								'Hur stor del av dina utgifter kommer vara övriga kostnader',
							label: '',
							content: '',
							helper: '%',
							description: '',
							form: {
								slider: 'miscPart',
								sliderText: ({
									supplierTotal = 0,
									miscPart = 0,
								}) =>
									`${formatNumber(
										supplierTotal * (miscPart / 100),
									)}`,
							},
							onChange: (key, value, answers, setAnswers) => {
								let {
									suppliesPart = 0,
									rentPart = 0,
									insurancePart = 0,
									miscPart = 0,
								} = answers;

								let numberValue = parseInt(value, 10);
								if (Number.isNaN(numberValue)) {
									numberValue = 0;
								}

								let diff = numberValue - miscPart;
								miscPart = numberValue;

								suppliesPart -= diff;
								diff =
									suppliesPart < 0
										? Math.abs(suppliesPart)
										: 0;
								suppliesPart = Math.max(0, suppliesPart);

								rentPart -= diff;
								diff = rentPart < 0 ? Math.abs(rentPart) : 0;
								rentPart = Math.max(0, rentPart);

								insurancePart -= diff;
								diff =
									insurancePart < 0
										? Math.abs(insurancePart)
										: 0;
								insurancePart = Math.max(0, insurancePart);

								setAnswers({
									...answers,
									suppliesPart,
									rentPart,
									insurancePart,
									miscPart,
								});
							},
						},
					],
				},
				onInit: (list, statistics) => {
					const { withdraw: { parts = [] } = {} } = statistics;
					const { total: supplierTotal = 0 } =
						parts.find(
							({ status, tag: { name } = {} }) =>
								status === 'FORECAST' && name === '$supplier',
						) || {};

					return {
						supplierTotal: Math.abs(Math.round(supplierTotal)),
						suppliesPart: 0,
						rentPart: 0,
						insurancePart: 0,
						miscPart: 100,
					};
				},
			},
			{
				id: 5,
				title: () => 'Förväntar du dig övriga utgifter?',
				label: '',
				content: 'Te.x ugifter som inte tillhör någon kategori',
				helper: '',
				/* description: 'Te.x ugifter som inte tillhör någon kategori', */
				form: { confirm: 'hasWithdrawMisc', input: 'withdrawMisc' },
				onInit: (list, statistics) => {
					const { withdraw: { parts = [] } = {} } = statistics;
					const { total: withdrawMisc = 0 } =
						parts.find(
							({ status, tag: { name } = {} }) =>
								status === 'FORECAST' && name === '$misc',
						) || {};

					return {
						hasWithdrawMisc: withdrawMisc !== 0,
						withdrawMisc: Math.abs(Math.round(withdrawMisc)),
					};
				},
			},
			{
				id: 6,
				title: ({
					numberOfCustomers = 0,
					customersTotal = 0,
					salary,
				}) =>
					`Du kommer fakturera ${numberOfCustomers} kunder för ${formatNumber(
						customersTotal,
					)} sek och betala ut löner för ${formatNumber(
						salary,
					)} kr. Därför uppskattas din skatt till att bli ${formatNumber(
						customersTotal * 0.25 + salary * 0.5,
					)} sek. Stämmer detta?`,
				label: '',
				content: '',
				helper: '',
				description: '',
				form: { confirm: true },
			},
		],
	},
];

export default Questions;
