import React, { forwardRef } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Text } from '@asteria/component-core/typography';
import { Getter, compileStyles } from '@asteria/utils';
import InfoBoxToggle from './toggler';

const Security = styled.div`
	display: flex;
	align-items: center;
`;
Security.displayName = 'DetailedBarInfoBoxSecurity';

const InfoBoxWrapper = styled.div``;
InfoBoxWrapper.displayName = 'DetailedBarInfoBoxWrapper';
const InfoBoxContainer = styled.div``;
InfoBoxContainer.displayName = 'DetailedBarInfoBoxContainer';

const InfoBox = styled(
	forwardRef(({ className, open, text, children, ...props }, ref) => {
		if (!open) {
			return false;
		}
		return (
			<InfoBoxWrapper
				className={classNames(className, 'asteria-infobox')}
				{...props}
				ref={ref}
			>
				<InfoBoxContainer>{children}</InfoBoxContainer>
			</InfoBoxWrapper>
		);
	}),
)`
	${({ theme }) => compileStyles(InfoBox, theme)}
`;
InfoBox.displayName = 'DetailedBarInfoBox';
InfoBox.Styler = {
	base: [Getter('infobox')],
	children: [
		{
			base: [Getter('text', {})],
			component: Text,
		},
		{
			base: [Getter('container', {})],
			component: InfoBoxContainer,
		},
	],
};
export default InfoBox;
export { InfoBoxToggle };
