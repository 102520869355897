export default [
	{
		_id: 'transaction',
		name: '$transaction',
		tags: [{ _id: 'cash', name: '$cash' }, { _id: 'card', name: '$card' }],
	},
	{
		_id: 'invoices',
		name: '$invoices',
		tags: [
			{ _id: 'customer', name: '$customer' },
			{ _id: 'supplier', name: '$supplier' },
		],
	},
	{
		_id: 'salaries',
		name: '$salaries',
		tags: [{ _id: 'salary', name: '$salary' }],
	},
	{
		_id: 'taxes',
		name: '$taxes',
		tags: [{ _id: 'tax', name: '$tax' }],
	},
];
