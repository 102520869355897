export default {
	formV2: {
		group: {
			style: {
				position: 'relative',
			},
		},
		label: {
			style: {
				padding: '8px 0 4px',
				height: '32px',
				alignItems: 'center',
			},
			text: {
				style: {
					fontWeight: 'regular',
					textTransform: 'none',
					fontSize: '14px',
					fontFamily: 'Arial, Helvetica, sans-serif',
					color: '#262626',
					lineHeight: '18px',
				},
			},
			types: {
				error: { text: { style: { color: 'rgba(197, 19, 28, 1)' } } },
				warning: { text: { style: { color: '#E4BB3F' } } },
				success: {
					text: { style: { color: 'rgba(153, 204, 102, 1)' } },
				},
			},
		},
		help: {
			style: { padding: '8px 0', height: '36px' },
			text: { style: { fontSize: '10px', color: '#888' } },
			types: {
				error: { text: { style: { color: 'rgba(197, 19, 28, 1)' } } },
				warning: { text: { style: { color: '#E4BB3F' } } },
				success: {
					text: { style: { color: 'rgba(153, 204, 102, 1)' } },
				},
			},
		},
		input: {
			style: {
				backgroundColor: 'rgba(245, 243, 240, 1)',
				borderRadius: '0px',
				borderStyle: 'solid',
				borderWidth: '0px',
				borderColor: '#E2E2E4',
				borderBottomWidth: '2px',
				borderBottomColor: 'rgba(212, 196, 188, 1)',
				// paddingLeft: '8px',
				// padding: '8px 10px',
				minHeight: '38px',
				height: '48px',
				boxSizing: 'border-box',
				width: '100%',
			},
			value: {
				style: {
					fontSize: '16px',
					fontFamily: 'Swedbank Sans',
					color: 'rgba(81, 43, 43, 1)',
					fontWeight: '700',
					paddingLeft: '8px',
					paddingRight: '8px',
					boxSizing: 'border-box',
				},
				types: {
					disabled: { style: { color: '#93969A' } },
					small: { style: { fontSize: '14px', lineHeight: '20px' } },
					invertedcolor: { style: { color: 'rgb(41, 45, 62);' } },
				},
			},
			placeholder: {
				style: {
					opacity: 1,
					color: 'rgba(114, 96, 95, 1)',
					fontFamily: 'Swedbank Sans',
					fontWeight: '700',
				},
			},
			types: {
				textarea: { style: { height: '108px' } },
				disabled: {
					style: { backgroundColor: 'rgba(211, 195, 188, 1)' },
					value: {
						style: {
							color: 'rgba(149, 131, 124, 1)',
							fontFamily: 'Swedbank Sans',
							fontWeight: '700',
						},
					},
				},
				small: {
					style: {
						padding: '8px 10px',
						minHeight: '38px',
						height: '38px',
					},
					text: {
						style: {
							fontSize: '13px',
						},
					},
					value: {
						style: {
							fontSize: '12px',
						},
					},
				},
				error: {
					style: {
						borderColor: '#EB5757',
						backgroundColor: 'rgba(245, 243, 240, 1)',
						borderRadius: '0px',
						borderStyle: 'solid',
						borderWidth: '0px',
						borderBottomWidth: '2px',
						borderBottomColor: 'rgba(197, 19, 28, 1)',
						borderLeftWidth: '0px',
						borderRightWidth: '0px',
						borderTopWidth: '0px',
					},
				},
				warning: {
					style: {
						borderColor: '#E4BB3F',
						backgroundColor: 'rgba(245, 243, 240, 1)',
						borderRadius: '0px',
						borderStyle: 'solid',
						borderWidth: '0px',
						borderBottomWidth: '2px',
						borderBottomColor: 'rgba(249, 206, 34, 1)',
					},
				},
				success: {
					style: {
						borderColor: '#219653',
						backgroundColor: 'rgba(245, 243, 240, 1)',
						borderRadius: '0px',
						borderStyle: 'solid',
						borderWidth: '0px',
						borderBottomWidth: '2px',
						borderBottomColor: 'rgba(153, 204, 102, 1)',
					},
				},
				invertedcolor: { style: { backgroundColor: '#fff' } },
			},
			states: {
				focus: {
					style: {
						borderBottomWidth: '2px',
						borderBottomColor: 'rgba(83, 161, 172, 1)',
					},
				},
				active: {
					borderBottomWidth: '2px',
					borderBottomColor: 'rgba(83, 161, 172, 1)',
				},
			},
			helper: {
				style: {
					textTransform: 'uppercase',
					position: 'absolute',
					right: '16px',
					bottom: '13px',
					pointerEvents: 'unset',
					cursor: 'default',
				},
				text: {
					style: {
						fontSize: '16px',
						fontFamily: 'Swedbank Sans',
						fontWeight: 700,
						lineHeight: '21px',
						color: '#72605F',
					},
				},
			},
		},
		radio: {
			style: { paddingTop: '5px', paddingBottom: '5px' },
			radioElement: {
				style: {
					alignItems: 'center',
					paddingRight: '30px',
					marginTop: '8px',
				},
				mark: {
					style: {
						border: '2px solid #999',
						width: '20px',
						height: '20px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: '50%',
						marginRight: '5px',
						cursor: 'pointer',
					},
					icon: {
						style: {
							width: '8px',
							height: '8px',
							alignSelf: 'center',
						},
						svg: { style: { fill: 'rgba(0, 0, 0, 0)' } },
					},
				},
				label: {
					style: {
						fontSize: '16px',
						color: 'var(--text-color)',
						fontFamily: 'Swedbank Sans',
						fontWeight: '700',
					},
				},
				types: {
					checked: {
						mark: {
							style: {
								borderColor: 'rgba(114, 96, 94, 1)',
								backgroundColor: 'rgba(114, 96, 94, 1)',
								marginRight: '5px',
							},
							icon: {
								svg: {
									style: {
										width: '8px',
										fill: 'rgba(255, 255, 255, 1)',
										alignSelf: 'center',
									},
								},
							},
						},
					},
				},
			},
			types: {
				stacked: {
					style: {
						flexDirection: 'column',
						alignItems: 'flex-start',
					},
				},
			},
		},
		checkbox: {
			style: {
				paddingTop: '8px',
				paddingBottom: '8px',
				cursor: 'pointer',
			},
			checkboxElement: {
				style: { alignItems: 'flex-start', paddingRight: '30px' },
				mark: {
					style: {
						border: '2px solid #bdbdbd',
						borderColor: 'rgba(114, 96, 94, 1)',
						width: '21px',
						height: '21px',
						borderRadius: '2px',
						marginRight: '8px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexShrink: 0,
					},
					icon: {
						style: { width: '14px', height: '12px' },
						svg: { style: { fill: 'rgba(0, 0, 0, 0)' } },
					},
				},
				label: {
					style: {
						fontSize: '16px',
						fontFamily: 'Swedbank Sans',
						fontWeight: 700,
						color: 'var(--text-color)',
						lineHeight: '21px',
					},
				},
				types: {
					checked: {
						mark: {
							style: { backgroundColor: 'rgba(114, 96, 94, 1)' },
							icon: { svg: { style: { fill: '#ffffff' } } },
						},
					},
				},
			},
		},
		switch: {
			toggle: {
				style: {
					width: '36px',
					height: '12px',
					marginBottom: '16px',
					marginTop: '16px',
					marginRight: '4px',
				},
				track: {
					style: {
						width: '100%',
						height: '12px',
						borderRadius: '8px',
						backgroundColor: '#D5C4BD',
						border: '2px solid #D5C4BD',
						transition: 'all 80ms linear',
						transitionProperty: 'background-color',
						transitionDelay: '50ms',
						flexShrink: '0',
					},
				},
				slider: {
					style: {
						width: '10px',
						height: '10px',
						borderRadius: '50%',
						border: '7px solid #72605e',
						backgroundColor: '#72605e',
						transform: 'translate3d(0, 0, 0)',
						transition: 'all 80ms linear',
						transitionProperty: 'transform',
						position: 'absolute',
						left: '0',
					},
				},
			},
			label: {
				style: {
					marginLeft: '8px',
					marginTop: '14px',
					fontSize: '16px',
					fontFamily: 'Swedbank Sans',
					fontWeight: 700,
					color: 'var(--text-color)',
					lineHeight: '20px',
				},
			},
			types: {
				active: {
					toggle: {
						track: {
							style: {
								backgroundColor: '#84CDC3',
								border: '2px solid #84CDC3',
							},
						},
						slider: {
							style: {
								transform: 'translate3d(16px, 0, 0)',
								background: '#0E7984',
								border: '7px solid #0E7984',
							},
						},
					},
				},
				disabled: {
					style: {
						opacity: '0.5',
						cursor: 'not-allowed',
					},
					toggle: {
						style: {
							cursor: 'not-allowed',
						},
					},
				},
			},
		},
		datepicker: {
			style: {},
			calendars: {
				style: {
					display: 'flex',
					position: 'absolute',
					boxShadow: '0 3px 5px 0 rgba(0,0,0,.25)',
					right: '0',
				},
			},
			wrapper: {
				style: {
					flexDirection: 'row',
					// background: '#eaeaea',
					background: '#fff',
					height: '0px',
					overflow: 'hidden',
					zIndex: 2,
					minWidth: '390px',
				},
				tabs: {
					tabsNavigation: {
						style: {},
						button: {
							style: {
								background: '#efb7b6',
								borderRadius: '50%',
								width: '40px',
								height: '40px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							},
							text: {
								style: {
									color: '#2f2424',
									fontFamily: 'Arial,Helvetica,sans-serif',
									fontSize: '14px',
									fontWeight: '400',
									textDecoration: 'none',
								},
							},
						},
						text: {
							style: {
								fontFamily: 'Swedbank Sans',
								color: '#512B2B',
								fontWeight: '500',
								fontSize: '14px',
								margin: 'auto',
								padding: 'auto',
								cursor: 'pointer',
							},
						},
					},
				},
				selectYear: {
					style: {
						height: '300px',
						width: '100%',
						overflow: 'auto',
					},
					yearsList: {
						style: {
							padding: '3px',
						},
					},
				},
				section: {
					style: {
						display: 'flex',
						flexDirection: 'column',
						flex: '1',
					},
					header: {
						style: {
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'stretch',
						},
						headerSection: {
							style: {
								background: '#fff',
								display: 'flex',
								flexDirection: 'row',
								flex: '1',
								justifyContent: 'flex-start',
								alignItems: 'center',
							},
							title: {
								style: {
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									margin: '0 auto',
									textAlign: 'center',
									fontSize: '16px',
									fontFamily: 'Swedbank Sans',
									color: '#262626',
									fontWeight: '700',
								},
							},
							button: {
								style: {
									background: 'transparent',
								},
								icon: {
									style: {},
									svg: {
										style: {
											fill: '#666666',
										},
									},
								},
								types: {
									next: {
										style: {},
									},
								},
								states: {
									hover: {
										icon: {
											style: {
												visibility: 'visible',
											},
											svg: {
												style: {
													fill: '#363636',
												},
											},
										},
									},
									disabled: {
										style: {
											pointerEvents: 'none',
											cursor: 'default',
										},
										icon: {
											svg: {
												style: {
													opacity: '0.35',
												},
											},
										},
									},
								},
							},
						},
					},
					weekdays: {
						style: {
							display: 'grid',
							gridTemplateColumns: 'repeat(7, 1fr)',
							background: '#d4c4bc',
						},
						text: {
							style: {
								color: '#222',
								fontFamily: 'Arial,Helvetica,sans-serif',
								fontSize: '14px',
								fontWeight: '400',
								paddingTop: '4px',
								textAlign: 'center',
								paddingBottom: '4px',
							},
						},
					},
					dates: {
						style: {
							display: 'grid',
							gridTemplateColumns: 'repeat(7, 1fr)',
							gridGap: '0',
						},
						date: {
							style: {
								background: '#fff',
								position: 'relative',
								border: '0',
								justifyContent: 'center',
								alignItems: 'center',
								display: 'flex',
								// height: '40px'
								width: '100%',
								// paddingBottom: '55%',
								paddingBottom: '100%',
							},
							wrapper: {
								style: {
									position: 'absolute',
									top: '0',
									left: '0',
									right: '0',
									bottom: '0',
									textAlign: 'center',
									justifyContent: 'center',
									display: 'flex',
									alignItems: 'center',
								},
							},
							button: {
								style: {
									width: '40px',
									height: '40px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								},
								text: {
									style: {
										color: '#2f2424',
										fontFamily:
											'Arial,Helvetica,sans-serif',
										fontSize: '14px',
										fontWeight: '400',
										textDecoration: 'none',
									},
								},
							},
							states: {
								hover: {
									style: {
										background: '#ebe7e2',
										cursor: 'pointer',
									},
								},
								active: {
									style: {},
									button: {
										style: {
											background: '#efb7b6',
											borderRadius: '50%',
											width: '40px',
											height: '40px',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										},
										text: {
											style: {
												color: '#2f2424',
												fontFamily:
													'Arial,Helvetica,sans-serif',
												fontSize: '14px',
												fontWeight: '600',
												textDecoration: 'none',
											},
										},
									},
									states: {
										/* styla hover på active verkar inte gå, i detta fall ska de ej få en grå bakgrund om ett datum är vält vid mus-över */
										hover: {
											style: {
												backgroud: 'red',
											},
										},
									},
								},
							},
							types: {
								past: {
									style: {},
									button: {
										style: {
											pointerEvents: 'none',
											cursor: 'default',
										},
										text: {
											style: {
												opacity: '0.35',
											},
										},
									},
								},
								today: {
									button: {
										text: {
											style: {
												color: '#2F242',
											},
										},
									},
								},
								other: {
									style: {
										background: '#fff',
									},
									button: {
										style: {
											pointerEvents: 'none',
											cursor: 'default',
										},
										text: {
											style: {
												opacity: '0',
											},
										},
									},
								},
								future: {
									button: {
										text: {
											style: {
												color: '#2F242',
											},
										},
									},
								},
								unavailable: {
									button: {
										text: {
											style: {
												color: '#c5131c',
											},
										},
									},
								},
							},
						},
					},
					months: {
						style: {
							display: 'grid',
							gridTemplateColumns: 'repeat(4, 1fr)',
							gridGap: '0',
							overflow: 'hidden',
						},
						monthsbox: {
							style: {
								fontFamily: 'Arial,Helvetica,sans-serif',
								fontSize: '14px',
								textDecoration: 'none',
								lineHeight: '124.1%',
								padding: '10px',
								margin: '15px 5px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								textAlign: 'center',
								color: '#5C5353',
								button: {
									style: {
										background: 'none',
										color: 'inherit',
										border: 'none',
										padding: '0',
										cursor: 'pointer',
										outline: 'inherit',
									},
								},
							},
							states: {
								hover: {
									style: {
										background: '#ebe7e2',
										cursor: 'pointer',
									},
								},
								active: {
									style: {},
									button: {
										style: {
											background: '#efb7b6',
											borderRadius: '50%',
											width: '40px',
											height: '40px',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										},
										text: {
											style: {
												color: '#2f2424',
												fontFamily:
													'Arial,Helvetica,sans-serif',
												fontSize: '14px',
												fontWeight: '400',
												textDecoration: 'none',
											},
										},
									},
								},
							},
						},
					},
					types: {
						second: {
							style: {
								/* funkar ej nu */
							},
						},
					},
				},
				types: {
					open: {
						style: {
							height: 'auto',
							width: '100%',
							borderTop: '1px solid #eaeaea',
							overflow: 'visible',
						},
					},
				},
			},
		},
		dropdown: {
			style: {},
			toggle: {
				style: {
					flexDirection: 'inherit',
					backgroundColor: '#fff',
					borderRadius: '0px',
					borderStyle: 'solid',
					borderWidth: '0px',
					borderColor: '#E2E2E4',
					borderBottomWidth: '2px',
					borderBottomColor: 'rgba(212, 196, 188, 1)',
					paddingLeft: '8px',
					// padding: '8px 10px',
					minHeight: '38px',
					height: '48px',
					boxSizing: 'border-box',
					width: '100%',
				},
				text: {
					style: {
						fontSize: '16px',
						fontFamily: 'Swedbank Sans',
						fontWeight: '700',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					},
				},
				icon: {
					style: {
						height: '8px',
						flexGrow: '0',
						marginLeft: 'auto',
					},
					svg: {
						style: {
							fill: '#53A1AC',
						},
					},
				},
				types: {
					placeholder: {
						text: {
							style: {
								color: '#726060',
							},
						},
					},
					primary: {
						style: {
							backgroundColor: '#ee7023',
						},
					},
				},
			},
			dropdown: {
				style: {
					backgroundColor: '#ffffff',
					boxShadow: 'rgba(0, 0, 0, 0.25) 0px 3px 5px 0px',
					// minWidth: '270px',
					width: '100%',
					overflow: 'hidden',
					transition: 'max-height 0.3s',
					maxHeight: '0',
					display: 'block',
				},
				item: {
					style: {
						borderTop: '1px solid rgb(244, 244, 244)',
						padding: '16px',
					},
					text: {
						style: {
							fontFamily: 'Swedbank Sans',
							fontWeight: 700,
							fontSize: '16px',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						},
					},
					icon: {
						style: {
							width: '14px',
						},
						svg: {
							style: {
								opacity: '0',
							},
						},
					},
					states: {
						active: {
							icon: {
								style: {},
								svg: {
									style: {
										fill: '#99cc66',
										opacity: '1',
									},
								},
							},
							text: {
								style: {},
							},
						},
						hover: {
							style: {
								backgroundColor: 'rgb(242, 242, 242)',
							},
						},
					},
				},
			},
			types: {
				open: {
					toggle: {},
					dropdown: {
						style: {
							transition: 'max-height 0.5s',
							maxHeight: '250px',
							overflowY: 'auto',
						},
					},
				},
			},
		},
	},
};
