import React, { useState, useContext, useEffect, useCallback } from 'react';
import Modal, { ModalBody, ModalFooter } from '@asteria/component-modal';
import classNames from 'classnames';

import DatalayerContext from '@asteria/services-datalayer/react/context';
import { useFeature } from '@asteria/component-featureflag';
import Button from '@asteria/component-button/button';
import styled from 'styled-components';
import {
	FormGroupV2,
	// Counter,
	InputV2,
	CheckboxGroupV2,
} from '@asteria/component-form';
import { Translation, TranslationService } from '@asteria/services-language';
import {
	createValidator,
	useInput,
	useIsMounted,
	Getter,
} from '@asteria/utils';
import Alert from '@asteria/component-alert';
import { Text, Rating } from '@asteria/component-core';
import getStoreValue from '@asteria/services-datalayer/utils/get';
import { saveUser } from '@asteria/services-datalayer/services/auth/actions';

const FormWrapper = styled.div`
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	/* margin: 0 20px; */
	padding: 16px 16px 30px;
	background-color: #faf2eb;
`;

const MESSAGE_FORM_GROUP_STYLE = { marginBottom: '8px' };

// const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const FeedbackModal = styled(
	({ className, type, flow, rating, onClose = () => {} }) => {
		const { dispatch, lookup } = useContext(DatalayerContext);
		const [selectedRating, setRating] = useState(rating);
		// const [email, handleEmailChange] = useInput('');
		const [message, handleMessageChange] = useInput(
			TranslationService.get(`feedback.${type}.message`, ''),
		);
		const [submission, setSubmission] = useState(false);
		const [validationErrors, setErrors] = useState({});
		const [showSuccess, setShowSuccess] = useState(false);

		const isMessageDisabled = useFeature({
			key: `feedback-${type?.toLowerCase()}-disable-message`,
		});

		const baseTranslationKeys = ['feedback', `feedback.${type}`];

		useEffect(() => {
			handleMessageChange({
				target: {
					value: TranslationService.get(
						`feedback.${type}.message`,
						'',
					),
				},
			});
		}, [handleMessageChange, type]);

		// const maxMessageLength = 400;

		// Create validator function and define its rules and messages
		const validate = useCallback(
			createValidator(
				{
					message: () => undefined,
				},
				setErrors,
				validationErrors,
			),
			[setErrors, validationErrors, message],
		);

		const isMounted = useIsMounted();
		// Prepare a submit function here to keep it consistent with validation state
		const submitfeedback = useCallback(async () => {
			if (!Object.keys(validationErrors).length) {
				const user = getStoreValue(
					lookup('store-auth'),
					({ user: obj }) => obj,
				);

				dispatch(
					saveUser({
						...user,
						settings: {
							...(user?.settings || {}),
							flags: {
								...(user?.settings?.flags || {}),
								integrationOnboarding: {
									message,
									rating: selectedRating + 1,
								},
							},
						},
					}),
				);

				setShowSuccess(true);
			}
			if (!isMounted.current) return;
			setSubmission(false); // Don't forget to drop submission flag
		}, [
			dispatch,
			isMounted,
			lookup,
			message,
			selectedRating,
			validationErrors,
		]);

		// Submit form
		useEffect(() => {
			// Try to submit form just after we get a consistent state
			if (submission) {
				submitfeedback();
			}
		}, [submitfeedback, submission]);

		const handleCreate = useCallback(() => {
			validate(); // Check all form fields again
			setSubmission(true); // Try to submit form after we get a consistent form state
		}, [validate]);

		if (showSuccess) {
			return (
				<div
					className={classNames('asteria-feedback-modal', className)}
				>
					<Modal
						open
						className={className}
						title={TranslationService.get([
							'feedback.title',
							'Lämna feedback',
						])}
						onClose={onClose}
					>
						<ModalBody>
							<div
								style={{
									display: 'flex',
									flex: '1',
									flexDirection: 'column',
									margin: '0 20px',
									padding: '16px',
									backgroundColor: '#FAF2EB',
								}}
							>
								<Text type="paragraph">
									{TranslationService.get(
										baseTranslationKeys.map(
											t => `${t}.success.text`,
										),
										'Tack för din feedback. Din åsikt är viktigt för oss och vi tar till oss er feedback i strävan efter att förbättra våra tjänster för våra kunder.',
										{ showSuccess },
									)}
								</Text>
							</div>
						</ModalBody>
						<ModalFooter
							style={{
								paddingLeft: '20px',
								paddingRight: '20px',
							}}
						>
							<Button
								size="regular"
								type="primary"
								text={TranslationService.get(
									[
										'button.done',
										...baseTranslationKeys.map(
											t => `${t}.success.done`,
										),
									],
									'Färdig',
								)}
								onClick={onClose}
							/>
						</ModalFooter>
					</Modal>
				</div>
			);
		}

		return (
			<div className={classNames('asteria-feedback-modal', className)}>
				<Modal
					open
					className={className}
					title={TranslationService.get([
						'feedback.title',
						'Lämna feedback',
					])}
					onClose={onClose}
				>
					<ModalBody scroll className="modal-body">
						<Translation
							Component={Text}
							className="asteria-text-desc"
							translationKey="feedback.description"
							defaultText="Eftersom dina synpunkter är anonyma kan vi inte svara dig. Om du vill ha hjälp eller ställa en fråga är du välkommen att kontakta oss."
						/>
						<Rating
							rating={rating}
							onClick={setRating}
							flow={flow}
						/>
						<FormWrapper className="asteria-dialog-box">
							<FormGroupV2 style={MESSAGE_FORM_GROUP_STYLE}>
								<InputV2
									className="asteria-feedback-message"
									elementType="textarea"
									placeholder={TranslationService.get(
										baseTranslationKeys.map(
											t => `${t}.message.placeholder`,
										),
										'Hur upplever du att ansluta ditt bokföringssystem gick',
									)}
									onChange={handleMessageChange}
									disabled={isMessageDisabled}
									value={message}
									invertedcolor
								/>
								{validationErrors.message ? (
									<Alert
										icon
										type="error"
										showClose={false}
										headingContent={
											validationErrors.message
										}
									/>
								) : null}
							</FormGroupV2>
						</FormWrapper>
					</ModalBody>
					<ModalFooter className="modal-footer">
						<Button
							size="medium"
							type="default"
							className="asteria-button-cancel"
							text={TranslationService.get(
								[
									'button.abort',
									...baseTranslationKeys.map(
										t => `${t}.button.cancel`,
									),
								],
								'Avbryt',
							)}
							onClick={onClose}
						/>
						<Button
							size="medium"
							type="primary"
							className="asteria-button-send"
							text={TranslationService.get(
								[
									'button.create',
									...baseTranslationKeys.map(
										t => `${t}.button.create`,
									),
								],
								'Skicka',
							)}
							disabled={submission}
							onClick={handleCreate}
						/>
					</ModalFooter>
				</Modal>
			</div>
		);
	},
)``;

FeedbackModal.Styler = {
	children: [
		{
			component: Button,
			base: [Getter('button')],
		},
		{
			component: CheckboxGroupV2,
			base: [Getter('checkbox')],
		},
		{
			component: InputV2,
			base: [Getter('input')],
		},
		{
			component: FormWrapper,
			base: [Getter('form')],
		},
	],
};

export default FeedbackModal;
