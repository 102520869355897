import React, { useState, useContext, useCallback, useEffect } from 'react';
import { LinearIndicator } from '@asteria/component-progressbar-indicator';
import { TextV2, Title } from '@asteria/component-typography';
import { ButtonV2 } from '@asteria/component-button';
import { TranslationService } from '@asteria/services-language';
import { useStore } from '@asteria/utils';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { fetchIntegrations } from '@asteria/services-datalayer/services/integrations/actions';
import Steps from '../../static/steps';
import Layout from '../../layout';
import Success from './success';

let timer;
let nextCheckTime;

const clearAllTimeouts = () => {
	clearTimeout(timer);
	timer = undefined;
	nextCheckTime = 0;
};

const Connecting = ({ navigator }) => {
	const { dispatch } = useContext(DatalayerContext);

	const timeoutBetweenChecks = 5000;
	const [connectionAttempts, setConnectionAttempts] = useState(0);
	const [isWindowOpened, setIsWindowOpened] = useState(false);

	const [erps = []] = useStore(
		'store-integrations',
		({ integrations = [] }) => integrations,
	);

	const [integrationId] = useStore(
		'store-integrations',
		({ integrationInProgress }) => integrationInProgress,
	);

	const [error] = useStore(
		'store-integrations',
		({ networkError }) => networkError,
	);

	if (error) {
		navigator.back(null, navigator);
	}

	const integration = erps.find(int => int.id === integrationId);

	// TODO this might need some refactoring, It was nearly 6AM x)
	const recheck = useCallback(() => {
		if (connectionAttempts * timeoutBetweenChecks < 60000) {
			const timestamp = new Date().getTime();

			if (!nextCheckTime || timestamp >= nextCheckTime) {
				// Do polling during a minute
				clearAllTimeouts();
				timer = setTimeout(() => {
					dispatch(fetchIntegrations());
					setConnectionAttempts(connectionAttempts + 1);
					recheck();
				}, timeoutBetweenChecks);
				nextCheckTime = timestamp + timeoutBetweenChecks;
			}
		} else {
			clearAllTimeouts();
			// Give an error if we've exceeded this timeout
			navigator.back(
				{
					event: 'goto',
					integration: 'vismaeekonomi',
				},
				navigator,
			);
		}
	}, [connectionAttempts, dispatch, navigator]);

	useEffect(() => {
		if (!isWindowOpened) {
			const [{ formattedHref = null } = {}] =
				integration?.config?.server?.urls?.REDIRECT || [];

			if (formattedHref !== null) {
				setIsWindowOpened(true);

				const win = window.open('', '_blank');
				win.location = formattedHref;
				win.focus();

				recheck();
			}
		}
	}, [integration, isWindowOpened, recheck]);

	if (integration?.config?.errors?.length) {
		clearAllTimeouts();

		navigator.back(
			{
				event: 'goto',
				integration: 'vismaeekonomi',
			},
			navigator,
		);

		return null;
	}
	if (integration?.config?.connected === true) {
		clearAllTimeouts();

		dispatch(() => ({
			action: 'CONNECTION_CLEANUP',
		}));
		navigator.navigateTo(Success.navigationKey, navigator);

		return null;
	}

	return (
		<Layout
			header={
				<Title
					className="asteria-title-onboarding-header"
					size="title2"
				>
					{TranslationService.get(
						'onboarding.connect.header',
						'2. Anslut till bokföringssystem',
					)}
				</Title>
			}
			type="vismaeekonomi-connecting"
			body={
				<>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							padding: '20px 20px 20px',
							backgroundColor: '#fbf2ea',
							minHeight: '200px',
							justifyContent: 'center',
						}}
						className="asteria-dialog-box"
					>
						<TextV2 style={{ marginBottom: '8px' }}>
							{TranslationService.get(
								'onboarding.vismaeekonomi.connecting.text',
								'Ansluter till Visma eEkonomi',
							)}
						</TextV2>
						<LinearIndicator />
					</div>

					<div
						className="asteria-wizard-inner-footer"
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							paddingTop: '20px',
							marginTop: '55px',
						}}
					>
						<ButtonV2
							size="medium"
							type="default"
							onClick={() =>
								navigator.cancel(
									{
										event: 'cancel',
									},
									navigator,
								)
							}
							text={TranslationService.get(
								'onboarding.abort',
								'Avbryt',
							)}
						/>
						<ButtonV2
							size="medium"
							type="secondary"
							onClick={() =>
								navigator.back(
									{
										event: 'goto',
										integration: 'vismaeekonomi',
									},
									navigator,
								)
							}
							text={TranslationService.get(
								'onboarding.back',
								'Tillbaka',
							)}
						/>
					</div>
				</>
			}
			footer={null}
		/>
	);
};

Connecting.navigationKey = Steps.VismaConnecting;

export default Connecting;
