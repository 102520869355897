import { useEffect, useState, useContext } from 'react';
import DatalayerContext from '@asteria/services-datalayer/react/context';

const useStore = (store, query, compare) => {
	const { lookup } = useContext(DatalayerContext);
	const Store = lookup(store);
	const [state, updateState] = useState(query(Store.value || {}));

	useEffect(() => {
		const subscriptions = [
			Store.query(query, compare).subscribe(data => {
				updateState(data);
			}),
		];
		return () => {
			subscriptions.map(it => it.unsubscribe());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Store]);

	return [state];
};

export default useStore;
