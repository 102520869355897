import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import kebabCase from 'lodash/fp/kebabCase';
import { fontSizes, colors } from '@asteria/services-constants';
import PropTypes from 'prop-types';
import { Getter, compileStyles } from '@asteria/utils';

/**
 * Base Text component wrapper
 */
const Text = styled(
	React.forwardRef(
		(
			{
				children,
				styler,
				className,
				editStyle,
				editProps,
				size,
				text,
				...otherProps
			},
			ref,
		) => (
			<p
				ref={ref}
				className={classNames(
					'asteria-text',
					size && `asteria-${kebabCase(size)}`,
					className,
				)}
				size={size}
				{...otherProps}
			>
				{text || children}
			</p>
		),
	),
)`
	display: block;

	font-size: ${props => fontSizes[props.size]};
	color: ${colors.headline};

	${({ theme }) => {
		const style = compileStyles(Text, theme);
		return style;
	}}
`;

// Styler structure for text component
Text.Styler = {
	component: Text,
	base: Getter('typography.text'),
};

Text.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	className: PropTypes.string,
	size: PropTypes.string,
};

Text.defaultProps = {
	children: null,
	className: '',
	size: '',
};

Text.displayName = 'Text';
export default Text;
