import React, { useState, useCallback, useContext, useEffect } from 'react';
import { TextV2, Title } from '@asteria/component-typography';
import { ButtonV2 } from '@asteria/component-button';
import { Translation, TranslationService } from '@asteria/services-language';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { initiateIntegration } from '@asteria/services-datalayer/services/integrations/actions';
import { useStore } from '@asteria/utils';
import { FormGroup, LabelV2, InputV2 } from '@asteria/component-form';
import Alert from '@asteria/component-alert';

import Layout from '../../layout';
import Context from '../../context';

const Key = ({ navigator, callback = () => {} }) => {
	const { setStepIndex } = useContext(Context);
	const [invalidKeyError, setInvalidKeyError] = useState(false);
	const [corporateId, setCorporateId] = useState('');

	useEffect(() => {
		setStepIndex(2);
		callback('goto', {
			event: 'goto',
			integration: 'swedbank',
			integrationTitle: 'Swedbank',
			step: {
				stepId: 'swedbankActivation',
				title: 'Aktivera',
			},
			steps: [
				{ stepId: 'swedbankActivation', title: 'Aktivera' },
				{
					stepId: 'swedbankConnecting',
					title: 'Väntar på anslutning',
				},
			],
		});
	}, [callback, setStepIndex]);

	const { dispatch } = useContext(DatalayerContext);
	const [erps = []] = useStore(
		'store-integrations',
		({ integrations = [] }) => integrations,
	);
	const [integrationId] = useStore(
		'store-integrations',
		({ integrationInProgress }) => integrationInProgress,
	);

	const [errors, setErrors] = useState([]);

	useEffect(() => {
		if (integrationId) {
			const integration = erps.find(int => int.id === integrationId);
			if (integration) {
				setErrors([...errors, ...integration.config.errors]);
				dispatch(() => ({
					action: 'CONNECTION_CLEANUP',
				}));
			}
		}
	}, [dispatch, erps, errors, integrationId]);

	const nextStep = useCallback(() => {
		if (!corporateId || corporateId.length === 0) {
			setInvalidKeyError(true);
		} else {
			dispatch(
				initiateIntegration({
					integrationName: 'swedbank.psd2',
					type: 'bank',
					config: {
						client: {
							/*
							'PSU-CORPORATE-ID': corporateId
								.replace('-', '')
								.replace(' ', ''),
							'PSU-User-Agent': navigator.userAgent,
							'PSU-IP-Address': '2.64.205.214',
							'PSU-IP-Port': '443',
							'PSU-ID': '198605306979',
							*/
							'PSU-CORPORATE-ID': `06${corporateId}`
								.replace('-', '')
								.replace(' ', ''),
							'PSU-User-Agent': navigator.userAgent,
							'PSU-IP-Address': '2.64.205.214',
							'PSU-IP-Port': '443',
						},
					},
				}),
			);
			navigator.next(null, navigator);
		}
	}, [corporateId, dispatch, navigator]);

	return (
		<Layout
			type="swedbank-key"
			header={
				<Title
					className="asteria-title-onboarding-header"
					size="title2"
				>
					{TranslationService.get(
						[
							'onboarding.connect.header',
							'onboarding.connect.bank.header',
						],
						'2. Anslut till bokföringssystem',
					)}
				</Title>
			}
			body={
				<>
					<div
						className="asteria-dialog-box"
						style={{
							padding: '20px 20px 8px',
							backgroundColor: '#fbf2ea',
						}}
					>
						<Title size="title4">Swedbank</Title>
						<TextV2 size="small">
							{TranslationService.get(
								'onboarding.swedbank.api_form.title',
								'För att aktivera kopplingen behöver du logga in hos Swedbank och godkänna att Asteria AB för tillgång till er bank data',
							)}
						</TextV2>
						{/* <ButtonV2
							size="small"
							type="flat"
							text={TranslationService.get(
								'onboarding.swedbank.url',
								'Logga in hos Swedbank',
							)}
							onClick={() => nextStep()}
							style={{height: 'auto', margin: '8px 0 0',}}
						/> */}
					</div>
					<div
						className="asteria-dialog-box"
						style={{
							backgroundColor: '#fbf2ea',
							padding: '0px 20px 15px',
						}}
					>
						<FormGroup>
							<LabelV2 path="form.label">
								{TranslationService.get(
									'onboarding.swedbank.api_form.corporateId.label',
									'Organisationsnummer',
								)}
							</LabelV2>
							<InputV2
								placeholder={TranslationService.get(
									'onboarding.swedbank.api_form.corporateId.placeholder',
									'Fyll i organisationsnummer',
								)}
								onChange={e => {
									if (invalidKeyError) {
										// hide alert after user started typing again
										setInvalidKeyError(false);
									}
									setCorporateId(e.target.value);
								}}
								value={corporateId}
								invertedcolor
							/>
						</FormGroup>
						{invalidKeyError ? (
							<Alert
								icon
								description={false}
								type="error"
								headingContent={
									<Translation
										translationKey="onboarding.swedbank.api_form.corporateId.missing"
										defaultText="Var vänlig fyll i ditt organisationsnummer."
									/>
								}
								onClose={() => setInvalidKeyError(false)}
							/>
						) : null}
					</div>
					<div
						className="asteria-wizard-inner-footer"
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							paddingTop: '20px',
						}}
					>
						<ButtonV2
							size="medium"
							type="default"
							onClick={() =>
								navigator.cancel(
									{
										event: 'cancel',
									},
									navigator,
								)
							}
							text={TranslationService.get(
								'onboarding.abort',
								'Avbryt',
							)}
						/>
						<div
							className="asteria--activation-key-controls"
							style={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<ButtonV2
								size="medium"
								type="secondary"
								onClick={() =>
									navigator.back(
										{
											event: 'goto',
											step: {
												stepId: 'selectErp',
											},
										},
										navigator,
									)
								}
								text={TranslationService.get(
									'onboarding.back',
									'Tillbaka',
								)}
							/>
							<ButtonV2
								size="medium"
								type="primary"
								text={TranslationService.get(
									'onboarding.swedbank.url',
									'Logga in hos Swedbank',
								)}
								onClick={() => nextStep()}
								style={{ marginLeft: '16px' }}
							/>
						</div>
					</div>
				</>
			}
			footer={<></>}
		/>
	);
};

Key.navigationKey = Symbol('SwedbankKey');

export default Key;
