import React, {
	useState,
	useCallback,
	useMemo,
	useEffect,
	useContext,
} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import {
	SortableContainer,
	SortableElement,
	SortableHandle,
} from 'react-sortable-hoc';

import DatalayerContext from '@asteria/services-datalayer/react/context';
import Modal, { ModalBody, ModalFooter } from '@asteria/component-modal';
import Button from '@asteria/component-core/button';
import { TranslationService } from '@asteria/services-language';
import { Title, Text } from '@asteria/component-core';
import { useStore } from '@asteria/utils';
import { setVisibleColumns } from '@asteria/services-datalayer/services/list/actions';
import Alert from '@asteria/component-alert';
import { saveUser } from '@asteria/services-datalayer/services/auth/actions';

const DragHandle = SortableHandle(() => (
	<Button
		icon="menu"
		type="link"
		size="medium"
		className="asteria-button-arrange"
	/>
));

const Column = styled(
	({
		className,
		value,
		onAdd,
		onRemove,
		active = false,
		disableAction = false,
	}) => (
		<li
			className={classNames(className, 'asteria-list-settings-item', {
				'asteria-state-active': active,
				'asteria-state-disabled': disableAction,
			})}
		>
			<Button
				icon={active ? 'circleMinus' : 'circlePlus'}
				type="link"
				size="medium"
				className="asteria-button-toggle"
				disabled={disableAction}
				onClick={() => (active ? onRemove(value) : onAdd(value))}
			/>
			<Text>{TranslationService.get(`list.column.${value}`, value)}</Text>
			{active ? <DragHandle /> : null}
		</li>
	),
)`
	list-style: none;
	margin: 0;
	display: flex;
	align-items: center;
	padding: 12px 8px 12px 8px;
	background: #fff;
	border-top: 1px solid rgb(235, 231, 225);
	border-bottom: 1px solid rgb(235, 231, 225);
	margin: -1px 0;
	&.asteria-list-settings-item {
		${Text} {
			flex-grow: 1;
			font-size: 16px;
			font-family: Swedbank Sans;
			font-weight: 700;
			color: var(--text-color);
		}
		.asteria-button-link {
			&.asteria-button-toggle {
				.asteria-icon-wrapper {
					svg {
						fill: #ee7024;
					}
				}
				margin-right: 8px;

				&.circleMinus {
					&:hover {
						border-radius: 100px;
						background: #ee7024;
						svg {
							fill: #fff;
						}
					}
				}
				&.circlePlus {
					&:hover {
						border-radius: 100px;
						background: #ee7024;
						svg {
							fill: #fff;
						}
					}
				}
			}
			&.asteria-button-arrange {
				.asteria-icon-wrapper {
					svg {
						fill: #9f8c82;
					}
				}
				&:hover {
					.asteria-icon-wrapper {
						svg {
							fill: #2f2424;
						}
					}
				}
			}
		}
		&.asteria-state-disabled {
			.asteria-button-toggle {
				pointer-events: none;
				.asteria-icon-wrapper {
					svg {
						fill: rgb(159, 140, 130);
					}
				}
			}
		}
	}
`;

const ColumnList = styled(
	({ className, items, onRemove, onAdd, disableAction, Item = Column }) => (
		<ul className={className}>
			{items.map((value, index) => (
				<Item
					key={`item-${value}`}
					index={index}
					value={value}
					onRemove={onRemove}
					onAdd={onAdd}
					disableAction={disableAction}
				/>
			))}
		</ul>
	),
)`
	list-style: none;
	padding: 0;
	margin: 0;
	margin-bottom: 24px;
`;

const ActiveColumnsListItem = SortableElement(
	({ value, onRemove, disableAction }) => (
		<Column
			value={value}
			onRemove={onRemove}
			active
			disableAction={disableAction}
		/>
	),
);

const ActiveColumnsList = SortableContainer(
	({ className, items, onRemove, disableAction }) => (
		<ColumnList
			className={className}
			items={items}
			onRemove={onRemove}
			Item={ActiveColumnsListItem}
			disableAction={disableAction}
		/>
	),
);

const AllCategories = [
	'description',
	'payment-date',
	'categories',
	'flat-status',
	'total',
	'risk',
	'currency',
];

const Settings = styled(({ className, show, setShow }) => {
	const { dispatch } = useContext(DatalayerContext);
	const [error, setError] = useState(false);
	const [columns, setColumns] = useState([
		'description',
		'payment-date',
		'categories',
		'flat-status',
		'total',
		'risk',
		'currency',
	]);

	const [user] = useStore('store-auth', ({ user: obj }) => obj);
	const [visibleColumns] = useStore(
		'store-list',
		({ visibleColumns: list }) => list,
	);
	useEffect(() => {
		setColumns([...visibleColumns]);
	}, [visibleColumns]);

	const close = useCallback(() => setShow(false), [setShow]);
	const save = useCallback(() => {
		if (columns.length < 3) {
			setError('list.settings.errors.none_selected');
		} else {
			dispatch(setVisibleColumns(columns));

			dispatch(
				saveUser({
					...user,
					settings: {
						...user.settings,
						listColumns: columns,
					},
				}),
			);

			close();
		}
	}, [close, columns, dispatch, user]);

	const removeItem = useCallback(
		removeValue => {
			if (columns.length > 3) {
				setColumns(columns.filter(value => value !== removeValue));
			}
		},
		[columns],
	);

	const addItem = useCallback(
		item => {
			if (columns.length < 7) {
				setError(false);
				setColumns([...columns, item]);
			}
		},
		[columns],
	);

	const updateOrder = useCallback(
		({ oldIndex, newIndex }) => {
			const items = [...columns];
			const item = items.splice(oldIndex, 1)[0];
			items.splice(newIndex, 0, item);

			setColumns(items);
		},
		[columns],
	);

	const availableColumns = useMemo(
		() => AllCategories.filter(item => !columns.includes(item)),
		[columns],
	);

	return (
		<Modal
			className={classNames(className)}
			onClose={() => setShow(false)}
			open={show}
			title={TranslationService.get('list.column.settings.title')}
		>
			<ModalBody>
				<Title size="title3">
					{TranslationService.get('list.settings.title1')}
				</Title>
				<Text>{TranslationService.get('list.settings.text')}</Text>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						marginTop: '16px',
						marginBottom: '8px',
					}}
				>
					<Title size="title4">
						{TranslationService.get(
							'list.settings.title.active.columns',
						)}
					</Title>
					<Text
						style={{ marginLeft: 'auto', color: '#999' }}
						size="small"
					>
						{TranslationService.get(
							'list.settings.text.active.columns',
							'',
							{ columns: columns.length },
						)}
					</Text>
				</div>
				<Alert
					icon
					type="error"
					show={error}
					showClose={false}
					headingContent={TranslationService.get(error)}
				/>
				{columns.length > 0 ? (
					<ActiveColumnsList
						items={columns}
						shouldCancelStart={() => {}}
						onSortEnd={updateOrder}
						helperClass="sortableHelper"
						useDragHandle
						axis="y"
						lockAxis="y"
						helperContainer={document.getElementById(
							'asteria-widget',
						)}
						disableAction={columns.length === 3}
						onRemove={removeItem}
					/>
				) : (
					<Text style={{ marginTop: '-8px' }}>
						{TranslationService.get(
							'list.settings.no.selected.columns',
						)}
					</Text>
				)}
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						marginTop: '16px',
						marginBottom: '8px',
					}}
				>
					<Title size="title4">
						{TranslationService.get('list.settings.title.columns')}
					</Title>
				</div>
				{availableColumns.length > 0 ? (
					<ColumnList
						items={availableColumns}
						onAdd={addItem}
						disableAction={columns.length === 7}
					/>
				) : (
					<Text style={{ marginTop: '-8px' }}>
						{TranslationService.get(
							'list.settings.not.available.columns',
						)}
					</Text>
				)}
			</ModalBody>
			<ModalFooter className="modal-footer">
				<Button
					size="medium"
					type="default"
					text={TranslationService.get(
						'settings.button.cancel',
						'Avbryt',
					)}
					onClick={close}
				/>
				<Button
					size="medium"
					type="primary"
					text={TranslationService.get(
						'settings.button.save',
						'Spara',
					)}
					onClick={save}
				/>
			</ModalFooter>
		</Modal>
	);
})`
	.modal-footer {
		.asteria-button-default {
			margin-right: auto;
		}
	}

	${Alert} {
		margin-bottom: 16px;
	}
`;

export default Settings;
