import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { startOfMonth, startOfISOWeek, startOfYear, addMonths } from 'date-fns';
import { Text, Title } from '@asteria/component-core/typography';
import Button from '@asteria/component-core/button';
import PropTypes from 'prop-types';
import DatalayerContext from '@asteria/services-datalayer/react/context';
/* import Icon from '@asteria/component-icon'; */
import classNames from 'classnames';
import { RadioGroupV2 } from '@asteria/component-form';
import Tabs, {
	TabsContent,
	TabsNavigation,
} from '@asteria/component-form/tabs';
import { setListOpen } from '@asteria/services-datalayer/services/appstate/actions';

import {
	setListItems,
	setListDates,
} from '@asteria/services-datalayer/services/list/actions';
import {
	setActiveBars,
	setActiveGroups,
} from '@asteria/services-datalayer/services/graph/actions';
import {
	editTransactions,
	addTransactions,
	setOnboardingStep,
} from '@asteria/services-datalayer/services/adjustable/actions';
import AdjustableQuestions from './adjustableQuestion';
import Questions from './adjustableArray';

const startOfTime = (date, size) => {
	if (size === 'week') {
		return startOfISOWeek(date);
	}
	if (size === 'month') {
		return startOfMonth(date);
	}
	if (size === 'year') {
		return startOfYear(date);
	}

	return startOfMonth(date);
};

const InfoTempDiv = styled.div`
	background-color: #f9f8f6;
	border-bottom: 2px solid rgba(238, 112, 35, 1);
	border-top: 2px solid rgba(238, 112, 35, 1);
	margin-bottom: 10px;
	margin-top: 0;
	padding: 16px;
	font-size: 14px;
	line-height: 20px;
	color: rgba(34, 34, 34, 1);
	display: block;
	font-family: Arial, Helvetica, sans-serif;
	margin: 8px 0 16px;
`;

const FirstDiv = styled.div`
	max-width: 440px;
	width: 100%;
	margin: 0 auto;
	flex-grow: 0;
	flex-shrink: 1;

	${Button} {
		height: 35px;
		margin-bottom: 32px;
	}
`;

const SecondDiv = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 32px;
`;

const DivInSecond = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 32px;
`;

const TitleListHeadline = styled(Title)`
	font-size: 26px;
	color: rgb(238, 112, 35);
	font-family: Swedbank Headline;
	font-weight: 400;
	text-align: center;
`;

const AsteriaListIntro = styled(Text)`
	font-size: 16px !important;
	color: #222 !important;
	font-family: Swedbank Sans !important;
	font-weight: 400 !important;
	text-align: center !important;
`;

const AdjustableQuestionsForm = styled(
	({ className, transactions, statistics, next, timeSize, id }) => {
		const { dispatch } = useContext(DatalayerContext);
		const [answers, setAnswers] = useState({});
		const [showInfo, showInfoBox] = useState(false);
		useEffect(() => {
			let initialAnswers = {};
			for (let i = 0; i < Questions.length; i += 1) {
				const type = Questions[i];
				for (let j = 0; j < type.questions.length; j += 1) {
					const { onInit = () => ({}) } = type.questions[j];
					initialAnswers = {
						...initialAnswers,
						...onInit(transactions, statistics),
					};
				}
			}
			setAnswers(initialAnswers);
			setOnboardingStep({
				id,
				type: 'DEPOSIT',
			});
		}, [transactions, id, statistics]);

		return (
			<div className={classNames(className)} style={{}}>
				<TitleListHeadline>Skapa din prognos</TitleListHeadline>
				<AsteriaListIntro>
					Effektivisera arbetet och ge mer tid över till värdeskapande
				</AsteriaListIntro>
				<Button
					type="link"
					size="small"
					className="asteria-button-transactions-header-info"
					icon={showInfo ? 'circleClose' : 'circleHelp'}
					onClick={() => showInfoBox(!showInfo)}
				/>
				{showInfo ? (
					<InfoTempDiv>
						Här kommer du igång med att skapa din prognos. Prognosen
						har baserats på tidigare händesler i ditt
						bokföringssystem och hos banken genom att justera gör du
						en mer pricksäker prognos.
					</InfoTempDiv>
				) : null}
				<FirstDiv>
					<Tabs
						active="DEPOSIT"
						onChange={([type]) => {
							dispatch(
								setOnboardingStep({
									id,
									type,
								}),
							);
						}}
					>
						<TabsNavigation key="navigation">
							<Button type="primary" tab="DEPOSIT">
								Inbetalningar
							</Button>
							<Button type="primary" tab="WITHDRAW">
								Utbetalningar
							</Button>
						</TabsNavigation>
						<TabsContent key="content">
							{Questions.map(type => (
								<AdjustableQuestions
									tab={type.type}
									key={type.type}
									answers={answers}
									setAnswers={setAnswers}
									questions={type.questions}
								/>
							))}
						</TabsContent>
					</Tabs>
				</FirstDiv>

				<SecondDiv>
					<Title>Sker detta varje månad?</Title>
					<Text>
						Om det är återkommande händelser behöver du inte gå
						igenom varje månad för att skapa din prognos
					</Text>
					<RadioGroupV2
						className="asteria-checkbox-group-wrapper-transactions-small"
						items={['Ja', 'Nej']}
						onChange={() => {}}
					/>
					<DivInSecond>
						<Button size="medium" type="link" text="Avbryt" />
						<Button
							size="medium"
							type="primary"
							text="Spara"
							onClick={() => {
								dispatch(setListOpen(false));
								dispatch(editTransactions([]));
								dispatch(addTransactions([]));
								dispatch(
									setActiveGroups('cashflow-bar-graph', []),
								);
								dispatch(
									setActiveBars('cashflow-bar-graph', []),
								);
								dispatch(setListItems([]));
								dispatch(
									setListDates(
										startOfTime(new Date(), timeSize),
										addMonths(
											startOfTime(new Date(), timeSize),
											1,
										),
										['DEPOSIT', 'WITHDRAW'],
										null,
										timeSize,
									),
								);
								dispatch(
									setOnboardingStep({
										id: next,
										type: 'DEPOSIT',
									}),
								);
							}}
						/>
					</DivInSecond>
				</SecondDiv>
			</div>
		);
	},
)`
	background: #faf0e7;
	display: flex;
	justify-content: center;
	align-content: center;
	padding: 40px;
	flex-direction: column;
	position: relative;

	.asteria-button-transactions-header-info {
		position: absolute;
		right: 40px;
		top: 40px;
	}

	.asteria-title {
		text-align: center;
	}

	.asteria-button-transactions-header-info svg { fill: #f35b1c !important; }
	}
`;

AdjustableQuestionsForm.displayName = 'AdjustableQuestionsForm';

AdjustableQuestionsForm.Styler = {
	children: [],
};

AdjustableQuestionsForm.propTypes = {
	questions: PropTypes.array,
	answers: PropTypes.array,
	setAnswers: PropTypes.array,
};

export default AdjustableQuestionsForm;
