import useStore from './useStore';

const useHistory = () => {
	const [history] = useStore(
		'store-appstate',
		({ history: object }) => object,
	);

	return history;
};

export default useHistory;
