import React, { Component } from 'react';
import ReactDOM, { findDOMNode } from 'react-dom';
import styled from 'styled-components';
import classNames from 'classnames';
import { eventPath } from '@asteria/utils';
import TooltipContent from './tooltipContent';

const Wrapper = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	align-items: center;
	align-self: center;
	/* z-index: 6; */
	z-index: 9999;
	transform: translate(-50%, -100%);
	pointer-events: none;

	&.asteria-tooltip-close-to-top {
		transform: translate(-50%, 0%);
	}
`;
const TriggerWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

TriggerWrapper.displayName = 'TriggerWrapper';

const isIOS =
	navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i);

class Tooltip extends Component {
	constructor(props) {
		super(props);

		this.triggerEl = React.createRef();
		this.contentRef = React.createRef();
		this.wrapper = document.getElementById('asteria-tooltip');

		if (!this.wrapper) {
			const el = document.createElement('div');
			el.setAttribute('id', 'asteria-tooltip');
			el.style.position = 'fixed';
			el.style.top = '0px';
			el.style.left = '0px';
			el.style.zIndex = '99999';
			el.style.width = '150%';
			el.style.height = '150%';
			el.style.pointerEvents = 'none';

			const rootEl =
				document.getElementById('asteria-widget') || document.body;
			if (rootEl.append) {
				rootEl.append(el);
			} else {
				rootEl.appendChild(el);
			}

			this.wrapper = document.getElementById('asteria-tooltip');
		}

		this.state = {
			isOpen: props.open || false,
			maxTooltipWidth: 0,
			top: 0,
			left: 0,
			bottom: 0,
			contentWidth: 0,
			contentHeight: 0,
		};

		this.handleOrientationChange = this.handleOrientationChange.bind(this);
		this.componentDidUpdate = this.handleOrientationChange;
	}

	componentDidMount() {
		if ('onorientationchange' in window) {
			window.addEventListener(
				'orientationchange',
				this.handleOrientationChange,
				false,
			);
		}

		const { targetEl } = this.props;
		if (targetEl && targetEl.current && !isIOS) {
			/* eslint-disable-next-line react/no-find-dom-node */
			const domEl = findDOMNode(this.triggerEl.current);

			if (domEl) {
				domEl.addEventListener('mouseenter', this.handlerEnter);
				domEl.addEventListener('mouseleave', this.handlerLeave);
			}
		}

		const { isOpen } = this.state;

		if (isOpen) {
			const { width, height } = this.getContentWidth();
			this.setState({
				maxTooltipWidth: this.getMaxTooltipWidth(),
				contentWidth: width,
				contentHeight: height,
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener(
			'orientationchange',
			this.handleOrientationChange,
			false,
		);

		document.removeEventListener('mousemove', this.handlerMove);
		const { targetEl } = this.props;
		if (targetEl && targetEl.current && !isIOS) {
			/* eslint-disable-next-line react/no-find-dom-node */
			const domEl = findDOMNode(this.triggerEl.current);

			if (domEl) {
				domEl.removeEventListener('mouseenter', this.handlerEnter);
				domEl.removeEventListener('mouseleave', this.handlerLeave);
			}
		}
	}

	getContentWidth() {
		const { contentWidth, contentHeight } = this.state;
		if (this.contentRef && this.contentRef.current) {
			/* eslint-disable-next-line react/no-find-dom-node */
			const domEl = findDOMNode(this.contentRef.current);
			if (domEl) {
				const {
					width: elWidth,
					height: elHeight,
				} = domEl.getBoundingClientRect();
				return { width: elWidth, height: elHeight };
			}
		}

		return { width: contentWidth, height: contentHeight };
	}

	getMaxTooltipWidth() {
		const { targetEl } = this.props;
		let left = 0;
		let width = 0;

		if (this.triggerEl && this.triggerEl.current) {
			/* eslint-disable-next-line react/no-find-dom-node */
			const domEl = findDOMNode(this.triggerEl.current);
			if (domEl) {
				const {
					left: elLeft,
					width: elWidth,
				} = domEl.getBoundingClientRect();
				left = elLeft + elWidth / 2;
				width = (window.innerWidth - left) * 2;
			}
		}

		if (targetEl && targetEl.current) {
			/* eslint-disable-next-line react/no-find-dom-node */
			const domEl = findDOMNode(targetEl.current);
			if (domEl) {
				const {
					left: elLeft,
					width: elWidth,
				} = domEl.getBoundingClientRect();
				left = elLeft + elWidth / 2;
				width = (window.innerWidth - left) * 2;
			}
		}

		return width;
	}

	handler = () => {
		const { isOpen } = this.state;
		this.setState({
			isOpen: !isOpen,
		});
	};

	handlerEnter = () => {
		const { hover = false } = this.props;
		const { isOpen } = this.state;

		if (hover === true && isOpen === false) {
			this.setState({
				isOpen: true,
				maxTooltipWidth: this.getMaxTooltipWidth(),
			});
		}
	};

	handlerLeave = () => {
		const { hover = false } = this.props;
		const { isOpen } = this.state;
		if (hover === true && isOpen === true) {
			this.setState({
				isOpen: false,
			});
		}
	};

	handlerMove = e => {
		const { hover = false, targetEl } = this.props;
		const { isOpen } = this.state;
		if (
			isOpen === true &&
			targetEl &&
			targetEl.current &&
			hover === true &&
			eventPath(e).indexOf(targetEl.current) === -1
		) {
			this.setState({
				isOpen: false,
			});
		}
	};

	handleOrientationChange() {
		const { targetEl } = this.props || {};
		const {
			top: currentTop,
			left: currentLeft,
			bottom: currentBottom,
			isOpen = false,
		} = this.state || {};
		let top = 0;
		let left = 0;
		let bottom = 0;

		if (this.triggerEl && this.triggerEl.current) {
			/* eslint-disable-next-line react/no-find-dom-node */
			const domEl = findDOMNode(this.triggerEl.current);
			if (domEl) {
				const {
					top: elTop,
					bottom: elBottom,
					left: elLeft,
					width: elWidth,
				} = (domEl.firstElementChild || domEl).getBoundingClientRect();
				top = elTop;
				bottom = elBottom;
				left = elLeft + elWidth / 2;
			}
		}

		if (targetEl && targetEl.current) {
			/* eslint-disable-next-line react/no-find-dom-node */
			const domEl = findDOMNode(targetEl.current);
			if (domEl) {
				const {
					top: elTop,
					bottom: elBottom,
					left: elLeft,
					width: elWidth,
				} = domEl.getBoundingClientRect();
				top = elTop;
				bottom = elBottom;
				left = elLeft + elWidth / 2;
			}

			if (domEl && !isIOS) {
				domEl.removeEventListener('mouseenter', this.handlerEnter);
				domEl.removeEventListener('mouseleave', this.handlerLeave);
			}

			if (domEl && !isIOS) {
				domEl.addEventListener('mouseenter', this.handlerEnter);
				domEl.addEventListener('mouseleave', this.handlerLeave);
			}
		}

		if (isOpen) {
			document.addEventListener('mousemove', this.handlerMove);
		} else {
			document.removeEventListener('mousemove', this.handlerMove);
		}

		if (
			currentTop !== top ||
			currentLeft !== left ||
			currentBottom !== bottom
		) {
			this.setState({ top, left, bottom });
		}

		const { contentWidth, contentHeight } = this.state;

		if (isOpen) {
			const {
				width: newWidth,
				height: newHeight,
			} = this.getContentWidth();
			if (newWidth !== contentWidth || newHeight !== contentHeight) {
				// eslint-disable-next-line react/no-did-update-set-state
				this.setState({
					contentWidth: newWidth,
					contentHeight: newHeight,
				});
			}
		}
	}

	renderTooltip(top, left) {
		const {
			title,
			content,
			styler,
			path,
			trigger,
			children,
			targetEl,
			open,
			hover,
			className,
			...props
		} = this.props;
		const {
			maxTooltipWidth,
			contentWidth,
			contentHeight,
			bottom,
		} = this.state;
		// if (!maxTooltipWidth) return null;
		if (!content && !title) return null;

		let safeLeft = left;
		let safeTop = top;
		let switchToBottom = false;
		let pointerOffset = 0;

		if (left - contentWidth / 2 < 0) {
			safeLeft = contentWidth / 2;
			pointerOffset = -Math.abs(left - contentWidth / 2);
		} else if (left + contentWidth / 2 > window.innerWidth) {
			safeLeft = Math.abs(window.innerWidth - contentWidth / 2);
			pointerOffset = Math.abs(
				window.innerWidth - (left + contentWidth / 2),
			);
		}

		if (top < contentHeight) {
			switchToBottom = true;
			safeTop = bottom + 10;
		}

		return ReactDOM.createPortal(
			<Wrapper
				{...props}
				key="portal_wrapper"
				style={{ top: `${safeTop}px`, left: `${safeLeft}px` }}
				className={classNames('asteria-tooltip', className, {
					'asteria-tooltip-close-to-top': switchToBottom,
				})}
			>
				<TooltipContent
					title={title}
					headerContent={content}
					styler={styler}
					ref={this.contentRef}
					maxwidth={maxTooltipWidth}
					pointerOffset={pointerOffset}
					path={path}
					{...props}
				/>
			</Wrapper>,
			this.wrapper,
		);
	}

	render() {
		const { children, open } = this.props;
		const { isOpen, left = 0 } = this.state;
		let { top } = this.state;
		if (!this.wrapper) {
			return null;
		}

		top -= 10;

		return [
			children ? (
				<TriggerWrapper
					onClick={this.handler}
					onMouseEnter={this.handlerEnter}
					onMouseLeave={this.handlerLeave}
					className="asteria-tooltip-trigger-wrapper"
					ref={this.triggerEl}
					key="tooltip_wrapper"
				>
					{children}
				</TriggerWrapper>
			) : null,
			isOpen || open ? this.renderTooltip(top, left) : null,
		];
	}
}

Tooltip.displayName = 'Tooltip';

export default Tooltip;
