const reducer = (state = {}, { action, payload }) => {
	switch (action) {
		case 'LOGIN_SUCCESS': {
			return {
				...state,
				hasFetchedIntegrations: false,
				needsOnboarding: undefined,
			};
		}
		case 'LOGOUT':
			return {
				...state,
				hasFetchedIntegrations: false,
				needsOnboarding: undefined,
			};
		case 'SET_INTEGRATIONS': {
			let needsOnboarding = false;

			const integrations = payload || [];

			if (integrations.length === 0) {
				needsOnboarding = true;
			}

			if (
				integrations.some(
					integration =>
						integration?.status?.state !== 'IDLE' &&
						integration?.status?.state !== 'ERROR',
				)
			) {
				needsOnboarding = true;
			}

			return {
				...state,
				integrations: payload,
				hasFetchedIntegrations: true,
				needsOnboarding,
				loading: false,
			};
		}
		case 'CONNECTION_SUCCESSFUL':
			return {
				...state,
				integrationInProgress: payload,
			};
		case 'FETCHING_INTEGRATIONS':
			return {
				...state,
				loading: true,
			};
		case 'CONNECTION_CLEANUP':
			return {
				...state,
				integrationInProgress: undefined,
			};
		case 'CONNECTION_FAILED':
			return {
				...state,
				networkError: payload,
			};
		case 'NETWORK_ERROR':
			return {
				...state,
				networkError: payload,
			};
		default:
			return state;
	}
};

export default reducer;
