import jsonwebtoken from 'jsonwebtoken';
import Store from '../../core/store';

const reducer = (
	state = new Store({ authorized: false }),
	{ action, payload },
) => {
	switch (action) {
		case 'LOGIN_INPROGRESS': {
			return {
				...state,
				loading: true,
				errors: false,
			};
		}
		case 'LOGIN_SUCCESS': {
			const tokenData = jsonwebtoken.decode(payload.accessToken);
			return {
				...state,
				authorized: true,
				tokens: payload,
				data: tokenData?.data,
				hasFetchedUser: false,
				loading: false,
				errors: false,
			};
		}
		case 'LOGIN_FAIL': {
			return {
				...state,
				loading: false,
				errors: payload,
			};
		}
		case 'SET_PARTNERS':
			return {
				...state,
				partners: payload,
			};
		case 'ADMIN_LOGIN_INPROGRESS':
			return {
				...state,
				admin: {
					...state.admin,
					authorized: false,
					tokens: null,
					errors: [],
					loading: true,
				},
			};
		case 'ADMIN_LOGIN_FAIL':
			return {
				...state,
				admin: {
					...state.admin,
					errors: payload,
					loading: false,
				},
			};
		case 'ADMIN_LOGIN_SUCCESS':
			return {
				...state,
				admin: {
					...state.admin,
					authorized: true,
					tokens: payload,
					errors: [],
					loading: false,
				},
			};
		case 'LOGOUT':
			return {
				...state,
				authorized: false,
				tokens: undefined,
				hasFetchedUser: false,
			};
		case 'ADMIN_LOGOUT':
			return {
				...state,
				admin: {
					authorized: false,
					tokens: null,
					errors: [],
					loading: false,
				},
			};
		case 'SET_LOADING_USER': {
			return {
				...state,
				userLoading: true,
			};
		}
		case 'SET_USER':
			return {
				...state,
				user: payload,
				hasFetchedUser: true,
				userLoading: false,
			};
		case 'SET_USER_SETTINGS':
			return {
				...state,
				user: {
					...(state?.user || {}),
					settings: {
						...(state?.user?.settings || {}),
						...payload,
					},
				},
			};
		default:
			return state;
	}
};

export default reducer;
