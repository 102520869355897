import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { TranslationService } from '@asteria/services-language';
import Modal, { ModalBody } from '@asteria/component-modal';
import { useLocation, useHistory, useRoute } from '@asteria/utils/hooks';
import { useFeature } from '@asteria/component-featureflag';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { fetchIntegrations } from '@asteria/services-datalayer/services/integrations/actions';

import ErpAddPage from '../integrationsAdd';

import ErpList from './erp';

const SupportModal = styled(({ className }) => {
	const { dispatch } = useContext(DatalayerContext);
	const integrationsAddModal = useFeature({ key: 'integrations-add-modal' });
	const { pathname } = useLocation();
	const history = useHistory();
	const { params: { type = 'status' } = {} } = useRoute();
	const open = pathname === '/integrations' || pathname === '/integrations/';

	useEffect(() => {
		if (open) {
			dispatch(fetchIntegrations());
		}
	}, [dispatch, open]);

	if (
		integrationsAddModal &&
		pathname !== '/integrations' &&
		pathname !== '/integrations/'
	) {
		return type ? <ErpAddPage /> : null;
	}

	return (
		<Modal
			open={open}
			onClose={() => {
				history.push('/');
			}}
			className={className}
			title={TranslationService.get(
				'erpList.modal.title',
				'Lägg till bokföringssystem',
			)}
		>
			<ModalBody className="modal-body">
				<ErpList />
			</ModalBody>
			{/* <ModalFooter /> */}
		</Modal>
	);
})``;

SupportModal.Styler = Modal.Styler;

export default SupportModal;
