import React, { useState, useContext } from 'react';
import { Text } from '@asteria/component-typography';
import Button from '@asteria/component-button';
import {
	Label,
	FormGroup,
	TextInput,
	Dropdown,
	FormGroupV2,
} from '@asteria/component-form/';
import { Translation, TranslationService } from '@asteria/services-language';
import Alert from '@asteria/component-alert';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import Modal, { ModalBody, ModalFooter } from '@asteria/component-modal';
import { selectLanguage } from '@asteria/services-datalayer/services/language/actions';
import { useStore } from '@asteria/utils';
import FormColumn from './formColumn';
import FormRow from './formRow';

const LANGUAGES = [
	{
		value: 'sv',
		label: 'Swedish',
	},
	{
		value: 'en',
		label: 'English',
	},
	{
		value: 'es',
		label: 'Spanish',
	},
	{
		value: 'pt',
		label: 'Portuguese',
	},
	{
		value: 'de',
		label: 'German',
	},
];

const getError = (errors, errorField) =>
	errors.find(({ field }) => field === errorField);

const Signup = ({ path, moveToStage }) => {
	const { lookup } = useContext(DatalayerContext);
	const [errors, setErrors] = useState([]);
	const [showTerms, setShowTerms] = useState(false);
	const [repeatPassword, setRepeatPassword] = useState('');
	const { dispatch } = useContext(DatalayerContext);
	const [form, setForm] = useState({
		company: {},
		user: {},
	});
	const [partnerId] = useStore('store-appstate', state => state.partnerId);

	const onSave = () => {
		const validationErrors = [];

		if (!form.user.password) {
			validationErrors.push({ field: 'user.password', code: 'empty' });
		} else if (repeatPassword !== form.user.password) {
			validationErrors.push({
				field: 'user.password',
				code: 'missmatch',
			});
		}

		if (!form.user.username) {
			validationErrors.push({ field: 'user.username', code: 'empty' });
		}

		if (!form.company.name) {
			validationErrors.push({ field: 'company.name', code: 'empty' });
		}

		if (!form.company.orgnumber) {
			validationErrors.push({
				field: 'company.orgnumber',
				code: 'empty',
			});
		}

		if (validationErrors.length > 0) {
			setErrors(validationErrors);
		} else {
			const apiService = lookup('service-api');
			apiService
				.query(
					`
					mutation Signup($user: SignupUserInputType!, $company: SignupCompanyInputType!, $partnerId: ID, $signupKey: String) {
						signup(
						  user: $user
						  company: $company
						  partnerId: $partnerId
						  signupKey: $signupKey,
						) {
						  ok
						}
					  }
					  
					`,
					{ ...form, partnerId },
					{ reqAuth: false },
				)
				.subscribe(
					resp => {
						if (resp?.data?.signup.ok === true) {
							moveToStage('login');
						}
					},
					e => {
						const message = e?.response?.data?.errors?.[0]?.message;
						setErrors([
							{
								field: 'system.internal',
								code: 'empty',
								message,
							},
						]);
					},
				);
		}
	};

	return (
		<div className="asteria-signup-form">
			{getError(errors, 'system.internal') ? (
				<Alert
					icon
					type="error"
					showClose={false}
					headingContent={getError(errors, 'system.internal').message}
				/>
			) : null}
			<FormColumn className="asteria-signup-form-fields">
				<FormGroup className="asteria-form-group-company">
					<Label>
						{TranslationService.get('signup.company.label')}
					</Label>
					<TextInput
						placeholder={TranslationService.get(
							'signup.company.placeholder',
							'',
						)}
						value={form.company.name}
						onChange={e => {
							setForm({
								...form,
								company: {
									...form.company,
									name: e.target.value,
								},
							});
						}}
					/>
					{getError(errors, 'company.name') ? (
						<Alert
							icon
							type="error"
							showClose={false}
							headingContent={TranslationService.get([
								`error.${
									getError(errors, 'company.name').code
								}`,
								`signup.company.error.${
									getError(errors, 'company.name').code
								}`,
							])}
						/>
					) : null}
				</FormGroup>
				<FormGroup className="asteria-form-group-organisation-number">
					<Label>
						{TranslationService.get(
							'signup.organisationNumber.label',
						)}
					</Label>
					<TextInput
						placeholder={TranslationService.get(
							'signup.organisationNumber.placeholder',
							'',
						)}
						value={form.company.orgnumber}
						onChange={e => {
							setForm({
								...form,
								company: {
									...form.company,
									orgnumber: e.target.value,
								},
							});
						}}
					/>
					{getError(errors, 'company.orgnumber') ? (
						<Alert
							icon
							type="error"
							showClose={false}
							headingContent={TranslationService.get([
								`error.${
									getError(errors, 'company.orgnumber').code
								}`,
								`signup.orgnumber.error.${
									getError(errors, 'company.orgnumber').code
								}`,
							])}
						/>
					) : null}
				</FormGroup>
				<FormGroup className="asteria-form-group-firstname">
					<Label>
						{TranslationService.get('signup.firstname.label')}
					</Label>
					<TextInput
						placeholder={TranslationService.get(
							'signup.firstname.placeholder',
							'',
						)}
						value={form.user.firstname}
						onChange={e => {
							setForm({
								...form,
								user: {
									...form.user,
									firstName: e.target.value,
								},
							});
						}}
					/>
				</FormGroup>
				<FormGroup className="asteria-form-group-surname">
					<Label>
						{TranslationService.get('signup.lastname.label')}
					</Label>
					<TextInput
						placeholder={TranslationService.get(
							'signup.lastname.placeholder',
							'',
						)}
						value={form.user.firstname}
						onChange={e => {
							setForm({
								...form,
								user: {
									...form.user,
									lastName: e.target.value,
								},
							});
						}}
					/>
				</FormGroup>
				<FormGroup className="asteria-form-group-email">
					<Label>
						{TranslationService.get('signup.email.label')}
					</Label>
					<TextInput
						placeholder={TranslationService.get(
							'signup.email.placeholder',
							'',
						)}
						type="email"
						value={form.user.username}
						onChange={e => {
							setForm({
								...form,
								user: {
									...form.user,
									username: e.target.value,
								},
							});
						}}
					/>
					{getError(errors, 'user.username') ? (
						<Alert
							icon
							type="error"
							showClose={false}
							headingContent={TranslationService.get([
								`error.${
									getError(errors, 'user.username').code
								}`,
								`signup.username.error.${
									getError(errors, 'user.username').code
								}`,
							])}
						/>
					) : null}
				</FormGroup>
				<FormGroup className="asteria-form-group-password">
					<Label>
						{TranslationService.get('signup.password.label')}
					</Label>
					<TextInput
						placeholder={TranslationService.get(
							'signup.password.placeholder',
							'',
						)}
						type="password"
						value={form.user.password}
						onChange={e => {
							setForm({
								...form,
								user: {
									...form.user,
									password: e.target.value,
								},
							});
						}}
					/>
					{getError(errors, 'user.password') ? (
						<Alert
							icon
							type="error"
							showClose={false}
							headingContent={TranslationService.get([
								`error.${
									getError(errors, 'user.password').code
								}`,
								`signup.password.error.${
									getError(errors, 'user.password').code
								}`,
							])}
						/>
					) : null}
				</FormGroup>
				<FormGroup className="asteria-form-group-re-password">
					<Label>
						{TranslationService.get('signup.rePassword.label')}
					</Label>
					<TextInput
						placeholder={TranslationService.get(
							'signup.rePassword.placeholder',
							'',
						)}
						type="password"
						value={repeatPassword}
						onChange={e => {
							setRepeatPassword(e.target.value);
						}}
					/>
				</FormGroup>
				<FormGroup className="asteria-form-group-invitation-code">
					<Label>
						{TranslationService.get('signup.invitationCode.label')}
					</Label>
					<TextInput
						placeholder={TranslationService.get(
							'signup.invitationCode.placeholder',
							'',
						)}
						type="text"
						value={form?.signupKey || ''}
						onChange={e => {
							setForm({
								...form,
								signupKey: e.target.value,
							});
						}}
					/>
				</FormGroup>
				<FormGroupV2 className="asteria-form-group-language">
					<Label>
						{TranslationService.get('signup.language.label')}
					</Label>
					<Dropdown
						iconClosed="triangleDown"
						iconOpen="triangleUp"
						options={LANGUAGES}
						placeholder={TranslationService.get(
							'signup.language.placeholder',
							'Välj språk',
						)}
						itemIcon="check"
						showSelected
						search={false}
						type="default"
						selected={[
							LANGUAGES.find(
								({ value }) =>
									value ===
									(localStorage.asteriaLanguage || 'sv'),
							),
						]}
						onChange={option => {
							localStorage.asteriaLanguage = option.value;
							dispatch(selectLanguage(option.value));
						}}
					/>
				</FormGroupV2>
				<FormRow className="asteria-agree-box">
					<Text>{TranslationService.get('signup.agree.terms')}</Text>
					<Button
						text={TranslationService.get('signup.terms.button', '')}
						onClick={() => setShowTerms(true)}
						size="small"
						type="link"
					/>
				</FormRow>
			</FormColumn>
			<Modal
				open={showTerms}
				onClose={() => setShowTerms(false)}
				className="asteria-modal-show-terms"
				size="large"
				title={TranslationService.get(
					'signup.terms.title',
					'Användarvillkor för Asteria',
				)}
			>
				<ModalBody className="modal-body">
					<Translation
						translationKey="signup.terms"
						Component={Text}
					/>
				</ModalBody>
				<ModalFooter className="modal-footer">
					<Button
						size="medium"
						type="default"
						text={TranslationService.get('button.close', 'Stäng')}
						onClick={() => setShowTerms(false)}
					/>
				</ModalFooter>
			</Modal>

			<Button
				text={TranslationService.get('signup.register.button', '')}
				size="regular"
				type="primary"
				onClick={() => onSave()}
			/>
			<FormRow path={`${path}.formRow`}>
				<Button
					text={TranslationService.get(
						'signup.alreadyRegistered.button',
						'',
					)}
					size="regular"
					onClick={() => moveToStage('login')}
					type="link"
					className="asteria-button-registered"
				/>
			</FormRow>
		</div>
	);
};

export default Signup;
