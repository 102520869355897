import Key from './key';
import Success from './success';
import { navigatorCarrier } from '../../createNavigator';

Key.navigator = {
	next: navigatorCarrier(Success.navigationKey),
};

Success.navigator = {
	back: navigatorCarrier(Key.navigationKey),
};

// Build up the symbol-based key-value store of given steps
export default {
	[Success.navigationKey]: Success,
	[Key.navigationKey]: Key,
};

export { Key, Success };
