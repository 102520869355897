import React, { useState, useContext, useCallback } from 'react';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import Button from '@asteria/component-button/button';
import { FormGroup, InputV2, LabelV2 } from '@asteria/component-form';
import styled from 'styled-components';
import { compileStyles, StyleGetter, useStore } from '@asteria/utils';
import { useLocation, useHistory } from '@asteria/utils/hooks';
import { login } from '@asteria/services-datalayer/services/auth/actions';
import { Translation, TranslationService } from '@asteria/services-language';
import Alert from '@asteria/component-alert';
import FormColumn from './formColumn';

const Division = styled.div``;

const ModalBodyStyled = styled(Division)`
	display: flex;
	justify-content: flex-end;

	${({ theme }) =>
		// Wrapper styles
		compileStyles(
			{
				component: Division,
				base: [StyleGetter(`authenticationV2.login.modalBody`, {})],
				children: [
					{
						component: Button,
						base: [StyleGetter(`button`, {})],
					},
				],
			},
			theme,
		)}
`;

/**
 * Login flow component
 */

const Login = styled(({ moveToStage }) => {
	const { dispatch } = useContext(DatalayerContext);
	const { state: { location = '/' } = {} } = useLocation();
	const history = useHistory();
	const [errors] = useStore('store-auth', ({ errors: list }) => list);
	const [loading] = useStore('store-auth', ({ loading: value }) => value);
	const [form, setForm] = useState({ username: '', password: '' });
	const [partnerId] = useStore('store-appstate', state => state.partnerId);

	const onSubmit = useCallback(
		e => {
			e.preventDefault();
			dispatch(
				login(
					{ ...form, partnerId },
					history,
					['login', 'signup'].includes(location) ? '/' : location,
				),
			);
		},
		[dispatch, form, history, location, partnerId],
	);

	return (
		<div className="asteria-login-form">
			<form onSubmit={onSubmit}>
				{errors && (
					<Alert
						icon
						description={false}
						type="error"
						headingContent={
							<Translation
								translationKey="authentication.login.error"
								defaultText="Fel användarnamn eller lösenord"
							/>
						}
					/>
				)}
				<FormColumn className="asteria-user-token">
					<FormGroup>
						<LabelV2 style={{ boxSizing: 'border-box' }}>
							{TranslationService.get('login.email.label')}
						</LabelV2>
						<InputV2
							placeholder={TranslationService.get(
								'login.email.placeholder',
								'',
							)}
							type="email"
							onChange={e => {
								setForm({
									...form,
									username: e.target.value,
								});
							}}
							value={form.username}
						/>
					</FormGroup>
					<FormGroup>
						<LabelV2 style={{ boxSizing: 'border-box' }}>
							{TranslationService.get('login.password.label')}
						</LabelV2>
						<InputV2
							placeholder={TranslationService.get(
								'login.password.placeholder',
								'',
							)}
							type="password"
							onChange={e => {
								setForm({
									...form,
									password: e.target.value,
								});
							}}
							value={form.password}
						/>
					</FormGroup>
					<ModalBodyStyled>
						<Button
							text={TranslationService.get(
								'login.lostPassword.button',
								'',
							)}
							size="small"
							onClick={() => moveToStage('restorePass')}
							type="link"
							className="asteria-button-lost-password"
						/>
					</ModalBodyStyled>
				</FormColumn>
				<Button
					text={TranslationService.get('login.login.button', '')}
					size="regular"
					type="primary"
					buttonType="submit"
					disabled={loading}
				/>
				<Button
					text={TranslationService.get(
						'login.notRegistered.button',
						'',
					)}
					size="regular"
					onClick={() => moveToStage('signup')}
					type="link"
					className="asteria-button-register"
				/>
			</form>
		</div>
	);
})``;

export default Login;
