import React from 'react';
import styled, { css } from 'styled-components';
import { StyleGetter, preProcess } from '@asteria/utils';
import Tooltip from '@asteria/component-tooltip';
import classNames from 'classnames';
import posed from 'react-pose';

import { TranslationService } from '@asteria/services-language';
import LineGraph, { LineGroup } from './line/lineGraph';
import BarGraph from './bar/barGraph';
import BarGroup from './bar/bargroup';
import YAxis, { YAxisLabels, YAxisLines, YAxisLine, Text } from '../yaxis';
import XAxis, { XAxisLabel } from '../xaxis';
import { range as RangeGenerator } from '../utils';
import GraphContext from '../context';

const AnimatedGraph = posed.div({
	open: {
		opacity: 1,
		delayChildren: 2000,
		staggerChildren: 500,
		transition: { duration: 2000 },
	},
	closed: { opacity: 0, transition: { duration: 2000 } },
});
AnimatedGraph.displayName = 'AnimatedGraph';

const GraphArea = styled(AnimatedGraph)`
	display: -ms-grid;
	-ms-grid-columns: auto;
	-ms-grid-rows: auto 50px;
	-ms-grid-row: 1;
	-ms-grid-row-span: 2;
	-ms-grid-column: 2;

	display: grid;
	grid-auto-columns: min-content;
	grid-template-rows: auto 50px;
	grid-row: 1 / 3;
	grid-column: 2 / 2;

	overflow: hidden;
	position: relative;

	${BarGroup.type} {
		-ms-grid-row: 1;
		-ms-grid-column: auto;
		grid-row: 1;
		grid-column: auto;
	}

	${LineGroup} {
		-ms-grid-row: 1;
		-ms-grid-column: auto;
		grid-row: 1;
		grid-column: auto;
	}

	&.asteria-graph-area-grouped {
		&.asteria-graph-area-bars {
			${XAxisLabel} {
				${({ groups }) =>
					RangeGenerator(groups - 2).map(
						index =>
							`:nth-of-type(${index +
								groups -
								2}) {grid-column: ${index};-ms-grid-column: ${index};}`,
					)}
			}
		}

		&.asteria-graph-area-lines {
			${LineGroup} {
				${({ groups }) =>
					RangeGenerator(groups).map(
						index =>
							`:nth-of-type(${index + 1}) {grid-column: ${index +
								1};-ms-grid-column: ${index + 1};}`,
					)}
			}

			${LineGroup} {
				${({ groups }) =>
					RangeGenerator(groups - 1).map(
						index =>
							`:nth-of-type(${index + groups}) {
									grid-column: ${index + 1};
								-ms-grid-column: ${index + 1};
							}`,
					)}
			}

			${XAxisLabel} {
				${({ groups }) =>
					RangeGenerator(groups).map(
						index =>
							`:nth-of-type(${index +
								groups * 2 -
								3}) {grid-column: ${index};-ms-grid-column: ${index};}`,
					)}
			}
		}
	}

	&.asteria-graph-area-stacked {
		&.asteria-graph-area-lines {
			${LineGroup} {
				${({ groups }) =>
					RangeGenerator(groups).map(
						index =>
							`:nth-of-type(${index +
								groups -
								1}) {grid-column: ${index +
								1};-ms-grid-column: ${index + 1};}`,
					)}
			}

			${LineGroup} {
				${({ groups }) =>
					RangeGenerator(groups).map(
						index =>
							`:nth-of-type(${index +
								groups * 2 -
								2}) {grid-column: ${index +
								1};-ms-grid-column: ${index + 1};}`,
					)}
			}

			${XAxisLabel} {
				${({ groups }) =>
					RangeGenerator(groups).map(
						index =>
							`:nth-of-type(${index +
								groups * 3 -
								5}) {grid-column: ${index};-ms-grid-column: ${index};}`,
					)}
			}
		}
	}

	&.asteria-graph-area-bars {
		${BarGroup.type} {
			${({ groups }) =>
				RangeGenerator(groups).map(
					index =>
						`:nth-of-type(${index}) {grid-column: ${index};-ms-grid-column: ${index};}`,
				)}
		}

		${XAxisLabel} {
			${({ groups }) =>
				RangeGenerator(groups).map(
					index =>
						`:nth-of-type(${index +
							groups -
							2}) {grid-column: ${index};-ms-grid-column: ${index};}`,
				)}
		}
	}

	${XAxisLabel} {
		-ms-grid-row: 2;
		grid-row: 2;
	}
`;
GraphArea.displayName = 'GraphArea';
GraphArea.Styler = {
	children: [
		{
			component: BarGroup.type,
			base: [StyleGetter('bargroup')],
		},
		{
			component: LineGroup,
			base: [StyleGetter('linegroup')],
		},
		{
			component: XAxisLabel,
			base: [StyleGetter('xaxis')],
		},
	],
};

const processColor = (color = '', theme) => {
	if (color.startsWith('$')) {
		return preProcess(
			`var(--${color.replace('$', 'system-')}-color)`,
			theme,
		);
	}

	return color;
};

const Graph = styled(
	class Graph extends React.Component {
		constructor(props) {
			super();

			this.moved = 0;

			this.handleMouseDown = this.handleMouseDown.bind(this);
			this.handleMouseUp = this.handleMouseUp.bind(this);
			this.handleMouseMove = this.handleMouseMove.bind(this);
			this.handleMouseEnter = this.handleMouseEnter.bind(this);
			this.handleMouseLeave = this.handleMouseLeave.bind(this);
			this.handleScroll = this.handleScroll.bind(this);

			this.updateSize = this.updateSize.bind(this);
			this.target = null;

			this.state = {
				topMargin: 0,
				bottomMargin: 0,
				tooltip: {},
				isDragging: false,
				actions: {
					clickAction: props.onClick,
					mouseEnterAction: this.handleMouseEnter,
					mouseLeaveAction: this.handleMouseLeave,
					setTarget: el => {
						this.target = el;
					},
				},
			};
		}

		static getDerivedStateFromProps(props, state) {
			if (state.actions.clickAction !== props.onClick) {
				return {
					...state,
					actions: {
						...state.actions,
						clickAction: props.onClick,
					},
				};
			}

			return null;
		}

		handleScroll(e) {
			const { next = () => {} } = this.props;
			const {
				shiftKey = false,
				wheelDeltaX = 0,
				wheelDeltaY = 0,
				wheelDelta = 0,
			} = e.nativeEvent;

			if ((shiftKey && wheelDelta !== 0) || wheelDeltaX) {
				const value = wheelDeltaX * 10 || wheelDelta;

				if (wheelDeltaY !== 0) {
					return;
				}

				if (value >= 50) {
					e.preventDefault();
					this.setState(state => {
						next(-1);
						return state;
					});
				} else if (value <= -50) {
					e.preventDefault();
					this.setState(state => {
						next(1);
						return state;
					});
				}
			}
		}

		handleMouseDown(e) {
			const { button, type, changedTouches } = e;
			let { clientX, clientY } = e;
			if (button !== 0 && type !== 'touchstart') {
				return;
			}

			e.preventDefault();

			if (type === 'touchstart') {
				const [{ clientX: touchX, clientY: touchY }] = changedTouches;
				clientX = touchX;
				clientY = touchY;
			}

			if (type === 'touchstart') {
				window.addEventListener('touchmove', this.handleMouseMove);
				window.addEventListener('touchend', this.handleMouseUp);
			} else {
				window.addEventListener('mousemove', this.handleMouseMove);
				window.addEventListener('mouseup', this.handleMouseUp);
			}

			this.moved = 0;
			this.isDragging = true;
			this.setState({ isDragging: true });
			this.origin = { x: clientX, y: clientY };
			this.last = { x: clientX, y: clientY };
		}

		handleMouseUp() {
			window.removeEventListener('mousemove', this.handleMouseMove);
			window.removeEventListener('mouseup', this.handleMouseUp);
			window.removeEventListener('touchmove', this.handleMouseMove);
			window.removeEventListener('touchend', this.handleMouseUp);
			this.isDragging = false;
			if (this.target) {
				this.target = null;
				this.targetSize = null;
			}
			this.setState({ isDragging: false });
		}

		handleMouseMove(e) {
			const { changedTouches, type } = e;
			let { clientX, clientY } = e;

			const {
				origin: { x, y },
			} = this;

			const {
				last: { x: lastX, y: lastY },
			} = this;

			if (type === 'touchmove') {
				const [{ clientX: touchX, clientY: touchY }] = changedTouches;
				clientX = touchX;
				clientY = touchY;

				const diffX = clientX - lastX;
				const diffY = clientY - lastY;

				this.last.x = clientX;
				this.last.y = clientY;

				if (diffX === 0 || diffY !== 0) {
					return;
				}
			}

			e.preventDefault();

			const { next = () => {} } = this.props;

			const translation = {
				x: clientX - x,
				y: clientY - y,
				deltaX: clientX - lastX,
				deltaY: clientY - lastY,
			};

			if (this.target) {
				this.target(translation);
			} else {
				if (this.moved - Math.floor(translation.x / 100) === 0) {
					return;
				}

				const diff = Math.floor(translation.x / 100) - this.moved;
				this.moved = Math.floor(translation.x / 100);
				this.setState(state => {
					next(-diff);
					return state;
				});
			}
		}

		handleMouseEnter(data) {
			const { onMouseEnter = () => {} } = this.props;

			if (this.isDragging) {
				return;
			}

			if (data.target) {
				this.setState({ tooltip: data });
			}

			onMouseEnter(data);
		}

		handleMouseLeave(data) {
			const { onMouseLeave = () => {} } = this.props;

			if (this.isDragging) {
				return;
			}

			this.setState({ tooltip: {} });

			onMouseLeave(data);
		}

		updateSize(value, size) {
			const {
				parts = ['bars', 'lines'],
				graph: { steps = [], barsSteps = [], linesSteps = [] },
			} = this.props;

			let xAxis = steps;
			if (parts.length === 1) {
				xAxis = parts.includes('bars') ? barsSteps : linesSteps;
			}

			const { topMargin, bottomMargin, isDragging } = this.state;
			if (isDragging) {
				return;
			}
			if (value === xAxis[0].value && topMargin !== size) {
				this.setState({
					bottomMargin,
					topMargin: size,
					yAxisStyle: {
						marginTop: size / 2,
						marginBottom: bottomMargin / 2 - 1,
					},
				});
			} else if (
				value === xAxis[xAxis.length - 1].value &&
				bottomMargin !== size
			) {
				this.setState({
					topMargin,
					bottomMargin: size,
					yAxisStyle: {
						marginTop: topMargin === 0 ? size / 2 : topMargin / 2,
						marginBottom: size / 2 - 1,
					},
				});
			}
		}

		render() {
			const {
				className,
				parts = ['bars', 'lines'],
				graph: {
					id = 'base-graph',
					steps = [],
					barsSteps = [],
					linesSteps = [],
					range = [],
					barGroups = {},
					graphMaxValue = 0,
					barsMaxValue = 0,
					linesMaxValue = 0,
					linesMinValue = 0,
					graphGroupWidth = 0,
					visibleCategories = [],
					activeBars = [],
					activeGroups = [],
					hoverGroups = [],
					hoverBars = [],
				},
				options: { showXAxis = true, showYAxis = true } = {},
				updateSize = () => {},
				getTooltip = () => {},
				filters = [],
				size,
				barLayout = 'grouped',
			} = this.props;
			const { yAxisStyle, tooltip, actions } = this.state;

			let yAxis = steps;
			if (parts.length === 1) {
				yAxis = parts.includes('bars') ? barsSteps : linesSteps;
			}

			let maxValue = graphMaxValue;
			let minValue = 0;
			if (parts.length === 1) {
				maxValue = parts.includes('bars')
					? barsMaxValue
					: linesMaxValue;
				minValue = linesMinValue;
			}

			return (
				<GraphContext.Provider value={actions}>
					<div
						className={classNames(
							className,
							`asteria-graph`,
							`asteria-graph-${id}`,
							`asteria-graph-${barLayout}`,
							visibleCategories.map(
								category =>
									`asteria-graph-visible-category-${
										category.name.startsWith('$')
											? category.name.replace('$', '')
											: category._id
									}`,
							),
							{
								'asteria-graph-has-filter':
									filters.filter(({ type }) => type === 'tag')
										.length > 0,
								'asteria-graph-has-no-filter':
									filters.filter(({ type }) => type === 'tag')
										.length === 0,
								'asteria-graph-has-active':
									activeGroups.length > 0 ||
									activeBars.length > 0,
								'asteria-graph-is-active-group':
									activeGroups.length > 0 &&
									activeBars.length === 0,
								'asteria-graph-dragging': this.isDragging,
								'asteria-graph-bars': parts.includes('bars'),
								'asteria-graph-lines': parts.includes('lines'),
							},
						)}
					>
						{showYAxis ? (
							<>
								{yAxis.length > 1 &&
								Math.abs(yAxis[0].value) -
									Math.abs(yAxis[1].value) >
									1000 ? (
									<Text className="asteria-curreny-y-axis">
										{TranslationService.get(
											[
												'graph.yaxis.prefix',
												'graph.yaxis.prefix.1000',
											],
											'Tkr',
										)}
									</Text>
								) : (
									<Text className="asteria-curreny-y-axis">
										{TranslationService.get(
											'graph.yaxis.prefix',
											'Kr',
										)}
									</Text>
								)}
								<YAxis
									steps={yAxis}
									style={yAxisStyle}
									updateSize={this.updateSize}
								/>
							</>
						) : null}
						<GraphArea
							groups={range.length}
							className={classNames(
								'asteria-graph-area',
								`asteria-graph-area-${barLayout}`,
								{
									'asteria-graph-area-has-filter':
										visibleCategories.length > 0,
									'asteria-graph-area-has-no-filter':
										visibleCategories.length === 0,
									'asteria-graph-area-has-active':
										activeGroups.length > 0 ||
										activeBars.length > 0,
									'asteria-graph-area-dragging': this
										.isDragging,
									'asteria-graph-area-bars': parts.includes(
										'bars',
									),
									'asteria-graph-area-lines': parts.includes(
										'lines',
									),
								},
							)}
							onMouseDown={this.handleMouseDown}
							onTouchStart={this.handleMouseDown}
							onMouseUp={this.handleMouseUp}
							onTouchEnd={this.handleMouseUp}
							onWheel={this.handleScroll}
						>
							{parts.includes('bars') ? (
								<BarGraph
									id={id}
									layout={barLayout}
									range={range}
									groups={barGroups}
									maxValue={maxValue}
									margin={yAxisStyle}
									activeGroups={activeGroups}
									activeBars={activeBars}
									hoverGroups={hoverGroups}
									filters={filters}
									size={size}
								/>
							) : null}
							{parts.includes('lines') ? (
								<LineGraph
									id={id}
									layout={barLayout}
									range={range}
									groups={barGroups}
									maxValue={maxValue}
									minValue={minValue}
									margin={yAxisStyle}
									activeGroups={activeGroups}
									activeBars={activeBars}
									hoverGroups={hoverGroups}
									size={size}
									steps={steps}
									width={
										Number.isNaN(graphGroupWidth)
											? 'auto'
											: graphGroupWidth
									}
								/>
							) : null}
							{showXAxis ? (
								<XAxis
									labels={range}
									updateSize={updateSize}
									activeGroups={activeGroups}
									activeBars={activeBars}
									hoverGroups={hoverGroups}
									hoverBars={hoverBars}
									groups={barGroups}
								/>
							) : null}
						</GraphArea>
						{tooltip.target && !this.isDragging ? (
							<Tooltip
								key="tooltip"
								open
								hover
								isStatic
								targetEl={tooltip.target}
								{...getTooltip(tooltip)}
							/>
						) : null}
					</div>
				</GraphContext.Provider>
			);
		}
	},
)`
	height: 300px;
	display: -ms-grid;
	-ms-grid-columns: 100px auto;
	-ms-grid-rows: auto 50px;

	display: grid;
	grid-template-columns: 100px 1fr;
	grid-template-rows: 1fr 50px;

	${YAxisLabels} {
		grid-row: 1 / 1;
		grid-column: 1 / 2;
	}

	&.asteria-graph-stacked {
		${BarGroup.type} {
			grid-template-rows: ${({ graph: { steps = [] } = {} }) => {
				const size = steps.length - 1;
				const zeroIndex = steps.findIndex(({ value }) => value === 0);
				const topZone = (size - zeroIndex) * (100 / size);
				const bottomZone = (size - (size - zeroIndex)) * (100 / size);

				return `calc(${bottomZone}% - 2.5px) calc(${topZone}% - 1px) !important`;
			}};
		}
	}

	${({ theme, filters = [], availableCategories = [] }) => {
		const statusFilters = filters
			.filter(({ type }) => type === 'status')
			.map(({ config: { status } = {} }) => status);

		let parts = filters
			.map(({ id, type, config: { name } = {} }) => {
				if (type === 'tag') {
					return css`
						.asteria-graph-part-tag-${id} {
							background-color: ${processColor(name, theme)};
							display: block;
						}
					`;
				}

				return '';
			})
			.concat(
				filters
					.filter(({ type }) => type === 'tag')
					.reduce((acc, { id }) => {
						const category = availableCategories.find(
							({ tags }) =>
								tags.find(({ _id }) => id === _id) !==
								undefined,
						);

						if (category) {
							return [
								...acc,
								css`
									.asteria-graph-part-category-${category._id} {
										display: block;
										background-color: ${processColor(
											category.name,
											theme,
										)};
									}
								`,
							];
						}

						return acc;
					}, []),
			);

		if (statusFilters.length !== 0) {
			parts = [
				...parts,
				css`
					.asteria-graph-bar-main > .asteria-graph-bar-part {
						display: none;
					}
				`,
			];
			parts = parts.concat(
				statusFilters.map(
					status => css`
						.asteria-graph-bar-main
							> .asteria-graph-bar-part-${status.toLowerCase()} {
							display: block;
							background-color: transparent;
						}
					`,
				),
			);
		}

		if (parts) {
			return [];
		}

		return [];
	}}

	.asteria-unpaid .asteria-graph-bar-main {
		background-color: yellow;
	}
`;
Graph.displayName = 'Graph';
Graph.Styler = {
	children: [
		{
			component: YAxisLabels,
			base: [StyleGetter('yaxis.labels')],
			typePrefix: 'asteria-graph-yaxis-labels',
			children: [
				{
					component: Text,
					base: [StyleGetter('text')],
					typePrefix: 'asteria-graph-yaxis-label',
				},
			],
		},
		{
			component: YAxisLines,
			base: [StyleGetter('yaxis.lines')],
			typePrefix: 'asteria-graph-yaxis-lines',
			children: [
				{
					component: YAxisLine,
					base: [StyleGetter('line')],
					typePrefix: 'asteria-graph-yaxis-line',
				},
			],
		},
		{
			component: GraphArea,
			base: [StyleGetter('graphArea')],
		},
	],
	typePrefix: 'asteria-graph',
};

export default Graph;
