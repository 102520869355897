/* eslint-disable no-unused-vars */
import { map } from 'rxjs/operators';
import uuid from 'uuid/v4';

import {
	ADD_TRANSACTION,
	UPDATE_TRANSACTION,
	UPDATE_TRANSACTIONS,
	DELETE_TRANSACTION,
	DELETE_TRANSACTIONS,
} from './queries';

import { setSelectedType } from '../appstate/actions';

export const addTransaction = (transaction = {}) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'ADD_TRANSACTION',
		payload: transaction,
	}));
};

export const addTransactions = (transactions = []) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'ADD_TRANSACTIONS',
		payload: transactions,
	}));
};

export const removeTransaction = transaction => ({ dispatch }) => {
	dispatch(() => ({
		action: 'REMOVE_TRANSACTION',
		payload: transaction,
	}));
};

export const removeFromList = transaction => ({ dispatch }) => {
	dispatch(() => ({
		action: 'REMOVE_FROM_LIST',
		payload: transaction,
	}));
};

export const editTransactions = (transactions = []) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'EDIT_TRANSACTIONS',
		payload: transactions,
	}));
};

export const setOnboardingStep = ({ id, type }) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_ADJUSTABLE_ONBOARDING_STEP',
		payload: { id, type },
	}));
};

export const setOnboarding = active => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_ADJUSTABLE_ONBOARDING',
		payload: active,
	}));
};

export const openRepatableModal = payload => ({ dispatch }) => {
	dispatch(() => ({
		action: 'OPEN_REPEATABLE_MODAL',
		payload,
	}));
};

export const closeRepeatableModal = payload => ({ dispatch }) => {
	dispatch(() => ({
		action: 'CLOSE_REPEATABLE_MODAL',
		payload,
	}));
};

export const editTransactionField = payload => ({ dispatch }) => {
	dispatch(() => ({
		action: 'EDIT_TRANSACTION_FIELD',
		payload,
	}));
};

export const clearTransactionField = payload => ({ dispatch }) => {
	dispatch(() => ({
		action: 'CLEAR_TRANSACTION_FIELD',
		payload,
	}));
};

export const applyEditsToTransactions = (item, changes) => {
	const original = changes.$original || {};
	const update = {
		...item,
	};

	if (changes['payment-date']) {
		update.paymentDate = new Date(changes['payment-date']);
	}

	if (changes.total) {
		update.sums = {
			original: {
				total: parseFloat(changes.total),
				currency: changes.currency,
			},
		};
	}

	const links =
		original?.links?.filter(({ type }) => type !== 'CLIENT') || [];
	if (changes.client) {
		delete update.description;
		update.links = [
			...links.map(({ id: linkId, type: linkType, clientId }) => ({
				id: linkId,
				type: linkType,
				clientId,
			})),
			{
				id: changes.client,
				type: 'CLIENT',
				clientId: changes.client,
			},
		];
	} else if (changes.type === 'free') {
		update.links = links.map(
			({ id: linkId, type: linkType, clientId }) => ({
				id: linkId,
				type: linkType,
				clientId,
			}),
		);
	}

	if (
		changes.description &&
		changes.type !== 'customer' &&
		changes.type !== 'supplier'
	) {
		update.meta = { description: changes.description };
	}

	// let tags;
	if (changes.type) {
		// tags = [];
		// update.
	}

	return { data: update };
};

export const saveEditedTransactions = payload => ({
	lookup,
	dispatch,
	rootStore,
}) => {
	const apiService = lookup('service-api');
	const { adjustable: { edits = {} } = {} } = rootStore;

	const ids = payload || Object.keys(edits);

	const updates = ids
		.filter(item => edits[item] && !edits[item]?.$removed)
		.map(item => {
			const changes = edits[item];
			const original = changes.$original || {};
			const update = {};

			if (changes['payment-date']) {
				update.paymentDate = new Date(changes['payment-date']);
			}

			if (changes.total) {
				update.sums = {
					original: {
						total: parseFloat(changes.total),
						currency: changes.currency,
					},
				};
			}

			const links =
				original?.links?.filter(({ type }) => type !== 'CLIENT') || [];
			if (changes.client) {
				update.links = [
					...links.map(
						({ id: linkId, type: linkType, clientId }) => ({
							id: linkId,
							type: linkType,
							clientId,
						}),
					),
					{
						id: changes.client,
						type: 'CLIENT',
						clientId: changes.client,
					},
				];
			} else if (changes.type === 'free') {
				update.links = links.map(
					({ id: linkId, type: linkType, clientId }) => ({
						id: linkId,
						type: linkType,
						clientId,
					}),
				);
			}

			if (
				changes.description &&
				changes.type !== 'customer' &&
				changes.type !== 'supplier'
			) {
				update.meta = { description: changes.description };
			}

			let tags;
			if (changes.type) {
				tags = [
					{
						tagName: changes.categories.tag,
						categoryName: changes.categories.category,
					},
				];

				if (!update.meta) {
					update.meta = {};
				}

				update.meta.tags = [];
				update.meta.categories = [];
			}

			return { id: item, data: update, tags };
		});

	const removed = ids.filter(item => edits[item] && edits[item]?.$removed);

	apiService
		.query(DELETE_TRANSACTIONS, { ids: removed }, { reqAuth: true })
		.subscribe(() => {
			apiService
				.query(
					UPDATE_TRANSACTIONS,
					{ input: updates },
					{ reqAuth: true },
				)
				.pipe(map(({ data }) => data?.updateTransactions))
				.subscribe({
					next: trans => trans,
					complete: () => {
						const {
							list: { types },
						} = rootStore;

						dispatch(setSelectedType([...types]));
					},
				});
		});
};

export const saveTransactions = (
	transactions = [],
	_,
	removeRepeatable = false,
) => ({ lookup, dispatch, rootStore }) => {
	const apiService = lookup('service-api');
	transactions.map(({ $new, id, meta, description, ...transaction }) =>
		apiService
			.query(
				id ? UPDATE_TRANSACTION : ADD_TRANSACTION,
				{
					id,
					recurrent: removeRepeatable,
					input: {
						...transaction,
						recurrentEvent: transaction?.recurrentEvent
							? {
									...transaction?.recurrentEvent,
									parentId: undefined,
							  }
							: undefined,
						message: undefined,
						meta: {
							...meta,
							tags: undefined,
							categories: undefined,
						},
						manual: id ? transaction.manual : true,
						links: transaction?.links?.find(
							({ type }) => type === 'CLIENT',
						)
							? transaction?.links?.map(
									({
										id: linkId,
										type: linkType,
										clientId,
									}) => ({
										id: linkId,
										type: linkType,
										clientId,
									}),
							  )
							: undefined,
					},
					categories:
						meta?.tags?.map(({ category }) => ({
							categoryName: category?.name || category,
						})) || [],
					tags:
						meta?.tags?.map(({ name, category }) => ({
							tagName: name,
							categoryName: category?.name || category,
						})) || [],
				},
				{ reqAuth: true },
			)
			.pipe(
				map(
					({ data }) =>
						data?.createTransaction?.data ||
						data?.updateTransaction?.data ||
						false,
				),
			)
			.subscribe({
				next: trans => trans,
				complete: () => {
					const {
						list: { types },
					} = rootStore;

					dispatch(setSelectedType([...types]));
				},
			}),
	);
};

export const removeTransactions = (
	transactions = [],
	removeRepeatable = false,
) => ({ lookup, dispatch, rootStore }) => {
	const apiService = lookup('service-api');
	transactions.map(transaction =>
		apiService
			.query(
				DELETE_TRANSACTION,
				{
					id: transaction.id,
					recurrent: removeRepeatable,
				},
				{ reqAuth: true },
			)
			.pipe(map(({ data }) => data?.deleteTransaction))
			.subscribe({
				next: trans => trans,
				complete: () => {
					const {
						list: { types },
					} = rootStore;

					dispatch(setSelectedType([...types]));
				},
			}),
	);
};
