import React, { useState, useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import {
	addMonths,
	format,
	isThisMonth,
	isFuture,
	addWeeks,
	addYears,
	startOfMonth,
	startOfISOWeek,
	addDays,
	startOfYear,
	isPast,
} from 'date-fns';
import { TranslationService } from '@asteria/services-language';
import { Text } from '@asteria/component-core/typography';
import Button from '@asteria/component-core/button';
import { FormGroupV2, InputV2, Dropdown } from '@asteria/component-form';
import { useStore } from '@asteria/utils';
import InfoBox, { InfoBoxToggle } from './infoBox';

const addTimeslice = (date, size, count = 1) => {
	if (size === 'day') {
		return addDays(date, count);
	}
	if (size === 'week') {
		return addWeeks(date, count);
	}
	if (size === 'month') {
		return addMonths(date, count);
	}
	if (size === 'year') {
		return addYears(date, count);
	}

	return date;
};

const startOfTime = (date, size) => {
	if (size === 'week') {
		return startOfISOWeek(date);
	}

	if (size === 'month') {
		return startOfMonth(date);
	}

	if (size === 'year') {
		return startOfYear(date);
	}

	return startOfMonth(date);
};

const Row = styled.div`
	align-items: center;
	justify-content: center;
	/* border-bottom: 1px solid var(--transaction-list-border-color); */
	/* 	min-height: 48px; */
`;

const Cell = styled.div`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 0;
`;

const Tag = styled.div``;
const Prefix = styled.div``;

function Header({ list = [], firstDate, setFirstDate }) {
	return (
		<div className="asteria-header">
			<Row className="asteria-header-list">
				<Cell className="asteria-header-list-cell">
					{/* <Text style={{ ...alignLeft }}>Kategori</Text> */}
					{/* 					<Button
						type="link"
						icon="calendar"
						size="small"
						text="2020"
					/> */}
				</Cell>
				<Cell className="asteria-header-list-cell">
					<Button
						type="link"
						icon="circleArrowLeft"
						size="small"
						className="asteria-button-left"
						onClick={() => {
							setFirstDate(addTimeslice(firstDate, 'month', -12));
						}}
					/>
				</Cell>
				{list.map(item => (
					<Cell
						key={item.id}
						className={classNames('asteria-header-list-cell', {
							'asteria-header-list-cell-history':
								!isThisMonth(item.date) && isPast(item.date),
							'asteria-header-list-cell-today': isThisMonth(
								item.date,
							),
							'asteria-header-list-cell-future':
								!isThisMonth(item.date) && isFuture(item.date),
						})}
					>
						<Text>{item.title}</Text>
					</Cell>
				))}
				<Cell className="asteria-header-list-cell">
					<Button
						type="link"
						icon="circleArrowRight"
						size="small"
						className="asteria-button-right"
						onClick={() => {
							setFirstDate(addTimeslice(firstDate, 'month', 12));
						}}
					/>
				</Cell>
			</Row>
		</div>
	);
}

/* const Sidebar = styled(
	({
		className,
		level = 0,
		editable = false,
		removeChild,
		item: {
			id,
			active = false,
			color,
			title,
			type,
			adaptable = false,
			items = [],
			values = [],
		},
	}) => {
		const [open, setOpen] = useState(active ? id : false);
		const [hover, setHover] = useState(false);
		const [children, setChildren] = useState(items);

		useEffect(() => {
			if (items.length > 0) {
				setChildren(items);
			}
		}, [items]);

		return (
			<SidebarWrapper
				key={id}
				className={classNames(
					className,
					'asteria-transactions-sidebar',
					`asteria-transactions-sidebar-${level}`,
					`asteria-transactions-sidebar-${type}`,
				)}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				<Cell
					onClick={() => setOpen(open === id ? false : id)}
					className={classNames('asteria-transactions-sidebar-cell', {
						'asteria-transactions-sidebar-toggle':
							children.length > 0,
					})}
				>
					<Tag className="asteria-tag">
						{children.length > 0 ? (
							<Button
								type="link"
								icon="right"
								className={`${
									open
										? 'asteria-button-folded'
										: 'asteria-button-expanded'
								} asteria-button asteria-button-toggle`}
								size="small"
								onClick={() =>
									setOpen(open === id ? false : id)
								}
							/>
						) : null}

						{color ? (
							<Prefix
								className="asteria-tag-prefix"
								style={{ backgroundColor: color }}
							/>
						) : null}
						{editable ? (
							<FormGroupV2 className="asteria-form">
								<InputV2
									className="asteria-input-wrapper"
									elementType="input"
									size="small"
									type="text"
									placeholder="Fyll i namnet"
									value={title[0]}
									onChange={() => {}}
								/>
							</FormGroupV2>
						) : (
							<Text className="asteria-tag-label">
								{TranslationService.get(title, title[0])}
							</Text>
						)}
					</Tag>

					{editable ? (
						<Button
							type="link"
							className="asteria-button asteria-button-remove-row"
							icon="circleMinusFilled"
							size="small"
							onClick={() => removeChild()}
						/>
					) : null}
				</Cell>
			</SidebarWrapper>
		);
	},
)``;
 */
const Categories = {
	deposit: [
		{
			label: 'Kundfakturor',
			value: '$customer',
		},
		{
			label: 'Kortbetalningar',
			value: '$card',
		},
		{
			label: 'Kontantbetalningar',
			value: '$cash',
		},
		{
			label: 'Överföringar',
			value: '$transfer',
		},
	],
	withdraw: [
		{
			label: 'Leverantörsfaktura',
			value: '$supplier',
		},
		{
			label: 'Lön',
			value: '$salary',
		},
		{
			label: 'Skatt',
			value: '$tax',
		},
		{
			label: 'Kortbetalningar',
			value: '$card',
		},
		{
			label: 'Kontantbetalningar',
			value: '$cash',
		},
		{
			label: 'Överföringar',
			value: '$transfer',
		},
	],
};

const TransactionRow = styled(
	({
		className,
		level = 0,
		editable = false,
		header = [],
		item: {
			id,
			active = false,
			color,
			title,
			type,
			adaptable = false,
			items = [],
			values = [],
		},
	}) => {
		const [open, setOpen] = useState(active ? id : false);
		const [hover, setHover] = useState(false);
		const [children, setChildren] = useState(items);

		useEffect(() => {
			if (items.length > 0) {
				setChildren(items);
			}
		}, [items]);

		return (
			<div
				key={id}
				className={classNames(
					className,
					'asteria-transactions-list-wrapper',
					`asteria-transactions-list-wrapper-${level}`,
					{
						'asteria-transactions-list-wrapper-open': open,
						'asteria-transactions-list-wrapper-folded': !open,
					},
				)}
			>
				<Row
					key={id}
					className={classNames(
						className,
						'asteria-transactions-list',
						`asteria-transactions-list-${level}`,
						`asteria-transactions-list-${type}`,
						{
							'asteria-transactions-list-open': open,
							'asteria-transactions-list-folded': !open,
						},
					)}
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
				>
					<Cell
						onClick={() => setOpen(open === id ? false : id)}
						className={classNames(
							'asteria-transactions-list-cell',
							{
								'asteria-transactions-list-toggle':
									children.length > 0,
							},
						)}
					>
						<Tag className="asteria-tag">
							{children.length > 0 ? (
								<Button
									type="link"
									icon="right"
									className={`${
										open
											? 'asteria-button-folded'
											: 'asteria-button-expanded'
									} asteria-button asteria-button-toggle`}
									size="small"
									onClick={() =>
										setOpen(open === id ? false : id)
									}
								/>
							) : null}

							{color ? (
								<Prefix
									className="asteria-tag-prefix"
									style={{ backgroundColor: color }}
								/>
							) : null}
							{editable ? (
								<FormGroupV2 className="asteria-form">
									{level === 1 ? (
										<Dropdown
											options={Categories[type]}
											showSelected
											placeholder="Välj Kategori"
										/>
									) : (
										<InputV2
											className="asteria-input-wrapper"
											elementType="input"
											size="small"
											type="text"
											placeholder="Fyll i namnet"
											value={title[0]}
											onChange={() => {}}
										/>
									)}
								</FormGroupV2>
							) : (
								<Text className="asteria-tag-label">
									{TranslationService.get(title, title[0])}
								</Text>
							)}
							{level === 1 ? (
								<Button
									type="link"
									icon="plus"
									className="asteria-button-add-category-row"
									onClick={e => {
										e.stopPropagation();
										setChildren([
											...children,
											{ title: '', editable: true, type },
										]);
										setOpen(id);
									}}
								/>
							) : null}
						</Tag>
					</Cell>
					{header.map(({ key: hId, date }, index) => (
						<Cell
							className={classNames(
								'asteria-transactions-list-cell',
								{
									'asteria-transactions-list-cell-history':
										!isThisMonth(date) && isPast(date),
									'asteria-transactions-list-cell-today': isThisMonth(
										date,
									),
									'asteria-transactions-list-cell-future':
										!isThisMonth(date) && isFuture(date),
								},
							)}
							key={`value-${hId}`}
						>
							<FormGroupV2 className="asteria-form">
								<InputV2
									className="asteria-input-wrapper asteria-input-value"
									elementType="input"
									type="number"
									placeholder="Belopp"
									value={values[index] || 0}
									size="small"
								/>
							</FormGroupV2>
						</Cell>
					))}

					{/* 					{editable ? (
						<Button
							type="link"
							className="asteria-button asteria-button-remove-row"
							icon="circleMinusFilled"
							size="small"
							onClick={() => removeChild()}
						/>
					) : null} */}

					<Dropdown
						className="asteria-transaction-options"
						size="medium"
						type="link"
						icon="menu"
						options={[
							{ value: 'view', label: 'Visa kategori' },
							{ value: 'view', label: 'Visa kund' },
							{ value: 'view', label: 'Visa transaktioner' },
							{ value: 'duplicate', label: 'Gör kopia' },
							{ value: 'remove', label: 'Ta bort kategori' },
						]}
					/>
				</Row>
				{open === id
					? children.map(item => (
							<TransactionRow
								item={item}
								header={header}
								key={item.id}
								level={level + 1}
								editable={adaptable || item.editable}
								removeChild={() => {
									const index = children.indexOf(item);
									const newChildren = children.slice();
									newChildren.splice(index, 1);
									setChildren(newChildren);
								}}
							/>
					  ))
					: null}
				{open ? (
					<Button
						type="link"
						icon="circlePlusFilled"
						className={classNames(
							'asteria-button asteria-button-add-row',
							{ 'asteria-state-hover': hover },
						)}
						size="small"
						text="Lägg till rad"
						onClick={() => {
							setChildren([
								...children,
								{ title: '', editable: true, type },
							]);
							setOpen(id);
						}}
					/>
				) : null}
			</div>
		);
	},
)``;

TransactionRow.displayName = 'TransactionRow';

const generateDateIds = (first, length, size) =>
	new Array(length)
		.fill(1)
		.map((_, index) =>
			format(
				addTimeslice(first, size, index),
				'YYYY-MM-DD[T00:00:00.000Z]',
			),
		);

const getClients = (tag, groupIds, graphGroups, clientsData) => {
	const type = tag === '$supplier' ? 'WITHDRAW' : 'DEPOSIT';

	const uniqueClients = [
		...new Set(
			Object.values(graphGroups)
				.map(({ clients = [] }) =>
					clients.filter(
						({ status, type: clientType }) =>
							status === 'FORECAST' && type === clientType,
					),
				)
				.flat()
				.reduce((acc, { clientId }) => [...acc, clientId], []),
		),
	];

	const rows = uniqueClients
		.map(
			id =>
				clientsData.find(({ id: client }) => client === id) || {
					id,
					name: 'Ny kund',
				},
		)
		.map(({ id, name }) => ({
			id,
			type: 'client',
			title: [name],
			values: groupIds.map(groupId => {
				const { clients = [] } = graphGroups[groupId] || {};
				const { sums: { original: { total = 0 } = {} } = {} } =
					clients.find(
						({ status, clientId }) =>
							clientId === id && status === 'FORECAST',
					) || {};

				return total;
			}),
		}));

	return rows;
};

function Transactions({ headers, firstDate }) {
	const [graphGroups = {}] = useStore(
		'store-graph',
		({ 'cashflow-bar-graph': { barGroups = {} } = {} }) => barGroups,
	);

	const [clients = []] = useStore(
		'store-appstate',
		({ clients: list = [] }) => list,
	);

	const groupIds = useMemo(
		() => generateDateIds(firstDate, 12, 'month'),
		[firstDate],
		[],
	);

	const items = useMemo(() => {
		const types = [
			...new Set(
				Object.values(graphGroups)
					.map(({ bars = [] }) => bars)
					.flat()
					.reduce(
						(acc, { data: { type } = {} }) => [...acc, type],
						[],
					),
			),
		];

		const tags = [];

		for (let i = 0; i < types.length; i += 1) {
			tags.push(
				[
					...new Set(
						Object.values(graphGroups)
							.map(({ bars = [] }) =>
								bars
									.filter(
										({ data: { type } = {} }) =>
											type === types[i],
									)
									.map(({ parts = [] }) =>
										parts.map(
											({ parts: subParts = [] }) =>
												subParts,
										),
									),
							)
							.flat(3)
							.reduce(
								(
									acc,
									{ data: { tag: { name } = {} } = {} } = {},
								) => [...acc, name],
								[],
							),
					),
				].sort((a, b) => a.localeCompare(b)),
			);
		}

		const rows = types.map((type, index) => ({
			id: `${type}`,
			type: type.toLowerCase(),
			title: [`adjustable.advanced.${type}`],
			color: `var(--system-${type.toLowerCase()}-color)`,
			values: groupIds.map(id => {
				const { bars = [] } = graphGroups[id] || {};
				const { value = 0 } =
					bars.find(
						({
							types: groupTypes = [],
							data: { type: groupType } = {},
						}) =>
							type === groupType &&
							groupTypes.includes('forecast'),
					) || {};

				return value;
			}),
			items: tags[index].map(tag => ({
				id: `${type}-${tag}`,
				type: tag,
				title: [
					`adjustable.advanced.${tag.replace('$', '')}`,
					`adjustable.advanced.${type}.${tag.replace('$', '')}`,
				],
				color: `var(--system-${tag
					.replace('$', '')
					.toLowerCase()}-color)`,
				values: groupIds.map(id => {
					const { bars = [] } = graphGroups[id] || {};
					const { parts: barParts = [] } =
						bars.find(
							({
								types: groupTypes = [],
								data: { type: groupType } = {},
							}) =>
								type === groupType &&
								groupTypes.includes('forecast'),
						) || {};

					const { value = 0 } =
						barParts.reduce(
							(_, { parts: partCategories }) =>
								partCategories.find(
									({
										data: {
											tag: { name },
										},
									}) => name === tag,
								),
							false,
						) || {};

					return value;
				}),
				items: ['$supplier', '$customer'].includes(tag)
					? getClients(tag, groupIds, graphGroups, clients)
					: [],
				adaptable: true,
			})),
		}));

		return rows;

		// for (let i = 0; i < 12; i += 1) {}
	}, [graphGroups, groupIds, clients]);

	const cashflow = useMemo(
		() => headers.map(() => Math.round(Math.random() * 10000000)),
		[headers],
	);
	const account = useMemo(
		() => headers.map(() => Math.round(Math.random() * 10000000)),
		[headers],
	);

	return (
		<div className="asteria-transactions">
			{items.map(item => (
				/* 				<div
					className={classNames(
						'asteria-transactions-group',
						`asteria-transactions-group-${item.type}`,
					)}
					key={item.id}
				> */
				<TransactionRow item={item} key={item.id} header={headers} />
				/* </div> */
			))}
			<TransactionRow
				header={headers}
				item={{
					title: ['Kontobehållning'],
					type: 'accountbalance',
					values: account,
					id: 'accountbalance',
				}}
			/>
			<TransactionRow
				header={headers}
				item={{
					title: ['Kassaflöde'],
					type: 'cashflow',
					values: cashflow,
					id: 'cashflow',
				}}
			/>
		</div>
	);
}

const AdvancedAdjustable = styled(({ className }) => {
	const [firstDate, setFirstDate] = useState(startOfTime(new Date(), 'year'));

	const [showInfo, showInfoBox] = useState(false);

	const list = useMemo(() => {
		const header = new Array(12).fill(1).map((_, index) => ({
			key: index,
			date: addTimeslice(firstDate, 'month', index),
			title: format(addTimeslice(firstDate, 'month', index), 'MMMM'),
		}));
		return header;
	}, [firstDate]);
	const onScroll = useCallback(
		e => {
			const {
				shiftKey = false,
				wheelDeltaX = 0,
				wheelDelta = 0,
			} = e.nativeEvent;
			if ((shiftKey && wheelDelta !== 0) || wheelDeltaX) {
				const value = wheelDeltaX || wheelDelta;
				if (value > 50) {
					setFirstDate(addTimeslice(firstDate, 'month', -1));
				} else if (value < 50) {
					setFirstDate(addTimeslice(firstDate, 'month', 1));
				}
			}
		},
		[firstDate],
	);
	return (
		<div className={classNames(className, 'asteria-forecast')}>
			{/* <div className={classNames(className, 'asteria-forecast-modal')}> */}
			{/* 			<Modal
				title="Gör flera prognoser samtidigt"
				onClose={onClose}
				className={classNames(className, 'asteria-modal-forecast')}
			> */}
			{/* 				<div className="asteria-advanced-tools">
					<ButtonGroup className="asteria-button-group asteria-button-group-time">
						<Button
							type="link"
							icon="left"
							size="small"
							onClick={() => {
								setFirstDate(
									addTimeslice(firstDate, 'month', -3),
								);
							}}
						/>
						<Button
							type="link"
							icon="calendar"
							size="small"
							text="2020"
						/>
						<Button
							type="link"
							icon="right"
							size="small"
							onClick={() => {
								setFirstDate(
									addTimeslice(firstDate, 'month', 3),
								);
							}}
						/>
					</ButtonGroup>

					<Button
						type="link"
						icon="exportExcel"
						size="small"
						text="Exportera"
						className="asteria-button-export"
					/>
					<InfoBoxToggle
						className="asteria-button-info"
						open={showInfo}
						toggle={showInfoBox}
					/>
				</div> */}
			<InfoBoxToggle
				className="asteria-button-info"
				open={showInfo}
				toggle={showInfoBox}
			/>
			<InfoBox open={showInfo} className="asteria-info-box">
				<Text>
					Lorem ipsum dolor sit amet, id oblique pertinax mediocrem
					pro, imperdiet comprehensam has in.
				</Text>
			</InfoBox>
			{/* <ModalBody className="modal-body"> */}
			<div className="asteria-forecast" onWheel={onScroll}>
				<Header
					list={list}
					firstDate={firstDate}
					setFirstDate={setFirstDate}
				/>
				<Transactions headers={list} firstDate={firstDate} />
			</div>
			{/* 				</ModalBody>
			</Modal> */}
		</div>
	);
})`
	.asteria-modal {
		width: 100%;
		max-width: 1120px;
		.modal-header {
			.asteria-button {
				svg {
					fill: rgb(114, 96, 93) !important;
				}
			}
		}
		.modal-body {
			padding: 0;
			overflow-y: hidden;
		}
	}

	.asteria-forecast {
		/* 		display: grid;
		grid-template-areas:
			'header'
			'transactions';
		grid-template-rows: 40px 1fr; */
		height: 100%;
	}

	.asteria-header {
		/* 		display: grid;
		grid-area: header; */
	}

	.asteria-header-list {
		padding-top: 1px;
		padding-bottom: 0;
		border-bottom: 2px solid var(--filter-header-border-color);
		background-color: var(--filter-header-bg-color);
		height: 37px;
		min-height: auto;
		display: flex;
		flex-direction: row;
		/* 		display: grid;
		grid-template-columns: minmax(162px, 1fr) repeat(12, 1fr); */
		@media (max-width: 600px) {
			/* 			grid-template-columns: minmax(100%, 1fr);
			grid-template-rows: repeat(12, 1fr); */
		}
		${Text} {
			color: #72605f;
			font-weight: 700;
			font-family: 'Swedbank Headline';
			font-size: 10px;
			margin: 0px;
			padding: 0px 0 0px 0px;
			text-decoration: none;
			/* text-transform: uppercase; */
			text-align: center;
		}

		.asteria-header-list-cell {
			flex: 1;
			&:first-of-type {
				/* padding-left: 10px; */
				flex-basis: 158px;
				flex-shrink: 0;
				flex-grow: 0;
			}
			&:nth-of-type(2) {
				flex-grow: 0;
				flex-shrink: 0;
				flex-basis: 28px;
			}
			&:last-of-type {
				flex-grow: 0;
				flex-shrink: 0;
				flex-basis: 28px;
				margin-left: 4px;
				margin-right: 4px;
			}
			svg {
				fill: rgb(114, 96, 95) !important;
			}

			.asteria-button-left {
				margin-left: auto;
				height: 24px;
			}

			.asteria-button-right {
				margin-left: auto;
				height: 24px;
			}
			&.asteria-header-list-cell-history {
				opacity: 0.7;
			}
		}
	}

	.asteria-info-box div {
		margin: 0;
	}

	${Text} {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.asteria-advanced-tools {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		position: relative;
		background: #f0f0f0;
		border-bottom: 1px solid #d4c4bc;

		.asteria-button-export {
			margin-left: auto;
		}

		.asteria-button-info {
			width: 16px;
			margin-right: 16px;
		}
	}

	.asteria-button-group {
		display: flex;
		&.asteria-button-group-time {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.asteria-transactions {
		/* 		display: grid;
		grid-area: transactions; */
		/* height: calc(100% - 200px); */
		height: 100%;

		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	.asteria-transactions-group {
		/* 		margin: 1px 0; */
		display: flex;
		flex-direction: column;
	}

	.asteria-transactions-list-wrapper {
		display: flex;
		flex-direction: column;
		/* margin-bottom: 2px; */

		.asteria-transactions-list-wrapper-1,
		.asteria-transactions-list-wrapper-2 {
			&.asteria-transactions-list-wrapper-open {
				flex: 0;
				overflow: visible;
			}
		}
		.asteria-transactions-list-wrapper-1 {
			&.asteria-transactions-list-wrapper-open {
				.asteria-button-add-row {
					opacity: 1;
					position: relative;
					top: auto;
					transform: translateX(0%);
					margin: 0 0 0 18px;
					justify-content: flex-end;
					.asteria-text {
						display: block;
					}
				}
			}
			.asteria-button-add-row {
				display: none;
			}
		}

				&.asteria-transactions-list-wrapper-folded {
			/* 			flex: 0; */
		}

		&.asteria-transactions-list-wrapper-open {
			/* 			overflow: auto;
			height: auto;
			flex: 1; */

			/* background: #f9f8f6; */

			&::-webkit-scrollbar {
				background-color: #fff;
				width: 16px;
			}

			&::-webkit-scrollbar-track {
				background-color: #fff;
			}

			&::-webkit-scrollbar-thumb {
				background-color: #babac0;
				border-radius: 16px;
				border: 6px solid #fff;
			}
		}

		&:last-of-type {
			.asteria-button-add-row {
				display: flex;
			}
		}
	}

	.asteria-transactions-list {
		/* 		display: grid;
		grid-template-columns: minmax(170px, 1fr) repeat(12, 1fr); */
		padding-left: 0;
		position: relative;
		/* 		margin: 1px 0; */
		display: flex;
		flex-direction: row;
		/* padding-right: 35px; */

		@media (max-width: 600px) {
			/* 			grid-template-columns: minmax(100%, 1fr);
			grid-template-rows: repeat(12, 1fr); */
		}

		&.asteria-transactions-list-1 {
			/* 			grid-template-columns: minmax(142px, 1fr) repeat(12, 1fr); */
			/* padding-left: 28px; */
			/* padding-right: 35px; */
			.asteria-transactions-list-cell:first-of-type {
				/* flex-basis: 224px; */
				flex-basis: 400px;
				max-width: 186px;
				overflow: visible;
			}
			.asteria-transactions-list-cell:nth-of-type(2) {
				.asteria-input-wrapper {
					&:before {
						/* opacity: 1; */
					}
				}
			}
			.asteria-tag {
				margin: 0 0 0 28px;
				.asteria-tag-label {
					font-size: 12px;
				}
			}
			.asteria-forms-dropdown-menu-direction-center {
				min-width: 160px;
			}
			.asteria-forms-dropdown-toggle {
				height: auto;
				border-bottom: 0px solid transparent;
				p {
					font-size: 12px;
					color: #257886;
				}
			}
			.asteria-forms-dropdown-item {
				padding: 12px;
				.asteria-text {
					font-size: 12px;
				}
			}
			.asteria-transactions-list-cell.asteria-transactions-list-toggle {
				/* flex-basis: 246px; */
				flex-basis: 424px;
				margin-left: -11px;
			}
			@media (max-width: 600px) {
				/* 				grid-template-columns: minmax(100%, 1fr);
				grid-template-rows: repeat(12, 1fr); */
			}
		}
		&.asteria-transactions-list-2 {
			/* 			grid-template-columns: minmax(108px, 1fr) repeat(12, 1fr); */
			/* padding-left: 62px; */
			/* padding-left: 42px; */
			padding-right: 0;
			/* margin: 1px 0 3px; */
			.asteria-transactions-list-toggle {
/* 				margin-left: -10px; */
			}

			.asteria-transactions-list-cell:first-of-type {
				flex-basis: 400px;
			}

			@media (max-width: 600px) {
				/* 				grid-template-columns: minmax(100%, 1fr);
				grid-template-rows: repeat(12, 1fr); */
			}
			.asteria-tag {
				margin: 0 0 0 45px;
				.asteria-tag-label {
					font-size: 12px;
				}
			}
		}

		.asteria-transactions-list-toggle {
			cursor: pointer;
			/* flex-basis: 270px; */
			flex-basis: 400px;
		}

		&:last-of-type {
			.asteria-transactions-list-cell {
				.asteria-input-value {
					/* border-bottom-color: transparent; */
				}
			}
		}

		.asteria-button-remove-row {
			/* 			position: absolute;
			left: 22px;
			top: 50%;
			transform: translateY(-50%); */
			width: 20px;
			height: 20px;
			margin-left: 8px;
			margin-right: 8px;
			/* opacity: 0; */
			svg {
				fill: rgb(114, 96, 95) !important;
				&:hover {
					fill: #257886 !important;
				}
			}
		}

		&:hover {
			.asteria-button-remove-row {
				opacity: 1;
			}
		}

		.asteria-transactions-list-cell {
			&:nth-of-type(1) {
				.asteria-input-wrapper {
					.asteria-input {
						padding-left: 0;
						text-align: left !important;
						&::placeholder,
						&::-webkit-input-placeholder {
							font-size: 13px;
							color: #d0c3bd;
							text-align: left !important;
							padding-left: 0 !important;
						}
					}
				}
			}
			&:nth-of-type(2) {
				.asteria-input-wrapper {
					&:before {
						/* opacity: 1; */
					}
				}
			}
			&:last-of-type {
				.asteria-input-wrapper {
					&:after {
						display: none;
					}
				}
			}
		}

		&.asteria-transactions-list-cashflow {
			.asteria-transactions-list-cell {
				&:first-of-type {
					padding-left: 10px;
				}
				.asteria-input-wrapper {
					border-bottom-color: transparent;
					.asteria-input {
						font-weight: 700;
						font-family: Swedbank Headline;
					}
					:after {
						display: none;
					}
				}
			}
		}

		&.asteria-transactions-list-accountbalance {
			margin-top: 8px;
			/* border-top: 1px solid var(--filter-header-border-color); */
			.asteria-transactions-list-cell {
				&:first-of-type {
					padding-left: 10px;
				}
				.asteria-input-wrapper {
					border-bottom-color: transparent;
					.asteria-input {
						font-weight: 700;
						font-family: Swedbank Headline;
					}
					:after {
						display: none;
					}
				}
			}
		}

		.asteria-input-wrapper {
			padding: 0 0;
			background: #fff;
			border-bottom-width: 1px;
			border-bottom-color: #fff;
			.asteria-input {
				overflow: hidden;
				white-space: nowrap;
				text-align: center;
				text-overflow: ellipsis;
				&::placeholder,
				&::-webkit-input-placeholder {
					font-size: 13px;
					color: #d0c3bd;
					color: #fff;
					text-align: center;
				}
			}

			&.asteria-input-value {
				border-bottom-color: var(--transaction-list-border-color);
				position: relative;
				&:before {
					background: var(--transaction-list-border-color);
					content: '';
					width: 1px;
/* 					height: 100%; */
					height: 50%;
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					opacity: 0;
				}
				&:after {
					background: var(--transaction-list-border-color);
					content: '';
					width: 1px;
/* 					height: 100%; */
					height: 50%;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);/
				}

				.asteria-input {
					&::-webkit-outer-spin-button,
					&::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}
					-moz-appearance: textfield;

					&.asteria-text-placeholder {
						color: #d0c3bd;
					}
				}
			}

			&:hover {
				border-bottom-color: #d0c3bd;
				border-bottom-width: 2px;
				padding-top: 1px;
			}

			&.asteria-state-focus {
				border-bottom-color: rgba(83, 161, 172, 1);
				border-bottom-width: 2px;
				padding-top: 1px;
				color: #000;
			}
		}
	}

	.asteria-button {
		&.asteria-button-toggle {
			svg {
				fill: rgb(114, 96, 95) !important;
			}
		}
		&.asteria-button-expanded {
			svg {
				margin-top: 2px;
				height: 12px;
				width: 12px;
				transform: rotate(0deg);
				transition: transform 0.25s ease-in 0s;
			}
		}
		&.asteria-button-folded {
			svg {
				transform: rotate(90deg);
			}
		}
	}
	.asteria-button-add-row {
/* 		margin-left: calc(50% + 85px);
		margin-top: -11px;
		margin-bottom: -11px; */
		z-index: 1;
/* 		transform: translateX(-50%);
		opacity: 0; */
		/* 		width: 20px !important;
		height: 20px !important; */
		margin: 0 0 0 2px;
		justify-content: flex-end;
		width: auto !important;
		height: auto !important;
		.asteria-text {
/* 			display: none; */
			font-size: 12px;
		}
		&.asteria-state-hover,
		&:hover {
			opacity: 1;
		}
		.asteria-icon-wrapper {
			height: 12px;
			svg {
				fill: #257886 !important;
				&:hover {
					fill: #257886 !important;
				}
			}
		}
	}

	.asteria-tag {
		-webkit-box-align: center;
		align-items: center;
		margin-right: 8px;
		align-self: center;
		display: flex;
		.asteria-tag-prefix {
			height: 8px;
			width: 8px;
			min-width: 8px;
			margin-right: 8px;
			background-color: #999;
			background-image: var(--system-customer-image);
			border-radius: 50%;
			cursor: pointer;
		}
		.asteria-tag-label {
			color: #512b2b;
			font-family: 'Swedbank Sans';
			font-weight: 700;
			font-size: 14px;
			line-height: normal;
		}
	}
`;

export default AdvancedAdjustable;
