export default {
	list: {
		style: {
			overflowY: 'auto',
		},
		header: {
			style: {
				background: 'rgb(251, 242, 234)',
				borderBottom: '2px solid rgb(235, 231, 226)',
				padding: '10px 12px',
				fontFamily: 'Arial,Helvetica,sans-serif',
				fontSize: '14px',
				color: 'var(--text-color)',
			},
		},
		body: {
			style: {
				height: 'auto',
			},
		},
		list: {
			item: {
				style: {
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
				},
			},
		},
	},
};
