export default {
	erps: {
		style: {
			// maxHeight: '300px',
			// minHeight: '300px',
			overflowY: 'auto',
		},
		header: {
			style: {
				background: 'rgb(251, 242, 234)',
				borderBottom: '2px solid rgb(235, 231, 226)',
				padding: '10px 12px',
				fontFamily: 'Arial,Helvetica,sans-serif',
				fontSize: '14px',
				color: 'var(--text-color)',
			},
		},
		body: {
			style: {
				// flexGrow: '1',
				// flexShrink: '1',
				height: 'auto',
			},
			text: {
				styles: {
					// margin: '0  0 16px',
				},
			},
			title: {
				types: {
					title2: {
						style: {
							// fontSize: '20px',
						},
					},
				},
			},
		},
		list: {
			item: {
				style: {
					display: 'flex',
					// minHeight: '56px',
					justifyContent: 'center',
					flexDirection: 'column',
				},
				details: {
					style: {
						alignItems: 'center',
						padding: '9px 0 9px 12px',
						borderBottom: '1px solid rgb(235, 231, 225)',
					},
					status: {
						style: {
							width: '10px',
							height: '10px',
							borderRadius: '100%',
							marginRight: '8px',
						},
					},
					info: {
						name: {
							style: {
								fontSize: '16px',
								fontFamily: 'Swedbank Sans',
								fontWeight: 700,
								color: 'var(--text-color)',
							},
						},
						lastsync: {
							style: {
								color: 'var(--muted-color)',
								fontFamily: 'Arial,Helvetica,sans-serif',
								fontSize: '12px',
							},
						},
					},
					button: {
						icon: {
							svg: {
								style: {
									fill: 'var(--primary-color)',
								},
							},
						},
					},
				},
				alert: {
					styles: {
						marginBottom: '200px',
					},
				},
				types: {
					connected: {
						details: {
							status: {
								style: {
									backgroundColor: '#A4CA73',
								},
							},
						},
					},
					disconnected: {
						details: {
							status: {
								style: {
									backgroundColor: '#BF2828',
								},
							},
							info: {
								name: {
									style: {},
								},
								lastsync: {
									style: {},
								},
							},
						},
					},
					error: {
						details: {
							status: {
								style: {
									backgroundColor: '#CABA72',
								},
							},
							info: {
								name: {
									style: {},
								},
								lastsync: {
									style: {},
								},
							},
						},
					},
				},
				dismissdialog: {
					style: {
						backgroundColor: '#f9f8f6',
						borderTop: '2px solid rgba(238, 112, 35, 1)',
						borderBottom: '2px solid rgba(238, 112, 35, 1)',
						padding: '16px',
					},
					text: {
						types: {
							textInfo: {
								style: {
									color: 'var(--dialog-text-color)',
									fontFamily: 'Arial,Helvetica,sans-serif',
									fontSize: '14px',
									whiteSpace: 'pre-wrap',
								},
							},
						},
					},
					button: {
						types: {
							confirm: {
								style: {
									marginLeft: '16px',
								},
							},
						},
					},
				},
			},
		},
		button: {
			types: {
				addErp: {
					style: {
						marginTop: '32px',
						paddingLeft: '0',
					},
					text: {
						style: {
							fontSize: '16px',
							textDecoration: 'none',
							fontFamily: 'Swedbank Headline',
							fontWeight: '400',
						},
					},
				},
			},
		},
	},
};
