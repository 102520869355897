import React, { useContext } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useHistory, useStore } from '@asteria/utils/hooks';
import { Dropdown } from '@asteria/component-form';
import {
	FeatureFlag,
	useFeature,
	Service as FeatureService,
} from '@asteria/component-featureflag';
import { TranslationService } from '@asteria/services-language';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { logout } from '@asteria/services-datalayer/services/auth/actions';

const AppMenu = styled(({ className, basePath = '', exclude = [] }) => {
	const { dispatch } = useContext(DatalayerContext);
	const history = useHistory();
	const hasFeatureSenarios = useFeature({ key: 'senarios' });

	const [accounts = []] = useStore(
		'store-bankaccounts',
		({ bankAccounts = [] }) => bankAccounts,
	);

	const options = [
		{
			label: TranslationService.get('app.menu.account'),
			value: `${basePath}/account`,
			feature: 'user-account',
		},
		{
			label: TranslationService.get('app.menu.graphsettings'),
			value: `${basePath}/settings`,
		},
		{
			label: TranslationService.get('app.menu.integrations'),
			value: `${basePath}/integrations`,
		},
		{
			label: TranslationService.get('app.menu.statement', 'Årsrapport'),
			value: `${basePath}/statement`,
			feature: 'statement',
		},
		{
			label: TranslationService.get('app.menu.accounts'),
			value:
				accounts && accounts.length > 0 ? `${basePath}/accounts` : '',
		},
		// {
		// 	label: TranslationService.get('app.menu.currencyoverview', 'Valutaöversikt'),
		// 	value: `${basePath}/currencyoverview`,
		// 	feature: 'fx-module',
		// },
		{
			label: TranslationService.get('app.menu.currency'),
			value: `${basePath}/currencies`,
			feature: 'currency-managment',
		},
		{
			label: TranslationService.get('app.menu.guide'),
			value: `${basePath}/guide`,
		},
		{
			label: TranslationService.get('app.menu.support'),
			value: `${basePath}/support`,
		},
		{
			label: TranslationService.get('app.menu.logout'),
			value: '/logout',
			action: () => {
				dispatch(logout());
			},
		},
	]
		.filter(({ value }) => value && !exclude.includes(value))
		.filter(({ feature }) => !feature || FeatureService.isActive(feature));

	if (hasFeatureSenarios) {
		options.push({
			label: TranslationService.get('app.menu.senarios'),
			value: `${basePath}/senarios`,
		});
	}

	const hasBackdropForDropdown = useFeature({
		key: 'has-backdrop-for-this-dropdown',
	});

	return (
		<>
			<FeatureFlag feature="user-menu-icon" invert>
				<Dropdown
					className={classNames(className, 'asteria-button-settings')}
					size="medium"
					type="link"
					iconClosed="menudots"
					iconOpen="circleClose"
					backdrop={hasBackdropForDropdown}
					data-tooltip={TranslationService.get(
						'settings.navigation.title',
						'Inställningar',
					)}
					direction={['down', 'left']}
					onChange={({ value, action }) => {
						history.push(value);

						if (action) {
							action(dispatch);
						}
					}}
					options={options}
				/>
			</FeatureFlag>
			<FeatureFlag feature="user-menu-icon">
				<Dropdown
					className={classNames(className, 'asteria-button-settings')}
					size="medium"
					type="link"
					iconClosed="usernav"
					data-tooltip={TranslationService.get(
						'settings.navigation.title',
						'Inställningar',
					)}
					backdrop={hasBackdropForDropdown}
					iconOpen="usernav"
					direction={['down', 'left']}
					onChange={({ value, action }) => {
						history.push(value);

						if (action) {
							action(dispatch);
						}
					}}
					options={options}
				/>
			</FeatureFlag>
		</>
	);
})``;

AppMenu.displayName = 'AppMenu';
AppMenu.Styler = Dropdown.Styler;

export default AppMenu;
