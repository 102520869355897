import difference from 'lodash/fp/difference';
import SVG from './svg';
import DefaultAssets from './assets/default';

class AssetsService {
	getUrl(asset, { assets = {} } = {}) {
		const value = assets[asset] || DefaultAssets[asset] || '';

		return { __html: value };
	}

	getMarkupData(asset, { assets = {} } = {}) {
		const assetLookup = Array.isArray(asset) ? asset : [asset];
		const assetKey = assetLookup.reduce(
			(acc, ass) => (assets[ass] || DefaultAssets[ass] ? ass : acc),
			false,
		);

		const assetData = assetKey
			? assets[assetKey] || DefaultAssets[assetKey]
			: '';

		const source = new SVG(assetData);

		return {
			__html: source.getBody(),
			props: source.getHeader(),
			classes: [assetKey],
		};
	}

	getDefaultAssets() {
		return Object.keys(DefaultAssets);
	}

	getCustomAssets({ assets = {} } = {}) {
		return difference(Object.keys(assets), Object.keys(DefaultAssets));
	}

	get() {
		return '';
	}
}

export default AssetsService;
