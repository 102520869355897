import React from 'react';
import { findDOMNode } from 'react-dom';
import styled, { withTheme } from 'styled-components';
import classNames from 'classnames';
import { Getter, compileStyles, preProcess } from '@asteria/utils';
// import { Service as FeatureService } from '@asteria/component-featureflag';
import Text from '@asteria/component-typography/text';
import Title from '@asteria/component-typography/title';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { TranslationService } from '@asteria/services-language';
import {
	setGraphGroupSize,
	requestNewRange,
	setRange,
	setActiveGroups,
	setActiveBars,
	setHoverBars,
	setHoverGroups,
} from '@asteria/services-datalayer/services/graph/actions';
import { setListDates } from '@asteria/services-datalayer/services/list/actions';
import {
	setListOpen,
	setTimesliceSize,
	setCurrentDate,
	setAdjustOpen,
	setSelectedDate,
	setSelectedType,
} from '@asteria/services-datalayer/services/appstate/actions';
import {
	addMonths,
	format,
	isThisMonth,
	isFuture,
	addWeeks,
	addYears,
	startOfMonth,
	startOfISOWeek,
	isThisISOWeek,
	isThisYear,
	addDays,
	startOfYear,
	endOfISOWeek,
	endOfMonth,
	endOfYear,
} from 'date-fns';
import { filter } from 'rxjs/operators';

import GraphLayout from './layout';

const TimeSizeOrder = ['day', 'week', 'month', 'year'];

const addTimeslice = (date, size, count = 1) => {
	if (size === 'day') {
		return addDays(date, count);
	}
	if (size === 'week') {
		return addWeeks(date, count);
	}
	if (size === 'month') {
		return addMonths(date, count);
	}
	if (size === 'year') {
		return addYears(date, count);
	}

	return date;
};

const endOfTime = (date, size) => {
	if (size === 'week') {
		return endOfISOWeek(date);
	}

	if (size === 'month') {
		return endOfMonth(date);
	}

	if (size === 'year') {
		return endOfYear(date);
	}

	return endOfMonth(date);
};

const startOfTime = (date, size, prevSize) => {
	const modDate =
		TimeSizeOrder.indexOf(size) > TimeSizeOrder.indexOf(prevSize)
			? endOfTime(date, prevSize)
			: date;

	if (size === 'week') {
		return startOfISOWeek(modDate);
	}

	if (size === 'month') {
		return startOfMonth(modDate);
	}

	if (size === 'year') {
		return startOfYear(modDate);
	}

	return startOfMonth(modDate);
};

const getKeyPermutations = (key = '', delimiter = '.', initial = []) =>
	key
		.toString()
		.split('.')
		.reduce(
			(acc, item) => [
				...acc,
				acc.length > 0
					? [acc[acc.length - 1], item.toLowerCase()].join(delimiter)
					: item,
			],
			initial,
		);

const mergeCssVars = (vars, sufix) => {
	const [color, ...rest] = vars;
	if (rest.length !== 0) {
		return `var(--${color}-${sufix}, ${mergeCssVars(rest, sufix)})`;
	}

	if (color.startsWith('system-')) {
		return `var(--${color}-${sufix})`;
	}

	return color;
};

const processColor = (color = '', types = [], theme, sufix = 'color') => {
	let fallback = 'rgba(0, 0, 0, 0)';
	if (types.includes('unpaid') || color.includes('.unpaid')) {
		fallback = `system-unpaid`;
	}

	if (types.includes('signed') || color.includes('.signed')) {
		fallback = `system-signed`;
	}

	if (types.includes('forecast') || color.includes('.forecast')) {
		fallback = `system-forecast`;
	}

	if (types.includes('overdue') || color.includes('.overdue')) {
		fallback = `system-overdue`;
	}

	if (color.startsWith('$')) {
		const colors = mergeCssVars(
			[
				...getKeyPermutations(color.replace('$', ''), '-', [
					'system',
				]).reverse(),
				fallback,
			],
			sufix,
		);

		return preProcess(colors, theme);
	}

	return color;
};

const CashflowTooltipPrefix = styled.span`
	background-color: ${({ color, theme }) =>
		processColor(color, [], theme, 'color')};
	background-image: ${({ color, theme }) =>
		processColor(color, [], theme, 'image')};
`;
CashflowTooltipPrefix.displayName = 'CashflowTooltipPrefix';
CashflowTooltipPrefix.Styler = {
	typePrefix: 'asteria-cashflow-tooltip-content-row-prefix',
};

const CashflowTooltipContentSectionTitle = styled(Title)``;
CashflowTooltipContentSectionTitle.displayName =
	'CashflowTooltipContentSectionTitle';

const CashflowTooltipRow = styled.li`
	display: flex;
	align-items: center;
`;
CashflowTooltipRow.displayName = 'CashflowTooltipRow';

CashflowTooltipRow.Styler = {
	children: [
		{
			component: CashflowTooltipPrefix,
			base: [Getter('prefix')],
		},
		{
			component: Text,
			base: [Getter('text')],
		},
	],
};
/*
const CashFlowTagGroup = styled.div`
	display: flex;
	flex-direction: column;
`;
*/

const CashflowTooltipContentSection = styled.li`
	width: 100%;
	list-style: none;
	margin: 0px;
	padding: 0px;

	.asteria-cashflow-tooltip-section-inner {
		list-style: none;
		margin: 0px;
		padding: 0px;

		display: flex;
		flex-direction: column;
	}

	.asteria-cashflow-tooltip-section-inner-currencies {
		display: flex;
		flex-direction: row;
		> .asteria-cashflow-tooltip-row-currencies-risk {
			.asteria-cashflow-tooltip-label {
				flex-grow: 0 !important;
				min-width: auto !important;
			}
		}
	}

	${({ theme = {} }) => {
		const style = compileStyles(
			CashflowTooltipContentSection.Styler,
			theme,
		);
		return style;
	}}
`;
CashflowTooltipContentSection.displayName = 'CashflowTooltipContentSection';
CashflowTooltipContentSection.Styler = {
	typePrefix: 'asteria-cashflow-tooltip-content-section',
	base: [Getter('tooltip.cashflow')],
	children: [
		{
			component: CashflowTooltipRow,
			base: [Getter('row')],
		},
	],
};

const CashflowTooltipContent = styled.ul`
	list-style: none;
	margin: 0px;
	padding: 0px;

	.asteria-cashflow-tooltip-row-paid,
	.asteria-cashflow-tooltip-section-paid {
		order: 5;
	}

	.asteria-cashflow-tooltip-row-unpaid,
	.asteria-cashflow-tooltip-section-unpaid {
		order: 4;
	}

	.asteria-cashflow-tooltip-row-signed,
	.asteria-cashflow-tooltip-section-signed {
		order: 3;
	}

	.asteria-cashflow-tooltip-row-forecast,
	.asteria-cashflow-tooltip-section-forecast {
		order: 2;
	}

	.asteria-cashflow-tooltip-row-overdue,
	.asteria-cashflow-tooltip-section-overdue {
		order: 1;
	}

	.asteria-cashflow-tooltip-row-total,
	.asteria-cashflow-tooltip-section-total {
		order: 99;
	}

	${({ theme = {} }) => {
		const style = compileStyles(CashflowTooltipContent.Styler, theme);
		return style;
	}}
`;
CashflowTooltipContent.displayName = 'CashflowTooltipContent';

CashflowTooltipContent.Styler = {
	typePrefix: 'asteria-cashflow-tooltip-content',
	base: [Getter('tooltip.cashflow')],
	children: [
		{
			component: CashflowTooltipRow,
			base: [Getter('row')],
		},
	],
};

// eslint-disable-next-line react/no-find-dom-node
const getElement = el => (React.isValidElement(el) ? el : findDOMNode(el));

function getSize(el) {
	if (!el) {
		return {
			width: 0,
			height: 0,
		};
	}

	return {
		width: el.offsetWidth,
		height: el.offsetHeight,
	};
}

/*
const getBarToolTipRows = (bar = {}) => {
	const { parts = [] } = bar;

	const partsContent = parts.map(part => {
		const {
			chip: {
				config: { name, status, subtags, currency },
			},
			data: { max, min },
		} = part;

		return (
			<TagTooltipGroup
				type="graph"
				prefix
				tag={name}
				status={status}
				data={{
					value: part.value,
					count: part.count,
					max,
					min,
					subtags,
					currency,
				}}
			/>
		);
	});

	return partsContent;
};
*/
let timeout = null;

/*
<CashflowTooltipContentSection key="cashflow-tooltip-account-section">
	<CashflowTooltipContentSectionTitle key="cashflow-tooltip-row-title">
		{sectionTitle}
	</CashflowTooltipContentSectionTitle>
	{rows}
</CashflowTooltipContentSection>
rows.push(
	<CashflowTooltipRow
		className="asteria-cashflow-tooltip-row-account asteria-cashflow-tooltip-row-forecast"
		key="cashflow-tooltip-row-account-forecast"
	>
		<CashflowTooltipPrefix />
		<Text
			className="asteria-cashflow-tooltip-label"
			size="label"
		>
			{typeForecastLabel}
		</Text>
		<Text
			className="asteria-cashflow-tooltip-total"
			size="body"
		>
			{typeForecastTotal}
		</Text>
	</CashflowTooltipRow>,
);

rows.push(
	<CashflowTooltipRow
		className="asteria-cashflow-tooltip-row-account asteria-cashflow-tooltip-row-forecast-subtext"
		key="cashflow-tooltip-row-account-subtext"
	>
		<Text
			className="asteria-cashflow-tooltip-subtext"
			size="body"
		>
			{typeForecastSubtext}
		</Text>
	</CashflowTooltipRow>,
);
*/
let processInfo = () => {};

const buildTooltipRow = (
	{ id, data: { type, label, value, variants = [] } = {}, data = {} },
	context = {},
	as = 'li',
) => (
	<CashflowTooltipRow
		as={as}
		className={classNames(
			`asteria-cashflow-tooltip-row-${type}`,
			getKeyPermutations(id, '-', ['asteria-cashflow-tooltip-row']),
			variants.map(
				variant =>
					`asteria-cashflow-tooltip-row-${variant.toLowerCase()}`,
			),
			{
				'asteria-state-negative': value?.original?.total < 0,
			},
		)}
		key={`cashflow-tooltip-row-${id}`}
	>
		{type === 'currency' && (
			<div
				className={`currency-flag currency-flag-${value?.original?.currency?.toLowerCase()}`}
			/>
		)}
		{type === 'tag' && (
			<CashflowTooltipPrefix color={`$${id.toLowerCase()}`} />
		)}
		<Text className="asteria-cashflow-tooltip-label" size="label">
			{TranslationService.get(
				[
					...getKeyPermutations(id, '.', ['cashflow.tooltip']).map(
						key => `${key}.label`,
					),
				],
				label,
				{ ...data, ...context },
			)}
		</Text>
		{type !== 'text' && type !== 'subtext' && (
			<Text
				className={classNames('asteria-cashflow-tooltip-total')}
				size="body"
			>
				{TranslationService.get(
					[
						value,
						...getKeyPermutations(id, '.', [
							'cashflow.tooltip',
						]).map(key => `${key}.value`),
					],
					value,
					{ ...data, ...context },
				)}
			</Text>
		)}
	</CashflowTooltipRow>
);

const buildTooltipGroup = (
	{
		id,
		data: { type, limit = false, items = [], variants = [] } = {},
		data = {},
	},
	context = {},
) => (
	<CashflowTooltipContentSection
		key={`cashflow-tooltip-section-${id}`}
		className={classNames(
			getKeyPermutations(id, '-', ['asteria-cashflow-tooltip-section']),
			variants.map(
				variant =>
					`asteria-cashflow-tooltip-section-${variant.toLowerCase()}`,
			),
		)}
	>
		{id &&
			TranslationService.get(
				[
					id,
					...getKeyPermutations(id, '.', ['cashflow.tooltip']).map(
						key => `${key}.title`,
					),
				],
				'',
			) && (
				<CashflowTooltipContentSectionTitle key="cashflow-tooltip-row-title">
					{TranslationService.get(
						[
							id,
							...getKeyPermutations(id, '.', [
								'cashflow.tooltip',
							]).map(key => `${key}.title`),
						],
						'',
					)}
				</CashflowTooltipContentSectionTitle>
			)}
		{type && buildTooltipRow({ id, data }, context, 'div')}
		<ul
			className={classNames(
				getKeyPermutations(id, '-', [
					'asteria-cashflow-tooltip-section-inner',
				]),
			)}
		>
			{(limit ? items.slice(0, limit) : items).map(item =>
				processInfo(item, context),
			)}
			{items.length - (limit ? items.slice(0, limit) : items).length >
				0 && (
				<li className="asteria-cashflow-tooltip-section-inner-rest">
					{TranslationService.get(
						[
							...getKeyPermutations(id, '.', [
								'cashflow.tooltip',
							]).map(key => `${key}.rest`),
						],
						'+{{rest}}',
						{
							count: items.length,
							limit,
							rest:
								items.length -
								(limit ? items.slice(0, limit) : items).length,
						},
					)}
				</li>
			)}
		</ul>
	</CashflowTooltipContentSection>
);

processInfo = (item, context = {}) => {
	if (item.type === 'tooltip.group') {
		return buildTooltipGroup(item, context);
	}

	if (item.type === 'tooltip.row') {
		return buildTooltipRow(item, context);
	}

	return null;
};

const mergeGroups = list => {
	const result = list
		.filter(({ type }) => type === 'tooltip.group')
		.reduce((acc, item) => {
			const {
				id,
				data: { items },
			} = item;
			if (!acc[id]) {
				acc[id] = {
					...item,
					data: {
						...item.data,
						items: [],
					},
				};
			}

			acc[id].data.items.push(...items);

			return acc;
		}, {});

	return Object.values(result);
};

class GraphData extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentDate: addTimeslice(new Date(), 'month', -4),
			currentTimesliceSize: 'month',
			isListOpen: false,
			userSettings: {},
			size: { width: 0, height: 0 },
			bankAccounts: [],
		};

		this.subscriptions = [];
		this.ref = React.createRef();
		this.requestedGroups = 1;
		this.groupWidths = [];
		this.groupWidth = 0;

		this.getToolTip = this.getToolTip.bind(this);
		this.setDate = this.setDate.bind(this);
		this.setSize = this.setSize.bind(this);
		this.setTimesliceSize = this.setTimesliceSize.bind(this);
		this.updateRange = this.updateRange.bind(this);
		this.handleResize = this.handleResize.bind(this);
		this.next = this.next.bind(this);
		this.prev = this.prev.bind(this);
		this.updateSize = this.updateSize.bind(this);
		this.clickAction = this.clickAction.bind(this);
		this.mouseEnterAction = this.mouseEnterAction.bind(this);
		this.mouseLeaveAction = this.mouseLeaveAction.bind(this);
	}

	componentWillMount() {
		this.init();
	}

	componentDidMount() {
		this.handleResize();

		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		this.subscriptions.map(it => it.unsubscribe());
	}

	getToolTip({ bar, group, line, badge }) {
		const { bankAccounts = [] } = this.state;
		const { theme } = this.props;
		const content = [];
		let title = '';

		if (badge) {
			const { data: { type = 'unknown' } = {} } = bar;
			const { value: total } = badge;
			const toolTipKey = `graph.bargraph.bar.${type.toLowerCase()}`;

			const badgeType = badge.types.includes('overdue')
				? 'overdue'
				: 'unpaid';

			title = TranslationService.get(
				[
					`${toolTipKey}.tooltip.title`,
					`${toolTipKey}.badge.tooltip.title`,
					`${toolTipKey}.${badgeType}.tooltip.title`,
					`${toolTipKey}.badge.${badgeType}.tooltip.title`,
				],
				`${toolTipKey}.tooltip.title`,
				{
					bar,
					group,
					total,
					badge,
				},
			);

			const titleSubText = TranslationService.get(
				[
					`${toolTipKey}.tooltip.title.subtext`,
					`${toolTipKey}.badge.tooltip.title.subtext`,
					`${toolTipKey}.${badgeType}.tooltip.title.subtext`,
					`${toolTipKey}.badge.${badgeType}.tooltip.title.subtext`,
				],
				'',
				{
					bar,
					group,
					total,
					badge,
				},
			);

			if (titleSubText) {
				content.push(
					<CashflowTooltipRow
						className="asteria-cashflow-tooltip-row-title asteria-cashflow-tooltip-row-subtext"
						key="cashflow-tooltip-row-badge-title-subtext"
					>
						<Text
							className="asteria-cashflow-tooltip-subtext"
							size="body"
						>
							{titleSubText}
						</Text>
					</CashflowTooltipRow>,
				);
			}

			const typeLabel = TranslationService.get(
				`${toolTipKey}.tooltip.label`,
				`${toolTipKey}.tooltip.label`,
				{
					bar,
					group,
					total,
					badge,
				},
			);

			const typeTotal = TranslationService.get(
				`${toolTipKey}.tooltip.total`,
				`${toolTipKey}.tooltip.total`,
				{
					bar,
					group,
					total,
					badge,
				},
			);

			const typeSubtext = TranslationService.get(
				`${toolTipKey}.badge.${badgeType}.tooltip.subtext`,
				``,
				{
					bar,
					group,
					badge,
					total,
				},
			);

			const badgeLabel = TranslationService.get(
				`${toolTipKey}.badge.${badgeType}.tooltip.label`,
				`${toolTipKey}.badge.${badgeType}.tooltip.label`,
				{
					bar,
					group,
					badge,
					total,
				},
			);

			const badgeTotal = TranslationService.get(
				`${toolTipKey}.badge.${badgeType}.tooltip.total`,
				`${toolTipKey}.badge.${badgeType}.tooltip.total`,
				{
					bar,
					group,
					badge,
					total,
				},
			);

			content.push(
				<CashflowTooltipRow
					key="cashflow-tooltip-row-badge-overdue"
					className={classNames('asteria-cashflow-tooltip-row-part')}
				>
					<CashflowTooltipPrefix
						color={processColor(`$${badgeType}`, [], theme)}
					/>
					<Text
						className="asteria-cashflow-tooltip-label"
						size="label"
					>
						{badgeLabel}
					</Text>
					<Text
						className="asteria-cashflow-tooltip-total"
						size="body"
					>
						{badgeTotal}
					</Text>
				</CashflowTooltipRow>,
			);

			if (typeSubtext) {
				content.push(
					<CashflowTooltipRow
						className="asteria-cashflow-tooltip-row-subtext"
						key="cashflow-tooltip-row-badge-subtext"
					>
						<Text
							className="asteria-cashflow-tooltip-subtext"
							size="body"
						>
							{typeSubtext}
						</Text>
					</CashflowTooltipRow>,
				);
			}

			content.push(
				<CashflowTooltipRow
					className="asteria-cashflow-tooltip-row-total"
					key="cashflow-tooltip-row-badge-total"
				>
					<Text
						className="asteria-cashflow-tooltip-label"
						size="label"
					>
						{typeLabel}
					</Text>
					<Text
						className="asteria-cashflow-tooltip-total"
						size="body"
					>
						{typeTotal}
					</Text>
				</CashflowTooltipRow>,
			);
		}

		const { lines = [] } = group;
		if (line && lines.some(l => l?.info)) {
			const info = lines.map(l => l?.info || []).flat();
			content.push(
				...info
					?.filter(({ type }) => type === 'tooltip.group')
					?.map(item => processInfo(item, { bankAccounts }) || []),
			);
		} else if (group) {
			content.push(
				...(mergeGroups(
					(group?.bars || [])
						.filter(
							({ data: { type } = {} }) =>
								bar && type === bar.data.type,
						)
						.map(({ parts }) => parts || [])
						.flat()
						.map(({ info }) => info || [])
						.flat(1)
						.filter(({ type }) => type === 'tooltip.group'),
				).map(item => processInfo(item, { bankAccounts }) || []) || []),
			);
		}

		return {
			title,
			content: <CashflowTooltipContent>{content}</CashflowTooltipContent>,
		};
	}

	setDate(date) {
		/*
		this.setState({
			currentDate: date,
		});
		*/
		const { dispatch } = this.context;
		dispatch(setCurrentDate(date));
	}

	setSize(size) {
		this.setState({ size });
	}

	setTimesliceSize(timeslice) {
		const { dispatch } = this.context;
		this.setState({
			currentTimesliceSize: timeslice,
		});
		dispatch(setTimesliceSize(timeslice));
	}

	handleResize() {
		if (this.ref.current) {
			this.setSize(getSize(getElement(this.ref.current)));
		}
	}

	init() {
		const { actions, lookup, dispatch } = this.context;
		this.subscriptions.push(
			actions
				.pipe(
					filter(
						({ action }) => action === 'GRAPH_REQUEST_NEW_RANGE',
					),
				)
				.subscribe(({ payload: size, timesliceSize }) => {
					const { currentDate } = this.state;
					this.updateRange(currentDate, size, timesliceSize);
				}),
		);

		this.subscriptions.push(
			lookup('store-graph')
				.query(({ 'cashflow-bar-graph': state = {} }) => state)
				.subscribe(data => {
					this.setState({ graph: data });
				}),
		);

		this.subscriptions.push(
			lookup('store-appstate')
				.query(({ listOpen = false }) => listOpen)
				.subscribe(isListOpen => {
					this.setState({ isListOpen });
				}),
		);

		this.subscriptions.push(
			lookup('store-appstate')
				.query(({ currentDate: date }) => date)
				.subscribe(date => {
					const { currentDate, graph } = this.state;
					if (date !== currentDate && graph) {
						this.setState({ currentDate: date });
						this.updateRange(date, this.requestedGroups);
					}
				}),
		);

		this.subscriptions.push(
			lookup('store-auth')
				.query(({ user: { settings } = {} }) => settings)
				.subscribe(settings => {
					this.setState({ userSettings: settings });

					if (timeout) {
						clearTimeout(timeout);
						timeout = null;
					}

					timeout = setTimeout(() => {
						dispatch(setGraphGroupSize('cashflow-bar-graph', 100));
						this.groupWidths = [];
						this.groupWidth = 0;
						timeout = null;
					}, 50);
				}),
		);

		this.subscriptions.push(
			lookup('store-bankaccounts')
				.query(({ bankAccounts = [] }) => bankAccounts || [])
				.subscribe(bankAccounts => {
					this.setState({
						bankAccounts: bankAccounts.filter(
							({ active }) => active,
						),
					});
				}),
		);
	}

	updateRange(requestedDate, size, newTimeslice) {
		const {
			currentTimesliceSize,
			isListOpen,
			graph: {
				range: dataRange = [],
				activeGroups = [],
				activeBars = [],
			},
		} = this.state;
		const { dispatch } = this.context;
		let date = requestedDate;
		const newRange = [];
		const requestedSize = size || dataRange.length;
		const timeslice = newTimeslice || currentTimesliceSize;

		let prevDate = date;
		if (timeslice === 'month') {
			date = startOfMonth(date);
		} else if (timeslice === 'week') {
			date = startOfISOWeek(date);
			prevDate = addWeeks(date, -1);
		} else if (timeslice === 'year') {
			date = startOfYear(date);
		}

		for (let i = 0; i < requestedSize; i += 1) {
			let key = 'graph.xaxis';

			if (
				(timeslice === 'year' && isThisYear(date)) ||
				(timeslice === 'month' && isThisMonth(date)) ||
				(timeslice === 'week' && isThisISOWeek(date))
			) {
				key = `${key}.today`;
			} else if (isFuture(date)) {
				key = `${key}.future`;
			} else {
				key = `${key}.history`;
			}

			let prefixKey = key;

			if (timeslice === 'month' && date.getMonth() === 0) {
				prefixKey = `${prefixKey}.year`;
			}

			if (
				timeslice === 'week' &&
				date.getMonth() !== prevDate.getMonth()
			) {
				prefixKey = `${prefixKey}.month`;
			}

			const prefix = TranslationService.get(`${prefixKey}.prefix`, '', {
				date,
			});
			const label = TranslationService.get(
				`graph.xaxis.history.${timeslice}.label`,
				'',
				{ date },
			);
			let type = 'history';
			if (
				(timeslice === 'year' && isThisYear(date)) ||
				(timeslice === 'month' && isThisMonth(date)) ||
				(timeslice === 'week' && isThisISOWeek(date))
			) {
				type = 'today';
			} else if (isFuture(date)) {
				type = 'forecast';
			}

			newRange.push({
				id: format(date, 'YYYY-MM-DD[T00:00:00.000Z]', {
					locale: TranslationService.getLocale(),
				}),
				types: [type],
				prefix,
				label,
			});

			if (timeslice === 'week') {
				prevDate = date;
				date = addWeeks(date, 1);
			} else if (timeslice === 'month') {
				date = addMonths(date, 1);
			} else if (timeslice === 'year') {
				date = addYears(date, 1);
			}
		}

		if (timeslice !== currentTimesliceSize) {
			if (isListOpen) {
				if (activeGroups.length > 0) {
					const [groupId] = activeGroups;
					const [bar] = activeBars;
					const selectedDate = startOfTime(
						new Date(groupId),
						timeslice,
						currentTimesliceSize,
					);

					dispatch(setSelectedDate(selectedDate));
					dispatch(setCurrentDate(selectedDate));

					dispatch(
						setCurrentDate(
							addTimeslice(selectedDate, timeslice, -4),
						),
					);

					dispatch(
						setSelectedType(bar ? [bar] : ['DEPOSIT', 'WITHDRAW']),
					);

					dispatch(
						setActiveGroups('cashflow-bar-graph', [
							format(selectedDate, 'YYYY-MM-DD[T00:00:00.000Z]'),
						]),
					);
				}
			} else {
				dispatch(
					setListDates(
						startOfTime(new Date(), timeslice),
						addTimeslice(
							startOfTime(new Date(), timeslice),
							timeslice,
							1,
						),
						['DEPOSIT', 'WITHDRAW'],
						null,
						timeslice,
					),
				);
			}
		}

		const newIds = newRange.map(({ id }) => id);
		const oldIds = dataRange.map(({ id }) => id);

		if (
			newIds.length !== oldIds.length ||
			!newIds.every(id => oldIds.indexOf(id) !== -1)
		) {
			if (currentTimesliceSize !== timeslice) {
				this.setTimesliceSize(timeslice);
			}

			dispatch(setRange('cashflow-bar-graph', newRange));
		}
	}

	next(size = 1) {
		const { currentTimesliceSize, currentDate } = this.state;
		const nextMonth = addTimeslice(currentDate, currentTimesliceSize, size);
		// this.updateRange(nextMonth, this.requestedGroups);
		this.setDate(nextMonth);
	}

	prev(size = 1) {
		const { currentTimesliceSize, currentDate } = this.state;
		const prevMonth = addTimeslice(
			currentDate,
			currentTimesliceSize,
			-size,
		);
		// this.updateRange(prevMonth, this.requestedGroups);
		this.setDate(prevMonth);
	}

	updateSize(size) {
		const { dispatch } = this.context;
		const {
			size: { width = 0 },
			graph: { range: dataRange = [] },
		} = this.state;
		this.groupWidths.push(size);
		if (this.groupWidths.length >= dataRange.length - 2) {
			const newSize = Math.max(...this.groupWidths);

			this.groupWidths.length = 0;

			if (
				newSize !== 0 &&
				!Number.isNaN(newSize) &&
				this.groupWidth !== newSize
			) {
				this.groupWidth = newSize;
				dispatch(setGraphGroupSize('cashflow-bar-graph', newSize));
			}
		}

		if (
			width &&
			this.groupWidth &&
			this.requestedGroups !== Math.floor(width / this.groupWidth) + 2
		) {
			this.requestedGroups = Math.floor(width / this.groupWidth) + 2;
			dispatch(
				requestNewRange(
					'cashflow-bar-graph',
					Math.floor(width / this.groupWidth) + 2,
				),
			);
		}
	}

	clickAction({ bar, group, badge, source }) {
		// const { currentTimesliceSize } = this.state;
		const { dispatch } = this.context;
		const { currentTimesliceSize } = this.state;

		if (badge) {
			// const date = new Date(group.id);
			dispatch(setActiveGroups('cashflow-bar-graph', [group.id]));
			dispatch(setListOpen(true, source));
			dispatch(setSelectedDate(new Date(group.id)));
			dispatch(
				setCurrentDate(
					addTimeslice(new Date(group.id), currentTimesliceSize, -4),
				),
			);
			dispatch(
				setSelectedType(
					badge.types.includes('deposit')
						? ['DEPOSIT']
						: ['WITHDRAW'],
				),
			);
			/*
			status: badge.types.includes('overdue') ? ['OVERDUE'] : [],
			*/
		} else {
			if (group) {
				// const date = new Date(group.id);
				dispatch(setActiveGroups('cashflow-bar-graph', [group.id]));
				dispatch(setListOpen(true, source));
				dispatch(setSelectedDate(new Date(group.id)));
				dispatch(
					setCurrentDate(
						addTimeslice(
							new Date(group.id),
							currentTimesliceSize,
							-4,
						),
					),
				);
				dispatch(
					setSelectedType(
						bar
							? [bar.data.type.toUpperCase()]
							: ['DEPOSIT', 'WITHDRAW'],
					),
				);

				if (source === 'edit') {
					dispatch(setAdjustOpen(true));
				} else {
					dispatch(setAdjustOpen(false));
				}
			} else {
				dispatch(setActiveGroups('cashflow-bar-graph', []));
			}

			if (bar) {
				dispatch(setListOpen(true, source));
				dispatch(setActiveBars('cashflow-bar-graph', [bar.data.type]));
				if (source === 'edit') {
					dispatch(setAdjustOpen(true));
				} else {
					dispatch(setAdjustOpen(false));
				}
			} else {
				dispatch(setActiveBars('cashflow-bar-graph', []));
			}
		}
	}

	mouseEnterAction({ bar, group }) {
		const { dispatch } = this.context;
		if (group) {
			dispatch(setHoverGroups('cashflow-bar-graph', [group.id]));
		}

		if (bar) {
			dispatch(setHoverBars('cashflow-bar-graph', [bar]));
		}
	}

	mouseLeaveAction({ bar }) {
		const { dispatch } = this.context;
		if (!bar) {
			dispatch(setHoverGroups('cashflow-bar-graph', []));
		}

		if (bar) {
			dispatch(setHoverBars('cashflow-bar-graph', []));
		}
	}

	render() {
		const { graph = {}, currentTimesliceSize, userSettings } = this.state;

		return (
			<GraphLayout
				graph={graph}
				updateSize={this.updateSize}
				next={this.next}
				prev={this.prev}
				ref={this.ref}
				getTooltip={this.getToolTip}
				onClick={this.clickAction}
				onMouseEnter={this.mouseEnterAction}
				onMouseLeave={this.mouseLeaveAction}
				currentTimesliceSize={currentTimesliceSize}
				settings={userSettings}
			/>
		);
	}
}

GraphData.contextType = DatalayerContext;

GraphData.displayName = 'GraphData';

export { CashflowTooltipContent };
export default React.memo(withTheme(GraphData));
