import React, { useState, useEffect, useCallback } from 'react';
import { ModalBody, ModalFooter } from '@asteria/component-modal';
import classNames from 'classnames';

import { useFeature } from '@asteria/component-featureflag';
import Button from '@asteria/component-button/button';
import styled from 'styled-components';
import {
	FormGroupV2,
	// Counter,
	InputV2,
	CheckboxGroupV2,
} from '@asteria/component-form';
import { Translation, TranslationService } from '@asteria/services-language';
import {
	createValidator,
	useInput,
	useIsMounted,
	Getter,
} from '@asteria/utils';
import Alert from '@asteria/component-alert';
import { Text } from '@asteria/component-core';

const FormWrapper = styled.div`
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	/* margin: 0 20px; */
	padding: 16px 16px 30px;
	background-color: #faf2eb;
`;

const MESSAGE_FORM_GROUP_STYLE = { marginBottom: '8px' };

const Survey = styled(({ className, type, onClose = () => {} }) => {
	const [message, handleMessageChange] = useInput(
		TranslationService.get(`survey.${type}.message`, ''),
	);

	const [submission, setSubmission] = useState(false);
	const [validationErrors, setErrors] = useState({});
	const [showSuccess, setShowSuccess] = useState(false);

	const isMessageDisabled = useFeature({
		key: `survey-${type?.toLowerCase()}-disable-message`,
	});

	const baseTranslationKeys = ['survey', `survey.${type}`];

	useEffect(() => {
		handleMessageChange({
			target: {
				value: TranslationService.get(`survey.${type}.message`, ''),
			},
		});
	}, [handleMessageChange, type]);

	// const maxMessageLength = 400;

	// Create validator function and define its rules and messages
	const validate = useCallback(
		createValidator(
			{
				message: () => {
					if (!message.length) {
						return TranslationService.get(
							baseTranslationKeys.map(
								t => `${t}.form.message.validation.required`,
							),
							'survey.form.message.validation.required',
							'Vänligen fyll i din åsikt',
						);
					}
					// if (message.length > maxMessageLength) {
					// 	return TranslationService.get(
					// 		'survey.form.message.validation.length',
					// 		'Your message is too long',
					// 	);
					// }
					return undefined;
				},
			},
			setErrors,
			validationErrors,
		),
		[setErrors, validationErrors, message],
	);

	const isMounted = useIsMounted();
	// Prepare a submit function here to keep it consistent with validation state
	const submitSurvey = useCallback(async () => {
		if (!Object.keys(validationErrors).length) {
			setShowSuccess(true);
		}
		if (!isMounted.current) return;
		setSubmission(false); // Don't forget to drop submission flag
	}, [isMounted, validationErrors]);

	// Submit form
	useEffect(() => {
		// Try to submit form just after we get a consistent state
		if (submission) {
			submitSurvey();
		}
	}, [submitSurvey, submission]);

	const handleCreate = useCallback(() => {
		validate(); // Check all form fields again
		setSubmission(true); // Try to submit form after we get a consistent form state
	}, [validate]);

	if (showSuccess) {
		return (
			<div className={classNames('asteria-survey-modal', className)}>
				<ModalBody>
					<div
						style={{
							display: 'flex',
							flex: '1',
							flexDirection: 'column',
							margin: '0 20px',
							padding: '16px',
							backgroundColor: '#FAF2EB',
						}}
					>
						<Text type="paragraph">
							{TranslationService.get(
								baseTranslationKeys.map(
									t => `${t}.success.text`,
								),
								'Tack för din feedback. Din åsikt är viktigt för oss och vi tar till oss er feedback i strävan efter att förbättra våra tjänster för våra kunder.',
								{ showSuccess },
							)}
						</Text>
					</div>
				</ModalBody>
				<ModalFooter
					style={{
						paddingLeft: '20px',
						paddingRight: '20px',
					}}
				>
					<Button
						size="regular"
						type="primary"
						text={TranslationService.get(
							[
								'button.done',
								...baseTranslationKeys.map(
									t => `${t}.success.done`,
								),
							],
							'Färdig',
						)}
						onClick={() => onClose(message)}
					/>
				</ModalFooter>
			</div>
		);
	}

	return (
		<div className={classNames('asteria-survey-modal', className)}>
			<ModalBody scroll className="modal-body">
				<Translation
					Component={Text}
					className="asteria-text-desc"
					translationKey="survey.description"
					defaultText="Din åsikt är viktig! Vi skulle gärna vilja ha din åsikt om Företagskollen, vi vill gärna veta vad du är intresserad av att kunna göra och se i Företagskollen. <br/><br/> Finns det något som du tycker är extra jobbigt med ditt kassaflöde? Finns det något som skulle förenkla för dig som får dig att fokusera på det du är bäst på - ditt företagande?"
				/>
				<FormWrapper className="asteria-dialog-box">
					<FormGroupV2 style={MESSAGE_FORM_GROUP_STYLE}>
						<InputV2
							className="asteria-survey-message"
							elementType="textarea"
							placeholder={TranslationService.get(
								baseTranslationKeys.map(
									t => `${t}.message.placeholder`,
								),
								'Skriv din åsikt här',
							)}
							onChange={handleMessageChange}
							disabled={isMessageDisabled}
							value={message}
							invertedcolor
						/>
						{validationErrors.message ? (
							<Alert
								icon
								type="error"
								showClose={false}
								headingContent={validationErrors.message}
							/>
						) : null}
					</FormGroupV2>
				</FormWrapper>
			</ModalBody>
			<ModalFooter className="modal-footer">
				<Button
					size="medium"
					type="default"
					className="asteria-button-cancel"
					text={TranslationService.get(
						[
							'button.abort',
							...baseTranslationKeys.map(
								t => `${t}.button.cancel`,
							),
						],
						'Avbryt',
					)}
					onClick={onClose}
				/>
				<Button
					size="medium"
					type="primary"
					className="asteria-button-send"
					text={TranslationService.get(
						[
							'button.create',
							...baseTranslationKeys.map(
								t => `${t}.button.create`,
							),
						],
						'Skicka',
					)}
					disabled={submission}
					onClick={handleCreate}
				/>
			</ModalFooter>
		</div>
	);
})``;

Survey.Styler = {
	children: [
		{
			component: Button,
			base: [Getter('button')],
		},
		{
			component: CheckboxGroupV2,
			base: [Getter('checkbox')],
		},
		{
			component: InputV2,
			base: [Getter('input')],
		},
		{
			component: FormWrapper,
			base: [Getter('form')],
		},
	],
};

export default Survey;
