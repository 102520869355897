import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Getter } from '@asteria/utils';
import { format } from 'date-fns';
import Button from '@asteria/component-button/button';

const TextWrapper = styled.div``;
const MonthsBox = styled(({ className, date, active = false, setActive }) => (
	<div
		className={classNames(className, `asteria-datepicker-date`, {
			'asteria-state-active': active,
		})}
		onClick={e => {
			e.stopPropagation();
			setActive(date);
		}}
		onKeyPress={() => setActive(date)}
		role="button"
		tabIndex="-1"
	>
		<Button
			className="asteria-button-select-date"
			text={format(date, 'MMM')}
			onClick={e => {
				e.stopPropagation();
				setActive(date);
			}}
		/>
	</div>
))`
	${Button} {
		background: none;
		color: inherit;
		border: none;
		padding: 0;
		cursor: pointer;
		outline: inherit;
	}
`;

MonthsBox.Styler = {
	typePrefix: 'asteria-datepicker-date',
	children: [
		{
			component: TextWrapper,
			base: [Getter('wrapper')],
		},
		{
			component: Button,
			base: [Getter('button')],
		},
	],
};

export default MonthsBox;
