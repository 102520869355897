import React, { useContext, useEffect } from 'react';
import { TextV2, TitleV2 } from '@asteria/component-typography';
import { ButtonV2 } from '@asteria/component-button';
import Alert from '@asteria/component-alert';
import { TranslationService } from '@asteria/services-language';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import Layout from '../../layout';
import Context from '../../context';

const Success = ({ navigator }) => {
	const { setStepIndex } = useContext(Context);
	const { dispatch } = useContext(DatalayerContext);

	useEffect(() => {
		setStepIndex(3);
	}, [setStepIndex]);

	dispatch(() => ({
		action: 'CONNECTION_CLEANUP',
	}));

	return (
		<Layout
			type="asteria-success"
			header={null}
			body={
				<>
					<Alert
						icon
						type="success"
						headingContent={TranslationService.get(
							'onboarding.swedbank.success.succesAlert.title',
							'Klart',
						)}
						showClose={false}
						description={
							<>
								<TitleV2 size="title3">
									{TranslationService.get(
										'onboarding.swedbank.success.succesAlert.content.text1',
										'Du har anslutit banken',
									)}
								</TitleV2>
								<TitleV2 size="title2">Swedbank</TitleV2>
								<TextV2 size="small">
									{TranslationService.get(
										'onboarding.swedbank.success.succesAlert.content.text2',
										'Under Företagskollen kommer du strax se en prognos av ditt företags utveckling.',
									)}
								</TextV2>
							</>
						}
					/>
					<div
						className="asteria-wizard-inner-footer"
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							paddingTop: '20px',
							marginTop: '55px',
						}}
					>
						<ButtonV2
							size="medium"
							type="primary"
							text={TranslationService.get(
								'onboarding.swedbank.success.finishButton',
								'Klar',
							)}
							onClick={() =>
								navigator.next(
									{
										name: 'Finish',
										integrationTitle: 'Swedbank',
									},
									navigator,
								)
							}
						/>
					</div>
				</>
			}
			footer={<></>}
		/>
	);
};

Success.navigationKey = Symbol('Success');

export default Success;
