import React, { useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { compileStyles, Getter } from '@asteria/utils';

const Segments = [
	{
		label: 'Client invoices',
		value: 90,
		target: '',
		type: 'client',
	},
	{
		label: 'Supplier invoices',
		value: 90,
		target: '',
		type: 'supplier',
	},
	{
		label: 'Tax',
		value: 90,
		target: '',
		type: 'tax',
	},
	{
		label: 'Cash',
		value: 90,
		target: '',
		type: 'cash',
	},
];

const PieSegment = styled.div`
	--pie-offset: ${({ offset }) => offset};
	height: 100%;
	position: absolute;
	width: 100%;
	overflow: hidden;
	transform: rotate(calc(var(--pie-offset) * 1deg));
	transform-origin: 50% 50%;
	pointer-events: none;
	transition: all 0.2s ease-in;

	.asteria-pie-segment-value {
		cursor: pointer;
		pointer-events: all;
		background: ${({ color }) => color};
		content: '';
		height: 100%;
		position: absolute;
		transform: translate(0, 100%) rotate(45deg);
		transform-origin: 100% 50%;
		width: 100%;
		:after {
			/* background: rgba(255, 255, 255, 0.2); */
			background: #dadada;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			content: '';
			display: block;
			opacity: 0;
		}
	}

	.asteria-pie-segment-value-above {
		transform-origin: 0% 50%;
	}

	&.asteria-pie-segment-client {
		.asteria-pie-segment-value {
			background: #5981ca;
		}
	}

	&.asteria-pie-segment-supplier {
		.asteria-pie-segment-value {
			background: #b0558c;
		}
	}

	&.asteria-pie-segment-tax {
		.asteria-pie-segment-value {
			background: #512b2b;
		}
	}

	&.asteria-pie-segment-cash {
		.asteria-pie-segment-value {
			background: #eca363;
		}
	}
`;

const Pie = styled(
	({ className = () => {}, hover = false, items = Segments, children }) => {
		const segments = useMemo(() => {
			const total = items.reduce((acc, { value }) => acc + value, 0);
			let offset = 0;
			return items
				.map(({ value, type, max, ...item }) => {
					const itemValue = (value / (max || total)) * 360;
					offset += itemValue;
					return {
						...item,
						offset: offset - itemValue,
						length: itemValue,
						tooltip: `${type} ${Math.round(
							(value / (max || total)) * 100,
						)}%`,
						type,
					};
				})
				.sort(({ length: a }, { length: b }) => a - b);
		}, [items]);

		return (
			<div className={classNames(className, 'asteria-graph-pie')}>
				<div className="overview">{children}</div>
				{segments.map(
					({
						id,
						offset,
						length,
						onClick,
						onMouseEnter,
						onMouseLeave,
						color,
						type,
					}) => (
						<PieSegment
							key={`${id}-${type}`}
							className={classNames(
								'asteria-pie-segment',
								`asteria-pie-segment-${type}`,
								{
									'asteria-state-hover': hover === id,
								},
							)}
							offset={offset}
							color={color}
						>
							<span
								className={classNames(
									'asteria-pie-segment-value',
								)}
								style={{
									transform: `translate(-50%, 0%) rotate(calc(${Math.min(
										length,
										180,
									)} * 1deg))`,
								}}
								onClick={onClick}
								onKeyPress={onClick}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
								role="button"
								tabIndex="-1"
							/>
							{length > 180 && (
								<span
									className={classNames(
										'asteria-pie-segment-value',
										'asteria-pie-segment-value-above',
									)}
									style={{
										transform: `translate(50%, 0%) rotate(calc(${length -
											180}*1deg))`,
									}}
									onClick={onClick}
									onKeyPress={onClick}
									onMouseEnter={onMouseEnter}
									onMouseLeave={onMouseLeave}
									role="button"
									tabIndex="-1"
								/>
							)}
						</PieSegment>
					),
				)}
			</div>
		);
	},
)`
	background: #fff;
	border-radius: 100%;
	height: 200px;
	position: relative;
	width: 200px;
	overflow: hidden;

	// &:hover {
	// 	.asteria-pie-segment {
	// 		opacity: 0.5;
	// 		&:hover {
	// 			opacity: 1;
	// 		}
	// 	}
	// }

	/* 	&:before {
		background: #fff;
		height: 100%;
		content: "";
		width: 4px;
		transform: translate(-50%, -50%);
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 50%;
	} */

	.overview {
		height: 70%;
		width: 70%;
		position: absolute;
		top: 50%;
		left: 50%;
		background: #fff;
		z-index: 1;
		transform: translate(-50%, -50%);
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 100%;
		text-align: center;
		flex-direction: column;
		pointer-events: none;
	}

}

	${({ theme }) => compileStyles(Pie, theme)}
`;
Pie.displayName = 'Pie';
Pie.Styler = {
	base: [Getter('graph.pie')],
	children: [
		{
			typePrefix: 'asteria-pie-segment',
			component: '.asteria-pie-segment',
			base: [Getter('segment')],
			children: [
				{
					typePrefix: 'asteria-pie-segment-value',
					component: '.asteria-pie-segment-value',
					base: [Getter('value')],
				},
			],
		},
	],
};

export default Pie;
