import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { LinearIndicator } from '@asteria/component-progressbar-indicator';
import Dialog from '@asteria/component-modal/dialog';
import { TranslationService } from '@asteria/services-language';
import { Title, Text } from '@asteria/component-core';
import DatalayerContext from '@asteria/services-datalayer/react/context';

const GET_INTEGRATIONS = `
query GetIntegrations {
	integrations {
		id
		status {
			state
		}
	}
}
`;

const IntegrationStatusDialog = styled(({ className, callback }) => {
	const { lookup } = useContext(DatalayerContext);
	const [showDialog, setShow] = useState(true);
	const [integrationStatuses, setIntegrationStatuses] = useState({});

	const socket = lookup('service-websocket');
	const apiService = lookup('service-api');

	useEffect(() => {
		const updateIntegrationStatus = ({ id, _id = id, status }) => {
			const state = status?.state || 'IDLE';

			if (
				state !== 'INITIATING' &&
				integrationStatuses[_id] !== undefined
			) {
				const newIntegrations = { ...integrationStatuses };
				delete newIntegrations[_id];
				setIntegrationStatuses(newIntegrations);
				callback('integration.status', { integrationId: _id, status });
			} else if (
				state === 'INITIATING' &&
				integrationStatuses[_id] === undefined
			) {
				setIntegrationStatuses({
					...integrationStatuses,
					[_id]: state,
				});
				callback('integration.status', { integrationId: _id, status });
			}
		};

		apiService
			.query(GET_INTEGRATIONS, {}, { reqAuth: true })
			.subscribe(({ data = {} }) =>
				(data?.integrations || []).map(integration =>
					updateIntegrationStatus(integration || {}),
				),
			);

		const subscription = socket
			.subscribe({
				query: `
	                subscription IntegrationUpdated {
	                    integrationUpdated {
	                    	_id
	                    	status {
	                    		state
	                    	}
	                    }
	                }
	            `,
				operationName: 'IntegrationUpdated',
				variables: {},
			})
			.subscribe(({ data: { integrationUpdated = {} } = {} }) =>
				updateIntegrationStatus(integrationUpdated || {}),
			);

		return () => {
			subscription.unsubscribe();
		};
	}, [socket, integrationStatuses, apiService, callback]);

	if (!showDialog || !Object.keys(integrationStatuses).length) {
		return false;
	}

	return (
		<Dialog
			className={classNames(className, 'asteria-dialog-floating')}
			onClose={() => setShow(false)}
		>
			<Title>
				{TranslationService.get(
					'dialog.integration.importing.title',
					'Vi hämtar och analyserar dina kategorier och transaktioner',
				)}
			</Title>
			<LinearIndicator />
			<Text>
				{TranslationService.get(
					'dialog.integration.importing.text',
					'Inläsning av dina transaktioner från internetbanken pågår. Du kan självklart logga ut eller göra annat under tiden.',
				)}
			</Text>
		</Dialog>
	);
})`
	display: flex;
	flex-direction: column;
	background-color: #f9f8f6;
	position: fixed;
	right: 32px;
	left: auto;
	z-index: 9;
	padding: 32px;
	width: 290px;
	box-shadow: 1px 2px 13px rgba(0, 0, 0, 0.2);
	border-radius: 4px;

	${Text} {
		margin-top: 12px;
		text-align: center;
		font-size: 12px;
		margin: 8px 0 0 !important;
	}

	${Title} {
		text-align: center;
		color: var(--title-color);
	}

	${LinearIndicator} {
		margin-top: 12px;
		margin-bottom: 12px;
		width: 100%;
	}

	.asteria-icon-wrapper {
		width: 12px !important;
		svg {
			fill: #99999e !important;
		}
		&:hover {
			svg {
				fill: #ee7024 !important;
			}
		}
	}
`;

IntegrationStatusDialog.displayName = 'IntegrationStatusDialog';

IntegrationStatusDialog.Styler = {
	children: [],
};
export default IntegrationStatusDialog;
