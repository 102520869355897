import gql from 'graphql-tag';

export const GET_SUMMARY = gql`
	query GetSummary(
		$size: TRANSACTION_SUMMARY_SIZE
		$startDate: Date
		$endDate: Date
	) {
		transactionsSummary(
			filters: { size: $size, startDate: $startDate, endDate: $endDate }
		) {
			date
			types {
				date
				sums {
					original {
						total
					}
				}
				max {
					original {
						total
					}
				}
				min {
					original {
						total
					}
				}
				probability
				type
				status
				count
			}

			categories {
				type
				status
				sums {
					original {
						total
					}
				}
				max {
					original {
						total
					}
				}
				min {
					original {
						total
					}
				}
				probability
				category {
					id
					name
					tags {
						id
						name
					}
				}
			}

			tags {
				type
				status
				sums {
					original {
						total
					}
				}
				max {
					original {
						total
					}
				}
				min {
					original {
						total
					}
				}
				probability
				tag {
					id
					name
				}
			}

			clients {
				type
				status
				sums {
					original {
						total
					}
				}
				clientId
			}
		}
	}
`;

export const GET_GRAPH = gql`
	query CashFlow($startDate: Date!, $endDate: Date!, $size: String!) {
		cashflow(
			input: { startDate: $startDate, endDate: $endDate, size: $size }
		) {
			entries {
				incoming {
					total
					volatility
					tags {
						total
						tag
						color
						count
						forecast
					}
					currencies {
						total
						convertedTotal
						currency
						gain
						volatility
					}
				}
				outgoing {
					total
					volatility
					tags {
						total
						tag
						color
						count
						forecast
					}
					currencies {
						total
						convertedTotal
						currency
						gain
						volatility
					}
				}
				account {
					all
					currencies {
						total
						convertedTotal
						currency
					}
					tags {
						total
						tag
						count
					}
					fx {
						total
						convertedTotal
						currency
						gain
						volatility
						type
					}
				}
				credit
				type
				factory
				timeslice
				date
			}
		}
	}
`;

export const GET_CATEGORIES = gql`
	query Categories {
		categories {
			_id
			name
			tags {
				_id
				name
			}
		}
	}
`;

export const QUERY_CASHFLOW = gql`
	query QueryCashflow(
		$startDate: Date
		$endDate: Date
		$tags: [ObjectId]
		$clients: [ObjectId]
		$status: [String]
		$currencies: [String]
		$preview: CashFlowQueryPreviewInputType
	) {
		cashflow(
			filters: {
				startDate: $startDate
				endDate: $endDate
				search: {
					tags: $tags
					clients: $clients
					status: $status
					currencies: $currencies
				}
				preview: $preview
			}
		) {
			sums {
				original {
					total
				}
			}
			max {
				original {
					total
				}
			}
			min {
				original {
					total
				}
			}
			probability
			type
			status
			count
			badge
			chip {
				id
				type
				config
			}
			info {
				id
				type
				data
			}
		}
	}
`;

export const QUERY_CASHFLOWS = gql`
	query QueryCashflows(
		$dates: [CashflowQueryFilterInputType]!
		$tags: [ObjectId]
		$clients: [ObjectId]
		$status: [String]
		$currencies: [String]
		$preview: CashFlowQueryPreviewInputType
	) {
		cashflows(
			dates: $dates
			filters: {
				search: {
					tags: $tags
					clients: $clients
					status: $status
					currencies: $currencies
				}
				preview: $preview
			}
		) {
			sums {
				original {
					total
				}
			}
			max {
				original {
					total
				}
			}
			min {
				original {
					total
				}
			}
			probability
			type
			status
			count
			badge
			chip {
				id
				type
				config
			}
			info {
				id
				type
				data
			}
		}
	}
`;
