export default [
	{
		active: true,
		name: 'Företagskonto',
		sums: { original: { total: 50000, currency: 'SEK' } },
		identifiers: { number: '97116629' },
	},
	{
		active: true,
		name: 'Sparkonto',
		sums: { original: { total: 25000, currency: 'SEK' } },
		identifiers: { number: '77898420' },
	},
	{
		active: true,
		name: 'Investmentkonto',
		sums: { original: { total: 10000, currency: 'EUR' } },
		identifiers: { number: '99614137' },
	},
	{
		active: true,
		name: 'Utlandskonto',
		sums: { original: { total: 8000, currency: 'USD' } },
		identifiers: { number: '34713047' },
	},
];
