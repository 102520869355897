import { map } from 'rxjs/operators';

import { LOGIN, UPDATE_USER, ADMIN_LOGIN } from './queries';

import { fetchEnabledFeatures } from '../features/actions';

export const loginSuccess = tokens => ({ dispatch }) => {
	dispatch(() => ({
		action: 'LOGIN_SUCCESS',
		payload: tokens,
	}));
};

export const login = (
	{ username, password, partnerId },
	history,
	location = '/',
) => ({ dispatch, lookup }) => {
	dispatch(() => ({ action: 'LOGIN_INPROGRESS' }));
	const apiService = lookup('service-api');

	return apiService
		.query(LOGIN, { username, password, partnerId })
		.pipe(map(({ data: { login: { data } } = {} } = {}) => data))
		.subscribe(
			tokens => {
				localStorage.wingsToken = tokens.accessToken;
				history.push(location);
				dispatch(loginSuccess(tokens));
				dispatch(fetchEnabledFeatures());
			},
			e =>
				dispatch(() => ({
					action: 'LOGIN_FAIL',
					payload: { error: e },
				})),
		);
};

export const logout = () => ({ dispatch }) => {
	dispatch(() => ({ action: 'LOGOUT' }));
	// dispatch(gotoRoute('/login'));
	delete localStorage.wingsToken;
	// eslint-disable-next-line no-restricted-globals
	location.reload();
};

export const setUserSettings = settings => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_USER_SETTINGS',
		payload: settings,
	}));
};

export const fetchUser = () => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_LOADING_USER',
		payload: {},
	}));

	dispatch(() => ({
		action: 'REQUEST_DATA_USER',
		payload: {},
	}));
};

export const saveUser = (user, onSuccess = () => {}, onFailure = () => {}) => ({
	lookup,
	dispatch,
}) => {
	const apiService = lookup('service-api');
	return apiService
		.query(UPDATE_USER, { input: user })
		.subscribe(({ data }) => {
			if (data?.updateUser?.user) {
				dispatch(() => ({
					action: 'SET_USER',
					payload: data?.updateUser?.user || {},
				}));
			}

			onSuccess();
		}, onFailure);
};

export const loginAdmin = ({ username, password }) => ({
	dispatch,
	lookup,
}) => {
	dispatch(() => ({ action: 'ADMIN_LOGIN_INPROGRESS' }));
	const apiService = lookup('service-api');

	return apiService
		.query(ADMIN_LOGIN, { username, password })
		.pipe(
			map(resp => {
				if (resp?.errors) {
					throw resp.errors;
				}

				return resp?.data?.loginAdmin?.data;
			}),
		)
		.subscribe(
			tokens => {
				localStorage.asteriaAdminToken = tokens.accessToken;
				dispatch(() => ({
					action: 'ADMIN_LOGIN_SUCCESS',
					payload: tokens,
				}));
			},
			e =>
				dispatch(() => ({
					action: 'ADMIN_LOGIN_FAIL',
					payload: [e],
				})),
		);
};

export const fetchPartners = () => ({ dispatch, lookup }) => {
	const apiService = lookup('service-api');

	return apiService
		.query(
			`
			query Partners {
				partners {
				edges {node {name id}}
				}
			}
		`,
			{},
			{ reqAuth: true, admin: true },
		)
		.pipe(
			map(resp => {
				if (resp?.errors) {
					throw resp.errors;
				}

				return resp?.data?.partners?.edges;
			}),
		)
		.subscribe(
			data => {
				dispatch(() => ({
					action: 'SET_PARTNERS',
					payload: data.map(({ node }) => node),
				}));
			},
			() => {},
		);
};

export const logoutAdmin = () => ({ dispatch }) => {
	dispatch(() => ({ action: 'ADMIN_LOGOUT' }));
	delete localStorage.asteriaAdminToken;
};
