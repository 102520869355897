import React, { Component } from 'react';
import { Debounce, StyleCache } from '@asteria/utils';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

// Append fonts
const GlobalStyle = createGlobalStyle`
	${({ theme: { typography: { fonts = [] } = {} } = {} }) =>
		fonts.map(font => font.src).join('\n')};
`;

class ProviderService extends Component {
	state = {
		theme: false,
	};

	componentDidMount() {
		// Apply debounce
		this.refreshTheme = Debounce(this.refreshTheme.bind(this), 250);

		// update theme every time we change dimensions of the window
		window.addEventListener('resize', this.refreshTheme);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.refreshTheme);
	}

	// reapply the theme to refresh component styles
	refreshTheme = () => {
		const { theme } = this.state;

		StyleCache.clear();
		this.setState({ theme: { ...theme } });
	};

	render() {
		const { children, theme = {} } = this.props;

		// Don't render anything id we don't have any theme
		if (!theme) {
			return null;
		}

		return (
			<ThemeProvider theme={theme}>
				<>
					<GlobalStyle />
					{children}
				</>
			</ThemeProvider>
		);
	}
}

export default ProviderService;
