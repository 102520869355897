const reducer = (state = {}, { action, payload }) => {
	switch (action) {
		case 'SELECT_LANGUAGE':
			return {
				...state,
				fetchLanguage: payload,
			};
		case 'SET_TRANSLATIONS':
			return {
				...state,
				language: payload.code,
				translations: payload.translations,
				current: payload.translations,
			};
		default:
			return state;
	}
};

export default reducer;
