export default {
	global: {
		css:
			// '.asteria-feature-swedbankify .asteria-fill-color1 {fill: #FBF2EA; fill-opacity: 1;} .color-muted {color: var(--muted-color);} .align-left {text-align:left;} .align-center {text-align:center;} .align-right {text-align:right;}',
			'.asteria-feature-swedbankify .asteria-fill-color1 {fill: #FFD7BE; fill-opacity: 1;} .color-muted {color: var(--muted-color);} .align-left {text-align:left;} .align-center {text-align:center;} .align-right {text-align:right;}',

		variables: {
			systemSignedColor: 'rgb(253, 193, 41)',
			systemSignedImage:
				'repeating-linear-gradient(135deg, rgb(252, 193, 39), rgb(252, 193, 39) 2.5px, rgb(250, 156, 16) 3px, rgb(250, 156, 16) 4px, rgb(252, 193, 39) 4.5px)',

			systemPaymentColor: 'rgb(253, 193, 41)',

			systemDepositColor: 'rgba(43,153,164,1)',
			systemForecastColorPaid: '#8B8B8B',
			systemForecastImage:
				'repeating-linear-gradient(135deg,transparent,transparent 1px,rgba(255,255,255,0.2) 2px,rgba(255,255,255,0.2) 4px,transparent 4.5px);',
			systemUnpaidColor: 'rgb(253, 193, 41)',
			systemUnpaidImage:
				'repeating-linear-gradient(135deg, rgb(252, 193, 39), rgb(252, 193, 39) 2.5px, rgb(250, 156, 16) 3px, rgb(250, 156, 16) 4px, rgb(252, 193, 39) 4.5px)',
			systemOverdueColor: 'rgb(168, 47, 44)',
			systemWithdrawColor: 'rgba(146,74,121,1)',
			systemSalariesColor: 'transparent',
			systemSalaryColor: 'rgb(80, 177, 208)',
			systemTaxesColor: 'transparent',
			systemTaxColor: 'rgb(81, 43, 43)',
			systemInvoicesColor: 'transparent',
			systemCustomerColor: 'rgb(80, 128, 208)',
			systemCustomerOverdueColor: 'rgb(168, 47, 44)',
			systemSupplierColor: 'rgb(190, 76, 143)',
			systemSupplierOverdueColor: 'rgb(168, 47, 44)',
			systemCashColor: 'rgba(236, 163, 99, 1)',
			systemCardColor: 'rgba(207, 175, 153, 1)',
			systemMiscColor: 'rgba(153, 153, 153, 1)',
			systemTransferColor: 'rgba(80, 208, 178, 1)',
			systemBankgiroColor: 'rgba(239, 183, 182, 1)',
			systemPlusgiroColor: 'rgba(138, 205, 195, 1)',

			systemSwishColor: 'rgba(210, 138, 197, 1)',
			systemFactoringColor: 'rgba(74, 165, 100)',

			systemInvoiceClientColor: 'rgb(80, 128, 208)',

			systemInternalTransfersColor: 'rgba(80, 177, 208)',

			systemOfficeSpaceColor: 'rgba(192,208,80,1)',
			systemOfficeElectricityColor: 'rgba(204,204,102,1)',
			systemOfficeInternetColor: 'rgba(153,204,51,1)',
			systemOfficeCleaningColor: 'rgba(102,153,0,1)',
			systemOfficeRentColor: 'rgba(112,188,104,1)',
			systemOfficeSuppliceColor: 'rgba(255,255,153,1)',
			systemOfficeMortgageColor: 'rgba(204,255,153,1)',
			systemOfficeRenovationColor: 'rgba(2,154,91,1)',
			systemOfficePetsColor: 'rgba(0,134,96,1)',
			systemOfficeGardeningColor: 'rgba(0,82,73,1)',
			systemOfficeSecurityColor: 'rgba(144,149,95,1)',
			systemOfficeVacationColor: 'rgba(242,254,220,1)',
			systemOfficeSanitationColor: 'rgba(39,55,0,1)',
			systemOfficeServicesColor: 'rgba(191,208,80,1)',

			systemTransportColor: 'rgba(239,183,182,1)',
			systemTransportParkingColor: 'rgba(204,157,173,1)',
			systemTransportFuelColor: 'rgba(163,134,160,1)',
			systemTransportServicesColor: 'rgba(121,113,141,1)',
			systemTransportLoadColor: 'rgba(81,93,117,1)',
			systemTransportCleaningColor: 'rgba(47,72,88,1)',
			systemTransportPublictransportationColor: 'rgba(182,130,129,1)',
			systemTransportTaxiColor: 'rgba(0,92,130,1)',
			systemTransportMotorbikeColor: 'rgba(191,165,164,1)',
			systemTransportOtherColor: 'rgba(87,65,65,1)',

			systemChildrenColor: 'rgba(236,163,99,1)',
			systemChildrenClothingColor: 'rgba(192,125,63,1)',
			systemChildrenToysColor: 'rgba(150,89,27,1)',
			systemChildrenActivityColor: 'rgba(109,54,0,1)',
			systemChildrenCareColor: 'rgba(72,22,0,1)',
			systemChildrenBabysittingColor: 'rgba(236,163,99,1)',
			systemChildrenAllowanceColor: 'rgba(176,137,105,1)',
			systemChildrenSchoolColor: 'rgba(64,46,50,1)',
			systemChildrenOtherColor: 'rgba(255,176,107,1)',

			systemHealthColor: 'rgba(208,80,80,1)',
			systemHealthPharmacyColor: 'rgba(255,165,158,1)',
			systemHealthHealthcareColor: 'rgba(136,0,27,1)',
			systemHealthSportsColor: 'rgba(202,75,75,1)',
			systemHealthBeautyColor: 'rgba(177,52,56,1)',
			systemHealthHairColor: 'rgba(208,80,80,1)',
			systemHealthSpaColor: 'rgba(255,117,115,1)',
			systemHealthOpticianColor: 'rgba(189,130,125,1)',
			systemHealthOtherColor: 'rgba(214,147,147,1)',

			systemFinancialColor: 'rgba(80,116,208,1)',
			systemFinancialBankColor: 'rgba(46,92,180,1)',
			systemFinancialLoadColor: 'rgba(0,68,152,1)',
			systemFinancialLatefeesColor: 'rgba(0,47,126,1)',
			systemFinancialOtherColor: 'rgba(0,27,100,1)',

			systemShoppingColor: 'rgba(246,208,3,1)',
			systemShoppingMultimediaColor: 'rgba(255,198,78,1)',
			systemShoppingClothingColor: 'rgba(255,192,127,1)',
			systemShoppingTailorColor: 'rgba(194,162,0,1)',
			systemShoppingBooksColor: 'rgba(144,119,0,1)',
			systemShoppingAlcoholColor: 'rgba(99,78,0,1)',
			systemShoppingElectronicsColor: 'rgba(64,40,0,1)',
			systemShoppingTobaccoColor: 'rgba(161,143,92,1)',
			systemShoppingFlowersColor: 'rgba(246,208,3,1)',
			systemShoppingGiftsColor: 'rgba(242,254,220,1)',
			systemShoppingJeweleryColor: 'rgba(159,144,101,1)',
			systemShoppingOtherColor: 'rgba(202,170,0,1)',

			systemLeisureColor: 'rgba(138,205,195,1)',
			systemLeisureTheatherColor: 'rgba(0,190,129,1)',
			systemLeisureMembershipColor: 'rgba(60,126,97,1)',
			systemLeisureBarColor: 'rgba(52,70,61,1)',
			systemLeisurePartiesColor: 'rgba(138,205,195,1)',
			systemLeisureCharitiesColor: 'rgba(0,170,151,1)',
			systemLeisureGolfingColor: 'rgba(97,232,206,1)',
			systemLeisureOutdoorColor: 'rgba(84,84,121,1)',
			systemLeisureArtColor: 'rgba(138,205,195,1)',
			systemLeisureDanceColor: 'rgba(52,75,72,1)',
			systemLeisurePhotoColor: 'rgba(150,177,172,1)',
			systemLeisureMusicColor: 'rgba(0,132,112,1)',
			systemLeisureHuntingColor: 'rgba(138,205,195,1)',
			systemLeisureBoatColor: 'rgba(149,216,206,1)',
			systemLeisureBettingColor: 'rgba(108,175,165,1)',
			systemLeisureSkiColor: 'rgba(100,167,157,1)',
			systemLeisureHorseColor: 'rgba(0,47,41,1)',
			systemLeisureBikingColor: 'rgba(5,83,75,1)',
			systemLeisureOtherColor: 'rgba(96,162,153,1)',

			systemEducationColor: 'rgba(0,130,141,1)',
			systemEducationLoanColor: 'rgba(0,99,110,1)',
			systemEducationFeeColor: 'rgba(0,69,80,1)',
			systemEducationEquipmentColor: 'rgba(0,41,52,1)',
			systemEducationOtherColor: 'rgba(53,122,140,1)',

			systemTravelColor: 'rgba(196,196,196,1)',
			systemTravelHotelColor: 'rgba(172,175,180,1)',
			systemTravelTransportColor: 'rgba(144,155,163,1)',
			systemTravelEntertainmentColor: 'rgba(114,137,143,1)',
			systemTravelFooddrinkColor: 'rgba(87,119,119,1)',
			systemTravelOtherColor: 'rgba(67,101,90,1)',

			systemInvestmentColor: 'rgba(115,172,57,1)',
			systemInvestmentBufferColor: 'rgba(140,198,83,1)',
			systemInvestmentPensionColor: 'rgba(153,204,102,1)',
			systemInvestmentSecuritiesColor: 'rgba(166,210,121,1)',
			systemInvestmentChildrenColor: 'rgba(179,217,140,1)',
			systemInvestmentOtherColor: 'rgba(191,223,159,1)',

			systemFoodColor: 'rgba(226,147,100,1)',
			systemFoodGroceriesColor: 'rgba(222,132,79,1)',
			systemFoodFastfoodColor: 'rgba(226,147,100,1)',
			systemFoodRestaurantsColor: 'rgba(230,162,121,1)',
			systemFoodKioskColor: 'rgba(234,177,143,1)',
			systemFoodOtherColor: 'rgba(238,192,164,1)',

			systemInsuranceColor: 'rgba(103,39,210,1)',
			systemInsuranceBuildingColor: 'rgba(132,79,222,1)',
			systemInsuranceCarColor: 'rgba(147,100,226,1)',
			systemInsuranceLifeColor: 'rgba(162,121,230,1)',
			systemInsuranceIncomeColor: 'rgba(177,143,234,1)',
			systemInsuranceAccidentColor: 'rgba(192,164,238,1)',
			systemInsuranceOtherColor: 'rgba(62,10,148,1)',

			/* COLOR */
			primaryColor: '#f35b1c',
			secondaryColor: '#31A3AE',
			textColor: '#512b2b',
			titleColor: '#512b2b',
			chipColor: '#d3c3bc',
			mutedColor: '#999999',
			labelColor: '#696969',
			labelInputColor: '#2F2424',
			dialogTextColor: '#262626',
			/* LIST */
			filterHeaderBgColor: '#EAE7E3',
			filterHeaderBorderColor: '#D0C3BD',
			filterHeaderTextColor: '#72605F',
			transactionListBgColor: '#fff',
			transactionListBorderColor: '#EBE7E2',
			transactionListTextColor: '#262626',
			transactionListActiveBgColor: '#FBF2EA',
			transactionListDetailedBgColor: '#FBF2EA',
			/* COLORS */
			fontColorBody: '#512b2b',
			fontColorCategory: '#512b2b',
			fontColorTag: '#512b2b',
			fontColorHeader: '#512b2b',
			fontColorLabel: '#2f2424',
			fontColorLink: '#31a3ae',
			/* GRAPH */
			fontColorCurrent: '#512B2B',
			fontColorActive: '#F35B1C',
			fontColorPast: '#696969',
			/* ALERT */
			alertTextColor: '#262626',
			alertWarningBgColor: '#FFF3D5',
			alertErrorBgColor: '#F9DED3',
			alertErrorBorderColor: '#C5131C',
			alertWarningBorderColor: '#F9C642',
			/* FONTS */
			/*
				fontHeadline-1: 'Swedbank Headline',
				fontHeadline-2: 'Swedbank Headline',
				fontHeadline-3: 'Swedbank Headline',
				fontHeadline-4: 'Swedbank Headline',
				fontHeadline-5: 'Swedbank Headline',
				*/
			fontCategory: 'Swedbank Sans',
			fontTag: 'Swedbank Sans',
			fontParagraphLead: 'Swedbank Sans',
			fontParagraph: 'Arial,Helvetica,sans-serif',
			fontBody: 'Arial,Helvetica,sans-serif',
			/* FONT SIZES */
			fontSizeCategory: '14px',
			fontSizeTag: '14px',
			/* MEDIA QUERIES */

			// Small devices (landscape phones, less than 768px)
			mobileBreakpoint: '767.98px',
			// Medium devices (tablets, 768px and up)
			tabletBreakpoint: '768px',
			// Large devices (desktops, 992px and up)
			desktopBreakpoint: '992px',
			// Extra large devices (large desktops, 1200px and up)
			desktopLargeBreakpoint: '1200px',

			// MOVE IN TO NEW ASTERIA THEME ???

			pageTitleFontSize: '48px',

			title1FontSize: '32px',
			title2FontSize: '28px',
			title3FontSize: '20px',
			title4FontSize: '18px',
			title5FontSize: '16px',
			title6FontSize: '15px',
			titleFontWeight: '400',
			titleTextTransform: 'none',
			titleFontFamily: `'Open Sans', sans-serif`,

			textLargeFontSize: '24px',
			textDefaultFontSize: '16px',
			textMediumFontSize: '14px',
			textSmallFontSize: '12px',
			textMicroFontSize: '10px',

			textFontFamily: `'Open Sans', sans-serif`,
			textFontWeight: '300',
			textTransform: '',

			defaultBG: '#fff',
			defaultSurfaceBG: '#fff',

			buttonBorderColor: '#dadce0',
			buttonBorderWidth: '1px',
			buttonBorderRadius: '100px',
			buttonColor: '#4C5362',
			buttonBgColor: '#fff',
			buttonBorderStyle: 'solid',
			buttonFontFamily: `'Open Sans', sans-serif`,

			buttonPrimaryBgColor: '#EE8F8B',
			buttonPrimaryBgColorHover: '#BE726F',
			buttonPrimaryColor: '#fff',
			buttonPrimaryColorHover: '#fff',
			buttonPrimaryBorderColor: 'transparent',

			buttonSecondaryBgColor: '#4C5362',
			buttonSecondaryBgColorHover: '#3D424E',
			buttonSecondaryColor: '#fff',
			buttonSecondaryColorHover: '#fff',
			buttonSecondaryBorderColor: 'transparent',

			buttonDefaultBgColor: '#F7F7F7',
			buttonDefaultBgColorHover: '#C6C6C6',
			buttonDefaultColor: '#EE8F8B',
			buttonDefaultColorHover: '#EE8F8B',
			buttonDefaultBorderColor: 'transparent',

			buttonIconOnlyBgColor: 'transparent',
			buttonIconOnlyBgColorActive: 'transparent',
			buttonIconOnlyBgColorHover: 'transparent',
			buttonIconOnlyBorderColor: 'transparent',
			buttonIconOnlyIconColor: '#3D424E',
			buttonIconOnlyIconColorActive: '#000',
			buttonIconOnlyIconColorHover: '#000',
			buttonIconOnlyIconBorderRadius: '0',
			buttonIconOnlyIconBorderWidth: '0px',
			buttonIconOnlyIconBorderStyle: 'solid',
			buttonIconOnlyIconPadding: '0',
			buttonIconOnlyIconMargin: '0',

			buttonIconOnlyRoundBgColor: '#ACACAC',
			buttonIconOnlyRoundBgColorActive: '#ACACAC',
			buttonIconOnlyRoundBgColorHover: '#ACACAC',
			buttonIconOnlyRoundBorderColor: 'transparent',
			buttonIconOnlyIconRoundColor: '#3D424E',
			buttonIconOnlyIconRoundColorActive: '#EE8F8B',
			buttonIconOnlyIconRoundColorHover: '#BE726F',
			buttonIconOnlyIconRoundBorderRadius: '50%',
			buttonIconOnlyIconRoundBorderWidth: '0px',
			buttonIconOnlyIconRoundBorderStyle: 'solid',
			buttonIconOnlyIconRoundPadding: '0',
			buttonIconOnlyIconRoundMargin: '0',

			buttonLinkBgColor: 'transparent',
			buttonLinkColor: '#EE8F8B',
			buttonLinkColorHover: '#BE726F',
			buttonLinkBorderColor: 'transparent',

			buttonFlatBgColor: 'transparent',
			buttonFlatColor: '#EE8F8B',
			buttonFlatColorHover: '#BE726F',
			buttonFlatBorderColor: 'transparent',

			buttonLargePadding: '24px 32px',
			buttonMediumPadding: '16px 24px',
			buttonSmallPadding: '8px 24px',

			buttonLargeFontSize: '24px',
			buttonMediumFontSize: '16px',
			buttonSmallFontSize: '14px',

			buttonFontWeight: '500',
			buttonTextTransform: 'none',

			iconColor: '#ACACAC',
			iconColorActive: '#000',
			iconColorHover: '#3D424E',
			iconColorInverted: 'rgba(255,255,255,0.3)',

			labelColorInverted: '#B8B8B8',
			labelFontWeight: '600',
			labelFontFamily: `'Open Sans', sans-serif`,
			labelFontSize: '12px',

			inputBorderColor: '#dadce0',
			inputBorderColorHover: '#4C5362',
			inputBorderColorInverted: 'rgba(255,255,255,0.3)',
			inputBorderColorHoverInverted: 'rgba(255,255,255,0.5)',
			inputBorderWidth: '1px',
			inputBorderRadius: '4px',
			inputColor: '#4C5362',
			inputColorInverted: '#dadce0',
			inputBgColor: '#fff',
			inputBgColorInverted: 'transparent',
			inputBorderStyle: 'solid',
			inputBorderColorFocus: '#EE8F8B',
			inputBorderColorFocusInverted: '#EE8F8B',
			inputPlaceholderColor: '#ABAEB5',
			inputPlaceholderFontSize: '14px',
			inputPlaceholderFontWeight: '300',
			inputPlaceholderColorInverted: 'rgba(255,255,255,0.3)',
			inputFontFamily: `'Open Sans', sans-serif`,
			inputFontWeight: '300',

			inputRadioLabelColor: '#ABAEB5',
			inputRadiolabelFontWeight: '300',
			inputRadioBorderColor: '#ACACAC',
			inputRadioBorderStyle: 'solid',
			inputRadioBorderWidth: '1px',
			inputRadioBorderColorSelected: '#EE8F8B',
			inputRadioBgColorSelected: '#EE8F8B',
			inputRadioLabelColorSelected: '#4C5362',
			inputRadioBorderColorHover: '#4C5362',
			inputRadioLabelColorHover: '#4C5362',

			inputDefaultPadding: '20px 40px 20px 20px',
			inputDefaultHeight: '56px',

			dropdownMenuBorderColor: '',
			dropdownMenuBorderWidth: '',
			dropdownMenuBorderRadius: '4px',
			dropdownMenuBorderBoxShadow: '',
			dropdownMenuBg: '#fff',
			dropdownMenuBoxShadow:
				'0 1px 3px 0 rgba(0,0,0,0.302), 0 4px 8px 3px rgba(0,0,0,0.149)',
			dropdownMenuItemBg: '#fff',
			dropdownMenuItemHoverBg: 'rgba(0,0,0,.06)',
			dropdownMenuItemBgActive: '#fff',
			dropdownMenuItemColor: '#616161',
			dropdownMenuItemActiveColor: '#000',
			dropdownMenuItemBorderColor: 'transparent',
			dropdownMenuItemWidth: '0px',
			dropdownMenuItemFontFamily: `'Open Sans', sans-serif`,
			dropdownMenuItemFontWeight: '300',
			dropdownMenuItemFontSize: '14px',

			modalBackDropBg: 'rgba(194, 196, 201, 0,8)',
			modalWidth: '400px',
			modalBgColor: '#fff',
			modalPadding: '0',
			modalBorderColor: 'transparent',
			modalBorderWidth: '0',
			modalBorderRadius: '8px',
			modalBoxShadow:
				'0 1px 3px 0 rgba(0,0,0,0.302), 0 4px 8px 3px rgba(0,0,0,0.149)',
			modalHeaderBgColor: '',
			modalHeaderPadding: '16px 24px 4px 24px',
			modalHeaderBorderColor: 'transparent',
			modalHeaderBorderWidth: '0',
			modalHeaderBorderRadius: '0',
			modalHeaderBoxShadow: '',
			ModalHeaderTitleFontSize: '24px',
			ModalHeaderTitleColor: '#4C5362',
			modalBodyBgColor: '',
			modalBodyPadding: '8px 4px 32px 8px',
			modalBodyBorderColor: 'transparent',
			modalBodyBorderWidth: '0',
			modalBodyBorderRadius: '0',
			modalBodyBoxShadow: '',
			modalFooterBgColor: '',
			modalFooterPadding: '16px 24px 16px 24px',
			modalFooterBorderColor: 'transparent',
			modalFooterBorderWidth: '0',
			modalFooterBorderRadius: '0',
			modalFooterBoxShadow: '',

			headerBgColor: '#fff',

			alertTitleColor: '#262626',
			alertTitleFontSize: '16',
			alertTitleFontWeight: '800',
			alertTextFontSize: '12px',
			alertTextFontWeight: '600',

			sectionTitleBgColor: 'transparent',
			sectionTitleFontFamily: `'Open Sans', sans-serif`,
			sectionTitleFontWeight: '600',
			sectionTitleFontSize: '12px',
			sectionTitleColor: '#4C5362',
			sectionTextTransform: 'capitalize',
			sectionTextColor: '#4C5362',
			sectionTextFontSize: '12px',
			sectionIconColor: '#ACACAC',
			sectionTitlePadding: '0',
			sectionTitleBorderColor: 'transparent',
			sectionTitleBorderWidth: '0',
			sectionTitleBorderStyle: 'solid',
			sectionTitleBorderRadius: '0',

			faqPadding: '0',
			faqBorderColor: 'transparent',
			faqBorderWidth: '0',
			faqBorderStyle: 'solid',
			faqBorderRadius: '0',
			faqIconColor: '#ACACAC',
			faqIconColorActive: '#4C5362',
			faqTitleFontSize: '14px',
			faqTitleFontFamily: `'Open Sans', sans-serif`,
			faqTitleFontWeight: '500',
			faqTitleFontColor: '#4C5362',
			faqTitleFontColorActive: '#EE8F8B',
			faqTitlePadding: '0',
			faqTitleBorderColor: 'transparent',
			faqTitleBorderWidth: '0',
			faqTitleBorderStyle: 'solid',
			faqTitleBorderRadius: '0',
			faqTextFontSize: '14px',
			faqTextFontWeight: '300',
			faqTextFontColor: '#4C5362',
			faqTextPadding: '0',
			faqTextBorderColor: 'transparent',
			faqTextBorderWidth: '0',
			faqTextBorderStyle: 'solid',
			faqTextBorderRadius: '0',
		},
	},
};
