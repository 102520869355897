import { map } from 'rxjs/operators';
import GET_THEME from './queries';
import Theme from './themes/swedbank';

export default ({ id }) => ({ dispatch, lookup }) => {
	dispatch(() => ({ action: 'FETCH_THEME_LOADING' }));
	const apiService = lookup('service-api');

	apiService
		.query(GET_THEME, { id }, { reqAuth: false })
		.pipe(map(({ data }) => data?.theme || {}))
		.subscribe(
			theme =>
				dispatch(() => ({
					action: 'FETCH_THEME_SUCCESS',
					payload: {
						id,
						styles: { ...Theme, ...(theme?.styles || {}) },
					} || { styles: {} },
				})),
			() =>
				dispatch(() => ({
					action: 'FETCH_THEME_FAIL',
					payload: { error: 'Dont know' },
				})),
		);
};
