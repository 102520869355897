import { StyleProvider } from '@asteria/utils';

export default new StyleProvider('authentication', {
	formRow: ({ parentSelector }) => [
		{ base: [parentSelector], selector: 'formRow.style' },
	],
	formColumn: ({ parentSelector }) => [
		{ base: [parentSelector], selector: 'formColumn.style' },
	],
});
