const map = new WeakMap();
let index = 0;

export default function weakKey(obj) {
	let key = map.get(obj);
	if (!key) {
		index += 1;
		key = `weak-key-${index}`;
		map.set(obj, key);
	}
	return key;
}
