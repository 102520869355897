export default {
	modal: {
		style: {
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
		},
		queries: {
			'(max-width: 767.98px)': {
				style: {
					alignItems: 'flex-start',
				},
			},
			'(min-width: 768px)': {
				style: {},
			},
			'(min-width: 1200px)': {
				style: {},
			},
		},
		dialog: {
			style: {
				maxHeight: '90vh',
				minHeight: 'auto',
				width: '100%',
			},
			queries: {
				'(max-width: 767.98px)': {
					style: {
						margin: '12px',
					},
				},
				'(min-width: 768px)': {
					style: {
						width: '70%',
						maxWidth: '520px',
					},
				},
				'(min-width: 1200px)': {
					style: {
						width: '70%',
						maxWidth: '540px',
					},
				},
			},
			types: {
				full: {
					queries: {
						'(max-width: 767.98px)': {
							style: {
								margin: '12px',
							},
						},
						'(min-width: 768px)': {
							style: {
								width: '100%',
								maxWidth: '100%',
								maxHeight: '100vh',
								minHeight: '100vh',
							},
						},
						'(min-width: 1200px)': {
							style: {
								width: '100%',
								maxWidth: '100%',
								maxHeight: '100vh',
								minHeight: '100vh',
							},
						},
					},
				},
			},
			header: {
				style: {
					borderBottom: '1px solid rgba(124, 124, 124, 0.3)',
					alignItems: 'center',
					padding: '12px 0px 12px 12px',
				},
				queries: {
					'(max-width: 767.98px)': {
						style: {},
					},
					'(min-width: 768px)': {
						style: {
							padding: '16px 20px 16px 32px',
						},
					},
					'(min-width: 1200px)': {
						style: {},
					},
				},
				title: {
					style: {
						fontSize: '20px',
						lineHeight: 'normal',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					},
					queries: {
						'(max-width: 767.98px)': {
							style: {},
						},
						'(min-width: 768px)': {
							style: {
								fontSize: '24px',
								lineHeight: '36px',
							},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
				},
				types: {
					withBack: {
						style: {
							padding: '12px 0px 12px 0',
						},
						icon: {
							style: {
								alignItems: 'center',
							},
						},
						queries: {
							'(max-width: 767.98px)': {
								style: {},
							},
							'(min-width: 768px)': {
								style: {
									padding: '16px 20px 16px 16px',
								},
							},
							'(min-width: 1200px)': {
								style: {},
							},
						},
					},
				},
				close: {
					icon: {
						style: {
							alignItems: 'center',
						},
						svg: {
							style: {
								height: '18px',
								fill: 'rgb(114, 96, 93)',
							},
						},
					},
					queries: {
						'(max-width: 767.98px)': {
							style: {
								height: '22px',
							},
						},
						'(min-width: 768px)': {
							style: {},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
				},
			},
			body: {
				style: {
					flexGrow: '1',
					flexShrink: '1',
					height: 'auto',
					padding: '12px 12px',
					overflow: 'hidden',
					overflowY: 'auto',
					flexDirection: 'row',
					overflowX: 'hidden',
				},
				queries: {
					'(max-width: 767.98px)': {
						style: {},
					},
					'(min-width: 768px)': {
						style: {
							padding: '32px 32px',
						},
					},
					'(min-width: 1200px)': {
						style: {
							// height: '440px',
							// marginBottom: '20px',
						},
					},
					'(-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
						style: {
							height: '440px',
							marginBottom: '20px',
						},
					},
				},
				title: {
					types: {
						title2: {
							style: {
								fontSize: '20px',
							},
						},
					},
				},
				'::-webkit-scrollbar': {
					style: {
						backgroundColor: '#FAF0E7',
						width: '16px',
					},
				},
				'::-webkit-scrollbar-track': {
					style: {
						backgroundColor: 'transparent',
					},
				},
				'::-webkit-scrollbar-thumb': {
					style: {
						backgroundColor: '#BABAC0',
						borderRadius: '16px',
						border: '6px solid #FAF0E7',
					},
				},
			},
			footer: {
				style: {
					padding: '12px 12px',
					justifyContent: 'flex-end',
					borderTop: '1px solid rgba(124, 124, 124, 0.3)',
					flexDirection: 'column-reverse',
				},
				queries: {
					'(max-width: 767.98px)': {
						style: {},
					},
					'(min-width: 768px)': {
						style: {
							flexDirection: 'row',
							padding: '16px 32px',
						},
					},
					'(min-width: 1200px)': {
						style: {},
					},
					'(-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
						style: {
							justifyContent: 'space-between',
						},
					},
				},
				button: {
					style: {
						width: '100%',
						marginBottom: 'auto',
					},
					queries: {
						'(max-width: 767.98px)': {
							style: {},
						},
						'(min-width: 768px)': {
							style: {
								width: 'auto',
							},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
					types: {
						primary: {
							style: {},
							queries: {
								'(max-width: 767.98px)': {
									style: {
										marginBottom: '6px',
									},
								},
								'(min-width: 768px)': {
									style: {
										marginLeft: 'auto',
									},
								},
								'(min-width: 1200px)': {
									style: {},
								},
							},
						},
						secondary: {
							style: {},
							queries: {
								'(max-width: 767.98px)': {
									style: {
										marginBottom: '6px',
									},
								},
								'(min-width: 768px)': {
									style: {
										marginLeft: 'auto',
									},
								},
								'(min-width: 1200px)': {
									style: {},
								},
							},
						},
					},
				},
			},
		},
	},
	types: {
		findApi: {
			style: {
				background: 'red',
			},
		},
	},
};
