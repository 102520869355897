import classNames from 'classnames';
import React, { useCallback, useState, useContext } from 'react';
import styled from 'styled-components';
import { Title, Text, TitleText } from '@asteria/component-core/typography';
import { TranslationService } from '@asteria/services-language';
import Button from '@asteria/component-core/button';
import Alert from '@asteria/component-alert';
import { capitalize, Getter } from '@asteria/utils';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { removeIntegration } from '@asteria/services-datalayer/services/integrations/actions';
import { useFeature } from '@asteria/component-featureflag';
import { Row, RowActions, Item } from '@asteria/component-core/list';

const ButtonGroup = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-top: 16px;
`;

/**
 * Defined connection statuses
 */
const erpsStatus = { get: value => (value ? 'connected' : 'disconnected') }; // new Map([[true, 'connected'], [false, 'disconnected']]);

const InfoBoxWrapper = styled.div``;

InfoBoxWrapper.Styler = {
	children: [
		{
			component: Text,
			base: [Getter('text')],
		},
		{
			component: Button,
			base: [Getter('button')],
		},
	],
};

function getNumeralName(type, count) {
	switch (type) {
		case 'month':
			return count === 1
				? TranslationService.get('erpList.time.month', 'månad')
				: TranslationService.get('erpList.time.months', 'månader');
		case 'day':
			return count === 1
				? TranslationService.get('erpList.time.day', 'dag')
				: TranslationService.get('erpList.time.days', 'dagar');
		case 'hour':
			return count === 1
				? TranslationService.get('erpList.time.hour', 'timme')
				: TranslationService.get('erpList.time.hours', 'timmar');
		case 'minute':
			return count === 1
				? TranslationService.get('erpList.time.minute', 'minut')
				: TranslationService.get('erpList.time.minutes', 'minuter');
		case 'second':
			return count === 1
				? TranslationService.get('erpList.time.second', 'sekund')
				: TranslationService.get('erpList.time.seconds', 'sekunder');
		default:
			return '';
	}
}

/**
 *
 * @param dateStart {number|string} something which defines date. There can be
 * passed both timestamp or date string in ISO format (or any other format which can be consumed by Date.parse method)
 * @return {string|undefined} N/A if undefined date is provided, string of 'x of
 * something ago' otherwise. If current date is provided - returns undefined.
 */
function timeAgo(dateStart) {
	if (typeof dateStart === 'undefined') {
		return 'N/A';
	}

	const second = 1000;
	const minute = second * 60;
	const hour = minute * 60;
	const day = hour * 24;
	const month = day * 30;

	// get 0 if date range is negative
	let range = Math.max(
		0,
		new Date().getTime() -
			(typeof dateStart === 'number' ? dateStart : Date.parse(dateStart)),
	);

	const months = Math.round(range / month);
	range -= months * month;

	const days = Math.round(range / day);
	range -= days * day;

	const hours = Math.round(range / hour);
	range -= hours * hour;

	const minutes = Math.round(range / minute);
	range -= minutes * minute;

	const seconds = Math.round(range / second);

	// return only meaningful value, and only largest one
	return [
		months
			? `${months} ${getNumeralName(
					'month',
					months,
			  )} ${TranslationService.get('erpList.time.ago', 'sedan')}`
			: undefined,
		days
			? `${days} ${getNumeralName('day', days)} ${TranslationService.get(
					'erpList.time.ago',
					'sedan',
			  )}`
			: undefined,
		hours
			? `${hours} ${getNumeralName(
					'hour',
					hours,
			  )} ${TranslationService.get('erpList.time.ago', 'sedan')}`
			: undefined,
		minutes
			? `${minutes} ${getNumeralName(
					'minute',
					minutes,
			  )} ${TranslationService.get('erpList.time.ago', 'sedan')}`
			: undefined,
		seconds
			? `${seconds} ${getNumeralName(
					'second',
					seconds,
			  )} ${TranslationService.get('erpList.time.ago', 'sedan')}`
			: undefined,
	].filter(Boolean)[0];
}

/**
 * Wrapper for timeAgo function
 * @param time {string|number} date string or timestamp
 * @return {string|string} result of timeAgo function or default string if
 * that result was undefined
 */
export function getCreationTime(time) {
	if (time) {
		return (
			timeAgo(time) ||
			TranslationService.get('erpList.time.justNow', 'precis nu')
		);
	}
	return TranslationService.get('erpList.time.none', 'Ingen data hämtad');
}

/*
const REMOVE_INTEGRATIONS = gql`
	mutation RemoveIntegration($id: ID!) {
		removeIntegration(id: $id) {
			ok
		}
	}
`;
*/

/**
 * Status Marker component
 */
const StatusMarker = styled(props => {
	const { className, status = undefined } = props;

	return (
		<span
			title={TranslationService.get(
				`erpList.status.${status ? 'connected' : 'error'}.dot`,
			)}
			className={classNames(
				className,
				erpsStatus.get(status)
					? `asteria-erps-item-status-${erpsStatus.get(status)}`
					: 'asteria-erps-item-status-na',
			)}
		/>
	);
})`
	width: 10px;
	height: 10px;
	background-color: red;

	&.asteria-erps-item-status-connected {
		background-color: #a4ca73;
	}
`;

StatusMarker.Styler = {
	typePrefix: 'asteria-erps-item-status',
};

const Info = styled.div`
	flex-grow: 1;
`;
Info.Styler = {
	children: [
		{
			component: Title,
			base: [Getter('name')],
		},
		{
			component: Text,
			base: [Getter('lastsync')],
		},
	],
};

const Details = styled.div`
	display: flex;
`;
Details.Styler = {
	children: [
		{
			component: StatusMarker,
			base: [Getter('status')],
		},
		{
			component: Info,
			base: [Getter('info')],
		},
		{
			component: Button,
			base: [Getter('button')],
		},
	],
};

const ErpListItem = styled(props => {
	const { dispatch, lookup } = useContext(DatalayerContext);
	const hasSyncNow = useFeature({ key: 'integration-sync-now' });
	const [isPromptShown, setIsPromptShown] = useState(false);
	const [isAlertShown, setIsAlertShown] = useState(true);
	const hidePrompt = useCallback(() => setIsPromptShown(false), [
		setIsPromptShown,
	]);
	const showPrompt = useCallback(() => setIsPromptShown(true), [
		setIsPromptShown,
	]);

	const apiService = lookup('service-api');

	const { data, className, newList } = props;
	const {
		id,
		key,
		type,
		name,
		lastSync,
		config: { connected, errors = [] } = {},
	} = data;

	const fetch = useCallback(() => {
		apiService
			.query(
				`mutation Import($id: ObjectId!) {importIntegration(id: $id) {ok}}`,
				{ id },
				{
					reqAuth: true,
				},
			)
			.subscribe(() => {});
	}, [apiService, id]);

	if (newList) {
		return (
			<Item
				className={classNames(
					className,
					erpsStatus.get(connected)
						? `asteria-erps-item-${erpsStatus.get(connected)}`
						: 'asteria-erps-item-na',
				)}
			>
				<Row>
					<StatusMarker status={connected} />
					<TitleText
						title={TranslationService.get(
							`integration.${type}.${key}.name`,
							name ? capitalize(name) : capitalize(key),
						)}
						text={getCreationTime(lastSync)}
					/>
					<RowActions>
						{hasSyncNow ? (
							<Button onClick={fetch} size="medium" type="link">
								Hämta nu
							</Button>
						) : null}
						<Button
							icon={isPromptShown ? 'circleClose' : 'circleMinus'}
							onClick={isPromptShown ? hidePrompt : showPrompt}
							size="medium"
							invert
							type="primary"
						/>
					</RowActions>
				</Row>
				{errors.length && isAlertShown ? (
					<Row>
						<Alert
							icon
							description
							type="error"
							headingContent={TranslationService.get(
								'integration.error.title',
								'There was an issue while communicating with {{name}}',
								{
									name: TranslationService.get(
										`integration.${type}.${key}.name`,
										name
											? capitalize(name)
											: capitalize(key),
									),
								},
							)}
							content={
								<>
									{TranslationService.get(
										`integration.error.${key}.${
											errors[0].code
										}`,
										`Error - ${errors[0].message}`,
									)}
									<br />
									{`${TranslationService.get(
										'erpList.lastSync',
										'Tid sedan den senaste synkroniseringen',
									)} – ${getCreationTime(lastSync)}`}
								</>
							}
							onClose={() => setIsAlertShown(false)}
							key={`${key}-${errors[0].code}`}
							// TODO Check later and maybe put these more
							// likely styler props into custom Alert
							// component or refactor them out in other way
							overrideRoot="graph"
							parentSelector="alert"
						/>
					</Row>
				) : null}
				{isPromptShown && (
					<Row>
						<InfoBoxWrapper className="asteria-infobox">
							<Text size="body" className="asteria-text-info">
								{TranslationService.get(
									'erpList.removePrompt',
									'Är du säker på att du vill koppla bort din anslutning?',
								)}
							</Text>
							<ButtonGroup>
								<Button
									onClick={hidePrompt}
									size="medium"
									text={TranslationService.get(
										'erpList.removePrompt.reject',
										'Nej',
									)}
									className="asteria-button-reject"
									type="default"
								/>
								<Button
									onClick={() => {
										dispatch(removeIntegration({ id }));
										hidePrompt();
									}}
									size="medium"
									className="asteria-button-confirm"
									text={TranslationService.get(
										'erpList.removePrompt.agree',
										'Ja',
									)}
									type="secondary"
								/>
							</ButtonGroup>
						</InfoBoxWrapper>
					</Row>
				)}
			</Item>
		);
	}

	return (
		<div
			className={classNames(
				className,
				erpsStatus.get(connected)
					? `asteria-erps-item-${erpsStatus.get(connected)}`
					: 'asteria-erps-item-na',
			)}
		>
			<Details>
				<StatusMarker status={connected} />
				<Info>
					<Title size="title4">
						{TranslationService.get(
							`integration.${type}.${key}.name`,
							name ? capitalize(name) : capitalize(key),
						)}
					</Title>
					<Text size="small">{getCreationTime(lastSync)}</Text>
				</Info>
				{hasSyncNow ? (
					<Button onClick={fetch} size="medium" type="link">
						{TranslationService.get('erpList.refetch', 'Hämta nu')}
					</Button>
				) : null}
				<Button
					icon={isPromptShown ? 'circleClose' : 'circleMinus'}
					onClick={isPromptShown ? hidePrompt : showPrompt}
					size="medium"
					type="link"
				/>
			</Details>
			{errors.length && isAlertShown ? (
				<Alert
					icon
					description
					type="error"
					headingContent={TranslationService.get(
						'integration.error.title',
						'There was an issue while communicating with {{name}}',
						{
							name: TranslationService.get(
								`integration.${type}.${key}.name`,
								name ? capitalize(name) : capitalize(key),
							),
						},
					)}
					content={
						<>
							{TranslationService.get(
								`integration.error.${key}.${errors[0].code}`,
								`Error - ${errors[0].message}`,
							)}
							<br />
							{`${TranslationService.get(
								'erpList.lastSync',
								'Tid sedan den senaste synkroniseringen',
							)} – ${getCreationTime(lastSync)}`}
						</>
					}
					onClose={() => setIsAlertShown(false)}
					key={`${key}-${errors[0].code}`}
					// TODO Check later and maybe put these more
					// likely styler props into custom Alert
					// component or refactor them out in other way
					overrideRoot="graph"
					parentSelector="alert"
				/>
			) : null}
			{isPromptShown && (
				<InfoBoxWrapper className="asteria-infobox">
					<Text size="body" className="asteria-text-info">
						{TranslationService.get(
							'erpList.removePrompt',
							'Är du säker på att du vill koppla bort din anslutning?',
						)}
					</Text>
					<ButtonGroup>
						<Button
							onClick={hidePrompt}
							size="medium"
							text={TranslationService.get(
								'erpList.removePrompt.reject',
								'Nej',
							)}
							className="asteria-button-reject"
							type="default"
						/>
						<Button
							onClick={() => {
								dispatch(removeIntegration({ id }));
								hidePrompt();
							}}
							size="medium"
							className="asteria-button-confirm"
							text={TranslationService.get(
								'erpList.removePrompt.agree',
								'Ja',
							)}
							type="secondary"
						/>
					</ButtonGroup>
				</InfoBoxWrapper>
			)}
		</div>
	);
})`
	${Row} {
		${StatusMarker} {
			width: 10px;
			height: 10px;
			border-radius: 100%;
			margin-right: 8px;
		}
	}
`;

ErpListItem.Styler = {
	typePrefix: 'asteria-erps-item',
	children: [
		{
			component: Details,
			base: [Getter('details')],
		},
		{
			component: InfoBoxWrapper,
			base: [Getter('dismissdialog')],
		},
	],
};

export default ErpListItem;
