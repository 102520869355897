import debug from 'debug/dist/debug';

function logger(name) {
	return target => {
		// eslint-disable-next-line no-param-reassign
		target.prototype.logger = debug(`asteria:${name}`);
	};
}

export default logger;
