import 'regenerator-runtime/runtime';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import styled, { createGlobalStyle } from 'styled-components';
import DatalayerComponent from '@asteria/component-datalayer';
import init from '@asteria/services-datalayer';
import kebabCase from 'lodash/fp/kebabCase';
import { compileStyles } from '@asteria/utils';
import { createMemoryHistory } from 'history';
import {
	setHistory,
	setSite,
} from '@asteria/services-datalayer/services/appstate/actions';
import { selectLanguage } from '@asteria/services-datalayer/services/language/actions';
import { loginSuccess } from '@asteria/services-datalayer/services/auth/actions';
import { makePath } from '@asteria/services-datalayer/react/router';
import { useRoutes, useStore } from '@asteria/utils/hooks';

import Button from '@asteria/component-core/button';
import { Title, Text } from '@asteria/component-core/typography';

import jwt from 'jsonwebtoken';

import Authentication from '@asteria/component-authentication';

import IntegrationsAdd from './integrationsAdd';
import IntegrationsManage from './integrations';
import IntegrationStatusDialog from './integrationStatusDialog';
import SiteCSS from './theme';

const getBaseUrl = token => {
	const { data: { baseUrl } = {} } = jwt.decode(token) || {};

	return baseUrl;
};

const getPartnerId = token => {
	const { data: { partnerId } = {} } = jwt.decode(token) || {};

	return partnerId;
};

const Routes = [
	{ path: makePath('/login'), View: Authentication, auth: false },
	{ path: makePath('/integrations'), View: IntegrationsManage },
	{ path: makePath('/integrations/add/:type'), View: IntegrationsAdd },
	{ path: makePath('/'), View: null },
];

const ThemeGlobalStyle = createGlobalStyle`
	:root {
		${({ theme: { global: { variables = {} } = {} } = {} }) =>
			Object.entries(variables).map(
				([key, value]) => `--${kebabCase(key)}: ${value};`,
			)}
	}
	
	.sortableHelper {
		z-index: 10000;
	}

	${({ theme: { global: { css: globalCss = '' } = {} } = {} }) => globalCss}
	${({ hideSwedbankPageTitle }) =>
		hideSwedbankPageTitle
			? 'sw-head, sw-head h1, .asteria-widget-title {display: none;}'
			: ''}
	${({ theme: { font: { fonts = [] } = {} } = {} }) =>
		fonts.map(font => font.src).join('\n')};
`;

const StyleWrapper = styled.div`
	${({ theme }) => {
		const style = compileStyles(
			{
				children: [
					{ component: Button },
					{ component: Text },
					{ component: Title },
				],
			},
			theme,
		);

		return style;
	}}

	${SiteCSS}
`;

const App = ({ callback }) => {
	const route = useRoutes(Routes);

	const { View = null } = route || {};

	const [featureClasses = []] = useStore(
		'store-features',
		store => store?.classes,
	);

	return (
		<StyleWrapper className={classNames('asteria-widget', featureClasses)}>
			<ThemeGlobalStyle />
			{View && <View />}
			<IntegrationStatusDialog callback={callback} />
		</StyleWrapper>
	);
};

const createDataLayer = async ({
	externalDatalayer = false,
	accessToken = false,
	env = 'production',
	route = '/integrations',
	languageCode = 'sv',
	partnerId = getPartnerId(accessToken),
	callback = () => {},
}) => {
	let datalayer = externalDatalayer || false;
	if (datalayer === false) {
		const baseUrl = getBaseUrl(accessToken);

		datalayer = await init({
			platform: 'ativo',
			env,
			baseURL: baseUrl,
		});

		const { dispatch } = datalayer;

		const history = createMemoryHistory();

		history.push(route);

		dispatch(setHistory(history));
		dispatch(selectLanguage(languageCode));
		dispatch(setSite('asteria', partnerId));

		if (accessToken) {
			dispatch(loginSuccess({ accessToken }));
		}

		callback('create.history', history);
	}

	return datalayer;
};

const createErp = async (
	element,
	{
		env = 'production',
		accessToken = false,
		refreshToken = false,
		callback = () => {},
		languageCode = 'sv',
		route = '/integrations',
		externalDatalayer = false,
		partnerId = getPartnerId(accessToken),
	} = {},
) => {
	const datalayer = await createDataLayer({
		env,
		accessToken,
		refreshToken,
		callback,
		languageCode,
		route,
		externalDatalayer,
		partnerId,
	});

	ReactDOM.render(
		<DatalayerComponent
			baseUrl={getBaseUrl(accessToken)}
			accessToken={accessToken}
			refreshToken={refreshToken}
			datalayer={datalayer}
			languageCode={languageCode}
		>
			<App callback={callback} />
		</DatalayerComponent>,
		element,
	);

	return true;
};

const cleanup = element => {
	ReactDOM.unmountComponentAtNode(element);
};

const AsteriaWidget = ({
	env = 'production',
	accessToken = false,
	refreshToken = false,
	callback,
	languageCode = 'sv',
	route = '/integrations',
	externalDatalayer = false,
	partnerId = getPartnerId(accessToken),
} = {}) => {
	const [datalayer, setDatalayer] = useState(null);

	useEffect(() => {
		const createDatalayer = async () => {
			const newDatalayer = await createDataLayer({
				env,
				accessToken,
				refreshToken,
				callback,
				languageCode,
				route,
				externalDatalayer,
				partnerId,
			});

			setDatalayer(newDatalayer);
		};

		if (!(externalDatalayer || datalayer)) {
			createDatalayer();
		}
	}, [
		accessToken,
		callback,
		datalayer,
		env,
		externalDatalayer,
		languageCode,
		partnerId,
		refreshToken,
		route,
	]);

	if (!datalayer) {
		return null;
	}

	return (
		<DatalayerComponent
			baseUrl={getBaseUrl(accessToken)}
			accessToken={accessToken}
			refreshToken={refreshToken}
			datalayer={datalayer}
			languageCode={languageCode}
		>
			<App callback={callback} />
		</DatalayerComponent>
	);
};

const create = createErp;

export default createErp;
export { create, createErp, cleanup, AsteriaWidget };
