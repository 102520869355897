import React, { useContext, useEffect, useState } from 'react';
import { Rating } from '@asteria/component-core';
import { TextV2, TitleV2 } from '@asteria/component-typography';
import { ButtonV2 } from '@asteria/component-button';
import { FeatureFlag } from '@asteria/component-featureflag';
import Alert from '@asteria/component-alert';
import { TranslationService } from '@asteria/services-language';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { showModal } from '@asteria/services-datalayer/services/appstate/actions';
import Layout from '../../layout';
import Context from '../../context';

const Success = ({ navigator }) => {
	const [showFeedback, setShowFeedback] = useState(true);
	const { setStepIndex } = useContext(Context);
	const { dispatch } = useContext(DatalayerContext);

	useEffect(() => {
		setStepIndex(3);
	}, [setStepIndex]);

	dispatch(() => ({
		action: 'CONNECTION_CLEANUP',
	}));

	return (
		<Layout
			type="asteria-success"
			header={null}
			body={
				<>
					<Alert
						icon
						type="success"
						headingContent={TranslationService.get(
							'onboarding.asteria.success.succesAlert.title',
							'Klart',
						)}
						showClose={false}
						description={
							<>
								<TitleV2 size="title3">
									{TranslationService.get(
										'onboarding.asteria.success.succesAlert.content.text1',
										'Du har anslutit bokföringssystemet',
									)}
								</TitleV2>
								<TitleV2 size="title2">
									Asteria Bokföring
								</TitleV2>
								<TextV2 size="small">
									{TranslationService.get(
										'onboarding.asteria.success.succesAlert.content.text2',
										'Under Företagskollen kommer du strax se en prognos av ditt företags utveckling.',
									)}
								</TextV2>
							</>
						}
					/>
					{showFeedback && (
						<FeatureFlag feature="integration-rating">
							<div>
								<Rating
									onClose={() => setShowFeedback(false)}
									flow="onboarding.asteria"
									onClick={rating => {
										setShowFeedback(false);
										dispatch(
											showModal('integrationFeedback', {
												rating,
												flow: 'onboarding.asteria',
											}),
										);
									}}
								/>
							</div>
						</FeatureFlag>
					)}
					<div
						className="asteria-wizard-inner-footer"
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							paddingTop: '20px',
							marginTop: '55px',
						}}
					>
						<ButtonV2
							size="medium"
							type="primary"
							text={TranslationService.get(
								'onboarding.asteria.success.finishButton',
								'Klar',
							)}
							onClick={() =>
								navigator.next(
									{
										name: 'Finish',
										integrationTitle: 'asteria',
									},
									navigator,
								)
							}
						/>
					</div>
				</>
			}
			footer={<></>}
		/>
	);
};

Success.navigationKey = Symbol('Success');

export default Success;
