import React, { useContext, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { useStore } from '@asteria/utils';

import { fetchListData } from '@asteria/services-datalayer/services/list/actions';
import { TranslationService } from '@asteria/services-language';
import {
	addFilter,
	addFilters,
} from '@asteria/services-datalayer/services/appstate/actions';

import { FeatureFlag, useFeature } from '@asteria/component-featureflag';
import Text from '@asteria/component-typography/text';
import Title from '@asteria/component-typography/title';

import SearchContext from './context';
import SearchExamples from './examples';
import SearchMatch from './searchMatch';
import HorizontalScroll from './scroll';
import filterAction from './filterAction';

const findCustomer = ({ clients = [] }, clientId) =>
	(clients || []).find(c => c.clientId === clientId);

const SearchResults = styled(() => {
	const {
		isFocus,
		setFocus,
		search,
		statsClients,
		tagAction,
		widthdrawTotal,
		listStatistics,
		depositTotal,
		activeCustomer,
		dispatch,
		setActiveCustomer,
		id,
		matchedParts,
		setListOpen,
		onHide,
		hideResults,
	} = useContext(SearchContext);

	const isNotifications = useFeature({
		key: 'notifications-drawer-notifications',
	});

	const isAsteriaImprovments = useFeature({
		key: 'asteria-improvements',
	});

	const [notifications] = useStore(
		'store-notifications',
		({ notifications: list }) => list,
	);

	const [filters = []] = useStore(
		'store-appstate',
		({ filters: list }) => list,
	);

	const matchNotifications = useMemo(
		() => (isNotifications ? notifications : []),
		[isNotifications, notifications],
	);

	const isActive = useCallback(
		match => filters.find(({ id: fId }) => match.id === fId),
		[filters],
	);

	const matchAction = useCallback(
		(match, status) => {
			if (match.type === 'currencies' || match.type === 'currencyRisk') {
				dispatch(addFilters(match.currencies));
			} else {
				filterAction(match, dispatch);

				if (status) {
					dispatch(
						addFilter({
							id: `status-${status}`,
							type: 'status',
							config: {
								name: `$${status.toLowerCase()}`,
								status,
							},
						}),
					);
				}
			}

			onHide();
		},
		[dispatch, onHide],
	);

	if (!isFocus || hideResults) {
		return null;
	}

	return (
		<div className="asteria-filter-nav">
			{search && (
				<FeatureFlag feature="search-customers">
					<HorizontalScroll>
						{statsClients.map(customer => (
							<div
								className={classNames(
									'asteria-scorecard asteria-customer',
									`asteria-scorecard asteria-customer-${
										customer.type
									}`,
									{
										'asteria-state-active':
											activeCustomer === customer,
									},
								)}
								onClick={() => {
									dispatch(setListOpen(true, 'bar'));
									dispatch(
										fetchListData({
											types: ['DEPOSIT', 'WITHDRAW'],
											size: 'month',
											search: {
												'links.clientId': customer.id,
											},
										}),
									);

									setActiveCustomer(customer);
									setFocus(false);
								}}
								onKeyPress={() => {}}
								tabIndex="-1"
								role="button"
							>
								<Title>{customer.name}</Title>
								<Text>
									{`${Math.round(
										(findCustomer(
											listStatistics,
											customer.id,
										).total /
											(customer.type === 'SUPPLIER'
												? -widthdrawTotal
												: depositTotal)) *
											100,
									)}% av ${
										customer.type === 'SUPPLIER'
											? 'utbetalningar'
											: 'inbetalningar'
									}`}
								</Text>
							</div>
						))}
					</HorizontalScroll>
				</FeatureFlag>
			)}
			{!search && (!isAsteriaImprovments || !filters.length) && (
				<SearchExamples id={id} tagAction={tagAction} />
			)}
			<ul className="asteria-search-results">
				{matchedParts.map(part => (
					<SearchMatch
						key={part.id || part._id}
						match={part}
						onHide={onHide}
						notifications={matchNotifications}
						isActive={isActive(part)}
						matchAction={matchAction}
					/>
				))}
				{matchedParts.length === 0 && search ? (
					<li className="asteria-search-results-item asteria-search-results-item-nothing">
						<Text style={{ marginLeft: '16px' }}>
							{TranslationService.get('search.nomatches')}
						</Text>
					</li>
				) : null}
			</ul>
		</div>
	);
})``;

SearchResults.displayName = 'SearchResults';

export default SearchResults;
