import { throttle } from 'rxjs/operators';
import { interval, Subject } from 'rxjs';
// import { GET_TRANSACTIONS, GET_TRANSACTIONS_STATS } from './queries';

export const setVisibleColumns = columns => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_VISIBLE_COLUMNS',
		payload: columns,
	}));
};

export const addItemAction = action => ({ dispatch }) => {
	dispatch(() => ({
		action: 'ADD_ITEM_ACTION',
		payload: action,
	}));
};

export const setItemAction = action => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_ITEM_ACTION',
		payload: action,
	}));
};

export const setIgnore = ids => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_IGNORE',
		payload: ids,
	}));
};

export const addIgnore = ids => ({ dispatch }) => {
	dispatch(() => ({
		action: 'ADD_IGNORE',
		payload: ids,
	}));
};

export const setListItems = items => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_LIST_ITEMS',
		payload: items,
	}));
};

export const addListItems = items => ({ dispatch }) => {
	dispatch(() => ({
		action: 'ADD_LIST_ITEMS',
		payload: items,
	}));
};

export const setListStatistics = (data, path) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_LIST_STATISTICS',
		payload: { data, path },
	}));
};

export const setListCursor = cursor => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_LIST_CURSOR',
		payload: cursor,
	}));
};

export const setListReverse = reverse => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_LIST_REVERSE',
		payload: reverse,
	}));
};

export const setListDates = (
	startDate,
	endDate,
	types,
	status,
	size,
	search,
	reverse = false,
) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_LIST_DATES',
		payload: { startDate, endDate, types, status, size, search, reverse },
	}));
};

export const setListStatus = status => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_LIST_STATUS',
		payload: status,
	}));
};

export const toggleActiveRow = item => ({ dispatch }) => {
	dispatch(() => ({
		action: 'TOGGLE_LIST_ACTIVE_ROW',
		payload: item,
	}));
};

export const toggleSelectedRow = item => ({ dispatch }) => {
	dispatch(() => ({
		action: 'TOGGLE_LIST_SELECTED_ROW',
		payload: item,
	}));
};

export const setSelectedRow = item => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_LIST_SELECTED_ROW',
		payload: item,
	}));
};

const listPipe = new Subject();
listPipe
	.pipe(throttle(() => interval(100), { leading: false, trailing: true }))
	.subscribe(val => {
		const { dispatch, payload } = val;

		dispatch(() => ({
			action: 'REQUEST_DATA_LIST',
			payload,
		}));

		dispatch(() => ({
			action: 'REQUEST_DATA_LIST_STATISTICS',
			payload: {
				request: payload,
			},
		}));
	});

export const fetchListData = ({
	startDate,
	endDate,
	types = ['DEPOSIT'],
	status = ['PAID', 'UNPAID', 'OVERDUE', 'FORECAST'],
	size = 'month',
	reverse = false,
	search,
}) => ({ dispatch }) => {
	dispatch(
		setListDates(startDate, endDate, types, status, size, search, reverse),
	);

	listPipe.next({
		payload: { startDate, endDate, types, status, size, search, reverse },
		dispatch,
	});
};

export const fetchMoreListData = ({
	startDate,
	endDate,
	types = ['DEPOSIT'],
	status = ['PAID', 'UNPAID', 'OVERDUE', 'FORECAST'],
	size = 'month',
	search,
	reverse = false,
	cursor,
}) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'REQUEST_MORE_DATA_LIST',
		payload: {
			startDate,
			endDate,
			types,
			status,
			size,
			search,
			cursor,
			reverse,
		},
	}));
};
