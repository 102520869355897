import React, { useState, useEffect, cloneElement, useCallback } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import Button from '@asteria/component-button';
import { Getter } from '@asteria/utils';
// import isFunction from '@asteria/utils/isFunction';

const TabsNavigation = styled(({ className, children, tab, setTab }) => {
	const content = (Array.isArray(children) ? children : [children])
		.filter(c => c)
		.map((child, index) => {
			if (child.props) {
				const { props: { onClick } = {} } = child;
				return cloneElement(child, {
					className: classNames(
						child.props.className,
						'asteria-tab-navigation-item',
						`asteria-tab-navigation-${child.props.tab}`,
						{
							'asteria-state-active': tab.includes(
								child.props.tab,
							),
						},
					),
					key: `${child.props.tab}-${index}`,
					onClick: (e, ...args) => {
						setTab(child.props.tab);
						if (onClick) {
							onClick(...args);
						}
					},
				});
			}

			return child({
				className: classNames('asteria-tab-navigation-item'),
				tab,
				index,
				setTab,
			});
		});

	return (
		<div className={classNames(className, 'asteria-tabs-navigation')}>
			{content}
		</div>
	);
})``;

TabsNavigation.displayName = 'TabsNavigation';

TabsNavigation.Styler = {
	children: [
		{
			component: Button,
			base: [Getter('button')],
			children: [
				{
					component: '&::after',
					base: [Getter('after')],
				},
			],
		},
	],
};

const TabsContent = styled(({ className, children, tab, setTab }) => {
	const content = (Array.isArray(children) ? children : [children])
		.filter(c => c)
		.map(child =>
			cloneElement(child, {
				className: classNames(
					child.props.className,
					'asteria-tab',
					`asteria-tab-${child.props.tab}`,
					{
						'asteria-state-active': tab.includes(child.props.tab),
						'asteria-state-not-active': !tab.includes(
							child.props.tab,
						),
					},
				),
				tab,
				setTab,
				key: child.props.tab,
				isTabActive: tab.includes(child.props.tab),
			}),
		);

	return (
		<div className={classNames(className, 'asteria-tabs-wrapper')}>
			{content}
		</div>
	);
})`
	> .asteria-tab {
		height: 100%;

		&.asteria-state-not-active {
			display: none;
		}
	}
`;

TabsContent.displayName = 'TabsContent';

const Tabs = styled(({ className, active, children, onChange = () => {} }) => {
	const [tab, setTabs] = useState(
		Array.isArray(active) ? active : active.split(','),
	);

	useEffect(() => {
		setTabs(Array.isArray(active) ? active : active.split(','));
	}, [active]);

	const setTab = useCallback(
		next => {
			const result = onChange(Array.isArray(next) ? next : [next]);

			if (result !== false) {
				setTabs(Array.isArray(next) ? next : [next]);
			}
		},
		[onChange],
	);

	const content = (Array.isArray(children) ? children : [children])
		.filter(c => c)
		.map(child =>
			cloneElement(child, {
				tab,
				setTab,
			}),
		);

	return <div className={classNames(className)}>{content}</div>;
})`
	> .asteria-tab {
		display: none;
		&.asteria-state-active {
			display: block;
		}
	}
`;

Tabs.displayName = 'Tabs';

Tabs.Styler = {
	children: [
		{
			component: TabsNavigation,
			base: [Getter('navigation')],
		},
		{
			component: TabsContent,
			base: [Getter('content')],
		},
	],
};

export default Tabs;
export { TabsNavigation, TabsContent };
