/**
 * Takes an input, filters out empty elements and returns them joined.
 * Also joins inner array elements together if there are arrays among
 * arguments.
 * @param {...String|Array} elements
 * @returns {string} suitable for use as a path
 */
export default function makePath(...elements) {
	return elements
		.filter(item => item)
		.map(item => (Array.isArray(item) ? makePath(...item) : item))
		.join('.');
}
