/* eslint-disable */
import 'core-js/modules/es.promise';
import 'core-js/modules/es.string.repeat';
import 'core-js/modules/es.string.includes';
import 'core-js/modules/es.string.starts-with';
import 'core-js/modules/es.string.ends-with';
import 'core-js/modules/es.symbol';
import 'core-js/modules/es.weak-set';
import 'core-js/modules/es.set';
import 'core-js/modules/es.object.entries';
import 'core-js/modules/es.object.keys';
import 'core-js/modules/es.object.assign'; 
import 'core-js/es/array/virtual/keys';
import 'core-js/modules/es.array.includes';
import 'core-js/modules/es.array.find';
import 'core-js/modules/es.array.find-index';
import 'core-js/modules/es.array.fill';
import 'core-js/modules/es.array.filter';
import 'core-js/modules/es.array.flat';
import 'core-js/modules/es.array.from';
import 'core-js/modules/es.number.is-nan';
import 'core-js/modules/es.number.is-integer';
import 'core-js/modules/es.math.sign';
import 'core-js/modules/es.math.log10';
import { TextEncoder } from 'text-encoding';
import 'whatwg-fetch';
import 'react-app-polyfill/ie11';

window.TextEncoder = TextEncoder;
