import { useState, useEffect, useCallback } from 'react';

function useResize(el, callback) {
	const [width, setWidth] = useState(null);
	const [height, setHeight] = useState(null);

	const handleResize = useCallback(
		entries => {
			if (Array.isArray(entries) && entries.length > 0) {
				const entry = entries[0];
				setWidth(entry.contentRect.width);
				setHeight(entry.contentRect.height);

				if (callback) {
					callback(entry);
				}
			}
		},
		[callback],
	);

	useEffect(() => {
		if (!el || !window.ResizeObserver) {
			return () => {};
		}

		let rs = new ResizeObserver(handleResize);

		rs.observe(el);

		return () => {
			rs.disconnect(el);

			rs = null;
		};
	}, [el, callback, handleResize]);

	return [width, height];
}

export default useResize;
