import Store from '../../core/store';
import logger from '../../logger';

@logger('adjustable:store')
class AdjustableStore extends Store {
	name = 'adjustable';

	constructor(subject) {
		super(subject, {
			onboarding: false,
			currentOnboardingStep: false,
			edits: {},
			transactions: {
				adding: [],
				editing: [],
				removing: [],
			},
			repeatable: null,
		});
	}
}

export default AdjustableStore;
