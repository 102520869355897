import React, { useContext } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Rating } from '@asteria/component-core';
import Modal from '@asteria/component-modal';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { showModal } from '@asteria/services-datalayer/services/appstate/actions';

import FeedbackModal from './feedback';

const Feedback = styled(
	({ className, show = false, data: { flow, rating } = {} }) => {
		// const { pathname } = useLocation();
		const { dispatch } = useContext(DatalayerContext);

		if (show === false) {
			return null;
		}

		return (
			<div className={classNames('asteria-feedback', className)}>
				<Rating rating={rating} />
				<FeedbackModal
					type="feedback"
					onClose={() => dispatch(showModal(null))}
					rating={rating}
					flow={flow}
				/>
			</div>
		);
	},
)``;

Feedback.Styler = Modal.Styler;

export default Feedback;
