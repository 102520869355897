import React, {
	useCallback,
	useContext,
	useMemo,
	useEffect,
	useState,
} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { addDays, addWeeks, addMonths, addYears } from 'date-fns';
import { useFeature } from '@asteria/component-featureflag';
import { TranslationService } from '@asteria/services-language';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { useStore } from '@asteria/utils';
import {
	showPrompt,
	setCurrentDate,
	addFilter,
	setFilters,
	setListOpen,
	setMode,
	setCredit,
} from '@asteria/services-datalayer/services/appstate/actions';
import { Dropdown } from '@asteria/component-form';
import { useHistory } from '@asteria/utils/hooks';

const addTimeslice = (date, size, count = 1) => {
	if (size === 'day') {
		return addDays(date, count);
	}
	if (size === 'week') {
		return addWeeks(date, count);
	}
	if (size === 'month') {
		return addMonths(date, count);
	}
	if (size === 'year') {
		return addYears(date, count);
	}

	return date;
};

const GET_CURRENCIES = `
query Exposure($filters: TransactionFiltersInput){
	transactionCurrencies(filters: $filters) {
	  currency
	  exposure {
		original {
		  total
		  currency
		}
		display {
		  total
		  currency
		}
	  }
	  risk {
		  original {
			  total
			  currency
		  }
		  display {
			  total
			  currency
		  }
		  risk
		  alert
	  }
	}
}
`;

const FinancialSelector = React.memo(
	styled(({ className, onChange = () => {}, onState = () => {} }) => {
		const { dispatch, lookup } = useContext(DatalayerContext);
		const [selected, setSelected] = useState([]);
		const hasCreditApplication = useFeature({ key: 'credit-application' });
		const hasFactoring = useFeature({ key: 'factoring' });
		const hasFxModule = useFeature({ key: 'fx-module' });

		const apiService = lookup('service-api');
		const history = useHistory();
		const [mode] = useStore('store-appstate', ({ mode: val }) => val);

		const [currencies, setCurrencies] = useState([]);

		useEffect(() => {
			apiService
				.query(GET_CURRENCIES, {}, { reqAuth: true })
				.subscribe(({ data: { transactionCurrencies = [] } = {} }) => {
					setCurrencies(
						transactionCurrencies
							.filter(
								currency =>
									currency?.risk?.alert &&
									currency?.exposure?.original?.currency !==
										currency?.exposure?.display?.currency,
							)
							.map(currency => ({
								id: `currency_${currency.currency}`,
								type: 'currency',
								config: {
									name: currency.currency,
								},
								currency,
							})),
					);
				});
		}, [apiService]);

		const availableFinances = useMemo(() => {
			const options = [];
			if (hasFactoring) {
				options.push({
					value: 'factoring',
					label: 'menu.financial.factoring.title',
				});
			}

			if (hasFxModule) {
				options.push({
					value: 'currencyrisk',
					label: 'menu.financial.currencyrisk.title',
				});
			}

			if (hasCreditApplication) {
				options.push({
					value: 'credit',
					label: 'menu.financial.credit.title',
				});
			}

			return options;
		}, [hasCreditApplication, hasFactoring, hasFxModule]);
		const [size = false] = useStore(
			'store-appstate',
			({ timesliceSize }) => timesliceSize,
			true,
		);
		const [availableCategories = []] = useStore(
			'store-graph',
			state => state.availableCategories,
		);
		const availableTags = useMemo(
			() =>
				availableCategories
					.reduce((acc, category) => acc.concat(category?.tags), [])
					.map(({ _id, name }) => ({
						id: _id,
						type: 'tag',
						config: { name },
					})),
			[availableCategories],
		);
		const activateFactoring = useCallback(() => {
			dispatch(setCurrentDate(addTimeslice(new Date(), size, -1)));
			dispatch(
				addFilter({
					id: `status-UNPAID`,
					type: 'status',
					config: {
						name: `$unpaid`,
						status: 'UNPAID',
					},
				}),
			);
			dispatch(
				addFilter({
					id: `status-FORECAST`,
					type: 'status',
					config: {
						name: `$forecast`,
						status: 'FORECAST',
					},
				}),
			);

			const factoring = availableTags.find(
				({ config: { name } }) => name === '$factoring',
			);

			if (factoring) {
				dispatch(addFilter(factoring));
			}

			const customers = availableTags.find(
				({ config: { name } }) => name === '$customer',
			);

			if (customers) {
				dispatch(addFilter(customers));
			}

			dispatch(setListOpen(true));

			history.push('/factoring');
		}, [availableTags, dispatch, size, history]);

		const activateCurrencyRisk = useCallback(() => {
			dispatch(setFilters(currencies));
		}, [currencies, dispatch]);

		useEffect(() => {
			if (mode === 'credit') {
				setSelected(
					availableFinances.filter(({ value }) => value === 'credit'),
				);
			} else {
				setSelected([]);
			}
		}, [availableFinances, mode]);

		const activateCredit = useCallback(() => {
			if (mode === 'credit') {
				dispatch(setCredit(0));
			}
			dispatch(setMode(mode === 'credit' ? null : 'credit'));
		}, [mode, dispatch]);

		const onStateChange = useCallback(
			val => {
				onState(val, 'service');
			},
			[onState],
		);

		const selectService = useCallback(
			({ value }) => {
				if (value === 'factoring') {
					activateFactoring();
				} else if (value === 'currencyrisk') {
					activateCurrencyRisk();
					dispatch(
						showPrompt({
							type: 'currency.risk',
							once: false,
							action: {
								uri: '/request/currencyrisk',
							},
						}),
					);
				} else if (value === 'credit') {
					activateCredit();
				}
				onChange();
			},
			[
				activateCurrencyRisk,
				activateFactoring,
				activateCredit,
				dispatch,
				onChange,
			],
		);

		if (!availableFinances || availableFinances.length === 0) {
			return null;
		}

		return (
			<Dropdown
				iconClosed="triangleDown"
				iconOpen="triangleUp"
				itemIconSelected="check"
				options={availableFinances}
				selected={selected}
				onState={onStateChange}
				placeholder={TranslationService.get(
					'menu.financial.title',
					'Finansiera',
				)}
				className={classNames(
					className,
					'asteria-display-financial-navigation',
				)}
				type="link"
				onChange={selectService}
			/>
		);
	})``,
);

FinancialSelector.displayName = 'FinancialSelector';

FinancialSelector.Styler = {
	children: [],
};
export default FinancialSelector;
