import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Getter } from '@asteria/utils';
import { TranslationService } from '@asteria/services-language';
import Button from '@asteria/component-core/button';
import { FeatureFlag } from '@asteria/component-featureflag';
import HorizontalBar from './horizontalBar';
import HorizontalPills from './horizontalPills';
import SecurityBar from '../securityBar';
import InfoBox from './infoBox';
import AdjustableTools from '../adjustable/adjustableTools';
/* import { addTransactions } from '@asteria/services-datalayer/services/adjustable/actions'; */

const Box = styled.div``;

const Actions = styled.div``;

const Slides = styled.div`
	display: flex;
	overflow-x: none;
`;

const Slide = styled.div`
	flex-shrink: 0;
	width: 100%;
	transform-origin: center center;
	transform: scale(1);
	transition: transform 0.5s;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Indicator = styled.div``;

Box.Styler = {
	children: [
		{
			component: HorizontalBar,
			base: [Getter('bar')],
		},
		{
			component: HorizontalPills,
			base: [Getter('pills')],
		},
	],
};

const Header = styled(({ className, onClick, statistics }) => (
	<div className={classNames(className)}>
		<Button
			type="link"
			icon="down"
			className="asteria-button-total"
			onClick={onClick}
		>
			{TranslationService.get('list.header.total', 'Kategorier', {
				statistics,
			})}
		</Button>
		<FeatureFlag feature="adjustable">
			<FeatureFlag feature="adjustable-tools-categories">
				<AdjustableTools />
			</FeatureFlag>
		</FeatureFlag>
		<FeatureFlag feature="Accordion - Horizontal-carousel">
			<Actions>
				<Button
					className="asteria-button-left"
					type="primary"
					/* onClick={() => onPrev()} */
					icon="circleArrowLeft"
				/>
				<Button
					className="asteria-button-right"
					type="primary"
					/* onClick={() => onNext()} */
					icon="circleArrowRight"
				/>
			</Actions>
		</FeatureFlag>
	</div>
))`
	width: 100%;
	display: flex;
`;

const Wrapper = styled.div``;

Header.displayName = 'Header';

Header.Styler = {
	children: [
		{
			component: Button,
			base: [Getter('button')],
		},
		{
			component: SecurityBar,
			base: [Getter('security')],
		},
	],
};

const DetailedBar = styled(
	({
		className,
		locked = false,
		statistics,
		listFilter = [],
		isFuture,
		mainFilter = [],
		setListFilter,
	}) => {
		const [showHorizontal, setShowHorizontal] = useState(true);
		const [showNextPage, setShowNextPage] = useState(false);
		const [showPreviousPage, setShowPreviousPage] = useState(false);
		const [isShowInfoBox, setShowInfoBox] = useState(false);
		const toggleHorizontal = useCallback(
			() => setShowHorizontal(!showHorizontal),
			[showHorizontal],
		);

		const tags = useMemo(
			() =>
				statistics?.parts
					?.filter(
						p =>
							mainFilter.length === 0 ||
							!!mainFilter.find(m => m._id === p?.tag?.id),
					)
					.sort((a, b) => {
						if (
							a?.status === 'FORECAST' &&
							b?.status !== 'FORECAST'
						) {
							return 1;
						}
						if (
							b?.status === 'FORECAST' &&
							a?.status !== 'FORECAST'
						) {
							return -1;
						}

						if (a?.status === 'UNPAID' && b?.status !== 'UNPAID') {
							return 1;
						}
						if (b?.status === 'UNPAID' && a?.status !== 'UNPAID') {
							return -1;
						}

						if (!a?.tag?.name || a?.tag?.name === '$misc') {
							return 1;
						}
						if (!b?.tag?.name || b?.tag?.name === '$misc') {
							return -1;
						}

						return Math.abs(b.total) - Math.abs(a.total);
					}),
			[statistics, mainFilter],
		);

		return (
			<div
				className={classNames(className, {
					'asteria-state-active': showHorizontal && !locked,
					'asteria-detailed-bar-locked': locked,
				})}
			>
				<Header
					statistics={statistics}
					isFuture={isFuture}
					onClick={toggleHorizontal}
					showInfoBox={setShowInfoBox}
					isShowInfoBox={isShowInfoBox}
				/>
				<Box>
					<Wrapper>
						<HorizontalBar
							max={statistics?.total || 0}
							parts={tags}
						/>
						<FeatureFlag feature="Detailed bar - Horizontal-carousel">
							<Actions>
								<Button
									className={classNames(
										className,
										'asteria-button-left',
									)}
									type="link"
									onClick={() =>
										setShowPreviousPage(!showPreviousPage)
									}
									icon="circleArrowLeft"
								/>
								<Button
									className={classNames(
										className,
										'asteria-button-right',
									)}
									type="link"
									onClick={() =>
										setShowNextPage(!showNextPage)
									}
									icon="circleArrowRight"
								/>
							</Actions>
							<Slides>
								<Slide
									style={{
										transform: showPreviousPage
											? 'translateX(-100%)'
											: '100%',
									}}
								>
									<HorizontalPills
										max={statistics?.total || 0}
										parts={tags}
										setListFilter={setListFilter}
										listFilter={listFilter}
									/>
								</Slide>
								<Slide
									style={{
										transform: showPreviousPage
											? 'translateX(-100%)'
											: '100%',
									}}
								>
									<HorizontalPills
										max={statistics?.total || 0}
										parts={tags}
										setListFilter={setListFilter}
										listFilter={listFilter}
									/>
								</Slide>
							</Slides>
						</FeatureFlag>
						<FeatureFlag
							feature="Detailed bar - Horizontal-carousel"
							invert
						>
							<HorizontalPills
								max={statistics?.total || 0}
								parts={tags}
								setListFilter={setListFilter}
								listFilter={listFilter}
							/>
						</FeatureFlag>
						<FeatureFlag feature="Detailed bar - Horizontal-carousel: carousel indicators">
							<Indicator>
								<Button
									className={classNames(
										className,
										'asteria-button-dot',
									)}
									type="button"
									/* onClick={() => onNext()} */
								/>
								<Button
									className={classNames(
										className,
										'asteria-button-dot asteria-button-active',
									)}
									type="button"
									/* onClick={() => onNext()} */
								/>
							</Indicator>
						</FeatureFlag>
					</Wrapper>
				</Box>
			</div>
		);
	},
)`
	width: 100%;
`;

DetailedBar.displayName = 'DetailedBar';

DetailedBar.Styler = {
	typePrefix: 'asteria-detailed-bar',
	children: [
		{
			component: Header,
			base: [Getter('header')],
			children: [
				{
					component: Actions,
					base: [Getter('actions')],
					children: [
						{
							component: Button,
							base: [Getter('button')],
						},
					],
				},
			],
		},
		{
			component: InfoBox,
			base: [Getter('info')],
		},
		{
			component: Box,
			base: [Getter('box')],
		},
		{
			component: Wrapper,
			base: [Getter('wrapper')],
			children: [
				{
					component: Actions,
					base: [Getter('actions')],
					children: [
						{
							component: Button,
							base: [Getter('button')],
						},
					],
				},
				{
					component: Indicator,
					base: [Getter('indicator')],
					children: [
						{
							component: Button,
							base: [Getter('button')],
						},
					],
				},
			],
		},
	],
};
export default DetailedBar;
