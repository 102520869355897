import { TranslationService } from '@asteria/services-language';
import { Key } from '../erpSystemFlows/fortnox';
import { Key as AsteriaKey } from '../erpSystemFlows/asteria';
import { Key as VismaKey } from '../erpSystemFlows/visma';
import { Key as VismaAdministrationKey } from '../erpSystemFlows/vismaAdministration';
import { Key as WintKey } from '../erpSystemFlows/wint';
import { Key as BjornLundenKey } from '../erpSystemFlows/bjornlunden';
import { Key as HandelsbankenKey } from '../bankSystemFlows/handelsbanken';
import { Key as SEBKey } from '../bankSystemFlows/seb';
import { Key as SwedbankKey } from '../bankSystemFlows/swedbank';
import { Key as AsteriaBankKey } from '../bankSystemFlows/asteria';
import { Key as ProcountorKey } from '../erpSystemFlows/procountor';
import { Key as NordeaKey } from '../bankSystemFlows/nordea';

export default () => ({
	fortnox: {
		type: 'erp',
		key: 'erp-Fortnox',
		title: TranslationService.get('onboarding.fortnox.title', 'Fortnox'),
		value: 'erp.fortnox',
		flowStart: Key.navigationKey,
	},
	asteria: {
		type: 'erp',
		key: 'erp-Asteria',
		title: TranslationService.get('onboarding.asteria.title', 'Asteria'),
		value: 'erp.asteria',
		flowStart: AsteriaKey.navigationKey,
	},
	asteriaBank: {
		type: 'bank',
		key: 'bank-Asteria',
		title: TranslationService.get('onboarding.asteria.title', 'Asteria'),
		value: 'bank.asteria',
		flowStart: AsteriaBankKey.navigationKey,
	},
	procountor: {
		type: 'erp',
		key: 'erp-Procountor',
		title: TranslationService.get(
			'onboarding.procountor.title',
			'Procountor',
		),
		value: 'erp.procountor',
		flowStart: ProcountorKey.navigationKey,
	},
	visma: {
		type: 'erp',
		key: 'erp-Visma',
		title: TranslationService.get(
			'onboarding.visma.title',
			'Visma eEkonomi',
		),
		value: 'erp.visma',
		flowStart: VismaKey.navigationKey,
	},
	vismaAdministration: {
		type: 'erp',
		key: 'erp-VismaAdministration',
		title: TranslationService.get(
			'onboarding.visma.title',
			'Visma Administration',
		),
		value: 'erp.vismaAdministration',
		flowStart: VismaAdministrationKey.navigationKey,
	},
	wint: {
		type: 'erp',
		key: 'erp-Wint',
		title: TranslationService.get('onboarding.wint.title', 'Wint'),
		value: 'erp.wint',
		flowStart: WintKey.navigationKey,
	},
	bjornlunden: {
		type: 'erp',
		key: 'erp-Bjornlunden',
		title: TranslationService.get(
			'onboarding.bjornlunden.title',
			'Björn Lundén',
		),
		value: 'bjornlunden.wint',
		flowStart: BjornLundenKey.navigationKey,
	},
	handelsbanken: {
		type: 'bank',
		key: 'bank-Handelsbanken',
		title: TranslationService.get(
			'onboarding.handelsbanken.title',
			'Handelsbanken',
		),
		value: 'bank.handelsbanken',
		flowStart: HandelsbankenKey.navigationKey,
	},
	seb: {
		type: 'bank',
		key: 'bank-SEB',
		title: TranslationService.get('onboarding.seb.title', 'SEB'),
		value: 'bank.seb',
		flowStart: SEBKey.navigationKey,
	},
	nordea: {
		type: 'bank',
		key: 'bank-Nordea',
		title: TranslationService.get('onboarding.nordea.title', 'Nordea'),
		value: 'bank.nordea',
		flowStart: NordeaKey.navigationKey,
	},
	swedbank: {
		type: 'bank',
		key: 'bank-Swedbank',
		title: TranslationService.get('onboarding.swedbank.title', 'Swedbank'),
		value: 'bank.swedbank',
		flowStart: SwedbankKey.navigationKey,
	},
});
