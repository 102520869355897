const buildLegendIcon = style => {
	const data = `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"><path d="M 0 5 L 12 5" style="${style}"></path></svg>`;
	return `url("data:image/svg+xml;charset=utf8,${escape(data)}")`;
};

export default {
	widget: {
		style: {},
		dashboard: {
			graph: {
				style: {},
			},
			types: {
				transactionListVisible: {
					style: {},
					graph: {
						style: {},
					},
					transactions: {
						style: {},
					},
				},
				transactionListCompress: {
					transactions: {
						list: {
							list: {
								transaction: {
									info: {
										style: {
											minHeight: 'auto',
										},
										cell: {
											types: {
												description: {
													text: {
														style: {
															fontSize: '13px',
														},
													},
												},
												paymentDate: {
													text: {
														style: {
															fontSize: '13px',
														},
													},
												},
												total: {
													text: {
														style: {
															fontSize: '13px',
														},
													},
												},
												security: {
													style: {
														width: '12px',
														height: '15px',
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
		},
		features: {
			graphTabs: {
				dashboard: {
					graph: {
						'&layout-tabs': {
							style: {
								// height: 'calc(40vh - 117px)',
							},
							title: {
								style: {
									display: 'none',
								},
							},
							navigation: {
								style: {
									alignItems: 'flex-end',
								},
								button: {
									style: {
										bottom: '8px',
										top: 'auto',
									},
								},
							},
							tabs: {
								style: {
									display: 'flex',
									flex: '1',
									flexDirection: 'column',
									height: '100%',
									minHeight: '278px;',
								},
								queries: {
									'(max-height: 800px)': {
										style: {
											minHeight: '207px !important',
										},
									},
									'(max-height: 960px)': {
										style: {
											minHeight: '207px !important',
										},
									},
								},
								navigation: {
									style: {
										flexDirection: 'row',
										padding: '0',
										maxHeight: '40px',
										maxWidth: '60%',
									},
									button: {
										style: {
											// background: 'transparent',
											flex: '0',
											// paddingRight: '4px',
										},
										text: {
											style: {
												// color: '#222',
												// fontSize: '18px',
												// fontFamily: 'Swedbank Sans',
												// fontWeight: 500,
											},
										},
										icon: {
											svg: {
												style: {
													fill: '#9f8c82',
												},
											},
										},
										after: {
											style: {
												borderLeft:
													'0px solid transparent',
												borderRight:
													'0px solid transparent',
												borderTop:
													'0px solid transparent',
												height: '2px',
												width: '100%',
												bottom: '0',
											},
										},
										states: {
											active: {
												style: {
													// background: 'transparent',
												},
												text: {
													style: {
														// fontFamily:
														// 	'Swedbank Headline',
														// fontWeight: 200,
														// color: '#512b2b',
													},
												},
												after: {
													style: {
														borderLeft:
															'0px solid transparent',
														borderRight:
															'0px solid transparent',
														borderTop:
															'0px solid transparent',
														height: '0px',
														background:
															'transparent',
														width: '100%',
													},
												},
												icon: {
													svg: {
														style: {
															fill: '#EE7024',
														},
													},
												},
											},
										},
									},
								},
								content: {
									style: {
										paddingTop: '8px',
									},
								},
							},
							legends: {
								style: {
									marginBottom: '-8px',
								},
							},
							graph: {
								yaxis: {
									labels: {
										style: {
											minHeight: '100%',
										},
									},
								},
								types: {
									lines: {
										style: {
											// height: '100%',
											height: '260px',
											marginBottom: '0',
										},
										graphArea: {
											queries: {
												'(max-height: 800px)': {
													style: {
														gridTemplateRows:
															'auto 40px',
													},
												},
												'(max-height: 960px)': {
													style: {
														gridTemplateRows:
															'auto 40px',
													},
												},
											},
										},
									},
									bars: {
										style: {
											// height: '100%',
											height: '260px',
										},
									},
								},
							},
						},
					},
				},
			},
			workareas: {
				'&positioning-fixed': {
					style: {},
					dashboard: {
						style: {
							height: 'calc(100vh - 199px)',
							minHeight: 'auto',
						},
						graph: {
							style: {
								// height: 'calc(100vh - 270px)',
								height: 'calc(50% - 97px)',
								paddingTop: '0',
							},
							header: {
								timesliceselector: {
									style: {},
									queries: {
										'(max-width: 767.98px)': {
											style: {},
										},
										'(min-width: 768px)': {
											style: {
												marginTop: '16px',
											},
										},
										'(max-height: 800px)': {
											style: {
												// marginTop: '-5px',
											},
										},
										'(max-height: 960px)': {
											style: {
												// marginTop: '-5px',
											},
										},
										'(min-width: 1200px)': {
											style: {},
										},
									},
								},
								menu: {
									style: {
										minWidth: '32px',
									},
									queries: {
										'(max-width: 767.98px)': {
											style: {},
										},
										'(min-width: 768px)': {
											style: {
												marginTop: '7px',
											},
										},
										'(max-height: 800px)': {
											style: {
												// marginTop: '-5px',
											},
										},
										'(min-width: 1200px)': {
											style: {},
										},
									},
								},
							},
							title: {
								style: {},
								queries: {
									'(max-width: 767.98px)': {
										style: {
											fontSize: '19px',
											display: 'none',
										},
									},
									'(min-width: 768px)': {
										style: {
											fontSize: '19px',
											display: 'none',
										},
									},
									'(min-width: 1200px)': {
										style: {
											fontSize: '19px',
											display: 'none',
										},
									},
								},
							},
							navigation: {
								button: {
									style: {
										/* 										top: 'auto',
										bottom: '-78px', */
									},
									queries: {
										'(max-width: 767.98px)': {
											style: {},
										},
										'(min-width: 768px)': {
											style: {},
										},
										'(max-height: 800px)': {
											style: {
												/* 												top: 'auto',
												bottom: '-48px !important', */
											},
										},
										'(max-height: 960px)': {
											style: {
												/* 												top: 'auto',
												bottom: '-48px !important', */
											},
										},
										'(min-width: 990px)': {
											style: {},
										},
										'(min-width: 1281px)': {
											style: {
												/* 												bottom: '-84px', */
											},
										},
									},
								},
							},
							graph: {
								style: {
									// height: '100%',
									height: 'calc(46% - 1px)',
								},
								graphArea: {
									style: {
										height: '100%',
									},
									xaxis: {},
									linegroup: {
										style: {
											// height: 'calc(100% - 20px)',
										},
										wrapper: {
											style: {
												// height: '100%',
											},
										},
									},
									bargroup: {
										style: {
											// height: 'calc(100% - 20px)',
										},
									},
								},
								yaxis: {
									labels: {
										style: {},
										text: {
											queries: {
												'(max-height: 800px)': {
													style: {
														fontSize: '10px',
													},
												},
												'(max-height: 960px)': {
													style: {
														fontSize: '10px',
													},
												},
											},
										},
									},
								},
								types: {
									lines: {
										style: {
											// height: 'calc(100% - 97px)',
											marginBottom: '-42px',
										},
										queries: {
											'(max-height: 800px)': {
												style: {
													marginBottom: '-12px',
													gridTemplateRows: '1fr 0px',
												},
											},
											'(max-height: 960px)': {
												style: {
													marginBottom: '-12px',
													gridTemplateRows: '1fr 0px',
												},
											},
										},
									},
									bargroup: {
										style: {
											gridTemplateRows: '50% 50%',
											gridTemplateColumns: '100%',
										},
									},
									bars: {
										style: {
											// height: 'calc(100% - 97px)',
										},
									},
								},
							},
							legends: {
								style: {},
								queries: {
									'(max-height: 800px)': {
										style: {
											height: '13px',
											marginBottom: '-10px',
										},
									},
									'(max-height: 960px)': {
										style: {
											height: '13px',
											marginBottom: '-10px',
										},
									},
								},
								part: {
									queries: {
										'(max-height: 800px)': {
											style: {
												marginRight: '12px',
											},
										},
										'(max-height: 960px)': {
											style: {
												marginRight: '12px',
											},
										},
									},
									text: {
										style: {
											color: 'var(--text-color)',
											fontFamily: 'Swedbank Sans',
											fontWeight: 700,
										},
										queries: {
											'(max-height: 800px)': {
												style: {
													fontSize: '11px',
												},
											},
											'(max-height: 960px)': {
												style: {
													fontSize: '11px',
												},
											},
										},
									},
									/* 									prefix: {
										queries: {
											'(max-height: 800px)': {
												style: {
													marginRight: '4px',
													height: '8px',
													width: '8px',
													marginTop: '-2px',
												},
											},
											'(max-height: 960px)': {
												style: {},
											},
										},
									}, */
								},
							},
							controllers: {
								menu: {
									style: {},
									queries: {
										'(max-height: 800px)': {
											style: {
												height: '20px !important',
											},
										},
										'(max-height: 960px)': {
											style: {
												height: '20px !important',
											},
										},
									},
									categories: {
										style: {},
										category: {
											style: {},
											prefix: {
												style: {
													display: 'none',
												},
											},
											text: {
												style: {},
												queries: {
													'(max-height: 800px)': {
														style: {
															fontSize: '11px',
														},
													},
													'(max-height: 960px)': {
														style: {
															fontSize: '11px',
														},
													},
												},
											},
										},
									},
									tags: {
										style: {},
										queries: {
											'(max-height: 800px)': {
												style: {},
											},
										},
										tag: {
											style: {},
											/* prefix: {
												style: {},
												queries: {
													'(max-height: 800px)': {
														style: {
															marginRight: '4px',
															height: '8px',
															width: '8px',
															marginTop: '-2px',
														},
													},
													'(max-height: 960px)': {
														style: {
															marginRight: '4px',
															height: '8px',
															width: '8px',
															marginTop: '-2px',
														},
													},
												},
											}, */
											text: {
												style: {},
												queries: {
													'(max-height: 800px)': {
														style: {
															fontSize: '11px',
														},
													},
													'(max-height: 960px)': {
														style: {
															fontSize: '11px',
														},
													},
												},
											},
										},
									},
								},
							},
							tabs: {
								style: {
									height: '100%',
								},
								navigation: {
									style: {
										flexDirection: 'row',
										padding: '0',
										maxHeight: '40px',
										maxWidth: '60%',
									},
									button: {
										style: {
											// background: 'transparent',
											flex: '0',
											// paddingRight: '4px',
										},
										text: {
											style: {
												// color: '#222',
												// fontSize: '18px',
												// fontFamily: 'Swedbank Sans',
												// fontWeight: 500,
											},
										},
										icon: {
											svg: {
												style: {
													fill: '#9f8c82',
												},
											},
										},
										after: {
											style: {
												borderLeft:
													'0px solid transparent',
												borderRight:
													'0px solid transparent',
												borderTop:
													'0px solid transparent',
												height: '2px',
												width: '100%',
												bottom: '0',
											},
										},
										states: {
											active: {
												style: {
													// background: 'transparent',
												},
												text: {
													style: {
														// fontFamily:
														// 	'Swedbank Headline',
														// fontWeight: 200,
														// color: '#512b2b',
													},
												},
												after: {
													style: {
														borderLeft:
															'0px solid transparent',
														borderRight:
															'0px solid transparent',
														borderTop:
															'0px solid transparent',
														height: '0px',
														background:
															'transparent',
														width: '100%',
													},
												},
												icon: {
													svg: {
														style: {
															fill: '#EE7024',
														},
													},
												},
											},
										},
									},
								},
								content: {
									style: {
										/* paddingTop: '50px', */
										// height: 'calc(100% - 67px)',
										height: 'calc(100% - 57px)',
										paddingTop: '8px',
									},
									graph: {
										yaxis: {
											labels: {
												style: {},
												text: {
													queries: {
														'(max-height: 800px)': {
															style: {
																fontSize:
																	'14px',
															},
														},
														'(max-height: 960px)': {
															style: {
																fontSize:
																	'14px',
															},
														},
													},
												},
											},
										},
									},
								},
							},
							types: {
								hasFilter: {
									types: {
										grouped: {
											graph: {
												types: {
													lines: {
														style: {},
													},
												},
											},
											tabs: {
												content: {
													style: {
														/* paddingTop: '50px', */
													},
												},
											},
										},
									},
								},
								stacked: {
									style: {},
									queries: {
										'(max-width: 767.98px)': {
											style: {
												height: 'calc(40vh - 101px)',
											},
										},
										'(min-width: 768px)': {
											style: {
												height: 'calc(40vh - 101px)',
											},
										},
										'(min-width: 990px)': {
											style: {
												height: 'calc(40vh - 101px)',
											},
										},
										'(min-width: 1200px)': {
											style: {
												height: 'calc(40vh - 101px)',
											},
										},
									},
									legends: {
										style: {
											marginTop: '1px',
										},
										queries: {
											'(max-height: 800px)': {
												style: {},
											},
										},
										part: {
											queries: {
												'(max-height: 800px)': {
													style: {
														marginRight: '12px',
													},
												},
												'(max-height: 960px)': {
													style: {
														marginRight: '12px',
													},
												},
											},
											text: {
												style: {
													color: 'var(--text-color)',
													fontFamily: 'Swedbank Sans',
													fontWeight: 700,
												},
												queries: {
													'(max-height: 800px)': {
														style: {
															fontSize: '14px',
														},
													},
													'(max-height: 960px)': {
														style: {
															fontSize: '14px',
														},
													},
												},
											},
											prefix: {
												queries: {
													'(max-height: 800px)': {
														style: {
															marginRight: '8px',
															height: '12px',
															width: '12px',
															marginTop: '0px',
															backgroundPosition:
																'0 0',
															/* 															backgroundSize:
																'auto', */
														},
													},
													'(min-height: 800px)': {
														style: {
															backgroundPosition:
																'0px 0px',
														},
													},
												},
											},
										},
									},
									controllers: {
										menu: {
											style: {},
											queries: {
												'(max-height: 800px)': {
													style: {},
												},
											},
											categories: {
												style: {},
												category: {
													style: {},
													prefix: {
														style: {
															display: 'none',
														},
													},
													text: {
														style: {},
														queries: {
															'(max-height: 800px)': {
																style: {
																	fontSize:
																		'14px',
																},
															},
															'(max-height: 960px)': {
																style: {
																	fontSize:
																		'14px',
																},
															},
														},
													},
												},
											},
											tags: {
												style: {},
												tag: {
													style: {},
													prefix: {
														style: {},
														queries: {
															'(max-height: 800px)': {
																style: {
																	marginRight:
																		'8px',
																	height:
																		'12px',
																	width:
																		'12px',
																	marginTop:
																		'0px',
																},
															},
															'(max-height: 960px)': {
																style: {
																	marginRight:
																		'8px',
																	height:
																		'12px',
																	width:
																		'12px',
																	marginTop:
																		'0px',
																},
															},
														},
													},
													text: {
														style: {},
														queries: {
															'(max-height: 800px)': {
																style: {
																	fontSize:
																		'14px',
																},
															},
															'(max-height: 960px)': {
																style: {
																	fontSize:
																		'14px',
																},
															},
														},
													},
												},
											},
										},
									},
									graph: {
										style: {
											height: '100%',
											marginBottom: '0',
										},
										yaxis: {
											labels: {
												style: {},
												text: {
													queries: {
														'(max-height: 800px)': {
															style: {
																fontSize:
																	'14px',
															},
														},
														'(max-height: 960px)': {
															style: {
																fontSize:
																	'14px',
															},
														},
													},
												},
											},
										},
										types: {
											lines: {
												style: {
													// height: 'calc(100% - 97px)',
												},
												queries: {
													'(max-height: 800px)': {
														style: {
															gridTemplateRows:
																'1fr 40px',
															'-ms-grid-rows':
																'calc(100% - 40px) 40px',
														},
													},
													'(max-height: 960px)': {
														style: {
															gridTemplateRows:
																'1fr 40px',
															'-ms-grid-rows':
																'calc(100% - 40px) 40px',
														},
													},
												},
											},
										},
										graphArea: {
											bargroup: {
												style: {
													gridTemplateRows:
														'calc(50% - 1px) calc(50% - 1px)',
													gridRowGap: '3px',
													minWidth: '100px',
													'-ms-grid-rows':
														'calc(50% - 1px) calc(50% - 1px)',
												},
												bar: {
													indicator: {
														style: {
															display: 'none',
														},
													},
												},
											},
										},
									},
								},
							},
							'&layout-tabs': {
								style: {
									// height: 'calc(40vh - 117px)',
								},
								title: {
									style: {
										display: 'none',
									},
								},
								navigation: {
									style: {
										alignItems: 'flex-end',
									},
									button: {
										style: {
											bottom: '45px',
											top: 'auto',
										},
										queries: {
											'(max-width: 767.98px)': {
												style: {},
											},
											'(min-width: 768px)': {
												style: {},
											},
											'(max-height: 800px)': {
												style: {
													/* 													top: 'auto',
													bottom: '-48px !important', */
												},
											},
											'(max-height: 960px)': {
												style: {
													/* 													top: 'auto',
													bottom: '-48px !important', */
												},
											},
											'(min-width: 990px)': {
												style: {},
											},
											'(min-width: 1281px)': {
												/* 											style: {
													bottom: '-85px',
												}, */
											},
										},
									},
								},
								legends: {
									style: {
										marginBottom: '-3px',
										height: '25px',
									},
									queries: {
										'(max-height: 800px)': {
											style: {},
										},
									},
									part: {
										queries: {
											'(max-height: 800px)': {
												style: {
													marginRight: '12px',
												},
											},
											'(max-height: 960px)': {
												style: {
													marginRight: '12px',
												},
											},
										},
										text: {
											style: {
												color: 'var(--text-color)',
												fontFamily: 'Swedbank Sans',
												fontWeight: 700,
											},
											queries: {
												'(max-height: 800px)': {
													style: {
														fontSize: '14px',
													},
												},
												'(max-height: 960px)': {
													style: {
														fontSize: '14px',
													},
												},
											},
										},
										prefix: {
											queries: {
												'(max-height: 800px)': {
													style: {
														marginRight: '8px',
													},
												},
												'(max-height: 960px)': {
													style: {
														marginRight: '8px',
													},
												},
											},
										},
									},
								},
								controllers: {
									menu: {
										style: {},
										queries: {
											'(max-height: 800px)': {
												style: {
													height: '32px !important',
												},
											},
											'(max-height: 960px)': {
												style: {
													height: '32px !important',
												},
											},
										},
										categories: {
											style: {},
											category: {
												style: {},
												prefix: {
													style: {
														display: 'none',
													},
												},
												text: {
													style: {},
													queries: {
														'(max-height: 800px)': {
															style: {
																fontSize:
																	'14px',
															},
														},
														'(max-height: 960px)': {
															style: {
																fontSize:
																	'14px',
															},
														},
													},
												},
											},
										},
										tags: {
											style: {
												marginTop: '-15px !important',
												paddingTop: '0',
											},
											tag: {
												style: {},
												prefix: {
													style: {},
													queries: {
														'(max-height: 800px)': {
															style: {
																marginRight:
																	'8px',
																height: '12px',
																width: '12px',
																marginTop:
																	'0px',
															},
														},
														'(max-height: 960px)': {
															style: {
																marginRight:
																	'8px',
																height: '12px',
																width: '12px',
																marginTop:
																	'0px',
															},
														},
													},
												},
												text: {
													style: {},
													queries: {
														'(max-height: 800px)': {
															style: {
																fontSize:
																	'14px',
															},
														},
														'(max-height: 960px)': {
															style: {
																fontSize:
																	'14px',
															},
														},
													},
												},
											},
										},
									},
								},
								tabs: {
									style: {
										display: 'flex',
										flex: '1',
										flexDirection: 'column',
										height: '100%',
									},
									navigation: {
										style: {
											flexDirection: 'row',
											padding: '0',
											maxHeight: '40px',
											maxWidth: '60%',
										},
										button: {
											style: {
												// background: 'transparent',
												flex: '0',
												// paddingRight: '4px',
											},
											text: {
												style: {
													// color: '#222',
													// fontSize: '18px',
													// fontFamily: 'Swedbank Sans',
													// fontWeight: 500,
												},
											},
											icon: {
												svg: {
													style: {
														fill: '#9f8c82',
													},
												},
											},
											after: {
												style: {
													borderLeft:
														'0px solid transparent',
													borderRight:
														'0px solid transparent',
													borderTop:
														'0px solid transparent',
													height: '2px',
													width: '100%',
													bottom: '0',
												},
											},
											states: {
												active: {
													style: {
														// background: 'transparent',
													},
													text: {
														style: {
															// fontFamily:
															// 	'Swedbank Headline',
															// fontWeight: 200,
															// color: '#512b2b',
														},
													},
													after: {
														style: {
															borderLeft:
																'0px solid transparent',
															borderRight:
																'0px solid transparent',
															borderTop:
																'0px solid transparent',
															height: '0px',
															background:
																'transparent',
															width: '100%',
														},
													},
													icon: {
														svg: {
															style: {
																fill: '#EE7024',
															},
														},
													},
												},
											},
										},
									},
									content: {
										style: {
											paddingTop: '8px',
										},
									},
								},
								graph: {
									yaxis: {
										labels: {
											style: {
												minHeight: '100%',
											},
										},
									},
									types: {
										lines: {
											style: {
												height: 'calc(100% - 1px)',
											},
										},
										bars: {
											style: {
												height: '100%',
											},
										},
									},
								},
							},
						},
						transactions: {
							types: {
								open: {
									style: {
										flex: '1',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '16px',
									},
									list: {
										style: {
											display: 'flex',
											flex: '1',
											flexDirection: 'column',
											position: 'relative',
										},
										wrapper: {
											style: {
												overflowY: 'auto',
												display: 'flex',
												position: 'absolute',
												left: '0',
												right: '0',
												top: '38px',
												bottom: '0',
												padding: '0 0 0',
												flexDirection: 'column',
												flex: '1',
												flexWrap: 'nowrap',
											},
											list: {},
										},
									},
								},
							},
						},
						actionbar: {
							style: {},
						},
						types: {
							hasFilter: {
								graph: {
									graph: {
										types: {
											lines: {
												style: {},
											},
										},
									},
								},
								controllers: {
									menu: {
										style: {},
									},
								},
							},
							// transactionListVisibleBar: {
							// 	style: {},
							// 	graph: {
							// 		title: {
							// 			style: {},
							// 		},
							// 		tabs: {
							// 			style: {},
							// 			navigation: {
							// 				style: {
							// 					display: 'flex',
							// 				},
							// 				button: {},
							// 			},
							// 			content: {
							// 				style: {
							// 					height: 'calc(100%)',
							// 					paddingTop: '20px',
							// 				},
							// 				states: {
							// 					active: {
							// 						title: {
							// 							style: {
							// 								opacity: '1',
							// 								height: 'inherit',
							// 								marginTop: '0',
							// 							},
							// 						},
							// 						graph: {
							// 							types: {
							// 								lines: {
							// 									style: {
							// 										height:
							// 											'calc(50%)',
							// 										// height: '100%',
							// 									},
							// 								},
							// 								bars: {
							// 									style: {
							// 										// opacity: '1',
							// 										// height:
							// 										// 	'inherit',
							// 										// padding: '0',
							// 										// margin: '0',
							// 									},
							// 								},
							// 							},
							// 						},
							// 						controllers: {
							// 							menu: {
							// 								style: {},
							// 								tags: {
							// 									style: {
							// 										marginTop:
							// 											'-14px',
							// 										paddingTop: '0',
							// 									},
							// 								},
							// 							},
							// 						},
							// 					},
							// 				},
							// 			},
							// 		},
							// 		graph: {
							// 			yaxis: {
							// 				labels: {
							// 					style: {
							// 						minHeight: '100%',
							// 					},
							// 				},
							// 			},
							// 		},
							// 		controllers: {
							// 			menu: {
							// 				style: {},
							// 			},
							// 		},
							// 		types: {
							// 			grouped: {
							// 				style: {},
							// 				header: {
							// 					title: {
							// 						style: {},
							// 					},
							// 				},
							// 				legends: {
							// 					style: {
							// 						opacity: '0',
							// 						height: '0',
							// 						padding: '0',
							// 						margin: '0',
							// 					},
							// 				},
							// 				title: {
							// 					style: {},
							// 				},
							// 				graph: {
							// 					style: {},
							// 					types: {
							// 						lines: {
							// 							style: {},
							// 						},
							// 					},
							// 				},
							// 			},
							// 		},
							// 	},
							// 	transactions: {
							// 		style: {},
							// 	},
							// 	actionbar: {
							// 		style: {},
							// 	},
							// },
							// transactionListVisibleBadge: {
							// 	graph: {
							// 		title: {
							// 			style: {},
							// 		},
							// 		controllers: {
							// 			menu: {
							// 				style: {},
							// 			},
							// 		},
							// 		graph: {
							// 			yaxis: {
							// 				labels: {
							// 					style: {
							// 						minHeight: '100%',
							// 					},
							// 				},
							// 			},
							// 		},
							// 		types: {
							// 			grouped: {
							// 				graph: {
							// 					types: {
							// 						lines: {
							// 							style: {
							// 								height: 'calc(50%)',
							// 							},
							// 						},
							// 						bars: {
							// 							style: {
							// 								height:
							// 									'calc(100% - 20px)',
							// 							},
							// 						},
							// 					},
							// 				},
							// 				tabs: {
							// 					content: {
							// 						style: {
							// 							height: 'calc(100% - 80px)',
							// 							paddingTop: '20px',
							// 						},
							// 					},
							// 				},
							// 			},
							// 		},
							// 	},
							// },
							// transactionListVisibleInsightUnpaid: {
							// 	graph: {
							// 		style: {
							// 			height: 'calc(50% - 97px)',
							// 			paddingTop: '0',
							// 		},
							// 		title: {
							// 			style: {
							// 				display: 'none',
							// 			},
							// 		},
							// 		controllers: {
							// 			menu: {
							// 				style: {},
							// 			},
							// 		},
							// 		graph: {
							// 			yaxis: {
							// 				labels: {
							// 					style: {
							// 						minHeight: '100%',
							// 					},
							// 				},
							// 			},
							// 		},
							// 		types: {
							// 			grouped: {
							// 				graph: {
							// 					types: {
							// 						lines: {
							// 							style: {
							// 								height: 'calc(50%)',
							// 							},
							// 						},
							// 						bars: {
							// 							style: {
							// 								// height:
							// 								// 	'calc(100% - 20px)',
							// 							},
							// 						},
							// 					},
							// 				},
							// 				tabs: {
							// 					content: {
							// 						style: {
							// 							height: 'calc(100% - 80px)',
							// 							paddingTop: '20px',
							// 						},
							// 					},
							// 				},
							// 			},
							// 		},
							// 	},
							// },
							// transactionListVisibleInsightOverdue: {
							// 	graph: {
							// 		style: {
							// 			height: 'calc(50% - 97px)',
							// 		},
							// 		title: {
							// 			style: {
							// 				display: 'none',
							// 			},
							// 		},
							// 		controllers: {
							// 			menu: {
							// 				style: {},
							// 			},
							// 		},
							// 		graph: {
							// 			yaxis: {
							// 				labels: {
							// 					style: {
							// 						minHeight: '100%',
							// 					},
							// 				},
							// 			},
							// 		},
							// 		types: {
							// 			grouped: {
							// 				graph: {
							// 					types: {
							// 						lines: {
							// 							style: {
							// 								height:
							// 									'calc(100% - 75px)',
							// 							},
							// 						},
							// 						bars: {
							// 							style: {
							// 								height:
							// 									'calc(100% - 20px)',
							// 							},
							// 						},
							// 					},
							// 				},
							// 				tabs: {
							// 					content: {
							// 						style: {
							// 							height: 'calc(100% - 80px)',
							// 							paddingTop: '20px',
							// 						},
							// 					},
							// 				},
							// 			},
							// 		},
							// 	},
							// },
							// transactionListVisible: {
							// 	style: {
							// 		height: 'calc(100vh - 199px)',
							// 		minHeight: 'auto',
							// 	},
							// 	graph: {
							// 		style: {
							// 			height: 'calc(50% - 97px)',
							// 		},
							// 		header: {
							// 			title: {
							// 				style: {},
							// 			},
							// 		},
							// 		title: {
							// 			style: {
							// 				display: 'none',
							// 			},
							// 		},
							// 		graph: {
							// 			yaxis: {
							// 				labels: {
							// 					style: {
							// 						minHeight: '100%',
							// 					},
							// 				},
							// 			},
							// 			types: {
							// 				lines: {
							// 					style: {
							// 						height: 'calc(50%)',
							// 						// height: '100%',
							// 					},
							// 				},
							// 				bars: {
							// 					style: {
							// 						height: 'calc(50%)',
							// 						// height: '100%',
							// 					},
							// 				},
							// 			},
							// 		},
							// 		controllers: {
							// 			menu: {
							// 				style: {},
							// 			},
							// 		},
							// 		tabs: {
							// 			content: {
							// 				style: {
							// 					height: 'calc(100% - 80px)',
							// 					paddingTop: '20px',
							// 				},
							// 			},
							// 		},
							// 	},
							// 	transactions: {
							// 		types: {
							// 			open: {
							// 				style: {
							// 					flex: '1',
							// 					display: 'flex',
							// 					flexDirection: 'column',
							// 					paddingBottom: '16px',
							// 				},
							// 				list: {
							// 					style: {
							// 						display: 'flex',
							// 						flex: '1',
							// 						flexDirection: 'column',
							// 						position: 'relative',
							// 					},
							// 					wrapper: {
							// 						style: {
							// 							overflowY: 'auto',
							// 							display: 'flex',
							// 							position: 'absolute',
							// 							left: '0',
							// 							right: '0',
							// 							top: '38px',
							// 							bottom: '0',
							// 							padding: '0 0 0',
							// 							flexDirection: 'column',
							// 							flex: '1',
							// 							flexWrap: 'nowrap',
							// 						},
							// 						list: {},
							// 					},
							// 				},
							// 			},
							// 		},
							// 	},
							// },
							// transactionListVisibleAccount: {
							// 	graph: {
							// 		style: {
							// 			height: 'calc(50% - 97px)',
							// 		},
							// 		header: {
							// 			title: {
							// 				style: {},
							// 			},
							// 		},
							// 		title: {
							// 			style: {
							// 				display: 'none',
							// 			},
							// 		},
							// 		tabs: {
							// 			style: {},
							// 			navigation: {
							// 				style: {
							// 					display: 'flex',
							// 				},
							// 				button: {},
							// 			},
							// 			content: {
							// 				style: {
							// 					height: 'calc(100% - 80px)',
							// 					paddingTop: '20px',
							// 				},
							// 				types: {
							// 					account: {},
							// 				},
							// 			},
							// 		},
							// 		legends: {
							// 			style: {
							// 				marginBottom: '-8px',
							// 			},
							// 		},
							// 		controllers: {
							// 			menu: {
							// 				style: {},
							// 			},
							// 		},
							// 		graph: {
							// 			yaxis: {
							// 				labels: {
							// 					style: {
							// 						minHeight: '100%',
							// 					},
							// 				},
							// 			},
							// 		},
							// 		types: {
							// 			grouped: {
							// 				graph: {
							// 					types: {
							// 						lines: {
							// 							style: {
							// 								// height:
							// 								// 	'calc(100% - 50px)',
							// 							},
							// 						},
							// 						bars: {
							// 							style: {
							// 								// opacity: '0',
							// 								// height: '0',
							// 								// padding: '0',
							// 								// margin: '0',
							// 							},
							// 						},
							// 					},
							// 				},
							// 			},
							// 		},
							// 	},
							// },
						},
					},
					features: {
						search: {
							dashboard: {
								graph: {
									controllers: {
										menu: {
											tags: {
												style: {
													marginTop: '-14px',
													paddingTop: '0',
												},
											},
										},
									},
								},
							},
						},
						tabsAsIcons: {
							dashboard: {
								graph: {
									tabs: {
										style: {
											height: '100%',
										},
										navigation: {
											style: {
												maxHeight: '40px',
												maxWidth: 'max-content',
												width: 'auto',
												position: 'absolute',
												right: '240px',
												top: '-16px',
											},
											button: {
												style: {},
												icon: {
													svg: {
														style: {},
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
			swap: {
				dashboard: {
					style: {},
					graph: {
						style: {},
						title: {
							style: {},
							queries: {
								'(max-width: 767.98px)': {
									style: {},
								},
								'(min-width: 768px)': {
									style: {},
								},
								'(min-width: 1200px)': {
									style: {
										/* marginTop: '39px', */
										marginTop: '0',
									},
								},
							},
						},
						graph: {
							style: {},
							types: {
								lines: {
									style: {},
								},
								bars: {
									style: {},
								},
							},
						},
					},
					transactions: {
						style: {},
					},
					controllers: {
						menu: {
							style: {},
						},
					},
					actionbar: {
						style: {},
					},
					types: {
						hasFilter: {
							graph: {
								graph: {
									types: {
										lines: {
											style: {
												opacity: '0',
												height: '0',
												padding: '0',
												margin: '0',
											},
										},
									},
								},
							},
							controllers: {
								menu: {
									style: {
										opacity: '0',
										height: '0',
										padding: '0',
										margin: '0',
									},
								},
							},
						},
						transactionListVisibleBar: {
							style: {},
							graph: {
								style: {},
								header: {
									title: {
										style: {},
									},
								},
								legends: {
									style: {
										opacity: '0',
										height: '0',
										padding: '0',
										margin: '0',
									},
								},
								title: {
									style: {
										marginTop: '-38px',
									},
								},
								graph: {
									style: {},
									types: {
										lines: {
											style: {
												/* 												opacity: '0',
												height: '0',
												padding: '0',
												margin: '0', */
											},
										},
									},
								},
							},
							controllers: {
								menu: {
									style: {
										opacity: '0',
										height: '0',
										padding: '0',
										margin: '0',
									},
								},
							},
							transactions: {
								style: {},
							},
							actionbar: {
								style: {},
							},
						},
						transactionListVisibleBadge: {
							graph: {
								graph: {
									types: {
										lines: {
											style: {
												opacity: '0',
												height: '0',
												padding: '0',
												margin: '0',
											},
										},
									},
								},
							},
							controllers: {
								menu: {
									style: {
										opacity: '0',
										height: '0',
										padding: '0',
										margin: '0',
									},
								},
							},
						},
						transactionListVisibleInsightUnpaid: {
							graph: {
								graph: {
									types: {
										lines: {
											style: {
												opacity: '0',
												height: '0',
												padding: '0',
												margin: '0',
											},
										},
									},
								},
							},
							controllers: {
								menu: {
									style: {
										opacity: '0',
										height: '0',
										padding: '0',
										margin: '0',
									},
								},
							},
						},
						transactionListVisibleInsightOverdue: {
							graph: {
								graph: {
									types: {
										lines: {
											style: {
												opacity: '0',
												height: '0',
												padding: '0',
												margin: '0',
											},
										},
									},
								},
							},
							controllers: {
								menu: {
									style: {
										opacity: '0',
										height: '0',
										padding: '0',
										margin: '0',
									},
								},
							},
						},
						transactionListVisibleAccount: {
							graph: {
								header: {
									title: {
										style: {},
									},
								},
								title: {
									style: {},
								},
								graph: {
									types: {
										bars: {
											style: {
												opacity: '0',
												height: '0',
												padding: '0',
												margin: '0',
											},
										},
									},
								},
								controllers: {
									menu: {
										style: {
											opacity: '0',
											height: '0',
											padding: '0',
											margin: '0',
										},
									},
								},
							},
							controllers: {
								menu: {
									style: {
										opacity: '0',
										height: '0',
										padding: '0',
										margin: '0',
									},
								},
							},
						},
						transactionListVisible: {
							style: {},
							graph: {
								style: {},
								title: {
									style: {},
								},
								graph: {
									style: {},
									graphArea: {
										style: {},
									},
									types: {
										bars: {
											style: {},
										},
									},
								},
								controllers: {
									menu: {
										style: {},
									},
								},

								types: {
									lines: {
										style: {},
										graphArea: {
											style: {},
											linegroup: {
												style: {},
												wrapper: {
													style: {},
												},
											},
										},
									},
								},
							},
							transactions: {
								style: {},
								list: {
									style: {},
								},
							},
						},
					},
				},
			},
			divided: {
				dashboard: {
					style: {
						// // display: 'flex',
						// // flexDirection: 'row',
						// // flexWrap: 'wrap',
						// // justifyContent: 'space-between',
						// display: 'grid',
						// gridTemplateAreas: "'graph list' 'graph insights'",
						// gridTemplateColumns: '50% 50% 50%',
						// // gridTemplateRows: '1fr 60px 1fr',
						// gridColumnGap: '20px',
						// height: '100%',
						// minHeight: '100%',
					},
					header: {
						style: {
							gridRowStart: '1',
							gridColumnStart: '1',
							gridColumnEnd: '4',
						},
					},
					graph: {
						style: {
							// display: 'flex',
							// flexDirection: 'column',
							// overflow: 'hidden',
							// width: '66%',
							// flex: '0 0 100%',
							// order: '1',
							// boxSizing: 'border-box',
							gridRowStart: '2',
							gridColumnStart: '1',
						},
						header: {
							style: {
								gridRowStart: '1',
								gridColumnStart: '1',
								gridColumnEnd: '4',
							},
						},
						title: {
							style: {
								display: 'block !important',
							},
						},
						tabs: {
							style: {},
							navigation: {
								style: {
									flexDirection: 'row',
									padding: '0',
									maxHeight: '40px',
									maxWidth: '60%',
								},
								button: {
									style: {
										// background: 'transparent',
										flex: '0',
										// paddingRight: '4px',
									},
									text: {
										style: {
											// color: '#222',
											// fontSize: '18px',
											// fontFamily: 'Swedbank Sans',
											// fontWeight: 500,
										},
									},
									icon: {
										svg: {
											style: {
												fill: '#9f8c82',
											},
										},
									},
									after: {
										style: {
											borderLeft: '0px solid transparent',
											borderRight:
												'0px solid transparent',
											borderTop: '0px solid transparent',
											height: '2px',
											width: '100%',
											bottom: '0',
										},
									},
									states: {
										active: {
											style: {
												// background: 'transparent',
											},
											text: {
												style: {
													// fontFamily:
													// 	'Swedbank Headline',
													// fontWeight: 200,
													// color: '#512b2b',
												},
											},
											after: {
												style: {
													borderLeft:
														'0px solid transparent',
													borderRight:
														'0px solid transparent',
													borderTop:
														'0px solid transparent',
													height: '0px',
													background: 'transparent',
													width: '100%',
												},
											},
											icon: {
												svg: {
													style: {
														fill: '#EE7024',
													},
												},
											},
										},
									},
								},
							},
							content: {
								style: {
									paddingTop: '20px',
								},
							},
						},
					},
					transactions: {
						style: {
							// display: 'flex',
							// overflow: 'hidden',
							// flexDirection: 'column',
							// width: '33%',
							// order: '2',
							// boxSizing: 'border-box',
							gridRowStart: '2',
							gridColumnStart: '3',
						},
						list: {
							style: {},
						},
					},
					insights: {
						style: {
							// display: 'flex',
							// width: '33%',
							// order: '3',
							// boxSizing: 'border-box',
							gridArea: 'insights',
						},
					},
					actionbar: {
						style: {},
					},
					types: {
						hasFilter: {
							style: {},
							graph: {
								style: {},
							},
							transactions: {
								style: {},
								list: {
									style: {},
								},
							},
							insights: {
								style: {
									background: 'red',
								},
							},
						},
						transactionListVisibleBar: {
							style: {},
							graph: {
								style: {
									height: 'calc(100vh - 370px)',
								},
								types: {
									grouped: {
										graph: {
											types: {
												lines: {
													style: {
														height: '50%',
													},
													linegroup: {
														style: {
															height:
																'calc(100% - 20px)',
														},
													},
												},
												bars: {
													style: {
														height: '50%',
													},
												},
											},
										},
									},
								},
							},
							transactions: {
								style: {},
								list: {
									style: {},
								},
							},
							insights: {
								style: {
									background: 'red',
								},
							},
							actionbar: {
								style: {},
							},
						},
						transactionListVisibleBadge: {
							style: {},
							graph: {
								style: {
									height: 'calc(100vh - 370px)',
								},
								types: {
									grouped: {
										graph: {
											types: {
												lines: {
													style: {
														height: '50%',
													},
													linegroup: {
														style: {
															height:
																'calc(100% - 20px)',
														},
													},
												},
												bars: {
													style: {
														height: '50%',
													},
												},
											},
										},
									},
								},
							},
							transactions: {
								style: {},
								list: {
									style: {},
								},
							},
							insights: {
								style: {
									background: 'red',
								},
							},
						},
						transactionListVisibleInsightUnpaid: {
							style: {},
							graph: {
								style: {
									height: 'calc(100vh - 370px)',
								},
								types: {
									grouped: {
										graph: {
											types: {
												lines: {
													style: {
														height: '50%',
													},
													linegroup: {
														style: {
															height:
																'calc(100% - 20px)',
														},
													},
												},
												bars: {
													style: {
														height: '50%',
													},
												},
											},
										},
									},
								},
							},
							transactions: {
								style: {},
								list: {
									style: {},
								},
							},
							insights: {
								style: {
									background: 'red',
								},
							},
						},
						transactionListVisibleInsightOverdue: {
							style: {},
							graph: {
								style: {
									height: 'calc(100vh - 370px)',
								},
								types: {
									grouped: {
										graph: {
											types: {
												lines: {
													style: {
														height: '50%',
													},
													linegroup: {
														style: {
															height:
																'calc(100% - 20px)',
														},
													},
												},
												bars: {
													style: {
														height: '50%',
													},
												},
											},
										},
									},
								},
							},
							transactions: {
								style: {},
								list: {
									style: {},
								},
							},
							insights: {
								style: {
									background: 'red',
								},
							},
						},
						transactionListVisibleAccount: {
							graph: {
								style: {
									height: 'calc(100vh - 370px)',
								},
								types: {
									grouped: {
										graph: {
											types: {
												lines: {
													style: {
														height: '50%',
													},
													linegroup: {
														style: {
															height:
																'calc(100% - 20px)',
														},
													},
												},
												bars: {
													style: {
														height: '50%',
													},
												},
											},
										},
									},
								},
							},
						},
						transactionListVisible: {
							style: {
								gridTemplateAreas: "'graph list' 'graph list'",
							},
							graph: {
								style: {
									height: 'calc(100vh - 370px)',
								},
								types: {
									grouped: {
										graph: {
											types: {
												lines: {
													style: {
														height: '50%',
													},
													linegroup: {
														style: {
															height:
																'calc(100% - 20px)',
														},
													},
												},
												bars: {
													style: {
														height: '50%',
													},
												},
											},
										},
									},
								},
							},
							transactions: {
								style: {},
								list: {
									style: {},
								},
							},
							insights: {
								style: {
									background: 'red',
								},
							},
						},
					},
				},
			},
			'adjustable-onboarding': {
				dashboard: {
					graph: {
						graph: {
							graphArea: {
								bargroup: {
									types: {
										afterBecon: {
											bar: {
												base: {
													types: {
														deposit: {
															style: {
																background:
																	'rgb(214, 214, 214)',
															},
														},
														withdraw: {
															style: {
																background:
																	'rgb(183, 183, 183)',
															},
														},
														history: {
															style: {
																display: 'none',
															},
														},
													},
													part: {
														style: {
															display: 'none',
														},
													},
													badge: {
														style: {
															display: 'none',
														},
													},
												},
											},
										},
									},
								},
								linegroup: {
									types: {
										becon: {
											lineinfo: {
												dot: {
													style: {
														backgroundColor:
															'rgba(255, 121, 63, 1)',
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
			search: {
				dashboard: {
					style: {},
					graph: {
						style: {},
						header: {
							timesliceselector: {
								style: {},
								queries: {
									'(max-width: 767.98px)': {
										style: {},
									},
									'(min-width: 768px)': {
										style: {
											marginTop: '0px',
										},
									},
									'(min-width: 1200px)': {
										style: {
											// marginTop: '16px',
										},
									},
								},
							},
							menu: {
								style: {
									minWidth: '32px',
								},
								queries: {
									'(max-width: 767.98px)': {
										style: {},
									},
									'(min-width: 768px)': {
										style: {
											marginTop: '0',
										},
									},
									'(min-width: 1200px)': {
										style: {
											marginTop: '8px',
										},
									},
								},
							},
						},
						controllers: {
							style: {},
							menu: {
								style: {},
								tags: {
									style: {
										marginTop: '-12px',
									},
								},
							},
						},
					},
				},
			},
			asteria: {
				dashboard: {
					style: {
						WebkitFontSmoothing: 'antialiased',
						MozOsxFontSmoothing: 'grayscale',
						marginTop: '-100px',
						zIndex: '3',
						position: 'relative',
						background: '#fff',
					},
					graph: {
						header: {
							style: {
								marginBottom: '-40px',
							},
							timesliceselector: {
								style: {
									marginRight: '16px',
									marginTop: '11px',
								},
								time: {
									style: {
										marginRight: '16px',
									},
									text: {
										style: {
											color: '#B1B1B1',
											fontFamily:
												'Open Sans, arial, sans-serif',
											fontSize: '12px',
										},
									},
									states: {
										hover: {
											text: {
												style: {
													textDecoration: 'none',
												},
											},
										},
										active: {
											text: {
												style: {
													textDecoration: 'none',
													color: '#4C5362',
												},
											},
										},
									},
								},
							},
							menu: {
								style: {
									minHeight: '0',
									background: 'transparent',
									right: '0px',
									alignSelf: 'flex-start',
								},
								queries: {
									'(max-width: 767.98px)': {
										style: {
											marginLeft: 'auto',
											marginBottom: '-27px',
										},
									},
									'(min-width: 768px)': {
										style: {},
									},
									'(min-width: 1200px)': {
										style: {
											alignSelf: 'flex-start',
										},
									},
								},
								toggle: {
									style: {
										height: '36px',
										border: '0',
										padding: '0',
										background: 'transparent',
									},
									icon: {
										style: {
											height: 'auto',
											width: 'auto',
										},
										svg: {
											style: {
												fill: '#ACACAC',
											},
										},
									},
									states: {
										active: {
											icon: {
												style: {},
												svg: {
													style: {
														fill: '#4C5362',
													},
												},
											},
										},
										hover: {
											icon: {
												style: {},
												svg: {
													style: {
														fill: '#4C5362',
													},
												},
											},
										},
									},
								},
								dropdown: {
									style: {
										backgroundColor: '#ffffff',
										boxShadow:
											'rgba(0, 0, 0, 0.25) 0px 3px 5px 0px',
										minWidth: '210px',
										overflow: 'hidden',
										transition: 'max-height 0.3s',
										maxHeight: '0',
										display: 'block',
										borderRadius: '5px',
									},
									item: {
										style: {
											borderTop:
												'1px solid rgb(244, 244, 244)',
											padding: '16px',
											fontFamily:
												'Open Sans, arial, sans-serif',
											fontWeight: '600',
										},
										text: {
											style: {
												fontSize: '12px',
												fontWeight: '600',
												color: '#4C5362',
												fontFamily:
													'Open Sans, arial, sans-serif',
											},
										},
										states: {
											hover: {
												style: {
													backgroundColor:
														'rgb(242, 242, 242)',
												},
											},
										},
									},
								},
								types: {
									open: {
										toggle: {},
										dropdown: {
											style: {
												transition: 'max-height 0.5s',
												maxHeight: '500px',
											},
										},
									},
								},
							},
						},
						title: {
							style: {
								color: '#4C5362',
								fontFamily: 'Open Sans, arial, sans-serif',
								fontSize: '18px',
								fontWeight: '600',
								marginBottom: '16px',
							},
						},
						graph: {
							yaxis: {
								labels: {
									style: {
										marginRight: '10px',
									},
									text: {
										style: {
											textAlign: 'right',
											color: '#ACACAC',
											fontFamily:
												'Open Sans, arial, sans-serif',
											fontWeight: '500',
											fontSize: '12px',
										},
									},
								},
								lines: {
									line: {
										style: {
											borderTop: '1px solid #F0F0F0',
										},
										types: {
											zero: {
												style: {
													borderTop:
														'2px solid #ABAEB5',
												},
											},
										},
									},
								},
							},
							graphArea: {
								linegroup: {
									style: {},
									line: {
										style: {
											stroke: 'rgb(76, 83, 98)',
											strokeWidth: 2,
										},
									},
									lineinfo: {
										text: {
											style: {
												color: '#ACACAC',
												fontFamily:
													'Open Sans, arial, sans-serif',
												fontWeight: '400',
												fontSize: '10px',
											},
										},
									},
									states: {
										hover: {
											lineinfo: {
												dot: {
													style: {
														backgroundColor:
															'#EE8F8B',
													},
												},
												text: {
													style: {
														color: '#4C5362',
														fontFamily:
															'Open Sans, arial, sans-serif',
													},
												},
											},
										},
										active: {
											lineinfo: {
												dot: {
													style: {
														backgroundColor:
															'#EE8F8B',
													},
												},
											},
										},
									},
									types: {
										history: {
											lineinfo: {
												dot: {
													style: {
														borderColor:
															'rgb(76, 83, 98)',
													},
												},
											},
										},
										active: {
											lineinfo: {
												dot: {
													style: {
														backgroundColor:
															'rgb(76, 83, 98)',
													},
												},
											},
										},
										forecast: {
											line: {
												style: {
													stroke: 'rgb(76, 83, 98)',
													strokeDasharray: 7,
													strokeWidth: 2,
												},
												types: {
													vertical: {
														style: {
															transform:
																'translateY(-1px)',
															strokeWidth: 4,
														},
													},
													overdraft: {
														style: {
															strokeDasharray: 8,
														},
													},
												},
											},
										},
										today: {
											lineinfo: {
												dot: {
													style: {
														backgroundColor:
															'rgb(76, 83, 98)',
														borderColor:
															'rgb(76, 83, 98)',
													},
												},
												text: {
													style: {
														color: '#4C5362',
														fontFamily:
															'Open Sans, arial, sans-serif',
														fontWeight: '400',
														fontSize: '14px',
													},
												},
											},
										},
									},
								},
								bargroup: {
									style: {
										margin: '8px 0px',
									},
									bar: {
										base: {
											style: {
												minHeight: '4px',
												cursor: 'pointer',
												borderBottomRightRadius: '20px',
												borderBottomLeftRadius: '20px',
											},
											part: {
												style: {
													borderBottomRightRadius:
														'20px',
													borderBottomLeftRadius:
														'20px',
												},
												types: {
													forecast: {
														style: {
															backgroundImage:
																' repeating-linear-gradient(135deg,transparent,transparent 1px,rgba(255,255,255,0.2) 2px,rgba(255,255,255,0.2) 4px,transparent 4.5px)',
														},
														part: {
															style: {
																backgroundImage:
																	'repeating-linear-gradient(135deg, transparent, transparent 1px, rgba(0, 0, 0, 0.1) 2px, rgba(0, 0, 0, 0.1) 4px, transparent 4.5px)',
															},
														},
													},
													unpaid: {
														style: {
															backgroundColor:
																'var(--system-unpaid-color)',
															backgroundImage:
																'repeating-linear-gradient(135deg, transparent, transparent 1px, rgba(0, 0, 0, 0.1) 2px, rgba(0, 0, 0, 0.1) 4px, transparent 4.5px)',
														},
													},
													overdue: {
														style: {
															display: 'none',
														},
													},
												},
											},
											badge: {
												style: {
													position: 'absolute',
													left: '-10px',
													bottom: '-10px',
													height: '20px',
													width: '20px',
													borderRadius: '50%',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													transform: 'rotate(180deg)',
													zIndex: '1',
												},
												types: {
													overdue: {
														style: {
															backgroundColor:
																'rgb(168, 47, 44);',
														},
													},
													unpaid: {
														style: {
															backgroundColor:
																'rgb(168, 47, 44);',
														},
													},
												},
												text: {
													style: {
														color: '#ffffff',
														fontSize: '12px',
														fontFamily:
															'Swedbank Headline',
														fontWeight: '200',
														justifyContent:
															'center',
														alignItems: 'center',
														lineHeight: '17px',
													},
												},
											},
											types: {
												zero: {
													style: {
														minHeight: '0px',
													},
												},
												forecast: {
													style: {
														backgroundImage:
															'repeating-linear-gradient(135deg,transparent,transparent 1px,rgba(255,255,255,0.2) 2px,rgba(255,255,255,0.2) 4px,transparent 4.5px)',
													},
													part: {
														types: {
															unpaid: {
																style: {
																	display:
																		'none',
																},
															},
														},
													},
												},
											},
										},
										types: {
											deposit: {
												base: {
													style: {
														backgroundColor:
															'#4C5362',
													},
												},
											},
											withdraw: {
												base: {
													style: {
														backgroundColor:
															'#C2C4C9',
													},
												},
											},
											unpaid: {
												base: {
													style: {
														backgroundColor:
															'var(--system-unpaid-color)',
														backgroundImage:
															'repeating-linear-gradient(135deg, transparent, transparent 1px, rgba(0, 0, 0, 0.1) 2px, rgba(0, 0, 0, 0.1) 4px, transparent 4.5px)',
													},
												},
											},
										},
										states: {
											active: {
												indicator: {
													style: {
														display: 'block',
													},
												},
											},
											hover: {
												indicator: {
													style: {
														display: 'block',
													},
												},
											},
										},
									},
									states: {
										active: {
											background: {
												style: {
													backgroundColor:
														'rgb(251, 242, 234)',
												},
											},
										},
									},
								},
								xaxis: {
									style: {
										justifyContent: 'flex-end',
										alignItems: 'center',
										height: '32px',
									},
									label: {
										style: {
											color: '#ACACAC',
											fontSize: '12px',
											cursor: 'pointer',
										},
									},
									prefix: {
										style: {
											color: '#000000',
											fontSize: '10px',
											fontFamily:
												'Open Sans, arial, sans-serif',
											fontWeight: '500',
											cursor: 'pointer',
										},
									},
									states: {
										hover: {
											label: {
												style: {
													fontFamily:
														'Open Sans, arial, sans-serif',
													fontWeight: '500',
													color:
														'var(--font-color-current)',
												},
											},
										},
										active: {
											label: {
												style: {
													fontFamily:
														'Open Sans, arial, sans-serif',
													fontWeight: '500',
													color:
														'var(--font-color-active)',
												},
											},
										},
									},
									types: {
										noChildrenActive: {
											indicator: {
												style: {
													display: 'block',
													border:
														'0px solid transparent',
													height: '2px',
													background: '#EE8F8B',
													width: '32px',
													marginTop: '8px',
												},
											},
										},
										noChildrenHover: {
											indicator: {
												style: {
													display: 'block',
													border:
														'0px solid transparent',
													height: '2px',
													background: '#EE8F8B',
													width: '32px',
													marginTop: '8px',
												},
											},
										},
										history: {
											prefix: {
												style: {
													color:
														'var(--font-color-past)',
													fontSize: '12px',
													fontFamily:
														'Open Sans, arial, sans-serif',
													fontWeight: '500',
												},
											},
											label: {
												style: {
													fontFamily:
														'Open Sans, arial, sans-serif',
													fontWeight: '500',
													color:
														'var(--font-color-past)',
												},
											},
											states: {
												hover: {
													label: {
														style: {
															fontFamily:
																'Open Sans, arial, sans-serif',
															fontWeight: '500',
															color: '#EE8F8B',
														},
													},
												},
												active: {
													label: {
														style: {
															fontFamily:
																'Open Sans, arial, sans-serif',
															fontWeight: '500',
															color: '#EE8F8B',
														},
													},
												},
											},
										},
										today: {
											prefix: {
												style: {
													color: '#4C5362',
													fontSize: '12px',
													fontFamily:
														'Open Sans, arial, sans-serif',
													fontWeight: '500',
												},
											},
											label: {
												style: {
													fontFamily:
														'Open Sans, arial, sans-serif',
													fontWeight: '500',
													color: '#4C5362',
												},
											},
											states: {
												hover: {
													label: {
														style: {
															fontFamily:
																'Open Sans, arial, sans-serif',
															fontWeight: '500',
															color: '#EE8F8B',
														},
													},
												},
												active: {
													label: {
														style: {
															fontFamily:
																'Open Sans, arial, sans-serif',
															fontWeight: '500',
															color: '#EE8F8B',
														},
													},
												},
											},
										},
										forecast: {
											prefix: {
												style: {
													color: '#4C5362',
													fontSize: '12px',
													fontFamily:
														'Open Sans, arial, sans-serif',
													fontWeight: '500',
												},
											},
											label: {
												style: {
													fontFamily:
														'Open Sans, arial, sans-serif',
													fontWeight: '500',
													color: '#ACACAC',
												},
											},
											states: {
												hover: {
													label: {
														style: {
															fontFamily:
																'Open Sans, arial, sans-serif',
															fontWeight: '500',
															color: '#EE8F8B',
														},
													},
												},
												active: {
													label: {
														style: {
															fontFamily:
																'Open Sans, arial, sans-serif',
															fontWeight: '500',
															color: '#EE8F8B',
														},
													},
												},
											},
										},
									},
								},
							},
						},
						controllers: {
							menu: {
								categories: {
									category: {
										style: {
											backgroundColor: '#DCDCDC',
										},
										prefix: {
											style: {
												display: 'none',
											},
										},
										text: {
											style: {
												color: '#4C5362',
												fontFamily:
													'Open Sans, arial, sans-serif',
												fontWeight: 400,
												fontSize: '12px',
											},
										},
										states: {
											hover: {
												style: {
													backgroundColor: '#CBCBCB',
												},
											},
											active: {
												style: {
													backgroundColor: '#CBCBCB',
												},
											},
										},
										type: {
											small: {
												style: {
													height: '24px',
													paddingLeft: '12px',
													paddingRight: '12px',
													paddingTop: '4px',
													paddingBottom: '4px',
												},
												text: {
													style: {
														fontSize: '12px',
													},
												},
											},
										},
									},
								},
								tags: {
									style: {},
									tag: {
										style: {
											alignItems: 'center',
											marginRight: '8px',
											alignSelf: 'center',
										},
										prefix: {
											style: {
												height: '12px',
												width: '12px',
												marginRight: '8px',
												borderRadius: '3px',
											},
										},
										text: {
											style: {
												color: '#4C5362',
												fontFamily:
													'Open Sans, arial, sans-serif',
												fontWeight: 400,
												fontSize: '12px',
												whiteSpace: 'pre',
											},
										},
										types: {
											deposit: {
												prefix: {
													style: {
														backgroundColor:
															'#4C5362',
													},
												},
											},
											withdraw: {
												prefix: {
													style: {
														backgroundColor:
															'#C2C4C9',
													},
												},
											},
											forecast: {
												prefix: {
													style: {
														backgroundColor:
															'rgb(255, 255, 255)',
														backgroundImage:
															'repeating-linear-gradient(135deg, transparent, transparent 2.5px, rgb(150, 150, 150) 3px, rgb(150, 150, 150) 4px, transparent 4.5px)',
													},
												},
											},
											defaultWithdraw: {
												style: {
													order: '-1',
												},
											},
											defaultDeposit: {
												style: {
													order: '-1',
												},
											},
											defaultForecast: {
												style: {
													order: '-1',
												},
											},
										},
									},
								},
							},
						},
						legends: {
							style: {},
							part: {
								style: {},
								text: {
									style: {
										color: '#4C5362',
										fontFamily:
											'Open Sans, arial, sans-serif',
										fontWeight: 400,
										fontSize: '12px',
									},
								},
								prefix: {
									style: {
										height: '12px',
										width: '12px',
										marginRight: '8px',
									},
								},
								types: {
									history: {
										prefix: {
											style: {
												backgroundImage: buildLegendIcon(
													'stroke: rgba(76,83,98,1);stroke-width: 2px;',
												),
												// 'url("data:image/svg+xml;utf8,<svg xmlns=\\"http://www.w3.org/2000/svg\\" width=\\"12\\" height=\\"12\\"><path d=\\"M 0 5 L 12 5\\" style=\\"stroke: rgba(76,83,98,1);stroke-width: 2px;\\"></path></svg>")',
											},
										},
									},
									forecast: {
										prefix: {
											style: {
												backgroundImage: buildLegendIcon(
													'stroke: rgba(76,83,98,1);stroke-width: 2px;stroke-dasharray: 4;',
												),
												// 'url("data:image/svg+xml;utf8,<svg xmlns=\\"http://www.w3.org/2000/svg\\" width=\\"12\\" height=\\"12\\"><path d=\\"M 0 5 L 12 5\\" style=\\"stroke: rgba(76,83,98,1);stroke-width: 2px;stroke-dasharray: 4;\\"></path></svg>")',
											},
										},
									},
									spread: {
										prefix: {
											style: {
												backgroundImage:
													'linear-gradient(141deg, #dadada 25%, #bfbfbf 25%, #bfbfbf 50%, #dadada 50%, #dadada 75%, #bfbfbf 75%, #bfbfbf 100%)',
												backgroundSize: '6.36px 5.15px',
												borderRadius: '0',
												marginTop: '-1px',
											},
										},
									},
								},
							},
						},
						navigation: {
							button: {
								icon: {
									style: {
										margin: '0 auto',
									},
									svg: {
										style: {
											fill: '#ACACAC',
											transition: 'all 80ms linear',
										},
									},
								},
								states: {
									hover: {
										icon: {
											svg: {
												style: {
													fill: '#4C5362',
												},
											},
										},
									},
								},
							},
						},
					},
					transactions: {
						header: {
							style: {
								marginBottom: '-2px',
								paddingLeft: '16px',
								paddingRight: '4px',
								position: 'relative',
								flexWrap: 'wrap',
								borderBottom: '0px solid transparent',
							},
							queries: {
								'(max-width: 767.98px)': {
									style: {
										flexDirection: 'column',
										alignItems: 'flex-start',
										padding: '8px 0',
										marginLeft: '12px',
										marginRight: '12px',
									},
								},
								'(min-width: 768px)': {
									style: {
										paddingBottom: '8px',
										paddingTop: '8px',
										margin: '0 12px',
									},
								},
								'(min-width: 1200px)': {
									style: {
										margin: '0 0',
									},
								},
							},
							button: {
								style: {
									paddingTop: '0',
									paddingBottom: '0px',
									// height: 'auto',
									paddingLeft: '0',
									/* justifyContent: 'flex-start', */
								},
								queries: {
									'(max-width: 767.98px)': {
										style: {
											width: '95%',
										},
									},
									'(min-width: 768px)': {
										style: {},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
								text: {
									style: {},
									queries: {
										'(max-width: 767.98px)': {
											style: {},
										},
										'(min-width: 768px)': {
											style: {},
										},
										'(min-width: 1200px)': {
											style: {},
										},
									},
								},
								icon: {
									style: {
										flexGrow: 0,
										marginRight: '4px',
									},
									svg: { style: { fill: '#ACACAC' } },
									states: {
										hover: {
											icon: {
												svg: {
													style: {
														fill: '#4C5362',
													},
												},
											},
										},
									},
								},
								types: {
									large: {
										text: {
											style: {
												fontSize: '28px',
												color: '#4C5362',
												fontFamily:
													'Open Sans, arial, sans-serif',
												fontWeight: 400,
												textAlign: 'left',
												flexGrow: 1,
												textDecoration: 'none',
												cursor: 'default',
											},
											queries: {
												'(max-width: 767.98px)': {
													style: {
														fontSize: '24px',
														whiteSpace: 'nowrap',
														overflow: 'hidden',
														textOverflow:
															'ellipsis',
													},
												},
												'(min-width: 768px)': {
													style: {},
												},
												'(min-width: 1200px)': {
													style: {},
												},
											},
										},
									},
									transactionsHeaderInfo: {
										style: {
											padding: 0,
											flexGrow: 0,
											borderBottom:
												'0px solid transparent',
										},
										queries: {
											'(max-width: 767.98px)': {
												style: {
													width: 'auto',
													display: 'none',
												},
											},
											'(min-width: 768px)': {
												style: {},
											},
											'(min-width: 1200px)': {
												style: {},
											},
										},
										icon: {
											style: {
												height: '24px',
												width: '24px',
											},
											svg: {
												style: {
													fill: '#ACACAC',
												},
											},
										},
										states: {
											hover: {
												icon: {
													svg: {
														style: {
															fill: '#4C5362',
														},
													},
												},
											},
										},
									},
									transactionsHeaderClose: {
										style: {
											padding: 0,
											flexGrow: 0,
											borderBottom:
												'0px solid transparent',
										},
										queries: {
											'(max-width: 767.98px)': {
												style: {
													width: 'auto',
													position: 'absolute',
													right: '0',
													top: '14px',
												},
											},
											'(min-width: 768px)': {
												style: {},
											},
											'(min-width: 1200px)': {
												style: {},
											},
										},
										icon: {
											style: {
												height: '16px',
												width: '16px',
												marginRight: '0',
											},
										},
									},
								},
								states: {
									hover: {
										icon: {
											svg: { style: { fill: '#4C5362' } },
										},
									},
								},
							},
							security: {
								style: {
									marginLeft: '8px',
								},
								queries: {
									'(max-width: 767.98px)': {
										style: {
											marginLeft: '0',
											marginTop: '-31px',
										},
									},
									'(min-width: 768px)': {
										style: {},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
							},
							amount: {
								style: {
									color: '#4C5362',
									fontFamily: 'Open Sans, arial, sans-serif',
									fontWeight: '300',
								},
								queries: {
									'(max-width: 767.98px)': {
										style: {
											marginLeft: '20px',
										},
									},
									'(min-width: 768px)': {
										style: {},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
							},
							adjustable: {
								style: {},
								text: {
									style: {
										fontFamily: 'Arial, sans-serif',
										fontWeight: '400',
										fontSize: '16px',
										color: '#EE8F8B',
										display: 'none',
									},
									queries: {
										'(max-width: 767.98px)': {
											style: {},
										},
										'(min-width: 768px)': {
											style: {},
										},
										'(min-width: 1200px)': {
											style: {},
										},
									},
								},
								icon: {
									style: {
										// marginRight: '4px',
										// width: '20px',
									},
									svg: {
										style: {
											fill: '#EE8F8B',
										},
									},
								},
								states: {
									hover: {
										icon: {
											svg: {
												style: {
													fill: '#D37C79',
												},
											},
										},
									},
								},
							},
							tools: {
								style: {
									display: 'flex',
									flexBasis: '100%',
									alignItems: 'flex-end',
									justifyContent: 'flex-end',
								},
								button: {
									style: {
										width: 'auto',
										marginRight: '12px',
										paddingRight: '0',
									},
									text: {},
								},
							},
						},
						list: {
							list: {
								transaction: {
									info: {
										style: {
											minHeight: 'auto',
										},
										cell: {},
									},
								},
							},
						},
					},
					insights: {
						style: {
							background: 'red',
						},
						insight: {
							style: {
								background: 'red',
							},
							types: {
								guide: {
									states: {
										hover: {
											style: {
												backgroundColor:
													'rgba(242,241,237,1)',
											},
										},
									},
									style: {
										background: 'red',
									},
								},
								open: {
									states: {
										hover: {
											style: {
												backgroundColor:
													'rgba(255,243,213,1)',
											},
										},
									},
									style: {
										background: 'red',
									},
								},
								overdue: {
									states: {
										hover: {
											style: {
												backgroundColor:
													'rgba(251,237,244,1)',
											},
										},
									},
									style: {
										background: 'red',
									},
								},
							},
						},
					},
				},
			},
			statusIconForecast: {
				dashboard: {
					transactions: {
						list: {
							cell: {
								types: {
									status: {
										icon: {
											types: {
												forecast: {
													style: {
														display: 'block',
													},
													svg: {
														style: {
															fill: '#53A1AC',
														},
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
			statusIconPaid: {
				dashboard: {
					transactions: {
						list: {
							cell: {
								types: {
									status: {
										icon: {
											types: {
												paid: {
													style: {
														display: 'block',
													},
													svg: {
														style: {
															fill: '#99CC66',
														},
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
			statusIconCard: {
				dashboard: {
					transactions: {
						list: {
							cell: {
								types: {
									statusCard: {
										icon: {
											types: {
												unpaid: {
													style: {
														display: 'none',
													},
												},
												forecast: {
													style: {
														display: 'none',
													},
												},
											},
										},
									},
									status: {
										icon: {
											types: {
												card: {
													style: {
														display: 'block',
													},
													svg: {
														style: {},
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
			statusIconCash: {
				dashboard: {
					transactions: {
						list: {
							cell: {
								types: {
									statusCash: {
										icon: {
											types: {
												unpaid: {
													style: {
														display: 'none',
													},
												},
												forecast: {
													style: {
														display: 'none',
													},
												},
											},
										},
									},
									status: {
										icon: {
											types: {
												cash: {
													style: {
														display: 'block',
													},
													svg: {
														style: {},
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
			statusIconSupplier: {
				dashboard: {
					transactions: {
						list: {
							cell: {
								types: {
									statusSupplier: {
										icon: {
											types: {
												unpaid: {
													style: {
														display: 'none',
													},
												},
												overdue: {
													style: {
														display: 'none',
													},
												},
												forecast: {
													style: {
														display: 'none',
													},
												},
											},
										},
									},
									status: {
										icon: {
											types: {
												supplier: {
													style: {
														display: 'block',
													},
													svg: {
														style: {},
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
			statusIconCustomer: {
				dashboard: {
					transactions: {
						list: {
							cell: {
								types: {
									statusCustomer: {
										icon: {
											types: {
												unpaid: {
													style: {
														display: 'none',
													},
												},
												overdue: {
													style: {
														display: 'none',
													},
												},
												forecast: {
													style: {
														display: 'none',
													},
												},
											},
										},
									},
									status: {
										icon: {
											types: {
												customer: {
													style: {
														display: 'block',
													},
													svg: {
														style: {},
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
			statusIconTransfer: {
				dashboard: {
					transactions: {
						list: {
							cell: {
								types: {
									status: {
										icon: {
											types: {
												transfer: {
													style: {
														display: 'block',
													},
													svg: {
														style: {},
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
			statusIconTax: {
				dashboard: {
					transactions: {
						list: {
							cell: {
								types: {
									statusTax: {
										icon: {
											types: {
												unpaid: {
													style: {
														display: 'none',
													},
												},
												forecast: {
													style: {
														display: 'none',
													},
												},
											},
										},
									},
									status: {
										icon: {
											types: {
												tax: {
													style: {
														display: 'block',
													},
													svg: {
														style: {},
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
			statusIconSalary: {
				dashboard: {
					transactions: {
						list: {
							cell: {
								types: {
									statusSalary: {
										icon: {
											types: {
												unpaid: {
													style: {
														display: 'none',
													},
												},
												forecast: {
													style: {
														display: 'none',
													},
												},
											},
										},
									},
									status: {
										icon: {
											types: {
												salary: {
													style: {
														display: 'block',
													},
													svg: {
														style: {},
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
			statusIconMisc: {
				dashboard: {
					transactions: {
						list: {
							cell: {
								types: {
									statusMisc: {
										icon: {
											types: {
												unpaid: {
													style: {
														display: 'none',
													},
												},
												forecast: {
													style: {
														display: 'none',
													},
												},
											},
										},
									},
									status: {
										icon: {
											types: {
												misc: {
													style: {
														display: 'block',
													},
													svg: {
														style: {},
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
			statusIconSwish: {
				dashboard: {
					transactions: {
						list: {
							cell: {
								types: {
									statusSwish: {
										icon: {
											types: {
												unpaid: {
													style: {
														display: 'none',
													},
												},
												forecast: {
													style: {
														display: 'none',
													},
												},
											},
										},
									},
									status: {
										icon: {
											types: {
												swish: {
													style: {
														display: 'block',
													},
													svg: {
														style: {},
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
			statusIconBankgiro: {
				dashboard: {
					transactions: {
						list: {
							cell: {
								types: {
									statusBankgiro: {
										icon: {
											types: {
												unpaid: {
													style: {
														display: 'none',
													},
												},
												forecast: {
													style: {
														display: 'none',
													},
												},
											},
										},
									},
									status: {
										icon: {
											types: {
												bankgiro: {
													style: {
														display: 'block',
													},
													svg: {
														style: {},
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
			statusIconPlusgiro: {
				dashboard: {
					transactions: {
						list: {
							cell: {
								types: {
									statusPlusgiro: {
										icon: {
											types: {
												unpaid: {
													style: {
														display: 'none',
													},
												},
												forecast: {
													style: {
														display: 'none',
													},
												},
											},
										},
									},
									status: {
										icon: {
											types: {
												plusgiro: {
													style: {
														display: 'block',
													},
													svg: {
														style: {},
													},
												},
											},
										},
									},
								},
							},
						},
					},
				},
			},
			'sidepane-transaction-adjustable-edit': {
				dashboard: {
					style: {},
					transactions: {
						list: {
							list: {
								transaction: {
									types: {
										edit: {
											style: {
												padding: '16px',
												background: '#FBF2EA',
												height: '100%',
												borderBottom:
													'0px solid transparent',
											},
											adjustable: {
												style: {
													flexDirection: 'column',
													alignItems: 'flex-start',
												},
												queries: {
													'(max-width: 767.98px)': {
														style: {},
													},
													'(min-width: 768px)': {
														style: {
															flexDirection:
																'column',
															alignItems:
																'flex-end',
														},
													},
													'(min-width: 1200px)': {
														style: {},
													},
												},
												infobox: {
													queries: {
														'(max-width: 767.98px)': {
															style: {},
														},
														'(min-width: 768px)': {
															style: {
																margin: '0 0px',
															},
														},
														'(min-width: 1200px)': {
															style: {},
														},
													},
													button: {
														types: {
															default: {
																style: {
																	padding:
																		'8px 16px !important',
																	marginTop:
																		'0px !important',
																},
															},
															primary: {},
														},
													},
												},
												header: {
													style: {},
													queries: {
														'(max-width: 767.98px)': {
															style: {},
														},
														'(min-width: 768px)': {
															style: {
																margin: '0 0px',
															},
														},
														'(min-width: 1200px)': {
															style: {},
														},
													},
													title: {
														style: {
															display: 'none',
														},
													},
												},
												group: {
													style: {
														width: '100%',
														margin: '0 !important',
													},
													queries: {
														'(max-width: 767.98px)': {
															style: {},
														},
														'(min-width: 768px)': {
															style: {
																flex: '1',
																margin:
																	'0 10px',
															},
														},
														'(min-width: 1200px)': {
															style: {},
														},
													},
													types: {
														customer: {
															style: {
																maxWidth:
																	'none',
															},
														},
														date: {
															style: {
																maxWidth:
																	'none',
															},
															datepicker: {
																calendars: {
																	style: {
																		right:
																			'0',
																	},
																},
																wrapper: {
																	style: {
																		maxWidth:
																			'240px',
																	},
																},
															},
														},
														amount: {
															style: {
																maxWidth:
																	'none',
																marginBottom:
																	'16px !important',
															},
														},
													},
												},
												input: {
													style: {
														background: '#fff',
													},
												},
												label: {
													style: {},
												},
												dropdown: {
													style: {},
													toggle: {
														style: {},
														icon: {
															svg: {
																style: {
																	fill:
																		'#53A1AC',
																},
															},
														},
													},
												},
											},
										},
									},
									states: {
										active: {
											style: {
												borderBottom: 'none',
												zIndex: '2',
											},
										},
									},
								},
							},
						},
					},
				},
			},
			'sidepane-transaction-adjustable-add': {
				dashboard: {
					style: {},
					transactions: {
						adjustable: {
							infobox: {
								queries: {
									'(max-width: 767.98px)': {
										style: {},
									},
									'(min-width: 768px)': {
										style: {
											margin: '0 0px',
										},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
							},
							header: {
								style: {},
								queries: {
									'(max-width: 767.98px)': {
										style: {},
									},
									'(min-width: 768px)': {
										style: {
											margin: '0 0px',
										},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
								title: {
									style: {
										display: 'none',
									},
								},
							},
							list: {
								style: {},
								queries: {
									'(max-width: 767.98px)': {
										style: {},
									},
									'(min-width: 768px)': {
										style: {
											justifyContent: 'space-between',
											marginLeft: '0',
											marginRight: '0',
											padding: '16px',
											height: '100%',
											background: '#FBF2EA',
										},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
								transaction: {
									style: {
										alignItems: 'flex-start',
										/* overflow: 'visible', */
									},
									queries: {
										'(max-width: 767.98px)': {
											style: {
												flexDirection: 'column',
												width: '100%',
											},
										},
										'(min-width: 768px)': {
											style: {
												flexDirection: 'column',
											},
										},
										'(min-width: 1200px)': {
											style: {},
										},
									},
									group: {
										style: {
											width: '100%',
										},
										queries: {
											'(max-width: 767.98px)': {
												style: {},
											},
											'(min-width: 768px)': {
												style: {
													flex: '1',
													margin: '0 0px',
												},
											},
											'(min-width: 1200px)': {
												style: {},
											},
										},
										types: {
											customer: {
												style: {
													maxWidth: 'none',
												},
											},
											date: {
												style: {
													maxWidth: 'none',
												},
												/* datepicker: {
													style: {
														style: {
															right: '0',
														},
													},
													calendars: {
														style: {
															right: '0',
														},
													},
												}, */
											},
											amount: {
												style: {
													maxWidth: 'none',
													marginBottom:
														'16px !important',
												},
											},
										},
									},
									input: {
										style: {
											background: '#fff',
										},
									},
									label: {
										style: {},
									},
									button: {
										style: {},
										types: {
											remove: {
												style: {
													color: '#a38b80',
													marginBottom: '4px',
													padding: '0',
													marginLeft: '0',
													marginTop: '8px',
												},
												queries: {
													'(max-width: 767.98px)': {
														style: {
															paddingLeft: '0',
															marginTop: '12px',
														},
													},
													'(min-width: 768px)': {
														style: {},
													},
													'(min-width: 1200px)': {
														style: {},
													},
												},
												text: {
													style: {
														color: '#512b2b',
														fontSize: '16px',
													},
												},
												icon: {
													svg: {
														style: {
															fill: '#a38b80',
														},
													},
												},
											},
										},
									},
								},
							},
							button: {
								types: {
									addTransactionRow: {
										style: {
											padding: '0',
											marginLeft: '0',
											marginTop: '16px',
										},
									},
								},
							},
						},
						list: {
							list: {
								transaction: {
									states: {
										active: {
											style: {
												borderBottom: 'none',
												zIndex: '2',
											},
										},
									},
								},
							},
						},
					},
				},
			},
			'sidepane-transaction-detail': {
				dashboard: {
					transactions: {
						style: {},
						list: {
							style: {},
							cell: {
								style: {},
							},
							detailswrapper: {
								style: {
									height: '100% !important',
									overflow: 'visible',
								},
								details: {
									style: {
										padding: '16px 16px',
										border: '0px solid transparent',
										background: '#fff',
									},
									cell: {
										style: {
											borderBottom: '1px solid #EAE7E3',
											flexBasis: 'auto',
											padding: '12px 8px',
										},
										icon: {
											style: {
												marginLeft: 'auto',
											},
										},
										text: {
											style: {
												marginLeft: 'auto',
											},
										},
										button: {
											style: {
												marginLeft: 'auto',
												maxWidth: '190px',
											},
											text: {
												style: {
													whiteSpace: 'nowrap',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
												},
											},
										},
										types: {
											allCategories: {
												style: {
													flexDirection: 'column',
													alignItems: 'flex-start',
													padding: '12px 8px',
													borderBottom:
														'0px solid transparent',
												},
												title: {
													style: {
														marginBottom: '8px',
													},
												},
											},
											textStatus: {
												style: {
													justifyContent: 'flex-end',
												},
												title: {
													style: {
														marginRight: 'auto',
													},
												},
												text: {
													style: {
														marginLeft: '0',
													},
												},
											},
										},
									},
								},
								overview: {
									style: {
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										flexDirection: 'column',
										padding: '32px 16px',
										border: '0px solid transparent',
										backgroundColor: '#FBF2EA',
									},
									cell: {
										style: {},
										types: {
											overviewDescription: {
												style: {
													maxWidth: '100%',
												},
												text: {
													style: {
														fontFamily:
															'Swedbank Sans',
														fontWeight: '400',
														fontSize: '18px',
														whiteSpace: 'nowrap',
														overflow: 'hidden',
														textOverflow:
															'ellipsis',
													},
												},
											},
											overviewAmount: {
												style: {
													padding: '4px 8px',
												},
												text: {
													style: {
														fontFamily:
															'Swedbank Sans',
														fontWeight: '700',
														fontSize: '24px',
														whiteSpace: 'nowrap',
														overflow: 'hidden',
														textOverflow:
															'ellipsis',
													},
												},
											},
											overviewDate: {
												style: {},
												text: {
													style: {
														whiteSpace: 'nowrap',
														overflow: 'hidden',
														textOverflow:
															'ellipsis',
													},
												},
											},
										},
									},
								},
							},
							states: {
								active: {
									style: {
										borderBottom: '0px solid transparent',
										zIndex: '2',
									},
								},
							},
						},
					},
				},
			},
			'sticky-navigation': {
				dashboard: {
					style: {},
					graph: {
						style: {},
						header: {
							style: {
								position: 'sticky',
								top: '80px',
								background: '#fff',
								zIndex: '6',
								padding: '8px 0',
							},
							queries: {
								'(max-width: 767.98px)': {
									style: {
										position: 'relative',
										top: '0',
									},
								},
								'(min-width: 768px)': {
									style: {
										position: 'sticky',
										top: '80px',
										background: '#fff',
										zIndex: '6',
										padding: '8px 0',
									},
								},
								'(min-width: 1200px)': {
									style: {},
								},
								'(max-width: 990px)': {
									style: {
										padding: '8px 12px',
										width: 'calc(100% - 24px)',
									},
								},
							},
							menu: {
								style: {},
								queries: {
									'(max-width: 767.98px)': {
										style: {},
									},
									'(min-width: 768px)': {
										style: {
											marginRight: '8px',
										},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
								toggle: {
									icon: {
										style: {
											marginLeft: 'auto',
											marginRight: '4px',
										},
										svg: {
											style: {},
										},
									},
								},
							},
						},
					},
					transactions: {
						header: {
							style: {
								position: 'sticky',
								top: '145px',
								zIndex: '3',
								background: '#fff',
							},
						},
						list: {
							header: {
								style: {
									position: 'sticky',
									top: '211px',
									zIndex: '2',
								},
							},
						},
					},
				},
			},
			swedbankify: {
				variables: {
					systemWithdrawColor: 'rgba(146,74,121,1)',
				},
			},
		},
	},
};
