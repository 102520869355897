import React, { useCallback, useContext, useEffect } from 'react';
import { endOfMonth, endOfISOWeek, endOfYear, format } from 'date-fns';

import { useStore } from '@asteria/utils';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { queryCashflow } from '@asteria/services-datalayer/services/graph/actions';
import classNames from 'classnames';
import BarGroup from './bargroup';

const endOfTime = (date, size) => {
	if (size === 'week') {
		return endOfISOWeek(date);
	}

	if (size === 'month') {
		return endOfMonth(date);
	}

	if (size === 'year') {
		return endOfYear(date);
	}

	return endOfMonth(date);
};

const BarGraph = React.memo(
	({
		range = [],
		groups = {},
		maxValue = 0,
		margin,
		activeGroups = [],
		hoverGroups = [],
		activeBars,
		onClick,
		filters = [],
		size,
	}) => {
		const { dispatch } = useContext(DatalayerContext);
		const [onboardingStep = { id: false, type: false }] = useStore(
			'store-adjustable',
			({ currentOnboardingStep }) => currentOnboardingStep,
		);

		const clickAction = useCallback(
			(...args) => {
				if (onClick) {
					onClick(...args);
				}
			},
			[onClick],
		);

		const [first] = range;
		const last = [...range].pop();
		const firstGroup = groups[(first?.id)];
		const lastGroup = groups[(last?.id)];

		useEffect(() => {
			if (first && !firstGroup) {
				dispatch(
					queryCashflow('cashflow-bar-graph', {
						startDate: first?.id,
						endDate: `${format(
							endOfTime(new Date(first?.id), size),
							'YYYY-MM-DD',
						)}T23:59:59.999Z`,
						tags: filters
							.filter(({ type }) => type === 'tag')
							.map(({ id: tagId }) => tagId),
						clients: filters
							.filter(({ type }) => type === 'client')
							.map(({ id: customerId }) => customerId),
						currencies: filters
							.filter(({ type }) => type === 'currency')
							.map(({ config: { name } }) => name),
						status: filters
							.filter(({ type }) => type === 'status')
							.map(({ config: { status } }) => status),
					}),
				);
			}

			if (last && !lastGroup) {
				dispatch(
					queryCashflow('cashflow-bar-graph', {
						startDate: last?.id,
						endDate: `${format(
							endOfTime(new Date(last?.id), size),
							'YYYY-MM-DD',
						)}T23:59:59.999Z`,
						tags: filters
							.filter(({ type }) => type === 'tag')
							.map(({ id: tagId }) => tagId),
						clients: filters
							.filter(({ type }) => type === 'client')
							.map(({ id: customerId }) => customerId),
						currencies: filters
							.filter(({ type }) => type === 'currency')
							.map(({ config: { name } }) => name),
						status: filters
							.filter(({ type }) => type === 'status')
							.map(({ config: { status } }) => status),
					}),
				);
			}
		}, [dispatch, filters, first, firstGroup, last, lastGroup, size]);

		let afterOboarding = false;
		return range.slice(1, -1).map((r, index) => {
			if (r?.id === onboardingStep.id) {
				afterOboarding = true;
			}
			return (
				<BarGroup
					key={`bargroup-${r?.id || index}`}
					active={activeGroups.includes(r?.id)}
					activeBars={activeBars}
					group={groups[(r?.id)]}
					maxValue={maxValue}
					margin={margin}
					range={r}
					onClick={clickAction}
					filters={filters}
					size={size}
					first={index === 0}
					last={index === range.length - 3}
					beacon={
						r?.id === onboardingStep.id
							? onboardingStep.type
							: false
					}
					className={classNames({
						'asteria-graph-bar-group-after-becon': afterOboarding,
						'asteria-state-hover': hoverGroups.includes(r?.id),
					})}
				/>
			);
		});
	},
);

BarGraph.displayName = 'BarGraph';

export default BarGraph;
