import React, { useRef } from 'react';
import styled from 'styled-components';
import { useComponentSize } from '@asteria/utils';
import Text from '@asteria/component-typography/text';
import classNames from 'classnames';

const YAxisLabel = React.memo(styled(
	// eslint-disable-next-line arrow-body-style
	({ className, updateSize = () => {}, value, label }) => {
		const ref = useRef(null);
		const { height } = useComponentSize(ref);

		updateSize(value, height);

		return (
			<Text
				size="label"
				ref={ref}
				className={classNames(className, 'asteria-graph-yaxis-label', {
					'asteria-graph-yaxis-label-zero': value === 0,
				})}
			>
				{label}
			</Text>
		);
	},
)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
`);
YAxisLabel.displayName = 'YAxisLabel';

const YAxisLabels = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const YAxisLines = styled.div`
	-ms-grid-row: 1;
	-ms-grid-column: 2;
	-ms-grid-column-span: 2;

	grid-row-start: 1;
	grid-row-end: 1;
	grid-column-start: 2;
	grid-column-end: 3;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 2;
	position: relative;
	pointer-events: none;

	${Text} {
		position: relative;
		:after {
			content: '';
			border-top: 1px solid black;
			width: 100%;
			display: block;
			position: absolute;
			top: 50%;
		}
	}
`;
YAxisLines.displayName = 'YAxisLines';

const YAxisLine = styled.div`
	position: relative;
	border-top: 1px solid black;
`;
YAxisLine.displayName = 'YAxisLine';

const YAxis = React.memo(({ steps = [], updateSize, style }) => (
	<>
		<YAxisLabels className={classNames(`asteria-graph-yaxis-labels`)}>
			{steps.map(({ value = 0, label = '0' } = {}) => (
				<YAxisLabel
					key={value}
					className={classNames('asteria-graph-yaxis-label', {
						'asteria-graph-yaxis-label-zero': value === 0,
					})}
					updateSize={updateSize}
					label={label}
					value={value}
				/>
			))}
		</YAxisLabels>
		<YAxisLines
			className={classNames(`asteria-graph-yaxis-lines`)}
			style={style}
		>
			{steps.map(({ value = 0 } = {}) => (
				<YAxisLine
					key={value}
					className={classNames('asteria-graph-yaxis-line', {
						'asteria-graph-yaxis-line-zero': value === 0,
					})}
					style={{ color: 'transparent' }}
				/>
			))}
		</YAxisLines>
	</>
));
YAxis.displayName = 'YAxis';
export default YAxis;
export { YAxisLabels, YAxisLine, YAxisLines, Text };
