import { css } from 'styled-components';
import merge from 'lodash/fp/merge';
import get from 'lodash/fp/get';
import kebabCase from 'lodash/fp/kebabCase';
import camelCase from 'lodash/fp/camelCase';
import debug from 'debug/dist/debug';

const testEl = document.createElement('i');
testEl.style.setProperty('--x', 'y');
let noCSSVars = true;
if (testEl.style.getPropertyValue('--x') === 'y' || !testEl.msMatchesSelector) {
	noCSSVars = false;
}

let applyStates = () => {};

const BaseStyleSelector = get('style');
const CSS_CACHE = new Map();

const logger = debug('asteria:styler:compiler');

const getStyler = component => {
	if (component.Styler) {
		return typeof component.Styler === 'function'
			? component.Styler()
			: component.Styler;
	}

	return component;
};

const getDisplayName = configOrComponent => {
	if (configOrComponent.component) {
		return configOrComponent.component.displayName || 'Unknown';
	}

	return configOrComponent.displayName || 'Unknown';
};

const regFindGetters = /var\(--(.*?)(,.*)?\)/g;
const resolveVariable = (str, variables) => {
	regFindGetters.lastIndex = 0;
	if (regFindGetters.test(str)) {
		regFindGetters.lastIndex = 0;
		return (
			str
				.replace(regFindGetters, ($0, $1, $2) => {
					const result = variables[camelCase($1)] || false;
					if ($2) {
						const subVariables = resolveVariable(
							$2.substring(1).trim(),
							variables,
						);

						if (subVariables === 'false') {
							return result;
						}

						return `${result}/${subVariables}`;
					}
					return result;
				})
				.split('/')
				.filter(s => s && s !== 'false')?.[0] || false
		);
	}

	return str;
};

const preProcess = (style, theme) => {
	if (noCSSVars) {
		const { global: { variables = {} } = {} } = theme;

		if (typeof style === 'string') {
			return resolveVariable(style, variables);
		}

		const keys = Object.entries(style).filter(
			([, value = '']) => value?.includes && value?.includes('var('),
		);

		for (let i = 0; i < keys.length; i += 1) {
			const [key, value] = keys[i];

			// eslint-disable-next-line no-param-reassign
			style[key] = resolveVariable(value, variables);
		}
	}

	return style;
};

const processEntries = (obj, prefix) =>
	obj
		.filter(([key]) => key.startsWith(prefix))
		.map(([key, value]) => [key.replace(prefix, '').trim(), value]);

const compileTypesStyle = (
	entries,
	prefix,
	config,
	props,
	options,
	compile,
	path,
) =>
	entries
		.map(([type, typeStyle]) => {
			const style = compile(
				config,
				typeStyle,
				props,
				options,
				`${path}.${type}`,
			);
			if (!style) {
				return '';
			}

			return `&.${prefix}-${kebabCase(type)} {${style}}`;
		})
		.join('\n');

const compilePsudoElementsStyle = (
	entries,
	config,
	props,
	options,
	compile,
	path,
) =>
	entries
		.map(([psudo, psudoStyle]) => {
			const style = compile(
				{},
				psudoStyle,
				props,
				options,
				`${path}.${psudo}`,
			);

			if (!style) {
				return '';
			}

			return `&::${psudo} {${style}}`;
		})
		.join('\n');

const compileMediaQueries = (entries, config, props, options, compile, path) =>
	entries
		.map(([query, queryStyle]) => {
			const style = compile(
				config,
				queryStyle,
				props,
				options,
				`${path}.${query}`,
			);

			if (!style) {
				return '';
			}

			return `
				@media screen and ${query} {
					${style}
				}
			`;
		})
		.join('\n');

const compileChildrenStyle = (
	children,
	config,
	props,
	options,
	compile,
	path,
) => {
	const { componentGetter = comp => comp } = options;

	return children
		.map(child => {
			const displayName = getDisplayName(child);
			const style = compile(
				child,
				child.style || config,
				props,
				options,
				`${path}.${displayName}`,
			);

			if (!style) {
				return '';
			}

			// eslint-disable-next-line prettier/prettier
			const comp = componentGetter(child.component) || child.component;
			let styleClass = child.cssSelector || '';

			if (comp) {
				styleClass = comp.styledComponentId || comp;
			}

			return `.${styleClass}${child.cssSelector || ''} {${style}}`;
		})
		.join('\n');
};

const compileVariablesStyle = entries =>
	// eslint-disable-next-line prettier/prettier
	entries.map(([key, value]) => `--${kebabCase(key)}: ${value};`).filter(s => s !== '').join('\n');

const getBaseStyle = (config, component, theme) => {
	let { base = false } = config;

	// Extend with eventual children
	if (base === false && component && component.Styler) {
		const { base: componentBase } = getStyler(component);

		if (base === false && componentBase && componentBase.length > 0) {
			base = componentBase;
		}
	}

	if (!base || base.length === 0) {
		return theme;
	}

	if (Array.isArray(base)) {
		return Object.assign({}, ...base.map(f => f(theme) || {}));
	}

	if (typeof base === 'function') {
		return base(theme);
	}

	return theme[base];
};

const getTypePrefix = (config, component) => {
	let { typePrefix = 'asteria' } = config;

	// Extend with eventual children
	if (component && component.Styler) {
		const { typePrefix: componentTypePrefix = 'asteria' } = getStyler(
			component,
		);
		typePrefix =
			typePrefix !== 'asteria' ? typePrefix : componentTypePrefix;
	}

	return typePrefix;
};

const getChildren = (config, component) => {
	let { children = [] } = config;

	if (component && component.Styler) {
		const { children: componentChildren = [] } = getStyler(component);
		children = [...children, ...componentChildren];
	}

	return children;
};

const compile = (
	configOrComponent = {},
	theme = {},
	props = {},
	options = {},
	path = 'root',
) => {
	const config = getStyler(configOrComponent);

	if (CSS_CACHE.has(theme)) {
		const themeCache = CSS_CACHE.get(theme);
		if (themeCache.has(config)) {
			// logger('Cache hit');
			return themeCache.get(config);
		}

		logger(
			'Cache miss',
			path,
			config,
			theme,
			CSS_CACHE.has(theme),
			CSS_CACHE.has(theme) ? themeCache.has(config) : false,
		);
	} else {
		logger('Cache miss', path, theme, CSS_CACHE.has(theme), false);
	}

	if (!options.baseTheme) {
		// eslint-disable-next-line no-param-reassign
		options.baseTheme = theme;
	}

	const { componentGetter = type => type } = options;
	const {
		baseSelector = false,
		styleSelectors = [BaseStyleSelector],
	} = config;

	// Fetch the component to use
	const component =
		componentGetter(config.component) || config.component || false;

	// fetch style from the theme, and parse eventual states and types and queries
	const children = getChildren(config, component);
	const typePrefix = getTypePrefix(config, component);
	const baseStyle = getBaseStyle(config, component, theme);

	if (!baseStyle || Object.keys(baseStyle).length === 0) {
		if (!CSS_CACHE.has(theme)) {
			CSS_CACHE.set(theme, new Map());
		}

		const themeCache = CSS_CACHE.get(theme);
		themeCache.set(config, '');
		return '';
	}

	const {
		states = {},
		types = {},
		queries = {},
		features = {},
		variables = {},
		...styleObject
	} = baseStyle;
	// fetch the style field ( or use custom selector for it )
	const style = Object.assign({}, ...styleSelectors.map(f => f(baseStyle)));
	const styleKeys = Object.entries(styleObject);

	const typeEntries = Object.entries(types).concat(
		processEntries(styleKeys, '&'),
	);

	const queriesEntries = Object.entries(queries).concat(
		processEntries(styleKeys, '@'),
	);

	const statesEntries = Object.entries(states).concat(
		processEntries(styleKeys, '!'),
	);

	const psudoEntries = processEntries(styleKeys, '::');

	const childrenEntries = Object.entries(queries)
		.concat(processEntries(styleKeys, '>'))
		.map(([key, value]) => ({ component: key, style: value }));

	const featureEntries = Object.entries(features);

	const variableEntires = Object.entries(variables).concat(
		processEntries(styleKeys, '--'),
	);

	const childrenCss = compileChildrenStyle(
		children.concat(childrenEntries),
		baseStyle,
		props,
		options,
		compile,
		path,
	);

	const typesCss = compileTypesStyle(
		typeEntries,
		typePrefix,
		{ ...config, base: [] },
		props,
		options,
		compile,
		path,
	);

	const mediaQueryCss = compileMediaQueries(
		queriesEntries,
		{ ...config, base: [] },
		props,
		options,
		compile,
		path,
	);

	const statesCss = applyStates(config, statesEntries, props, options);
	const psudoCss = compilePsudoElementsStyle(
		psudoEntries,
		baseStyle,
		props,
		options,
		compile,
		path,
	);

	const featuresCss =
		featureEntries.length > 0
			? featureEntries
					.map(([feature, featureStyle]) => {
						const compiledStyle = compile(
							{ ...config, base: [] },
							featureStyle,
							props,
							options,
						);

						if (!style) {
							return '';
						}
						// eslint-disable-next-line prettier/prettier
					return `&.asteria-feature-${kebabCase(feature)} {${compiledStyle}}`;
					})
					.join('\n')
			: '';

	const variableStyle = compileVariablesStyle(variableEntires);
	const elementStyle = preProcess(style, options.baseTheme);

	let result = '';

	if (
		variableStyle.length +
			Object.keys(elementStyle).length +
			statesCss.length +
			childrenCss.length +
			psudoCss.length +
			typesCss.length +
			mediaQueryCss.length +
			featuresCss.length >
		0
	) {
		result = [
			variableStyle,
			css`
				${elementStyle}
			`.join('\n'),
			statesCss,
			childrenCss,
			psudoCss,
			typesCss,
			mediaQueryCss,
			featuresCss,
		];

		if (path !== 'root') {
			result = result.join('\n');
		}
	}

	if (baseSelector) {
		result = css`
			${baseSelector} {
				${result}
			}
		`;
	} else if (path === 'root') {
		result = css`
			${result}
		`;
	}

	result = Array.isArray(result) ? result.join('') : result;

	if (!CSS_CACHE.has(theme)) {
		CSS_CACHE.set(theme, new Map());
	}

	const themeCache = CSS_CACHE.get(theme);
	themeCache.set(config, result);

	return result;
};

const BUILT_IN = [
	'any',
	'any-link',
	'checked',
	'default',
	'defined',
	'disabled',
	'empty',
	'first',
	'first-child',
	'first-of-type',
	'fullscreen',
	'focus',
	'hover',
	'visited',
	'intermediate',
	'in-range',
	'invalid',
	'last-child',
	'last-of-type',
	'left',
	'link',
	'only-child',
	'only-of-type',
	'optional',
	'out-of-range',
	'read-only',
	'read-write',
	'required',
	'right',
	'root',
	'scope',
	'target',
	'valid',
	'visited',
];
const getStateKey = event => {
	if (!BUILT_IN.includes(event)) {
		return `&.asteria-state-${event}`;
	}

	return `:${event}, &.asteria-state-${event}`;
};

applyStates = (config, entries, props, options) => {
	if (entries.length === 0) {
		return '';
	}

	const result = entries
		.map(
			([key, event]) => `
			${getStateKey(key)} {
				${compile({ ...config, base: [] }, event, props, options)}
			}
		`,
		)
		.join('\n');

	return result;
};

const compileObject = (config, theme, props, options = {}) => {
	const { componentGetter = type => type } = options;
	const { activeTypes = [], activeStates = [] } = options;
	const { base = [] } = config;
	let { children = [] } = config;

	const component =
		componentGetter(config.component) || config.component || false;

	const baseStyle =
		base.length > 0 ? Object.assign({}, ...base.map(f => f(theme))) : theme;
	const {
		states = {},
		types = {},
		queries = {},
		...objectConfig
	} = baseStyle;

	if (component && component.Styler) {
		const { children: componentChildren = [] } = getStyler(component);
		children = [...children, ...componentChildren];
	}

	let styledObject = merge({ children: [] }, objectConfig);

	// Apply states
	Object.entries(states)
		.filter(([state]) => activeStates.includes(state))
		.forEach(([, stateStyle]) => {
			styledObject = merge(
				styledObject,
				compileObject(
					{ ...config, base: [] },
					stateStyle,
					props,
					options,
				),
			);
		});

	// Apply types
	Object.entries(types)
		.filter(
			([type]) =>
				activeTypes.includes(`asteria-${type}`) ||
				activeTypes.includes('all'),
		)
		.forEach(([, typeStyle]) => {
			styledObject = merge(
				styledObject,
				compileObject(
					{ ...config, base: [] },
					typeStyle,
					props,
					options,
				),
			);
		});

	Object.entries(queries)
		.filter(([query]) => window.matchMedia(query).matches)
		.forEach(([, typeStyle]) => {
			styledObject = merge(
				styledObject,
				compileObject(
					{ ...config, base: [] },
					typeStyle,
					props,
					options,
				),
			);
		});

	if (children.length > 0) {
		children.forEach(child => {
			styledObject.children.push(
				compileObject(child, baseStyle, props, options),
			);
		});
	}

	return styledObject;
};

export default compile;
export { compileObject, preProcess };
