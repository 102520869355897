import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import Button from '@asteria/component-button/button';
import {
	Dropdown,
	/* InputV2, */
	LabelV2,
	FormGroupV2,
} from '@asteria/component-form';
import Modal, { ModalBody, ModalFooter } from '@asteria/component-modal';
import { useFeature } from '@asteria/component-featureflag';
import { addYears } from 'date-fns';
import { TranslationService } from '@asteria/services-language';

const Periods = [
	/* 	{
		value: 'day',
		label: 'Dag',
	}, */
	{
		value: 'isoWeek',
		label: 'forms.repeatable.isoWeek.label',
	},
	{
		value: 'month',
		label: 'forms.repeatable.month.label',
	},
	/* 	{
		value: 'year',
		label: 'År',
	}, */
];

const RepeatableModal = styled(
	({ className, repeatable = {}, open, onClose }) => {
		const [form, setRepeatable] = useState(repeatable);

		const { startDate, endDate, period } = form;

		const [activeDay, setActiveDay] = useState([]);

		const update = useCallback(() => {
			onClose();
		}, [onClose]);

		return (
			<Modal
				open={open}
				title="Repeatera"
				onClose={update}
				className={className}
			>
				<ModalBody className="asteria-modal-body modal-body">
					<div className={classNames(`asteria-form`)}>
						<FormGroupV2>
							<LabelV2>Från</LabelV2>
							{startDate}
						</FormGroupV2>
						<div className={classNames(`asteria-form-grouped`)}>
							<FormGroupV2 className="asteria-group">
								<LabelV2>Var</LabelV2>
								<Dropdown
									iconClosed="triangleDown"
									iconOpen="triangleUp"
									options={[
										{
											value: '1',
											label: '1',
										},
										{
											value: '2',
											label: '2',
										},
										{
											value: '3',
											label: '3',
										},
										{
											value: '4',
											label: '4',
										},
										{
											value: '5',
											label: '5',
										},
										{
											value: '6',
											label: '6 (upp till 99)',
										},
									]}
									placeholder="1"
									itemIcon="check"
									showSelected
									type="default"
								/>
							</FormGroupV2>
							<FormGroupV2 className="asteria-group">
								<LabelV2 />
								<Dropdown
									iconClosed="triangleDown"
									iconOpen="triangleUp"
									options={Periods}
									selected={Periods.find(
										({ value }) => value === period,
									)}
									placeholder="Månad"
									itemIcon="check"
									showSelected
									type="default"
									onChange={({ value }) => {
										setRepeatable({
											...form,
											period: value,
										});
									}}
								/>
							</FormGroupV2>
						</div>
						<ul className="asteria-weekdays">
							{['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'].map(
								day => (
									<li className="asteria-weekday">
										<Button
											type="link"
											size="medium"
											className={classNames(
												`asteria-weekday-button`,
												{
													'asteria-state-active': activeDay.includes(
														day,
													),
												},
											)}
											onClick={() =>
												setActiveDay(
													activeDay.includes(day)
														? [
																...activeDay.filter(
																	d =>
																		d !==
																		day,
																),
														  ]
														: [...activeDay, day],
												)
											}
											text={day}
										/>
									</li>
								),
							)}
						</ul>
						<FormGroupV2>
							<LabelV2>Slutdatum</LabelV2>
							{endDate}
							<Button
								icon="close"
								type="icon"
								size="small"
								className="asteria-date-remove"
							/>
						</FormGroupV2>
					</div>
				</ModalBody>
				<ModalFooter className="modal-footer">
					<Button
						size="medium"
						type="default"
						text="Avbryt"
						onClick={() => onClose()}
					/>
					<Button
						size="medium"
						type="primary"
						text="Spara"
						onClick={update}
					/>
				</ModalFooter>
			</Modal>
		);
	},
)`
	.asteria-modal {
		.asteria-modal-body {
			overflow: visible;
		}

		.asteria-form {
			background-color: rgb(250, 242, 235);
			padding: 16px 16px 30px;
			.asteria-form-grouped {
				justify-content: space-between;
				margin-left: -10px;
				margin-right: -10px;
				display: flex;
				.asteria-form-group {
					flex: 1;
					margin: 0 10px;
					width: 100%;
					&:first-of-type {
						max-width: 22%;
					}
				}
			}

			.asteria-weekdays {
				flex-direction: row;
				display: flex;
				list-style-type: none;
				padding: 0;
				margin: 24p 0 16px 0;
				.asteria-weekday {
					list-style-type: none;
					padding: 0;
					margin: 0;
					flex: 1;
					align-items: center;
					justify-content: center;
					display: flex;
					.asteria-weekday-button {
						border-radius: 50%;
						width: 40px;
						height: 40px;
						display: flex;
						justify-content: center;
						align-items: center;
						background: #ebe7e2;
						.asteria-text {
							color: #2f2424;
							font-family: Arial, Helvetica, sans-serif;
							font-size: 14px;
							font-weight: 400;
							text-decoration: none;
						}
						&.asteria-state-active {
							background: #efb7b6;
						}
					}
				}
			}

			.asteria-form-group {
				.asteria-state {
					background: #fff;
				}
				.asteria-forms-dropdown-open
					.asteria-forms-dropdown-menu-direction-down {
					max-height: 240px;
				}
				.asteria-button {
					&.asteria-date-remove {
						margin-top: -36px;
						margin-left: auto;
						margin-right: 44px;
						background: transparent;
						.asteria-icon-wrapper {
							svg {
								fill: #9f8c82;
							}
						}
					}
				}
			}
		}
	}
`;

RepeatableModal.displayName = 'RepeatableModal';

RepeatableModal.Styler = {
	children: [],
};

const REPEATABLE = [
	{
		value: 'never',
		label: 'forms.repeatable.never.label',
	},
	{
		value: 'isoWeek',
		label: 'forms.repeatable.isoWeek.label',
	},
	{
		value: 'month',
		label: 'forms.repeatable.month.label',
	},
	/* 	{
		value: 'year',
		label: 'Varje år',
	}, */
	/* 	{
		value: 'other',
		label: 'Annat',
	}, */
];

const Repeatable = styled(({ className, onChange = () => {} }) => {
	const [showCustom, setShowCustom] = useState(false);
	const [repeatable, setRepatable] = useState({
		startDate: new Date(),
		endDate: addYears(new Date(), 1),
		period: REPEATABLE[0].value,
	});

	const update = data => {
		const newData = { ...repeatable, ...data };
		setRepatable(newData);
		onChange(newData);
	};

	const { period } = repeatable;

	const hasAdjustableRepeatable = useFeature({
		key: 'adjustable-repeatable',
	});

	const updateRepeatable = useCallback(() => {
		setShowCustom(false);
	}, []);

	if (!hasAdjustableRepeatable) {
		return null;
	}
	return (
		<div className={classNames(className, `asteria-forms-repeatable`)}>
			<FormGroupV2 className="asteria-group">
				<LabelV2>
					{TranslationService.get('forms.repeatable.label')}
				</LabelV2>
				<Dropdown
					iconClosed="triangleDown"
					iconOpen="triangleUp"
					options={REPEATABLE}
					placeholder="Aldrig"
					showSelected
					itemIcon="check"
					selected={[
						REPEATABLE.find(({ value }) => value === period),
					]}
					type="default"
					onChange={option => {
						if (option.value === 'other') {
							setShowCustom(true);
						}
						update({ period: option.value });
					}}
				/>
			</FormGroupV2>
			<RepeatableModal open={showCustom} onClose={updateRepeatable} />
		</div>
	);
})`
	width: 100%;
`;

export default Repeatable;
