import gql from 'graphql-tag';

export const GET_INTEGRATIONS = gql`
	query GetIntegrations {
		integrations {
			id
			key
			type
			lastSync
			config {
				connected
				server
				client
				errors {
					code
					message
				}
			}
			status {
				state
			}

			actions {
				_id
				action
				status
				data
			}
		}
	}
`;

export const SEND_MISSING_ERP = gql`
	mutation AddMissingErp($name: String!) {
		addCustomErpSystem(input: { title: $name }) {
			ok
		}
	}
`;

export const UPDATE_INTEGRATION = gql`
	mutation UpdateIntegration($input: CompanyInputIntegrationType) {
		updateIntegration(input: $input) {
			integrations {
				key
				config
			}
		}
	}
`;

export const ADD_INTEGRATION = gql`
	mutation AddIntegration($input: IntegrationCreateInputType!) {
		addIntegration(input: $input) {
			ok
			error
			integration {
				id
			}
		}
	}
`;

export const REMOVE_INTEGRATION = gql`
	mutation RemoveIntegration($input: ID!) {
		removeIntegration(id: $input) {
			ok
		}
	}
`;
