export default el => {
	const path = [];
	let currentEl = el;

	while (currentEl) {
		path.push(currentEl);

		if (currentEl.tagName === 'HTML') {
			path.push(document);
			path.push(window);

			return path;
		}

		currentEl = currentEl.parentElement;
	}

	return path;
};
