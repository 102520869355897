import TextV2 from './text';
import TitleV2 from './title';

export default TitleV2;

export {
	TextV2 as Text,
	TitleV2 as Title,
	TextV2,
	TitleV2,
	TextV2 as StyledText,
	TextV2 as TextElement,
};
