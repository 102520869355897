import styled from 'styled-components';
import { StyleMerger } from '@asteria/utils';
import styleProvider from './styleProvider';

/**
 * Takes component props and returns the target style merged with overridding
 * ones depending on whether the overrideRoot option is set or not
 * @param {Object} theme - The current theme passed as an prop to the component
 * @param {String} parentSelector - something like path to parent component
 * needed to find new styles in different theme tree
 * @param {String} overrideRoot - Key of the root of the new target theme
 * @param {Object} props - The components props
 * @param {string} target - The target styleprovider flow / selector to use
 * @param {string} [overridingTarget] - Additional target styleprovider flow / selector to use to collect overrode styles
 */
const getMergedStyle = (
	{ theme, parentSelector, overrideRoot, ...props },
	target,
	overridingTarget,
) => {
	const ownStyles = styleProvider.getStyle(theme, props, target);

	// If overrideRoot or parentSelector is set then return new styles but
	// merged with inherited from original path
	if (overrideRoot || parentSelector) {
		return StyleMerger(
			ownStyles,
			styleProvider.getStyle(
				theme,
				{ parentSelector, overrideRoot, ...props },
				overridingTarget || target,
			),
		);
	}
	return ownStyles;
};

/**
 * Create styled view for Row component
 */
const Wrapper = styled.div`
	margin-left: auto;
	margin-right: auto;
	${props => (props.type === 'row' ? 'flex-direction: row;' : '')};
	${props =>
		getMergedStyle(props, props.type === 'row' ? 'formRow' : 'formColumn')};
`;

export default Wrapper;
