/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import { TranslationService } from '@asteria/services-language';
import { useHistory, useStore } from '@asteria/utils/hooks';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { LinearIndicator } from '@asteria/component-progressbar-indicator';
import { Title } from '@asteria/component-core';
import {
	saveUser,
	fetchUser,
} from '@asteria/services-datalayer/services/auth/actions';
import { fetchIntegrations } from '@asteria/services-datalayer/services/integrations/actions';
import {
	fetchClients,
	setListOpen,
	setSelectedDate,
	setSelectedType,
	showModal,
} from '@asteria/services-datalayer/services/appstate/actions';
import {
	setRange,
	fetchCategories,
	queryCashflow,
} from '@asteria/services-datalayer/services/graph/actions';
import { getAccounts } from '@asteria/services-datalayer/services/bankaccounts/actions';
import { useFeature } from '@asteria/component-featureflag';
import { format } from 'date-fns';
import {
	getNotifications,
	subscribeToNotifications,
} from '@asteria/services-datalayer/services/notifications/actions';
import compare from '@asteria/utils/compare';

const LoadingPage = React.memo(styled(({ className }) => (
	<div className={classNames(className, `asteria-loading-app`)}>
		<Title size="title3">
			{TranslationService.get('app.loading.title')}
		</Title>
		<LinearIndicator />
	</div>
))`
	height: 100%;
	width: 100%;
	min-height: 330px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	${Title} {
		font-size: 16px !important;
		font-family: Swedbank Sans !important;
		color: rgba(81, 43, 43, 1);
		font-weight: bold !important;
		margin-bottom: 8px !important;
	}
`);

const DataFetching = React.memo(() => {
	const { dispatch } = useContext(DatalayerContext);
	const history = useHistory();

	const onboardingStatusDialog = useFeature({
		key: 'onboarding-status-dialog',
	});

	const hasOnboardingSurvey = useFeature({
		key: 'onboarding-survey',
	});

	const hasListShowOnLoad = useFeature({
		key: 'list-show-on-load',
	});

	const hasIntegrationModalFirstTime = useFeature({
		key: 'integration-modal-first-time',
	});

	const hasShowFullOnboarding = useFeature({
		key: 'integration-empty',
	});

	useEffect(() => {
		dispatch(getAccounts());
		dispatch(fetchIntegrations());
		dispatch(fetchCategories());
		dispatch(fetchUser());
		dispatch(fetchClients());
		dispatch(getNotifications());
		dispatch(subscribeToNotifications());

		if (hasListShowOnLoad) {
			dispatch(setListOpen(true, 'account'));
			dispatch(setSelectedType(['DEPOSIT', 'WITHDRAW']));
			dispatch(
				setSelectedDate(
					`${format(new Date(), 'YYYY-MM')}-01T00:00:00.000Z`,
				),
			);
		}

		dispatch(
			setRange('cashflow-bar-graph', [
				{
					id: '2019-01-01T00:00:00.000Z',
					label: 'Januari',
				},
				{
					id: '2019-02-01T00:00:00.000Z',
					label: 'Februari',
				},
				{
					id: '2019-03-01T00:00:00.000Z',
					label: 'Mars',
				},
			]),
		);

		dispatch(
			queryCashflow('cashflow-bar-graph', {
				startDate: '2020-05-01T00:00:00.000Z',
				endDate: '2020-05-31T00:00:00.000Z',
			}),
		);
	}, [dispatch, hasListShowOnLoad]);

	const [{ user, hasFetchedUser }] = useStore(
		'store-auth',
		({ user: obj, hasFetchedUser: has = false }) => ({
			user: obj,
			hasFetchedUser: has,
		}),
		compare,
	);

	const [
		{
			list: integrations,
			hasFetched: hasFetchedIntegrations,
			need: needsOnboarding,
		},
	] = useStore(
		'store-integrations',
		({
			hasFetchedIntegrations: hasFetched = false,
			integrations: list,
			needsOnboarding: need,
		}) => ({ list, hasFetched, need }),
		compare,
	);

	const [hasFetchedGraph = false] = useStore(
		'store-graph',
		({ 'cashflow-bar-graph': { fetching: has } = {} }) =>
			has !== undefined && has !== true,
	);

	const [hasFetchedClients = false] = useStore(
		'store-appstate',
		({ clients }) => clients,
	);

	const hasErp = useMemo(
		() => integrations.filter(e => e).find(({ type }) => type === 'erp'),
		[integrations],
	);

	const isDone = useMemo(
		() =>
			hasFetchedUser &&
			hasFetchedIntegrations &&
			user &&
			hasFetchedGraph &&
			needsOnboarding !== undefined &&
			hasFetchedClients,
		[
			hasFetchedClients,
			hasFetchedGraph,
			hasFetchedIntegrations,
			hasFetchedUser,
			needsOnboarding,
			user,
		],
	);

	if (!isDone) {
		return <LoadingPage />;
	}

	const { settings: { flags = {}, hasOnboarded, erpReminder = false } = {} } =
		user || {};

	if (!flags?.whyDidYouOnboard && hasOnboardingSurvey) {
		dispatch(showModal('survey'));
	}

	if (hasOnboarded === false) {
		if (needsOnboarding && !onboardingStatusDialog) {
			history.push('/integrations/status');
			return null;
		}

		if (user && !onboardingStatusDialog) {
			dispatch(
				saveUser({
					...user,
					settings: {
						...user.settings,
						hasOnboarded: true,
					},
				}),
			);
		}
	}

	if (hasIntegrationModalFirstTime && !hasErp && erpReminder === false) {
		history.push('/integrations/erp/add');
		return null;
	}

	if (
		hasShowFullOnboarding &&
		integrations.length === 0 &&
		needsOnboarding === true
	) {
		history.push('/onboarding');
		return null;
	}

	if (history.location.pathname === '/loading') {
		history.push('/');
	}

	return null;
});

export default DataFetching;
