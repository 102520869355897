import React from 'react';
import ContentLoader from 'react-content-loader';

const ListLoaderItem = props => (
	<ContentLoader height={40} width={1060} speed={1} {...props}>
		<rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
		<rect x="390" y="15" rx="5" ry="5" width="50" height="10" />
		<rect x="450" y="15" rx="5" ry="5" width="50" height="10" />
		<circle cx="590" cy="20" r="7" />
		<circle cx="610" cy="20" r="7" />
		<circle cx="630" cy="20" r="7" />
		<rect x="968" y="15" rx="5" ry="5" width="80" height="10" />
	</ContentLoader>
);

const ListLoader = () => (
	<React.Fragment>
		{Array(10)
			.fill(0)
			.map((el, i) => i)
			.map((e, i) => (
				<ListLoaderItem
					key={e}
					style={{ opacity: Number(2 / i).toFixed(1) }}
				/>
			))}
	</React.Fragment>
);

export const DetailsHeaderLoader = props => (
	<ContentLoader height={33} width={1060} speed={1} {...props}>
		<rect x="25" y="10" rx="5" ry="5" width="180" height="10" />
	</ContentLoader>
);

export const DetailsContentLoader = props => (
	<ContentLoader height={100} width={1060} speed={1} {...props}>
		<rect x="5" y="15" rx="5" ry="5" width="1050" height="10" />
		<circle r="7" cx="25" cy="45" />
		<rect rx="5" ry="5" height="10" x="40" width="180" y="40" />
		<rect rx="5" ry="5" x="40" width="100" height="8" y="56" />
		<rect rx="5" ry="5" x="40" width="120" y="70" height="8" />
		<circle r="7" cy="45" cx="275" />
		<rect rx="5" ry="5" height="10" width="180" y="40" x="290" />
		<rect rx="5" ry="5" width="100" height="8" y="56" x="290" />
		<rect rx="5" ry="5" width="120" y="70" height="8" x="290" />
	</ContentLoader>
);

export default ListLoader;
