import { Subscriber } from 'rxjs';
import axios, { CancelToken } from 'axios';

class AxiosSubscriber extends Subscriber {
	constructor(
		observer,
		instance = axios,
		url,
		method,
		data,
		{ accessToken, noBearer = false } = {},
	) {
		super(observer);

		this.cancelToken = CancelToken.source();
		this.aborted = false;
		this.instance = instance;

		this.execute(url, method, data, {
			headers: {
				...(accessToken
					? {
							Authorization: noBearer
								? accessToken
								: `Bearer ${accessToken}`,
					  }
					: {}),
			},
		});
	}

	async execute(url, method = 'GET', data = {}, options) {
		return this.instance({
			method,
			url,
			data,
			cancelToken: this.cancelToken.token,
			...options,
		})
			.then(response => {
				this.next(response.data);
				this.complete();
			})
			.catch(error => {
				this.error(error);
			});
	}

	unsubscribe() {
		super.unsubscribe();
		if (this.aborted === false) {
			this.cancelToken.cancel();
			this.aborted = true;
		}
	}
}

export default AxiosSubscriber;
