import React, { useState, useCallback, useContext, useEffect } from 'react';
import { TextV2, Title } from '@asteria/component-typography';
import { ButtonV2 } from '@asteria/component-button';
import { Translation, TranslationService } from '@asteria/services-language';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { initiateIntegration } from '@asteria/services-datalayer/services/integrations/actions';
import { useStore } from '@asteria/utils';
import {
	FormGroup,
	LabelV2,
	InputV2,
	RadioGroupV2,
} from '@asteria/component-form';
import Alert from '@asteria/component-alert';
import Layout from '../../layout';
import Context from '../../context';

const Key = ({ navigator, callback = () => {} }) => {
	const { setStepIndex } = useContext(Context);
	const [invalidKeyError, setInvalidKeyError] = useState(false);
	const [corporateId, setCorporateId] = useState('');
	const [coporateType, setCorporateType] = useState(0);

	useEffect(() => {
		setStepIndex(2);
		callback('goto', {
			event: 'goto',
			integration: 'seb',
			integrationTitle: 'SEB',
			step: {
				stepId: 'sebActivation',
				title: 'Aktivera',
			},
			steps: [
				{ stepId: 'sebActivation', title: 'Aktivera' },
				{
					stepId: 'sebConnecting',
					title: 'Väntar på anslutning',
				},
			],
		});
	}, [callback, setStepIndex]);

	const { dispatch } = useContext(DatalayerContext);
	const [erps = []] = useStore(
		'store-integrations',
		({ integrations = [] }) => integrations,
	);
	const [integrationId] = useStore(
		'store-integrations',
		({ integrationInProgress }) => integrationInProgress,
	);

	const [errors, setErrors] = useState([]);

	useEffect(() => {
		if (integrationId) {
			const integration = erps.find(int => int.id === integrationId);
			if (integration) {
				setErrors([...errors, ...integration.config.errors]);
				dispatch(() => ({
					action: 'CONNECTION_CLEANUP',
				}));
			}
		}
	}, [dispatch, erps, errors, integrationId]);

	const nextStep = useCallback(() => {
		if (!corporateId || corporateId.length === 0) {
			setInvalidKeyError(true);
		} else {
			const coporateTypeId = coporateType === 0 ? '4' : '9';
			const clientId = `${corporateId
				.replace('-', '')
				.replace(' ', '')}000${coporateTypeId}`;

			dispatch(
				initiateIntegration({
					integrationName: 'sebbank',
					type: 'bank',
					config: {
						client: {
							headers: {
								'PSU-Corporate-Id': clientId,
							},
						},
					},
				}),
			);
			navigator.next(null, navigator);
		}
	}, [coporateType, corporateId, dispatch, navigator]);

	return (
		<Layout
			type="seb-key"
			header={
				<Title
					className="asteria-title-onboarding-header"
					size="title2"
				>
					{TranslationService.get(
						[
							'onboarding.connect.header',
							'onboarding.connect.bank.header',
						],
						'2. Anslut till bokföringssystem',
					)}
				</Title>
			}
			body={
				<>
					<div
						className="asteria-dialog-box"
						style={{
							padding: '20px 20px 8px',
							backgroundColor: '#fbf2ea',
						}}
					>
						<Title size="title4">SEB</Title>
						<TextV2 size="small">
							{TranslationService.get(
								'onboarding.seb.api_form.title',
								'För att aktivera kopplingen behöver du logga in hos SEB och godkänna att Asteria AB för tillgång till er bank data. Fyll i organisationsnumret och klicka sedan på "Logga in hos SEB" för att aktivera.',
							)}
						</TextV2>
					</div>
					<div
						className="asteria-dialog-box"
						style={{
							backgroundColor: '#fbf2ea',
							padding: '0px 20px 15px',
						}}
					>
						<FormGroup>
							<LabelV2 path="form.label">
								{TranslationService.get(
									'onboarding.seb.api_form.corporateId.label',
									'Organisationsnummer',
								)}
							</LabelV2>
							<InputV2
								placeholder={TranslationService.get(
									'onboarding.seb.api_form.corporateId.placeholder',
									'',
								)}
								onChange={e => {
									if (invalidKeyError) {
										// hide alert after user started typing again
										setInvalidKeyError(false);
									}
									setCorporateId(e.target.value);
								}}
								value={corporateId}
								invertedcolor
							/>
							{invalidKeyError ? (
								<Alert
									icon
									description={false}
									type="error"
									headingContent={
										<Translation
											translationKey="onboarding.seb.api_form.corporateId.missing"
											defaultText="Var vänlig fyll i ditt organisationsnummer."
										/>
									}
									onClose={() => setInvalidKeyError(false)}
								/>
							) : null}
						</FormGroup>
						<FormGroup>
							<LabelV2 path="form.label">
								{TranslationService.get(
									'onboarding.seb.api_form.type.label',
									'Inloggningssätt',
								)}
							</LabelV2>
							<RadioGroupV2
								items={[
									TranslationService.get(
										'onboarding.seb.corporation_form_1',
										'Aktiebolag / Handelsbolag',
									),
									TranslationService.get(
										'onboarding.seb.corporation_form_2',
										'Enskild',
									),
								]}
								selected={[coporateType]}
								direction="vertical"
								onChange={index => {
									setCorporateType(index);
								}}
								style={{
									alignItems: 'flex-start',
									flexDirection: 'row',
									marginLeft: '0px',
									marginTop: '0px',
									marginBottom: '16px',
								}}
							/>
						</FormGroup>
					</div>
					<div
						className="asteria-wizard-inner-footer"
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							paddingTop: '20px',
						}}
					>
						<ButtonV2
							size="medium"
							type="default"
							onClick={() =>
								navigator.cancel(
									{
										event: 'cancel',
									},
									navigator,
								)
							}
							text={TranslationService.get(
								'onboarding.abort',
								'Avbryt',
							)}
						/>
						<div
							className="asteria--activation-key-controls"
							style={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<ButtonV2
								size="medium"
								type="secondary"
								onClick={() =>
									navigator.back(
										{
											event: 'goto',
											step: {
												stepId: 'selectErp',
											},
										},
										navigator,
									)
								}
								text={TranslationService.get(
									'onboarding.back',
									'Tillbaka',
								)}
							/>
							<ButtonV2
								size="medium"
								type="primary"
								text={TranslationService.get(
									'onboarding.seb.url',
									'Logga in hos SEB',
								)}
								onClick={() => nextStep()}
								style={{ marginLeft: '16px' }}
							/>
						</div>
					</div>
				</>
			}
			footer={<></>}
		/>
	);
};

Key.navigationKey = Symbol('SEBKey');

export default Key;
