import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import kebabCase from 'lodash/fp/kebabCase';
import { fontSizes, colors } from '@asteria/services-constants';
import PropTypes from 'prop-types';
import { Getter } from '@asteria/utils';

/**
 * Base Text component wrapper
 */
const Text = styled(
	React.forwardRef(
		(
			{
				children,
				styler,
				className,
				editStyle,
				editProps,
				size,
				theme,
				text,
				Component = 'p',
				...otherProps
			},
			ref,
		) => (
			<Component
				ref={ref}
				className={classNames(
					'asteria-text',
					size && `asteria-text-${kebabCase(size)}`,
					className,
				)}
				{...otherProps}
			>
				{text || children}
			</Component>
		),
	),
)``;

// Styler structure for text component
Text.Styler = {
	typePrefix: 'asteria-text',
	component: Text,
	base: Getter('typography.text'),
};

Text.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	className: PropTypes.string,
	size: PropTypes.string,
};

Text.defaultProps = {
	children: null,
	className: '',
	size: 'text',
};

Text.displayName = 'Text';

const ElementMapper = {
	title1: 'h1',
	title2: 'h2',
	title3: 'h3',
};

/**
 * Base Title component wrapper
 */
const Title = styled(
	({
		children,
		styler,
		className,
		editStyle,
		editProps,
		size,
		theme,
		...otherProps
	}) => {
		const ElementType = ElementMapper[size] || 'span';

		return (
			<ElementType
				className={classNames(
					'asteria-title',
					size && `asteria-title-${kebabCase(size)}`,
					className,
				)}
				{...otherProps}
			>
				{children}
			</ElementType>
		);
	},
)`
	display: block;

	font-size: ${props => fontSizes[props.size]};
	color: ${colors.headline};
`;

// Styler structure for title component
Title.Styler = {
	typePrefix: 'asteria-title',
	component: Title,
	base: Getter('typography.title'),
};

Title.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	className: PropTypes.string,
	size: PropTypes.string,
};

Title.defaultProps = {
	children: null,
	className: '',
	size: 'title',
};

Title.displayName = 'Title';

const TitleText = styled(({ className, title, text }) => (
	<div className={classNames('asteria-titletext', className)}>
		<Title size="title4">{title}</Title>
		<Text size="small">{text}</Text>
	</div>
))`
	${Text} {
		&.asteria-text-small {
			color: var(--muted-color);
			font-family: Arial, Helvetica, sans-serif;
			font-size: 12px !important;
		}
	}

	${Title} {
		&.asteria-title-title-4 {
			font-size: 16px !important;
			font-family: Swedbank Sans;
			font-weight: 700;
			color: var(--text-color);
		}
	}
`;

export { Text, Title, TitleText };
