export default {
	dialogs: {
		dialog: {
			style: {
				flexDirection: 'row',
				marginBottom: '16px',
			},
			wrapper: {
				title: {
					style: {
						marginBottom: '8px',
					},
				},
			},
			alert: {
				style: {
					background: '#e7f9f2',
					padding: '16px 16px',
					position: 'relative',
				},
				controllers: {
					style: {
						display: 'flex',
						justifyContent: 'flex-end',
						flexWrap: 'wrap',
					},
					missing: {
						style: {
							flexBasis: '100%',
							marginTop: '32px',
							borderTop: '1px solid rgba(0,0,0,0.08)',
							paddingTop: '24px',
						},
						text: {
							style: {
								marginBottom: '8px',
							},
						},
						button: {
							style: {
								marginLeft: 'auto',
								marginTop: '16px',
							},
						},
						input: {
							style: {
								background: '#fff',
							},
						},
						dropdown: {
							style: {
								marginBottom: '16px',
							},
							toggle: {
								style: {
									marginTop: '0px',
								},
								icon: {
									svg: {
										style: {
											fill: '#257886',
										},
									},
								},
							},
							dropdown: {
								item: {
									text: {
										style: {
											marginBottom: '0',
										},
									},
								},
							},
						},
					},
				},
			},
			reminder: {
				style: {
					background: '#ffebf4',
					padding: '10px 20px',
				},
			},
			button: {
				types: {
					close: {
						style: {
							position: 'absolute',
							top: '5px',
							right: '5px',
							height: '24px',
							width: '24px',
						},
						icon: {
							svg: {
								style: {
									fill: '#C1BDBD',
								},
							},
						},
					},
				},
			},
		},
	},
};
