const reducer = (state = {}, { action, payload }) => {
	switch (action) {
		case 'ADD_WIDGET_CLASS': {
			return {
				...state,
				widgetClasses: [
					...state.widgetClasses.filter(item => item !== payload),
					payload,
				],
			};
		}
		case 'REMOVE_WIDGET_CLASS': {
			return {
				...state,
				widgetClasses: [
					...state.widgetClasses.filter(item => item !== payload),
				],
			};
		}
		case 'SET_SITE': {
			return {
				...state,
				site: payload.site,
				partnerId: payload.partnerId,
			};
		}
		case 'SET_COLOR_MODE': {
			return {
				...state,
				colorMode: payload,
			};
		}
		case 'SET_HISTORY': {
			return {
				...state,
				history: payload,
			};
		}
		case 'SET_MODAL': {
			return {
				...state,
				modal: payload,
			};
		}
		case 'SET_CREDIT': {
			return {
				...state,
				credit: payload,
			};
		}
		case 'SET_DISPLAY_CURRENCY': {
			return {
				...state,
				displayCurrency: payload,
			};
		}
		case 'SET_BANK_ACCOUNTS': {
			return {
				...state,
				credit: Math.round(
					payload?.reduce(
						(total, account) =>
							total + account?.sums?.display?.credit || 0,
						0,
					),
				),
			};
		}
		case 'SET_MODE': {
			return {
				...state,
				mode: payload,
			};
		}
		case 'SET_LAYOUT': {
			return {
				...state,
				layout: payload,
			};
		}
		case 'SET_SEARCH_FOCUS': {
			return {
				...state,
				searchFocus: payload,
			};
		}
		case 'ADD_TO_FACTORING': {
			return {
				...state,
				factoring: [
					...(state.factoring || []),
					...(Array.isArray(payload) ? payload : [payload]),
				],
			};
		}
		case 'SENT_FACTORING': {
			return {
				...state,
				sentToFactoring: [...state.sentToFactoring, ...payload],
			};
		}
		case 'SET_FACTORING': {
			return {
				...state,
				factoring: payload,
			};
		}
		case 'SET_USER': {
			return {
				...state,
				layout: payload?.settings?.layout || {
					graph: {
						layout: 'flat',
						barLayout: 'grouped',
					},
					layout: 'vertical',
					positioning: 'relative',
				},
			};
		}
		case 'SET_ROUTE': {
			return {
				...state,
				route: payload,
			};
		}
		case 'ADD_FILTER': {
			const { id, type, target, config } = payload;
			const { filters = [] } = state;
			return {
				...state,
				filters: [
					...filters.filter(({ id: fId }) => fId !== id),
					{ id, type, target, config },
				],
			};
		}
		case 'ADD_FILTERS': {
			const addFilters = payload;
			const { filters = [] } = state;
			const ids = addFilters.map(({ id }) => id);
			return {
				...state,
				filters: [
					...filters.filter(({ id: fId }) => !ids.includes(fId)),
					...addFilters,
				],
			};
		}
		case 'SET_FILTERS': {
			return {
				...state,
				filters: payload || [],
			};
		}
		case 'REMOVE_FILTER': {
			const id = payload;
			const { filters = [] } = state;
			return {
				...state,
				filters: filters.filter(({ id: fId }) => fId !== id),
			};
		}
		case 'TOGGLE_FILTER': {
			const { id, type, target, config } = payload;
			const { filters } = state;
			const index = filters.findIndex(({ id: fId }) => fId === id);

			return {
				...state,
				filters:
					index === -1
						? [...filters, { id, type, target, config }]
						: filters.filter(({ id: fId }) => fId !== id),
			};
		}
		case 'SET_USER_SETTINGS': {
			return {
				...state,
				settings: {
					...state.settings,
					user: payload,
				},
			};
		}
		case 'SET_ACTIVE_CUSTOMER': {
			return {
				...state,
				activeCustomer: payload,
			};
		}
		case 'SET_TIMESLICE_SIZE':
			return {
				...state,
				timesliceSize: payload,
			};
		case 'SET_CURRENT_DATE':
			return {
				...state,
				currentDate: payload,
			};
		case 'SET_SELECTED_DATE':
			return {
				...state,
				selectedDate: payload,
			};
		case 'SET_SELECTED_TYPE':
			return {
				...state,
				selectedType: payload,
			};
		case 'INC_SIDEPANE':
			return {
				...state,
				sidepanes: (state.sidepanes || 0) + 1,
			};
		case 'DEC_SIDEPANE':
			return {
				...state,
				sidepanes:
					state.sidepanes && state.sidepanes > 0
						? state.sidepanes - 1
						: 0,
			};
		case 'SET_LIST_OPEN': {
			const { open, source } = payload;
			return {
				...state,
				listOpen: open,
				listOpenSource: source,
				adjustOpen: payload === false ? false : state.adjustOpen,
			};
		}
		case 'SET_ADJUST_OPEN': {
			return {
				...state,
				adjustOpen: payload,
			};
		}
		case 'SET_CLIENTS': {
			return {
				...state,
				clients: payload,
			};
		}
		case 'ADD_CLIENT': {
			return {
				...state,
				clients: [...state.clients, payload],
			};
		}
		case 'SET_NUMBER_OF_OVERDUE': {
			const { total = 0, deposit = 0, withdraw = 0 } = payload;
			return {
				...state,
				overdueInvoices: {
					total,
					deposit,
					withdraw,
				},
			};
		}
		case 'SET_NUMBER_OF_OPEN': {
			const { total = 0, deposit = 0, withdraw = 0 } = payload;
			return {
				...state,
				openInvoices: {
					total,
					deposit,
					withdraw,
				},
			};
		}
		case 'SHOW_PROMPT': {
			const { type } = payload;
			const { prompts = [] } = state;
			return {
				...state,
				prompts: [...prompts.filter(({ t }) => t !== type), payload],
			};
		}
		case 'HIDE_PROMPT': {
			const { prompts = [] } = state;
			return {
				...state,
				prompts: prompts.filter(p => p !== payload),
			};
		}
		case 'SET_GRAPH_SIZE': {
			return {
				...state,
				size: {
					...(state?.size || {}),
					graph: {
						...(state?.size?.graph || {}),
						...payload,
					},
				},
			};
		}
		default:
			return state;
	}
};

export default reducer;
