import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { compileStyles, Getter } from '@asteria/utils';
import Button from '@asteria/component-button';
import Text from '@asteria/component-typography/text';

const Box = styled.div``;
Box.displayName = 'InsightBox';

const HeaderWrapper = styled.div``;
HeaderWrapper.displayName = 'InsightHeaderWrapper';

const Header = styled.div``;
Header.displayName = 'InsightHeader';

const ContentWrapper = styled.div``;
ContentWrapper.displayName = 'InsightContentWrapper';

const Content = styled.div``;
Content.displayName = 'InsightContent';

const Insight = styled(
	forwardRef((props, ref) => {
		const { buttonText, className, content, header, onClick } = props;
		return (
			<Box className={className} ref={ref} onClick={onClick}>
				<HeaderWrapper>
					<Header className="asteria-title">
						<Text>{header}</Text>
					</Header>
				</HeaderWrapper>
				<ContentWrapper>
					<Content className="asteria-text">
						<Text>{content}</Text>
					</Content>
				</ContentWrapper>
				<Button text={buttonText} size="regular" type="link" />
			</Box>
		);
	}),
)`
	${({ theme }) => compileStyles(Insight.Styler, theme)}
`;

Insight.displayName = 'Insight';
Insight.Styler = {
	base: [Getter('insight', {})],
	children: [
		{
			base: [Getter('button', {})],
			component: Button,
		},
		{
			base: [Getter('contentWrapper', {})],
			children: [
				{
					base: [Getter('content', {})],
					children: [
						{
							base: [Getter('text', {})],
							component: Text,
						},
					],
					component: Content,
				},
			],
			component: ContentWrapper,
		},
		{
			base: [Getter('headerWrapper', {})],
			children: [
				{
					base: [Getter('header', {})],
					children: [
						{
							base: [Getter('text', {})],
							component: Text,
						},
					],
					component: Header,
				},
			],
			component: HeaderWrapper,
		},
	],
};
export default Insight;
