export default {
	SelectProvider: Symbol('SelectProvider'),
	Splash: Symbol('Splash'),
	Status: Symbol('Status'),
	FortnoxConnecting: Symbol('FortnoxConnecting'),
	AsteriaConnecting: Symbol('AsteriaConnecting'),
	AsteriaBankConnecting: Symbol('AsteriaBankConnecting'),
	VismaConnecting: Symbol('VismaConnecting'),
	WintConnecting: Symbol('WintConnecting'),
	BjornLundenConnecting: Symbol('BjornLundenConnecting'),
	HandelsbankenConnecting: Symbol('HandelsbankenConnecting'),
	SEBConnecting: Symbol('SEBConnecting'),
	ProcountorConnecting: Symbol('ProcountorConnecting'),
	SwedbankConnecting: Symbol('SwedbankConnecting'),
	NordeaConnecting: Symbol('NordeaConnecting'),
};
