import React, { useCallback, useContext, useState } from 'react';
import Button from '@asteria/component-button/button';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import classNames from 'classnames';
import styled from 'styled-components';
import { selectLanguage } from '@asteria/services-datalayer/services/language/actions';
import { useStore } from '@asteria/utils';
import Signup from './signup';
import Login from './login';
import RestorePass from './restorePass';

/**
 * Store here authentication component types in a way, convenient for next use in
 * template
 */
const authComponents = {
	signup: props => <Signup path="signup" {...props} />,
	login: props => <Login path="login" {...props} />,
	restorePass: props => <RestorePass path="restorePass" {...props} />,
};

/**
 * Authentication component
 */

const Authentication = styled(({ stage = 'login' }) => {
	const [currentStage, setStage] = useState(stage);
	const { dispatch } = useContext(DatalayerContext);
	// Select AuthComponent to render depending on active stage
	const AuthComponent = authComponents[currentStage]
		? authComponents[currentStage]
		: // Select any first one if provided stage is incorrect
		  authComponents[Object.keys(authComponents)[0]];

	const changeLanguage = useCallback(
		language => {
			localStorage.asteriaLanguage = language;
			dispatch(selectLanguage(language));
		},
		[dispatch],
	);
	// eslint-disable-next-line no-unused-vars
	const [language] = useStore('store-language', store => store.language);

	const currentLanguage = localStorage.asteriaLanguage || 'sv';

	return (
		<div className={classNames('asteria-authentication')}>
			<AuthComponent stage={currentStage} moveToStage={setStage} />
			<div className={classNames('asteria-site-language')}>
				<Button
					text="SV"
					size="small"
					type="link"
					className={
						currentLanguage === 'sv' ? 'asteria-state-active' : ''
					}
					onClick={() => changeLanguage('sv')}
				/>
				<Button
					text="EN"
					size="small"
					type="link"
					className={
						currentLanguage === 'en' ? 'asteria-state-active' : ''
					}
					onClick={() => changeLanguage('en')}
				/>
				<Button
					text="FR"
					size="small"
					type="link"
					className={
						currentLanguage === 'fr' ? 'asteria-state-active' : ''
					}
					onClick={() => changeLanguage('fr')}
				/>
				<Button
					text="ES"
					size="small"
					type="link"
					className={
						currentLanguage === 'es' ? 'asteria-state-active' : ''
					}
					onClick={() => changeLanguage('es')}
				/>
				<Button
					text="PT"
					size="small"
					type="link"
					className={
						currentLanguage === 'pt' ? 'asteria-state-active' : ''
					}
					onClick={() => changeLanguage('pt')}
				/>
				{/* 				<Button
					text="BR"
					size="small"
					type="link"
					className={
						currentLanguage === 'br' ? 'asteria-state-active' : ''
					}
					onClick={() => changeLanguage('br')}
				/> */}
			</div>
		</div>
	);
})`
	width: 100%;
	align-items: center;
	justify-content: center;
`;

Authentication.displayName = 'Authentication';

export default Authentication;
