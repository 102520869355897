import React from 'react';
import styled from 'styled-components';
import Icon from '@asteria/component-icon';
import { compileStyles, makePath, StyleGetter } from '@asteria/utils';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Text from './basic/text';
// import themeV2 from './theme_sb/index';
//
// console.log(themeV2, '???');

// Wrap Text component with styler sidebar
const StyledLabel = React.forwardRef(({ children, ...componentProps }, ref) => (
	<Text ref={ref} {...componentProps}>
		{children}
	</Text>
));

/**
 * Block wrapper for check mark
 */
export const CheckMarkBox = styled.div`
	box-sizing: border-box;
`;

/**
 * Checkbox item (checkmark + label) wrapper
 */
export const CheckboxWrapper = styled.div`
	display: flex;
`;

/**
 * Checkbox item component
 */
export const CheckBox = React.forwardRef(
	(
		{
			item,
			styler,
			checked = false,
			onClick,
			labelPosition,
			position = 'default',
			component = 'checkbox',
		},
		ref,
	) => {
		const pathPrefix = checked ? 'types.checked' : null;
		return (
			<CheckboxWrapper
				ref={ref}
				style={{
					flexDirection:
						labelPosition === 'above' || labelPosition === 'under'
							? 'column'
							: 'row',
				}}
				onClick={onClick}
				className={classNames(
					'asteria-checkbox-wrapper',
					checked ? 'asteria-checked' : '',
				)}
			>
				<CheckMarkBox
					element="iconWrapper"
					className="asteria-checkbox-mark"
					position={position}
					checked={checked}
					style={{
						order: 1,
					}}
					path={makePath(pathPrefix, 'mark')}
					styler={styler}
				>
					{checked ? (
						<Icon
							asset={component === 'checkbox' ? 'check' : 'radio'}
							styles={{}}
							styler={styler}
							path={makePath(pathPrefix, 'icon')}
							editStyle
							editAsset={false}
						/>
					) : null}
				</CheckMarkBox>
				<StyledLabel
					checked={checked}
					position={position}
					className="asteria-checkbox-label"
					style={{
						order:
							labelPosition === 'above' ||
							labelPosition === 'before'
								? 0
								: 2,
					}}
					path={makePath(pathPrefix, 'label')}
					styler={styler}
				>
					{item?.label !== undefined ? item.label : item}
				</StyledLabel>
			</CheckboxWrapper>
		);
	},
);

CheckBox.propTypes = {
	item: PropTypes.object.isRequired,
	checked: PropTypes.bool,
	position: PropTypes.string,
	component: PropTypes.string,
	styler: PropTypes.bool,
};

CheckBox.defaultProps = {
	styler: false,
	checked: false,
	position: 'default',
	component: 'checkbox',
};

/**
 * Checkbox group component
 */
const CheckboxGroup = styled(
	React.forwardRef(
		(
			{
				className,
				items = [],
				selected = [],
				labelPosition,
				onChange,
				styler,
			},
			ref,
		) => (
			<div
				ref={ref}
				className={classNames(
					className,
					'asteria-checkbox-group-wrapper',
				)}
			>
				{items.map((item, index) => {
					const isActive =
						selected.includes(index) || selected.includes(item);
					return (
						<CheckBox
							key={item?.value ? item.value : item}
							item={item}
							checked={isActive}
							labelPosition={labelPosition}
							onClick={e => {
								e.stopPropagation();
								onChange(index, item, isActive);
							}}
							styler={styler}
							path="checkboxElement"
						/>
					);
				})}
			</div>
		),
	),
)`
	display: flex;

	${({ theme }) => compileStyles(CheckboxGroup, theme)}
`;

CheckboxGroup.Styler = {
	base: [StyleGetter(`formV2.checkbox`, {})],
	typePrefix: 'asteria-checkbox-group-wrapper',
	children: [
		{
			component: CheckboxWrapper,
			base: [StyleGetter(`checkboxElement`, {})],
			children: [
				{
					component: CheckMarkBox,
					base: [StyleGetter(`mark`, {})],
					children: [
						{
							component: Icon,
							base: [StyleGetter(`icon`, {})],
						},
					],
				},
				{
					component: Text,
					base: [StyleGetter(`label`, {})],
				},
			],
		},
	],
};

CheckboxGroup.propTypes = {
	items: PropTypes.array,
	selected: PropTypes.array,
	styler: PropTypes.bool,
	labelPosition: PropTypes.string,
	onChange: PropTypes.func,
};

CheckboxGroup.defaultProps = {
	styler: false,
	items: [],
	selected: [],
	labelPosition: '',
	onChange: () => {},
};

export default CheckboxGroup;
