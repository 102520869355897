import React, { useCallback, useMemo, useContext } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Getter, formatNumber, useStore } from '@asteria/utils';
import { Text } from '@asteria/component-core/typography';
import { FeatureFlag, useFeature } from '@asteria/component-featureflag';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { toggleSelectedRow } from '@asteria/services-datalayer/services/list/actions';
import { addDays, format } from 'date-fns';
import Icon from '@asteria/component-core/icon';
import Button from '@asteria/component-core/button';
import Tooltip from '@asteria/component-tooltip';
import { TranslationService } from '@asteria/services-language';
import { CheckboxGroup, Dropdown } from '@asteria/component-form';
import { useLocation } from '@asteria/utils/hooks';
import { showModal } from '@asteria/services-datalayer/services/appstate/actions';

import getStoreValue from '@asteria/services-datalayer/utils/get';
import {
	editTransactionField,
	removeTransactions,
	saveEditedTransactions,
} from '@asteria/services-datalayer/services/adjustable/actions';
import Tags from '../components/tags';
import SecurityBar from '../components/securityBar';
import Cell from './cell';
import { getVolatilityText } from '../utils';

const composedPath = el => {
	const path = [];
	let currentEl = el;

	while (currentEl) {
		path.push(currentEl);

		if (currentEl.tagName === 'HTML') {
			path.push(document);
			path.push(window);

			return path;
		}

		currentEl = currentEl.parentElement;
	}

	return path;
};

const DropdownOptionsForecast = [
	{
		value: 'edit',
		label: 'transaction.options.edit',
	},
	{
		value: 'remove',
		label: 'transaction.options.remove',
	},
	{
		value: 'details',
		label: 'transaction.options.details',
	},
];

const DropdownOptions = [
	{
		value: 'details',
		label: 'transaction.options.details',
	},
];

const CATEGORIES = [
	{
		value: { tag: '$customer', category: '$invoices', type: 'DEPOSIT' },
		label: 'Kundfakturor',
		id: 'customer',
		icon: '$tag',
		color: '$customer',
		new: true,
	},
	{
		value: { tag: '$supplier', category: '$invoices', type: 'WITHDRAW' },
		label: 'Leverantörsfakturor',
		id: 'supplier',
		icon: '$tag',
		color: '$supplier',
		new: true,
	},
	{
		value: { tag: '$salary', category: '$salaries', type: 'WITHDRAW' },
		label: 'Löner',
		id: 'salary',
		icon: '$tag',
		color: '$salary',
		new: true,
	},
	{
		value: { tag: '$tax', category: '$taxes', type: 'WITHDRAW' },
		label: 'Skatter',
		id: 'tax',
		icon: '$tag',
		color: '$tax',
		new: true,
	},
	{
		value: { tag: '$misc', category: '$withdraw', type: 'WITHDRAW' },
		label: 'Övrigt Utgifter',
		id: 'misc_withdraw',
		icon: '$tag',
		color: '$misc',
		new: true,
	},
	{
		value: { tag: '$misc', category: '$deposit', type: 'DEPOSIT' },
		label: 'Övrigt Inkomster',
		id: 'misc_desposit',
		icon: '$tag',
		color: '$misc',
		new: true,
	},
].sort((a, b) => a.label.localeCompare(b.label));

const getClientId = item => {
	const links = item?.links || [];

	if (links.find(({ type }) => type === 'CLIENT')) {
		const client = links.find(({ type }) => type === 'CLIENT');
		return client?.id || false;
	}

	if (links.find(({ type }) => type === 'INVOICE_PAYMENT')) {
		const invoice = links.find(({ type }) => type === 'INVOICE_PAYMENT');
		return invoice?.invoiceDetails?.client?.id || false;
	}

	return false;
};

const getDescription = (item, edit) => {
	if (edit && getClientId(item)) {
		return getClientId(item);
	}

	if (item?.description?.startsWith('$')) {
		return TranslationService.get(
			`list.transactions.transaction.description.${item?.description.replace(
				'$',
				'',
			)}`,
		);
	}

	return item?.description || 'transaction.unknown';
};

const getPaymentDate = item => item?.paymentDate || new Date();
const getTotal = item => Math.round(item?.sums?.original?.total || 0);

const getRiskHigh = item => {
	if (item.status === 'PAID') {
		if (item?.oracle?.currency?.risk < 1) {
			return 0;
		}

		return item?.oracle?.currency?.max?.display?.total;
	}

	if (item.status === 'OVERDUE') {
		return 0;
	}

	return item?.oracle?.currency?.min?.display?.total;
};

const getRiskLow = item => {
	if (item.status === 'PAID') {
		if (item?.oracle?.currency?.risk >= 1) {
			return 0;
		}

		return item?.oracle?.currency?.max?.display?.total;
	}

	if (item.status === 'OVERDUE') {
		return 0;
	}

	return item?.oracle?.currency?.max?.display?.total;
};

const getCurrency = item => item?.sums?.original?.currency || 'SEK';

const getPrimaryTag = item => {
	const t = item?.meta?.tags || [];
	const [primaryTag] = t.slice(0, 1);

	const option = CATEGORIES.find(
		({ value: { tag, category } }) =>
			primaryTag?.name === tag && primaryTag?.category?.name === category,
	);

	return option ? option.value : undefined;
};

const hasFieldAction = ({ fields = [] }, field) => fields.includes(field);

const Currencies = [
	{ value: 'SEK', label: 'SEK', icon: '$currency', color: 'SEK', new: true },
	{ value: 'NOK', label: 'NOK', icon: '$currency', color: 'NOK', new: true },
	{ value: 'DKK', label: 'DKK', icon: '$currency', color: 'DKK', new: true },
	{ value: 'GBP', label: 'GBP', icon: '$currency', color: 'GBP', new: true },
	{ value: 'CHF', label: 'CHF', icon: '$currency', color: 'CHF', new: true },
	{ value: 'JPY', label: 'JPY', icon: '$currency', color: 'JPY', new: true },
	{ value: 'CNY', label: 'CNY', icon: '$currency', color: 'CNY', new: true },
	{ value: 'USD', label: 'USD', icon: '$currency', color: 'USD', new: true },
	{ value: 'EUR', label: 'EUR', icon: '$currency', color: 'EUR', new: true },
].sort((a, b) => a.label.localeCompare(b.label));

const Row = styled(
	React.memo(
		({
			className,
			edit = false,
			editGroup,
			onClick,
			item = {},
			edits = {},
			tagFilter = [],
			actions = {},
			setEdit,
		}) => {
			const { dispatch, lookup } = useContext(DatalayerContext);
			const { pathname } = useLocation();
			const mainTag = item?.meta?.tags?.[0];
			const isNew =
				(actions && actions.operation === 'CREATE') || item?.$new;
			const isFactoringMode = pathname === '/factoring';
			const clientId = getClientId(item);
			const isForecast = item?.status === 'FORECAST';
			const isForecastEdit = edit && isForecast;

			const hasFxModule = useFeature({
				key: 'fx-module',
			});

			const [factoring = []] = useStore(
				'store-appstate',
				state => state.factoring,
			);

			const securityToolTipTitle = '';
			const securityToolTipContent = TranslationService.get(
				`list.transactions.transaction.security.tooltip.${item?.status?.toLowerCase()}.content`,
				null,
				{
					item,
					volatilityText: getVolatilityText(item?.oracle?.risk || 0),
				},
			);

			const statusTooltipTitle = '';
			const statusTooltipContent = TranslationService.get(
				[
					`list.transactions.transaction.status.tooltip.${item?.status?.toLowerCase()}.content`,
					`list.transactions.transaction.status.tooltip.${mainTag?.name?.replace(
						'$',
						'',
					)}.${item?.status?.toLowerCase()}.content`,
				],
				null,
				{ item },
			);

			const totalTooltipTitle = '';
			const totalTooltipContent = [];

			const forecastTooltipContent = TranslationService.get(
				`list.transactions.transaction.total.tooltip.${item?.status?.toLowerCase()}.content`,
				null,
				{ item },
			);

			if (
				item?.oracle?.forecast?.min?.display?.total !==
				item?.oracle?.forecast?.max?.display?.total
			) {
				totalTooltipContent.push(
					<p key="tooltip-text-forecast">{forecastTooltipContent}</p>,
				);
			}

			const totalCurrencyTooltipContent = TranslationService.get(
				`list.transactions.transaction.total.currency.tooltip.content`,
				null,
				{ item },
			);

			if (
				item?.status !== 'PAID' &&
				item?.status !== 'OVERDUE' &&
				hasFxModule &&
				item?.oracle?.currency &&
				item?.oracle?.currency?.risk !== 1 &&
				item?.oracle?.currency?.min?.display?.total !==
					item?.oracle?.currency?.max?.display?.total
			) {
				totalTooltipContent.push(
					<p key="tooltip-text-currency">
						{totalCurrencyTooltipContent}
					</p>,
				);
			}

			let descriptionSubText = TranslationService.get(
				item?.meta?.tags?.map(
					tag =>
						`list.transactions.transaction.description.${tag?.name?.replace(
							'$',
							'',
						)}.subtext`,
				),
				'',
				{ item },
			);

			if (item.message) {
				descriptionSubText = TranslationService.get(
					item.message,
					item.message,
					{ item },
				);
			}

			let [isSelected] = useStore(
				'store-list',
				({ state: { selected = [] } = {} }) =>
					selected.find(({ id }) => id === item.id),
			);

			let fixedSelected = false;
			if (
				isFactoringMode &&
				clientId &&
				factoring.find(({ client: { id } = {} }) => clientId === id)
			) {
				isSelected = true;
				fixedSelected = true;
			}

			if (isFactoringMode && isSelected) {
				// eslint-disable-next-line no-param-reassign
				actions = {
					fields: [...(actions.fields || []), 'paymentDate'],
					transaction: {
						...(actions.transaction || {}),
						paymentDate: addDays(item.paymentDate, -30),
					},
				};
			}

			const toggleSelected = useCallback(() => {
				if (!fixedSelected) {
					dispatch(toggleSelectedRow(item));
				}
			}, [dispatch, fixedSelected, item]);

			const tags = useMemo(() => {
				const t = item?.meta?.tags || [];
				if (tagFilter.length !== 0) {
					return t.filter(tag => tagFilter.includes(tag?.id));
				}

				return t.slice(0, 1);
			}, [item, tagFilter]);

			const descriptionEdit = useMemo(() => {
				if (!isForecastEdit) {
					return false;
				}

				const clients = getStoreValue(
					lookup('store-appstate'),
					({ clients: list }) => list,
				);

				let itemType = edits.type || 'free';

				if (!edits.type && getClientId(item)) {
					const itemClientId = getClientId(item);
					itemType =
						clients?.find(
							({ _id, id = _id }) => id === itemClientId,
						)?.type || 'free';
				}

				if (
					(getClientId(item) && edits.type !== 'free') ||
					edits.type === 'supplier' ||
					edits.type === 'customer'
				) {
					return [
						{
							label: TranslationService.get([
								'list.adjustable.description.add',
								`list.adjustable.description.add.${itemType}`,
							]),
							value: {
								id: 'addClient',
								action: 'showModal',
								modal: 'addClient',
								data: {
									clientType: itemType.toUpperCase(),
								},
							},
						},
					].concat(
						getStoreValue(
							lookup('store-appstate'),
							({ clients: list }) => list,
						)
							.filter(
								({ type }) => type === itemType.toUpperCase(),
							)
							.map(({ name, id }) => ({
								label: name,
								value: id,
							})),
					);
				}

				return 'text';
			}, [edits.type, isForecastEdit, item, lookup]);

			const transactionType = useMemo(() => {
				const itemTags = item?.meta?.tags || [];
				if (itemTags.some(t => t.name === '$customer')) {
					return 'customer';
				}

				if (itemTags.some(t => t.name === '$supplier')) {
					return 'supplier';
				}

				return 'free';
			}, [item.meta.tags]);

			const useTransactiopnRowClick = useFeature({
				key: 'transaction-row-click',
			});

			const toggleTransaction = useCallback(
				e => {
					const path =
						e.path ||
						(e.composedPath && e.composedPath()) ||
						composedPath(e.target) ||
						[];

					if (
						!edit &&
						!path.find(p =>
							p?.classList?.contains('asteria-forms-dropdown'),
						)
					) {
						onClick(item);
					}
				},
				[edit, item, onClick],
			);

			const onEdit = useCallback(
				(displayField, value, isSame) => {
					const { id } = item;
					const type = edits.type || transactionType;

					const field = displayField;

					if (value && value.action === 'showModal') {
						dispatch(showModal(value.modal, value.data));
						return;
					}

					if (isSame) {
						dispatch(
							editTransactionField({ id, field, value: null }),
						);
					} else {
						dispatch(
							editTransactionField({
								id,
								field: '$original',
								value: item,
							}),
						);

						dispatch(editTransactionField({ id, field, value }));

						if (displayField === 'description' && type !== 'free') {
							dispatch(
								editTransactionField({
									id,
									field: 'client',
									value,
								}),
							);
						}

						if (field === 'total' && !edits.currency) {
							dispatch(
								editTransactionField({
									id,
									field: 'currency',
									value: item.sums.original.currency,
								}),
							);
						}

						if (field === 'categories') {
							if (
								value &&
								value.tag === '$customer' &&
								type !== 'customer'
							) {
								dispatch(
									editTransactionField({
										id,
										field: 'client',
										value: 'none',
									}),
								);
								dispatch(
									editTransactionField({
										id,
										field: 'description',
										value: 'none',
									}),
								);
								dispatch(
									editTransactionField({
										id,
										field: 'type',
										value: 'customer',
									}),
								);
							} else if (
								value &&
								value.tag === '$supplier' &&
								type !== 'supplier'
							) {
								dispatch(
									editTransactionField({
										id,
										field: 'client',
										value: 'none',
									}),
								);

								dispatch(
									editTransactionField({
										id,
										field: 'description',
										value: 'none',
									}),
								);

								dispatch(
									editTransactionField({
										id,
										field: 'type',
										value: 'supplier',
									}),
								);
							} else if (
								value &&
								value.tag !== '$customer' &&
								value.tag !== '$supplier'
							) {
								dispatch(
									editTransactionField({
										id,
										field: 'client',
										value: undefined,
									}),
								);

								dispatch(
									editTransactionField({
										id,
										field: 'description',
										value: '',
									}),
								);

								dispatch(
									editTransactionField({
										id,
										field: 'type',
										value: 'free',
									}),
								);
							} else {
								dispatch(
									editTransactionField({
										id,
										field: 'client',
										value: 'none',
									}),
								);

								dispatch(
									editTransactionField({
										id,
										field: 'description',
										value: 'none',
									}),
								);

								dispatch(
									editTransactionField({
										id,
										field: 'type',
										value: value.tag.replace('$', ''),
									}),
								);
							}
						}
					}
				},
				[item, edits, transactionType, dispatch],
			);

			// const removeTimer = useRef(null);
			const triggerRemove = useCallback(() => {
				dispatch(removeTransactions([item]));
			}, [dispatch, item]);

			const handleOptions = useCallback(
				({ value }) => {
					if (value === 'edit') {
						setEdit(true);
					} else if (value === 'remove') {
						onEdit(
							'$removed',
							!edits.$removed,
							!edits.$removed === false,
						);

						triggerRemove();
					} else if (value === 'details') {
						onClick(item);
					}
				},
				[edits.$removed, item, onClick, onEdit, setEdit, triggerRemove],
			);

			return (
				<div
					className={classNames(
						className,
						'asteria-transaction-wrapper-inner',
						{
							'asteria-transaction-edit': edit,
							'asteria-transaction-edit-removed': edits.$removed,
						},
					)}
					onClick={useTransactiopnRowClick && toggleTransaction}
					onKeyPress={useTransactiopnRowClick && toggleTransaction}
					role="button"
					tabIndex="-1"
				>
					<Cell
						field="status"
						onEdit={onEdit}
						edits={edits.status}
						className={classNames(
							item?.meta?.tags
								?.slice(0, 1)
								?.map(
									tag =>
										`asteria-transaction-cell-status-${tag?.name?.replace(
											'$',
											'',
										)}`,
								),
						)}
					>
						<FeatureFlag feature="adjustable" invert>
							<Tooltip
								className="asteria-tooltip-transaction-cell-status"
								hover
								isStatic
								title={statusTooltipTitle}
								content={statusTooltipContent}
							>
								<Icon
									className={classNames(
										'asteria-icon-status',
									)}
									asset={[
										item?.status?.toLowerCase(),
										`${item?.type?.toLowerCase()}${item?.status?.toLowerCase()}`,
									]}
								/>
								<Icon
									className={classNames(
										'asteria-icon-tag',
										item?.meta?.tags
											?.slice(0, 1)
											?.map(
												tag =>
													`asteria-icon-${tag?.name?.replace(
														'$',
														'',
													)}`,
											),
									)}
									asset={[
										item?.status?.toLowerCase(),
										`${item?.type?.toLowerCase()}${item?.status?.toLowerCase()}`,
										...(
											item?.meta?.tags
												?.slice(0, 1)
												?.map(tag => [
													`${tag?.name?.replace(
														'$',
														'',
													)}`,
													`${tag?.name?.replace(
														'$',
														'',
													)}${item?.type?.toLowerCase()}`,
													`${tag?.name?.replace(
														'$',
														'',
													)}${item?.status?.toLowerCase()}`,
													`${tag?.name?.replace(
														'$',
														'',
													)}${item?.type?.toLowerCase()}${item?.status?.toLowerCase()}`,
												]) || []
										).flat(),
									]}
								/>
							</Tooltip>
						</FeatureFlag>
						{isFactoringMode ? (
							<FeatureFlag feature="factoring">
								{(item.status === 'UNPAID' ||
									item.status === 'FORECAST') &&
								!item?.meta?.tags?.find(
									tag => tag?.name === '$factoring',
								) &&
								item.links.find(
									({ type, invoiceDetails }) =>
										type === 'INVOICE_PAYMENT' &&
										invoiceDetails?.client?.type ===
											'CUSTOMER',
								) ? (
									<CheckboxGroup
										items={[
											{ value: 'selected', label: '' },
										]}
										selected={isSelected ? [0] : []}
										onChange={toggleSelected}
									/>
								) : null}
							</FeatureFlag>
						) : (
							<FeatureFlag feature="adjustable">
								{item.status === 'FORECAST' && !isNew ? (
									<CheckboxGroup
										items={[
											{ value: 'selected', label: '' },
										]}
										selected={isSelected ? [0] : []}
										onChange={toggleSelected}
									/>
								) : null}
							</FeatureFlag>
						)}
					</Cell>
					<Cell
						field="description"
						edits={edits.description}
						getter={getDescription}
						edit={
							!item?.links?.some(
								({ type }) => type === 'INVOICE_PAYMENT',
							) && descriptionEdit
						}
						className={classNames({
							'asteria-state-disabled':
								descriptionEdit &&
								item?.links?.some(
									({ type }) => type === 'INVOICE_PAYMENT',
								),
						})}
						tooltip={
							descriptionEdit &&
							item?.links?.some(
								({ type }) => type === 'INVOICE_PAYMENT',
							)
								? TranslationService.get(
										'transaction.adjustable.no_edit',
										'Du kan tyvärr inte ändra följande fält då de är kopplat till en prognostiserad faktura. Utifall detta fält är inkorrekt, kan du ta bort transaktionen och lägg till en ny med korrekta värden',
								  )
								: false
						}
						data={item}
						onEdit={onEdit}
					>
						<Text size="body">{getDescription(item)}</Text>
						{descriptionSubText ? (
							<Text size="small">{descriptionSubText}</Text>
						) : null}
					</Cell>
					<Cell
						field="payment-date"
						edits={edits['payment-date']}
						getter={getPaymentDate}
						edit={isForecastEdit ? 'date' : false}
						data={item}
						onEdit={onEdit}
					>
						{hasFieldAction(actions, 'paymentDate') ? (
							<Text size="body">
								{format(
									actions?.transaction?.paymentDate ||
										new Date(),
									'YYYY-MM-DD',
								)}
							</Text>
						) : null}
						<Text
							size="body"
							className={classNames({
								'asteria-text-modified': hasFieldAction(
									actions,
									'paymentDate',
								),
							})}
							style={{
								textDecoration: hasFieldAction(
									actions,
									'paymentDate',
								)
									? 'line-through'
									: 'initial',
							}}
						>
							{format(
								item?.paymentDate || new Date(),
								'YYYY-MM-DD',
							)}
						</Text>
						{/* <FeatureFlag feature="transaction-date-below">
							<Text size="small">2020-04-01</Text>
						</FeatureFlag> */}
					</Cell>
					<FeatureFlag feature="transaction-row-single-tag">
						<Cell
							field="categories"
							onEdit={onEdit}
							edits={edits.categories}
							getter={getPrimaryTag}
							tooltip={
								descriptionEdit &&
								item?.links?.some(
									({ type }) => type === 'INVOICE_PAYMENT',
								)
									? TranslationService.get(
											'transaction.adjustable.no_edit',
											'Du kan tyvärr inte ändra följande fält då de är kopplat till en prognostiserad faktura. Utifall detta fält är inkorrekt, kan du ta bort transaktionen och lägg till en ny med korrekta värden',
									  )
									: false
							}
							edit={
								isForecastEdit &&
								!item?.links?.some(
									({ type }) => type === 'INVOICE_PAYMENT',
								)
									? CATEGORIES
									: undefined
							}
							className={classNames({
								'asteria-state-disabled':
									descriptionEdit &&
									item?.links?.some(
										({ type }) =>
											type === 'INVOICE_PAYMENT',
									),
							})}
							data={item}
						>
							<Tags item={item} tags={tags} />
						</Cell>
					</FeatureFlag>
					<FeatureFlag feature="transaction-row-single-tag" invert>
						<Cell
							field="categories"
							onEdit={onEdit}
							edits={edits.categories}
							getter={getPrimaryTag}
							edit={
								isForecastEdit &&
								!item?.links?.some(
									({ type }) => type === 'INVOICE_PAYMENT',
								)
									? CATEGORIES
									: undefined
							}
							data={item}
						>
							<Tags item={item} />
						</Cell>
					</FeatureFlag>
					<FeatureFlag feature="List - flat status">
						<Cell
							field="flat-status"
							onEdit={onEdit}
							edits={edits['flat-status']}
						>
							<FeatureFlag feature="adjustable">
								<Cell
									className={classNames(
										'asteria-transaction-cell',
										'asteria-transaction-cell-status',
										item?.meta?.tags
											?.slice(0, 1)
											?.map(
												tag =>
													`asteria-transaction-cell-status-${tag?.name?.replace(
														'$',
														'',
													)}`,
											),
									)}
									style={{
										float: 'left',
										width: 'auto',
										// marginRight: '0px',
									}}
								>
									<Tooltip
										className="asteria-tooltip-transaction-cell-status"
										hover
										isStatic
										title={statusTooltipTitle}
										content={statusTooltipContent}
									>
										<Icon
											className={classNames(
												'asteria-icon-status',
											)}
											asset={[
												item?.status?.toLowerCase(),
												`${item?.type?.toLowerCase()}${item?.status?.toLowerCase()}`,
											]}
										/>
									</Tooltip>
								</Cell>
							</FeatureFlag>
							<FeatureFlag
								feature="transaction-row-source"
								invert
							>
								<Text size="body">
									{TranslationService.get(
										`list.transactions.transaction.status.${item?.status?.toLowerCase()}`,
									)}
								</Text>
							</FeatureFlag>
							<FeatureFlag feature="transaction-row-source">
								<div style={{ flexDirection: 'row' }}>
									<Text size="body">
										{TranslationService.get(
											`list.transactions.transaction.status.${item?.status?.toLowerCase()}`,
										)}
									</Text>
									{item?.status === 'FORECAST' ? (
										<Text
											size="small"
											style={{
												color: '#696969',
												fontSize: '12px',
											}}
										>
											{TranslationService.get(
												item.manual
													? 'list.transactions.transaction.source.user'
													: 'list.transactions.transaction.source.asteria',
											)}
										</Text>
									) : null}
								</div>
							</FeatureFlag>
						</Cell>
					</FeatureFlag>
					<FeatureFlag feature="fx-module">
						<Cell
							field="risk-high"
							onEdit={onEdit}
							edits={edits['risk-high']}
						>
							{item?.sums?.display?.currency !==
								item?.sums?.original?.currency &&
								item?.oracle?.currency &&
								item?.oracle?.currency.risk &&
								getRiskHigh(item) !== 0 && (
									<Text size="body">
										{formatNumber(
											getRiskHigh(item),
											false,
											false,
											true,
										)}
									</Text>
								)}
						</Cell>
						<Cell
							field="risk-low"
							onEdit={onEdit}
							edits={edits['risk-low']}
						>
							{item?.sums?.display?.currency !==
								item?.sums?.original?.currency &&
								item?.oracle?.currency &&
								item?.oracle?.currency.risk &&
								getRiskLow(item) !== 0 && (
									<Text size="body">
										{formatNumber(
											getRiskLow(item),
											false,
											false,
											true,
										)}
									</Text>
								)}
						</Cell>
						<Cell field="risk" onEdit={onEdit} edits={edits.risk}>
							<div
								style={{
									flexDirection: 'column',
									textAlign: 'right',
									marginLeft: 'auto',
								}}
							>
								{item?.sums?.display?.currency !==
									item?.sums?.original?.currency &&
									item?.oracle?.currency &&
									item?.oracle?.currency.risk &&
									getRiskHigh(item) !== 0 && (
										<Text size="body">
											{`±${formatNumber(
												getRiskHigh(item),
												false,
												false,
												true,
											)}`}
										</Text>
									)}
							</div>
						</Cell>
					</FeatureFlag>
					<Cell
						field="currency"
						edit={isForecastEdit ? Currencies : false}
						getter={getCurrency}
						data={item}
						onEdit={onEdit}
						edits={edits.currency}
					>
						<div>
							<div
								className={`currency-flag currency-flag-${item?.sums?.original?.currency?.toLowerCase()}`}
							/>
							<Text size="body">
								{item?.sums?.original?.currency}
							</Text>
						</div>
					</Cell>
					<Cell
						field="total"
						edits={
							edits.total ||
							(hasFieldAction(actions, 'sums.original.total')
								? actions?.transaction?.sums?.original?.total ||
								  0
								: undefined)
						}
						getter={getTotal}
						edit={isForecastEdit ? 'number' : false}
						data={item}
						onEdit={onEdit}
					>
						<Tooltip
							className="asteria-tooltip-transaction-cell-amount"
							hover
							isStatic
							title={totalTooltipTitle}
							content={
								totalTooltipContent.length > 0
									? totalTooltipContent
									: null
							}
							style={{ maxWidth: '190px', minWidth: '0' }}
						>
							<div
								style={{
									flexDirection: 'column',
									textAlign: 'right',
									marginLeft: 'auto',
								}}
							>
								{hasFieldAction(
									actions,
									'sums.original.total',
								) ? (
									<Text size="body">
										{`${formatNumber(
											actions?.transaction?.sums?.original
												?.total || 0,
										)} ${item?.sums?.display?.currency ||
											''}`}
									</Text>
								) : null}
								<Text
									size="body"
									className={classNames({
										'asteria-text-modified': hasFieldAction(
											actions,
											'sums.original.total',
										),
									})}
									style={{
										textDecoration: hasFieldAction(
											actions,
											'sums.original.total',
										)
											? 'line-through'
											: 'initial',
									}}
								>
									{`${formatNumber(
										item?.sums?.display?.total || 0,
										item?.type === 'WITHDRAW',
									)} ${item?.sums?.display?.currency || ''}`}
								</Text>
								<FeatureFlag feature="transaction-currency">
									{item?.sums?.original?.currency !==
									item?.sums?.display?.currency ? (
										<Text size="small">
											{`${formatNumber(
												item?.sums?.original?.total ||
													0,
												item?.type === 'WITHDRAW',
											)} ${item?.sums?.original
												?.currency || ''}`}
										</Text>
									) : null}
								</FeatureFlag>
							</div>
						</Tooltip>
						<Tooltip
							className="asteria-tooltip-transaction-cell-security"
							hover
							isStatic
							content={securityToolTipContent}
							title={securityToolTipTitle}
						>
							<SecurityBar
								value={item?.oracle?.risk}
								max={1}
								steps={5}
							/>
						</Tooltip>
					</Cell>
					<FeatureFlag feature="transaction-options-dropdown" invert>
						<Cell
							field="toggle"
							onEdit={onEdit}
							edits={edits.toggle}
						>
							{edit && (
								<Button
									role="button"
									className="asteria-transaction-cell-remove"
									tabIndex="-1"
									icon={
										edits.$removed
											? 'undo'
											: 'circleMinusFilled'
									}
									type="link"
									size="small"
									title={TranslationService.get(
										edits.$removed
											? 'list.transaction.adjustable.revert.tooltip'
											: 'list.transaction.adjustable.remove.tooltip',
									)}
									onClick={() =>
										onEdit(
											'$removed',
											!edits.$removed,
											!edits.$removed === false,
										)
									}
								/>
							)}
							{!edit && (
								<>
									<FeatureFlag
										feature="sidepane-transaction-detail"
										invert
									>
										<Button
											onClick={() => onClick(item)}
											onKeyPress={() => onClick(item)}
											role="button"
											tabIndex="-1"
											icon="down"
											type="link"
											size="small"
										/>
									</FeatureFlag>
									<FeatureFlag feature="sidepane-transaction-detail">
										<Button
											onClick={() => onClick(item)}
											onKeyPress={() => onClick(item)}
											role="button"
											tabIndex="-1"
											icon="right"
											type="link"
											size="small"
										/>
									</FeatureFlag>
								</>
							)}
						</Cell>
					</FeatureFlag>
					<FeatureFlag feature="transaction-options-dropdown">
						<Cell field="options">
							{!edit && (
								<Dropdown
									size="medium"
									type="link"
									iconClosed="moredotted"
									iconOpen="moredotted"
									onChange={handleOptions}
									options={
										isForecast
											? DropdownOptionsForecast
											: DropdownOptions
									}
								/>
							)}
							{edit &&
								editGroup &&
								item?.status === 'FORECAST' && (
									<Button
										role="button"
										className="asteria-transaction-cell-remove"
										tabIndex="-1"
										icon={
											edits.$removed ? 'undo' : 'trashcan'
										}
										type="link"
										size="small"
										title={TranslationService.get(
											edits.$removed
												? 'list.transaction.adjustable.revert.tooltip'
												: 'list.transaction.adjustable.remove.tooltip',
										)}
										onClick={() =>
											onEdit(
												'$removed',
												!edits.$removed,
												!edits.$removed === false,
											)
										}
									/>
								)}
							{edit && !editGroup && item?.status === 'FORECAST' && (
								<>
									<Button
										key="asteria-currency-cancel-edit"
										className="asteria-adjustable-total-cancel"
										text={TranslationService.get(
											'list.transactions.divider.adjustable.edit.cancel',
											'Avbryt',
										)}
										type="link"
										size="small"
										title={TranslationService.get(
											'list.transactions.divider.adjustable.edit.cancel.tooltip',
										)}
										onClick={() => {
											setEdit(false);
										}}
									/>
									<Button
										key="asteria-currency-show"
										className="asteria-adjustable-total"
										icon="checkFilled"
										type="link"
										title={TranslationService.get(
											'list.transactions.divider.adjustable.save.tooltip',
										)}
										onClick={() => {
											dispatch(
												saveEditedTransactions([
													item._id || item.id,
												]),
											);

											setEdit(false);
										}}
									/>
								</>
							)}
						</Cell>
					</FeatureFlag>
				</div>
			);
		},
	),
)`
	display: flex;

	.asteria-text-modified {
		color: rgb(105, 105, 105) !important;
		font-size: 12px !important;
		text-decoration: line-through !important;
	}

	&.asteria-transaction-edit-removed {
	}
`;

Row.displayName = 'TransactionRow';

Row.Styler = {
	typePrefix: 'asteria-transaction-row',
	children: [
		{
			component: Cell,
			base: [Getter('cell')],
		},
	],
};

Row.displayName = 'Row';

export default Row;
