import React, { useState } from 'react';
import { TitleV2 } from '@asteria/component-typography';
import { ButtonV2 } from '@asteria/component-button';
import { FormGroup, InputV2, LabelV2 } from '@asteria/component-form';
import Alert from '@asteria/component-alert';
import { Translation, TranslationService } from '@asteria/services-language';
import Layout from '../../layout';

const removeError = (error, errors) =>
	errors.filter(({ key }) => error.key !== key);

const Key = ({ showHelp, navigator }) => {
	const [errors, setErrors] = useState([]);
	const [apiKey, setApiKey] = useState('');

	return (
		<Layout
			header={<TitleV2>Activation</TitleV2>}
			body={
				<>
					<div
						style={{
							padding: '3px 18px 9px',
							borderBottom: '1px solid rgb(235, 231, 225)',
							marginBottom: '12px',
						}}
					>
						<TitleV2
							size="title2"
							className="asteria-loading-title"
							style={{
								fontSize: '24px',
								color: 'rgba(238,112,35,1)',
								display: 'block',
								fontFamily: 'Swedbank Headline',
								fontWeight: '400',
							}}
						>
							{TranslationService.get(
								'onboarding.fortnox.connect.title',
								'{{num}} Anslut till bokföringssystem',
								{
									num: '2. ',
								},
							)}
						</TitleV2>
					</div>
					{
						<Alert
							icon
							description={false}
							type="error"
							headingContent={
								<Translation
									translationKey="onboarding.fortnox.api_form.missing_key"
									defaultText="Var vänlig ange din <b>API-nyckel</b> innan du fortsätter"
								/>
							}
							onClose={() =>
								setErrors(
									removeError(
										{ key: 'validationError' },
										errors,
									),
								)
							}
						/>
					}
					{errors && errors.length > 0 ? (
						<Alert
							icon
							description={false}
							type="error"
							headingContent="Generic Connection Error"
							onClose={() =>
								setErrors(
									removeError(
										{ key: 'networkError' },
										errors,
									),
								)
							}
						/>
					) : null}
					<div
						className="asteria-dialog-box"
						style={{
							backgroundColor: '#fbf2ea',
							padding: '20px 20px 15px',
						}}
					>
						<div>
							<Translation
								translationKey="onboarding.fortnox.api_form.title"
								defaultText="För att aktivera kopplingen behöver du hämta en API-nyckel i ditt bokföringssystem och fylla i den här."
							/>
						</div>

						<FormGroup>
							<LabelV2>
								{TranslationService.get(
									'onboarding.fortnox.api_form.label',
									'API-nyckel',
								)}
							</LabelV2>
							<InputV2
								placeholder={TranslationService.get(
									'onboarding.fortnox.api_form.placeholder',
									'',
								)}
								onChange={e => setApiKey(e.target.value)}
								value={apiKey}
								invertedcolor
							/>
						</FormGroup>
					</div>
					<ButtonV2
						text={TranslationService.get(
							'onboarding.fortnox.api_form.help',
							'Var hittar jag min API-nyckel',
						)}
						type="link"
						size="medium"
						icon="triangleRight"
						iconPosition="after"
						onClick={() => {
							showHelp(0);
						}}
					/>
					<div
						className="asteria-wizard-inner-footer"
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							paddingTop: '20px',
						}}
					>
						<ButtonV2
							size="medium"
							type="default"
							onClick={() =>
								navigator.cancel(
									{
										event: 'cancel',
									},
									navigator,
								)
							}
							text={TranslationService.get(
								'onboarding.abort',
								'Avbryt',
							)}
						/>
						<div className="asteria--activation-key-controls">
							<ButtonV2
								size="medium"
								type="secondary"
								onClick={() =>
									navigator.back(
										{
											event: 'goto',
											step: {
												stepId: 'selectErp',
											},
										},
										navigator,
									)
								}
								text={TranslationService.get(
									'onboarding.back',
									'Tillbaka',
								)}
							/>
							<ButtonV2
								size="medium"
								type="secondary"
								text={TranslationService.get(
									'onboarding.continue',
									'Fortsätt',
								)}
								onClick={() => navigator.next(null, navigator)}
							/>
						</div>
					</div>
				</>
			}
			footer={<></>}
		/>
	);
};

Key.navigationKey = Symbol('Key');

export default Key;
