/* eslint-disable no-unused-vars */
import { format, addDays, addWeeks, addMonths, addYears } from 'date-fns';

const SIZES = {
	week: 7,
	month: 28,
	year: 365,
};

const addTimeslice = (date, size, count = 1) => {
	if (size === 'day') {
		return addDays(date, count);
	}
	if (size === 'week') {
		return addWeeks(date, count);
	}
	if (size === 'month') {
		return addMonths(date, count);
	}
	if (size === 'year') {
		return addYears(date, count);
	}

	return date;
};

const tagToCategory = {
	$customer: { name: '$invoices', id: '$invoices' },
	$supplier: { name: '$invoices', id: '$invoices' },
	$tax: { name: '$taxes', id: '$taxes' },
	$salary: { name: '$salaries', id: '$salaries' },
	$plusgiro: { name: '$transaction', id: '$transaction' },
	$bankgiro: { name: '$transaction', id: '$transaction' },
};

export default (data, date, size, statuses, types) => {
	const list = [];
	const formatedDate = `${format(
		date || new Date('2019-01-01'),
		'YYYY-MM-DD',
	)}T00:00:00.000Z`;

	const parts = (
		data
			?.find(({ date: d }) => d === formatedDate)
			?.bars?.reduce((acc, bar) => [...acc, ...bar.parts], []) || []
	)
		.filter(({ chip: { type } }) => type === 'tag')
		.filter(({ data: { type } }) =>
			types && types.length > 0
				? types.includes(type.toUpperCase())
				: true,
		);

	const tags = parts.map(p => ({
		type: p.data.type.toUpperCase(),
		status: p.status,
		sums: {
			original: { total: p.value },
		},
		tag: { name: p.chip.config.name, id: p.chip.config.name },
		category: tagToCategory[p.chip.config.name] || '',
	}));

	for (let i = 0; i < tags.length; i += 1) {
		const {
			type,
			status,
			sums: {
				original: { total: tagTotal },
			},
			tag,
			category,
		} = tags[i];

		const paymentDate = addDays(
			date,
			Math.floor(Math.random() * SIZES[size]),
		);

		list.push({
			id: `${formatedDate}_i_${Math.random()}`,
			type,
			status,
			paymentDate,
			sums: {
				original: { total: tagTotal, currency: 'SEK' },
				display: { total: tagTotal, currency: 'SEK' },
			},
			meta: {
				categories: [category],
				tags: [
					{
						...tag,
						_id: tag.id,
						category,
					},
				],
			},
			description: tag.name,
			oracle: { risk: 1 },
		});
	}

	const currencyTotal = 7368;
	list.push({
		id: `${formatedDate}_i_${Math.random()}`,
		type: types && types[0],
		status: statuses && statuses[0],
		paymentDate: addDays(date, 1),
		sums: {
			original: { total: currencyTotal / 10, currency: 'EUR' },
			display: { total: currencyTotal, currency: 'SEK', rate: 10 },
		},
		meta: {
			categories: [],
			tags: [],
		},
		description: 'Valuta Transaktion',
		oracle: {
			risk: 1,
			currency: {
				risk: 0.8,
				max: {
					display: { total: currencyTotal * 0.1, currency: 'SEK' },
				},
				min: {
					display: { total: currencyTotal * 0.1, currency: 'SEK' },
				},
			},
		},
	});

	return list;
};

export const Statistics = (data, date = new Date(), size, statuses) => {
	const formatedDate = `${format(date, 'YYYY-MM-DD')}T00:00:00.000Z`;
	const slice = data.find(({ date: d }) => d === formatedDate) || {};

	if (
		statuses.length === 1 &&
		(statuses.includes('OVERDUE') || statuses.includes('UNPAID'))
	) {
		const { sums: { original: { total: deposit = 0 } = {} } = {} } =
			slice?.types?.find(t => t.type === 'OVERDUE') || {};

		return {
			firstDate: formatedDate,
			lastDate: `${format(
				addTimeslice(date, size, 1),
				'YYYY-MM-DD',
			)}T00:00:00.000Z`,
			deposit: {
				total: deposit,
				parts: [],
			},
			withdraw: {
				total: 0,
				parts: [],
			},
		};
	}

	const { sums: { original: { total: deposit = 0 } = {} } = {} } =
		slice?.types?.find(t => t.type === 'DEPOSIT') || {};

	const { sums: { original: { total: withdraw = 0 } = {} } = {} } =
		slice?.types?.find(t => t.type === 'WITHDRAW') || {};

	return {
		firstDate: formatedDate,
		lastDate: `${format(
			addTimeslice(date, size, 1),
			'YYYY-MM-DD',
		)}T00:00:00.000Z`,
		deposit: {
			total: deposit,
			parts:
				slice?.tags
					?.filter(t => t.type === 'DEPOSIT')
					.map(
						({
							tag,
							status,
							sums: {
								original: { total: tagTotal },
							},
						}) => ({
							total: tagTotal,
							percentage: 0,
							status,
							probability: null,
							tag,
						}),
					) || [],
		},
		withdraw: {
			total: -withdraw,
			parts:
				slice?.tags
					?.filter(t => t.type === 'WITHDRAW')
					.map(
						({
							tag,
							status,
							sums: {
								original: { total: tagTotal },
							},
						}) => ({
							total: -tagTotal,
							percentage: 0,
							status,
							probability: null,
							tag,
						}),
					) || [],
		},
	};
};
