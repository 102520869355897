// import { format } from 'date-fns';
import Store from '../../core/store';
import logger from '../../logger';

@logger('notifications:store')
class NotificationsStore extends Store {
	name = 'notifications';

	constructor(subject) {
		super(subject, {
			notifications: [
				/* 				{
					id: 0,
					notificationkey: 'forecast.salaries',
					title: 'Löner',
					data: {},
					badge: true,
				}, */
				/*
				{
					id: 1,
					type: 'TEXT',
					notificationKey: 'forecast.explanation',
					data: {
						body:
							'Prognosen är en indikation på framtida inbetalningar och utbetalningar. Den baseras på tidigare genomförda transaktioner för att få den riktigt bra så ber vi dig granska följande kategorier.',
					},
					options: {
						sticky: true,
						badge: true,
						viewed: false,
					},
				},
				{
					id: 2,
					type: 'FORECAST',
					notificationKey: 'forecast.salaries',
					title: 'Löner',
					data: {
						startDate: `${format(
							new Date('2020-11-01'),
							'YYYY-MM-DD',
						)}T00:00:00.000Z`,
						endDate: `${format(
							new Date('2020-11-30'),
							'YYYY-MM-DD',
						)}T00:00:00.000Z`,
						probability: 0.6,
						bar: {
							tag: '$salary',
							data: {
								values: [{ size: 50, status: 'FORECAST' }],
							},
						},
						tag: {
							name: '$salary',
							status: 'FORECAST',
						},
					},
					options: {
						sticky: true,
						badge: true,
						viewed: false,
					},
				},
				{
					id: 3,
					type: 'FORECAST',
					notificationKey: 'forecast.invoices.customer',
					title: 'Kundfakturor',
					data: {
						startDate: `${format(
							new Date('2020-12-01'),
							'YYYY-MM-DD',
						)}T00:00:00.000Z`,
						endDate: `${format(
							new Date('2020-12-31'),
							'YYYY-MM-DD',
						)}T00:00:00.000Z`,
						probability: 0.6,
						bar: {
							tag: '$customer',
							data: {
								values: [{ size: 40, status: 'FORECAST' }],
							},
						},
						tag: {
							name: '$customer',
							status: 'FORECAST',
						},
					},
					options: {
						sticky: true,
						badge: true,
						viewed: false,
					},
				},
				{
					id: 4,
					type: 'FORECAST',
					notificationKey: 'forecast.invoices.supplier',
					title: 'Leverantörsfakturor',
					data: {
						startDate: `${format(
							new Date('2020-12-01'),
							'YYYY-MM-DD',
						)}T00:00:00.000Z`,
						endDate: `${format(
							new Date('2020-12-31'),
							'YYYY-MM-DD',
						)}T00:00:00.000Z`,
						probability: 0.3,
						bar: {
							tag: '$supplier',
							data: {
								values: [{ size: 25, status: 'FORECAST' }],
							},
						},
						tag: {
							name: '$supplier',
							status: 'FORECAST',
						},
					},
					options: {
						sticky: true,
						badge: true,
						viewed: false,
					},
				},
				{
					id: 5,
					type: 'FORECAST',
					notificationKey: 'forecast.taxes',
					title: 'Skatter',
					data: {
						startDate: `${format(
							new Date('2020-11-01'),
							'YYYY-MM-DD',
						)}T00:00:00.000Z`,
						endDate: `${format(
							new Date('2020-11-30'),
							'YYYY-MM-DD',
						)}T00:00:00.000Z`,
						probability: 0.3,
						bar: {
							tag: '$tax',
							data: {
								values: [{ size: 20, status: 'FORECAST' }],
							},
						},
						tag: {
							name: '$tax',
							status: 'FORECAST',
						},
					},
					options: {
						sticky: true,
						badge: true,
						viewed: false,
					},
				},
				{
					id: 'status.overdue',
					type: 'TEXT',
					notificationKey: 'status.supplier.overdue',
					title: 'Förfallna',
					data: {
						body:
							'Du har ett gäng nya förfallna fakturor, var vänlig ta en titt på dem!',
						action: {
							text: 'Visa förfallna',
							type: 'transaction.list',
							data: {
								startDate: `${format(
									new Date('2020-12-01'),
									'YYYY-MM-DD',
								)}T00:00:00.000Z`,
								endDate: `${format(
									new Date('2020-12-31'),
									'YYYY-MM-DD',
								)}T00:00:00.000Z`,
								filters: [
									{
										categoryName: '$invoices',
										tagName: '$supplier',
									},
									{
										id: `status-overdue`,
										type: 'status',
										config: {
											name: `$overdue`,
											status: 'OVERDUE',
										},
									},
								],
							},
						},
					},
					options: {
						sticky: true,
						badge: true,
						viewed: false,
					},
				},
				{
					id: 'erp.add',
					type: 'TEXT',
					notificationKey: 'erp.new',
					data: {
						body:
							'Vi har nu gjort de möjligt för dig att använda speedledger i Företagskollen',
						action: {
							text: 'Anslut speedlegder',
							type: 'link',
							data: 'integrations/erp/add',
						},
					},
					options: {
						sticky: true,
						badge: true,
						viewed: false,
					},
				},
				{
					id: 'erp.error',
					type: 'TEXT',
					notificationKey: 'erp.error',
					data: {
						body:
							'De verkar som att din anslutning till bokföringssystemet Fortnox krånglar. Var vänligen kika',
						action: {
							text: 'Hantera bokföringsystem',
							type: 'link',
							data: 'integrations',
						},
					},
					options: {
						sticky: true,
						badge: true,
						viewed: false,
					},
				},
				{
					id: 'category.new',
					type: 'TEXT',
					notificationKey: 'category.new',
					data: {
						body:
							'Vi har nu en ny kategori för dina transaktioner! Var vänligen ta en titt',
						action: {
							text: 'Visa kategori',
							type: 'filter',
							data: {
								filters: [
									{
										categoryName: '$salaries',
										tagName: '$salary',
									},
								],
							},
						},
					},
					options: {
						sticky: true,
						badge: true,
						viewed: false,
					},
				},
				{
					id: 'transaction.add',
					type: 'TEXT',
					notificationKey: 'transaction.add',
					data: {
						body:
							'Visste du att du kan lägga till egna händelser i framtiden ?',
						action: {
							text: 'Lägg till händelse',
							type: 'transaction.add',
							data: {
								paymentDate: new Date('2020-12-10'),
							},
						},
					},
					options: {
						sticky: true,
						badge: true,
						viewed: false,
					},
				},
				{
					id: 'transaction.list',
					type: 'TEXT',
					notificationKey: 'transaction.list',
					data: {
						body:
							'Du har massor med fina Kundfakturor och Löner i December, 2020! Ta en titt vetja!',
						action: {
							text: 'Visa transaktioner',
							type: 'transaction.list',
							data: {
								startDate: `${format(
									new Date('2020-12-01'),
									'YYYY-MM-DD',
								)}T00:00:00.000Z`,
								endDate: `${format(
									new Date('2020-12-31'),
									'YYYY-MM-DD',
								)}T00:00:00.000Z`,
								filters: [
									{
										categoryName: '$invoices',
										tagName: '$customer',
									},
									{
										categoryName: '$salaries',
										tagName: '$salary',
									},
								],
							},
						},
					},
					options: {
						sticky: true,
						badge: true,
						viewed: false,
					},
				},
				*/
			],
		});
	}
}

export default NotificationsStore;
