import { endOfISOWeek, endOfYear, endOfMonth, format } from 'date-fns';
import { bufferTime, throttle } from 'rxjs/operators';
import { interval, Subject } from 'rxjs';

import {
	SUBSCRIBE_TRANSACTION_CREATED,
	SUBSCRIBE_TRANSACTION_UPDATED,
	SUBSCRIBE_TRANSACTION_DELETED,
} from './queries';

export const updateGraph = graphId => ({ dispatch }) => {
	dispatch(() => ({
		action: 'UPDATE_GRAPH',
		graphId,
	}));
};

export const setRange = (graphId, range = []) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_RANGE',
		graphId,
		payload: range,
	}));

	dispatch(updateGraph(graphId));
};

export const setBarGroup = (graphId, group) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_BAR_GROUP',
		graphId,
		payload: group,
	}));
};

export const clearGraphData = (graphId, keepRange = false) => ({
	dispatch,
}) => {
	dispatch(() => ({
		action: 'CLEAR_GRAPH_DATA',
		graphId,
		payload: keepRange,
	}));
};

export const setGraphGroupSize = (graphId, width) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_GRAPH_GROUP_WIDTH',
		graphId,
		payload: width,
	}));
};

export const setHideDeposit = (graphId, value) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_HIDE_DEPOSIT',
		graphId,
		payload: value,
	}));
};

export const setHideWithdraw = (graphId, value) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_HIDE_WITHDRAW',
		graphId,
		payload: value,
	}));
};

export const requestGraphData = (graphId, payload) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'REQUEST_GRAPH_DATA',
		graphId,
		payload,
	}));
};

export const requestNewRange = (graphId, size, timesliceSize) => ({
	dispatch,
}) => {
	dispatch(() => ({
		action: 'GRAPH_REQUEST_NEW_RANGE',
		graphId,
		payload: size,
		timesliceSize,
	}));
};

export const showGraphTags = (graphId, parts) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SHOW_GRAPH_TAGS',
		graphId,
		payload: parts,
	}));
};

export const hideGraphTags = (graphId, parts) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'HIDE_GRAPH_TAGS',
		graphId,
		payload: parts,
	}));
};

export const toggleGraphTags = (graphId, parts) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'TOGGLE_GRAPH_TAGS',
		graphId,
		payload: parts,
	}));
};

export const setVisibleGraphTags = (graphId, categories) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_VISIBLE_GRAPH_TAGS',
		graphId,
		payload: categories,
	}));
};

export const showGraphCategories = (graphId, categories) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SHOW_GRAPH_CATEGORIES',
		graphId,
		payload: categories,
	}));
};

export const hideGraphCategories = (graphId, categories) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'HIDE_GRAPH_CATEGORIES',
		graphId,
		payload: categories,
	}));
};

export const toggleGraphCategories = (graphId, categories) => ({
	dispatch,
}) => {
	dispatch(() => ({
		action: 'TOGGLE_GRAPH_CATEGORIES',
		graphId,
		payload: categories,
	}));
};

export const setVisibleGraphCategories = (graphId, categories) => ({
	dispatch,
}) => {
	dispatch(() => ({
		action: 'SET_VISIBLE_GRAPH_CATEGORIES',
		graphId,
		payload: categories,
	}));
};

export const setGraphLayout = (graphId, layout) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_GRAPH_LAYOUT',
		graphId,
		payload: layout,
	}));

	dispatch(updateGraph(graphId));
};

export const setAvailableCategories = categories => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_AVAILABLE_CATEGORIES',
		payload: categories,
	}));
};

export const fetchCategories = () => ({ dispatch }) => {
	dispatch(() => ({
		action: 'REQUEST_DATA_GRAPH_CATEGORIES',
		payload: {},
	}));
};

export const setActiveGroups = (graphId, groups) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_ACTIVE_GROUPS',
		graphId,
		payload: groups,
	}));
};

export const setActiveBars = (graphId, bars) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_ACTIVE_BARS',
		graphId,
		payload: bars,
	}));
};

export const setHoverGroups = (graphId, groups) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_HOVER_GROUPS',
		graphId,
		payload: groups,
	}));
};

export const setHoverBars = (graphId, bars) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_HOVER_BARS',
		graphId,
		payload: bars,
	}));
};

export const updateGraphGroup = (graphId, data) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'UPDATE_BAR_GROUP',
		graphId,
		payload: data,
	}));

	dispatch(updateGraph(graphId));
};

export const fetchGraphData = (graphId, { endDate, size = 'month' }) => ({
	dispatch,
}) => {
	dispatch(() => ({
		action: 'REQUEST_DATA_GRAPH',
		payload: { graphId, size, endDate },
	}));
};

const cashflowPipe = new Subject();
cashflowPipe.pipe(bufferTime(200)).subscribe(vals => {
	if (vals.length === 0) {
		return;
	}

	const { dispatch, payload } = vals[vals.length - 1];

	dispatch(() => ({
		action: 'QUERY_CASHFLOWS',
		payload: {
			...payload,
			dates: vals.map(({ payload: { startDate, endDate } }) => ({
				startDate,
				endDate,
			})),
		},
	}));
});

export const queryCashflow = (
	graphId,
	{ startDate, endDate, tags, clients, currencies, status, preview },
) => ({ dispatch }) => {
	cashflowPipe.next({
		action: 'QUERY_CASHFLOW',
		payload: {
			graphId,
			startDate,
			endDate,
			tags,
			clients,
			status,
			preview,
			currencies,
		},
		dispatch,
	});
	/*
	dispatch(() => ({
		action: 'QUERY_CASHFLOW',
		payload: {
			graphId,
			startDate,
			endDate,
			tags,
			clients,
			status,
			preview,
		},
	}));
	*/
};

export const queryCashflows = (
	graphId,
	{ dates, tags, clients, currencies, status, preview },
) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'QUERY_CASHFLOWS',
		payload: {
			graphId,
			dates,
			tags,
			clients,
			status,
			preview,
			currencies,
		},
	}));
};

const endOfTime = (date, size) => {
	if (size === 'week') {
		return endOfISOWeek(date);
	}

	if (size === 'month') {
		return endOfMonth(date);
	}

	if (size === 'year') {
		return endOfYear(date);
	}

	return endOfMonth(date);
};

export const subscribeToGraphUpdates = () => ({ dispatch, lookup }) => {
	const socket = lookup('service-websocket');

	const refreshGraph = () => {
		const graphStore = lookup('store-graph');
		const appStore = lookup('store-appstate');

		const {
			'cashflow-bar-graph': { range = [] },
		} = graphStore.value;

		const { filters = [], timesliceSize = 'month' } = appStore.value;

		dispatch(
			queryCashflows('cashflow-bar-graph', {
				dates: range.map(({ id }) => ({
					startDate: id,
					endDate: `${format(
						endOfTime(new Date(id), timesliceSize),
						'YYYY-MM-DD',
					)}T23:59:59.999Z`,
				})),
				tags: filters
					.filter(({ type }) => type === 'tag')
					.map(({ id: tagId }) => tagId),
				clients: filters
					.filter(({ type }) => type === 'client')
					.map(({ id: customerId }) => customerId),
				currencies: filters
					.filter(({ type }) => type === 'currency')
					.map(({ config: { name } }) => name),
				status: filters
					.filter(({ type }) => type === 'status')
					.map(({ config: { status } }) => status),
			}),
		);
	};

	socket
		.subscribe({
			query: SUBSCRIBE_TRANSACTION_CREATED,
			operationName: 'TimesliceCreated',
		})
		.pipe(throttle(() => interval(500), { leading: false, trailing: true }))
		.subscribe(() => {
			refreshGraph();
		});

	socket
		.subscribe({
			query: SUBSCRIBE_TRANSACTION_UPDATED,
			operationName: 'TimesliceUpdated',
		})
		.pipe(throttle(() => interval(500), { leading: false, trailing: true }))
		.subscribe(() => {
			refreshGraph();
		});

	socket
		.subscribe({
			query: SUBSCRIBE_TRANSACTION_DELETED,
			operationName: 'TimesliceDeleted',
		})
		.pipe(throttle(() => interval(500), { leading: false, trailing: true }))
		.subscribe(() => {
			refreshGraph();
		});
};
