/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_ALL_FEATURES = gql`
	query GetAllFeatures {
		allFeatures
	}
`;

export const GET_ACTIVE_FEATURES = gql`
	query ActiveFeatures {
		activeFeatures
	}
`;

export const ADD_FEATURE = gql`
	mutation AddFeature($input: FeatureAddInput!) {
		addFeature(input: $input) {
			ok
			error
		}
	}
`;

export const UPDATE_FEATURE = gql`
	mutation UpdateFeature($id: ID!, $input: FeatureUpdateInput!) {
		updateFeature(id: $id, input: $input) {
			ok
			error
		}
	}
`;

export const REMOVE_FEATURE = gql`
	mutation RemoveFeature($id: ID!) {
		removeFeature(id: $id) {
			ok
			error
		}
	}
`;

export const ENABLE_FEATURE = gql`
	mutation EnableFeature($id: ID, $key: String, $input: FeatureEnableInput!) {
		enableFeature(id: $id, key: $key, input: $input) {
			ok
			error
		}
	}
`;

export const ENABLE_FEATURES = gql`
	mutation EnableFeatures(
		$ids: [ID]
		$keys: [String]
		$input: FeatureEnableInput!
	) {
		enableFeatures(ids: $ids, keys: $keys, input: $input) {
			ok
			error
		}
	}
`;

export const DISABLE_FEATURE = gql`
	mutation DisableFeature($id: ID!, $input: FeatureDisableInput!) {
		disableFeature(id: $id, input: $input) {
			ok
			error
		}
	}
`;

export const DISABLE_FEATURES = gql`
	mutation DisableFeatures($ids: [ID!]!, $input: FeatureDisableInput!) {
		disableFeatures(ids: $ids, input: $input) {
			ok
			error
		}
	}
`;
