import React from 'react';
import styled from 'styled-components';
import Title from '@asteria/component-typography/title';
import { TranslationService } from '@asteria/services-language';
import { Getter, compileStyles } from '@asteria/utils';

const ErpListHeader = styled(Title)``;
ErpListHeader.Styler = Title.Styler;

const ErpList = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`;
ErpList.Styler = {
	children: [
		{
			component: '> li',
			base: [Getter('item')],
		},
	],
};

/**
 * ErpList component
 */
const List = styled(({ className, children, header = '' }) => (
	<div className={className}>
		<ErpListHeader size="small">
			{`${TranslationService.get(header)}`}
		</ErpListHeader>
		<ErpList className="asteria-list">{children}</ErpList>
	</div>
))`
	${({ theme }) => compileStyles(List, theme)}
`;

List.Styler = {
	base: [Getter('list')],
	children: [
		{
			component: ErpListHeader,
			base: [Getter('header')],
		},
		{
			component: ErpList,
			base: [Getter('list')],
		},
	],
};

export default List;
