import getStoreValue from '@asteria/services-datalayer/utils/get';
import {
	setCurrentDate,
	setFilters,
	setListOpen,
	setSelectedDate,
	setSelectedType,
	showPrompt,
} from '@asteria/services-datalayer/services/appstate/actions';
import { addTransaction } from '@asteria/services-datalayer/services/adjustable/actions';
import { setActiveGroups } from '@asteria/services-datalayer/services/graph/actions';
import { Service as FeatureService } from '@asteria/component-featureflag';
import { setListReverse } from '@asteria/services-datalayer/services/list/actions';
import { addDays, addMonths, addWeeks, addYears } from 'date-fns';

const addTimeslice = (date, size, count = 1) => {
	if (size === 'day') {
		return addDays(date, count);
	}
	if (size === 'week') {
		return addWeeks(date, count);
	}
	if (size === 'month') {
		return addMonths(date, count);
	}
	if (size === 'year') {
		return addYears(date, count);
	}

	return date;
};

const trigger = (action = {}, { dispatch = {}, lookup = {}, history }) => {
	const {
		uri,
		prompt,
		types,
		filters,
		listStartDate,
		graphStartDate,
		add,
	} = action;

	if (uri) {
		history.push(uri);
	}

	if (filters) {
		const tags = getStoreValue(
			lookup('store-graph'),
			({ availableTags = [] }) => availableTags,
		);

		const activeFilters = [
			...tags.filter(({ config: { name } = {} }) =>
				filters.find(({ tagName }) => tagName === name),
			),
			...filters.filter(({ type: filterType }) => filterType),
		];

		const hasListPaymentdateOrder = FeatureService.isActive(
			'list-paymentdate-order',
		);

		dispatch(setFilters(activeFilters));

		if (
			activeFilters.find(
				({ id }) => id.toLowerCase() === 'status-forecast',
			)
		) {
			if (hasListPaymentdateOrder) {
				dispatch(setListReverse(true));
			}
		} else if (!hasListPaymentdateOrder) {
			dispatch(setListReverse(false));
		}
	}

	if (add && add.type === 'transaction.add') {
		dispatch(
			addTransaction({
				type: 'DEPOSIT',
				status: 'FORECAST',
				manual: true,
				paymentDate: new Date(),
				oracle: {
					risk: 1,
				},
				sums: { original: { total: 0 } },
				meta: {
					tags: [
						{
							name: '$customer',
							category: '$invoices',
						},
					],
				},
				...add.data,
			}),
		);
	}

	if (types) {
		dispatch(setSelectedType(types || ['WITHDRAW', 'DEPOSIT']));
	}

	if (listStartDate) {
		dispatch(setActiveGroups('cashflow-bar-graph', [listStartDate]));
		dispatch(setListOpen(true, 'notifications'));

		dispatch(setSelectedDate(listStartDate));
	}

	if (graphStartDate) {
		const size = getStoreValue(
			lookup('store-appstate'),
			({ timesliceSize }) => timesliceSize,
		);

		dispatch(setCurrentDate(addTimeslice(graphStartDate, size, -1)));
	}

	if (prompt) {
		const { once, type, action: promptAction } = prompt;
		const currentUser = getStoreValue(
			lookup('store-auth'),
			({ user }) => user,
		);

		let triggerPrompt = true;
		if (once !== false) {
			const value = currentUser?.settings?.flags?.[type] || null;

			if (value === null) {
				triggerPrompt = true;
			}
		}

		if (triggerPrompt) {
			dispatch(showPrompt({ ...prompt, action: promptAction || action }));
			return false;
		}
	}

	return true;
};

export default trigger;
