export default {
	onboarding: {
		// layout
		style: {
			queries: {
				'(max-width: 767.98px)': {
					style: {
						flexDirection: 'column',
					},
				},
				'(min-width: 768px)': {
					style: {
						marginTop: '16px',
					},
				},
				'(min-width: 1200px)': {
					style: {},
				},
			},
		},
		header: {
			style: {
				justifyContent: 'flex-start',
				borderBottomColor: 'rgba(124, 124, 124, 0.3)',
				borderBottomWidth: '2px',
				borderBottomStyle: 'solid',
				marginRight: '20px',
				marginBottom: '16px',
			},
		},
		body: {
			style: {
				height: 'auto',
				paddingTop: '0px',
				paddingBottom: '20px',
				paddingRight: '20px',
				overflow: 'visible',
			},
			label: {
				style: {
					height: '28px',
					paddingBottom: '0',
					paddingTop: '12px',
				},
			},
		},
		footer: {
			style: {
				paddingTop: '20px',
				paddingBottom: '20px',
				borderTopColor: 'rgba(124, 124, 124, 0.3)',
				alignItems: 'center',
				borderTopWidth: '0px',
				justifyContent: 'center',
				paddingRight: '20px',
			},
		},
		types: {
			fortnoxKey: {
				body: {
					button: {
						types: {
							link: {
								style: {
									justifyContent: 'flex-start',
									padding: '10px 12px',
									height: '55px',
									borderColor:
										'rgb(217, 217, 217) rgb(217, 217, 217) rgb(217, 217, 217) rgb(0, 166, 173)',
									borderLeftColor: 'rgb(0, 166, 173)',
									borderWidth: '1px 1px 1px 8px',
									borderLeftWidth: '8px',
									backgroundColor: 'rgb(249, 248, 246)',
									borderRadius: '0px',
									width: '100%',
									marginTop: '25px',
									borderLeft: '8px solid rgb(0, 166, 173)',
								},
								text: {
									style: {
										textDecoration: 'none',
										fontSize: '16px',
									},
								},
								icon: {
									style: {
										margin: 'auto 12px auto 0px',
										width: '15px',
										height: '15px',
										paddingTop: '4px',
										paddingBottom: '4px',
										position: 'absolute',
										top: '0px',
										bottom: '0px',
										right: '0px',
									},
								},
							},
						},
					},
				},
			},
			fortnoxSuccess: {
				body: {
					alert: {
						style: {
							marginBottom: '35px',
							paddingBottom: '40px',
							borderWidth: '0px 0px 0px',
						},
						header: {
							title: {
								style: {
									fontWeight: 700,
									fontFamily: 'Swedbank Headline',
									fontSize: '20px',
								},
							},
						},
						content: {
							style: {
								borderTop: '1px solid rgba(124, 124, 124, 0.3)',
								marginTop: '8px',
							},
							title: {
								style: {
									textAlign: 'center',
									fontFamily: 'Swedbank Sans',
									fontWeight: 700,
								},
								types: {
									title3: {
										style: {
											fontSize: '22px',
											marginTop: '16px',
										},
									},
									title2: {
										style: {
											color: 'rgb(72, 140, 26)',
											fontSize: '24px',
											lineHeight: '44px',
											marginBottom: '8px',
										},
									},
								},
							},
							text: {
								style: {
									fontFamily: 'Swedbank Sans',
									fontWeight: 700,
								},
								types: {
									small: {
										style: {
											display: 'block',
											textAlign: 'center',
										},
									},
								},
							},
						},
					},
				},
			},
			fortnoxConnecting: {
				body: {
					text: {
						style: {
							// marginBottom: '0.25em',
						},
					},
				},
			},
			asteriaKey: {
				body: {
					button: {
						types: {
							link: {
								style: {
									justifyContent: 'flex-start',
									padding: '10px 12px',
									height: '55px',
									borderColor:
										'rgb(217, 217, 217) rgb(217, 217, 217) rgb(217, 217, 217) rgb(0, 166, 173)',
									borderLeftColor: 'rgb(0, 166, 173)',
									borderWidth: '1px 1px 1px 8px',
									borderLeftWidth: '8px',
									backgroundColor: 'rgb(249, 248, 246)',
									borderRadius: '0px',
									width: '100%',
									marginTop: '25px',
									borderLeft: '8px solid rgb(0, 166, 173)',
								},
								text: {
									style: {
										textDecoration: 'none',
										fontSize: '16px',
									},
								},
								icon: {
									style: {
										margin: 'auto 12px auto 0px',
										width: '15px',
										height: '15px',
										paddingTop: '4px',
										paddingBottom: '4px',
										position: 'absolute',
										top: '0px',
										bottom: '0px',
										right: '0px',
									},
								},
							},
						},
					},
				},
			},
			asteriaSuccess: {
				body: {
					alert: {
						style: {
							marginBottom: '35px',
							paddingBottom: '40px',
							borderWidth: '0px 0px 0px',
						},
						header: {
							title: {
								style: {
									fontWeight: 700,
									fontFamily: 'Swedbank Headline',
									fontSize: '20px',
								},
							},
						},
						content: {
							style: {
								borderTop: '1px solid rgba(124, 124, 124, 0.3)',
								marginTop: '8px',
							},
							title: {
								style: {
									textAlign: 'center',
									fontFamily: 'Swedbank Sans',
									fontWeight: 700,
								},
								types: {
									title3: {
										style: {
											fontSize: '22px',
											marginTop: '16px',
										},
									},
									title2: {
										style: {
											color: 'rgb(72, 140, 26)',
											fontSize: '24px',
											lineHeight: '44px',
											marginBottom: '8px',
										},
									},
								},
							},
							text: {
								style: {
									fontFamily: 'Swedbank Sans',
									fontWeight: 700,
								},
								types: {
									small: {
										style: {
											display: 'block',
											textAlign: 'center',
										},
									},
								},
							},
						},
					},
				},
			},
			asteriaConnecting: {
				body: {
					text: {
						style: {
							// marginBottom: '0.25em',
						},
					},
				},
			},
		},
		features: {
			bankOnlyOnboarding: {
				header: {
					style: {
						padding: '0px',
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '40px',
						marginRight: '40px',
						height: 'auto',
						alignItems: 'flex-start',
						flexDirection: 'row',
					},
				},
				types: {
					status: {
						body: {
							style: {
								padding: '0px',
								marginTop: '20px',
								marginBottom: '20px',
								marginLeft: '40px',
								marginRight: '40px',
							},
							alert: {
								style: {
									marginTop: '10px',
								},
							},
							text: {
								types: {
									textOnboardingStatus: {
										style: {
											marginTop: '5px',
										},
									},
								},
							},
							title: {
								types: {
									title2: {
										style: {
											color: 'var(--primary-color)',
											borderBottom: '1px solid #ece7e2',
										},
									},
								},
							},
							button: {
								style: {
									marginTop: '10px',
									alignSelf: 'flex-end',
								},
							},
						},
					},
				},
			},
		},
	},
};
