import React, { useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import DatalayerContext from '@asteria/services-datalayer/react/context';
import { useStore } from '@asteria/utils';
import { toggleFilter } from '@asteria/services-datalayer/services/appstate/actions';
import { Dropdown } from '@asteria/component-form';
import { TranslationService } from '@asteria/services-language';

const ClientSelector = React.memo(
	styled(({ className, onChange = () => {}, onState = () => {} }) => {
		const { dispatch } = useContext(DatalayerContext);
		const [clients] = useStore(
			'store-appstate',
			({ clients: list }) => list,
		);

		const [filters = []] = useStore(
			'store-appstate',
			({ filters: list }) => list,
		);

		const clientOptions = useMemo(
			() =>
				(clients || [])
					.map(({ _id, id: clientId = _id, name: clientName }) => ({
						label: clientName,
						id: clientId,
						value: clientId,
					}))
					.sort(({ label: a }, { label: b }) => a.localeCompare(b)),
			[clients],
		);

		const setFilter = useCallback(
			({ id }) => {
				const client = clients.find(
					({ _id, id: clientId = _id }) => id === clientId,
				);

				if (client) {
					const { name, type } = client;
					dispatch(
						toggleFilter({
							id,
							type: 'client',
							config: {
								client: id,
								name,
								type,
							},
						}),
					);
				}

				onChange();
			},
			[clients, dispatch, onChange],
		);

		const selected = useMemo(() => {
			const tagFilters = filters.filter(({ type }) => type === 'client');

			return tagFilters.map(({ id }) =>
				clientOptions.find(({ id: valueId }) => valueId === id),
			);
		}, [clientOptions, filters]);

		const onStateChange = useCallback(
			val => {
				onState(val, 'clients');
			},
			[onState],
		);

		if (!clientOptions || clientOptions.length === 0) {
			return null;
		}

		return (
			<Dropdown
				iconClosed="triangleDown"
				iconOpen="triangleUp"
				itemIconSelected="check"
				options={clientOptions}
				selected={selected}
				onState={onStateChange}
				placeholder={TranslationService.get(
					'menu.clients.title',
					'Clients',
				)}
				className={classNames(className, 'asteria-display-clients')}
				type="link"
				onChange={setFilter}
			/>
		);
	})``,
);

ClientSelector.displayName = 'ClientSelector';

ClientSelector.Styler = {
	children: [],
};
export default ClientSelector;
