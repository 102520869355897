import React, { useState } from 'react';
import styled from 'styled-components';
import Onboarding from '@asteria/component-onboarding';
import Steps from '@asteria/component-onboarding/static/steps';
import { Text } from '@asteria/component-typography';
import Alert from '@asteria/component-alert';
import { TranslationService } from '@asteria/services-language';
import { useHistory, useRoute } from '@asteria/utils/hooks';
import { useFeature } from '@asteria/component-featureflag';

export const Row = styled.div`
	flex-direction: row;
	justify-content: space-between;
`;

const ButtonWrapper = styled.div`
	padding: 12px 25px;
	cursor: pointer;
	${props => props.customStyle};
`;

const ButtonLabel = styled(Text)`
	font-family: 'Swedbank Headline Black', 'SwedbankHeadlineBlack',
		'Swedbank Sans', sans-serif;
	font-size: 16px;
	font-weight: 400;
	color: #f35b1c;
	${props => props.customStyle};
`;

export const Button = props => {
	const { children, wrapperStyle, labelStyle, clickHandler } = props;

	return (
		<ButtonWrapper customStyle={wrapperStyle} onClick={clickHandler}>
			<ButtonLabel customStyle={labelStyle}>{children}</ButtonLabel>
		</ButtonWrapper>
	);
};

export const Box = styled.div`
	font-family: 'Swedbank Sans', sans-serif;
	background: #ebf8f2;
	flex-direction: column;
	color: #512b2b;
	font-size: 12px;
	line-height: 1.45;
	padding: 36px 18px;
	margin-top: 24px;
	margin-bottom: 24px;
`;

const AddErpPage = ({
	showTerms: initialShowTermsFlag = false,
	callback = () => {},
	onDone,
}) => {
	const history = useHistory();
	const { params: { type } = {} } = useRoute();
	const onboardingStatusDialog = useFeature({
		key: 'onboarding-status-dialog',
	});

	const hasShowFullOnboarding = useFeature({
		key: 'onboarding-full-flow',
	});

	const integrationsAddModal = useFeature({ key: 'integrations-add-modal' });
	// const integrationsAddModal = useFeature({ key: 'integrations-add-modal' });
	const [showTerms, setShowTerms] = useState(initialShowTermsFlag);
	if (type === 'status' && onboardingStatusDialog) {
		history.push('/');
		return null;
	}

	if (!type) {
		return null;
	}

	let startStep = type === 'status' ? Steps.Status : Steps.SelectProvider;

	if (hasShowFullOnboarding && type === 'add') {
		startStep = Steps.Splash;
	}

	return (
		<Onboarding
			startStep={startStep}
			beforeNavigate={(step, path) => {
				if (step === '' && hasShowFullOnboarding && type === 'add') {
					return Steps.Splash;
				}

				if (!step) {
					if (onDone) {
						onDone();
					} else if (onboardingStatusDialog) {
						history.push(
							integrationsAddModal ? '/integrations' : '/',
						);
					} else if (type === 'status') {
						return Steps.Status;
					} else if (type === 'erp' || type === 'bank') {
						history.push(
							integrationsAddModal ? '/integrations' : '/',
						);
					} else if (type === 'add' && hasShowFullOnboarding) {
						history.push('/');
					} else {
						return Steps.SelectProvider;
					}

					return path[path.length - 1];
				}

				if (step === Steps.Splash && type !== 'add') {
					onDone();
					return Steps.SelectProvider;
				}

				return step;
			}}
			type={type === 'status' ? 'erp' : type}
			initialData="ERP_SOFTWARE"
			callback={callback}
			showNotification={
				showTerms ? (
					<div
						style={{
							paddingBottom: '10px',
							paddingLeft: '40px',
							paddingRight: '40px',
						}}
					>
						<Alert
							icon
							styler
							type="success"
							headingContent={TranslationService.get(
								'thor.terms_signed',
								'Villkor och avtal godkända.',
							)}
							onClose={() => setShowTerms(false)}
						/>
					</div>
				) : null
			}
		/>
	);
};

export default AddErpPage;
