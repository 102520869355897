import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Title, Text } from '@asteria/component-typography';
import { TranslationService, Translation } from '@asteria/services-language';
import { fetchIntegrations } from '@asteria/services-datalayer/services/integrations/actions';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { useHistory } from '@asteria/utils/hooks';
import { useStore } from '@asteria/utils';
import Alert from '@asteria/component-alert';
import Button from '@asteria/component-button';
import { saveUser } from '@asteria/services-datalayer/services/auth/actions';
import Layout from '../../layout';
import Context from '../../context';
import Steps from '../../static/steps';

const DotItem = styled(Text)`
	&:before {
		content: '';
		width: 5px;
		height: 10px;
		background-color: #436bc3;
		display: inline-block;
		border-radius: 0% 20px 20px 0%;
		margin-right: 10px;
	}
`;

const getTranslationStatusKeys = status => {
	const keys = [];

	if (status === 'LOADING' || status === 'INITIATING') {
		keys.push('LOADING');
	}

	if (status === 'INITIATING' || status === 'ANALYZING') {
		keys.push('INITIATING');
	}

	if (status === 'ANALYZING') {
		keys.push('ANALYZING');
	}

	if (keys.length === 0) {
		keys.push(status);
	}

	return keys;
};

const getInfoType = status => {
	if (status === 'MISSING') {
		return 'info';
	}

	if (status === 'IDLE') {
		return 'success';
	}

	return 'info';
};

const IntegrationStatus = ({ type, status = 'MISSING' }) => {
	if (status === 'MISSING') {
		return null;
	}
	return (
		<Alert
			icon
			type={getInfoType(status)}
			headingContent={TranslationService.get(
				getTranslationStatusKeys(status).map(
					s => `onboarding.status.${type}.${s.toLowerCase()}`,
				),
			)}
		/>
	);
};

const Status = styled(({ className, navigator }) => {
	const { dispatch } = useContext(DatalayerContext);
	const { setERPSoftwareType, setStepIndex } = useContext(Context);
	const history = useHistory();
	const [erps = []] = useStore(
		'store-integrations',
		({ integrations = [] }) => integrations,
	);

	const [currentUser = {}] = useStore('store-auth', ({ user }) => user);

	const hasBank = erps.find(({ type }) => type === 'bank');
	const bankStatus = erps.find(({ type }) => type === 'bank')?.status?.state;

	const hasErp = erps.find(({ type }) => type === 'erp');
	const erpStatus = erps.find(({ type }) => type === 'erp')?.status?.state;

	useEffect(() => {
		let running = false;
		setStepIndex(0);
		const updateIntegrations = () => {
			dispatch(fetchIntegrations());

			running = setTimeout(() => updateIntegrations(), 20000);
		};

		updateIntegrations();

		return () => {
			clearTimeout(running);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout
			className={className}
			type="status"
			style={{ flexBasis: '100%' }}
			body={
				<div
					className="asteria-status-layout"
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						style={{
							flexBasis: '66%',
							marginRight: '40px',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Title
							className="asteria-title-onboarding-header"
							size="title2"
							style={{ paddingLeft: '0' }}
						>
							{TranslationService.get(
								'onboarding.status.header',
								'Snart syns ditt kassaflöde här!',
							)}
						</Title>
						<IntegrationStatus
							type="bank"
							exists={hasBank}
							status={bankStatus}
						/>
						<IntegrationStatus
							type="erp"
							exists={hasErp}
							status={erpStatus}
						/>
						{!hasErp ||
						erpStatus === 'LOADING' ||
						bankStatus === 'LOADING' ? (
							<Translation
								type="body"
								className="asteria-text-onboarding-status"
								translationKey="onboarding.status.waiting"
								Component={Text}
								defaultText="Du kan självklart logga ut eller göra annat under tiden."
							/>
						) : null}
						{hasBank && bankStatus === 'IDLE' ? (
							<Button
								type="primary"
								style={{
									width: '200px',
									marginTop: '16px',
									alignSelf: 'flex-end',
								}}
								onClick={() => {
									dispatch(
										saveUser({
											...currentUser,
											settings: {
												...currentUser.settings,
												hasOnboarded: true,
											},
										}),
									);
									history.push('/');
								}}
							>
								{TranslationService.get(
									'onboarding.status.showcashflow',
								)}
							</Button>
						) : null}
					</div>
					<div
						style={{
							marginRight: '40px',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Title
							size="title3"
							style={{
								fontFamily: 'Swedbank Headline',
								fontWeight: '200',
								marginBottom: '0px',
							}}
						>
							{TranslationService.get(
								'onboarding.status.header',
								'Vill du även ansluta ditt bokföringssystem?',
							)}
						</Title>
						<Text
							type="body"
							className="asteria-text-onboarding asteria-text-onboarding-status"
						>
							{TranslationService.get(
								'onboarding.status.erp.text',
								'Just nu går de att hämta information från följande bokföringssystem:',
							)}
						</Text>

						<DotItem className="asteria-text-onboarding-status">
							Fortnox
						</DotItem>

						<Text
							type="body"
							className="asteria-text-onboarding asteria-text-onboarding-status"
							style={{ marginTop: '16px' }}
						>
							{TranslationService.get(
								'onboarding.status.erp.pros',
								'Genom att koppla ditt bokföringssystem får du följande fördelar',
							)}
						</Text>

						<DotItem className="asteria-text-onboarding-status">
							{TranslationService.get(
								'onboarding.status.erp.prop.one',
								'En mer träffsäker prognos på ditt kassaflöde',
							)}
						</DotItem>
						<DotItem className="asteria-text-onboarding-status">
							{TranslationService.get(
								'onboarding.status.erp.prop.two',
								'Bättre översikt med flera kategorier t.ex. skatt, lön och kortbetalningar',
							)}
						</DotItem>
						<DotItem className="asteria-text-onboarding-status">
							{TranslationService.get(
								'onboarding.status.erp.prop.three',
								'Se kommande kundfakturor och leverantörsbetalningar',
							)}
						</DotItem>

						<Text
							type="body"
							className="asteria-text-onboarding asteria-text-onboarding-status"
							style={{ marginTop: '16px' }}
						>
							{TranslationService.get(
								'onboarding.status.erp.action',
								'Du kan ansluta nu direkt och behöver inte vänta på att dina transaktioner från internetbanken ska laddas klart',
							)}
						</Text>

						<Button
							text={TranslationService.get(
								'onboarding.status.erp.connect',
								'Anslut bokföringssystem',
							)}
							size="medium"
							type="secondary"
							onClick={() => {
								setERPSoftwareType();
								navigator.navigateTo(Steps.SelectProvider);
							}}
						/>
					</div>
				</div>
			}
			footer={null}
		/>
	);
})`
	${Text} {
		&.asteria-text-onboarding {
			padding: 4px 0px;
		}
	}

	.asteria-status-layout {
		@media (max-width: 767.98px) {
			flex-direction: column !important;
			padding: 0 10px;
			margin-right: 0;
		}

		div {
			@media (max-width: 767.98px) {
				margin-right: 0 !important;
			}
		}
	}

	.asteria-alert-success {
		margin-bottom: 12px;
	}

	.asteria-text {
		@media (max-width: 767.98px) {
			margin-right: 0 !important;
		}
	}

	${Title} {
		padding: 8px 16px;

		&.asteria-title-3 {
			padding-top: 14px;
			margin-bottom: 12px;
			padding-left: 0px;
			font-family: 'Swedbank Sans';
			font-weight: 700;
		}

		&.asteria-title-title-3 {
			padding-top: 14px;
			margin-bottom: 12px;
			padding-left: 0px;
			font-family: 'Swedbank Sans';
			font-weight: 700;
		}
	}

	.asteria-title-onboarding-header {
		border-bottom-color: rgba(124, 124, 124, 0.3);
		border-bottom-width: 2px;
		border-bottom-style: solid;
		margin-bottom: 16px;
	}

	.asteria-onboarding-header {
		margin-bottom: 0px;
	}

	${DotItem} {
		padding: 4px 0px;
	}

	${Button} {
		align-self: flex-end;
		margin-top: 16px;
	}
`;

Status.navigationKey = Steps.Status;

export default Status;
