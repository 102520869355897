import React, { useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { useStore } from '@asteria/utils';
import Modal, { ModalHeader } from '@asteria/component-modal';
import { useLocation, useHistory } from '@asteria/utils/hooks';
import Button from '@asteria/component-button/button';
import Title from '@asteria/component-typography/title';
import { GLOBAL } from '@asteria/env';
import { TranslationService } from '@asteria/services-language';

import Step from './step';

const GuideModal = styled(({ className, basePath }) => {
	const [step, gotoStep] = useState(0);
	const { pathname } = useLocation();
	const history = useHistory();
	const [{ language = 'sv' } = {}] = useStore(
		'store-language',
		state => state,
	);

	const [site] = useStore('store-appstate', state => state.site);

	let numSteps = 0;
	for (let i = 0; i < 99; i += 1) {
		if (TranslationService.get(`guide.step.${i + 1}.title`, '') === '') {
			break;
		}
		numSteps += 1;
	}

	const next = useCallback(() => {
		if (step === numSteps - 1) {
			history.goBack();
		} else {
			gotoStep(step + 1);
		}
	}, [step, numSteps, history]);

	const prev = useCallback(() => {
		if (step === 0) {
			history.goBack();
		}
		gotoStep(step === 0 ? 0 : step - 1);
	}, [step, history]);

	if (pathname !== `${basePath}/guide`) {
		if (step !== 0) {
			gotoStep(0);
		}
	}

	const { header } = useMemo(() => {
		const key = `guide.step.${step + 1}`;
		return {
			image: `${GLOBAL.baseUrl}assets/asteria/images/guide/step_01.png`,
			title: TranslationService.get(`${key}.title`),
			header: TranslationService.get(`${key}.header`),
			text: TranslationService.get(`${key}.text`),
		};
	}, [step]);

	return (
		<Modal
			open={pathname === `${basePath}/guide`}
			onClose={() => history.push(basePath)}
			className={className}
			title={
				<ModalHeader
					className={classNames('asteria-header', {
						'asteria-modal-header-with-back': step !== 0,
					})}
				>
					{step !== 0 ? (
						<Button
							icon="left"
							type="link"
							onClick={prev}
							className="asteria-button-back"
						/>
					) : null}
					<Title size="title1">{header}</Title>
					<Button
						icon="close"
						type="link"
						onClick={() => history.push(basePath)}
						className="asteria-button-close"
					/>
				</ModalHeader>
			}
		>
			<Step
				language={language}
				next={next}
				prev={prev}
				step={step}
				site={site}
				onClose={() => history.push(basePath)}
			/>
		</Modal>
	);
})``;

GuideModal.Styler = Modal.Styler;

export default GuideModal;
