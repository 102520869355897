import React, { useContext, useCallback } from 'react';
import styled from 'styled-components';
import Modal from '@asteria/component-modal';
import { TranslationService } from '@asteria/services-language';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { showModal } from '@asteria/services-datalayer/services/appstate/actions';
import getStoreValue from '@asteria/services-datalayer/utils/get';
import { saveUser } from '@asteria/services-datalayer/services/auth/actions';

import Survey from './survey';

const SurveyModal = styled(({ className, show = false }) => {
	const { dispatch, lookup } = useContext(DatalayerContext);

	if (show === false) {
		return null;
	}

	const onClose = useCallback(
		val => {
			const user = getStoreValue(
				lookup('store-auth'),
				({ user: obj }) => obj,
			);

			let message = true;
			if (typeof val === 'string' || val instanceof String) {
				message = val;
			}

			dispatch(
				saveUser({
					...user,
					settings: {
						...(user?.settings || {}),
						flags: {
							...(user?.settings?.flags || {}),
							whyDidYouOnboard: message,
						},
					},
				}),
			);

			dispatch(showModal(null));
		},
		[dispatch, lookup],
	);

	return (
		<Modal
			open
			className={className}
			title={TranslationService.get([
				'survey.title',
				'Din åsikt är viktig',
			])}
			onClose={onClose}
		>
			<Survey type="survey" onClose={onClose} />
		</Modal>
	);
})``;

SurveyModal.Styler = Modal.Styler;

export default SurveyModal;
