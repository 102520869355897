import React from 'react';
import styled from 'styled-components';
import registerActivity from './registerActivity';

/**
 * Create onclick handler anchor
 */
const ActivityListenerWrapper = styled.div``;

/**
 * Wrapper with click handler which will
 * @param props
 * @return {*}
 * @constructor
 */
export default props => {
	const { children } = props;

	return (
		<ActivityListenerWrapper onClick={registerActivity}>
			{children}
		</ActivityListenerWrapper>
	);
};
