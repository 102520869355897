export default {
	accounts: {
		style: {},
		header: {
			style: {
				background: 'rgb(251, 242, 234)',
				borderBottom: '2px solid rgb(235, 231, 226)',
				padding: '10px 12px',
				fontFamily: 'Arial,Helvetica,sans-serif',
				fontSize: '14px',
				color: 'var(--text-color)',
			},
		},
		text: {
			types: {
				textNothing: {
					style: {},
				},
			},
		},
		list: {
			item: {
				style: {
					alignItems: 'center',
					padding: '9px 12px 9px 12px',
					borderBottom: '1px solid rgb(235, 231, 225)',
					margin: '0',
				},
				checkbox: {
					checkboxElement: {
						style: {
							paddingRight: '0px',
						},
					},
				},
				info: {
					name: {
						style: {
							fontSize: '16px',
							fontFamily: 'Swedbank Sans',
							fontWeight: 700,
							color: 'var(--text-color)',
						},
					},
					number: {
						style: {
							color: 'var(--muted-color)',
							fontFamily: 'Arial,Helvetica,sans-serif',
							fontSize: '12px',
							fontWeight: '400',
						},
					},
				},
				total: {
					style: {
						fontSize: '16px',
						fontFamily: 'Swedbank Sans',
						fontWeight: 700,
						color: 'var(--text-color)',
					},
				},
			},
		},
	},
};
