import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import DatalayerContext from '@asteria/services-datalayer/react/context';
import { useStore } from '@asteria/utils';
import { toggleFilter } from '@asteria/services-datalayer/services/appstate/actions';
import { TranslationService } from '@asteria/services-language';
import { Dropdown } from '@asteria/component-form';

const GET_CURRENCIES = `
query Exposure($filters: TransactionFiltersInput){
	transactionCurrencies(filters: $filters) {
	  currency
	}
}
`;

const CurrencySelector = React.memo(
	styled(({ className, onChange = () => {}, onState = () => {} }) => {
		const { dispatch, lookup } = useContext(DatalayerContext);
		const apiService = lookup('service-api');
		const [currencies, setCurrencies] = useState([]);

		const [filters = []] = useStore(
			'store-appstate',
			({ filters: list }) => list,
		);

		useEffect(() => {
			apiService
				.query(GET_CURRENCIES, {}, { reqAuth: true })
				.subscribe(({ data: { transactionCurrencies = [] } = {} }) => {
					setCurrencies(
						transactionCurrencies
							.map(({ currency }) => ({
								label: currency,
								value: currency,
								icon: '$currency',
								color: currency,
							}))
							.filter(c => c)
							.sort(
								({ label: a = '' }, { label: b = '' }) =>
									a !== null && a.localeCompare(b),
							),
					);
				});
		}, [apiService]);

		const setFilter = useCallback(
			({ value }) => {
				dispatch(
					toggleFilter({
						id: `currency_${value}`,
						type: 'currency',
						config: {
							name: value,
						},
					}),
				);

				onChange();
			},
			[dispatch, onChange],
		);

		const selected = useMemo(() => {
			const currencyFilters = filters
				.filter(({ type }) => type === 'currency')
				.map(({ config: { name } }) => name);

			return currencyFilters.map(v =>
				currencies.find(({ value }) => value === v),
			);
		}, [currencies, filters]);

		const onStateChange = useCallback(
			val => {
				onState(val, 'currency');
			},
			[onState],
		);

		if (!currencies || currencies.length < 2) {
			return null;
		}

		return (
			<Dropdown
				iconClosed="triangleDown"
				iconOpen="triangleUp"
				itemIconSelected="check"
				options={currencies}
				selected={selected}
				onState={onStateChange}
				placeholder={TranslationService.get(
					'menu.currency.title',
					'Valuta',
				)}
				className={classNames(
					className,
					'asteria-display-currency-navgiation',
				)}
				type="link"
				onChange={setFilter}
			/>
		);
	})``,
);

CurrencySelector.displayName = 'CurrencySelector';

CurrencySelector.Styler = {
	children: [],
};
export default CurrencySelector;
