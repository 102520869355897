import classNames from 'classnames';
import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Text } from '@asteria/component-typography';
import { TranslationService } from '@asteria/services-language';
import SecurityBar from '@asteria/component-security-bar';
// import Icon from '@asteria/component-icon';

import { StyleProvider, makePath, uniqBy, StyleMerger } from '@asteria/utils';
import { withActivityListener } from '@asteria/services-activity-listener';

const styleProvider = new StyleProvider('tags', {
	// theme.{overrideRoot}.{type}.{selector}
	// theme.{overrideRoot}.{type}.{path[{n}]}.{selector}
	wrapper: ({ parentSelector, type }) => [
		{ base: [parentSelector, type], selector: 'style' },
		{
			type: 'props',
			selector: 'wrapper.style',
		},
	],
	tag: ({
		active,
		forceState,
		parentSelector,
		selector,
		tagKey,
		type = 'tag',
	}) => [
		{ base: ['tag'], path: [active], selector: 'style' },
		{
			base: [parentSelector, type],
			path: [active],
			selector: makePath(
				forceState && 'states',
				forceState,
				selector || 'style',
			),
		},
		{
			base: [parentSelector, type, tagKey],
			path: [active],
			selector: makePath(
				forceState && 'states',
				forceState,
				selector || 'style',
			),
		},
		{
			type: 'props',
			selector: 'tag.style',
		},
	],
	icon: ({ parentSelector, type, active }) => [
		{ base: [parentSelector, 'tag'], path: [active], selector: 'icon' },
		{
			base: [parentSelector, type, 'tag'],
			path: [active],
			selector: 'icon',
		},
	],
	dot: ({ parentSelector, type, active }) => [
		{
			base: [parentSelector, 'tag'],
			path: [active],
			selector: 'dot.style',
		},
		{
			base: [parentSelector, type, 'tag'],
			path: [active],
			selector: 'dot.style',
		},
		{
			type: 'props',
			selector: 'tag.dot.style',
		},
	],
	text: ({ parentSelector, type, active }) => [
		{ base: [parentSelector, 'tag'], path: [active], selector: 'text' },
		{
			base: [parentSelector, type, 'tag'],
			path: [active],
			selector: 'text',
		},
		{
			type: 'props',
			selector: 'tag.text',
		},
	],
});

const getStyle = ({ theme = {}, ...props }, target) =>
	styleProvider.getStyle(theme, props, target);

/**
 * Takes component props and returns the target style merged with overridding
 * ones depending on whether the overrideRoot option is set or not
 * @param {Object} props - The components props
 * @param {string} target - The target styleprovider flow / selector to use
 */
const getMergedStyle = (props, target) => {
	const { parentSelector, overrideRoot, ...defaultProps } = props;
	const ownStyles = getStyle(defaultProps, target);

	return overrideRoot
		? StyleMerger(ownStyles, getStyle(props, target))
		: ownStyles;
};

/**
 * Takes the current theme and returns the target style for the forced state.
 * If empty forced state provided - returns empty object.
 * @param {Object} props - The components props
 * @param {string} target - The target styleprovider flow / selector to use
 * @param {string} forceState - The state to force when fetching the style
 * @return {Object} - collected styles
 */
const getStateStyle = (props, target, forceState) =>
	forceState ? getMergedStyle({ ...props, forceState }, target) : {};

/**
 * Gets props object and check if forceState property is set. If so - returns
 * relevant styles. If not - just returns empty object without any further
 * lookups.
 * @param props {Object} - component props Object
 * @param target - The target styleprovider flow / selector to use
 * @return {Object} - result styles
 */
const checkForStateStyle = (props, target) => {
	const { forceState } = props;
	return forceState ? getStateStyle(props, target, forceState) : {};
};

const TagsWrapper = styled.div`
	flex-direction: row;
	flex-wrap: wrap;
	${props => getMergedStyle(props, 'wrapper')};

	/* Add css style for events on wrapper */
	:hover {
		${props => getStateStyle(props, 'wrapper', 'hover')};
	}
	:active,
	&.asteria-active {
		${props => getStateStyle(props, 'wrapper', 'active')};
	}
	:focus {
		${props => getStateStyle(props, 'wrapper', 'focus')};
	}

	/* Apply styles from props styles={STYLE}*/
	${props =>
		StyleMerger(
			getStyle(props, 'wrapper'),
			checkForStateStyle(props, 'wrapper'),
		)};
`;

const TagStyle = styled.div`
	cursor: pointer;
	flex-direction: row;
	height: 30px;
	padding: 8px 16px;
	${props => getMergedStyle(props, 'tag')};

	/* Add css style for events on tag */
	:hover {
		${props => getStateStyle(props, 'tag', 'hover')};
	}
	:active,
	&.asteria-active {
		${props => getStateStyle(props, 'tag', 'active')};
	}
	:focus {
		${props => getStateStyle(props, 'tag', 'focus')};
	}

	/* Add css style for events on subcomponent text */
	:hover .text {
		${props => getStateStyle(props, 'text', 'hover')};
	}
	:active .asteria-text,
	&.asteria-active .asteria-text {
		${props => getStateStyle(props, 'text', 'active')};
	}
	:focus .asteria-text {
		${props => getStateStyle(props, 'text', 'focus')};
	}

	/* Add css style for events on subcomponent icon */
	:hover .asteria-icon {
		${props => getStateStyle(props, 'icon', 'hover')};
	}
	:active .asteria-icon,
	&.asteria-active .asteria-icon {
		${props => getStateStyle(props, 'icon', 'active')};
	}
	:focus .asteria-icon {
		${props => getStateStyle(props, 'icon', 'focus')};
	}

	/* Apply styles from props styles={STYLE}*/
	${props =>
		StyleMerger(getStyle(props, 'tag'), checkForStateStyle(props, 'tag'))};
	.asteria-icon {
		${props => checkForStateStyle(props, 'icon')};
	}
	.asteria-text {
		${props => checkForStateStyle(props, 'text')};
	}
`;

const Tag = withActivityListener(TagStyle);

const TagColor = styled.div``;

const TagDot = withTheme(props => {
	const { styler, color, backgroundImage, theme = {} } = props;

	// const iconStyle = getMergedStyle(props, 'icon');
	const dotStyle = getMergedStyle(props, 'dot');
	return (
		<TagColor
			theme={theme}
			style={styleProvider.preprocess(theme, {
				...dotStyle,
				backgroundImage,
				backgroundColor: color,
			})}
			className="tag-dot"
			styler={styler}
			path="dot"
		/>
	);

	/*
	return (
		<Icon
			asset={icon}
			styles={StyleMerger(iconStyle, {
				icon: { style: { fill: color } },
			})}
			styler={styler}
			editAsset={false}
			editStyle
			path="icon"
		/>
	);
	*/
});

const getTagTitle = (tag = {}, dontOverrideTitles) => {
	let name = tag.key || '';
	const key = tag.key || '';

	if (dontOverrideTitles) {
		return tag.title || name;
	}

	if (key.indexOf('invoiceClient') !== -1 && !tag.onClick) {
		if (tag.status === 'unpaid') {
			name = TranslationService.get(
				'tag.invoiceClientUnpaid',
				'Obetald kundfaktura',
			);
		} else if (tag.status === 'overdue') {
			name = TranslationService.get(
				'tag.invoiceClientOverdue',
				'Förfallen kundfaktura',
			);
		} else {
			name = TranslationService.get('tags.invoiceClient', 'Kundfakturor');
		}
	}

	if (key.indexOf('invoiceSupplier') !== -1 && !tag.onClick) {
		if (tag.status === 'unpaid') {
			name = TranslationService.get(
				'tag.invoiceSupplierUnpaid',
				'Obetald leverantörsfaktura',
			);
		} else if (tag.status === 'overdue') {
			name = TranslationService.get(
				'tag.invoiceSupplierOverdue',
				'Förfallen leverantörsfaktura',
			);
		} else {
			name = TranslationService.get(
				'tags.invoiceSupplier',
				'Leverantörsfakturor',
			);
		}
	}

	if (key.indexOf('incoming') !== -1) {
		name = TranslationService.get('tags.incoming', 'Inbetalningar');
	}

	if (key.indexOf('outgoing') !== -1) {
		name = TranslationService.get('tags.outgoing', 'Utbetalningar');
	}

	if (key.indexOf('tax') !== -1 && key !== 'taxSocial') {
		// Tax social also includes 'tax' but this is a compeltely different entity
		name = TranslationService.get('tags.tax', 'Skatt');
	}

	if (key.indexOf('salary') !== -1) {
		name = TranslationService.get('tags.salary', 'Löner');
	}

	if (key.indexOf('card') !== -1) {
		name = TranslationService.get('tags.card', 'Kortbetalningar');
	}

	if (key.indexOf('cash') !== -1) {
		name = TranslationService.get('tags.cash', 'Kontantbetalningar');
	}

	if (key.indexOf('deposit') !== -1) {
		name = TranslationService.get('tags.deposit', 'Insättningar');
	}

	if (key.indexOf('withdrawal') !== -1) {
		name = TranslationService.get('tags.withdrawal', 'Uttag');
	}

	if (key.indexOf('unknown') !== -1) {
		name = TranslationService.get('tags.unknown', 'Övrigt');
	}

	if (key.indexOf('Forecast') !== -1) {
		let tagName = name.toLowerCase();

		if (key.indexOf('invoiceSupplier') !== -1) {
			tagName = TranslationService.get(
				'tags.invoiceSupplierFilter',
				'Leverantörsfakturor',
			).toLowerCase();
		} else if (key.indexOf('invoiceClient') !== -1) {
			tagName = TranslationService.get(
				'tags.invoiceClientFilter',
				'Kundfakturor',
			).toLowerCase();
		}

		name = TranslationService.get('tags.forecast', 'Prognos {{tag}}', {
			tag: tagName,
		});
	}

	if (key.indexOf('overdue') !== -1) {
		name = TranslationService.get('tags.overdue', 'Förfallna fakturor');
	}

	if (key.indexOf('Open') !== -1) {
		name = TranslationService.get('tags.open', 'Obetalda {{tag}}', {
			tag: name.toLowerCase(),
		});
	}

	if (key.indexOf('unpaid') !== -1) {
		name = TranslationService.get('tags.unpaid', 'Obetalda fakturor');
	}
	if (key.includes('products')) {
		name = TranslationService.get('tags.products', 'Produkter');
	}

	if (name === key && tag.title) {
		name = tag.title;
	}

	if (!name && tag.title) {
		// if we still don't have any name but there is a title set in the tag
		return tag.title;
	}

	return `${name}`;
};

const Tags = withTheme(
	React.forwardRef((props, ref) => {
		const {
			tags = [],
			className,
			parentSelector,
			overrideRoot,
			type,
			styler,
			styledSeparately,
			styles,
			withDots = true,
			dontOverrideTitles = false,
			onClick = () => {},
		} = props;
		const textStyle = getMergedStyle(props, 'text');
		return (
			<TagsWrapper
				className={className}
				overrideRoot={overrideRoot}
				parentSelector={parentSelector}
				ref={ref}
				styler={styler}
				styles={styles}
				type={type}
			>
				{uniqBy(tags, 'key')
					.filter(tag => tag.visible !== false)
					.map(tag => (
						<Tag
							className={classNames(
								'tag',
								tag && tag.className,
								tag.active ? 'asteria-active' : false,
							)}
							color={tag.color}
							key={`tag-${tag.key}`}
							styler={styler}
							path={makePath('tag', styledSeparately && tag.key)}
							active={tag.active}
							type={makePath(type, 'tag')}
							icon={tag.icon || false}
							parentSelector={parentSelector}
							overrideRoot={overrideRoot}
							styles={styles}
							onClick={e => {
								if (tag.onClick) {
									tag.onClick(e, tag);
								} else if (onClick) {
									onClick(e, tag);
								}
							}}
							tagKey={tag.key}
							style={tag.style}
						>
							{withDots && (
								<TagDot
									color={
										tag.color || 'rgba(255, 255, 255, 1)'
									}
									backgroundImage={tag.backgroundImage}
									icon={tag.icon || false}
									active={tag.active}
									type={type}
									styler={styler}
									parentSelector={parentSelector}
									overrideRoot={overrideRoot}
									styles={styles}
								/>
							)}
							<Text
								styles={textStyle}
								style={{ userSelect: 'none' }}
								type={type}
								styler={styler}
								path="text"
							>
								{getTagTitle(tag, dontOverrideTitles)}
							</Text>
							{tag.security !== undefined ? (
								<SecurityBar
									wrapperStyle={{
										flexBasis: '14px',
										flexGrow: 0,
										height: '20px',
										marginLeft: '10px',
										marginTop: '2px',
									}}
									tooltip={`Sannolikhet\n ${Math.round(
										tag.security * 100,
									)}%`}
									path="component"
									steps={[0, 0.1875, 0.375, 0.5625, 0.75]}
									value={tag.security}
								/>
							) : null}
						</Tag>
					))}
			</TagsWrapper>
		);
	}),
);

export default Tags;
