import React, { useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { addDays, addWeeks, addMonths, addYears } from 'date-fns';

import DatalayerContext from '@asteria/services-datalayer/react/context';
import { useStore } from '@asteria/utils';
import {
	removeFilter,
	setCurrentDate,
	toggleFilter,
} from '@asteria/services-datalayer/services/appstate/actions';
import { Dropdown } from '@asteria/component-form';
import { clearGraphData } from '@asteria/services-datalayer/services/graph/actions';
import { useFeature } from '@asteria/component-featureflag';
import { TranslationService } from '@asteria/services-language';

const addTimeslice = (date, size, count = 1) => {
	if (size === 'day') {
		return addDays(date, count);
	}
	if (size === 'week') {
		return addWeeks(date, count);
	}
	if (size === 'month') {
		return addMonths(date, count);
	}
	if (size === 'year') {
		return addYears(date, count);
	}

	return date;
};

const StatusSelector = React.memo(
	styled(({ className, onChange = () => {}, onState = () => {} }) => {
		const { dispatch } = useContext(DatalayerContext);
		const hasShowStatusChip = useFeature({ key: 'show-status-chip' });
		const [filters = []] = useStore(
			'store-appstate',
			({ filters: list }) => list,
		);

		const [open = 0] = useStore(
			'store-appstate',
			({ openInvoices: { deposit = 0 } = {} }) => deposit,
		);

		const [overdue = 0] = useStore(
			'store-appstate',
			({ overdueInvoices: { deposit = 0 } = {} }) => deposit,
		);

		const [timesliceSize] = useStore(
			'store-appstate',
			({ timesliceSize: size }) => size,
		);

		const availableStatus = useMemo(
			() => [
				{
					value: 'all',
					label: 'graph.menu.status.all',
				},
				{
					value: 'PAID',
					label: 'graph.menu.status.paid',
				},
				...(open && open > 0
					? [
							{
								value: 'UNPAID',
								label: 'graph.menu.status.unpaid',
							},
					  ]
					: []),
				...(overdue && overdue > 0
					? [
							{
								value: 'OVERDUE',
								label: 'graph.menu.status.overdue',
							},
					  ]
					: []),

				{
					value: 'FORECAST',
					label: 'graph.menu.status.forecast',
				},
			],
			[open, overdue],
		);

		const toggleStatus = useCallback(
			status => {
				dispatch(clearGraphData('cashflow-bar-graph', true));
				if (status === 'all') {
					filters
						.filter(({ type }) => type === 'status')
						.forEach(({ id }) => dispatch(removeFilter(id)));
				} else {
					dispatch(
						toggleFilter({
							id: `status-${status}`,
							type: 'status',
							config: {
								name: `$${status}`,
								status: status.toUpperCase(),
							},
						}),
					);

					const statusFilters = filters.filter(
						({ type }) => type === 'status',
					);

					if (status === 'FORECAST' && statusFilters.length === 0) {
						dispatch(
							setCurrentDate(
								addTimeslice(new Date(), timesliceSize, -1),
							),
						);
					}
				}
				onChange();
			},
			[dispatch, filters, onChange, timesliceSize],
		);

		const selected = useMemo(() => {
			const statusFilters = filters
				.filter(({ type }) => type === 'status')
				.map(({ config: { status } }) => status);

			if (statusFilters.length === 0) {
				return [availableStatus[0]];
			}

			return statusFilters.map(v =>
				availableStatus.find(({ value }) => value === v),
			);
		}, [availableStatus, filters]);

		const onStateChange = useCallback(
			val => {
				onState(val, 'status');
			},
			[onState],
		);

		return (
			<Dropdown
				iconClosed="triangleDown"
				iconOpen="triangleUp"
				itemIconSelected="check"
				options={availableStatus}
				selected={selected}
				onState={onStateChange}
				placeholder={TranslationService.get(
					'menu.status.title',
					'Status',
				)}
				showSelected={!hasShowStatusChip}
				className={classNames(
					className,
					'asteria-display-status-navgiation',
				)}
				type="link"
				onChange={({ value }) => toggleStatus(value)}
			/>
		);
	})``,
);

StatusSelector.displayName = 'StatusSelector';

StatusSelector.Styler = {
	children: [],
};
export default StatusSelector;
