import React from 'react';
import { TextV2, TitleV2 } from '@asteria/component-typography';
import Alert from '@asteria/component-alert';
import { TranslationService } from '@asteria/services-language';
import { ButtonV2 } from '@asteria/component-button';
import Layout from '../../layout';

const Success = ({ navigator }) => (
	<Layout
		header={<TitleV2>Kopplingen till bokföringssystemet är klar</TitleV2>}
		body={
			<>
				<Alert
					icon
					description
					type="success"
					headingContent={TranslationService.get(
						'onboarding.fortnox.success.succesAlert.title',
						'Klart',
					)}
					showClose={false}
					content={
						<>
							<TitleV2>
								{TranslationService.get(
									'onboarding.fortnox.success.succesAlert.content.text1',
									'Du har anslutit bokföringssystemet',
								)}
							</TitleV2>
							<TitleV2>Fortnox</TitleV2>
							<TextV2>
								{TranslationService.get(
									'onboarding.fortnox.success.succesAlert.content.text2',
									'Under kassaflöde kommer du strax se en prognos av ditt företags utveckling.',
								)}
							</TextV2>
						</>
					}
				/>
				<div
					className="asteria-wizard-inner-footer"
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						paddingTop: '20px',
						marginTop: '55px',
					}}
				>
					<ButtonV2
						size="medium"
						type="primary"
						text={TranslationService.get(
							'onboarding.fortnox.success.finishButton',
							'Klar',
						)}
						onClick={() =>
							navigator.finish(
								{
									name: 'Finish',
									integrationTitle: 'FORTNOX',
								},
								navigator,
							)
						}
					/>
				</div>
			</>
		}
		footer={<></>}
	/>
);

Success.navigationKey = Symbol('Success');

export default Success;
