import Globals from './globals';
import TypoGraphy from './typography';
import Tooltip from './tooltip';
import Button from './button';
import Graph from './graph';
import Forms from './forms';
import Modal from './modal';
import Account from './account';
import Erps from './erps';
import Settings from './settings';
import Support from './support';
import Guide from './guide';
import Insights from './insights';
import Alert from './alert';
import Transactions from './transactions';
import Dialog from './dialog';
import SecurityBar from './securitybar';
import Onboarding from './onboarding';
import ProgressBarIndicator from './progressBarIndicator';
import List from './list';
import InfoBox from './infobox';
import ActionBar from './actionbar';
import Framework from './framework';

export default {
	...Globals,
	...TypoGraphy,
	...Tooltip,
	...Button,
	...Graph,
	...Forms,
	...Modal,
	...Account,
	...Erps,
	...Settings,
	...Support,
	...Guide,
	...Insights,
	...Alert,
	...Transactions,
	...Dialog,
	...SecurityBar,
	...Framework,
	...Onboarding,
	...ProgressBarIndicator,
	...List,
	...InfoBox,
	...ActionBar,
	scrolltop: {
		style: {},
		button: {
			style: {
				backgroundColor: '#fff',
				marginTop: '0',
				borderRadius: '0px 0px 34px 34px',
				height: 'auto',
				marginLeft: 'auto',
				marginRight: 'auto',
				padding: '0px 16px 4px 8px',
				lineHeight: '19px',
				border: '2px solid rgb(235,231,225)',
				borderTop: '0px solid #fff',
				flexDirection: 'row-reverse',
				boxShadow: '0px 0px 5px rgba(0,0,0,0.07)',
			},
			icon: {
				style: {
					flexShrink: '1',
					flexGrow: '0',
					marginRight: '0px',
					width: '24px',
					alignItems: 'center',
				},
				svg: {
					style: {
						marginTop: '0px',
						height: '8px',
						width: '8px',
					},
				},
			},
			text: {
				style: {
					fontSize: '14px',
					textDecoration: 'none',
				},
			},
		},
	},
	integrationalert: {
		alert: {
			controllers: {
				style: {
					paddingTop: '10px',
				},
			},
		},
	},
};
