import * as WidgetOnboarding from '@asteria/widget-onboarding';

export default ({ accessToken, env = 'production', languageCode = 'sv' }) => {
	WidgetOnboarding.createErp(document.getElementById('root'), {
		accessToken,
		partnerId: '5ef5b5f096bceb52bcd2b62a',
		route: '/integrations/add/erp',
		languageCode,
		env,
		callback: (event, data) => {
			if (event === 'create.history') {
				// data.push('/integrations');
			}

			console.log(event, data);
		},
	});
};
