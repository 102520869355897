import React, {
	useState,
	useEffect,
	useRef,
	useMemo,
	useCallback,
} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import classNames from 'classnames';
import { Getter, compileStyles } from '@asteria/utils';
import Text from '@asteria/component-typography/text';
import { TranslationService } from '@asteria/services-language';
import Button from '@asteria/component-button/button';
import Icon from '@asteria/component-icon';
import Backdrop from '@asteria/component-backdrop';

const composedPath = el => {
	const path = [];
	let currentEl = el;

	while (currentEl) {
		path.push(currentEl);

		if (currentEl.tagName === 'HTML') {
			path.push(document);
			path.push(window);

			return path;
		}

		currentEl = currentEl.parentElement;
	}

	return path;
};

const DropDownToggle = styled(
	({
		className,
		showSelected,
		placeholder = '',
		selected = [],
		size,
		type,
		icon,
		open,
		setOpen,
		toggleOptions,
	}) => (
		<Button
			className={classNames(className, {
				'asetria-dropdown-toggle-placeholder': selected.length === 0,
			})}
			type={type}
			size={size}
			icon={icon || selected?.[0]?.icon}
			iconOptions={selected?.[0]?.icon ? selected?.[0] : undefined}
			onClick={() => setOpen(!open)}
			{...toggleOptions}
		>
			{showSelected && selected.length > 0
				? selected
						.map(s =>
							TranslationService.get(
								`${s?.label}`,
								`${s?.label}`,
							),
						)
						.join(', ')
				: ''}

			{showSelected && selected.length === 0 ? placeholder : ''}
			{!showSelected ? placeholder : null}
		</Button>
	),
)``;

DropDownToggle.Styler = {
	...Button.Styler,
};

DropDownToggle.displayName = 'DropDownToggle';

const OptionsList = styled(
	({
		className,
		options = [],
		setOpen,
		itemIcon,
		itemIconSelected,
		selected,
		onClick,
	}) => (
		<div className={classNames('asteria-forms-dropdown-sub', className)}>
			{options.map((option, index) => (
				<DropDownItem
					key={option.id || option.value}
					option={option}
					setOpen={setOpen}
					icon={itemIcon}
					iconSelected={itemIconSelected}
					className={classNames('asteria-forms-dropdown-item', {
						'asteria-forms-dropdown-item-first': index === 0,
						'asteria-forms-dropdown-item-last':
							index === options.length - 1,
						'asteria-state-active': Array.isArray(selected)
							? selected.includes(option)
							: selected === option,
					})}
					selected={
						Array.isArray(selected)
							? selected.includes(option)
							: selected === option
					}
					onClick={() => onClick(option)}
				/>
			))}
		</div>
	),
)`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: 16px;
`;

OptionsList.displayName = 'OptionsList';

const DropDownItem = styled(
	({
		className,
		selected,
		onClick,
		setOpen,
		icon,
		iconSelected,
		option: {
			label,
			options,
			icon: optionIcon,
			iconSelected: optionIconSelected,
			type,
		} = {},
		option,
	}) => {
		const OptionIcon = useMemo(() => {
			if (optionIcon || optionIconSelected) {
				if (selected && optionIconSelected) {
					return <Icon {...option} asset={optionIconSelected} />;
				}
				return <Icon {...option} asset={optionIcon} />;
			}

			if (icon && (!iconSelected || !selected)) {
				return <Icon asset={icon} />;
			}

			if (iconSelected && selected && !options) {
				return <Icon asset={iconSelected} />;
			}

			return null;
		}, [
			icon,
			iconSelected,
			option,
			optionIcon,
			optionIconSelected,
			options,
			selected,
		]);

		return (
			<div
				className={classNames(
					className,
					'asteria-forms-dropdown-item',
					{
						[`asteria-forms-dropdown-item-${type}`]: type,
						'asteria-forms-dropdown-parent':
							options && options.length > 1,
					},
				)}
				onClick={() => {
					onClick(option);

					if (!options) {
						setOpen(false);
					}
				}}
				onKeyPress={() => {
					onClick(option);
					if (!options) {
						setOpen(false);
					}
				}}
				onMouseEnter={() => {
					if (options && options.length > 1) {
						onClick(option, 'add');
					}
				}}
				onMouseLeave={() => {
					if (options && options.length > 1) {
						onClick(option, 'remove');
					}
				}}
				role="button"
				tabIndex="-1"
			>
				<div className="asteria-option-inner">
					<Text>{TranslationService.get(label, label)}</Text>
					{OptionIcon}
				</div>
				{selected && options && (
					<OptionsList
						options={options}
						onClick={onClick}
						setOpen={setOpen}
						icon={icon}
						iconSelected={iconSelected}
					/>
				)}
			</div>
		);
	},
)`
	.asteria-option-inner {
		cursor: pointer;
		display: flex;
		align-items: center;
	}

	${Text} {
		flex-grow: 1;
		order: 2;
	}

	${Icon} {
		width: 20px;
		height: 20px;
		flex-grow: 0;
		order: 3;
		&.asteria-icon-tag {
			order: 1;
		}
	}
`;

DropDownItem.Styler = {
	children: [
		{
			component: Text,
			base: [Getter('text')],
		},
		{
			component: Icon,
			base: [Getter('icon')],
		},
	],
};

DropDownItem.displayName = 'DropDownItem';

const DropDownMenu = styled.div`
	z-index: 10;
	&.asteria-forms-dropdown-menu-direction-left {
		transform: translateX(calc(-100% + 30px));
	}
`;
DropDownMenu.Styler = {
	typePrefix: 'asteria-forms-dropdown-menu',
	children: [
		{
			component: DropDownItem,
			base: [Getter('item')],
		},
	],
};

DropDownMenu.displayName = 'DropDownMenu';

const Dropdown = styled(
	({
		className,
		open = false,
		selected,
		options = [],
		size,
		type,
		iconClosed,
		disabled = false,
		iconOpen,
		itemIcon,
		itemIconSelected,
		toggle,
		item,
		showSelected = false,
		placeholder,
		direction = ['center', 'down'],
		onChange = () => {},
		backdrop = false,
		children,
		onState,
		toggleOptions,
		...props
	}) => {
		const [isOpen, setInternalOpen] = useState(open);
		const internalState = useRef([]);
		const [internalSelected, setInternalSelected] = useState([]);
		const elRef = useRef(null);

		const setOpen = useCallback(
			state => {
				setInternalOpen(state);
				if (onState) {
					onState(state);
				}
			},
			[onState],
		);

		useEffect(() => setOpen(open), [open, setOpen]);
		useEffect(() => {
			const close = e => {
				const path =
					e.path ||
					(e.composedPath && e.composedPath()) ||
					composedPath(e.target);

				if (elRef && elRef.current) {
					if (!path || !path.includes(elRef.current)) {
						setOpen(false);
					}
				} else if (
					!path ||
					!path.find(p =>
						p?.classList?.contains('asteria-forms-dropdown-open'),
					)
				) {
					setOpen(false);
				}
			};

			if (isOpen) {
				document.addEventListener('click', close);
			}

			return () => {
				document.removeEventListener('click', close);
			};
		}, [isOpen, setOpen]);

		const updateInternal = useCallback(
			value => {
				setInternalSelected(value || []);
				internalState.current = value || [];
			},
			[internalState],
		);

		useEffect(() => {
			updateInternal(selected || []);
		}, [selected, updateInternal]);

		const onClick = useCallback(
			(select, action = 'toggle') => {
				let execute = action;
				if (execute === 'toggle') {
					const isSelected = Array.isArray(internalState.current)
						? internalState.current.includes(select)
						: internalState.current === select;

					execute = isSelected ? 'remove' : 'add';
				}

				if (execute === 'add') {
					updateInternal([...internalState.current, select]);
				} else if (execute === 'remove') {
					updateInternal(
						internalState.current.filter(o => o !== select),
					);
				}

				if (!select.options) {
					onChange(select);
				}
			},
			[internalState, onChange, updateInternal],
		);

		const ToggleComponent = toggle || DropDownToggle;
		const ItemComponent = item || DropDownItem;

		return (
			<div
				className={classNames(className, 'asteria-forms-dropdown', {
					'asteria-forms-dropdown-open': isOpen,
					'asteria-forms-show-selected': showSelected,
					'asteria-forms-dropdown-disabled': disabled,
					'asteria-forms-dropdown-has-backdrop': backdrop,
				})}
				ref={elRef}
			>
				{isOpen &&
					backdrop &&
					ReactDOM.createPortal(
						<Backdrop onClose={() => setOpen(false)} />,
						document.getElementById('asteria-widget'),
					)}
				{toggle !== null ? (
					<ToggleComponent
						size={size}
						type={type}
						icon={isOpen ? iconOpen : iconClosed}
						placeholder={placeholder}
						className={classNames('asteria-forms-dropdown-toggle', {
							'asteria-state-active': isOpen,
						})}
						showSelected={showSelected}
						setOpen={val => {
							if (disabled === false) {
								setOpen(val);
							}
						}}
						onClick={() => {
							if (disabled === false) {
								setOpen(!isOpen);
							}
						}}
						open={isOpen}
						selected={selected}
						toggleOptions={toggleOptions}
						{...props}
					/>
				) : null}
				<DropDownMenu
					className={classNames(
						'asteria-forms-dropdown-menu',
						direction.map(
							d => `asteria-forms-dropdown-menu-direction-${d}`,
						),
					)}
				>
					{children}
					{options?.map((option, index) => (
						<ItemComponent
							key={option.id || option.value}
							option={option}
							setOpen={setOpen}
							open={isOpen}
							icon={itemIcon}
							iconSelected={itemIconSelected}
							className={classNames(
								'asteria-forms-dropdown-item',
								{
									'asteria-forms-dropdown-item-first':
										index === 0,
									'asteria-forms-dropdown-item-last':
										index === options.length - 1,
									'asteria-state-active': Array.isArray(
										internalSelected,
									)
										? internalSelected.includes(option)
										: internalSelected === option,
								},
							)}
							selected={
								Array.isArray(internalSelected)
									? internalSelected.includes(option)
									: internalSelected === option
							}
							onClick={onClick}
						/>
					))}
				</DropDownMenu>
			</div>
		);
	},
)`
	position: relative;
	${DropDownMenu} {
		display: none;
		position: absolute;
		overflow-y: scroll;
	}
	&.asteria-forms-dropdown-open {
		${DropDownMenu} {
			display: block;
		}

		&.asteria-forms-dropdown-has-backdrop {
			z-index: 1001;

			${DropDownMenu} {
				z-index: 1001;
			}
		}
	}

	${({ theme }) => compileStyles(Dropdown, theme)}
`;

Dropdown.Styler = {
	base: [Getter('formV2.dropdown')],
	typePrefix: 'asteria-forms-dropdown',
	children: [
		{
			component: DropDownToggle,
			base: [Getter('toggle')],
		},
		{
			component: DropDownMenu,
			base: [Getter('dropdown')],
		},
	],
};

Dropdown.displayName = 'Dropdown';

export default Dropdown;
