import React, { useCallback, useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useFeature } from '@asteria/component-featureflag';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { setCredit } from '@asteria/services-datalayer/services/appstate/actions';
import Tooltip from '@asteria/component-tooltip';
import { TranslationService } from '@asteria/services-language';
import Icon from '@asteria/component-icon';

const Line = styled(
	({
		layout,
		className,
		maxValue = 0,
		minValue = 0,
		group = {},
		last = false,
		first = false,
		prevGroup,
		height = 0,
		center,
		setTarget,
		credit,
		mode,
		hasClickedCredit,
		setHasClickedCredit,
	}) => {
		const [showCreditTooltip, setShowCreditTooltip] = useState(false);
		const creditHandle = useRef(null);
		const { dispatch } = useContext(DatalayerContext);
		const { lines = [] } = group;
		const { lines: prevLines = [] } = prevGroup || {};
		const hasCreditIndicator = useFeature({
			key: 'graph-credit-line-indicator',
		});

		/*
		let { lines: [{ value = 0 } = {}] = [] } = group;
		let { lines: [{ value: prevValue = 0 } = {}] = [] } = prevGroup || {};

		value = Math.max(value, minValue);
		prevValue = Math.max(prevValue, minValue);

		let y1 = 100 - ((prevValue - minValue) / (maxValue - minValue)) * 100;
		let y2 = 100 - ((value - minValue) / (maxValue - minValue)) * 100;

		if (layout === 'stacked') {
			y1 = 50 - (prevValue / maxValue) * 50;
			y2 = 50 - (value / maxValue) * 50;
		}
		*/

		const mouseDown = useCallback(() => {
			setHasClickedCredit(true);
			setTarget(translate => {
				const delta = (translate.deltaY / height) * 100;
				dispatch(
					setCredit(
						Math.round(
							Math.min(
								Math.max(
									delta * ((maxValue - minValue) / 100) +
										credit,
									0,
								),
								Math.abs(minValue),
							),
						),
					),
				);
			});
		}, [
			setHasClickedCredit,
			setTarget,
			height,
			dispatch,
			maxValue,
			minValue,
			credit,
		]);

		return lines.map(line => {
			let { value = 0 } = line;
			const { types: [type] = [] } = line;

			const prevIndex = prevLines.findIndex(
				({ types: [prevType] = [] }) => prevType === type,
			);

			const isForecast = line.types.includes('forecast');

			const creditFraction =
				((credit - Math.abs(value)) / (maxValue - minValue)) * 100;

			if (
				prevIndex === -1 ||
				(line.types.includes('account') &&
					line.types.includes('forecast') &&
					lines.find(({ types }) => types.includes('history')))
			) {
				return null;
			}

			let { value: prevValue = 0 } = prevLines[prevIndex] || {};

			const { types: prevTypes = [] } = prevLines[prevIndex] || {};

			if (layout !== 'stacked') {
				value = Math.max(value, minValue);
				prevValue = Math.max(prevValue, minValue);
			}

			let y1 =
				100 - ((prevValue - minValue) / (maxValue - minValue)) * 100;
			let y2 = 100 - ((value - minValue) / (maxValue - minValue)) * 100;

			if (layout === 'stacked') {
				y1 = center - (prevValue / maxValue) * center;
				y2 = center - (value / maxValue) * center;
			}

			const originalY2 = y2;

			if (line.types.includes('sharp')) {
				if (creditFraction && isForecast && mode === 'credit') {
					y2 = Math.min(y2 + creditFraction, 100);
					y1 = prevTypes.includes('forecast')
						? Math.min(y1 + creditFraction, 100)
						: y1;
				}

				return (
					<React.Fragment key={type}>
						{y1 !== y2 && (
							<line
								className={classNames(
									className,
									'asteria-graph-line-overdraft',
									'asteria-graph-line-vertical',
									{
										'asteria-graph-line-below-zero':
											value < 0,
									},
								)}
								x1="0%"
								y1={`${height * (y1 / 100) + 2}`}
								x2="0%"
								y2={`${height * (y2 / 100) + 1}`}
							/>
						)}
						<line
							className={classNames(
								className,
								'asteria-graph-line-overdraft',
								{
									'asteria-graph-line-below-zero': value < 0,
								},
							)}
							x1="0%"
							y1={`${y2}%`}
							x2={last ? '150%' : '100%'}
							y2={`${y2}%`}
						/>
						{isForecast && mode === 'credit' && (
							<rect
								className={classNames(
									className,
									'asteria-overdraft-area',
								)}
								style={{
									fill: 'rgba(125, 0, 0, 0.3)',
									stroke: 'none',
									cursor: 'row-resize',
								}}
								x="0%"
								y={`${Math.min(
									originalY2 + creditFraction,
									originalY2,
								)}%`}
								height={`${Math.min(
									Math.abs(creditFraction),
									creditFraction > 0
										? 100 - originalY2
										: originalY2,
								)}%`}
								onMouseDown={
									mode === 'credit'
										? () => mouseDown(value)
										: undefined
								}
								width="100%"
							/>
						)}
						{isForecast &&
							mode === 'credit' &&
							(!prevTypes.includes('forecast') ||
								(first && isForecast)) && (
								<>
									<rect
										className={classNames(
											className,
											'asteria-overdraft-area-handle',
										)}
										style={{
											fill: '#5E0509',
											stroke: 'none',
											cursor: 'row-resize',
										}}
										x="50%"
										y={`${height * (y2 / 100) + 3}`}
										height="4px"
										onMouseDown={
											mode === 'credit'
												? () => mouseDown(value)
												: undefined
										}
										transform="translate(-4 0)"
										width="24px"
										rx="2px"
									/>

									{!hasClickedCredit && (
										<>
											<Icon
												asset="info"
												svg
												ref={creditHandle}
												x="50%"
												y={`${height * (y2 / 100) -
													28}`}
												styles={{
													icon: {
														style: {
															cursor: 'pointer',
															fill: '#53a1ac',
														},
													},
												}}
												onMouseEnter={() =>
													setShowCreditTooltip(true)
												}
												onMouseLeave={() =>
													setShowCreditTooltip(false)
												}
											/>
											<svg
												height="6"
												width="16"
												viewBox="0 0 100 100"
												preserveAspectRatio="none"
												x="50%"
												y={`${height * (y2 / 100) - 8}`}
											>
												<polygon
													points="10,0 90,0 50,75"
													style={{
														fill: '#53a1ac',
													}}
												/>
											</svg>
										</>
									)}
									{!hasClickedCredit && (
										<Tooltip
											hover
											isStatic
											open={
												showCreditTooltip &&
												!hasClickedCredit
											}
											title=""
											targetEl={creditHandle}
											content={TranslationService.get(
												'graph.credit.handle.tooltip',
											)}
										/>
									)}
								</>
							)}
						{first && hasCreditIndicator ? (
							<polygon
								className={classNames(
									className,
									'asteria-overdraft-indicator',
								)}
								style={{
									transform: `translateX(${
										layout === 'stacked' ? '24px' : '46px'
									}) translateY(${height * (y2 / 100) -
										5}px)`,
								}}
								points="0,0 10,5 0,10"
							/>
						) : null}
					</React.Fragment>
				);
			}

			return (
				<line
					className={classNames(className, {
						'asteria-graph-line-below-zero': value < 0,
					})}
					key={type}
					x1="0%"
					y1={`${y1}%`}
					x2={last ? '150%' : '100%'}
					y2={`${y2}%`}
				/>
			);
		});
	},
)`
	&.asteria-overdraft-indicator {
		transform: translateY(3.5px) translateX(-5px);
		fill: #c5131b;
		stroke: none !important;
	}
`;

Line.displayName = 'Line';
Line.Styler = {
	typePrefix: 'asteria-graph-line',
};

export default Line;
