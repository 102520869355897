import { useState, useEffect } from 'react';
import useStore from './useStore';

const useLocation = () => {
	const [history = { location: { pathname: '/' } }] = useStore(
		'store-appstate',
		({ history: object }) => object,
	);

	const [location, setLocation] = useState(history.location);

	useEffect(() => {
		const unlisten = history.listen(newLocation => {
			setLocation(newLocation);
		});

		return () => unlisten();
	}, [history]);

	return location;
};

export default useLocation;
