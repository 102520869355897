import React, { useCallback } from 'react';
import styled from 'styled-components';
import { compileStyles, StyleGetter, Getter } from '@asteria/utils';
import PropTypes from 'prop-types';
import Text from '@asteria/component-typography/text';
import classNames from 'classnames';

// Switch Track component element
const Track = styled.div``;

// Attach styler sidebar to the Track component
const StyledTrack = React.forwardRef((props, ref) => (
	<Track ref={ref} {...props} />
));

// Switch Slider component
const Slider = styled.div`
	position: absolute;
	left: 0;
	${props => (props.active ? 'left: auto; right: 0' : '')}
`;

// Attach styler sidebar to the Switch Slider component
const StyledSlider = React.forwardRef((props, ref) => (
	<Slider ref={ref} {...props} />
));

/**
 * Create Wrapper element for the Switch component and attach styles to it
 */
const ToggleSwitchWrapper = styled.div`
	display: flex;
	cursor: pointer;
	flex-direction: row;
	align-items: center;
	position: relative;
`;

/**
 * Switch component
 */
const ToggleSwitch = styled(
	React.forwardRef(
		(
			{
				className,
				onChange = () => {},
				label,
				title,
				active,
				styler,
				disabled,
			},
			ref,
		) => {
			const triggerChange = useCallback(() => {
				if (!disabled) {
					onChange(!active);
				}
			}, [active, disabled, onChange]);

			return (
				<div
					ref={ref}
					title={title}
					className={classNames(className, 'asteria-forms-switch', {
						'asteria-active': active,
						'asteria-disabled': disabled,
					})}
				>
					<ToggleSwitchWrapper className="asteria-forms-switch-wrapper">
						<StyledTrack
							path="track"
							styler={styler}
							className="asteria-forms-switch-track"
						/>
						<StyledSlider
							onClick={triggerChange}
							active={active}
							path="slider"
							styler={styler}
							className="asteria-forms-switch-slider"
						/>
					</ToggleSwitchWrapper>
					<Text size="label" className="asteria-forms-switch-label">
						{label}
					</Text>
				</div>
			);
		},
	),
)`
	display: flex;
	${({ theme }) => compileStyles(ToggleSwitch, theme)}
`;

ToggleSwitch.propTypes = {
	onChange: PropTypes.func,
	active: PropTypes.bool,
};

ToggleSwitch.defaultProps = {
	onChange: false,
	active: false,
};

ToggleSwitch.Styler = {
	base: [StyleGetter(`formV2.switch`, {})],
	children: [
		{
			component: ToggleSwitchWrapper,
			base: [Getter('toggle')],
			children: [
				{
					component: Track,
					base: [StyleGetter(`track`, {})],
				},
				{
					component: Slider,
					base: [StyleGetter(`slider`, {})],
				},
			],
		},
		{
			component: Text,
			base: [StyleGetter(`label`, {})],
		},
	],
};

/**
 * Append theming sidebar and attach styler trigger to Switch component
 */
export default ToggleSwitch;
