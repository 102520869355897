import React from 'react';
import styled from 'styled-components';

/**
 * Creates the styled button group wrapper
 */
const GroupWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

/**
 * Inject a specific prop into a button group element depending on it's order in the given set of the buttons
 * @param child
 * @param index
 * @param length
 * @return {React.DetailedReactHTMLElement<{groupPosition: string}, HTMLElement>}
 */
const attachPosition = (child, index, length) => {
	let position = 'middle';
	if (index === 0) {
		position = 'first';
	}

	if (index === length - 1) {
		position = 'last';
	}

	return React.cloneElement(child, { groupPosition: position });
};

/**
 * Button Group wrapper
 *
 * Used to inject additional prop into it's buttons
 * @param children
 * @param props
 * @return {*}
 */
export default ({ children, ...props }) => (
	<GroupWrapper className="asteria-button-group" {...props}>
		{React.Children.map(children, (child, index) =>
			attachPosition(child, index, children.length),
		)}
	</GroupWrapper>
);
