export default {
	'menu.financial.title': 'Finanzas',
	'menu.financial.factoring.title': 'Factoring',
	'menu.financial.currencyrisk.title': 'Riesgo de cambio',
	'menu.categories.title': 'Categorías',
	'menu.clients.title': 'Clientes',
	'menu.status.title': 'Estado',
	'company.overview.title': 'Monedas',
	'currencies.account': 'Cuenta',
	'currencies.currency': 'Divisa',
	'menu.currency.title': 'Monedas',
	'drawer.currency.notification':
		'Su negocio puede verse afectado por las fluctuaciones monetarias. Haga clic aquí para obtener una descripción general de sus monedas.',
	'request.help.action': 'Obtenga ayuda con el riesgo cambiario',
	'graph.tab.accountbalance': 'Saldo de la cuenta',
	'graph.tab.revenueexpenses': 'Depósitos / retiros',
	'factoring.first.title': 'Cómo funciona el factoring',
	'factoring.first.description':
		'Factoring significa que usted vende o hipoteca sus facturas a nosotros para que pueda liberar dinero mientras el banco maneja sus cuentas por cobrar. Es a la vez simple y rentable!',
	'factoring.first.steps.title': 'Así es como funciona',
	'factoring.first.steps.description':
		'1. Seleccione las facturas que desea manejar para las compras de facturas, vea los efectos immidiate en su flujo de efectivo en los gráficos</br></br>2. Revisa las facturas y el importe seleccionados</br></br>3. Enviar facturas al factoring',
	'button.abort': 'Cancelar',
	'button.start': 'Empezar',
	'factoring.title': 'Factoring',
	'factoring.sent.title': 'Factoring',
	'factoring.list.header': 'Para ser enviado a factoring',
	'factoring.list.empty.title': 'Eesto es lo que funciona',
	'factoring.list.empty.text':
		'Marque la casilla a la izquierda del nombre del cliente para ver cómo cambia su flujo de efectivo si factoriza las facturas. Aprobar antes de enviar para factoring.',
	'factoring.item.cost': 'Costo',
	'factoring.item.amount': 'Monto',
	'factoring.item.status': 'Estado',
	'factoring.total.amount.total': 'Total',
	'factoring.sent.content.title2': '{{count}} facturas',
	'factoring.sent.content.title1': 'Costo {{cost | round}} {{currency}}',
	'cashflow.title': 'Smart Cash Flow',
	'graph.cashflow.show_account': 'Mostrar el saldo de mi cuenta',
	'graph.cashflow.hide_account': 'Ocultar el saldo de mi cuenta',
	'button.factoring': 'Factoring ({{count}})',
	'factoring.footer.count': '{{count}} transacciones',
	'list.client.total.transactions': '{{count}} transacciones',
	'drawer.finance.factoring.list.title': 'Factoring',
	'drawer.finance.factoring.list.processed': 'Compras de facturas históricas',
	'client.factoring.box': 'Reciba pagos directamente con factoring',
	'factoring.sent.content.title3':
		'Atenderemos su solicitud de compras con factura. Puede seguir el caso navegando hacia la parte superior izquierda.',
	'factoring.sent.alert.title': '¡gracias!',
	'button.done': 'Hecho',
	'signup.lastname.placeholder': 'Apellido',
	'factoring.total.cost': 'Costo',
	'factoring.item.category': 'Categoría',
	'factoring.item.paymentDate': 'Fecha de pago',
	'factoring.item.number': 'Facturas',
	'graph.yaxis.prefix': 'EUR',
	'notification.currency.risk.onboarding.body':
		'Hemos identificado un riesgo de {{total | number : false}} {{{currency | currencyFlag}}} {{currency}}',
	'notification.currency.risk.onboarding.action': 'Riesgo mínimo',

	'menu.financial.credit.title': 'Préstamos de crédito',

	'financial.service.overdraft.amount.label':
		'Solicita un préstamo de crédito con un monto de:',

	'button.apply': 'Solicitar',
	'overdraft.modal.confirm.title': 'Préstamos de crédito',

	'overdraft.sent.alert.title': 'Gracias',
	'overdraft.sent.content.title1': 'Solicitud de sobregiro',
	'overdraft.sent.content.title3':
		'Un asesor se comunicará con usted lo antes posible con respecto a su solicitud de sobregiro.',
	'button.close': 'Cerca',
	'financial.service.overdraft.helpTitle': 'Asi es como ellos trabajan',
	'financial.service.overdraft.help':
		'Haga clic y arrastre el rectángulo rojo que aparece en el gráfico del saldo de la cuenta para solicitar un sobregiro.',
	'credit.tag.name': 'Sobregiro',
};
