import isFunction from './isFunction';

export default function uniqBy(arr, identity) {
	const getIdentity = isFunction(identity)
		? identity
		: item => item[identity];
	return arr.filter(
		(item, index) =>
			arr.findIndex(
				otherItem => getIdentity(otherItem) === getIdentity(item),
			) === index,
	);
}
