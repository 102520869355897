import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import Text from './basic/text';
import InputV2 from './input';

const Slider = styled.div`
	display: flex;
	flex-direction: row;
	margin: 20px 0;
`;

const Input = styled.input`
	-webkit-appearance: none;
	background-color: #d4c4bc;
	height: 8px;
	overflow: visible;
	width: 300px;
	position: relative;
	border-radius: 4px;

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		background-color: #31a3ae;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		position: relative;
		z-index: 100;
	}

	&::-webkit-slider-runnable-track {
	}

	&:hover::-webkit-slider-thumb:before {
		opacity: 1;
	}
`;

const RangeSlider = styled(
	({
		className,
		min = 0,
		max = 100,
		step = 10,
		value = min,
		input = false,
		onChange = () => {},
	}) => {
		const [internalValue, setValue] = useState(value);

		const onChangeRange = e => {
			onChange(e);
			setValue(e.target.value);
		};

		useEffect(() => {
			if (value < min) {
				setValue(min);
			} else if (value > max) {
				setValue(max);
			} else {
				setValue(value);
			}
		}, [value, min, max]);
		return (
			<div className={classNames(className)} style={{}}>
				<Slider className="asteria-range-slider">
					<Input
						className="asteria-range-slider__range"
						type="range"
						value={internalValue}
						onChange={onChangeRange}
						min={min}
						max={max}
						step={step}
					/>
					{input ? (
						<InputV2
							elementType="input"
							type="number"
							max={max}
							min={min}
							step={step}
							placeholder="0"
							value={internalValue}
							onChange={onChangeRange}
						/>
					) : (
						<Text className="asteria-range-slider__value">
							{internalValue}
						</Text>
					)}
				</Slider>
			</div>
		);
	},
)`
	display: flex;
	flex-direction: row;

	${Text} {
		font-size: 14px;
		color: #72605e;
		font-family: Arial, Helvetica, sans-serif;
		margin: 8px 0 16px;
	}
`;
RangeSlider.displayName = 'RangeSlider';

RangeSlider.Styler = {
	children: [],
};

export default RangeSlider;
