import React, { useState, useCallback, useContext, useEffect } from 'react';
import { TextV2, Title } from '@asteria/component-typography';
import { ButtonV2 } from '@asteria/component-button';
import { FormGroup, InputV2, LabelV2 } from '@asteria/component-form';
import Alert from '@asteria/component-alert';
import { Translation, TranslationService } from '@asteria/services-language';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { initiateIntegration } from '@asteria/services-datalayer/services/integrations/actions';
import { useStore } from '@asteria/utils';
import Layout from '../../layout';
import Context from '../../context';
import HelpStepOne from './helpStepOne';

const removeError = (error, errors) =>
	errors.filter(({ key }) => error.key !== key);

const Key = ({ navigator, callback = () => {} }) => {
	const {
		setApiKey: persistApiKey,
		apiKey: persistedApiKey = '',
		setActiveModal,
		setStepIndex,
	} = useContext(Context);

	useEffect(() => {
		setStepIndex(2);
		callback('goto', {
			event: 'goto',
			integration: 'asteria',
			integrationTitle: 'asteria',
			step: {
				stepId: 'asteriaActivation',
				title: 'Aktivera',
			},
			steps: [
				{ stepId: 'asteriaActivation', title: 'Aktivera' },
				{ stepId: 'asteriaConnecting', title: 'Väntar på anslutning' },
			],
		});
	}, [callback, setStepIndex]);

	const { dispatch } = useContext(DatalayerContext);
	const [erps = []] = useStore(
		'store-integrations',
		({ integrations = [] }) => integrations,
	);
	const [integrationId] = useStore(
		'store-integrations',
		({ integrationInProgress }) => integrationInProgress,
	);

	const [invalidKeyError, setInvalidKeyError] = useState(false);
	const [errors, setErrors] = useState([]);
	const [apiKey, setApiKey] = useState(persistedApiKey);

	useEffect(() => {
		if (integrationId) {
			const integration = erps.find(int => int.id === integrationId);
			if (integration) {
				setErrors([...errors, ...integration.config.errors]);
				dispatch(() => ({
					action: 'CONNECTION_CLEANUP',
				}));
			}
		}
	}, [dispatch, erps, errors, integrationId]);

	const nextStep = useCallback(() => {
		if (!apiKey) {
			setInvalidKeyError(true);
		} else {
			persistApiKey(apiKey);
			dispatch(
				initiateIntegration({
					type: 'bank',
					integrationName: 'asteriaBank',
					config: {
						client: {
							authorizationCode: apiKey,
							customers: [
								{
									qty: 10,
									currency: 'SEK',
								},
								{
									qty: 5,
									currency: 'EUR',
								},
								{
									qty: 5,
									currency: 'USD',
								},
								{
									qty: 5,
									currency: 'UAH',
								},
							],
							suppliers: [
								{
									qty: 10,
									currency: 'SEK',
								},
								{
									qty: 5,
									currency: 'EUR',
								},
								{
									qty: 5,
									currency: 'USD',
								},
								{
									qty: 5,
									currency: 'UAH',
								},
							],
							bankAccounts: [
								{
									currency: 'SEK',
								},
								{
									currency: 'EUR',
								},
								{
									currency: 'USD',
								},
							],
						},
					},
				}),
			);
			navigator.next(null, navigator);
		}
	}, [apiKey, dispatch, navigator, persistApiKey]);

	return (
		<Layout
			type="asteria-key"
			header={
				<Title
					className="asteria-title-onboarding-header"
					size="title2"
				>
					{TranslationService.get(
						[
							'onboarding.connect.header',
							'onboarding.connect.bank.header',
						],
						'2. Anslut till bokföringssystem',
					)}
				</Title>
			}
			body={
				<>
					{/*
					<div
						style={{
							padding: '3px 18px 9px',
							borderBottom: '1px solid rgb(235, 231, 225)',
							marginBottom: '12px',
						}}
					>
						<TitleV2
							size="title2"
							className="asteria-loading-title"
							style={{
								fontSize: '24px',
								color: 'rgba(238,112,35,1)',
								display: 'block',
								fontFamily: 'Swedbank Headline',
								fontWeight: '400',
							}}
						>
							{TranslationService.get(
								'onboarding.asteria.connect.title',
								'{{num}} Anslut till bokföringssystem',
								{
									num: '2. ',
								},
							)}
						</TitleV2>
					</div>
					*/}
					{invalidKeyError ? (
						<Alert
							icon
							description={false}
							type="error"
							headingContent={
								<Translation
									translationKey="onboarding.asteria.api_form.missing_key"
									defaultText="Var vänlig ange din <b>API-nyckel</b> innan du fortsätter"
								/>
							}
							onClose={() =>
								setErrors(
									removeError(
										{ key: 'validationError' },
										errors,
									),
								)
							}
						/>
					) : null}
					{errors && errors.length > 0 ? (
						<Alert
							icon
							description={false}
							type="error"
							headingContent={
								<Translation
									translationKey="onboarding.asteria.connection.error"
									defaultText="asteria lyckades ej ansluta"
								/>
							}
							onClose={() =>
								setErrors(
									removeError(
										{ key: 'networkError' },
										errors,
									),
								)
							}
						/>
					) : null}
					<div
						className="asteria-dialog-box"
						style={{
							padding: '20px 20px 20px',
							backgroundColor: '#fbf2ea',
						}}
					>
						<Title size="title4">Asteria</Title>
						<TextV2 size="small">
							{TranslationService.get(
								'onboarding.asteria.api_form.title',
								'För att aktivera kopplingen behöver du hämta en API-nyckel i ditt bokföringssystem och fylla i den här.',
							)}
						</TextV2>

						<FormGroup>
							<LabelV2 path="form.label">
								{TranslationService.get(
									'onboarding.asteria.api_form.label',
									'API-nyckel',
								)}
							</LabelV2>
							<InputV2
								placeholder={TranslationService.get(
									'onboarding.asteria.api_form.placeholder',
									'',
								)}
								onChange={e => {
									if (invalidKeyError) {
										// hide alert after user started typing again
										setInvalidKeyError(false);
									}
									setApiKey(e.target.value);
								}}
								value={apiKey}
								invertedcolor
							/>
						</FormGroup>
					</div>
					<ButtonV2
						text={TranslationService.get(
							'onboarding.asteria.api_form.help',
							'Var hittar jag min API-nyckel',
						)}
						type="flat"
						size="medium"
						icon="triangleRight"
						iconPosition="after"
						onClick={() =>
							setActiveModal(HelpStepOne.navigationKey)
						}
					/>
					<div
						className="asteria-wizard-inner-footer"
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							paddingTop: '20px',
						}}
					>
						<ButtonV2
							size="medium"
							type="default"
							onClick={() =>
								navigator.cancel(
									{
										event: 'cancel',
									},
									navigator,
								)
							}
							text={TranslationService.get(
								'onboarding.abort',
								'Avbryt',
							)}
						/>
						<div
							className="asteria--activation-key-controls"
							style={{
								display: 'flex',
								minWidth: '220px',
								justifyContent: 'space-between',
							}}
						>
							<ButtonV2
								size="medium"
								type="secondary"
								onClick={() =>
									navigator.back(
										{
											event: 'goto',
											step: {
												stepId: 'selectErp',
											},
										},
										navigator,
									)
								}
								text={TranslationService.get(
									'onboarding.back',
									'Tillbaka',
								)}
							/>
							<ButtonV2
								size="medium"
								type="primary"
								text={TranslationService.get(
									'onboarding.continue',
									'Fortsätt',
								)}
								onClick={() => nextStep()}
							/>
						</div>
					</div>
				</>
			}
			footer={<></>}
		/>
	);
};

Key.navigationKey = Symbol('Key');

export default Key;
