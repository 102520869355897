const reducer = (state = {}, { action, payload }) => {
	switch (action) {
		case 'SET_VISIBLE_COLUMNS':
			return {
				...state,
				visibleColumns: payload,
			};
		case 'ADD_ITEM_ACTION':
			return {
				...state,
				actions: [
					...(state.actions || []),
					...(Array.isArray(payload) ? payload : [payload]),
				],
			};
		case 'SET_ITEM_ACTION':
			return {
				...state,
				actions: [...(Array.isArray(payload) ? payload : [payload])],
			};
		case 'ADD_IGNORE':
			return {
				...state,
				ignore: [
					...(state.ignore || []),
					...(Array.isArray(payload) ? payload : [payload]),
				],
			};
		case 'SET_IGNORE':
			return {
				...state,
				ignore: [...(Array.isArray(payload) ? payload : [payload])],
			};
		case 'SET_LIST_ITEMS':
			return {
				...state,
				items: payload,
				state: {
					...state.state,
					loading: false,
					active: null,
				},
			};
		case 'SET_USER': {
			const { settings: { listColumns = [] } = {} } = payload;

			return {
				...state,
				visibleColumns: listColumns,
			};
		}
		case 'REQUEST_MORE_DATA_LIST': {
			return {
				...state,
				loadingMore: true,
			};
		}
		case 'ADD_LIST_ITEMS':
			return {
				...state,
				loadingMore: false,
				items: [...state.items, ...payload],
			};
		case 'SET_LIST_STATISTICS': {
			const { data, path = 'statistics' } = payload;
			return {
				...state,
				[path]: data,
			};
		}
		case 'SET_LIST_CURSOR': {
			return {
				...state,
				cursor: payload,
			};
		}
		case 'SET_LIST_STATUS': {
			return {
				...state,
				status: payload,
			};
		}
		case 'SET_LIST_REVERSE': {
			return {
				...state,
				reverse: payload,
			};
		}
		case 'SET_LIST_DATES': {
			const { startDate, endDate, types, status, size, search } = payload;
			return {
				...state,
				startDate,
				endDate,
				types,
				status,
				size,
				search,
				state: {
					...state.state,
					loading: true,
					active: null,
				},
			};
		}
		case 'TOGGLE_LIST_ACTIVE_ROW': {
			return {
				...state,
				state: {
					...state.state,
					active:
						state.state.active === payload || !payload
							? null
							: payload,
				},
			};
		}
		case 'TOGGLE_LIST_SELECTED_ROW': {
			const { items, state: { selected = [] } = {} } = state;

			let newSelected = [];
			if (payload && payload.id === 'header') {
				if (selected.length === 0) {
					newSelected = items;
				} else {
					newSelected = [];
				}
			} else {
				newSelected = selected.includes(payload)
					? selected.filter(i => i !== payload)
					: [...selected, payload];
			}

			return {
				...state,
				state: {
					...state.state,
					selected: newSelected,
				},
			};
		}
		case 'SET_LIST_SELECTED_ROW': {
			return {
				...state,
				state: {
					...state.state,
					selected: payload,
				},
			};
		}
		case 'REMOVE_FROM_LIST': {
			return {
				...state,
				items: state.items.filter(item => item !== payload),
			};
		}
		default:
			return state;
	}
};

export default reducer;
