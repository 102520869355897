import React, { useCallback, useContext } from 'react';
import classNames from 'classnames';
import { useHistory } from '@asteria/utils/hooks';
import styled from 'styled-components';
import { Title, Text } from '@asteria/component-core';
import Button from '@asteria/component-core/button';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import {
	answerNotification,
	markNotificationAsViewed,
} from '@asteria/services-datalayer/services/notifications/actions';

import { Translation, TranslationService } from '@asteria/services-language';

import NotificationItemBase from './base';
import trigger from '../actions';

const NotificationItemText = styled(
	({
		className,
		id,
		title,
		notificationKey = '',
		data: { body, action, questions } = {},
		data: notificationData,
		onClick = () => {},
	}) => {
		const { dispatch, lookup } = useContext(DatalayerContext);
		const history = useHistory();

		const onClose = useCallback(() => {
			dispatch(markNotificationAsViewed(id));
		}, [dispatch, id]);

		const onAction = useCallback(() => {
			onClick();

			const result = trigger(action, { dispatch, lookup, history });

			if (result === true) {
				dispatch(markNotificationAsViewed(id));
			}
		}, [action, dispatch, history, id, lookup, onClick]);

		const onAnswer = useCallback(
			answer => {
				dispatch(markNotificationAsViewed(id));
				dispatch(answerNotification(id, answer));
			},
			[dispatch, id],
		);

		return (
			<NotificationItemBase
				onClick={onAction}
				className={classNames(
					className,
					`asteria-notification-item`,
					(notificationKey || '')
						.split('.')
						.reduce(
							(acc, k) => [...acc, `${acc[acc.length - 1]}-${k}`],
							['asteria-notification-item'],
						),
				)}
			>
				{title && (
					<div className="asteria-notification-item-header">
						<div className="asteria-title-wrapper">
							<Translation
								translationKey={title}
								defaultText={title}
								data={notificationData}
								Component={Title}
								size="title"
							/>
						</div>
					</div>
				)}

				<div className="asteria-notification-item-body">
					<Translation
						translationKey={body}
						defaultText={body}
						data={notificationData}
						Component={Text}
						size="text"
					/>
				</div>

				{action && (
					<div className="asteria-notification-item-footer">
						<Button
							className={className}
							onClick={onAction}
							type="link"
							size="small"
							title={TranslationService.get(
								action.text,
								action.text,
							)}
							text={TranslationService.get(
								action.text,
								action.text,
							)}
						/>
					</div>
				)}
				{questions && (
					<div className="asteria-notification-item-footer">
						{questions.map(({ text, answer }) => (
							<Button
								className={className}
								onClick={() => onAnswer(answer)}
								type="link"
								size="small"
								title={TranslationService.get(text, text)}
								text={TranslationService.get(text, text)}
							/>
						))}
					</div>
				)}
				<Button
					className="asteria-close"
					onClick={onClose}
					type="link"
					icon="close"
					size="small"
					title={TranslationService.get('notitification.hide')}
				/>
			</NotificationItemBase>
		);
	},
)``;

export default NotificationItemText;
