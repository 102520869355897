import SelectProvider from './selectProvider';
import Splash from './splash';
import Status from './status';
import { Key } from '../../erpSystemFlows/fortnox';
import { navigatorCarrier } from '../../createNavigator';

// Apply default navigation
Splash.navigator = {
	next: navigatorCarrier(SelectProvider.navigationKey),
};

SelectProvider.navigator = {
	next: navigatorCarrier(Key.navigationKey),
};

export default {
	[Status.navigationKey]: Status,
	[Splash.navigationKey]: Splash,
	[SelectProvider.navigationKey]: SelectProvider,
};

export { Status, Splash, SelectProvider };
