import { GLOBAL } from '@asteria/env';

import { createDispatcher } from './core';
import { Store as AuthStore } from './services/auth';
import { Store as ThemeStore } from './services/theme';
import GraphStore from './services/graph/store';
import ListStore from './services/list/store';
import AdjustableStore from './services/adjustable/store';
import LanguageStore from './services/language/store';
import AppStateStore from './services/appstate/store';
import BankAccountsStore from './services/bankaccounts/store';
import IntegrationsStore from './services/integrations/store';
import NotificationsStore from './services/notifications/store';

import HttpService from './core/httpService';
import WebsocketService from './core/websocketSubscriber';

import ThemeReducer from './services/theme/reducer';
import AuthReducer from './services/auth/reducer';
import GraphReducer from './services/graph/reducer';
import ListReducer from './services/list/reducer';
import AdjustableReducer from './services/adjustable/reducer';
import LanguageReducer from './services/language/reducer';
import AppStateReducer from './services/appstate/reducer';
import BankAccountsReducer from './services/bankaccounts/reducer';
import IntegrationsReducer from './services/integrations/reducer';
import NotificationsReducer from './services/notifications/reducer';

// Sources

import HttpSource from './source/http';
import DemoSource from './source/demo';

import {
	Store as FeaturesStore,
	Reducer as FeaturesReducer,
} from './services/features';

const ENVS = {
	default: {
		apiBaseURL: 'https://prod.api.asteria.ai/',
	},
	development: {
		apiBaseURL: 'https://dev-api.asteria.ai/',
	},
	local: {
		apiBaseURL: 'http://localhost:4004/',
		path: '',
	},
	stage: {
		apiBaseURL: 'https://stage-api.asteria.ai/',
	},
	production: {
		apiBaseURL: 'https://prod.api.asteria.ai/',
	},
	demo: {},
};

const init = async ({
	demo = false,
	platform = 'external',
	env = 'production',
	baseURL,
} = {}) => {
	const {
		createStore,
		lookup,
		register,
		dispatch,
		actions,
		subject,
	} = createDispatcher();

	const config = {
		...ENVS.default,
		...(ENVS[env] || {}),
	};

	const {
		apiBaseURL = 'https://prod.api.asteria.ai/',
		path = 'api/v3/',
	} = config;
	const apiURL = baseURL ? `${baseURL}${path}` : `${apiBaseURL}${path}`;

	if (window && !window.Asteria) {
		window.Asteria = {};
	}

	window.Asteria.Env = env;
	window.Asteria.Platform = platform;

	GLOBAL.baseUrl = baseURL || apiBaseURL;

	await register('store-auth', new AuthStore(subject));
	await register('store-theme', new ThemeStore(subject));
	await register('store-graph', new GraphStore(subject));
	await register('store-list', new ListStore(subject));
	await register('store-adjustable', new AdjustableStore(subject));
	await register('store-language', new LanguageStore(subject));
	await register('store-appstate', new AppStateStore(subject));
	await register('store-bankaccounts', new BankAccountsStore(subject));
	await register('store-integrations', new IntegrationsStore(subject));
	await register('store-notifications', new NotificationsStore(subject));

	await register(
		'service-api',
		new HttpService({
			baseURL: apiURL,
			authStore: lookup('store-auth'),
			demo,
		}),
	);

	await register(
		'service-websocket',
		new WebsocketService({
			uri: apiURL,
			authStore: lookup('store-auth'),
			demo,
		}),
	);

	await register(
		'service-old-api',
		new HttpService({
			baseURL: 'https://stage.api.asteria.ai/api/v1/graphql',
			authStore: lookup('store-auth'),
			demo,
			noBearer: true,
		}),
	);

	let source = HttpSource;
	if (demo) {
		source = DemoSource;
	}

	actions.subscribe(action =>
		source(action, {
			dispatch,
			register,
			lookup,
			createStore,
			actions,
		}),
	);

	createStore(ThemeReducer, lookup('store-theme'));
	createStore(AuthReducer, lookup('store-auth'));
	createStore(GraphReducer, lookup('store-graph'));
	createStore(ListReducer, lookup('store-list'));
	createStore(AdjustableReducer, lookup('store-adjustable'));
	createStore(LanguageReducer, lookup('store-language'));
	createStore(AppStateReducer, lookup('store-appstate'));
	createStore(BankAccountsReducer, lookup('store-bankaccounts'));
	createStore(IntegrationsReducer, lookup('store-integrations'));
	createStore(NotificationsReducer, lookup('store-notifications'));

	await register('store-features', new FeaturesStore(subject));
	createStore(FeaturesReducer, lookup('store-features'));

	return { dispatch, register, lookup, createStore, actions, apiURL, demo };
};

export default init;
export { init };
