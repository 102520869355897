import React, { Component, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ThemeProviderService } from '@asteria/services-themes';
import { LanguageProvider } from '@asteria/services-language';
import { useStore, useHistory } from '@asteria/utils/hooks';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import fetchTheme from '@asteria/services-datalayer/services/theme/actions';
import { fetchTranslations } from '@asteria/services-datalayer/services/language/actions';
import { fetchEnabledFeatures } from '@asteria/services-datalayer/services/features/actions';
import { FeatureFlag } from '@asteria/component-featureflag';

import CrashError from './crashError';
import Toolbar from './common/toolbar';

const DataLayer = ({ themeId = '5c40ae76a69c025d08e2d154', children }) => {
	const { dispatch } = useContext(DatalayerContext);

	const [theme = null] = useStore('store-theme', store => store.theme);
	const [fetchLanguage] = useStore(
		'store-language',
		store => store.fetchLanguage,
	);

	const [language] = useStore('store-language', store => store.language);

	// eslint-disable-next-line no-unused-vars

	const [loaded = false] = useStore('store-features', store => store.loaded);
	const [partnerId = false] = useStore(
		'store-appstate',
		store => store.partnerId,
	);

	const { styles = false } = theme || {};

	useEffect(() => {
		if (!fetchLanguage || !partnerId) {
			return;
		}
		dispatch(
			fetchTranslations({ code: fetchLanguage, themeId, partnerId }),
		);
	}, [themeId, dispatch, fetchLanguage, partnerId]);

	useEffect(() => {
		if (!fetchLanguage || !language) {
			return;
		}
		if (fetchLanguage === language) {
			dispatch(fetchTheme({ id: themeId }));
			dispatch(fetchEnabledFeatures());
		}
	}, [themeId, dispatch, fetchLanguage, language]);

	if (!styles || !loaded) {
		return null;
	}

	return (
		<LanguageProvider code={language}>
			<ThemeProviderService theme={styles}>
				{children}
			</ThemeProviderService>
		</LanguageProvider>
	);
};

DataLayer.propTypes = {
	themeId: PropTypes.string,
};

const AuthProtector = ({ children }) => {
	const [authorized] = useStore(
		'store-auth',
		({ authorized: value }) => value,
	);
	const history = useHistory();

	const pathName = history.location.pathname;

	history.replace('/');
	history.push(pathName);

	if (!authorized) {
		history.push('/login', { location: pathName });
	}

	return children;
};

const Wrapper = props => (
	<DataLayer {...props}>
		<AuthProtector {...props} />
		<FeatureFlag feature="wings-toolbar">
			<Toolbar />
		</FeatureFlag>
	</DataLayer>
);

class DataLayerWrapper extends Component {
	state = { hasError: false };

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	render() {
		const { hasError = false } = this.state;
		const { datalayer } = this.props;

		if (window && !window.Asteria) {
			window.Asteria = {};
		}

		window.Asteria.datalayer = datalayer;

		if (hasError) {
			return <CrashError />;
		}

		return (
			<DatalayerContext.Provider value={datalayer}>
				<Wrapper {...this.props} />
			</DatalayerContext.Provider>
		);
	}
}

export default DataLayerWrapper;
