import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { InputV2 } from '@asteria/component-form';
import debounce from '@asteria/utils/debounce';

const AdjustInlineTotal = styled(
	({ className, total: initialTotal = 0, min, max, onChange }) => {
		// Testing
		const [total, setTotal] = useState(initialTotal || 0);

		useEffect(() => {
			setTotal(
				Math.round(
					Math.max(
						Math.min(initialTotal, max || Number.MAX_VALUE),
						min || Number.MIN_VALUE,
					),
				),
			);
		}, [initialTotal, min, max]);

		const debouncedOnChange = useMemo(() => {
			if (!onChange) {
				return null;
			}

			return debounce(value => onChange(value), 250);
		}, [onChange]);

		const updateTotal = useCallback(
			e => {
				const text = e?.target?.value || '';

				const value = text ? parseFloat(text) : '';

				setTotal(value);

				if (onChange) {
					debouncedOnChange(value);
				}
			},
			[debouncedOnChange, onChange],
		);

		return (
			<div className={classNames(className)}>
				<InputV2
					className="asteria-adjustable-total"
					elementType="input"
					type="number"
					placeholder=""
					value={total}
					min={min}
					max={max}
					step={10000}
					helper="sek"
					onChange={updateTotal}
				/>
			</div>
		);
	},
)``;

export default AdjustInlineTotal;
