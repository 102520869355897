import React, { useState, useEffect, useContext, useCallback } from 'react';
import { ModalBody, ModalFooter } from '@asteria/component-modal';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import Button from '@asteria/component-button/button';
import classNames from 'classnames';
import Title from '@asteria/component-typography/title';
import Text from '@asteria/component-typography/text';
import { RangeSlider } from '@asteria/component-form';
// import { useUserSettings } from '@asteria/services-dashboard';
import { TranslationService } from '@asteria/services-language';
import styled from 'styled-components';

const GET_CURRENCIES = `
query Exposure($filters: TransactionFiltersInput){
	transactionCurrencies(filters: $filters) {
	  currency
	}

	company {
		id
		settings {
			currency
			currencies {
				currency
				threshold
			}
		}
	  }
  }
`;

const UPDATE_COMPANY = `
	mutation updateCurrentCompany($id: ID!, $input: CompanyUpdateInputType!) {
		updatePartialCompany(id: $id, input: $input) {
			ok
			error
		}
	}
`;

const SettingsView = styled(({ className, open, onClose }) => {
	const { lookup } = useContext(DatalayerContext);
	const apiService = lookup('service-api');
	const [currencies, setCurrencies] = useState([]);
	const [company, setCompany] = useState(false);

	useEffect(() => {
		if (open === false) {
			return;
		}

		apiService
			.query(GET_CURRENCIES, {}, { reqAuth: true })
			.subscribe(
				({
					data: {
						transactionCurrencies = [],
						company: userCompany = {},
					} = {},
				}) => {
					const defaultCurrency =
						userCompany?.settings?.currency || 'SEK';
					const companyCurrencies =
						userCompany?.settings?.currencies || [];
					setCompany(userCompany);
					setCurrencies(
						[
							...companyCurrencies,
							...transactionCurrencies.filter(
								({ currency }) =>
									!companyCurrencies.some(
										({ currency: userCurrency }) =>
											currency === userCurrency,
									),
							),
						]
							.filter(
								({ currency }) => currency !== defaultCurrency,
							)
							.sort(({ currency: a }, { currency: b }) =>
								a.localeCompare(b),
							),
					);
				},
			);
	}, [apiService, open]);

	const update = useCallback(
		(c, value) => {
			setCurrencies(
				[
					...currencies.filter(
						({ currency }) => currency !== c.currency,
					),
					{ ...c, threshold: parseInt(value, 10) || 0 },
				].sort(({ currency: a }, { currency: b }) =>
					a.localeCompare(b),
				),
			);
		},
		[currencies],
	);

	const save = useCallback(() => {
		apiService
			.query(
				UPDATE_COMPANY,
				{ id: company.id, input: { settings: { currencies } } },
				{ reqAuth: true },
			)
			.subscribe(() => {}, () => {});

		onClose();
	}, [apiService, company.id, currencies, onClose]);

	return (
		<div className={classNames(className)}>
			<ModalBody className="modal-body">
				<Title size="title2" className="asteria-title-settings-risk">
					{TranslationService.get(
						'settings.currency.risk.title',
						'Valutarisk',
					)}
				</Title>
				<Text>
					{TranslationService.get(
						'settings.currency.risk.standard.text',
						'Justera hur dina valutor påverkas lågt till högt.',
					)}
				</Text>
				<ul className="asteria-list asteria-list-currency-settings">
					{currencies.map(currency => (
						<li
							className="asteria-list-item"
							key={currency.currency}
						>
							<div className="asteria-list-item-inner">
								<div className="asteria-cell asteria-cell-risk-currency">
									<Text>
										<span
											className={classNames(
												'currency-flag',
												`currency-flag-${currency.currency.toLowerCase()}`,
											)}
										/>
										{currency.currency}
									</Text>
								</div>
								<div className="asteria-cell asteria-cell-risk-slider">
									<RangeSlider
										max={100}
										min={0}
										step={1}
										input
										value={
											currency.threshold !== null
												? currency.threshold
												: 10
										}
										onChange={e => {
											update(currency, e.target.value);
										}}
									/>
								</div>
							</div>
						</li>
					))}
				</ul>
			</ModalBody>
			<ModalFooter className="modal-footer">
				<Button
					size="medium"
					type="default"
					text={TranslationService.get(
						'settings.button.cancel',
						'Avbryt',
					)}
					onClick={onClose}
				/>
				<Button
					size="medium"
					type="primary"
					text={TranslationService.get(
						'settings.button.save',
						'Spara',
					)}
					onClick={save}
				/>
			</ModalFooter>
		</div>
	);
})``;

export default SettingsView;
