import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Getter, formatNumber } from '@asteria/utils';
import { Text } from '@asteria/component-core/typography';
import { TranslationService } from '@asteria/services-language';
import Pill from './pill';

const Box = styled.div``;
Box.Styler = {
	children: [
		{
			typePrefix: 'asteria-horizontal-bar-pill',
			component: Pill,
			base: [Getter('pill')],
		},
		{ component: Text, base: [Getter('text')] },
	],
};

const HorizontalScroll = styled.div`
	display: flex;
	/* height: 54px; */
	flex-wrap: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	overflow: -moz-scrollbars-none;
	scrollbar-width: none;
	-ms-overflow-style: none;
	/* margin-bottom: -32px; */
	padding-right: 32px;
	scrollbar-width: none;
	-ms-overflow-style: none;
	-webkit-mask: none;

	&::-webkit-scrollbar {
		height: 0 !important;
	}

	-webkit-mask: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0, black),
		color-stop(0.45, black),
		color-stop(0.7, black),
		color-stop(0.85, black),
		color-stop(1, rgba(0, 0, 0, 0))
	);
	mask: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0, black),
		color-stop(0.45, black),
		color-stop(0.7, black),
		color-stop(0.85, black),
		color-stop(1, rgba(0, 0, 0, 0))
	);

	@media only screen and (max-width: 1200px) {
		display: flex;
		margin-top: 0;
	}

	&:after {
		content: '&nbsp;';
		display: block;
		width: 100px;
		background: transparent;
		height: 30px;
		color: transparent;
		order: 99;
	}
`;

const HorizontalPills = styled(
	({ className, setListFilter, listFilter, max = 0, parts = [] }) => (
		<div className={classNames(className)}>
			<HorizontalScroll>
				{parts.map(({ total = 0, probability, status, tag }) => (
					<Box key={`${tag?.name}-${status}`}>
						<Pill
							onClick={() => setListFilter({ ...tag, status })}
							className={classNames(
								'asteria-horizontal-bar-pill',
								`asteria-horizontal-bar-pill-${status?.toLowerCase()}`,
								`asteria-horizontal-bar-pill-${tag?.name?.replace(
									'$',
									'',
								)}`,
								`asteria-horizontal-bar-pill-${status?.toLowerCase()}-${tag?.name?.replace(
									'$',
									'',
								)}`,
								{
									'asteria-state-active':
										(listFilter.id === tag?.id ||
											listFilter._id === tag?.id) &&
										listFilter.status === status,
								},
							)}
							text={TranslationService.get(
								[
									`list.details.${(
										tag?.name || 'unknown'
									).replace('$', '')}`,
									`list.details.${(
										tag?.name || 'unknown'
									).replace(
										'$',
										'',
									)}.${status?.toLowerCase()}`,
								],
								tag?.name ||
									TranslationService.get(
										'list.details.misc',
										'Övrigt',
									),
							)}
							color={tag?.name || '$misc'}
							status={status}
							probability={probability}
						/>
						<Text
							type="body"
							className="asteria-horizontal-bar-pill-total"
						>
							{`${formatNumber(total)} kr`}
						</Text>
						<Text
							type="body"
							className="asteria-horizontal-bar-pill-faction"
						>
							{`${Math.round((total / max) * 100)}% av totalen`}
						</Text>
					</Box>
				))}
			</HorizontalScroll>
		</div>
	),
)`
	display: flex;
	width: 100%;

	// When the user has clicked on a arrow to scroll right, just add the fade on the left side also
	/* -webkit-mask: -webkit-gradient(
		linear,
		right top,
		left top,
		color-stop(0, black),
		color-stop(0.45, black),
		color-stop(0.7, black),
		color-stop(0.85, black),
		color-stop(1, rgba(0, 0, 0, 0))
	);
	mask: -webkit-gradient(
		linear,
		right top,
		left top,
		color-stop(0, black),
		color-stop(0.45, black),
		color-stop(0.7, black),
		color-stop(0.85, black),
		color-stop(1, rgba(0, 0, 0, 0))
	); */
`;

HorizontalPills.displayName = 'HorizontalPills';

HorizontalPills.Styler = {
	children: [{ component: Box, base: [Getter('box')] }],
};
export default HorizontalPills;
