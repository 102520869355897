import React, { useContext } from 'react';
import { Text } from '@asteria/component-typography';
import { ButtonV2 } from '@asteria/component-button';
import Modal, { ModalBody, ModalFooter } from '@asteria/component-modal';
import { Translation, TranslationService } from '@asteria/services-language';
import { GLOBAL } from '@asteria/env';
import Context from '../../context';
import helpKeys from './helpKeys';

const HelpStepOne = () => {
	const { setActiveModal } = useContext(Context);

	return (
		<Modal
			backdrop
			open
			onClose={() => setActiveModal('')}
			size="small"
			detach
			asteria-onboarding-guide-modal
			title={TranslationService.get(
				'onboarding.asteria.help.first.header',
				'Var hittar jag min API-nyckel',
			)}
		>
			<ModalBody className="modal-body">
				<div
					style={{
						width: '100%',
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
					}}
				>
					<Text>
						<Translation
							translationKey="onboarding.asteria.help.first.url"
							defaultText="Logga in hos asteria"
							Component="a"
							href="https://apps.asteria.se/fs/fs/login.php"
							target="_blank"
							rel="noopener noreferrer"
						/>
					</Text>
					<Translation
						translationKey="onboarding.asteria.help.first.text"
						defaultText={`Klicka på ditt användarnamn och välj Tilläggsbeställning\nScrolla ner och aktivera Integration\n\nOm du redan aktiverat integrationer gå till nästa steg`}
						Component={Text}
					/>
					<div>
						<img
							style={{
								// width: '100%',
								width: '56%',
								display: 'flex',
								margin: '16px auto',
								// maxHeight: '350px',
								// paddingRight: '40px',
							}}
							src={`${
								GLOBAL.baseUrl
							}assets/asteria/images/integrations/asteria/gif/01.gif`}
							alt=""
						/>
					</div>
				</div>
			</ModalBody>
			<ModalFooter className="modal-footer">
				<div
					className="asteria-onboarding-help-footer"
					style={{
						display: 'flex',
						width: '100%',
						justifyContent: 'space-between',
					}}
				>
					<ButtonV2
						size="medium"
						type="default"
						text={TranslationService.get(
							'onboarding.abort',
							'Avbryt',
						)}
						onClick={() => setActiveModal('')}
					/>
					<ButtonV2
						size="medium"
						type="primary"
						text={TranslationService.get(
							'onboarding.continue',
							'Fortsätt',
						)}
						onClick={() => setActiveModal(helpKeys.HelpStepTwo)}
					/>
				</div>
			</ModalFooter>
		</Modal>
	);
};

HelpStepOne.navigationKey = helpKeys.HelpStepOne;

export default HelpStepOne;
