import React, {
	useContext,
	useState,
	useCallback,
	useRef,
	useEffect,
	useMemo,
} from 'react';
import styled from 'styled-components';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import Modal, { ModalBody, ModalFooter } from '@asteria/component-modal';
import classNames from 'classnames';
import { formatNumber, useStore } from '@asteria/utils';
import Button from '@asteria/component-button/button';
import { useFeature } from '@asteria/component-featureflag';
import { saveUser } from '@asteria/services-datalayer/services/auth/actions';
import { GLOBAL } from '@asteria/env';
import { Text, Title } from '@asteria/component-typography';
import Alert from '@asteria/component-alert';
import debounce from '@asteria/utils/debounce';

import { useHistory } from '@asteria/utils/hooks';

import { Translation, TranslationService } from '@asteria/services-language';
import {
	setCredit,
	setMode,
} from '@asteria/services-datalayer/services/appstate/actions';
import { Input } from '@asteria/component-form';
import SidePane from '@asteria/component-core/sidepane';
// import { NeedInput } from '../components/statement';

const Image = styled(({ className, src, fallback, ...props }) => {
	const [image, setImage] = useState(src);

	useEffect(() => {
		setImage(src);
	}, [src]);

	return (
		<img
			className={className}
			src={image}
			alt=""
			onError={() => {
				setImage(fallback);
			}}
			{...props}
		/>
	);
})``;

const CreditFirstTimeModal = styled(
	({ className, open = false, onClose: onCloseModal = () => {} }) => {
		const { dispatch } = useContext(DatalayerContext);
		const history = useHistory();
		const [user = {}] = useStore('store-auth', ({ user: obj }) => obj);

		const [site] = useStore('store-appstate', state => state.site);

		const onClose = useCallback(() => {
			onCloseModal();
			dispatch(
				saveUser({
					...user,
					settings: {
						...(user?.settings || {}),
						flags: {
							...(user?.settings?.flags || {}),
							hasReadCreditOnboarding: true,
						},
					},
				}),
			);
		}, [dispatch, onCloseModal, user]);

		const [integrations = []] = useStore(
			'store-integrations',
			({ integrations: list = [] }) => list,
		);

		const hasERP = integrations.find(({ type }) => type === 'erp');

		const onAbort = useCallback(() => {
			dispatch(setMode(null));
		}, [dispatch]);

		if (user?.settings?.flags?.hasReadCreditOnboarding && open === false) {
			return null;
		}

		return (
			<Modal
				open
				className={className}
				title={TranslationService.get([
					'modal.overdraft.firsttime.title',
					'Hur fungerar Finansiering',
				])}
				onClose={onClose}
			>
				<ModalBody>
					<div className="asteria-credit-firsttime-help">
						<div className="asteria-content-group">
							<Title size="title3">
								{TranslationService.get(
									'financial.service.overdraft.helpTitle',
									'Så här fungerar det',
								)}
							</Title>
							<Translation
								Component={Text}
								translationKey="financial.service.overdraft.help"
								defaultText="Klicka och drag i den röda rektangeln som syns i kontobalansgrafen för själv räkna ut vilket finansieringsbehov som skulle kunna passa dig, du kan även fylla i rutan nedan. När du räknat ut ditt finansieringsbehov skickas du vidare till kontaktformuläret. För att eventuellt få ett en snabbare granskning av ärendet rekommenderar vi att du gör följande."
							/>
						</div>
						<Image
							src={`${
								GLOBAL.baseUrl
							}assets/${site}/images/guide/factoring.gif`}
							fallback={`${
								GLOBAL.baseUrl
							}assets/asteria/images/guide/factoring.gif`}
							alt="factoring"
							style={{ display: 'none' }}
						/>
						<div className="asteria-content-group">
							<Title size="title4">
								{TranslationService.get(
									'financial.service.overdraft.helpTitleTwo',
									'Ansluter ditt bokföringssystem',
								)}
							</Title>
							<Text>
								{TranslationService.get(
									'financial.service.overdraft.helpTwo',
									'Genom att ansluta ditt bokföringssystem får du i tillägg till en bättre prognos även tillgång till din resultat- och balansräkning.',
								)}
							</Text>

							{hasERP ? (
								<Button
									type="link"
									size="small"
									className="asteria-button-show-statement"
									icon="triangleRight"
									iconPosition="after"
									onClick={() => history.push('/statement')}
								>
									{TranslationService.get(
										'statement.button.open',
										'Visa årsrapport',
									)}
								</Button>
							) : (
								<Button
									type="link"
									size="small"
									className="asteria-button-add-erp"
									icon="triangleRight"
									iconPosition="after"
									onClick={() =>
										history.push('/integrations/erp/add')
									}
								>
									{TranslationService.get(
										'statement.missing.erp.add',
										'Lägg till bokföringssystem',
									)}
								</Button>
							)}
						</div>

						<div className="asteria-content-group">
							<Title size="title4">
								{TranslationService.get(
									'financial.service.overdraft.helpTitleThree',
									'Gör en prognos',
								)}
							</Title>
							<Text>
								{TranslationService.get(
									'financial.service.overdraft.helpThree',
									'Det underlättar för rådgivaren att se en prognos på ert förväntade kassaflöde för innevarande samt kommande två år.',
								)}
							</Text>
						</div>

						<div className="asteria-content-group">
							<Title size="title4">
								{TranslationService.get(
									'financial.service.overdraft.helpTitleFour',
									'Delar Företagskollen med rådgivare',
								)}
							</Title>
							<Text>
								{TranslationService.get(
									'financial.service.overdraft.helpFour',
									'Du ser om du delar din data från Företagskollen under:',
								)}
							</Text>
							<Text className="asteria-text-bold">
								{TranslationService.get(
									'financial.service.overdraft.helpFourSub',
									'Övriga tjänster >  Godkännande och val > Företagskollen – dela med rådgivare',
								)}
							</Text>
						</div>
					</div>
				</ModalBody>
				<ModalFooter className="asteria-modal-footer">
					<Button
						size="medium"
						type="default"
						onClick={onAbort}
						text={TranslationService.get('button.abort', 'Avbryt')}
					/>
					<Button
						size="medium"
						type="primary"
						text={TranslationService.get('button.start', 'Starta')}
						onClick={onClose}
					/>
				</ModalFooter>
			</Modal>
		);
	},
)`
	.asteria-credit-firsttime-help {
		display: flex;
		flex-direction: column;
		background-color: #fbf2ea;
		margin-top: 16px;
		padding: 16px;

		${Title} {
			margin-bottom: 8px;
		}

		img {
			max-width: 50%;
			padding-right: 0;
			margin: 0 auto 8px;
		}

		div {
			padding-left: 9px;
		}
	}
	.asteria-modal-footer {
		.asteria-button {
			.asteria-text {
				margin-bottom: 0 !important;
			}
		}
		justify-content: space-between;
		.asteria-button-primary {
			margin-left: auto;
			.asteria-text {
				font-size: 16px;
			}
		}
	}
`;

const ActionBar = styled(({ className }) => {
	const { dispatch } = useContext(DatalayerContext);

	const hasCreditSidePane = useFeature({ key: 'credit-sidepane' });

	const [applyCredit, setApplyCredit] = useState(false);
	const [localCredit, setLocalCredit] = useState(false);
	const [error, setError] = useState(false);

	const [showFirstTimeModal, setShowFirstTimeModal] = useState(false);
	const [mode] = useStore('store-appstate', ({ mode: val }) => val);
	const [credit] = useStore('store-appstate', ({ credit: val }) => val);
	const [displayCurrency] = useStore(
		'store-appstate',
		({ displayCurrency: val }) => val,
	);
	const originalCredit = useRef(null);

	useEffect(() => {
		if (originalCredit.current === null) {
			originalCredit.current = credit;
		}

		if (mode !== 'credit') {
			dispatch(setCredit(originalCredit.current));
		}
	}, [credit, dispatch, mode]);

	useEffect(() => {
		setLocalCredit(Math.ceil(credit / 10000) * 10000);
	}, [credit]);

	const debouncedOnChange = useMemo(
		() =>
			debounce(value => {
				let val = parseInt(value, 10);

				setError(false);

				if (!Number.isNaN(val)) {
					val = value;
					dispatch(setCredit(val));
				}
			}, 250),
		[dispatch],
	);

	// const [integrations = []] = useStore(
	// 	'store-integrations',
	// 	({ integrations: list = [] }) => list,
	// );

	// const hasERP = integrations.find(({ type }) => type === 'erp');

	if (mode !== 'credit' || !hasCreditSidePane) {
		return null;
	}

	return (
		<>
			{applyCredit && (
				<Modal
					open
					title={TranslationService.get(
						'overdraft.modal.confirm.title',
						'Kreditlån',
					)}
					className="asteria-modal-overdraft-confirm"
					onClose={() => {
						setApplyCredit(0);
					}}
				>
					<ModalBody className="asteria-modal-body">
						<Alert
							icon
							type="success"
							headingContent={TranslationService.get(
								'overdraft.sent.alert.title',
								'Tack!',
							)}
							showClose={false}
							description={
								<>
									<Title
										size="title2"
										style={{
											marginTop: '0px',
											marginBottom: '2',
											fontSize: '14px',
										}}
									>
										{TranslationService.get(
											'overdraft.sent.content.title1',
											'Finansieringsbehov',
										)}
									</Title>
									<Title
										size="title1"
										style={{
											marginTop: '0px',
											marginBottom: '8px',
										}}
									>
										{formatNumber(applyCredit, false)}
									</Title>
									<Text size="small">
										{TranslationService.get(
											'overdraft.sent.content.title3',
											'Du kommer få fylla i detta belopp manuellt igen på nästa sida.',
										)}
									</Text>
								</>
							}
						/>
					</ModalBody>
					<ModalFooter className="asteria-modal-footer">
						<Button
							size="medium"
							type="default"
							text={TranslationService.get('button.close')}
							onClick={() => {
								setApplyCredit(0);
							}}
						/>
						<Button
							size="medium"
							type="primary"
							text={TranslationService.get('button.continue')}
							onClick={() => {
								setApplyCredit(0);
								dispatch(setCredit(originalCredit.current));
								dispatch(setMode(null));

								const url = TranslationService.get(
									'overdraft.sent.redirect',
									'/app/ib/lana/foretagslan/ansokan?amount={{credit}}',
									{
										credit: applyCredit,
									},
								);

								if (url) {
									window.history.pushState(
										{},
										TranslationService.get(
											'overdraft.sent.redirect.title',
											'Finansiera',
										),
										url,
									);
								}
							}}
						/>
					</ModalFooter>
				</Modal>
			)}
			<SidePane
				open={mode === 'credit' && hasCreditSidePane}
				onClose={() => {
					setApplyCredit(0);
					dispatch(setCredit(originalCredit.current));
					dispatch(setMode(null));
				}}
				className={classNames(
					className,
					'asteria-sidepane-right asteria-sidepane-overdraft-application',
				)}
				title={TranslationService.get(
					'credit.sidepane.title',
					'Finansieringsbehov',
				)}
				actions={[
					{
						type: 'default',
						action: () => {
							setApplyCredit(0);
							dispatch(setCredit(originalCredit.current));
							dispatch(setMode(null));
						},
						title: TranslationService.get('button.abort'),
					},
					{
						type: 'secondary',
						action: () => {
							if (
								!Number.isFinite(localCredit) ||
								originalCredit.current === localCredit
							) {
								setError(true);
							} else {
								setApplyCredit(localCredit);
							}
						},
						title: TranslationService.get(
							'button.continue',
							'Gå vidare',
						),
					},
					/*
				...(hasERP
					? [
							{
								type: 'primary',
								action: () => {
									setApplyCredit(credit);
								},
								title: TranslationService.get('button.send'),
							},
					  ]
					: []),
				*/
				]}
			>
				<CreditFirstTimeModal
					open={showFirstTimeModal}
					onClose={() => setShowFirstTimeModal(false)}
				/>
				<Title size="title4">
					{TranslationService.get(
						'financial.service.overdraft.amount.label',
						'Möjligt finansieringsbehov:',
					)}
				</Title>
				<div className="asteria-credit-application-input-wrapper">
					<Input
						value={localCredit}
						onChange={e => {
							setLocalCredit(e.target.value);
							debouncedOnChange(e.target.value);
						}}
					/>
					<Text>{displayCurrency}</Text>
				</div>
				{error && (
					<Alert
						icon
						description={false}
						type="error"
						headingContent={
							<Translation
								translationKey="financial.service.overdraft.amount.error"
								defaultText="Du måste ange ett belopp som skiljer sig från din nuvarande finansiering"
								data={{ original: originalCredit.current }}
							/>
						}
					/>
				)}

				<Button
					type="link"
					size="small"
					className="asteria-button-help"
					onClick={() => setShowFirstTimeModal(true)}
				>
					{TranslationService.get(
						'financial.service.overdraft.help.link',
						'Hur fungerar Finansiering?',
					)}
				</Button>

				{/* Need input bara synlig om ej ERP finns påkopplat */}

				{/* Skicka kan bara finnas om de har ERP påkopplat */}
			</SidePane>
		</>
	);
})`
	.asteria-button-add-erp {
		padding: 0;
		text-align: left;
		margin: 0 0 32px;
		justify-content: flex-start;
		align-items: center;
		.asteria-text {
			margin: 0 !important;
		}
	}

	.asteria-button-help {
		padding: 0;
		text-align: left;
		margin: 8px 0 32px;
		justify-content: flex-start;
		align-items: center;
		.asteria-text {
			margin: 0 !important;
		}
	}

	.asteria-content-group {
		margin-bottom: 16px;

		.asteria-text {
			margin-bottom: 8px;
		}

		.asteria-text-bold {
			font-size: 12px;
			margin-top: -8px;
			color: #512b2b;
			font-weight: 800;
		}

		.asteria-button-add-erp {
			margin-bottom: 0;
			margin-top: -8px;
		}

		.asteria-button-show-statement {
			margin-bottom: 0;
			margin-top: -8px;
			padding-left: 0;
			.asteria-text {
				margin: 0;
				margin-bottom: 0 !important;
			}
		}
	}

	.asteria-credit-application-input-wrapper {
		display: flex;
		align-items: center;
		.asteria-input {
		}
		.asteria-text {
			margin: 0 0 0 16px !important;
		}
	}

	.asteria-alert-error {
		margin-top: 8px;
		.asteria-text {
			margin-right: 8px;
			margin-bottom: 0px !important;
			font-size: 12px;
		}
	}
`;

export default ActionBar;
