const reducer = (state = {}, { action, payload }) => {
	switch (action) {
		case 'SET_BANK_ACCOUNTS':
			return {
				...state,
				bankAccounts: payload,
				credit: payload?.reduce(
					(total, account) =>
						total + account?.sums?.display?.credit || 0,
					0,
				),
				loadingBankAccounts: false,
			};
		case 'BANK_ACCOUNTS_LOADING':
			return {
				...state,
				loadingBankAccounts: true,
			};
		default:
			return state;
	}
};

export default reducer;
