/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_TRANSACTIONS_STATS = gql`
	query GetList($startDate: Date, $endDate: Date, $status: [String]) {
		transactionStatistic(
			filters: {
				startDate: $startDate
				endDate: $endDate
				status: $status
			}
		) {
			firstDate
			lastDate
			deposit {
				total
				oracle {
					probability
					max {
						original {
							total
							currency
						}
					}
					min {
						original {
							total
							currency
						}
					}
				}
				parts {
					total
					percentage
					status
					probability
					count
					tag {
						id
						name
					}
				}
			}

			withdraw {
				total
				oracle {
					probability
					max {
						original {
							total
							currency
						}
					}
					min {
						original {
							total
							currency
						}
					}
				}
				parts {
					total
					percentage
					status
					probability
					count
					tag {
						id
						name
					}
				}
			}

			clients {
				total
				clientId
			}
		}
	}
`;

export const GET_TRANSACTIONS = gql`
	query GetList(
		$endDate: Date
		$startDate: Date
		$types: [String]
		$status: [String]
		$search: JSON
		$beforeCursor: String
		$afterCursor: String
		$last: Int
		$first: Int
	) {
		transactions(
			filters: {
				endDate: $endDate
				startDate: $startDate
				types: $types
				status: $status
			}
			search: $search
			pageFilters: {
				last: $last
				first: $first
				orderField: "paymentDate"
				before: $beforeCursor
				after: $afterCursor
			}
		) {
			pageInfo {
				startCursor
				endCursor
				hasNextPage
				hasPreviousPage
			}
			edges {
				node {
					id
					type
					status
					paymentDate
					sums {
						original {
							total
							currency
						}

						display {
							total
							currency
							rate
						}
					}
					meta {
						description
						categories {
							id
							name
						}
						tags {
							id
							_id
							name
							category {
								id
								_id
								name
							}
						}
					}
					description
					message
					oracle {
						risk
						max {
							original {
								total
								currency
							}
						}
						min {
							original {
								total
								currency
							}
						}
						forecast {
							risk
							max {
								display {
									total
									currency
								}
							}
							min {
								display {
									total
									currency
								}
							}
						}
						currency {
							risk
							max {
								display {
									total
									currency
								}
							}
							min {
								display {
									total
									currency
								}
							}
						}
					}
					manual
					recurrentEvent {
						startDate
						endDate
						period
						parentId
					}
					links {
						id
						type
						invoiceDetails: details {
							... on Invoice {
								dates {
									invoiceSent
									invoiceDue
									invoicePaid
								}
								meta {
									message
									invoiceNumber
								}
								info {
									status
								}
								sums {
									original {
										total
										currency
									}
									display {
										rate
									}
								}
								client {
									id
									name
									type
								}
							}
						}
						accountDetails: details {
							... on BankAccountTransaction {
								identifiers {
									accountNumber
								}
								dates {
									booked
								}
								sums {
									account {
										total
										currency
										rate
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
