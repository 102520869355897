import React from 'react';
import styled from 'styled-components';
import { compileObject, StyleGetter } from '@asteria/utils';

const BarView = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	transition: width 0.3s linear;
`;

const CircularBar = ({ percentage, theme, ...props }) => {
	const circumference = 5.85 * 2 * 3.1416;
	const { bar: { svg: { style: svgStyle = {} } = {} } = {} } = compileObject(
		{
			base: [StyleGetter(`progressBar.circular`, {})],
		},
		theme,
	);
	const radius = 5.85;

	return (
		<BarView {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.7 12.7">
				<circle
					r={radius + 1 - (parseInt(svgStyle.width, 10) / 2 || 1)}
					cy="6.3499999"
					cx="6.3499999"
					transform="rotate(-90 6.35 6.35)"
					style={{
						fill: 'none',
						stroke: svgStyle.stroke,
						strokeWidth: parseInt(svgStyle.width, 10) / 2 || '1',
						strokeDasharray: `${(percentage / 100) *
							circumference}, ${circumference}`,
						transition: '0.3s linear',
					}}
				/>
			</svg>
		</BarView>
	);
};

export default CircularBar;
