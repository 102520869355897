import React, { useContext, useRef, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import Text from '@asteria/component-typography/text';
import { formatNumber, Getter } from '@asteria/utils';
import classNames from 'classnames';
import { useFeature } from '@asteria/component-featureflag';
import GraphContext from '../../context';

const Dot = styled.div`
	height: 10px;
	width: 10px;
	background-color: white;
	border: 2px solid rgb(114, 96, 95);
	border-radius: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
`;

const pulseAnimation = keyframes`
	0% {
		transform: scale(0.95) translate(-50%, -50%);
		box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
	}
	
	70% {
		transform: scale(1) translate(-50%, -50%);
		box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
	}
	100% {
		transform: scale(0.95) translate(-50%, -50%);
		box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
	}
}`;

const Becon = styled(Dot)`
	background-color: rgba(255, 121, 63, 1);
	box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
	border-radius: 50%;
	transform: scale(1) translate(-50%, -50%);
	animation: ${pulseAnimation} 2s infinite;
`;

const calcPosition = ({
	layout,
	maxValue = 0,
	minValue = 0,
	center = 50,
	group: { lines: [{ value = 0 } = {}] = [] } = {},
}) => {
	const range = Math.abs(maxValue - minValue);
	const relateiveValue = value - minValue;

	let top = 100 - (relateiveValue / range) * 100;

	if (layout === 'stacked') {
		top = center - (value / maxValue) * center;
	}

	return Math.max(Math.min(top, 100), 0);
};

const LineInfo = styled(props => {
	const { group = {}, className, becon = false } = props;
	const ref = useRef(null);
	const { mouseEnterAction, mouseLeaveAction, clickAction } = useContext(
		GraphContext,
	);

	const hasFxModule = useFeature({
		key: 'fx-module',
	});

	const clickHandler = useCallback(() => {
		clickAction({ group, source: 'account' });
	}, [clickAction, group]);

	const top = calcPosition(props);

	const { lines: [line = {}] = [] } = group;

	return (
		<div
			role="button"
			tabIndex="-1"
			className={classNames(className, {
				'asteria-graph-line-info-below-zero': line.value < 0,
				'asteria-graph-line-info-close-to-top': top < 15,
				'asteria-graph-line-info-risk':
					hasFxModule &&
					line?.info?.some(({ type }) => type === 'risk'),
			})}
			onClick={clickHandler}
			onKeyPress={clickHandler}
			onMouseEnter={() => mouseEnterAction({ line, group, target: ref })}
			onMouseLeave={() => mouseLeaveAction({ line, group, target: ref })}
		>
			{becon ? <Becon ref={ref} /> : <Dot ref={ref} />}
			<Text size="label" className="asteria-graph-line-dot">
				{formatNumber(line.value, false)}
			</Text>
		</div>
	);
})`
	position: absolute;
	top: ${props => `${calcPosition(props)}%`};
	right: 0px;
	height: 0px;
	width: 0px;
	pointer-events: all;
	:hover {
		${Dot} {
			background-color: rgb(255, 182, 0);
		}
	}

	&.asteria-graph-line-info-risk {
		${Dot} {
			background-color: red;
			animation: ${pulseAnimation} 2s infinite;
		}
	}

	/* TODO:
	- I want to be able to style the color of the amount above the dot
	- I want to be able to style uniquely the color, font, size etc for the amount above the dot
	*/

	${Text} {
		transform: translateX(-50%);
		position: absolute;
		top: -25px;
		cursor: pointer;
		white-space: nowrap;
		color: #512b2b;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	&.asteria-graph-line-info-close-to-top {
		${Text} {
			top: 10px;
		}
	}
`;
LineInfo.displayName = 'Dot';
LineInfo.Styler = {
	typePrefix: 'asteria-graph-line-info',
	children: [
		{ component: Dot, base: [Getter('dot')] },
		{ component: Text, base: [Getter('text')] },
	],
};

export default LineInfo;
