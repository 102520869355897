import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import Button from '@asteria/component-core/button';

const Dialog = styled(({ className, children, onClose }) => (
	<div className={classNames(className)}>
		<Button
			type="link"
			icon="close"
			className="asteria-button-dialog-close"
			onClick={onClose}
		/>
		{children}
	</div>
))`
	position: fixed;
	bottom: 24px;
	left: 264px;
	width: 250px;
	padding: 12px;

	${Button} {
		&.asteria-button-dialog-close {
			position: absolute;
			right: 6px;
			top: 6px;
		}
	}
`;

Dialog.displayName = 'Dialog';

Dialog.Styler = {
	children: [],
};
export default Dialog;
