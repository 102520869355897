import React, { Component } from 'react';
import styled from 'styled-components';
import Title from '@asteria/component-typography/title';
import Text from '@asteria/component-typography/text';
import { preProcess } from '@asteria/utils';
import Pointer from './pointer';

const currentMinWidth = 160; // currently set in the theme
const minWidthSecurity = 28; // magic number to handle current shadows (which will add horizontal scroll bar if placed outside body) and something else o_O

const ContentWrapper = styled.div`
	align-items: stretch;
	flex-basis: auto;
	flex-shrink: 0;
	border: 0 solid black;
	margin: 0;
	min-height: 0;
	min-width: 0;
	padding: 0;
	position: relative;
	flex-direction: column;
	display: flex;

	-webkit-align-items: stretch;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	border: 0 solid black;
	margin: 0;
	min-height: 0;
	min-width: 0;
	padding: 0;
	position: relative;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	position: static;
	z-index: 1000;
	background-color: #fff;
	padding: 16px 16px;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 5px;
	border: 1px solid #999999;
	border-radius: 3px;
	min-width: 160px;
	right: 0;
	bottom: 120%;

	position: ${props => (props.isStatic ? 'static' : 'absolute')};
	z-index: 1000;
	${({ maxwidth }) =>
		maxwidth && maxwidth < currentMinWidth
			? `min-width: ${maxwidth - minWidthSecurity}px`
			: ''};

	${Text} {
		text-align: center;
		max-width: 200px;
	}

	${Title} {
		margin-bottom: 3px;
		margin-bottom: 3px;
		font-size: 14px;
		text-transform: none;
		text-align: center;
		line-height: 18px;
		font-family: 'Swedbank Headline', sans-serif;
		color: ${({ theme }) => preProcess('var(--text-color)', theme)};
	}
`;

class TooltipContent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: props.open,
		};
	}

	handler = () => {
		const { isOpen } = this.state;
		this.setState({
			isOpen: !isOpen,
		});
	};

	render() {
		const {
			title,
			content,
			headerContent,
			styler,
			path,
			innerRef,
			isStatic,
			pointerOffset = 0,
			...props
		} = this.props;

		let titleEl = title;
		if (!React.isValidElement(titleEl)) {
			titleEl = title ? <Title>{title}</Title> : null;
		}

		let contentEl = headerContent;
		if (!React.isValidElement(contentEl)) {
			contentEl = headerContent ? <Text>{headerContent}</Text> : null;
		}

		return (
			<ContentWrapper
				editStyle
				ref={innerRef}
				isStatic={isStatic}
				{...props}
				className="asteria-tooltip-wrapper"
			>
				{titleEl}
				{contentEl}
				{content}
				<Pointer
					className="asteria-tooltip-pointer"
					path="tooltipPointer"
					style={{ transform: `translateX(${pointerOffset}px)` }}
					styler={styler}
					{...props}
				/>
			</ContentWrapper>
		);
	}
}

export default React.forwardRef((props, ref) => (
	<TooltipContent innerRef={ref} {...props} />
));
