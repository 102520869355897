import React, { useContext, useEffect, useState } from 'react';
import Authentication from '@asteria/component-authentication';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { makePath } from '@asteria/services-datalayer/react/router';
import { useHistory, useRoutes, useStore } from '@asteria/utils/hooks';
import { useFeature } from '@asteria/component-featureflag';

// Pages
import HomePage from './routes/home';
import ErpAddPage from './routes/erpAdd';
import LoadingPage from './routes/loading';
import OnboardingPage from './routes/onboarding';

// import ComponentsPage from './routes/components';

const Routes = [
	{ path: makePath('/loading'), View: LoadingPage },
	{ path: makePath('/login'), View: Authentication, auth: false },
	{ path: makePath('/home'), View: HomePage },
	{ path: makePath('/onboarding'), View: OnboardingPage },
	{ path: makePath('/onboarding/add/:type'), View: OnboardingPage },
	{ path: makePath('/onboarding/:modal'), View: OnboardingPage },
	{ path: makePath('/integrations/:type'), View: ErpAddPage },
	{ path: makePath('/integrations/:type/:action'), View: ErpAddPage },
	{ path: makePath('/:modal'), View: HomePage },
	{ path: makePath('/:modal/:id'), View: HomePage },
	{ path: makePath('/'), View: HomePage },
];

const Router = React.memo(props => {
	const [hasLoaded, setLoaded] = useState(false);
	const { dispatch } = useContext(DatalayerContext);
	const [authorized] = useStore(
		'store-auth',
		({ authorized: value }) => value,
	);
	const history = useHistory();
	const integrationsAddModal = useFeature({ key: 'integrations-add-modal' });
	useStore('store-language', store => store.current);
	let route = useRoutes(Routes);

	if (
		authorized &&
		(history.location.pathname === '/loading' || !hasLoaded)
	) {
		[route] = Routes;
	}

	useEffect(() => {
		if (authorized) {
			history.push('/loading');
			setLoaded(true);
		} else {
			setLoaded(false);
		}
	}, [authorized, dispatch, history]);

	let { View = HomePage } = route || {};
	const { auth = true } = route || {};

	if ((auth && !authorized) || (auth && !hasLoaded)) {
		return null;
	}

	if (integrationsAddModal && View === ErpAddPage) {
		View = HomePage;
	}

	return <View {...props} />;
});

Router.displayName = 'Router';

export default Router;
