import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Text from '@asteria/component-typography/text';
import Icon, { SVG } from '@asteria/component-icon';
import IconV2, { SVG as SVGV2 } from '@asteria/component-icon/v2';
import PropTypes from 'prop-types';

import { compileStyles } from '@asteria/utils';
import Tooltip from '@asteria/component-tooltip';

// Attach styles to button's root node
const Button = styled(
	({
		text,
		subtext,
		children,
		icon,
		iconPosition,
		groupPosition,
		size,
		type,
		buttonType = 'button',
		className,
		notification = false,
		disabled = false,
		iconOptions,
		...props
	}) => (
		// eslint-disable-next-line react/button-has-type
		<button
			type={buttonType}
			className={classNames(
				'asteria-button',
				{
					[`asteria-button-${size}`]: size,
					[`asteria-button-${type}`]: type,
					[`asteria-button-group-${groupPosition}`]: groupPosition,
					[`asteria-button-icon-${iconPosition}`]:
						(text || children) && icon,
					'asteria-button-icon': icon,
					'asteria-button-icon-only': !(text || children) && icon,
					'asteria-button-has-subtext': subtext,
				},
				className,
			)}
			disabled={disabled}
			{...props}
		>
			{notification && (
				<Tooltip hover isStatic title="" content={notification}>
					<Icon
						className="asteria-button-notification-icon"
						asset="info"
					/>
				</Tooltip>
			)}
			{!subtext && (text !== '' || children) ? (
				<Text size="body" name="button-text" path="text" editStyle>
					{text || children}
				</Text>
			) : null}
			{subtext !== '' ? (
				<div
					style={{
						flexDirection: 'row',
						textAlign: 'left',
						marginRight: 'auto',
					}}
				>
					{text !== '' || children ? (
						<Text
							size="body"
							name="button-text"
							path="text"
							editStyle
						>
							{text || children}
						</Text>
					) : null}
					<Text
						size="small"
						name="button-subtext"
						path="text"
						editStyle
					>
						{subtext}
					</Text>
				</div>
			) : null}

			{icon && !iconOptions?.new ? (
				<IconV2 asset={icon} path="icon" />
			) : null}
			{icon && iconOptions?.new ? (
				<Icon asset={icon} path="icon" {...iconOptions} />
			) : null}
		</button>
	),
)`
	cursor: pointer;
	align-items: center;
	justify-content: center;
	width: 100%;
	flex-shrink: 1;
	background-color: none;
	position: relative;
	display: flex;
	box-sizing: border-box;
	border: 0;

	${Icon} {
		max-height: 100%;
		${SVG} {
			width: auto;
		}

		&.asteria-button-notification-icon {
			position: absolute;
			top: 0px;
			left: 0px;
			height: 10px;
			width: 10px;
			transform: translateX(-50%) translateX(-50%);
		}
	}

	${IconV2} {
		max-height: 100%;
		${SVGV2} {
			width: auto;
		}

		&.asteria-button-notification-icon {
			position: absolute;
			top: 0px;
			left: 0px;
			height: 10px;
			width: 10px;
			transform: translateX(-50%) translateX(-50%);
		}
	}

	&.asteria-button-icon-before {
		flex-direction: row-reverse;
	}

	${({ theme }) => {
		const style = compileStyles(Button, theme);
		return style;
	}}
`;

Button.Styler = {
	typePrefix: 'asteria-button',
	base: `button`,
	children: [
		{
			component: Text,
			base: `text`,
		},
		{
			component: Icon,
			base: `icon`,
		},
		{
			component: IconV2,
			base: `icon`,
		},
	],
};

Button.propTypes = {
	text: PropTypes.string,
	subtext: PropTypes.string,
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	iconPosition: PropTypes.string,
	groupPosition: PropTypes.string,
	size: PropTypes.string,
	type: PropTypes.string,
	className: PropTypes.string,
	buttonType: PropTypes.string,
	disabled: PropTypes.bool,
};

Button.defaultProps = {
	text: '',
	icon: false,
	subtext: '',
	iconPosition: 'before',
	groupPosition: undefined,
	size: 'medium',
	type: 'default',
	className: '',
	buttonType: 'button',
	disabled: false,
};

Button.displayName = 'Button';

export default Button;
