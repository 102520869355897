// import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { FETCH_TRANSLATIONS } from './queries';

export const selectLanguage = languageCode => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SELECT_LANGUAGE',
		payload: languageCode,
	}));
};

export const setTranslations = (translations, code) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_TRANSLATIONS',
		payload: { translations, code },
	}));
};

export const fetchTranslations = ({ code, themeId, partnerId }) => ({
	lookup,
	dispatch,
}) => {
	const apiService = lookup('service-api');
	setTimeout(() => {
		apiService
			.query(
				FETCH_TRANSLATIONS,
				{ code, themeId, partnerId },
				{ reqAuth: true },
			)
			.pipe(map(({ data }) => data?.translation?.translations || {}))
			.subscribe(data => {
				dispatch(setTranslations(data, code));
			});
	}, 0);
};
