import { useEffect, useRef } from 'react';

export default function useIsMounted() {
	const isMountedReference = useRef(false);
	useEffect(() => {
		isMountedReference.current = true;
		return () => {
			isMountedReference.current = false;
		};
	}, []);
	return isMountedReference;
}
