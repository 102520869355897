import styled from 'styled-components';
import { Getter } from '@asteria/utils';
import { Title } from '@asteria/component-typography';

const Header = styled.li`
	background: rgb(251, 242, 234);
	border-bottom: 2px solid rgb(235, 231, 226);
	padding: 10px 12px;

	${Title} {
		font-family: Arial, Helvetica, sans-serif;
		font-size: 14px;
		color: var(--text-color);
	}
`;

const Row = styled.div``;

const Item = styled.li`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	${Row} {
		display: flex;
		padding: 9px 0 9px 12px;
		align-items: center;
	}

	border-bottom: 1px solid rgb(235, 231, 225);
`;

const RowActions = styled.div`
	display: flex;
	margin-left: auto;
	align-items: center;
`;

const List = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`;

List.Styler = {
	children: [
		{
			component: Row,
			base: [Getter('item')],
		},
	],
};

export default List;
export { Header, Row, RowActions, Item };
