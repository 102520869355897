import React from 'react';
import styled from 'styled-components';
import Title from '@asteria/component-typography/title';
import Text from '@asteria/component-typography/text';
import { CheckboxGroupV2 } from '@asteria/component-form';
import { formatNumber, capitalize, Getter } from '@asteria/utils';

const Info = styled.div``;
Info.Styler = {
	children: [
		{
			component: Title,
			base: [Getter('name')],
		},
		{
			component: Text,
			base: [Getter('number')],
		},
	],
};

const Item = styled(props => {
	const { data, active, toggleSelection, className } = props;
	return (
		<li className={className}>
			<CheckboxGroupV2
				items={['']}
				onChange={toggleSelection}
				selected={active ? [0] : []}
				style={{
					maxWidth: '30px',
				}}
				groupItemStyle={{
					paddingRight: 0,
				}}
				iconContainerStyle={{
					width: '20px',
					height: '20px',
					marginRight: '10px',
					marginBottom: '3px;',
					paddingLeft: '6px',
					paddingRight: '6px',
					paddingTop: '2px',
					paddingBottom: '2px',
				}}
				supressOwnState
			/>
			<Info>
				<Title
					size="title4"
					title={data?.name}
					style={{ fontWeight: '600' }}
				>
					{capitalize(data?.name)}
				</Title>
				<Text size="small" title={data?.identifiers?.number || ''}>
					{data?.identifiers?.number || ''}
				</Text>
			</Info>
			<Text size="body">
				{formatNumber(data?.sums?.original?.total, false, false, true, {
					defaultFractionPart: '.00',
					round: false,
				})}
			</Text>
		</li>
	);
})`
	display: flex;

	${Info} {
		flex-grow: 1;
	}
`;

Item.Styler = {
	children: [
		{
			component: CheckboxGroupV2,
			base: [Getter('checkbox')],
		},
		{
			component: Info,
			base: [Getter('info')],
		},
		{
			component: Text,
			base: [Getter('total')],
		},
	],
};

export default Item;
