const reducer = (state = {}, { action, payload }) => {
	switch (action) {
		case 'FETCH_THEME_SUCCESS':
			console.log(payload);
			return {
				...state,
				theme: payload,
			};
		default:
			return state;
	}
};

export default reducer;
