import React, { useState, useContext, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useStore, Getter } from '@asteria/utils';
// import { isPast } from 'date-fns';
// import { cloneDeep } from 'lodash/fp';
import { isAfter, format } from 'date-fns';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { TranslationService } from '@asteria/services-language';
import classNames from 'classnames';
import {
	Dropdown,
	InputV2,
	LabelV2,
	FormGroupV2,
} from '@asteria/component-form';
import DatePicker from '@asteria/component-form/datepicker';
import Alert from '@asteria/component-alert';
import Button from '@asteria/component-core/button';
import { FeatureFlag, useFeature } from '@asteria/component-featureflag';
import {
	addTransactions,
	removeTransaction,
	editTransactions,
	saveTransactions,
	removeTransactions,
} from '@asteria/services-datalayer/services/adjustable/actions';

import { Text, Title } from '@asteria/component-core/typography';
import SidePane from '@asteria/component-core/sidepane';
import InfoBox, { InfoBoxToggle } from '../infoBox';
import AddClientModal from './addClient';
import Repeatable from './repeatable';

const CATEGORIES = [
	{
		value: 'invoiceClient',
		label: 'list.adjustable.category.invoiceClient',
		tag: '$customer',
		category: '$invoices',
		type: 'DEPOSIT',
		inputText: 'Välj kund',
		icon: '$tag',
		color: '$customer',
		new: true,
	},
	{
		value: 'invoiceSupplier',
		label: 'list.adjustable.category.invoiceSupplier',
		tag: '$supplier',
		category: '$invoices',
		type: 'WITHDRAW',
		inputText: 'Välj leverantör',
		icon: '$tag',
		color: '$supplier',
		new: true,
	},
	{
		value: 'salary',
		label: 'list.adjustable.category.salary',
		tag: '$salary',
		type: 'WITHDRAW',
		category: '$salaries',
		inputText: 'Fyll i anställdas namn',
		icon: '$tag',
		color: '$salary',
		new: true,
	},
	/*
	{
		value: 'bankgiro',
		label: 'list.adjustable.category.bankgiro',
		tag: '$bankgiro',
		type: 'WITHDRAW',
		category: '$transaction',
		inputText: '',
	},
	{
		value: 'plusgiro',
		label: 'list.adjustable.category.plusgiro',
		tag: '$plusgiro',
		type: 'WITHDRAW',
		category: '$transaction',
		inputText: '',
	},
	{
		value: 'swish',
		label: 'list.adjustable.category.swish',
		tag: '$swish',
		type: 'WITHDRAW',
		category: '$transaction',
		inputText: '',
	},
	*/
	{
		value: 'tax',
		label: 'list.adjustable.category.tax',
		tag: '$tax',
		type: 'WITHDRAW',
		category: '$taxes',
		inputText: 'Skatt',
		icon: '$tag',
		color: '$tax',
		new: true,
	},
	{
		value: 'misc',
		label: 'list.adjustable.category.withdraw.misc',
		tag: '$misc',
		type: 'WITHDRAW',
		category: '$withdraw',
		inputText: 'Fyll i utgiftens beskrivning',
		icon: '$tag',
		color: '$misc',
		new: true,
	},
	{
		value: 'misc',
		label: 'list.adjustable.category.deposit.misc',
		tag: '$misc',
		type: 'DEPOSIT',
		category: '$deposit',
		inputText: 'Fyll i inkomstens beskrivning',
		icon: '$tag',
		color: '$misc',
		new: true,
	},
];

/*
SEK
NOK
DKK
EUR
USD
GBP
CHF
JPY
CNY
*/

const Currencies = [
	{ value: 'SEK', label: 'SEK', icon: '$currency', color: 'SEK', new: true },
	{ value: 'NOK', label: 'NOK', icon: '$currency', color: 'NOK', new: true },
	{ value: 'DKK', label: 'DKK', icon: '$currency', color: 'DKK', new: true },
	{ value: 'GBP', label: 'GBP', icon: '$currency', color: 'GBP', new: true },
	{ value: 'CHF', label: 'CHF', icon: '$currency', color: 'CHF', new: true },
	{ value: 'JPY', label: 'JPY', icon: '$currency', color: 'JPY', new: true },
	{ value: 'CNY', label: 'CNY', icon: '$currency', color: 'CNY', new: true },
	{ value: 'USD', label: 'USD', icon: '$currency', color: 'USD', new: true },
	{ value: 'EUR', label: 'EUR', icon: '$currency', color: 'EUR', new: true },
].sort((a, b) => a.label.localeCompare(b.label));

const tagsToCategory = (tags, type) =>
	CATEGORIES.find(c =>
		tags.find(({ name }) => c.tag === name && c.type === type),
	);

const categoryToTag = category => ({
	name: CATEGORIES.find(c => c === category)?.tag || category?.label,
	category: CATEGORIES.find(c => c === category)?.category,
});

const Transaction = styled(
	({
		className,
		transaction = {},
		update,
		remove,
		index = 0,
		listIndex = 0,
		onClick = () => {},
		accordion,
		showAccordion,
		editRepeatable,
		active,
	}) => {
		const [form, updateForm] = useState(transaction);
		const [validation] = useState([]);
		const useSidepan = useFeature({
			key: 'sidepane-transaction-adjustable-add',
		});
		const {
			// id: transId,
			type,
			paymentDate = new Date(),
			meta: { description = '', tags = [] } = {},
			sums: { original: { total = '', currency = 'SEK' } = {} } = {},
			links = [],
			manual = true,
		} = form;

		const displayCurrency = 'SEK';

		const category = tagsToCategory(tags, type);

		const [clients = []] = useStore(
			'store-appstate',
			state => state.clients,
		);

		const customers = useMemo(
			() => [
				{
					label: TranslationService.get([
						'list.adjustable.description.add',
						'list.adjustable.description.add.customer',
					]),
					value: '$new',
				},
				...clients
					.filter(({ type: cType }) => cType === 'CUSTOMER')
					.map(c => ({ label: c.name, value: c.id }))
					.sort(({ label: aName }, { label: bName }) =>
						aName?.localeCompare(bName),
					),
			],
			[clients],
		);

		const suppliers = useMemo(
			() => [
				{
					label: TranslationService.get([
						'list.adjustable.description.add',
						'list.adjustable.description.add.supplier',
					]),
					value: '$new',
				},
				...clients
					.filter(({ type: cType }) => cType === 'SUPPLIER')
					.map(c => ({ label: c.name, value: c.id }))
					.sort(({ label: aName }, { label: bName }) =>
						aName?.localeCompare(bName),
					),
			],
			[clients],
		);

		const selectedClient = useMemo(() => {
			let cLink = links?.find(({ type: cType }) => cType === 'CLIENT');

			if (!cLink) {
				const id =
					links?.find(
						({ type: cType }) => cType === 'INVOICE_PAYMENT',
					)?.invoiceDetails?.client?.id || null;

				if (!id) {
					return null;
				}

				cLink = { id };
			}

			return [...customers, ...suppliers].find(
				({ value }) => value === cLink.id,
			);
		}, [customers, suppliers, links]);

		const [newClient, setNewClient] = useState(false);

		return (
			<>
				{newClient ? (
					<AddClientModal
						onClose={({
							id: newClientId,
							name: newClientName,
						} = {}) => {
							if (newClientId) {
								updateForm({
									...form,
									meta: {
										...form.meta,
										...(form?.description
											? {
													description:
														form.description,
											  }
											: {}),
										description: newClientName,
									},
									links: [
										...links.filter(
											({ type: cType }) =>
												cType !== 'CLIENT',
										),
										{
											type: 'CLIENT',
											id: newClientId,
											clientId: newClientId,
										},
									],
								});
								update(index, {
									...form,
									meta: {
										...form.meta,
										...(form?.description
											? {
													description:
														form.description,
											  }
											: {}),
										description: newClientName,
									},
									links: [
										...links.filter(
											({ type: cType }) =>
												cType !== 'CLIENT',
										),
										{
											type: 'CLIENT',
											id: newClientId,
											clientId: newClientId,
										},
									],
								});
							}
							setNewClient(false);
						}}
						clientType={category?.tag
							?.replace('$', '')
							.toUpperCase()}
					/>
				) : null}
				{showAccordion ? (
					<Button
						type="link"
						className="asteria-adjustable-transaction-accordion-toggle asteria-button-toggle"
						onClick={() => onClick(active ? false : transaction)}
						icon={active ? 'up' : 'down'}
						iconPosition="after"
					>
						{TranslationService.get(
							'list.adjustable.accordion.title',
							`Händelse {{index}}`,
							{
								index: listIndex + 1,
							},
						)}
					</Button>
				) : null}
				<form
					className={classNames(className, {
						'asteria-adjustable-transaction-accordion': accordion,
					})}
				>
					<FormGroupV2 className="asteria-group-customer">
						<LabelV2>
							{TranslationService.get(
								'list.adjustable.category.label',
							)}
						</LabelV2>
						<Dropdown
							iconClosed={useSidepan ? 'triangleDown' : undefined}
							iconOpen={useSidepan ? 'triangleUp' : undefined}
							options={CATEGORIES}
							toggleOptions={
								!useSidepan
									? { iconPosition: 'before' }
									: undefined
							}
							placeholder={TranslationService.get(
								'list.adjustable.category.placeholder',
							)}
							itemIcon="check"
							showSelected
							disabled={!manual}
							selected={CATEGORIES.filter(c => c === category)}
							onChange={c => {
								let risk = 1;
								if (
									c &&
									currency !== displayCurrency &&
									(c.value === 'invoiceClient' ||
										c.value === 'invoiceSupplier')
								) {
									risk = 0.9;
								}

								updateForm({
									...form,
									type: c.type,
									meta: {
										...form.meta,
										tags: [categoryToTag(c)],
										description: '',
									},
									links: [
										...links.filter(
											({ type: cType }) =>
												cType !== 'CLIENT',
										),
									],
									oracle: {
										...(form?.oracle || {}),
										currency: { risk },
									},
								});
								update(index, {
									...form,
									type: c.type,
									meta: {
										...form.meta,
										tags: [categoryToTag(c)],
										description: '',
									},
									links: [
										...links.filter(
											({ type: cType }) =>
												cType !== 'CLIENT',
										),
									],
									oracle: {
										...(form?.oracle || {}),
										currency: { risk },
									},
								});
							}}
						/>
						{!manual ? (
							<Text size="small" style={{ marginTop: '4px' }}>
								{TranslationService.get(
									'list.adjustable.category.no_edit',
									'I dagsläget kan man bara ändra kategori på egenskapade händelser.',
								)}
							</Text>
						) : null}
					</FormGroupV2>
					{/* <FormGroupV2>
						<LabelV2>Betalningsdatum</LabelV2>
						{/* extend and multi prop is available on datepicker */}
					{/* <DatePicker
							allow={['day', 'month', 'year']}
							value={paymentDate}
							onChange={date => {
								updateForm({
									...form,
									paymentDate: date,
								});
								update(index, {
									...form,
									paymentDate: date,
									links: [
										...links.filter(
											({ type: cType }) =>
												cType !== 'CLIENT',
										),
									],
								});

								setNewClient(false);
							}}
							search={false}
							type="default"
						/>
					</FormGroupV2> */}
					<FormGroupV2 className="asteria-group-description">
						<LabelV2>
							{TranslationService.get(
								'list.adjustable.description.label',
							)}
						</LabelV2>
						{['$supplier', '$customer'].includes(category?.tag) &&
						newClient === false ? (
							<Dropdown
								iconClosed={
									useSidepan ? 'triangleDown' : undefined
								}
								iconOpen={useSidepan ? 'triangleUp' : undefined}
								placeholder={TranslationService.get([
									'list.adjustable.description.placeholder',
									`list.adjustable.description.placeholder.${category?.tag?.replace(
										'$',
										'',
									)}`,
									`list.adjustable.description.placeholder.${
										category?.type
									}.${category?.tag?.replace('$', '')}`,
								])}
								itemIcon="check"
								className="asteria-dropdown-clients"
								selected={
									selectedClient ? [selectedClient] : []
								}
								options={
									category.tag === '$supplier'
										? suppliers
										: customers
								}
								disabled={!manual}
								onChange={({ value }) => {
									if (value === '$new') {
										setNewClient(true);
									} else {
										updateForm({
											...form,
											meta: {
												...form.meta,
												...(form?.description
													? {
															description:
																form.description,
													  }
													: {}),
												description: value,
											},
											links: [
												...links.filter(
													({ type: cType }) =>
														cType !== 'CLIENT',
												),
												{
													type: 'CLIENT',
													id: value,
													clientId: value,
												},
											],
										});
										update(index, {
											...form,
											meta: {
												...form.meta,
												...(form?.description
													? {
															description:
																form.description,
													  }
													: {}),
												description: value,
											},
											links: [
												...links.filter(
													({ type: cType }) =>
														cType !== 'CLIENT',
												),
												{
													type: 'CLIENT',
													id: value,
													clientId: value,
												},
											],
										});
									}
								}}
								showSelected
							/>
						) : (
							<div
								style={{
									display: 'flex',
									position: 'relative',
								}}
							>
								<InputV2
									className="asteria-adjustable-description"
									elementType="input"
									style={{
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										paddingRight: '16px',
									}}
									placeholder={TranslationService.get([
										'list.adjustable.description.placeholder',
										`list.adjustable.description.placeholder.${category?.tag?.replace(
											'$',
											'',
										)}`,
									])}
									value={description}
									onChange={e => {
										updateForm({
											...form,
											meta: {
												...form.meta,
												...(form?.description
													? {
															description:
																form.description,
													  }
													: {}),
												description: e.target.value,
											},
										});
										update(index, {
											...form,
											meta: {
												...form.meta,
												...(form?.description
													? {
															description:
																form.description,
													  }
													: {}),
												description: e.target.value,
											},
										});
									}}
								/>
								{newClient ? (
									<Button
										type="link"
										icon="close"
										className="asteria-button-clear"
										onClick={() => {
											updateForm({
												...form,
												meta: {
													...form.meta,
													description: '',
												},
											});

											setNewClient(false);
										}}
										style={{
											position: 'absolute',
											right: '12px',
											top: '11px',
										}}
									/>
								) : null}
							</div>
						)}
					</FormGroupV2>

					<FormGroupV2 className="asteria-group-date">
						<LabelV2>
							{TranslationService.get(
								'list.adjustable.date.label',
							)}
						</LabelV2>
						<DatePicker
							value={paymentDate}
							min={new Date(format(new Date(), 'YYYY-MM-DD'))}
							onChange={date => {
								updateForm({
									...form,
									paymentDate: date,
								});
								update(index, {
									...form,
									paymentDate: date,
								});
							}}
						>
							{/* 							{editRepeatable ? (
								<Repeatable
									onChange={recurrentEvent => {
										let data = recurrentEvent;
										if (recurrentEvent.period === 'never') {
											data = undefined;
										}

										updateForm({
											...form,
											recurrentEvent: data,
										});
										update(index, {
											...form,
											recurrentEvent: data,
										});
									}}
								/>
							) : null} */}
						</DatePicker>
					</FormGroupV2>

					{editRepeatable ? (
						<Repeatable
							onChange={recurrentEvent => {
								let data = recurrentEvent;
								if (recurrentEvent.period === 'never') {
									data = undefined;
								}

								updateForm({
									...form,
									recurrentEvent: data,
								});
								update(index, {
									...form,
									recurrentEvent: data,
								});
							}}
						/>
					) : null}

					<FormGroupV2 className="asteria-group-amount">
						<LabelV2>
							{TranslationService.get(
								'list.adjustable.amount.label',
							)}
						</LabelV2>
						<FeatureFlag feature="currency-adjustable" invert>
							<InputV2
								elementType="input"
								type="number"
								placeholder="Fyll i belopp"
								value={Math.floor(total)}
								helper={currency}
								style={{
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									paddingRight: '46px',
								}}
								onChange={e => {
									updateForm({
										...form,
										sums: {
											...form?.sums,
											original: {
												...(form?.sums?.original || {}),
												currency,
												total: e.target.value
													? parseFloat(e.target.value)
													: 0,
											},
										},
									});

									update(index, {
										...form,
										sums: {
											...form?.sums,
											original: {
												...(form?.sums?.original || {}),
												currency,
												total: e.target.value
													? parseFloat(e.target.value)
													: 0,
											},
										},
									});
								}}
							/>
						</FeatureFlag>
						<FeatureFlag feature="currency-adjustable">
							<div className="asteria-group-horizontal">
								<InputV2
									elementType="input"
									type="number"
									placeholder="Fyll i belopp"
									value={Math.floor(total)}
									helper={currency}
									style={{
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										paddingRight: '46px',
									}}
									onChange={e => {
										updateForm({
											...form,
											sums: {
												...form?.sums,
												original: {
													...(form?.sums?.original ||
														{}),
													currency,
													total: e.target.value
														? parseFloat(
																e.target.value,
														  )
														: 0,
												},
											},
										});

										update(index, {
											...form,
											sums: {
												...form?.sums,
												original: {
													...(form?.sums?.original ||
														{}),
													currency,
													total: e.target.value
														? parseFloat(
																e.target.value,
														  )
														: 0,
												},
											},
										});
									}}
								/>
								<Dropdown
									options={Currencies}
									showSelected
									selected={Currencies.filter(
										({ value }) => value === currency,
									)}
									iconClosed={
										useSidepan ? 'triangleDown' : undefined
									}
									iconOpen={
										useSidepan ? 'triangleUp' : undefined
									}
									itemIcon={useSidepan ? 'check' : undefined}
									direction={['triangleDown', 'triangleUp']}
									className="asteria-button-dropdown-currencies"
									onChange={({ value }) => {
										let risk = 1;

										if (
											category &&
											value !== displayCurrency &&
											(category.value ===
												'invoiceClient' ||
												category.value ===
													'invoiceSupplier')
										) {
											risk = 0.9;
										}

										// oracle.currency.risk

										updateForm({
											...form,
											sums: {
												...form?.sums,
												original: {
													...(form?.sums?.original ||
														{}),
													currency: value,
												},
											},
											oracle: {
												...(form?.oracle || {}),
												currency: { risk },
											},
										});

										update(index, {
											...form,
											sums: {
												...form?.sums,
												original: {
													...(form?.sums?.original ||
														{}),
													currency: value,
												},
											},
											oracle: {
												...(form?.oracle || {}),
												currency: { risk },
											},
										});
									}}
								/>
							</div>
						</FeatureFlag>
					</FormGroupV2>

					{showAccordion ? (
						<Button
							type="link"
							icon="circleMinusFilled"
							text={TranslationService.get(
								'list.adjustable.removerow',
							)}
							className="asteria-button-remove"
							onClick={() => remove(transaction)}
						/>
					) : null}
				</form>
				{validation.length > 0
					? validation.map(({ code }) => (
							<Alert type="error" icon headingContent={code} />
					  ))
					: null}
			</>
		);
	},
)`
	display: flex;

	&.asteria-adjustable-transaction-accordion {
		max-height: 0px;
		overflow: hidden;
		transition: max-height 0.5s;

		&.asteria-state-active {
			max-height: 500px;
			overflow: visible;
			transition: max-height 0.5s;
		}
	}

	.asteria-dropdown-clients {
		.asteria-forms-dropdown-menu-direction-center
			.asteria-forms-dropdown-item {
			&:first-of-type {
				.asteria-text {
					text-decoration: underline;
					color: #257886;
					overflow: visible;
					&:before {
						content: '';
						height: 24px;
						width: 24px;
						background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0ZM13 5H11V11H5V13H11V19H13V13H19V11H13V5Z' fill='%2353A1AC'/%3E%3C/svg%3E%0A");
						position: relative;
						margin: 0px 8px -8px 0;
						display: inline-block;
					}
				}
			}
		}

		.asteria-button .asteria-text {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 160px;
			text-align: left;
			@media (max-width: 1024px) {
				width: 100%;
			}
		}
	}
	.asteria-button-clear {
		svg {
			fill: #9f8c82 !important;
		}
		&:hover {
			svg {
				fill: #5b3737 !important;
			}
		}
	}
	.asteria-group-customer {
		max-width: 220px;
		@media (max-width: 767.98px) {
			max-width: 100%;
		}
	}
`;

Transaction.displayName = 'AdjustableTransaction';
Transaction.Styler = {
	children: [
		{
			component: Button,
			base: [Getter('button')],
		},
		{
			component: LabelV2,
			base: [Getter('label')],
		},
		{
			component: InputV2,
			base: [Getter('input')],
		},
		{
			component: Dropdown,
			base: [Getter('dropdown')],
		},
		{
			compontent: DatePicker,
			base: [Getter('datepicker')],
		},
		{
			component: FormGroupV2,
			base: [Getter('group')],
		},
	],
};

const TransactionsWrapper = styled.div``;
TransactionsWrapper.Styler = {
	children: [
		{
			component: Transaction,
			base: [Getter('transaction')],
		},
	],
};

const ControllerWrapper = styled.div``;
ControllerWrapper.Styler = {
	children: [
		{
			component: Button,
			base: [Getter('button')],
		},
	],
};

const AdjustableHeader = styled.div``;

const Transactions = styled(({ className, date }) => {
	const [removing, setToRemove] = useState([]);
	const { dispatch } = useContext(DatalayerContext);
	const [active, setActive] = useState(null);
	const useSidepan = useFeature({
		key: 'sidepane-transaction-adjustable-add',
	});
	const [{ editing: originalEditing = [], adding = [] } = {}] = useStore(
		'store-adjustable',
		({ transactions }) => transactions,
	);

	useEffect(() => {
		setToRemove([]);
	}, [originalEditing.length]);

	const editing = useMemo(
		() => originalEditing.filter(t => !removing.includes(t)),
		[originalEditing, removing],
	);

	const updateAdding = (index, data) => {
		if (active && active.length > 0 && active[0] === adding[index]) {
			setActive([data]);
		}
		adding[index] = data;
		dispatch(addTransactions([...adding]));
	};

	const updateEditing = (index, data) => {
		if (active && active.length > 0 && active[0] === editing[index]) {
			setActive([data]);
		}
		editing[index] = data;
		dispatch(editTransactions([...editing]));
	};

	const [showInfo, showInfoBox] = useState(false);

	useEffect(() => {
		if (active === null && (adding.length > 0 || editing.length > 0)) {
			setActive(adding.length > 0 ? [adding[0]] : [editing[0]]);
		} else if (
			active !== null &&
			adding.length === 0 &&
			editing.length === 0
		) {
			setActive(null);
		}
	}, [active, adding, editing, originalEditing]);

	const el = (
		<div
			className={classNames(className, 'asteria-adjustable-add', {
				'asteria-adjustable-accordion': useSidepan,
			})}
		>
			<TransactionsWrapper className="asteria-adjustable-accordion-inner">
				<AdjustableHeader
					className={classNames(
						className,
						`asteria-adjustable-section-title`,
					)}
				>
					<Title size="title4">
						{TranslationService.get('list.adjustable.title')}
					</Title>
					<InfoBoxToggle
						className="asteria-button-info"
						open={showInfo}
						toggle={showInfoBox}
					/>
					<FeatureFlag feature="adjustable-add-header-options">
						<Button
							type="link"
							size="medium"
							text={TranslationService.get('button.abort')}
							onClick={() => {
								setToRemove([]);
								dispatch(editTransactions([]));
								dispatch(addTransactions([]));
							}}
						/>
						<Button
							size="medium"
							type="link"
							icon="checkFilled"
							className="asteria-button-save-adjustments"
							alt={TranslationService.get('button.save')}
							onClick={() => {
								dispatch(
									saveTransactions([...adding, ...editing]),
								);

								dispatch(removeTransactions(removing));

								setToRemove([]);
								dispatch(editTransactions([]));
								dispatch(addTransactions([]));
							}}
						/>
					</FeatureFlag>
				</AdjustableHeader>
				<InfoBox open={showInfo}>
					<Text>
						{TranslationService.get('list.adjustable.info')}
					</Text>
				</InfoBox>
				{editing.map((transaction, index) => (
					<Transaction
						key={transaction._id || transaction.id}
						transaction={transaction}
						index={index}
						listIndex={index}
						update={updateEditing}
						className={classNames({
							'asteria-state-active':
								active && active.includes(transaction),
						})}
						active={active && active.includes(transaction)}
						onClick={t => {
							if (t && !active.includes(t)) {
								setActive([t]);
							} else if (!t) {
								setActive([]);
							}
						}}
						accordion={useSidepan}
						showAccordion={editing.length + adding.length > 1}
						remove={() => {
							if (editing.includes(transaction)) {
								let next = null;

								if (editing.length > 1) {
									const list = editing.filter(
										a => a !== transaction,
									);

									next = [list[list.length - 1]];
								} else if (adding.length > 0) {
									next = [adding[adding.length - 1]];
								}

								setActive(next);
							}
							setToRemove([...removing, transaction]);
						}}
					/>
				))}
				{adding.map((transaction, index) => (
					<Transaction
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						transaction={transaction}
						index={index}
						listIndex={editing.length + index}
						update={updateAdding}
						editRepeatable
						remove={() => {
							if (active.includes(transaction)) {
								let next = null;

								if (adding.length > 1) {
									const list = adding.filter(
										a => a !== transaction,
									);

									next = [list[list.length - 1]];
								} else if (editing.length > 0) {
									next = [editing[editing.length - 1]];
								}

								setActive(next);
							}
							dispatch(removeTransaction(transaction));
						}}
						className={classNames({
							'asteria-state-active':
								active && active.includes(transaction),
						})}
						active={active && active.includes(transaction)}
						onClick={t => {
							if (t && !active.includes(t)) {
								setActive([t]);
							} else if (!t) {
								setActive([]);
							}
						}}
						accordion={useSidepan}
						showAccordion={editing.length + adding.length > 1}
					/>
				))}
				<FeatureFlag feature="adjustable-add-row">
					<Button
						type="link"
						icon="circlePlusFilled"
						size="medium"
						className="asteria-button-add-transaction-row"
						text={TranslationService.get('list.adjustable.addrow')}
						onClick={() => {
							const item = {
								type: 'DEPOSIT',
								status: 'FORECAST',
								manual: true,
								sums: { original: { total: 0 } },
								paymentDate: isAfter(new Date(), date)
									? new Date()
									: date,
								oracle: {
									risk: 1,
								},
								meta: {
									tags: [
										{
											name: '$customer',
											category: '$invoices',
										},
									],
								},
							};
							dispatch(addTransactions([...adding, item]));
							setActive([item]);
						}}
					/>
				</FeatureFlag>
			</TransactionsWrapper>
			{adding.length + editing.length > 0 && !useSidepan ? (
				<FeatureFlag feature="adjustable-add-header-options" invert>
					<ControllerWrapper className="asteria-adjustable-controllers">
						<Button
							type="default"
							size="medium"
							text={TranslationService.get('button.abort')}
							onClick={() => {
								setToRemove([]);
								dispatch(editTransactions([]));
								dispatch(addTransactions([]));
							}}
						/>
						{editing.length > 0 ? (
							<Button
								type="default"
								size="medium"
								text={TranslationService.get('button.remove')}
								onClick={() => {
									setToRemove([]);
									dispatch(editTransactions([]));
									dispatch(addTransactions([]));
								}}
							/>
						) : null}

						<FeatureFlag feature="adjustable-save-as" invert>
							<Button
								type="primary"
								size="medium"
								text={TranslationService.get('button.save')}
								onClick={() => {
									dispatch(
										saveTransactions([
											...adding,
											...editing,
										]),
									);

									dispatch(removeTransactions(removing));

									setToRemove([]);
									dispatch(editTransactions([]));
									dispatch(addTransactions([]));
								}}
							/>
						</FeatureFlag>

						<FeatureFlag feature="adjustable-save-as">
							<Button size="regular" type="primary" text="Next" />
							<Dropdown
								iconClosed="triangleDown"
								iconOpen="triangleUp"
								options={[
									{
										value: 'saveAsSceario',
										label: 'Spara som scenario',
									},
									{
										value: 'save',
										label: 'Spara',
									},
								]}
								placeholder="Spara"
								showSelected
								type="primary"
							/>
						</FeatureFlag>
					</ControllerWrapper>
				</FeatureFlag>
			) : null}
		</div>
	);

	const actions = [
		/*
		{
			title: TranslationService.get('button.abort'),
			type: 'default',
			action: () => {
				dispatch(editTransactions([]));
				dispatch(addTransactions([]));
			},
		},
		*/
	];

	if (editing.length > 0) {
		actions.push({
			title: TranslationService.get('button.remove'),
			type: 'default',
			action: () => {
				dispatch(removeTransactions(originalEditing));

				setToRemove([]);

				dispatch(editTransactions([]));
				dispatch(addTransactions([]));
			},
		});
	}

	actions.push({
		title: TranslationService.get('button.save'),
		type: 'primary',
		action: () => {
			dispatch(saveTransactions([...adding, ...editing]));

			dispatch(removeTransactions(removing));

			setToRemove([]);
			dispatch(editTransactions([]));
			dispatch(addTransactions([]));
		},
	});

	return (
		<>
			<FeatureFlag feature="sidepane-transaction-adjustable-add" invert>
				<FeatureFlag feature="inline-transaction-adjustable-add" invert>
					{editing.length === 0 && adding.length === 0 ? null : el}
				</FeatureFlag>
			</FeatureFlag>
			<FeatureFlag feature="sidepane-transaction-adjustable-add">
				<SidePane
					title={TranslationService.get('sidepanel.adjustable.title')}
					className="asteria-sidepane-manage-transaction"
					actions={actions}
					open={editing.length > 0 || adding.length > 0}
					onClose={() => {
						dispatch(editTransactions([]));
						dispatch(addTransactions([]));
					}}
				>
					{el}
				</SidePane>
			</FeatureFlag>
		</>
	);
})`
	display: flex;
	flex-direction: column;
	.asteria-adjustable-section-title {
		padding: 0 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-bottom: 8px !important;
		@media (max-width: 767.98px) {
			align-items: flex-start;
		}
		.asteria-title {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.asteria-button-info {
			margin-left: auto;
			@media (max-width: 767.98px) {
				display: block;
			}
			svg {
				fill: var(--primary-color) !important;
			}
		}
	}
	.asteria-adjustable-total {
		padding-right: 50px;
	}

	:not(.asteria-adjustable-accordion) {
		.asteria-adjustable-transaction-accordion-toggle {
			display: none;
		}
	}

	.asteria-adjustable-transaction-accordion-toggle {
		padding-left: 0 !important;
		padding-right: 0 !important;
		padding-bottom: 0 !important;
		.asteria-text {
			flex-grow: 1;
			text-align: left;
			font-family: Swedbank Headline !important;
			font-weight: 400 !important;
			color: var(--title-color) !important;
			font-size: 14px !important;
			text-decoration: none !important;
		}
		.asteria-icon-wrapper {
			width: 16px !important;
			svg {
				fill: #9f8c82 !important;
				width: auto;
			}
		}
	}
	.asteria-datepicker > div {
		&:last-child {
		}
	}
`;

Transactions.displayName = 'AdjustableTransactions';
Transactions.Styler = {
	children: [
		{
			component: TransactionsWrapper,
			base: [Getter('list')],
		},
		{
			component: ControllerWrapper,
			base: [Getter('controllers')],
		},
		{
			component: Button,
			base: [Getter('button')],
		},
		{
			component: InfoBox,
			base: [Getter('infobox')],
			children: [
				{
					component: Button,
					base: [Getter('button')],
				},
			],
		},
		{
			component: AdjustableHeader,
			base: [Getter('header')],
			children: [
				{
					component: Title,
					base: [Getter('title')],
				},
				{
					component: Text,
					base: [Getter('text')],
				},
			],
		},
	],
};

export default Transactions;
export { Transaction };
