import React from 'react';
import styled from 'styled-components';
import Title from '@asteria/component-typography/title';
import Text from '@asteria/component-typography/text';
import Alert from '@asteria/component-alert';
import { TranslationService } from '@asteria/services-language';
import { Getter, compileStyles } from '@asteria/utils';
import BankAccountItem from './bankAccountItem';

const BankAccountListGroupHeader = styled(Title)``;
BankAccountListGroupHeader.Styler = Title.Styler;

const BankAccountListGroup = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`;
BankAccountListGroup.Styler = {
	children: [
		{
			component: BankAccountItem,
			base: [Getter('item')],
		},
	],
};

/**
 * Shows list of bank account according to given currency
 * @param items
 * @param activeAccounts
 * @param toggleSelection
 * @param currency
 * @return {*}
 * @constructor
 */
const BankAccountList = styled(
	({ className, items, activeAccounts, toggleSelection, currency }) => (
		<div className={className}>
			<BankAccountListGroupHeader
				size="Small"
				className="asteria-title-section"
			>
				{TranslationService.get(
					'bankList.innerTitle',
					'Konton i svenska kronor',
					{
						currency,
					},
				)}
			</BankAccountListGroupHeader>
			<BankAccountListGroup className="asteria-list asteria-list-bank-accounts">
				{items.length > 0 ? (
					items
						.sort(
							(a, b) =>
								a?.name?.localeCompare(b?.name || '') || 0,
						) // Sort accounts by account name
						.map(accountItem => {
							const {
								identifiers: { number: id } = {},
							} = accountItem;
							return (
								<BankAccountItem
									key={id}
									active={activeAccounts.includes(
										accountItem,
									)}
									data={accountItem}
									toggleSelection={() =>
										toggleSelection(accountItem)
									}
								/>
							);
						})
				) : (
					<Alert
						type="warning"
						icon
						headingContent={TranslationService.get(
							'bankList.nothing_connected',
							'No connected integrations present...',
						)}
					/>
				)}
			</BankAccountListGroup>
		</div>
	),
)`
	${({ theme }) => compileStyles(BankAccountList, theme)}
`;

BankAccountList.Styler = {
	base: [Getter('accounts')],
	children: [
		{
			component: BankAccountListGroupHeader,
			base: [Getter('header')],
		},
		{
			component: BankAccountListGroup,
			base: [Getter('list')],
		},
		{
			component: Text,
			base: [Getter('text')],
		},
	],
};

export default BankAccountList;
