import { map } from 'rxjs/operators';
import { REPORT_ISSUE, ADD_CLIENT } from './queries';

export const setSite = (site, partnerId) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_SITE',
		payload: { site, partnerId },
	}));
};

export const setColorMode = colorMode => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_COLOR_MODE',
		payload: colorMode,
	}));
};

export const gotoRoute = route => ({ dispatch }) => {
	dispatch(() => ({
		action: 'GOTO_ROUTE',
		payload: route,
	}));
};

export const setHistory = history => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_HISTORY',
		payload: history,
	}));
};

export const setRoute = route => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_ROUTE',
		payload: route,
	}));
};

export const incrementSidepane = () => ({ dispatch }) => {
	dispatch(() => ({
		action: 'INC_SIDEPANE',
	}));
};

export const decrementSidepane = () => ({ dispatch }) => {
	dispatch(() => ({
		action: 'DEC_SIDEPANE',
	}));
};

export const setListOpen = (open, source) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_LIST_OPEN',
		payload: { open, source },
	}));
};

export const setTimesliceSize = size => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_TIMESLICE_SIZE',
		payload: size,
	}));
};

export const setCurrentDate = date => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_CURRENT_DATE',
		payload: date,
	}));
};

export const setSelectedDate = date => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_SELECTED_DATE',
		payload: date,
	}));
};

export const setSelectedType = type => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_SELECTED_TYPE',
		payload: type,
	}));
};

export const setViewCustomer = id => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_ACTIVE_CUSTOMER',
		payload: id,
	}));
};

export const setAdjustOpen = open => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_ADJUST_OPEN',
		payload: open,
	}));
};

export const setNumberOfOverdue = (total, deposit, withdraw) => ({
	dispatch,
}) => {
	dispatch(() => ({
		action: 'SET_NUMBER_OF_OVERDUE',
		payload: { total, deposit, withdraw },
	}));
};

export const setNumberOfOpen = (total, deposit, withdraw) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_NUMBER_OF_OPEN',
		payload: { total, deposit, withdraw },
	}));
};

export const sendSupport = ({ subject, email, message }) => ({ lookup }) => {
	const apiService = lookup('service-api');

	return apiService.query(
		REPORT_ISSUE,
		{ input: { subject, email, message } },
		{ reqAuth: true },
	);
};

export const addFilter = ({ id, type, target, config }) => ({ dispatch }) => {
	dispatch({
		action: 'ADD_FILTER',
		payload: { id, type, target, config },
	});
};

export const addFilters = filters => ({ dispatch }) => {
	dispatch({
		action: 'ADD_FILTERS',
		payload: filters,
	});
};

export const setFilters = filters => ({ dispatch }) => {
	dispatch({
		action: 'SET_FILTERS',
		payload: filters,
	});
};

export const removeFilter = id => ({ dispatch }) => {
	dispatch({
		action: 'REMOVE_FILTER',
		payload: id,
	});
};

export const toggleFilter = ({ id, type, target, config }) => ({
	dispatch,
}) => {
	dispatch({
		action: 'TOGGLE_FILTER',
		payload: { id, type, target, config },
	});
};

export const showModal = (modal, data) => ({ dispatch }) => {
	dispatch({
		action: 'SET_MODAL',
		payload: { modal, data },
	});
};

export const fetchClients = () => ({ dispatch }) => {
	// const apiService = lookup('service-api');

	dispatch({
		action: 'REQUEST_CLIENTS',
	});
};

export const setGraphSize = ({ width, height }) => ({ dispatch }) => {
	dispatch({
		action: 'SET_GRAPH_SIZE',
		payload: { width, height },
	});
};

export const addClient = ({ name, type }) => ({ lookup, dispatch }) => {
	const apiService = lookup('service-api');

	const request = apiService
		.query(ADD_CLIENT, { name, type }, { reqAuth: true })
		.pipe(
			map(resp => resp?.data?.addClient?.data),
			map(data => {
				dispatch(() => ({
					action: 'ADD_CLIENT',
					payload: data,
				}));
				return data;
			}),
		);

	return request;
};

export const fetchNumberOfOverdue = () => ({ dispatch }) => {
	dispatch(() => ({
		action: 'REQUEST_DATA_NUMBER_OF_OVERDUE',
		payload: {},
	}));
	/*
	const apiService = lookup('service-api');

	apiService
		.query(GET_NUMBER_OF_OVERDUE, {}, { reqAuth: true })
		.pipe(map(({ data }) => data?.transactions?.pageInfo || []))
		.subscribe(({ count = 0 }) => {
			dispatch(setNumberOfOverdue(count));
		});
	*/
};

export const fetchNumberOfOpen = () => ({ dispatch }) => {
	dispatch(() => ({
		action: 'REQUEST_DATA_NUMBER_OF_OPEN',
		payload: {},
	}));
	/*
	const apiService = lookup('service-api');

	apiService
		.query(GET_NUMBER_OF_OPEN, {}, { reqAuth: true })
		.pipe(map(({ data }) => data?.transactions?.pageInfo || []))
		.subscribe(({ count = 0 }) => {
			dispatch(setNumberOfOpen(count));
		});
	*/
};

export const setLayout = layout => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_LAYOUT',
		payload: layout,
	}));
};

export const addToFactoring = item => ({ dispatch }) => {
	dispatch(() => ({
		action: 'ADD_TO_FACTORING',
		payload: item,
	}));
};

export const setFactoring = item => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_FACTORING',
		payload: item,
	}));
};

export const sendFactoring = item => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SENT_FACTORING',
		payload: item,
	}));
};

export const showPrompt = prompt => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SHOW_PROMPT',
		payload: prompt,
	}));
};

export const hidePrompt = prompt => ({ dispatch }) => {
	dispatch(() => ({
		action: 'HIDE_PROMPT',
		payload: prompt,
	}));
};

export const setSearchFocus = focus => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_SEARCH_FOCUS',
		payload: focus,
	}));
};

export const setCredit = credit => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_CREDIT',
		payload: credit,
	}));
};

export const setMode = mode => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_MODE',
		payload: mode,
	}));
};

export const addWidgetClass = className => ({ dispatch }) => {
	dispatch(() => ({
		action: 'ADD_WIDGET_CLASS',
		payload: className,
	}));
};

export const removeWidgetClass = className => ({ dispatch }) => {
	dispatch(() => ({
		action: 'REMOVE_WIDGET_CLASS',
		payload: className,
	}));
};
