function getViewport() {
	let viewPortWidth;
	let viewPortHeight;

	// the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
	if (typeof window.innerWidth !== 'undefined') {
		viewPortWidth = window.innerWidth;
		viewPortHeight = window.innerHeight;
	}

	// IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
	else if (
		typeof document.documentElement !== 'undefined' &&
		typeof document.documentElement.clientWidth !== 'undefined' &&
		document.documentElement.clientWidth !== 0
	) {
		viewPortWidth = document.documentElement.clientWidth;
		viewPortHeight = document.documentElement.clientHeight;
	}

	// older versions of IE
	else {
		viewPortWidth = document.getElementsByTagName('body')[0].clientWidth;
		viewPortHeight = document.getElementsByTagName('body')[0].clientHeight;
	}
	return [viewPortWidth, viewPortHeight];
}

export default height => {
	if (!height) {
		return 'medium';
	}

	const [, viewPortHeight] = getViewport();
	const ratio = height / viewPortHeight;

	if (viewPortHeight < 700 && height < 220) {
		return 'small';
	}
	if (viewPortHeight >= 700 && (height < 300 || ratio < 0.2)) {
		return 'small';
	}

	if (viewPortHeight < 700 && height >= 330) {
		return 'large';
	}

	if (ratio > 0.7) {
		return 'large';
	}

	return 'medium';
};
