import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Text } from '@asteria/component-core/typography';
import { preProcess } from '@asteria/utils';
import { TagTooltip } from '@asteria/component-tags';

const processColor = (color = '', prefix = 'color', status = 'PAID', theme) => {
	if (status === 'UNPAID') {
		return preProcess(`var(--system-unpaid-${prefix})`, theme);
	}

	if (status === 'OVERDUE') {
		return preProcess(`var(--system-overdue-${prefix})`, theme);
	}

	if (color.startsWith('$')) {
		return preProcess(
			`var(--${color.replace(
				'$',
				'system-',
			)}-${prefix}-${status.toLowerCase()}, var(--${color.replace(
				'$',
				'system-',
			)}-${prefix}, var(--system-${status.toLowerCase()}-${prefix})))`,
			theme,
		);
	}

	return color;
};

const Badge = styled(({ className, badge }) => {
	const { count, status } = badge;

	return (
		<div
			className={classNames(
				className,
				'asteria-badge',
				`asteria-badge-${status}`,
			)}
		>
			<Text size="label">{count > 9 ? '+9' : count}</Text>
		</div>
	);
})`
	pointer-events: all;
`;

const BarPart = styled(({ className, status, tag }) => (
	<div
		className={classNames(
			className,
			'asteria-bar-part',
			`asteria-bar-part-${tag}`,
			`asteria-bar-part-${status}`,
		)}
	/>
))`
	min-width: 4px;
	width: ${({ size }) => `${size}%`};
	height: 100%;
	background-color: ${({ theme, status = 'PAID', tag }) =>
		processColor(tag, 'color', status, theme)};
	background-image: ${({ theme, status = 'PAID', tag }) =>
		processColor(tag, 'image', status, theme)};

	&.asteria-bar-part-PAID {
		order: 1;
	}

	&.asteria-bar-part-FORECAST {
		order: 4;
	}

	&.asteria-bar-part-UNPAID {
		order: 2;
	}

	&.asteria-bar-part-UNPAID {
		order: 3;
	}

	&.asteria-bar-part-OVERDUE {
		order: 5;
	}
`;

BarPart.displayName = 'BarPart';

BarPart.Styler = {
	children: [],
};

const Bar = styled(
	({
		className,
		tag,
		horizontal = false,
		data: { values = [], badge = false } = {},
		data = {},
		onClick,
		translationKey,
		hasTooltip = true,
	}) => {
		const ref = useRef(null);
		const [showTooltip, setShowTooltip] = useState(false);

		return (
			<div
				className={classNames(className, {
					'asteria-bar-horizontal': horizontal,
				})}
				ref={ref}
				onMouseEnter={() => setShowTooltip(true && hasTooltip)}
				onMouseLeave={() => setShowTooltip(false)}
				onClick={onClick}
				onKeyPress={onClick}
				role="button"
				tabIndex="-1"
			>
				{values
					.filter(
						({ status }) =>
							values.length === 1 || status !== 'OVERDUE',
					)
					.map(({ size, status }) => (
						<BarPart size={size} tag={tag} status={status} />
					))}
				{badge ? <Badge badge={badge} /> : null}
				{showTooltip ? (
					<TagTooltip
						target={ref}
						tag={tag}
						data={data}
						translationKey={translationKey}
					/>
				) : null}
			</div>
		);
	},
)`
	display: flex;
	flex-direction: column;
	background-color: #eae7e3;
	position: relative;
	${Badge} {
		position: absolute;
		top: -20px;
		right: 0px;
		height: 20px;
		width: 20px;
		background-color: rgb(168, 47, 44);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		display: none;

		${Text} {
			&.asteria-text-label {
				color: #ffffff;
				font-size: 12px;
				font-family: Swedbank Headline;
				font-weight: 200;
				text-align: center;
			}
		}
	}
	&.asteria-bar-horizontal {
		flex-direction: row;
		cursor: pointer;
		.asteria-bar-part {
			animation: loadBar 1.5s ease-in-out;
		}
	}
	@keyframes loadBar {
		0% {
			max-width: 0%;
		}
		60% {
			max-width: 100%;
		}
		100% {
			max-width: 100%;
		}
	}
`;

Bar.displayName = 'Bar';

Bar.Styler = {
	children: [],
};

export default Bar;
