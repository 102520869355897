/* eslint-disable no-restricted-globals */
import React, {
	useContext,
	useRef,
	useState,
	useEffect,
	useCallback,
} from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { Getter, useComponentSize, useStore } from '@asteria/utils';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import getStoreValue from '@asteria/services-datalayer/utils/get';
import { saveUser } from '@asteria/services-datalayer/services/auth/actions';

import GraphContext from '../../context';

import Line from './line';
import LineDot from './dot';
import Background from '../background';

const SvgWrapper = styled.svg``;
SvgWrapper.displayName = 'SvgWrapper';

const LineGroup = styled.div`
	/* pointer-events: none; */
	position: relative;
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 100%;
	-ms-grid-rows: 100%;

	${SvgWrapper} {
		z-index: 2;
	}

	${LineDot} {
		z-index: 2;
	}
`;
LineGroup.displayName = 'LineGroup';

const Spread = styled.svg`
	height: 100%;
	width: 100%;

	-ms-grid-column: 1;
	-ms-grid-row: 1;

	grid-column-start: 1;
	grid-column-end: -1;
	grid-row-start: 1;
	grid-row-end: -1;
	z-index: 1;
	position: relative;
	transform: translateX(-50%);

	&.asteria-graph-line-below-zero {
		.asteria-fill-color1 {
			fill: rgba(254, 227, 225, 1);
		}
	}
`;

LineGroup.Styler = {
	typePrefix: 'asteria-graph-line-group',
	children: [
		{
			typePrefix: 'asteria-graph-line',
			component: Line,
			base: [Getter('line')],
		},
		{
			component: LineDot,
			base: [Getter('lineinfo')],
		},
		{
			component: Background,
			base: [Getter('background')],
		},
		{
			component: SvgWrapper,
			base: [Getter('wrapper')],
		},
		{
			component: Spread,
			base: [Getter('wrapper')],
		},
	],
};

const LineColumn = ({
	className,
	id,
	layout,
	group,
	prevGroup,
	minValue,
	maxValue,
	width,
	margin,
	types,
	activeGroups,
	hoverGroups,
	first = false,
	last = false,
	onClick,
	center = 100,
	credit,
	mode,
	setTarget,
	hasClickedCredit,
	setHasClickedCredit,
}) => {
	const ref = useRef(null);
	const { height } = useComponentSize(ref);
	const { lines: [{ max = 0, min = 0, value = 0 } = {}] = [] } = group;

	const {
		lines: [{ max: prevMax = 0, min: prevMin = 0 } = {}] = [],
	} = prevGroup;

	const yMax = center - ((max - minValue) / (maxValue - minValue)) * center;
	const yPrevMax =
		center - ((prevMax - minValue) / (maxValue - minValue)) * center;

	const yMin = center - ((min - minValue) / (maxValue - minValue)) * center;
	const yPrevMin =
		center - ((prevMin - minValue) / (maxValue - minValue)) * center;

	return (
		<LineGroup
			key={`linegroup_${id}`}
			ref={ref}
			style={{
				width,
				...margin,
			}}
			className={classNames(
				className,
				'asteria-graph-line-group',
				types.map(t => `asteria-graph-line-group-${t}`),
				{
					'asteria-state-active': activeGroups.includes(id),
					'asteria-state-hover': hoverGroups.includes(id),
				},
			)}
		>
			<Background />
			<Spread
				viewBox="0 0 100 100"
				preserveAspectRatio="none"
				className={classNames({
					'asteria-graph-line-below-zero': value < 0,
				})}
			>
				<defs>
					<pattern
						id={`stripe-fill-forecast-${id}`}
						width="5"
						height="5"
						patternUnits="userSpaceOnUse"
						patternTransform="rotate(45)"
						fillOpacity="1"
					>
						<rect
							width="8"
							height="8"
							transform="translate(0,0)"
							fill="#000000"
							fillOpacity="0.1"
							className="asteria-fill-color1"
						/>
						<rect
							width="1"
							height="8"
							transform="translate(3.5,0)"
							fill="#000000"
							fillOpacity="0.15"
							className="asteria-fill-color2"
						/>
					</pattern>
				</defs>
				<polygon
					points={`0,${yPrevMax} 100,${yMax} 100,${yMin} 0,${yPrevMin}`}
					style={{
						fill: `url(${location.protocol}//${location.host}${
							location.pathname
						}#stripe-fill-forecast-${id})`,
					}}
				/>
			</Spread>
			<SvgWrapper
				height="100%"
				width="100%"
				style={{
					maxHeight: '100%',
					maxWidth: '100%',
					transform: 'translateX(-50%)',
					msGridColumn: 1,
					msGridColumnSpan: -1,
					msGridRow: 1,
					msGridRowSpan: -1,
					gridColumnStart: 1,
					gridColumnEnd: -1,
					gridRowStart: 1,
					gridRowEnd: -1,
					position: 'relative',
					overflow: first ? 'visible' : 'visible',
				}}
			>
				<Line
					key={`line-${id}`}
					height={height}
					group={group}
					prevGroup={prevGroup}
					maxValue={maxValue}
					minValue={minValue}
					margin={margin}
					onClick={onClick}
					layout={layout}
					first={first}
					last={last}
					center={center}
					setTarget={setTarget}
					credit={credit}
					mode={mode}
					hasClickedCredit={hasClickedCredit}
					setHasClickedCredit={setHasClickedCredit}
				/>
			</SvgWrapper>
		</LineGroup>
	);
};

const LineGraph = React.memo(
	({
		layout,
		className,
		width = 0,
		range = [],
		groups = {},
		maxValue = 0,
		minValue = 0,
		activeGroups = [],
		hoverGroups = [],
		margin,
		steps = [],
		onClick = () => {},
	}) => {
		const { dispatch, lookup } = useContext(DatalayerContext);
		const [credit] = useStore('store-appstate', ({ credit: val }) => val);
		const [hasClickedCredit, setHasClickedCredit] = useState(false);
		const [mode] = useStore('store-appstate', ({ mode: val }) => val);
		const [onboardingStep = { id: false, type: false }] = useStore(
			'store-adjustable',
			({ currentOnboardingStep }) => currentOnboardingStep,
		);

		useEffect(() => {
			const user = getStoreValue(
				lookup('store-auth'),
				({ user: obj }) => obj,
			);

			if (user?.settings?.flags?.hasClickedCredit) {
				setHasClickedCredit(true);
			}
		}, [lookup]);

		const setClickedCredit = useCallback(() => {
			const user = getStoreValue(
				lookup('store-auth'),
				({ user: obj }) => obj,
			);

			if (!hasClickedCredit) {
				setHasClickedCredit(true);
				dispatch(
					saveUser({
						...user,
						settings: {
							...(user?.settings || {}),
							flags: {
								...(user?.settings?.flags || {}),
								hasClickedCredit: true,
							},
						},
					}),
				);
			}
		}, [dispatch, hasClickedCredit, lookup]);

		const { mouseEnterAction, mouseLeaveAction, setTarget } = useContext(
			GraphContext,
		);
		let center = 100;

		if (layout === 'stacked') {
			const size = steps.length - 1;
			const zeroIndex = steps.findIndex(({ value: v }) => v === 0);
			// const topZone = (size - zeroIndex) * (100 / size);
			const bottomZone = (size - (size - zeroIndex)) * (100 / size);

			center = bottomZone;
		}

		return (
			<>
				{range.map(({ id, types = [] }, index) => {
					if (index === 0) {
						return null;
					}

					return (
						<LineColumn
							id={id}
							key={id}
							className={className}
							group={groups[id] || {}}
							layout={layout}
							width={width}
							types={types}
							margin={margin}
							maxValue={maxValue}
							minValue={minValue}
							activeGroups={activeGroups}
							hoverGroups={hoverGroups}
							first={index === 1}
							last={index === range.length - 1}
							onClick={onClick}
							center={center}
							setTarget={setTarget}
							credit={credit}
							mode={mode}
							prevGroup={
								groups[(range[index - 1] || {}).id] || {}
							}
							hasClickedCredit={hasClickedCredit}
							setHasClickedCredit={setClickedCredit}
						/>
					);
				})}
				{range.slice(1, -1).map(({ id, types = [] }) => {
					const group = groups[id] || {};
					const mouseEnter = () => mouseEnterAction({ group });
					const mouseLeave = () => mouseLeaveAction({ group });
					return (
						<LineGroup
							key={`linegroup_dot_${id}`}
							className={classNames(
								className,
								'asteria-graph-line-group',
								'asteria-graph-line-group-dot',
								types.map(t => `asteria-graph-line-group-${t}`),
								{
									'asteria-state-active': activeGroups.includes(
										id,
									),
									'asteria-state-hover': hoverGroups.includes(
										id,
									),
									'asteria-graph-line-group-becon':
										id === onboardingStep.id,
								},
							)}
							style={{
								pointerEvents: 'none',
								position: 'relative',
								transform: 'translateX(-50%)',
								width,
								zIndex: 2,
								...margin,
							}}
						>
							<div
								style={{
									// pointerEvents: 'auto',
									pointerEvents: 'none',
									height: '100%',
									width: '100%',
									transform: 'translateX(50%)',
								}}
								onMouseEnter={mouseEnter}
								onMouseLeave={mouseLeave}
							/>
							<LineDot
								key={`dot-${id}`}
								group={groups[id]}
								maxValue={maxValue}
								minValue={minValue}
								layout={layout}
								center={center}
								becon={id === onboardingStep.id}
							/>
						</LineGroup>
					);
				})}
			</>
		);
	},
);

LineGraph.displayName = 'LineGraph';

export default LineGraph;
export { LineGroup };
