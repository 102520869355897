import React from 'react';
import styled from 'styled-components';
import Modal from '@asteria/component-modal';
import { TranslationService } from '@asteria/services-language';
import { useLocation, useHistory } from '@asteria/utils/hooks';

import Account from './account';

const SupportModal = styled(({ className, basePath }) => {
	const { pathname } = useLocation();
	const history = useHistory();

	return (
		<Modal
			open={pathname === `${basePath}/account`}
			onClose={() => history.push(basePath)}
			className={className}
			title={TranslationService.get('account.title')}
		>
			<Account onClose={() => history.push(basePath)} />
		</Modal>
	);
})``;

SupportModal.Styler = Modal.Styler;

export default SupportModal;
