import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import Insight from '@asteria/component-insight';
import { TranslationService } from '@asteria/services-language';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { useStore } from '@asteria/utils';
import {
	setListOpen,
	setFilters,
	setSelectedType,
} from '@asteria/services-datalayer/services/appstate/actions';
import { useHistory } from '@asteria/utils/hooks';

// import { Getter } from '@asteria/utils';

const Insights = styled(({ className }) => {
	const { dispatch } = useContext(DatalayerContext);
	const history = useHistory();
	const [open = 0] = useStore(
		'store-appstate',
		({ openInvoices: { total = 0 } = {} }) => total,
	);
	const [overdue = 0] = useStore(
		'store-appstate',
		({ overdueInvoices: { total = 0 } = {} }) => total,
	);

	const showOpen = useCallback(() => {
		dispatch(setListOpen(true, 'insight-unpaid'));

		dispatch(setSelectedType(['DEPOSIT', 'WITHDRAW']));

		dispatch(
			setFilters([
				{
					id: 'status-UNPAID',
					type: 'status',
					config: {
						name: '$unpaid',
						status: 'UNPAID',
					},
				},
			]),
		);
	}, [dispatch]);

	const showOverdue = useCallback(() => {
		dispatch(setListOpen(true, 'insight-overdue'));
		dispatch(setSelectedType(['WITHDRAW', 'DEPOSIT']));

		dispatch(
			setFilters([
				{
					id: 'status-OVERDUE',
					type: 'status',
					config: {
						name: '$overdue',
						status: 'OVERDUE',
					},
				},
			]),
		);
	}, [dispatch]);

	const showGuide = useCallback(() => history.push('/guide'), [history]);

	return (
		<div className={classNames(className, 'asteria-insights')}>
			{open > 0 ? (
				<Insight
					header={TranslationService.get(
						'insights.open.header',
						'insights.open.header',
						{
							count: open,
						},
					)}
					content={TranslationService.get(
						'insights.open.content',
						'insights.open.content',
					)}
					buttonText={TranslationService.get(
						'insights.open.button',
						'insights.open.button',
					)}
					className="asteria-open"
					onClick={showOpen}
				/>
			) : null}
			{overdue > 0 ? (
				<Insight
					header={TranslationService.get(
						'insights.overdue.header',
						'insights.overdue.header',
						{
							count: overdue,
						},
					)}
					content={TranslationService.get(
						'insights.overdue.content',
						'insights.overdue.content',
					)}
					buttonText={TranslationService.get(
						'insights.overdue.button',
						'insights.overdue.button',
					)}
					className="asteria-overdue"
					onClick={showOverdue}
				/>
			) : null}
			<Insight
				header={TranslationService.get(
					'insights.guide.header',
					'insights.guide.header',
				)}
				content={TranslationService.get(
					'insights.guide.content',
					'insights.guide.content',
				)}
				buttonText={TranslationService.get(
					'insights.guide.button',
					'insights.guide.button',
				)}
				className="asteria-guide"
				onClick={showGuide}
			/>
		</div>
	);
})`
	display: flex;
	width: 100%;
`;

Insights.displayName = 'Insights';

Insights.Styler = {
	children: [],
};
export default Insights;
