import { useStore } from '@asteria/utils';
import Service from './service';

const useFeature = ({ key, minVersion = false, maxVersion = false }) => {
	const [feature] = useStore('store-features', store => store?.active?.[key]);

	if (!Service.detected[key]) {
		Service.detected[key] = {
			key,
		};
	}

	if (Service.hasFeature(feature, { minVersion, maxVersion })) {
		return true;
	}

	return false;
};

export default useFeature;
