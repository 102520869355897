import gql from 'graphql-tag';

export const GET_NOTIFICATIONS = gql`
	query GetNotifications {
		notifications(filters: { options: { viewed: false } }) {
			edges {
				node {
					id
					type
					data
					title
					notificationKey
					options {
						sticky
						badge
						viewed
					}
					createdAt
				}
			}
		}
	}
`;

export const MARK_AS_VIEWED = gql`
	mutation MarkAsViewed($id: ObjectId!) {
		updateNotificationSetViewed(input: { _id: $id, value: true }) {
			ok
		}
	}
`;

export const ANSWER_NOTIFICATION = gql`
	mutation Answer($id: ObjectId!, $answer: String!) {
		updateNotificationSetAnswer(input: { _id: $id, value: $answer }) {
			ok
		}
	}
`;

export const SUBSCRIBE_NOTIFICATION_CREATED = gql`
	subscription NotificationCreate {
		notificationCreated {
			id
		}
	}
`;

export const SUBSCRIBE_NOTIFICATION_UPDATED = gql`
	subscription NotificationUpdated {
		notificationUpdated {
			id
		}
	}
`;

export const SUBSCRIBE_NOTIFICATION_DELETED = gql`
	subscription NotificationDeleted {
		notificationDeleted {
			id
		}
	}
`;
