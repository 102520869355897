/* eslint-disable no-unused-vars */
import {
	isFuture,
	addMonths,
	startOfMonth,
	format,
	differenceInDays,
	addDays,
	addWeeks,
	addYears,
	startOfYear,
	startOfISOWeek,
} from 'date-fns';

import { filter } from 'rxjs/operators';
import { setListItems, setListStatistics } from '../../services/list/actions';
import {
	setNumberOfOverdue,
	setNumberOfOpen,
} from '../../services/appstate/actions';
import { setBankAccounts } from '../../services/bankaccounts/actions';

import {
	setBarGroup,
	updateGraph,
	setAvailableCategories,
	updateGraphGroup,
} from '../../services/graph/actions';

import ListDemoDate, { Statistics as ListStatisticsDemoData } from './list';
import BankAccountsDemoData from './bankaccounts';
import GraphDemoData from './graph';
import CategoriesDemoData from './categories';
import IntegrationsDemoData from './integrations';

const addTimeslice = (date, size, count = 1) => {
	if (size === 'day') {
		return addDays(date, count);
	}
	if (size === 'week') {
		return addWeeks(date, count);
	}
	if (size === 'month') {
		return addMonths(date, count);
	}
	if (size === 'year') {
		return addYears(date, count);
	}

	return date;
};

const startOfTime = (date, size) => {
	if (size === 'week') {
		return startOfISOWeek(date);
	}

	if (size === 'month') {
		return startOfMonth(date);
	}

	if (size === 'year') {
		return startOfYear(date);
	}

	return startOfMonth(date);
};

const generatePart = (type, tagType, tag, status, total, asBadge) => {
	let groupId = `${type}`;

	if (tag) {
		groupId = `${type}.${tag.replace('$', '')}`;
	}

	let rowId = `${type}`;

	if (tag) {
		rowId = tag.replace('$', '');
	}

	const typeInfo = {
		id: groupId,
		type: 'tooltip.group',
		data: {
			items: [
				{
					id: `${rowId}.${status}`,
					type: 'tooltip.row',
					data: {
						variants: [status],
						type: 'tag',
						value: {
							original: { total, currency: 'SEK' },
							system: { total, currency: 'SEK' },
							display: { total, currency: 'SEK' },
						},
						currency: 'EUR',
						items: [],
					},
				},
			],
		},
	};

	const info = [typeInfo];

	if (asBadge || status === 'OVERDUE') {
		return {
			badge: false,
			value: total,
			status,
			types: [
				type.toLowerCase(),
				tagType.replace('$', ''),
				status.toLowerCase(),
			],
			parts: [],
			chip: {
				id: `${tagType.toLowerCase()}-${tag.toLowerCase()}`,
				type: tagType.toLowerCase(),
				config: {
					name: `$${tag.toLowerCase()}`,
					status,
					subtags: [],
					type,
				},
			},
			count: Math.ceil(Math.random() * 10),
			data: {
				type: type.toLowerCase(),
				status,
			},
			info,
		};
	}

	return {
		value: total,
		types: [type.toLowerCase(), tag.toLowerCase(), status.toLowerCase()],
		parts: [],
		status,
		chip: {
			id: `${tagType.toLowerCase()}-${tag.toLowerCase()}`,
			type: tagType.toLowerCase(),
			config: {
				name: `$${tag.toLowerCase()}`,
				status,
				subtags: [],
				type,
			},
		},
		data: {
			type: type.toLowerCase(),
			status,
			probability: 1,
			max: status === 'FORECAST' ? total * 1.1 : total,
			min: status === 'FORECAST' ? total * 0.9 : total,
		},
		info,
	};
};

const buildCashflowCategories = (type, categories, total, isFutureCashflow) => {
	const parts = [];

	let rest = total;
	const smallest = total * 0.1;
	for (let i = 0; i < categories.length; i += 1) {
		const tag = categories[i];
		const partTotal =
			smallest +
			Math.floor(
				Math.random() * (rest - (categories.length - i) * smallest),
			);

		if ((tag === 'customer' || tag === 'supplier') && Math.random() > 0.5) {
			const splittotal = i === categories.length - 1 ? rest : partTotal;
			parts.push(
				generatePart(
					type,
					'tag',
					tag,
					isFutureCashflow ? 'UNPAID' : 'OVERDUE',
					splittotal / 4,
				),
			);

			parts.push(
				generatePart(
					type,
					'tag',
					tag,
					isFutureCashflow ? 'FORECAST' : 'PAID',
					(splittotal / 4) * 3,
				),
			);
		} else {
			parts.push(
				generatePart(
					type,
					'tag',
					tag,
					isFutureCashflow ? 'FORECAST' : 'PAID',
					i === categories.length - 1 ? rest : partTotal,
				),
			);
		}

		rest -= partTotal;
	}

	return parts;
};

const buildCashflow = (size, depositTags = [], withdrawTags = []) => {
	const slices = [];
	let date = addTimeslice(new Date(), size, -20);
	let accountBalance = 0;
	for (let i = 0; i < 24; i += 1) {
		let type = 'DEPOSIT';
		let total = Math.ceil(Math.random() * 10000);
		const isFutureCashflow = i > 20;
		const slice = {
			date: `${format(
				startOfTime(date, size),
				'YYYY-MM-DD',
			)}T00:00:00.000Z`,
			id: `${format(
				startOfTime(date, size),
				'YYYY-MM-DD',
			)}T00:00:00.000Z`,
			bars: [],
			lines: [],
		};

		slice.bars.push({
			type: type.toUpperCase(),
			value: total,
			types: [type.toLowerCase()],
			parts: buildCashflowCategories(
				type,
				depositTags,
				total,
				isFutureCashflow,
			),
			data: {
				type: type.toLowerCase(),
			},
		});

		accountBalance += total;

		type = 'WITHDRAW';
		total = Math.ceil(Math.random() * 10000);
		slice.bars.push({
			type: type.toUpperCase(),
			value: total,
			types: [type.toLowerCase()],
			parts: buildCashflowCategories(
				type,
				withdrawTags,
				total,
				isFutureCashflow,
			),
			data: {
				type: type.toLowerCase(),
			},
		});

		accountBalance -= total;

		const linesInfo = [];

		const accountInfo = {
			id: 'account',
			type: 'tooltip.group',
			data: {
				type: 'subtext',
				value: {},
				items: [],
			},
		};

		linesInfo.push(accountInfo);

		accountInfo.data.items.push({
			id: isFutureCashflow ? `account.forecast` : `account.paid.current`,
			type: 'tooltip.row',
			data: {
				type: 'tag',
				value: {
					system: { total: accountBalance, currency: 'EUR' },
					original: { total: accountBalance, currency: 'SEK' },
					display: { total: accountBalance, currency: 'EUR' },
				},
			},
		});

		slice.lines.push({
			type: type.toUpperCase(),
			value: accountBalance,
			types: ['account', isFutureCashflow ? 'forecast' : 'history'],
			parts: [],
			max: isFutureCashflow ? accountBalance * 1.1 : accountBalance,
			min: isFutureCashflow ? accountBalance * 0.9 : accountBalance,
			info: linesInfo,
		});

		accountInfo.data.items.push({
			id: `account.credit`,
			type: 'tooltip.row',
			data: {
				type: 'tag',
				value: {
					system: { total: -10000, currency: 'EUR' },
					original: { total: -10000, currency: 'SEK' },
					display: { total: -10000, currency: 'SEK' },
				},
			},
		});

		slice.lines.push({
			value: -10000,
			max: -10000,
			min: -10000,
			type: 'CREDIT',
			types: [
				'credit',
				'sharp',
				isFutureCashflow ? 'forecast' : 'history',
			],
			parts: [],
		});

		if (i === 20) {
			slice.lines.push({
				type: type.toUpperCase(),
				value: accountBalance,
				types: ['account', 'forecast'],
				parts: [],
				max: isFutureCashflow ? accountBalance * 1.1 : accountBalance,
				min: isFutureCashflow ? accountBalance * 0.9 : accountBalance,
			});
		}

		slices.push(slice);
		date = addTimeslice(date, size, 1);
	}

	return { slices, accountBalance: slices[slices.length - 1].lines };
};

let CASHFLOW = false;

let CurrentGraph = false;

const getCashFlow = (payload, dispatch) => {
	const {
		graphId,
		startDate,
		endDate,
		tags = [],
		status: filterStatus = [],
	} = payload;

	const diff = differenceInDays(startDate, endDate);
	let size = 'month';
	if (Math.abs(diff) < 14) {
		size = 'week';
	} else if (Math.abs(diff) > 40) {
		size = 'year';
	}

	if (!CASHFLOW || CASHFLOW.size !== size) {
		CASHFLOW = buildCashflow(
			size,
			['customer', 'bankgiro', 'plusgiro'],
			['supplier', 'salary', 'tax'],
		);

		CASHFLOW.size = size;
	}

	const part = CASHFLOW.slices.find(({ id }) => id === startDate);
	if (part) {
		dispatch(
			updateGraphGroup(graphId, {
				...part,
				bars: part.bars.map(bar => {
					const barTags = bar.parts.filter(
						({
							chip: {
								type,
								config: { name },
							},
						}) => type === 'tag',
					);

					let parts = [];
					if (tags && tags.length > 0) {
						parts = barTags.filter(
							({
								chip: {
									type,
									config: { name },
								},
							}) => type === 'tag' && tags.includes(name),
						);
					} else {
						parts.push(
							generatePart(
								bar.type,
								'type',
								bar.type,
								'PAID',
								barTags
									.filter(({ status }) => status === 'PAID')
									.reduce((acc, { value }) => acc + value, 0),
							),
						);

						parts.push(
							generatePart(
								bar.type,
								'type',
								bar.type,
								'UNPAID',
								barTags
									.filter(({ status }) => status === 'UNPAID')
									.reduce((acc, { value }) => acc + value, 0),
							),
						);

						parts.push(
							generatePart(
								bar.type,
								'type',
								bar.type,
								'FORECAST',
								barTags
									.filter(
										({ status }) => status === 'FORECAST',
									)
									.reduce((acc, { value }) => acc + value, 0),
							),
						);

						parts.push(
							generatePart(
								bar.type,
								'type',
								bar.type,
								'OVERDUE',
								barTags
									.filter(
										({ status }) => status === 'OVERDUE',
									)
									.reduce((acc, { value }) => acc + value, 0),
								true,
							),
						);
					}

					if (filterStatus && filterStatus.length > 0) {
						parts = parts.filter(({ status }) =>
							filterStatus.includes(status),
						);

						if (
							filterStatus.length === 1 &&
							filterStatus.includes('OVERDUE')
						) {
							parts = parts.map(p => ({
								...p,
								badge: false,
							}));
						}
					}

					return {
						...bar,
						value: parts
							.filter(({ status }) =>
								filterStatus.length === 1 &&
								filterStatus.includes('OVERDUE')
									? true
									: status !== 'OVERDUE',
							)
							.reduce((acc, { value }) => acc + value, 0),
						parts: parts.filter(
							({ value, badge = false }) => value !== 0 && !badge,
						),
						badges: parts.filter(
							({ value, badge = false }) =>
								value !== 0 && badge === true,
						),
					};
				}),
			}),
		);

		dispatch({
			action: 'FETCHED_GRAPH_DATA',
			graphId,
		});
	} else {
		dispatch(
			updateGraphGroup(graphId, {
				id: startDate,
				bars: [],
				lines: isFuture(startDate) ? CASHFLOW.accountBalance : [],
			}),
		);
	}
};

const source = ({ action, payload }, { dispatch }) => {
	switch (action) {
		case 'REQUEST_DATA_LIST': {
			const { size, startDate, status, types } = payload;
			// eslint-disable-next-line no-debugger
			if (!CASHFLOW || CASHFLOW.size !== size) {
				CASHFLOW = buildCashflow(
					size,
					['customer', 'bankgiro', 'plusgiro'],
					['supplier', 'salary', 'tax'],
				);

				CASHFLOW.size = size;
			}

			dispatch(
				setListItems(
					ListDemoDate(
						CASHFLOW.slices,
						startOfTime(startDate, size),
						size,
						status,
						types,
					),
				),
			);
			break;
		}
		case 'REQUEST_CLIENTS': {
			dispatch(() => ({
				action: 'SET_CLIENTS',
				payload: [],
			}));
			break;
		}
		case 'REQUEST_DATA_LIST_STATISTICS': {
			const {
				request: { size, startDate, status, types },
				path,
			} = payload;

			const parts = (CASHFLOW?.slices || [])
				.slice(19, 20)
				.reduce(
					(acc, { bars }) =>
						bars.reduce(
							(barAcc, { parts: barParts }) => [
								...barAcc,
								barParts,
							],
							acc,
						),
					[],
				)
				.flat()
				.map(p => ({
					count: 1,
					status: p.status,
					tag: { id: p.chip.config.name, name: p.chip.config.name },
					name: p.chip.config.name,
					total: p.value,
					type: p.data.type.toUpperCase(),
				}));

			dispatch(
				setListStatistics(
					{
						deposit: {
							parts: parts.filter(
								({ type }) => type === 'DEPOSIT',
							),
							total: 102836,
						},
						withdraw: {
							parts: parts.filter(
								({ type }) => type === 'WITHDRAW',
							),
							total: -81255,
						},
					},
					path,
				),
			);
			break;
		}
		case 'REQUEST_DATA_NUMBER_OF_OVERDUE': {
			dispatch(setNumberOfOverdue(3, 3, 0));
			break;
		}
		case 'REQUEST_DATA_NUMBER_OF_OPEN': {
			dispatch(setNumberOfOpen(0, 0, 0));
			break;
		}
		case 'REQUEST_DATA_BANK_ACCOUNTS': {
			dispatch(setBankAccounts(BankAccountsDemoData));
			break;
		}
		case 'REQUEST_STORE_BANK_ACCOUNT': {
			break;
		}
		case 'QUERY_CASHFLOW': {
			getCashFlow(payload, dispatch);

			break;
		}
		case 'QUERY_CASHFLOWS': {
			const { dates, ...rest } = payload;
			for (let i = 0; i < dates.length; i += 1) {
				getCashFlow({ ...rest, ...dates[i] }, dispatch);
			}

			break;
		}
		case 'REQUEST_DATA_GRAPH': {
			const { graphId, size } = payload;
			const data = GraphDemoData(size === 'isoWeek' ? 'week' : size);
			CurrentGraph = data;

			const buildType = (types, categories, tags) => {
				let availableTypes = types.filter(
					({ type, status }) =>
						['DEPOSIT', 'WITHDRAW'].includes(type) &&
						(status === 'PAID' || status === 'FORECAST'),
				);

				if (!availableTypes.find(({ type }) => type === 'DEPOSIT')) {
					availableTypes = availableTypes.concat(
						types
							.filter(
								({ type, status }) =>
									['DEPOSIT'].includes(type) &&
									status === 'OVERDUE',
							)
							.map(t => ({
								...t,
								status: 'PAID',
								sums: { original: { total: 0 } },
							})),
					);
				}

				if (!availableTypes.find(({ type }) => type === 'WITHDRAW')) {
					availableTypes = availableTypes.concat(
						types
							.filter(
								({ type, status }) =>
									['WITHDRAW'].includes(type) &&
									status === 'OVERDUE',
							)
							.map(t => ({
								...t,
								status: 'PAID',
								sums: { original: { total: 0 } },
							})),
					);
				}

				const result = availableTypes.map(
					({
						type,
						status,
						sums: { original: { total = 0 } = {} } = {},
					}) => ({
						value:
							type.toLowerCase() === 'deposit' ? total : -total,
						types: [
							type.toLowerCase(),
							status === 'FORECAST' ? 'forecast' : 'history',
						],
						data: {
							type,
						},
						badges: types
							.filter(
								({ status: badgeStatus, type: badgeType }) =>
									badgeStatus === 'OVERDUE' &&
									type === badgeType &&
									status !== 'FORECAST',
							)
							.map(
								({
									count,
									sums: {
										original: {
											total: badgeTotal = 0,
										} = {},
									} = {},
								}) => ({
									types: [type.toLowerCase(), 'overdue'],
									value: badgeTotal,
									count,
								}),
							),
						/*
						badges: [
							{
								types: [
									type.toLowerCase(),
									'overdue',
								],
								value: 27638,
								count: 6,
							},
						],
						*/
						parts: categories
							.filter(
								({ type: categoryType }) =>
									categoryType === type,
							)
							.sort(
								({ status: aStatus }, { status: bStatus }) =>
									['PAID', 'UNPAID', 'FORECAST'].indexOf(
										aStatus,
									) -
									['PAID', 'UNPAID', 'FORECAST'].indexOf(
										bStatus,
									),
							)
							.map(
								({
									status: categoryStatus,
									category: {
										id,
										name: categoryName,
										tags: categoryTags = [],
									} = {},
									sums: {
										original: {
											total: categoryTotal = 0,
										} = {},
									} = {},
								}) => ({
									value:
										type.toLowerCase() === 'deposit'
											? categoryTotal
											: -categoryTotal,
									data: {
										category: {
											id,
											name: categoryName,
											tags: categoryTags,
										},
										type,
									},
									types: [
										type.toLowerCase(),
										`category-${id}`,
										(categoryStatus || '').toLowerCase(),
									],
									parts: tags
										.filter(
											({
												type: tagType,
												status: tagStatus,
												tag: { id: tagId } = {},
											}) =>
												tagType === type &&
												tagStatus === categoryStatus &&
												categoryTags.find(
													t => t.id === tagId,
												),
										)
										.map(
											({
												tag: {
													id: tagId,
													name: tagName,
												} = {},
												sums: {
													original: {
														total: tagTotal = 0,
													} = {},
												} = {},
												status: tagStatus,
											}) => ({
												value:
													type.toLowerCase() ===
													'deposit'
														? tagTotal
														: -tagTotal,
												data: {
													category: {
														id,
														name: categoryName,
														tags: categoryTags,
													},
													tag: {
														id: tagId,
														name: tagName,
													},
													type,
												},
												types: [
													type.toLowerCase(),
													`tag-${tagId}`,
													(
														tagStatus || ''
													).toLowerCase(),
												],
											}),
										)
										.sort((a, b) => {
											if (
												a.types.includes('forecast') &&
												!b.types.includes('forecast')
											) {
												return 1;
											}

											if (
												!a.types.includes('forecast') &&
												b.types.includes('forecast')
											) {
												return -1;
											}

											return a.value - b.value;
										}),
								}),
							),
					}),
				);

				return result;
			};

			dispatch(
				setBarGroup(
					graphId,
					data.map(
						({ date, types = [], categories = [], tags = [] }) => ({
							id: date,
							lines: types
								.filter(
									({ type }) =>
										type === 'ACCOUNT' || type === 'CREDIT',
								)
								.map(
									({
										sums: {
											original: { total = 0 } = {},
										} = {},
										probability,
										status,
										type,
										max,
										min,
									}) => ({
										value: total,
										max: max?.original?.total || total,
										min: min?.original?.total || total,
										probability,
										types: [
											type.toLowerCase(),
											isFuture(new Date(date)) ||
											status === 'FORECAST'
												? 'forecast'
												: 'history',
										],
									}),
								),
							bars: buildType(types, categories, tags),
						}),
					),
				),
			);

			dispatch(updateGraph(graphId));

			break;
		}
		case 'REQUEST_DATA_GRAPH_CATEGORIES': {
			dispatch(setAvailableCategories(CategoriesDemoData));
			break;
		}
		case 'REQUEST_DATA_INTEGRATIONS': {
			dispatch(() => ({
				action: 'SET_INTEGRATIONS',
				payload: IntegrationsDemoData,
			}));
			break;
		}
		case 'REQUEST_REMOVE_INTEGRATION': {
			break;
		}
		case 'REQUEST_DATA_USER': {
			dispatch(() => ({
				action: 'SET_USER',
				payload: {
					settings: {
						layout: {
							graph: {
								layout: 'flat',
								barLayout: 'grouped',
							},
							layout: 'vertical',
							positioning: 'relative',
						},
						flags: {
							whyDidYouOnboard: true,
							hasReadCreditOnboarding: true,
						},
						compressList: false,
						erpReminder: false,
						hasOnboarded: true,
						hasReadAdjustable: true,
						showBalance: true,
						showBalanceNumbers: true,
						showIn: true,
						showOut: true,
						listColumns: [
							'description',
							'payment-date',
							'categories',
							'flat-status',
							'total',
						],
					},
				},
			}));
			break;
		}
		default:
			break;
	}
};

export default source;
