import styled from 'styled-components';
import { preProcess } from '@asteria/utils';

const processColor = (color = '', prefix = 'color', status = 'PAID', theme) => {
	if (status === 'UNPAID') {
		return preProcess(`var(--system-unpaid-${prefix})`, theme);
	}

	if (status === 'OVERDUE') {
		return preProcess(`var(--system-overdue-${prefix})`, theme);
	}

	if (color.startsWith('$')) {
		// eslint-disable-next-line no-debugger
		return preProcess(
			`var(--${color.replace(
				'$',
				'system-',
			)}-${prefix}-${status.toLowerCase()}, var(--${color.replace(
				'$',
				'system-',
			)}-${prefix}, var(--system-${status.toLowerCase()}-${prefix})))`,
			theme,
		);
	}

	return color;
};
/*
#1e5799 0%,
#1e5799 51%,
#ff0000 51%,
#ff0000 78%,
#19ff00 78%,
#19ff00 100%

*/

const Prefix = styled.span`
	border-radius: 50%;

	${({ theme, status = 'PAID', color }) =>
		Array.isArray(color)
			? `
			background: linear-gradient(
				135deg,
				${color
					.map((c, index) => {
						const gradColor = processColor(
							c,
							'color',
							status,
							theme,
						);

						return `${gradColor} ${(100 / color.length) *
							index}%, ${gradColor} ${(100 / color.length) *
							(index + 1)}%`;
					})
					.join(',')}
				
			);
			`
			: `
			background-color: ${processColor(color, 'color', status, theme)};
			background-image: ${processColor(color, 'image', status, theme)};`}
`;

export default Prefix;
