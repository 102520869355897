import Store from '../../core/store';
import logger from '../../logger';

@logger('bankAccounts:store')
class BankAccountsStore extends Store {
	name = 'bankAccounts';

	constructor(subject) {
		super(subject, {});
	}
}

export default BankAccountsStore;
