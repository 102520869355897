import React from 'react';
import styled from 'styled-components';

import NotificationItemText from './text';
import NotificationItemEmpty from './empty';

import NotificationItemForecast from './forecast';

const ItemMap = {
	empty: NotificationItemEmpty,
	TEXT: NotificationItemText,
	FORECAST: NotificationItemForecast,
};

const NotificationItem = styled(({ notification = {}, ...props }) => {
	const { type } = notification;
	const Item = ItemMap[type] || NotificationItemText;

	return <Item {...notification} {...props} />;
})``;

export default NotificationItem;
