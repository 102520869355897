import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { Getter } from '@asteria/utils';

import AddAdjustableTransaction from './addAdjustable';

const AdjustableTools = styled(({ className }) => (
	<div className={classNames(className, 'asteria-transactions-tools')}>
		<AddAdjustableTransaction />
	</div>
))``;

AdjustableTools.Styler = {
	children: [
		{ component: AddAdjustableTransaction, base: [Getter('button')] },
	],
};

export default AdjustableTools;
