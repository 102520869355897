import { useStore } from '@asteria/utils/hooks';

const LogicNotificationsCount = ({ children }) => {
	const [notificationCount] = useStore(
		'store-notifications',
		({ notifications: list = [] }) => list.length,
	);

	return children({
		notificationCount,
	});
};

const LogicNotifications = ({ children }) => {
	const [notifications] = useStore(
		'store-notifications',
		({ notifications: list }) => list,
	);

	return children({
		notificationCount: notifications.length,
		notifications,
	});
};

export default LogicNotifications;
export { LogicNotificationsCount };
