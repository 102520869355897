import get from 'lodash/fp/get';
import merge from 'lodash/fp/merge';
import mergeWith from 'lodash/fp/mergeWith';
import union from 'lodash/fp/union';
import Mustache from 'mustache';
import { StyleGetter } from './getter';

const paramRegex = /\$([a-zA-Z0-9.[\]]*)*/g;
const paramPropsRegex = /\$\{([a-zA-Z0-9.[\]]*)\}*/g;
const arraySkipper = (objValue, srcValue) => {
	if (Array.isArray(objValue) || Array.isArray(srcValue)) {
		return srcValue;
	}

	return undefined;
};

function includes(substr) {
	return str => str.includes(substr);
}

class StyleCache {
	constructor() {
		this.cache = {};
		this.hits = 0;
		this.misses = 0;
	}

	get(key) {
		return this.cache[key] || false;
	}

	set(key, value) {
		this.cache[key] = value;
	}

	delete(key) {
		this.hits = 0;
		this.misses = 0;

		// Find closest cache for this path!

		const path = key.split('.');
		let currentKey = key;
		for (let i = path.length; i >= 0; i -= 1) {
			if (this.cache[currentKey]) {
				delete this.cache[currentKey];

				// Clear all subkeys
				// TODO: Find better way ?
				if (
					currentKey.includes('.style') ||
					currentKey.includes('.exclude') ||
					currentKey.includes('.layout')
				) {
					path.pop();
					currentKey = path.join('.');
				}
				Object.keys(this.cache)
					.filter(includes(currentKey))
					.forEach(keyItem => {
						// console.log('Delete Key', keys[j]);
						delete this.cache[keyItem];
					});

				return;
			}
			path.pop();
			currentKey = path.join('.');
		}
	}

	has(key) {
		const result = this.cache[key] || false;
		if (result === false) {
			this.misses += 1;
		} else {
			this.hits += 1;
		}

		return result;
	}

	clear() {
		this.cache = {};
	}
}

const styleCache = new StyleCache();
const EMPTY_VALUES = {
	array: [],
	object: {},
	string: '',
};
export default class StyleProvider {
	constructor(objectName, schema) {
		this.styleGetter = StyleGetter(objectName);
		this.objectName = objectName;
		this.schema = schema || {};
		this.theme = undefined;
	}

	/**
	 * Compare theme with stored one to check whether is it the same or not
	 *
	 * @param newTheme {Object} theme to compare
	 * @return {boolean}
	 */
	isNewTheme(newTheme) {
		// First run, just fill the theme field
		if (this.theme === undefined) {
			this.theme = newTheme;
			return false;
		}

		if (this.theme === newTheme) {
			return false;
		}

		return true;
	}

	getStyle(theme, props, target, format = 'object') {
		if (!this.schema[target]) {
			return EMPTY_VALUES[format];
		}

		// Refresh styles every time theme changes
		if (this.isNewTheme(theme)) {
			this.styleGetter = StyleGetter(this.objectName);
		}

		const paths = this.schema[target](props).filter(Boolean);
		const { overrideRoot = false } = props;

		const defaultVal = EMPTY_VALUES[format];

		const styles = paths.reduce((style, path) => {
			const pathStyle = this.extractStyle(
				theme,
				path,
				props,
				overrideRoot,
			);
			let result = style;
			switch (format) {
				case 'array':
					result = union(style, pathStyle);
					break;
				case 'string':
					result = `${result} ${pathStyle}`.trim();
					break;
				default:
					result = mergeWith(arraySkipper, style, pathStyle);
			}

			return result;
		}, defaultVal);
		return styles;
	}

	getValues(item, path, selector) {
		if (item === undefined) {
			return [];
		}

		const value = get(selector, item);

		if (path.length === 0) {
			return value === undefined ? [] : [value];
		}

		const result = this.getValues(item[path[0]], path.slice(1), selector);

		if (value !== undefined) {
			result.push(value);
		}

		return result;
	}

	extractStyle(
		theme = {},
		{
			base = [],
			path = [],
			selector = 'style',
			type = 'theme',
			format = 'object',
			// permutation = false,
		},
		{ data, styles = {}, ...props },
		overrideRoot = false,
	) {
		const basePath = base.filter(item => item);
		const selectors = path.filter(item => item);
		const styleGetter = overrideRoot
			? StyleGetter(overrideRoot)
			: this.styleGetter;

		let item = type === 'props' ? styles : styleGetter(theme) || {};

		const totalPath = basePath.concat(selectors);
		const baseKeyPath = basePath.concat(selectors).join('.');
		const cacheKey = `${overrideRoot || this.objectName}${
			totalPath.length > 0 ? `.${baseKeyPath}` : ''
		}.${selector}`;

		let result = {};

		if (
			type !== 'props' &&
			styleCache.has(cacheKey) !== false &&
			theme === this.theme
		) {
			// console.log('cache hit', cacheKey, styleCache.hits);
			result = styleCache.get(cacheKey) || EMPTY_VALUES[format];
			return this.preprocess(theme, result, props);
		}

		/* if (type !== 'props') {
			console.log('cache miss', cacheKey, styleCache.misses);
		} */

		// if (this.objectName === 'graph') {
		// 	console.log('style lookup', cacheKey, basePath.join('.'), item);
		// }

		item = basePath.length > 0 ? get(basePath.join('.'), item) : item;

		if (item === undefined) {
			if (type !== 'props') {
				styleCache.set(cacheKey, EMPTY_VALUES[format]);
			}
			return EMPTY_VALUES[format];
		}

		const values = this.getValues(item, selectors, selector).reverse();
		if (values.length === 0) {
			if (type !== 'props') {
				styleCache.set(cacheKey, EMPTY_VALUES[format]);
			}
			return EMPTY_VALUES[format];
		}

		switch (format) {
			case 'array':
				result = union(...values);
				break;
			case 'string':
				result = values.join(' ');
				if (data) {
					result = Mustache.render(result, data);
				}
				break;
			default:
				result = values.reduce(
					(acc, value) => mergeWith(arraySkipper, acc, value),
					{},
				);
		}

		// if (this.objectName === 'graph') {
		// 	console.log('style lookup', cacheKey, result);
		// }

		if (type !== 'props') {
			styleCache.set(cacheKey, result);
		}

		result = this.preprocess(theme, result, props);
		return result;
	}

	preprocess(theme, style, props) {
		if (Array.isArray(style)) {
			return style;
		}
		if (typeof style === 'string') {
			return style;
		}

		const keys = Object.keys(style);
		const processedStyle = Object.assign({}, style);
		for (let i = 0; i < keys.length; i += 1) {
			const key = keys[i];
			const value = style[key];

			if (typeof value === 'string') {
				processedStyle[key] = value.replace(
					paramPropsRegex,
					(str, match) => props[match] || str,
				);

				processedStyle[key] = processedStyle[key].replace(
					paramRegex,
					(str, match) => get(match, theme) || str,
				);
			} else if (typeof value === 'object') {
				processedStyle[key] = this.preprocess(
					theme,
					processedStyle[key],
					props,
				);
			}
		}

		return processedStyle;
	}
}

export { merge as StyleMerger, styleCache as StyleCache };
