import React, {
	useState,
	useMemo,
	useEffect,
	useContext,
	useCallback,
} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { addMonths, format } from 'date-fns';

import { TranslationService } from '@asteria/services-language';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { clearGraphData } from '@asteria/services-datalayer/services/graph/actions';
import {
	setCurrentDate,
	setFilters,
} from '@asteria/services-datalayer/services/appstate/actions';
import { formatNumber, useStore } from '@asteria/utils';

import Text from '@asteria/component-typography/text';
import Title from '@asteria/component-typography/title';
import Button from '@asteria/component-button';
import { useHistory } from '@asteria/utils/hooks';
import { StatsTag } from '@asteria/component-tags';

const GET_CLIENT_CASHFLOW = `
	query QueryCashflow(
		$startDate: Date
		$endDate: Date
		$clients: [ObjectId]
	) {
		cashflow(
			filters: {
				startDate: $startDate
				endDate: $endDate
				search: { clients: $clients }
			}
		) {
			sums {
				original {
					total
				}
			}
			max {
				original {
					total
				}
			}
			min {
				original {
					total
				}
			}
			probability
			type
			status
			count
			badge
			chip {
				id
				type
				config
			}
		}
	}

`;

const GET_CLIENTS_STATS = `
  query GetClientsStatistics($start: Date, $end: Date) {
	clients(pageFilters: {first: 0}) {
	  edges {
		node {
		  id
		  name
		  type
		  statistics {
			invoices(input: {dates: {invoicePaid: {gte: $start, lte: $end}}}) {
			  total
			  count
			}
		  }
		}
	  }
	  statistics(input: {isPaginatedStatistics: false}) {
		invoices {
		  total
		}
	  }
	}
  }
`;

const ClientCashFlow = styled(
	({ className, setShow, start, client: { id, type, name } = {} }) => {
		const { lookup, dispatch } = useContext(DatalayerContext);
		const apiService = lookup('service-api');
		const [stats, setStats] = useState(null);

		const transType = type === 'SUPPLIER' ? 'WITHDRAW' : 'DEPOSIT';
		const tagName = `$${type.toLowerCase()}`;

		const [availableTags = []] = useStore(
			'store-graph',
			state => state.availableTags,
		);

		const filterTag = availableTags.find(
			({ config: { name: tName } }) => tName === tagName,
		);

		useEffect(() => {
			apiService
				.query(
					GET_CLIENT_CASHFLOW,
					{
						startDate: `${format(
							start || new Date(),
							'YYYY-01-01',
						)}T00:00:00.000`,
						endDate: `${format(
							start || new Date(),
							'YYYY-12-31',
						)}T23:59:59.999`,
						clients: [id],
					},
					{ reqAuth: true },
				)
				.subscribe(data => {
					const paid = data?.data?.cashflow?.filter(
						({ status, type: cType }) =>
							status === 'PAID' && cType === transType,
					);

					const unpaid = data?.data?.cashflow?.filter(
						({ status, type: cType }) =>
							status === 'UNPAID' && cType === transType,
					);

					const overdue = data?.data?.cashflow?.filter(
						({ status, type: cType }) =>
							status === 'OVERDUE' && cType === transType,
					);

					const forecast = data?.data?.cashflow?.filter(
						({ status, type: cType }) =>
							status === 'FORECAST' && cType === transType,
					);

					const clientStats = {
						total: data?.data?.cashflow?.reduce(
							(
								acc,
								{
									type: cType,
									sums: {
										original: { total },
									},
								},
							) => {
								if (cType !== transType) {
									return acc;
								}

								return acc + total;
							},
							0,
						),
						parts: [],
					};

					if (paid.length !== 0) {
						clientStats.parts.push({
							total: paid.reduce(
								(
									acc,
									{
										sums: {
											original: { total },
										},
									},
								) => acc + total,
								0,
							),
							status: 'PAID',
							count: paid.reduce(
								(acc, { count }) => acc + count,
								0,
							),
							tag: {
								name: tagName,
							},
						});
					}

					if (forecast.length !== 0) {
						clientStats.parts.push({
							total: forecast.reduce(
								(
									acc,
									{
										sums: {
											original: { total },
										},
									},
								) => acc + total,
								0,
							),
							status: 'FORECAST',
							count: forecast.reduce(
								(acc, { count }) => acc + count,
								0,
							),
							tag: {
								name: tagName,
							},
						});
					}

					if (unpaid.length !== 0) {
						clientStats.parts.push({
							total: unpaid.reduce(
								(
									acc,
									{
										sums: {
											original: { total },
										},
									},
								) => acc + total,
								0,
							),
							status: 'UNPAID',
							count: unpaid.reduce(
								(acc, { count }) => acc + count,
								0,
							),
							tag: {
								name: tagName,
							},
						});
					}

					if (overdue.length !== 0) {
						clientStats.parts.push({
							total: overdue.reduce(
								(
									acc,
									{
										sums: {
											original: { total },
										},
									},
								) => acc + total,
								0,
							),
							status: 'OVERDUE',
							count: overdue.reduce(
								(acc, { count }) => acc + count,
								0,
							),
							tag: {
								name: tagName,
							},
						});
					}

					setStats({
						[transType.toLowerCase()]: clientStats,
					});
				});
		}, [apiService, id, start, tagName, transType]);

		const onFilter = useCallback(
			(_, status) => {
				const filters = [
					{
						id,
						type: 'client',
						config: {
							client: id,
							name,
							type,
						},
					},
				];

				if (filterTag) {
					filters.push(filterTag);
				}

				dispatch(clearGraphData('cashflow-bar-graph', true));
				dispatch(
					setCurrentDate(
						`${format(
							addMonths(start || new Date(), -1),
							'YYYY-12-01',
						)}T00:00:00.000`,
					),
				);

				if (status) {
					filters.push({
						id: `status-${status}`,
						type: 'status',
						config: {
							name: `$${status.toLowerCase()}`,
							status,
						},
					});
				}

				dispatch(setFilters(filters));
				setShow(false);
			},
			[dispatch, filterTag, id, name, setShow, start, type],
		);

		if (!stats) {
			return null;
		}

		return (
			<div className={className}>
				<StatsTag
					name={tagName}
					tagType="tag"
					type={transType.toLowerCase()}
					statistics={stats}
					onClick={onFilter}
				/>
			</div>
		);
	},
)``;

const ClientView = styled(
	({
		className,
		index,
		total,
		start,
		onClick = () => {},
		setShow = () => {},
		client,
		client: { name, type: clientType, statistics },
	}) => {
		const [showCashflow, setShowCashFlow] = useState(false);
		return (
			// Hej

			<li
				className={classNames(className, 'asteria-list-item', {
					'asteria-list-item-customer': clientType === 'CUSTOMER',
					'asteria-list-item-supplier': clientType === 'SUPPLIER',
				})}
			>
				<div className="asteria-list-item-inner">
					<div className="asteria-client-rank">
						<Text>{index + 1}</Text>
					</div>
					<div
						className="asteria-client-overview"
						onClick={() => onClick(client)}
						onKeyPress={() => onClick(client)}
						tabIndex="-1"
						role="button"
					>
						<Text>{name}</Text>
						<div
							className="asteria-prefix"
							style={{
								width: `${((statistics?.invoices?.total
									?.display?.[0]?.total || 0) /
									total) *
									100}%`,
							}}
						>
							<span className="asteria-client-prefix-indicator" />
						</div>
					</div>
					<Text className="asteria-text-client-amount">
						{formatNumber(
							statistics?.invoices?.total?.display?.[0]?.total ||
								0,
							clientType === 'SUPPLIER',
						)}
					</Text>
					<Button
						type="link"
						size="small"
						// icon="down"
						icon={showCashflow ? 'up' : 'down'}
						onClick={() => setShowCashFlow(!showCashflow)}
					/>
				</div>

				{showCashflow && (
					<ClientCashFlow
						client={client}
						start={start}
						setShow={setShow}
					/>
				)}
			</li>
		);
	},
)`
	.asteria-list-item-inner {
		display: flex;
	}

	flex-direction: column;
`;

const ClientsView = styled(
	({ className, start, setShow = () => {}, type = 'ALL' }) => {
		const { lookup, dispatch } = useContext(DatalayerContext);
		const apiService = lookup('service-api');

		const [clients, setClients] = useState([]);
		const [total, setTotal] = useState(0);
		const history = useHistory();
		useEffect(() => {
			apiService
				.query(
					GET_CLIENTS_STATS,
					{
						start: `${format(
							start || new Date(),
							'YYYY-01-01',
						)}T00:00:00.000`,
						end: `${format(
							start || new Date(),
							'YYYY-12-31',
						)}T23:59:59.999`,
					},
					{ reqAuth: true },
				)
				.subscribe(data => {
					setClients(data?.data?.clients?.edges || []);
					setTotal(
						data?.data?.clients?.statistics?.invoices?.total
							?.display?.[0]?.total || 0,
					);
				});
		}, [apiService, start]);

		const [integrations = []] = useStore(
			'store-integrations',
			({ integrations: list = [] }) => list,
		);

		const erps = integrations.filter(({ type: t }) => t === 'erp');

		const sortedClients = useMemo(
			() =>
				clients
					.filter(
						({ node: { type: clientType } = {} }) =>
							type === 'ALL' || clientType === type,
					)
					.filter(
						({ node }) =>
							(node?.statistics?.invoices?.total?.display?.[0]
								?.total || 0) !== 0,
					)
					.sort((a, b) => {
						const aTotal =
							a?.node?.statistics?.invoices?.total?.display?.[0]
								?.total || 0;
						const bTotal =
							b?.node?.statistics?.invoices?.total?.display?.[0]
								?.total || 0;

						return bTotal - aTotal;
					}),
			[clients, type],
		);

		const showCustomer = useCallback(
			({ id, name, type: clientType }) => {
				dispatch(clearGraphData('cashflow-bar-graph', true));
				dispatch(
					setCurrentDate(
						`${format(
							addMonths(start || new Date(), -1),
							'YYYY-12-01',
						)}T00:00:00.000`,
					),
				);
				const chip = {
					id,
					type: 'client',
					config: {
						client: id,
						name,
						type: clientType,
					},
				};

				dispatch(setFilters([chip]));
				setShow(false);
			},
			[dispatch, setShow, start],
		);

		return (
			<div className={classNames(className)}>
				<div
					className={classNames(
						'asteria-clients-overview',
						`asteria-clients-overview-${type}`,
					)}
				>
					<div className="asteria-list-wrapper">
						{erps.length === 0 && (
							<div className="asteria-missing-erp-integration">
								<Text>
									{TranslationService.get(
										'drawer.clients.missing.erp',
										'You need to add your business software to see your clients.',
									)}
								</Text>
								<Button
									type="primary"
									size="medium"
									onClick={() => {
										setShow(false);
										history.push('/integrations/erp/add');
									}}
								>
									{TranslationService.get(
										'drawer.clients.missing.erp.button.text',
										'Add business software',
									)}
								</Button>
							</div>
						)}
						{/* 				<Title size="2" className="asteria-section-title">
					{TranslationService.get('drawer.clients.title', 'Kunder')}
				</Title> */}
						{sortedClients.length === 0 && erps.length !== 0 && (
							<Text className="asteria-list-empty">
								{TranslationService.get(
									[
										'drawer.clients.empty',
										`drawer.clients.${type}.empty`,
									],
									'Kunder',
									{
										start,
									},
								)}
							</Text>
						)}
						<ul className="asteria-list">
							{sortedClients.map(({ node }, index) => (
								<ClientView
									key={node.id}
									onClick={showCustomer}
									setShow={setShow}
									total={total}
									client={node}
									index={index}
									start={start}
								/>
							))}
						</ul>
					</div>
				</div>
			</div>
		);
	},
)`
	display: flex;
	justify-content: space-around;
	padding: 0px 40px 32px;
	flex-direction: column;

	.asteria-clients-overview {

	}

	.asteria-list-wrapper {
		&.asteria-financial-factoring {
		}
		.asteria-section-title {
			font-family: Swedbank Headline;
			font-size: 24px;
			display: flex;
			align-items: center;
			line-height: 100%;
			color: #f35c1b;
			padding: 12px 16px;
			border-bottom: 2px solid #d4c4bc;
			margin: 0 !important;
		}
		width: 100%;
		margin: 8px 32px 0 0;
		${Button} {
			margin-left: 4px;
			padding-left: 0 !important;
			.asteria-icon-wrapper {
				width: 20px;
				height: 12px;
				margin-right: 4px;
				svg {
					fill: #257886 !important;
				}
			}
		}
		.asteria-list {
			margin: 0;
			padding: 0;
			display: flex;
			justify-content: center;
			flex-direction: column;
			grid-auto-flow: column;
			grid-template-columns: 50% 50%;	
			grid-template-rows: repeat(5, auto);
			display: grid;
			.asteria-list-item-divider {
				background: rgb(251, 242, 234);
				border-bottom: 2px solid rgb(235, 231, 226);
				display: flex;
				flex-direction: row;
				padding: 10px 12px;
				margin: 0;
				${Text} {
					font-family: Arial, Helvetica, sans-serif;
					font-size: 14px;
					color: var(--text-color);
				}
				.asteria-list-item-summary {
					margin-left: auto;
				}
			}

			
			.asteria-list-item {
				display: flex;
				.asteria-list-item-inner {
					align-items: center;
					padding: 9px 8px 9px 12px;
					/* border-bottom: 1px solid rgb(235, 231, 225); */
					display: flex;
					flex-direction: row;
					margin: 0;

					.asteria-client-rank {
						min-width: 16px;
						margin: 0 8px 0 0;
						font-family: Swedbank Sans;
						.asteria-text {
							font-family: Arial,Helvetica,sans-serif;
							font-size: 14px;
							color: var(--text-color);
						}
					}

					.asteria-client-overview {
						flex: 1;
						margin: 0 16px 0 0;
						position: relative;
						height: 32px;
						display: flex;
						align-items: center;
						.asteria-text {
							font-family: Swedbank Sans;
							font-weight: 700;
							font-size: 14px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							max-width: 100%;
							color: var(--text-color);
							border: 1px solid transparent;
							background: transparent;
							align-items: center;
							cursor: pointer;
							display: inline-flex;
							padding: 5px 12px 6px 12px;
							width: auto;
							border-radius: 50px;
							&:hover {
								border: 1px solid #D3CAC0;
								background: #D3CAC0;
							}
							&.asteria-state-active {
								border: 1px solid #D3CAC0;
								background: #D3CAC0;
							}
						}
						.asteria-prefix {
							background: #EAE7E4;
							border-radius: 50px;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							z-index: -1;
							.asteria-prefix-indicator {
								
							}
						}
					}

					.asteria-text-client-amount {
						width: 115px;
						text-align: right;
						padding-left: 6px;
						font-family: Arial,Helvetica,sans-serif;
						font-size: 14px;
						color: var(--text-color);
					}

					.asteria-button-icon .asteria-icon-wrapper svg {
						fill: #9f8c82 !important;

					.asteria-list-item-description {
						flex-grow: 1;
						${Title} {
							font-size: 16px;
							font-family: Swedbank Sans;
							font-weight: 700;
							color: var(--text-color);
							margin: 0 0 4px !important;
						}
						${Text} {
							color: var(--muted-color);
							font-family: Arial, Helvetica, sans-serif;
							font-size: 12px;
						}
					}

					.asteria-list-item-summary {
						display: flex;
						flex-direction: row;
						align-items: center;
						${Title} {
							margin: 0;
							font-size: 16px;
							font-family: Swedbank Sans;
							font-weight: 700;
							color: var(--text-color);
							margin: 0 0 4px !important;
						}
						${Button} {
							margin-left: 4px;
							.asteria-icon-wrapper {
								svg {
									fill: #9f8c82 !important;
								}
							}
						}
					}
				}
			}
		}
	}

	.asteria-box {
		border: 1px solid #f2f0ed;
		padding: 16px;
		display: flex;
		align-content: center;
		justify-content: center;
		flex-direction: column;
		min-height: 184px;
		max-width: 244px;
		margin-right: 16px;
		position: relative;
		${Title} {
			color: #262626;
			font-family: 'Swedbank Headline';
			font-size: 18px;
			margin: 0 auto 8px !important;
			text-align: center;
			font-weight: 600;
			line-height: 1.4;
			flex-grow: 0 !important;
		}
		${Text} {
			color: #696969;
			font-size: 14px;
			text-align: center;
		}
		${Button} {
			margin-top: 32px;
			.asteria-icon-wrapper {
				width: 20px;
				height: 12px;
				margin-right: 4px;
			}
			.asteria-text {
				color: #257886 !important;
			}
			&.asteria-button-cover {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				width: 100%;
				height: 100%;
			}
		}
		&:hover {
			background: #f5f5f5;
			box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
			cursor: pointer;
			${Title} {
				color: #53a1ac;
			}
			${Text} {
			}
			${Button} {
			}
		}
	}
`;

ClientsView.displayName = 'ClientsView';

ClientsView.Styler = {
	children: [],
};

export default ClientsView;
