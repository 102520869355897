import React, {
	useState,
	useMemo,
	useCallback,
	useReducer,
	useEffect,
} from 'react';
import createNavigator from './createNavigator';

/**
 * Create initial Onboarding component context
 */
const Context = React.createContext({});

const registerEventInAnalytics = () => {}; // Replace this with a real event registerer

export const bankSoftwareTypeKey = 'BANK_SOFTWARE';
export const erpSoftwareTypeKey = 'ERP_SOFTWARE';
const MAX_HISTORY_LENGTH = 10;

const reducer = (state, { type, data }) => {
	switch (type) {
		case 'SET_BANK_SOFTWARE_TYPE':
			return {
				...state,
				softwareType: bankSoftwareTypeKey,
			};
		case 'SET_ERP_SOFTWARE_TYPE':
			return {
				...state,
				softwareType: erpSoftwareTypeKey,
			};
		case 'SET_PROVIDER':
			return {
				...state,
				softwareProvider: data.providerName,
			};
		case 'SET_API_KEY':
			return {
				...state,
				apiKey: data.apiKey,
			};
		default:
			return state;
	}
};

/**
 * Custom context provider component
 *
 * @param children
 * @param startStep
 * @param initialData
 * @param beforeNavigate
 * @return {*}
 * @constructor
 */
const OnboardingProvider = ({
	children,
	startStep = undefined,
	initialData = {},
	type: initialType = 'erp',
	beforeNavigate = step => step,
	callback = () => {},
}) => {
	const [data, dispatch] = useReducer(reducer, initialData);
	const [currentStep, setCurrentStep] = useState(startStep);
	const [stepIndex, setStepIndex] = useState(0);
	const [lastEvent, setLastEvent] = useState('');
	const [type, setType] = useState(initialType);
	const [history, setHistory] = useState(startStep ? [startStep] : []);
	const [activeModal, setActiveModal] = useState('');

	useEffect(() => {
		if (history.length === 1 && startStep) {
			setCurrentStep(startStep);
			setHistory([startStep]);
		}
	}, [history.length, startStep]);

	const updateHistory = useCallback(
		step => {
			if (history.length < MAX_HISTORY_LENGTH) {
				setHistory([...history, step]);
			} else {
				setHistory([...history.slice(1, 10), step]);
			}
		},
		[history],
	);

	const navigate = useCallback(
		step => {
			const gotoStep = beforeNavigate(step, history);
			setLastEvent('goto');
			updateHistory(gotoStep);
			setCurrentStep(gotoStep);
		},
		[beforeNavigate, history, updateHistory],
	);

	const navigator = useMemo(
		() =>
			createNavigator({
				currentStep,
				previousStep: history[history.length - 1],
				history,
				lastEvent,
				next: () => {
					navigate('');
				},
				back: () => {
					let newHistory = [];
					if (history.length) {
						newHistory = history.slice(0, history.length - 1);
						setHistory(newHistory);
					}

					beforeNavigate(history[history.length - 2], newHistory);
					setCurrentStep(history[history.length - 2] || '');
				},
				cancel: () => {
					navigate(false);
					setLastEvent('cancel');
					callback('cancel', {
						event: 'cancel',
					});
				},
				navigateTo: (key, statData) =>
					navigate(key) && registerEventInAnalytics(statData),
			}),
		[beforeNavigate, callback, currentStep, history, lastEvent, navigate],
	);

	return (
		<Context.Provider
			value={{
				data,
				navigator,
				activeModal,
				setActiveModal,
				stepIndex,
				type,
				setStepIndex,
				setBankSoftwareType: () => {
					setType('bank');
					dispatch({ type: 'SET_BANK_SOFTWARE_TYPE' });
				},
				setERPSoftwareType: () => {
					setType('erp');
					dispatch({ type: 'SET_ERP_SOFTWARE_TYPE' });
				},
				setProvider: providerName =>
					dispatch({ type: 'SET_PROVIDER', data: { providerName } }),
				setApiKey: apiKey =>
					dispatch({ type: 'SET_API_KEY', data: { apiKey } }),
			}}
		>
			{children}
		</Context.Provider>
	);
};

const { OnboardingConsumer } = Context;

export default Context;
export { OnboardingConsumer, OnboardingProvider };
