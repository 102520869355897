/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { Subject, BehaviorSubject } from 'rxjs';
import debug from 'debug/dist/debug';

const logger = debug(`asteria:datalayer:actions`);

// eslint-disable-next-line arrow-body-style
const devToolsMiddleware = dispatch => {
	// eslint-disable-next-line no-underscore-dangle
	if (!window.__REDUX_DEVTOOLS_EXTENSION__) {
		return () => {};
	}

	const devTools = window.__REDUX_DEVTOOLS_EXTENSION__.connect({
		name: 'Asteria',
	});
	devTools.subscribe(message => {
		switch (message.type) {
			case 'ACTION': {
				try {
					const action = JSON.parse(
						message.payload.trim().replace('\t', ''),
					);
					// eslint-disable-next-line no-undef
					dispatch(() => action);
					break;
				} catch (err) {
					break;
				}
			}
			case 'DISPATCH': {
				break;
			}
			default:
		}
	});

	return (action, next, store) => {
		next(action);

		const { action: type, ...rest } = action;

		devTools.send({ type, ...rest }, store);
	};
};

const createDispatcher = () => {
	let rootStore = {};
	const subject = new BehaviorSubject(rootStore);
	const SERVICES = {};
	const listeners = [];
	const stores = [];
	const actionCounter = {};
	const register = async (key, service) => {
		SERVICES[key] = service;
	};

	const lookup = name => SERVICES[name];

	const actions = new Subject();
	const dispatch = fn => {
		let action = fn;

		if (typeof fn === 'function') {
			action = fn({ actions, dispatch, lookup, rootStore });
		}

		if (!action) {
			return action;
		}

		if (!actionCounter[action.action]) {
			actionCounter[action.action] = 0;
		}

		actionCounter[action.action] += 1;

		logger('Dispatching action', action);
		actions.next(action);
		return action;
	};

	const createStore = (reducer, store) => {
		listeners.push(action =>
			store.setState(state => reducer(state, action, { rootStore })),
		);
		stores.push(store);
	};

	const middleWares = [
		(action, next) => {
			logger('Handling action', action);
			next();
		},
		(action, next) => {
			logger('Running setState middleware');
			listeners.forEach(fn => fn(action));
			next();
		},
		(action, next) => {
			logger('doneHandlerAction');
			next();
		},
		// devToolsMiddleware(dispatch),
	];

	const executeMiddleware = async (action, fn, remaningMiddlewares) => {
		// eslint-disable-next-line no-debugger
		try {
			let next = () =>
				executeMiddleware(
					action,
					remaningMiddlewares[0],
					remaningMiddlewares.slice(1),
				);
			if (!remaningMiddlewares || remaningMiddlewares.length === 0) {
				next = () => {};
			}
			const result = fn(action, next, rootStore);
			if (result && result instanceof Promise) {
				logger('Return promise');
				await result;
				next();
			}
		} catch (err) {
			console.log(err);
		}
	};

	actions.subscribe(async action => {
		try {
			await executeMiddleware(
				action,
				middleWares[0],
				middleWares.slice(1),
			);
		} catch (err) {
			console.log(err);
		}
	});

	const updateStores = async () => {
		const newStore = stores.reduce(
			(prev, store) => store.dispatch(prev),
			rootStore,
		);

		if (rootStore !== newStore) {
			logger('Updating RootStore', rootStore, newStore);
			subject.next(newStore);
			rootStore = newStore;
		}

		requestAnimationFrame(updateStores);
	};

	requestAnimationFrame(updateStores);

	return {
		actions,
		createStore,
		dispatch,
		lookup,
		register,
		subject,
	};
};

export default createDispatcher;
export { createDispatcher };
