import React, { useCallback } from 'react';
import classNames from 'classnames';
import Button from '@asteria/component-button';
import { TranslationService } from '@asteria/services-language';
import { useHistory } from '@asteria/utils/hooks';

export default ({
	type,
	onClick = () => {},
	className = 'asteria-button-risk-help',
}) => {
	const history = useHistory();

	const action = useCallback(() => {
		onClick();
		history.push(`/request/${type}`);
	}, [onClick, history, type]);

	return (
		<Button
			onClick={action}
			type="link"
			size="small"
			className={classNames('asteria-button-help', className)}
		>
			{TranslationService.get(
				['request.help.action', `request.help.action.${type}`],
				'Få hjälp med valutarisk',
			)}
		</Button>
	);
};
