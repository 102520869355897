import styled from 'styled-components';

const HorizontalScroll = styled.div`
	display: flex;
	/* height: 54px; */
	flex-wrap: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	overflow: -moz-scrollbars-none;
	scrollbar-width: none;
	-ms-overflow-style: none;
	/* margin-bottom: -32px; */
	padding-right: 32px;
	scrollbar-width: none;
	-ms-overflow-style: none;
	-webkit-mask: none;

	&::-webkit-scrollbar {
		height: 0 !important;
	}

	-webkit-mask: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0, black),
		color-stop(0.45, black),
		color-stop(0.7, black),
		color-stop(0.85, black),
		color-stop(1, rgba(0, 0, 0, 0))
	);
	mask: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0, black),
		color-stop(0.45, black),
		color-stop(0.7, black),
		color-stop(0.85, black),
		color-stop(1, rgba(0, 0, 0, 0))
	);

	@media only screen and (max-width: 1200px) {
		display: flex;
		margin-top: 0;
	}

	&:after {
		content: '&nbsp;';
		display: block;
		width: 100px;
		background: transparent;
		height: 30px;
		color: transparent;
		order: 99;
	}
`;

HorizontalScroll.displayName = 'HorizontalScroll';

export default HorizontalScroll;
