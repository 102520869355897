import React from 'react';
import FormBlock from './formBlock';

/**
 * Append theming sidebar to imported FormBlock component
 */
export default React.forwardRef((props, ref) => {
	const { path, styler, children, ...innerProps } = props;
	return (
		<FormBlock ref={ref} type="row" {...innerProps}>
			{children}
		</FormBlock>
	);
});
