import enLocale from 'date-fns/locale/en';
import svLocale from 'date-fns/locale/sv';
import ptLocale from 'date-fns/locale/pt';
import frLocale from 'date-fns/locale/fr';
import esLocale from 'date-fns/locale/es';
import deLocale from 'date-fns/locale/de';

import React from 'react';
import Mustache from 'mustache';
import { formatNumber, formatRound, useStore } from '@asteria/utils';
import capitalize from 'lodash/fp/capitalize';

import { format } from 'date-fns';
import Formatter from './formatter';

import SV from './languages/sv';
import EN from './languages/en';
import BR from './languages/br';
import FR from './languages/fr';
import ES from './languages/es';
import DE from './languages/de';
import PT from './languages/pt';

const FormattedMustache = Formatter(Mustache, {
	number: formatNumber,
	capitalize,
	mul: (val, times) => val * times,
	round: val => Math.round(val),
	numberRound: formatRound,
	currencyFlag: currency =>
		`<div class="currency-flag currency-flag-${currency.toLowerCase()}"></div>`,
});

const LOCALES = {
	en: enLocale,
	sv: svLocale,
	br: ptLocale,
	fr: frLocale,
	es: esLocale,
	de: deLocale,
	pt: ptLocale,
};

const Context = React.createContext({ translations: [] });

const StaticTranslations = {
	sv: SV,
	en: EN,
	br: BR,
	fr: FR,
	es: ES,
	de: DE,
	pt: PT,
};

const TranslationService = {
	translations: [],
	features: [],
	code: 'sv',
	/**
	 *
	 * @param {String} translationKey
	 * @param {String} defaultText
	 * @param {Object} data
	 * @return {String}
	 */
	get: (translationKey = '', defaultText = translationKey, data = {}) => {
		const translationKeyArray = Array.isArray(translationKey)
			? translationKey
			: [translationKey];

		const keys = translationKeyArray
			.map(key => [
				key,
				...TranslationService.features.map(
					feature => `${feature}.${key}`,
				),
			])
			.flat();

		const text = keys.reduce(
			(translation, key) => {
				if (!key || !key.toLowerCase) {
					return translation;
				}

				if (
					TranslationService.translations &&
					TranslationService.translations[key.toLowerCase()] !==
						undefined
				) {
					return TranslationService.translations[key.toLowerCase()];
				}

				if (
					TranslationService.translations &&
					TranslationService.translations[key] !== undefined
				) {
					return TranslationService.translations[key];
				}

				return translation;
			},
			Array.isArray(defaultText)
				? defaultText[defaultText.length - 1]
				: defaultText,
		);

		if (!text) {
			return text;
		}

		return FormattedMustache.render(text.toString(), {
			...data,
			formatters: {
				date: (d, f, o = {}, ...rest) =>
					format(
						d,
						f,
						{
							...o,
							locale:
								LOCALES[TranslationService.code] || svLocale,
						},
						...rest,
					),
			},
		});
	},
	getLocale: () => LOCALES[TranslationService.code],
};

export { TranslationService, FormattedMustache as Mustache };

export const Translation = ({
	translationKey,
	defaultText,
	data,
	Component = 'span',
	...props
}) => {
	const html = TranslationService.get(translationKey, defaultText, data);
	// eslint-disable-next-line react/no-danger
	return <Component dangerouslySetInnerHTML={{ __html: html }} {...props} />;
};

export const LanguageProvider = ({ children }) => {
	const [{ language = 'sv', current = {} } = {}] = useStore(
		'store-language',
		state => state,
	);

	const [features = {}] = useStore('store-features', store => store?.active);

	TranslationService.code = language;
	TranslationService.translations = {
		...StaticTranslations[language],
		...current,
	};

	TranslationService.features = Object.keys(features);

	const translations = { translations: TranslationService.translations };

	if (translations === false) {
		return null;
	}

	return <Context.Provider value={translations}>{children}</Context.Provider>;
};

export default Context;
