/* eslint-disable no-unused-vars */
import {
	addMonths,
	format,
	isThisMonth,
	isFuture,
	addWeeks,
	addYears,
	startOfMonth,
	startOfISOWeek,
	isThisISOWeek,
	isThisYear,
	addDays,
	startOfYear,
	endOfISOWeek,
	endOfMonth,
	endOfYear,
	isBefore,
	isPast,
} from 'date-fns';

const isThisTime = (date, size) => {
	if (size === 'week') {
		return isThisISOWeek(date);
	}
	if (size === 'month') {
		return isThisMonth(date);
	}
	if (size === 'year') {
		return isThisYear(date);
	}

	return isThisMonth(date);
};

const addTimeslice = (date, size, count = 1) => {
	if (size === 'day') {
		return addDays(date, count);
	}
	if (size === 'week') {
		return addWeeks(date, count);
	}
	if (size === 'month') {
		return addMonths(date, count);
	}
	if (size === 'year') {
		return addYears(date, count);
	}

	return date;
};

const endOfTime = (date, size) => {
	if (size === 'week') {
		return endOfISOWeek(date);
	}

	if (size === 'month') {
		return endOfMonth(date);
	}

	if (size === 'year') {
		return endOfYear(date);
	}

	return endOfMonth(date);
};

const startOfTime = (date, size) => {
	if (size === 'week') {
		return startOfISOWeek(date);
	}

	if (size === 'month') {
		return startOfMonth(date);
	}

	if (size === 'year') {
		return startOfYear(date);
	}

	return startOfMonth(date);
};

const Tags = {
	deposit: {
		invoices: ['customer'],
		transaction: ['cash', 'card'],
	},
	withdraw: {
		invoices: ['supplier'],
		taxes: ['tax'],
		salaries: ['salary'],
		transaction: ['cash', 'card'],
	},
};

const generateTags = (tags, category, type, status, total) => {
	const categoryTags = [];
	let totalLeft = total;

	for (let i = 0; i < tags.length; i += 1) {
		const tagTotal =
			i === tags.length - 1 ? totalLeft : Math.random() * totalLeft;
		totalLeft -= tagTotal;
		categoryTags.push({
			type: type.toUpperCase(),
			status,
			sums: {
				original: {
					total: tagTotal,
				},
			},
			tag: {
				id: tags[i],
				name: `$${tags[i]}`,
			},
			category,
		});
	}

	return categoryTags;
};

const Types = ['deposit', 'withdraw'];
const generateCategories = (status, totals) => {
	const categories = [];
	let tags = [];

	for (let i = 0; i < Types.length; i += 1) {
		const type = Types[i];
		const typeCategories = Object.keys(Tags[type]);
		let typeTotal = totals[type];

		for (let j = 0; j < typeCategories.length; j += 1) {
			const key = typeCategories[j];

			if (status === 'UNPAID' && key !== 'invoices') {
				// eslint-disable-next-line no-continue
				continue;
			}

			const categoryTotal =
				j === typeCategories.length - 1
					? typeTotal
					: Math.random() * typeTotal;
			typeTotal -= categoryTotal;

			tags = tags.concat(
				generateTags(
					Tags[type][key],
					{
						id: key,
						name: `$${key}`,
					},
					type,
					status,
					categoryTotal,
				),
			);

			categories.push({
				type: type.toUpperCase(),
				status,
				sums: {
					original: {
						total: categoryTotal,
					},
				},
				category: {
					id: key,
					name: `$${key}`,
					tags: Tags[type][key].map(t => ({ id: t, name: `$${t}` })),
				},
			});
		}
	}

	return {
		tags,
		categories,
	};
};

const generateTimeSlice = (
	date,
	size,
	{ account: { total = 0, min = 0, max = 0 } = {} } = {},
) => {
	const formatedDate = `${format(date, 'YYYY-MM-DD')}T00:00:00.000Z`;
	const deposit = Math.round(Math.random() * 1000000) + 200000;
	const withdraw = Math.round(Math.random() * 1000000) + 200000;
	const nextAccount = total + (deposit - withdraw);
	let nextMinAccount = min;
	let nextMaxAccount = max;
	let status = 'PAID';

	if (isFuture(date)) {
		status = 'FORECAST';
		nextMinAccount = nextAccount - Math.abs(nextAccount) * 0.3;
		nextMaxAccount = nextAccount + Math.abs(nextAccount) * 0.3;
	}

	const types = [];

	types.push({
		date: formatedDate,
		sums: {
			original: {
				total: deposit,
			},
		},
		max: null,
		min: null,
		probability: null,
		type: 'DEPOSIT',
		status,
		count: 11,
	});

	types.push({
		date: formatedDate,
		sums: {
			original: {
				total: withdraw,
			},
		},
		max: null,
		min: null,
		probability: null,
		type: 'WITHDRAW',
		status,
		count: 8,
	});

	const linesInfo = [];

	const accountInfo = {
		id: 'account',
		type: 'tooltip.group',
		data: {
			type: 'subtext',
			value: {},
			items: [],
		},
	};

	linesInfo.push(accountInfo);

	accountInfo.data.items.push({
		id: `account.paid.current`,
		type: 'tooltip.row',
		data: {
			type: 'tag',
			value: {
				system: { total: nextAccount, currency: 'EUR' },
				original: { total: nextAccount, currency: 'SEK' },
				display: { total: nextAccount, currency: 'EUR' },
			},
		},
	});

	types.push({
		date: formatedDate,
		sums: {
			original: {
				total: nextAccount,
			},
		},
		max: {
			original: {
				total: nextMaxAccount,
			},
		},
		min: {
			original: {
				total: nextMinAccount,
			},
		},
		probability: 0.8,
		type: 'ACCOUNT',
		status: status === 'PAID' ? 'HISTORY' : status,
		info: linesInfo,
		count: 0,
	});

	linesInfo.push({
		id: `account.credit`,
		type: 'tooltip.row',
		data: {
			type: 'tag',
			value: {
				system: { total: 1000, currency: 'EUR' },
				original: { total: 1000, currency: 'SEK' },
				display: { total: 1000, currency: 'SEK' },
			},
		},
	});

	types.push({
		date: formatedDate,
		sums: {
			original: {
				total: 1000,
			},
		},
		type: 'CREDIT',
		status: status === 'PAID' ? 'HISTORY' : status,
		info: linesInfo,
		count: 0,
	});

	if (isPast(date) && Math.random() > 0.75) {
		types.push({
			date: formatedDate,
			sums: {
				original: {
					total: Math.round(Math.random() * 100000),
				},
			},
			max: null,
			min: null,
			probability: null,
			type: 'WITHDRAW',
			status: 'OVERDUE',
			count: Math.ceil(Math.random() * 12),
		});
	}

	if (isPast(date) && Math.random() > 0.75) {
		types.push({
			date: formatedDate,
			sums: {
				original: {
					total: Math.round(Math.random() * 100000),
				},
			},
			max: null,
			min: null,
			probability: null,
			type: 'DEPOSIT',
			status: 'OVERDUE',
			count: Math.ceil(Math.random() * 12),
		});
	}

	let { categories, tags } = generateCategories(status, {
		deposit: deposit * (isThisTime(date, size) ? 0.5 : 1),
		withdraw: withdraw * (isThisTime(date, size) ? 0.5 : 1),
	});

	if (isThisTime(date, size)) {
		const {
			categories: openCategories,
			tags: openTags,
		} = generateCategories('UNPAID', {
			deposit: deposit * 0.5,
			withdraw: withdraw * 0.5,
		});

		categories = categories.concat(openCategories);
		tags = tags.concat(openTags);
	}

	return {
		date: formatedDate,
		types,
		categories,
		tags,
		next: {
			account: {
				total: nextAccount,
				min: nextMinAccount,
				max: nextMaxAccount,
			},
		},
	};
};

const GeneratedateRange = (start, end, size = 'month') => {
	const dates = [];
	let currentDate = startOfTime(start, size);

	while (isBefore(currentDate, end)) {
		dates.push(currentDate);
		currentDate = addTimeslice(currentDate, size, 1);
	}

	return dates;
};

export default size => {
	const dates = GeneratedateRange(
		new Date('2019-01-01'),
		new Date('2020-12-01'),
		size,
	);
	const data = [];
	let prev = { account: { total: 0, max: 0, min: 0 } };

	for (let i = 0; i < dates.length; i += 1) {
		const slice = generateTimeSlice(dates[i], size, prev);
		prev = slice.next;

		data.push(slice);
	}

	return data;
};
