/* eslint-disable import/prefer-default-export */
import { map } from 'rxjs/operators';
import {
	GET_ALL_FEATURES,
	ADD_FEATURE,
	UPDATE_FEATURE,
	REMOVE_FEATURE,
	GET_ACTIVE_FEATURES,
	ENABLE_FEATURE,
	DISABLE_FEATURE,
	ENABLE_FEATURES,
	DISABLE_FEATURES,
} from './queries';

export const setAvailableFeatures = features => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_AVAILABLE_FEATURES',
		payload: features,
	}));
};

export const setActiveFeatures = features => ({ dispatch }) => {
	dispatch(() => ({
		action: 'SET_ACTIVE_FEATURE',
		payload: features,
	}));
};

export const fetchAllFeatures = () => ({ dispatch, lookup }) => {
	const apiService = lookup('service-api');

	apiService
		.query(GET_ALL_FEATURES, {}, { reqAuth: true, admin: true })
		.pipe(map(({ data }) => data?.allFeatures || []))
		.subscribe(features =>
			dispatch(setAvailableFeatures(Object.values(features))),
		);
};

export const fetchEnabledFeatures = () => ({ dispatch, lookup }) => {
	const apiService = lookup('service-api');

	apiService
		.query(GET_ACTIVE_FEATURES, {}, { reqAuth: true })
		.pipe(map(({ data }) => data?.activeFeatures || []))
		.subscribe(features => {
			if (localStorage.getItem('asteriaFeatures')) {
				let localFeatures = {};

				try {
					localFeatures = JSON.parse(
						localStorage.getItem('asteriaFeatures'),
					);
				} catch (e) {
					// console.error(e);
				}

				dispatch(
					setActiveFeatures({
						...features,
						...localFeatures,
					}),
				);
			} else {
				dispatch(setActiveFeatures(features));
			}
		});
};

export const enableFeature = (
	feature,
	{ type, entityId, version = 1 },
	persist,
) => ({ dispatch, lookup }) => {
	const apiService = lookup('service-api');
	const { _id: id, key } = feature;
	if (!persist) {
		dispatch(() => ({
			action: 'ENABLE_FEATURE',
			payload: feature,
		}));
	} else {
		apiService
			.query(
				ENABLE_FEATURE,
				{ id, key, input: { type, entityId, version } },
				{ reqAuth: true, admin: true },
			)
			.subscribe(() => dispatch(fetchEnabledFeatures()));
	}
};

export const enableFeatures = (features, { type, entityId }) => ({
	dispatch,
	lookup,
}) => {
	const apiService = lookup('service-api');
	const { ids, keys } = features;

	apiService
		.query(
			ENABLE_FEATURES,
			{ ids, keys, input: { type, entityId } },
			{ reqAuth: true, admin: true },
		)
		.subscribe(() => dispatch(fetchEnabledFeatures()));
};

export const disableFeature = (feature, { type, entityId }, persist) => ({
	dispatch,
	lookup,
}) => {
	const apiService = lookup('service-api');

	const { _id: id } = feature;
	if (!id || !persist) {
		dispatch(() => ({
			action: 'DISABLE_FEATURE',
			payload: feature,
		}));
	} else {
		apiService
			.query(
				DISABLE_FEATURE,
				{ id, input: { type, entityId } },
				{ reqAuth: true, admin: true },
			)
			.subscribe(() => dispatch(fetchEnabledFeatures()));
	}
};

export const disableFeatures = (features, { type, entityId }) => ({
	dispatch,
	lookup,
}) => {
	const apiService = lookup('service-api');

	const { ids } = features;
	apiService
		.query(
			DISABLE_FEATURES,
			{ ids, input: { type, entityId } },
			{ reqAuth: true, admin: true },
		)
		.subscribe(() => dispatch(fetchEnabledFeatures()));
};

export const updateFeature = ({ _id: id, name }) => ({ lookup, dispatch }) => {
	const apiService = lookup('service-api');

	apiService
		.query(
			UPDATE_FEATURE,
			{ id, input: { name } },
			{ reqAuth: true, admin: true },
		)
		.subscribe(() => dispatch(fetchAllFeatures()));
};

export const addFeature = feature => ({ lookup, dispatch }) => {
	const apiService = lookup('service-api');

	if (feature._id) {
		return;
	}

	apiService
		.query(ADD_FEATURE, { input: feature }, { reqAuth: true, admin: true })
		.subscribe(() => dispatch(fetchAllFeatures()));
};

export const removeFeature = ({ _id: id }) => ({ lookup, dispatch }) => {
	const apiService = lookup('service-api');

	apiService
		.query(REMOVE_FEATURE, { id }, { reqAuth: true, admin: true })
		.subscribe(() => dispatch(fetchAllFeatures()));
};
