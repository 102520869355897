import React from 'react';
import { LinearIndicator } from '@asteria/component-progressbar-indicator';
import { TitleV2 } from '@asteria/component-typography';
import { ButtonV2 } from '@asteria/component-button';
import { Translation, TranslationService } from '@asteria/services-language';
import Layout from '../../layout';

const Connecting = ({ navigator }) => (
	<Layout
		header={<TitleV2>Connecting</TitleV2>}
		body={
			<>
				<div
					style={{
						padding: '3px 18px 9px',
						borderBottom: '1px solid rgb(235, 231, 225)',
						marginBottom: '55px',
					}}
				>
					<TitleV2
						size="title2"
						className="asteria-loading-title"
						style={{
							fontSize: '24px',
							color: 'rgba(238,112,35,1)',
							display: 'block',
							fontFamily: 'Swedbank Headline',
							fontWeight: '400',
						}}
					>
						{TranslationService.get(
							'onboarding.fortnox.connect.title',
							'{{num}} Anslut till bokföringssystem',
							{
								num: '2. ',
							},
						)}
					</TitleV2>
				</div>
				<div>
					<Translation
						translationKey="onboarding.fortnox.connecting.text"
						defaultText="Ansluter till Fortnox"
					/>
					<LinearIndicator />
				</div>
				<div
					className="asteria-wizard-inner-footer"
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						paddingTop: '20px',
						marginTop: '55px',
					}}
				>
					<ButtonV2
						size="medium"
						type="default"
						onClick={() =>
							navigator.cancel(
								{
									event: 'cancel',
								},
								navigator,
							)
						}
						text={TranslationService.get(
							'onboarding.abort',
							'Avbryt',
						)}
					/>
					<ButtonV2
						size="medium"
						type="secondary"
						onClick={() =>
							navigator.back(
								{
									event: 'goto',
									integration: 'fortnox',
									// integrationTitle: fortnox.title,
									// step: fortnox.steps[0],
								},
								navigator,
							)
						}
						text={TranslationService.get(
							'onboarding.back',
							'Tillbaka',
						)}
					/>
				</div>
			</>
		}
		footer={<></>}
	/>
);

Connecting.navigationKey = Symbol('Connecting');

export default Connecting;
