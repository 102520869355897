export default {
	alert: {
		style: {
			padding: '8px 0px',
		},
		header: {
			icon: {
				style: {
					width: 'auto !important',
					height: 'auto !important',
					minWidth: '16px',
					minHeight: '16px',
					maxWidth: '16px',
					maxHeight: '16px',
				},
				types: {
					alert: {
						style: {
							flexGrow: '0',
							backgroundColor: 'rgb(197, 19, 28)',
							padding: '8px',
							paddingLeft: '16px',
							borderBottomRightRadius: '16px',
							borderTopRightRadius: '16px',
						},
						svg: {
							style: {
								fill: '#ffffff',
							},
						},
					},
					close: {
						style: {
							padding: '8px',
							paddingRight: '16px',
						},
					},
				},
			},
			title: {
				style: {
					marginLeft: '8px',
					color: 'var(--alert-text-color)',
					fontFamily: 'Arial, sans-serif',
					fontWeight: '400',
					fontSize: '14px',
				},
			},
		},
		types: {
			error: {
				style: {
					borderTop: '2px solid var(--alert-error-border-color)',
					backgroundColor: 'var(--alert-error-bg-color)',
				},
				header: {
					icon: {
						types: {
							alert: {
								style: {
									backgroundColor:
										'var(--alert-error-border-color)',
								},
								svg: {
									style: {
										fill: '#fff',
									},
								},
							},
						},
						svg: {
							style: {
								fill: '#C1BDBD',
							},
						},
					},
				},
			},
			warning: {
				style: {
					borderTop: '2px solid var(--alert-warning-border-color)',
					backgroundColor: 'var(--alert-warning-bg-color)',
				},
				header: {
					icon: {
						types: {
							alert: {
								style: {
									backgroundColor:
										'var(--alert-warning-border-color)',
								},
								svg: {
									style: {
										fill: '#ffffff',
									},
								},
							},
						},
					},
				},
			},
			success: {
				style: {
					backgroundColor: 'rgb(231, 244, 228)',
					borderColor: 'rgb(74, 165, 100)',
					borderStyle: 'solid',
					borderImage: 'none 100% / 1 / 0 stretch',
					borderWidth: '0.25rem 0px 0px',
				},
				header: {
					icon: {
						svg: {
							style: {
								fill: '#C1BDBD',
							},
						},
						types: {
							alert: {
								style: {
									backgroundColor: 'rgb(74, 165, 100)',
								},
								svg: {
									style: {
										fill: '#ffffff',
									},
								},
							},
						},
					},
				},
			},
			info: {
				style: {
					backgroundColor: '#e7effa',
					borderColor: '#436bc3',
					borderStyle: 'solid',
					borderImage: 'none 100% / 1 / 0 stretch',
					borderWidth: '0.25rem 0px 0px',
				},
				header: {
					icon: {
						svg: {
							style: {
								fill: '#436bc3',
							},
						},
						types: {
							alert: {
								style: {
									backgroundColor: '#436bc3',
								},
								svg: {
									style: {
										fill: '#ffffff',
									},
								},
							},
						},
					},
				},
			},
		},
	},
};
