import React, { useContext, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import Modal, { ModalBody, ModalFooter } from '@asteria/component-modal';
import classNames from 'classnames';
import { useStore, Getter, compileStyles } from '@asteria/utils';
import Button from '@asteria/component-button/button';
import { FeatureFlag, useFeature } from '@asteria/component-featureflag';
import {
	setSelectedRow,
	setItemAction,
	addIgnore,
} from '@asteria/services-datalayer/services/list/actions';
import Title, { Text } from '@asteria/component-typography';

import Alert from '@asteria/component-alert';
import { TranslationService } from '@asteria/services-language';
import {
	editTransactions,
	removeTransactions,
} from '@asteria/services-datalayer/services/adjustable/actions';
import { useHistory, useLocation } from '@asteria/utils/hooks';
import {
	setCredit,
	setFactoring,
	setFilters,
	setMode,
} from '@asteria/services-datalayer/services/appstate/actions';
import { Input } from '@asteria/component-form';

const Actions = styled.div``;

const ModalAction = ({ onClose = () => {} }) => (
	<Modal
		backdrop
		onClose={onClose}
		size="small"
		detach
		title="Hola Daniel San"
	>
		<ModalBody className="modal-body">
			<Text>Hola Daniel San</Text>
		</ModalBody>
		<ModalFooter className="modal-footer">
			<div
				className="asteria-onboarding-help-footer"
				style={{
					display: 'flex',
					width: '100%',
					justifyContent: 'space-between',
				}}
			>
				<Button
					size="medium"
					type="default"
					text={TranslationService.get('onboarding.abort', 'Avbryt')}
					onClick={onClose}
				/>
				<Button
					size="medium"
					type="secondary"
					text={TranslationService.get('onboarding.send', 'Skicka')}
					onClick={onClose}
				/>
			</div>
		</ModalFooter>
	</Modal>
);

const getClient = item => {
	const links = item?.links || [];
	if (links.find(({ type }) => type === 'INVOICE_PAYMENT')) {
		const invoice = links.find(({ type }) => type === 'INVOICE_PAYMENT');
		return invoice?.invoiceDetails?.client || false;
	}
	if (links.find(({ type }) => type === 'CLIENT')) {
		const client = links.find(({ type }) => type === 'CLIENT');
		return client ? { ...client, name: item.description } : false;
	}

	return false;
};

const ActionBar = styled(({ className }) => {
	const { dispatch } = useContext(DatalayerContext);
	const isModalAction = useFeature({ key: 'action-bar-modal' });

	const hasCreditActionBar = useFeature({ key: 'credit-action-bar' });

	const [applyCredit, setApplyCredit] = useState(false);
	const [isModalOpen, setOpenModal] = useState(false);
	const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
	const hasClientFactoring = useFeature({ key: 'factoring-client' });
	const history = useHistory();
	const { pathname } = useLocation();

	const [mode] = useStore('store-appstate', ({ mode: val }) => val);
	const [credit] = useStore('store-appstate', ({ credit: val }) => val);

	const [clients = []] = useStore(
		'store-appstate',
		({ factoring }) => factoring,
	);

	const [selectedTransactions = []] = useStore(
		'store-list',
		({ state: { selected = [] } = {} }) => selected,
	);

	const [actions = []] = useStore(
		'store-list',
		({ actions: list = [] }) => list,
	);

	const [availableCategories = []] = useStore(
		'store-graph',
		state => state.availableCategories,
	);

	const availableTags = useMemo(
		() =>
			availableCategories
				.reduce((acc, category) => acc.concat(category?.tags), [])
				.map(({ _id, name }) => ({
					id: _id,
					type: 'tag',
					config: { name },
				})),
		[availableCategories],
	);

	const cancel = useCallback(() => {
		dispatch(setSelectedRow([]));
		dispatch(setFactoring([]));
		setShowRemoveConfirm(false);
	}, [dispatch]);

	const remove = useCallback(() => {
		dispatch(removeTransactions(selectedTransactions));
		dispatch(setSelectedRow([]));
		setShowRemoveConfirm(false);
	}, [dispatch, selectedTransactions]);

	const hasActions =
		actions.filter(({ transaction: { id } }) =>
			selectedTransactions.find(({ id: i }) => i === id),
		).length > 0;

	const ignore = useCallback(() => {
		/*
		dispatch(removeTransactions(selectedTransactions));
		dispatch(setSelectedRow([]));
		setShowRemoveConfirm(false);
		*/

		dispatch(
			setItemAction(
				actions.filter(
					({ transaction: { id } }) =>
						!selectedTransactions.find(({ id: i }) => i === id),
				),
			),
		);
		dispatch(addIgnore(selectedTransactions.map(({ id }) => id)));
		dispatch(setSelectedRow([]));
	}, [actions, dispatch, selectedTransactions]);

	const factoringTransactions = useMemo(
		() =>
			selectedTransactions.filter(
				transaction =>
					!transaction?.meta?.tags
						?.filter(tag => tag)
						.some(({ name }) => name === '$factoring') &&
					transaction?.meta?.tags
						?.filter(tag => tag)
						.some(({ name }) => name === '$customer'),
			),
		[selectedTransactions],
	);

	const factoringTransactionClients = useMemo(() => {
		const transClients = factoringTransactions.reduce((list, client) => {
			const clientData = getClient(client);
			if (clientData && !list.find(({ id }) => id === clientData?.id)) {
				list.push(clientData);
			}

			return list;
		}, []);

		return transClients;
	}, [factoringTransactions]);

	const addToFactoring = useCallback(() => {
		const filters = [];
		filters.push({
			id: `status-UNPAID`,
			type: 'status',
			config: {
				name: `$unpaid`,
				status: 'UNPAID',
			},
		});
		filters.push({
			id: `status-FORECAST`,
			type: 'status',
			config: {
				name: `$forecast`,
				status: 'FORECAST',
			},
		});

		const factoring = availableTags.find(
			({ config: { name } }) => name === '$factoring',
		);

		if (factoring) {
			filters.push(factoring);
		}

		const customers = availableTags.find(
			({ config: { name } }) => name === '$customer',
		);

		if (customers) {
			filters.push(customers);
		}

		dispatch(setFilters(filters));
		dispatch(setSelectedRow(factoringTransactions));

		setTimeout(() => {
			history.push('/factoring');
		}, 50);
	}, [availableTags, dispatch, factoringTransactions, history]);

	if (
		selectedTransactions.length === 0 &&
		clients.length === 0 &&
		(mode !== 'credit' || !hasCreditActionBar)
	) {
		return null;
	}

	if (showRemoveConfirm) {
		return (
			<div
				className={classNames(
					className,
					'asteria-actionbar',
					'asteria-actionbar-confirm',
				)}
			>
				<Title size="title2">
					{TranslationService.get('actionbar.confirm.remove')}
				</Title>
				<Actions className="asteria-actionbar-actions">
					<Button
						size="medium"
						type="secondary"
						text={TranslationService.get('button.no')}
						className="asteria-button-remove"
						onClick={() => setShowRemoveConfirm(false)}
					/>
					<Button
						size="medium"
						type="primary"
						text={TranslationService.get('button.yes')}
						className="asteria-button-adjust"
						onClick={remove}
					/>
				</Actions>
			</div>
		);
	}

	if (mode === 'credit' && hasCreditActionBar) {
		if (applyCredit) {
			return (
				<Modal
					open
					title={TranslationService.get(
						'overdraft.modal.confirm.title',
						'Finansieringsbehov',
					)}
					className="asteria-modal-overdraft-confirm"
				>
					<ModalBody>
						<Alert
							icon
							type="success"
							headingContent={TranslationService.get(
								'overdraft.sent.alert.title',
								'Tack!',
							)}
							showClose={false}
							description={
								<>
									<Title
										size="title2"
										style={{
											marginTop: '0px',
											marginBottom: '2',
											fontSize: '14px',
										}}
									>
										{TranslationService.get(
											'overdraft.sent.content.title1',
											'Finansieringsbehov',
										)}
									</Title>
									<Title
										size="title1"
										style={{
											marginTop: '0px',
											marginBottom: '8px',
										}}
									>
										{applyCredit}
									</Title>
									<Text size="small">
										{TranslationService.get(
											'overdraft.sent.content.title3',
											'Du kommer få fylla i detta belopp manuellt igen på nästa sida.',
										)}
									</Text>
								</>
							}
						/>
					</ModalBody>
					<ModalFooter>
						<Button
							size="medium"
							type="primary"
							text={TranslationService.get(
								'button.continue',
								'Gå vidare',
							)}
							onClick={() => {
								setApplyCredit(0);
								dispatch(setCredit(0));
								dispatch(setMode(null));
							}}
						/>
					</ModalFooter>
				</Modal>
			);
		}
		return (
			<div
				className={classNames(
					className,
					'asteria-actionbar',
					'asteria-actionbar-credit',
				)}
			>
				<Text>
					{TranslationService.get(
						'financial.service.overdraft.amount.label',
						'Möjligt finansieringsbehov',
					)}
				</Text>
				<Input
					value={credit}
					onChange={e => {
						let val = parseInt(e.target.value, 10);
						if (Number.isNaN(val)) {
							val = e.target.value;
						}
						dispatch(setCredit(val));
					}}
				/>
				<Text>SEK</Text>
				<Actions className="asteria-actionbar-actions">
					<Button
						size="medium"
						type="secondary"
						text={TranslationService.get('button.abort')}
						onClick={() => {
							setApplyCredit(0);
							dispatch(setCredit(0));
							dispatch(setMode(null));
						}}
					/>
					<Button
						size="medium"
						type="primary"
						text={TranslationService.get('button.apply')}
						onClick={() => setApplyCredit(credit)}
					/>
				</Actions>
			</div>
		);
	}

	const isFactoringMode = pathname === '/factoring';

	if (isFactoringMode) {
		return null;
	}

	if (clients.length > 0) {
		if (!hasClientFactoring) {
			return null;
		}

		return (
			<>
				<div className={classNames(className, 'asteria-actionbar')}>
					<Title size="title2">
						{TranslationService.get(
							'actionbar.clients.selected',
							'Du har markerat {{selectedClients}} kunder. Vad vill du göra',
							{
								selectedClients: clients.length,
							},
						)}
					</Title>
					<Actions className="asteria-actionbar-actions">
						<Button
							size="medium"
							type="link"
							text={TranslationService.get('button.abort')}
							className="asteria-button-cancel"
							onClick={cancel}
						/>
						<FeatureFlag feature="factoring">
							<Button
								size="medium"
								type="primary"
								text={TranslationService.get(
									'button.factoring',
									'Fakturaköp',
									{
										count: clients.length,
									},
								)}
								className="asteria-button-factoring"
								onClick={addToFactoring}
							/>
						</FeatureFlag>
					</Actions>
				</div>
			</>
		);
	}

	return (
		<>
			<div className={classNames(className, 'asteria-actionbar')}>
				<Title size="title2">
					{TranslationService.get('actionbar.selected', '', {
						selectedTransactions: selectedTransactions.length,
					})}
				</Title>
				{isModalAction === false ? (
					<Actions className="asteria-actionbar-actions">
						<Button
							size="medium"
							type="link"
							text={TranslationService.get('button.abort')}
							className="asteria-button-cancel"
							onClick={cancel}
						/>
						<Button
							size="medium"
							type="default"
							text={TranslationService.get('button.remove')}
							className="asteria-button-remove"
							onClick={() => setShowRemoveConfirm(true)}
						/>
						{hasActions ? (
							<Button
								size="medium"
								type="primary"
								text={TranslationService.get('button.ignore')}
								className="asteria-button-remove"
								onClick={ignore}
							/>
						) : null}
						<FeatureFlag feature="adjustable-edit-multiple-actionbar">
							<Button
								size="medium"
								type="secondary"
								text={TranslationService.get('button.edit')}
								className="asteria-button-adjust"
								onClick={() => {
									dispatch(
										editTransactions(selectedTransactions),
									);
									cancel();
								}}
							/>
						</FeatureFlag>
						{factoringTransactions.length > 0 ? (
							<FeatureFlag feature="factoring">
								<FeatureFlag feature="factoring-invoice">
									<Button
										size="medium"
										type="secondary"
										text={TranslationService.get(
											'button.factoring',
											'Fakturaköp ({{count}})',
											{
												count:
													factoringTransactions.length,
											},
										)}
										className="asteria-button-adjust"
										onClick={addToFactoring}
									/>
								</FeatureFlag>
								{factoringTransactionClients.length > 0 ? (
									<FeatureFlag
										feature="factoring-invoice"
										invert
									>
										<FeatureFlag feature="factoring-client">
											<Button
												size="medium"
												type="secondary"
												text={TranslationService.get(
													'button.factoring',
													'Fakturaköp ({{count}})',
													{
														count:
															factoringTransactions.length,
													},
												)}
												className="asteria-button-adjust"
												onClick={() => {
													dispatch(
														setSelectedRow([]),
													);
													dispatch(
														setFactoring(
															factoringTransactionClients.map(
																client => ({
																	type:
																		'CLIENT',
																	client,
																	transactions: factoringTransactions.filter(
																		item => {
																			const clientData = getClient(
																				item,
																			);
																			if (
																				clientData?.id ===
																				client?.id
																			) {
																				return true;
																			}

																			return false;
																		},
																	),
																}),
															),
														),
													);
												}}
											/>
										</FeatureFlag>
									</FeatureFlag>
								) : null}
							</FeatureFlag>
						) : null}
					</Actions>
				) : null}
				{isModalAction === true ? (
					<Actions className="asteria-actionbar-actions">
						<Button
							size="medium"
							type="default"
							text="Avbryt"
							className="asteria-button-cancel"
							onClick={cancel}
						/>
						<Button
							size="medium"
							type="primary"
							text="Skicka"
							className="asteria-button-adjust"
							onClick={() => setOpenModal(true)}
						/>
					</Actions>
				) : null}
			</div>
			{isModalOpen ? (
				<ModalAction onClose={() => setOpenModal(false)} />
			) : null}
		</>
	);
})`
	${({ theme }) => compileStyles(ActionBar, theme)}
`;

ActionBar.Styler = {
	base: [Getter('actionbar')],
	children: [
		{
			component: Text,
			base: [Getter('text')],
		},
		{
			component: Title,
			base: [Getter('title')],
		},
		{
			component: Actions,
			base: [Getter('actions')],
			children: [
				{
					component: Button,
					base: [Getter('button')],
				},
			],
		},
		{
			component: Button,
			base: [Getter('button')],
		},
	],
};

export default ActionBar;
