import React, { useMemo, useState, useContext } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { formatNumber, useStore } from '@asteria/utils';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { CheckboxGroup } from '@asteria/component-form';

import { Text, Title } from '@asteria/component-core';
import Tooltip from '@asteria/component-tooltip';
import Button from '@asteria/component-core/button';
import { TranslationService } from '@asteria/services-language';
import {
	addToFactoring,
	setFactoring,
} from '@asteria/services-datalayer/services/appstate/actions';
import SecurityBar from '../components/securityBar';
import Transactions from '../transactions/transaction';
import { getVolatilityText } from '../utils';

const Client = styled(
	({
		className,
		selected,
		onSelect,
		client,
		transactions,
		isListFuture,
		tagFilter,
		visibleColumns,
	}) => {
		const { name } = client;
		const [showList, setShowList] = useState(false);
		const total = useMemo(
			() =>
				transactions.reduce(
					(acc, trans) => acc + trans?.sums?.original?.total || 0,
					0,
				),
			[transactions],
		);

		const diffTotal = useMemo(
			() =>
				transactions.reduce(
					(acc, trans) =>
						acc +
						(trans?.sums?.original?.total || 0) *
							(trans?.oracle?.risk || 1),
					0,
				),
			[transactions],
		);

		return (
			<div
				className={classNames(className, `asteria-list-clients-row`, {
					'asteria-state-active': showList,
					'asteria-state-selected': selected,
				})}
			>
				<div className="asteria-transaction">
					<div className="asteria-transaction-cell asteria-transaction-cell-status">
						<CheckboxGroup
							selected={selected ? [0] : []}
							items={[{ value: 'selected', label: '' }]}
							onChange={() => onSelect(!selected)}
						/>
					</div>
					<div className="asteria-transaction-cell asteria-transaction-cell-description">
						<Title>{name}</Title>
						<Text size="small" className="color-muted align-right">
							{TranslationService.get(
								'list.client.total.transactions',
								'{{count}} fakturor',
								{
									count: transactions.length,
								},
							)}
						</Text>
					</div>
					<div className="asteria-transaction-cell asteria-transaction-cell-total">
						<Text>{formatNumber(total)}</Text>
						<Tooltip
							hover
							isStatic
							content={TranslationService.get(
								'list.transactions.client.security',
								'{{volatilityText}}',
								{
									volatilityText: getVolatilityText(
										diffTotal / total,
									),
								},
							)}
							title=""
						>
							<SecurityBar
								value={diffTotal / total}
								max={1}
								steps={5}
							/>
						</Tooltip>
					</div>
					<div className="asteria-transaction-cell asteria-transaction-cell-toggle">
						<Button
							onClick={() => setShowList(!showList)}
							type="link"
							size="small"
							className="asteria-button-toggle"
							icon={showList ? 'up' : 'down'}
						/>
					</div>
				</div>

				<div className="asteria-transaction-cell asteria-transactions-list-clients">
					<div className="asteria-transactions-list-clients-header">
						<Title>
							{TranslationService.get(
								'list.transactions.client.transactions.title',
								'Transaktioner',
							)}
						</Title>
						<Button
							type="link"
							size="small"
							text={TranslationService.get(
								'list.transactions.client.more',
								'Visa kundinformation',
							)}
						/>
					</div>
					<Transactions
						items={transactions}
						isFuture={isListFuture}
						tagFilter={tagFilter}
						visibleColumns={visibleColumns}
					/>
				</div>
			</div>
		);
	},
)`
	&.asteria-state-active,
	&.asteria-state-selected {
		background: rgb(251, 242, 234);
		.asteria-transaction {
			background: rgb(251, 242, 234);
			.asteria-checkbox-group-wrapper
				.asteria-checkbox-wrapper
				.asteria-checkbox-mark {
				opacity: 1 !important;
			}
		}
	}

	.asteria-transactions-list-clients-header {
		flex-direction: row;
		display: flex;
		height: 40px;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 8px;
		.asteria-button {
			margin-left: auto;
			padding: 0;
			display: none;
		}
	}

	.asteria-transaction {
		border-bottom: 1px solid var(--transaction-list-border-color);
		background-color: var(--transaction-list-bg-color);
		min-height: 48px;
		align-items: center;
		display: flex;
		&:hover {
			background: rgb(251, 242, 234);
		}
		.asteria-transaction-cell {
			flex-shrink: 0;
			display: flex;
			&.asteria-transaction-cell-status {
				width: 40px;
				order: 0;
				.asteria-checkbox-group-wrapper {
					padding-top: 8px;
					padding-bottom: 8px;
					cursor: pointer;
					.asteria-checkbox-wrapper {
						align-items: flex-start;
						padding-right: 30px;
						.asteria-checkbox-mark {
							width: 16px;
							height: 16px;
							border: 2px solid rgb(114, 96, 94);
							opacity: 0.15;
							margin-left: 10px;
						}
					}
					&:hover .asteria-checkbox-wrapper .asteria-checkbox-mark {
						opacity: 1;
					}
				}
			}
			&.asteria-transaction-cell-description {
				-webkit-box-flex: 1;
				flex-grow: 1;
				flex-shrink: 1;
				overflow: hidden;
				white-space: nowrap;
				align-items: flex-start;
				flex-direction: column;
				.asteria-title {
					color: var(--transaction-list-text-color);
					font-family: 'Swedbank Sans';
					font-weight: 700;
					font-size: 16px;
				}
				.color-muted {
					color: #999;
				}
			}
			&.asteria-transaction-cell-total {
				width: 150px;
				padding-right: 8px;
				-webkit-box-pack: end;
				justify-content: flex-end;
				align-self: stretch;
				-webkit-box-align: center;
				align-items: center;
				margin-right: 8px;
				margin-left: 8px;
				.asteria-text {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					margin-right: 4px;
					color: var(--transaction-list-text-color);
					font-family: 'Swedbank Sans';
					font-weight: 700;
					font-size: 16px;
				}
				.asteria-tooltip-trigger-wrapper {
					width: 16px;
				}
				.asteria-security-bar {
					width: 12px;
					height: 20px;
					display: flex;
					flex-direction: column-reverse;
					margin-top: -2px;
					margin-right: 0px;
				}
			}
			&.asteria-transaction-cell-toggle {
				width: 40px;
				-webkit-box-pack: center;
				justify-content: center;
				.asteria-button-toggle {
					.asteria-icon-wrapper {
						svg {
							fill: #9f8c82 !important;
						}
					}
				}
			}
		}
	}

	.asteria-transactions-list-clients {
		padding: 16px;
		display: none;

		.asteria-transaction-filter {
			.asteria-transaction-cell {
				&.asteria-transaction-cell-status {
					/* 					&:first-of-type {
						width: 16px !important;
					} */
				}
			}
		}

		.asteria-list-wrapper {
			border-left: 1px solid rgba(0, 0, 0, 0.07);
			border-right: 1px solid rgba(0, 0, 0, 0.07);
		}

		.asteria-transaction > div {
			width: 100%;
			min-width: 100%;
		}

		.asteria-transaction-cell {
			/* 			&:first-of-type {
				width: 16px !important;
				&:first-of-type {
					width: auto !important;
				}
			} */
			&.asteria-transaction-cell-total {
				.asteria-tooltip-trigger-wrapper {
					width: auto;
				}
			}
		}
	}

	&.asteria-state-active {
		.asteria-transactions-list-clients {
			display: block;
		}
	}
`;

const Clients = styled(
	({
		className,
		transactions = [],
		isListFuture,
		tagFilter,
		visibleColumns,
	}) => {
		const { dispatch } = useContext(DatalayerContext);
		const [factoring = []] = useStore(
			'store-appstate',
			state => state.factoring,
		);

		const [clients = []] = useStore(
			'store-appstate',
			state => state.clients,
		);

		const clientMap = useMemo(
			() =>
				clients.reduce(
					(acc, client) => ({ ...acc, [client.id]: client }),
					{},
				),
			[clients],
		);

		const transactionsMap = useMemo(
			() =>
				transactions
					.filter(transaction =>
						transaction?.links?.find(
							({ type }) =>
								type === 'INVOICE_PAYMENT' || type === 'CLIENT',
						),
					)
					.reduce((map, transaction) => {
						const link = transaction?.links?.find(
							({ type }) =>
								type === 'INVOICE_PAYMENT' || type === 'CLIENT',
						);

						const clientId =
							link.type === 'INVOICE_PAYMENT'
								? link?.invoiceDetails?.client?.id || link.id
								: link.id;

						const newMap = map;
						if (!newMap[clientId]) {
							newMap[clientId] = [];
						}

						newMap[clientId].push(transaction);

						return newMap;
					}, {}),
			[transactions],
		);

		const clientsWithTransactions = useMemo(
			() =>
				Object.keys(transactionsMap).sort((a, b) => {
					const { name: aName = '' } = clientMap[a] || {};
					const { name: bName = '' } = clientMap[b] || {};

					return aName.localeCompare(bName);
				}),
			[clientMap, transactionsMap],
		);

		return (
			<div className={classNames(className)}>
				{clientsWithTransactions
					.filter(clientId => clientMap[clientId])
					.map(clientId => (
						<Client
							selected={factoring.find(
								({ client: { id } = {} }) => id === clientId,
							)}
							onSelect={selected => {
								if (selected) {
									dispatch(
										addToFactoring({
											type: 'CLIENT',
											client: clientMap[clientId],
											transactions:
												transactionsMap[clientId],
										}),
									);
								} else {
									dispatch(
										setFactoring(
											factoring.filter(
												({ client: { id } = {} }) =>
													id !== clientId,
											),
										),
									);
								}
							}}
							client={clientMap[clientId]}
							transactions={transactionsMap[clientId]}
							isFuture={isListFuture}
							tagFilter={tagFilter}
							visibleColumns={visibleColumns}
						/>
					))}
			</div>
		);
	},
)``;

export default Clients;
