import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

const Backdrop = styled(({ className, onClose }) => {
	const handleClose = useCallback(
		e => {
			console.log('Close backdrop');
			if (onClose) {
				e.preventDefault();
				if (e.target === e.currentTarget) {
					onClose();
				}
			}
		},
		[onClose],
	);

	return (
		<div
			className={classNames(className, 'asteria-backdrop')}
			onClick={handleClose}
			onKeypress={handleClose}
			role="button"
			tabIndex="-1"
		/>
	);
})`
	background-color: rgba(0, 0, 0, 0.3);
	height: 100%;
	width: 100%;
	flex-direction: row;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 1000;
`;

Backdrop.propTypes = {
	onClose: PropTypes.func,
	className: PropTypes.string,
};

Backdrop.Styler = {
	typePrefix: 'asteria-backdrop',
	base: `backdrop`,
};

export default Backdrop;
