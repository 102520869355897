import styled from 'styled-components';

const NotificationItemBase = styled.div`
	position: relative;

	cursor: pointer;

	.asteria-notification-item-header {
		flex-direction: row;
		display: flex;
		margin-bottom: 8px;
		.asteria-title-wrapper {
			flex-direction: row;
			display: flex;
			align-items: center;

			.asteria-title {
				font-family: Swedbank Sans !important;
				font-weight: 600 !important;
				font-size: 14px !important;
				color: #512b2b !important;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.asteria-notification-item-body {
		.asteria-text {
			font-family: Arial !important;
			font-size: 12px !important;
			color: #512b2b !important;
			padding-right: 28px;
		}
	}

	.asteria-close {
		position: absolute;
		right: 0;
		top: 0;
		opacity: 0.5;
		height: 12px;
		width: 12px !important;
		padding: 0 !important;
		&:hover {
			opacity: 1;
		}
		.asteria-icon-wrapper svg {
			fill: rgb(159, 140, 130) !important;
		}
	}

	.asteria-notification-item-footer {
		display: flex;
		justify-content: flex-start;
		.asteria-button-link {
			padding-left: 0 !important;
			padding-right: 0 !important;
			padding-bottom: 0 !important;
			margin-right: 16px;
			.asteria-text {
				color: #31818f !important;
			}
		}
	}
`;

export default NotificationItemBase;
