import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { Getter } from '@asteria/utils';

const HorizontalScroll = styled.div`
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	overflow: -moz-scrollbars-none;
	scrollbar-width: none;
	-ms-overflow-style: none;
	scrollbar-width: none;
	-ms-overflow-style: none;
	-webkit-mask: none;

	-webkit-mask: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0, black),
		color-stop(0.45, black),
		color-stop(0.7, black),
		color-stop(0.85, black),
		color-stop(1, rgba(0, 0, 0, 0))
	);

	mask: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0, black),
		color-stop(0.45, black),
		color-stop(0.7, black),
		color-stop(0.85, black),
		color-stop(1, rgba(0, 0, 0, 0))
	);

	&::-webkit-scrollbar {
		height: 0 !important;
	}

	&:after {
		content: '&nbsp;';
		display: block;
		width: 100px;
		background: transparent;
		height: 100%;
		color: transparent;
		order: 99;
	}

	> * {
		flex-shrink: 0;
	}
`;

HorizontalScroll.Styler = {
	typePrefix: 'asteria-scroll',
};

const Wrapper = styled(({ children, className, horizontalScroll }) => (
	<div className={classNames(className, 'asteria-wrapper')}>
		{horizontalScroll ? (
			<HorizontalScroll className="asteria-scroll-horizontal">
				{children}
			</HorizontalScroll>
		) : (
			children
		)}
	</div>
))`
	padding: 4px;
	margin: 4px;
`;

Wrapper.displayName = 'Wrapper';
Wrapper.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	horizontalScroll: PropTypes.bool,
};

Wrapper.Styler = {
	typePrefix: 'asteria-wrapper',
	base: [Getter('core.wrapper'), Getter('wrapper')],
	children: [
		{
			component: HorizontalScroll,
			base: 'scroll',
		},
	],
};

export default Wrapper;
