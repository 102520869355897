/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const REPORT_ISSUE = gql`
	mutation reportIssue($input: IssueReportInputType!) {
		reportIssue(input: $input) {
			ok
			error
			reportId
		}
	}
`;

export const GET_NUMBER_OF_OVERDUE = gql`
	query GetNumberOfOverdue {
		deposit: transactions(
			filters: { types: ["DEPOSIT"], status: ["OVERDUE"] }
		) {
			pageInfo {
				count
			}
		}

		withdraw: transactions(
			filters: { types: ["WITHDRAW"], status: ["OVERDUE"] }
		) {
			pageInfo {
				count
			}
		}

		total: transactions(
			filters: { types: ["DEPOSIT", "WITHDRAW"], status: ["OVERDUE"] }
		) {
			pageInfo {
				count
			}
		}
	}
`;

export const GET_NUMBER_OF_OPEN = gql`
	query GetNumberOfOverdue {
		deposit: transactions(
			filters: { types: ["DEPOSIT"], status: ["UNPAID"] }
		) {
			pageInfo {
				count
			}
		}

		withdraw: transactions(
			filters: { types: ["WITHDRAW"], status: ["UNPAID"] }
		) {
			pageInfo {
				count
			}
		}

		total: transactions(
			filters: { types: ["DEPOSIT", "WITHDRAW"], status: ["UNPAID"] }
		) {
			pageInfo {
				count
			}
		}
	}
`;

export const FETCH_CLIENTS = gql`
	query GetClients {
		clients(pageFilters: { first: 0 }) {
			edges {
				node {
					id
					name
					type
					statistic {
						invoices {
							perYear {
								total
							}
						}
					}
				}
			}
		}
	}
`;
