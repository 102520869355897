import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { useStore } from '@asteria/utils';

import { TranslationService } from '@asteria/services-language';

import Text from '@asteria/component-typography/text';
import { Tag } from '@asteria/component-tags';
import { FeatureFlag } from '@asteria/component-featureflag';

import { setMode } from '@asteria/services-datalayer/services/appstate/actions';

import SearchContext from './context';

const SearchExamples = React.memo(styled(
	({
		className,
		tagAction,
		orderd,
		customers,
		dispatch,
		filters,
		currency,
		currencies,
		unpaid,
		overdue,
	}) => (
		<div className={classNames(className)}>
			<Text className="asteria-section-label">
				{TranslationService.get('search.onboarding.label')}
			</Text>
			<div className="asteria-search-examples">
				{orderd
					.slice(0, 3)
					.concat(customers)
					.map(tag => (
						<Tag
							tag={tag.config.name}
							type={tag.type}
							onClick={() => tagAction(tag, dispatch)}
							className={classNames({
								'asteria-state-active': filters.find(
									({ id }) => tag.id === id,
								),
							})}
						/>
					))}
				{currency ? (
					<Tag
						tag={currency.currency}
						type="currency"
						prefix={false}
						onClick={() =>
							tagAction({
								id: `currency-${currency.currency}`,
								type: 'currency',
								config: {
									name: currency.currency,
								},
							})
						}
						className={classNames({
							'asteria-state-active': filters.find(
								({ id }) =>
									id === `currency-${currency.currency}`,
							),
						})}
					/>
				) : null}
				{currency ? (
					<Tag
						tag={TranslationService.get(
							'search.match.currencyRiskHigh',
							'Hög valutarisk',
						)}
						type="currencyRisk"
						prefix={false}
						onClick={() =>
							tagAction({
								id: `currencyRisk`,
								type: 'currencyRisk',
								config: {
									name: TranslationService.get(
										'search.match.currencyRiskHigh',
										'Hög valutarisk',
									),
								},
								currencies: currencies
									.filter(
										c =>
											c?.risk?.alert &&
											c?.exposure?.original?.currency !==
												c?.exposure?.display?.currency,
									)
									.map(c => ({
										id: `currency_${c.currency}`,
										type: 'currency',
										config: {
											name: c.currency,
										},
										currency: c,
									})),
							})
						}
						className={classNames({
							'asteria-state-active': filters.find(
								({ id }) => id === `currencyRisk`,
							),
						})}
					/>
				) : null}
				{unpaid > 0 ? (
					<Tag
						tag="$cashflow"
						status="UNPAID"
						type="status"
						onClick={() =>
							tagAction({
								id: 'status-UNPAID',
								type: 'status',
								config: {
									name: '$unpaid',
									status: 'UNPAID',
								},
							})
						}
						className={classNames({
							'asteria-state-active': filters.find(
								({ id }) => id === 'status-UNPAID',
							),
						})}
						data={{ count: unpaid }}
					/>
				) : null}
				{overdue ? (
					<Tag
						tag="$cashflow"
						type="status"
						status="OVERDUE"
						onClick={() =>
							tagAction({
								id: 'status-OVERDUE',
								type: 'status',
								config: {
									name: '$overdue',
									status: 'OVERDUE',
								},
							})
						}
						className={classNames({
							'asteria-state-active': filters.find(
								({ id }) => id === 'status-OVERDUE',
							),
						})}
						data={{ count: overdue }}
					/>
				) : null}
				<FeatureFlag feature="credit-application">
					<Tag
						tag={TranslationService.get(
							'credit.tag.name',
							'Kreditlån',
						)}
						type="credit"
						onClick={() => {
							dispatch(setMode('credit'));
							tagAction();
						}}
						data={{ count: overdue }}
					/>
				</FeatureFlag>
			</div>
		</div>
	),
)`
	display: flex;
	padding: 16px;

	display: flex;
	flex-direction: column;

	.asteria-section-label {
		color: #80584f;
		font-family: Arial, Helvetica, sans-serif;
		font-size: 12px;
		display: block;
		margin-bottom: 8px;
	}

	.asteria-search-examples {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		.asteria-category-overview {
			width: auto;
			@media (max-width: 1024px) {
				width: auto;
			}
		}
	}

	div[role='button'] {
		margin-right: 8px;
		margin-bottom: 8px;
		.asteria-chip {
			max-height: 32px;
			padding: 5px 12px 6px 12px;
			.asteria-text {
				font-family: Swedbank Sans;
				font-weight: 700;
				font-size: 14px;
			}
			border: 1px solid #eae7e4;
			&:hover {
				border: 1px solid #eae7e4;
				background: #eae7e4;
			}
			&.asteria-state-active {
				border: 1px solid #eae7e4;
			}
		}
		&:last-child {
			margin-right: 0;
		}
	}
`);

SearchExamples.displayName = 'SearchExamples';

SearchExamples.Styler = {
	children: [],
};

const SearchExamplesData = ({ tagAction }) => {
	const { availableTags: available, dispatch, currencies } = useContext(
		SearchContext,
	);

	const [listStatistics = []] = useStore(
		'store-list',
		({ searchStatistics }) => searchStatistics,
	);

	const [filters = []] = useStore(
		'store-appstate',
		({ filters: list }) => list,
	);

	const orderd = useMemo(() => {
		const depositParts = (listStatistics?.deposit?.parts || [])
			.filter(({ status }) => status === 'PAID')
			.map(p => ({
				id: p.tag.id,
				type: 'tag',
				config: {
					name: p.tag.name,
					status: 'PAID',
				},
				total: p.total,
			}));

		const withdrawParts = (listStatistics?.withdraw?.parts || [])
			.filter(({ status }) => status === 'PAID')
			.map(p => ({
				id: p.tag.id,
				type: 'tag',
				config: {
					name: p.tag.name,
					status: 'PAID',
				},
				total: p.total,
			}));

		return depositParts
			.concat(withdrawParts)
			.sort((a, b) => (b?.total || 0) - (a?.total || 0));
	}, [listStatistics]);

	const customers = useMemo(() => {
		const thisYear = new Date().getFullYear();
		const clients = available
			.filter(({ type }) => type === 'client')
			.sort((a, b) => {
				const totalA =
					a?.config?.statistic?.invoices?.perYear?.total?.[
						thisYear
					] || 0;
				const totalB =
					b?.config?.statistic?.invoices?.perYear?.total?.[
						thisYear
					] || 0;

				return Math.abs(totalB) - Math.abs(totalA);
			})
			.slice(0, 2);

		return clients;
	}, [available]);

	const unpaid = useMemo(() => {
		const parts = [
			...(listStatistics?.deposit?.parts || []),
			...(listStatistics?.withdraw?.parts || []),
		];

		return parts
			.filter(({ status }) => status === 'UNPAID')
			.reduce((acc, { count }) => acc + count, 0);
	}, [listStatistics]);

	const overdue = useMemo(() => {
		const parts = [
			...(listStatistics?.deposit?.parts || []),
			...(listStatistics?.withdraw?.parts || []),
		];

		return parts
			.filter(({ status }) => status === 'OVERDUE')
			.reduce((acc, { count }) => acc + count, 0);
	}, [listStatistics]);

	const [currency = false] = useMemo(() => {
		if (!currencies || currencies.length < 2) {
			return [];
		}

		return currencies.sort(
			(
				{
					exposure: {
						display: { total: a, currency: currencyA },
					},
				},
				{
					exposure: {
						display: { total: b, currency: currencyB },
					},
				},
			) => {
				if (b === 0 && a === 0) {
					return (currencyA || '').localeCompare(currencyB);
				}

				return b - a;
			},
		);
	}, [currencies]);

	return (
		<SearchExamples
			tagAction={tagAction}
			orderd={orderd}
			customers={customers}
			dispatch={dispatch}
			filters={filters}
			currency={currency}
			currencies={currencies}
			unpaid={unpaid}
			overdue={overdue}
		/>
	);
};

SearchExamplesData.displayName = 'SearchExamplesData';

export default SearchExamplesData;
