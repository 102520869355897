import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Button from '@asteria/component-button/button';
import { TranslationService } from '@asteria/services-language';
import { Getter, compileStyles } from '@asteria/utils';
/**
 * Create Grid component view
 */
export const Grid = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	position: fixed;
	top: 80px;
	z-index: 9;
	left: 0px;

	@media (max-width: 990px) {
		top: 50px;
	}
`;

/**
 * Create Column component view
 */
export const Col = styled.div`
	order: ${props => (props.order ? props.order : '1')};
	margin: ${props => (props.margin ? props.margin : '0')};
	width: ${props => (props.width ? props.width : 'auto')};
	align-items: flex-start;
	${props => (props.justify ? `justify-content: ${props.justify}` : '')};
`;

/**
 * Create Row component view
 */
export const Row = styled.div`
	padding: 10px 0;
	flex-wrap: wrap;
	flex-direction: ${props =>
		props.direction === 'column' ? 'column' : 'row'};
`;

const ScrollToGraph = styled(({ className }) => {
	const [showScrollToGraph, setScrollToGraph] = useState(false);

	const scrollDetector = () => {
		const currentScrollTop =
			window.pageYOffset || document.documentElement.scrollTop;

		if (currentScrollTop > 700) {
			setScrollToGraph(true);
		} else {
			setScrollToGraph(false);
		}
	};

	useEffect(() => {
		document.addEventListener('scroll', scrollDetector);
		return () => {
			document.removeEventListener('scroll', scrollDetector);
		};
	}, []);

	const handler = useCallback(
		() => window.scrollTo({ top: 0, behavior: 'smooth' }),
		[],
	);

	if (!showScrollToGraph) {
		return null;
	}

	return (
		<div className={className}>
			<Button
				type="link"
				icon="up"
				text={TranslationService.get('graph.show_me_graph')}
				onClick={handler}
			/>
		</div>
	);
})`
	display: flex;
	position: fixed;
	top: 80px;
	justify-content: center;
	/*width: 100%;*/
	left: 50%;
	z-index: 3;

	@media (max-width: 990px) {
		top: 80px;
		left: 36%;
	}

	${({ theme }) => compileStyles(ScrollToGraph, theme)}
`;

ScrollToGraph.Styler = {
	base: [Getter('scrolltop')],
	children: [
		{
			component: Button,
			base: [Getter('button')],
		},
	],
};

export default ScrollToGraph;
