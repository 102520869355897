import React, { useContext, useMemo } from 'react';
// import styled from 'styled-components';
import { Title, Text } from '@asteria/component-core/typography';
import ButtonV2 from '@asteria/component-core/button';
import Tags from '@asteria/component-tags';
import Icon from '@asteria/component-core/icon';
import { useStore } from '@asteria/utils';
import { TranslationService } from '@asteria/services-language';
import Layout from '../../layout';
import Context from '../../context';
import Steps from '../../static/steps';

const Splash = ({ navigator }) => {
	const { setBankSoftwareType, setERPSoftwareType } = useContext(Context);

	const colors = {
		connected: {
			color: '#74E39B',
		},
		disconnected: {
			color: '#E3CE57',
		},
		notConnected: {
			color: '#C5C5C5',
		},
	};

	const [integrations = []] = useStore(
		'store-integrations',
		({ integrations: list = [] }) => list,
	);

	const bankConnectionStatus = useMemo(() => {
		const banks = integrations.filter(({ type }) => type === 'bank');
		if (banks.length === 0) {
			return {
				notConnected: false,
			};
		}

		return {
			connected: banks.filter(({ config }) => config.connected).length,
			disconnected: banks.filter(({ config }) => !config.connected)
				.length,
		};
	}, [integrations]);

	const softwareConnectionStatus = useMemo(() => {
		const erps = integrations.filter(({ type }) => type === 'erp');
		if (erps.length === 0) {
			return {
				notConnected: false,
			};
		}

		return {
			connected: erps.filter(({ config }) => config.connected).length,
			disconnected: erps.filter(({ config }) => !config.connected).length,
		};
	}, [integrations]);

	const bankTags = Object.entries(bankConnectionStatus).map(
		([key, count]) => ({
			title: TranslationService.get(
				[
					`onboarding.splash.status.${key}`,
					`onboarding.splash.bank.status.${key}`,
				],
				'',
				{ count },
			),
			key: `user_tag_${key}`,
			color: colors[key].color,
		}),
	);

	const softwareTags = Object.entries(softwareConnectionStatus).map(
		([key, count]) => ({
			title: TranslationService.get(
				[
					`onboarding.splash.status.${key}`,
					`onboarding.splash.erp.status.${key}`,
				],
				'',
				{ count },
			),
			key: `user_tag_${key}`,
			color: colors[key].color,
		}),
	);

	return (
		<Layout
			header={<Title size="title2">Active Smart Cash Flow</Title>}
			className="asteria-onboarding-splash"
			body={
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Text
						size="medium"
						style={{
							marginBottom: '32px',
							maxWidth: '630px',
							textAlign: 'center',
						}}
					>
						To make full use of Smart Cash Flow, you just have to
						add your business and/or banking software. It should
						take you less than 10 minutes.
					</Text>
					<div style={{ display: 'flex' }}>
						<div>
							<Icon asset="list" />
							<Title>Business Software</Title>
							<Text>
								To activate Smart Cash Flow, connect to your
								Business Software
							</Text>
							<Tags tags={softwareTags} />
							<ButtonV2
								text="Connect"
								size="medium"
								type="primary"
								onClick={() => {
									setERPSoftwareType();
									navigator.next(
										Steps.SelectProvider,
										navigator,
									);
								}}
							/>
						</div>
						<div>
							<Icon asset="list" />
							<Title>Bank</Title>
							<Text>
								To activate Smart Cash Flow, connect to your
								Bank
							</Text>
							<Tags tags={bankTags} />
							<ButtonV2
								text="Connect"
								size="medium"
								type="primary"
								onClick={() => {
									setBankSoftwareType();
									navigator.next(
										Steps.SelectProvider,
										navigator,
									);
								}}
							/>
						</div>
					</div>
				</div>
			}
			footer={null}
		/>
	);
};

Splash.navigationKey = Steps.Splash;

export default Splash;
