import mergeWith from 'lodash/mergeWith';
import DefaultTheme from './default';

const arraySkipper = (objValue, srcValue) => {
	if (Array.isArray(objValue) || Array.isArray(srcValue)) {
		return srcValue;
	}

	return undefined;
};

class ThemesService {
	constructor(themeHandler) {
		this.loaded = false;
		this.themes = [DefaultTheme];
		this.themeHandler = themeHandler;
	}

	async load() {
		const response = await this.themeHandler.getAll();
		if (response.errors && response.errors.length > 0) {
			return;
		}

		const { data: { themes = [] } = {} } = response;
		this.themes = [DefaultTheme, ...themes];
		this.loaded = true;
	}

	async get(id) {
		if (this.loaded === false) {
			await this.load();
		}

		return this.resolve(id);
	}

	async add(themeData) {
		const {
			data: { createTheme },
		} = await this.themeHandler.create({ ...themeData });

		if (createTheme.ok) {
			const { data: theme } = createTheme;
			this.themes.push({ ...theme, styles: {} });
		} else {
			// console.error(createTheme.error);
		}
	}

	async update({ id, params }) {
		const {
			data: { updateTheme },
		} = await this.themeHandler.update({ id, params });

		if (updateTheme.ok) {
			const index = this.themes.findIndex(e => e.id === id);
			this.themes[index] = { ...updateTheme.theme };
		} else {
			// console.error(updateTheme.error);
		}
	}

	resolve(id) {
		let childTheme = this.themes.find(theme => theme.id === id);

		if (!childTheme) {
			return {};
		}

		const savedThemes = JSON.parse(localStorage.owlThemes || '{}');

		if (savedThemes[id]) {
			/*
			childTheme = lodash.mergeWith(
				childTheme,
				savedThemes[id],
				arraySkipper,
			);
			*/
			childTheme = savedThemes[id];
		}

		const { inherits = [] } = childTheme;
		const themesToApply = [
			...inherits.map(parent => this.resolve(parent)),
			childTheme,
		].map(theme => ({
			...theme,
			styles: theme.styles || {},
		}));

		return themesToApply.reduce(
			(acc, theme) => mergeWith(acc, theme, arraySkipper),
			{},
		);
	}
}

export default ThemesService;
