import Store from '../../core/store';
import logger from '../../logger';

@logger('features:store')
class FeaturesStore extends Store {
	constructor(subject) {
		super(subject, { loaded: false, active: [], available: [] });
	}

	name = 'features';
}

export default FeaturesStore;
