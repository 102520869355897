/* eslint-disable no-unused-vars */
import { map } from 'rxjs/operators';
import { isFuture, isBefore, addMonths } from 'date-fns';
import { Service as FeatureService } from '@asteria/component-featureflag';

import { GET_TRANSACTIONS, GET_TRANSACTIONS_STATS } from './list';
import {
	setListItems,
	setListStatistics,
	setListCursor,
	addListItems,
} from '../../services/list/actions';

import { GET_USER } from './auth';

import {
	GET_NUMBER_OF_OVERDUE,
	GET_NUMBER_OF_OPEN,
	FETCH_CLIENTS,
} from './appstate';
import {
	setNumberOfOverdue,
	setNumberOfOpen,
} from '../../services/appstate/actions';

import {
	FETCH_ACCOUNTS,
	UPDATE_BANK_ACCOUNT,
	UPDATE_BANK_ACCOUNTS,
} from './bankaccounts';
import { setBankAccounts } from '../../services/bankaccounts/actions';

import {
	GET_SUMMARY,
	GET_CATEGORIES,
	QUERY_CASHFLOW,
	QUERY_CASHFLOWS,
} from './graph';
import {
	setBarGroup,
	updateGraph,
	setAvailableCategories,
	updateGraphGroup,
} from '../../services/graph/actions';

import { GET_INTEGRATIONS, REMOVE_INTEGRATION } from './integrations';
import { fetchIntegrations } from '../../services/integrations/actions';

const source = ({ action, payload }, { dispatch, lookup }) => {
	const apiService = lookup('service-api');

	switch (action) {
		case 'REQUEST_DATA_LIST': {
			const hasListDivider = FeatureService.isActive('list-divider');
			const { reverse } = payload;
			const pageQuery = {};

			if (hasListDivider) {
				if (reverse) {
					pageQuery.afterCursor = payload.cursor;
					pageQuery.endDate = undefined;
					pageQuery.first = 50;
				} else {
					pageQuery.beforeCursor = payload.cursor;
					pageQuery.startDate = undefined;
					pageQuery.last = 50;
				}
			} else {
				pageQuery.first = 0;
			}

			apiService
				.query(
					GET_TRANSACTIONS,
					{
						...payload,
						...pageQuery,
					},
					{ reqAuth: true },
				)
				.pipe(map(({ data }) => data?.transactions || {}))
				.subscribe(data => {
					dispatch(
						setListItems(
							data?.edges?.map(({ node }) => node) || [],
						),
					);
					if (reverse) {
						dispatch(
							setListCursor(
								data?.pageInfo?.hasNextPage
									? data?.pageInfo?.endCursor
									: null,
							),
						);
					} else {
						dispatch(
							setListCursor(
								data?.pageInfo?.hasPreviousPage
									? data?.pageInfo?.startCursor
									: null,
							),
						);
					}
				});
			break;
		}
		case 'REQUEST_MORE_DATA_LIST': {
			const hasListDivider = FeatureService.isActive('list-divider');
			const { reverse } = payload;
			const pageQuery = {};

			if (hasListDivider) {
				if (reverse) {
					pageQuery.afterCursor = payload.cursor;
					pageQuery.endDate = undefined;
					pageQuery.first = 50;
				} else {
					pageQuery.beforeCursor = payload.cursor;
					pageQuery.startDate = undefined;
					pageQuery.last = 50;
				}
			} else {
				pageQuery.first = 0;
			}

			apiService
				.query(
					GET_TRANSACTIONS,
					{ ...payload, ...pageQuery },
					{ reqAuth: true },
				)
				.pipe(map(({ data }) => data?.transactions || {}))
				.subscribe(data => {
					dispatch(
						addListItems(
							data?.edges?.map(({ node }) => node) || [],
						),
					);
					if (reverse) {
						dispatch(
							setListCursor(
								data?.pageInfo?.hasNextPage
									? data?.pageInfo?.endCursor
									: null,
							),
						);
					} else {
						dispatch(
							setListCursor(
								data?.pageInfo?.hasPreviousPage
									? data?.pageInfo?.startCursor
									: null,
							),
						);
					}
				});
			break;
		}
		case 'REQUEST_DATA_LIST_STATISTICS': {
			const { request, path } = payload;
			apiService
				.query(GET_TRANSACTIONS_STATS, request, { reqAuth: true })
				.pipe(map(({ data }) => data?.transactionStatistic || {}))
				.subscribe(data => {
					dispatch(setListStatistics(data, path));
				});
			break;
		}
		case 'REQUEST_DATA_NUMBER_OF_OVERDUE': {
			apiService
				.query(GET_NUMBER_OF_OVERDUE, {}, { reqAuth: true })
				.pipe(
					map(({ data }) => ({
						total: data?.total?.pageInfo?.count,
						deposit: data?.deposit?.pageInfo?.count,
						withdraw: data?.withdraw?.pageInfo?.count,
					})),
				)
				.subscribe(({ total = 0, deposit = 0, withdraw = 0 }) => {
					dispatch(setNumberOfOverdue(total, deposit, withdraw));
				});
			break;
		}
		case 'REQUEST_DATA_NUMBER_OF_OPEN': {
			apiService
				.query(GET_NUMBER_OF_OPEN, {}, { reqAuth: true })
				.pipe(
					map(({ data }) => ({
						total: data?.total?.pageInfo?.count,
						deposit: data?.deposit?.pageInfo?.count,
						withdraw: data?.withdraw?.pageInfo?.count,
					})),
				)
				.subscribe(({ total = 0, deposit = 0, withdraw = 0 }) => {
					dispatch(setNumberOfOpen(total, deposit, withdraw));
				});
			break;
		}
		case 'REQUEST_DATA_BANK_ACCOUNTS': {
			apiService
				.query(FETCH_ACCOUNTS, {}, { reqAuth: true })
				.pipe(map(({ data }) => data?.bankAccounts?.edges || []))
				.subscribe(data => {
					dispatch(setBankAccounts(data.map(el => el.node)));
				});
			break;
		}
		case 'REQUEST_STORE_BANK_ACCOUNT': {
			apiService
				.query(UPDATE_BANK_ACCOUNT, payload, {
					reqAuth: true,
				})
				.subscribe(() => {});

			break;
		}
		case 'REQUEST_CLIENTS': {
			apiService
				.query(FETCH_CLIENTS, {}, { reqAuth: true })
				.pipe(map(resp => resp?.data?.clients?.edges || []))
				.subscribe(data => {
					dispatch(() => ({
						action: 'SET_CLIENTS',
						payload: data.map(d => d.node),
					}));
				});
			break;
		}
		case 'REQUEST_STORE_BANK_ACCOUNTS': {
			apiService
				.query(UPDATE_BANK_ACCOUNTS, payload, {
					reqAuth: true,
				})
				.subscribe(() => {
					apiService
						.query(FETCH_ACCOUNTS, {}, { reqAuth: true })
						.pipe(
							map(({ data }) => data?.bankAccounts?.edges || []),
						)
						.subscribe(data => {
							dispatch(setBankAccounts(data.map(el => el.node)));
						});
				});

			break;
		}
		case 'QUERY_CASHFLOW': {
			const { graphId, ...requestData } = payload;
			const { startDate } = requestData;

			const buildBar = (data, barType, types = []) => {
				const pieces =
					data?.filter(
						({ type }) => type === barType.toUpperCase(),
					) || [];

				return {
					value: pieces
						.filter(({ badge }) => !badge)
						.reduce(
							(
								acc,
								{
									sums: {
										original: { total },
									},
								},
							) => acc + total,
							0,
						),
					types: [barType.toLowerCase(), ...types],
					badges: pieces
						.filter(({ type, badge }) => badge)
						.map(
							({
								status,
								type,
								count,
								probability,
								sums: {
									original: { total },
								},
								max,
								min,
								chip: {
									config: { name, tagType = name },
								},
								chip,
							}) => ({
								value: total,
								types: [
									type.toLowerCase(),
									tagType.replace('$', ''),
									status.toLowerCase(),
								],
								parts: [],
								chip,
								count,
								data: {
									type: type.toLowerCase(),
									status,
									probability: probability || 1,
									max: max?.original?.total,
									min: min?.original?.total,
								},
							}),
						),
					parts: pieces
						.filter(({ type, badge }) => !badge)
						.map(
							({
								status,
								type,
								probability,
								sums: {
									original: { total },
								},
								max,
								min,
								chip: {
									config: { name, tagType = name },
								},
								chip,
							}) => ({
								value: total,
								types: [
									type.toLowerCase(),
									tagType.replace('$', ''),
									status.toLowerCase(),
								],
								parts: [],
								chip,
								data: {
									type: type.toLowerCase(),
									status,
									probability: probability || 1,
									max: max?.original?.total,
									min: min?.original?.total,
								},
							}),
						),
					data: {
						type: barType.toLowerCase(),
						probability: 1,
					},
				};
			};

			apiService
				.query(QUERY_CASHFLOW, requestData, { reqAuth: true })
				.pipe(map(({ data }) => data?.cashflow || []))
				.subscribe(data => {
					const deposit = buildBar(
						data.filter(({ status }) => status !== 'BACKGROUND'),
						'deposit',
					);
					const withdraw = buildBar(
						data.filter(({ status }) => status !== 'BACKGROUND'),
						'withdraw',
					);

					dispatch(
						updateGraphGroup(graphId, {
							id: startDate,
							lines: data
								.filter(
									({ type }) =>
										type === 'ACCOUNT' || type === 'CREDIT',
								)
								.map(
									({
										sums: {
											original: { total },
										},
										max,
										min,
										status,
										probability,
										type,
									}) => ({
										value: total,
										max: max?.original?.total || total,
										min: min?.original?.total || total,
										probability,
										types: [
											type.toLowerCase(),
											status === 'FORECAST'
												? 'forecast'
												: 'history',
											...(type === 'CREDIT'
												? ['sharp']
												: []),
										],
									}),
								),
							bars: [
								buildBar(
									data.filter(
										({ status }) => status === 'BACKGROUND',
									),
									'deposit',
									['background'],
								),
								deposit,
								buildBar(
									data.filter(
										({ status }) => status === 'BACKGROUND',
									),
									'withdraw',
									['background'],
								),
								withdraw,
							],
						}),
					);

					dispatch(() => ({
						action: 'FETCHED_GRAPH_DATA',
						graphId,
					}));
				});

			break;
		}
		case 'QUERY_CASHFLOWS': {
			const { graphId, ...requestData } = payload;
			const { dates = [] } = requestData;

			const buildBar = (data, barType, types = []) => {
				const pieces =
					data?.filter(
						({ type }) => type === barType.toUpperCase(),
					) || [];

				return {
					value: pieces
						.filter(({ badge }) => !badge)
						.reduce(
							(
								acc,
								{
									sums: {
										original: { total },
									},
								},
							) => acc + total,
							0,
						),
					types: [barType.toLowerCase(), ...types],
					badges: pieces
						.filter(({ type, badge }) => badge)
						.map(
							({
								status,
								type,
								count,
								probability,
								sums: {
									original: { total },
								},
								max,
								min,
								chip: {
									config: { name, tagType = name },
								},
								chip,
								info,
							}) => ({
								value: total,
								types: [
									type.toLowerCase(),
									tagType.replace('$', ''),
									status.toLowerCase(),
								],
								parts: [],
								chip,
								count,
								data: {
									type: type.toLowerCase(),
									status,
									probability: probability || 1,
									max: max?.original?.total,
									min: min?.original?.total,
								},
								info: info || [],
							}),
						),
					parts: pieces
						.filter(({ type, badge }) => !badge)
						.map(
							({
								status,
								type,
								probability,
								sums: {
									original: { total },
								},
								max,
								min,
								chip: {
									config: { name, tagType = name },
								},
								chip,
								info,
							}) => ({
								value: total,
								types: [
									type.toLowerCase(),
									tagType.replace('$', ''),
									status.toLowerCase(),
								],
								parts: [],
								chip,
								data: {
									type: type.toLowerCase(),
									status,
									probability: probability || 1,
									max: max?.original?.total,
									min: min?.original?.total,
								},
								info: info || [],
							}),
						),
					data: {
						type: barType.toLowerCase(),
						probability: 1,
					},
				};
			};

			apiService
				.query(QUERY_CASHFLOWS, requestData, { reqAuth: true })
				.pipe(map(({ data }) => data?.cashflows || []))
				.subscribe(entries => {
					entries.forEach((data, index) => {
						const { startDate } = dates[index];
						const deposit = buildBar(
							data.filter(
								({ status }) => status !== 'BACKGROUND',
							),
							'deposit',
						);
						const withdraw = buildBar(
							data.filter(
								({ status }) => status !== 'BACKGROUND',
							),
							'withdraw',
						);

						dispatch(
							updateGraphGroup(graphId, {
								id: startDate,
								lines: data
									.filter(
										({ type }) =>
											type === 'ACCOUNT' ||
											type === 'CREDIT',
									)
									.map(
										({
											sums: {
												original: { total },
											},
											max,
											min,
											status,
											probability,
											type,
											info,
										}) => ({
											value: total,
											max: max?.original?.total || total,
											min: min?.original?.total || total,
											probability,
											types: [
												type.toLowerCase(),
												status === 'FORECAST'
													? 'forecast'
													: 'history',
												...(type === 'CREDIT'
													? ['sharp']
													: []),
											],
											info: info || [],
										}),
									),
								bars: [
									buildBar(
										data.filter(
											({ status }) =>
												status === 'BACKGROUND',
										),
										'deposit',
										['background'],
									),
									deposit,
									buildBar(
										data.filter(
											({ status }) =>
												status === 'BACKGROUND',
										),
										'withdraw',
										['background'],
									),
									withdraw,
								],
							}),
						);

						dispatch(() => ({
							action: 'FETCHED_GRAPH_DATA',
							graphId,
						}));
					});
				});

			break;
		}
		case 'REQUEST_DATA_GRAPH': {
			const { graphId, ...requestData } = payload;

			dispatch(() => ({
				action: 'FETCHING_GRAPH_DATA',
				graphId,
			}));

			const buildType = (types, categories, tags) => {
				let availableTypes = types.filter(
					({ type, status }) =>
						['DEPOSIT', 'WITHDRAW'].includes(type) &&
						(status === 'PAID' || status === 'FORECAST'),
				);

				if (!availableTypes.find(({ type }) => type === 'DEPOSIT')) {
					availableTypes = availableTypes.concat(
						types
							.filter(
								({ type, status }) =>
									['DEPOSIT'].includes(type) &&
									status === 'OVERDUE',
							)
							.map(t => ({
								...t,
								status: 'PAID',
								sums: { original: { total: 0 } },
							})),
					);
				}

				if (!availableTypes.find(({ type }) => type === 'WITHDRAW')) {
					availableTypes = availableTypes.concat(
						types
							.filter(
								({ type, status }) =>
									['WITHDRAW'].includes(type) &&
									status === 'OVERDUE',
							)
							.map(t => ({
								...t,
								status: 'PAID',
								sums: { original: { total: 0 } },
							})),
					);
				}

				const result = availableTypes
					.sort(
						({ status: aStatus }, { status: bStatus }) =>
							['PAID', 'UNPAID', 'FORECAST'].indexOf(bStatus) -
							['PAID', 'UNPAID', 'FORECAST'].indexOf(aStatus),
					)
					.map(
						({
							type,
							status,
							sums: { original: { total = 0 } = {} } = {},
							max,
							min,
							probability,
						}) => ({
							value:
								type.toLowerCase() === 'deposit'
									? total
									: -total,
							types: [
								type.toLowerCase(),
								status === 'FORECAST' ? 'forecast' : 'history',
							],
							data: {
								type,
								status,
								probability: probability || 1,
								max:
									max?.original?.total ||
									type.toLowerCase() === 'deposit'
										? total
										: -total,
								min:
									min?.original?.total ||
									type.toLowerCase() === 'deposit'
										? total
										: -total,
							},
							badges: types
								.filter(
									({
										status: badgeStatus,
										type: badgeType,
									}) =>
										badgeStatus === 'OVERDUE' &&
										type === badgeType &&
										status !== 'FORECAST',
								)
								.map(
									({
										count,
										sums: {
											original: {
												total: badgeTotal = 0,
											} = {},
										} = {},
									}) => ({
										types: [type.toLowerCase(), 'overdue'],
										value: badgeTotal,
										count,
									}),
								),
							parts: categories
								.filter(
									({
										type: categoryType,
										status: categoryStatus,
									}) =>
										categoryType === type &&
										(status === categoryStatus ||
											(status !== 'FORECAST' &&
												categoryStatus === 'UNPAID')),
								)
								.sort(
									(
										{ status: aStatus },
										{ status: bStatus },
									) =>
										['PAID', 'UNPAID', 'FORECAST'].indexOf(
											aStatus,
										) -
										['PAID', 'UNPAID', 'FORECAST'].indexOf(
											bStatus,
										),
								)
								.map(
									({
										status: categoryStatus,
										category: {
											id,
											name: categoryName,
											tags: categoryTags = [],
										} = {},
										sums: {
											original: {
												total: categoryTotal = 0,
											} = {},
										} = {},
										max: categoryMax,
										min: categoryMin,
										probability: categoryProbability,
									}) => ({
										value:
											type.toLowerCase() === 'deposit'
												? categoryTotal
												: -categoryTotal,
										data: {
											category: {
												id,
												name: categoryName,
												tags: categoryTags,
											},
											type,
											probability:
												categoryProbability || 1,
											max:
												categoryMax?.original?.total ||
												type.toLowerCase() === 'deposit'
													? total
													: -total,
											min:
												categoryMin?.original?.total ||
												type.toLowerCase() === 'deposit'
													? total
													: -total,
										},
										types: [
											type.toLowerCase(),
											`category-${id}`,
											(
												categoryStatus || 'paid'
											).toLowerCase(),
										],
										parts: tags
											.filter(
												({
													type: tagType,
													status: tagStatus,
													tag: { id: tagId } = {},
												}) =>
													tagType === type &&
													tagStatus ===
														categoryStatus &&
													categoryTags.find(
														t => t.id === tagId,
													),
											)
											.map(
												({
													tag: {
														id: tagId,
														name: tagName,
													} = {},
													sums: {
														original: {
															total: tagTotal = 0,
														} = {},
													} = {},
													max: tagMax,
													min: tagMin,
													probability: tagProbability,
													status: tagStatus,
												}) => ({
													value:
														type.toLowerCase() ===
														'deposit'
															? tagTotal
															: -tagTotal,
													data: {
														category: {
															id,
															name: categoryName,
															tags: categoryTags,
														},
														tag: {
															id: tagId,
															name: tagName,
														},
														type,
														probability:
															tagProbability || 1,
														max:
															tagMax?.original
																?.total ||
															type.toLowerCase() ===
																'deposit'
																? total
																: -total,
														min:
															tagMin?.original
																?.total ||
															type.toLowerCase() ===
																'deposit'
																? total
																: -total,
													},
													types: [
														type.toLowerCase(),
														`tag-${tagId}`,
														(
															tagStatus || 'paid'
														).toLowerCase(),
													],
												}),
											)
											.sort((a, b) => {
												if (
													a.types.includes(
														'forecast',
													) &&
													!b.types.includes(
														'forecast',
													)
												) {
													return 1;
												}

												if (
													!a.types.includes(
														'forecast',
													) &&
													b.types.includes('forecast')
												) {
													return -1;
												}

												return a.value - b.value;
											}),
									}),
								),
						}),
					);

				return result;
			};

			apiService
				.query(GET_SUMMARY, requestData, { reqAuth: true })
				// .pipe(mergeMap(({ data }) => from(data?.transactionsSummary || [])))
				.pipe(map(({ data }) => data?.transactionsSummary || []))
				.subscribe({
					next: data =>
						dispatch(
							setBarGroup(
								graphId,
								data.map(
									({
										date,
										types = [],
										categories = [],
										tags = [],
										clients = [],
									}) => ({
										id: date,
										lines: types
											.filter(
												({ type }) =>
													type === 'ACCOUNT',
											)
											.map(
												({
													sums: {
														original: {
															total = 0,
														} = {},
													} = {},
													probability,
													status,
													max,
													min,
												}) => ({
													value: total,
													max:
														max?.original?.total ||
														total,
													min:
														min?.original?.total ||
														total,
													probability,
													types: [
														'account',
														isFuture(
															new Date(date),
														) ||
														status === 'FORECAST'
															? 'forecast'
															: 'history',
													],
												}),
											),
										bars: buildType(
											types,
											categories,
											tags,
										),
										clients,
									}),
								),
							),
						),
					complete: () => {
						dispatch(updateGraph(graphId));
						dispatch(() => ({
							action: 'FETCHED_GRAPH_DATA',
							graphId,
						}));
					},
				});

			break;
		}
		case 'REQUEST_DATA_GRAPH_CATEGORIES': {
			apiService
				.query(GET_CATEGORIES, {}, { reqAuth: true })
				.subscribe(({ data }) => {
					const categories = data?.categories || [];
					const mapped = categories.map(c => ({
						...c,
						tags: c.tags.map(t => ({
							...t,
							category: c,
						})),
					}));

					dispatch(setAvailableCategories(mapped));
				});
			break;
		}
		case 'REQUEST_DATA_INTEGRATIONS': {
			apiService
				.query(GET_INTEGRATIONS)
				.pipe(map(({ data }) => data?.integrations || []))
				.subscribe({
					next: data =>
						dispatch(() => ({
							action: 'SET_INTEGRATIONS',
							payload: data,
						})),
				});
			break;
		}
		case 'REQUEST_REMOVE_INTEGRATION': {
			apiService.query(REMOVE_INTEGRATION, payload).subscribe(
				() => {
					dispatch(fetchIntegrations());
				},
				e =>
					dispatch(() => ({
						action: 'NETWORK_ERROR',
						payload: { error: e },
					})),
			);
			break;
		}
		case 'REQUEST_DATA_USER': {
			apiService
				.query(GET_USER)
				.pipe(
					map(({ data: { me = {}, company = {} } = {} } = {}) => ({
						user: me,
						company,
					})),
				)
				.subscribe(
					({ user, company }) => {
						dispatch(() => ({
							action: 'SET_USER',
							payload: user || {},
						}));

						dispatch(() => ({
							action: 'SET_DISPLAY_CURRENCY',
							payload: company?.settings?.currency || 'SEK',
						}));
					},
					e =>
						dispatch(() => ({
							action: 'FETCH_USER_FAILED',
							payload: { error: e },
						})),
				);
			break;
		}
		default:
			break;
	}
};

export default source;
