export const ASTERIA_TRANSACTION_URI =
	'https://stage.api.asteria.ai/api/v1/graphql';

export const ASTERIA_AUTH_URI = 'https://stage.api.asteria.ai/api/v3/';

export const ASTERIA_TRANSACTION_URI2 = 'https://stage.api.asteria.ai';

export const ASTERIA_GATEWAY_URI = 'https://stage.api.asteria.ai/api/v3/';

export const GLOBAL = {
	baseUrl: 'https://stage.api.asteria.ai/',
};
