export default {
	'menu.financial.title': 'Finance',
	'menu.financial.factoring.title': 'Factoring',
	'menu.financial.currencyrisk.title': 'Currency risk',
	'menu.categories.title': 'Categories',
	'menu.clients.title': 'Clients',
	'menu.status.title': 'Status',
	'menu.currency.title': 'Currencies',
	'company.overview.title': 'Currencies',
	'graph.tab.accountbalance': 'Account Balance',
	'currencies.account': 'Account',
	'request.help.action': 'Get help with currency risk',
	'currencies.currency': 'Currency',
	'drawer.currency.notification':
		'Your business may be affected by currency fluctuations. Click here for an overview of your currencies.',
	'graph.tab.revenueexpenses': 'Deposits / withdrawals',
	'factoring.first.title': 'How does factoring work',
	'factoring.first.description':
		'Factoring means that you sell or mortgage your invoices to us so you can free tied up money as the bank handles your account receivables. It is both simple and cost effective!',
	'factoring.first.steps.title': 'This is how it works',
	'factoring.first.steps.description':
		'1. Select the invoices you want to handle for invoice purchases, see the immidiate effects on your Cash Flow in the graphs</br></br>2. 2. Review your selected invoices and amount</br></br>3. Send invoices to factoring',
	'button.abort': 'Cancel',
	'button.start': 'Start',
	'factoring.title': 'Factoring',
	'factoring.sent.title': 'Factoring',
	'factoring.list.header': 'To be sent to factoring',
	'factoring.list.empty.title': 'This is how it works',
	'factoring.list.empty.text':
		'Tick the box to the left of the client name to see how your cash flow changes if you factor invoices. Approve before sending for factoring.',
	'factoring.item.cost': 'Cost',
	'factoring.item.amount': 'Amount',
	'factoring.item.status': 'Status',
	'factoring.total.amount.total': 'Total',
	'factoring.sent.content.title2': '{{count}} invoices',
	'factoring.sent.content.title1': 'Cost {{cost | round}} {{currency}}',
	'cashflow.title': 'Smart Cash Flow',
	'graph.cashflow.show_account': 'Show my account balance',
	'graph.cashflow.hide_account': 'Hide my account balance',
	'button.factoring': 'Factoring ({{count}})',
	'list.client.total.transactions': '{{count}} transactions',
	'drawer.finance.factoring.list.title': 'Factoring',
	'drawer.finance.factoring.list.processed': 'Historical invoice purchases',
	'client.factoring.box': 'Get paid directly with factoring',
	'factoring.sent.content.title3':
		'We will handle your request for factoring. You can follow up the case by navigating to the top left.',
	'factoring.sent.alert.title': 'Thanks!',
	'button.done': 'Done',
	'signup.lastname.label': 'Surname',
	'signup.lastname.placeholder': 'Surname',
	'': '{{item.transactions.length}} fakturor',
	'factoring.footer.count': '{{count}} invoices',
	'factoring.total.cost': 'Cost',
	'factoring.item.category': 'Category',
	'factoring.item.paymentDate': 'Payment date',
	'factoring.item.number': 'Invoices',
	'notification.currency.risk.onboarding.body':
		'We have identified a risk of {{{currency | currencyFlag}}} {{currency}} of {{total | number : false}}',
	'notification.currency.risk.onboarding.action': 'View risk',

	'menu.financial.credit.title': 'Overdraft',

	'financial.service.overdraft.amount.label':
		'Apply for a overdraft with an amount of:',

	'overdraft.modal.confirm.title': 'Overdraft',
	'button.apply': 'Apply',
	'overdraft.sent.alert.title': 'Thanks',
	'overdraft.sent.content.title1': 'Overdraft application',
	'overdraft.sent.content.title3':
		'An advisor will contact you as soon as possible regarding your overdraft application.',
	'button.close': 'Close',
	'financial.service.overdraft.helpTitle': 'How it works',
	'financial.service.overdraft.help':
		'Click and drag the red rectangle that appears in the account balance graph to apply for a overdraft.',
	'credit.tag.name': 'Overdraft',
};
