import Store from '../../core/store';
import logger from '../../logger';

@logger('auth:store')
class AuthStore extends Store {
	name = 'auth';

	constructor(subject) {
		super(subject, {});
	}

	async setAccessToken(accessToken) {
		this.setState(state => ({ ...state, accessToken }));
	}

	getAccessToken() {
		return this.get(({ tokens: { accessToken } = {} }) => accessToken);
	}
}

export default AuthStore;
