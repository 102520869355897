export default {
	securitybar: {
		style: {
			width: '10px',
			height: '15px',
			display: 'flex',
			flexDirection: 'column-reverse',
			marginTop: '-2px',
			marginRight: '8px',
		},
		level: {
			style: {
				marginTop: '1px',
				backgroundColor: 'rgb(235, 231, 226)',
				flex: '1 1 0%',
				borderRadius: '3px',
			},
			states: {
				active: {
					style: {
						backgroundColor: 'rgb(253, 193, 41)',
					},
				},
			},
		},
		types: {
			'5': {
				level: {
					states: {
						active: {
							style: {
								backgroundColor: '#99CC66',
							},
						},
					},
				},
			},
			'4': {
				level: {
					states: {
						active: {
							style: {
								backgroundColor: '#99CC66',
							},
						},
					},
				},
			},
			'3': {
				level: {
					states: {
						active: {
							style: {
								backgroundColor: '#FDC129',
							},
						},
					},
				},
			},
			'2': {
				level: {
					states: {
						active: {
							style: {
								backgroundColor: '#BE121A',
							},
						},
					},
				},
			},
			'1': {
				level: {
					states: {
						active: {
							style: {
								backgroundColor: '#BE121A',
							},
						},
					},
				},
			},
		},
	},
};
