import React, { useCallback, useContext, useEffect, useState } from 'react';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { ModalBody, ModalFooter } from '@asteria/component-modal';
import Button from '@asteria/component-button/button';
import classNames from 'classnames';
import Title from '@asteria/component-typography/title';
import Text from '@asteria/component-typography/text';
import { FeatureFlag } from '@asteria/component-featureflag';
import {
	Dropdown,
	InputV2,
	LabelV2,
	FormGroupV2,
	CheckboxGroupV2,
	SwitchV2,
} from '@asteria/component-form';
// import { useUserSettings } from '@asteria/services-dashboard';
import { TranslationService } from '@asteria/services-language';
import styled from 'styled-components';
import { compileStyles, Getter } from '@asteria/utils';

import { selectLanguage } from '@asteria/services-datalayer/services/language/actions';
import {
	logout,
	saveUser,
} from '@asteria/services-datalayer/services/auth/actions';
import Alert from '@asteria/component-alert';
import { ButtonGroup } from '@asteria/component-button';
import { setColorMode } from '@asteria/services-datalayer/services/appstate/actions';

const LANGUAGES = [
	{
		value: 'sv',
		label: 'Swedish',
	},
	{
		value: 'en',
		label: 'English',
	},
	{
		value: 'fr',
		label: 'French',
	},
	{
		value: 'es',
		label: 'Spanish',
	},
	{
		value: 'pt',
		label: 'Portuguese',
	},
	{
		value: 'de',
		label: 'German',
	},
];

const GET_USER = `
query {
	me {
	  username
	  firstName
	  lastName
	  companies {
		edges {
		  node {
			name
			orgnumber
		  }
		}
	  }
	}
  }
`;

const GET_COMPANY = `
query {
	company {
	  id
	  name
	  orgnumber
	}
  }
`;

const UPDATE_COMPANY = `
mutation updateCurrentCompany($id: ID!, $input: CompanyUpdateInputType!) {
	updateCompany(id: $id, input: $input) {
		ok
		error
	}
}
`;

const DELETE_COMPANY = `
mutation deleteCompany($id: ID!) {
	deleteCompany(id: $id) {
    	ok
  	}
}
`;

const InfoBoxWrapper = styled.div``;

const FormError = styled(({ className, errors, errorKey }) =>
	errors?.[errorKey] ? (
		<Alert
			className={className}
			icon
			type="error"
			showClose={false}
			headingContent={TranslationService.get(errors?.[errorKey].message)}
		/>
	) : null,
)``;

const AccountView = styled(({ className, onClose = () => {} }) => {
	const { dispatch, lookup } = useContext(DatalayerContext);
	const apiService = lookup('service-api');

	const [showCancelAccount, setShowCancelAccount] = useState(false);

	const [errors, setErrors] = useState([]);

	const [, setUser] = useState({});
	const [userForm, setUserForm] = useState({});

	const [company, setCompany] = useState({});
	const [companyForm, setCompanyForm] = useState({});

	const [repeatPassword, setRepeatPassword] = useState('');

	const [language, setLanguage] = useState(
		localStorage.asteriaLanguage || 'sv',
	);

	const [colorMode, setActiveColorMode] = useState(
		localStorage.asteriaColorMode || 'light',
	);

	useEffect(() => {
		apiService.query(GET_COMPANY, {}, { reqAuth: true }).subscribe(data => {
			const { name, orgnumber } = data?.data?.company || {};
			setCompany(data?.data?.company || {});
			setCompanyForm({ name, orgnumber });
		});
	}, [apiService]);

	useEffect(() => {
		apiService.query(GET_USER, {}, { reqAuth: true }).subscribe(data => {
			const { username = '', firstName = '', lastName } =
				data?.data?.me || {};
			setUser(data?.data?.me || {});
			setUserForm({ username, firstName, lastName });
		});
	}, [apiService]);

	const onValidate = useCallback(() => {
		const validationErrors = {};
		const {
			username,
			password,
			newpassword,
			firstName,
			lastName,
		} = userForm;
		const { name, orgnumber } = companyForm;

		if (!name || name.length === 0) {
			validationErrors['company.name'] = {
				code: 1,
				key: 'company.name',
				message: 'form.validation.length',
			};
		}

		if (!orgnumber || orgnumber.length === 0) {
			validationErrors['company.orgnumber'] = {
				code: 1,
				key: 'company.orgnumber',
				message: 'form.validation.length',
			};
		}

		if (!firstName || firstName.length === 0) {
			validationErrors['user.firstName'] = {
				code: 1,
				key: 'user.firstName',
				message: 'form.validation.length',
			};
		}

		if (!lastName || lastName.length === 0) {
			validationErrors['user.lastName'] = {
				code: 1,
				key: 'user.lastName',
				message: 'form.validation.length',
			};
		}

		if (!username || username.length < 0) {
			validationErrors['user.username'] = {
				code: 1,
				key: 'user.username',
				message: 'form.validation.length',
			};
		}

		if (newpassword && newpassword !== repeatPassword) {
			validationErrors['user.repassword'] = {
				code: 1,
				key: 'user.repassword',
				message: 'form.validation.missmatch',
			};
		}

		if (newpassword && newpassword.length < 6) {
			validationErrors['user.newpassword'] = {
				code: 1,
				key: 'user.repassword',
				message: 'form.validation.length',
			};
		}

		if (newpassword && !password) {
			validationErrors['user.password'] = {
				code: 1,
				key: 'user.password',
				message: 'form.validation.missing',
			};
		}

		setErrors(validationErrors);
		return Object.keys(validationErrors).length === 0;
	}, [companyForm, repeatPassword, userForm]);

	const onSave = useCallback(() => {
		if (onValidate()) {
			localStorage.asteriaLanguage = language;
			localStorage.asteriaColorMode = colorMode;
			dispatch(
				saveUser(
					userForm,
					() => {
						onClose();
					},
					() => {
						setErrors({
							'system.password': {
								code: 1,
								key: 'system.password',
								message: 'form.validation.password.incorrect',
							},
						});
					},
				),
			);
			apiService
				.query(
					UPDATE_COMPANY,
					{ id: company.id, input: companyForm },
					{ reqAuth: true },
				)
				.subscribe(() => {}, () => {});
		}
	}, [
		onValidate,
		language,
		colorMode,
		dispatch,
		userForm,
		apiService,
		company.id,
		companyForm,
		onClose,
	]);

	const onCancel = useCallback(() => {
		dispatch(selectLanguage(localStorage.asteriaLanguage));
		dispatch(setColorMode(localStorage.asteriaColorMode));
		onClose();
	}, [dispatch, onClose]);

	const onCancelAccount = useCallback(() => {
		apiService
			.query(DELETE_COMPANY, { id: company.id }, { reqAuth: true })
			.subscribe(
				() => {
					dispatch(logout());
				},
				() => {},
			);
	}, [apiService, company.id, dispatch]);

	const { name = '', orgnumber = '' } = companyForm || {};
	const { username = '', firstName = '', lastName = '' } = userForm;

	return (
		<div className={classNames(className, `asteria-account-details`)}>
			<ModalBody className="modal-body">
				<FormError errors={errors} errorKey="system.password" />
				<FormGroupV2>
					<LabelV2>
						{TranslationService.get('signup.company.label')}
					</LabelV2>
					<InputV2
						placeholder={TranslationService.get(
							'signup.company.placeholder',
							'',
						)}
						value={name}
						onChange={e => {
							setCompanyForm({
								...companyForm,
								name: e.target.value,
							});
						}}
					/>
					<FormError errors={errors} errorKey="company.name" />
				</FormGroupV2>

				<FormGroupV2>
					<LabelV2>
						{TranslationService.get(
							'signup.organisationNumber.label',
						)}
					</LabelV2>
					<InputV2
						placeholder={TranslationService.get(
							'signup.organisationNumber.placeholder',
							'',
						)}
						value={orgnumber}
						onChange={e => {
							setCompanyForm({
								...companyForm,
								orgnumber: e.target.value,
							});
						}}
					/>
					<FormError errors={errors} errorKey="company.orgnumber" />
				</FormGroupV2>

				<FormGroupV2>
					<LabelV2>
						{TranslationService.get('signup.firstname.label')}
					</LabelV2>
					<InputV2
						placeholder={TranslationService.get(
							'signup.firstname.placeholder',
							'',
						)}
						value={firstName}
						onChange={e => {
							setUserForm({
								...userForm,
								firstName: e.target.value,
							});
						}}
					/>
					<FormError errors={errors} errorKey="user.firstName" />
				</FormGroupV2>

				<FormGroupV2>
					<LabelV2>
						{TranslationService.get('signup.lastname.label')}
					</LabelV2>
					<InputV2
						placeholder={TranslationService.get(
							'signup.lastname.placeholder',
							'',
						)}
						value={lastName}
						onChange={e => {
							setUserForm({
								...userForm,
								lastName: e.target.value,
							});
						}}
					/>
					<FormError errors={errors} errorKey="user.lastName" />
				</FormGroupV2>

				<FormGroupV2>
					<LabelV2>
						{TranslationService.get('signup.email.label')}
					</LabelV2>
					<InputV2
						placeholder={TranslationService.get(
							'signup.email.placeholder',
							'',
						)}
						value={username}
						onChange={e =>
							setUserForm({
								...userForm,
								username: e.target.value,
							})
						}
					/>
					<FormError errors={errors} errorKey="user.username" />
				</FormGroupV2>

				<FormGroupV2>
					<LabelV2>
						{TranslationService.get('account.password.label')}
					</LabelV2>
					<InputV2
						placeholder={TranslationService.get(
							'account.password.placeholder',
							'',
						)}
						onChange={e =>
							setUserForm({
								...userForm,
								password: e.target.value,
							})
						}
						type="password"
					/>
					<FormError errors={errors} errorKey="user.password" />
				</FormGroupV2>

				<FormGroupV2>
					<LabelV2>
						{TranslationService.get('account.newpassword.label')}
					</LabelV2>
					<InputV2
						placeholder={TranslationService.get(
							'account.newpassword.placeholder',
							'',
						)}
						onChange={e =>
							setUserForm({
								...userForm,
								newpassword: e.target.value,
							})
						}
						type="password"
					/>
					<FormError errors={errors} errorKey="user.newpassword" />
				</FormGroupV2>

				<FormGroupV2>
					<LabelV2>
						{TranslationService.get('account.rePassword.label')}
					</LabelV2>
					<InputV2
						placeholder={TranslationService.get(
							'account.rePassword.placeholder',
							'',
						)}
						onChange={e => setRepeatPassword(e.target.value)}
						type="password"
					/>
					<FormError errors={errors} errorKey="user.repassword" />
				</FormGroupV2>

				<FormGroupV2>
					<LabelV2>
						{TranslationService.get('signup.language.label')}
					</LabelV2>
					<Dropdown
						iconClosed="triangleDown"
						iconOpen="triangleUp"
						options={LANGUAGES}
						placeholder={TranslationService.get(
							'settings.language.label',
							'Välj språk',
						)}
						itemIcon="check"
						showSelected
						search={false}
						type="default"
						selected={[
							LANGUAGES.find(({ value }) => value === language),
						]}
						onChange={option => {
							setLanguage(option.value);
							dispatch(selectLanguage(option.value));
						}}
					/>
				</FormGroupV2>

				<FeatureFlag feature="dark-switch">
					<FormGroupV2 style={{ marginTop: '24px' }}>
						<LabelV2>
							{TranslationService.get('settings.darkmode.label')}
						</LabelV2>
						<SwitchV2
							active={colorMode === 'dark'}
							label={TranslationService.get(
								'settings.darkmode.dark.text',
							)}
							onChange={() => {
								setActiveColorMode(
									colorMode === 'dark' ? 'light' : 'dark',
								);
								dispatch(
									setColorMode(
										colorMode === 'dark' ? 'light' : 'dark',
									),
								);
							}}
						/>
					</FormGroupV2>
				</FeatureFlag>

				<FeatureFlag feature="cancel-account">
					<Button
						text={TranslationService.get(
							'account.remove.button',
							'',
						)}
						size="regular"
						type="link"
						style={{ marginTop: '32px', paddingLeft: '0' }}
						onClick={() => setShowCancelAccount(true)}
					/>
				</FeatureFlag>
				{showCancelAccount && (
					<InfoBoxWrapper className="asteria-infobox">
						<Text size="body" className="asteria-text-info">
							{TranslationService.get(
								'account.removePrompt',
								'Är du säker på att du vill avsluta ditt konto hos Asteria?',
							)}
						</Text>
						<ButtonGroup>
							<Button
								onClick={() => setShowCancelAccount(false)}
								size="medium"
								text={TranslationService.get(
									'account.removePrompt.reject',
									'Nej',
								)}
								className="asteria-button-reject"
								type="default"
							/>
							<Button
								onClick={onCancelAccount}
								size="medium"
								className="asteria-button-confirm"
								text={TranslationService.get(
									'account.removePrompt.agree',
									'Ja',
								)}
								type="secondary"
							/>
						</ButtonGroup>
					</InfoBoxWrapper>
				)}
				{/* Klicka på remove öppnar dialog-rutan som vi har på ta bort erp, med ja eller nej.. klick på ja redirectar användaren ut från wings osv. */}
			</ModalBody>
			<ModalFooter className="modal-footer">
				<Button
					size="medium"
					type="default"
					text={TranslationService.get(
						'settings.button.cancel',
						'Avbryt',
					)}
					onClick={onCancel}
				/>
				<Button
					size="medium"
					type="primary"
					text={TranslationService.get(
						'settings.button.save',
						'Spara',
					)}
					onClick={onSave}
				/>
			</ModalFooter>
		</div>
	);
})`
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	${({ theme }) => compileStyles(AccountView, theme)}
`;

AccountView.Styler = {
	base: [Getter('settings')],
	children: [
		{
			component: Title,
			base: [Getter('title')],
		},
		{
			component: FormGroupV2,
			base: [Getter('groups')],
			children: [
				{
					component: CheckboxGroupV2,
					base: [Getter('checkbox')],
				},
			],
		},
	],
};

export default AccountView;
