/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const ADD_TRANSACTION = gql`
	mutation AddTransaction(
		$input: TransactionCreateInputType!
		$tags: [TransactionAssignTagInputType]
		$categories: [TransactionAssignCategoryInputType]
	) {
		createTransaction(input: $input, tags: $tags, categories: $categories) {
			ok
			error
			data {
				id
				type
				status
				paymentDate
				sums {
					original {
						total
						currency
					}
				}
				meta {
					categories {
						id
						name
					}
					tags {
						id
						_id
						name
						category {
							id
							_id
							name
						}
					}
				}
				description
				oracle {
					risk
				}

				links {
					id
					type
					details {
						... on Invoice {
							dates {
								invoiceSent
								invoiceDue
								invoicePaid
							}
						}
					}
				}
			}
		}
	}
`;

export const UPDATE_TRANSACTION = gql`
	mutation UpdateTransaction(
		$id: ObjectId!
		$input: TransactionUpdateInputType!
		$tags: [TransactionAssignTagInputType]
		$categories: [TransactionAssignCategoryInputType]
		$recurrent: Boolean
	) {
		updateTransaction(
			id: $id
			input: $input
			tags: $tags
			categories: $categories
			recurrent: $recurrent
		) {
			ok
			error
			data {
				id
				type
				status
				paymentDate
				sums {
					original {
						total
						currency
					}
				}
				meta {
					categories {
						id
						name
					}
					tags {
						id
						_id
						name
						category {
							id
							_id
							name
						}
					}
				}
				description
				oracle {
					risk
				}

				links {
					id
					type
					details {
						... on Invoice {
							dates {
								invoiceSent
								invoiceDue
								invoicePaid
							}
						}
					}
				}
			}
		}
	}
`;

export const UPDATE_TRANSACTIONS = gql`
	mutation UpdateTransactions(
		$input: [TransactionUpdateMultipleInputType!]!
	) {
		updateTransactions(input: $input) {
			ok
			error
			data {
				id
				type
				status
				paymentDate
				sums {
					original {
						total
						currency
					}
				}
				meta {
					categories {
						id
						name
					}
					tags {
						id
						_id
						name
						category {
							id
							_id
							name
						}
					}
				}
				description
				oracle {
					risk
				}

				links {
					id
					type
					details {
						... on Invoice {
							dates {
								invoiceSent
								invoiceDue
								invoicePaid
							}
						}
					}
				}
			}
		}
	}
`;

export const DELETE_TRANSACTION = gql`
	mutation DeleteTransaction($id: ObjectId!, $recurrent: Boolean) {
		deleteTransaction(id: $id, recurrent: $recurrent) {
			ok
			error
		}
	}
`;

export const DELETE_TRANSACTIONS = gql`
	mutation DeleteTransactions($ids: [ObjectId!]) {
		deleteTransactions(ids: $ids) {
			ok
			error
		}
	}
`;

export const ADD_TAG = gql`
	mutation AddTag($id: ObjectId!, $tag: String, $category: String) {
		assignTag(transactionId: $id, tagName: $tag, categoryName: $category) {
			ok
			error
		}
	}
`;
