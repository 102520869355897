import { distinctUntilChanged, map } from 'rxjs/operators';
import logger from '../logger';

@logger('store')
class Store {
	value = {};

	dirty = true;

	constructor(subject, value = {}) {
		this.subject = subject;
		this.init(value);
	}

	init(initValue) {
		this.logger('Creating store with initial data', initValue);
		this.value = initValue;
	}

	setState(stateFn) {
		const newState = stateFn(this.value);
		if (newState !== this.value) {
			this.logger('Updating state: ', this.value, newState);
			this.value = newState;
			this.dirty = true;
		}
	}

	dispatch(store) {
		if (this.dirty) {
			this.dirty = false;
			return {
				...store,
				[this.name]: this.value,
			};
		}

		return store;
	}

	get(key) {
		return this.query(store => store[key]);
	}

	query(query = () => {}, compare) {
		return this.subject.pipe(
			map(({ [this.name]: store = {} } = {}) => store),
			distinctUntilChanged(),
			map(query),
			distinctUntilChanged(compare),
		);
	}
}

export default Store;
