import gql from 'graphql-tag';

export const GET_COMPANY = gql`
	query GetCompany {
		company {
			id
			name

			settings {
				currency
			}
		}
	}
`;

export const GET_USER = gql`
	query GetUser {
		me {
			settings {
				layout
				flags
				showBalance
				showBalanceNumbers
				showIn
				showOut
				hasReadAdjustable
			}
		}
	}
`;

export const UPDATE_USER = gql`
	mutation updateCurrentUser($input: UserUpdateInputType!) {
		updateUser(input: $input) {
			ok
			error
			user {
				settings {
					layout
					flags
					showBalance
					showBalanceNumbers
					showIn
					showOut
					combineGraph
					compressList
					erpReminder
					hasOnboarded
					hasReadAdjustable
					listColumns
				}
			}
		}
	}
`;

export const LOGIN = gql`
	mutation Login($username: String!, $password: String!, $partnerId: ID) {
		login(username: $username, password: $password, partnerId: $partnerId) {
			ok
			error
			data {
				accessToken
				refreshToken
			}
			user {
				companies {
					edges {
						node {
							name
						}
					}
				}
			}
		}
	}
`;

export const ADMIN_LOGIN = gql`
	mutation AdminLogin($username: String!, $password: String!) {
		loginAdmin(username: $username, password: $password) {
			ok
			error
			data {
				accessToken
				refreshToken
			}
			user {
				username
			}
		}
	}
`;
