import React from 'react';
import styled from 'styled-components';
import { compileStyles, StyleGetter } from '@asteria/utils';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Text from './basic/text';

/**
 * Append theming sidebar and attach styler trigger to the Label text element
 */
const LabelText = React.forwardRef(({ children, ...componentProps }, ref) => (
	<Text ref={ref} {...componentProps} className="asteria-label">
		{children}
	</Text>
));

/**
 * Label component
 */
const Label = styled(
	React.forwardRef(
		({ children, className, styler, validation, style }, ref) => (
			<div
				ref={ref}
				style={style}
				className={classNames(
					className,
					'asteria-label-wrapper',
					validation ? `asteria-${validation}` : '',
				)}
			>
				<LabelText path="text" styler={styler}>
					{children}
				</LabelText>
			</div>
		),
	),
)`
	display: flex;
	${({ theme }) => compileStyles(Label.Styler, theme)}
`;

Label.Styler = {
	component: Label,
	base: [StyleGetter('formV2.label', {})],
	children: [
		{
			component: Text,
			base: [StyleGetter(`text`, {})],
		},
	],
};

Label.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	styler: PropTypes.bool,
	validation: PropTypes.oneOf(['error', 'warning', 'success', '']),
	style: PropTypes.object,
};

Label.defaultProps = {
	children: null,
	styler: false,
	validation: '',
	style: {},
};

/**
 * Append theming sidebar and attach styler trigger to Help component
 */
export default Label;
// Style container for the whole Help component
export const LabelWrapper = Label;
