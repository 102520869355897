export const setBankAccounts = accounts => ({ dispatch }) => {
	dispatch({
		action: 'SET_BANK_ACCOUNTS',
		payload: accounts,
	});
};

export const getAccounts = () => ({ dispatch }) => {
	dispatch({
		action: 'BANK_ACCOUNTS_LOADING',
	});

	dispatch({
		action: 'REQUEST_DATA_BANK_ACCOUNTS',
		payload: {},
	});
};

export const updateAccount = ({ active, identifiers: { number } }) => ({
	dispatch,
}) => {
	dispatch({
		action: 'REQUEST_STORE_BANK_ACCOUNT',
		payload: { input: { active, identifiers: { number } } },
	});
};

export const updateAccounts = accounts => ({ dispatch }) => {
	dispatch({
		action: 'REQUEST_STORE_BANK_ACCOUNTS',
		payload: {
			input: accounts.map(({ active, identifiers: { number } }) => ({
				active,
				identifiers: { number },
			})),
		},
	});
};
