import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ModalBody, ModalFooter } from '@asteria/component-modal';
import Button from '@asteria/component-button/button';
import Title from '@asteria/component-typography/title';
import Text from '@asteria/component-typography/text';
import { TranslationService } from '@asteria/services-language';
import { Getter, compileStyles } from '@asteria/utils';
import { GLOBAL } from '@asteria/env';

const Image = styled(({ className, src, fallback }) => {
	const [image, setImage] = useState(src);

	useEffect(() => {
		setImage(src);
	}, [src]);

	return (
		<img
			className={className}
			src={image}
			alt=""
			onError={() => {
				setImage(fallback);
			}}
		/>
	);
})`
	max-width: 100%;
`;

const Step = styled(({ className, step, next, prev, site }) => {
	let numSteps = 0;

	for (let i = 0; i < 99; i += 1) {
		if (TranslationService.get(`guide.step.${i + 1}.title`, '') === '') {
			break;
		}
		numSteps += 1;
	}

	const { title, text, image, fallbackImage } = useMemo(() => {
		const key = `guide.step.${step + 1}`;

		return {
			image: TranslationService.get(
				`${key}.image`,
				`{{{baseUrl}}}assets/${site}/images/guide/step{{step}}.png`,
				{ baseUrl: GLOBAL.baseUrl, step: step + 1 },
			),
			fallbackImage: TranslationService.get(
				`${key}.image`,
				`{{{baseUrl}}}assets/asteria/images/guide/step{{step}}.png`,
				{ baseUrl: GLOBAL.baseUrl, step: step + 1 },
			),
			title: TranslationService.get(`${key}.title`),
			header: TranslationService.get(`${key}.header`),
			text: TranslationService.get(`${key}.text`),
		};
	}, [site, step]);

	return (
		<div className={className}>
			<ModalBody className="modal-body">
				<Title size="title3">{title}</Title>
				<Text size="body">{text}</Text>
				<Image src={image} fallback={fallbackImage} />
				<Text size="small" className="asteria-text-step-counter">
					{`${step + 1}/${numSteps}`}
				</Text>
			</ModalBody>
			<ModalFooter className="modal-footer">
				<Button
					size="medium"
					type="default"
					text={
						step === 0
							? TranslationService.get('button.abort')
							: TranslationService.get('button.back')
					}
					onClick={prev}
				/>
				<Button
					size="medium"
					type="secondary"
					text={
						step === numSteps - 1
							? TranslationService.get('button.end')
							: TranslationService.get('button.next')
					}
					onClick={next}
				/>
			</ModalFooter>
		</div>
	);
})`
	flex-grow: 1;
	${({ theme }) => compileStyles(Step, theme)}
`;

Step.Styler = {
	base: [Getter('guide')],
	children: [
		{
			component: Title,
			base: [Getter('title')],
		},
		{
			component: Text,
			base: [Getter('text')],
		},
		{
			component: Image,
			base: [Getter('image')],
		},
	],
};

export default Step;
