import React, { Component } from 'react';
import styled from 'styled-components';
import Icon from '@asteria/component-icon';

const TriangleElement = styled.div`
	display: block;
	position: absolute;
	width: 16px;
	height: 10px;
	margin: 0 auto;
	top: calc(100% - 1px);
	left: 0;
	right: 0;
	margin-top: -0.1px;
`;

const TRIANGLE_WRAPPER_STYLE = {
	position: 'absolute',
	width: '100%',
	height: '100%',
	top: '0',
	left: '0',
};
const WHITE_STYLES = {
	icon: {
		style: {
			fill: 'white',
			position: 'absolute',
			stroke: 'white',
			top: '-1px',
		},
	},
	wrapper: {
		style: {
			top: '-2px',
		},
	},
};
const WHITE_WRAPPER_STYLE = {
	position: 'absolute',
	width: '100%',
	height: '100%',
	top: '0',
	left: '0',
};
class Pointer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: props.open,
		};
	}

	handler = () => {
		const { isOpen } = this.state;
		this.setState({
			isOpen: !isOpen,
		});
	};

	render() {
		const {
			styler,
			path,
			innerRef,
			position = 'default',
			...props
		} = this.props;

		const triangleIcon = {
			default: 'triangleDown',
			right: 'triangleLeft',
			bottom: 'triangleUp',
			left: 'triangleRight',
		}[position];

		return (
			<TriangleElement ref={innerRef} position={position} {...props}>
				<Icon
					asset={triangleIcon}
					styler={styler}
					wrapperStyle={TRIANGLE_WRAPPER_STYLE}
					path="triangle"
					editStyle
					editAsset={false}
				/>
				<Icon
					asset={triangleIcon}
					styles={WHITE_STYLES}
					styler={styler}
					wrapperStyle={WHITE_WRAPPER_STYLE}
					path="triangle"
					editStyle
					editAsset={false}
				/>
			</TriangleElement>
		);
	}
}

export default React.forwardRef((props, ref) => (
	<Pointer innerRef={ref} {...props} />
));
