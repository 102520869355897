import Tabs from './tabs';

export default {
	transactions: {
		style: {},
		header: {
			style: {
				borderBottom: '2px solid #D4C4BC',
				marginBottom: '-2px',
				paddingLeft: '12px',
				paddingRight: '12px',
				position: 'relative',
				flexWrap: 'wrap',
			},
			queries: {
				'(max-width: 767.98px)': {
					style: {
						flexDirection: 'row',
						alignItems: 'center',
						padding: '8px 0',
						marginLeft: '12px',
						marginRight: '12px',
					},
				},
				'(min-width: 768px)': {
					style: {
						paddingBottom: '8px',
						paddingTop: '8px',
						margin: '0 0',
					},
				},
				'(min-width: 1200px)': {
					style: {
						margin: '0 0',
					},
				},
			},
			button: {
				style: {
					paddingTop: '0',
					paddingBottom: '0px',
					paddingLeft: '0',
				},
				queries: {
					'(max-width: 767.98px)': {
						style: {
							width: 'auto',
						},
					},
					'(min-width: 768px)': {
						style: {},
					},
					'(min-width: 1200px)': {
						style: {},
					},
				},
				text: {
					style: {},
					queries: {
						'(max-width: 767.98px)': {
							style: {},
						},
						'(min-width: 768px)': {
							style: {},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
				},
				icon: {
					style: {
						flexGrow: 0,
						marginRight: '4px',
					},
					svg: { style: { fill: 'rgb(159, 140, 130)' } },
				},
				types: {
					large: {
						text: {
							style: {
								fontSize: '26px',
								color: 'rgb(238, 112, 35)',
								fontFamily: 'Swedbank Headline',
								fontWeight: '400',
								textAlign: 'left',
								flexGrow: 1,
								textDecoration: 'none',
								cursor: 'default',
								justifyContent: 'center',
								alignItems: 'flex-start',
								display: 'flex',
								flexDirection: 'column',
							},
							queries: {
								'(max-width: 767.98px)': {
									style: {
										fontSize: '24px',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									},
								},
								'(min-width: 768px)': {
									style: {},
								},
								'(min-width: 1200px)': {
									style: {},
								},
							},
							types: {
								small: {
									style: {
										color: '#979797',
										fontFamily:
											'Arial, Helvetica, sans-serif',
										fontWeight: '400',
										fontSize: '14px',
										marginTop: '-4px',
									},
								},
							},
						},
					},
					transactionsHeaderInfo: {
						style: {
							padding: 0,
							flexGrow: 0,
							borderBottom: '0px solid transparent',
						},
						queries: {
							'(max-width: 767.98px)': {
								style: {
									width: 'auto',
									display: 'none',
								},
							},
							'(min-width: 768px)': {
								style: {},
							},
							'(min-width: 1200px)': {
								style: {},
							},
						},
						icon: {
							style: {
								height: '24px',
								width: '24px',
							},
							svg: {
								style: {
									fill: 'var(--primary-color)',
								},
							},
						},
					},
					transactionsHeaderClose: {
						style: {
							padding: 0,
							flexGrow: 0,
							borderBottom: '0px solid transparent',
						},
						queries: {
							'(max-width: 767.98px)': {
								style: {
									/* 									width: 'auto',
									position: 'absolute',
									right: '0',
									top: '21px', */
								},
							},
							'(min-width: 768px)': {
								style: {},
							},
							'(min-width: 1200px)': {
								style: {},
							},
						},
						icon: {
							style: {
								height: '16px',
								width: '16px',
								/* marginRight: '0', */
							},
						},
					},
				},
				states: {
					hover: {
						icon: {
							svg: { style: { fill: 'rgb(238, 112, 36)' } },
						},
					},
				},
			},
			security: {
				style: {
					marginLeft: '8px',
					marginRight: '8px',
				},
				queries: {
					'(max-width: 767.98px)': {
						style: {
							marginLeft: '0',
							marginTop: '0',
						},
					},
					'(min-width: 768px)': {
						style: {},
					},
					'(min-width: 1200px)': {
						style: {},
					},
				},
				level: {
					style: {
						marginTop: '1px',
						backgroundColor: 'rgb(235, 231, 226)',
						flex: '1 1 0%',
						borderRadius: '3px',
					},
					states: {
						active: {
							style: {
								backgroundColor: 'rgb(253, 193, 41)',
							},
						},
					},
				},
				types: {
					'5': {
						level: {
							states: {
								active: {
									style: {
										backgroundColor: '#99CC66',
									},
								},
							},
						},
					},
					'4': {
						level: {
							states: {
								active: {
									style: {
										backgroundColor: '#99CC66',
									},
								},
							},
						},
					},
					'3': {
						level: {
							states: {
								active: {
									style: {
										backgroundColor: '#FDC129',
									},
								},
							},
						},
					},
					'2': {
						level: {
							states: {
								active: {
									style: {
										backgroundColor: '#BE121A',
									},
								},
							},
						},
					},
					'1': {
						level: {
							states: {
								active: {
									style: {
										backgroundColor: '#BE121A',
									},
								},
							},
						},
					},
				},
			},
			amount: {
				style: {
					color: '#222',
				},
				queries: {
					'(max-width: 767.98px)': {
						style: {
							marginLeft: '20px',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							maxWidth: '110px',
							marginRight: '8px',
						},
					},
					'(min-width: 768px)': {
						style: {},
					},
					'(min-width: 1200px)': {
						style: {},
					},
				},
			},
			adjustable: {
				style: {},
				queries: {
					'(max-width: 767.98px)': {
						style: {
							position: 'fixed',
							bottom: '24px',
							right: '8px',
							zIndex: '5',
						},
					},
					'(min-width: 768px)': {
						style: {},
					},
					'(min-width: 1200px)': {
						style: {},
					},
				},
				text: {
					style: {
						fontFamily: 'Arial, sans-serif',
						fontWeight: '400',
						fontSize: '16px',
					},
					queries: {
						'(max-width: 767.98px)': {
							style: {
								display: 'none',
							},
						},
						'(max-width: 1100px)': {
							style: {
								display: 'none',
							},
						},
						'(min-width: 768px)': {
							style: {},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
				},
				icon: {
					style: {
						// marginRight: '4px',
						// width: '20px',
						marginRight: '8px',
					},
					svg: {
						style: {
							fill: '#53A1AC',
						},
					},
				},
				states: {
					hover: {
						icon: {
							svg: {
								style: {
									fill: '#53A1AC',
								},
							},
						},
					},
				},
			},
			tools: {
				style: {
					display: 'flex',
					flexBasis: '100%',
					alignItems: 'flex-end',
					justifyContent: 'flex-end',
				},
				button: {
					style: {
						width: 'auto',
						marginRight: '12px',
						paddingRight: '0',
					},
					text: {},
				},
			},
		},
		...Tabs,
		list: {
			cell: {
				style: {
					flexShrink: 0,
				},
				types: {
					status: {
						style: {
							width: '40px',
							order: '0',
						},
						icon: {
							types: {
								unpaid: {
									style: {
										// display: 'none',
										marginRight: '8px',
									},
									svg: {},
								},
								overdue: {
									style: {
										// display: 'none',
										marginRight: '8px',
									},
									svg: {},
								},
								forecast: {
									style: {
										display: 'none',
									},
									svg: {},
								},
								tax: {
									style: {
										display: 'none',
									},
									svg: {},
								},
								salary: {
									style: {
										display: 'none',
									},
									svg: {},
								},
								cash: {
									style: {
										display: 'none',
									},
									svg: {},
								},
								card: {
									style: {
										display: 'none',
									},
									svg: {},
								},
								supplier: {
									style: {
										display: 'none',
									},
									svg: {},
								},
								customer: {
									style: {
										display: 'none',
									},
									svg: {},
								},
								paid: {
									style: {
										display: 'none',
									},
									svg: {},
								},
								misc: {
									style: {
										display: 'none',
									},
									svg: {},
								},
								transfer: {
									style: {
										display: 'none',
									},
									svg: {},
								},
								swish: {
									style: {
										display: 'none',
									},
									svg: {},
								},
								plusgiro: {
									style: {
										display: 'none',
									},
									svg: {},
								},
								bankgiro: {
									style: {
										display: 'none',
									},
									svg: {},
								},
							},
						},
						checkbox: {
							style: {
								opacity: '0.15',
								marginLeft: '10px',
							},
						},
						states: {
							hover: {
								style: {},
								checkbox: {
									style: {
										/* opacity: '1', */
									},
								},
							},
						},
					},
					paymentDate: {
						style: {},
						queries: {
							'(max-width: 767.98px)': {
								style: {},
							},
							'(min-width: 768px)': {
								style: {
									width: '20%',
								},
							},
							'(min-width: 1200px)': {
								style: {},
							},
						},
					},
					description: {
						style: {
							flexGrow: 1,
							flexShrink: 1,
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							minWidth: '10%',
						},
						queries: {
							'(max-width: 767.98px)': {
								style: {
									maxWidth: '409px !important',
								},
							},
							'(min-width: 768px)': {
								style: {
									/* maxWidth: '409px', */
								},
							},
							'(min-width: 1200px)': {
								style: {},
							},
						},
					},
					categories: {
						style: {
							width: '15%',
						},
					},
					flatStatus: {
						style: {
							width: '10%',
							flexDirection: 'column',
						},
					},
					riskHigh: {
						style: {
							width: '10%',
							flexDirection: 'column',
						},
					},
					riskLow: {
						style: {
							width: '10%',
							flexDirection: 'column',
						},
					},
					risk: {
						style: {
							width: '10%',
							flexDirection: 'column',
						},
					},
					total: {
						style: {
							width: '150px',
							// paddingRight: '8px',
							justifyContent: 'flex-end',
							alignSelf: 'stretch',
							alignItems: 'center',
							marginRight: '8px',
							marginLeft: '8px',
						},
						security: {
							style: {
								marginLeft: '4px',
								width: '14px',
							},
						},
						title: {
							style: {},
							text: {
								types: {
									small: {
										style: {
											color: '#979797',
										},
									},
								},
							},
						},
						button: {
							style: {
								justifyContent: 'flex-end !important',
								padding: '0 !important',
							},
						},
						text: {
							queries: {
								'(max-width: 767.98px)': {
									style: {
										maxWidth: '110px',
									},
								},
								'(min-width: 768px)': {
									style: {
										justifyContent: 'flex-end',
									},
								},
								'(min-width: 1200px)': {
									style: {},
								},
							},
							types: {
								small: {
									style: {
										color: '#979797',
										fontFamily:
											'Arial, Helvetica, sans-serif',
										fontWeight: '400',
										fontSize: '14px',
										marginTop: '-4px',
									},
								},
							},
						},
						queries: {
							'(max-width: 767.98px)': {
								style: {},
							},
							'(min-width: 768px)': {
								style: {},
							},
							'(min-width: 1200px)': {
								style: {},
							},
						},
					},
					toggle: {
						style: {
							// width: '40px',
							width: '24px',
							justifyContent: 'center',
						},
						icon: {
							style: {
								width: '16px',
								height: '16px',
							},
							svg: {
								style: {
									fill: '#9f8b82',
									transition: 'transform 0.25s ease-in 0s',
								},
							},
						},
					},
				},
				icon: {
					style: {
						width: '24px',
						height: '24px',
						flexGrow: 0,
					},
					svg: {
						style: {
							fill: 'rgb(114, 96, 95)',
						},
					},
				},
			},
			header: {
				style: {
					paddingTop: '1px',
					paddingBottom: '0',
					borderBottom: '2px solid var(--filter-header-border-color)',
					backgroundColor: 'var(--filter-header-bg-color)',
					height: '37px',
					alignItems: 'center',
					justifyContent: 'flex-start',
					zIndex: '1',
					position: 'relative',
					marginTop: '-2px',
				},
				queries: {
					'(max-width: 767.98px)': {
						style: {
							height: 'auto',
							alignItems: 'flex-start',
							margin: '0 12px',
							display: 'none',
						},
					},
					'(min-width: 768px)': {
						style: {},
					},
					'(min-width: 1200px)': {
						style: {},
					},
				},
				cell: {
					style: {},
					queries: {
						'(max-width: 767.98px)': {
							style: {
								width: 'auto',
								justifyContent: 'flex-start',
								padding: '0 8px',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								flexShrink: '0',
							},
						},
						'(min-width: 768px)': {
							style: {},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
					button: {
						style: {
							padding: '1px 8px 0 0',
							width: '100%',
							justifyContent: 'flex-start',
						},
						text: {
							style: {
								color: 'var(--filter-header-text-color)',
								fontWeight: '700',
								fontFamily: 'Swedbank Headline',
								margin: 0,
								padding: 0,
								fontSize: '14px',
								paddingRight: '4px',
								textDecoration: 'none',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								maxWidth: '52%',
							},
							types: {
								small: {
									style: {
										color: '#979797',
										fontFamily:
											'Arial, Helvetica, sans-serif',
										fontWeight: '400',
										fontSize: '10px',
										marginTop: '-4px',
										textAlign: 'left',
									},
								},
							},
						},
						icon: {
							style: {
								width: '10px',
								height: '24px',
								visibility: 'hidden',
								marginRight: 0,
							},
						},
						states: {
							hover: {
								icon: {
									style: {
										visibility: 'visible',
									},
									svg: {
										style: {
											fill: 'rgba(114, 96, 94, 0.5)',
										},
									},
								},
							},
						},
					},
					states: {
						active: {
							button: {
								icon: {
									style: {
										visibility: 'visible',
									},
									svg: {
										style: {
											fill: 'rgb(114, 96, 94)',
										},
									},
								},
							},
						},
					},
					types: {
						status: {
							style: {
								opacity: '1',
							},
							queries: {
								'(max-width: 767.98px)': {
									style: {
										display: 'block',
										position: 'absolute',
										top: '6px',
									},
								},
								'(min-width: 768px)': {
									style: {},
								},
								'(min-width: 1200px)': {
									style: {},
								},
							},
							checkbox: {
								style: {
									paddingTop: '8px',
									paddingBottom: '8px',
									cursor: 'pointer',
									opacity: '1',
								},
								checkboxElement: {
									style: {
										alignItems: 'flex-start',
										paddingRight: '30px',
										opacity: '0.5',
									},
									mark: {
										style: {
											width: '16px',
											height: '16px',
										},
										icon: {
											style: {},
											svg: { style: {} },
										},
									},
									types: {
										checked: {
											style: {
												opacity: '1',
											},
										},
										active: {
											style: {
												opacity: '1',
											},
										},
										hover: {
											style: {
												opacity: '1',
											},
										},
									},
								},
							},
						},
					},
				},
			},
			wrapper: {},
			list: {
				style: {},
				queries: {
					'(max-width: 767.98px)': {
						style: {
							borderTop: '2px solid #D4C4BC',
							margin: '0 8px',
							paddingTop: '0',
						},
					},
					'(min-width: 768px)': {
						style: {},
					},
					'(min-width: 1200px)': {
						style: {},
					},
				},
				transaction: {
					style: {},
					queries: {
						'(max-width: 767.98px)': {
							style: {
								position: 'relative',
							},
						},
						'(min-width: 768px)': {
							style: {},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
					info: {
						style: {
							borderBottom:
								'1px solid var(--transaction-list-border-color)',
							backgroundColor: 'var(--transaction-list-bg-color)',
							minHeight: '48px',
							alignItems: 'center',
							// cursor: 'pointer',
						},
						queries: {
							'(max-width: 767.98px)': {
								style: {
									flexWrap: 'wrap',
								},
							},
							'(min-width: 768px)': {
								style: {},
							},
							'(min-width: 1200px)': {
								style: {},
							},
							'(-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
								style: {
									minHeight: 'auto',
									padding: '4px 0',
								},
							},
						},
						cell: {
							style: {},
							queries: {
								'(max-width: 767.98px)': {
									style: {
										justifyContent: 'flex-start',
										padding: '4px 0 0',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										flexShrink: '0',
									},
								},
								'(min-width: 768px)': {
									style: {},
								},
								'(min-width: 1200px)': {
									style: {},
								},
							},
							text: {
								style: {
									color: 'var(--transaction-list-text-color)',
									fontFamily: 'Swedbank Sans',
									fontWeight: '700',
									fontSize: '16px',
								},
								queries: {
									'(max-width: 767.98px)': {
										style: {
											fontSize: '14px',
										},
									},
									'(min-width: 768px)': {
										style: {},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
							},
							security: {
								style: {
									width: '12px',
									height: '20px',
									display: 'flex',
									flexDirection: 'column-reverse',
									marginTop: '-2px',
									marginRight: '0',
								},
								level: {
									style: {
										marginTop: '1px',
										backgroundColor: 'rgb(235, 231, 226)',
										flex: '1 1 0%',
										borderRadius: '3px',
									},
									states: {
										active: {
											style: {
												backgroundColor:
													'rgb(253, 193, 41)',
											},
										},
									},
								},
								types: {
									'5': {
										level: {
											states: {
												active: {
													style: {
														backgroundColor:
															'#99CC66',
													},
												},
											},
										},
									},
									'4': {
										level: {
											states: {
												active: {
													style: {
														backgroundColor:
															'#99CC66',
													},
												},
											},
										},
									},
									'3': {
										level: {
											states: {
												active: {
													style: {
														backgroundColor:
															'#FDC129',
													},
												},
											},
										},
									},
									'2': {
										level: {
											states: {
												active: {
													style: {
														backgroundColor:
															'#BE121A',
													},
												},
											},
										},
									},
									'1': {
										level: {
											states: {
												active: {
													style: {
														backgroundColor:
															'#BE121A',
													},
												},
											},
										},
									},
								},
							},
							tags: {
								tag: {
									style: {
										alignItems: 'center',
										marginRight: '8px',
										alignSelf: 'center',
									},
									prefix: {
										style: {
											height: '12px',
											width: '12px',
											marginRight: '8px',
										},
									},
									text: {
										style: {
											color: 'var(--text-color)',
											fontFamily: 'Swedbank Sans',
											fontWeight: 700,
											fontSize: '14px',
											lineHeight: 'normal',
										},
										queries: {
											'(max-width: 767.98px)': {
												style: {
													fontSize: '12px',
												},
											},
											'(min-width: 768px)': {
												style: {},
											},
											'(min-width: 1200px)': {
												style: {},
											},
										},
									},
								},
							},
							types: {
								status: {
									style: {},
									queries: {
										'(max-width: 767.98px)': {
											style: {
												// order: '1',
												marginTop: '5px',
												display: 'none',
											},
										},
										'(min-width: 768px)': {
											style: {},
										},
										'(min-width: 1200px)': {
											style: {},
										},
									},
									icon: {
										style: {},
										queries: {
											'(max-width: 767.98px)': {
												style: {
													height: '16px',
													width: '16px',
												},
											},
											'(min-width: 768px)': {
												style: {
													width: '16px',
													height: '16px',
												},
											},
											'(min-width: 1200px)': {
												style: {},
											},
										},
									},
								},
								description: {
									style: {},
									queries: {
										'(max-width: 767.98px)': {
											style: {
												maxWidth: '52%',
												flexBasis: '52%',
												order: '0',
												paddingTop: '13px',
												paddingLeft: '8px',
											},
										},
										'(min-width: 768px)': {
											style: {},
										},
										'(min-width: 1200px)': {
											style: {},
										},
									},
									text: {
										style: {
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											paddingRight: '10px',
										},
										types: {
											small: {
												style: {
													color: '#979797',
													fontFamily:
														'Arial, Helvetica, sans-serif',
													fontWeight: '400',
													fontSize: '14px',
													marginTop: '-4px',
												},
											},
										},
										queries: {
											'(max-width: 767.98px)': {
												style: {
													whiteSpace: 'nowrap',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
												},
											},
											'(min-width: 768px)': {
												style: {},
											},
											'(min-width: 1200px)': {
												style: {},
											},
										},
									},
								},
								categories: {
									style: {},
									queries: {
										'(max-width: 767.98px)': {
											style: {
												width: '60%',
												order: '999',
												marginTop: '-16px',
												paddingLeft: '8px',
											},
										},
										'(min-width: 768px)': {
											style: {
												width: '15%',
											},
										},
										'(min-width: 1200px)': {
											style: {},
										},
									},
									tags: {
										tag: {
											prefix: {
												queries: {
													'(max-width: 767.98px)': {
														style: {
															marginRight: '4px',
														},
													},
													'(min-width: 768px)': {
														style: {},
													},
													'(min-width: 1200px)': {
														style: {},
													},
												},
											},
										},
									},
								},
								paymentDate: {
									style: {},
									queries: {
										'(max-width: 767.98px)': {
											style: {
												order: '5',
												marginLeft: 'auto',
												justifyContent: 'flex-end',
												display: 'flex',
												position: 'absolute',
												right: '54px',
												top: '28px',
											},
										},
										'(min-width: 768px)': {
											style: {
												width: '20%',
											},
										},
										'(min-width: 1200px)': {
											style: {},
										},
									},
									text: {
										style: {},
										queries: {
											'(max-width: 767.98px)': {
												style: {
													fontFamily:
														'Arial, Helvetica, sans-serif',
													fontWeight: '400',
													fontSize: '12px',
													color: 'rgb(105, 105, 105)',
												},
											},
											'(min-width: 768px)': {
												style: {},
											},
											'(min-width: 1200px)': {
												style: {},
											},
										},
										types: {
											small: {
												style: {
													color: '#979797',
													fontFamily:
														'Arial, Helvetica, sans-serif',
													fontWeight: '400',
													fontSize: '14px',
													marginTop: '-4px',
												},
											},
										},
									},
								},
								flatStatus: {
									style: {},
									queries: {
										'(max-width: 767.98px)': {
											style: {
												fontFamily:
													'Arial, Helvetica, sans-serif',
												fontWeight: '400',
												fontSize: '12px',
												color: 'rgb(105, 105, 105)',
												display: 'none',
											},
										},
										'(min-width: 768px)': {
											style: {
												width: '10%',
												flexDirection: 'row',
											},
										},
										'(min-width: 1200px)': {
											style: {},
										},
									},
									text: {
										style: {
											fontSize: '14px',
										},
									},
								},
								riskHigh: {
									style: {},
									queries: {
										'(max-width: 767.98px)': {
											style: {
												fontFamily:
													'Arial, Helvetica, sans-serif',
												fontWeight: '400',
												fontSize: '12px',
												color: 'rgb(105, 105, 105)',
												display: 'none',
											},
										},
										'(min-width: 768px)': {
											style: {
												width: '10%',
												flexDirection: 'row',
											},
										},
										'(min-width: 1200px)': {
											style: {},
										},
									},
									text: {
										style: {
											fontSize: '14px',
										},
									},
								},
								riskLow: {
									style: {},
									queries: {
										'(max-width: 767.98px)': {
											style: {
												fontFamily:
													'Arial, Helvetica, sans-serif',
												fontWeight: '400',
												fontSize: '12px',
												color: 'rgb(105, 105, 105)',
												display: 'none',
											},
										},
										'(min-width: 768px)': {
											style: {
												width: '10%',
												flexDirection: 'row',
											},
										},
										'(min-width: 1200px)': {
											style: {},
										},
									},
									text: {
										style: {
											fontSize: '14px',
										},
									},
								},
								risk: {
									style: {},
									queries: {
										'(max-width: 767.98px)': {
											style: {
												fontFamily:
													'Arial, Helvetica, sans-serif',
												fontWeight: '400',
												fontSize: '12px',
												color: 'rgb(105, 105, 105)',
												display: 'none',
											},
										},
										'(min-width: 768px)': {
											style: {
												width: '10%',
												flexDirection: 'row',
											},
										},
										'(min-width: 1200px)': {
											style: {},
										},
									},
									text: {
										style: {
											fontSize: '14px',
										},
									},
								},
								total: {
									style: {},
									queries: {
										'(max-width: 767.98px)': {
											style: {
												order: '3',
												justifyContent: 'flex-end',
												position: 'absolute',
												right: '54px',
												top: '9px',
												maxWidth: '100px',
											},
										},
										'(min-width: 768px)': {
											style: {},
										},
										'(min-width: 1200px)': {
											style: {},
										},
									},
									text: {
										style: {
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											marginRight: '4px',
										},
										types: {
											small: {
												style: {
													color: '#979797',
													fontFamily:
														'Arial, Helvetica, sans-serif',
													fontWeight: '400',
													fontSize: '14px',
													marginTop: '-4px',
												},
											},
										},
									},
								},
								security: {
									style: {},
									queries: {
										'(max-width: 767.98px)': {
											style: {
												order: '4',
												marginRight: '0',
												position: 'absolute',
												right: '32px',
												top: '18px',
											},
										},
										'(min-width: 768px)': {
											style: {},
										},
										'(min-width: 1200px)': {
											style: {},
										},
									},
								},
								toggle: {
									style: {},
									queries: {
										'(max-width: 767.98px)': {
											style: {
												order: '5',
												position: 'absolute',
												right: '-16px',
												top: '14px',
											},
										},
										'(min-width: 768px)': {
											style: {
												marginLeft: 'auto',
											},
										},
										'(min-width: 1200px)': {
											style: {},
										},
									},
									button: {
										style: {},
										queries: {
											'(max-width: 767.98px)': {
												style: {
													width: '20px',
													height: '20px',
												},
											},
											'(min-width: 768px)': {
												style: {},
											},
											'(min-width: 1200px)': {
												style: {},
											},
										},
										icon: {
											style: {},
										},
									},
								},
							},
						},
					},
					detailswrapper: {
						style: {
							height: '0px',
							overflow: 'hidden',
							transition: 'height 0.2s linear',
							margin: 0,
						},
						details: {
							style: {
								/* width: '100%', */
								display: 'flex',
								flexDirection: 'column',
								padding: '16px 40px 16px',
							},
							queries: {
								'(max-width: 767.98px)': {
									style: {
										padding: '16px 10px 16px',
									},
								},
								'(min-width: 768px)': {
									style: {},
								},
								'(min-width: 1200px)': {
									style: {},
								},
							},
							cell: {
								style: {
									display: 'flex',
									flexBasis: '39%',
									alignItems: 'center',
									minHeight: '20',
									zoom: '1',
									padding: '8px 0px',
								},
								title: {
									style: {
										flexBasis: '43%',
										color:
											'var(--transaction-list-text-color)',
										fontFamily: 'Arial, sans-serif',
										fontWeight: '400',
										fontSize: '14px',
									},
									queries: {
										'(max-width: 767.98px)': {
											style: {
												fontSize: '12px',
											},
										},
										'(min-width: 768px)': {
											style: {},
										},
										'(min-width: 1200px)': {
											style: {},
										},
									},
								},
								text: {
									queries: {
										'(max-width: 767.98px)': {
											style: {
												fontSize: '12px',
											},
										},
										'(min-width: 768px)': {
											style: {},
										},
										'(min-width: 1200px)': {
											style: {},
										},
									},
								},
								types: {
									infobox: {
										button: {
											style: {
												marginLeft: '16px',
												width: 'auto',
												padding: '8px 16px',
											},
											queries: {
												'(max-width: 767.98px)': {
													style: {},
												},
												'(min-width: 768px)': {
													style: {
														width: 'auto',
													},
												},
												'(min-width: 1200px)': {
													style: {},
												},
											},
										},
									},
									allCategories: {
										style: {
											padding: '4px',
										},
										tags: {
											queries: {
												'(max-width: 767.98px)': {
													style: {
														maxWidth: '188px',
													},
												},
												'(min-width: 768px)': {
													style: {
														margin: '0 -4px',
														maxWidth: '492px',
													},
												},
												'(min-width: 1200px)': {
													style: {
														maxWidth: '492px',
													},
												},
											},
											tag: {
												style: {
													alignItems: 'center',
													marginRight: '8px',
													alignSelf: 'center',
												},
												prefix: {
													style: {
														height: '12px',
														width: '12px',
														marginRight: '8px',
													},
												},
												text: {
													style: {
														color:
															'var(--text-color)',
														fontFamily:
															'Swedbank Sans',
														fontWeight: 700,
														fontSize: '14px',
														lineHeight: 'normal',
													},
													queries: {
														'(max-width: 767.98px)': {
															style: {
																fontSize:
																	'12px',
															},
														},
														'(min-width: 768px)': {
															style: {},
														},
														'(min-width: 1200px)': {
															style: {},
														},
													},
												},
											},
										},
									},
									remove: {
										style: {
											marginLeft: 'auto',
										},
									},
									adjustable: {
										style: {
											justifyContent: 'flex-start',
											flexDirection: 'column',
										},
										queries: {
											'(max-width: 767.98px)': {
												style: {},
											},
											'(min-width: 768px)': {
												style: {
													justifyContent: 'flex-end',
													flexDirection: 'row',
												},
											},
											'(min-width: 1200px)': {
												style: {},
											},
										},
										button: {
											style: {
												width: '100%',
											},
											queries: {
												'(max-width: 767.98px)': {
													style: {},
												},
												'(min-width: 768px)': {
													style: {
														width: 'auto',
													},
												},
												'(min-width: 1200px)': {
													style: {},
												},
											},
											types: {
												adjust: {
													style: {
														order: '-1',
														marginLeft: '0',
													},
													queries: {
														'(max-width: 767.98px)': {
															style: {},
														},
														'(min-width: 768px)': {
															style: {
																marginLeft:
																	'16px',
																/* order: '0', */
															},
														},
														'(min-width: 1200px)': {
															style: {},
														},
													},
													text: {
														style: {
															fontSize: '16px',
														},
														queries: {
															'(max-width: 767.98px)': {
																style: {},
															},
															'(min-width: 768px)': {
																style: {},
															},
															'(min-width: 1200px)': {
																style: {},
															},
														},
													},
												},
												remove: {
													style: {
														marginTop: '8px',
													},
													queries: {
														'(max-width: 767.98px)': {
															style: {},
														},
														'(min-width: 768px)': {
															style: {
																marginLeft:
																	'16px',
																marginTop:
																	'4px !important',
																marginRight:
																	'0 !important',
																padding:
																	'8px 16px !important',
															},
														},
														'(min-width: 1200px)': {
															style: {},
														},
													},
													text: {
														style: {
															fontSize: '18px',
														},
														queries: {
															'(max-width: 767.98px)': {
																style: {},
															},
															'(min-width: 768px)': {
																style: {},
															},
															'(min-width: 1200px)': {
																style: {},
															},
														},
													},
												},
											},
										},
									},
								},
							},
						},
					},
					states: {
						hover: {
							style: {
								backgroundColor: 'rgb(251, 242, 234)',
							},
							info: {
								style: {
									backgroundColor:
										'var(--transaction-list-active-bg-color)',
								},
								cell: {
									types: {
										toggle: {
											icon: {
												svg: {
													style: {
														fill:
															'var(--primary-color)',
													},
												},
											},
										},
									},
								},
							},
						},
						active: {
							style: {
								backgroundColor: 'rgb(251, 242, 234)',
								borderBottom: '1px solid #ece7e2',
							},
							info: {
								style: {
									backgroundColor:
										'var(--transaction-list-active-bg-color)',
								},
								queries: {
									'(max-width: 767.98px)': {
										style: {
											// padding: '0px 8px',
										},
									},
									'(min-width: 768px)': {
										style: {},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
								cell: {
									types: {
										status: {
											icon: {
												style: {
													// display: 'none',
												},
												svg: {
													style: {},
												},
											},
										},
										toggle: {
											style: {
												cursor: 'pointer',
											},
											queries: {
												'(max-width: 767.98px)': {
													style: {
														right: '-16px',
													},
												},
												'(min-width: 768px)': {
													style: {},
												},
												'(min-width: 1200px)': {
													style: {},
												},
											},
											icon: {
												svg: {
													style: {
														transform:
															'rotate(180deg)',
													},
												},
											},
										},
									},
								},
							},
							detailswrapper: {
								style: {
									// height: '252px',
									height: 'auto',
								},
								details: {
									style: {
										borderTopWidth: '1px',
										borderTopColor: 'rgb(235, 231, 226)',
										borderTopStyle: 'solid',
									},
								},
							},
						},
					},
					types: {
						actionRemove: {
							info: {
								style: {
									boxShadow: '3px 0px inset #C5131C',
									background: '#f7f7f7',
								},
							},
						},
						actionCreate: {
							info: {
								style: {
									boxShadow: '3px 0px inset #51971B',
									background: '#f7f7f7',
								},
							},
						},
						actionModify: {
							info: {
								style: {
									boxShadow: '3px 0px inset #53a1ac',
									background: '#f7f7f7',
								},
							},
						},
						statusForecast: {
							info: {
								cell: {
									types: {
										status: {
											style: {},
											queries: {
												'(max-width: 767.98px)': {
													style: {
														display: 'block',
														position: 'absolute',
														top: '6px',
													},
												},
												'(min-width: 768px)': {
													style: {},
												},
												'(min-width: 1200px)': {
													style: {},
												},
											},
											checkbox: {
												style: {
													paddingTop: '8px',
													paddingBottom: '8px',
													cursor: 'pointer',
												},
												checkboxElement: {
													style: {
														alignItems:
															'flex-start',
														paddingRight: '30px',
													},
													mark: {
														style: {
															width: '16px',
															height: '16px',
														},
														icon: {
															style: {},
															svg: { style: {} },
														},
													},
												},
											},
										},
										description: {
											style: {},
											queries: {
												'(max-width: 767.98px)': {
													style: {
														paddingLeft: '36px',
													},
												},
												'(min-width: 768px)': {
													style: {},
												},
												'(min-width: 1200px)': {
													style: {},
												},
											},
										},
										categories: {
											style: {},
											queries: {
												'(max-width: 767.98px)': {
													style: {
														paddingLeft: '36px',
													},
												},
												'(min-width: 768px)': {
													style: {},
												},
												'(min-width: 1200px)': {
													style: {},
												},
											},
										},
										tags: {
											tag: {
												prefix: {
													style: {
														backgroundImage:
															'repeating-linear-gradient(135deg,transparent,transparent 1px,rgba(255,255,255,0.2) 2px,rgba(255,255,255,0.2) 4px,transparent 4.5px)',
													},
												},
											},
										},
										paymentDate: {
											style: {},
											queries: {
												'(max-width: 767.98px)': {
													style: {
														order: '5',
														marginLeft: 'auto',
														justifyContent:
															'flex-end',
														display: 'flex',
														position: 'absolute',
														right: '54px',
														top: '28px',
													},
												},
												'(min-width: 768px)': {
													style: {},
												},
												'(min-width: 1200px)': {
													style: {},
												},
											},
											text: {
												style: {},
												queries: {
													'(max-width: 767.98px)': {
														style: {
															fontFamily:
																'Arial, Helvetica, sans-serif',
															fontWeight: '400',
															fontSize: '12px',
															color:
																'rgb(105, 105, 105)',
														},
													},
													'(min-width: 768px)': {
														style: {},
													},
													'(min-width: 1200px)': {
														style: {},
													},
												},
											},
										},
										total: {
											style: {},
											queries: {
												'(max-width: 767.98px)': {
													style: {
														order: '3',
														justifyContent:
															'flex-end',
														position: 'absolute',
														right: '54px',
														top: '9px',
													},
												},
												'(min-width: 768px)': {
													style: {},
												},
												'(min-width: 1200px)': {
													style: {},
												},
											},
										},
									},
								},
								states: {
									hover: {
										cell: {
											style: {},
											types: {
												status: {
													style: {},
													checkbox: {
														style: {
															opacity: '1',
														},
													},
												},
											},
										},
									},
								},
							},
							detailswrapper: {
								details: {
									cell: {
										types: {
											textStatus: {
												icon: {
													style: {
														display: 'none',
													},
													queries: {
														'(max-width: 767.98px)': {
															style: {
																width: '16px',
																height: '16px',
															},
														},
														'(min-width: 768px)': {
															style: {},
														},
														'(min-width: 1200px)': {
															style: {},
														},
													},
												},
											},
											adjustable: {
												style: {
													justifyContent: 'flex-end',
												},
												button: {
													style: {},
													types: {
														adjust: {
															style: {
																// marginLeft: '16px',
															},
														},
														remove: {},
													},
												},
											},
										},
									},
								},
							},
							states: {
								active: {
									style: {},
									info: {
										cell: {
											types: {
												status: {
													style: {},
													checkbox: {
														style: {
															opacity: '1',
														},
													},
												},
											},
										},
									},
								},
							},
						},
						statusPaid: {
							info: {
								cell: {
									types: {
										total: {
											security: {
												style: {
													visibility: 'hidden',
												},
											},
										},
									},
								},
							},
							detailswrapper: {
								details: {
									cell: {
										types: {
											textStatus: {
												icon: {
													style: {
														display: 'none',
													},
													queries: {
														'(max-width: 767.98px)': {
															style: {
																width: '16px',
																height: '16px',
															},
														},
														'(min-width: 768px)': {
															style: {},
														},
														'(min-width: 1200px)': {
															style: {},
														},
													},
												},
											},
										},
									},
								},
							},
						},
						statusUnpaid: {
							info: {
								cell: {
									types: {
										status: {
											icon: {
												svg: {
													style: {
														fill:
															'rgb(249, 206, 35)',
													},
												},
											},
										},
										paymentDate: {
											style: {},
											queries: {
												'(max-width: 767.98px)': {
													style: {
														order: '5',
														marginLeft: 'auto',
														justifyContent:
															'flex-end',
														display: 'flex',
														position: 'absolute',
														right: '54px',
														top: '28px',
													},
												},
												'(min-width: 768px)': {
													style: {},
												},
												'(min-width: 1200px)': {
													style: {},
												},
											},
											text: {
												style: {},
												queries: {
													'(max-width: 767.98px)': {
														style: {
															fontFamily:
																'Arial, Helvetica, sans-serif',
															fontWeight: '400',
															fontSize: '12px',
															color:
																'rgb(105, 105, 105)',
														},
													},
													'(min-width: 768px)': {
														style: {},
													},
													'(min-width: 1200px)': {
														style: {},
													},
												},
											},
										},
										total: {
											style: {},
											queries: {
												'(max-width: 767.98px)': {
													style: {
														order: '3',
														justifyContent:
															'flex-end',
														position: 'absolute',
														right: '54px',
														top: '9px',
													},
												},
												'(min-width: 768px)': {
													style: {},
												},
												'(min-width: 1200px)': {
													style: {},
												},
											},
										},
									},
								},
							},
							detailswrapper: {
								details: {
									cell: {
										types: {
											textStatus: {
												icon: {
													queries: {
														'(max-width: 767.98px)': {
															style: {
																width: '16px',
																height: '16px',
															},
														},
														'(min-width: 768px)': {
															style: {
																width: '16px',
																height: '16px',
															},
														},
														'(min-width: 1200px)': {
															style: {},
														},
													},
													style: {
														marginRight: '8px',
													},
													svg: {
														style: {
															fill:
																'rgb(249, 206, 35)',
														},
													},
												},
											},
										},
									},
								},
							},
						},
						statusOverdue: {
							info: {
								cell: {
									types: {
										total: {
											security: {
												style: {
													visibility: 'hidden',
												},
											},
										},
										status: {
											icon: {
												svg: {
													style: {
														fill:
															'rgb(197, 19, 27)',
													},
												},
											},
										},
									},
								},
							},
							detailswrapper: {
								details: {
									cell: {
										types: {
											textStatus: {
												icon: {
													style: {
														marginRight: '8px',
													},
													queries: {
														'(max-width: 767.98px)': {
															style: {
																width: '16px',
																height: '16px',
															},
														},
														'(min-width: 768px)': {
															style: {
																width: '16px',
																height: '16px',
															},
														},
														'(min-width: 1200px)': {
															style: {
																width: '16px',
																height: '16px',
															},
														},
													},
													svg: {
														style: {
															fill:
																'rgb(197, 19, 27)',
														},
													},
												},
											},
										},
									},
								},
							},
						},
						edit: {
							style: {
								padding: '20px 10px',
								borderBottom: '1px solid #ece7e2',
								background: '#FBF2EA',
							},
							adjustable: {
								style: {
									flexDirection: 'column',
									alignItems: 'flex-start',
								},
								queries: {
									'(max-width: 767.98px)': {
										style: {},
									},
									'(min-width: 768px)': {
										style: {
											flexDirection: 'row',
											alignItems: 'flex-end',
										},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
								group: {
									style: {
										width: '100%',
									},
									queries: {
										'(max-width: 767.98px)': {
											style: {},
										},
										'(min-width: 768px)': {
											style: {
												flex: '1',
												margin: '0 10px',
											},
										},
										'(min-width: 1200px)': {
											style: {},
										},
									},
									types: {
										date: {
											queries: {
												'(max-width: 767.98px)': {
													style: {},
												},
												'(min-width: 768px)': {
													style: {
														maxWidth: '150px',
													},
												},
												'(min-width: 1200px)': {
													style: {},
												},
											},
										},
										amount: {
											queries: {
												'(max-width: 767.98px)': {
													style: {},
												},
												'(min-width: 768px)': {
													style: {
														maxWidth: '190px',
													},
												},
												'(min-width: 1200px)': {
													style: {},
												},
											},
										},
									},
								},
								input: {
									style: {
										background: '#fff',
									},
								},
								label: {
									style: {},
								},
								dropdown: {
									style: {},
									toggle: {
										style: {},
										icon: {
											svg: {
												style: {
													fill: '#53A1AC',
												},
											},
										},
									},
								},
								button: {
									style: {},
									types: {
										toggle: {
											style: {
												display: 'none',
											},
										},
										remove: {
											style: {
												color: '#a38b80',
												marginTop: 'auto',
												marginBottom: '4px',
											},
											queries: {
												'(max-width: 767.98px)': {
													style: {
														paddingLeft: '0',
														marginTop: '12px',
													},
												},
												'(min-width: 768px)': {
													style: {
														marginRight:
															'20px !important',
													},
												},
												'(min-width: 1200px)': {
													style: {},
												},
											},
											text: {
												style: {
													color: '#512b2b',
													fontSize: '16px',
												},
											},
											icon: {
												style: {
													marginRight: '8px',
												},
												svg: {
													style: {
														fill: '#a38b80',
													},
												},
											},
										},
									},
								},
							},
							controllers: {
								style: {
									display: 'flex',
									marginTop: '16px',
									justifyContent: 'stretch',
									flexDirection: 'column',
								},
								queries: {
									'(max-width: 767.98px)': {
										style: {},
									},
									'(min-width: 768px)': {
										style: {
											marginLeft: '0',
											marginRight: '0',
											flexDirection: 'row',
											alignItems: 'flex-end',
											justifyContent: 'flex-end',
											paddingRight: '12px',
											marginTop: '16px',
										},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
								button: {
									style: {},
									types: {
										remove: {
											style: {
												marginTop: '8px',
											},
											queries: {
												'(max-width: 767.98px)': {
													style: {},
												},
												'(min-width: 768px)': {
													style: {
														marginLeft: '16px',
														marginTop: '0',
													},
												},
												'(min-width: 1200px)': {
													style: {},
												},
											},
										},
										cancel: {
											style: {
												marginTop: '8px',
											},
											queries: {
												'(max-width: 767.98px)': {
													style: {},
												},
												'(min-width: 768px)': {
													style: {},
												},
												'(min-width: 1200px)': {
													style: {},
												},
											},
										},
										save: {
											style: {
												order: '-1',
											},
											queries: {
												'(max-width: 767.98px)': {
													style: {},
												},
												'(min-width: 768px)': {
													style: {
														marginLeft: '16px',
														order: '2',
													},
												},
												'(min-width: 1200px)': {
													style: {},
												},
											},
										},
									},
								},
							},
						},
						selected: {
							info: {
								style: {
									background:
										'var(--transaction-list-active-bg-color)',
								},
								cell: {
									types: {
										status: {
											style: {},
											checkbox: {
												style: {
													opacity: '1',
												},
											},
										},
									},
								},
							},
						},
					},
					confirm: {
						container: {
							style: {
								display: 'flex',
								flexDirection: 'column',
							},
						},
						text: {
							style: {
								flexGrow: 1,
							},
						},
					},
				},
			},
			empty: {
				style: {
					backgroundColor: 'var(--filter-header-bg-color)',
					padding: '16px 40px',
				},
			},
		},
		info: {
			content: {
				style: {
					display: 'flex',
					marginTop: '10px',
				},
				queries: {
					'(max-width: 767.98px)': {
						style: {
							flexDirection: 'column',
						},
					},
					'(min-width: 768px)': {
						style: {
							marginLeft: '16px',
							order: '2',
						},
					},
					'(min-width: 1200px)': {
						style: {},
					},
				},
				security: {
					text: {
						style: {
							marginRight: '4px',
						},
					},
					bar: {
						level: {
							style: {
								marginTop: '1px',
								backgroundColor: 'rgb(235, 231, 226)',
								flex: '1 1 0%',
								borderRadius: '3px',
							},
							states: {
								active: {
									style: {
										backgroundColor: 'rgb(253, 193, 41)',
									},
								},
							},
						},
						types: {
							'5': {
								level: {
									states: {
										active: {
											style: {
												backgroundColor: '#99CC66',
											},
										},
									},
								},
							},
							'4': {
								level: {
									states: {
										active: {
											style: {
												backgroundColor: '#99CC66',
											},
										},
									},
								},
							},
							'3': {
								level: {
									states: {
										active: {
											style: {
												backgroundColor: '#FDC129',
											},
										},
									},
								},
							},
							'2': {
								level: {
									states: {
										active: {
											style: {
												backgroundColor: '#BE121A',
											},
										},
									},
								},
							},
							'1': {
								level: {
									states: {
										active: {
											style: {
												backgroundColor: '#BE121A',
											},
										},
									},
								},
							},
						},
					},
				},
			},
			style: {
				transition: 'max-height 0.5s',
				maxHeight: '0',
				overflow: 'hidden',
			},
			container: {
				style: {
					backgroundColor: '#f9f8f6',
					borderBottom: '2px solid rgba(238, 112, 35, 1)',
					borderTop: '2px solid rgba(238, 112, 35, 1)',
					marginBottom: '10px',
					marginTop: '0',
					padding: '16px',
				},
			},
			text: {
				style: {
					color: 'rgba(34,34,34,1)',
					display: 'block',
					fontSize: '14px',
					lineHeight: '20px',
				},
			},
		},
		detailedbar: {
			style: {
				overflow: 'hidden',
			},
			queries: {
				'(max-width: 767.98px)': {
					style: {
						margin: '0px 12px',
						width: 'auto',
					},
				},
				'(min-width: 768px)': {
					style: {},
				},
				'(min-width: 1200px)': {
					style: {},
				},
			},
			header: {
				button: {
					text: {
						style: {
							fontFamily: 'Swedbank Headline',
							fontWeight: '400',
							fontSize: '14px',
							color: '#262626',
							textDecoration: 'none',
							marginRight: '8px',
							lineHeight: '100%',
							marginTop: '3px',
						},
					},
					style: {
						padding: '0px',
						borderBottom: '0px solid transparent',
						marginTop: '4px',
						marginBottom: '4px',
					},
					icon: {
						style: {
							width: '12px',
							height: '10px',
							marginRight: '4px',
							marginTop: '4px',
						},
						svg: {
							style: {
								transform: 'rotate(0deg)',
								transition: 'transform 0.25s ease-in 0s',
								fill: 'rgb(159,140,130)',
							},
						},
					},
					types: {
						infoHelp: {
							style: {
								padding: '0px',
								width: 'auto',
								height: 'auto',
								marginTop: '0px',
							},
							icon: {
								style: {
									width: '24px',
									height: '24px',
									marginLeft: '4px',
								},
								svg: {
									style: {
										transform: 'rotate(0deg)',
										fill: 'var(--primary-color)',
									},
								},
							},
						},
					},
				},
				actions: {
					style: {
						display: 'flex',
						flexDirection: 'row-reverse',
						justifyContent: 'center',
						alignItems: 'center',
						marginLeft: 'auto',
						paddingRight: '4px',
					},
					button: {
						style: {
							position: 'relative',
							zIndex: '1',
							background: 'transparent',
						},
						queries: {
							'(max-width: 767.98px)': {
								style: {},
							},
							'(min-width: 768px)': {
								style: {},
							},
							'(min-width: 1200px)': {
								style: {},
							},
						},
						icon: {
							style: {
								height: '100%',
								width: '100%',
								margin: 'auto',
							},
							svg: {
								style: {
									fill: 'rgb(159,140,130)',
								},
							},
						},
						states: {
							hover: {
								icon: {
									svg: {
										style: {
											fill: 'var(--primary-color)',
										},
									},
								},
							},
						},
						types: {
							left: {
								style: {
									/* marginLeft: '0', */
									marginLeft: 'auto',
								},
								queries: {
									'(max-width: 767.98px)': {
										style: {},
									},
									'(min-width: 768px)': {
										style: {},
									},
									'(min-width: 1200px)': {
										style: {
											/* marginLeft: '-6px', */
											paddingLeft: '0',
											paddingRight: '0',
											width: '28px',
										},
									},
								},
							},
							right: {
								style: {},
								queries: {
									'(max-width: 767.98px)': {
										style: {},
									},
									'(min-width: 768px)': {
										style: {},
									},
									'(min-width: 1200px)': {
										style: {
											/* marginLeft: 'auto', */
										},
									},
								},
							},
						},
					},
				},
			},
			box: {
				style: {
					maxHeight: '0px',
					transition: 'max-height 0.5s',
				},
				bar: {
					style: {
						height: '18px',
						margin: '0 0 8px',
						backgroundColor: '#B7B7B7',
					},
					part: {
						types: {
							forecast: {
								style: {
									backgroundImage:
										'repeating-linear-gradient(135deg,transparent,transparent 1px,rgba(255, 255, 255, 0.2) 2px,rgba(255, 255, 255, 0.2) 4px,transparent 4.5px)',
								},
							},
						},
					},
				},
				pills: {
					box: {
						style: {
							marginRight: '16px',
							flexShrink: '0',
						},
						pill: {
							style: {
								border: '1px solid rgb(235, 231, 226)',
								borderRadius: '30px',
								padding: '6px 12px',
								cursor: 'pointer',
								marginBottom: '4px',
							},
							text: {
								style: {
									margin: '0',
								},
							},
							security: {
								style: {
									marginLeft: '8px',
								},
							},
							states: {
								hover: {
									style: {
										backgroundColor: 'rgb(253, 193, 40)',
										borderColor: 'rgb(253, 193, 40)',
									},
								},
								active: {
									style: {
										backgroundColor: 'rgb(253, 193, 40)',
										borderColor: 'rgb(253, 193, 40)',
									},
								},
							},
							types: {
								forecast: {
									prefix: {
										style: {
											backgroundImage:
												'repeating-linear-gradient(135deg,transparent,transparent 1px,rgba(255, 255, 255, 0.2) 2px,rgba(255, 255, 255, 0.2) 4px,transparent 4.5px)',
										},
									},
								},
							},
						},
						text: {
							style: {
								marginLeft: '32px',
							},
						},
					},
				},
			},
			states: {
				active: {
					box: {
						style: {
							maxHeight: '200px',
						},
					},
					header: {
						button: {
							text: {},
							style: {
								borderBottom: '0px solid transparent',
							},
							icon: {
								style: {},
								svg: {
									style: {
										transform: 'rotate(180deg)',
									},
								},
							},
						},
					},
				},
				hover: {
					header: {
						button: {
							text: {},
							style: {},
							icon: {
								style: {},
								svg: {
									style: {
										fill: 'var(--primary-color)',
									},
								},
							},
						},
					},
				},
			},
			wrapper: {
				style: {
					padding: '0px 0 16px',
					position: 'relative',
				},
				actions: {
					style: {
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						position: 'absolute',
						top: '0px',
						height: '100%',
						width: '100%',
						/* ADD IF USER HAS CLICKED TO SCROLL HORIZONTAL FIRST TIME: (move in chips and show left arrow again) paddingLeft: '40px', */
					},
					queries: {
						'(max-width: 767.98px)': {
							style: {
								alignItems: 'flex-start',
								top: '-42px',
								padding: '0',
							},
						},
						'(min-width: 768px)': {
							style: {},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
					button: {
						style: {
							position: 'relative',
							zIndex: '1',
						},
						queries: {
							'(max-width: 767.98px)': {
								style: {},
							},
							'(min-width: 768px)': {
								style: {},
							},
							'(min-width: 1200px)': {
								style: {
									marginTop: '-71px',
								},
							},
						},
						icon: {
							svg: {
								style: {
									fill: 'rgb(159,140,130)',
								},
							},
						},
						states: {
							hover: {
								icon: {
									svg: {
										style: {
											fill: 'var(--primary-color)',
										},
									},
								},
							},
						},
						types: {
							left: {
								style: {
									/* marginLeft: '0', */
									marginLeft: 'auto',
								},
								queries: {
									'(max-width: 767.98px)': {
										style: {
											/* marginLeft: '-6px', */
											paddingLeft: '0',
											paddingRight: '0',
											width: '28px',
											marginRight: '0',
										},
									},
									'(min-width: 768px)': {
										style: {},
									},
									'(min-width: 1200px)': {
										style: {
											/* marginLeft: '-6px', */
											paddingLeft: '0',
											paddingRight: '0',
											width: '28px',
										},
									},
								},
							},
							right: {
								style: {},
								queries: {
									'(max-width: 767.98px)': {
										style: {
											/* marginLeft: '-6px', */
											paddingLeft: '0',
											paddingRight: '0',
											width: '28px',
											marginRight: '0',
											marginLeft: '0',
										},
									},
									'(min-width: 768px)': {
										style: {},
									},
									'(min-width: 1200px)': {
										style: {
											/* marginLeft: '-6px', */
											paddingLeft: '0',
											paddingRight: '0',
											width: '28px',
										},
									},
								},
							},
						},
					},
				},
				indicator: {
					style: {
						marginTop: '24px',
						marginBottom: '8px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					},
					button: {
						style: {
							height: '8px',
							width: '8px',
							borderRadius: '100%',
							background: '#C6C3BF',
							padding: '0',
							margin: '0 2px',
						},
						types: {
							active: {
								style: {
									background: '#72605f',
								},
							},
						},
					},
				},
			},
			types: {
				locked: {
					header: {
						button: {
							style: {
								display: 'none',
							},
						},
					},
				},
			},
		},
		types: {
			infoOpen: {
				info: {
					style: {
						transition: 'max-height 0.5s',
						maxHeight: '130px',
					},
				},
			},
			open: {
				style: {
					paddingBottom: '72px',
				},
			},
		},
		adjustable: {
			style: {},
			button: {
				text: {
					style: {},
					queries: {
						'(max-width: 767.98px)': {
							style: {},
						},
						'(min-width: 768px)': {
							style: {},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
				},
				icon: {
					style: {
						// marginRight: '4px',
						// width: '20px',
					},
					svg: {
						style: {
							fill: '#53A1AC',
						},
					},
				},
				states: {
					hover: {
						icon: {
							svg: {
								style: {
									fill: '#53A1AC',
								},
							},
						},
					},
				},
				types: {
					addTransactionRow: {
						style: {
							justifyContent: 'flex-end',
							marginTop: '16px',
							width: 'max-content',
						},
						queries: {
							'(max-width: 767.98px)': {
								style: { paddingLeft: '0' },
							},
							'(min-width: 768px)': {
								style: {},
							},
							'(min-width: 1200px)': {
								style: {},
							},
						},
						text: {
							style: {
								fontFamily: 'Arial, sans-serif',
								fontWeight: '400',
								fontSize: '16px',
							},
						},
						icon: {
							style: {
								flexGrow: '0',
								marginRight: '8px',
							},
							svg: {
								style: {},
							},
						},
					},
					adjustable: {
						fontFamily: 'Arial, sans-serif',
						fontWeight: '400',
						fontSize: '16px',
					},
				},
			},
			infobox: {
				queries: {
					'(max-width: 767.98px)': {
						style: {},
					},
					'(min-width: 768px)': {
						style: {
							margin: '0 10px',
						},
					},
					'(min-width: 1200px)': {
						style: {},
					},
				},
			},
			header: {
				style: {},
				queries: {
					'(max-width: 767.98px)': {
						style: {},
					},
					'(min-width: 768px)': {
						style: {
							margin: '0 10px',
						},
					},
					'(min-width: 1200px)': {
						style: {},
					},
				},
			},
			list: {
				style: {
					background: '#FAF0E7',
					padding: '20px 10px',
					marginBottom: '16px',
					marginLeft: '12px',
					marginRight: '12px',
					display: 'flex',
					flexDirection: 'column',
				},
				queries: {
					'(max-width: 767.98px)': {
						style: {},
					},
					'(min-width: 768px)': {
						style: {
							justifyContent: 'space-between',
							marginLeft: '0',
							marginRight: '0',
						},
					},
					'(min-width: 1200px)': {
						style: {},
					},
				},
				transaction: {
					style: {
						alignItems: 'flex-start',
					},
					queries: {
						'(max-width: 767.98px)': {
							style: {
								flexDirection: 'column',
								width: '100%',
							},
						},
						'(min-width: 768px)': {
							style: {},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
					group: {
						style: {
							width: '100%',
						},
						queries: {
							'(max-width: 767.98px)': {
								style: {},
							},
							'(min-width: 768px)': {
								style: {
									flex: '1',
									margin: '0 10px',
								},
							},
							'(min-width: 1200px)': {
								style: {},
							},
						},
						types: {
							date: {
								queries: {
									'(max-width: 767.98px)': {
										style: {},
									},
									'(min-width: 768px)': {
										style: {
											maxWidth: '150px',
										},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
							},
							amount: {
								queries: {
									'(max-width: 767.98px)': {
										style: {},
									},
									'(min-width: 768px)': {
										style: {
											maxWidth: '190px',
										},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
							},
						},
					},
					input: {
						style: {
							background: '#fff',
						},
					},
					label: {
						style: {},
					},
					button: {
						style: {},
						types: {
							remove: {
								style: {
									color: '#a38b80',
									marginTop: 'auto',
									marginBottom: '4px',
								},
								queries: {
									'(max-width: 767.98px)': {
										style: {
											paddingLeft: '0',
											marginTop: '12px',
										},
									},
									'(min-width: 768px)': {
										style: {},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
								text: {
									style: {
										color: '#512b2b',
										fontSize: '16px',
									},
								},
								icon: {
									style: {
										marginRight: '8px',
									},
									svg: {
										style: {
											fill: '#a38b80',
										},
									},
								},
							},
						},
					},
				},
			},
			controllers: {
				style: {
					display: 'flex',
					marginTop: '0',
					justifyContent: 'stretch',
					flexDirection: 'column',
					marginLeft: '12px',
					marginRight: '12px',
					marginBottom: '32px',
				},
				queries: {
					'(max-width: 767.98px)': {
						style: {},
					},
					'(min-width: 768px)': {
						style: {
							marginLeft: '0',
							marginRight: '0',
							flexDirection: 'row',
							alignItems: 'flex-end',
							justifyContent: 'flex-end',
							paddingRight: '0px',
							marginTop: '0',
						},
					},
					'(min-width: 1200px)': {
						style: {},
					},
				},
				button: {
					style: {},
					types: {
						default: {
							style: {
								marginTop: '8px',
								order: '1',
							},
							queries: {
								'(max-width: 767.98px)': {
									style: {},
								},
								'(min-width: 768px)': {
									style: {
										marginLeft: '16px',
										marginTop: '0',
									},
								},
								'(min-width: 1200px)': {
									style: {},
								},
							},
						},
						secondary: {
							style: {
								marginTop: '8px',
							},
							queries: {
								'(max-width: 767.98px)': {
									style: {},
								},
								'(min-width: 768px)': {
									style: {
										marginTop: '0',
									},
								},
								'(min-width: 1200px)': {
									style: {},
								},
							},
						},
						primary: {
							style: {
								order: '-1',
							},
							queries: {
								'(max-width: 767.98px)': {
									style: {},
								},
								'(min-width: 768px)': {
									style: {
										marginLeft: '16px',
										order: '2',
									},
								},
								'(min-width: 1200px)': {
									style: {},
								},
							},
						},
					},
				},
			},
		},
		adjust: {
			style: {},
			wrapper: {
				style: {
					background: '#FAF0E7',
					padding: '20px 10px',
					marginBottom: '16px',
					display: 'flex',
					flexDirection: 'column',
					marginLeft: '12px',
					marginRight: '12px',
				},
				queries: {
					'(max-width: 767.98px)': {
						style: {},
					},
					'(min-width: 768px)': {
						style: {
							padding: '20px 20px',
							marginLeft: '0',
							marginRight: '0',
						},
					},
					'(min-width: 1200px)': {
						style: {},
					},
				},
				total: {
					style: {
						marginBottom: '16px',
					},
					input: {
						style: {
							background: '#fff',
						},
					},
				},
				categories: {
					style: {
						margin: '0 0px',
					},
					queries: {
						'(max-width: 767.98px)': {
							style: {},
						},
						'(min-width: 768px)': {
							style: {
								margin: '0 -10px',
							},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
					category: {
						style: {
							marginBottom: '16px',
							flexDirection: 'column',
							alignItems: 'flex-start',
						},
						queries: {
							'(max-width: 767.98px)': {
								style: {},
							},
							'(min-width: 768px)': {
								style: {
									flexDirection: 'column',
									alignItems: 'flex-end',
								},
							},
							'(min-width: 1200px)': {
								style: {},
							},
						},
						group: {
							style: {
								width: '100%',
							},
							queries: {
								'(max-width: 767.98px)': {
									style: {},
								},
								'(min-width: 768px)': {
									style: {
										flex: '1',
										margin: '0 10px',
									},
								},
								'(min-width: 1200px)': {
									style: {},
								},
							},
							types: {
								customer: {
									style: {
										maxWidth: '222px',
									},
								},
								// date: {
								// 	queries: {
								// 		'(max-width: 767.98px)': {
								// 			style: {},
								// 		},
								// 		'(min-width: 768px)': {
								// 			style: {
								// 				maxWidth: '150px',
								// 			},
								// 		},
								// 		'(min-width: 1200px)': {
								// 			style: {},
								// 		},
								// 	},
								// },
								// amount: {
								// 	queries: {
								// 		'(max-width: 767.98px)': {
								// 			style: {},
								// 		},
								// 		'(min-width: 768px)': {
								// 			style: {
								// 				maxWidth: '150px',
								// 			},
								// 		},
								// 		'(min-width: 1200px)': {
								// 			style: {},
								// 		},
								// 	},
								// },
							},
						},
						input: {
							style: {
								backgroundColor: '#fff',
							},
						},
					},
					button: {
						text: {
							style: {},
							queries: {
								'(max-width: 767.98px)': {
									style: {},
								},
								'(min-width: 768px)': {
									style: {},
								},
								'(min-width: 1200px)': {
									style: {},
								},
							},
						},
						icon: {
							style: {
								// marginRight: '4px',
								// width: '20px',
							},
							svg: {
								style: {
									fill: '#53A1AC',
								},
							},
						},
						states: {
							hover: {
								icon: {
									svg: {
										style: {
											fill: '#53A1AC',
										},
									},
								},
							},
						},
						types: {
							remove: {
								style: {
									color: '#a38b80',
									marginTop: 'auto',
									marginBottom: '4px',
								},
								queries: {
									'(max-width: 767.98px)': {
										style: {
											paddingLeft: '0',
											marginTop: '12px',
										},
									},
									'(min-width: 768px)': {
										style: {},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
								text: {
									style: {
										color: '#512b2b',
										fontSize: '16px',
									},
								},
								icon: {
									svg: {
										style: {
											fill: '#a38b80',
										},
									},
								},
							},
							addCategoriesRow: {
								style: {
									justifyContent: 'flex-end',
									marginTop: '0px',
								},
								queries: {
									'(max-width: 767.98px)': {
										style: {
											paddingLeft: '0',
										},
									},
									'(min-width: 768px)': {
										style: {},
									},
									'(min-width: 1200px)': {
										style: {},
									},
								},
								text: {
									style: {
										fontFamily: 'Arial, sans-serif',
										fontWeight: '400',
										fontSize: '16px',
									},
								},
								icon: {
									style: {
										flexGrow: '0',
									},
									svg: {
										style: {},
									},
								},
							},
							info: {
								icon: {
									svg: {
										style: {
											fill: 'var(--primary-color)',
										},
									},
								},
							},
						},
					},
				},
			},
			controller: {
				style: {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'stretch',
					marginTop: '16px',
					marginBottom: '32px',
					flexDirection: 'column',
					marginLeft: '12px',
					marginRight: '12px',
				},
				queries: {
					'(max-width: 767.98px)': {
						style: {},
					},
					'(min-width: 768px)': {
						style: {
							marginLeft: '0',
							marginRight: '0',
							flexDirection: 'row',
							alignItems: 'flex-end',
							justifyContent: 'flex-end',
						},
					},
					'(min-width: 1200px)': {
						style: {},
					},
				},
				button: {
					style: {
						width: '100%',
					},
					queries: {
						'(max-width: 767.98px)': {
							style: {},
						},
						'(min-width: 768px)': {
							style: {
								width: 'auto',
							},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
					types: {
						default: {
							style: {
								marginTop: '8px',
							},
							queries: {
								'(max-width: 767.98px)': {
									style: {},
								},
								'(min-width: 768px)': {
									style: {
										marginLeft: '16px',
										marginTop: '0',
									},
								},
								'(min-width: 1200px)': {
									style: {},
								},
							},
						},
						primary: {
							style: {
								order: '-1',
							},
							queries: {
								'(max-width: 767.98px)': {
									style: {},
								},
								'(min-width: 768px)': {
									style: {
										marginLeft: '16px',
										order: '0',
									},
								},
								'(min-width: 1200px)': {
									style: {},
								},
							},
						},
					},
				},
			},
		},
	},
};
