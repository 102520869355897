import React from 'react';
import styled from 'styled-components';
import Modal from '@asteria/component-modal';
import { useHistory, useRoute } from '@asteria/utils/hooks';
import { TranslationService } from '@asteria/services-language';
import classNames from 'classnames';

import AddErpPage from './erpAdd';

const IntegrationsModal = styled(({ className }) => {
	const history = useHistory();
	const { params: { type } = {} } = useRoute();

	return (
		<Modal
			open
			className={classNames(className, 'asteria-integrations-modal')}
			title={TranslationService.get(
				['onboarding.modal.title', `onboarding.modal.${type}.title`],
				'Lägg till bokföringssystem',
			)}
			onClose={() => history.push('/')}
		>
			<AddErpPage />
		</Modal>
	);
})``;

IntegrationsModal.Styler = Modal.Styler;
IntegrationsModal.displayName = 'IntegrationsModal';

export default IntegrationsModal;
