import React, { useContext, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Dropdown } from '@asteria/component-form';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import Text from '@asteria/component-typography/text';
import { TranslationService } from '@asteria/services-language';
import { clearGraphData } from '@asteria/services-datalayer/services/graph/actions';
import { useStore, Getter, preProcess } from '@asteria/utils';
import { useFeature, FeatureFlag } from '@asteria/component-featureflag';
import { addDays, addWeeks, addMonths, addYears } from 'date-fns';
import {
	toggleFilter,
	removeFilter,
	setFilters,
	setCurrentDate,
} from '@asteria/services-datalayer/services/appstate/actions';
import Legends from '../components/legends';

const addTimeslice = (date, size, count = 1) => {
	if (size === 'day') {
		return addDays(date, count);
	}
	if (size === 'week') {
		return addWeeks(date, count);
	}
	if (size === 'month') {
		return addMonths(date, count);
	}
	if (size === 'year') {
		return addYears(date, count);
	}

	return date;
};

const HorizontalScroll = styled.div`
	display: flex;
	/* height: 54px; */
	height: 40px;
	flex-wrap: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	overflow: -moz-scrollbars-none;
	scrollbar-width: none;
	-ms-overflow-style: none;
	margin-bottom: -16px;
	padding-right: 32px;
	scrollbar-width: none;
	-ms-overflow-style: none;
	-webkit-mask: none;

	&::-webkit-scrollbar {
		height: 0 !important;
	}

	-webkit-mask: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0, black),
		color-stop(0.45, black),
		color-stop(0.7, black),
		color-stop(0.85, black),
		color-stop(1, rgba(0, 0, 0, 0))
	);
	mask: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0, black),
		color-stop(0.45, black),
		color-stop(0.7, black),
		color-stop(0.85, black),
		color-stop(1, rgba(0, 0, 0, 0))
	);

	@media only screen and (max-width: 768px) {
		display: flex;
		margin-top: 0;
		max-width: 300px !important;
	}

	@media only screen and (min-width: 769px) and (max-width: 1199px) {
		display: flex;
		margin-top: 0;
		max-width: 680px !important;
	}

	@media only screen and (min-width: 1200px) {
		display: flex;
		margin-top: 0;
		max-width: 100%;
	}

	&:after {
		content: '&nbsp;';
		display: block;
		width: 100px;
		background: transparent;
		height: 30px;
		color: transparent;
		order: 99;
	}
`;

const processColor = (color = '', prefix = 'color', theme = {}) => {
	if (color.startsWith('$')) {
		return preProcess(
			`var(--${color.replace('$', 'system-')}-${prefix})`,
			theme,
		);
	}

	return color;
};

const mapTagOrCategory = (
	{
		tag: { _id: tagId, name: tagName } = {},
		category: { _id: categoryId, name: categoryName } = {},
	} = {},
	prefix = '.',
) => {
	let key = tagName || categoryName;

	if (
		tagName === '$deposit' ||
		tagName === '$withdraw' ||
		tagName === '$forecast'
	) {
		if (categoryId === 'default') {
			return `default${prefix}${key.replace('$', '')}`;
		}

		return `default${prefix}${key.replace('$', '')}`;
	}

	if (categoryId) {
		key = categoryName.startsWith('$')
			? categoryName.replace('$', '')
			: categoryId;

		if (tagId) {
			key += `${prefix}${
				tagName.startsWith('$') ? tagName.replace('$', '') : tagId
			}`;
		}
	} else if (tagId) {
		key = `${tagName.startsWith('$') ? tagName.replace('$', '') : tagId}`;
	}

	return key;
};

const Prefix = styled.span`
	display: inline-block;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	margin-right: 5px;
	background-color: ${({ theme, part: { name, color } = {} }) =>
		processColor(color || name, 'color', theme)};
	background-image: ${({ theme, part: { name, color } = {} }) =>
		processColor(color || name, 'image', theme)};
`;
Prefix.displayName = 'Prefix';

const Part = styled(
	({ className, active, onClick = () => {}, part, children }) => (
		<div
			className={classNames(className, {
				'asteria-state-active': active,
			})}
			onClick={() => onClick(part)}
			onKeyPress={() => onClick(part)}
			role="button"
			tabIndex="-1"
		>
			<Prefix part={part} />
			{children}
		</div>
	),
)`
	display: flex;
	margin-right: 5px;
`;
Part.displayName = 'Part';

Part.Styler = {
	children: [
		{
			component: Prefix,
			base: [Getter('prefix')],
		},
		{
			component: Text,
			base: [Getter('text')],
		},
	],
};

const order = ['$taxes', '$salaries', '$transaction', '$invoices'];
const sort = ({ name: idA } = {}, { name: idB } = {}) => {
	const posA = order.indexOf(idA);
	const posB = order.indexOf(idB);

	return posA - posB;
};

const Categories = styled(
	({
		className,
		layout,
		categories = [],
		active = [],
		onClick = () => {},
	}) => (
		<div
			className={classNames(
				className,
				'asteria-graph-controlls-menu-categories',
			)}
		>
			<HorizontalScroll>
				{categories.sort(sort).map(category => {
					const label = TranslationService.get(
						[
							`graph.controllers.menu.category.${mapTagOrCategory(
								{
									category,
								},
							)}`,
							`graph.${layout}.controllers.menu.category.${mapTagOrCategory(
								{
									category,
								},
							)}`,
						],
						category.name,
					);

					if (label.startsWith('$')) {
						return null;
					}

					return (
						<Part
							key={category._id}
							part={category}
							active={active.includes(category)}
							onClick={onClick}
							className={`asteria-graph-controlls-menu-category-${mapTagOrCategory(
								{
									category,
								},
								'-',
							)}`}
						>
							<Text size="label">{label}</Text>
						</Part>
					);
				})}
			</HorizontalScroll>
		</div>
	),
)``;
Categories.displayName = 'Categories';
Categories.Styler = {
	children: [
		{
			component: Part,
			base: [Getter('category')],
		},
	],
};

const Tags = styled(
	({
		className,
		tags = [],
		category,
		includeLegends,
		layout,
		onClick = () => {},
	}) => {
		const { dispatch } = useContext(DatalayerContext);
		const hasSpreadFeature = useFeature({ key: 'legend-spread' });
		const hideBaseplates = useFeature({ key: 'hide-baseplates' });

		const [filters = []] = useStore(
			'store-appstate',
			({ filters: list }) => list,
		);

		const [timesliceSize] = useStore(
			'store-appstate',
			({ timesliceSize: size }) => size,
		);

		const [hasCredit] = useStore(
			'store-graph',
			({ hasCredit: bool = false }) => bool,
		);

		const legends =
			hideBaseplates &&
			filters.filter(({ type }) => type !== 'type').length > 0
				? []
				: [
						{
							key: 'history',
							label: TranslationService.get(
								'graph.account.stacked.legend.history',
							),
						},
						{
							key: 'forecast',
							label: TranslationService.get(
								'graph.account.stacked.legend.forecast',
							),
						},
				  ];

		if (hasCredit) {
			legends.push({
				key: 'credit',
				label: TranslationService.get([
					'graph.account.legend.credit',
					'graph.account.stacked.legend.credit',
				]),
			});
		}

		legends.push({
			key: 'risk',
			label: TranslationService.get([
				'graph.account.legend.risk',
				'graph.account.stacked.legend.risk',
			]),
		});

		const [open = 0] = useStore(
			'store-appstate',
			({ openInvoices: { deposit = 0 } = {} }) => deposit,
		);
		const [overdue = 0] = useStore(
			'store-appstate',
			({ overdueInvoices: { deposit = 0 } = {} }) => deposit,
		);

		const availableStatus = useMemo(
			() => [
				{
					value: 'all',
					label: 'graph.menu.status.all',
				},
				{
					value: 'PAID',
					label: 'graph.menu.status.paid',
				},
				...(open && open > 0
					? [
							{
								value: 'UNPAID',
								label: 'graph.menu.status.unpaid',
							},
					  ]
					: []),
				...(overdue && overdue > 0
					? [
							{
								value: 'OVERDUE',
								label: 'graph.menu.status.overdue',
							},
					  ]
					: []),

				{
					value: 'FORECAST',
					label: 'graph.menu.status.forecast',
				},
			],
			[open, overdue],
		);

		if (hasSpreadFeature && legends.length > 0) {
			legends.push({
				key: 'spread',
				label: TranslationService.get('graph.account.legend.spread'),
			});
		}

		const selected = useMemo(() => {
			const statusFilters = filters
				.filter(({ type }) => type === 'status')
				.map(({ config: { status } }) => status);

			if (statusFilters.length === 0) {
				return [availableStatus[0]];
			}

			return statusFilters.map(v =>
				availableStatus.find(({ value }) => value === v),
			);
		}, [availableStatus, filters]);

		const toggleStatus = useCallback(
			status => {
				dispatch(clearGraphData('cashflow-bar-graph', true));
				if (status === 'all') {
					filters
						.filter(({ type }) => type === 'status')
						.forEach(({ id }) => dispatch(removeFilter(id)));
				} else {
					dispatch(
						toggleFilter({
							id: `status-${status}`,
							type: 'status',
							config: {
								name: `$${status}`,
								status: status.toUpperCase(),
							},
						}),
					);

					const statusFilters = filters.filter(
						({ type }) => type === 'status',
					);

					if (status === 'FORECAST' && statusFilters.length === 0) {
						dispatch(
							setCurrentDate(
								addTimeslice(new Date(), timesliceSize, -1),
							),
						);
					}
				}
			},
			[dispatch, filters, timesliceSize],
		);

		return (
			<div
				className={classNames(
					className,
					'asteria-graph-controlls-menu-tags',
				)}
			>
				<HorizontalScroll>
					{includeLegends ? (
						<Legends
							className="asteria-graph-line-graph-component"
							legends={legends}
						/>
					) : null}
					{tags.map(tag => {
						const label = TranslationService.get(
							[
								`graph.controllers.menu.category.${mapTagOrCategory(
									{
										category,
										tag,
									},
								)}`,
								`graph.${layout}.controllers.menu.category.${mapTagOrCategory(
									{
										category,
										tag,
									},
								)}`,
							],
							tag.name,
						);

						if (label.startsWith('$')) {
							return null;
						}

						return (
							<Part
								key={tag._id}
								part={tag}
								active={tags.includes(tag)}
								onClick={onClick}
								className={`asteria-graph-controlls-menu-tag-${mapTagOrCategory(
									{
										tag,
									},
									'-',
								)}`}
							>
								<Text size="label">{label}</Text>
							</Part>
						);
					})}
				</HorizontalScroll>

				<FeatureFlag feature="asteria-improvements" invert>
					<Dropdown
						iconClosed="triangleDown"
						iconOpen="triangleUp"
						itemIconSelected="check"
						options={availableStatus}
						selected={selected}
						placeholder="Status"
						showSelected
						className="asteria-display-status-navgiation"
						type="link"
						onChange={({ value }) => toggleStatus(value)}
					/>
				</FeatureFlag>
			</div>
		);
	},
)`
	.asteria-forms-dropdown-toggle {
		border: 0px solid transparent;
		padding-right: 0;
		margin-right: 0;

		// background: #eae7e4;
		// border-radius: 40px;
		height: 32px;
		padding: 0 16px;
		min-height: 32px;

		&:hover,
		&.asteria-state-active {
			// background-color: #d3d3d3;
			.asteria-text {
				text-decoration: underline;
			}
		}

		.asteria-icon-wrapper {
			width: 16px !important;
			height: 6px !important;
			margin-left: 8px;
			svg {
				fill: #9f8c82 !important;
			}
		}
	}

	.asteria-forms-dropdown {
		margin-top: 4px;
		margin-left: auto;
		position: absolute;
		// z-index: 3;
		z-index: 5;
		right: 2px;
		@media (max-width: 990px) {
		}

		&.asteria-forms-dropdown-open {
			.asteria-forms-dropdown-menu-direction-down {
				max-height: 160px;
			}
		}

		.asteria-text {
			font-family: Swedbank Sans;
			font-weight: 700;
			font-size: 14px;
			color: var(--text-color);
			text-decoration: none;
		}
		.asteria-state-active {
		}
		.asteria-forms-dropdown-menu-direction-down {
			min-width: 220px;

			&::-webkit-scrollbar {
				background-color: #fff;
				width: 16px;
			}

			&::-webkit-scrollbar-track {
				background-color: #fff;
			}

			&::-webkit-scrollbar-thumb {
				background-color: #babac0;
				border-radius: 16px;
				border: 6px solid #fff;
			}
			right: 0;
			.asteria-forms-dropdown-item {
				.asteria-text {
					font-family: Swedbank Sans;
					font-weight: 700;
					font-size: 14px;
					color: var(--text-color);
				}
			}
		}
	}

	${({ hasFilter }) =>
		hasFilter
			? `
		--system-deposit-color: rgb(214,214,214);
		--system-withdraw-color: rgb(183,183,183);
	`
			: ''};
`;
Tags.displayName = 'Tags';
Tags.Styler = {
	children: [
		{
			component: Part,
			base: [Getter('tag')],
			typePrefix: 'asteria-graph-controlls-menu-tag',
		},
	],
};

const Menu = styled(({ className, layout }) => {
	const { dispatch } = useContext(DatalayerContext);
	const newNavigation = useFeature({ key: 'search' });
	const [open = 0] = useStore(
		'store-appstate',
		({ openInvoices: { total = 0 } = {} }) => total,
	);
	const [overdue = 0] = useStore(
		'store-appstate',
		({ overdueInvoices: { total = 0 } = {} }) => total,
	);

	const [filters = []] = useStore(
		'store-appstate',
		({ filters: list }) => list,
	);

	const [availableCategories = []] = useStore(
		'store-graph',
		state => state.availableCategories,
	);

	let categories = useMemo(
		() =>
			filters
				.filter(({ type }) => type === 'tag')
				.reduce((acc, filter) => {
					const cat = availableCategories.find(({ tags = [] }) =>
						tags.find(({ _id }) => filter.id === _id),
					);

					if (cat && !acc.includes(cat)) {
						return [...acc, cat];
					}

					return acc;
				}, []),
		[availableCategories, filters],
	);

	if (newNavigation) {
		categories = [];
	}

	const selectCategory = useCallback(
		category => {
			if (categories.includes(category)) {
				dispatch(setFilters([]));
			} else {
				const tagFilters = category?.tags?.map(({ _id, name }) => ({
					id: _id,
					type: 'tag',
					config: { name },
				}));
				dispatch(setFilters(tagFilters));
			}
		},
		[categories, dispatch],
	);

	/*
	const selectTag = useCallback(
		tag => {
			if (!tags.includes(tag)) {
				dispatch(setVisibleGraphTags(id, [tag]));
			} else {
				dispatch(setVisibleGraphTags(id, []));
			}
		},
		[id, dispatch, tags],
	);
	*/

	const typeFilters = filters.filter(({ type }) => type === 'type');
	const statusFilters = filters.filter(({ type }) => type === 'status');
	const DefaultTags = [];

	const hideBaseplates = useFeature({ key: 'hide-baseplates' });

	if (
		!hideBaseplates ||
		filters.filter(({ type }) => type !== 'type' && type !== 'currency')
			.length === 0
	) {
		if (
			typeFilters.length === 0 ||
			typeFilters.find(({ config: { type } }) => type === 'DEPOSIT')
		) {
			DefaultTags.push({
				_id: '$deposit',
				name: '$deposit',
			});
		}

		if (
			typeFilters.length === 0 ||
			typeFilters.find(({ config: { type } }) => type === 'WITHDRAW')
		) {
			DefaultTags.push({
				_id: '$withdraw',
				name: '$withdraw',
			});
		}
	}

	if (
		statusFilters.length === 0 ||
		statusFilters.find(({ config: { status } }) => status === 'FORECAST')
	) {
		DefaultTags.push({
			_id: '$forecast',
			name: '$forecast',
		});
	}

	if (
		(open > 0 && statusFilters.length === 0) ||
		statusFilters.find(({ config: { status } }) => status === 'UNPAID')
	) {
		DefaultTags.push({
			_id: '$unpaid',
			name: '$unpaid',
		});
	}

	if (
		(overdue > 0 && statusFilters.length === 0) ||
		statusFilters.find(({ config: { status } }) => status === 'OVERDUE')
	) {
		DefaultTags.push({
			_id: '$overdue',
			name: '$overdue',
		});
	}

	return (
		<div className={classNames(className, 'asteria-graph-controller-menu')}>
			<FeatureFlag feature="search" invert>
				<Categories
					key="categories"
					categories={availableCategories}
					active={categories}
					onClick={selectCategory}
					filters={filters}
					layout={layout}
				/>
			</FeatureFlag>
			<Tags
				includeLegends={layout === 'stacked'}
				layout={layout}
				hasFilter={
					filters.filter(({ type }) => type === 'tag').length > 0
				}
				tags={
					categories.length > 0
						? [...categories[0].tags, ...DefaultTags]
						: DefaultTags
				}
				category={
					categories.length > 0
						? categories[0]
						: { _id: 'default', name: 'default' }
				}
			/>
		</div>
	);
})`
	position: relative;
`;
Menu.displayName = 'Menu';
Menu.Styler = {
	children: [
		{
			component: Categories,
			base: [Getter('categories')],
		},
		{
			component: Tags,
			base: [Getter('tags')],
		},
		{
			typePrefix: 'asteria-horizontal-scroll',
			component: HorizontalScroll,
			base: [Getter('wrap')],
		},
	],
};

export default Menu;
