import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { compileStyles, StyleGetter } from '@asteria/utils';
import { ButtonContainer } from '@asteria/component-button';
import { TextV2, TitleV2 } from '@asteria/component-typography';
import IconV2 from '@asteria/component-icon';
import { LabelWrapper } from '@asteria/component-form/label';
import Alert from '@asteria/component-alert';

export const HeaderWrapper = styled.div`
	display: flex;
	position: relative;
`;

export const BodyWrapper = styled.div`
	overflow: hidden;
`;

export const FooterWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

const OnboardingStyler = {
	base: [StyleGetter('onboarding', {})],
	typePrefix: 'asteria-onboarding',
	children: [
		{
			component: HeaderWrapper,
			base: [StyleGetter(`header`, {})],
			children: [
				{
					component: TitleV2,
					base: [StyleGetter(`title`, {})],
				},
			],
		},
		{
			component: BodyWrapper,
			base: [StyleGetter(`body`, {})],
			children: [
				{
					component: ButtonContainer,
					base: [StyleGetter(`button`, {})],
				},
				{
					component: Alert,
					base: [StyleGetter(`alert`, {})],
				},
				{
					component: LabelWrapper,
					base: [StyleGetter(`label`, {})],
				},
				{
					component: TextV2,
					base: [StyleGetter(`text`, {})],
				},
				{
					component: TitleV2,
					base: [StyleGetter(`title`, {})],
				},
				{
					component: IconV2,
					base: [StyleGetter(`icon`, {})],
				},
			],
		},
		{
			component: FooterWrapper,
			base: [StyleGetter(`footer`, {})],
			children: [
				{
					component: ButtonContainer,
					base: [StyleGetter(`button`, {})],
				},
			],
		},
	],
};

export const WindowWrapper = styled.div`
	display: flex;
	height: 100%;
	position: relative;
	flex-direction: column;
	flex-basis: 50%;
	flex-grow: 1;

	.asteria-title-onboarding-header {
		padding-left: 16px;
		color: #f35b1c;
		padding: 8px 16px;
	}

	${({ theme }) =>
		// Wrapper styles
		compileStyles(OnboardingStyler, theme)}
`;

export default ({ className, style, header, body, footer, type }) => (
	<WindowWrapper
		className={classNames(className, {
			[`${OnboardingStyler.typePrefix}-${type}`]: type,
		})}
		style={style}
	>
		{header ? (
			<HeaderWrapper className="asteria-onboarding-header">
				{header}
			</HeaderWrapper>
		) : null}
		{body ? (
			<BodyWrapper className="asteria-onboarding-body">
				{body}
			</BodyWrapper>
		) : null}
		{footer ? (
			<FooterWrapper className="asteria-onboarding-footer">
				{footer}
			</FooterWrapper>
		) : null}
	</WindowWrapper>
);
