import React from 'react';
import styled from 'styled-components';
import { compileStyles, StyleGetter } from '@asteria/utils';
import PropTypes from 'prop-types';
import Text from './basic/text';

// Wrap Text component with styler sidebar
const HelpText = React.forwardRef(({ children, ...componentProps }, ref) => (
	<Text ref={ref} {...componentProps}>
		{children}
	</Text>
));

// Style container for the whole Help component
const HelpWrapper = styled.div`
	display: flex;

	${({ theme }) => compileStyles(HelpWrapper, theme)}
`;

HelpWrapper.Styler = {
	base: [StyleGetter('formV2.help', {})],
	children: [
		{
			component: Text,
			base: [StyleGetter(`text`, {})],
		},
	],
};

/**
 * Help component
 */
const Help = React.forwardRef(({ children, styler, validation }, ref) => (
	<HelpWrapper
		ref={ref}
		className={validation ? `asteria-${validation}` : ''}
	>
		<HelpText path="text" styler={styler}>
			{children}
		</HelpText>
	</HelpWrapper>
));

Help.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	styler: PropTypes.bool,
	validation: PropTypes.oneOf(['error', 'warning', 'success', '']),
};

Help.defaultProps = {
	children: null,
	styler: false,
	validation: '',
};

/**
 * Append theming sidebar and attach styler trigger to Help component
 */
export default Help;
