import { Observable, Subscriber } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { print } from 'graphql/language/printer';

class WebSocketOperation extends Subscriber {
	constructor(observer, websocket, query, variables, operationName) {
		super(observer);
		this.id =
			new Date().getTime().toString() +
			operationName +
			Math.floor(Math.random() * 10000000);
		this.query = query;
		this.variables = variables;
		this.operationName = operationName;
		this.websocket = websocket;
	}

	unsubscribe() {
		this.websocket.unsubscribe(this);
	}

	getMessage() {
		return {
			query:
				typeof this.query === 'string' ? this.query : print(this.query),
			variables: this.variables,
			operationName: this.operationName,
		};
	}

	handle(message) {
		this.next(message);
	}
}

class WebSocketService {
	constructor({ uri = 'ws://localhost', authStore, demo }) {
		this.queue = [];
		this.client = null;
		this.operations = new Map();
		this.isReconnecting = false;

		// eslint-disable-next-line no-undef
		this.WSImpl = WebSocket || MozWebSocket;

		if (demo) {
			return;
		}

		if (!this.WSImpl) {
			throw new Error(
				'Unable to find native implementation, or alternative implementation for WebSocket!',
			);
		}
		this.uri = uri
			.replace('http://', 'ws://')
			.replace('https://', 'wss://');

		const accessToken$ = authStore.query(state => state.tokens);

		accessToken$.pipe(skipWhile(val => !val)).subscribe(token => {
			this.token = token;
			this.connect(token);
		});
		// this.connect();
	}

	getStatus() {
		if (this.client === null) {
			return this.WSImpl.CLOSED;
		}

		return this.client.readyState;
	}

	connect(token = this.token) {
		this.client = new this.WSImpl(this.uri, 'graphql-ws');
		this.client.onopen = async () => {
			if (this.getStatus() === this.WSImpl.OPEN) {
				try {
					const { accessToken } = token;
					// Send CONNECTION_INIT message, no need to wait for connection to success (reduce roundtrips)
					this.sendMessage(undefined, 'connection_init', {
						Authorization: `Bearer ${accessToken}`,
					});
				} catch (err) {
					console.log(err);
					// this.sendMessage(undefined, 'connection_error', err);
					this.flushQueue();
				}
			}
		};

		this.client.onclose = () => {
			this.close(false, false);
		};

		this.client.onmessage = ({ data }) => {
			this.processData(data);
		};
	}

	sendMessage(id, type, payload) {
		this.sendMessageRaw({
			id,
			type,
			payload,
		});
	}

	sendMessageRaw(message) {
		switch (this.getStatus()) {
			case this.WSImpl.OPEN: {
				const serializedMessage = JSON.stringify(message);
				JSON.parse(serializedMessage);

				this.client.send(serializedMessage);
				break;
			}
			case this.WSImpl.CONNECTING:
				this.queue.push(message);

				break;
			case this.WSImpl.CLOSED:
				this.queue.push(message);
				break;
			default:
				break;
		}
	}

	processData(data) {
		let message = null;
		try {
			message = JSON.parse(data);
		} catch (e) {
			throw new Error(`Message must be JSON-parseable. Got: ${data}`);
		}

		switch (message.type) {
			case 'connection_ack':
				this.isReconnecting = false;
				this.flushQueue();
				break;
			case 'data': {
				const { id, payload } = message;

				const op = this.operations.get(id);

				if (op) {
					op.handle(payload);
				}

				break;
			}
			case 'complete': {
				const { id } = message;

				const op = this.operations.get(id);

				if (op) {
					op.unsubscribe();
				}
				break;
			}
			default:
				console.log(message);
				break;
		}
	}

	flushQueue() {
		this.queue.forEach(message => {
			this.sendMessageRaw(message);
		});
		this.queue = [];
	}

	close(isForced = true, closedByUser = true) {
		if (this.client !== null) {
			this.closedByUser = closedByUser;

			if (isForced) {
				this.sendMessage(undefined, 'connection_terminate', null);
			}

			this.client.close();
			this.client = null;

			if (!isForced) {
				this.tryReconnect();
			}
		}
	}

	tryReconnect() {
		if (this.isReconnecting) {
			return;
		}

		this.isReconnecting = true;
		if (this.tryReconnectTimeoutId) {
			clearTimeout(this.tryReconnectTimeoutId);
			this.tryReconnectTimeoutId = null;
		}

		this.operations.forEach(operation => {
			this.sendMessage(operation.id, 'start', operation.getMessage());
		});

		this.tryReconnectTimeoutId = setTimeout(() => {
			this.connect();
		}, 3000);
	}

	subscribe(request) {
		const { query, variables, operationName } = request;
		return new Observable(observer => {
			const operation = new WebSocketOperation(
				observer,
				this,
				query,
				variables,
				operationName,
			);

			this.operations.set(operation.id, operation);

			this.sendMessage(operation.id, 'start', operation.getMessage());

			return operation;
		});
	}

	unsubscribe(operation) {
		const op = this.operations.get(operation.id || operation);
		if (op) {
			this.operations.delete(op.id);
			this.sendMessage(op.id, 'stop', undefined);
		}
	}
}

export default WebSocketService;
