import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Getter, compileStyles } from '@asteria/utils';

const range = len => [...Array(len).keys()];

const Level = styled(({ className }) => (
	<div className={classNames(className)} />
))`
	margin-top: 1px;
	background-color: rgb(235, 231, 226);
	flex: 1 1 0%;
	border-radius: 3px;
`;

Level.displayName = 'Level';
Level.Styler = {
	typePrefix: 'asteria-security-bar-level',
};

const SecurityBar = styled(({ className, value, max, steps }) => {
	const roundedValue = Math.min(1, Math.round(value * 100) / 100);
	return (
		<div
			className={classNames(
				className,
				'asteria-security-bar',
				`asteria-security-bar-${Math.min(
					Math.floor(roundedValue / (max / steps)),
					steps,
				)}`,
			)}
		>
			{range(steps).map(step => (
				<Level
					key={`step_${step}`}
					className={classNames(
						'asteria-security-bar-level',
						`asteria-security-bar-level-${step + 1}`,
						{
							'asteria-state-active':
								roundedValue / (max / steps) >= step + 1,
						},
					)}
				/>
			))}
		</div>
	);
})`
	width: 12px;
	height: 20px;
	display: flex;
	flex-direction: column-reverse;
	margin-right: 8px;

	${({ theme }) => {
		const style = compileStyles(SecurityBar, theme);
		return style;
	}}
`;

SecurityBar.displayName = 'SecurityBar';

SecurityBar.Styler = {
	base: [Getter('securitybar')],
	typePrefix: 'asteria-security-bar',
	children: [
		{
			component: Level,
			base: [Getter('level')],
		},
	],
};
export default SecurityBar;
