import styled from 'styled-components';

import { preProcess } from '@asteria/utils';

const processColor = (color = '', prefix = 'color', theme = {}) => {
	if ((color || '').startsWith('$')) {
		return preProcess(
			`var(--${color.replace('$', 'system-')}-${prefix})`,
			theme,
		);
	}

	return color;
};

const Prefix = styled.span`
	display: inline-block;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	margin-right: 5px;
	background-color: ${({ part: { name, color } = {}, theme }) =>
		processColor(color || name, 'color', theme)};
	background-image: ${({ part: { name, color } = {}, theme }) =>
		processColor(color || name, 'image', theme)};
`;
Prefix.displayName = 'Prefix';

export default Prefix;
