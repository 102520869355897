export default {
	insights: {
		style: {
			marginTop: '16px',
			marginLeft: '-4px',
		},
		queries: {
			'(max-width: 767.98px)': {
				style: {
					flexDirection: 'column',
					margin: '16px 0',
					padding: '0 0',
					flex: '1 auto',
				},
			},
			'(min-width: 768px)': {
				style: {},
			},
			'(min-width: 1200px)': {
				style: {
					marginLeft: '-20px',
				},
			},
		},
	},
	insight: {
		button: {
			label: {
				style: {
					color: 'rgba(49,163,174,1)',
					display: 'block',
					fontFamily: 'Swedbank Headline',
					fontSize: '16px',
					fontWeight: '700',
					justifyContent: 'center',
					lineHeight: '26px',
					order: '2',
					textAlign: 'center',
					textDecoration: 'none',
					width: '100%',
				},
			},
			style: {
				alignItems: 'center',
				backgroundColor: 'none',
				borderBottomLeftRadius: '3px',
				borderBottomRightRadius: '3px',
				borderColor: 'rgba(217,217,217,1)',
				borderRadius: '2px',
				borderTopLeftRadius: '3px',
				borderTopRightRadius: '3px',
				borderWidth: '1px',
				cursor: 'pointer',
				display: 'flex',
				flexDirection: 'row',
				flexShrink: 1,
				height: '20px',
				justifyContent: 'center',
				marginLeft: 'auto',
				marginRight: 'auto',
				marginTop: 'auto',
				paddingBottom: '16px',
				paddingLeft: '12px',
				paddingRight: '12px',
				paddingTop: '10px',
				position: 'relative',
				textDecoration: 'none',
				width: '100%',
			},
			text: {
				style: {
					fontSize: '16px',
					textDecoration: 'none',
					fontFamily: 'Swedbank Headline',
					color: '#31A3AE',
				},
			},
		},
		contentWrapper: {
			content: {
				style: {
					display: 'flex',
					flex: 1,
					zIndex: 'auto',
				},
				text: {
					style: {
						color: '#696969',
						display: 'block',
						fontFamily: 'Arial,Helvetica,sans-serif',
						fontSize: '14px',
						lineHeight: '18px',
						textAlign: 'center',
					},
				},
			},
			style: {
				display: 'flex',
				flexDirection: 'row',
				flexGrow: '1',
				lineHeight: '18px',
				marginBottom: '20px',
				marginLeft: 'auto',
				marginRight: 'auto',
				marginTop: '0px',
				minHeight: '0px',
				width: '100%',
				zIndex: 'auto',
			},
			queries: {
				'(max-width: 767.98px)': {
					style: {
						width: 'auto',
					},
				},
				'(min-width: 768px)': {
					style: {},
				},
				'(min-width: 1200px)': {
					style: {},
				},
			},
		},
		headerWrapper: {
			header: {
				style: {
					display: 'flex',
					flex: 1,
					zIndex: 'auto',
				},
				text: {
					style: {
						display: 'block',
						textAlign: 'center',
						fontFamily: 'Swedbank Headline',
						fontSize: '18px',
						fontWeight: '600',
						color: 'rgba(34,34,34,1)',
						margin: '0 auto',
					},
					queries: {
						'(max-width: 767.98px)': {
							style: {
								alignItems: 'center',
								margin: '0 auto',
								width: '80%',
							},
						},
						'(min-width: 768px)': {
							style: {},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
				},
			},
			style: {
				borderRadius: '30px',
				color: '#222',
				flexDirection: 'row',
				flexGrow: '0',
				flexShrink: '1',
				fontFamily: "'Swedbank Headline',sans-serif",
				fontSize: '18px',
				fontWeight: '600',
				height: '60px',
				justifyContent: 'center',
				marginBottom: '16px',
				marginLeft: 'auto',
				marginRight: 'auto',
				minHeight: '0px',
				padding: '0',
				textAlign: 'center',
				width: '100%',
				zIndex: 'auto',
			},
			queries: {
				'(max-width: 767.98px)': {
					style: {
						height: 'auto',
						marginBottom: '8px',
					},
				},
				'(min-width: 768px)': {
					style: {},
				},
				'(min-width: 1200px)': {
					style: {},
				},
			},
		},
		style: {
			alignItems: 'center',
			borderRadius: '0px 0px 0px 0px',
			boxShadow: '0px 0px 0px rgba(0,0,0,0.25)',
			boxSizing: 'border-box',
			cursor: 'pointer',
			display: 'flex',
			flexDirection: 'column',
			height: '230px',
			justifyContent: 'stretch',
			marginLeft: '20px',
			marginTop: '10px',
			padding: '24px 20px',
			paddingBottom: '24px',
			paddingLeft: '20px',
			paddingRight: '20px',
			paddingTop: '24px',
			textAlign: 'center',
			width: '226px',
		},
		queries: {
			'(max-width: 767.98px)': {
				style: {
					margin: '0 12px 12px',
					padding: '16px 16px',
					width: 'auto',
					flexGrow: 1,
					textAlign: 'center',
					height: '150px',
				},
			},
			'(min-width: 768px)': {
				style: {
					height: '230px',
				},
			},
			'(min-width: 1200px)': {
				style: {},
			},
		},
		types: {
			guide: {
				states: {
					hover: {
						style: {
							backgroundColor: 'rgba(242,241,237,1)',
						},
					},
				},
				style: {
					backgroundColor: 'rgba(230,225,219,1)',
				},
			},
			open: {
				states: {
					hover: {
						style: { backgroundColor: 'rgba(255,243,213,1)' },
					},
				},
				style: {
					backgroundColor: 'rgba(255,239,199,1)',
				},
			},
			overdue: {
				states: {
					hover: {
						style: { backgroundColor: 'rgba(251,237,244,1)' },
					},
				},
				style: {
					backgroundColor: 'rgba(248,222,235,1)',
				},
			},
		},
	},
};
