/**
 * Format given number into a fine readable string representing amount of currency
 * @param inputNumber {Number} - actual amount
 * @param invert {boolean} - invert sign or not
 * @param divide {boolean} - divide on 1000 or not (as output string represents thousands)
 * @param skipSign {boolean} - skip sign check and return unsigned amount.
 * @param defaultFractionPart {string} - fractional part of the number which should be added to integer result
 * @return {string}
 */
export default (
	inputNumber,
	invert = false,
	divide = false,
	skipSign = false,
	{ defaultFractionPart = '', thousand = 'tkr', round = true } = {},
) => {
	// Set default value for number if it is not correct
	const number = inputNumber === null || !inputNumber ? 0 : inputNumber;
	// Divide number, if flag `divide` is set
	const num =
		divide && Math.abs(number) > 1000
			? Math.abs(number) / 1000
			: Math.abs(number);

	let str;

	if (num >= 1) {
		// Leave large numbers as is
		if (round) {
			str = `${Math.round(num).toString()}`;
		} else {
			str = `${num.toString()}`;
		}
	} else if (!Number.isInteger(num)) {
		// Fix up to three digits of fraction part, if the number is quite small
		if (round) {
			str = (Math.round(num * 1000) / 1000).toString();
		} else {
			str = (num / 1000).toString();
		}
	} else {
		str = num.toString();
	}

	const parts = str.split('.');
	// Fill integer part with spaces
	const integerPart = parts
		.shift()
		.split('')
		.reverse()
		.reduce((acc, val, index) => {
			if (index !== 0 && index % 3 === 0) {
				return `${val} ${acc}`;
			}

			return val + acc;
		}, '');

	let faction = false;
	if (parts.length) {
		faction = parts.shift();
		if (faction.length === 1) {
			faction = `${faction}0`;
		}

		faction = faction.substring(0, 2);
	}

	// Add to integer part fraction one, if it exists
	str = `${integerPart}${faction ? `.${faction}` : defaultFractionPart}`;

	// Add signs
	if (skipSign !== true) {
		const sign = Math.sign(number * (invert ? -1 : 1));

		// Leave zero number without sign
		if (sign) {
			str = `${sign > 0 ? '+' : '-'}${str}`;
		}
	}
	/*
	else {
		const sign = Math.sign(number * (invert ? -1 : 1));

		if (sign < 0) {
			str = `-${str}`;
		}
	}
	*/

	if (divide && Math.abs(number) > 1000 && thousand) {
		str = `${str} ${thousand}`;
	}

	return str;
};
