import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Getter, formatNumber, formatRound } from '@asteria/utils';
import { Title, Text } from '@asteria/component-core/typography';
import { FeatureFlag } from '@asteria/component-featureflag';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { format } from 'date-fns';
import Icon from '@asteria/component-core/icon';
import Button from '@asteria/component-core/button';
import { Translation, TranslationService } from '@asteria/services-language';
import { RadioGroupV2 } from '@asteria/component-form';
import {
	removeTransactions,
	saveEditedTransactions,
} from '@asteria/services-datalayer/services/adjustable/actions';
import { useHistory } from '@asteria/utils/hooks';
import RequestHelp from '@asteria/component-support/components/requestHelp';

import Tags from '../components/tags';
import InfoBox, { InfoBoxToggle } from '../components/infoBox';
import Cell from './cell';

const DetailsOverview = styled.div``;
const Details = styled.div``;

const getDescription = description => {
	if (description.startsWith('$')) {
		return TranslationService.get(
			`list.transactions.transaction.description.${description.replace(
				'$',
				'',
			)}`,
		);
	}

	return description;
};

const getTransactionAccount = item => {
	const links = item?.links || [];

	if (links.find(({ type }) => type === 'BANK_TRANSACTION')) {
		const transaction = links.find(
			({ type }) => type === 'BANK_TRANSACTION',
		);
		return transaction?.accountDetails?.identifiers?.accountNumber || false;
	}

	return false;
};

/*
const getBankTransaction = item => {
	const links = item?.links || [];

	if (links.find(({ type }) => type === 'BANK_TRANSACTION')) {
		const transaction = links.find(
			({ type }) => type === 'BANK_TRANSACTION',
		);
		return transaction?.accountDetails;
	}

	return false;
};

const isBankAccount = item => {
	const links = item?.links || [];

	if (links.find(({ type }) => type === 'BANK_TRANSACTION')) {
		return true;
	}

	return false;
};
*/

const getInvoice = item => {
	const links = item?.links || [];

	if (links.find(({ type }) => type === 'INVOICE_PAYMENT')) {
		const transaction = links.find(
			({ type }) => type === 'INVOICE_PAYMENT',
		);
		return transaction?.invoiceDetails;
	}

	return false;
};

const isInvoice = item => {
	const links = item?.links || [];

	if (links.find(({ type }) => type === 'INVOICE_PAYMENT')) {
		return true;
	}

	return false;
};

const getRegisteredDate = item => {
	const links = item?.links || [];
	if (links.find(({ type }) => type === 'INVOICE_PAYMENT')) {
		const invoice = links.find(({ type }) => type === 'INVOICE_PAYMENT');
		return TranslationService.get(
			[
				`list.transactions.transaction.registerdate.${item?.status?.toLowerCase()}`,
				`list.transactions.transaction.registerdate`,
			],
			'',
			{
				sent: invoice?.invoiceDetails?.dates?.invoiceSent || '',
				due: invoice?.invoiceDetails?.dates?.invoiceDue || '',
				paid: invoice?.invoiceDetails?.dates?.invoicePaid || '',
			},
		);
	}

	if (links.find(({ type }) => type === 'BANK_TRANSACTION')) {
		const transaction = links.find(
			({ type }) => type === 'BANK_TRANSACTION',
		);
		if (transaction?.dates?.booked) {
			return TranslationService.get(
				[
					`list.transactions.transaction.registerdate.${item?.status?.toLowerCase()}`,
					`list.transactions.transaction.registerdate`,
				],
				'',
				{
					sent: transaction?.accountDetails?.dates?.booked || '',
					due: transaction?.accountDetails?.created || '',
					paid: transaction?.accountDetails?.created || '',
				},
			);
		}
	}

	return '';
};

const getClientId = item => {
	const links = item?.links || [];

	if (links.find(({ type }) => type === 'CLIENT')) {
		const client = links.find(({ type }) => type === 'CLIENT');
		return client?.id || false;
	}

	if (links.find(({ type }) => type === 'INVOICE_PAYMENT')) {
		const invoice = links.find(({ type }) => type === 'INVOICE_PAYMENT');
		return invoice?.invoiceDetails?.client?.id || false;
	}

	return false;
};

const TransactionDetails = styled(
	React.memo(
		({
			className,
			item = {},
			setEdit,
			edit = false,
			showRemove = false,
			setRemove = () => {},
			setActive,
			showEditConfirm: showEdit,
		}) => {
			const { dispatch } = useContext(DatalayerContext);
			const history = useHistory();
			const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
			const [showEditConfirm, setShowEditConfirm] = useState(false);
			const [removeRepeatable, setRemoveRepeatable] = useState(false);
			const [editRepeatable, setEditRepeatable] = useState(false);
			const [isCurrencyHelpOpen, setCurrencyHelpOpen] = useState(false);

			useEffect(() => {
				setShowRemoveConfirm(showRemove);
			}, [showRemove]);

			useEffect(() => {
				setShowEditConfirm(showEdit);
			}, [showEdit]);

			let descriptionSubText = TranslationService.get(
				item?.meta?.tags?.map(
					tag =>
						`list.transactions.transaction.description.${tag?.name?.replace(
							'$',
							'',
						)}.subtext`,
				),
				'',
				{ item },
			);

			if (item.message) {
				descriptionSubText = TranslationService.get(
					item.message,
					item.message,
					{ item },
				);
			}

			const clientId = getClientId(item);

			return (
				<div className={classNames(className)}>
					<FeatureFlag feature="sidepane-transaction-detail">
						<DetailsOverview className="asteria-transaction-detail-header">
							<Cell className="asteria-transaction-cell-overview-description">
								<Text size="body">
									{getDescription(
										item?.description || 'Unknown',
									)}
								</Text>
							</Cell>
							{descriptionSubText ? (
								<Text size="small">{descriptionSubText}</Text>
							) : null}
							<Cell className="asteria-transaction-cell-overview-amount">
								<Text
									size="body"
									className="asteria-text-amount"
								>
									{`${formatNumber(
										item?.sums?.original?.total || 0,
										item?.type === 'WITHDRAW',
									)} ${item?.sums?.original?.currency || ''}`}
								</Text>
							</Cell>
							{getRegisteredDate(item) ? (
								<Cell className="asteria-transaction-cell-overview-date">
									<Text size="body">
										{TranslationService.get(
											'list.transactions.transaction.registerdate.label',
										)}
										<span
											style={{
												marginLeft: '4px',
											}}
										>
											{getRegisteredDate(item)}
										</span>
									</Text>
								</Cell>
							) : null}
						</DetailsOverview>
					</FeatureFlag>
					<Details className="asteria-transaction-detail-inner">
						<FeatureFlag feature="customerPanel">
							{clientId ? (
								<Cell className="asteria-transaction-cell-text-customer">
									<Title size="title4">
										{TranslationService.get(
											'list.transactions.transaction.customer.label',
										)}
									</Title>
									<Button
										type="link"
										size="small"
										text={getDescription(
											item?.description || 'Unknown',
										)}
										style={{
											padding: '8px 0',
										}}
										onClick={e => {
											e.stopPropagation();
											// onClick(false);
											// setEdit(false);
											history.push(
												`/customer/${clientId}`,
											);
										}}
									/>
								</Cell>
							) : null}
						</FeatureFlag>
						<Cell className="asteria-transaction-cell-text-status">
							<Title size="title4">
								{TranslationService.get(
									'list.transactions.transaction.status.label',
								)}
							</Title>
							<Icon asset={item?.status?.toLowerCase()} />
							<Text size="strong">
								{TranslationService.get(
									`list.transactions.transaction.status.${item?.status?.toLowerCase()}`,
								)}
							</Text>
						</Cell>
						<Cell className="asteria-transaction-cell-register-date">
							<Title size="title4">
								{TranslationService.get(
									'list.transactions.transaction.paymentdate.label',
								)}
							</Title>
							<Text size="strong" style={{ textAlign: 'right' }}>
								{format(
									item?.paymentDate || new Date(),
									'YYYY-MM-DD',
								)}
							</Text>
						</Cell>
						<FeatureFlag feature="adjustable-repeatable">
							{item?.recurrentEvent ? (
								<Cell className="asteria-transaction-cell-repeatable">
									<Title size="title4">
										{TranslationService.get(
											'list.transactions.transaction.repeatable.label',
										)}
									</Title>
									<Text size="strong">
										{TranslationService.get(
											`forms.repeatable.${
												item?.recurrentEvent.period
											}.label`,
										)}
									</Text>
								</Cell>
							) : null}
						</FeatureFlag>
						{getTransactionAccount(item) ? (
							<Cell className="asteria-transaction-cell-account">
								<Title size="title4">
									{TranslationService.get(
										'list.transactions.transaction.account.label',
									)}
								</Title>
								<Text size="strong">
									{getTransactionAccount(item)}
								</Text>
							</Cell>
						) : null}
						<FeatureFlag feature="transaction-details-source">
							{item?.status === 'FORECAST' ? (
								<Cell className="asteria-transaction-cell-source">
									<Title size="title4">
										{TranslationService.get(
											'list.transactions.transaction.source.label',
										)}
									</Title>
									<Text size="strong">
										{TranslationService.get(
											item.manual
												? 'list.transactions.transaction.source.user'
												: 'list.transactions.transaction.source.asteria',
										)}
									</Text>
								</Cell>
							) : null}
						</FeatureFlag>
						<FeatureFlag feature="transaction-row-single-tag">
							<Cell className="asteria-transaction-cell-all-categories">
								<Title size="title4">
									{TranslationService.get(
										'list.transactions.transaction.categories.label',
									)}
								</Title>
								<Tags item={item} />
							</Cell>
						</FeatureFlag>
						<FeatureFlag feature="fx-module">
							{item?.oracle?.currency &&
								item?.sums?.original?.currency !==
									item?.sums?.display?.currency &&
								item?.status !== 'PAID' && (
									<>
										<InfoBoxToggle
											open={isCurrencyHelpOpen}
											toggle={() =>
												setCurrencyHelpOpen(
													!isCurrencyHelpOpen,
												)
											}
										/>
										<InfoBox open={isCurrencyHelpOpen}>
											<Translation
												Component={Text}
												translationKey="list.transactions.transaction.currency.help"
											/>
										</InfoBox>
									</>
								)}
						</FeatureFlag>
						{item?.sums?.display?.currency !==
							item?.sums?.original?.currency &&
							item?.oracle?.currency &&
							item?.status === 'PAID' && (
								<>
									<Cell className="asteria-transaction-cell-currency">
										<Title size="title4">
											{TranslationService.get(
												'list.transactions.transaction.currency.label',
												'Valuta',
											)}
										</Title>
										<div className="asteria-currency-convert">
											<div className="asteria-currency-org">
												<div
													className={`currency-flag currency-flag-${item?.sums?.original?.currency?.toLowerCase()}`}
												/>
												<Text size="strong">
													{`${
														item?.sums?.original
															?.currency
													}`}
												</Text>
											</div>
											<Text size="small">
												{`1 ${
													item?.sums?.original
														?.currency
												} = ${formatRound(
													item?.sums?.display?.rate,
												)} ${
													item?.sums?.display
														?.currency
												}`}
											</Text>
											{isInvoice(item) && (
												<Text size="smaller">
													{`${TranslationService.get(
														'list.transactions.transaction.rate.sent.label',
														'Skickad',
													)} 1 ${
														getInvoice(item)?.sums
															?.original?.currency
													} = ${formatRound(
														getInvoice(item)?.sums
															?.display?.rate,
													)} ${
														item?.sums?.display
															?.currency
													}`}
												</Text>
											)}
										</div>
									</Cell>
									<Cell
										className={classNames(
											'asteria-transaction-cell-currency-results',
											{
												'asteria-state-negative':
													item.oracle?.currency?.max
														?.display?.total < 0,
											},
										)}
									>
										<Title size="title4">
											{TranslationService.get(
												item.oracle?.currency?.max
													?.display?.total < 0
													? 'list.transactions.transaction.loss.label'
													: 'list.transactions.transaction.gain.label',
											)}
										</Title>
										<Text size="strong">
											{formatNumber(
												item.oracle?.currency?.max
													?.display?.total,
											)}
										</Text>
									</Cell>
								</>
							)}
						<FeatureFlag feature="fx-module">
							{item?.oracle?.currency &&
								item?.sums?.original?.currency !==
									item?.sums?.display?.currency &&
								item?.status !== 'PAID' && (
									<>
										<Cell className="asteria-transaction-cell-currency">
											<Title size="title4">
												{TranslationService.get(
													'list.transactions.transaction.currency.label',
													'Valuta',
												)}
											</Title>
											<div className="asteria-currency-convert">
												<div className="asteria-currency-org">
													<div
														className={`currency-flag currency-flag-${item?.sums?.original?.currency?.toLowerCase()}`}
													/>
													<Text size="strong">
														{`${
															item?.sums?.original
																?.currency
														}`}
													</Text>
												</div>
												<Text size="small">
													{`1 ${
														item?.sums?.original
															?.currency
													} = ${formatRound(
														item?.sums?.display
															?.rate,
													)} ${
														item?.sums?.display
															?.currency
													}`}
												</Text>
											</div>
										</Cell>
										<Cell className="asteria-transaction-cell-risk-low">
											<Title size="title4">
												{TranslationService.get(
													'list.transactions.transaction.risk.label',
													'Valutarisk, hög',
												)}
											</Title>
											<Text size="strong">
												{`±${formatNumber(
													item?.oracle?.currency?.min
														?.display?.total,
													false,
													false,
													true,
												)}`}
											</Text>
										</Cell>
										<RequestHelp type="currencyrisk" />
									</>
								)}
						</FeatureFlag>
						<FeatureFlag
							feature="transaction-options-dropdown"
							invert
						>
							<FeatureFlag feature="adjustable">
								{showRemoveConfirm === false &&
								item?.status === 'FORECAST' ? (
									<FeatureFlag
										feature="sidepane-transaction-detail"
										invert
									>
										<Cell
											className="asteria-transaction-cell-adjustable"
											key="adjustable-forecast"
										>
											{!edit && (
												<Button
													type="default"
													size="medium"
													className="asteria-button-remove"
													text={TranslationService.get(
														'button.remove',
													)}
													onClick={e => {
														e.stopPropagation();
														setShowRemoveConfirm(
															true,
														);
														setRemove(true);
													}}
												/>
											)}
											<Button
												type="secondary"
												className="asteria-button-adjust"
												size="medium"
												text={TranslationService.get(
													edit
														? 'button.save'
														: 'button.edit',
												)}
												onClick={e => {
													if (edit) {
														setEdit(false);
														dispatch(
															saveEditedTransactions(
																[
																	item._id ||
																		item.id,
																],
															),
														);
													} else if (
														item?.recurrentEvent
													) {
														setShowEditConfirm(
															true,
														);
													} else {
														setEdit(true);
													}

													e.stopPropagation();
												}}
											/>
										</Cell>
									</FeatureFlag>
								) : null}
								<InfoBox
									open={showEditConfirm}
									key="adjustable-confirm"
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											width: '100%',
										}}
									>
										<Text>
											{TranslationService.get(
												'list.transactions.transaction.confirm.edit',
												'Vad vill du editera?',
											)}
										</Text>
										{item?.recurrentEvent ? (
											<RadioGroupV2
												className="asteria-checkbox-group-wrapper-repeatable"
												style={{
													flexDirection: 'column',
													alignItems: 'flex-start',
													textAlign: 'left',
												}}
												selected={[
													editRepeatable ? 1 : 0,
												]}
												onChange={index => {
													setEditRepeatable(
														index === 1,
													);
												}}
												/* 											items={[
												`Bara denna transaktion från ${format(
													item.paymentDate,
													'YYYY-MM-DD',
												)}`,
												'Alla liknande transaktioner',
											]} */
												items={[
													TranslationService.get(
														'list.transactions.transaction.edit.series.one',
													),
													TranslationService.get(
														'list.transactions.transaction.edit.series.all',
													),
												]}
											/>
										) : null}
									</div>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											width: '100%',
											justifyContent: 'flex-end',
										}}
									>
										<Button
											type="secondary"
											className="asteria-button-adjust"
											size="medium"
											text={TranslationService.get(
												'button.yes',
											)}
											onClick={e => {
												e.stopPropagation();
												setEdit(true, editRepeatable);
											}}
											style={{
												padding: '8px 16px',
												marginLeft: '16px',
												width: 'auto',
											}}
										/>
									</div>
								</InfoBox>
								<InfoBox
									open={showRemoveConfirm}
									key="adjustable-confirm"
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											width: '100%',
										}}
									>
										<Text>
											{TranslationService.get(
												'list.transactions.transaction.confirm.remove',
											)}
										</Text>
										{item?.recurrentEvent ? (
											<FeatureFlag feature="adjustable-repeatable">
												<RadioGroupV2
													className="asteria-checkbox-group-wrapper-repeatable"
													style={{
														flexDirection: 'column',
														alignItems:
															'flex-start',
														textAlign: 'left',
													}}
													selected={[
														removeRepeatable
															? 1
															: 0,
													]}
													onChange={index => {
														setRemoveRepeatable(
															index === 1,
														);
													}}
													/* 											items={[
													`Bara denna transaktion från ${format(
														item.paymentDate,
														'YYYY-MM-DD',
													)}`,
													'Alla liknande transaktioner',
												]} */
													items={[
														TranslationService.get(
															'list.transactions.transaction.edit.series.one',
														),
														TranslationService.get(
															'list.transactions.transaction.edit.series.all',
														),
													]}
												/>
											</FeatureFlag>
										) : null}
									</div>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											width: '100%',
											justifyContent: 'flex-end',
										}}
									>
										<Button
											type="default"
											size="medium"
											className="asteria-button-remove"
											text={TranslationService.get(
												'button.no',
											)}
											onClick={e => {
												e.stopPropagation();
												setShowRemoveConfirm(false);
												setRemove(false);
											}}
											style={{
												padding: '8px 16px',
												marginLeft: '16px',
												width: 'auto',
											}}
										/>
										<Button
											type="secondary"
											className="asteria-button-adjust"
											size="medium"
											text={TranslationService.get(
												'button.yes',
											)}
											onClick={e => {
												e.stopPropagation();
												dispatch(
													removeTransactions(
														[item],
														removeRepeatable,
													),
												);
											}}
											style={{
												padding: '8px 16px',
												marginLeft: '16px',
												width: 'auto',
											}}
										/>
									</div>
								</InfoBox>
							</FeatureFlag>
						</FeatureFlag>
						<FeatureFlag feature="transaction-options-dropdown">
							<Button
								onClick={setActive}
								type="link"
								className="asteria-button-close-accordion"
								size="medium"
							>
								{TranslationService.get(
									'transaction.details.close.accordion',
									'Stäng',
								)}
							</Button>
						</FeatureFlag>
					</Details>
				</div>
			);
		},
	),
)`
	${Cell} {
		display: flex;
	}
	.asteria-checkbox-group-wrapper-repeatable {
		.asteria-checkbox-wrapper {
			padding-right: 0;
			.asteria-checkbox-mark {
				max-width: 20px;
				max-height: 20px;
				min-width: 20px;
			}
		}
		.asteria-checkbox-label {
			font-size: 14px;
		}
	}

	.transaction.details.close.accordion {
		margin-left: auto;
		margin-right: 0;
		padding: 0;
		margin-top: 26px;
	}
`;

TransactionDetails.displayName = 'TransactionDetails';

TransactionDetails.Styler = {
	typePrefix: 'asteria-transaction-details',
	children: [
		{
			component: DetailsOverview,
			base: [Getter('overview')],
			children: [
				{
					component: Cell,
					base: [Getter('cell')],
				},
			],
		},
		{
			component: Details,
			base: [Getter('details')],
			children: [
				{
					component: Cell,
					base: [Getter('cell')],
				},
			],
		},
	],
};

export default TransactionDetails;
