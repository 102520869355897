export default {
	actionbar: {
		style: {
			position: 'fixed',
			bottom: '0px',
			width: '100%',
			left: '50%',
			zIndex: '5',
			transform: 'translateX(calc(-50% + 0.5px))',
			boxShadow: '0px 2px 1px 0px rgba(0,0,0,0.112)',
			background: 'white',
			textAlign: 'center',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			padding: '0 16px 0 24px',
			height: '72px',
			maxWidth: '1095px',
		},
		queries: {
			'(max-width: 767.98px)': {
				style: {
					left: '0',
					transform: 'translateX(0%)',
					flexDirection: 'column',
					height: 'auto',
					padding: '0 0',
				},
			},
			'(min-width: 768px)': {
				style: {},
			},
			'(min-width: 1200px)': {
				style: {},
			},
		},
		title: {
			style: {
				color: 'var(--transaction-list-text-color)',
				fontFamily: 'Swedbank Sans',
				fontWeight: '700',
				fontSize: '16px',
			},
			queries: {
				'(max-width: 767.98px)': {
					style: {
						padding: '0 60px',
						marginTop: '16px',
						marginBottom: '12px',
					},
				},
				'(min-width: 768px)': {
					style: {},
				},
				'(min-width: 1200px)': {
					style: {},
				},
			},
			types: {},
		},
		actions: {
			style: {
				display: 'flex',
				flexDirection: 'column',
				marginLeft: 'auto',
			},
			queries: {
				'(max-width: 767.98px)': {
					style: {
						marginLeft: '0',
						width: '100%',
						padding: '8px 8px',
						boxSizing: 'border-box',
					},
				},
				'(min-width: 768px)': {
					style: {
						flexDirection: 'row',
					},
				},
				'(min-width: 1200px)': {
					style: {},
				},
			},
			button: {
				style: {},
				queries: {
					'(max-width: 767.98px)': {
						style: {},
					},
					'(min-width: 768px)': {
						style: {
							width: 'auto',
							minWidth: '126px',
						},
					},
					'(min-width: 1200px)': {
						style: {},
					},
				},
				types: {
					default: {
						style: {},
						queries: {
							'(max-width: 767.98px)': {
								style: {
									marginTop: '8px',
									order: '2',
								},
							},
							'(min-width: 768px)': {
								style: {
									marginRight: '16px',
								},
							},
							'(min-width: 1200px)': {
								style: {},
							},
						},
					},
					secondary: {
						style: {},
						queries: {
							'(max-width: 767.98px)': {
								style: {
									marginTop: '8px',
								},
							},
							'(min-width: 768px)': {
								style: {
									marginRight: '16px',
									order: '0',
								},
							},
							'(min-width: 1200px)': {
								style: {},
							},
						},
					},
					primary: {
						style: {},
						queries: {
							'(max-width: 767.98px)': {
								style: {
									marginTop: '8px',
									order: '-1',
								},
							},
							'(min-width: 768px)': {
								style: {
									marginRight: '16px',
								},
							},
							'(min-width: 1200px)': {
								style: {},
							},
						},
					},
				},
			},
		},
	},
};
