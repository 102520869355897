import React, { useContext, useState, useCallback } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Getter, useStore } from '@asteria/utils';
import { Title, Text } from '@asteria/component-typography';
import { TranslationService } from '@asteria/services-language';
import Button from '@asteria/component-button';
import { Dropdown, FormGroupV2, Input } from '@asteria/component-form';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { sendMissingErp } from '@asteria/services-datalayer/services/integrations/actions';
import { MissingErp } from '@asteria/component-onboarding';
import { useFeature } from '@asteria/component-featureflag';
import { saveUser } from '@asteria/services-datalayer/services/auth/actions';
import { useHistory } from '@asteria/utils/hooks';
import Dialog from './dialog';

const ERPS = [
	{
		value: 'bokio',
		label: 'Bokio',
	},
	{
		value: 'dooer',
		label: 'Dooer',
	},
	{
		value: 'Fortnox',
		label: 'Fortnox',
	},
	{
		value: 'speedledger',
		label: 'SpeedLedger',
	},
	{
		value: 'showERP',
		label: 'integrations.missing',
	},
];

const FormGroupMissingErp = styled.div``;

const Controllers = styled(({ className, close }) => {
	const { dispatch } = useContext(DatalayerContext);
	const hasMissingErpModal = useFeature({ key: 'missing-erp-modal' });
	const history = useHistory();
	const [showCustom, setShowCustom] = useState(false);
	const [erpMissing, setErpMissing] = useState(false);
	const [selected, setSelected] = useState([]);
	const [name, setname] = useState('');
	return (
		<div className={classNames(className, 'asteria-integrations-actions')}>
			<Button
				size="medium"
				className="asteria-button-report-missing-erp"
				type="link"
				onClick={() => setShowCustom(!showCustom)}
			>
				{TranslationService.get(
					'dashboardPopups.notification.dont_have',
					'Mitt bokföringssystem saknas',
				)}
			</Button>
			<Button
				size="medium"
				className="asteria-button-add-erp"
				type="secondary"
				onClick={() => {
					history.push('/integrations/erp/add');
				}}
			>
				{TranslationService.get(
					'dashboardPopups.initial.addAccountingSystem',
					'Anslut bokföringssystem',
				)}
			</Button>
			{showCustom && hasMissingErpModal === false ? (
				<FormGroupMissingErp className="asteria-missing-erp">
					<Text>
						{TranslationService.get(
							'missing.erp.description',
							'Vi är ledsna för att ditt system inte finns med ännu. Vi jobbar hela tiden på att utveckla tjänsten - hjälp oss genom att berätta vilket system du saknar.',
						)}
					</Text>
					<FormGroupV2>
						<Dropdown
							iconClosed="triangleDown"
							iconOpen="triangleUp"
							options={ERPS}
							placeholder="Välj bokföringssystem"
							itemIcon="check"
							showSelected
							search={false}
							type="default"
							selected={selected}
							onChange={option => {
								setSelected([option]);
								if (option.value === 'showERP') {
									setname('');
									setErpMissing(true);
								} else {
									setErpMissing(false);
									setname(option.value);
								}
							}}
						/>
					</FormGroupV2>

					{erpMissing ? (
						<Input
							placeholder="Ange ditt bokföringssystem"
							onChange={e => setname(e.target.value)}
							className="asteria-missing-system-custom-input"
						/>
					) : null}

					<Button
						size="medium"
						className="asteria-button-submit"
						type="primary"
						onClick={() => {
							if (name === '') {
								return;
							}
							dispatch(sendMissingErp({ name }));
							close();
						}}
					>
						Skicka
					</Button>
				</FormGroupMissingErp>
			) : null}
			{showCustom && hasMissingErpModal ? (
				<MissingErp
					onClose={() => close()}
					onAbort={() => setShowCustom(false)}
				/>
			) : null}
		</div>
	);
})``;

Controllers.displayName = 'Controllers';

Controllers.Styler = {
	children: [
		{
			component: Button,
			base: [Getter('button')],
		},
		{
			component: Input,
			base: [Getter('input')],
		},
		{
			component: FormGroupMissingErp,
			base: [Getter('missing')],
			children: [
				{
					component: Button,
					base: [Getter('button')],
				},
				{
					component: Text,
					base: [Getter('text')],
				},
				{
					component: Input,
					base: [Getter('input')],
				},
				{
					component: Dropdown,
					base: [Getter('dropdown')],
				},
			],
		},
	],
};

const ReminderControllers = styled(({ className, close = () => {} }) => (
	<div className={classNames(className)}>
		<Button
			size="medium"
			className="asteria-button-dismiss"
			type="link"
			onClick={() => close(true)}
		>
			{TranslationService.get(
				'dashboardPopups.notification.dontAskAgainButton',
				'Fråga mig inte igen.',
			)}
		</Button>
		<Button
			size="medium"
			className="asteria-button-accept"
			type="secondary"
			style={{ minWidth: '90px' }}
			onClick={() => close(false)}
		>
			{TranslationService.get(
				'dashboardPopups.notification.closeConfirm',
				'Ok',
			)}
		</Button>
	</div>
))`
	display: flex;
	justify-content: flex-end;
`;

ReminderControllers.displayName = 'ReminderControllers';

ReminderControllers.Styler = {
	children: [
		{
			component: Button,
			base: [Getter('button')],
		},
		{
			component: Input,
			base: [Getter('input')],
		},
		{
			component: Button,
			base: [Getter('button')],
		},
	],
};

const Wrapper = styled.div``;

Wrapper.displayName = 'Wrapper';

Wrapper.Styler = {
	children: [
		{
			component: Title,
			base: [Getter('title')],
		},
		{
			component: Text,
			base: [Getter('text')],
		},
		{
			component: Button,
			base: [Getter('button')],
		},
	],
};

const IntegrationAlert = styled(({ className, close = () => {} }) => (
	<>
		<div className={classNames(className)}>
			<Button
				icon="close"
				type="link"
				size="medium"
				className="asteria-button-close"
				onClick={() => close()}
			/>
			<Wrapper>
				<Title size="title3">
					{TranslationService.get(
						'dashboardPopups.initial.title',
						'Vill du ansluta ditt bokföringssytem?',
					)}
				</Title>
				<Text size="body">
					{TranslationService.get(
						'dashboardPopups.initial.text',
						'Genom att koppla ditt bokföringssystem får du en mer träffsäker prognos på ditt kassaflöde och en bättre översikt med fakturor och flera kategorier från ditt bokföringsystem. I dagsläget finns stöd för Fortnox, Björn Lundén, Visma eEkonomi och Wint. Vi jobbar på att lägga till fler bokföringssystem.',
					)}
				</Text>
			</Wrapper>
			<Controllers close={close} />
		</div>
	</>
))`
	&.asteria-integrations-modal {
		position: static !important;
		padding: unset;
		background: transparent !important;
		.asteria-integrations-modal-inner {
			position: fixed !important;
			padding: unset !important;
			background: rgba(0, 0, 0, 0.5) !important;
			.asteria-modal {
				@media (min-width: 1200px) {
					width: 70%;
					max-width: 540px !important;
				}

				.asteria-button-report-missing-erp {
					height: 40px;
					margin-right: auto;
					margin-top: 16px;
					padding-left: 0;
					padding-right: 0;
					.asteria-text {
						font-size: 14px;
					}
				}

				.asteria-integrations-actions {
					margin-top: 32px;
					flex-wrap: unset;
				}
			}
		}
	}
`;

IntegrationAlert.displayName = 'IntegrationAlert';

IntegrationAlert.Styler = {
	children: [
		{
			component: Wrapper,
			base: [Getter('wrapper')],
		},
		{
			component: Controllers,
			base: [Getter('controllers')],
		},
	],
};

const IntegrationReminder = styled(({ className, close }) => (
	<div className={classNames(className)}>
		<Wrapper>
			<Title size="title3" style={{ marginTop: '6px' }}>
				{TranslationService.get(
					'dashboardPopups.notification.title',
					'Du kan ansluta ditt bokföringssystem när du vill',
				)}
			</Title>
			<Text size="body">
				{TranslationService.get(
					'dashboardPopups.notification.text',
					'Välj “Hantera bokföringssystem” i menyn för tjänsten. Klicka på cirkeln med tre punkter.',
				)}
			</Text>
		</Wrapper>
		<ReminderControllers close={close} />
	</div>
))``;

IntegrationReminder.displayName = 'IntegrationReminder';

IntegrationReminder.Styler = {
	children: [
		{
			component: Wrapper,
			base: [Getter('wrapper')],
		},
		{
			component: Controllers,
			base: [Getter('controllers')],
		},
	],
};

const IntegrationPopup = styled(({ className }) => {
	const { dispatch } = useContext(DatalayerContext);
	const [showAlert, setAlertOpen] = useState(true);
	const [showReminder, setReminderOpen] = useState(false);
	const [erps = []] = useStore(
		'store-integrations',
		({ integrations = [] }) => integrations,
	);

	const hasIntegrationModalFirstTime = useFeature({
		key: 'integration-modal-first-time',
	});

	const hasIntegrationOnboardingPopup = useFeature({
		key: 'integration-onboarding-popup',
	});

	const [user] = useStore('store-auth', ({ user: obj }) => obj);

	const { settings: { erpReminder = false } = {} } = user || {};

	const closeAlert = useCallback(() => {
		setAlertOpen(false);
		setReminderOpen(true);
	}, []);

	const closeReminder = useCallback(
		value => {
			if (value === true) {
				dispatch(
					saveUser({
						...user,
						settings: {
							...user.settings,
							erpReminder: true,
						},
					}),
				);
			}
			setReminderOpen(false);
		},
		[dispatch, user],
	);

	const hasErp = erps.filter(e => e).find(({ type }) => type === 'erp');

	if (hasErp || erpReminder === true || hasIntegrationModalFirstTime) {
		return null;
	}

	if (!hasIntegrationOnboardingPopup) {
		return null;
	}

	if (!showReminder && !showAlert) {
		return null;
	}

	return (
		<div className={classNames(className)}>
			<Dialog>
				{showReminder ? (
					<IntegrationReminder close={closeReminder} />
				) : null}
				{showAlert ? <IntegrationAlert close={closeAlert} /> : null}
			</Dialog>
		</div>
	);
})`
	${Title} {
		font-family: arial;
		font-weight: bold;
	}

	${Text} {
		font-family: arial;
	}
`;

IntegrationPopup.displayName = 'IntegrationPopup';

IntegrationPopup.Styler = {
	children: [
		{
			component: Dialog,
			base: [Getter('dialog')],
			children: [
				{
					component: IntegrationAlert,
					base: [Getter('alert')],
				},
				{
					component: IntegrationReminder,
					base: [Getter('reminder')],
				},
				{
					component: Wrapper,
					base: [Getter('wrapper')],
				},
				{
					component: Button,
					base: [Getter('button')],
				},
			],
		},
	],
};

export default IntegrationPopup;
