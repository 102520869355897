import React from 'react';
import styled from 'styled-components';
import Icon from '@asteria/component-icon';
import { compileStyles, StyleGetter } from '@asteria/utils';
import PropTypes from 'prop-types';
import Text from './basic/text';
import { CheckBox, CheckboxWrapper, CheckMarkBox } from './checkbox';

/**
 * Attach styler sidebar to the checkbox wrapper component
 * @type {WithStyler}
 */
const StyledRadio = CheckBox;

/**
 * Radio group component
 */
const RadioGroup = styled(
	React.forwardRef(
		(
			{
				className,
				items = [],
				selected = [],
				labelPosition,
				style,
				onChange = () => {},
				styler,
			},
			ref,
		) => (
			<div className={className} ref={ref} style={style}>
				{items.map((title, index) => {
					const isActive = selected.includes(index);
					return (
						<StyledRadio
							text={title}
							item={title}
							checked={isActive}
							onClick={() => onChange(index)}
							component="radio"
							labelPosition={labelPosition}
							styler={styler}
							path="radioElement"
						/>
					);
				})}
			</div>
		),
	),
)`
	display: flex;
	align-items: center;

	${({ theme }) => compileStyles(RadioGroup, theme)}
`;

RadioGroup.propTypes = {
	items: PropTypes.array,
	selected: PropTypes.array,
	styler: PropTypes.bool,
	style: PropTypes.object,
};

RadioGroup.defaultProps = {
	styler: false,
	items: [],
	selected: [],
	style: {},
};

RadioGroup.Styler = {
	base: [StyleGetter(`formV2.radio`, {})],
	children: [
		{
			component: CheckboxWrapper,
			base: [StyleGetter(`radioElement`, {})],
			children: [
				{
					component: CheckMarkBox,
					base: [StyleGetter(`mark`, {})],
					children: [
						{
							component: Icon,
							base: [StyleGetter(`icon`, {})],
						},
					],
				},
				{
					component: Text,
					base: [StyleGetter(`label`, {})],
				},
			],
		},
	],
};

/**
 * Append theming sidebar to Radio group wrapper
 */
export default RadioGroup;
