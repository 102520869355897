import React, { useState, useEffect, useContext, useMemo } from 'react';
import { createMemoryHistory } from 'history';
import { match } from 'path-to-regexp';

import DatalayerContext from '@asteria/services-datalayer/react/context';
import { gotoRoute } from '@asteria/services-datalayer/services/appstate/actions';

const memoryHistory = createMemoryHistory({
	initialEntries: ['/integrations/types'],
});

const Context = React.createContext({
	history: memoryHistory,
});

const makePath = path => match(path, { decode: decodeURIComponent });

const matchRoutes = (pathname, routes) => {
	const matched = routes.find(({ path }) => path(pathname));

	if (matched) {
		return { ...matched, ...matched.path(pathname) };
	}

	return false;
};

const useLocation = () => {
	const { location } = useContext(Context);
	return location;
};

const useHistory = () => {
	const { history } = useContext(Context);
	return history;
};

const useMatch = path => {
	const matcher = useMemo(() => makePath(path), [path]);
	const { pathname = '/' } = useLocation();

	return matcher(pathname);
};

const Router = ({ children, history = memoryHistory }) => {
	const { dispatch } = useContext(DatalayerContext);
	const [location, setLocation] = useState(history.location);

	useEffect(() => {
		const unlisten = history.listen(newLocation => {
			setLocation(newLocation);
		});

		return () => unlisten();
	}, [history]);

	useEffect(() => {
		let listenHistory = false;
		if (history) {
			listenHistory = history.listen(({ pathname }) => {
				dispatch(gotoRoute(pathname));
			});

			const {
				location: { pathname },
			} = history;

			dispatch(gotoRoute(pathname));
		}

		return () => {
			if (listenHistory) {
				listenHistory();
			}
		};
	}, [dispatch, history]);

	return (
		<Context.Provider value={{ history, location }}>
			{children}
		</Context.Provider>
	);
};

export default Router;
export {
	Context as RouterContext,
	useHistory,
	useLocation,
	useMatch,
	makePath,
	matchRoutes,
};
