import classNames from 'classnames';
import React from 'react';
import styled, { withTheme } from 'styled-components';
import AssetsService from '@asteria/services-assets';
import { colors } from '@asteria/services-constants';

const defaultAssetService = new AssetsService();

const SVG = styled.svg`
	fill: ${colors.icon};
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
	height: 100%;
	width: 100%;
	max-height: 100%;
	max-width: 100%;
`;
SVG.displayName = 'IconSVGV2';
const Wrapper = styled.div`
	cursor: pointer;
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	height: 100%;
	width: 100%;
`;
Wrapper.displayName = 'IconWrapperV2';

const Icon = withTheme(
	styled(
		({
			asset,
			service = defaultAssetService,
			theme = {},
			className,
			...props
		}) => {
			const content = service.getMarkupData(asset, theme);

			return (
				<Wrapper
					className={classNames(
						'asteria-icon-wrapper',
						content?.classes?.map(a => `asteria-icon-${a}`) || [],
						className,
					)}
				>
					<SVG
						className="asteria-icon-svg"
						dangerouslySetInnerHTML={content}
						{...content.props}
						{...props}
						// preserveAspectRatio={preserveAspectRatio}
					/>
				</Wrapper>
			);
		},
	)``,
);

Icon.displayName = 'IconV2';

Icon.Styler = {
	typePrefix: 'asteria-icon',
	children: [
		{
			component: SVG,
			base: 'svg',
		},
	],
};

export { SVG };
export default Icon;
