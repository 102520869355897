import Key from './key';
import Connecting from './connecting';
import Success from './success';
import Concent from './concent';

import { navigatorCarrier } from '../../createNavigator';

// Apply default navigation
Connecting.navigator = {
	next: navigatorCarrier(Concent.navigationKey),
};

Concent.navigator = {
	next: navigatorCarrier(Success.navigationKey),
};

Key.navigator = {
	next: navigatorCarrier(Connecting.navigationKey),
};

Success.navigator = {
	back: navigatorCarrier(Key.navigationKey),
};

// Build up the symbol-based key-value store of given steps
export default {
	[Connecting.navigationKey]: Connecting,
	[Success.navigationKey]: Success,
	[Key.navigationKey]: Key,
	[Concent.navigationKey]: Concent,
};

export { Connecting, Key, Success };
