import { cloneElement } from 'react';
import { useStore } from '@asteria/utils';
import Service from './service';

const FeatureFlag = ({
	feature: key,
	minVersion = false,
	maxVersion = false,
	invert = false,
	wings = false,
	children,
	...props
}) => {
	const [feature] = useStore('store-features', store => store?.active?.[key]);
	if (!Service.detected[key]) {
		Service.detected[key] = {
			key,
		};
	}

	if (!children) {
		return null;
	}

	if (
		wings === true &&
		window.Asteria &&
		window.Asteria.Platform === 'wings'
	) {
		if (Array.isArray(children)) {
			return children
				.filter(child => child)
				.map((child, index) =>
					cloneElement(child, { key: `child-${index}`, ...props }),
				);
		}

		return cloneElement(children, props);
	}

	if (invert === true) {
		if (
			Service.hasFeature(feature, {
				minVersion,
				maxVersion,
			})
		) {
			return null;
		}

		if (Array.isArray(children)) {
			return children
				.filter(child => child)
				.map((child, index) =>
					cloneElement(child, { key: `child-${index}`, ...props }),
				);
		}

		return cloneElement(children, props);
	}
	if (Service.hasFeature(feature, { minVersion, maxVersion })) {
		if (Array.isArray(children)) {
			return children
				.filter(child => child)
				.map((child, index) =>
					cloneElement(child, { key: `child-${index}`, ...props }),
				);
		}

		return cloneElement(children, props);
	}

	return null;
};

export default FeatureFlag;
