import React, { useState, useEffect, useCallback } from 'react';

import styled from 'styled-components';
import classNames from 'classnames';
import { Getter, compileStyles } from '@asteria/utils';
import Title, { Text } from '@asteria/component-typography';
import {
	addDays,
	addMonths,
	addYears,
	isSameMonth,
	isSameDay,
	startOfMonth,
	subYears,
	startOfYear,
	isSameYear,
	startOfISOWeek,
	setHours,
} from 'date-fns';
import CalendarInput from './datePicker/calenderInput';
import CalenderWrapper from './datePicker/calenderWrapper';

const HeaderSection = styled.div``;

const Calendars = styled.div``;

HeaderSection.Styler = {
	children: [
		{
			component: Title,
			base: [Getter('title')],
		},
	],
};

const Weekdays = styled.div``;

Weekdays.Styler = {
	children: [
		{
			component: Text,
			base: [Getter('text')],
		},
	],
};

const Calendar = styled.div``;
const GenerateDates = startDate => {
	const dates = [];
	let date = setHours(startOfISOWeek(startOfMonth(startDate)), 12);
	if (!isSameMonth(date, startDate)) {
		while (!isSameMonth(date, startDate)) {
			dates.push(date);
			date = addDays(date, 1);
		}
	}
	while (isSameMonth(date, startDate)) {
		dates.push(date);
		date = addDays(date, 1);
	}
	return dates;
};

const GenerateMonths = startMonth => {
	const months = [];
	let month = startOfYear(startMonth);

	while (isSameYear(month, startMonth)) {
		months.push(month);
		month = addMonths(month, 1);
	}
	return months;
};

const GenerateYears = (startYear, subtractedYears = 0, howManyYears = 10) => {
	const years = [];
	let year = startOfYear(subYears(startYear, subtractedYears));

	for (let i = 0; years.length < howManyYears; i += 1) {
		years.push(year);
		year = addYears(year, 1);
	}
	return years;
};

const CalenderLogic = ({
	allow,
	id,
	isOpen,
	extend,
	onChange,
	multi,
	value,
	children,
}) => {
	const [currentDate, setCurrentDate] = useState(
		value && value.length > 0 ? value[0] : new Date(),
	);
	const [dates, setDates] = useState(GenerateDates(currentDate));
	const [active, setActive] = useState(currentDate);

	const [months, setMonths] = useState(GenerateMonths(currentDate));
	const [years] = useState(GenerateYears(currentDate));

	const changeActive = useCallback(
		date => {
			if (multi) {
				const index = active.findIndex(d => isSameDay(d, date));
				if (index === -1) {
					setActive([...active, date]);
					onChange([...active, date], id);
					setCurrentDate(date);
				} else {
					setActive(active.filter(d => !isSameDay(d, date)));
					onChange(active.filter(d => !isSameDay(d, date)), id);
				}
			} else {
				setActive(date);
				onChange(date, id);
				setCurrentDate(date);
			}
		},
		[active, multi, onChange, id],
	);

	useEffect(() => {
		setDates(GenerateDates(currentDate));
	}, [currentDate]);

	useEffect(() => {
		setMonths(GenerateMonths(currentDate));
	}, [currentDate]);

	return (
		<CalenderWrapper
			currentDate={currentDate}
			allow={allow}
			active={active}
			multi={multi}
			extend={extend}
			changeActive={changeActive}
			dates={dates}
			months={months}
			years={years}
			isOpen={isOpen}
			setCurrentDate={setCurrentDate}
		>
			{children}
		</CalenderWrapper>
	);
};

CalenderLogic.displayName = 'CalenderLogic';

const DatePicker = styled(
	({
		className,
		multi = false,
		allow = ['day'],
		value,
		extend = false,
		min = false,
		onChange = () => {},
		children,
	}) => {
		const [active, setActive] = useState({ start: [value], end: [value] });
		const [isOpen, setOpen] = useState(false);

		const changed = useCallback(
			(date, id) => {
				if (extend === true || multi === true) {
					setOpen(true);
				} else {
					setOpen(false);
				}

				setActive({ ...active, [id]: date });
				onChange(date);
			},
			[active, extend, multi, onChange],
		);

		const toggle = useCallback(() => setOpen(!isOpen), [isOpen]);

		useEffect(() => {
			const close = e => {
				if (
					!e.path ||
					!e.path.find(p =>
						p?.classList?.contains('asteria-calendar-wrapper'),
					)
				) {
					setOpen(false);
				}
			};

			if (isOpen) {
				document.addEventListener('click', close);

				return () => {
					document.removeEventListener('click', close);
				};
			}

			return undefined;
		}, [isOpen]);
		return (
			<Calendar
				className={classNames(className, 'asteria-datepicker', {
					'asteria-datepicker-extend': extend,
				})}
			>
				<CalendarInput
					extend={extend}
					toggle={toggle}
					multi={multi}
					active={active}
					onChange={changed}
					min={min}
					isOpen={isOpen}
					id="start"
				/>
				<Calendars className="asteria-calendar-calendars">
					<CalenderLogic
						id="start"
						allow={allow}
						isOpen={isOpen}
						extend={extend}
						toggle={toggle}
						onChange={changed}
						multi={multi}
						value={active.start}
					>
						{children}
					</CalenderLogic>
					{extend ? (
						<CalenderLogic
							id="end"
							extend={extend}
							allow={allow}
							isOpen={isOpen}
							toggle={toggle}
							onChange={changed}
							multi={multi}
						/>
					) : null}
				</Calendars>
			</Calendar>
		);
	},
)`
	&.asteria-datepicker-extend {
		.asteria-calender-wrapper ~ .asteria-calender-wrapper {
			border-left: none;
		}
	}
	position: relative;
	${({ theme }) => compileStyles(DatePicker, theme)}

	.asteria-forms-repeatable {
		background: #faf0e7;
		padding: 0 16px 16px 16px;
		.asteria-group {
			margin: 0 !important;
		}
	}
`;

DatePicker.Styler = {
	base: [Getter('formV2.datepicker')],
	children: [
		{
			component: Calendars,
			typePrefix: 'asteria-calendar-calendars',
			base: [Getter('calendars')],
		},
		{
			component: CalenderWrapper,
			typePrefix: 'asteria-calendar-wrapper',
			base: [Getter('wrapper')],
		},
	],
};

export default DatePicker;
