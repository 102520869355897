import React, { useContext } from 'react';

import { FeatureFlag } from '@asteria/component-featureflag';

import generalFlowComponents, { Splash } from './genericFlows/general';
import genericApiKeyFlowComponents from './genericFlows/accessToken';
import fortnoxFlowComponents, { helpModals } from './erpSystemFlows/fortnox';
import wintFlowComponents, {
	helpModals as wintHelpModals,
} from './erpSystemFlows/wint';
import bjornLundenFlowComponents, {
	helpModals as bjornLundenHelpModals,
} from './erpSystemFlows/bjornlunden';
import vismaFlowComponents, {
	helpModals as vismaHelpModals,
} from './erpSystemFlows/visma';
import vismaAdminFlowComponents from './erpSystemFlows/vismaAdministration';
import procountorFlowComponents from './erpSystemFlows/procountor';
import asteriaFlowComponents, {
	helpModals as asteriHelpModals,
} from './erpSystemFlows/asteria';
import asteriaBankFlowComponents from './bankSystemFlows/asteria';
import handelsBanken, {
	helpModals as handelsBankenHelpModals,
} from './bankSystemFlows/handelsbanken';
import sebBanken from './bankSystemFlows/seb';
import nordeaBanken from './bankSystemFlows/nordea';
import swedbankBanken from './bankSystemFlows/swedbank';
import Context from './context';
import Navigation from './navigation';

const onboardingComponents = {
	...generalFlowComponents,
	...genericApiKeyFlowComponents,
	...fortnoxFlowComponents,
	...asteriaFlowComponents,
	...vismaFlowComponents,
	...wintFlowComponents,
	...handelsBanken,
	...sebBanken,
	...bjornLundenFlowComponents,
	...asteriaBankFlowComponents,
	...procountorFlowComponents,
	...vismaAdminFlowComponents,
	...swedbankBanken,
	...nordeaBanken,
};

const modals = {
	...helpModals,
	...asteriHelpModals,
	...wintHelpModals,
	...bjornLundenHelpModals,
	...vismaHelpModals,
	...handelsBankenHelpModals,
};

export default ({ callback }) => {
	const {
		navigator,
		navigator: { currentStep },
		activeModal,
		stepIndex,
	} = useContext(Context);

	let StageView;
	if (onboardingComponents[currentStep]) {
		StageView = onboardingComponents[currentStep];
	} else {
		StageView = Splash;
	}

	let ModalView = () => null;
	if (activeModal && modals[activeModal]) {
		ModalView = modals[activeModal];
	}

	return (
		<>
			<div
				style={{ display: 'flex' }}
				className="astera-onboarding-step-wrapper"
			>
				<StageView
					callback={callback}
					navigator={
						StageView.navigator
							? Object.assign(navigator, StageView.navigator) // Apply default navigation if present
							: navigator
					}
				/>
				<FeatureFlag feature="integrations-add-hide-navigation" invert>
					{stepIndex > 0 ? (
						<Navigation
							step={stepIndex}
							navigator={
								StageView.navigator
									? Object.assign(
											navigator,
											StageView.navigator,
									  ) // Apply default navigation if present
									: navigator
							}
						/>
					) : null}
				</FeatureFlag>
			</div>
			<ModalView />
		</>
	);
};
