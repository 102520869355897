/**
 * Create a new validator function for form
 * @param {Object} validators - set of validator functions stored in {key: function} format
 * @param {function} setNewErrors - optional error setter
 * @param {Object} currentErrors - existing error messages
 * @return {function((string[]|string|null)): {}} - validator function
 */
export default (validators, setNewErrors, currentErrors = {}) =>
	/**
	 * The exact validator function, that checks everything and updates errors
	 *
	 * @param {string[]|string|null} attributes - one or more field names to validate.
	 * If string is given, we assume that this is the single attribute name to validate.
	 * If array of attributes is given, we validate every single one, if we have a defined validator for it
	 * If nothing is given, we run all available validators
	 */
	attributes => {
		const errors = { ...currentErrors };
		// Use all available validators by default
		let fieldsToValidate = Object.keys(validators);

		// use attributes if given any
		if (attributes) {
			fieldsToValidate = Array.isArray(attributes)
				? attributes
				: [attributes];
		}

		// Run all available validators one by one
		fieldsToValidate.forEach(field => {
			if (validators[field]) {
				const messages = validators[field]();

				if (messages) {
					// If there are any errors returned - save them instead of old ones
					errors[field] = messages;
				} else {
					// If no errors - unset old value
					delete errors[field];
				}
			}
		});

		if (setNewErrors) {
			// Run setter directly if given
			setNewErrors(errors);
		}

		return errors;
	};
