import React, { useCallback, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { startOfYear, endOfYear, format, addMonths } from 'date-fns';
// eslint-disable-next-line no-unused-vars
import { GraphLayout, GraphData } from '@asteria/component-graph';
import { SearchData } from '@asteria/component-search';
import AppMenu from '@asteria/component-graph/components/appmenu';
import { CashflowHeader } from '@asteria/component-graph/logic/layout';
import TimesliceSelector from '@asteria/component-graph/components/time';

import { Title, Text } from '@asteria/component-core/typography';
import { TranslationService } from '@asteria/services-language';

import SidePane from '@asteria/component-core/sidepane';
import ActionBar from '@asteria/component-core/actionbar';

import Icon from '@asteria/component-icon';

import AddAdjustableTransaction from '@asteria/component-list/components/adjustable/addAdjustable';

import List, { ListLogic } from '@asteria/component-list';

import DatalayerContext from '@asteria/services-datalayer/react/context';
import { compileStyles, useStore, Getter } from '@asteria/utils';

import AdvancedAdjustable from '@asteria/component-list/components/advancedAdjustable';
import Button from '@asteria/component-button/button';

import {
	setActiveBars,
	setActiveGroups,
	subscribeToGraphUpdates,
} from '@asteria/services-datalayer/services/graph/actions';
import {
	fetchNumberOfOverdue,
	fetchNumberOfOpen,
	setFilters,
	setCurrentDate,
	setListOpen,
	setSelectedType,
	setSelectedDate,
} from '@asteria/services-datalayer/services/appstate/actions';
import { useFeature, FeatureFlag } from '@asteria/component-featureflag';
// import { useLocation } from '@asteria/utils/hooks';
import { Dropdown } from '@asteria/component-form';

import NotificationsBadge from '@asteria/component-notifications/badge';
import LogicNotifications from '@asteria/component-notifications/logic';
import NotificationDropdownItem from '@asteria/component-notifications/listing/dropdown';
// import SecurityBar from '@asteria/component-security-bar';

import ScrollToGraph from '../components/scrollToGraph';

import UserInput from '../components/userinput';

import CustomerPanel from '../components/customerpanel';

import Insights from '../components/insights';

import IntegrationAlert from '../components/integration';

import AdjustableRepeatable from '../components/repeatable';

import Modals from './modals';

import Factoring from './factoring';
import CreditApplication from './credit';

import IntegrationStatusDialog from '../components/integrationStatusDialog';

import CompanyOverview from '../components/overview';
import Prompts from '../components/prompts';

const HomePage = styled(
	React.memo(({ className }) => {
		const { dispatch } = useContext(DatalayerContext);
		// const { pathname } = useLocation();
		const compressListSetting = useFeature({
			key: 'compress-list-setting',
		});
		const hasSearchFeature = useFeature({ key: 'search' });
		const hasCreditActionBar = useFeature({ key: 'credit-action-bar' });
		const [mode] = useStore('store-appstate', ({ mode: val }) => val);
		const hasListShowOnLoad = useFeature({
			key: 'list-show-on-load',
		});
		const hasAdjustableAdvancedFeature = useFeature({
			key: 'AdvancedAdjustable',
		});

		const [isListOpen = false] = useStore(
			'store-appstate',
			({ listOpen }) => listOpen,
		);

		const [sidepanes = false] = useStore(
			'store-appstate',
			({ sidepanes: count }) => count,
		);

		const [listOpenSource = false] = useStore(
			'store-appstate',
			({ listOpenSource: source }) => source,
		);

		const [isSearchFocus = false] = useStore(
			'store-appstate',
			({ searchFocus: source }) => source,
		);

		const [hasFilters] = useStore(
			'store-appstate',
			({ filters: list }) => list && list.length > 0,
		);

		const [widgetClasses] = useStore(
			'store-appstate',
			({ widgetClasses: val }) => val,
		);

		const [currentUser = false] = useStore(
			'store-auth',
			({ user }) => user,
		);

		const [hasActionBar = false] = useStore(
			'store-list',
			({ state: { selected = [] } = {} }) => selected.length > 0,
		);

		const [hasAdjustable = false] = useStore(
			'store-adjustable',
			({ transactions: { adding = [] } = {} }) => adding.length > 0,
		);

		const [hasFactoringClients] = useStore(
			'store-appstate',
			({ factoring }) => factoring && factoring.length > 0,
		);

		const { settings: { compressList = false } = {} } = currentUser;

		useEffect(() => {
			dispatch(fetchNumberOfOverdue());
			dispatch(fetchNumberOfOpen());
			dispatch(subscribeToGraphUpdates());
		}, [dispatch]);

		useEffect(() => {
			if (hasSearchFeature) {
				dispatch(() => ({
					action: 'REQUEST_DATA_LIST_STATISTICS',
					payload: {
						request: {
							startDate: startOfYear(new Date()),
							endDate: endOfYear(new Date()),
							status: ['PAID', 'UNPAID', 'OVERDUE', 'FORECAST'],
						},
						path: 'searchStatistics',
					},
				}));
			}
		}, [dispatch, hasAdjustableAdvancedFeature, hasSearchFeature]);

		const [showAdvanced, setShowAdvanced] = useState(false);

		const [repeatableInformation = null] = useStore(
			'store-adjustable',
			({ repeatable = [] }) => repeatable,
		);

		const reset = useCallback(() => {
			dispatch(setFilters([]));
			dispatch(setCurrentDate(addMonths(new Date(), -4)));
			dispatch(
				setSelectedDate(
					`${format(new Date(), 'YYYY-MM')}-01T00:00:00.000Z`,
				),
			);
			dispatch(setActiveGroups('cashflow-bar-graph', []));
			dispatch(setActiveBars('cashflow-bar-graph', []));

			if (hasListShowOnLoad) {
				dispatch(setListOpen(true, 'account'));
				dispatch(setSelectedType(['DEPOSIT', 'WITHDRAW']));
				dispatch(
					setSelectedDate(
						`${format(new Date(), 'YYYY-MM')}-01T00:00:00.000Z`,
					),
				);
			} else {
				dispatch(setListOpen(false));
			}
		}, [dispatch, hasListShowOnLoad]);

		// const isFactoringMode = pathname === '/factoring';
		const hasBackdropForDropdown = useFeature({
			key: 'has-backdrop-for-this-dropdown',
		});

		return (
			<div
				className={classNames(
					className,
					`asteria-widget-wrapper`,
					{
						'asteria-transaction-list-visible': isListOpen,
						[`asteria-transaction-list-visible-${listOpenSource}`]: isListOpen,
						'asteria-transaction-list-compress':
							compressList && compressListSetting,
						'asteria-has-actionbar':
							hasActionBar ||
							hasFactoringClients ||
							(mode === 'credit' && hasCreditActionBar),
						'asteria-has-adjustable': hasAdjustable,
						'asteria-has-sidepane': sidepanes > 0,
						'asteria-has-search-focus': isSearchFocus,
						'asteria-has-filters': hasFilters,
					},
					widgetClasses,
				)}
			>
				<div className="asteria-widget-main">
					<ScrollToGraph />
					<IntegrationAlert />
					<FeatureFlag feature="asteria-improvements">
						<CashflowHeader
							className="asteria-cashflow-header"
							key="cashflow-header"
						>
							<div className="asteria-cashflow-overview-wrapper">
								<CompanyOverview />
								<LogicNotifications>
									{({ notifications = [] }) => (
										<Dropdown
											toggle={NotificationsBadge}
											item={NotificationDropdownItem}
											backdrop={hasBackdropForDropdown}
											data-tooltip={TranslationService.get(
												'notitification.navigation.title',
												'Händelser',
											)}
											options={
												notifications &&
												notifications.length > 0
													? notifications
													: [
															{
																id: 'empty',
																type: 'empty',
																key: 'empty',
															},
													  ]
											}
											className={classNames(
												'asteria-notifications',
												{
													'asteria-notifications-empty':
														notifications.length ===
														0,
												},
											)}
										>
											<div className="asteria-dropdown-header">
												<Title size="title">
													{TranslationService.get(
														'notitification.navigation.title',
														'Händelser',
													)}
												</Title>
											</div>
										</Dropdown>
									)}
								</LogicNotifications>
							</div>

							<div className="asteria-search-wrapper">
								{/* <Dropdown
									iconClosed="triangleDown"
									iconOpen="triangleUp"
									itemIconSelected="check"
									// options={categoryOptions}
									// selected={selected}
									placeholder="Kund"
									showSelected
									className="asteria-display-filter-navigation"
									type="link"
									// onChange={setFilter}
								/> */}
								<SearchData id="cashflow-bar-graph" />
								<TimesliceSelector />
								<FeatureFlag feature="asteria-improvements-add-in-header">
									<div className="asteria-adjustable-navigation">
										<AddAdjustableTransaction />
									</div>
								</FeatureFlag>
								<AppMenu />
							</div>
						</CashflowHeader>

						{/* <div className="asteria-floating-navigation">
							<AddAdjustableTransaction />
						</div> */}
					</FeatureFlag>

					<FeatureFlag feature="asteria-improvements" invert>
						<CashflowHeader
							className="asteria-cashflow-header"
							key="cashflow-header"
						>
							<FeatureFlag feature="partner-logo">
								<div
									className={classNames(
										'asteria-partner-logo',
									)}
									onClick={reset}
									onKeyPress={reset}
									role="button"
									tabIndex="-1"
								>
									<Icon asset="logo" />
								</div>
							</FeatureFlag>
							{/* <FeatureFlag feature="filter-dropdown">
								<Dropdown
									iconClosed="triangleDown"
									iconOpen="triangleUp"
									itemIconSelected="check"
									options={categoryOptions}
									// selected={selected}
									placeholder="Kategorier"
									showSelected
									className="asteria-display-filter-navigation"
									type="link"
									onChange={setFilter}
								/>
							</FeatureFlag> */}
							<FeatureFlag feature="search">
								<SearchData id="cashflow-bar-graph" />
							</FeatureFlag>
							<FeatureFlag feature="adjustable">
								<FeatureFlag feature="adjustable-navigation">
									<AddAdjustableTransaction />
								</FeatureFlag>
							</FeatureFlag>
							<FeatureFlag feature="timeSpanDropdown" invert>
								<TimesliceSelector />
							</FeatureFlag>
							<FeatureFlag feature="notifications-header">
								<FeatureFlag
									feature="notifications-header-dropdown"
									key="notifications-header-dropdown"
								>
									<LogicNotifications>
										{({ notifications = [] }) => (
											<Dropdown
												toggle={NotificationsBadge}
												item={NotificationDropdownItem}
												backdrop={
													hasBackdropForDropdown
												}
												data-tooltip={TranslationService.get(
													'notitification.navigation.title',
													'Händelser',
												)}
												options={
													notifications &&
													notifications.length > 0
														? notifications
														: [
																{
																	id: 'empty',
																	type:
																		'empty',
																	key:
																		'empty',
																},
														  ]
												}
												className={classNames(
													'asteria-notifications',
													{
														'asteria-notifications-empty':
															notifications.length ===
															0,
													},
												)}
											>
												<div className="asteria-dropdown-header">
													<Title size="title">
														{TranslationService.get(
															'notitification.navigation.title',
															'Händelser',
														)}
													</Title>
												</div>
											</Dropdown>
										)}
									</LogicNotifications>
								</FeatureFlag>
								<FeatureFlag
									feature="notifications-header-dropdown"
									key="notifications-header-dropdown-inverted"
									invert
								>
									<NotificationsBadge />
								</FeatureFlag>
							</FeatureFlag>
							<AppMenu />
						</CashflowHeader>
					</FeatureFlag>

					<Prompts type="currency.risk" />

					<FeatureFlag feature="header-company-overview">
						<CompanyOverview />
					</FeatureFlag>

					<GraphData />

					<FeatureFlag feature="SidePane">
						<SidePane />
					</FeatureFlag>

					<FeatureFlag feature="adjustable">
						<FeatureFlag feature="adjustable-floating-nav">
							<div className="asteria-floating-navigation">
								<AddAdjustableTransaction />
							</div>
						</FeatureFlag>
					</FeatureFlag>

					<FeatureFlag feature="AdvancedAdjustable">
						<Button
							size="small"
							type="link"
							icon="calendar"
							onClick={() => setShowAdvanced(true)}
						>
							Gör flera prognoser samtidigt
						</Button>

						{showAdvanced ? (
							<AdvancedAdjustable
								onClose={() => setShowAdvanced(false)}
							/>
						) : null}
					</FeatureFlag>

					<ListLogic />
					<FeatureFlag feature="asteria-improvements" invert>
						{!isListOpen ? (
							<Insights className="asteria-insights" />
						) : null}
					</FeatureFlag>
					<FeatureFlag feature="actionbar-in-list" invert>
						<ActionBar />
					</FeatureFlag>
					<FeatureFlag feature="userInput">
						<UserInput />
					</FeatureFlag>
					<FeatureFlag feature="customerPanel">
						<CustomerPanel />
					</FeatureFlag>
					<FeatureFlag feature="onboarding-status-dialog">
						<IntegrationStatusDialog />
					</FeatureFlag>
					<FeatureFlag feature="mobile-message">
						<div className="asteria-mobile-message">
							<Title size="title2">
								{TranslationService.get('mobile.message.title')}
							</Title>
							<Text className="asteria-small" size="body">
								{TranslationService.get('mobile.message.text')}
							</Text>
						</div>
					</FeatureFlag>
					<FeatureFlag feature="adjustable-repeatable">
						{repeatableInformation !== null ? (
							<AdjustableRepeatable />
						) : null}
					</FeatureFlag>
					<FeatureFlag feature="factoring">
						<Factoring />
					</FeatureFlag>

					<FeatureFlag feature="credit-application">
						<CreditApplication />
					</FeatureFlag>

					<Modals />

					{/* <FeatureFlag feature="adjustable-save-as">
				<Button size="regular" type="primary" text="Next" />
				<Dropdown
					iconClosed="triangleDown"
					iconOpen="triangleUp"
					options={[
						{
							value: 'saveAsSceario',
							label: 'Spara som scenario',
						},
						{
							value: 'save',
							label: 'Spara',
						},
					]}
					placeholder="Spara"
					showSelected
					type="primary"
				/>
			</FeatureFlag> */}
				</div>
				<div id="asteria-drawers" />
			</div>
		);
	}),
)`
	display: flex;
	/* height: 100%; */
	position: relative;
	.asteria-widget-main {
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.asteria-cashflow-header {
		.asteria-adjustable {
			margin-right: 12px !important;
			height: 40px !important;
			flex-grow: 1;
			align-self: flex-start;
			margin-top: 6px;
			flex-shrink: 0;
			.asteria-text {
				font-family: Arial, sans-serif !important;
				font-weight: 400 !important;
				font-size: 16px !important;
			}
			.asteria-icon-wrapper {
				height: 24px !important;
				flex-grow: 1 !important;
				width: 40px !important;
				margin-right: 0 !important;
				svg {
					fill: #53a1ac !important;
				}
			}
		}
	}

	.asteria-adjustable {
		/* 		width: 310px !important;
		margin-right: 16px !important; */
		height: 40px !important;
		.asteria-text {
			font-family: Arial, sans-serif !important;
			font-weight: 400 !important;
			font-size: 16px !important;
		}
		.asteria-icon-wrapper {
			width: 24px !important;
			margin-right: 8px;
			svg {
				fill: #53a1ac !important;
			}
		}
	}

	&.asteria-has-sidepane {
		&.asteria-widget-wrapper {
			.asteria-widget-main {
				@media screen and (min-width: 1680px) {
					/* width: calc(100% - 288px); */
					width: calc(100% - 108px);
					// width: calc(100%);
					// margin-left: -148px;
					// padding-left: 148px;
					width: calc(100% - 188px) !important;
				}
			}
		}

		.asteria-widget-main {
			transition: all 0.2s ease-in-out;
			width: calc(100% - 288px);
			@media screen and (min-width: 1440px) {
				width: calc(100% - 288px);
			}
			@media screen and (min-width: 1540px) {
				width: calc(100% - 238px);
			}
			@media screen and (min-width: 1600px) {
				width: calc(100% - 228px);
			}

			@media screen and (min-width: 1680px) {
				.asteria-widget .asteria-has-sidepane .asteria-widget-main {
					width: calc(100% - 118px);
				}
			}
			@media screen and (min-width: 1540px) and (max-width: 1600px) {
				.asteria-widget .asteria-has-sidepane .asteria-widget-main {
					width: calc(100% - 238px);
				}
			}
			@media screen and (min-width: 1601px) {
				.asteria-widget .asteria-has-sidepane .asteria-widget-main {
					width: calc(100% - 288px);
				}
			}
		}
		#asteria-drawers {
			@media screen and (min-width: 1600px) {
				width: 15%;
			}
		}
	}

	#asteria-drawers {
		pointer-events: none;
		width: 0;
		div {
			pointer-events: none;
		}
		&:empty {
			width: 0;
		}
		width: 288px;
		transition: width 0.2s ease-in-out;

		@media screen and (max-width: 1920px) {
			width: 0;
		}

		@media screen and (max-width: 1680px) {
			width: 0;
		}

		@media screen and (max-width: 1280px) {
			width: 0;
		}
	}

	&.asteria-transaction-list-visible {
		${Insights} {
			display: none;
		}
	}

	.asteria-forms-dropdown-menu-direction-down {
		&::-webkit-scrollbar {
			background-color: #fff;
			width: 16px;
		}

		&::-webkit-scrollbar-track {
			background-color: #fff;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #babac0;
			border-radius: 16px;
			border: 6px solid #fff;
		}
	}

	.asteria-alert-success {
		width: 100% !important;
	}

	.asteria-alert-success .asteria-title-2,
	.asteria-onboarding-asteria-success .asteria-title-3 {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0 16px !important;
	}

	.asteria-notifications .asteria-forms-dropdown-menu-direction-down {
		min-width: 330px;
		left: auto;
		right: 0;
	}

	.asteria-notifications-empty.asteria-forms-dropdown-open
		.asteria-forms-dropdown-menu-direction-down {
		min-height: auto !important;
		.asteria-forms-dropdown-item {
			.asteria-notification-item {
				cursor: default;
			}
			&:hover,
			&.asteria-state-hover {
				background: transparent !important;
			}
		}
	}

	.asteria-notifications.asteria-forms-dropdown-open
		.asteria-forms-dropdown-menu-direction-down {
		z-index: 11;
		max-height: 420px;
		// overflow: visible;
	}

	.asteria-notifications {
		align-self: flex-start;
		.asteria-dropdown-header {
			font-size: 18px !important;
			color: #512b2b !important;
			padding: 12px 0px;
			margin: 0 16px;

			position: sticky;
			background: #fff;
			height: 13px;
			// z-index: 3;
		}
	}

	${({ theme }) => compileStyles(HomePage, theme)}
`;

HomePage.Styler = {
	children: [
		{
			component: Insights,
			base: [Getter('insights')],
		},
		{
			component: IntegrationAlert,
			base: [Getter('dialogs')],
		},
		{
			component: GraphLayout,
			base: [Getter('graph')],
		},
		{
			component: List,
			base: [Getter('transactions')],
		},
		{
			component: CashflowHeader,
			base: [Getter('graph.header')],
		},
	],
};

export default HomePage;
