/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import './style.css';

class UserInput extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			items: [
				{
					text:
						'As a user I want to focus on different date format in the graph (weeks, months, years)',
					done: false,
				},
				{
					text:
						'As a user I want to show/hide my Account Balance graph',
					done: false,
				},
				{
					text:
						'As a user I want to show/hide the numbers inside my Account Balance graph',
					done: false,
				},
				{
					text:
						'As a user I want to show/hide my incoming payments in the graph',
					done: false,
				},
				{
					text:
						'As a user I want to show/hide my outgoing payments in the graph',
					done: false,
				},
				{
					text:
						'As a user I want to show comprimised/uncomprimised transaction list columns',
					done: false,
				},
				{
					text: 'As a user I want to get started with Företagskollen',
					done: false,
				},
				{
					text:
						'As a user I want to see a list of all my integrated business softwares',
					done: false,
				},
				{
					text: 'As a user I want to add my business software',
					done: false,
				},
				{
					text: 'As a user I want to disconnect my business software',
					done: false,
				},
				{
					text:
						'As a user I want to see a list of all my bank accounts',
					done: false,
				},
				{
					text: 'As a user I want to enable/disable a bank account',
					done: false,
				},
				{
					text: 'As a user I want to find frequently asked questions',
					done: false,
				},
				{
					text: 'As a user I want to ask for help or report an issue',
					done: false,
				},
				{
					text:
						'As a user I want to understand how Företagskollen works',
					done: false,
				},
				{
					text:
						'As a user I need help with how to find my API key for my business software',
					done: false,
				},
				{
					text:
						'As a user I want to understand in detail what is included inside a graph bar',
					done: false,
				},
				{
					text:
						'As a user I want to request support for a new business software because can´t find my business software',
					done: false,
				},
				{
					text:
						'As a user I want to continue using Företagskollen without my business software',
					done: false,
				},
				{
					text:
						'As a user I want to show/hide my incoming payments in the graph',
					done: false,
				},
				{
					text: 'As a user I want to filter to show my Taxes',
					done: false,
				},
				{
					text: 'As a user I want to filter to show my Salaries',
					done: false,
				},
				{
					text:
						'As a user I want to filter to show my Transaction types',
					done: false,
				},
				{
					text: 'As a user I want to filter to show my Invoices',
					done: false,
				},
				{
					text:
						'As a user I want more information what a Incoming bar in the graph contains (past, today and forecasted)',
					done: false,
				},
				{
					text:
						'As a user I want more information what a Outgoing bar in the graph contains (past, today and forecasted)',
					done: false,
				},
				{
					text:
						'As a user I want more information what a Account balance dot in the graph contains (past, today and forecasted)',
					done: false,
				},
				{
					text:
						'As a user I want to understand what different parts in the graph means (colors, stripes, lines, et.c)',
					done: false,
				},
				{
					text:
						'As a user I want find out more detailed information what a Incoming bar in the graph contains (past, today and forecasted)',
					done: false,
				},
				{
					text:
						'As a user I want find out more detailed information what a Outgoing bar in the graph contains (past, today and forecasted)',
					done: false,
				},
				{
					text:
						'As a user I want find out more detailed information what a Account balance dot in the graph contains (past, today and forecasted)',
					done: false,
				},
				{
					text:
						'As a user I want find out more detailed information what a specific Date in the graph contains (past, today and forecasted)',
					done: false,
				},
				{
					text: 'As a user I can see my current Account balanace',
					done: false,
				},
				{
					text:
						'As a user I want navigate through different times (past 6 months, today, 6 months ahead)',
					done: false,
				},
				{
					text: 'As a user I can see if I have any Unpaid invoices',
					done: false,
				},
				{
					text: 'As a user I can see if I have any Overdue invoices',
					done: false,
				},
				{
					text:
						'As a user I can find out more detailed information about my Unpaid invoices',
					done: false,
				},
				{
					text:
						'As a user I can find out more detailed information about my Overdue invoices',
					done: false,
				},
				{
					text: 'As a user I want to understand what this means (?)',
					done: false,
				},
				{
					text:
						'As a user I can see a probability of a forecasted transaction',
					done: false,
				},
				{
					text:
						'As a user I can see a probability of a forecasted total amount of a specific date',
					done: false,
				},
				{
					text:
						'As a user I can filter the list based on available cateogies',
					done: false,
				},
				{
					text:
						'As a user I can find my transactions (past, today, forecasted)',
					done: false,
				},
				{
					text:
						'As a user I can find more detailed information about a transaction',
					done: false,
				},
				{
					text: 'As a user I can find a Paid invoice',
					done: false,
				},
				{
					text: 'As a user I can find a Unpaid invoice',
					done: false,
				},
				{
					text: 'As a user I can find a Overdue invoice',
					done: false,
				},
				{
					text: 'As a user I want to get back to my overview',
					done: false,
				},
				{
					text: 'As a user I can find matched transactions',
					done: false,
				},
			],
			current: 0,
		};
	}

	// As a < type of user >, I want < some goal > so that < some reason >

	goToNext() {
		const nextIndex = Math.floor(
			Math.random() * (this.state.items.length - 1),
		);
		if (this.state.current + 1 === this.state.items.length) {
			this.setState({ current: nextIndex });
		} else {
			this.setState({ current: nextIndex });
		}
	}

	render() {
		return (
			<div class="card-stack-wrapper">
				<div className="card-stack">
					<ul className="card-list">
						{this.state.items.map((item, index) => (
							<li
								className={`card ${
									this.state.current === index
										? 'current'
										: ''
								}`}
								key={item.id}
								onClick={() => this.goToNext()}
							>
								<span
									className={item.done ? 'done' : ''}
									style={{
										color: 'rgb(105, 105, 105)',
										fontSize: '12px',
									}}
								>
									{item.text}
								</span>
							</li>
						))}
					</ul>
					<div className="reaction" style={{ display: 'none' }}>
						<ul className="reaction-list">
							<li className="reaction-dislike">
								<span className="reaction-dislike-btn">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<g
											fill="none"
											fillRule="evenodd"
											stroke="none"
											strokeWidth="1"
										>
											<g
												fill="#FFF"
												fillRule="nonzero"
												transform="rotate(-180 12 11.727)"
											>
												<path d="M6.56345455 21.2481818L6.56345455 9.90027273 0.238636364 9.90027273 0.238636364 21.2481818z" />
												<path d="M7.308 10.459v10.188l2.171.82c.93.34 1.985.713 3.008.991 1.023.28 2.014.466 3.07.588 1.054.125 2.17.187 2.976.157s1.303-.155 1.737-.373a3.368 3.368 0 001.085-.868c.279-.34.464-.713.558-1.023.094-.31.094-.558.124-.806.031-.248.093-.497.155-.683.062-.185.124-.31.216-.495l.311-.651c.093-.218.155-.404.187-.651.03-.249.03-.56.03-.838 0-.279 0-.527.061-.805.062-.28.187-.59.28-.838l.217-.62c.063-.186.125-.372.125-.559 0-.186-.062-.372-.125-.559-.062-.185-.124-.372-.124-.526 0-.156.062-.28.156-.497a4.15 4.15 0 00.28-.867 3.2 3.2 0 000-1.086 2.898 2.898 0 00-.404-1.084 2.917 2.917 0 00-.867-.868c-.342-.218-.714-.342-1.458-.404s-1.86-.062-2.573-.093c-.713-.03-1.024-.092-1.178-.154-.156-.062-.156-.124-.032-.372s.371-.682.558-1.147a5.76 5.76 0 00.373-1.52 8.147 8.147 0 000-1.736c-.062-.558-.187-1.055-.435-1.488A2.546 2.546 0 0016.643.537a3.858 3.858 0 00-1.736-.34c-.527.031-.9.216-1.147.588-.248.373-.372.93-.497 1.644-.124.713-.248 1.58-.744 2.573-.497.992-1.364 2.107-2.112 2.945-.748.838-1.375 1.396-1.872 1.769-.495.372-.86.557-1.225.742h-.002z" />
											</g>
										</g>
									</svg>
								</span>
							</li>
							<li className="rate">
								<ul>
									<li>
										<span className="reaction-rate">1</span>
									</li>
									<li>
										<span className="reaction-rate">2</span>
									</li>
									<li>
										<span className="reaction-rate">3</span>
									</li>
									<li>
										<span className="reaction-rate">4</span>
									</li>
									<li>
										<span className="reaction-rate">5</span>
									</li>
								</ul>
							</li>
							<li className="reaction-like">
								<span className="reaction-like-btn">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<g
											fill="none"
											fillRule="evenodd"
											stroke="none"
											strokeWidth="1"
										>
											<g fill="#FFF" fillRule="nonzero">
												<path d="M6.56345455 21.2481818L6.56345455 9.90027273 0.238636364 9.90027273 0.238636364 21.2481818z" />
												<path d="M7.308 10.459v10.188l2.171.82c.93.34 1.985.713 3.008.991 1.023.28 2.014.466 3.07.588 1.054.125 2.17.187 2.976.157s1.303-.155 1.737-.373a3.368 3.368 0 001.085-.868c.279-.34.464-.713.558-1.023.094-.31.094-.558.124-.806.031-.248.093-.497.155-.683.062-.185.124-.31.216-.495l.311-.651c.093-.218.155-.404.187-.651.03-.249.03-.56.03-.838 0-.279 0-.527.061-.805.062-.28.187-.59.28-.838l.217-.62c.063-.186.125-.372.125-.559 0-.186-.062-.372-.125-.559-.062-.185-.124-.372-.124-.526 0-.156.062-.28.156-.497a4.15 4.15 0 00.28-.867 3.2 3.2 0 000-1.086 2.898 2.898 0 00-.404-1.084 2.917 2.917 0 00-.867-.868c-.342-.218-.714-.342-1.458-.404s-1.86-.062-2.573-.093c-.713-.03-1.024-.092-1.178-.154-.156-.062-.156-.124-.032-.372s.371-.682.558-1.147a5.76 5.76 0 00.373-1.52 8.147 8.147 0 000-1.736c-.062-.558-.187-1.055-.435-1.488A2.546 2.546 0 0016.643.537a3.858 3.858 0 00-1.736-.34c-.527.031-.9.216-1.147.588-.248.373-.372.93-.497 1.644-.124.713-.248 1.58-.744 2.573-.497.992-1.364 2.107-2.112 2.945-.748.838-1.375 1.396-1.872 1.769-.495.372-.86.557-1.225.742h-.002z" />
											</g>
										</g>
									</svg>
								</span>
							</li>
						</ul>
						<div className="add-note" style={{ display: 'none' }}>
							<span className="add-note-button">
								Add note
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="9"
									viewBox="0 0 16 9"
								>
									<g
										fill="none"
										fillRule="evenodd"
										stroke="none"
										strokeWidth="1"
									>
										<g fill="#000" fillRule="nonzero">
											<path d="M.65.112A.38.38 0 10.112.65L7.73 8.27a.38.38 0 00.538 0L15.89.65a.38.38 0 10-.54-.538L7.998 7.464.65.112z" />
										</g>
									</g>
								</svg>
							</span>
						</div>
					</div>
					{/*
                lägg till verktyg som survey, lista man checkar av / kan skapa eget/egen lista som admin, ladda upp film/ljud och övriga notes eller observationer, smiley/etc
                
                kan vi få denna att poppa upp i feature flag menyn för context?
                */}
				</div>
			</div>
		);
	}
}

export default UserInput;
