import React, {
	useState,
	useContext,
	useEffect,
	useCallback,
	useMemo,
} from 'react';
import { ModalBody, ModalFooter } from '@asteria/component-modal';

import { sendSupport } from '@asteria/services-datalayer/services/appstate/actions';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { useFeature } from '@asteria/component-featureflag';
import Button from '@asteria/component-button/button';
import styled from 'styled-components';
import {
	FormGroupV2,
	// Counter,
	InputV2,
	CheckboxGroupV2,
} from '@asteria/component-form';
import { TranslationService } from '@asteria/services-language';
import {
	createValidator,
	useInput,
	useIsMounted,
	Getter,
} from '@asteria/utils';
import Alert from '@asteria/component-alert';
import { Text } from '@asteria/component-core';

const FormWrapper = styled.div`
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	/* margin: 0 20px; */
	padding: 16px 16px 30px;
	background-color: #faf2eb;
`;

const SUBJECT_FORM_GROUP_STYLE = { marginBottom: '15px' };
const EMAIL_FORM_GROUP_STYLE = SUBJECT_FORM_GROUP_STYLE;
const MESSAGE_FORM_GROUP_STYLE = { marginBottom: '8px' };

function invert(value) {
	return !value;
}
const ONLY_FIRST_SELECTED = [0];
const NONE_SELECTED = [];
const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const CreateTicket = styled(({ className, type, onClose = () => {} }) => {
	const { dispatch } = useContext(DatalayerContext);
	const [title, handleTitleChange] = useInput(
		TranslationService.get(`request.help.${type}.subject`, ''),
	);
	const [email, handleEmailChange] = useInput('');
	const [message, handleMessageChange] = useInput(
		TranslationService.get(`request.help.${type}.message`, ''),
	);
	const [isAgreed, setAgreementFlag] = useState(false);
	const [submission, setSubmission] = useState(false);
	const [validationErrors, setErrors] = useState({});
	const [showSuccess, setShowSuccess] = useState(false);

	const isTitleDisabled = useFeature({
		key: `request-help-${type?.toLowerCase()}-disable-title`,
	});

	const isMessageDisabled = useFeature({
		key: `request-help-${type?.toLowerCase()}-disable-message`,
	});

	const baseTranslationKeys = ['request.help', `request.help.${type}`];

	useEffect(() => {
		handleTitleChange({
			target: {
				value: TranslationService.get(
					`request.help.${type}.subject`,
					'',
				),
			},
		});
		handleMessageChange({
			target: {
				value: TranslationService.get(
					`request.help.${type}.message`,
					'',
				),
			},
		});
	}, [handleMessageChange, handleTitleChange, type]);

	// const maxMessageLength = 400;

	// Create validator function and define its rules and messages
	const validate = useCallback(
		createValidator(
			{
				title: () =>
					!title.length
						? TranslationService.get(
								baseTranslationKeys.map(
									t => `${t}.subject.validation.required`,
								),
								'Vänligen, skriv ett ärende för meddelandet',
						  )
						: undefined,
				email: () => {
					if (!email.length) {
						return TranslationService.get(
							baseTranslationKeys.map(
								t => `${t}.email.validation.required`,
							),
							'Vänligen, skriv din e-postadress adress för meddelandet',
						);
					}
					if (!emailPattern.test(String(email).toLowerCase())) {
						return TranslationService.get(
							baseTranslationKeys.map(
								t => `${t}.email.validation.format`,
							),
							'Vänligen ange din e-postadress',
						);
					}
					return undefined;
				},
				message: () => {
					if (!message.length) {
						return TranslationService.get(
							baseTranslationKeys.map(
								t => `${t}.message.validation.required`,
							),
							'supportModal.step2.form.message.validation.required',
						);
					}
					// if (message.length > maxMessageLength) {
					// 	return TranslationService.get(
					// 		'supportModal.step2.form.message.validation.length',
					// 		'Your message is too long',
					// 	);
					// }
					return undefined;
				},
				agreement: () =>
					!isAgreed
						? TranslationService.get(
								baseTranslationKeys.map(
									t => `${t}.agreement.validation.required`,
								),
								'Please agree here',
						  )
						: undefined,
			},
			setErrors,
			validationErrors,
		),
		[setErrors, validationErrors, isAgreed, message, email, title],
	);

	const isMounted = useIsMounted();
	// Prepare a submit function here to keep it consistent with validation state
	const submitTicket = useCallback(async () => {
		if (!Object.keys(validationErrors).length) {
			// If there are no errors
			// setShowSuccess('1234');
			// if (false) {
			const response = await dispatch(
				sendSupport({ subject: title, message, email }),
			).toPromise();

			if (!isMounted.current) return;

			const {
				reportIssue: { ok, reportId },
			} = response.data;

			if (ok) {
				setShowSuccess(reportId);
			}
		}
		if (!isMounted.current) return;
		setSubmission(false); // Don't forget to drop submission flag
	}, [dispatch, email, isMounted, message, title, validationErrors]);

	// Submit form
	useEffect(() => {
		// Try to submit form just after we get a consistent state
		if (submission) {
			submitTicket();
		}
	}, [submitTicket, submission]);
	const checkboxGroupItems = useMemo(
		() => [
			TranslationService.get(
				baseTranslationKeys.map(t => `${t}.agreement.label`),
				'Jag godkänner att Asteria kontaktar mig för support av Kassaflöde pä ovan nämnda e-postaddress',
			),
		],
		[baseTranslationKeys],
	);
	const toggleIsAgreed = useCallback(() => setAgreementFlag(invert), []);
	const handleCreate = useCallback(() => {
		validate(); // Check all form fields again
		setSubmission(true); // Try to submit form after we get a consistent form state
	}, [validate]);

	if (showSuccess) {
		return (
			<div className={className}>
				<ModalBody>
					<div
						style={{
							display: 'flex',
							flex: '1',
							flexDirection: 'column',
							margin: '0 20px',
							padding: '16px',
							backgroundColor: '#FAF2EB',
						}}
					>
						<Text type="paragraph">
							{TranslationService.get(
								baseTranslationKeys.map(
									t => `${t}.success.text`,
								),
								'Ditt support ärende nummer är #',
								{ showSuccess },
							)}
						</Text>
					</div>
				</ModalBody>
				<ModalFooter
					style={{
						paddingLeft: '20px',
						paddingRight: '20px',
					}}
				>
					<Button
						size="regular"
						type="primary"
						text={TranslationService.get(
							[
								'button.done',
								...baseTranslationKeys.map(
									t => `${t}.success.done`,
								),
							],
							'Färdig',
						)}
						onClick={onClose}
					/>
				</ModalFooter>
			</div>
		);
	}

	return (
		<div className={className}>
			<ModalBody scroll className="modal-body">
				<FormWrapper className="asteria-dialog-box">
					<FormGroupV2 style={SUBJECT_FORM_GROUP_STYLE}>
						<InputV2
							className="asteria-faq-title"
							placeholder={TranslationService.get(
								baseTranslationKeys.map(
									t => `${t}.subject.placeholder`,
								),
								'Ange ämne',
							)}
							onChange={handleTitleChange}
							value={title}
							disabled={isTitleDisabled}
							invertedcolor
						/>
						{validationErrors.title ? (
							<Alert
								icon
								type="error"
								showClose={false}
								headingContent={validationErrors.title}
							/>
						) : null}
					</FormGroupV2>
					<FormGroupV2 style={EMAIL_FORM_GROUP_STYLE}>
						<InputV2
							className="asteria-risk-help-email"
							placeholder={TranslationService.get(
								baseTranslationKeys.map(
									t => `${t}.email.placeholder`,
								),
								'Skriv din e-postadress',
							)}
							onChange={handleEmailChange}
							value={email}
							invertedcolor
						/>
						{validationErrors.email ? (
							<Alert
								icon
								type="error"
								showClose={false}
								headingContent={validationErrors.email}
							/>
						) : null}
					</FormGroupV2>
					<FormGroupV2 style={MESSAGE_FORM_GROUP_STYLE}>
						<InputV2
							className="asteria-risk-message"
							elementType="textarea"
							placeholder={TranslationService.get(
								baseTranslationKeys.map(
									t => `${t}.message.placeholder`,
								),
								'Skriv ditt meddelande här',
							)}
							onChange={handleMessageChange}
							disabled={isMessageDisabled}
							value={message}
							invertedcolor
						/>
						{validationErrors.message ? (
							<Alert
								icon
								type="error"
								showClose={false}
								headingContent={validationErrors.message}
							/>
						) : null}
					</FormGroupV2>
				</FormWrapper>
				<FormGroupV2>
					<CheckboxGroupV2
						items={checkboxGroupItems}
						selected={
							isAgreed ? ONLY_FIRST_SELECTED : NONE_SELECTED
						}
						onChange={toggleIsAgreed}
						supressOwnState
						labelPosition="default"
					/>
					{validationErrors.agreement ? (
						<Alert
							icon
							type="error"
							showClose={false}
							headingContent={validationErrors.agreement}
						/>
					) : null}
				</FormGroupV2>
			</ModalBody>
			<ModalFooter className="modal-footer">
				<Button
					size="medium"
					type="default"
					className="asteria-button-cancel"
					text={TranslationService.get(
						[
							'button.abort',
							...baseTranslationKeys.map(
								t => `${t}.button.cancel`,
							),
						],
						'Avbryt',
					)}
					onClick={onClose}
				/>
				<Button
					size="medium"
					type="primary"
					className="asteria-button-send"
					text={TranslationService.get(
						[
							'button.create',
							...baseTranslationKeys.map(
								t => `${t}.button.create`,
							),
						],
						'Skicka',
					)}
					disabled={submission}
					onClick={handleCreate}
				/>
			</ModalFooter>
		</div>
	);
})``;

CreateTicket.Styler = {
	children: [
		{
			component: Button,
			base: [Getter('button')],
		},
		{
			component: CheckboxGroupV2,
			base: [Getter('checkbox')],
		},
		{
			component: InputV2,
			base: [Getter('input')],
		},
		{
			component: FormWrapper,
			base: [Getter('form')],
		},
	],
};

export default CreateTicket;
