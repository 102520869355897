import React, { useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import DatalayerContext from '@asteria/services-datalayer/react/context';
import { TranslationService } from '@asteria/services-language';
import { useStore } from '@asteria/utils';
import { toggleFilter } from '@asteria/services-datalayer/services/appstate/actions';
import { Dropdown } from '@asteria/component-form';
import { useFeature } from '@asteria/component-featureflag';

const CategoriesSelector = React.memo(
	styled(({ className, onChange = () => {}, onState = () => {} }) => {
		const { dispatch } = useContext(DatalayerContext);
		const hasCategoryGroup = useFeature({
			key: 'selector-category-grouping',
		});
		const [categories] = useStore(
			'store-graph',
			({ availableCategories: list }) => list,
		);

		const [filters = []] = useStore(
			'store-appstate',
			({ filters: list }) => list,
		);

		const [tags] = useStore(
			'store-graph',
			({ availableTags: list }) => list,
		);

		const onStateChange = useCallback(
			val => {
				onState(val, 'categories');
			},
			[onState],
		);

		const categoryOptions = useMemo(() => {
			if (hasCategoryGroup) {
				return categories.map(
					({ _id, name, tags: categoryTags = [] }) => ({
						label: TranslationService.get(
							`tags.${name.replace('$', '')}`,
						),
						id:
							categoryTags.length <= 1
								? categoryTags?.[0]?._id
								: _id,
						icon:
							categoryTags && categoryTags.length > 1
								? 'right'
								: '$tag',
						iconSelected:
							categoryTags && categoryTags.length > 1
								? 'left'
								: false,
						color: categoryTags?.[0]?.name,
						options:
							categoryTags && categoryTags.length > 1
								? categoryTags.map(
										({ _id: tagId, name: tagName }) => ({
											label: TranslationService.get(
												`tags.${tagName.replace(
													'$',
													'',
												)}`,
											),
											id: tagId,
											icon: '$tag',
											color: tagName,
										}),
								  )
								: undefined,
					}),
				);
			}

			return categories
				.map(({ name, tags: categoryTags = [] }) =>
					categoryTags.map(({ _id: tagId, name: tagName }) => ({
						label: TranslationService.get([
							`tags.${tagName.replace('$', '')}`,
							`tags.${name.replace('$', '')}.${tagName.replace(
								'$',
								'',
							)}`,
						]),
						id: tagId,
						icon: '$tag',
						color: tagName,
					})),
				)
				.flat();
		}, [categories, hasCategoryGroup]);

		const setFilter = useCallback(
			({ id }) => {
				const tag = tags.find(({ id: tagId }) => id === tagId);

				if (tag) {
					dispatch(toggleFilter(tag));
				}

				onChange();
			},
			[dispatch, onChange, tags],
		);

		const selected = useMemo(() => {
			const tagFilters = filters.filter(({ type }) => type === 'tag');

			const unfoldedTags = categoryOptions
				.reduce(
					(acc, tag) => [...acc, tag, ...(tag?.options || [])],
					[],
				)
				.flat();

			return tagFilters.map(({ id }) =>
				unfoldedTags.find(({ id: valueId }) => valueId === id),
			);
		}, [categoryOptions, filters]);

		return (
			<Dropdown
				iconClosed="triangleDown"
				iconOpen="triangleUp"
				itemIconSelected="check"
				options={categoryOptions}
				selected={selected}
				placeholder={TranslationService.get(
					'menu.categories.title',
					'Categories',
				)}
				onState={onStateChange}
				className={classNames(
					className,
					'asteria-display-filter-navigation',
				)}
				type="link"
				onChange={setFilter}
			/>
		);
	})``,
);

CategoriesSelector.displayName = 'CategoriesSelector';

CategoriesSelector.Styler = {
	children: [],
};
export default CategoriesSelector;
