import React, {
	useState,
	useEffect,
	useContext,
	useMemo,
	useCallback,
	useRef,
} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { format } from 'date-fns';

import DatalayerContext from '@asteria/services-datalayer/react/context';
import { Text, Title } from '@asteria/component-core';
import { formatNumber, useStore } from '@asteria/utils';
import RequestHelp from '@asteria/component-support/components/requestHelp';
import Button from '@asteria/component-core/button';
import { Translation, TranslationService } from '@asteria/services-language';
import { markNotificationAsViewed } from '@asteria/services-datalayer/services/notifications/actions';
import Tooltip from '@asteria/component-tooltip';
import { LinearIndicator } from '@asteria/component-progressbar-indicator';
import Icon from '@asteria/component-core/icon';
import { FeatureFlag } from '@asteria/component-featureflag';
import InfoBox, {
	InfoBoxToggle,
} from '@asteria/component-list/components/infoBox';
// TODO: Fix this
import 'currency-flags/dist/currency-flags.css';

import { clearGraphData } from '@asteria/services-datalayer/services/graph/actions';
import {
	addFilters,
	setFilters,
} from '@asteria/services-datalayer/services/appstate/actions';
import Tabs, {
	TabsContent,
	TabsNavigation,
} from '@asteria/component-form/tabs';
import { useHistory } from '@asteria/utils/hooks';
import { StatsTag } from '@asteria/component-tags';
import Pie from '../pie';

const GET_CURRENCIES = `
query Exposure($filters: TransactionFiltersInput){
	transactionCurrencies(filters: $filters) {
	  currency
	  deposit {
		original {
		  total
		  currency
		}
		display {
		  total
		  currency
		}
		exposure {
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		tags {
		  tag {
			id
			name
		  }
		  type
		  parts {
			status
			count
			original {
			  total
			  currency
			}
			display {
			  total
			  currency
			}
		  }
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		count
	  }
  
	  withdraw {
		original {
		  total
		  currency
		}
		display {
		  total
		  currency
		}
		exposure {
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		tags {
		  tag {
			id
			name
		  }
		  type
		  parts {
			status
			count
			original {
			  total
			  currency
			}
			display {
			  total
			  currency
			}
		  }
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		count
	  }
  
	  exposure {
		original {
		  total
		  currency
		}
		display {
		  total
		  currency
		}
	  }

	  risk {
		  original {
			  total
			  currency
		  }
		  display {
			  total
			  currency
		  }
		  risk
		  alert
	  }
	}
  }
  
`;

const CurrencyTagDetails = styled(
	({ tag, total = 1, className, active, setShow = () => {} }) => {
		const { dispatch } = useContext(DatalayerContext);
		const { type } = tag;
		const name = tag?.tag?.name || '$unknown';

		const stats = useMemo(() => {
			const clientStats = {
				total,
				parts: tag?.parts?.map(part => ({
					total: part?.display?.total || 0,
					count: part?.count || 0,
					status: part?.status,
					tag: {
						name,
					},
				})),
			};

			return { [type.toLowerCase()]: clientStats };
		}, [total, tag.parts, type, name]);

		const [availableTags = []] = useStore(
			'store-graph',
			state => state.availableTags,
		);

		const filterTag = availableTags.find(
			({ config: { name: tName } }) => tName === name,
		);

		const [filters = []] = useStore(
			'store-appstate',
			({ filters: list }) => list,
		);

		const isActive = useMemo(() => active && filters.includes(filterTag), [
			active,
			filterTag,
			filters,
		]);

		const onFilter = useCallback(
			(_, status) => {
				const enableFilters = [
					{
						id: `currency_${tag?.original?.currency}`,
						type: 'currency',
						config: {
							name: tag?.original?.currency,
						},
					},
				];

				if (filterTag) {
					enableFilters.push(filterTag);
				}

				dispatch(clearGraphData('cashflow-bar-graph', true));

				if (status) {
					enableFilters.push({
						id: `status-${status}`,
						type: 'status',
						config: {
							name: `$${status.toLowerCase()}`,
							status,
						},
					});
				}

				dispatch(setFilters(enableFilters));
				setShow(false);
			},
			[dispatch, filterTag, setShow, tag.original.currency],
		);

		return (
			<StatsTag
				className={className}
				name={name}
				tagType="tag"
				type={type.toLowerCase()}
				statistics={stats}
				onClick={onFilter}
				active={isActive}
			/>
		);
	},
)``;

const CurrencyDetails = styled(
	({ className, setShow, currency, tagActive }) => (
		<div className={classNames(className, 'asteria-list-item-details')}>
			{currency?.tags?.map(tag => (
				<CurrencyTagDetails
					tag={tag}
					setShow={setShow}
					active={tagActive}
					total={currency?.display?.total || 1}
				/>
			))}
		</div>
	),
)``;

const CurrencyExposure = styled(({ currency }) => {
	const isVisible =
		currency?.deposit?.exposure?.display?.total !== 0 ||
		currency?.withdraw?.exposure?.display?.total !== 0 ||
		currency?.exposure?.display?.total !== 0;

	const [accounts] = useStore(
		'store-bankaccounts',
		({ bankAccounts = [] }) => bankAccounts,
	);

	const hasAccount = useMemo(
		() =>
			(accounts || []).some(
				item => item?.sums?.original?.currency === currency.currency,
			),
		[accounts, currency.currency],
	);

	if (!isVisible) {
		return (
			<div className="asteria-currency-cell asteria-currency-cell-exposure" />
		);
	}

	if (hasAccount) {
		return (
			<div className="asteria-currency-cell asteria-currency-cell-exposure">
				<div
					className={classNames(
						'asteria-currency-cell-exposure-total',
						{
							'asteria-state-negative':
								(currency?.exposure?.display?.total || 0) !== 0,
							'asteria-state-zero':
								(currency?.exposure?.display?.total || 0) === 0,
						},
					)}
				>
					<Text className="asteria-text asteria-label">
						{TranslationService.get(
							'currencies.exposure',
							'Exponering',
						)}
					</Text>
					<Text className={classNames('asteria-text-exposure')}>
						{formatNumber(
							currency?.exposure?.display?.total || 0,
							false,
							false,
							false,
						)}
						<span>
							{currency?.exposure?.display?.currency ||
								currency?.deposit?.display?.currency}
						</span>
					</Text>
					<Text
						className={classNames(
							'asteria-text-exposure',
							'asteria-text-original',
						)}
					>
						{formatNumber(
							currency?.exposure?.original?.total || 0,
							false,
							false,
							false,
						)}
						<span>
							{currency?.exposure?.original?.currency ||
								currency?.deposit?.original?.currency}
						</span>
					</Text>
				</div>
			</div>
		);
	}

	return (
		<div className="asteria-currency-cell asteria-currency-cell-exposure">
			<div className="asteria-currency-cell-exposure-deposit asteria-text-deposit">
				<Text className="asteria-text asteria-label">
					{TranslationService.get(
						'currencies.exposure.in',
						'Exponering in',
					)}
				</Text>
				<Text className="asteria-text-exposure asteria-text-deposit">
					{formatNumber(
						currency?.deposit?.exposure?.display?.total || 0,
						false,
						false,
						false,
					)}
					<span>
						{currency?.deposit?.exposure?.display?.currency ||
							currency?.deposit?.display?.currency}
					</span>
				</Text>
				<Text className="asteria-text-exposure asteria-text-original asteria-text-deposit">
					{formatNumber(
						currency?.deposit?.exposure?.original?.total || 0,
						false,
						false,
						false,
					)}
					<span>
						{currency?.deposit?.exposure?.original?.currency ||
							currency?.deposit?.original?.currency}
					</span>
				</Text>
			</div>
			<div className="asteria-currency-cell-exposure-withdraw asteria-text-withdraw">
				<Text className="asteria-text asteria-label">
					{TranslationService.get(
						'currencies.exposure.out',
						'Exponering ut',
					)}
				</Text>
				<Text className="asteria-text-exposure asteria-text-withdraw">
					{formatNumber(
						currency?.withdraw?.exposure?.display?.total || 0,
						true,
						false,
						false,
					)}
					<span>
						{currency?.withdraw?.exposure?.display?.currency ||
							currency?.deposit?.display?.currency}
					</span>
				</Text>
				<Text className="asteria-text-exposure asteria-text-original asteria-text-withdraw">
					{formatNumber(
						currency?.withdraw?.exposure?.original?.total || 0,
						true,
						false,
						false,
					)}
					<span>
						{currency?.withdraw?.exposure?.original?.currency ||
							currency?.deposit?.original?.currency}
					</span>
				</Text>
			</div>
		</div>
	);
})``;

const CurrencyAccount = styled(({ currency, setShow }) => {
	const [accounts] = useStore(
		'store-bankaccounts',
		({ bankAccounts = [] }) => bankAccounts,
	);

	const hasAccount = useMemo(
		() =>
			(accounts || []).some(
				item => item?.sums?.original?.currency === currency.currency,
			),
		[accounts, currency.currency],
	);

	const { original, display } = useMemo(
		() =>
			(accounts || [])
				.filter(
					item =>
						item?.sums?.original?.currency === currency.currency,
				)
				.reduce(
					(acc, item) => ({
						original:
							acc.original + item?.sums?.original?.total || 0,
						display: acc.display + item?.sums?.display?.total || 0,
					}),
					{ display: 0, original: 0 },
				),
		[accounts, currency.currency],
	);

	return (
		<div className="asteria-currency-cell asteria-currency-cell-currency-total">
			{hasAccount &&
				currency?.exposure?.display?.currency !==
					currency?.exposure?.original?.currency && (
					<>
						<Text className="asteria-text asteria-label">
							{TranslationService.get(
								'currencies.total',
								'Belopp',
							)}
						</Text>
						<Text className="asteria-text-amount">
							{formatNumber(display || 0, false, false, false)}
							<span>{currency?.exposure?.display?.currency}</span>
						</Text>
					</>
				)}
			{hasAccount && (
				<Text
					className={classNames('asteria-text-amount', {
						'asteria-text-original':
							currency?.exposure?.display?.currency !==
							currency?.exposure?.original?.currency,
					})}
				>
					{formatNumber(original || 0, false, false, false)}
					<span>
						{currency?.exposure?.original?.currency ||
							currency?.deposit?.original?.currency}
					</span>
				</Text>
			)}
			{!hasAccount && (
				<Tooltip
					className="asteria-tooltip-transaction-cell-account"
					hover
					isStatic
					title=""
					content={TranslationService.get(
						'currencies.account.missing.tooltip',
						'Du har inget valutakonto i denna valuta. Klicka för att få hjälp med att få veta mer om valutakonton.',
					)}
				>
					<RequestHelp
						type="currencyAccount"
						className="asteria-button-no-account-help asteria-text-amount asteria-no-currency-account"
						onClick={() => setShow(false)}
					/>
				</Tooltip>
			)}
		</div>
	);
})``;

const CurrencyImpact = styled(({ currency, totalImpact = 0 }) => {
	if (currency.currency === 'EUR' && !currency.hasAccount) {
		// eslint-disable-next-line no-param-reassign
		currency.hasAccount = true;
	}

	const currencyTotal =
		(currency?.deposit?.display?.total || 0) -
		(currency?.withdraw?.display?.total || 0);

	const currencyImpact = Math.round(
		(Math.abs(currencyTotal) / totalImpact) * 100,
	);

	const displayDepositTotal = currency?.deposit?.display?.total;
	const displayWithdrawTotal = currency?.withdraw?.display?.total;

	const piePieces = useMemo(
		() => [
			{
				label: 'Inkomster',
				value: Math.abs(
					(displayDepositTotal || 0) - (displayWithdrawTotal || 0),
				),
				target: '',
				type: 'amount',
			},
			{
				label: 'Övriga',
				value:
					totalImpact -
					Math.abs(
						(displayDepositTotal || 0) -
							(displayWithdrawTotal || 0),
					),
				target: '',
				type: 'rest',
			},
		],
		[displayDepositTotal, displayWithdrawTotal, totalImpact],
	);

	return (
		<div className="asteria-currency-cell asteria-currency-cell-currency-balance">
			<div className="asteria-currency-cell-currency-balance-inner">
				<Text className="asteria-text asteria-label">
					{TranslationService.get(
						'currencies.currency.balance',
						'Valutanetto',
					)}
				</Text>
				<Text className="asteria-text-amount">
					{formatNumber(
						(currency?.deposit?.display?.total || 0) -
							(currency?.withdraw?.display?.total || 0),
					)}
				</Text>
			</div>
			<Tooltip
				className="asteria-tooltip-transaction-cell-status"
				hover
				isStatic
				title=""
				content={TranslationService.get(
					'currencies.currency.impact.tooltip',
					'Denna valuta har en potentiell {{currencyImpact}}% påverkan på ditt framtida kassaflöde',
					{ currencyImpact },
				)}
			>
				<Pie items={piePieces} />
			</Tooltip>
		</div>
	);
})``;

const CurrencyView = styled(
	({
		className,
		index,
		total = 1,
		totalImpact = 0,
		currency,
		setShow,
		append = false,
	}) => {
		const { dispatch } = useContext(DatalayerContext);
		const [showDetails, setShowDetails] = useState(false);
		const history = useHistory();

		const riskAction = useCallback(() => {
			setShow(false);
			history.push(`/request/currencyrisk`);
		}, [history, setShow]);

		const onFilter = useCallback(() => {
			const filters = [
				{
					id: `currency_${currency?.currency}`,
					type: 'currency',
					config: {
						name: currency?.currency,
					},
				},
			];

			dispatch(clearGraphData('cashflow-bar-graph', true));

			if (append) {
				dispatch(addFilters(filters));
			} else {
				dispatch(setFilters(filters));
			}

			setShow(false);
		}, [append, currency.currency, dispatch, setShow]);

		const [filters = []] = useStore(
			'store-appstate',
			({ filters: list }) => list,
		);

		const isActive = useMemo(
			() =>
				filters.find(
					({ id }) => id === `currency_${currency?.currency}`,
				),
			[currency.currency, filters],
		);

		return (
			<li
				className={classNames(className, 'asteria-list-item', {
					'asteria-state-active': isActive,
					'asteria-state-open': showDetails,
				})}
			>
				<div className="asteria-list-item-inner">
					{index !== undefined && (
						<div className="asteria-currency-cell asteria-cell-rank asteria-rank">
							<Text>{index + 1}</Text>
						</div>
					)}
					<div className="asteria-currency-cell asteria-currency-cell-currency asteria-overview">
						<Text
							onClick={onFilter}
							onKeyPress={onFilter}
							tabIndex="-1"
							role="button"
						>
							<span
								className={`currency-flag currency-flag-${currency?.currency.toLowerCase()}`}
							/>
							{currency?.currency}
						</Text>
						{/* <Text className="asteria-text-account-number">
							Du har inget valutakonto, vänd dig till din rådgivare för mer information
						</Text> */}
						{/* <div
							className="asteria-prefix"
							style={{
								width: `${Math.abs(
									Math.round(
										(currency?.exposure?.display?.total /
											total) *
											100,
									),
								)}%`,
							}}
						>
							<span className="asteria-prefix-indicator" />
						</div> */}
					</div>
					<CurrencyExposure currency={currency} />
					<div
						className={classNames(
							'asteria-currency-cell',
							'asteria-currency-cell-currency-risk',
							{
								'asteria-state-risk': currency?.risk?.alert,
							},
						)}
					>
						{currency?.risk?.display?.total !== 0 && (
							<>
								{currency?.risk?.alert && (
									<Tooltip
										hover
										isStatic
										title=""
										content={TranslationService.get(
											'currencies.risk.alert',
											'Ditt företag har en större valutarisk i {{currency.currency}}. Klicka för att få hjälp med hur du effektivt kan hantera din valutarisk',
											{ currency },
										)}
									>
										<Icon
											asset="info"
											onClick={riskAction}
										/>
									</Tooltip>
								)}
								<div className="asteria-currency-cell-risk-inner">
									<Text className="asteria-text asteria-label">
										{TranslationService.get(
											'currencies.total.risk',
											'Valutarisk',
										)}
									</Text>
									<Text
										className={classNames(
											'asteria-text-risk',
										)}
									>
										{`±${formatNumber(
											currency?.risk?.display?.total || 0,
											false,
											false,
											true,
										)}`}
									</Text>
								</div>
							</>
						)}
					</div>
					<div
						className="asteria-currency-cell asteria-currency-compare"
						style={{ display: 'none' }}
					>
						{(currency?.deposit?.exposure?.display?.total !== 0 ||
							currency?.withdraw?.exposure?.display?.total !==
								0 ||
							currency?.exposure?.display?.total !== 0) && (
							<div
								className="asteria-prefix"
								style={{
									width: `${Math.abs(
										Math.round(
											(currency?.exposure?.display
												?.total /
												total) *
												100,
										),
									)}%`,
								}}
								title={TranslationService.get(
									'currency.compare.label',
									'Påverkan i valuta {{impact}}%',
									{
										impact: Math.abs(
											Math.round(
												(currency?.exposure?.display
													?.total /
													total) *
													100,
											),
										),
									},
								)}
							>
								<span className="asteria-prefix-indicator" />
							</div>
						)}
					</div>
					<CurrencyImpact
						currency={currency}
						totalImpact={totalImpact}
					/>
					<CurrencyAccount currency={currency} setShow={setShow} />
					<FeatureFlag feature="currency-list-sub">
						<div className="asteria-currency-cell asteria-currency-cell-expand">
							<Button
								type="link"
								size="small"
								// icon="down"
								icon={showDetails ? 'up' : 'down'}
								onClick={e => {
									e.stopPropagation();
									setShowDetails(!showDetails);
								}}
							/>
						</div>
					</FeatureFlag>
				</div>
				<FeatureFlag feature="currency-list-sub">
					{showDetails && (
						<div className="asteria-list-item-sub">
							<Tabs active="deposit">
								<TabsNavigation>
									{({
										tab,
										setTab,
										className: buttonClass,
									}) => (
										<div className={buttonClass}>
											<Button
												className={classNames({
													'asteria-state-active': tab.includes(
														'deposit',
													),
												})}
												type="link"
												onClick={() =>
													setTab('deposit')
												}
											>
												{TranslationService.get(
													'currencies.tab.deposit',
													'Inbetalningar',
												)}
											</Button>
											<Text className="asteria-sum-deposit">
												{formatNumber(
													currency?.deposit?.display
														?.total || 0,
												)}
											</Text>
										</div>
									)}
									{({
										tab,
										setTab,
										className: buttonClass,
									}) => (
										<div className={buttonClass}>
											<Button
												className={classNames({
													'asteria-state-active': tab.includes(
														'withdraw',
													),
												})}
												type="link"
												onClick={() =>
													setTab('withdraw')
												}
											>
												{TranslationService.get(
													'currencies.tab.withdraw',
													'Inbetalningar',
												)}
											</Button>
											<Text className="asteria-sum-withdraw">
												{formatNumber(
													currency?.withdraw?.display
														?.total || 0,
													true,
												)}
											</Text>
										</div>
									)}
								</TabsNavigation>
								<TabsContent>
									<CurrencyDetails
										tab="deposit"
										currency={currency.deposit}
										setShow={setShow}
										tagActive={isActive}
									/>
									<CurrencyDetails
										tab="withdraw"
										currency={currency.withdraw}
										setShow={setShow}
										tagActive={isActive}
									/>
								</TabsContent>
							</Tabs>
						</div>
					)}
				</FeatureFlag>
			</li>
		);
	},
)`
	${Pie} {
		width: 32px;
		height: 32px;

		.asteria-pie-segment-amount {
			.asteria-pie-segment-value {
				cursor: default;
				background-color: #54A1AC;
			}
		}

		.asteria-pie-segment-rest {
			// background-color: #D7E5E7;
			.asteria-pie-segment-value {
				cursor: default;
				background-color: #D7E5E7;
			}
		}
	}

	&.asteria-list-item {
		display: flex;
		.asteria-list-item-inner {
			position: relative;
			align-items: center;
			padding: 9px 8px 9px 12px;
			/* border-bottom: 1px solid rgb(235, 231, 225); */
			display: flex;
			flex-direction: row;
			margin: 0;

			.asteria-rank {
				min-width: 16px;
				margin: 0 8px 0 0;
				font-family: Swedbank Sans;
				.asteria-text {
					font-family: Arial,Helvetica,sans-serif;
					font-size: 14px;
					color: var(--text-color);
				}
			}

			.asteria-overview {
				flex: 1;
				margin: 0 16px 0 0;
				position: relative;
				// height: 32px;
				display: flex;
				align-items: center;
				.asteria-text {
					font-family: Swedbank Sans;
					font-weight: 700;
					font-size: 14px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 100%;
					color: var(--text-color);
					border: 1px solid transparent;
					background: transparent;
					align-items: center;
					cursor: pointer;
					display: inline-flex;
					padding: 5px 12px 6px 12px;
					width: auto;
					border-radius: 50px;
					&:hover {
						border: 1px solid #D3CAC0;
						background: #D3CAC0;
					}
					&.asteria-state-active {
						border: 1px solid #D3CAC0;
						background: #D3CAC0;
					}
				}
			}
			.asteria-currency-compare {
				position: relative;	
				min-width: 160px;
				z-index: 1;
			}
			.asteria-prefix {
				background: #EAE7E4;
				border-radius: 50px;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;
				.asteria-prefix-indicator {
					
				}
			}

			.asteria-text-amount {
				width: 115px;
				text-align: right;
				padding-left: 6px;
				font-family: Arial,Helvetica,sans-serif;
				font-size: 14px;
				color: var(--text-color);
			}

			.asteria-button-icon .asteria-icon-wrapper svg {
				fill: #9f8c82 !important;

			.asteria-list-item-description {
				flex-grow: 1;
				${Text} {
					color: var(--muted-color);
					font-family: Arial, Helvetica, sans-serif;
					font-size: 12px;
				}
			}

			.asteria-list-item-summary {
				display: flex;
				flex-direction: row;
				align-items: center;
				${Button} {
					margin-left: 4px;
					.asteria-icon-wrapper {
						svg {
							fill: #9f8c82 !important;
						}
					}
				}
			}
		}
	}

	.asteria-list-item-inner {
		display: flex;
	}

	flex-direction: column;
`;

const CurrenciesView = styled(
	({
		className,
		isTabActive = true,
		start,
		setShow = () => {},
		type = 'ALL',
		data: initialData = false,
	}) => {
		const { lookup, dispatch, demo } = useContext(DatalayerContext);
		const apiService = lookup('service-api');
		const isDemo = demo;

		console.log(demo);

		let data = initialData;
		const demoData = useMemo(
			() => [
				{
					currency: 'SEK',
					deposit: {
						display: {
							total: 25000,
						},
						exposure: {
							display: {
								total: 5380,
							},
						},
					},
					withdraw: {
						display: {
							total: 15000,
						},
						exposure: {
							display: {
								total: 9370,
							},
						},
					},
				},
				{
					currency: 'EUR',
					risk: {
						display: {
							total: 1827,
						},
					},
					exposure: {
						display: {
							total: 538 - 937,
						},
						original: {
							total: (538 - 937) / 10,
						},
					},
					deposit: {
						display: {
							total: 2500,
						},
						exposure: {
							display: {
								total: 538,
							},
						},
					},
					withdraw: {
						display: {
							total: 1500,
						},
						exposure: {
							display: {
								total: 937,
							},
						},
					},
				},
			],
			[],
		);

		if (isDemo) {
			data = demoData;
		}

		const [isLoading, setLoading] = useState(true);
		const [isHelpOpen, setHelpOpen] = useState(false);
		const [currencies, setCurrencies] = useState(data || []);
		const [total, setTotal] = useState(0);
		const [impact, setTotalImpact] = useState(0);
		const [deposit, setTotalDeposit] = useState(0);
		const [withdraw, setTotalWithdraw] = useState(0);
		const [risk, setRisk] = useState(0);
		const [hasRiskAlert, setHasRiskAlert] = useState(false);
		// const history = useHistory();

		const [notifications] = useStore(
			'store-notifications',
			({ notifications: list }) => list,
		);

		const wasLastClosed = useRef(true);

		useEffect(() => {
			if (!isTabActive) {
				wasLastClosed.current = true;
				return;
			}

			if (wasLastClosed.current) {
				notifications
					.filter(({ notificationKey = '' }) =>
						notificationKey?.includes('currency'),
					)
					.map(({ id }) => dispatch(markNotificationAsViewed(id)));
			}
			wasLastClosed.current = false;
		}, [dispatch, isTabActive, notifications]);

		useEffect(() => {
			if (!isTabActive) {
				return;
			}
			if (data) {
				setLoading(false);
				setHasRiskAlert(data.some(({ risk: { alert } = {} }) => alert));
				setRisk(
					data.reduce(
						(acc, item) =>
							acc + Math.abs(item?.risk?.display?.total || 0),
						0,
					),
				);
				setTotal(
					data.reduce(
						(acc, item) =>
							acc + Math.abs(item?.exposure?.display?.total || 0),
						0,
					),
				);
				setTotalImpact(
					data.reduce(
						(acc, item) =>
							acc +
							Math.abs(
								(item?.deposit?.display?.total || 0) -
									(item?.withdraw?.display?.total || 0),
							),
						0,
					),
				);
				setTotalDeposit(
					data.reduce(
						(acc, item) =>
							acc +
							Math.abs(
								item?.deposit?.exposure?.display?.total || 0,
							),
						0,
					),
				);
				setTotalWithdraw(
					data.reduce(
						(acc, item) =>
							acc +
							Math.abs(
								item?.withdraw?.exposure?.display?.total || 0,
							),
						0,
					),
				);

				return;
			}
			setLoading(true);
			apiService
				.query(
					GET_CURRENCIES,
					{
						filters: {
							startDate: `${format(
								start || new Date(),
								'YYYY-01-01',
							)}T00:00:00.000`,
							endDate: `${format(
								start || new Date(),
								'YYYY-12-31',
							)}T23:59:59.999`,
						},
					},
					{ reqAuth: true },
				)
				.subscribe(resp => {
					const transactionCurrencies =
						resp?.data?.transactionCurrencies || [];
					setLoading(false);
					setHasRiskAlert(
						transactionCurrencies.some(
							({ risk: { alert } }) => alert,
						),
					);
					setRisk(
						transactionCurrencies.reduce(
							(acc, item) =>
								acc + Math.abs(item?.risk?.display?.total || 0),
							0,
						),
					);
					setCurrencies(
						transactionCurrencies.sort(
							(a, b) =>
								Math.abs(
									(b?.deposit?.display?.total || 0) -
										(b?.withdraw?.display?.total || 0),
								) -
								Math.abs(
									(a?.deposit?.display?.total || 0) -
										(a?.withdraw?.display?.total || 0),
								),
						),
					);
					setTotal(
						transactionCurrencies.reduce(
							(acc, item) =>
								acc +
								Math.abs(item?.exposure?.display?.total || 0),
							0,
						),
					);
					setTotalImpact(
						transactionCurrencies.reduce(
							(acc, item) =>
								acc +
								Math.abs(
									(item?.deposit?.display?.total || 0) -
										(item?.withdraw?.display?.total || 0),
								),
							0,
						),
					);
					setTotalDeposit(
						transactionCurrencies.reduce(
							(acc, item) =>
								acc +
								Math.abs(
									item?.deposit?.exposure?.display?.total ||
										0,
								),
							0,
						),
					);
					setTotalWithdraw(
						transactionCurrencies.reduce(
							(acc, item) =>
								acc +
								Math.abs(
									item?.withdraw?.exposure?.display?.total ||
										0,
								),
							0,
						),
					);
				});
		}, [apiService, data, isTabActive, start]);

		// How to enable empty state: Daniel Lauding, uncomment the next line
		// currencies.length = 0;

		if (isLoading) {
			return (
				<div
					className={classNames('asteria-loader-wrapper', className)}
				>
					<div
						className={classNames(
							'asteria-overview',
							`asteria-overview-${type}`,
						)}
					>
						<LinearIndicator />
					</div>
				</div>
			);
		}

		return (
			<div className={classNames('asteria-currency-overview', className)}>
				<div
					className={classNames(
						'asteria-overview',
						`asteria-overview-${type}`,
					)}
				>
					<div className="asteria-list-wrapper">
						{currencies.length === 0 && (
							<Text className="asteria-list-empty">
								{TranslationService.get(
									['drawer.currencies.empty'],
									'Du handlar inte i några andra valutor',
									{
										start,
									},
								)}
							</Text>
						)}
						{(deposit !== 0 ||
							withdraw !== 0 ||
							total !== 0 ||
							risk !== 0) && (
							<div className="asteria-list-item-meta-list">
								<FeatureFlag feature="currency-overview-header">
									<div className="asteria-exposure-header">
										{/* <Title className="asteria-title-exposure asteria-text-withdraw">
											<span>
												{TranslationService.get(
													'currencies.deposit.exposure',
													'Inbetalningar exponering ',
												)}
											</span>
											{formatNumber(
												withdraw || 0,
												true,
												false,
												true,
											)}
										</Title>
										<Title className="asteria-title-exposure asteria-title-deposit">
											<span>
												{TranslationService.get(
													'currencies.withdraw.exposure',
													'Utbetalningar exponering ',
												)}
											</span>
											{formatNumber(
												deposit || 0,
												false,
												false,
												true,
											)}
										</Title> */}
										<Title
											className={classNames(
												'asteria-title-exposure asteria-title-total-exposure',
												{
													'asteria-state-negative':
														(total || 0) !== 0,
													'asteria-state-zero':
														(total || 0) === 0,
												},
											)}
										>
											<span>
												{TranslationService.get(
													'currencies.total.exposure',
													'Exponering ',
												)}
											</span>
											{formatNumber(
												total || 0,
												false,
												false,
												true,
											)}
										</Title>
										<Title className="asteria-title-risk">
											<span>
												{TranslationService.get(
													'currencies.total.risk',
													'Valutarisk ',
												)}
											</span>
											{`±${formatNumber(
												risk || 0,
												false,
												false,
												true,
											)}`}
										</Title>
									</div>
								</FeatureFlag>
							</div>
						)}
						{/* 						<Title className="asteria-title-currency">
							<span>
								{TranslationService.get(
									'currencies.list.overview.title',
									'Valutor',
								)}
							</span>
						</Title> */}
						<InfoBoxToggle
							open={isHelpOpen}
							toggle={() => setHelpOpen(!isHelpOpen)}
						/>
						<InfoBox open={isHelpOpen}>
							<Translation
								Component={Text}
								translationKey="drawer.currencies.help"
							/>
							<RequestHelp
								type="currencyrisk"
								onClick={() => setShow(false)}
							/>
						</InfoBox>
						<div className="asteria-currency-filter">
							<div className="asteria-currency-cell asteria-currency-cell-currency">
								<Text className="asteria-text">
									{TranslationService.get(
										'currencies.currency',
										'Valuta',
									)}
								</Text>
							</div>
							{/* <div className="asteria-currency-cell asteria-currency-cell-exposure-deposit">
								<Text className="asteria-text">
									{TranslationService.get(
										'currencies.deposit.exposure',
										'Exponerad, inbetalningar',
									)}
								</Text>
							</div>
							<div className="asteria-currency-cell asteria-currency-cell-exposure-withdraw">
								<Text className="asteria-text">
									{TranslationService.get(
										'currencies.withdraw.exposure',
										'Exponerad, utbetalningar',
									)}
								</Text>
							</div> */}
							<div className="asteria-currency-cell asteria-currency-cell-exposure">
								<Text className="asteria-text">
									{TranslationService.get(
										'currencies.exposure',
										'Exponering',
									)}
								</Text>
							</div>
							<div className="asteria-currency-cell asteria-currency-cell-currency-risk">
								<Text className="asteria-text">
									{TranslationService.get(
										'currencies.total.risk',
										'Valutarisk',
									)}
								</Text>
							</div>
							<div className="asteria-currency-cell asteria-currency-cell-currency-balance">
								<Text className="asteria-text">
									{TranslationService.get(
										'currencies.currency.balance',
										'Valutanetto',
									)}
								</Text>
							</div>
							<div className="asteria-currency-cell asteria-currency-cell-currency-total">
								<Text className="asteria-text">
									{TranslationService.get(
										'currencies.account',
										'Konto',
									)}
								</Text>
							</div>
						</div>
						<ul className="asteria-list">
							{currencies.map((currency, index) => (
								<CurrencyView
									key={currency?.currency}
									currency={currency}
									total={total}
									totalImpact={impact}
									index={index}
									setShow={setShow}
								/>
							))}
						</ul>
						{hasRiskAlert && (
							<RequestHelp
								type="currencyrisk"
								onClick={() => setShow(false)}
							/>
						)}
					</div>
				</div>
			</div>
		);
	},
)`
	display: flex;
	justify-content: space-around;
	padding: 0px 40px 32px;
	flex-direction: column;
	// background: rgb(251,242,234);

	.asteria-overview {
	}

	.asteria-loading {
		margin: 32px auto 16px;
	}
	padding: 0 12px 16px;

	.asteria-title-currency {
		display: inline-block;
		margin-top: 20px !important;
	}

	.asteria-button-info {
		margin-bottom: 16px !important;
		margin-left: auto !important;
		// margin-top: 16px !important;
		// margin-top: -62px !important;
		margin-top: -28px !important;

		.asteria-icon-wrapper {
			max-width: 20px !important;
			max-height: 20px !important;
			margin-right: 0px !important;
			.asteria-icon-svg {
				fill: #ee7024 !important;
			}
		}
	}

	.asteria-state-risk {
		.asteria-icon-wrapper {
			max-width: 20px !important;
			max-height: 20px !important;
			margin-right: 8px !important;
			.asteria-icon-svg {
				fill: #C01F26 !important;
			}
		}   
	}

	.asteria-currency-filter {
		display: flex;
		padding: 1px 12px 0px 12px;
		border-bottom: 2px solid var(--filter-header-border-color);
		background-color: var(--filter-header-bg-color);
		height: 37px;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		margin-top: 42px;
		display: none;

		.asteria-currency-cell-currency-total {
			padding-right: 40px;
		}
		
		.asteria-currency-cell {
			overflow: hidden;
			white-space: nowrap;
			display: flex;
			.asteria-text {
				font-weight: 700;
				color: var(--filter-header-text-color);
			}
		}
	}

	.asteria-currency-cell {
		display: flex;
		flex: unset;
		margin: 0;
		&.asteria-currency-cell-expand {
			flex: 0;
		}
	}

	.asteria-currency-cell-currency {
		flex-grow: 0;
		flex-shrink: 0;
		width: 150px;
	}
	.asteria-currency-cell-exposure {
		min-width: 212px;
		flex: 1;

		.asteria-currency-cell-exposure-deposit {
			min-width: 100px;
			max-width: 105px;
		}
		.asteria-currency-cell-exposure-withdraw {
			min-width: 100px;
			max-width: 105px;
		}
	}
	.asteria-currency-cell-exposure-total {
		width: 168px;
	}
	.asteria-currency-cell-currency-risk {
		width: 100px;
	}
	.asteria-currency-cell-currency-balance {
		justify-content: flex-end;
		width: 150px;
		align-items: center;
		.asteria-graph-pie {
			margin-left: 8px;
			min-width: 32px;
		}
	}
	.asteria-currency-cell-currency-total {
		justify-content: flex-end;
		width: 150px;
	}
	.asteria-currency-cell-expand {
		margin-left: auto;
		padding-left: 16px;
	}

	.asteria-exposure-header {
		display: flex;
		flex-direction: row;
		margin-bottom: 0px;
		margin-top: 16px;
		display: none;
		.asteria-title-exposure {
			margin: 0 auto;
			font-size: 16px !important;
			justify-content: center;
			align-items: center;
			display: flex;
			color: #000000 !important;
			font-family: Swedbank Headline !important;
			font-weight: 200;
			// white-space: nowrap;
			line-height: 22px;
			overflow: visible;
			text-overflow: ellipsis;
			// max-width: 120px;
			flex-direction: column;
			span {
				flex-direction: column;
				text-align: center;
				font-size: 20px !important;
				margin-top: 0px !important;
				margin-bottom: 0px !important;
				color: #f35c1b !important;
				font-family: Swedbank Headline !important;
				font-weight: 200;
				padding-left: 0px !important;
				margin-left: 0px !important;
				// flex-grow: 1;
				display: flex;
			}
		}
		.asteria-title-risk {
			margin: 0 auto;
			font-size: 16px !important;
			justify-content: center;
			align-items: center;
			display: flex;
			color: #000000 !important;
			font-family: Swedbank Headline !important;
			font-weight: 200;
			// white-space: nowrap;
			overflow: visible;
			text-overflow: ellipsis;
			// max-width: 120px;
			line-height: 22px;
			flex-direction: column;
			span {
				flex-direction: column;
				text-align: center;
				font-size: 20px !important;
				margin-top: 0px !important;
				margin-bottom: 0px !important;
				color: #f35c1b !important;
				font-family: Swedbank Headline !important;
				font-weight: 200;
				padding-left: 0px !important;
				margin-left: 0px !important;
				// flex-grow: 1;
				display: flex;
			}
		}
	}

	.asteria-list-wrapper {
		.asteria-list {
			.asteria-currency-filter {
				display: none;
			}
			display: flex !important;
			border-bottom: 0px solid #EAE7E4;
			flex-direction: column;
			background: #ffffff;
			.asteria-list-item {
				margin: 0;
				&.asteria-state-open {
					background: #FCFAFA;
				}
				.asteria-list-item-sub {
					padding: 8px;
				}
				&:last-of-type {
					.asteria-list-item-inner {
						border-bottom: 0px solid transparent;
					}
				}
				.asteria-list-item-inner {
					.asteria-currency-compare {
						background-color: #eae7e3;
						.asteria-prefix {
							background: #999999 !important;
						}
					}
					.asteria-currency-cell, .asteria-currency-cell-exposure-total {
						.asteria-label {
							display: none;
						}
					}
					padding: 12px 12px 12px 12px;
					// border-top: 1px solid #EAE7E4;
					min-height: 50px;
					.asteria-rank {
						display: none;
					}
					.asteria-button {
						.asteria-icon-wrapper {
							margin-right: 0px !important;
							.asteria-icon-svg {
								fill: #9f8c82 !important
							}
						}
					}
					.asteria-overview {
						.asteria-text {
							border-color: #eae7e4 !important;
							&.asteria-state-active {
								// background-color: #eae7e4 !important;
								// border-color: #eae7e4 !important;
							}
							&:hover {
								// background-color: #eae7e4 !important;
								// border-color: #eae7e4 !important;
							}
						}
					}
					.asteria-prefix {
						background: #eae7e3;
						// margin-left: auto;
						// margin-left: 40px;
						height: 12px;
						height: 8px;
						border-radius: 0;
						position: relative;
					}
					.asteria-text-amount {
						font-family: Arial,Helvetica,sans-serif;
						font-size: 14px;
						color: var(--text-color);
						margin: 0;
						padding: 0;
						line-height: 1.4;
					}
				}
				.asteria-tabs-navigation {
					flex-direction: row;
					display: flex;
					justify-content: center;
					margin-bottom: 0px !important;
					padding-top: 24px !important;
					// border: 1px solid #eae7e4;
					border-bottom: 0px;
					padding: 0;
					margin: 0 !important;
					.asteria-tab-navigation-item {
						.asteria-sum-deposit {
							font-family: Arial,Helvetica,sans-serif;
							font-size: 14px;
							color: var(--text-color);
							margin: 0;
							padding: 0;
							line-height: 1.4;
							font-weight: 700;
							text-align: center;
						}
						.asteria-sum-withdraw {
							font-family: Arial,Helvetica,sans-serif;
							font-size: 14px;
							font-weight: 700;
							color: var(--text-color);
							margin: 0;
							padding: 0;
							line-height: 1.4;
							text-align: center;
						}
						&:first-of-type {
							.asteria-button-link {
								border-radius: 4px 0px 0px 4px;
							}
						}
						&:last-of-type {
							.asteria-button-link {
								border-radius: 0 4px 4px 0;   
							}
						}
					}
					.asteria-button-link {
						font-weight: 600;
						line-height: 1.2;
						font-size: 14px;
						max-height: 32px;
						color: #80584f;
						background-color: #ebe7e2;
						border: none;
						border-right: 1px solid #ffffff;
						width: 100%;
						padding: 8px 16px !important;
						transition: background-color .3s ease-out;
						text-align: center;
						cursor: pointer;
						margin-bottom: 8px;
						position: relative;
						&.asteria-state-active, &.asteria-state-hover, &:hover {
							background: #fdc129;
							color: #512b2b;
							.asteria-text {
								color: #512b2b !important;
							}
						}
						.asteria-text {
							text-decoration: none !important;
							color: var(--text-color) !important;
							font-family: "Swedbank Sans" !important;
							font-weight: 700 !important;
							font-size: 14px !important;
						}
					}
				}
				.asteria-tabs-wrapper {
					// border: 1px solid #eae7e4;
					margin-bottom: -1px;
					border-top: 0;
				}
				.asteria-list-item-details {
					padding: 16px 0px 40px 0;
					border-top: 0px solid #EAE7E4;
					.asteria-rank {
						display: block;
					}
					.asteria-category-overview {
						width: 450px;
					}
					.asteria-tag-overview-bar {
						width: 100%;
					}
					.asteria-button {
						.asteria-icon-wrapper {
							.asteria-icon-svg {
								fill: #9f8c82 !important;
							}
						}
					}
					.asteria-accordion-item {
						.asteria-category-overview {
							width: 422px;
						}
						.asteria-tag-overview-bar {

						}
					}
				}
				.asteria-currency-cell-exposure {
					display: flex;
					flex-direction: row;
					.asteria-text {
						color: var(--transaction-list-text-color);
						font-family: Swedbank Sans;
						font-weight: 700;
						font-size: 16px;
						span {
							visibility: hidden;
						}
					}
					.asteria-text-original {
						color: #979797;
						font-family: Arial,Helvetica,sans-serif;
						font-weight: 400;
						font-size: 12px;
						margin-top: -4px;
						span {
							visibility: visible;
							margin-left: 4px;
						}
					}
				}
				.asteria-currency-cell-risk {
					display: flex;
					.asteria-text {
						color: var(--transaction-list-text-color);
						font-family: Swedbank Sans;
						font-weight: 700;
						font-size: 16px; 
					}
				}
				.asteria-currency-cell-currency-risk {
					.asteria-text {
						color: var(--transaction-list-text-color);
						font-family: Swedbank Sans;
						font-weight: 700;
						font-size: 16px;
					}
				}
				.asteria-currency-cell-currency-balance {
					.asteria-text {
						color: var(--transaction-list-text-color);
						font-family: Swedbank Sans;
						font-weight: 700;
						font-size: 16px;
					}
				}
				.asteria-currency-cell-currency-total {
					flex-direction: column;
					align-items: flex-end;
					.asteria-text {
						color: var(--transaction-list-text-color);
						font-family: Swedbank Sans;
						font-weight: 700;
						font-size: 16px;
						span {
							visibility: hidden;
							display: none;
						}
					}
					.asteria-text-original {
						color: #979797;
						font-family: Arial,Helvetica,sans-serif;
						font-weight: 400;
						font-size: 12px;
						margin-top: -4px;
						span {
							visibility: visible;
							display: inline-flex;
							margin-left: 4px;
						}
					}
				}
				.asteria-text-exposure {
					display: column;
					flex-direction: row;
					margin-right: 8px;
					margin-bottom: 0;
					font-size: var(--text-small-font-size);
					flex: 1;
					// max-width: 70px;
					// color: #437A88 !important;
					// color: var(--text-color) !important;
					// &.asteria-state-zero {
						// color: #83645D !important;
						// color: var(--text-color) !important;
					// }
					// &.asteria-text-withdraw, &.asteria-state-negative {
						// color: #B8332E !important;
						// color: var(--text-color) !important;
					// }
					// &.asteria-text-deposit {
						// color: #437A88 !important;
						// color: var(--text-color) !important;
					// }
					// span {
						// display: flex;
						// align-items: center;
						// margin-right: 16px;
						// color: #83645D !important;
					// }
				}
				// .asteria-text-risk {
				//     display: column;
				//     flex-direction: row;
				//     margin-right: 8px;
				//     margin-bottom: 0;
				//     font-size: var(--text-small-font-size);
				//     flex: 1;
					// max-width: 70px;
					// color: #000000 !important;
					// color: var(--text-color) !important;
					span {
						// display: flex;
						// align-items: center;
						// margin-right: 16px;
						// color: #83645D !important;
					}
				}
				.currency-flag {
					margin-right: 8px;
					// border-radius: 500px;
					margin-right: 8px;
					max-width: 16px;
					max-height: 16px;
					max-width: 16px;
					max-height: 12px;
					overflow: hidden;
					min-height: 12px;
					min-width: 16px;
					background-position: 50% 50%;
				}
				.asteria-tag-type-tag {
					padding: 4px 0px;
				}
				.asteria-button {
				}
			}
		}
	}

	.asteria-box {
		border: 1px solid #f2f0ed;
		padding: 16px;
		display: flex;
		align-content: center;
		justify-content: center;
		flex-direction: column;
		min-height: 184px;
		max-width: 244px;
		margin-right: 16px;
		position: relative;
		${Text} {
			color: #696969;
			font-size: 14px;
			text-align: center;
		}
		${Button} {
			margin-top: 32px;
			.asteria-icon-wrapper {
				width: 20px;
				height: 12px;
				margin-right: 4px;
			}
			.asteria-text {
				color: #257886 !important;
			}
			&.asteria-button-cover {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				width: 100%;
				height: 100%;
			}
		}
		&:hover {
			background: #f5f5f5;
			box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
			cursor: pointer;
			${Text} {
			}
			${Button} {
			}
		}
	}
`;

CurrenciesView.displayName = 'CurrenciesView';

CurrenciesView.Styler = {
	children: [],
};

export default CurrenciesView;
export { CurrencyView };
