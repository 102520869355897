import React from 'react';
import styled from 'styled-components';
import { StyleGetter } from '@asteria/utils';
import classNames from 'classnames';
import Text from './basic/text';

const InputHelper = styled(({ className, children }) => (
	<div className={classNames(className, 'asteria-input-helper')}>
		<Text>{children}</Text>
	</div>
))``;

InputHelper.Styler = {
	children: [
		{
			component: Text,
			base: [StyleGetter(`text`)],
		},
	],
};

export default InputHelper;
