import React, { useContext } from 'react';
import styled from 'styled-components';
import { startOfMonth, startOfISOWeek, startOfYear, isAfter } from 'date-fns';
import classNames from 'classnames';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { TranslationService } from '@asteria/services-language';
import { useStore } from '@asteria/utils';
import Button from '@asteria/component-core/button';
import { addTransaction } from '@asteria/services-datalayer/services/adjustable/actions';
import { FeatureFlag, useFeature } from '@asteria/component-featureflag';
import { setSelectedRow } from '@asteria/services-datalayer/services/list/actions';
import {
	setListOpen,
	setSelectedDate,
	setSelectedType,
	// setAdjustOpen,
} from '@asteria/services-datalayer/services/appstate/actions';

const startOfTime = (date, size) => {
	if (size === 'week') {
		return startOfISOWeek(date);
	}
	if (size === 'month') {
		return startOfMonth(date);
	}
	if (size === 'year') {
		return startOfYear(date);
	}

	return startOfMonth(date);
};

const CATEGORIES = {
	$customer: '$invoices',
	$supplier: '$invoices',
	$salary: '$salaries',
	$tax: '$taxes',
};

const TAGTYPE = {
	$customer: 'DEPOSIT',
	$supplier: 'WITHDRAW',
	$salary: 'WITHDRAW',
	$tax: 'WITHDRAW',
};

const AddAdjustableTransaction = styled(({ className, onClick = () => {} }) => {
	const { dispatch } = useContext(DatalayerContext);
	const hasSidebarAdding = useFeature({
		key: 'sidepane-transaction-adjustable-add',
	});

	const [isListOpen] = useStore(
		'store-appstate',
		({ listOpen = false }) => listOpen,
	);

	const [filters] = useStore('store-appstate', ({ filters: list }) => list);

	const [listStartDate = startOfTime(new Date(), 'month')] = useStore(
		'store-list',
		({ startDate }) => startDate,
	);

	const [{ editing = [], adding = [] } = {}] = useStore(
		'store-adjustable',
		({ transactions }) => transactions,
	);

	return (
		<>
			<FeatureFlag
				feature="adjustable-adjustable-add-event-icon-only"
				invert
			>
				<Button
					type="link"
					icon="circlePlusFilled"
					size="large"
					text={TranslationService.get(
						'list.adjustable.addtransaction',
					)}
					title={TranslationService.get(
						'list.adjustable.addtransaction',
					)}
					className={classNames(className, `asteria-adjustable`)}
					onClick={() => {
						dispatch(setSelectedRow([]));
						dispatch(setListOpen(true));
						onClick();

						if (!isListOpen) {
							dispatch(setSelectedType(['DEPOSIT', 'WITHDRAW']));
							dispatch(setSelectedDate(listStartDate));
						}

						if (
							(editing.length === 0 && adding.length === 0) ||
							!hasSidebarAdding
						) {
							const tag = filters.find(
								({ type }) => type === 'tag',
							);

							dispatch(
								addTransaction({
									type:
										tag && TAGTYPE[(tag?.config?.name)]
											? TAGTYPE[(tag?.config?.name)]
											: TAGTYPE.$customer,
									status: 'FORECAST',
									manual: true,
									paymentDate: isAfter(
										new Date(),
										listStartDate,
									)
										? new Date()
										: listStartDate,
									oracle: {
										risk: 1,
									},
									sums: { original: { total: 0 } },
									meta: {
										tags:
											tag &&
											CATEGORIES[(tag?.config?.name)]
												? [
														{
															name:
																tag?.config
																	?.name,
															category:
																CATEGORIES[
																	(tag?.config
																		?.name)
																],
														},
												  ]
												: [
														{
															name: '$customer',
															category:
																CATEGORIES.$customer,
														},
												  ],
									},
								}),
							);
						}
					}}
				/>
			</FeatureFlag>
			<FeatureFlag feature="adjustable-adjustable-add-event-icon-only">
				<Button
					type="link"
					icon="circlePlusFilled"
					size="large"
					title={TranslationService.get(
						'list.adjustable.addtransaction',
					)}
					className={classNames(className, `asteria-adjustable`)}
					onClick={() => {
						dispatch(setSelectedRow([]));
						onClick();

						if (!isListOpen) {
							dispatch(setListOpen(true));
							dispatch(setSelectedType(['DEPOSIT', 'WITHDRAW']));
							dispatch(setSelectedDate(listStartDate));
						}

						if (editing.length === 0 && adding.length === 0) {
							const tag = filters.find(
								({ type }) => type === 'tag',
							);

							dispatch(
								addTransaction({
									type:
										tag && TAGTYPE[(tag?.config?.name)]
											? TAGTYPE[(tag?.config?.name)]
											: TAGTYPE.$customer,
									status: 'FORECAST',
									manual: true,
									paymentDate: isAfter(
										new Date(),
										listStartDate,
									)
										? new Date()
										: listStartDate,
									oracle: {
										risk: 1,
									},
									sums: { original: { total: 0 } },
									meta: {
										tags:
											tag &&
											CATEGORIES[(tag?.config?.name)]
												? [
														{
															name:
																tag?.config
																	?.name,
															category:
																CATEGORIES[
																	(tag?.config
																		?.name)
																],
														},
												  ]
												: [
														{
															name: '$customer',
															category:
																CATEGORIES.$customer,
														},
												  ],
									},
								}),
							);
						}
					}}
				/>
			</FeatureFlag>
		</>
	);
})``;

AddAdjustableTransaction.Styler = Button.Styler;
export default AddAdjustableTransaction;
