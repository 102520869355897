import styled from 'styled-components';

const Background = styled.div`
	height: 100%;
	width: 100%;

	-ms-grid-column: 1;
	-ms-grid-column-span: 10;
	-ms-grid-row: 1;
	-ms-grid-row-span: 10;

	grid-column-start: 1;
	grid-column-end: -1;
	grid-row-start: 1;
	grid-row-end: -1;
	z-index: 1;
	position: relative;
`;

Background.displayName = 'Background';

export default Background;
