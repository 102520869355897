/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const FETCH_ACCOUNTS = gql`
	query BankAccounts {
		bankAccounts(pageFilters: { first: 0 }) {
			edges {
				node {
					active
					name
					sums {
						original {
							total
							currency
						}

						display {
							total
							currency
							credit
						}
					}
					identifiers {
						number
					}
				}
			}
		}
	}
`;

export const UPDATE_BANK_ACCOUNT = gql`
	mutation updateBankAccount($input: BankAccountUpdateInput!) {
		updateBankAccount(input: $input) {
			ok
			error
		}
	}
`;

export const UPDATE_BANK_ACCOUNTS = gql`
	mutation updateBankAccounts($input: [BankAccountUpdateInput!]!) {
		updateBankAccounts(input: $input) {
			ok
			error
		}
	}
`;
