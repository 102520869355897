import React, { useState, useCallback, useContext, useEffect } from 'react';
import { TextV2, Title } from '@asteria/component-typography';
import { ButtonV2 } from '@asteria/component-button';
import { FormGroup, InputV2, LabelV2 } from '@asteria/component-form';
import Alert from '@asteria/component-alert';
import { Translation, TranslationService } from '@asteria/services-language';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { initiateIntegration } from '@asteria/services-datalayer/services/integrations/actions';
import { useStore } from '@asteria/utils';
import Layout from '../../layout';
import Context from '../../context';
import HelpStepOne from './helpStepOne';

const removeError = (error, errors) =>
	errors.filter(({ key }) => error.key !== key);

const Key = ({ navigator, callback = () => {} }) => {
	const { setActiveModal, setStepIndex } = useContext(Context);
	const [integrationConfig, setIntegrationConfig] = useState({
		'API-User': '',
		'API-Key': '',
	});

	useEffect(() => {
		setStepIndex(2);
		callback('goto', {
			event: 'goto',
			integration: 'bjornlunden',
			integrationTitle: 'Björn Lundén',
			step: {
				stepId: 'bjornlundenActivation',
				title: 'Aktivera',
			},
			steps: [
				{ stepId: 'bjornlundenActivation', title: 'Aktivera' },
				{
					stepId: 'bjornlundenConnecting',
					title: 'Väntar på anslutning',
				},
			],
		});
	}, [callback, setStepIndex]);

	const { dispatch } = useContext(DatalayerContext);
	const [erps = []] = useStore(
		'store-integrations',
		({ integrations = [] }) => integrations,
	);
	const [integrationId] = useStore(
		'store-integrations',
		({ integrationInProgress }) => integrationInProgress,
	);

	const [inputErrors, setInputErrors] = useState({});
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		if (integrationId) {
			const integration = erps.find(int => int.id === integrationId);

			if (integration) {
				setErrors([...errors, ...integration.config.errors]);
				dispatch(() => ({
					action: 'CONNECTION_CLEANUP',
				}));
			}
		}
	}, [dispatch, erps, errors, integrationId]);

	const nextStep = useCallback(() => {
		if (!integrationConfig?.['API-User']?.length) {
			setInputErrors({ 'API-User': true });
		} else if (!integrationConfig?.['API-Key']?.length) {
			setInputErrors({ 'API-Key': true });
		} else {
			dispatch(
				initiateIntegration({
					integrationName: 'bjornlunden',
					config: {
						client: {
							'API-User': integrationConfig['API-User'],
							'API-Key': integrationConfig['API-Key'],
						},
					},
				}),
			);
			navigator.next(null, navigator);
		}
	}, [dispatch, integrationConfig, navigator]);

	return (
		<Layout
			type="bjornlunden-key"
			header={
				<Title
					className="asteria-title-onboarding-header"
					size="title2"
				>
					{TranslationService.get(
						'onboarding.connect.header',
						'2. Anslut till bokföringssystem',
					)}
				</Title>
			}
			body={
				<>
					{inputErrors?.['API-User'] ? (
						<Alert
							icon
							description={false}
							type="error"
							headingContent={
								<Translation
									translationKey="onboarding.bjornlunden.api_form.missing_user"
									defaultText="Var vänlig ange ditt Användarnamn"
								/>
							}
							onClose={() =>
								setErrors(
									removeError(
										{ key: 'validationError' },
										errors,
									),
								)
							}
						/>
					) : null}
					{inputErrors?.['API-Key'] ? (
						<Alert
							icon
							description={false}
							type="error"
							headingContent={
								<Translation
									translationKey="onboarding.bjornlunden.api_form.missing_key"
									defaultText="Var vänlig ange din <b>API-Nyckel</b> innan du fortsätter"
								/>
							}
							onClose={() =>
								setErrors(
									removeError(
										{ key: 'validationError' },
										errors,
									),
								)
							}
						/>
					) : null}
					{errors && errors.length > 0 ? (
						<Alert
							icon
							description={false}
							type="error"
							headingContent={
								<Translation
									translationKey="onboarding.bjornlunden.connection.error"
									defaultText="Björn Lundén lyckades ej ansluta"
								/>
							}
							onClose={() =>
								setErrors(
									removeError(
										{ key: 'networkError' },
										errors,
									),
								)
							}
						/>
					) : null}
					<div
						className="asteria-dialog-box"
						style={{
							padding: '20px 20px 20px',
							backgroundColor: '#fbf2ea',
						}}
					>
						<Title size="title4">Björn Lundén</Title>
						<TextV2 size="small">
							{TranslationService.get(
								'onboarding.bjornlunden.api_form.title',
								'För att aktivera kopplingen behöver du skapa en App samt hämta Användarnamn och API-Nyckel från ditt Björn Lundén bokföringssystem och fylla i de här.',
							)}
						</TextV2>

						<FormGroup>
							<LabelV2 path="form.label">
								{TranslationService.get(
									'onboarding.bjornlunden.api_form.user.label',
									'Användarnamn',
								)}
							</LabelV2>
							<InputV2
								placeholder={TranslationService.get(
									'onboarding.bjornlunden.api_form.user.placeholder',
									'',
								)}
								onChange={e => {
									if (inputErrors?.['API-User']) {
										// hide alert after user started typing again
										setInputErrors({ 'API-User': false });
									}

									setIntegrationConfig({
										...integrationConfig,
										'API-User': e.target.value,
									});
								}}
								value={integrationConfig?.['API-User'] || ''}
								invertedcolor
							/>
						</FormGroup>
						<FormGroup>
							<LabelV2 path="form.label">
								{TranslationService.get(
									'onboarding.bjornlunden.api_form.key.label',
									'API-nyckel',
								)}
							</LabelV2>
							<InputV2
								placeholder={TranslationService.get(
									'onboarding.bjornlunden.api_form.key.placeholder',
									'',
								)}
								onChange={e => {
									if (inputErrors?.['API-Key']) {
										// hide alert after user started typing again
										setInputErrors({ 'API-Key': false });
									}

									setIntegrationConfig({
										...integrationConfig,
										'API-Key': e.target.value,
									});
								}}
								value={integrationConfig?.['API-Key'] || ''}
								invertedcolor
							/>
						</FormGroup>
					</div>
					<ButtonV2
						text={TranslationService.get(
							'onboarding.bjornlunden.api_form.help',
							'Var hittar jag min API-nyckel',
						)}
						type="flat"
						icon="triangleRight"
						iconPosition="after"
						onClick={() =>
							setActiveModal(HelpStepOne.navigationKey)
						}
					/>
					<div
						className="asteria-wizard-inner-footer"
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							paddingTop: '20px',
						}}
					>
						<ButtonV2
							size="medium"
							type="default"
							onClick={() =>
								navigator.cancel(
									{
										event: 'cancel',
									},
									navigator,
								)
							}
							text={TranslationService.get(
								'onboarding.abort',
								'Avbryt',
							)}
						/>
						<div
							className="asteria--activation-key-controls"
							style={{
								display: 'flex',
								// minWidth: '220px',
								// justifyContent: 'space-between',
							}}
						>
							<ButtonV2
								size="medium"
								type="secondary"
								onClick={() =>
									navigator.back(
										{
											event: 'goto',
											step: {
												stepId: 'selectErp',
											},
										},
										navigator,
									)
								}
								text={TranslationService.get(
									'onboarding.back',
									'Tillbaka',
								)}
							/>
							<ButtonV2
								size="medium"
								style={{ marginLeft: '16px' }}
								type="primary"
								text={TranslationService.get(
									'onboarding.continue',
									'Fortsätt',
								)}
								onClick={() => nextStep()}
							/>
						</div>
					</div>
				</>
			}
			footer={<></>}
		/>
	);
};

Key.navigationKey = Symbol('AsteriaKey');

export default Key;
