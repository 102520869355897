import React, { useContext } from 'react';
import { Text } from '@asteria/component-typography';
import { ButtonV2 } from '@asteria/component-button';
import Modal, { ModalBody, ModalFooter } from '@asteria/component-modal';
import { GLOBAL } from '@asteria/env';
import { Translation, TranslationService } from '@asteria/services-language';
import Context from '../../context';
import helpKeys from './helpKeys';

const HelpStepThree = () => {
	const { setActiveModal } = useContext(Context);

	return (
		<Modal
			backdrop
			open
			onClose={() => setActiveModal('')}
			size="small"
			className="asteria-onboarding-guide-modal"
			detach
			title={TranslationService.get(
				'onboarding.handelsbanken.help.third.header',
				'Var hittar jag min API-nyckel',
			)}
		>
			<ModalBody className="modal-body">
				<div
					style={{
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
					}}
				>
					<Translation
						translationKey="onboarding.handelsbanken.help.third.text"
						defaultText=""
						Component={Text}
					/>
					<div>
						<img
							style={{
								// width: '100%',
								width: '56%',
								display: 'flex',
								margin: '16px auto',
								// maxHeight: '350px',
								// paddingRight: '40px',
							}}
							src={`${
								GLOBAL.baseUrl
							}assets/asteria/images/integrations/handelsbanken/gif/03.gif`}
							alt=""
						/>
					</div>
				</div>
			</ModalBody>
			<ModalFooter className="modal-footer">
				<div
					className="asteria-onboarding-help-footer"
					style={{
						display: 'flex',
						width: '100%',
						justifyContent: 'space-between',
					}}
				>
					<ButtonV2
						size="medium"
						type="default"
						text={TranslationService.get(
							'onboarding.back',
							'Tillbaka',
						)}
						onClick={() => setActiveModal(helpKeys.HelpStepTwo)}
					/>
					<ButtonV2
						size="medium"
						type="secondary"
						text={TranslationService.get(
							'onboarding.close',
							'Stäng',
						)}
						onClick={() => setActiveModal('')}
					/>
				</div>
			</ModalFooter>
		</Modal>
	);
};

HelpStepThree.navigationKey = helpKeys.HelpStepThree;

export default HelpStepThree;
