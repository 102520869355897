import React, {
	useState,
	useCallback,
	useContext,
	useEffect,
	useRef,
} from 'react';
import { TextV2, Title } from '@asteria/component-typography';
import { ButtonV2 } from '@asteria/component-button';
import { TranslationService } from '@asteria/services-language';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import {
	initiateIntegration,
	removeIntegration,
} from '@asteria/services-datalayer/services/integrations/actions';
import { useStore } from '@asteria/utils';
import { FormGroup, LabelV2, InputV2 } from '@asteria/component-form';
import Layout from '../../layout';
import Context from '../../context';

const Key = ({ navigator, callback = () => {} }) => {
	const { setStepIndex } = useContext(Context);
	const inputRef = useRef(null);
	const hasPressedNext = useRef(false);
	const [erp, setErp] = useState(null);

	useEffect(() => {
		setStepIndex(2);
		callback('goto', {
			event: 'goto',
			integration: 'custom',
			integrationTitle: 'custom',
			step: {
				stepId: 'customActivation',
				title: 'Aktivera',
			},
			steps: [
				{ stepId: 'customActivation', title: 'Aktivera' },
				{ stepId: 'customConnecting', title: 'Väntar på anslutning' },
			],
		});
	}, [callback, setStepIndex]);

	const { dispatch } = useContext(DatalayerContext);
	const [erps = []] = useStore(
		'store-integrations',
		({ integrations = [] }) => integrations,
	);
	const [integrationId] = useStore(
		'store-integrations',
		({ integrationInProgress }) => integrationInProgress,
	);

	useEffect(() => {
		if (!integrationId) {
			dispatch(
				initiateIntegration({
					integrationName: 'custom',
					type: 'erp',
					config: {},
				}),
			);
		} else {
			return () => {
				if (hasPressedNext.current === false && integrationId) {
					dispatch(() => ({
						action: 'CONNECTION_CLEANUP',
					}));
					dispatch(removeIntegration({ id: integrationId }));
				}
			};
		}

		return () => {};
	}, [dispatch, integrationId]);

	useEffect(() => {
		if (!erp) {
			setErp(erps.find(int => int.id === integrationId));
		}
	}, [dispatch, erp, erps, integrationId]);

	const nextStep = useCallback(() => {
		hasPressedNext.current = true;
		navigator.next(null, navigator);
	}, [navigator]);

	const prevStep = useCallback(() => {
		navigator.back(
			{
				event: 'goto',
				step: {
					stepId: 'selectErp',
				},
			},
			navigator,
		);
	}, [navigator]);

	const cancelStep = useCallback(() => {
		navigator.cancel(
			{
				event: 'cancel',
			},
			navigator,
		);
	}, [navigator]);

	const copyText = useCallback(() => {
		if (!inputRef.current) {
			return;
		}

		inputRef.current.select();

		try {
			document.execCommand('copy');
		} catch (err) {
			//
		}
	}, []);

	const { config: { server: { integrationAccessToken = '' } = {} } = {} } =
		erp || {};

	return (
		<Layout
			type="custom-key"
			header={
				<Title
					className="asteria-title-onboarding-header"
					size="title2"
				>
					{TranslationService.get(
						'onboarding.connect.header',
						'2. Anslut till bokföringssystem',
					)}
				</Title>
			}
			body={
				<>
					<div
						className="asteria-dialog-box"
						style={{
							padding: '20px 20px 20px',
							backgroundColor: '#fbf2ea',
						}}
					>
						<Title size="title4">Visma Administration</Title>
						<TextV2 size="small" style={{ marginBottom: '16px' }}>
							{TranslationService.get(
								'onboarding.vismaAdministration.api_form.text1',
							)}
						</TextV2>
						<TextV2 size="small">
							{TranslationService.get(
								'onboarding.vismaAdministration.api_form.text2',
							)}
						</TextV2>
						<FormGroup>
							<LabelV2 path="form.label">
								{TranslationService.get(
									'onboarding.vismaAdministration.api_form.label',
									'API-nyckel',
								)}
							</LabelV2>
							<InputV2
								value={integrationAccessToken}
								invertedcolor
								className="asteria-token-copy"
								ref={inputRef}
								readOnly
								after={
									<ButtonV2
										type="link"
										icon="clipboard"
										style={{ backgroundColor: '#d4c4bc' }}
										onClick={() => copyText()}
										title={TranslationService.get(
											'onboarding.vismaAdministration.copyclipboard.label',
											'Kopiera',
										)}
									/>
								}
							/>
						</FormGroup>
					</div>
					<ButtonV2
						text={TranslationService.get(
							'onboarding.vismaAdministration.api_form.help',
							'Hur ansluter jag Visma Administration',
						)}
						type="flat"
						icon="triangleRight"
						iconPosition="after"
						onClick={() => {
							const win = window.open('', '_blank');
							win.location =
								'https://www.asteria.ai/visma-administration';
							win.focus();
						}}
					/>
					<div
						className="asteria-wizard-inner-footer"
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							paddingTop: '20px',
						}}
					>
						<ButtonV2
							size="medium"
							type="default"
							onClick={() => cancelStep()}
							text={TranslationService.get(
								'onboarding.abort',
								'Avbryt',
							)}
						/>
						<div
							className="asteria--activation-key-controls"
							style={{
								display: 'flex',
								// minWidth: '220px',
								// justifyContent: 'space-between',
							}}
						>
							<ButtonV2
								size="medium"
								type="secondary"
								onClick={() => prevStep()}
								text={TranslationService.get(
									'onboarding.back',
									'Tillbaka',
								)}
							/>
							<ButtonV2
								size="medium"
								type="primary"
								style={{ marginLeft: '16px' }}
								text={TranslationService.get(
									'onboarding.continue',
									'Fortsätt',
								)}
								onClick={() => nextStep()}
							/>
						</div>
					</div>
				</>
			}
			footer={<></>}
		/>
	);
};

Key.navigationKey = Symbol('VismaAdministrationKey');

export default Key;
