import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Wrapper } from '@asteria/component-core';
import Button from '@asteria/component-button';

const Buttons = styled(({ types, sizes }) => (
	<div>
		<div>
			{sizes.map(size => (
				<>
					<div style={{ display: 'flex' }}>
						{types.map(type => (
							<Button type={type} size={size}>
								{`${type} - ${size}`}
							</Button>
						))}
					</div>
					<div style={{ display: 'flex' }}>
						{types.map(type => (
							<Button type={type} size={size} icon="help">
								{`${type} - ${size}`}
							</Button>
						))}
					</div>
				</>
			))}
		</div>
	</div>
))``;

const ComponentsPage = styled(({ className }) => (
	<div className={classNames(className)}>
		<Wrapper>
			<div style={{ backgroundColor: 'blue' }}>Wrapped Content</div>
		</Wrapper>

		<Wrapper horizontalScroll>
			<div style={{ backgroundColor: 'blue', width: '1000px' }}>
				Wrapped Content With Scroll
			</div>
			<div style={{ backgroundColor: 'blue', width: '1000px' }}>
				Wrapped Content With Scroll
			</div>
		</Wrapper>

		<Buttons
			types={['primary', 'secondary', 'default', 'link']}
			sizes={['large', 'medium', 'small']}
		/>
	</div>
))``;

ComponentsPage.displayName = 'ComponentsPage';
ComponentsPage.Styler = {
	children: [
		{
			component: Wrapper,
		},
	],
};
export default ComponentsPage;
