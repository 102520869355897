import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
// import { Getter } from '@asteria/utils';
import { TranslationService } from '@asteria/services-language';
import { Text, Title } from '@asteria/component-typography';
import Icon, { SVG } from '@asteria/component-icon';

const Prefix = styled.div`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #ebe7e2;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	margin-right: 16px;

	${Text} {
		color: #512b2b;
		font-size: 16px;
		font-family: 'Swedbank Sans';
		font-weight: 700;
	}
`;

Prefix.displayName = 'Prefix';

Prefix.Styler = {
	children: [],
};

const Divider = styled.div`
	width: 2px;
	height: 32px;
	background-color: #d3c3bc;
	margin-left: 20px;
`;

Divider.displayName = 'Divider';

Divider.Styler = {
	children: [],
};

const Step = styled(({ className, active, done, index, title }) => (
	<div
		className={classNames(className, {
			'asteria-state-active': active,
			'asteria-step-done': done,
		})}
	>
		<Prefix>
			<Icon asset="check" />
			<Text>{index}</Text>
		</Prefix>
		<Title size="title3">{title}</Title>
	</div>
))`
	display: flex;
	align-items: center;

	${Prefix} {
		${Icon} {
			display: none;
			${SVG} {
				fill: #fff;
			}
		}
	}

	${Title} {
		font-family: 'Swedbank Sans';
		font-weight: 700;
		font-size: 16px;
		color: #72605e;
	}

	&.asteria-state-active {
		${Title} {
			color: #512b2b;
		}
		${Prefix} {
			background-color: #fdc129;
		}
	}

	&.asteria-step-done {
		${Prefix} {
			background-color: #99cc66;
			${Icon} {
				display: block;
				margin: 10px;
			}

			${Text} {
				display: none;
			}
		}

		${Title} {
			text-decoration: underline;
			color: #72605e;
		}
	}
`;

Step.displayName = 'Step';

Step.Styler = {
	children: [],
};

const Navigation = styled(({ className, step = 1 }) => (
	<div className={classNames(className, 'asteria-onboarding-steps')}>
		<div>
			<Step
				index={1}
				title={TranslationService.get('onboarding.navigation.erp')}
				done={step > 1}
				active={step === 1}
			/>
			<Divider />
			<Step
				index={2}
				title={TranslationService.get(
					'onboarding.navigation.erp_connecting',
				)}
				done={step > 2}
				active={step === 2}
			/>
			<Divider />
			<Step
				index={3}
				title={TranslationService.get('onboarding.navigation.success')}
				done={step > 3}
				active={step === 3}
			/>
		</div>
	</div>
))`
	display: flex;
	flex-direction: column;
	flex-basis: 50%;
	flex-grow: 0;
`;

Navigation.displayName = 'Navigation';

Navigation.Styler = {
	children: [],
};
export default Navigation;
