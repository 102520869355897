import React, { useRef, useContext } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { formatNumber, StyleGetter, useComponentSize } from '@asteria/utils';
import { FeatureFlag } from '@asteria/component-featureflag';
import Text from '@asteria/component-typography/text';
import { TranslationService } from '@asteria/services-language';
import GraphContext from './context';

const Indicator = styled.span`
	display: block;
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 8px solid rgb(244, 194, 78);
	position: absolute;
	bottom: 0px;
	display: none;
`;
Indicator.displayName = 'Indicator';

const XAxisLabel = styled(({ rangeId, className, updateSize, children }) => {
	const ref = useRef(null);
	const { width } = useComponentSize(ref);
	const { clickAction, mouseEnterAction, mouseLeaveAction } = useContext(
		GraphContext,
	);

	updateSize(width);

	return (
		<div
			ref={ref}
			className={className}
			onClick={() =>
				clickAction({ group: { id: rangeId }, source: 'account' })
			}
			onMouseEnter={() => mouseEnterAction({ group: { id: rangeId } })}
			onMouseLeave={() => mouseLeaveAction({ group: { id: rangeId } })}
			onKeyPress={() =>
				clickAction({ group: { id: rangeId }, source: 'account' })
			}
			role="button"
			tabIndex="-1"
			title={TranslationService.get('graph.xaxis.button')}
		>
			<Indicator className="asteria-time-indicator" />
			{children}
		</div>
	);
})`
	display: flex;
	flex-direction: column;
	position: relative;
	padding-bottom: 5px;

	${Text} {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
`;
XAxisLabel.displayName = 'XAxisLabel';

const Prefix = styled.span`
	display: block;
	font-size: 12px;
	::before {
		content: '\\200b';
	}
`;
Prefix.displayName = 'Prefix';

XAxisLabel.Styler = {
	typePrefix: 'asteria-graph-xaxis',
	children: [
		{
			component: Text,
			base: [StyleGetter('label')],
		},
		{
			component: Indicator,
			base: [StyleGetter('indicator')],
		},
		{
			component: Prefix,
			base: [StyleGetter('prefix')],
		},
	],
};

const XAxis = React.memo(
	({
		labels = [],
		updateSize,
		activeGroups = [],
		activeBars = [],
		hoverGroups = [],
		hoverBars = [],
		groups = {},
	}) => (
		<>
			{labels
				.slice(1, -1)
				.map(({ id, label, types = [], prefix = false }) => (
					<XAxisLabel
						className={classNames(
							{
								'asteria-state-active': activeGroups.includes(
									id,
								),
								'asteria-graph-xaxis-no-children-active':
									activeGroups.includes(id) &&
									activeBars.length === 0,
								'asteria-state-hover': hoverGroups.includes(id),
								'asteria-graph-xaxis-no-children-hover':
									hoverGroups.includes(id) &&
									hoverBars.length === 0,
							},
							types.map(t => `asteria-graph-xaxis-${t}`),
						)}
						key={id}
						rangeId={id}
						updateSize={updateSize}
					>
						<Prefix className="asteria-time-divider">
							{prefix}
						</Prefix>
						<Text size="label">{label}</Text>
						<FeatureFlag feature="graph-account-balance-below-bar-graph">
							<Text
								size="label"
								className={classNames(
									'asteria-graph-x-axis-label-total',
									{
										'asteria-graph-x-axis-label-total-negative':
											(groups?.[id]?.lines?.[0]?.value ||
												0) < 0,
									},
								)}
							>
								{formatNumber(
									groups?.[id]?.lines?.[0]?.value || 0,
								)}
							</Text>
						</FeatureFlag>
					</XAxisLabel>
				))}
		</>
	),
);
XAxis.displayName = 'XAxis';
export { XAxisLabel };
export default XAxis;
