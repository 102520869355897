import { useRef, useEffect, useCallback } from 'react';

function createWatcher() {
	const watcher = {
		observer: null,
		watching: [],
		visible: new Set(),
		callback: () => {},
	};

	watcher.observer = new IntersectionObserver(
		entry => {
			for (let i = 0; i < entry.length; i += 1) {
				if (entry[i].isIntersecting) {
					watcher.visible.add(entry[i].target);
				} else {
					watcher.visible.delete(entry[i].target);
				}
			}

			if (watcher.callback) {
				watcher.callback(entry, [...watcher.visible]);
			}
		},
		{
			threshold: 1.0,
		},
	);

	return watcher;
}

function useIntersect() {
	// const [visible, setVisible] = useState([]);
	const watcher = useRef(null);
	const callbacks = useRef(new Map());

	const update = useCallback((entry, visible) => {
		entry.forEach(e => {
			if (callbacks.current.has(e.target)) {
				callbacks.current.get(e.target)(
					e.isIntersecting,
					visible.indexOf(e.target),
				);
			}
		});
	}, []);

	useEffect(() => {
		if (!window.IntersectionObserver) {
			return () => {};
		}

		if (!watcher.current) {
			watcher.current = createWatcher();
		}

		watcher.current.callback = update;

		return () => {
			if (watcher.current) {
				watcher.current.observer.disconnect();
			}

			watcher.current.watching.length = 0;
		};
	}, [update]);

	const watch = useCallback((el, callback) => {
		if (!window.IntersectionObserver) {
			return;
		}

		if (watcher.current && watcher.current.watching.indexOf(el) === -1) {
			if (watcher.current && el.current) {
				watcher.current.observer.observe(el.current);
				watcher.current.watching = [...watcher.current.watching, el];
			}
		}

		if (el.current) {
			callbacks.current.set(el.current, callback);
		}
	}, []);

	const unWatch = useCallback(el => {
		if (!window.IntersectionObserver) {
			return;
		}

		if (watcher.current && el.current) {
			watcher.current.observer.unobserve(el.current);
		}

		watcher.current.watching = [
			...watcher.current.watching.filter(r => r !== el),
		];
		callbacks.current.delete(el.current);
	}, []);

	return { watch, unWatch };
}

export default useIntersect;
