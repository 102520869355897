import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { compileStyles, Getter } from '@asteria/utils';
import { Translation, TranslationService } from '@asteria/services-language';
import { ModalBody, ModalFooter } from '@asteria/component-modal';
import Button from '@asteria/component-button/button';
import Title, { Text } from '@asteria/component-typography';
import Alert from '@asteria/component-alert';
import { FormGroupV2, InputV2, CheckboxGroupV2 } from '@asteria/component-form';

import List from '../list';

const ScenarioListItem = styled(
	({ className, scenario, active, setActive }) => (
		<div className={classNames(className)}>
			<FormGroupV2>
				<CheckboxGroupV2
					className="asteria-scenario"
					items={[scenario]}
					onChange={(index, item, isCurrentlyActive) => {
						if (isCurrentlyActive) {
							setActive([...active.filter(a => a !== item)]);
						} else {
							setActive([...active, item]);
						}
					}}
					selected={active}
				/>
			</FormGroupV2>
		</div>
	),
)``;

const ScenarioAdd = styled(
	({ className, setPage = () => {}, save = () => {} }) => {
		const [name, setName] = useState('');
		const [validationErrors, setErrors] = useState({});
		const validateAndSave = e => {
			const errors = {};

			e.preventDefault();

			if (name.trim() === '') {
				errors.name = true;
			}

			if (Object.keys(errors).length === 0) {
				save({ name });
				setPage('list');
			} else {
				setErrors(errors);
			}
		};

		return (
			<div className={classNames(className, 'asteria-scenario-add')}>
				<form onSubmit={validateAndSave}>
					<ModalBody className="modal-body">
						<Text size="body" className="asteria-scenario-add-text">
							Lorem ipsum dolor sit amet, an meis erant nec, mea
							at essent mollis dolorem, pri te illum malis simul.
						</Text>

						<FormGroupV2>
							<InputV2
								className="asteria-scenario-add-input-name"
								placeholder={TranslationService.get(
									'scenarios.list.add.name.placeholder',
									'Scenariots namn',
								)}
								onChange={e => setName(e.target.value)}
								value={name}
								invertedcolor
							/>
							{validationErrors.name ? (
								<Alert
									icon
									type="error"
									showClose={false}
									headingContent="Vänligen, skriv ett namn för scenariot"
								/>
							) : null}
						</FormGroupV2>
					</ModalBody>
					<ModalFooter className="modal-footer">
						<Button
							size="medium"
							type="default"
							text={TranslationService.get(
								'settings.button.cancel',
								'Avbryt',
							)}
							onClick={() => setPage('list')}
						/>
						<Button
							size="medium"
							type="primary"
							buttonType="submit"
							text={TranslationService.get(
								'settings.button.add',
								'Lägg till',
							)}
						/>
					</ModalFooter>
				</form>
			</div>
		);
	},
)``;

ScenarioAdd.displayName = 'ScenarioAdd';

ScenarioAdd.Styler = {
	children: [],
};

const Scenarios = styled(({ className, onClose }) => {
	const [page, setPage] = useState('list');
	const [remindMe, setRemindMe] = useState(true);
	const [scenarios, setScenarios] = useState([
		{ value: 'asteria', label: 'Asteria' },
	]);
	const [active, setActive] = useState([]);
	const addSenario = useCallback(
		({ name }) => {
			setScenarios([...scenarios, { label: name, value: name }]);
		},
		[scenarios],
	);

	if (page === 'add') {
		return <ScenarioAdd setPage={setPage} save={addSenario} />;
	}

	return (
		<div className={className}>
			<ModalBody className="modal-body">
				<Title size="title2" className="asteria-scenarios-title">
					<Translation
						translationKey="settings.subtitle"
						defaultText="Simulera scenarion"
					/>
				</Title>
				<Text size="body" className="asteria-scenarios-text">
					Lorem ipsum dolor sit amet, an meis erant nec, mea at essent
					mollis dolorem, pri te illum malis simul.
				</Text>

				<List header="scenarios.list.title">
					{scenarios.map(item => (
						<ScenarioListItem
							scenario={item}
							key={item.id}
							active={active}
							setActive={setActive}
						/>
					))}
				</List>

				<Button
					type="link"
					className="asteria-button-add"
					onClick={() => setPage('add')}
				>
					Skapa scenario
				</Button>

				<FormGroupV2>
					<CheckboxGroupV2
						items={[{ label: 'Påminn mig om scenario hantering' }]}
						onChange={() => {
							setRemindMe(!remindMe);
						}}
						selected={remindMe ? [0] : []}
					/>
				</FormGroupV2>
			</ModalBody>
			<ModalFooter className="modal-footer">
				<Button
					size="medium"
					type="default"
					text={TranslationService.get(
						'settings.button.cancel',
						'Avbryt',
					)}
					onClick={onClose}
				/>
				<Button
					size="medium"
					type="primary"
					text={TranslationService.get(
						'settings.button.save',
						'Spara',
					)}
					onClick={onClose}
				/>
			</ModalFooter>
		</div>
	);
})`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	${({ theme }) => compileStyles(Scenarios, theme)}
`;

Scenarios.displayName = 'Scenarios';

Scenarios.Styler = {
	children: [
		{
			component: Title,
			base: [Getter('title')],
		},
		{
			component: Button,
			base: [Getter('button')],
		},
		{
			component: Button,
			base: [Getter('button')],
		},
		{
			component: CheckboxGroupV2,
			base: [Getter('checkbox')],
		},
		{
			component: InputV2,
			base: [Getter('input')],
		},
		{
			component: FormGroupV2,
			base: [Getter('group')],
		},
		{
			component: List,
			base: [Getter('list')],
		},
	],
};
export default Scenarios;
