import React from 'react';
import styled from 'styled-components';
import { Getter, preProcess } from '@asteria/utils';
import { TranslationService } from '@asteria/services-language';
import classNames from 'classnames';
import { Text } from '@asteria/component-core/typography';
import { Wrapper } from '@asteria/component-core';

const processColor = (color = '', prefix = 'color', status = 'PAID', theme) => {
	if (status === 'UNPAID') {
		return preProcess(`var(--system-unpaid-${prefix})`, theme);
	}

	if (status === 'OVERDUE') {
		return preProcess(`var(--system-overdue-${prefix})`, theme);
	}

	if (color.startsWith('$')) {
		/*
		return preProcess(
			`var(--${color.replace('$', 'system-')}-${prefix})`,
			theme,
		);
		*/
		return preProcess(
			`var(--${color.replace(
				'$',
				'system-',
			)}-${prefix}-${status.toLowerCase()}, var(--${color.replace(
				'$',
				'system-',
			)}-${prefix}, var(--system-${status.toLowerCase()}-${prefix})))`,
			theme,
		);
	}

	return color;
};

const mapTagOrCategory = (
	{
		tag: { id: tagId, name: tagName } = {},
		category: { id: categoryId, name: categoryName } = {},
	} = {},
	prefix = '.',
) => {
	let key = tagName || categoryName;

	if (categoryId) {
		key = categoryName.startsWith('$')
			? categoryName.replace('$', '')
			: categoryId;

		if (tagId) {
			key += `${prefix}${
				tagName.startsWith('$') ? tagName.replace('$', '') : tagId
			}`;
		}
	} else if (tagId) {
		key = `${tagName.startsWith('$') ? tagName.replace('$', '') : tagId}`;
	}

	return key;
};

const Prefix = styled.span`
	display: inline-block;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	margin-right: 5px;
	background-color: ${({
		theme,
		item: { status = 'PAID' } = {},
		part: { name, color } = {},
	}) => processColor(color || name, 'color', status, theme)};
	background-image: ${({
		theme,
		item: { status = 'PAID' } = {},
		part: { name, color } = {},
	}) => processColor(color || name, 'image', status, theme)};
`;

const Tag = styled(
	({ className, active, item, onClick = () => {}, part, children }) => (
		<div
			className={classNames(className, {
				'asteria-state-active': active,
			})}
			onClick={() => onClick(part)}
			onKeyPress={() => onClick(part)}
			role="button"
			tabIndex="-1"
		>
			<Prefix part={part} item={item} />
			{children}
		</div>
	),
)`
	display: flex;
	margin-right: 5px;
`;

Tag.Styler = {
	children: [
		{
			component: Prefix,
			base: [Getter('prefix')],
		},
		{
			component: Text,
			base: [Getter('text')],
		},
	],
};

const Tags = styled(({ className, item, tags, onClick = () => {} }) => (
	<Wrapper
		className={classNames(className, 'asteria-transaction-list-tags')}
		horizontalScroll
	>
		{(tags || item?.meta?.tags || [])
			?.filter(tag => tag)
			.map(tag => (
				<Tag
					key={tag.id}
					part={tag}
					item={item}
					active={item?.tags?.includes(tag)}
					onClick={onClick}
					className={`asteria-transaction-list-tags-tag-${mapTagOrCategory(
						{
							tag,
							category: tag.category,
						},
						'-',
					)}`}
				>
					<Text size="body">
						{TranslationService.get(
							[
								// `list.transactions.transaction.${item?.type.toLowerCase()}`,
								`list.transactions.transaction.category.${mapTagOrCategory(
									{
										tag,
										category: tag.category,
									},
								)}`,
								`list.transactions.transaction.${item?.status.toLowerCase()}.category.${mapTagOrCategory(
									{
										tag,
										category: tag.category,
									},
								)}`,
							],
							`list.transactions.transaction.${item?.status.toLowerCase()}.category.${mapTagOrCategory(
								{
									tag,
									category: tag.category,
								},
							)}`,
						)}
					</Text>
				</Tag>
			))}
	</Wrapper>
))``;

Tags.Styler = {
	children: [
		{
			typePrefix: 'asteria-transaction-list-tags-tag',
			component: Tag,
			base: [Getter('tag')],
		},
	],
};

export default Tags;
