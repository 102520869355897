class SVG {
	constructor(svgInput = '') {
		const cleanSvgInput = svgInput.replace(/(?:\r\n|\r|\n)/g, '');
		const match = /.*<svg([^<>]*)>(.*)<\/svg>/g.exec(cleanSvgInput);

		this.svg = match && match[0] ? match[0] : svgInput;
		this.svgHeader = match && match[1] ? match[1] : '';
		this.svgBody = match && match[2] ? match[2] : svgInput;
	}

	getFullContent() {
		return this.svg;
	}

	getBody() {
		return this.svgBody;
	}

	getHeader() {
		const match = this.svgHeader.match(/(\w+=?(?:['"][^'"]*['"])?)/g);
		let result = {}; // eslint-disable-line prefer-const
		if (match) {
			match.forEach(attr => {
				const values = attr.replace(/['"]$/, '').split(/=['"]/);
				if (values.length) {
					result[values[0]] = values.length > 1 ? values[1] : '';
				}
			});
		}

		return result;
	}
}

export default SVG;
