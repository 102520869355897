import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { Translation, TranslationService } from '@asteria/services-language';
import DatalayerContext from '@asteria/services-datalayer/react/context';

import Button from '@asteria/component-core/button';
import { useStore, useHistory } from '@asteria/utils/hooks';

import trigger from '@asteria/component-notifications/actions';
import { hidePrompt } from '@asteria/services-datalayer/services/appstate/actions';

const Prompt = styled(({ className, prompt }) => {
	const { type, action, once = false } = prompt;
	const { dispatch, lookup } = useContext(DatalayerContext);
	const history = useHistory();
	const clickAction = useCallback(
		response => {
			if (response !== false) {
				if (action) {
					trigger(action, { dispatch, lookup, history });
				}
			}

			if (once) {
				// TODO: Store response
			}

			dispatch(hidePrompt(prompt));
		},
		[action, dispatch, history, lookup, once, prompt],
	);

	return (
		<div
			className={classNames(
				'asteria-prompt-wrapper asteria-prompt-action',
				className,
			)}
		>
			<div className={classNames('asteria-prompt', className)}>
				<Translation
					translationKey={`prompt.${type}.dialog.title`}
					Component="h2"
					className="asteria-title"
					defaultText="Information"
				/>
				<Translation
					translationKey={`prompt.${type}.dialog.text`}
					Component="p"
					className="asteria-text"
					defaultText=""
				/>
				<div className="asteria-actions">
					<Button onClick={() => clickAction(false)} type="link">
						{TranslationService.get([
							'button.abort',
							`prompt.${type}.dialog.abort`,
						])}
					</Button>
					<Button onClick={() => clickAction(true)} type="primary">
						{TranslationService.get([
							'button.yes',
							`prompt.${type}.dialog.action`,
						])}
					</Button>
				</div>
			</div>
		</div>
	);
})``;

const Prompts = () => {
	const [prompts] = useStore('store-appstate', ({ prompts: list }) => list);

	const [prompt] = prompts || [];

	if (prompt) {
		return <Prompt prompt={prompt} />;
	}

	return null;
};

export default Prompts;
export { Prompt };
