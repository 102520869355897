export default {
	settings: {
		groups: {
			checkbox: {
				types: {
					showDeposit: {
						checkboxElement: {
							types: {
								checked: {
									mark: {
										style: {},
									},
								},
							},
							button: {
								types: {
									confirm: {
										style: {
											marginLeft: '16px',
										},
									},
								},
							},
						},
					},
				},
			},
		},
		title: {
			types: {
				titleSettingsTransactionlist: {
					style: {
						marginTop: '16px',
					},
				},
			},
		},
		button: {
			types: {
				confirm: {
					style: {
						marginLeft: '116px',
					},
				},
			},
		},
		modal: {
			dialog: {
				queries: {
					'(max-width: 767.98px)': {
						style: {},
					},
					'(min-width: 768px)': {
						style: {},
					},
					'(min-width: 1200px)': {
						style: {
							width: '70%',
							maxWidth: '660px',
						},
					},
				},
				header: {
					queries: {
						'(max-width: 767.98px)': {
							style: {},
						},
						'(min-width: 768px)': {
							style: {},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
				},
				body: {
					style: {
						height: 'auto',
					},
				},
				footer: {
					justifyContent: 'flex-start',
					style: {
						background: '#fff',
					},
					button: {
						types: {
							cancel: {
								style: {
									marginLeft: '0',
								},
							},
							primary: {
								style: {},
							},
						},
					},
				},
			},
		},
	},
};
