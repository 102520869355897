import React, {
	useState,
	useCallback,
	useContext,
	useEffect,
	useMemo,
} from 'react';
import classNames from 'classnames';
import { TextV2, Title } from '@asteria/component-typography';
import { ButtonV2 } from '@asteria/component-button';
import { RadioGroupV2, Dropdown, FormGroupV2 } from '@asteria/component-form';
import Alert from '@asteria/component-alert';
import { Translation, TranslationService } from '@asteria/services-language';
import {
	Service as FeatureService,
	FeatureFlag,
	useFeature,
} from '@asteria/component-featureflag';
import { useStore } from '@asteria/utils';
import Layout from '../../layout';
import Context, { bankSoftwareTypeKey } from '../../context';
import erpSoftwares from '../../static/erpSoftwares';
import Steps from '../../static/steps';
import MissingErpModal from '../../missingErp';

const LIST_CONTAINER_STYLES = {
	listStyleType: 'none',
	paddingLeft: '0px',
	margin: '0.5em 0 0',
};
const LIST_ELEMENT_STYLES = { display: 'flex', margin: 0 };

const ListMarker = ({ style = {} }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		id="svg8"
		version="1.1"
		viewBox="0 0 10.205579 20.410641"
		height="20"
		width="10"
		style={{
			width: '6px',
			minWidth: '6px',
			marginRight: '0.75em',
			...style,
		}}
		className="asteria-list-marker"
	>
		<g transform="translate(-51.074917,-104.18486)">
			<path
				d="M 51.074917,104.18486 V 124.5955 A 10.205358,10.205358 0 0 0 61.280496,114.39044 10.205358,10.205358 0 0 0 51.074917,104.18486 Z"
				style={{
					fill: '#4770C1',
					fillOpacity: '1',
					stroke: 'none',
				}}
			/>
		</g>
	</svg>
);

const SelectProvider = ({ navigator, callback = {} }) => {
	const {
		data: { softwareType, softwareProvider },
		setProvider,
		setStepIndex,
		type,
	} = useContext(Context);
	const [showMissingErp, setShowMissingErp] = useState(false);
	const hasShowFullOnboarding = useFeature({
		key: 'onboarding-full-flow',
	});

	const [features] = useStore('store-features', ({ active = [] }) => active);

	useEffect(() => {
		setStepIndex(1);
		if (
			(navigator.history.length < 2 ||
				navigator.history[navigator.history.length - 2] !==
					Steps.FortnoxConnecting) &&
			navigator.lastEvent !== 'cancel'
		) {
			callback('goto', {
				event: 'goto',
				step: { stepId: 'selectErp' },
			});

			callback('selectIntegration', {
				event: 'selectIntegration',
				integration: 'fortnox',
				integrationTitle: 'Fortnox',
				steps: [
					{ stepId: 'fortnoxActivation', title: 'Aktivera' },
					{
						stepId: 'fortnoxConnecting',
						title: 'Väntar på anslutning',
					},
				],
			});
		}
	}, [callback, navigator, setStepIndex]);

	const softwareData = erpSoftwares();

	const erpSoftwareKeys = Object.keys(softwareData)
		.filter(key => {
			if (!FeatureService.detected[softwareData[key].key]) {
				FeatureService.detected[softwareData[key].key] = {
					key: softwareData[key].key,
				};
			}

			return features?.[softwareData[key].key];
		})
		.filter(key => softwareData[key].type === type)
		.sort((a, b) =>
			softwareData[a].title.localeCompare(softwareData[b].title),
		);
	const erpTitles = erpSoftwareKeys.map(key => softwareData[key].title);
	const providerNames = softwareType === bankSoftwareTypeKey ? [] : erpTitles;

	const [selectedSystem, selectSystem] = useState(
		softwareProvider ? erpSoftwareKeys.indexOf(softwareProvider) : 0,
	);
	const [showSelectionAlert, setShowSelectionAlert] = useState(false);

	const goToNextStep = useCallback(() => {
		if (selectedSystem === undefined) {
			setShowSelectionAlert(true);
		} else {
			const systemKey = erpSoftwareKeys[selectedSystem];
			setProvider(systemKey);
			if (softwareData[systemKey].flowStart) {
				navigator.navigateTo(softwareData[systemKey].flowStart);
			}
		}
	}, [erpSoftwareKeys, navigator, selectedSystem, setProvider, softwareData]);

	const dropdownOptions = useMemo(
		() =>
			erpSoftwareKeys.map(key => ({
				value: key,
				label: softwareData[key].title,
			})),
		[erpSoftwareKeys, softwareData],
	);

	return (
		<Layout
			className={classNames(
				'asteria-status-layout',
				`asteria-status-layout-${type}`,
			)}
			header={
				<Title
					className="asteria-title-onboarding-header"
					size="title2"
				>
					{TranslationService.get(
						[
							`onboarding.selectProvider.header`,
							`onboarding.selectProvider.${type}.header`,
						],
						'1. Välj bokföringssystem',
					)}
				</Title>
			}
			body={
				<>
					{showSelectionAlert ? (
						<Alert
							icon
							description={false}
							type="error"
							headingContent="You need either to select system or define a custom one to proceed."
							onClose={() => setShowSelectionAlert(false)}
						/>
					) : null}
					<TextV2
						style={{
							fontSize: '14px',
							marginTop: '8px',
							lineHeight: '1.1',
							marginBottom: '8px',
							/* color: '#000', */
						}}
					>
						{TranslationService.get(
							[
								'onboarding.selectProvider.description',
								`onboarding.selectProvider.${type}.description`,
							],
							'Genom att koppla ditt bokföringssystem får du följande fördelar:',
						)}
					</TextV2>
					<ul style={LIST_CONTAINER_STYLES}>
						<li style={LIST_ELEMENT_STYLES}>
							<ListMarker />
							<TextV2
								style={{
									fontSize: '14px',
									marginTop: '3px',
									lineHeight: '1.1',
									marginBottom: '8px',
									color: '#000',
								}}
							>
								{TranslationService.get(
									[
										'onboarding.selectProvider.description.list.point1',
										`onboarding.selectProvider.${type}.description.list.point1`,
									],
									'En mer träffsäker prognos på ditt kassaflöde.',
								)}
							</TextV2>
						</li>
						<li style={LIST_ELEMENT_STYLES}>
							<ListMarker />
							<TextV2
								style={{
									fontSize: '14px',
									marginTop: '3px',
									lineHeight: '1.1',
									marginBottom: '8px',
									color: '#000',
								}}
							>
								{TranslationService.get(
									[
										'onboarding.selectProvider.description.list.point2',
										`onboarding.selectProvider.${type}.description.list.point2`,
									],
									'Bättre översikt med fler kategorier t.ex skatt, lön och kortbetalningar.',
								)}
							</TextV2>
						</li>
						<li style={LIST_ELEMENT_STYLES}>
							<ListMarker />
							<TextV2
								style={{
									fontSize: '14px',
									marginTop: '3px',
									lineHeight: '1.1',
									marginBottom: '8px',
									color: '#000',
								}}
							>
								{TranslationService.get(
									[
										'onboarding.selectProvider.description.list.point3',
										`onboarding.selectProvider.${type}.description.list.point3`,
									],
									'Se kommande kundfakturor och leverantörsbetalningar.',
								)}
							</TextV2>
						</li>
					</ul>
					<div
						className="asteria-dialog-box"
						style={{
							backgroundColor: '#fbf2ea',
							padding: '20px 20px 20px',
							marginTop: '16px',
							marginBottom: '16px',
						}}
					>
						<TextV2
							style={{
								fontSize: '16px',
								fontFamily: "'Swedbank Sans'",
								fontWeight: '700',
								color: '#000',
							}}
							className="asteria-label"
						>
							<Translation
								translationKey="onboarding.general.select.title"
								defaultText="Jag vill hämta information från"
							/>
						</TextV2>

						<FeatureFlag
							feature="asteria-onboarding-integrations-dropdown"
							invert
						>
							<RadioGroupV2
								items={providerNames}
								selected={[selectedSystem]}
								direction="vertical"
								onChange={index => {
									if (showSelectionAlert) {
										setShowSelectionAlert(false);
									}
									selectSystem(index);
								}}
								style={{
									alignItems: 'flex-start',
									flexDirection: 'column',
									marginLeft: '20px',
									marginTop: '10px',
								}}
							/>
						</FeatureFlag>

						<FeatureFlag feature="asteria-onboarding-integrations-dropdown">
							<FormGroupV2 style={{ marginTop: '8px' }}>
								<Dropdown
									iconClosed="triangleDown"
									iconOpen="triangleUp"
									options={dropdownOptions}
									placeholder="Välj bokföringssystem" /* Denna ska få asteria-text-placeholder och vara lite mer grå i texten, men går ej nu? */
									itemIcon="check"
									showSelected
									search={false}
									type="default"
									selected={[dropdownOptions[selectedSystem]]}
									onChange={value => {
										const index = dropdownOptions.indexOf(
											value,
										);

										if (showSelectionAlert) {
											setShowSelectionAlert(false);
										}
										selectSystem(index);
									}}
								/>
							</FormGroupV2>
						</FeatureFlag>
					</div>
					<ButtonV2
						type="flat"
						onClick={() => setShowMissingErp(true)}
						icon="triangleRight"
						iconPosition="after"
					>
						{TranslationService.get(
							`onboarding.${type}.missing`,
							'Mitt bokföringssystem saknas',
						)}
					</ButtonV2>
					{showMissingErp && (
						<MissingErpModal
							type={type}
							onClose={() => setShowMissingErp(false)}
						/>
					)}
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							paddingTop: '20px',
						}}
					>
						<ButtonV2
							size="medium"
							type="default"
							onClick={() => {
								if (hasShowFullOnboarding) {
									navigator.navigateTo(Steps.Splash);
								} else {
									navigator.cancel(
										{
											event: 'cancel',
										},
										navigator,
									);
								}
							}}
							text={TranslationService.get(
								'onboarding.notnow',
								'Avbryt',
							)}
						/>
						<ButtonV2
							size="medium"
							type="primary"
							text={TranslationService.get(
								'onboarding.continue',
								'Fortsätt',
							)}
							onClick={goToNextStep}
						/>
					</div>
				</>
			}
			footer={<></>}
		/>
	);
};

SelectProvider.navigationKey = Steps.SelectProvider;

export default SelectProvider;
