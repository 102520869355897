export default {
	button: {
		style: {
			padding: '8px 16px',
			backgroundColor: '#D0B9A6',
			borderRadius: '2px',
			width: 'auto',
		},
		text: {
			style: {
				fontFamily: 'Swedbank Headline',
				fontWeight: 600,
				color: '#2F2424',
				flexDirection: 'column',
				alignItems: 'flex-start',
			},
			types: {
				small: {
					style: {
						color: '#979797',
						fontFamily: 'Arial, Helvetica, sans-serif',
						fontWeight: '400',
						fontSize: '14px',
						textAlign: 'left',
					},
				},
			},
		},
		icon: {
			style: {
				flexGrow: '0',
				flexShrink: '1',
			},
			svg: {
				style: {
					fill: '#2F2424',
				},
			},
		},
		'! hover': {
			style: {
				backgroundColor: 'rgb(182, 173, 167)',
			},
		},
		'& primary': {
			style: {
				backgroundColor: '#ee7023',
			},
			text: {
				style: {
					color: '#ffffff',
				},
			},
			icon: {
				svg: {
					style: {
						fill: '#ffffff',
					},
				},
			},
			'!hover': {
				style: {
					backgroundColor: 'rgb(187, 110, 62)',
				},
			},
			'& invert': {
				style: {
					backgroundColor: 'transparent',
				},
				icon: {
					svg: {
						style: {
							fill: '#ee7023',
						},
					},
				},
			}
		},
		'& secondary': {
			style: {
				backgroundColor: 'var(--secondary-color)',
			},
			text: {
				style: {
					color: '#ffffff',
				},
			},
			icon: {
				svg: {
					style: {
						fill: '#ffffff',
					},
				},
			},
			'! hover': {
				style: {
					backgroundColor: '#447C81',
				},
			},
		},
		'& link': {
			style: {
				backgroundColor: 'transparent',
			},
			text: {
				style: {
					fontFamily: 'Swedbank Sans',
					fontWeight: 700,
					color: '#257886',
					textDecoration: 'underline',
				},
			},
			icon: {
				svg: {
					style: {
						fill: '#257886',
					},
				},
			},
		},
		'& flat': {
			style: {
				justifyContent: 'flex-start',
				padding: '10px 12px',
				height: '55px',
				borderColor:
					'rgb(217, 217, 217) rgb(217, 217, 217) rgb(217, 217, 217) rgb(0, 166, 173)',
				borderLeftColor: 'rgb(0, 166, 173)',
				borderWidth: '1px 1px 1px 8px',
				borderLeftWidth: '8px',
				backgroundColor: 'rgb(249, 248, 246)',
				borderRadius: '0px',
				width: '100%',
				marginTop: '25px',
				borderLeft: '8px solid rgb(0, 166, 173)',
			},
			text: {
				style: {
					textDecoration: 'none',
					fontSize: '16px',
					fontFamily: 'Swedbank Sans',
					fontWeight: 700,
					color: '#257886',
				},
			},
			icon: {
				style: {
					margin: 'auto 12px auto 0px',
					width: '15px',
					height: '12px',
					paddingTop: '4px',
					paddingBottom: '4px',
					position: 'absolute',
					top: '0px',
					bottom: '0px',
					right: '0px',
				},
				svg: {
					style: {
						fill: '#53A1AC',
					},
				},
			},
			'& medium': {
				style: {
					height: '52px',
				},
				text: {
					style: {
						fontSize: '16px',
					},
				},
			},
		},
		'& large': {
			style: {
				height: '48px',
			},
			text: {
				style: {
					fontSize: '18px',
				},
			},
			'& icon': {
				icon: {
					style: {
						height: '32px',
						width: '32px',
					},
				},
			},
			'& iconOnly': {
				style: {
					height: '32px',
					width: '32px',
				},
			},
		},
		'& medium': {
			style: {
				height: '40px',
			},
			text: {
				style: {
					fontSize: '16px',
				},
			},
			'& icon': {
				icon: {
					style: {
						width: '24px',
					},
				},
			},
			'& iconOnly': {
				style: {
					height: '24px',
					width: '24px',
				},
			},
		},
		'& small': {
			style: {
				height: '32px',
			},
			text: {
				style: {
					fontSize: '14px',
				},
			},
			'& icon': {
				icon: {
					style: {
						height: '18px',
						width: '18px',
					},
				},
			},
			'& iconOnly': {
				style: {
					height: '18px',
					width: '18px',
				},
			},
		},
		'& iconOnly': {
			style: {
				padding: 4,
			},
		},
	},
};
