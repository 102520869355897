import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import Modal, { ModalBody, ModalFooter } from '@asteria/component-modal';
import Button from '@asteria/component-core/button';
import { TranslationService } from '@asteria/services-language';
import { LabelV2, InputV2 } from '@asteria/component-form';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { addClient } from '@asteria/services-datalayer/services/appstate/actions';
import Alert from '@asteria/component-alert';

const AddClientModal = styled(({ className, clientType, onClose }) => {
	const { dispatch } = useContext(DatalayerContext);
	const [name, setName] = useState('');
	const [error, setError] = useState(false);

	const save = () => {
		if (!name || name.trim() === '') {
			setError(true);
		} else {
			const resp = dispatch(addClient({ name, type: clientType }));
			resp.subscribe(client => {
				onClose(client);
			});
		}
	};

	return (
		<Modal
			open
			onClose={onClose}
			className={className}
			title={TranslationService.get([
				'client.add.header',
				`client.add.${clientType}.header`,
			])}
		>
			<ModalBody className="modal-body">
				<div className="forms-wrapper">
					<LabelV2>
						{TranslationService.get([
							'client.add.name.label',
							`client.add.${clientType}.name.label`,
						])}
					</LabelV2>
					<InputV2
						className="asteria-adjustable-description"
						elementType="input"
						placeholder={TranslationService.get([
							'client.add.name.placeholder',
							`client.add.${clientType}.name.placeholder`,
						])}
						value={name}
						onChange={e => {
							setName(e.target.value);
						}}
					/>
					{error ? (
						<Alert
							icon
							type="error"
							showClose={false}
							headingContent={TranslationService.get(
								'client.add.name.error.missing',
							)}
						/>
					) : null}
				</div>
			</ModalBody>
			<ModalFooter className="modal-footer">
				<Button
					size="medium"
					type="default"
					text={TranslationService.get('button.abort')}
					onClick={onClose}
				/>
				<Button
					size="medium"
					type="primary"
					text={TranslationService.get('button.add')}
					onClick={save}
				/>
			</ModalFooter>
		</Modal>
	);
})`
	.forms-wrapper {
		padding: 16px 16px 30px;
		background-color: rgb(250, 242, 235);
		span {
			text-transform: none;
			font-size: 14px;
			font-family: Arial, Helvetica, sans-serif;
			color: rgb(38, 38, 38);
			line-height: 18px;
		}
		.asteria-input {
			background: #fff;
		}
	}

	.asteria-button-default {
		margin-right: auto;
		@media (max-width: 1024px) {
			margin-right: 0;
			order: -1;
			margin-bottom: 16px;
		}
	}
	.asteria-button-primary {
	}
`;

AddClientModal.Styler = Modal.Styler;

export default AddClientModal;
