import React, { useState, useContext } from 'react';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { addTransaction } from '@asteria/services-datalayer/services/adjustable/actions';
import styled from 'styled-components';
import classNames from 'classnames';
import Button from '@asteria/component-core/button';

// import { Getter } from '@asteria/utils';

import { Title, Text } from '@asteria/component-core/typography';
import Adjustable from './transaction';
import Adjust from './adjust';

const Grouped = styled.div`
	background: #faf0e7;
	padding: 20px;
`;

const AdjustGroup = styled(({ className }) => {
	const { dispatch } = useContext(DatalayerContext);
	const [showCustomAccountBalance, setShowCustomAccountBalance] = useState(
		false,
	);
	const [showCustomTransaction, setShowCustomTransaction] = useState(false);
	return (
		<div
			className={classNames(
				className,
				'asteria-transactions-grouped-tools',
			)}
		>
			<Grouped>
				<Title>Justera prognos</Title>
				<Text>
					Lorem ipsum dolor sit amet, id legimus meliore nonumes pro,
					novum feugiat nec ut. In cum malis maiorum reprehendunt. At
					duo assum tantas.
				</Text>

				<Button
					size="medium"
					className="asteria-button-report-missing-erp"
					type="link"
					onClick={() =>
						setShowCustomAccountBalance(!showCustomAccountBalance)
					}
				>
					Ändra kontobalans
				</Button>

				<Button
					size="medium"
					className="asteria-button-report-missing-erp"
					type="link"
					onClick={() => {
						dispatch(
							addTransaction({
								type: 'DEPOSIT',
								status: 'FORECAST',
								manual: true,
								sums: { original: { total: 0 } },
								oracle: {
									risk: 1,
								},
							}),
						);
						setShowCustomTransaction(!showCustomTransaction);
					}}
				>
					Lägg till transaktion
				</Button>

				{showCustomAccountBalance ? (
					<>
						<Adjust />
					</>
				) : null}

				{showCustomTransaction ? (
					<>
						<Adjustable />
					</>
				) : null}
			</Grouped>
		</div>
	);
})``;

export default AdjustGroup;
