import React, { useCallback } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { Title, Text } from '@asteria/component-core/typography';
import Button from '@asteria/component-button/button';
import { TranslationService } from '@asteria/services-language';
import { useHistory, useStore } from '@asteria/utils/hooks';
import { CashflowHeader } from '@asteria/component-graph/logic/layout';
import AppMenu from '@asteria/component-graph/components/appmenu';
import { FeatureFlag } from '@asteria/component-featureflag';
import Search, { SearchData } from '@asteria/component-search';

import IntegrationsModal from '../components/integrationsAdd';
import Modals from './modals';

const OnboardingPage = styled(({ className }) => {
	const history = useHistory();

	const [integrations = []] = useStore(
		'store-integrations',
		({ integrations: list = [] }) => list,
	);

	const onDone = useCallback(() => {
		if (integrations.filter(({ config }) => config?.connected).length > 0) {
			history.push('/');
		} else {
			history.push('/onboarding');
		}
	}, [history, integrations]);

	return (
		<div className={classNames(className, `asteria-widget-wrapper`)}>
			<div className="asteria-widget-main">
				<CashflowHeader
					className="asteria-cashflow-header"
					key="cashflow-header"
				>
					<FeatureFlag feature="search">
						<SearchData id="cashflow-bar-graph" />
					</FeatureFlag>
					<AppMenu
						basePath="/onboarding"
						exclude={[
							'/onboarding/accounts',
							'/onboarding/integrations',
							'/onboarding/settings',
						]}
					/>
				</CashflowHeader>
				<Modals basePath="/onboarding" exlcude={['integrations']} />
				<IntegrationsModal
					basePath="/onboarding/add/"
					onDone={() => onDone()}
				/>
				<div className="asteria-integrations-empty">
					<div className="asteria-integrations-empty-inner">
						<span className="asteria-icon" />
						<Title size="title2">
							{TranslationService.get('integration.empty.title')}
						</Title>
						<Text className="asteria-small">
							{TranslationService.get('integration.empty.text')}
						</Text>

						<Button
							size="medium"
							type="primary"
							onClick={() => history.push('/onboarding/add/erp')}
						>
							{TranslationService.get(
								'integration.empty.add.erp.button',
							)}
						</Button>
						<Button
							size="medium"
							type="primary"
							onClick={() => history.push('/onboarding/add/bank')}
						>
							{TranslationService.get(
								'integration.empty.add.bank.button',
							)}
						</Button>
						{/* 						<Button
							size="small"
							type="link"
							onClick={() => history.push('/')}
						>
							{TranslationService.get(
								'integration.empty.without.button',
							)}
						</Button> */}
					</div>
				</div>
			</div>
		</div>
	);
})`
	${Search} {
		visibility: hidden;
	}
`;

export default OnboardingPage;
