export default {
	infobox: {
		toggler: {
			types: {
				link: {
					style: {
						padding: 0,
						flexGrow: 0,
						borderBottom: '0px solid transparent',
					},
					queries: {
						'(max-width: 767.98px)': {
							style: {
								width: 'auto',
								display: 'none',
							},
						},
						'(min-width: 768px)': {
							style: {},
						},
						'(min-width: 1200px)': {
							style: {},
						},
					},
					icon: {
						style: {
							height: '24px',
							width: '24px',
						},
						svg: {
							style: {
								fill: 'var(--primary-color)',
							},
						},
					},
				},
			},
		},
		content: {
			style: {
				display: 'flex',
				marginTop: '10px',
			},
			security: {
				style: {
					alignItems: 'center',
					display: 'flex',
					marginRight: '15px',
				},
			},
		},
		container: {
			style: {
				backgroundColor: '#f9f8f6',
				borderBottom: '2px solid rgba(238, 112, 35, 1)',
				borderTop: '2px solid rgba(238, 112, 35, 1)',
				marginBottom: '10px',
				marginTop: '0',
				padding: '16px',
			},
		},
		text: {
			style: {
				color: 'rgba(34,34,34,1)',
				display: 'block',
				fontSize: '14px',
				lineHeight: '20px',
			},
		},
	},
};
