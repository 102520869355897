import mediaQuery from 'css-mediaquery';
import get from 'lodash/fp/get';
import isEqual from 'lodash/fp/isEqual';
import mergeWith from 'lodash/fp/mergeWith';

const Getter = (path, defVal) => {
	const internalGetter = get(path);
	return obj => internalGetter(obj) || defVal;
};

const fakeMediaMatch = query => ({
	matches: mediaQuery.match(query, window.OwlSize),
});

const arraySkipper = (objValue, srcValue) => {
	if (Array.isArray(objValue) || Array.isArray(srcValue)) {
		return srcValue;
	}

	return undefined;
};

const StyleGetter = (path, defVal) => {
	// Prepare getter function
	const internalGetter = get(path);
	let currentTheme;

	let oldViewport = {};

	/**
	 * Check whether given viewport is the same as previously
	 * @param {Object} currentViewport - viewport data
	 * @return {boolean}
	 */
	const isSameViewport = currentViewport => {
		if (!isEqual(currentViewport, oldViewport)) {
			oldViewport = currentViewport;
			return false;
		}

		return true;
	};

	let oldTheme;

	/**
	 * Check whether given theme is the same as an old one
	 * @param {Object} theme - theme object
	 * @return {boolean}
	 */
	const isSameTheme = theme => {
		if (theme !== oldTheme) {
			oldTheme = theme;
			return false;
		}

		return true;
	};

	// Return function that accepts theme as an argument and returns it's subtree
	// according to the path
	return (obj = {}) => {
		let val = internalGetter(obj) || defVal;
		if (path === '') {
			val = obj || defVal;
		}

		// Perform a simple check. If theme hasn't changed from last call time, and viewport parameters are the same -
		// then return cached value
		if (
			isSameTheme(obj) &&
			currentTheme &&
			isSameViewport(
				window.OwlSize || {
					type: 'screen',
					width: window.innerWidth,
					height: window.innerHeight,
				},
			)
		) {
			return currentTheme;
		}

		// TODO: Fix this so not to use globals window
		const mediaMatch = window.OwlSize ? fakeMediaMatch : window.matchMedia;
		if (obj.breakpoints) {
			const breakpoints = Object.keys(obj.breakpoints);

			for (let i = 0; i < breakpoints.length; i += 1) {
				if (mediaMatch(breakpoints[i]).matches) {
					val = mergeWith(
						arraySkipper,
						val,
						internalGetter(obj.breakpoints[breakpoints[i]]),
					);
				}
			}
		}

		// Cache calculated theme
		currentTheme = val;

		return val;
	};
};

export { StyleGetter };
export default Getter;
