import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { StyleGetter, compileStyles } from '@asteria/utils';

const Bar = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	transition: width 0.3s linear;
	${props => `width: ${props.percentage}%`};
`;

const BarViewLonger = styled.div`
	position: absolute;
	transition: width 0.3s linear;
	top: 0;
	left: 0;
	bottom: 0;
	will-change: left, right;
	animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
		infinite;
	@keyframes indeterminate {
		0% {
			left: -35%;
			right: 100%;
		}
		60% {
			left: 100%;
			right: -90%;
		}
		100% {
			left: 100%;
			right: -90%;
		}
	}
`;

const BarViewShorter = styled.div`
	position: absolute;
	transition: width 0.3s linear;
	top: 0;
	left: 0;
	bottom: 0;
	will-change: left, right;
	animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
		infinite;
	animation-delay: 1.15s;
	@keyframes indeterminate-short {
		0% {
			left: -200%;
			right: 100%;
		}
		60% {
			left: 107%;
			right: -8%;
		}
		100% {
			left: 107%;
			right: -8%;
		}
	}
`;

const LinearIndicator = styled(
	({ progressPercent, determinate, className }) => (
		<div
			className={classNames(
				'asteria-loading',
				determinate ? 'asteria-progress-linear' : '',
				className,
			)}
		>
			{determinate ? (
				<Bar
					percentage={parseInt(progressPercent, 10) || 0}
					determinate={determinate}
					className="asteria-loading-bar"
				/>
			) : (
				<>
					<BarViewLonger className="asteria-loading-bar-long" />
					<BarViewShorter className="asteria-loading-bar-short" />
				</>
			)}
		</div>
	),
)`
	display: block;
	position: relative;
	width: 300px;
	overflow: hidden;

	${({ theme }) => compileStyles(LinearIndicator, theme)}
`;

LinearIndicator.Styler = {
	typePrefix: 'asteria-progress',
	base: [StyleGetter(`progressBar.linear`, {})],
	children: [
		{
			component: Bar,
			base: [StyleGetter(`bar`, {})],
		},
		{
			component: BarViewShorter,
			base: [StyleGetter(`bar`, {})],
		},
		{
			component: BarViewLonger,
			base: [StyleGetter(`bar`, {})],
		},
	],
};

export default LinearIndicator;
