/* eslint-disable no-unused-vars */
import React, {
	useState,
	useMemo,
	useContext,
	useCallback,
	useEffect,
} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import SidePane from '@asteria/component-core/sidepane';
import { TranslationService, Translation } from '@asteria/services-language';
import { useLocation, useHistory } from '@asteria/utils/hooks';
import Button from '@asteria/component-core/button';
import { Title, Text } from '@asteria/component-core';
import Alert from '@asteria/component-alert';
import Modal, { ModalBody, ModalFooter } from '@asteria/component-modal';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import List, {
	Header,
	Item,
	Row,
	RowActions,
} from '@asteria/component-core/list';
import { formatNumber, useStore } from '@asteria/utils';
import {
	sendFactoring,
	setFactoring,
	setFilters,
	setListOpen,
} from '@asteria/services-datalayer/services/appstate/actions';
import {
	setSelectedRow,
	toggleSelectedRow,
} from '@asteria/services-datalayer/services/list/actions';
import {
	queryCashflow,
	queryCashflows,
} from '@asteria/services-datalayer/services/graph/actions';
import { endOfISOWeek, endOfMonth, endOfYear, format } from 'date-fns';

const endOfTime = (date, size) => {
	if (size === 'week') {
		return endOfISOWeek(date);
	}

	if (size === 'month') {
		return endOfMonth(date);
	}

	if (size === 'year') {
		return endOfYear(date);
	}

	return endOfMonth(date);
};

const FactoringItemDetails = styled(({ className, item = {} }) => {
	const {
		meta: { invoiceNumber = '12345678' } = {},
		sums: { original: { total = 12726, currency = 'SEK' } = {} } = {},
		dates: { invoiceDue = new Date() } = {},
		info: { status = 'UNPAID' } = {},
		client: { name = 'Kund' } = {},
	} = item;

	const cost = 0.05;

	return (
		<ul className={className}>
			<li>
				<Text className="asteria-text-label">
					{TranslationService.get('factoring.item.number', 'Faktura')}
				</Text>
				<Text className="asteria-text-info">{invoiceNumber}</Text>
			</li>
			<li>
				<Text className="asteria-text-label">
					{TranslationService.get('factoring.item.name', 'Kund')}
				</Text>
				<Text className="asteria-text-info">{name}</Text>
			</li>
			<li>
				<Text className="asteria-text-label">
					{TranslationService.get(
						'factoring.item.paymentDate',
						'Bet. datum',
					)}
				</Text>
				<Text className="asteria-text-info">
					{TranslationService.get(
						'factoring.item.paymentDate.value',
						'{{invoiceDue | date: "YYYY-MM-DD"}}',
						{
							item,
							invoiceDue,
						},
					)}
				</Text>
			</li>
			<li>
				<Text className="asteria-text-label">
					{TranslationService.get(
						'factoring.item.category',
						'Kategori',
					)}
				</Text>
				<Text className="asteria-text-info">
					{TranslationService.get(
						'factoring.item.category.customer',
						'Kundfaktura',
					)}
				</Text>
			</li>
			<li>
				<Text className="asteria-text-label">
					{TranslationService.get('factoring.item.status', 'Status')}
				</Text>
				<Text className="asteria-text-info">
					{TranslationService.get(
						`factoring.item.status.value.${status}`,
						'Obetald',
					)}
				</Text>
			</li>
			<li>
				<Text className="asteria-text-label">
					{TranslationService.get('factoring.item.cost', 'Kostnad')}
				</Text>
				<Text className="asteria-text-info">
					{`${Math.round(cost * 100)}%`}
				</Text>
			</li>
			<li>
				<Text className="asteria-text-label">
					{TranslationService.get('factoring.item.amount', 'Belopp')}
				</Text>
				<Text className="asteria-text-info">
					{`${formatNumber(total)} ${currency}`}
				</Text>
			</li>
		</ul>
	);
})`
	list-style: none;
	padding: 0;
	margin: 0;
	width: 100%;
	li {
		display: flex;
		padding: 8px 8px;
		box-shadow: 0px 1px 0px var(--transaction-list-border-color);
		&:last-of-type {
			box-shadow: 0px 0px 0px #d0c3bd;
		}
		margin: 0;
		.asteria-text-label {
			color: var(--transaction-list-text-color) !important;
			font-family: Arial, sans-serif !important;
			font-weight: 400 !important;
			font-size: 12px !important;
		}
		${Text} {
			flex-grow: 1;

			&.asteria-text-info {
				text-align: right !important;
				color: var(--transaction-list-text-color) !important;
				font-family: Arial, sans-serif !important;
				font-weight: 700 !important;
				font-size: 12px !important;
			}
		}
	}
`;

const FactoringItemClientDetails = styled(({ className, item = {} }) => {
	const { client, transactions } = item;
	const message = client?.name;

	const total = useMemo(
		() =>
			transactions.reduce(
				(acc, trans) => acc + trans?.sums?.original?.total || 0,
				0,
			),
		[transactions],
	);

	const cost = 0.05;

	return (
		<ul className={className}>
			<li>
				<Text className="asteria-text-label">
					{TranslationService.get(
						'factoring.item.client.number',
						'Fakturor',
					)}
				</Text>
				<Text className="asteria-text-info">{transactions.length}</Text>
			</li>
			<li>
				<Text className="asteria-text-label">
					{TranslationService.get('factoring.item.name', 'Kund')}
				</Text>
				<Text className="asteria-text-info">{client?.name}</Text>
			</li>
			<li>
				<Text className="asteria-text-label">
					{TranslationService.get(
						'factoring.item.category',
						'Kategori',
					)}
				</Text>
				<Text className="asteria-text-info">
					{TranslationService.get(
						'factoring.item.category.customer',
						'Kundfakturor',
					)}
				</Text>
			</li>
			<li>
				<Text className="asteria-text-label">
					{TranslationService.get('factoring.item.cost', 'Kostnad')}
				</Text>
				<Text className="asteria-text-info">
					{`${Math.round(cost * 100)}%`}
				</Text>
			</li>
			<li>
				<Text className="asteria-text-label">
					{TranslationService.get('factoring.item.amount', 'Belopp')}
				</Text>
				<Text className="asteria-text-info">
					{`${formatNumber(total)} ${
						transactions?.[0]?.sums?.original?.currency
					}`}
				</Text>
			</li>
		</ul>
	);
})`
	list-style: none;
	padding: 0;
	margin: 0;
	width: 100%;
	li {
		display: flex;
		padding: 8px 8px;
		box-shadow: 0px 1px 0px var(--transaction-list-border-color);
		&:last-of-type {
			box-shadow: 0px 0px 0px #d0c3bd;
		}
		margin: 0;
		.asteria-text-label {
			color: var(--transaction-list-text-color) !important;
			font-family: Arial, sans-serif !important;
			font-weight: 400 !important;
			font-size: 12px !important;
		}
		${Text} {
			flex-grow: 1;

			&.asteria-text-info {
				text-align: right !important;
				color: var(--transaction-list-text-color) !important;
				font-family: Arial, sans-serif !important;
				font-weight: 700 !important;
				font-size: 12px !important;
			}
		}
	}
`;

const FactoringClientItem = styled(({ className, item = {}, clients }) => {
	const { dispatch } = useContext(DatalayerContext);
	const [isExpanded, setExpanded] = useState(false);

	const { client, transactions } = item;
	const message = client?.name;

	const total = useMemo(
		() =>
			transactions.reduce(
				(acc, trans) => acc + trans?.sums?.original?.total || 0,
				0,
			),
		[transactions],
	);

	return (
		<Item className={className}>
			<Row className="asteria-factoring-list-item">
				<Button
					icon={isExpanded ? 'down' : 'right'}
					type="link"
					size="small"
					onClick={() => setExpanded(!isExpanded)}
					className="asteria-button-toggle"
				/>
				<div className="asteria-factoring-item-info">
					<Title>{message}</Title>
					<Text>
						{TranslationService.get(
							'factoring.client.subtext',
							'{{item.transactions.length}} fakturor',
							{
								item,
							},
						)}
					</Text>
				</div>
				<Title className="asteria-factoring-item-amount">
					{`${formatNumber(total)} ${
						transactions?.[0]?.sums?.original?.currency
					}`}
				</Title>
				<RowActions>
					<Button
						size="small"
						className="asteria-button-remove"
						icon="circleMinusFilled"
						type="link"
						onClick={() => {
							dispatch(
								setFactoring(clients.filter(c => c !== item)),
							);
						}}
					/>
				</RowActions>
			</Row>
			{isExpanded ? (
				<Row className="asteria-factoring-item-details">
					<FactoringItemClientDetails item={item} />
				</Row>
			) : null}
		</Item>
	);
})`
	margin: 0;

	.asteria-factoring-list-item {
		padding: 12px 8px 12px 8px;
	}

	.asteria-button-toggle {
		height: 20px !important;
		width: 20px !important;
		.asteria-icon-wrapper {
			svg {
				fill: #9f8c82 !important;
			}
		}
	}

	${RowActions} {
		margin-left: 0;
	}

	.asteria-button-remove {
		height: 24px !important;
		width: 24px !important;
		.asteria-icon-wrapper {
			svg {
				fill: #9f8c82 !important;
			}
		}
	}

	.asteria-factoring-item-info {
		flex-grow: 1;
		max-width: 47%;
		.asteria-text {
			color: var(--transaction-list-text-color);
			font-family: Swedbank Sans;
			font-weight: 700;
			font-size: 14px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.asteria-title {
		&.asteria-factoring-item-amount {
			color: var(--transaction-list-text-color);
			font-family: Swedbank Sans;
			font-weight: 700;
			font-size: 14px;
			text-align: right;
			margin-left: auto;
			padding-right: 4px;
		}
	}
	${Row} {
		&.asteria-factoring-item-details {
			padding: 9px 12px;
			background-color: #faf0e7;
			border-top: 1px solid var(--transaction-list-border-color);
		}
	}
`;

const FactoringItem = styled(({ className, item = {}, clients }) => {
	const { dispatch } = useContext(DatalayerContext);
	const [isExpanded, setExpanded] = useState(false);

	const { type: factoringType, links = [] } = item;

	if (factoringType === 'CLIENT') {
		return <FactoringClientItem item={item} clients={clients} />;
	}

	const invoiceLink =
		links.find(({ type }) => type === 'INVOICE_PAYMENT') || {};
	const {
		invoiceDetails: {
			meta: { message = 'Faktura Meddelande' } = {},
			sums: { original: { total = 12726, currency = 'SEK' } = {} } = {},
			client: { name = 'Kund' } = {},
		} = {},
	} = invoiceLink;

	return (
		<Item className={className}>
			<Row className="asteria-factoring-list-item">
				<Button
					icon={isExpanded ? 'down' : 'right'}
					type="link"
					size="small"
					onClick={() => setExpanded(!isExpanded)}
					className="asteria-button-toggle"
				/>
				<div className="asteria-factoring-item-info">
					<Title>{message}</Title>
					<Text>{name}</Text>
				</div>
				<Title className="asteria-factoring-item-amount">
					{`${formatNumber(total)} ${currency}`}
				</Title>
				<RowActions>
					<Button
						size="small"
						className="asteria-button-remove"
						icon="circleMinusFilled"
						type="link"
						onClick={() => {
							dispatch(toggleSelectedRow(item));
						}}
					/>
				</RowActions>
			</Row>
			{isExpanded ? (
				<Row className="asteria-factoring-item-details">
					<FactoringItemDetails item={invoiceLink?.invoiceDetails} />
				</Row>
			) : null}
		</Item>
	);
})`
	margin: 0;

	.asteria-factoring-list-item {
		padding: 12px 8px 12px 8px;
	}

	.asteria-button-toggle {
		height: 20px !important;
		width: 20px !important;
		.asteria-icon-wrapper {
			svg {
				fill: #9f8c82 !important;
			}
		}
	}

	${RowActions} {
		margin-left: 0;
	}

	.asteria-button-remove {
		height: 24px !important;
		width: 24px !important;
		.asteria-icon-wrapper {
			svg {
				fill: #9f8c82 !important;
			}
		}
	}

	.asteria-factoring-item-info {
		flex-grow: 1;
		max-width: 47%;
		.asteria-text {
			color: var(--transaction-list-text-color);
			font-family: Swedbank Sans;
			font-weight: 700;
			font-size: 14px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.asteria-title {
		&.asteria-factoring-item-amount {
			color: var(--transaction-list-text-color);
			font-family: Swedbank Sans;
			font-weight: 700;
			font-size: 14px;
			text-align: right;
			margin-left: auto;
			padding-right: 4px;
		}
	}
	${Row} {
		&.asteria-factoring-item-details {
			padding: 9px 12px;
			background-color: #faf0e7;
			border-top: 1px solid var(--transaction-list-border-color);
		}
	}
`;

const FactoringFooter = styled(({ className, items = [], clients = [] }) => {
	const totalFactoring = useMemo(
		() =>
			items.reduce(
				(
					acc,
					{
						sums: {
							original: { total },
						},
					},
				) => acc + total,
				0,
			) +
			clients.reduce(
				(acc, { transactions = [] }) =>
					acc +
					transactions.reduce(
						(
							totalTrans,
							{
								sums: {
									original: { total },
								},
							},
						) => totalTrans + total,
						0,
					),
				0,
			),
		[clients, items],
	);
	const currency = 'SEK';

	const countTransactions = useMemo(
		() =>
			items.length +
			clients.reduce(
				(acc, { transactions = [] }) => acc + transactions.length,
				0,
			),
		[clients, items],
	);

	return (
		<div className={className}>
			<div className="asteria-factoring-footer-price">
				<Title>
					{TranslationService.get(
						'factoring.total.cost.title',
						'Kostnad',
					)}
				</Title>
				<Title>
					{TranslationService.get(
						'factoring.total.cost',
						'{{cost | round}} {{currency}}',
						{
							cost: totalFactoring * 0.05,
							currency,
						},
					)}
				</Title>
				{/* Vill vi skriva ut vad 5% efter totala beloppet? hela kostnads-summan istället för 5%? tror det är tydligare */}
			</div>
			<div className="asteria-factoring-footer-info">
				<Title>
					{TranslationService.get(
						'factoring.total.amount.total',
						'Totalt',
					)}
				</Title>
				<div className="asteria-factoring-footer-total">
					<Title>
						{TranslationService.get(
							'factoring.total.amount',
							'{{total | number}} {{currency}}',
							{
								total: totalFactoring,
								currency,
							},
						)}
					</Title>
					<Text>
						{TranslationService.get(
							'factoring.footer.count',
							'{{count}} fakturor',
							{
								count: countTransactions,
							},
						)}
					</Text>
				</div>
			</div>
		</div>
	);
})`
	flex-basis: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	padding-bottom: 16px;
	.asteria-factoring-footer-info {
		display: flex;
		flex-direction: row;
		.asteria-title {
			color: var(--transaction-list-text-color) !important;
			font-family: Swedbank Sans !important;
			font-weight: 700 !important;
			font-size: 16px !important;
			white-space: nowrap !important;
			overflow: hidden !important;
			text-overflow: ellipsis !important;
		}
		.asteria-factoring-footer-total {
			text-align: right;
			margin-left: auto;
			.asteria-title {
				text-align: right !important;
				color: var(--transaction-list-text-color) !important;
				font-family: Arial, sans-serif !important;
				font-weight: 80 !important;
				font-size: 16px !important;
			}
			.asteria-text {
				text-align: right !important;
				color: var(--transaction-list-text-color) !important;
				font-family: Arial, sans-serif !important;
				font-weight: 400 !important;
				font-size: 12px !important;
				color: #999 !important;
			}
		}
	}
	.asteria-factoring-footer-price {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		margin-bottom: 8px;
		.asteria-title {
			color: #999999 !important;
			font-family: Swedbank Sans !important;
			font-weight: 700 !important;
			font-size: 14px !important;
			white-space: nowrap !important;
			overflow: hidden !important;
			text-overflow: ellipsis !important;
		}
		text-align: right;
		.asteria-title {
			text-align: right !important;
			color: #999999 !important;
			font-family: Arial, sans-serif !important;
			font-weight: 80 !important;
			font-size: 14px !important;
		}
		.asteria-text {
			text-align: right !important;
			color: #999999 !important;
			font-family: Arial, sans-serif !important;
			font-weight: 400 !important;
			font-size: 12px !important;
			color: #999 !important;
		}
	}
`;

const Image = styled(({ className, src, fallback, ...props }) => {
	const [image, setImage] = useState(src);

	useEffect(() => {
		setImage(src);
	}, [src]);

	return (
		<img
			className={className}
			src={image}
			alt=""
			onError={() => {
				setImage(fallback);
			}}
			{...props}
		/>
	);
})``;

const FactoringFirstTime = styled(({ className, open, onClose, onAbort }) => {
	const [site] = useStore('store-appstate', state => state.site);

	return (
		<Modal
			open={open}
			onClose={onAbort}
			className={className}
			title={TranslationService.get(
				'factoring.first.title',
				'Hur fungerar fakturaköp',
			)}
		>
			<ModalBody>
				<Text>
					{TranslationService.get(
						'factoring.first.description',
						'Fakturabelåning, eller factoring, innebär att du säljer eller belånar dina fakturor till oss. Du får snabbt loss dina pengar som annars skulle ha varit bundna i kundfakturor samtidigt som banken hanterar din kundreskontra. Det är både enkelt och kostnadseffektivt!',
					)}
				</Text>
				<div className="asteria-factoring-firsttime-help">
					<Image
						src={`https://dev.api.asteria.ai/assets/${site}/images/guide/factoring.gif`}
						fallback="https://dev.api.asteria.ai/assets/asteria/images/guide/factoring.gif"
						alt="factoring"
					/>
					<div>
						<Title>
							{TranslationService.get(
								'factoring.first.steps.title',
								'Så här fungerar det',
							)}
						</Title>
						<Translation
							translationKey="factoring.first.steps.description"
							Component={Text}
							defaultText="1. Välj de fakturorna du vill hantera för fakturaköp samtidigt som du får visuell påverkan i ditt kassaflöde</br></br>2. Granska dina valda fakturor och belopp så du känner dig nöjd</br></br>3. Skicka förfrågan om fakturaköp och se status under “Finansiera” i menyn"
							size="body"
						/>
					</div>
				</div>
			</ModalBody>
			<ModalFooter className="asteria-modal-footer">
				<Button
					size="medium"
					type="default"
					text={TranslationService.get('button.abort', 'Avbryt')}
					onClick={onAbort}
				/>
				<Button
					size="medium"
					type="primary"
					text={TranslationService.get('button.start', 'Starta')}
					onClick={onClose}
				/>
			</ModalFooter>
		</Modal>
	);
})`
	.asteria-factoring-firsttime-help {
		display: flex;
		background-color: #fbf2ea;
		margin-top: 16px;
		padding: 16px;

		${Title} {
			margin-bottom: 8px;
		}

		img {
			max-width: 50%;
			padding-right: 9px;
		}

		div {
			padding-left: 9px;
		}
	}

	.asteria-modal-footer {
		justify-content: space-between;
		.asteria-button-primary {
			margin-left: auto;
			.asteria-text {
				font-size: 16px;
			}
		}
	}
`;

const FactoringSent = styled(
	({ className, open, onClose, items = [], clients = [] }) => {
		const totalFactoring = useMemo(
			() =>
				items.reduce(
					(
						acc,
						{
							sums: {
								original: { total },
							},
						},
					) => acc + total,
					0,
				) +
				clients.reduce(
					(acc, { transactions = [] }) =>
						acc +
						transactions.reduce(
							(
								totalTrans,
								{
									sums: {
										original: { total },
									},
								},
							) => totalTrans + total,
							0,
						),
					0,
				),
			[clients, items],
		);
		const currency = 'SEK';

		const countTransactions = useMemo(
			() =>
				items.length +
				clients.reduce(
					(acc, { transactions = [] }) => acc + transactions.length,
					0,
				),
			[clients, items],
		);

		return (
			<Modal
				open={open}
				onClose={onClose}
				className={className}
				title={TranslationService.get(
					'factoring.sent.title',
					'Fakturaköp',
				)}
			>
				<ModalBody className="modal-body asteria-factoring-success">
					<Alert
						icon
						type="success"
						headingContent={TranslationService.get(
							'factoring.sent.alert.title',
							'Tack!',
						)}
						showClose={false}
						description={
							<>
								<Title
									size="title3"
									style={{
										marginTop: '0px',
										marginBottom: '8px',
										fontSize: '14px',
									}}
								>
									{TranslationService.get(
										'factoring.sent.content.title1',
										'Kostnad {{cost | round}} {{currency}}',
										{
											cost: totalFactoring * 0.05,
											currency,
										},
									)}
								</Title>
								<Title size="title2">
									{`${formatNumber(
										totalFactoring,
									)} ${currency}`}
								</Title>
								<Title
									size="title3"
									style={{
										marginTop: '4px',
										marginBottom: '8px',
										fontSize: '14px',
									}}
								>
									{TranslationService.get(
										'factoring.sent.content.title2',
										'{{count}} fakturor',
										{
											count: countTransactions,
										},
									)}
								</Title>
								<Text size="small">
									{TranslationService.get(
										'factoring.sent.content.title3',
										'Vi kommer att hantera din förfrågan på fakturaköp. Du kan följa upp ärendet genom navigationen uppe till vänster.',
									)}
								</Text>
							</>
						}
					/>
				</ModalBody>
				<ModalFooter className="modal-footer">
					<Button
						size="medium"
						type="primary"
						text={TranslationService.get('button.done', 'Spara')}
						onClick={onClose}
					/>
				</ModalFooter>
			</Modal>
		);
	},
)`
	.asteria-factoring-firsttime-help {
		display: flex;
		background-color: #fbf2ea;
		padding: 9px;

		img {
			max-width: 50%;
			padding-right: 9px;
		}

		.asteria-title-3 {
			font-size: 22px;
			margin-top: 16px;
			text-align: center;
			font-family: 'Swedbank Sans';
			font-weight: 700;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: 0px 16px !important;
		}

		.asteria-title-2 {
			line-height: 44px;
			margin-bottom: 8px;
			font-size: 36px;
			color: rgb(72, 140, 26);
			text-align: center;
			font-family: 'Swedbank Sans';
			font-weight: 700;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: 0px 16px !important;
		}

		.asteria-text {
			display: block;
			text-align: center;
			font-family: 'Swedbank Sans';
			font-weight: 700;
			font-size: 12px;
		}

		div {
			padding-left: 9px;
		}
	}

	.asteria-alert-success {
		margin-bottom: 35px;
		padding-bottom: 40px;
		.asteria-text {
			&:first-of-type {
				font-weight: 700;
				font-family: 'Swedbank Headline';
				font-size: 20px;
				text-align: left;

				.asteria-text {
					display: block;
					text-align: center;
					font-family: 'Swedbank Sans';
					font-weight: 700;
					font-size: 12px;
				}
			}
		}

		.asteria-title-title-3 {
			font-size: 22px;
			margin-top: 16px;
			text-align: center;
			font-family: 'Swedbank Sans';
			font-weight: 700;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: 0px 16px !important;
		}

		.asteria-title-title-2 {
			line-height: 44px;
			margin-bottom: 8px;
			font-size: 36px;
			color: rgb(72, 140, 26);
			text-align: center;
			font-family: 'Swedbank Sans';
			font-weight: 700;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: 0px 16px !important;
		}
	}
`;

const FactoringListEmpty = styled(({ className }) => (
	<Item className={classNames(className, 'asteria-factoring-list-empty')}>
		<Row className="asteria-factoring-list-empty-title">
			<Title>
				{TranslationService.get(
					'factoring.list.empty.title',
					'Så här fungerar det',
				)}
			</Title>
		</Row>
		<Row className="asteria-factoring-list-empty-body">
			<Text size="body">
				{TranslationService.get(
					'factoring.list.empty.text',
					'Välj de fakturor i listan till vänster som du vill skicka till fakturaköp och granska sedan här innan du väljer att skicka till försäljning.',
				)}
			</Text>
		</Row>
	</Item>
))`
	padding: 0 16px 16px 0;
`;

const getClientId = item => {
	const links = item?.links || [];
	if (links.find(({ type }) => type === 'INVOICE_PAYMENT')) {
		const invoice = links.find(({ type }) => type === 'INVOICE_PAYMENT');
		return invoice?.invoiceDetails?.client?.id || false;
	}
	if (links.find(({ type }) => type === 'CLIENT')) {
		const client = links.find(({ type }) => type === 'CLIENT');
		return client?.id || false;
	}

	return false;
};

const FactoringSidebar = styled(({ className }) => {
	const { dispatch } = useContext(DatalayerContext);
	const [isSent, setSend] = useState(false);
	const [isFirstTime, setFirstTime] = useState(true);
	const { pathname } = useLocation();
	const history = useHistory();
	const [clients = []] = useStore(
		'store-appstate',
		({ factoring }) => factoring,
	);

	const onAbort = useCallback(() => {
		dispatch(setFilters([]));
		dispatch(setListOpen(false));
		setSend(false);
		history.push('/');
	}, [dispatch, history]);

	const [selected = []] = useStore(
		'store-list',
		({ state: { selected: list = [] } = {} }) => list,
	);

	const [{ range }] = useStore(
		'store-graph',
		({ 'cashflow-bar-graph': state = {} }) => state,
	);

	const [filters = []] = useStore(
		'store-appstate',
		({ filters: list }) => list,
	);

	const [size = false] = useStore(
		'store-appstate',
		({ timesliceSize }) => timesliceSize,
		true,
	);

	const items = useMemo(
		() =>
			selected.filter(item => {
				const clientId = getClientId(item);
				if (!clientId) {
					return true;
				}

				return !clients.find(({ client: { id } }) => clientId === id);
			}),
		[clients, selected],
	);

	useEffect(() => {
		if (pathname === '/factoring') {
			dispatch(
				queryCashflows('cashflow-bar-graph', {
					dates: range.map(({ id }) => ({
						startDate: id,
						endDate: `${format(
							endOfTime(new Date(id), size),
							'YYYY-MM-DD',
						)}T23:59:59.999Z`,
					})),
					tags: filters
						.filter(({ type }) => type === 'tag')
						.map(({ id: tagId }) => tagId),
					clients: filters
						.filter(({ type }) => type === 'client')
						.map(({ id: customerId }) => customerId),
					status: filters
						.filter(({ type }) => type === 'status')
						.map(({ config: { status } }) => status),
					currencies: filters
						.filter(({ type }) => type === 'currency')
						.map(({ config: { name } }) => name),
					preview: {
						factoring: {
							transactions: items.map(
								({ id: transId }) => transId,
							),
							clients: clients.map(data => data?.client?.id),
						},
					},
				}),
			);
		}
	}, [dispatch, filters, range, items, pathname, size, clients]);

	return (
		<div className="asteria-sidepane">
			<FactoringFirstTime
				open={pathname === '/factoring' && isFirstTime}
				onClose={() => {
					setFirstTime(false);
				}}
				onAbort={onAbort}
			/>
			<FactoringSent
				open={pathname === '/factoring' && isSent && !isFirstTime}
				items={items}
				clients={clients}
				onClose={() => {
					setSend(false);
					dispatch(setFilters([]));
					dispatch(setSelectedRow([]));
					dispatch(setFactoring([]));
					history.push('/');
				}}
			/>
			<SidePane
				open={pathname === '/factoring' && !isFirstTime}
				onClose={() => {
					dispatch(setFilters([]));
					dispatch(setSelectedRow([]));
					dispatch(setFactoring([]));
					history.push('/');
				}}
				className={classNames(className, 'asteria-sidepane-right')}
				title={TranslationService.get('factoring.title', 'Fakturaköp')}
				footer={<FactoringFooter items={items} clients={clients} />}
				actions={[
					{
						type: 'default',
						action: () => {
							dispatch(setFilters([]));
							dispatch(setSelectedRow([]));
							dispatch(setFactoring([]));
							history.push('/');
						},
						title: TranslationService.get('button.abort'),
					},
					{
						type: 'primary',
						action: () => {
							const totalFactoring =
								items.reduce(
									(
										acc,
										{
											sums: {
												original: { total },
											},
										},
									) => acc + total,
									0,
								) +
								clients.reduce(
									(acc, { transactions = [] }) =>
										acc +
										transactions.reduce(
											(
												totalTrans,
												{
													sums: {
														original: { total },
													},
												},
											) => totalTrans + total,
											0,
										),
									0,
								);
							const currency = 'SEK';

							const countTransactions =
								items.length +
								clients.reduce(
									(acc, { transactions = [] }) =>
										acc + transactions.length,
									0,
								);
							dispatch(
								sendFactoring([
									{
										date: new Date(),
										total: totalFactoring,
										count: countTransactions,
										currency,
										transactions: items,
										clients,
										status: 'PROCESSING',
									},
								]),
							);
							setSend(true);
						},
						title: TranslationService.get('button.send'),
					},
				]}
			>
				<List>
					<Header className="asteria-sidepane-section-title">
						<Title>
							{`${TranslationService.get(
								'factoring.list.header',
								'Att skicka till fakturaköp',
							)}`}
						</Title>
					</Header>
					{clients.length + items.length === 0 ? (
						<FactoringListEmpty />
					) : null}
					{clients.map(item => (
						<FactoringItem item={item} clients={clients} />
					))}
					{items.map(item => (
						<FactoringItem item={item} />
					))}
				</List>
			</SidePane>
		</div>
	);
})`
	.asteria-sidepane-header {
		max-height: 24px !important;
	}

	.asteria-sidepane-body {
		.asteria-sidepane-body-scroll {
			background: #fff;
		}
		height: calc(100% - 218px);
		.asteria-sidepane-body-inner {
			height: auto;
		}
	}

	.asteria-sidepane-footer {
		flex-wrap: wrap;
	}

	.asteria-sidepane-section-title {
		margin: 0;
		.asteria-title {
			font-family: Arial, Helvetica, sans-serif;
			font-size: 14px;
			color: var(--text-color);
		}
	}
`;

export default FactoringSidebar;
