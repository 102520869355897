import { map } from 'rxjs/operators';
import {
	ADD_INTEGRATION,
	SEND_MISSING_ERP,
	// UPDATE_INTEGRATION,
} from './queries';

export const fetchIntegrations = () => ({ dispatch }) => {
	dispatch(() => ({
		action: 'FETCHING_INTEGRATIONS',
	}));

	dispatch(() => ({
		action: 'REQUEST_DATA_INTEGRATIONS',
		payload: {},
	}));
};

export const initiateIntegration = ({
	integrationName,
	type,
	config = {},
}) => ({ dispatch, lookup }) => {
	const apiService = lookup('service-api');

	return apiService
		.query(ADD_INTEGRATION, {
			input: {
				key: integrationName,
				type,
				config,
			},
		})
		.pipe(
			map(
				({
					data: {
						addIntegration: {
							integration: { id },
						},
					} = {},
				} = {}) => id,
			),
		)
		.subscribe(
			data => {
				dispatch(() => ({
					action: 'CONNECTION_SUCCESSFUL',
					payload: data,
				}));
				dispatch(fetchIntegrations());
			},
			e =>
				dispatch(() => ({
					action: 'CONNECTION_FAILED',
					payload: { error: e },
				})),
		);
};

export const initiateConnectionByApiKey = ({ integrationName, type, apiKey }) =>
	initiateIntegration({
		integrationName,
		type,
		config: {
			client: {
				authorizationCode: apiKey,
			},
		},
	});

export const removeIntegration = ({ id }) => ({ dispatch }) => {
	dispatch(() => ({
		action: 'REQUEST_REMOVE_INTEGRATION',
		payload: { input: id },
	}));
};

export const sendMissingErp = ({ name }) => ({ lookup }) => {
	const apiService = lookup('service-api');

	return apiService
		.query(SEND_MISSING_ERP, {
			name,
		})
		.subscribe(() => {});
};
