export default {
	progressBar: {
		linear: {
			style: {
				height: '4px',
				backgroundColor: 'rgba(205, 224, 227, 1)',
			},
			bar: {
				style: {
					backgroundColor: 'rgba(72, 147, 160, 1)',
				},
			},
			types: {
				determinite: {
					style: {
						height: '4px',
						backgroundColor: 'rgba(205, 224, 227, 1)',
					},
					bar: {
						style: {
							backgroundColor: 'rgba(72, 147, 160, 1)',
						},
					},
				},
			},
		},
		circular: {
			style: {
				width: '48px',
				height: '48px',
			},
			svg: {
				style: {
					stroke: 'rgba(72, 147, 160, 1)',
					width: '2',
				},
			},
			bar: {
				svg: {
					style: {
						stroke: 'rgba(205, 224, 227, 1)',
						width: '2',
					},
				},
			},
			backdrop: {
				style: {
					backgroundColor: 'rgba(255, 255, 255, 0.9)',
				},
			},
		},
	},
};
