import React, { useEffect, useMemo, useState, useContext } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { format, startOfMonth, startOfISOWeek, startOfYear } from 'date-fns';

import DatalayerContext from '@asteria/services-datalayer/react/context';
import { Title, Text } from '@asteria/component-core/typography';
import Button from '@asteria/component-core/button';

import { TranslationService } from '@asteria/services-language';

import Modal, { ModalBody, ModalFooter } from '@asteria/component-modal';

import { formatNumber, useStore } from '@asteria/utils';
import Tooltip from '@asteria/component-tooltip';
import SecurityBar from '@asteria/component-list/components/securityBar';
import CurrenciesView from '@asteria/component-graph/controllers/currency';
import { getVolatilityText } from '@asteria/component-list/utils';
import { FeatureFlag, useFeature } from '@asteria/component-featureflag';

const startOfTime = (date, size) => {
	if (size === 'week') {
		return startOfISOWeek(date);
	}
	if (size === 'month') {
		return startOfMonth(date);
	}
	if (size === 'year') {
		return startOfYear(date);
	}

	return startOfMonth(date);
};

const Overview = styled(
	({
		className,
		name = TranslationService.get('cashflow.overview.title'),
		notification,
		statistics = {},
	}) => {
		const hasFxModule = useFeature({ key: 'fx-module' });
		const [showAdvanced, setShowAdvanced] = useState(false);
		const [showAdvancedBalance, setShowAdvancedBalance] = useState(false);

		const {
			deposit: {
				yearPeriod: {
					total: thisYearDepositTotal = 0,
					expected: {
						total: thisYearExpectedDepositTotal = 0,
						probability: thisYearExpectedDepositProbability = 1,
					},
				} = {},
			} = {},
			withdraw: {
				yearPeriod: {
					total: thisYearWithdrawTotal = 0,
					expected: {
						total: thisYearExpectedWithdrawTotal = 0,
						probability: thisYearExpectedWithdrawProbability = 1,
					},
				} = {},
			} = {},
			account: {
				total: currentAccountBalance = 0,
				expected: {
					total: currentExpectedAccountBalance = 0,
					probability: currentExpectedAccountBalanceProbability = 1,
				},
				yearPeriod: {
					expected: {
						total: thisYearExpectedAccountBalance = 0,
						probability: thisYearExpectedAccountBalanceProbability = 1,
					},
				} = {},
			} = {},
			currencies = [],
			invoices: {
				new: {
					total: newInvoicesTotal = 0,
					count: newInvoicesCount = 0,
				} = {},
				forecast: {
					total: forecastInvoicesTotal = 0,
					count: forecastInvoicesCount = 0,
					probability: forecastInvoicesProbability = 1,
				} = {},
				unpaid: {
					total: unpaidInvoicesTotal = 0,
					count: unpaidInvoicesCount = 0,
					probability: unpaidInvoicesProbability = 1,
				} = {},
				overdue: {
					total: overdueInvoicesTotal = 0,
					count: overdueInvoicesCount = 0,
				} = {},
			} = {},
		} = statistics;

		const totalTooltipContent = [];

		if (currentExpectedAccountBalance !== currentAccountBalance) {
			totalTooltipContent.push(
				<p key="tooltip-text">
					{TranslationService.get(
						['cashflow.overview.accountbalance.current.tooltip'],
						'',
						{
							statistics,
						},
					)}
				</p>,
			);
		}

		return (
			<div className={classNames(className, 'asteria-cashflow-overview')}>
				<div className="asteria-cashflow-overview-header">
					<div className="asteria-title-company">
						<FeatureFlag feature="fx-module">
							{currencies.length > 1 && (
								<Button
									size="small"
									type="link"
									icon={showAdvanced ? 'down' : 'right'}
									onClick={() =>
										setShowAdvanced(!showAdvanced)
									}
									className="asteria-button-toggle-company-overview"
								/>
							)}
						</FeatureFlag>
						<Title
							size="title2"
							className="asteria-title-company-name"
							onClick={() =>
								hasFxModule && setShowAdvanced(!showAdvanced)
							}
						>
							{name}
						</Title>
						{notification && (
							<Tooltip
								hover
								isStatic
								title=""
								content={notification}
							>
								<Button
									className="asteria-button-notification-icon"
									onClick={() =>
										hasFxModule &&
										setShowAdvanced(!showAdvanced)
									}
									type="link"
									icon="info"
								/>
							</Tooltip>
						)}
					</div>

					{/* lägg på classnamn om det är neg eller pos belopp så man kan styla :) */}

					{/* Plocka ut lite nyckeltal och länka för att labba bättre översikt */}

					{/* Här visa nuvarande kontobalans och räkna med obetalda, prognostiserade och alla valutor */}

					{/* <Text className="asteria-small" style={{ marginBottom: '0' }}>
						{TranslationService.get('cashflow.overview.all')}
					</Text> */}
					<Title
						size="title2"
						onClick={() =>
							hasFxModule && setShowAdvanced(!showAdvanced)
						}
						className={classNames('asteria-company-amount', {
							'asteria-state-negative':
								currentExpectedAccountBalance < 0,
						})}
					>
						<FeatureFlag feature="overview-header-account-forecast">
							<Tooltip
								hover
								isStatic
								style={{ maxWidth: '190px', minWidth: '0' }}
								content={
									totalTooltipContent.length > 0
										? totalTooltipContent
										: false
								}
							>
								<div className="asteria-overview-current-account-balance-title">
									<Text className="asteria-small">
										{TranslationService.get(
											'cashflow.overview.accountbalance.forecast.title',
											'Prognos Kontobalans',
										)}
									</Text>
									{formatNumber(currentAccountBalance)}
								</div>
							</Tooltip>
							<Tooltip
								className="asteria-tooltip-security"
								hover
								isStatic
								content={TranslationService.get(
									[
										'securitybar.tooltip',
										'cashflow.overview.all.probability',
									],
									'',
									{
										volatilityText: getVolatilityText(
											currentExpectedAccountBalanceProbability,
										),
										probability: currentExpectedAccountBalanceProbability,
									},
								)}
							>
								<SecurityBar
									value={
										currentExpectedAccountBalanceProbability
									}
									max={1}
									steps={5}
								/>
							</Tooltip>
						</FeatureFlag>
						<FeatureFlag feature="overview-header-account-current">
							<div className="asteria-overview-current-account-balance-title">
								<Text className="asteria-small">
									{TranslationService.get(
										'cashflow.overview.accountbalance.current.title',
										'Aktuell kontobalans',
									)}
								</Text>
								{formatNumber(currentAccountBalance)}
							</div>
						</FeatureFlag>

						{/* Tillgänglig + prognostiserad (dina egna + asteria + obetalda + valutor) kontobalans */}
					</Title>
				</div>

				{/* Tänker att nedan ska vara en accordion man kan väckla ut för mer information */}

				<Modal
					open={currencies.length > 1 && showAdvanced}
					onClose={() => setShowAdvanced(false)}
					className={classNames(
						'asteria-modal-cashflow-company-overview',
					)}
					title={TranslationService.get('company.overview.title')}
					portal
				>
					<ModalBody className="modal-body">
						<div className="asteria-cashflow-overview-details">
							<div
								className="asteria-cashflow-overview-details"
								style={{ display: 'none' }}
							>
								<div className="asteria-cashflow-overview-details-section-header">
									<Title size="title4">
										{TranslationService.get(
											'cashflow.overview.available',
										)}
									</Title>
								</div>

								<div className="asteria-cashflow-overview-details-section-content">
									<div className="asteria-cashflow-overview-details-section-content-column">
										<Text className="asteria-small">
											{TranslationService.get(
												'cashflow.overview.deposit.current.title',
											)}
										</Text>
										<Title size="title2">
											{/* Pengar in detta (år?) */}
											{formatNumber(thisYearDepositTotal)}
										</Title>
									</div>

									<div className="asteria-cashflow-overview-details-section-content-column">
										<Text className="asteria-small">
											{TranslationService.get(
												'cashflow.overview.withdraw.current.title',
											)}
										</Text>
										<Title size="title2">
											{/* Pengar ut detta (år?) */}
											{formatNumber(
												thisYearWithdrawTotal,
											)}
										</Title>
									</div>

									<div className="asteria-cashflow-overview-details-section-content-column">
										<Text className="asteria-small">
											{TranslationService.get(
												'cashflow.overview.accountbalance.current.title',
											)}
										</Text>
										<Title
											size="title2"
											className={classNames({
												'asteria-state-negative':
													currentAccountBalance < 0,
											})}
										>
											{/* Tillgänglig kontobalans */}
											{/* Hur kan man räkna bort aktiekapital / pengar som man behöver ha på kontot? Eller inkludera så man vet... i Sverige -25k */}
											{formatNumber(
												currentAccountBalance,
											)}
										</Title>
									</div>
								</div>

								<Button
									size="small"
									type="link"
									text="Visa avancerad kontobalans"
									icon={
										showAdvancedBalance ? 'down' : 'right'
									}
									onClick={() =>
										setShowAdvancedBalance(
											!showAdvancedBalance,
										)
									}
									className="asteria-button-toggle-company-account-balance-overview"
								/>

								{showAdvancedBalance ? (
									<>
										<div className="asteria-cashflow-overview-details-section-header">
											<Title size="title4">
												{TranslationService.get(
													'cashflow.overview.expected',
												)}
											</Title>
										</div>

										<div className="asteria-cashflow-overview-details-section-content">
											<div className="asteria-cashflow-overview-details-section-content-column">
												<Text className="asteria-small">
													{TranslationService.get(
														'cashflow.overview.withdraw.expected.title',
													)}
												</Text>
												<Title size="title2">
													{/* Pengar in detta (år?) */}
													{formatNumber(
														thisYearExpectedDepositTotal,
													)}
													<Tooltip
														className="asteria-tooltip-security"
														hover
														isStatic
														content={TranslationService.get(
															[
																'securitybar.tooltip',
																'cashflow.overview.all.probability',
															],
															'',
															{
																volatilityText: getVolatilityText(
																	thisYearExpectedDepositProbability,
																),
																probability: thisYearExpectedDepositProbability,
															},
														)}
													>
														<SecurityBar
															value={
																thisYearExpectedDepositProbability
															}
															max={1}
															steps={5}
														/>
													</Tooltip>
												</Title>
											</div>

											<div className="asteria-cashflow-overview-details-section-content-column">
												<Text className="asteria-small">
													{TranslationService.get(
														'cashflow.overview.deposit.expected.title',
													)}
												</Text>
												<Title size="title2">
													{/* Pengar ut detta (år?) */}
													{formatNumber(
														thisYearExpectedWithdrawTotal,
													)}
													<Tooltip
														className="asteria-tooltip-security"
														hover
														isStatic
														content={TranslationService.get(
															[
																'securitybar.tooltip',
																'cashflow.overview.all.probability',
															],
															'',
															{
																volatilityText: getVolatilityText(
																	thisYearExpectedWithdrawProbability,
																),
																probability: thisYearExpectedWithdrawProbability,
															},
														)}
													>
														<SecurityBar
															value={
																thisYearExpectedWithdrawProbability
															}
															max={1}
															steps={5}
														/>
													</Tooltip>
												</Title>
											</div>

											<div className="asteria-cashflow-overview-details-section-content-column">
												<Text className="asteria-small">
													{TranslationService.get(
														'cashflow.overview.accountbalance.expected.title',
													)}
												</Text>
												<Title
													size="title2"
													className={classNames({
														'asteria-state-negative':
															thisYearExpectedAccountBalance <
															0,
													})}
												>
													{/* Tillgänglig kontobalans */}
													{formatNumber(
														thisYearExpectedAccountBalance,
													)}
													<Tooltip
														className="asteria-tooltip-security"
														hover
														isStatic
														content={TranslationService.get(
															[
																'securitybar.tooltip',
																'cashflow.overview.all.probability',
															],
															'',
															{
																volatilityText: getVolatilityText(
																	thisYearExpectedAccountBalanceProbability,
																),
																probability: thisYearExpectedAccountBalanceProbability,
															},
														)}
													>
														<SecurityBar
															value={
																thisYearExpectedAccountBalanceProbability
															}
															max={1}
															steps={5}
														/>
													</Tooltip>
												</Title>
											</div>
										</div>

										<div className="asteria-cashflow-overview-details-section-header">
											<Title size="title4">
												{TranslationService.get(
													'cashflow.overview.transactions',
												)}
											</Title>
										</div>

										<div className="asteria-cashflow-overview-details-section-content asteria-cashflow-overview-details-section-content-transactions">
											<div className="asteria-cashflow-overview-details-section-content-column">
												<Text className="asteria-small">
													{TranslationService.get(
														'cashflow.overview.transactions.new',
													)}
												</Text>

												<Title
													size="title2"
													className={classNames({
														'asteria-state-negative':
															newInvoicesTotal <
															0,
													})}
												>
													{/* Antalet nya transaktioner (alla) och summan */}
													<span>{`${newInvoicesCount} st `}</span>
													{formatNumber(
														newInvoicesTotal,
													)}
												</Title>
											</div>

											<div className="asteria-cashflow-overview-details-section-content-column">
												<Text className="asteria-small">
													{TranslationService.get(
														'cashflow.overview.transactions.forecast',
													)}
												</Text>
												<Title
													size="title2"
													className={classNames({
														'asteria-state-negative':
															forecastInvoicesTotal <
															0,
													})}
												>
													{/* Antalet prognostiserade och summan */}
													<span>{`${forecastInvoicesCount} st `}</span>
													{formatNumber(
														forecastInvoicesTotal,
													)}
													<Tooltip
														className="asteria-tooltip-security"
														hover
														isStatic
														content={TranslationService.get(
															[
																'securitybar.tooltip',
																'cashflow.overview.all.probability',
															],
															'',
															{
																volatilityText: getVolatilityText(
																	forecastInvoicesProbability,
																),
																probability: forecastInvoicesProbability,
															},
														)}
													>
														<SecurityBar
															value={
																forecastInvoicesProbability
															}
															max={1}
															steps={5}
														/>
													</Tooltip>
												</Title>
											</div>

											<div className="asteria-cashflow-overview-details-section-content-column">
												<Text className="asteria-small">
													{TranslationService.get(
														'cashflow.overview.transactions.unpaid',
													)}
												</Text>
												<Title
													size="title2"
													className={classNames({
														'asteria-state-negative':
															unpaidInvoicesTotal <
															0,
													})}
												>
													{/* Antalet obetalda och summan */}
													<span>{`${unpaidInvoicesCount} st `}</span>
													{formatNumber(
														unpaidInvoicesTotal,
													)}
													<Tooltip
														className="asteria-tooltip-security"
														hover
														isStatic
														content={TranslationService.get(
															[
																'securitybar.tooltip',
																'cashflow.overview.all.probability',
															],
															'',
															{
																volatilityText: getVolatilityText(
																	unpaidInvoicesProbability,
																),
																probability: unpaidInvoicesProbability,
															},
														)}
													>
														<SecurityBar
															value={
																unpaidInvoicesProbability
															}
															max={1}
															steps={5}
														/>
													</Tooltip>
												</Title>
											</div>

											<div className="asteria-cashflow-overview-details-section-content-column">
												<Text className="asteria-small">
													{TranslationService.get(
														'cashflow.overview.transactions.overdue',
													)}
												</Text>

												<Title
													size="title2"
													className={classNames({
														'asteria-state-negative':
															overdueInvoicesTotal <
															0,
													})}
												>
													{/* Antalet förfallna och summan */}
													<span>{`${overdueInvoicesCount} st`}</span>
													{formatNumber(
														overdueInvoicesTotal,
													)}
												</Title>
											</div>

											{/* <Button
												size="small"
												type="link"
												className="asteria-button-see-all-transactions"
												// icon="calendar"
												// onClick={() => setShowAdvanced(true)}
											>
												{TranslationService.get(
													'cashflow.overview.transactions.link',
												)}
											</Button> */}
										</div>

										<div
											className="asteria-cashflow-overview-details-section-header"
											style={{ display: 'none' }}
										>
											<Title size="title4">
												{TranslationService.get(
													'cashflow.overview.assets.title',
												)}
											</Title>

											<Text className="asteria-small">
												{TranslationService.get(
													'cashflow.overview.assets.description',
												)}
											</Text>
										</div>

										<div
											className="asteria-cashflow-overview-details-section-content asteria-cashflow-overview-details-section-content-assets"
											style={{ display: 'none' }}
										>
											{currencies.map(
												({ code, total = 0 }) => (
													<div className="asteria-cashflow-overview-details-section-content-column">
														<Text className="asteria-small">
															{code}
														</Text>
														<Title
															size="title2"
															className={classNames(
																{
																	'asteria-state-negative':
																		total <
																		0,
																},
															)}
														>
															{formatNumber(
																total,
															)}
														</Title>
													</div>
												),
											)}
										</div>
									</>
								) : null}
							</div>
							<CurrenciesView setShow={setShowAdvanced} />
						</div>
					</ModalBody>
					<ModalFooter className="modal-footer">
						<Button
							size="medium"
							type="default"
							text={TranslationService.get(
								'button.close',
								'Stäng',
							)}
							onClick={() => setShowAdvanced(false)}
						/>
						{/* <Button
							disabled={!showSomething}
							size="medium"
							type="primary"
							text={TranslationService.get(
								'settings.button.save',
								'Spara',
							)}
							onClick={saveSettings}
						/> */}
					</ModalFooter>
				</Modal>

				{/* Här visa nuvarande kontobalans, vad fan har jag att röra mig med? */}

				{/* <SecurityBar value={2} max={1} steps={5} /> */}

				{/* Egentligen borde detta vara intressant att plocka upp dynamiskt för skatter o alla kategorier.. */}
				{/* Visa antalet: Kategorier: 13 */}
				{/* Kanske återanvända översikten för att visa för en specifik tidsenhet tex månad */}
			</div>
		);
	},
)`
	.asteria-cashflow-overview-details {
		.asteria-cashflow-overview-details-section-header {
			margin-top: 32px;
		}
		.asteria-cashflow-overview-details-section-content {
			margin-bottom: 16px;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 16px 16px;
			&.asteria-cashflow-overview-details-section-content-transactions {
				grid-template-columns: 1fr 1fr 1fr 1fr;
			}
			&.asteria-cashflow-overview-details-section-content-assets {
				grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
			}
			.asteria-cashflow-overview-details-section-content-column {
				padding: 0 0;
			}
			.asteria-button-see-all-transactions {
				grid-area: 3;
				padding: 0px !important;
				justify-content: flex-start;
				margin-bottom: 32px !important;
				margin-top: -16px !important;
			}
		}
	}
`;

const GET_COMPANY = `
query {
	company {
	  id
	  name
	  orgnumber
	}
  }
`;

const OverviewDataWrapper = () => {
	const { lookup } = useContext(DatalayerContext);
	const apiService = lookup('service-api');
	const [company, setCompany] = useState({});
	const showCompanyName = useFeature({ key: 'show-company-name' });

	useEffect(() => {
		apiService.query(GET_COMPANY, {}, { reqAuth: true }).subscribe(data => {
			setCompany(data?.data?.company || {});
		});
	}, [apiService]);

	const [open] = useStore(
		'store-appstate',
		({ openInvoices = {} }) => openInvoices,
	);
	const [overdue = 0] = useStore(
		'store-appstate',
		({ overdueInvoices = {} }) => overdueInvoices,
	);

	const [cashflowBarGraphGroups = {}] = useStore(
		'store-graph',
		({ 'cashflow-bar-graph': { barGroups = {} } = {} }) => barGroups,
	);

	const [timeSize = 'month'] = useStore(
		'store-list',
		({ size = 'month' }) => size,
	);

	const [notifications] = useStore(
		'store-notifications',
		({ notifications: list }) => list,
	);

	const { lines = [] } =
		cashflowBarGraphGroups?.[
			format(
				startOfTime(new Date(), timeSize),
				'YYYY-MM-DD[T00:00:00.000Z]',
			)
		] || {};

	const { value: accountValueHistory = 0 } =
		lines.find(({ types = [] }) => types.includes('history')) || {};

	const {
		value: accountValueForecast = accountValueHistory,
		probability = 1,
		max: accountMax = accountValueForecast,
		min: accountMin = accountValueForecast,
	} = lines.find(({ types = [] }) => types.includes('forecast')) || {};

	const data = useMemo(
		() => ({
			deposit: {
				yearPeriod: {
					total: 1234,
					expected: {
						total: 12936,
						probability: Math.random() * 0.75 + 0.25,
					},
				},
			},
			withdraw: {
				yearPeriod: {
					total: 123836292,
					expected: {
						total: 1782639,
						probability: Math.random() * 0.75 + 0.25,
					},
				},
			},
			account: {
				total: accountValueHistory,
				expected: {
					total: accountValueForecast,
					max: accountMax,
					min: accountMin,
					probability,
				},
				yearPeriod: {
					expected: {
						total: -18268,
						probability: Math.random() * 0.75 + 0.25,
					},
				},
			},
			currencies: [
				{
					code: 'SEK',
					total: 17268,
					count: 8,
				},
				{
					code: 'USD',
					total: 95964,
					count: 19,
				},
				{
					code: 'EUR',
					total: 2304689,
					count: 2836,
				},
			],
			invoices: {
				new: {
					total: 629273,
					count: 18,
				},
				forecast: {
					total: -182638,
					count: 87,
					probability: Math.random() * 0.75 + 0.25,
				},
				unpaid: {
					total: -17253,
					count: open.total,
					probability: Math.random() * 0.75 + 0.25,
				},
				overdue: {
					total: 128358,
					count: overdue.total,
				},
			},
		}),
		[
			accountMax,
			accountMin,
			accountValueForecast,
			accountValueHistory,
			open.total,
			overdue.total,
			probability,
		],
	);

	const notification = useMemo(
		() =>
			notifications.some(({ notificationKey = '' }) =>
				notificationKey?.includes('currency'),
			)
				? TranslationService.get(
						'drawer.currency.notification',
						'Ditt företag kan påverkas av valutasvängningar. Klicka här för att få en översikt över dina valutor.',
						{
							notifications: notifications.filter(
								({ notificationKey = '' }) =>
									notificationKey?.includes('currency'),
							),
						},
				  )
				: false,
		[notifications],
	);

	let name = TranslationService.get('cashflow.title', 'Företagskollen');

	if (showCompanyName && company?.name) {
		name = company?.name;
	}

	return (
		<Overview statistics={data} name={name} notification={notification} />
	);
};

export default OverviewDataWrapper;
