import React, { useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { compileStyles, StyleGetter } from '@asteria/utils';
import PropTypes from 'prop-types';
import Button from '@asteria/component-button';

import InputHelper from '@asteria/component-form/helper';

import Text from './basic/text';

const noop = () => {};
const isNoop = func => func === noop;

// Define real input components
const Input = styled.input``;

const Textarea = styled.textarea`
	${({ resize }) => `resize: ${resize}`}
`;

// Styler structure for Input component

/**
 * Text input component
 */
const TextInput = styled(
	React.forwardRef(
		(
			{
				elementType,
				className,
				resize,
				before,
				after,
				value: externalValue,
				placeholder,
				validation,
				size,
				disabled,
				onChange,
				onCheck = () => true,
				invertedcolor,
				type,
				helper,
				...props
			},
			ref,
		) => {
			const [value, setValue] = useState(externalValue);
			const [hasFocus, setHasFocus] = useState(false);

			useEffect(() => {
				setValue(externalValue);
			}, [externalValue]);

			// Use different dom elements depending on given type
			const InputComponent = useMemo(
				() => (elementType === 'textarea' ? Textarea : Input),
				[elementType],
			);

			return (
				<div
					className={classNames(
						className,
						'asteria-input-wrapper',
						value && `asteria-state`,
						elementType === 'textarea' ? 'asteria-textarea' : '',
						hasFocus ? 'asteria-state-focus' : '',
						disabled ? 'asteria-disabled' : '',
						validation ? `asteria-${validation}` : '',
						size ? `asteria-${size}` : '',
						invertedcolor ? `asteria-invertedcolor` : '',
					)}
				>
					{before}
					<InputComponent
						ref={ref}
						// Pass "resize" attribute only to textarea components
						resize={elementType === 'textarea' ? resize : undefined}
						className={classNames(
							'asteria-text-value',
							'asteria-input',
							!value ? 'asteria-text-placeholder' : '',
							invertedcolor ? `asteria-invertedcolor` : '',
						)}
						element={elementType}
						disabled={disabled}
						value={value}
						onChange={e => {
							const res = onCheck(e);
							if (res === true) {
								setValue(e.target.value);
								if (!isNoop(onChange)) {
									onChange(e);
								}
							} else {
								setValue(res);
								if (!isNoop(onChange)) {
									onChange({ target: { value: res } });
								}
							}
						}}
						type={type}
						onFocus={() => setHasFocus(true)}
						onBlur={() => setHasFocus(false)}
						placeholder={placeholder}
						{...props}
					/>

					{helper ? <InputHelper>{helper}</InputHelper> : null}

					{after}
				</div>
			);
		},
	),
)`
	display: flex;
	width: 250px;

	& > input,
	& > textarea {
		width: 100%;
		height: 100%;
		background-color: transparent;
		border: none;
		outline: none;
	}

	${Button} {
		align-self: center;
	}

	${({ theme }) => {
		const styles = compileStyles(TextInput, theme);

		return styles;
	}}
`;

TextInput.propTypes = {
	elementType: PropTypes.string,
	resize: PropTypes.string,
	before: PropTypes.element,
	after: PropTypes.element,
	styler: PropTypes.bool,
	placeholder: PropTypes.string,
	validation: PropTypes.string,
	size: PropTypes.string,
	disabled: PropTypes.bool,
	style: PropTypes.object,
	onChange: PropTypes.func,
	invertedcolor: PropTypes.bool,
	type: PropTypes.string,
};

TextInput.defaultProps = {
	elementType: 'input',
	resize: 'none',
	before: null,
	after: null,
	styler: false,
	value: '',
	placeholder: '',
	validation: '',
	size: '',
	disabled: false,
	style: {},
	onChange: noop,
	invertedcolor: false,
	type: undefined,
};

TextInput.Styler = {
	base: [StyleGetter(`formV2.input`, {})],
	children: [
		{
			component: Input,
			base: [StyleGetter(`value`, {})],
		},
		{
			component: Textarea,
			base: [StyleGetter(`value`, {})],
		},
		{
			component: InputHelper,
			base: [StyleGetter(`helper`, {})],
		},
		{
			component: Input,
			base: [StyleGetter(`placeholder`, {})],
			baseSelector: '&::placeholder, &::-webkit-input-placeholder',
		},
		{
			component: Input,
			base: [StyleGetter(`placeholder`, {})],
			baseSelector: '&:-ms-input-placeholder',
		},
		{
			component: Textarea,
			base: [StyleGetter(`placeholder`, {})],
			baseSelector: '&::placeholder, &::-webkit-input-placeholder',
		},
		{
			component: Textarea,
			base: [StyleGetter(`placeholder`, {})],
			baseSelector: '&:-ms-input-placeholder',
		},
		{
			component: Text,
			base: [StyleGetter(`value`, {})],
			baseSelector: '&.asteria-text-value',
		},
		{
			component: Text,
			base: [StyleGetter(`placeholder`, {})],
			baseSelector: '&.asteria-text-placeholder',
		},
	],
};

/**
 * Append theming sidebar to Input component
 */
export default TextInput;
