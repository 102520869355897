import React from 'react';
import styled from 'styled-components';

import NotificationItem from '../items';

const NotificationDropdownItem = styled(
	({ className, option = {}, setOpen = () => {} }) => (
		<div
			className={className}
			onClick={e =>
				e.stopPropagation() && e.nativeEvent.stopImmediatePropagation()
			}
			onKeyPress={e =>
				e.stopPropagation() && e.nativeEvent.stopImmediatePropagation()
			}
			role="button"
			tabIndex="-1"
		>
			<NotificationItem
				notification={option}
				onClick={() => setOpen(false)}
			/>
		</div>
	),
)`
	padding: 16px;
	margin: 0 16px;
	border-top: 1px solid rgb(244, 244, 244);

	&.asteria-forms-dropdown-item-first {
		border-top: 0px solid rgb(244, 244, 244);
	}

	&.asteria-forms-dropdown-item-last {
		margin-bottom: 16px;
	}

	&:hover,
	&.asteria-state-hover {
		/* background: rgb(242, 242, 242); */
		background: #f7f7f7;
	}
`;

export default NotificationDropdownItem;
