import React, { useCallback, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { GLOBAL } from '@asteria/env';
import List, {
	Header,
	Item,
	Row,
	RowActions,
} from '@asteria/component-core/list';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { TextV2, Title } from '@asteria/component-typography';
import Button, { ButtonV2 } from '@asteria/component-button';
import { TranslationService } from '@asteria/services-language';
import { fetchIntegrations } from '@asteria/services-datalayer/services/integrations/actions';
import { useStore } from '@asteria/utils';
import { LinearIndicator } from '@asteria/component-progressbar-indicator';
import Layout from '../../layout';
import Success from './success';

/**
 * Status Marker component
 */
const StatusMarker = styled(props => {
	const { className, status = undefined } = props;

	return (
		<span
			title={TranslationService.get(
				`erpList.status.${status ? 'connected' : 'error'}.dot`,
			)}
			className={classNames(
				className,
				`asteria-erps-item-status-${status}`,
			)}
		/>
	);
})`
	width: 10px;
	height: 10px;
	border-radius: 100%;
	margin-right: 8px;
	background-color: red;

	&.asteria-erps-item-status-approved {
		background-color: #a4ca73;
	}

	&.asteria-erps-item-status-processed {
		background-color: #a4ca73;
	}

	&.asteria-erps-item-status-pending {
		background-color: rgb(235, 231, 226);
	}

	&.asteria-erps-item-status-progress {
		background-color: #fdc129;
	}

	&.asteria-erps-item-status-canceled {
		background-color: #be121a;
	}
`;

StatusMarker.Styler = {
	typePrefix: 'asteria-erps-item-status',
};

const ConcentItem = styled(({ className, integration, action }) => {
	const { status, data: { resourceId, accountNumber = '' } = {} } = action;
	const { id } = integration;

	const sign = useCallback(() => {
		const url = GLOBAL.baseUrl
			.replace('api.', 'integrations.')
			.replace('prod.', '');

		const redirectUrl = `${url}api/integrations/swedbank/history/${id}/${resourceId}`;
		const win = window.open('', '_blank');
		win.location = redirectUrl;
		win.focus();
	}, [id, resourceId]);

	return (
		// Pelle
		<Item className={className}>
			<Row>
				<StatusMarker status={status.toLowerCase()} />
				<Title>
					{TranslationService.get(
						'onboarding.swedbank.concent.item.description',
						'Konto: {{accountNumber}}',
						{
							accountNumber,
						},
					)}
				</Title>
				{['PENDING', 'CANCELED'].includes(status) ? (
					<RowActions>
						<Button onClick={sign} size="medium" type="link">
							{TranslationService.get(
								'onboarding.swedbank.concent.item.accept',
								'Godkänn hämtning',
								{
									accountNumber,
								},
							)}
						</Button>
					</RowActions>
				) : null}
				{['PROGRESS', 'APPROVED'].includes(status) ? (
					<RowActions>
						<LinearIndicator />
					</RowActions>
				) : null}
			</Row>
		</Item>
	);
})`
	${Row} {
		justify-content: center;
		margin: 0;
	}
	${RowActions} {
		${LinearIndicator} {
			width: 150px;
		}
	}
`;

const Key = ({ navigator }) => {
	const timeoutBetweenChecks = 5000;
	const timer = useRef(null);
	const { dispatch } = useContext(DatalayerContext);
	const [erps = []] = useStore(
		'store-integrations',
		({ integrations = [] }) => integrations,
	);

	const [integrationId] = useStore(
		'store-integrations',
		({ integrationInProgress }) => integrationInProgress,
	);

	const integration = erps.find(int => int.id === integrationId);

	const nextStep = useCallback(() => {
		dispatch(() => ({
			action: 'CONNECTION_CLEANUP',
		}));

		navigator.navigateTo(Success.navigationKey, navigator);
	}, [dispatch, navigator]);

	const update = useCallback(() => {
		if (timer.current) {
			clearTimeout(timer.current);
			timer.current = null;
		}

		setTimeout(() => {
			dispatch(fetchIntegrations());
			update();
		}, timeoutBetweenChecks);
	}, [dispatch]);

	useEffect(() => {
		if (timer.current) {
			clearTimeout(timer.current);
			timer.current = null;
		}
		update();

		return () => {
			if (timer.current) {
				clearTimeout(timer.current);
				timer.current = null;
			}
		};
	}, [update]);

	return (
		<Layout
			type="swedbank-key"
			header={
				<Title
					className="asteria-title-onboarding-header"
					size="title2"
				>
					{TranslationService.get(
						[
							'onboarding.connect.header',
							'onboarding.connect.bank.header',
						],
						'2. Anslut till bokföringssystem',
					)}
				</Title>
			}
			body={
				<>
					<div
						style={{
							padding: '20px 20px 20px',
							backgroundColor: '#fbf2ea',
						}}
						className="asteria-dialog-box"
					>
						<TextV2 size="small">
							{TranslationService.get(
								'onboarding.swedbank.concent.title',
								'För att få en så bra kassaflödes analys som möjligt, så ber vi dig att godkänna hämtning av transaktioner från följande konton',
							)}
						</TextV2>
					</div>
					<List>
						<Header className="asteria-title-section">
							<Title>
								{`${TranslationService.get(
									'onboarding.swedbank.concent.list.title',
									'Godkänn hämtning av transaktioner',
								)}`}
							</Title>
						</Header>
						{integration?.actions?.map(item => (
							<ConcentItem
								integration={integration}
								action={item}
								key={item.id}
							/>
						))}
					</List>
					<div
						className="asteria-wizard-inner-footer"
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							paddingTop: '20px',
						}}
					>
						<div
							className="asteria--activation-key-controls"
							style={{
								display: 'flex',
								// minWidth: '220px',
								// justifyContent: 'space-between',
							}}
						>
							<ButtonV2
								size="medium"
								type="primary"
								style={{ marginLeft: '16px' }}
								text={TranslationService.get(
									'onboarding.continue',
									'Fortsätt',
								)}
								onClick={() => nextStep()}
							/>
						</div>
					</div>
				</>
			}
			footer={<></>}
		/>
	);
};

Key.navigationKey = Symbol('SwedbankConcent');

export default Key;
