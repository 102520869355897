import React, { useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import Button from '@asteria/component-button';
import { Text, Title } from '@asteria/component-typography';
import { Label, FormGroup, TextInput } from '@asteria/component-form/';
import { TranslationService } from '@asteria/services-language';
import FormColumn from './formColumn';
import FormRow from './formRow';

const RestorePass = styled(props => {
	const [hasSent, setHasSent] = useState(false);
	const { className, path, moveToStage, styler } = props;

	if (hasSent) {
		return (
			<div className={classNames(className, 'asteria-forgot-success')}>
				<Title size="3">
					{TranslationService.get('restorePass.sent.title', '')}
				</Title>
				<Text>
					{TranslationService.get('restorePass.sent.text', '')}
				</Text>
				<FormRow>
					<Button
						text={TranslationService.get(
							'lostPassword.backToLogin',
							'',
						)}
						size="regular"
						onClick={() => moveToStage('login')}
						type="link"
					/>
				</FormRow>
			</div>
		);
	}

	return (
		<div className={classNames(className, 'asteria-forgot-form')}>
			<form
				onSubmit={e => {
					e.preventDefault();
					if (!e.target.checkValidity()) {
						return;
					}
					setHasSent(true);
				}}
			>
				<FormColumn>
					<FormGroup>
						<Label>
							{TranslationService.get('lostPassword.email.label')}
						</Label>
						<TextInput
							placeholder={TranslationService.get(
								'lostPassword.email.placeholder',
								'',
							)}
							type="email"
							required
							overrideRoot="authentication"
							parentSelector="restorePass.container.body.formColumn.form"
							path="form.input.default"
							styler={styler}
						/>
					</FormGroup>
				</FormColumn>
				<FormRow>
					<Button
						text={TranslationService.get(
							'lostPassword.send.button',
							'',
						)}
						size="regular"
						type="primary"
						buttonType="submit"
					/>
				</FormRow>
				<FormRow
					parentSelector="restorePass"
					path={`${path}.formRow`}
					styler={styler}
				>
					<Button
						text={TranslationService.get(
							'lostPassword.alreadyRegistered.button',
							'',
						)}
						onClick={() => moveToStage('login')}
						size="regular"
						type="link"
						overrideRoot="authentication"
						parentSelector="restorePass.formRow.button"
						path="button.link.regular"
						styler={styler}
					/>
				</FormRow>
			</form>
		</div>
	);
})`
	&.asteria-forgot-form {
		form {
			padding: 0 !important;
		}
	}
`;

export default RestorePass;
