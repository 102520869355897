import { formatNumber } from '@asteria/utils';

const roundedToNextSignficant = (val, space = 1) => {
	if (val === 0) {
		return 0;
	}

	const digits = Math.floor(Math.log10(Math.abs(val))) - 1;
	const base =
		(Math.ceil(Math.abs(val) / 10 ** digits) + space) * 10 ** digits;
	return val < 0 ? -base : base;
};

// eslint-disable-next-line no-unused-vars
const roundedToPrevSignficant = val => {
	if (val === 0) {
		return 0;
	}
	/*
	const d = Math.floor(Math.log10(val < 0 ? -val : val));
	const pw = 1 - d;
	const mag = 10 ** pw;
	const shifted = Math.ceil(val * mag);

	return Math.round(shifted / mag);
	*/
	const digits = Math.floor(Math.log10(Math.abs(val))) - 1;
	const base = Math.floor(val / 10 ** digits) * 10 ** digits;
	return base;
};

const generateLabelRange = (
	start,
	steps,
	interval,
	{ labelPrefix = '' } = {},
) => {
	const labels = [];
	for (let i = 0; i < steps + 1; i += 1) {
		labels.push({
			value: start + interval * i,
			label: `${labelPrefix || ''}${formatNumber(
				start + interval * i,
				false,
				interval > 1000,
				!!labelPrefix,
				{ thousand: false },
			)}`,
		});
	}

	return labels;
};

const getLabels = (valMax, valMin, steps, prefix = '±') => {
	if (
		valMax === Number.MIN_VALUE ||
		valMax === Number.MAX_VALUE ||
		valMin === Number.MAX_VALUE
	) {
		return generateLabelRange(0, steps, 33000, { labelPrefix: prefix });
	}

	let rangeMin = valMin;
	let rangeMax = valMax;

	if (rangeMin === rangeMax) {
		rangeMin -= 1000;
		rangeMax += 2000;
	}

	rangeMax = roundedToNextSignficant(rangeMax);
	rangeMin = roundedToNextSignficant(rangeMin, -1);

	let range = Math.abs(rangeMax - rangeMin);

	if (range === 0) {
		range = rangeMax || rangeMin;
	}
	const rawInterval = range / steps;
	let interval = roundedToNextSignficant(rawInterval);

	if (10000 / steps > interval) {
		interval = Math.ceil(interval / 1000) * 1000;
	}

	let first =
		interval === 0.0 ? 0.0 : Math.floor(rangeMin / interval) * interval;

	let last = first + interval * steps;
	const labels = [];
	let itter = 0;
	while (last < rangeMax && itter < 1000) {
		interval = roundedToNextSignficant(rawInterval + rangeMax - last);
		first =
			interval === 0.0 ? 0.0 : Math.floor(rangeMin / interval) * interval;
		last = first + interval * steps;
		itter += 1;
	}

	if (interval !== 0) {
		return generateLabelRange(first, steps, interval, {
			labelPrefix: prefix,
		});
	}

	return labels;
};

const updateGraph = (graphId, graphData, state, barLayout = 'grouped') => {
	const {
		[graphId]: { range = [], barGroups = {}, centerZero = true } = {},
	} = state;

	let barsMaxVal = range.slice(1, -1).reduce((max, { id }) => {
		const { bars = [{ value: 0 }] } = barGroups[id] || {};
		return bars.reduce(
			(localMax, bar) => Math.max(localMax, bar.value || 0),
			max,
		);
	}, Number.MIN_VALUE);

	let barsMinVal = range.slice(1, -1).reduce((min, { id }) => {
		const { bars = [{ value: 0 }] } = barGroups[id] || {};
		return bars.reduce(
			(localMin, bar) => Math.min(localMin, bar.value || 0),
			min,
		);
	}, Number.MAX_VALUE);

	const linesMaxVal = range.reduce((max, { id }) => {
		const { lines = [{ value: 0 }] } = barGroups[id] || {};
		return lines.reduce(
			(localMax, line) =>
				Math.max(
					localMax,
					line.min || line.value,
					line.max || line.value,
					line.value,
				),
			max,
		);
	}, -Number.MAX_VALUE);

	const linesMinVal = range.reduce((min, { id }) => {
		const { lines = [{ value: 0 }] } = barGroups[id] || {};
		return lines.reduce(
			(localMin, line) =>
				Math.min(
					localMin,
					line.min || line.value,
					line.max || line.value,
					line.value,
				),
			min,
		);
	}, Number.MAX_VALUE);

	if (centerZero) {
		barsMaxVal = Math.max(Math.abs(barsMaxVal), Math.abs(barsMinVal));
		barsMinVal = barLayout === 'grouped' ? 0 : -barsMaxVal;
	}

	const newBarSteps = getLabels(barsMaxVal, barsMinVal, 3);

	const { value: barsMaxValue } = newBarSteps[newBarSteps.length - 1] || 0;
	const { value: barsMinValue } = newBarSteps[0] || 0;

	const newLineSteps = getLabels(linesMaxVal, linesMinVal, 3, false);

	const { value: linesMaxValue } = newLineSteps[newLineSteps.length - 1] || 0;
	const { value: linesMinValue } = newLineSteps[0] || 0;

	const graphSteps = getLabels(
		Math.max(Math.abs(barsMaxVal), Math.abs(linesMaxVal)),
		-Math.max(Math.abs(barsMinVal), Math.abs(linesMinVal)),
		barLayout === 'grouped' ? 3 : 4,
		false,
	);

	const { value: graphMaxValue } = graphSteps[graphSteps.length - 1] || 0;
	const { value: graphMinValue } = graphSteps[0] || 0;

	return {
		...state,
		[graphId]: {
			...graphData,
			barsMaxValue,
			barsMinValue,
			linesMaxValue,
			linesMinValue,
			graphMaxValue,
			graphMinValue,
			steps: graphSteps.reverse(),
			barsSteps: newBarSteps.reverse(),
			linesSteps: newLineSteps.reverse(),
		},
	};
};

const reducer = (
	state = {},
	{ action, graphId = 'cashflow-bar-graph', payload },
	{ rootStore },
) => {
	const { [graphId]: graphData = { id: graphId } } = state;
	switch (action) {
		case 'SET_LAYOUT': {
			const newState = updateGraph(
				graphId,
				graphData,
				state,
				payload?.graph?.barLayout,
			);
			return {
				...newState,
				[graphId]: {
					...(newState?.[graphId] || { id: graphId }),
					layout: payload?.graph || {},
				},
			};
		}
		case 'SET_AVAILABLE_CATEGORIES': {
			const categories = payload || [];
			for (let i = 0; i < categories.length; i += 1) {
				for (let j = 0; j < categories[i].tags.length; j += 1) {
					categories[i].tags[j].category = categories[i];
				}
			}
			return {
				...state,
				availableCategories: categories,
				availableTags: categories
					.reduce((acc, category) => acc.concat(category?.tags), [])
					.map(({ _id, name }) => ({
						id: _id,
						type: 'tag',
						config: { name },
					})),
			};
		}
		case 'FETCHING_GRAPH_DATA':
			return {
				...state,
				[graphId]: {
					...graphData,
					fetching: true,
				},
			};
		case 'FETCHED_GRAPH_DATA':
			return {
				...state,
				[graphId]: {
					...graphData,
					fetching: false,
				},
			};
		case 'SET_RANGE':
			return {
				...state,
				[graphId]: {
					...graphData,
					range: payload,
				},
			};
		case 'SET_ACTIVE_GROUPS':
			return {
				...state,
				[graphId]: {
					...graphData,
					activeGroups: payload,
				},
			};
		case 'SET_ACTIVE_BARS':
			return {
				...state,
				[graphId]: {
					...graphData,
					activeBars: payload,
				},
			};
		case 'SET_HOVER_GROUPS':
			return {
				...state,
				[graphId]: {
					...graphData,
					hoverGroups: payload,
				},
			};
		case 'SET_HOVER_BARS':
			return {
				...state,
				[graphId]: {
					...graphData,
					hoverBars: payload,
				},
			};
		case 'SET_HIDE_DEPOSIT':
			return {
				...state,
				[graphId]: {
					...graphData,
					hideDeposit: payload,
				},
			};
		case 'SET_HIDE_WITHDRAW':
			return {
				...state,
				[graphId]: {
					...graphData,
					hideWithdraw: payload,
				},
			};
		case 'SET_GRAPH_GROUP_WIDTH':
			return {
				...state,
				[graphId]: {
					...graphData,
					graphGroupWidth: payload,
				},
			};
		case 'CLEAR_GRAPH_DATA': {
			const { range = [], barGroups = {} } = graphData;
			const newGroups = {};
			range.slice(1, -1).forEach(({ id }) => {
				newGroups[id] = barGroups[id];
			});

			return {
				...state,
				[graphId]: {
					...graphData,
					barGroups: payload ? newGroups : {},
				},
			};
		}
		case 'SET_BAR_GROUP': {
			// const { id: groupId } = payload;

			// const newBarGroups = { ...barGroups };
			const newBarGroups = {};
			const items = Array.isArray(payload) ? payload : [payload];

			for (let i = 0; i < items.length; i += 1) {
				const { id: groupId } = items[i];
				newBarGroups[groupId] = items[i];
			}

			return {
				...state,
				[graphId]: {
					...graphData,
					barGroups: newBarGroups,
				},
			};
		}
		case 'UPDATE_BAR_GROUP': {
			const newBarGroups = {};
			const items = Array.isArray(payload) ? payload : [payload];

			const hasCredit = items.some(({ lines }) =>
				lines.find(({ types }) => types.includes('credit')),
			);

			for (let i = 0; i < items.length; i += 1) {
				const { id: groupId } = items[i];
				newBarGroups[groupId] = items[i];
			}

			return {
				...state,
				hasCredit,
				[graphId]: {
					...graphData,
					barGroups: {
						...graphData.barGroups,
						...newBarGroups,
					},
				},
			};
		}
		case 'SET_VISIBLE_GRAPH_TAGS': {
			return {
				...state,
				[graphId]: {
					...graphData,
					visibleTags: payload,
				},
			};
		}
		case 'SHOW_GRAPH_TAGS': {
			const { [graphId]: { visibleTags = [] } = {} } = state;
			return {
				...state,
				[graphId]: {
					...graphData,
					visibleTags: visibleTags.concat(
						payload.filter(item => !visibleTags.includes(item)),
					),
				},
			};
		}
		case 'HIDE_GRAPH_TAGS': {
			const { [graphId]: { visibleTags = [] } = {} } = state;
			return {
				...state,
				[graphId]: {
					...graphData,
					visibleTags: visibleTags
						.slice()
						.filter(item => !payload.includes(item)),
				},
			};
		}
		case 'TOGGLE_GRAPH_TAGS': {
			const { [graphId]: { visibleTags = [] } = {} } = state;
			return {
				...state,
				[graphId]: {
					...graphData,
					visibleTags: [
						...visibleTags.filter(p => !payload.includes(p)),
						...payload.filter(p => !visibleTags.includes(p)),
					],
				},
			};
		}
		case 'SET_VISIBLE_GRAPH_CATEGORIES': {
			return {
				...state,
				[graphId]: {
					...graphData,
					visibleCategories: payload,
				},
			};
		}
		case 'SHOW_GRAPH_CATEGORIES': {
			const { [graphId]: { visibleCategories = [] } = {} } = state;
			return {
				...state,
				[graphId]: {
					...graphData,
					visibleCategories: visibleCategories.concat(
						payload.filter(
							item => !visibleCategories.includes(item),
						),
					),
				},
			};
		}
		case 'HIDE_GRAPH_CATEGORIES': {
			const { [graphId]: { visibleCategories = [] } = {} } = state;
			return {
				...state,
				[graphId]: {
					...graphData,
					visibleCategories: visibleCategories
						.slice()
						.filter(item => !payload.includes(item)),
				},
			};
		}
		case 'TOGGLE_GRAPH_CATEGORIES': {
			const { [graphId]: { visibleCategories = [] } = {} } = state;
			return {
				...state,
				[graphId]: {
					...graphData,
					visibleCategories: [
						...visibleCategories.filter(p => !payload.includes(p)),
						...payload.filter(p => !visibleCategories.includes(p)),
					],
				},
			};
		}
		case 'SET_USER': {
			const {
				appstate: {
					layout: { graph: { barLayout = 'grouped' } = {} } = {},
				} = {},
			} = rootStore;
			return updateGraph(
				graphId,
				graphData,
				state,
				payload?.settings?.layout?.graph?.barLayout || barLayout,
			);
		}
		case 'UPDATE_GRAPH': {
			const {
				appstate: {
					layout: { graph: { barLayout = 'grouped' } = {} } = {},
				} = {},
			} = rootStore;
			return updateGraph(graphId, graphData, state, barLayout);
		}
		default:
			return state;
	}
};

export default reducer;
