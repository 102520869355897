import React, { useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import classNames from 'classnames';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import Button from '@asteria/component-core/button';
import Title from '@asteria/component-typography/title';
import { CSSTransition } from 'react-transition-group';
import {
	incrementSidepane,
	decrementSidepane,
} from '@asteria/services-datalayer/services/appstate/actions';
/* import Button from '@asteria/component-button/button'; */
const SidePane = styled(
	({
		className,
		open,
		title,
		onClose,
		footer = null,
		actions = [],
		children,
	}) => {
		const { dispatch } = useContext(DatalayerContext);
		const modalRoot = document.getElementById('asteria-drawers');

		useEffect(() => {
			if (open) {
				dispatch(incrementSidepane());

				return () => {
					dispatch(decrementSidepane());
				};
			}

			return () => {};
		}, [dispatch, open]);

		if (!modalRoot) {
			return null;
		}

		return (
			<div className="asteria-sidepane">
				{open
					? ReactDOM.createPortal(
							<div className={classNames(className)} />,
							modalRoot,
					  )
					: null}
				<CSSTransition
					timeout={200}
					classNames="animate-drawer"
					appear
					in={open}
					unmountOnExit
					mountOnEnter
				>
					<div className={classNames(className)}>
						<div className="asteria-sidepane-wrapper">
							<div className="asteria-sidepane-header">
								<Button
									type="link"
									className="asteria-button-close"
									size="small"
									icon="close"
									onClick={onClose}
								/>
								<Title size="title3">{title}</Title>
							</div>
							<div className="asteria-sidepane-body">
								<div className="asteria-sidepane-body-scroll">
									<div className="asteria-sidepane-body-inner">
										{children}
									</div>
								</div>
							</div>
							<div className="asteria-sidepane-footer">
								{footer}
								{actions.map(
									(
										{ type, action, title: buttonText },
										index,
									) => (
										<Button
											type={
												type ||
												(index === 1
													? 'primary'
													: 'secondary')
											}
											onClick={action}
										>
											{buttonText}
										</Button>
									),
								)}
							</div>
						</div>
					</div>
				</CSSTransition>
			</div>
		);
	},
)`
	/* width: 288px; */

	.asteria-sidepane {
		// z-index: 0;
	}

	.asteria-sidepane-wrapper {
		/* 'width: 288px;' */
		right: 0;
		position: fixed;
		top: 81px;
		bottom: 0;
		background: #fff;
		z-index: 20 !important;
		border-left: 1px solid #eae7e3;
		display: flex;
		flex-direction: column;
	}

	.asteria-sidepane-header {
		display: flex;
		flex: 1;
		height: 56px;
		max-height: 86px;
		margin-top: 0;
		background: #fff;
		padding: 16px;
		flex-direction: row;
		border-bottom: 1px solid #eae7e3;
		.asteria-button-close {
			height: 16px !important;
			width: 16px !important;
			padding: 0 !important;
			margin-bottom: 16px;
			margin-top: 4px;
			margin-right: 8px;
			.asteria-icon-wrapper {
				width: 22px;
				svg {
					fill: #99999e !important;
				}
			}
			&:hover {
				.asteria-icon-wrapper {
					svg {
						fill: #5b3737 !important;
					}
				}
			}
		}
	}

	.asteria-sidepane-body {
		display: flex;
		flex-direction: column;
		height: calc(100% - 160px);
		flex: 1;
		.asteria-sidepane-body-scroll {
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			background: #fbf2ea;
			// Fix for opening calendar, to allow overflow in X direction
			/* 			margin-left: -200px;
			padding-left: 200px; */
			transition: all 0.5s ease-in-out;

			&::-webkit-scrollbar {
				background-color: #fff;
				width: 16px;
			}

			&::-webkit-scrollbar-track {
				background-color: #faf0e7;
			}

			&::-webkit-scrollbar-thumb {
				background-color: #babac0;
				border-radius: 16px;
				border: 6px solid #fff;
			}
			&::-webkit-scrollbar-track-piece {
				background-color: #fff;
			}
		}

		.asteria-sidepane-body-inner {
			background: #fff;
			display: flex;
			flex-direction: column;
			padding: 0;
			flex: 1;
			height: 100%;
		}
	}

	.asteria-sidepane-footer {
		border-top: 1px solid #eae7e3;
		display: flex;
		align-items: center;
		background: #fff;
		margin-top: auto;
		padding: 16px;
		.asteria-button-default {
			margin-right: 8px;
		}
		.asteria-button-secondary {
			margin-left: auto;
		}
		.asteria-button-primary {
			margin-left: auto;
		}
		&:empty {
			display: none;
		}
	}
`;

export default SidePane;
