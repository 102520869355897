import React, { useState, useCallback, useContext, useEffect } from 'react';
import { TextV2, Title } from '@asteria/component-typography';
import { ButtonV2 } from '@asteria/component-button';
import { TranslationService } from '@asteria/services-language';
import DatalayerContext from '@asteria/services-datalayer/react/context';
import { initiateIntegration } from '@asteria/services-datalayer/services/integrations/actions';
import { useStore } from '@asteria/utils';
import Layout from '../../layout';
import Context from '../../context';

const Key = ({ navigator, callback = () => {} }) => {
	const { setStepIndex } = useContext(Context);

	useEffect(() => {
		setStepIndex(2);
		callback('goto', {
			event: 'goto',
			integration: 'procountor',
			integrationTitle: 'Procountor',
			step: {
				stepId: 'procountorActivation',
				title: 'Aktivera',
			},
			steps: [
				{ stepId: 'procountorActivation', title: 'Aktivera' },
				{
					stepId: 'procountorConnecting',
					title: 'Väntar på anslutning',
				},
			],
		});
	}, [callback, setStepIndex]);

	const { dispatch } = useContext(DatalayerContext);
	const [erps = []] = useStore(
		'store-integrations',
		({ integrations = [] }) => integrations,
	);
	const [integrationId] = useStore(
		'store-integrations',
		({ integrationInProgress }) => integrationInProgress,
	);

	const [errors, setErrors] = useState([]);

	useEffect(() => {
		if (integrationId) {
			const integration = erps.find(int => int.id === integrationId);
			if (integration) {
				setErrors([...errors, ...integration.config.errors]);
				dispatch(() => ({
					action: 'CONNECTION_CLEANUP',
				}));
			}
		}
	}, [dispatch, erps, errors, integrationId]);

	const nextStep = useCallback(() => {
		dispatch(
			initiateIntegration({
				integrationName: 'procountor',
			}),
		);
		navigator.next(null, navigator);
	}, [dispatch, navigator]);

	return (
		<Layout
			type="procountor-key"
			header={
				<Title
					className="asteria-title-onboarding-header"
					size="title2"
				>
					{TranslationService.get(
						'onboarding.connect.header',
						'2. Anslut till bokföringssystem',
					)}
				</Title>
			}
			body={
				<>
					<div
						className="asteria-dialog-box"
						style={{
							padding: '20px 20px 20px',
							backgroundColor: '#fbf2ea',
						}}
					>
						<Title size="title4">Procountor</Title>
						<TextV2 size="small">
							{TranslationService.get(
								'onboarding.procountor.api_form.title',
								'För att aktivera kopplingen behöver du logga in hos Procountor och godkänna att Asteria AB för tillgång till er bokföring',
							)}
						</TextV2>
						{/* <ButtonV2
							size="small"
							style={{ paddingLeft: '0' }}
							type="link"
							text={TranslationService.get(
								'onboarding.procountor.url',
								'Logga in hos Procountor',
							)}
							onClick={() => nextStep()}
						/> */}
					</div>
					<div
						className="asteria-wizard-inner-footer"
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							paddingTop: '20px',
						}}
					>
						<ButtonV2
							size="medium"
							type="default"
							onClick={() =>
								navigator.cancel(
									{
										event: 'cancel',
									},
									navigator,
								)
							}
							text={TranslationService.get(
								'onboarding.abort',
								'Avbryt',
							)}
						/>
						<div
							className="asteria--activation-key-controls"
							style={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<ButtonV2
								size="medium"
								type="secondary"
								onClick={() =>
									navigator.back(
										{
											event: 'goto',
											step: {
												stepId: 'selectErp',
											},
										},
										navigator,
									)
								}
								text={TranslationService.get(
									'onboarding.back',
									'Tillbaka',
								)}
							/>
							<ButtonV2
								size="medium"
								type="primary"
								style={{ marginLeft: '16px' }}
								text={TranslationService.get(
									'onboarding.procountor.url',
									'Logga in hos Procountor',
								)}
								onClick={() => nextStep()}
							/>
						</div>
					</div>
				</>
			}
			footer={<></>}
		/>
	);
};

Key.navigationKey = Symbol('AsteriaKey');

export default Key;
