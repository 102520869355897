import React, {
	useState,
	useCallback,
	useEffect,
	useContext,
	useRef,
} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import DatalayerContext from '@asteria/services-datalayer/react/context';
import Modal, { ModalBody, ModalFooter } from '@asteria/component-modal';
import Button from '@asteria/component-core/button';
import { TranslationService, Translation } from '@asteria/services-language';
import { Title, Text } from '@asteria/component-core';
import { useStore } from '@asteria/utils';
import Alert from '@asteria/component-alert';
import {
	saveUser,
	setUserSettings,
} from '@asteria/services-datalayer/services/auth/actions';
import {
	FormGroupV2,
	CheckboxGroupV2,
	Dropdown,
} from '@asteria/component-form';
import { FeatureFlag } from '@asteria/component-featureflag';

const ListModes = [
	{
		label: 'list.settings.dropdown.clients',
		value: 'clients',
	},
	{
		label: 'list.settings.dropdown.transactions',
		value: 'transactions',
	},
];

const Settings = styled(({ className, show, setShow }) => {
	const { dispatch } = useContext(DatalayerContext);
	const originalValue = useRef(null);
	const originalListModeValue = useRef(null);
	const [compressList, setCompressList] = useState(false);
	const [listMode, setListMode] = useState(false);
	const [user] = useStore('store-auth', ({ user: obj }) => obj);

	useEffect(() => {
		const { settings: { compressList: setting = false } = {} } = user;
		setCompressList(setting);
		setListMode(user?.settings?.layout?.list?.view || 'transactions');

		if (originalValue.current === null) {
			originalValue.current = setting;
			originalListModeValue.current =
				user?.settings?.layout?.list?.view || 'transactions';
		}
	}, [user]);

	const close = useCallback(() => {
		originalValue.current = null;
		originalListModeValue.current = null;
		setShow(false);
	}, [setShow]);

	const cancel = useCallback(() => {
		dispatch(
			setUserSettings({
				...user.settings,
				compressList: originalValue.current,
				layout: {
					...(user?.settings?.layout || {}),
					list: {
						...(user?.settings?.layout?.list || {}),
						view: originalListModeValue.current,
					},
				},
			}),
		);
		setShow(false);
	}, [dispatch, setShow, user]);

	const save = useCallback(() => {
		dispatch(
			saveUser({
				...user,
				settings: {
					...user.settings,
					compressList,
					layout: {
						...(user?.settings?.layout || {}),
						list: {
							...(user?.settings?.layout?.list || {}),
							view: listMode,
						},
					},
				},
			}),
		);

		close();
	}, [close, compressList, dispatch, listMode, user]);

	return (
		<Modal
			className={classNames(className)}
			onClose={() => cancel()}
			open={show}
			title={TranslationService.get('list.settings.title')}
		>
			<ModalBody>
				<Title
					size="title2"
					className="asteria-title-settings-transactionlist"
				>
					<Translation
						translationKey="settings.transactionlist"
						defaultText="Transaktionslista"
					/>
				</Title>
				<FeatureFlag feature="transactions-list-view-settings">
					<FormGroupV2 className="asteria-formgroup-list-view">
						<Text>
							<Translation
								translationKey="list.settings.dropdown.label"
								defaultText="Visa lista som"
							/>
						</Text>
						<Dropdown
							size="medium"
							type="link"
							showSelected
							iconClosed="triangleDown"
							iconOpen="triangleUp"
							itemIconSelected="check"
							className="asteria-dropdown-list-view"
							selected={[
								ListModes.find(
									({ value: v }) => v === listMode,
								),
							]}
							options={ListModes}
							onChange={({ value: selectedValue }) => {
								setListMode(selectedValue);
								dispatch(
									setUserSettings({
										...user.settings,
										layout: {
											...(user?.settings?.layout || {}),
											list: {
												...(user?.settings?.layout
													?.list || {}),
												view: selectedValue,
											},
										},
									}),
								);
							}}
						/>
					</FormGroupV2>
				</FeatureFlag>
				<FormGroupV2>
					<CheckboxGroupV2
						className="asteria-checkbox-group-wrapper-transactions-small"
						items={[
							TranslationService.get(
								'settings.compressList.title',
								'Komprimerade tabellrader för ökad skärmyta',
							),
						]}
						onChange={() => {
							setCompressList(!compressList);
							dispatch(
								setUserSettings({
									...user.settings,
									compressList: !compressList,
								}),
							);
						}}
						selected={compressList ? [0] : []}
					/>
				</FormGroupV2>
			</ModalBody>
			<ModalFooter className="modal-footer">
				<Button
					size="medium"
					type="default"
					text={TranslationService.get(
						'settings.button.cancel',
						'Avbryt',
					)}
					onClick={cancel}
				/>
				<Button
					size="medium"
					type="primary"
					text={TranslationService.get(
						'settings.button.save',
						'Spara',
					)}
					onClick={save}
				/>
			</ModalFooter>
		</Modal>
	);
})`
	.asteria-formgroup-list-view {
		.asteria-text {
			margin: 0 0 8px;
		}
		background-color: #faf2eb;
		padding: 16px 16px 16px !important;
		margin-top: 16px;
		margin-bottom: 24px;
	}
	.asteria-dropdown-list-view {
		max-width: 100% !important;
		.asteria-button {
			flex-direction: inherit;
			background-color: #fff;
			border-radius: 0px;
			border-bottom: 2px solid rgba(212, 196, 188, 1) !important;
			padding-left: 8px;
			min-height: 38px;
			height: 48px;
			width: 100%;
			.asteria-text {
				font-size: 16px !important;
			}
			.asteria-icon-wrapper {
				width: 24px !important;
				height: 8px !important;
				margin-left: auto !important;
				svg {
					fill: #53a1ac !important;
				}
			}

			.asteria-forms-dropdown-menu-direction-down {
				// width: calc(100% - 32px) !important;
			}
		}
	}

	.modal-footer {
		.asteria-button-default {
			margin-right: auto;
		}
	}

	${Alert} {
		margin-bottom: 16px;
	}
`;

export default Settings;
