import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { format, isValid, isBefore } from 'date-fns';
import Button from '@asteria/component-button/button';

import Input from '../input';

const PositionDiv = styled.div`
	position: absolute;
	top: 7px;
	border: none;
	left: 10px;
	height: 30px;
	border-radius: 20px;
	width: 80px;
	background-color: #fcc127;
`;

const PositionDiv2 = styled.div`
	position: absolute;
	top: 7px;
	border: none;
	left: 95px;
	height: 30px;
	border-radius: 20px;
	width: 80px;
	background-color: #fcc127;
`;

const InputDiv = styled.div`
	font-family: Swedbank Sans;
	padding-top: 6px;
	padding-left: 7px;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
`;

const CalendarInput = styled(
	({
		className,
		id,
		toggle,
		active,
		isOpen,
		min,
		multi,
		extend,
		onChange = () => {},
	}) => {
		let value = '';
		if (extend) {
			value = `${format(active.start, 'YYYY-MM-DD')} - ${format(
				active.end,
				'YYYY-MM-DD',
			)}`;
		} else if (multi) {
			value = active.start.map(act => format(act, 'YYYY-MM-DD'));
		} else {
			value = format(active.start, 'YYYY-MM-DD');
		}
		return (
			<div className={className}>
				{!multi ? (
					<Input
						placeholder="YYYY-MM-DD"
						type="text"
						value={value}
						onCheck={e => {
							const date = new Date(e.target.value);
							if (isValid(date) && e.target.value.length === 10) {
								if (!min || !isBefore(date, min)) {
									return true;
								}
								return format(min, 'YYYY-MM-DD');
							}

							return true;
						}}
						onChange={e => {
							const date = new Date(e.target.value);
							if (isValid(date) && e.target.value.length === 10) {
								if (!min || !isBefore(date, min)) {
									onChange(date, id);
								} else {
									onChange(min, id);
								}
							}
						}}
					/>
				) : (
					<>
						<Input
							type="text"
							onChange={e => {
								const date = new Date(e.target.value);
								if (isValid(date)) {
									if (!min || !isBefore(date, min)) {
										onChange(date, id);
									} else {
										onChange(min, id);
									}
								}
							}}
						/>
						<PositionDiv>
							<InputDiv>{value[0]}</InputDiv>
						</PositionDiv>
					</>
				)}
				{active.start.length >= 2 ? (
					<PositionDiv2>
						<InputDiv>{value[1]}</InputDiv>
					</PositionDiv2>
				) : null}
				<Button
					type="link"
					icon="calendar"
					onClick={toggle}
					className={classNames({
						'asteria-state-active': isOpen,
					})}
				/>
			</div>
		);
	},
)`
	position: relative;
	${Button} {
		position: absolute;
		right: 12px;
		top: 11px;
	}
`;

export default CalendarInput;
