import React, {
	useContext,
	useCallback,
	useState,
	useMemo,
	useEffect,
	useRef,
} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import DatalayerContext from '@asteria/services-datalayer/react/context';
import { TranslationService } from '@asteria/services-language';

import {
	setListOpen,
	addFilters,
	setSearchFocus,
} from '@asteria/services-datalayer/services/appstate/actions';
import { useStore, Getter, domPath } from '@asteria/utils';
import { FeatureFlag } from '@asteria/component-featureflag';
import DetailedBar from '@asteria/component-list/components/detailedBar';
import Text from '@asteria/component-typography/text';
import Title from '@asteria/component-typography/title';

import Accordion from '@asteria/component-core/accordion';
import Pie from '@asteria/component-graph/pie';
import Drawer from '@asteria/component-graph/controllers/drawer';

import { fetchCategories } from '@asteria/services-datalayer/services/graph/actions';
import SearchContext from './context';
import ActiveFilters from './active';
import filterAction from './filterAction';
import Prefix from './prefix';
import ShowAccountBalanceOnFilterSetting from './showAccountBalance';
import SearchArea from './area';

import CategoriesSelector from './selectors/categories';
import CurrencySelector from './selectors/currency';
import StatusSelector from './selectors/status';
import FinancialSelector from './selectors/financial';
import ClientSelector from './selectors/client';
import SearchResults from './results';

const findCustomer = ({ clients = [] }, clientId) =>
	(clients || []).find(c => c.clientId === clientId);

const GET_CURRENCIES = `
query Exposure($filters: TransactionFiltersInput){
	transactionCurrencies(filters: $filters) {
	  currency
	  deposit {
		original {
		  total
		  currency
		}
		display {
		  total
		  currency
		}
		exposure {
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		tags {
		  tag {
			id
			name
		  }
		  type
		  parts {
			status
			count
			original {
			  total
			  currency
			}
			display {
			  total
			  currency
			}
		  }
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		count
	  }
  
	  withdraw {
		original {
		  total
		  currency
		}
		display {
		  total
		  currency
		}
		exposure {
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		tags {
		  tag {
			id
			name
		  }
		  type
		  parts {
			status
			count
			original {
			  total
			  currency
			}
			display {
			  total
			  currency
			}
		  }
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		count
	  }
  
	  exposure {
		original {
		  total
		  currency
		}
		display {
		  total
		  currency
		}
	  }

	  risk {
		  original {
			  total
			  currency
		  }
		  display {
			  total
			  currency
		  }
		  risk
		  alert
	  }
	}
  }
  
`;

const Search = styled(({ className }) => {
	const {
		isFocus,
		isShowDrawer,
		setSearch,
		setMatchedParts,
		setFocus,
		setShowDrawer,
		setActiveCustomer,
		setHideResults,
	} = useContext(SearchContext);

	const selectsOpen = useRef([]);
	const [drawerTab, setDrawerTab] = useState(['overview']);

	const onChange = useCallback(() => {
		setSearch('');
		setMatchedParts([]);
		setFocus(false);
		setShowDrawer(false);
	}, [setFocus, setMatchedParts, setSearch, setShowDrawer]);

	const onSelectStateChange = useCallback(
		(state, id) => {
			if (state) {
				selectsOpen.current = [
					...selectsOpen.current.filter(val => val !== id),
					id,
				];
			} else {
				selectsOpen.current = [
					...selectsOpen.current.filter(val => val !== id),
				];
			}

			if (selectsOpen.current.length === 0) {
				setHideResults(false);
			} else {
				setHideResults(true);
			}
		},
		[setHideResults, selectsOpen],
	);

	return (
		<div
			className={classNames(className, 'asteria-controller-search', {
				'asteria-state-focus': isFocus,
				'asteria-navigation-active': isShowDrawer,
			})}
		>
			<SearchArea />
			<FeatureFlag feature="asteria-improvements">
				<FeatureFlag feature="asteria-improvements-show-categories">
					<CategoriesSelector
						onChange={onChange}
						onState={onSelectStateChange}
					/>
				</FeatureFlag>
				<FeatureFlag feature="asteria-improvements-show-currency">
					<CurrencySelector
						onChange={onChange}
						onState={onSelectStateChange}
					/>
				</FeatureFlag>
				<FeatureFlag feature="asteria-improvements-show-client">
					<ClientSelector
						onChange={onChange}
						onState={onSelectStateChange}
					/>
				</FeatureFlag>
				<FeatureFlag feature="asteria-improvements-show-status">
					<StatusSelector
						onChange={onChange}
						onState={onSelectStateChange}
					/>
				</FeatureFlag>
				<FeatureFlag feature="asteria-improvements-show-financial">
					<FinancialSelector
						onChange={onChange}
						onState={onSelectStateChange}
					/>
				</FeatureFlag>
			</FeatureFlag>
			<FeatureFlag feature="asteria-improvements" invert>
				{isShowDrawer ? (
					<div className="asteria-filter-nav">
						<Drawer
							tab={drawerTab}
							setTab={setDrawerTab}
							setShow={setShowDrawer}
						/>
					</div>
				) : null}
				<ActiveFilters setActiveCustomer={setActiveCustomer} />
			</FeatureFlag>
			<SearchResults />
			<ShowAccountBalanceOnFilterSetting />
		</div>
	);
})`
	width: 100%;
	position: relative;
	margin-right: 16px;
	margin-left: 0px;

	.asteria-search-area {
		.asteria-badge {
			position: absolute;
			left: 32px;
			top: 25px;
			z-index: 2;
			border: 0px solid transparent;
			background: #c01f26;
			justify-content: center;
			align-items: center;
			border-radius: 50px;
			color: #ffffff;
			font-family: 'Swedbank Sans';
			font-weight: 700;
			font-size: 14px;
			width: 24px;
			height: 24px;
			white-space: nowrap;
			line-height: 100%;
			overflow: hidden;
			display: flex;
			text-overflow: ellipsis;
			margin-right: -16px;
			margin-left: 4px;
			margin-top: -24px;
		}
	}

	&:before {
		content: '';
		position: absolute;
		top: 48px;
		right: 0px;
		z-index: 3;
		left: 48px;
		background: #d1c4bd;
		height: 2px;
		@media (max-width: 1024px) {
			right: 12px;
		}
	}

	&.asteria-navigation-active {
		.asteria-search-area {
			// border: 1px solid #eaeaea;
		}
	}

	&.asteria-state-focus {
		.asteria-search-area {
			/* border-bottom: 2px solid rgb(49, 163, 174); */
			&:before {
				content: '';
				position: absolute;
				top: 48px;
				right: 0px;
				z-index: 3;
				left: 48px;
				background: rgb(49, 163, 174);
				height: 2px;
				@media (max-width: 1024px) {
					right: 12px;
				}
			}
			// border: 1px solid #eaeaea;
		}

		.asteria-filter-nav {
			left: 0px;
		}

		.asteria-search-area div {
		}

		.asteria-search-area button div,
		.asteria-controller-search-active-filters li div[role='button'] {
			border-bottom: 0px solid transparent;
		}
	}

	.asteria-search-area div.asteria-icon-search {
		height: 24px !important;
		width: 24px !important;
	}

	.asteria-controller-search-active-filters {
		margin-top: 8px;
	}
	.asteria-feature-status {
		.asteria-pie-tag-forecast {
			${Text} {
				font-size: 12px;
			}

			margin-left: 20px;
			align-items: center;
		}

		.asteria-pie-tag-unpaid {
			${Text} {
				font-size: 12px;
			}

			margin-left: 20px;
			align-items: center;
		}
	}

	.asteria-section-pie {
		display: flex;
		width: 100%;
		justify-content: center;
		margin-top: 20px;
		padding: 0px 0 30px;

		.asteria-graph-pie {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 50%;
				background: #fff;
				width: 4px;
				height: 100%;
			}
		}

		@media (max-width: 1200px) {
			flex-direction: column;
		}

		.asteria-pie-tag {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			border: 1px solid transparent;
			border-radius: 30px;
			padding: 4px 8px 4px 4px;
			&:hover {
				border: 1px solid #eae7e3;
				background: #eae7e3;
			}
			&.active {
				border: 1px solid #eae7e3;
				background: #eae7e3;
			}
			span {
				margin-top: 0;
			}
		}

		.asteria-pie-tag-paid span,
		.asteria-pie-tag-unpaid span,
		.asteria-pie-tag-forecast span {
			min-width: 8px;
			@media (max-width: 1279px) {
				min-width: 8px;
				width: 8px;
				height: 8px;
			}
		}

		.asteria-text-tag {
			flex-grow: 1;
			padding-right: 10px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: var(--text-color);
			font-family: Swedbank Sans;
			font-weight: 700;
			font-size: 14px;
			line-height: normal;
			@media (max-width: 1279px) {
				font-size: 12px;
			}
		}

		.asteria-text-total {
			white-space: nowrap;
			@media (max-width: 1279px) {
				font-size: 12px;
			}
		}

		&.asteria-section-pie-hover {
			li:not(.asteria-tags-list-header) {
				opacity: 0.6;
				&.asteria-state-hover {
					opacity: 1;
					/* 					border: 1px solid #eae7e3;
					background: #eae7e3; */
				}
			}

			${Pie} {
				.asteria-pie-segment {
					opacity: 0.3;

					&::hover {
						opacity: 1;
					}

					&.asteria-state-hover {
						opacity: 1;
					}
				}
			}
		}

		${Pie} {
			margin: 0px 16px;
			width: 150px;
			height: 150px;
			${Title} {
				&.asteria-pie-title {
					margin: 0;
					padding: 0;
					flex-grow: 0;
					text-align: center;
					width: 100%;
					display: block !important;
					margin-top: 0 !important;

					@media (max-width: 1279px) {
						font-size: 14px;
					}
				}
			}

			@media (max-width: 1200px) {
				display: none;
			}

			@media (max-width: 1280px) {
				margin: 0 16px;
			}

			@media (max-width: 1279px) {
				width: 150px;
				height: 150px;
			}

			@media (min-width: 1400px) {
				width: 170px;
				height: 170px;
			}
		}

		.asteria-tags-list-header {
			display: flex;
			align-items: center;

			${Title} {
				&.asteria-title-tags {
					margin: 0;
					padding: 0;
					text-align: left;
					cursor: pointer;
					display: block !important;
					margin-top: 0 !important;
					@media (max-width: 1279px) {
						font-size: 14px;
					}
				}
			}
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0 16px;
			max-width: 250px;
			width: 100%;

			@media (max-width: 1200px) {
				max-width: calc(100% - 20px);
			}

			@media (max-width: 1279px) {
				flex-direction: column;
				padding: 0 16px;
				margin: 0 0 16px;
			}

			li {
				display: flex;
				margin: 0;
				padding: 4px 0;
				min-width: 190px;
				cursor: pointer;

				@media (max-width: 1024px) {
					min-width: 0px;
					padding: 12px 0;
				}
			}
		}
	}

	.asteria-search-area {
		display: flex;
		justify-content: flex-start;
		background-color: #fff;
		/* border-bottom: 2px solid rgba(212, 196, 188, 1); */
		// border: 1px solid #eaeaea;
		border: 1px solid transparent;
		// border-radius: 3px;
		height: 47px;
		padding-left: 78px;
		/* margin-right: 90px; */

		@media (max-width: 1024px) {
			margin-right: 0;
			width: calc(100% - 90px);
		}

		.asteria-search-area-wrapper {
			max-width: 781px;
			overflow-x: auto;
			overflow-y: hidden;
			flex-wrap: nowrap;
			width: 100%;
			display: flex;
			height: 45px;

			overflow: -moz-scrollbars-none;
			scrollbar-width: none;
			-ms-overflow-style: none;
			scrollbar-width: none;
			-ms-overflow-style: none;
			-webkit-mask: none;

			/* -webkit-mask: -webkit-gradient(
				linear,
				left top,
				right top,
				color-stop(0, black),
				color-stop(0.45, black),
				color-stop(0.7, black),
				color-stop(0.85, black),
				color-stop(1, rgba(0, 0, 0, 0))
			);

			mask: -webkit-gradient(
				linear,
				left top,
				right top,
				color-stop(0, black),
				color-stop(0.45, black),
				color-stop(0.7, black),
				color-stop(0.85, black),
				color-stop(1, rgba(0, 0, 0, 0))
			); */

			&::-webkit-scrollbar {
				height: 0 !important;
			}

			/* 			&:after {
				content: '&nbsp;';
				display: block;
				width: 100px;
				background: transparent;
				height: 100%;
				color: transparent;
				order: 99;
			} */

			.asteria-text-placeholder {
				font-size: 16px;
				@media (max-width: 1024px) {
					font-size: 13px;
					padding-right: 43px;
				}
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.asteria-input {
				// font-size: 16px;
				@media (max-width: 1024px) {
					font-size: 13px;
					padding-right: 43px;
				}
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				font-size: 18px;
				padding-left: 18px;
			}
		}

		.asteria-button-close {
			position: absolute;
			right: 16px;
			top: 25px;
			transform: translateY(-50%);
			height: 22px;
			width: 22px;
			@media (max-width: 1024px) {
				right: 24px;
				top: 23px;
			}
		}

		.asteria-button-close svg {
			fill: #99999e !important;
		}

		.asteria-button-close:hover svg {
			fill: #5b3737 !important;
		}

		.asteria-button-navigation {
			position: absolute;
			left: 0;
			top: 24px;
			transform: translateY(-50%);
			height: 48px;
			width: 48px;
			border-radius: 0px;
			padding: 15px;
			// border-right: 1px solid #eaeaea;
		}

		.asteria-button-navigation svg {
			fill: #ee7024 !important;
		}

		.asteria-button-navigation:hover svg {
			fill: #ee7024 !important;
		}

		.asteria-button-navigation.asteria-navigation-active {
			/* 			border-bottom: 2px solid rgb(49,163,174); */
			&:after {
				content: '';
				position: absolute;
				top: 48px;
				width: 48px;
				z-index: 3;
				left: 0px;
				background: rgb(49, 163, 174);
				height: 2px;
			}
		}

		.asteria-button-navigation.asteria-navigation-active svg {
			fill: #ee7024 !important;
		}

		.asteria-button-search {
			position: absolute;
			left: 58px;
			top: 25px;
			transform: translateY(-50%);
			height: 32px;
			width: 32px;
		}

		.asteria-button-search svg {
			fill: #9f8c82 !important;
		}

		.asteria-button-search:hover svg {
			fill: #7e6262 !important;
		}

		.asteria-button-search.asteria-search-active svg {
			fill: #7e6262 !important;
		}

		.asteria-button-clear-search {
			display: none;
			right: 4px;
			top: 6px;
			.asteria-text {
				font-family: Arial, Helvetica, sans-serif;
				font-size: 14px;
				font-weight: 300;
			}
		}

		div {
			background-color: #fff;
			/* border-bottom: 2px solid rgba(212, 196, 188, 1); */
			border: 0px solid rgba(245, 243, 240, 1);
			/* border-radius: 3px; */
			height: 46px;
			@media (max-width: 1024px) {
				height: 46px;
			}
		}

		.asteria-button div {
			border: 0px solid transparent;
			height: 100%;
			.asteria-input {
				color: #512b2b;
				font-family: Swedbank Sans;
				font-size: 16px;
			}
		}

		.asteria-input {
			@media (max-width: 1024px) {
				font-size: 14px;
			}
		}

		.asteria-controller-search-active-filters {
			margin: 8px 0 0 0;
			padding: 0;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			/* flex-wrap: wrap; */
		}

		.asteria-controller-search-active-filters li {
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}

		.asteria-controller-search-active-filters li div[role='button'] span {
			height: 12px;
			width: 12px;
		}
		.asteria-controller-search-active-filters li div[role='button'] {
			flex-direction: row;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 30px;
			padding: 6px 12px;
			cursor: pointer;
			/* background-color: rgb(252, 193, 42); */
			background-color: rgb(234, 231, 227);
			height: auto;
			&:hover {
				.asteria-button-icon {
					.asteria-icon-wrapper {
						svg {
							fill: #262626;
						}
					}
				}
			}
			p {
				background-color: transparent;
			}
			.asteria-button-icon {
				margin-left: 4px;
				height: 16px;
				width: 16px;
				padding: 0;
				background: 0;
				.asteria-icon-wrapper {
					height: 10px;
					width: 10px;
					padding: 0;
					background: 0;
					svg {
						fill: rgb(159, 140, 130);
						transition: 0.3s ease-in-out;
					}
				}
			}
		}
		.asteria-controller-search-active-filters
			li
			div[role='button']
			.asteria-text {
			display: block;
			color: #512b2b;
			font-family: Swedbank Sans;
			font-size: 14px;
			margin: 0;
			padding: 0;
			line-height: 1.4;
			font-weight: 700;
		}
		li p {
			flex-direction: row;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 30px;
			padding: 6px 12px;
			cursor: pointer;
			height: auto;
			white-space: nowrap;
			margin: 0 8px 0 8px;
			/* background-color: rgb(252, 193, 42); */
			background-color: rgb(234, 231, 227);
			height: auto;
			&:hover {
				.asteria-button-icon {
					.asteria-icon-wrapper {
						svg {
							fill: #262626;
						}
					}
				}
			}
			p {
				background-color: transparent;
			}
			.asteria-button-icon {
				margin-left: 4px;
				height: 16px;
				width: 16px;
				padding: 0;
				background: 0;
				.asteria-icon-wrapper {
					height: 10px;
					width: 10px;
					padding: 0;
					background: 0;
					svg {
						fill: rgb(159, 140, 130);
						transition: 0.3s ease-in-out;
					}
				}
			}
		}
	}

	.asteria-filter-nav {
		background: #fff;
		position: relative;
		box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
		position: absolute;
		top: 50px;
		left: 0px;
		/* 		right: -236px; */
		right: 0;
		padding: 0;
		max-height: 520px;
		overflow-y: auto;
		overflow-x: hidden;
		z-index: 5;
		border-radius: 3px;

		width: calc(100% + 424px);

		@media (max-width: 1380px) {
			/* width: calc(100% + 329px); */
			width: calc(100% + 454px);
		}

		/* border-top: 2px solid rgb(49, 163, 174); */

		/* 		&:before {
			top: -2px;
			left: 0px;
			width: 48px;
			content: "";
			height: 2px;
			background: rgb(49, 163, 174);
			display: block;
		} */

		&.asteria-state-focus {
			left: 48px;
		}

		&::-webkit-scrollbar {
			background-color: #fff;
			width: 16px;
		}

		&::-webkit-scrollbar-track {
			background-color: #fff;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #babac0;
			border-radius: 16px;
			border: 6px solid #fff;
		}

		@media (max-width: 1024px) {
			right: 12px;
		}

		.asteria-search-results {
			list-style-type: none;
			padding: 0;
			margin: 0;
			.asteria-search-results-item {
				padding: 4px 16px;
				justify-content: flex-start;
				align-items: center;
				display: flex;
				// cursor: pointer;
				&:hover {
					/* background: #fbf2ea; */
				}

				.asteria-tag-overview-bar {
					width: 100%;
					max-width: 220px;
					margin-left: auto;
				}
				.asteria-category-overview {
					width: auto;
				}
				.asteria-bar-horizontal {
					/* width: 220px; */
					/* margin-left: auto; */
					@media (max-width: 1024px) {
						width: 85px;
					}
				}
				${Accordion} {
					display: flex;
					flex-direction: column;
					margin-left: 12px;
					/* 					margin-bottom: 8px;
					margin-top: 4px; */
					padding-right: 24px;
					@media (max-width: 1024px) {
						margin-left: 0;
						padding-right: 24px;
					}
					.asteria-tag-status-PAID {
						margin-top: 4px;
					}

					.asteria-category-overview {
					}

					.asteria-bar-horizontal {
						width: 220px;
						@media (max-width: 1024px) {
							width: 85px;
						}
					}

					div[role='button'] {
						&:only-child {
							margin-top: 4px;
						}
					}

					${Prefix} {
						/* 			height: 8px;
						width: 8px;
						margin-right: 8px; */
					}

					.asteria-chip {
						margin-left: 8px;
						margin-top: 4px;
						margin-bottom: 4px;
						@media (max-width: 1024px) {
							margin-left: 0;
						}
						.asteria-text {
							font-size: 12px;
						}
					}
				}
				&.asteria-search-results-item-currencies,
				&.asteria-search-results-item-currencyRisk {
					span {
						display: none;
					}
				}
				&.asteria-search-results-item-customer {
					div[role='button'] {
						width: 100%;
						.asteria-bar-horizontal {
							width: 220px;
							margin-left: auto;
							@media (max-width: 1024px) {
								width: 85px;
							}
						}
						.asteria-text-tag-size {
							margin-right: 24px;
							width: 55px;
							text-align: right;
							@media (max-width: 1024px) {
							}
						}
					}
					.asteria-category-overview {
						/* width: auto; */
						span {
							/* display: none; */
						}
						.asteria-text {
							width: auto;
							max-width: none;
						}
					}
					.asteria-text-tag-empty {
						margin-left: auto;
						width: 293px !important;
						font-size: 12px;
					}
				}
			}
			.asteria-search-results-item span {
				height: 12px;
				width: 12px;
			}
			.asteria-search-results-item .asteria-button {
				flex-direction: column;
				display: flex;
				justify-content: center;
				align-items: center;
				&[disabled] {
					pointer-events: none;
					opacity: 0.3;
				}
			}
			.asteria-search-results-item .asteria-text {
				display: block;
				color: #512b2b;
				font-family: Swedbank Sans;
				font-size: 14px;
				margin: 0;
				padding: 0;
				line-height: 1.4;
				font-weight: 700;
			}

			.asteria-search-results-item .asteria-text-tag-size {
				font-family: Arial, Helvetica, sans-serif;
				font-size: 14px;
				font-weight: 300;
				width: 55px;
			}
		}

		.asteria-search-results-clients {
			display: flex;
			list-style-type: none;
			padding: 0;
			margin: 0;
			flex-direction: column;

			.asteria-search-results-item {
				align-items: flex-start;
				justify-content: flex-start;
				padding: 4px 8px;
				flex: 1;
				.asteria-category-overview {
					width: 80%;
				}
				.asteria-text-tag-size {
					font-family: Arial, Helvetica, sans-serif !important;
					font-size: 14px !important;
					font-weight: 300 !important;
					width: 55px;
					text-align: right;
				}
			}

			.asteria-search-results-item span {
				height: 12px;
				width: 12px;
				display: none;
			}
			.asteria-search-results-item .asteria-button {
				flex-direction: column;
				display: flex;
				justify-content: center;
				align-items: center;
				align-items: flex-start;
				width: 100%;
				flex: 1;
				div {
					width: 100%;
					align-items: flex-start;
				}
			}
			.asteria-chip {
				padding: 6px 12px 6px 12px;
			}
			.asteria-search-results-item .asteria-text {
				display: block;
				color: #512b2b;
				font-family: Swedbank Sans;
				font-size: 14px;
				margin: 0;
				padding: 0;
				line-height: 1.4;
				font-weight: 700;
			}
			.asteria-search-results-item .asteria-title {
				display: block;
				color: #512b2b;
				font-family: Swedbank Sans;
				font-size: 16px;
				margin: 0;
				padding: 12px 12px;
				line-height: 1.4;
				font-weight: 700;
				cursor: pointer;
				width: 100%;
				&:hover {
					background: #fbf2ea;
				}
			}
		}

		.asteria-company-overview {
			display: flex;
			flex-direction: row;

			.asteria-company-overview-item {
				flex-direction: column;
				display: flex;
				text-align: center;
				padding: 32px 32px;
				justify-content: center;
				align-items: center;
				background: #f9f8f6;
				flex: 1;
				margin: 8px 16px;
				cursor: pointer;
				.asteria-title {
					margin: 0 auto;
					@media (max-width: 1279px) {
						font-size: 14px;
					}
				}
			}
		}

		.asteria-section {
			width: 100%;
			margin: 0;
			padding: 16px;
			box-sizing: border-box;
		}

		.asteria-scorecard {
			flex-direction: column;
			display: flex;
			text-align: center;
			padding: 32px 32px;
			justify-content: center;
			align-items: center;
			background: #f9f8f6;
			margin: 8px 16px;
			cursor: pointer;
			.asteria-title {
				margin: 0 auto;
			}
		}
	}
`;

Search.Styler = {
	children: [
		{
			component: DetailedBar,
			base: [Getter('detailedbar')],
		},
	],
};

Search.displayName = 'Search';

const SearchData = React.memo(({ id }) => {
	const [activeCustomer, setActiveCustomer] = useState(false);
	const [isShowDrawer, setShowDrawer] = useState();
	const [isFocus, setIsFocus] = useState(false);
	const [matchedParts, setMatchedParts] = useState([]);
	const [currencies, setCurrencies] = useState([]);
	const [totalImpact, setTotalImpact] = useState(0);
	const [currencyTotal, setCurrencyTotal] = useState([]);
	const [search, setSearch] = useState('');
	const [hideResults, setHideResults] = useState(false);
	const { dispatch, lookup } = useContext(DatalayerContext);
	const apiService = lookup('service-api');

	const inputRef = useRef(null);
	const setFocus = useCallback(
		val => {
			setIsFocus(val);
			dispatch(setSearchFocus(val));

			if (val === true && !isFocus && inputRef.current) {
				inputRef.current.focus();
			}

			if (val === true && isShowDrawer) {
				setShowDrawer(false);
			}
		},
		[dispatch, isFocus, isShowDrawer],
	);

	const onHide = useCallback(
		dontClear => {
			if (dontClear !== true) {
				setSearch('');
				setMatchedParts([]);
			}

			setFocus(false);
			setShowDrawer(false);
		},
		[setFocus],
	);

	useEffect(() => {
		const keypressFocus = e => {
			if (
				!['INPUT', 'TEXTAREA', 'SELECT', 'BUTTON'].includes(
					e.target.nodeName,
				)
			) {
				setFocus(true);
			}

			if (e.target.classList.contains('asteria-forms-dropdown-toggle')) {
				if (inputRef && inputRef.current) {
					inputRef.current.click();
					inputRef.current.focus();
				}
			}
		};

		document.addEventListener('keypress', keypressFocus);

		return () => {
			document.removeEventListener('keypress', keypressFocus);
		};
	}, [setFocus]);

	useEffect(() => {
		dispatch(fetchCategories());
		apiService
			.query(GET_CURRENCIES, {}, { reqAuth: true })
			.subscribe(({ data: { transactionCurrencies = [] } = {} }) => {
				setCurrencies(transactionCurrencies);
				setCurrencyTotal(
					transactionCurrencies.reduce(
						(acc, item) =>
							acc + Math.abs(item?.exposure?.display?.total || 0),
						0,
					),
				);
				setCurrencyTotal(
					transactionCurrencies.reduce(
						(acc, item) =>
							acc + Math.abs(item?.exposure?.display?.total || 0),
						0,
					),
				);
				setTotalImpact(
					transactionCurrencies.reduce(
						(acc, item) =>
							acc +
							Math.abs(
								(item?.deposit?.display?.total || 0) -
									(item?.withdraw?.display?.total || 0),
							),
						0,
					),
				);
			});
	}, [apiService, dispatch, isFocus]);

	useEffect(() => {
		const blur = e => {
			const path =
				e.path ||
				(e.composedPath && e.composedPath()) ||
				domPath(e.target);

			if (!path) {
				onHide(false);
			} else if (
				!path.find(p =>
					p?.classList?.contains('asteria-search-area'),
				) &&
				!path.find(p =>
					p?.classList?.contains('asteria-cashflow-navigation'),
				) &&
				!path.find(p =>
					p?.classList?.contains('asteria-search-wrapper'),
				)
			) {
				onHide(false);
			}
		};

		if (isFocus || isShowDrawer) {
			document.addEventListener('click', blur);
		}

		return () => {
			document.removeEventListener('click', blur);
		};
	}, [isFocus, isShowDrawer, onHide]);

	const [clients = []] = useStore('store-appstate', state => state.clients);
	const [availableCategories = []] = useStore(
		'store-graph',
		state => state.availableCategories,
	);

	const [tags = []] = useStore(
		'store-graph',
		({ [id]: { visibleTags = [] } = {} }) => visibleTags,
	);

	const [listStatus = null] = useStore('store-list', ({ status }) => status);

	const sortedClients = useMemo(
		() =>
			clients.sort(
				({ name: a = '' }, { name: b = '' }) =>
					a?.localeCompare(b) || 0,
			),
		[clients],
	);

	const availableTags = useMemo(
		() =>
			availableCategories
				.reduce((acc, category) => acc.concat(category?.tags), [])
				.map(({ _id, name }) => ({
					id: _id,
					type: 'tag',
					config: { name },
				}))
				.concat([
					{
						id: 'status-OVERDUE',
						type: 'status',
						config: { name: '$overdue', status: 'OVERDUE' },
					},
					{
						id: 'status-UNPAID',
						type: 'status',
						config: { name: '$unpaid', status: 'UNPAID' },
					},
					{
						id: 'status-FORECAST',
						type: 'status',
						config: { name: '$forecast', status: 'FORECAST' },
					},
					{
						id: 'status-deposit',
						type: 'type',
						config: { name: '$deposit', type: 'DEPOSIT' },
					},
					{
						id: 'status-withdraw',
						type: 'type',
						config: { name: '$withdraw', type: 'WITHDRAW' },
					},
				])
				.concat(
					currencies.length > 1
						? [
								{
									id: `currencies`,
									type: 'currencies',
									config: {
										name: TranslationService.get(
											'search.match.currencies',
											'Valutor',
										),
									},
									currencies: currencies.map(currency => ({
										id: `currency_${currency.currency}`,
										type: 'currency',
										config: {
											name: currency.currency,
										},
										currency,
									})),
								},
								{
									id: `currencyRiskHigh`,
									type: 'currencyRisk',
									config: {
										name: TranslationService.get(
											'search.match.currencyRiskHigh',
											'Hög valutarisk',
										),
									},
									currencies: currencies
										.filter(
											currency =>
												currency?.risk?.alert &&
												currency?.exposure?.original
													?.currency !==
													currency?.exposure?.display
														?.currency,
										)
										.map(currency => ({
											id: `currency_${currency.currency}`,
											type: 'currency',
											config: {
												name: currency.currency,
											},
											currency,
										})),
								},
								{
									id: `currencyRiskLow`,
									type: 'currencyRisk',
									config: {
										name: TranslationService.get(
											'search.match.currencyRiskLow',
											'Låg valutarisk',
										),
									},
									currencies: currencies
										.filter(
											currency =>
												!currency?.risk?.alert &&
												currency?.exposure?.original
													?.currency !==
													currency?.exposure?.display
														?.currency,
										)
										.map(currency => ({
											id: `currency_${currency.currency}`,
											type: 'currency',
											config: {
												name: currency.currency,
											},
											currency,
										})),
								},
						  ]
						: [],
				)
				.concat(
					currencies.map(currency => ({
						id: `currency_${currency.currency}`,
						type: 'currency',
						config: {
							name: currency.currency,
						},
						currency,
						total: currencyTotal,
						totalImpact,
					})),
				)
				.concat(
					sortedClients
						.filter(({ name }) => name)
						.map(({ id: clientId, name, type, statistic }) => ({
							id: clientId,
							type: 'client',
							config: {
								client: clientId,
								name,
								type,
								statistic,
							},
						})),
				),
		[
			availableCategories,
			currencies,
			currencyTotal,
			sortedClients,
			totalImpact,
		],
	);

	const [listStatistics = []] = useStore(
		'store-list',
		({ searchStatistics }) => searchStatistics,
	);

	const tagAction = useCallback(
		chip => {
			if (chip?.type === 'currencies' || chip?.type === 'currencyRisk') {
				dispatch(addFilters(chip.currencies));
			} else if (chip) {
				filterAction(chip, dispatch);
			}

			onHide();
		},
		[dispatch, onHide],
	);

	const depositTotal = listStatistics?.deposit?.total || 0;
	const widthdrawTotal = listStatistics?.withdraw?.total || 0;

	const isAvailable = useCallback(
		part => {
			const canBeCurrency = TranslationService.get(
				'search.match.currencies',
				'Valutor',
			).match(new RegExp(part, 'i'));
			const results = availableTags.filter(t => {
				const name = t?.config?.name?.replace('$', '');

				if (t.type === 'tag' || t.type === 'status') {
					const label = TranslationService.get(`tags.${name}`, name);

					if (label.match(new RegExp(part, 'i'))) {
						return true;
					}
				} else if (t.type === 'currency' && canBeCurrency) {
					return true;
				} else if (name.match(new RegExp(part, 'i'))) {
					return true;
				}

				return false;
			});

			return results;
		},
		[availableTags],
	);

	const statsClients = useMemo(
		() =>
			clients
				.filter(c => findCustomer(listStatistics, c.id))
				.sort(
					(a, b) =>
						findCustomer(listStatistics, b.id).total -
						findCustomer(listStatistics, a.id).total,
				),
		[clients, listStatistics],
	);

	const contextData = useMemo(
		() => ({
			id,
			dispatch,
			isFocus,
			setFocus,
			isShowDrawer,
			setActiveCustomer,
			activeCustomer,
			inputRef,
			setSearch,
			search,
			isAvailable,
			tags,
			setMatchedParts,
			matchedParts,
			currencies,
			listStatus,
			setListOpen,
			setShowDrawer,
			statsClients,
			tagAction,
			availableTags,
			widthdrawTotal,
			listStatistics,
			depositTotal,
			onHide,
			hideResults,
			setHideResults,
		}),
		[
			id,
			dispatch,
			isFocus,
			setFocus,
			isShowDrawer,
			activeCustomer,
			search,
			isAvailable,
			tags,
			matchedParts,
			currencies,
			listStatus,
			statsClients,
			tagAction,
			availableTags,
			widthdrawTotal,
			listStatistics,
			depositTotal,
			onHide,
			hideResults,
			setHideResults,
		],
	);

	return (
		<SearchContext.Provider value={contextData}>
			<Search />
		</SearchContext.Provider>
	);
});

SearchData.displayName = 'SearchData';

export default Search;
export { SearchData };
