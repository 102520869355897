import { addMonths, format } from 'date-fns';
import Store from '../../core/store';
import logger from '../../logger';

@logger('appstate:store')
class AppStateStore extends Store {
	name = 'appstate';

	constructor(subject) {
		super(subject, {
			timesliceSize: 'month',
			currentDate: addMonths(new Date(), -4),
			selectedType: ['DEPOSIT', 'WITHDRAW'],
			selectedDate: `${format(new Date(), 'YYYY-MM')}-01T00:00:00.000Z`,
			route: '',
			filters: [],
			factoring: [],
			widgetClasses: [],
			credit: 0,
			modal: null,
			layout: {
				graph: {
					layout: 'flat',
					barLayout: 'grouped',
				},
				layout: 'vertical',
				positioning: 'relative',
			},
			sentToFactoring: [
				/*
				{
					total: 1000000,
					count: 5,
					date: new Date('2020-05-05'),
					status: 'ACCEPTED',
				},
				*/
			],
		});
	}
}

export default AppStateStore;
