import React from 'react';
import { ModalBody, ModalFooter } from '@asteria/component-modal';
import { Text } from '@asteria/component-typography';
import Button from '@asteria/component-button/button';
import { TranslationService } from '@asteria/services-language';
import styled from 'styled-components';
import { compileStyles, StyleGetter } from '@asteria/utils';
import Division from '../components/division';

const ModalFooterStyled = styled(Division)`
	display: flex;
	justify-content: flex-end;
	width: 100%;

	${({ theme }) =>
		// Wrapper styles
		compileStyles(
			{
				component: Division,
				base: [StyleGetter(`supportV2.success.modalFooter`, {})],
				children: [
					{
						component: Button,
						base: [StyleGetter(`button`, {})],
					},
				],
			},
			theme,
		)}
`;

export default ({ reportId }) => (
	<>
		<ModalBody>
			<div
				style={{
					display: 'flex',
					flex: '1',
					flexDirection: 'column',
					margin: '0 20px',
					padding: '16px',
					backgroundColor: '#FAF2EB',
				}}
			>
				<Text type="paragraph">
					{TranslationService.get(
						'supportModal.step3.success.ticketNumber',
						'Ditt support ärende nummer är #',
					)}
					{reportId}
				</Text>
			</div>
		</ModalBody>
		<ModalFooter
			style={{
				paddingLeft: '20px',
				paddingRight: '20px',
			}}
		>
			{({ close }) => (
				<ModalFooterStyled>
					<Button
						size="regular"
						type="primary"
						text={TranslationService.get(
							'supportModal.step3.success.close',
							'Färdig',
						)}
						onClick={close}
					/>
				</ModalFooterStyled>
			)}
		</ModalFooter>
	</>
);
