import React from 'react';
import { OnboardingProvider } from './context';
import OnboardingView from './onboardingView';
import MissingErp from './missingErp';

export default ({ showNotification, provider, callback, ...props }) => (
	<OnboardingProvider callback={callback} {...props}>
		{showNotification}
		<OnboardingView callback={callback} provider={provider} />
	</OnboardingProvider>
);

export { MissingErp };
