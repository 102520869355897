import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import init from '@asteria/services-datalayer';
import {
	setHistory,
	setSite,
} from '@asteria/services-datalayer/services/appstate/actions';
import { loginSuccess } from '@asteria/services-datalayer/services/auth/actions';
import jsonwebtoken from 'jsonwebtoken';
import { createBrowserHistory } from 'history';
import DefaultAssets from '@asteria/services-assets/assets/default';
import ThemeAssets from '@asteria/services-datalayer/services/theme/themes/swedbank';
import axios from 'axios';

import Treyd from './partners/treyd';

import WingsApp from './App';
import * as serviceWorker from './serviceWorker';

function getUrlParams(search) {
	const hashes = search.slice(search.indexOf('?') + 1).split('&');
	return hashes.reduce((params, hash) => {
		const [key, val] = hash.split('=');
		return Object.assign(params, { [key]: decodeURIComponent(val) });
	}, {});
}

function getEnv() {
	// eslint-disable-next-line no-restricted-globals
	const { search = '' } = location || {};
	const searchParams = getUrlParams(search);

	let ENV = 'production';

	// eslint-disable-next-line no-restricted-globals
	if (location.href.includes('dev.') || location.href.includes('dev-')) {
		ENV = 'development';
	} else if (
		// eslint-disable-next-line no-restricted-globals
		location.href.includes('stage.') ||
		// eslint-disable-next-line no-restricted-globals
		location.href.includes('stage-')
	) {
		ENV = 'stage';
		// eslint-disable-next-line no-restricted-globals
	} else if (location.href.includes('localhost')) {
		ENV = 'development';
		// eslint-disable-next-line no-restricted-globals
	} else if (location.href.includes('local.')) {
		ENV = 'local';
	}

	if (searchParams.env) {
		ENV = searchParams.env;
	}

	ENV = 'stage';

	return ENV;
}

function getWidget() {
	// eslint-disable-next-line no-restricted-globals
	const { search = '' } = location || {};
	const searchParams = getUrlParams(search);

	if (searchParams.widget) {
		return searchParams.widget;
	}

	return 'wings';
}

const ENV = getEnv();

const DEMO_TOKEN =
	'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InR5cGUiOiJhY2Nlc3NUb2tlbiIsImlkIjoiMTExMTExMTExMTExMTExMTExMTExMTExIiwiYmFzZVVybCI6Imh0dHBzOi8vc3RhZ2UuYXBpLmFzdGVyaWEuYWkvIiwiY29tcGFueUlkIjoiMTExMTExMTExMTExMTExMTExMTExMTExIiwiY29tcGFueVRlc3REYXRhIjpmYWxzZSwicGFydG5lcklkIjoiNWM0MGFiOWJhNjljMDJkOWI5ZTJkMTUwIiwic2Vzc2lvbklkIjoiZGVtbyIsImFjY2VwdGVkVGVybXMiOnRydWUsInRva2VuVHlwZSI6IlVzZXJUb2tlbiIsInJvbGVzIjpbIkNPTVBBTllBRE1JTiJdfSwiaWF0IjoxNTc4MDUxMjQ1fQ.dOptQBj9lPCclRjryd2Ni3SZHvDLiqFjsSusaTtAaFE';
const DEMO = false;

const UrlToSite = {
	app: 'wings',
};

const PartnerMap = {
	plana: '5fc95b3b4315437216fe04ba',
	swedbank: '5c40ab9ba69c02d9b9e2d150',
	wings:
		// eslint-disable-next-line no-nested-ternary
		ENV === 'production'
			? '5cfe1cd07d50a67ec701e915'
			: ENV === 'stage'
			? '5caa687fa59f7a2ca29ed0eb'
			: '5dca8feda44e315962621ff2',
};

/*
const PartnerThemeMap = {
	plana: '5c40ae76a69c025d08e2d154',
	swedbank: '5c40ae76a69c025d08e2d154',
	wings: '5cb4788480f8e41a679bc177',
};
*/

const PartnerTitle = {
	plana: 'Plana',
	swedbank: 'Företagskollen',
	wings: 'WINGS – Asteria Smart Cash Flow',
};

const isDevEnv = hostname =>
	hostname.includes('localhost') ||
	hostname.includes('dev.wings.asteria.ai') ||
	hostname.includes('dev-wings.asteria.ai') ||
	hostname.includes('stage-wings.asteria.ai') ||
	hostname.includes('stage.wings.asteria.ai');

const getSite = () => {
	// eslint-disable-next-line no-restricted-globals
	const { hostname, search } = location;
	const searchParams = getUrlParams(search);
	const paths = hostname.split('.');

	if (searchParams.site) {
		if (isDevEnv(hostname)) {
			localStorage.asteriaSite = searchParams.site;
		}

		return searchParams.site;
	}

	if (isDevEnv(hostname) && localStorage.asteriaSite) {
		return localStorage.asteriaSite;
	}

	let site = paths?.[paths.length - 3] || 'wings';
	if (site.includes('-')) {
		[, site] = site.split('-');
	}

	return UrlToSite[site] || site;
};

const getSitePartner = async (site, { apiURL }) => {
	const response = await axios.post(apiURL, {
		query: `
			query {
				partnerByDomain(domain: "${site}") {
					id
					settings {
					  themeId
					  hosting {
						language
					  }
					}
				}
		  	}
		`,
	});

	if (response && response.status === 200) {
		const { data: { partnerByDomain } = {} } = response.data;

		return partnerByDomain;
	}

	return null;
};

document.title = PartnerTitle[getSite()] || PartnerTitle.wings;

const eventHandler = (event, data, history) => {
	if (
		event === 'error' &&
		(data.code === '401' || data.message === 'Access token is incorrect')
	) {
		localStorage.removeItem('wingsToken');
		localStorage.removeItem('wingsRefreshToken');
		window.location.href = '/';
		window.location.reload();
	}

	if (event === 'route') {
		history.push(data.to);
	}
};

const WidgetWithRouter = ({ history, ...props }) => (
	<WingsApp
		callback={(...args) => eventHandler(...args, history)}
		{...props}
	/>
);

const bootstrap = async () => {
	const datalayer = await init({
		platform: 'wings',
		env: ENV,
		demo: DEMO,
	});
	const { lookup, dispatch } = datalayer;
	const authStore = lookup('store-auth');
	const history = createBrowserHistory();

	if (window && !window.Asteria) {
		window.Asteria = {};
	}

	window.Asteria.datalayer = datalayer;

	dispatch(setHistory(history));

	if (authStore && localStorage.wingsToken) {
		try {
			const now = Math.floor(Date.now() / 1000);
			const data = jsonwebtoken.decode(localStorage.wingsToken);

			if (data.exp && data.exp < now) {
				throw new Error('Token Expired');
			}

			dispatch(loginSuccess({ accessToken: localStorage.wingsToken }));
		} catch (e) {
			authStore.setState(state => ({
				...state,
				tokens: undefined,
			}));
		}
	}

	if (authStore && localStorage.asteriaAdminToken) {
		try {
			const now = Math.floor(Date.now() / 1000);
			const data = jsonwebtoken.decode(localStorage.asteriaAdminToken);

			if (data.exp && data.exp < now) {
				throw new Error('Token Expired');
			}

			authStore.setState(state => ({
				...state,
				admin: {
					...state.admin,
					tokens: { accessToken: localStorage.asteriaAdminToken },
					authorized: true,
				},
			}));
			// eslint-disable-next-line no-empty
		} catch (e) {}
	}

	if (DEMO) {
		dispatch(loginSuccess({ accessToken: DEMO_TOKEN }));
	}

	const site = getSite();
	const partner = await getSitePartner(site, datalayer);

	if (partner) {
		dispatch(setSite(site, partner.id));
	} else {
		dispatch(setSite('wings', PartnerMap[site]));
	}

	let siteStyle = '';

	try {
		const { default: style } = await import(`./themes/${site}.js`);
		siteStyle = style;
	} catch (e) {
		const { default: style } = await import(`./themes/wings.js`);
		siteStyle = style;
	}

	try {
		const { default: Icons } = await import(`./themes/${site}_icons.js`);
		Object.keys(Icons).forEach(key => {
			DefaultAssets[key] = Icons[key];
			ThemeAssets.assets[key] = Icons[key];
		});
	} catch (e) {
		// DO nothing
	}

	ReactDOM.render(
		<WidgetWithRouter
			datalayer={datalayer}
			site={getSite()}
			siteStyle={siteStyle}
			themeId={partner.settings.themeId}
		/>,
		document.getElementById('root'),
	);
};

let accessToken = false;
if (localStorage.wingsToken) {
	try {
		const now = Math.floor(Date.now() / 1000);
		const data = jsonwebtoken.decode(localStorage.wingsToken);

		if (!data.exp || data.exp > now) {
			accessToken = localStorage.wingsToken;
		}
	} catch (e) {
		// Do nothing
	}
}

const widget = getWidget();

if (widget === 'treyd') {
	Treyd({
		accessToken,
		languageCode: 'sv',
		env: getEnv(),
	});
} else {
	bootstrap();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
