import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import kebabCase from 'lodash/fp/kebabCase';
import { fontSizes, colors } from '@asteria/services-constants';
import PropTypes from 'prop-types';
import { Getter, compileStyles } from '@asteria/utils';

const ElementMapper = {
	title1: 'h1',
	title2: 'h2',
	title3: 'h3',
};

/**
 * Base Title component wrapper
 */
const Title = styled(
	({
		children,
		styler,
		className,
		editStyle,
		editProps,
		size,
		theme,
		...otherProps
	}) => {
		const ElementType = ElementMapper[size] || 'span';

		return (
			<ElementType
				className={classNames(
					'asteria-title',
					size && `asteria-${kebabCase(size)}`,
					size && `asteria-title-${kebabCase(size)}`,
					className,
				)}
				size={size}
				{...otherProps}
			>
				{children}
			</ElementType>
		);
	},
)`
	display: block;

	font-size: ${props => fontSizes[props.size]};
	color: ${colors.headline};

	${({ theme }) => {
		const style = compileStyles(Title, theme);
		return style;
	}}
`;

// Styler structure for title component
Title.Styler = {
	component: Title,
	base: Getter('typography.title'),
};

Title.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	className: PropTypes.string,
	size: PropTypes.string,
	theme: PropTypes.object,
};

Title.defaultProps = {
	children: null,
	className: '',
	size: '',
	theme: {},
};

Title.displayName = 'Title';
export default Title;
