import React, { useContext, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { StyleGetter } from '@asteria/utils';
import classNames from 'classnames';
import { queryCashflow } from '@asteria/services-datalayer/services/graph/actions';
import { endOfMonth, endOfISOWeek, endOfYear, format } from 'date-fns';
import DatalayerContext from '@asteria/services-datalayer/react/context';

import { Bar } from './bar';
import GraphContext from '../../context';
import Background from '../background';

const endOfTime = (date, size) => {
	if (size === 'week') {
		return endOfISOWeek(date);
	}

	if (size === 'month') {
		return endOfMonth(date);
	}

	if (size === 'year') {
		return endOfYear(date);
	}

	return endOfMonth(date);
};

const BarGroup = React.memo(styled(
	({
		beacon = false,
		className,
		margin,
		group = {},
		active = false,
		activeBars = [],
		onClick = () => {},
		maxValue = 0,
		index = 0,
		range = {},
		filters = {},
		size,
	}) => {
		const { id, bars = [] } = group;
		const { types: rangeType = [] } = range;
		const { mouseEnterAction, mouseLeaveAction } = useContext(GraphContext);
		const { dispatch } = useContext(DatalayerContext);
		const { id: rangeId } = range;
		useEffect(() => {
			dispatch(
				queryCashflow('cashflow-bar-graph', {
					startDate: rangeId,
					endDate: `${format(
						endOfTime(new Date(rangeId), size),
						'YYYY-MM-DD',
					)}T23:59:59.999Z`,
					tags: filters
						.filter(({ type }) => type === 'tag')
						.map(({ id: tagId }) => tagId),
					clients: filters
						.filter(({ type }) => type === 'client')
						.map(({ id: customerId }) => customerId),
					currencies: filters
						.filter(({ type }) => type === 'currency')
						.map(({ config: { name } }) => name),
					status: filters
						.filter(({ type }) => type === 'status')
						.map(({ config: { status } }) => status),
				}),
			);
		}, [dispatch, filters, rangeId, size]);

		const max = {};
		for (let i = 0; i < bars.length; i += 1) {
			const { value = 0, data: { type = '' } = {} } = bars[i];

			if (!max[type]) {
				max[type] = bars[i];
			}

			if (Math.abs(value) > Math.abs(max[type].value)) {
				max[type] = bars[i];
			}
		}

		const mouseEnter = useCallback(() => mouseEnterAction({ group }), [
			group,
			mouseEnterAction,
		]);
		const mouseLeave = useCallback(() => mouseLeaveAction({ group }), [
			group,
			mouseLeaveAction,
		]);

		return (
			<div
				className={classNames(
					className,
					'asteria-graph-bar-group',
					rangeType.map(type => `asteria-graph-bar-group-${type}`),
					{
						'asteria-state-active': active,
						'asteria-graph-bar-group-becon': beacon,
					},
				)}
				style={margin}
				role="button"
				tabIndex="-1"
				onMouseEnter={mouseEnter}
				onMouseLeave={mouseLeave}
			>
				<Background />
				{!id ? (
					<>
						<Bar
							id={id}
							bar={{ value: 1, types: ['deposit', 'loading'] }}
							group={group}
							height={0.5}
							key="loading-deposit"
							index={index}
							className={classNames('asteria-loading')}
						/>
						<Bar
							id={id}
							bar={{ value: 1, types: ['withdraw', 'loading'] }}
							group={group}
							height={0.5}
							key="loading-withdraw"
							index={index}
							className={classNames('asteria-loading')}
						/>
					</>
				) : null}
				{bars.map(bar => {
					const { value, types, data: { type = '' } = {} } = bar;
					const magnitude = Math.abs(value / maxValue);
					return (
						<Bar
							beacon={
								beacon === bar.data.type &&
								types.includes('forecast')
							}
							pointer={max[type] === bar}
							id={id}
							active={
								active && activeBars.includes(bar.data.type)
							}
							bar={bar}
							group={group}
							height={magnitude}
							key={`${id}-${types.join('-')}`}
							rangeType={rangeType}
							index={index}
							onClick={onClick}
							className={classNames({
								'asteria-after-becon':
									beacon && beacon !== bar.data.type,
							})}
						/>
					);
				})}
			</div>
		);
	},
)`
	display: -ms-grid;
	display: grid;
`);

BarGroup.displayName = 'BarGroup';
BarGroup.type.Styler = {
	typePrefix: 'asteria-graph-bar-group',
	children: [
		{
			component: Bar,
			base: [StyleGetter('bar')],
		},
		{
			component: Background,
			base: [StyleGetter('background')],
		},
	],
};

export default BarGroup;
