import gql from 'graphql-tag';

export const REPORT_ISSUE = gql`
	mutation reportIssue($input: IssueReportInputType!) {
		reportIssue(input: $input) {
			ok
			error
			reportId
		}
	}
`;

export const GET_NUMBER_OF_OVERDUE = gql`
	query GetNumberOfOverdue {
		transactions(filters: { types: ["DEPOSIT"], status: ["OVERDUE"] }) {
			pageInfo {
				hasNextPage
			}
		}
	}
`;

export const GET_NUMBER_OF_OPEN = gql`
	query GetNumberOfOverdue {
		transactions(filters: { types: ["DEPOSIT"], status: ["UNPAID"] }) {
			pageInfo {
				hasNextPage
			}
		}
	}
`;

export const FETCH_CLIENTS = gql`
	query GetClients {
		clients(pageFilters: { first: 0 }) {
			edges {
				node {
					id
					name
					type
					statistic {
						invoices {
							perYear {
								total
							}
						}
					}
				}
			}
		}
	}
`;

export const ADD_CLIENT = gql`
	mutation AddClient($name: String!, $type: CLIENT_TYPE!) {
		addClient(input: { name: $name, type: $type }) {
			ok
			data {
				id
				name
				type
				unpaid: invoices(
					pageFilters: { first: 0 }
					filters: { paidStatus: UNPAID }
				) {
					id
				}
				overdue: invoices(
					pageFilters: { first: 0 }
					filters: { paidStatus: OVERDUE }
				) {
					id
				}
				statistic {
					invoices {
						perYear {
							total
						}
					}
				}
			}
		}
	}
`;
