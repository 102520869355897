import gql from 'graphql-tag';

export const GET_SUMMARY = gql`
	query GetSummary(
		$size: TRANSACTION_SUMMARY_SIZE
		$startDate: Date
		$endDate: Date
	) {
		transactionsSummary(
			filters: { size: $size, startDate: $startDate, endDate: $endDate }
		) {
			date
			types {
				date
				sums {
					original {
						total
					}
				}
				max {
					original {
						total
					}
				}
				min {
					original {
						total
					}
				}
				probability
				type
				status
				count
			}

			categories {
				type
				status
				sums {
					original {
						total
					}
				}
				category {
					id
					name
					tags {
						id
						name
					}
				}
			}

			tags {
				type
				status
				sums {
					original {
						total
					}
				}
				tag {
					id
					name
				}
			}
		}
	}
`;

export const GET_GRAPH = gql`
	query CashFlow($startDate: Date!, $endDate: Date!, $size: String!) {
		cashflow(
			input: { startDate: $startDate, endDate: $endDate, size: $size }
		) {
			entries {
				incoming {
					total
					volatility
					tags {
						total
						tag
						color
						count
						forecast
					}
					currencies {
						total
						convertedTotal
						currency
						gain
						volatility
					}
				}
				outgoing {
					total
					volatility
					tags {
						total
						tag
						color
						count
						forecast
					}
					currencies {
						total
						convertedTotal
						currency
						gain
						volatility
					}
				}
				account {
					all
					currencies {
						total
						convertedTotal
						currency
					}
					tags {
						total
						tag
						count
					}
					fx {
						total
						convertedTotal
						currency
						gain
						volatility
						type
					}
				}
				credit
				type
				factory
				timeslice
				date
			}
		}
	}
`;

export const GET_CATEGORIES = gql`
	query Categories {
		categories {
			_id
			name
			tags {
				_id
				name
			}
		}
	}
`;

export const SUBSCRIBE_TRANSACTION_CREATED = gql`
	subscription TimesliceCreated {
		transactionCreated {
			paymentDate
		}
	}
`;

export const SUBSCRIBE_TRANSACTION_UPDATED = gql`
	subscription TimesliceUpdated {
		transactionUpdated {
			paymentDate
		}
	}
`;

export const SUBSCRIBE_TRANSACTION_DELETED = gql`
	subscription TimesliceDeleted {
		transactionDeleted
	}
`;
