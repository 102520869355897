import React from 'react';
import styled, { withTheme } from 'styled-components';
import { compileObject, StyleGetter } from '@asteria/utils';

const StaticBarView = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
`;

const BarView = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	transition: width 0.3s linear;
	svg circle {
		animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
			infinite;
	}
	@keyframes indeterminate {
		0% {
			stroke-dasharray: 6, 36.8;
		}
		60% {
			stroke-dasharray: 22, 36.8;
		}
		100% {
			stroke-dasharray: 6, 36.8;
		}
	}
`;

const IndeterminateBar = styled(
	({ isStatic, theme, radius = 5.85, ...props }) => {
		const {
			bar: { svg: { style: svgStyle = {} } = {} } = {},
		} = compileObject(
			{
				base: [StyleGetter(`progressBar.circular`, {})],
			},
			theme,
		);

		const svgPic = (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.7 12.7">
				<circle
					r={radius + 1 - (parseInt(svgStyle.width, 10) / 2 || 1)}
					transform="rotate(-90 6.35 6.35)"
					cy="6.35"
					cx="6.35"
					style={{
						fill: 'none',
						stroke: svgStyle.stroke,
						strokeWidth: parseInt(svgStyle.width, 10) / 2 || '1',
						strokeDasharray: `14.2, 36.8`,
						transition: '0.3s linear',
					}}
				/>
			</svg>
		);

		return isStatic ? (
			<StaticBarView {...props}>{svgPic}</StaticBarView>
		) : (
			<BarView {...props}>{svgPic}</BarView>
		);
	},
)``;

// TODO Somehow theme needs to passed to the component via withTheme, maybe check it later
export default withTheme(IndeterminateBar);
