import { addMinutes } from 'date-fns';

export default [
	{
		id: '5e662f033e314150adb3b4c0',
		key: 'Fortnox',
		type: 'erp',
		lastSync: addMinutes(new Date(), -5),
		config: { connected: true, errors: [] },
		status: { state: 'IDLE' },
	},
	{
		id: '5e6648f63e31413eadb3b4c2',
		key: 'Fortnox Föregående Bokföring',
		type: 'erp',
		lastSync: addMinutes(new Date(), -2),
		config: { connected: true, errors: [] },
		status: { state: 'IDLE' },
	},
];
